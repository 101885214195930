(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockControlLocStock', {
        templateUrl: 'views/components/views/stockControlLocStock/stockControlLocStock.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'stockControlLocStockService', 'layoutService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, stockControlLocStockService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockLocationKeyno = $stateParams.stocklocation_keyno;
            let stockKeyno = $stateParams.stock_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                bgStockControlLocStockView: {
                    selected: 'CONTROL',
                    buttons: [
                        { id: 'CONTROL', label: 'bg_stockcontrollocstock_view_control', color: 'primary', onClick: () => vm.model.bgStockControlLocStockView.selected = 'CONTROL' },
                        { id: 'OTHER', label: 'bg_stockcontrollocstock_view_other', color: 'primary', onClick: () => vm.model.bgStockControlLocStockView.selected = 'OTHER' }
                    ]
                },
                volumeM3Buttons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodsizeedit', { prodsize_keyno: vm.model.edit.prodsize_keyno }) }
                ],
                eanListButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('producteans', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                edit: {},
                itemsListOthers: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                stockControlLocStockService.getEdit({
                    stocklocation_keyno: stockLocationKeyno,
                    stock_keyno: stockKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadOthers();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadOthers = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stocklocation_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.stock_keyno) !== true) return;
                
                vm.model.itemsListOthers = [];
                
                return stockControlLocStockService.listOthers({
                    stocklocation_keyno: vm.model.edit.stocklocation_keyno,
                    stock_keyno: vm.model.edit.stock_keyno
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOthers);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockControlLocStockView = function (value) {
                vm.model.bgStockControlLocStockView.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockControlLocStockService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
