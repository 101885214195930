import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterGlyph' })
export class FilterGlyphPipe implements PipeTransform {
    transform(glyphs: any[], item: any): any[] {
        const filtered: any[] = [];
        if (!glyphs || !item) return filtered;

        for (let i = 0; i < glyphs.length; i++) {
            if (
                glyphs[i] &&
                glyphs[i][0] &&
                glyphs[i][0].item_parent_id !== undefined &&
                item.item_id !== undefined &&
                glyphs[i][0].item_parent_id === item.item_id
            ) {
                glyphs[i].forEach((g: any) => {
                    filtered.push(g);
                });
                break;
            }
        }

        return filtered;
    }
}
