<div
    class="tt-toast"
    [ngClass]="'tt-toast--' + ttType + (contentOverflowMenu?.open === true || viewOverflowMenu?.open === true ? ' tt-toast--overflow-open' : '')"
    (mouseenter)="ttMouseEnter.emit(this)"
    (mouseleave)="ttMouseLeave.emit(this)"
>
    <header class="tt-toast__header">
        <span *ngIf="!ttGoto && !ttClick.observed" class="tt-toast__heading">
            <ng-container [ngTemplateOutlet]="ttType === 'success' ? successIcon : ttType === 'danger' ? dangerIcon : ttType === 'warning' ? warningIcon : primaryIcon"></ng-container>
            <span class="tt-toast__heading-text" [title]="title">{{ title }}</span>
        </span>
        <a *ngIf="!!ttGoto && !ttClick.observed" class="tt-toast__heading tt-toast__heading--link" [href]="utility.generateStatePath(ttGoto, ttGotoParms)" (click)="goto($event)">
            <ng-container [ngTemplateOutlet]="ttType === 'success' ? successIcon : ttType === 'danger' ? dangerIcon : ttType === 'warning' ? warningIcon : primaryIcon"></ng-container>
            <span class="tt-toast__heading-text tt-toast__heading-text--link" [title]="title">{{ title }}</span>
        </a>
        <button *ngIf="!!ttClick.observed" class="tt-toast__heading tt-toast__heading--link" (click)="ttClick.emit({ event: $event, toast: this })">
            <ng-container [ngTemplateOutlet]="ttType === 'success' ? successIcon : ttType === 'danger' ? dangerIcon : ttType === 'warning' ? warningIcon : primaryIcon"></ng-container>
            <span class="tt-toast__heading-text tt-toast__heading-text--link" [title]="title">{{ title }}</span>
        </button>

        <tt-overflow-menu *ngIf="!!ttOverflow" [ttIcon]="ttOverflow.icon">
            <ng-container *ngFor="let item of ttOverflow?.items">
                <!-- *ngIf="(item?.hidden && item?.hidden()) || !item.hidden" -->
                <tt-overflow-menu-item
                    [hidden]="item.hidden ? item.hidden() : false"
                    [ttText]="item.text || ''"
                    [ttIcon]="item.icon"
                    [ttHasDivider]="item.hasDivider"
                    [ttDanger]="item.danger"
                    (ttClick)="item.click($event)"
                    [ttTooltipText]="item.tooltipText ? item.tooltipText() : ''"
                    [ttDisabled]="item.disabled ? item.disabled() : false"
                ></tt-overflow-menu-item>
            </ng-container>
        </tt-overflow-menu>
        <ng-content select="tt-overflow-menu"></ng-content>
        <button [hidden]="!ttClose.observed" class="tt-toast__close" (click)="ttClose.emit(this)">
            <span class="fa-fw far fa-times"></span>
        </button>
    </header>
    <span *ngIf="!!message" class="tt-toast__message" [title]="message">{{ message }}</span>
</div>

<ng-template #primaryIcon>
    <span
        class="fa-fw fad fa-info-circle"
        style="--fa-primary-color: #ffffff; --fa-primary-opacity: 1; --fa-secondary-color: var(--tt-primary-color); --fa-secondary-opacity: 1; font-size: var(--tt-font-size-lg)"
    ></span>
</ng-template>
<ng-template #successIcon>
    <span
        class="fa-fw fad fa-check-circle"
        style="--fa-primary-color: #ffffff; --fa-primary-opacity: 1; --fa-secondary-color: var(--tt-success-color-500); --fa-secondary-opacity: 1; font-size: var(--tt-font-size-lg)"
    ></span>
</ng-template>
<ng-template #warningIcon>
    <span
        class="fa-fw fad fa-exclamation-triangle"
        style="--fa-primary-color: #000; --fa-primary-opacity: 1; --fa-secondary-color: var(--tt-warning-color); --fa-secondary-opacity: 1; font-size: var(--tt-font-size-lg)"
    ></span>
</ng-template>
<ng-template #dangerIcon>
    <span
        class="fa-fw fad fa-exclamation-circle"
        style="--fa-primary-color: #ffffff; --fa-primary-opacity: 1; --fa-secondary-color: var(--tt-danger-color); --fa-secondary-opacity: 1; font-size: var(--tt-font-size-lg)"
    ></span>
</ng-template>

<ng-template #heading>
    <ng-container [ngTemplateOutlet]="ttType === 'success' ? successIcon : ttType === 'danger' ? dangerIcon : ttType === 'warning' ? warningIcon : primaryIcon"></ng-container>
    <span class="tt-toast__heading-text" [title]="title">{{ title }}</span>
</ng-template>
