(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('oeeMenu', {
        templateUrl: 'views/components/views/oeeMenu/oeeMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', function (stateService) {
            const vm = this;
            
            vm.model = {
                name: stateService.getCurrentName()
            };
        }]
    });
})();
