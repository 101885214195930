(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2ReleaseNoteEdit', {
        templateUrl: 'views/components/views/p2ReleaseNoteEdit/p2ReleaseNoteEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2ReleaseNoteKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'p2ReleaseNoteEditService', 'layoutService', function ($stateParams, stateService, p2ReleaseNoteEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2ReleaseNoteKeyno = vm?.p2ReleaseNoteKeyno ?? $stateParams.p2_releasenote_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                selectListReleaseTypes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                p2ReleaseNoteEditService.getEdit({ p2_releasenote_keyno: p2ReleaseNoteKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadReleaseTypeNames = function () {
                p2ReleaseNoteEditService.listReleaseTypeNames().then(function (list) {
                    angular.copy(list, vm.model.selectListReleaseTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2ReleaseNoteEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;

                    if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                        vm.onSave();
                    } else {
                        stateService.back();
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
                //loadReleaseTypeNames();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2ReleaseNoteKeyno?.currentValue) p2ReleaseNoteKeyno = changes.p2ReleaseNoteKeyno.currentValue;

                if (p2ReleaseNoteKeyno) {
                    loadEdit();
                    loadReleaseTypeNames();
                }
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
