<section class="consignmentplansdelivery">
    <div class="row-fluid">
        <form class="form-horizontal im-no-pad" novalidate method="get" role="form">
            <div class="well well-sm col-xs-12">
                <tt-input-legacy label="Labour"
                          [field]="model.labour_name"
                          type="input"
                          typevar="rbg"
                          [optionfunc]="goToSelectLabour.bind(this)"
                          glyphs="glyphicon-search">
                </tt-input-legacy>

                <tt-input-legacy label="Location"
                          [field]="model.stocklocation_name"
                          type="input"
                          typevar="rbg"
                          [optionfunc]="goToSelectLocation.bind(this)"
                          glyphs="glyphicon-search">
                </tt-input-legacy>
            </div>

            <tt-list-legacy [listItems]="model.plans"
                     ltype="multi"
                     [mconfig]="model.mconfig"
                     designVar="s12t0"
                     [optionfunc]="gridClick.bind(this)"
                     typevar="g">
            </tt-list-legacy>
        </form>
    </div>
</section>
