(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupSettingEdit', {
        templateUrl: 'views/components/views/usergroupSettingEdit/usergroupSettingEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            itemId: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'usergroupSettingEditService', 'layoutService', function ($stateParams, stateService, usergroupSettingEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let itemId = vm?.itemId ?? $stateParams.item_id;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                usergroupSettingEditService.getEdit({ p2_usergroup_setting_keyno: itemId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                usergroupSettingEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;

                    if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                        vm.onSave();
                    } else {
                        stateService.back();
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT LOAD FUNCTION

            vm.$onInit = function () {
                //loadEdit();
            };

            // #endregion ON INIT LOAD FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.itemId?.currentValue) {
                    itemId = changes.itemId.currentValue;
                }

                if (itemId) loadEdit();
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
