// ******************************************************************
//  auth: ODL
//  description: beskrivelse
//  dependencies: 
//  mods: 
//      BJS 20210213: Refactored controller and added paging.
// *******************************************************************

import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';

(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceHeads', {
        templateUrl: 'views/components/views/invoiceHeads/invoiceHeads.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', '$timeout', '$localStorage', 'base64', 'stateService', 'utilityService', 'modalService', 'translateService', 'invoiceHeadsService', 'logisticService', 'rememberService', 'emailCreateService', 'printService', 'fileService', 'docarcService', 'graphAuthService', 'graphMailService', 'layoutService', function ($q, $stateParams, $timeout, $localStorage, base64, stateService, utilityService, modalService, translateService, invoiceHeadsService, logisticService, rememberService, emailCreateService, printService, fileService, docarcService, graphAuthService, graphMailService, layoutService) {
            var vm = this;

            let argType = $stateParams.argtype;

            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                argType: argType,
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filterval') }
                ],
                bgPrintStatus: {
                    selected: 'ALL',
                    buttons: [
                        { id: 'ALL', label: 'gb_printstatus_all', color: 'primary', onClick: () => setPrintStatusView('ALL') },
                        { id: 'NOT_PRINTED', label: 'gb_printstatus_not_printed', color: 'primary', onClick: () => setPrintStatusView('NOT_PRINTED') },
                        { id: 'PRINTED', label: 'gb_printstatus_printed', color: 'primary', onClick: () => setPrintStatusView('PRINTED') },
                        { id: 'NOTACCOUNTED', label: 'gb_printstatus_notaccounted', color: 'primary', onClick: () => setPrintStatusView('NOTACCOUNTED') }
                    ]
                },
                bgTouchFunction: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'gb_touchfunction_select', color: 'primary', onClick: () => setTouchFunctionView('SELECT') },
                        { id: 'GOTO', label: 'gb_touchfunction_goto', color: 'primary', onClick: () => setTouchFunctionView('GOTO') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                itemsListPagedInvoices: {
                    records: [],
                    total: 0
                },
                gridInvoicesReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.gridInvoices = {
                dataTask: {
                    rememberId: 'w_invoiceheads.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'invoiceheads_account_selected',
                                text: 'invoiceheads_account_selected',
                                func: function () {
                                    addItem('account');
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return hasValidReceiveStatus(); }
                                disabled: function () { return disabledBtnAccountSelected(); }
                                //disabled: function () {
                                //    if (vm.model.setting.tt_accouning_system === '1') {
                                //        return false;
                                //    } else {
                                //        return 'hidden';
                                //    }
                                //}
                            },
                            {
                                name: 'invoiceheads_set_marked_as_printed_ok',
                                text: 'invoiceheads_set_marked_as_printed_ok',
                                func: function () {
                                    addItem('mark_as_printed');
                                },
                                icon: 'fa-none',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                disabled: function () {
                                    if (vm.model.setting.tt_accouning_system !== '1' && vm.model.bgPrintStatus.selected === 'NOT_PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length > 0) {
                                        return false;
                                    } else if (vm.model.setting.tt_accouning_system !== '1' && vm.model.bgPrintStatus.selected === 'NOT_PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length === 0) {
                                        return true;
                                    }

                                    return 'hidden';
                                }
                            },
                            {
                                name: 'invoiceheads_set_marked_as_not_printed_ok',
                                text: 'invoiceheads_set_marked_as_not_printed_ok',
                                func: function () {
                                    addItem('mark_as_not_printed');
                                },
                                icon: 'fa-none',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                disabled: function () {
                                    if (vm.model.bgPrintStatus.selected === 'PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length > 0) {
                                        return false;
                                    } else if (vm.model.bgPrintStatus.selected === 'PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length === 0) {
                                        return true;
                                    }

                                    return 'hidden';
                                }
                            },
                            {
                                name: 'invoiceheads_send_email',
                                text: 'invoiceheads_send_email',
                                func: function () {
                                    if (vm.model.setting.report_filename === '' || vm.model.setting.invoice_reportdef_keyno === '') {
                                        modalService.show({
                                            type: 'warning',
                                            title: translations.could_not_send_email,
                                            message: translations.no_report_connected_to_your_company,
                                            buttons: [{
                                                label: translations.ok,
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();

                                                    modalService.progressInfo.finishclose = '1';
                                                    vm.model.runProgress = 'ABORT';
                                                    modalService.progressInfo.abortclose = true;

                                                    doStep();
                                                }
                                            }]
                                        });
                                    } else {
                                        addItem('mark_as_sent_email');
                                    }
                                },
                                icon: 'fa-envelope',
                                cssClass: 'tt-button tt-button--success im-grid-btn-xs-r',
                                translate: true,
                                disabled: function () {
                                    if (vm.model.setting.send_emails_for_chosen_invoices === '1' && vm.model.bgPrintStatus.selected === 'NOT_PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length > 0) {
                                        return false;
                                    } else if (vm.model.setting.send_emails_for_chosen_invoices === '1' && vm.model.bgPrintStatus.selected === 'NOT_PRINTED' && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length === 0) {
                                        return true;
                                    }

                                    return 'hidden';
                                }
                            }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },

                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            function disabledBtnAccountSelected() {
                //console.log('called disabledBtnAccountSelected');
                //console.dir(vm.model.setting.tt_accouning_system);
                //return 'hidden';

                if (vm.model.setting.tt_accouning_system === '1' && (vm.model.bgPrintStatus.selected === 'NOT_PRINTED' || vm.model.bgPrintStatus.selected === 'NOTACCOUNTED') && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length > 0) {
                    return false;
                } else if (vm.model.setting.tt_accouning_system === '1' && (vm.model.bgPrintStatus.selected === 'NOT_PRINTED' || vm.model.bgPrintStatus.selected === 'NOTACCOUNTED') && vm.gridInvoices.gridfunc.getDirtyRows().filter((row) => row.is_selected === true).length === 0) {
                    return true;
                } else {
                    return 'hidden';
                }
            }

            utilityService.setDefaultValues($localStorage, {
                invoiceHeadsCtrl: {
                    page: 1,
                    pageSize: 10
                },
                pageSizes: [5, 10, 15, 25, 50, 75, 100]
            });

            vm.$storage = $localStorage;

            var p2WInvoiceHeadsMarkAs = {
                printed: {},
                notPrinted: {}
            };

            var promises = {};

            let variableNames = {
                businessco_no: '',
                date_fom: '',
                date_tom: '',
                filterval: '',
                dateselector_index: '',
                gb_printstatus: '',
                gb_touchfunction: ''
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPrintStatusView = function (id) {
                vm.model.bgPrintStatus.selected = vm.model.setting.gb_printstatus = id;
            };

            let setTouchFunctionView = function (id) {
                vm.model.bgTouchFunction.selected = vm.model.setting.gb_touchfunction = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            var cancelAndRun = function (id, func, delay) {
                if (angular.isObject(promises[id])) {
                    $timeout.cancel(promises[id]);
                }

                delay = delay || 50;

                promises[id] = $timeout(func, delay);
            };

            var isValidValue = function (value) {
                if (angular.isUndefined(value))
                    return false;
                if (value === null)
                    return false;

                for (var i = 1; i < arguments.length; i++) {
                    if (arguments[i] === value)
                        return false;
                };

                return true;
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadPagedInvoices = function () {
                cancelAndRun('invoiceHeadList', function () {
                    if (isValidValue(vm.model.setting.businessco_no, '', '0', 0) !== true) return;
                    if (isValidValue(vm.model.setting.date_fom, '') !== true) return;
                    if (isValidValue(vm.model.setting.date_tom, '') !== true) return;

                    var sendSettings = angular.copy(vm.model.setting);

                    sendSettings.gb_printstatus = vm.model.bgPrintStatus.selected;
                    sendSettings.page = vm.$storage.invoiceHeadsCtrl.page;
                    sendSettings.pageSize = vm.$storage.invoiceHeadsCtrl.pageSize;

                    return invoiceHeadsService.listPagedInvoices(sendSettings).then(function (data) {
                        vm.model.itemsListPagedInvoices.total = data.total;

                        angular.copy(data.items, vm.model.itemsListPagedInvoices.records);
                    });
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                        case 'date_fom':
                        case 'date_tom':
                        case 'dateselector_index':
                        case 'gb_printstatus':
                        case 'gb_touchfunction':
                        case 'filterval':
                            variableNames[key] = stateService.getCurrentName() + '/' + $stateParams.argtype + '/' + $stateParams.argvalue + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'gb_printstatus':
                    case 'gb_touchfunction':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        variableValue = vm.model.setting.filterval;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var loadP2WInvoiceHeadsSetting = function () {
                invoiceHeadsService.genericFunction(806, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    webpage: stateService.getCurrentName() + '/' + $stateParams.argtype + '/' + $stateParams.argvalue
                }).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);

                    setPrintStatusView(vm.model.setting.gb_printstatus);
                    setTouchFunctionView(vm.model.setting.gb_touchfunction);
                    
                    loadPagedInvoices();
                    loadGridInvoices();
                });
            };

            let loadGridInvoices = function () {
                var sendSettings = angular.copy(vm.model.setting);

                sendSettings.gb_printstatus = vm.model.bgPrintStatus.selected;
                sendSettings.page = vm.$storage.invoiceHeadsCtrl.page;
                sendSettings.pageSize = vm.$storage.invoiceHeadsCtrl.pageSize;

                vm.gridInvoices.dataTask.loadData = {
                    method: 2988,
                    parameters: sendSettings
                };

                vm.model.gridInvoicesReady = true;
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filterval':
                        vm.model.setting.filterval = '';

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region VARIABLES & DEFINITIONS

            let translations = {
                could_not_send_email: '',
                no_report_connected_to_your_company: '',
                error: '',
                ok: ''
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items, func) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].invoice_no;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var parms = {
                            invoice_no: items[step].invoice_no
                        };

                        //console.log('HIT');
                        //console.log('invoice_no: ' + items[step].invoice_no);
                        //console.log('parms: ' + angular.toJson(parms));
                        //console.log('parms');
                        //console.dir(parms);

                        function getFunction() {
                            if (func === 'account') {
                                return invoiceHeadsService.sendInvoiceToAccounting(parms);
                            } else if (func === 'mark_as_printed') {
                                return invoiceHeadsService.gridMarkInvoiceAsPrinted(parms);
                            } else if (func === 'mark_as_not_printed') {
                                return invoiceHeadsService.gridMarkInvoiceAsNotPrinted(parms);
                            } else if (func === 'mark_as_sent_email') {
                                //return invoiceHeadsService.gridMarkInvoiceAsSentEmail(parms)
                                return vm.sendEmail(items[step].invoice_no);
                            }
                        }

                        getFunction()?.then(function (response) {
                            //console.log('HIT2');
                            //console.log(response);
                            //console.log('errorcode: ' + response[0].errorcode);
                            if (response && response?.[0] && response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let addItem = function (func) {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;
                
                var dirtyRows = vm.gridInvoices.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({ invoice_no: item.invoice_no });
                });
                
                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgress(selectedItems, func);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        modalService.progressInfo.finishclose = '1';
                        vm.model.runProgress = 'ABORT';
                        modalService.progressInfo.abortclose = true;
                    } else if (vm.model.runProgress === 'FINISH') {
                        loadGridInvoices();
                        vm.gridInvoices.gridfunc.read();
                    }
                });
            };

            let markAsPrintedGrid = async function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var dirtyRows = vm.gridInvoices.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push(item);
                });

                vm.model.progressInfo.max = dirtyRows.length;
                await invoiceHeadsService.genericFunction(826, {
                    records: selectedItems
                })

                await loadGridInvoices();
                vm.gridInvoices.gridfunc.rebind();
            };
            
            let markAsNotPrintedGrid = async function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var dirtyRows = vm.gridInvoices.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push(item);
                });

                vm.model.progressInfo.max = dirtyRows.length;
                await invoiceHeadsService.genericFunction(827, {
                    records: selectedItems
                })

                await loadGridInvoices();
                vm.gridInvoices.gridfunc.rebind();
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region SEND EMAIL BUTTON FUNCTION

            vm.sendEmail = function (invoice_no) {
                var deferred = $q.defer();

                var promises = [];
                var instances = {};
                var parameters = {};

                parameters.invoice_no = invoice_no;

                var instanceId = uuid();

                instances[instanceId] = { p2_reportdef_keyno: vm.model.setting.invoice_reportdef_keyno};

                promises.push(printService.archive({
                    reportId: vm.model.setting.report_filename,
                    instanceId: instanceId,
                    parameters: parameters
                }));

                $q.all(promises).then(function (result) {
                    var detailsPromises = [];

                    var newDocumentsCount = result.length;

                    angular.forEach(result, function (fileItem) {
                        detailsPromises.push(fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }));
                    });

                    $q.all(detailsPromises).then(async function (detailsResult) {
                        var existing = detailsResult.splice(newDocumentsCount, detailsResult.length - newDocumentsCount);

                        var docarcPromises = [];

                        angular.forEach(detailsResult, function (dr) {
                            var docarc = {
                                filename: dr.fileName,
                                contentType: dr.contentType || '',
                                latitude: 0,
                                longitude: 0,
                                altitude: 0,
                                createdTimestamp: dr.regDateTime || '',
                                size: dr.size || 0,
                                checksum: dr.checksum || '',
                                argType: 'invoice_no' || '',
                                argValue: invoice_no || '',
                                p2ReportdefKeyno: instances[dr.instanceId].p2_reportdef_keyno
                            };

                            docarcPromises.push(docarcService.add(docarc));
                        });

                        let sentByClient = '1';

                        try {
                            await graphAuthService.getAccount();
                        } catch (error) {
                            sentByClient = '0';
                        }

                        $q.all(docarcPromises).then(function (docarcResult) {
                            emailCreateService.details('invoicehead', 'invoice_no', invoice_no).then(function (emailDetails) {
                                var emailObj = {
                                    recipient_email: emailDetails.recipient_email,
                                    copy_email: emailDetails.copy_email,
                                    bcc_email: emailDetails.bcc_email,
                                    from_email: emailDetails.from_email,
                                    email_heading: base64.urlencode(emailDetails.email_heading),
                                    email_body: base64.urlencode(emailDetails.email_body),
                                    webpage: 'invoiceheads',
                                    argtype: 'invoice_no',
                                    argvalue: invoice_no,
                                    reports: [],
                                    documents: [],
                                    send_by_client: sentByClient,
                                    emailtemplate_keyno: emailDetails.emailtemplate_keyno
                                };

                                angular.forEach(docarcResult, function (item) {
                                    emailObj.reports.push({ docarc_keyno: item[0].docarc_keyno, docfilename: item[0].docfilename });
                                });

                                angular.forEach(existing, function (item) {
                                    emailObj.documents.push({ docarc_keyno: item.instanceId, docfilename: item.localFileName });
                                });
                                emailCreateService.sendEmail(emailObj).then(function (data) {
                                    //console.log('on success');
                                    //console.dir(data);
                                    //ieScreenBlockService.stop();

                                    if ('' + data.errorcode !== '0') {
                                        modalService.show({
                                            type: 'warning',
                                            title: 'Varsel',
                                            message: data.errormessage,
                                            buttons: [{
                                                label: 'OK',
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();
                                                }
                                            }]
                                        });

                                        deferred.reject(re);
                                    } else {
                                        if (sentByClient === '1' && data?.mailinfo && data.mailinfo.length > 0) {
                                            try {
                                                angular.forEach(data.mailinfo, function (mi) {
                                                    graphMailService.sendMessage({ subject: base64.urldecode(mi.subject), content: base64.urldecode(mi.bodyHtml), recipients: mi.to, ccRecipients: mi.cc, bccRecipients: mi.bcc, attachments: mi.attachments, userId: mi.from });
                                                });
                                            } catch (error) {
                                                modalService.show({
                                                    type: 'warning',
                                                    title: 'Varsel',
                                                    message: 'An error occured',
                                                    buttons: [{
                                                        label: 'OK',
                                                        cssClass: 'btn-warning',
                                                        action: function (dialogItself) {
                                                            dialogItself.close();
                                                        }
                                                    }]
                                                });

                                                deferred.reject(error);
                                            }
                                        }
                                    }

                                    deferred.resolve(data);

                                }, (function (data) {
                                    console.log('on failure');
                                    console.dir(data);
                                    //ieScreenBlockService.stop();
                                    modalService.show({
                                        type: 'warning',
                                        title: 'Varsel',
                                        message: data.data.message,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });

                                    deferred.reject(re);
                                }));
                            });
                            //let emailDetails = await emailCreateService.details('invoicehead', 'invoice_no', invoice_no);
                            //let emailDetails = (await emailCreateService.details('invoicehead', 'invoice_no', invoice_no));

                            //var emailObj = {
                            //    recipient_email: vm.model.setting.recipient_email,
                            //    copy_email: vm.model.setting.copy_email,
                            //    bcc_email: vm.model.setting.bcc_email,
                            //    from_email: vm.model.setting.from_email,
                            //    email_heading: base64.urlencode(vm.model.setting.email_heading),
                            //    email_body: base64.urlencode(vm.model.setting.email_body),
                            //    webpage: 'invoiceheads',
                            //    argtype: 'invoice_no',
                            //    argvalue: invoice_no,
                            //    reports: [],
                            //    documents: [],
                            //    send_by_client: sentByClient,
                            //    emailtemplate_keyno: vm.model.setting.emailtemplate_keyno
                            //};

                            
                        }, function (re) {
                            console.log('docarc rejection');
                            console.dir(re);
                            deferred.reject(re);
                        });
                    });
                });

                return deferred.promise;
            };

            // #endregion SEND EMAIL BUTTON FUNCTION

            vm.selectBgPrintStatus = function (value) {
                vm.model.bgPrintStatus.selected = value;
                vm.model.setting.gb_printstatus = vm.model.bgPrintStatus.selected;

                rememberFunc('gb_printstatus');
                loadPagedInvoices();
                vm.gridInvoices.gridfunc.rebind()
                loadGridInvoices();
            };

            vm.selectBgTouchFunction = function (value) {
                vm.model.bgTouchFunction.selected = value;
                vm.model.setting.gb_touchfunction = vm.model.bgTouchFunction.selected;

                rememberFunc('gb_touchfunction');
                loadPagedInvoices();
            };

            vm.pageChanged = function () {
                loadPagedInvoices();
            };

            vm.setPageSize = function (size) {
                vm.$storage.invoiceHeadsCtrl.pageSize = size;

                vm.pageChanged();
            };

            vm.showChecked = false;

            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id !== '0' && item.item_id !== '-1') {
                    if (item.item_is_selected == '0') {
                        item.item_is_selected = '1';
                    } else if (item.item_is_selected == '1') {
                        item.item_is_selected = '0';
                    }
                }
            };

            vm.selectAll = function () {
                angular.forEach(vm.model.itemsListPagedInvoices.records, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1') {
                        item.item_is_selected = '0';
                        vm.toggleChosen(item);
                    }
                });
            };

            vm.unselectAll = function () {
                angular.forEach(vm.model.itemsListPagedInvoices.records, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1') {
                        item.item_is_selected = '1';
                        vm.toggleChosen(item);
                    }
                });
            };

            vm.setMarkedAsPrinted = function () {
                var selectedToPrint = [];

                angular.forEach(vm.model.itemsListPagedInvoices.records, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1' && item.item_is_selected == '1') {
                        selectedToPrint.push(item);
                    }
                });

                invoiceHeadsService.genericFunction(826, {
                    records: selectedToPrint
                }).then(function (data) {
                    p2WInvoiceHeadsMarkAs.printed = angular.copy(data[0]);

                    if (p2WInvoiceHeadsMarkAs.printed.completed_ok == '1') {
                        loadPagedInvoices();
                    }
                });
            };

            vm.setMarkedAsNotPrinted = function () {
                var selectedToPrint = [];

                angular.forEach(vm.model.itemsListPagedInvoices.records, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1' && item.item_is_selected == '1') {
                        selectedToPrint.push(item);
                    }
                });

                invoiceHeadsService.genericFunction(827, {
                    records: selectedToPrint
                }).then(function (data) {
                    p2WInvoiceHeadsMarkAs.notPrinted = angular.copy(data[0]);

                    if (p2WInvoiceHeadsMarkAs.notPrinted.completed_ok == '1') {
                        loadPagedInvoices();
                    }
                });
            };

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval) !== true) return;

                        if (vm.model.setting.filterval > '') rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = async function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadPagedInvoices();
                        vm.gridInvoices.gridfunc.rebind();
                        loadGridInvoices();

                        vm.model.setting.tt_accouning_system = (await invoiceHeadsService.onBusinessCoChanged({ businessco_no: vm.model.setting.businessco_no }))[0].tt_accouning_system;
                    
                        vm.gridInvoices.gridfunc.refreshToolbarBtnDisability();
                        break;
                    case 'filterval':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filterval) !== true) return;

                        vm.model.setting.filterval = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    case 'tt_accouning_system':
                        if (utilityService.validateWatchValue(value, vm.model.setting.tt_accouning_system) !== true) return;

                        vm.model.setting.tt_accouning_system = value;

                        if (utilityService.validateParmsValue(vm.model.setting.tt_accouning_system, true) !== true) return;

                        //console.log('tt_accouning_system = ' + vm.model.setting.tt_accouning_system);
                        vm.gridInvoices.gridfunc.refreshToolbarBtnDisability();

                        //vm.gridInvoices = {
                        //    ...vm.gridInvoices
                        //};

                        //disabledBtnAccountSelected();
                        //vm.gridInvoices.gridfunc.refresh();

                        //vm.model.setting.tt_accouning_system = '1';

                        //loadGridInvoices();
                        //vm.gridInvoices.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadPagedInvoices();
                    vm.gridInvoices.gridfunc.rebind();
                    loadGridInvoices();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            loadP2WInvoiceHeadsSetting();

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
