(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('prodGrps', {
        templateUrl: 'views/components/views/prodGrps/prodGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'prodGrpsService', function (stateService, prodGrpsService) {
            
    		// #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListProductGroups: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE FUNCTION CALLS
            
            async function loadSetting() {
                vm.model.setting = (await prodGrpsService.getProductGroupSetting())[0];
            }

            async function loadProductGroups() {
                vm.model.itemsListProductGroups = (await prodGrpsService.listProductGroups());
            };

    		// #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prodgrp':
                        go({ prodgrp_id: '+' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
