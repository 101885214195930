(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('payCheck', {
        templateUrl: 'views/components/views/payCheck/payCheck.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'payCheckService', 'ttGridFactory', 'rememberService', 'layoutService', function ($stateParams, $q, stateService, utilityService, payCheckService, ttGridFactory, rememberService, layoutService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let payCheckKeyno = $stateParams.paycheck_keyno;
            let desktopSize = layoutService.getMediaQueries().desktop;

            let variableNames = {
                gb_paycheck_main: ''
            };

            vm.model = {
                gridReady: false,
                desktopSize: desktopSize,
                bgPayCheckMain: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'gb_paycheck_main_basic', color: 'primary', onClick: () => setPayCheckMainView('BASIC') },
                        { id: 'TOTRANSFER', label: 'gb_paycheck_main_totransfer', color: 'primary', onClick: () => setPayCheckMainView('TOTRANSFER') },
                        { id: 'FUNCTIONS', label: 'gb_paycheck_main_functions', color: 'primary', onClick: () => setPayCheckMainView('FUNCTIONS') }
                    ]
                },
                labourNameButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('laboursalarytypes', {
                            argtype: 'labour_no',
                            argvalue: vm.model.menu.labour_no
                        })
                    }
                ],
                menu: {},
    			itemsListPayCheckLs: []
    		};

            vm.grid = new ttGridFactory({
                rememberId: 'paycheckl_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPayCheckMainView = function (id) {
                vm.model.bgPayCheckMain.selected = vm.model.menu.gb_paycheck_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region LOAD PROCEDURE CALLS

            let loadMenu = function () {
                const deferred = $q.defer();

                payCheckService.getMenu({ paycheck_keyno: payCheckKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                    setPayCheckMainView(vm.model.menu.gb_paycheck_main);

                    loadPayCheckLs();
                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
    		let loadPayCheckLs = function () {
                if (utilityService.validateParmsValue(vm.model.menu.paycheck_keyno) !== true) return;

                return payCheckService.listPayCheckLs({ paycheck_keyno: vm.model.menu.paycheck_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListPayCheckLs);
    			});
    		};

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3506,
                    parameters: { paycheck_keyno: vm.model.menu.paycheck_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_paycheck_main':
                        if (utilityService.validateParmsValue(vm.model.menu[id]) !== true) return;

                        variableValue = vm.model.menu[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPayCheckMain = function (value) {
                vm.model.bgPayCheckMain.selected = value;
                vm.model.menu.gb_paycheck_main = vm.model.bgPayCheckMain.selected;
                
                rememberFunc('gb_paycheck_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
