(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('ni', {
        templateUrl: 'views/components/views/ni/ni.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'niService', function (stateService, niService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let inputNumber = '';
            let pressedComma = false;

            vm.model = {
                edit: {
                    number: '0'
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region CALCULATOR NUMBER FUNCTION
            
            vm.typeNumber = function (num) {
                if (num === '.') {
                    if (pressedComma === false) {
                        inputNumber = inputNumber + num;
                        pressedComma = true;
                    }
                } else {
                    inputNumber = inputNumber + num;
                }

                vm.model.edit.number = inputNumber;
            };

            // #endregion CALCULATOR NUMBER FUNCTION

            // #region CALCULATOR DELETE FUNCTION
            
            vm.deleteNumber = function () {
                vm.model.edit.number = '0';
                inputNumber = '';
                pressedComma = false;
            };

            // #endregion CALCULATOR DELETE FUNCTION

            // #region SAVE INTO SERVICE FUNCTION
            
            vm.saveToPre = function () {
                niService.inputNumber.number = vm.model.edit.number;
                
                stateService.back();
            };

            // #endregion SAVE INTO SERVICE FUNCTION

        }]
    });
})();
