(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('pohCompAdd', {
        templateUrl: 'views/components/views/pohCompAdd/pohCompAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'pohCompAddService', function ($stateParams, stateService, pohCompAddService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let pohKeyno = $stateParams.poh_keyno;
            let stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await pohCompAddService.getEdit({
                    poh_keyno: pohKeyno,
                    stock_keyno: stockKeyno
                }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                pohCompAddService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
