(function () {
    'use strict';

    angular.module("imApp").factory("p2OffDutyDataService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 574,
                    parameters: parms || {}
                });
            },
            listOffDutyCodes: function (parms) {
                return $ihttp.post({
                    method: 314,
                    parameters: parms || {}
                });
            },
            getAutoTime: function (edit) {
                return $ihttp.post({
                    method: 578,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 575,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 576,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
