(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2ImportProc', {
        templateUrl: 'views/components/views/p2ImportProc/p2ImportProc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2ImportProcService', function ($stateParams, stateService, p2ImportProcService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let p2ImportProcKeyno = $stateParams.p2_importproc_keyno;
            
            vm.model = {
                edit: {},
                selectListEncodingTypes: [],
                lockedSave: false
            };

            // #region VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                p2ImportProcService.getEdit({ p2_importproc_keyno: p2ImportProcKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            let loadEncodingTypes = function () {
                p2ImportProcService.listEncodingTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListEncodingTypes);
                });
            };

            // #endregion LOAD PROCEDURE CALL

    		// #region SAVE BUTTON FUNCTION
    		
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2ImportProcService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadEncodingTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
