(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('accessInfo', {
        templateUrl: 'views/components/views/accessInfo/accessInfo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'accessInfoService', function ($stateParams, $q, utilityService, accessInfoService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListSubscriptions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                accessInfoService.getAccessInfo({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadSubscriptions();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadSubscriptions = function () {
                if (utilityService.validateParmsValue(vm.model.setting.page_heading) !== true) return;

                vm.model.itemsListSubscriptions = [];

                return accessInfoService.listSubscriptions(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListSubscriptions);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
