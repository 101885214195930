<ng-container *ngIf="!isMobile">
    <input
        #dateRef
        matInput
        [tabIndex]="ttTabable ? '0' : '-1'"
        [matDatepicker]="picker"
        [attr.id]="ttId"
        [id]="ttId"
        class="tt-input__form-control tt-input__form-control--time mat-form-field-underline"
        inputmode="text"
        type="text"
        [placeholder]="translations['placeholder']"
        [(ngModel)]="this.model"
        (dateChange)="onDatePickerChanged($event)"
        ttOutsideClick
        [ignore]="picker.id"
        (onOutsideClick)="onDateInputBlur()"
        (keydown)="onDateInputKeydown($event)"
        (click)="openDatepicker()"
        [readonly]="ttReadonly"
        [disabled]="ttDisabled"
    />

    <mat-datepicker #picker [restoreFocus]="restoreFocus" yPosition="below" [disabled]="ttReadonly || ttDisabled">
        <p>{{ translations["label"] }}</p>
    </mat-datepicker>
</ng-container>
<div *ngIf="isMobile">
    <input
        #dateRef
        matInput
        [tabIndex]="ttTabable ? '0' : '-1'"
        [attr.id]="ttId"
        [id]="ttId"
        class="tt-input__form-control tt-input__form-control--time mat-form-field-underline tt-date-input__input--mobile-date"
        type="date"
        [placeholder]="translations['placeholder']"
        [(ngModel)]="this.model"
        (ngModelChange)="onMobileDateChanged($event)"
        [readonly]="ttReadonly"
        [disabled]="ttDisabled"
    />

    <input
        [tabIndex]="'-1'"
        [attr.id]="ttId"
        [id]="ttId"
        class="tt-input__form-control tt-input__form-control--time mat-form-field-underline tt-date-input__input--mobile-display"
        inputmode="text"
        type="text"
        [value]="this.modelView"
        [placeholder]="translations['placeholder']"
        [readonly]="ttReadonly"
        [disabled]="ttDisabled"
    />
</div>
