<div style="padding: 0.2rem">
    <article class="well well-sm">
        <header class="tt-webpagemenu-group__header">
            <h3 *ngIf="ttMenuGroup.group" class="tt-webpagemenu-group__heading">{{ ttMenuGroup!.group.menugrp_name }}</h3>
            <button *ngIf="widgetItem?.expandable !== false" class="group-header__expand" (click)="toggleExpansion()">
                <!-- <span [ngClass]="!!expanded ? 'far fa-chevron-up' : 'far fa-chevron-down'"></span> -->
                <span class="far fa-chevron-up" [style.transform]="!widgetItem?.expanded ? 'rotate(180deg)' : undefined"></span>
            </button>
        </header>
        <!-- <div class="tt-webpagemenu-group__links"> -->
        <div class="tt-webpagemenu-group__links" [style.transition]="transitionAnimation" [style.maxHeight]="widgetItem?.expandable ? (widgetItem?.expanded ? maxHeight : '0') : maxHeight">
            <!-- <div class="tt-webpagemenu-group__links" [style.transition]="transitionAnimation" [style.maxHeight]="widgetItem?.expanded || !widgetItem?.expandable === false ? maxHeight : '0'"> -->
            <tt-button-goto
                *ngFor="let link of ttMenuGroup.items"
                [ttGoto]="link.item_state"
                [ttGotoParms]="link.item_parms"
                [ttIcon]="getIcon(link.item_glyphicon || 'far-arrow-right')"
                [ttText]="link.item_name"
                [ttTranslateText]="false"
                style="display: inline-block"
            ></tt-button-goto>
        </div>
    </article>
</div>
