<!-- <label class="name-label sp-0" ng-class="vm.class.label" ng-style="vm.style.label">
    {{vm.translations.listTitle}}
    <label ng-if="item.required === true" class="name-label-required" ng-style="vm.style.label">*</label>
</label>
<div class="im-modal-dynamic-table" ng-style="vm.style.checklist">
    <table class="col-xs-12 sp-0">
        <tr ng-if="item.data.list.length > 1" class="">
            <td class="col-xs-1 im-td-cb" style="border-right:0px" ng-click="vm.goCheckToggleAll($event, item.data)">
                <input type="checkbox" class="im-grid-cols-modal-checkbox" style="margin-left: 3px !important;" ng-checked="vm.isListChecked( item.data ) === true" />
            </td>
            <td class="im-td im-favorites-container" style="border-left:0px">
                <input class="im-layouts-text" ng-model="item.data.filter" placeholder="{{item.filterPlaceholder}}" style="border: 0px;" />
                <span class="im-favorites-new">
                    <span class="im-modal-filter-icon far fa-filter"></span>
                </span>
            </td>
        </tr>
        <tr ng-repeat="row in item.data.list | filter:item.data.filter" ng-click="vm.goCheckToggle($event, row, $index, item.data)">
            <td class="col-xs-1 im-td-cb" style="border-right:0px">
                <input id="{{row.toString()}}" type="checkbox" class="im-grid-cols-modal-checkbox" style="margin-left: 3px !important;" ng-checked="row.is_selected === true" />
            </td>
            <td class="im-td im-favorites-container" style="border-left:0px">
                <div class="im-favorites-text">
                    {{row.item_name}}
                </div>
            </td>
        </tr>
    </table>
</div> -->
<tt-form-field-base
    [ttFieldId]="id.filter"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="multi-select"
>
    <div class="col-xs-12 sp-0">
        <div class="tt-multi-select__field tt-input__group col-xs-12 sp-0" [ngStyle]="style['group']">
            <input
                class="tt-multi-select__checkbox tt-input__form-control tt-input__form-control--checkbox"
                aria-label="select all"
                type="checkbox"
                [(ngModel)]="ttAllSelected"
                (ngModelChange)="toggleAllCheckItems($event)"
                [ngStyle]="style['checkbox']"
            />
            <div class="tt-multi-select__input-group">
                <input
                    #inputRef
                    [ngClass]="ttInvalid ? 'tt-multi-select__input--invalid' : ''"
                    class="tt-multi-select__input tt-input__form-control"
                    type="text"
                    [attr.id]="id.filter"
                    (focus)="select()"
                    [(ngModel)]="ttFilterValue"
                    (ngModelChange)="onFilterChange($event)"
                    [ngStyle]="style['input']"
                />
                <span
                    *ngIf="ttInvalid"
                    class="tt-input__icon tt-input__icon--invalid fa-fw fas fa-exclamation-circle"
                    style="position: absolute; right: 22px; top: 50%; transform: translateY(-50%)"
                    [ngStyle]="style['icon']"
                ></span>
                <span class="tt-multi-select__input-icon fa-fw far fa-filter"></span>
            </div>
        </div>
        <ul class="tt-multi-select__list col-xs-12 sp-0">
            <li *ngFor="let item of ttData | multiSelectFilter : this.ttFilterValue : this.ttDataSelected" class="tt-multi-select__item">
                <label class="tt-multi-select__field">
                    <input
                        class="tt-multi-select__checkbox tt-input__form-control tt-input__form-control--checkbox"
                        [checked]="item[ttDataSelected]"
                        (change)="toggleCheckItem($event, item)"
                        type="checkbox"
                        [ngStyle]="style['checkbox']"
                    />
                    <span class="tt-multi-select__label" [attr.title]="item[ttDataName]" [ngStyle]="style['checkboxLabel']">{{ item[ttDataName] }}</span>
                </label>
            </li>
        </ul>
    </div>
</tt-form-field-base>
<!-- <div class="tt-multi-select__field tt-input__group col-xs-12 sp-0">
    <input
        aria-label="select all"
        class="tt-multi-select__checkbox tt-input__form-control tt-input__form-control--checkbox"
        [checked]="isListChecked()"
        (change)="toggleAllCheckItems($event)"
        type="checkbox"
    />
    <div class="tt-multi-select__input-group">
        <input class="tt-multi-select__input tt-input__form-control" [(ngModel)]="ttFilterValue" type="text" />
        <span class="tt-multi-select__input-icon fa-fw far fa-filter"></span>
    </div>
</div>
<ul class="tt-multi-select__list col-xs-12 sp-0">
    <li *ngFor="let item of ttData" class="tt-multi-select__item">
        <label class="tt-multi-select__field">
            <input
                class="tt-multi-select__checkbox tt-input__form-control tt-input__form-control--checkbox"
                [checked]="item[ttDataSelected] === '1' ? true : false"
                (change)="toggleCheckItem($event, item)"
                type="checkbox"
            />
            <span class="tt-multi-select__label">{{ item[ttDataName] }}</span>
        </label>
    </li>
</ul> -->
