(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectOeeStateRemark', {
        templateUrl: 'views/components/views/selectOeeStateRemark/selectOeeStateRemark.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectOeeStateRemarkService', 'searchService', function ($stateParams, selectOeeStateRemarkService, searchService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let oeeStateKeyno = $stateParams.oeestate_keyno;

            vm.model = {
                itemsListRemarks: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region PROCEDURE FUNCTION CALL

            let loadRemarks = function () {
                selectOeeStateRemarkService.listRemarks({ oeestate_keyno: oeeStateKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListRemarks);
                });
            };

            // #endregion PROCEDURE FUNCTION CALL

            // #region SAVE FUNCTION

            vm.saveToPreOeeData = function (item) {
                selectOeeStateRemarkService.rememberingToPreOeeData = true;

                searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno = item.item_id;
                searchService.p2OeeStateRemarksList.oeeStateRemarkName = item.item_name;
                searchService.p2OeeStateRemarksList.showRemarkText = item.show_remarktext;
            };

            // #endregion SAVE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadRemarks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
