(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceMethodLangs', {
        templateUrl: 'views/components/views/invoiceMethodLangs/invoiceMethodLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'invoiceMethodLangsService', function ($stateParams, stateService, translateService, modalService, invoiceMethodLangsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let invoiceMethodNo = $stateParams.invoicemethod_no;

            let translations = {
                confirmation: '',
                error: '',
                ok: ''
            };

            vm.model = {
                inputListLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION

            async function loadLanguages() {
                vm.model.inputListLanguages = (await invoiceMethodLangsService.listLanguages({ invoicemethod_no: invoiceMethodNo }));
            }

            // #endregion LOAD PROCEDURE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                invoiceMethodLangsService.saveLanguages({ records: vm.model.inputListLanguages }).then(function (response) {
                    if (response[0].errorcode === '1') {
                    	modalService.show({
                    		type: 'success',
                            title: translations.confirmation,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                                cssClass: 'btn-success',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                    			}
                    		}]
                    	});
                    } else {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
