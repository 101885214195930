(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('seasonalities', {
        templateUrl: 'views/components/views/seasonalities/seasonalities.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'seasonalitiesService', function (stateService, seasonalitiesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                itemsListSeasonalities: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            async function loadSeasonalities() {
                vm.model.itemsListSeasonalities = (await seasonalitiesService.listSeasonalities());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'seasonality':
                        go({ seasonality_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSeasonalities();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
