import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-translate-edit-dialog',
    templateUrl: './translate-edit-dialog.component.html',
    styleUrls: ['./translate-edit-dialog.component.css'],
})
export class TranslateEditDialogComponent<TType extends Record<string, string>> {
    public heading = '';
    public data: TranslateEditDialogData<TType>;
    constructor(private translate: TranslateService, public dialog: MatDialogRef<TranslateEditDialogComponent<TType>>, @Inject(MAT_DIALOG_DATA) data?: TranslateEditDialogData<TType>) {
        this.data = {
            ...data,
            textToTranslate: data?.textToTranslate ?? '',
            translateFromLanguage: data?.translateFromLanguage ?? '',
            edits: data?.edits ?? [],
            googleLanguageIdKey: data?.googleLanguageIdKey ?? 'google_language_id',
            languageIdKey: data?.languageIdKey ?? 'language_id',
            languageNameKey: data?.languageNameKey ?? 'language_name',
            wordIdKey: data?.wordIdKey ?? 'word_id',
            wordNameKey: data?.wordNameKey ?? 'wordlang_name',
        };

        this.setHeading();
    }

    private async setHeading() {
        if (!this.data?.translateHeading) {
            this.heading = this.data?.heading ?? '';
        } else if (!!this.data.heading) {
            this.heading = await this.translate.translate(this.data.heading);
        }
    }

    public onSave = (edits: TType[]) => {
        this.data.edits = edits;
        this.data.save?.(this.data);
    };
}

export interface TranslateEditDialogData<TType extends Record<string, string>> {
    heading?: string;

    translateHeading?: boolean;

    textToTranslate: string;

    /**
     * Google language id, or if undefined will detect the lanuage from the inputted text.
     */
    translateFromLanguage?: string;

    edits: TType[];

    save?: (data: TranslateEditDialogData<TType>) => unknown;

    /**
     * The key of the property in the edits identifying the google language id, necesearry for using google translation api,
     *
     * @default 'google_language_id'
     */
    googleLanguageIdKey?: keyof TType;

    /**
     * The key of the property in the edit items identifying the language id for the edit items.
     *
     * @default 'language_id'
     */
    languageIdKey?: keyof TType;

    /**
     * The key of the property in the edit items identifying the language name for the edit items.
     *
     * @default 'language_name'
     */
    languageNameKey?: keyof TType;

    /**
     * The key of the property in the edit items identifying the id of word for the edit items.
     *
     * @default 'word_id'
     */
    wordIdKey?: keyof TType;

    /**
     * The key of the property in the edit items identifying the translated name of edit if any.
     *
     * @default 'wordlang_name'
     */
    wordNameKey?: keyof TType;
}
