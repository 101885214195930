import { Component } from '@angular/core';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-develop-menu',
    templateUrl: './develop-menu.component.html',
    styleUrls: ['./develop-menu.component.css'],
})
export class DevelopMenuComponent {
    /**
     * The name of the develop webpagemenu to load into generic webpagemnu component.
     */
    public webpageName = '';

    constructor(private state: StateService) {
        this.webpageName = this.state.getCurrentName();
    }
}
