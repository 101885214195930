<ng-container *ngIf="!!get">
    <tt-input ttLabel="p2_updatescript_keyno" [(ttModel)]="get.p2_updatescript_keyno" ttReadonly></tt-input>
    <tt-textarea ttLabel="updatescript" [(ttModel)]="get.updatescript"></tt-textarea>
    <tt-input ttLabel="runorder" [(ttModel)]="get.runorder" tt-placeholder=""></tt-input>
    <tt-select ttLabel="runbatch" [(ttModel)]="get.runbatch" [ttData]="runbatchList" ttDataId="runbatch" ttDataName="runbatch_name"></tt-select>
    <tt-textarea ttLabel="remarks" [(ttModel)]="get.remarks"></tt-textarea>
    <div class="form-field-group">
        <tt-datetime ttLabel="reg_datetime" [ttModel]="get.reg_datetime" ttReadonly></tt-datetime>
        <tt-datetime ttLabel="mod_datetime" [ttModel]="get.mod_datetime" ttReadonly></tt-datetime>
    </div>
    <tt-button-save *ngIf="!hideSaveButton" ttText="save" (ttClick)="save()"></tt-button-save>
</ng-container>
