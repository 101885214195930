<div class="tt-input__container col-xs-12 sp-0">
    <div class="tt-input__base col-xs-12 sp-0" [ngClass]="class['base']">
        <label [attr.for]="ttFieldId" class="tt-input__label" [ngStyle]="style['label']">
            {{ translations["label"] }}
            <span *ngIf="!!translations['toggletipText']" tabindex="0" class="far fa-question-circle tt-input__toggletip" [ttPopover]="tip" ttPopoverBackgroundColor="#323232"></span>
            <span *ngIf="ttRequired === true" class="tt-input__label--required">*</span>
        </label>

        <div *ngIf="ttInputType === 'progress' && ttLabelView === 'top'" #groupRef class="tt-input__group tt-input__group--checkbox" [ngStyle]="style['group']">
            <ng-container *ngTemplateOutlet="projected" />
            <!-- <ng-content></ng-content> -->
        </div>

        <span [hidden]="!ttSublabel" *ngIf="ttSublabel && (!ttInvalid || !ttInvalidText)" class="tt-input__sublabel" [ngClass]="class['sublabel']" [ngStyle]="style['sublabel']">
            {{ translations["sublabel"] }}
        </span>
        <span *ngIf="ttInvalidText && ttInvalid" class="tt-input__invalidtext" [ngClass]="class['invalidtext']" [ngStyle]="style['invalidtext']">
            {{ translations["invalidtext"] }}
        </span>
        <div
            *ngIf="ttInputType !== 'progress' || (ttInputType === 'progress' && ttLabelView !== 'top')"
            #groupRef
            class="tt-input__group"
            [ngStyle]="style['group']"
            [ngClass]="{
                'tt-input__group--readonly': ttReadonly === true,
                'tt-input__group--disabled': ttDisabled == true,
                'tt-input__group--checkbox': (ttInputType === 'checkbox' || ttInputType === 'progress'),
                'tt-input__group--multi-select': ttInputType === 'multi-select',
                'tt-input__group--invalid': ttInvalid === true,
                'tt-input__group--file-uploader': ttInputType === 'file-uploader',
                'tt-input__group--date-range': ttInputType === 'date-range',
            }"
        >
            <div [ngStyle]="ttInputType !== 'datetime' && ttInputType !== 'date-range' ? { width: '100%', position: 'relative' } : { display: 'contents' }">
                <ng-container *ngTemplateOutlet="projected" />
                <!-- <ng-content></ng-content> -->
                <span
                    *ngIf="!['checkbox', 'select', 'multiselect', 'datetime', 'textarea', 'progress'].includes(ttInputType) && ttInvalid"
                    class="fa-fw fas fa-exclamation-circle tt-input__icon tt-input__icon--invalid"
                ></span>
            </div>
            <ng-content select="[buttons]"></ng-content>
        </div>
    </div>
</div>

<ng-template #projected>
    <ng-content />
</ng-template>

<ng-template #tip>
    <span class="tt-input__toggletip-text">{{ translations["toggletipText"] }}</span>
</ng-template>
