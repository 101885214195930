<tt-card *ngIf="deviationSource" class="col-md-4 sp-4" [ttHeading]="deviationSourceKeyno === '0' ? 'create_deviationsource' : 'edit_deviationsource'">
    <tt-input ttLabel="deviationcause_keyno" [ttModel]="deviationSource.deviationsource_keyno" ttReadonly></tt-input>
    <tt-input *ngIf="deviationSourceKeyno > '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationSource.deviationsource_name" [ttButtons]="nameButtons"></tt-input>
    <tt-input *ngIf="deviationSourceKeyno === '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationSource.deviationsource_name"></tt-input>
    <tt-button-save ttText="deviationtype_save" (ttClick)="save()" [ttLoading]="saving" [ttDisabled]="deleting"></tt-button-save>
    <ng-container *ngIf="deviationSourceKeyno > '0'">
        <tt-button ttText="deviationtypemenu_delete" ttType="danger" (ttClick)="delete()" [ttLoading]="deleting"></tt-button>
        <tt-tabs [(ttSelectedId)]="selectedtab" ttRememberId="deviationsource_relation_tab">
            <tt-tab ttId="deviationtype_relation" ttLabel="deviationsources_deviationtype"></tt-tab>
            <tt-tab ttId="deviationsource2_relation" ttLabel="deviationsources_deviationsource2"></tt-tab>
        </tt-tabs>
        <ng-container [ngSwitch]="selectedtab">
            <tt-multi-select
                *ngSwitchCase="'deviationtype_relation'"
                [ttData]="deviationTypes"
                ttDataName="deviationtype_name"
                ttDataId="deviationtype_keyno"
                (ttSelectItem)="onDeviationTypeRelationChanged($event)"
                (ttCheckAll)="onAllDeviationTypesChecked($event)"
                ttAllSelected="false"
            ></tt-multi-select>
            <tt-multi-select
                *ngSwitchCase="'deviationsource2_relation'"
                [ttData]="deviationSources2"
                (ttSelectItem)="onDeviationSource2RelationChanged($event)"
                (ttCheckAll)="onAllDeviationSource2Checked($event)"
                ttAllSelected="false"
            ></tt-multi-select>
        </ng-container>
    </ng-container>
</tt-card>
