(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('powTimes', {
        templateUrl: 'views/components/views/powTimes/powTimes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'powTimesService', 'logisticService', 'ttGridFactory', 'rememberService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, powTimesService, logisticService, ttGridFactory, rememberService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let variableNames = {
                businessco_no: '',
                labour_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                cust_no: '',
                gb_powtimes_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                labourButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('labour_no') }
                ],
                customerNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.setting.cust_no }) }
                ],
                bgPowTimesMain: {
                    selected: 'DETAILS',
                    buttons: [
                        { id: 'DETAILS', label: 'gb_powtimes_main_details', color: 'primary', onClick: () => setPowTimesMainView('DETAILS') },
                        { id: 'PRDAY', label: 'gb_powtimes_main_prday', color: 'primary', onClick: () => setPowTimesMainView('PRDAY') },
                        { id: 'INPERIOD', label: 'gb_powtimes_main_inperiod', color: 'primary', onClick: () => setPowTimesMainView('INPERIOD') }
                    ]
                },
                setting: {},
                selectListBusinessCompanies: [],
                selectListLabours: [],
                gridReady: false,
                gridPrDayReady: false,
                gridInPeriodReady: false,
                creatingFromOffDutyCode: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '500px',
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridPrDay = new ttGridFactory({
                rememberId: 'w_powtimes_prday_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridInPeriod = new ttGridFactory({
                rememberId: 'w_powtimes_inperiod_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPowTimesMainView = function (id) {
                vm.model.bgPowTimesMain.selected = vm.model.setting.gb_powtimes_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();
                
                powTimesService.getPowTimes({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    setPowTimesMainView(vm.model.setting.gb_powtimes_main);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    $q.all([
                        loadLabours(),
                        loadGrid(),
                        loadGridPrDay(),
                        loadGridInPeriod()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListBusinessCompanies);
                });
            };

            let loadLabours = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                powTimesService.listLabours({
                    businessco_no: vm.model.setting.businessco_no,
                    add_all: '1'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListLabours);
                });
            };

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.setting.businessco_no) === true && utilityService.validateParmsValue(vm.model.setting.date_fomtom) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadGridPrDay = function () {
                vm.gridPrDay.dataTask.loadData = {
                    method: 3473,
                    parameters: vm.model.setting
                };

                vm.model.gridPrDayReady = true;

                if (vm.gridPrDay.gridfunc !== null) vm.gridPrDay.gridfunc.read();
            };

            let loadGridInPeriod = function () {
                vm.gridInPeriod.dataTask.loadData = {
                    method: 3492,
                    parameters: vm.model.setting
                };

                vm.model.gridInPeriodReady = true;

                if (vm.gridInPeriod.gridfunc !== null) vm.gridInPeriod.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'labour_no':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'cust_no':
                    case 'gb_powtimes_main':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'labour_no':
                        vm.model.setting.labour_no = '0';

                        if (vm.model.setting.labour_no === '0') {
                            rememberFunc('labour_no');
                            loadGrid();
                            loadGridPrDay();
                            loadGridInPeriod();
                        }
                        break;
                    case 'cust_no':
                        vm.model.setting.cust_no = '0';
                        vm.model.setting.cust_name = '';

                        if (vm.model.setting.cust_no === '0') {
                            rememberFunc('cust_no');
                            loadGrid();
                            loadGridPrDay();
                            loadGridInPeriod();
                        }
                        break;
                    case 'project_keyno':
                        vm.model.setting.project_keyno = '0';
                        vm.model.setting.cust_name = '';

                        if (vm.model.setting.project_keyno === '0') {
                            loadGrid();
                            loadGridPrDay();
                            loadGridInPeriod();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                });
            };

            vm.typeaheadSearchProjectName = function (value) {
                return typeaheadService.lookUpProjectNames({
                    bg_projects_activestatus: 'ACTIVE',
                    searchtext: value || '',
                    argtype: '0',
                    argvalue: '0'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.setting.cust_no = item?.item_id ?? '0';
                    vm.model.setting.cust_name = item?.item_name ?? '';

                    if (vm.model.setting.cust_no > 0) {
                        rememberFunc('cust_no');
                        loadGrid();
                        loadGridPrDay();
                        loadGridInPeriod();
                    }
                } else {
                    reset('cust_no');
                }
            };

            vm.onSelectedProjectName = function (item) {
                if (item) {
                    vm.model.setting.project_keyno = item?.item_id ?? '0';
                    vm.model.setting.project_name = item?.item_name ?? '';

                    if (vm.model.setting.project_keyno > 0) {
                        loadGrid();
                        loadGridPrDay();
                        loadGridInPeriod();
                    }
                } else {
                    reset('project_keyno');
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedCustName = function (value, item) {
                vm.model.setting.cust_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.setting.cust_no = item.item_id;
                    }
                } else {
                    reset('cust_no');
                }
            };

            vm.onChangedProjectName = function (value, item) {
                vm.model.setting.project_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.setting.project_keyno = item.item_id;
                    }
                } else {
                    reset('project_keyno');
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                reset('cust_no');
            };

            vm.onClearProjectName = function () {
                reset('project_keyno');
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region CREATE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.creatingFromOffDutyCode = true;
                
                powTimesService.createFromOffDutyCode({
                    workdate: vm.model.setting.date_fom,
                    businessco_no: vm.model.setting.businessco_no
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingFromOffDutyCode = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingFromOffDutyCode = false;

                        loadGrid();
                        loadGridPrDay();
                        loadGridInPeriod();
                    }
                });
            };

		    // #endregion CREATE PROCEDURE FUNCTION CALL
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'timeregister':
                        go({
                            custact_no: '0',
                            powtime_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadLabours();
                        loadGrid();
                        loadGridPrDay();
                        loadGridInPeriod();

                        vm.model.setting.labour_no = '0';
                        break;
                    case 'labour_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.labour_no) !== true) return;

                        vm.model.setting.labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.labour_no) !== true) return;

                        rememberFunc('labour_no');
                        loadGrid();
                        loadGridPrDay();
                        loadGridInPeriod();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadGrid();
                    loadGridPrDay();
                    loadGridInPeriod();
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPowTimesMain = function (value) {
                vm.model.bgPowTimesMain.selected = value;
                vm.model.setting.gb_powtimes_main = vm.model.bgPowTimesMain.selected;

                rememberFunc('gb_powtimes_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
