(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poMenu', {
        templateUrl: 'views/components/views/poMenu/poMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poMenuService', function ($stateParams, stateService, poMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let poKeyno = $stateParams.po_keyno;

            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await poMenuService.getProductionOrderMenu({ po_keyno: poKeyno }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'pofinishprod':
                        go({
                            po_keyno: vm.model.menu.po_keyno,
                            p2_pofinishprod_keyno: vm.model.menu.p2_pofinishprod_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
