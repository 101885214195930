(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceMethods', {
        templateUrl: 'views/components/views/invoiceMethods/invoiceMethods.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['invoiceMethodsService', function (invoiceMethodsService) {
            const vm = this;
            
            vm.model = {
                itemsListInvoiceMethods: []
            };

            async function loadInvoiceMethods() {
                vm.model.itemsListInvoiceMethods = (await invoiceMethodsService.listInvoiceMethods());
            }

            vm.$onInit = function () {
                loadInvoiceMethods();
            };
        }]
    });
})();
