(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('ttGridFactory', [function () {

        // JLR 2023 20230612

        /**
         * Creates an instance of ttGridFactory. Can either take the grid config object or create default values with rememberId and loadData added.
         * rememberId and loadData are only applied if dataTask is not provided.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_orderspecial.grid.', loadData: loadOrders });
         * 
         * @example
         * vm.grid = new ttGridFactory({
                dataTask: {
                    rememberId: 'w_gridexportarg.stockplace_id',
                    loadData: {
                        method: 1863,
                        parameters: { p2_exportproc_keyno: '5' }
                    },
                },
                config: {
                    toolbar: {
                        hidden: true
                    },
                    fixedHeader: true,
                },
            });
         * 
         * @param {{dataTask, translations, config, kendo, optionfunc, rememberId, loadData}} config configuration for the ttGridFactory
         * @param {GridDataTasks | undefined} config.dataTask configuration for the data tasks used in the grid.
         * @param {string[] | undefined} config.translations comma seperated list of words to translate.
         * @param {Config | undefined} config.config touchtime specific configurations for the grid.
         * @param {KendoConfig | undefined} config.kendo kendo specific configurations for the gid.
         * @param { (data: {  func: 'CheckboxHeaderClick' | 'CheckboxBoxClick' | 'CellClickHandler' | 'OnCellClose' | 'LookupCellEditor' }) => void } config.optionfunc function used to hook on events from the grid, events to hook on too are 'CheckboxHeaderClick', 'CheckboxBoxClick', 'CellClickHandler', 'OnCellClose' and 'LookupCellEditor'.
         * @param {string} config.rememberId id used to retrieve user configs for the grid.
         * @param {{method: number, parameters: ({} | null)}} config.loadData data task for loading data into the grid.
         * @returns the grid configuration object.
         */
        function ttGrid({ dataTask, translations, config, kendo, optionfunc, rememberId, loadData }) {
            this.dataTask = dataTask ?? {
                rememberId: rememberId ?? '',
                loadSetupId: null,
                loadData: loadData ?? null,
                addRow: {
                    method: null,
                    parameters: null
                },
                removeRow: {
                    method: null,
                    parameters: null
                },
                saveData: {
                    method: null,
                    parameters: null
                }
            };
            this.translations = translations ?? [];
            this.config = config ?? {
                toolbar: {
                    hidden: false,
                    pdfExport: true,
                    excelExport: true,
                    filter: true,
                    columnVisibility: true,
                    headers: true,
                    edit: false,
                    lock: false,
                    add: false,
                    delete: false,
                    save: false,
                    wrapping: true,
                    layouts: true,
                    refresh: false,
                    read: true,
                    rebind: false,
                    print: false,
                    buttons: [],
                    toggles: []
                },
                fixedHeader: true,
                keepSortOnAdd: false,
                keepSortOnCheckbox: false,
                keepSortOnIsSelected: false,
                rowClick: false,
                shortcuts: false,
                specialFunc: {
                    newTab: false,
                    buttons: []
                },
                navigation: {
                    altNav: false,
                    newLine: false,
                },
                css: {
                    altColor: true,
                    textWrapping: false,
                },
                onDataSourceChanges: null,
                onDataBound: null,
            };
            this.kendo = kendo ?? {
                height: null,
                sortable: true,
                aggregate: [],
                pager: false,
                selectable: false,
                filterable: false
            };
            this.optionfunc = optionfunc ?? null;
            this.gridfunc = null;

            return this;
        };

        /**
         * Sets the remember id for the grid, used for retrieving grid layout.
         * 
         * @param {string} id id for retrieving layout for grid.
         * @returns the tt-option grid configuration object.
         */
        ttGrid.prototype.setRememberId = function (id) {
            this.dataTask.rememberId = id ?? '';

            return this;
        }

        /**
         * @typedef {Object} Operation represents a data task operation.
         * @property {number} method the datatask procedure to call.
         * @property {Object | null} parameters parameters to send with the datatask procedure.
         */

        /**
         * Sets the load data function for the ttOptions grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setLoadDataFunction({ method: 2833, parameters: { } });
         * 
         * @param {Operation} loadData the load data procedure to add to the tt-options grid configuration object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setLoadDataFunction = function ({ method, parameters }) {
            this.dataTask.loadData = {
                method: method,
                parameters: parameters,
            };

            return this;
        }


        /**
         * @typedef {Object} AddRowOperation represents the add row grid operation.
         * @property {number} method the datatask procedure to call.
         * @property {Object | null} parameters parameters to send with the datatask procedure.
         * @property {((variable?) => void) | undefined} pre function to call before the operation is called.
         * @property {((variable?) => void) | undefined} post function to call after the operation is called.
         * @property {boolean} autoSave Will automatically save after the operation, default is false
         * @property {boolean} confirm adds a confirmation popup before saving, default is false
         */

        /**
         * Sets the add row function for the ttOptions grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setAddRowFunction({ method: 2833, parameters: { }, pre: beforenewrow, post: afternewrow, autoSave: false, confirm: true });
         * 
         * @param {AddRowOperation} addRow the add row procedure to add on the tt-options grid configuration object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setAddRowFunction = function ({ method, parameters, pre, post, autoSave, confirm }) {
            this.dataTask.addRow = {
                method: method,
                parameters: parameters,
                pre: pre,
                post: post,
                autoSave: autoSave,
                confirm: confirm
            }

            return this;
        }

        /**
         * @typedef {Object} RemoveRowOperation represents the remove row grid operation.
         * @property {number} method the datatask procedure to call.
         * @property {Object | null} parameters parameters to send with the datatask procedure.
         * @property {boolean} autoSave Will automatically save after the operation, default is false
         * @property {boolean} confirm adds a confirmation popup before saving, default is false
         * @property {() => void} post function to call after a row has been removed.
         */

        /**
         * Sets the remove row function for the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setRemoveRowFunction({ method: 2833, parameters: { }, autoSave: false, confirm: true });
         * 
         * @param {RemoveRowOperation} removeRow the remove row procedure to add on the tt-options grid configuration object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setRemoveRowFunction = function ({ method, parameters, autoSave, confirm, post }) {
            this.dataTask.removeRow = {
                method: method,
                parameters: parameters,
                autoSave: autoSave,
                confirm: confirm,
                post: post
            }

            return this;
        }

        /**
         * @typedef {Object} SaveDataOperation represents the save data grid operation.
         * @property {number} method the datatask procedure to call.
         * @property {{datatask_keyno?: number;}?} parameters parameters to send with the datatask procedure. If parameter `datatask_keyno` is defined it is an alternative to `method` property, this also provides logging to the database.
         * @property {boolean} autoSave Will automatically save after the operation, default is false
         * @property {boolean} confirm adds a confirmation popup before saving, default is false
         */

        /**
         * Sets the save data function for the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setSaveDataFunction({ method: 2833, parameters: { }, autoSave: false, confirm: true });
         * 
         * @param {SaveDataOperation} saveData the save data procedure to add on the tt-options grid configuration object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setSaveDataFunction = function ({ method, parameters, autoSave, confirm }) {
            this.dataTask.saveData = {
                method: method,
                parameters: parameters,
                autoSave: autoSave,
                confirm: confirm
            }

            return this;
        }


        /**
         * @typedef {Object} GridDataTasks represents the available data tasks in the grid.
         * @property {string} rememberId Id used to retrieve user configs for the grid.
         * @property {string | undefined} loadSetupId specify the id for the grid, uses default if null or undefined.
         * @property {Operation} loadData data task for loading data into the grid.
         * @property {AddRowOperation | undefined} addRow data task for adding a new row into the grid, if not provided a default function will be used.
         * @property {RemoveRowOperation | undefined} removeRow data task for removing a row from the grid, if not provided a default function will be used.
         * @property {SaveDataOperation | undefined} saveData data task for saving changes in the grid, if not provided a default function will be used.
         */

        /**
         * Sets dataTask configuration on the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setDataTaskConfig({ rememberId: 'w_somewebpage.grid', loadSetupId: uuid() });
         * 
         * @param {GridDataTasks} dataTask the data task configuration object, all fields are optional.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setDataTaskConfig = function ({ rememberId, loadSetupId, loadData, addRow, removeRow, saveData }) {
            if (rememberId) this.dataTask.rememberId = rememberId;
            if (loadSetupId) this.dataTask.loadSetupId = loadSetupId;
            if (loadData) this.dataTask.loadData = loadData;
            if (addRow) this.dataTask.addRow = addRow;
            if (removeRow) this.dataTask.removeRow = removeRow;
            if (saveData) this.dataTask.saveData = saveData;

            return this;
        }

        /**
         * Sets the list of words to translate.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setTranslations(['word_1', 'word_2']);
         * 
         * @param {string[]} translations comma seperated list of words to translate.
         */
        ttGrid.prototype.setTranslations = function (translations) {
            this.translations = translations;

            return this;
        }

        /**
         * @typedef {Object} ToolbarButton represents a button displayed in the grid toolbar.
         * @property {string} name name of the button, used in grid component.
         * @property {string | undefined} text text to display next to the button if any.
         * @property {(data) => void} func callback for click events on the toolbar button.
         * @property {string | undefined} icon string containing icon class to display.
         * @property {string} cssClass string containing css classes to add to the button, seperated by spaces.
         * @property {boolean} translate whether or not the button text should be translated.
         * @property {() => (boolean | 'hidden')} disabled function for checking if the button is disabled.
         */


        /**
         * @typedef {Object} ToggleState represents a possible state for a toggle button.
         * @property {string} text the text to display when this toggle state is active.
         * @property {() => void} func function to call when the toggle button is in this toggle state.
         * @property {string} icon icon classes for the icon to display in this toggle state.
         */

        /**
         * @typedef {Object} ToggleButton represents a toggle button displayed in the grid toolbar.
         * @property {string} name the name of the toggle button.
         * @property {string} text the initial/default text to the display with the button.
         * @property {string} state the initial state of the toggle button.
         * @property {ToggleState[]} states list of states to toggle between.
         * @property {string} icon the initial/default icon class for the icon to display.
         */

        /**
         * @typedef {Object} Toolbar represents the toolbar displayed above the grid.
         * @property {boolean} hidden if true, hides the toolbar, default is false.
         * @property {boolean} pdfExport adds a predefined button for exporting grid to pdf.
         * @property {boolean} excelExport adds a predefined button for exporting grid to excel.
         * @property {boolean} filter adds a predefined button for enabling or disabling filtering.
         * @property {boolean} columnVisibility adds a predefined button for deciding which columns to show or hide.
         * @property {boolean} headers adds a predefined button for switched between db column names and titles.
         * @property {boolean} edit adds a predefined button for enabling or disabling editing in columns.
         * @property {boolean} lock NOT IMPLEMENTED
         * @property {boolean} add adds predefined buttons for running an add function     // Needs addRow or saveData
         * @property {boolean} addSimple adds predefined button for running an add function     // Needs addRow or saveData
         * @property {boolean} deleteBtn adds a predefined button for running a delete function  // Needs removeRow or saveData
         * @property {boolean} save adds a predefined button for running a save function    // Needs saveData
         * @property {boolean} wrapping adds a predefined button for enabling or disabling text-wrapping in the cells.
         * @property {boolean} layouts adds a predefined button for adding, setting or removing predefined column layouts
         * @property {boolean} refresh adds a predefined button for repainting the grid data
         * @property {boolean} read adds a predefined button for reading the grid data
         * @property {boolean} rebind adds a predefined button for rebinding the grid
         * @property {boolean} print adds a predefined button for opening a modal view to select a report to print
         * @property {ToolbarButton[]} buttons list of custom buttons to add to the toolbar.
         * @property {ToggleButton[]} toggles list of toggle button to add to the toolbar.
         */

        /**
         * Sets the configuration for the predefined buttons available on the toolbar of the grid.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebpage.grid' }).setToolbar({ filter: false, save: true });
         * 
         * @param {Toolbar} toolbar the toolbar configuration object for the predefined buttons available, all fields are optional.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setToolbar = function ({ hidden, pdfExport, excelExport, filter, columnVisibility, headers, edit, add, addSimple, deleteBtn, save, wrapping, layouts, refresh, read, rebind, print, buttons, toggles }) {
            if (hidden !== undefined && hidden !== null) this.config.toolbar.hidden = hidden;
            if (pdfExport !== undefined && pdfExport !== null) this.config.toolbar.pdfExport = pdfExport;
            if (excelExport !== undefined && excelExport !== null) this.config.toolbar.excelExport = excelExport;
            if (filter !== undefined && filter !== null) this.config.toolbar.filter = filter;
            if (columnVisibility !== undefined && columnVisibility !== null) this.config.toolbar.columnVisibility = columnVisibility;
            if (headers !== undefined && headers !== null) this.config.toolbar.headers = headers;
            if (edit !== undefined && edit !== null) this.config.toolbar.edit = edit;
            if (add !== undefined && add !== null) this.config.toolbar.add = add;
            if (addSimple !== undefined && addSimple !== null) this.config.toolbar.addSimple = addSimple;
            if (deleteBtn !== undefined && deleteBtn !== null) this.config.toolbar.delete = deleteBtn;
            if (save !== undefined && save !== null) this.config.toolbar.save = save;
            if (wrapping !== undefined && wrapping !== null) this.config.toolbar.wrapping = wrapping;
            if (layouts !== undefined && layouts !== null) this.config.toolbar.layouts = layouts;
            if (refresh !== undefined && refresh !== null) this.config.toolbar.refresh = refresh;
            if (read !== undefined && read !== null) this.config.toolbar.read = read;
            if (rebind !== undefined && rebind !== null) this.config.toolbar.rebind = rebind;
            if (print !== undefined && print !== null) this.config.toolbar.print = print;
            if (buttons) this.config.toolbar.buttons = buttons;
            if (toggles) this.config.toolbar.toggles = toggles;

            return this;
        }

        /**
         * Sets list of custom toolbar buttons to add to the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setCustomToolbarButtons([{ name: 'customTest', text: '', func: () => console.log(), icon: 'star' }]);
         * 
         * @param {ToolbarButton[]} buttons list of custom toolbar buttons to add to the toolbar of the grid.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setCustomToolbarButtons = function (buttons) {
            this.config.toolbar.buttons = buttons;

            return this;
        }

        /**
         * Sets list of toggle buttons to add to the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setToggleButtons([{ name: 'test', state: 0, states: [{ text: 'test0', func: function () { testToggle(0) }, icon: 'fal fa-ballot-check' }, { text: 'test1', func: function () { testToggle(1) }, icon: 'fal fa-ballot' }] }]);
         * 
         * @param {ToggleButton[]} toggles list over toggle buttons to add to the toolbar of the grid.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setToggleButtons = function (toggles) {
            this.config.toolbar.toggles = toggles;

            return this;
        }


        /**
         * @typedef {Object} SpecialFunctionButton represents a button to be displayed in the special function column.
         * @property {string} name the name of the special function button.
         * @property {string} text the text to display in the special function button.
         * @property {undefined | 'primary' | 'success' | 'danger'} type the type for the special function button, undefined will indicate primary, other options are 'danger' or 'success'.
         * @property {(variables?) => void} func the function to be called when the special function button is clicked.
         * @property {string} icon the icon classes for the icon to display in the special function button.
         * @property {(variables?) => boolean} disabled function used to check whether the special function button is disabled or not.
         */


        /**
         * @typedef {Object} SpecialFunctions represents special functions to add in a seperate column.
         * @property {boolean} newTab if grid data has `item_path` a predefined new tab button in the function column.
         * @property {SpecialFunctionButton[]} buttons list of special function buttons to display in the function column. 
         */

        /**
         * List over special function buttons which will be displayed in a designated function column.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebapge.grid' }).setSpecialFunc({ newTab: true });
         * 
         * @param {SpecialFunctions} specialFuncConfig configuration for the special func object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setSpecialFunc = function ({ buttons, newTab }) {
            this.config.specialFunc.buttons = buttons;
            this.config.specialFunc.newTab = newTab;

            return this;
        }


        /**
         * @typedef {Object} Navigation represents navigation configurations for the grid.
         * @property {boolean} altNav enter to move right/left instead of down/up.
         * @property {boolean} newLine automatically generate a new line when on the last cell or first when moving.
         */

        /**
         * Sets the navigation configuration object to add to the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebpage.grid' }).setNavigation({ altNav: false, newLine: false });
         * 
         * @param {Navigation} navigation the navigation configuration object to add to the grid, all fields optional.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setNavigation = function ({ altNav, newLine }) {
            if (altNav !== undefined && altNav !== null) this.config.navigation.altNav = altNav;
            if (newLine !== undefined && newLine !== null) this.config.navigation.newLine = newLine;

            return this;
        }


        /**
         * @typedef {Object} CSSOptions represents the css options available for styling the grid.
         * @property {boolean | string | undefined} altColor alternating color for the rows, can be a boolean for default color or a hex value like '#A1B2C3'
         * @property {boolean | undefined} textWrapping enables text wrapping in columns, default is false.
         */

        /**
         * Sets the css options to add to the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebpage.grid' }).setCSSOptions({ altColor: '#A1B2C3' });
         * 
         * @param {CSSOptions} cssOptions the css options to set on the grid, all fields are optional.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setCSSOptions = function ({ altColor, textWrapping }) {
            if (altColor !== undefined && altColor !== null) this.config.css.altColor = altColor;
            if (textWrapping !== undefined && textWrapping !== null) this.config.css.textWrapping = textWrapping;

            return this;
        }

        /**
         * @typedef {Object} EditColumn represents configuration for editing columns.
         * @property {string} key the col_name for the column that should be editable.
         * @property {number} lookup the datatask method number for the lookup procedure.
         * @property {boolean} optionfunc whether or not the edit will trigger the related optionfunc.
         * @property {{key: string; value: string;}[]} relations key refers to related col_name to be updated. Value refers to which property the column is updated from.
         */

        /**
         * @typedef {Object} Config represents the TouchTime specific configurations.
         * @property {(string | EditColumn)[]} editColumns editable columns in the grid, either key of the columns or an object. used through colschema only.
         * @property {Toolbar?} toolbar the toolbar configuation.
         * @property {boolean} fixedHeader whether the header should be fixed when scrolling, defaults to false.
         * @property {boolean} keepSortOnAdd whether or not to keep the previous sort when rows are added to the grid.
         * @property {boolean} keepSortOnCheckbox whether or not to keep the previous sort when any checkbox is clicked. // Under maintenance
         * @property {boolean} keepSortOnIsSelected whether or not to keep the previous sort when is_selected column is toggled. // Under maintenance
         * @property {boolean} rowClick whether or not clicking a cell triggers a specific function. // deprecated
         * @property {boolean} shortcuts whether or not to enable shortcuts used in the grid. `ctrl` + `i` will insert a new row over the current row, `ctrl` + `shift` + `i` will insert a new row under the current row, `ctrl` + `d` will delete a row.
         * @property {SpecialFunctions} specialFunc adds a function column with custom buttons.
         * @property {GridNavigation} navigation navigation configuration for the grid.
         * @property {CSSOptions} css css options for styling the grid.
         * @property {(event) => void} onDataSourceChanges function to call when the datasource changes.
         * @property {(event) => void} onDataChanged function called when the data source has changed.
         * @property {(event) => void} onDataBound function called when the data source has been bound.
         */

        /**
         * Sets the touchtime specific configuraion to add to the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({ rememberId: 'w_somewebpage.grid' }).setConfig({ fixedHeader: false, shortcuts: true });
         * 
         * @param {Config} config the touchtime specific configuration to add to the tt-options grid configuration object, all fields are optional.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setConfig = function ({ toolbar, fixedHeader, keepSortOnAdd, keepSortOnCheckbox, keepSortOnIsSelected, rowClick, shortcuts, specialFunc, navigation, css, onDataSourceChanges, onDataChanged, onDataBound, onReady }) {
            if (toolbar !== undefined && toolbar !== null) this.config.toolbar = toolbar;
            if (fixedHeader !== undefined && fixedHeader !== null) this.config.fixedHeader = fixedHeader;
            if (keepSortOnAdd !== undefined && keepSortOnAdd !== null) this.config.keepSortOnAdd = keepSortOnAdd;
            if (keepSortOnCheckbox !== undefined && keepSortOnCheckbox !== null) this.config.keepSortOnCheckbox = keepSortOnCheckbox;
            if (keepSortOnIsSelected !== undefined && keepSortOnIsSelected !== null) this.config.keepSortOnIsSelected = keepSortOnIsSelected;
            if (rowClick !== undefined && rowClick !== null) this.config.rowClick = rowClick;
            if (shortcuts !== undefined && shortcuts !== null) this.config.shortcuts = shortcuts;
            if (specialFunc !== undefined && specialFunc !== null) this.config.specialFunc = specialFunc;
            if (navigation !== undefined && navigation !== null) this.config.navigation = navigation;
            if (css !== undefined && css !== null) this.config.css = css;
            if (onDataSourceChanges !== undefined && onDataSourceChanges !== null) this.config.onDataSourceChanges = onDataSourceChanges;
            if (onDataChanged !== undefined && onDataChanged !== null) this.config.onDataChanged = onDataChanged;
            if (onDataBound !== undefined && onDataBound !== null) this.onDataBound = onDataBound;
            if (onReady !== undefined && onReady !== null) this.config.onReady = onReady;

            return this;
        }


        /**
         * @typedef {Object} KendoConfig represents kendo specific configurations for the grid.
         * @property {string | undefined} height sets the height for the grid content, no units will result in the unit px.
         * @property {boolean} sortable whether the columns should be sortable or not.
         * @property {{field: string; aggregate: 'average' | 'count' | 'max' | 'min' | 'sum';}[] | boolean | undefined} aggregate true will create a sum aggregate for all decimal columns, otherwise a list of column key and aggregate functions can be defined. Aggregate function 'average', 'count', 'max', 'min', 'sum' are supported.
         * @property {boolean} pager whether or not pagination should be included with the grid. // NOT IMPLEMENTED
         * @property {boolean | 'row' | 'cell' | 'multiple, row' | 'multiple, cell'} selectable whether or not row or cell selection is enabled, default is false, true indicates grid row selection, other options are 'row', 'cell', 'multiple, row', 'multiple, cell'. 'multiple' can be used as a shorthand for 'multiple, row'.
         * @property {boolean | {mode: 'row' | 'menu'}} filterable whether or not filter options are displayed in the grid, default is row mode, true is menu mode, false will hide filter options.
         */

        /**
         * Sets the kendo configuration for the tt-options grid configuration object.
         * 
         * @example
         * vm.grid = new ttGridFactory({rememberId: 'w_somewebpage.grid', loadData: loadDataFunction}).setKendoConfig({ height: '20rem' });
         * 
         * @param {KendoConfig} kendo the kendo grid configuration object.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setKendoConfig = function ({ height, sortable, aggregate, pager, selectable, filterable }) {
            if (height !== undefined) this.kendo.height = height;
            if (sortable !== undefined) this.kendo.sortable = sortable;
            if (aggregate !== undefined) this.kendo.aggregate = aggregate;
            if (pager !== undefined) this.kendo.pager = pager;
            if (selectable !== undefined) this.kendo.selectable = selectable;
            if (filterable !== undefined) this.kendo.filterable = filterable;

            return this;
        }

        /**
         * Sets the option func property for the tt-options grid configuration object. Option func is used to hook onto events from the grid. Events available to hook on to are 'CheckboxHeaderClick', 'CheckboxBoxClick', 'CellClickHandler', 'OnCellClose' and 'LookupCellEditor'.
         * 
         * @example
         * vm.grid = new ttGridFactory({rememberId: 'w_somewebpage.grid', loadData: loadDataFunction}).setOptionFunc((data) => optionfuncInComponent(data.data));
         * 
         * @param {(data: {func: 'CheckboxHeaderClick' | 'CheckboxBoxClick' | 'CellClickHandler' | 'OnCellClose' | 'LookupCellEditor'}) => void} optionfunc function used to hook on events from the grid, events to hook on too are 'CheckboxHeaderClick', 'CheckboxBoxClick', 'CellClickHandler', 'OnCellClose' and 'LookupCellEditor'.
         * @returns the tt-options grid configuration object.
         */
        ttGrid.prototype.setOptionFunc = function (optionFunc) {
            this.optionfunc = optionFunc;

            return this;
        }
        
        /**
         *
         * @param {(event: Event) => void} onDataBound 
         * @returns 
         */
        ttGrid.prototype.onDataBound = function (onDataBound) {
            this.onDataBound = onDataBound;

            return this;
        }

        /**
         *
         * @param {() => void} onReady 
         * @returns 
         */
        ttGrid.prototype.onReady = function (onReady) {
            this.onReady = onReady;

            return this;
        }

        return ttGrid;
    }]);
})();
