(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceMakeMulti', {
        templateUrl: 'views/components/views/invoiceMakeMulti/invoiceMakeMulti.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'invoiceMakeMultiService', 'rememberService', function ($stateParams, stateService, utilityService, translateService, modalService, invoiceMakeMultiService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoNo = $stateParams.businessco_no;
            let invoiceMethodNo = $stateParams.invoicemethod_no;
            let invoiceValutaId = $stateParams.invoice_valuta_id;
            
            let variableNames = {
                deliverydate_tom: '',
                invoice_date: ''
            };

            let progress = {
                runProgress: 'START',
                label: 'progressbar',
                field: '',
                max: 0,
                min: 0,
                step: 0,
                finishclose: '1',
                abortclose: false
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgInvoiceMakeMultiMain: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_invoicemakemulti_main_select', color: 'primary', onClick: () => vm.model.bgInvoiceMakeMultiMain.selected = 'SELECT' },
                        { id: 'VIEW', label: 'bg_invoicemakemulti_main_view', color: 'primary', onClick: () => vm.model.bgInvoiceMakeMultiMain.selected = 'VIEW' }
                    ]
                },
                setting: {},
                itemsListCreateMultiInvoices: [],
                showSelectAll: false,
                showUnselectAll: false,
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION FUNCTION

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATION FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await invoiceMakeMultiService.getSetting({
                    businessco_no: businessCoNo,
                    invoicemethod_no: invoiceMethodNo,
                    invoice_valuta_id: invoiceValutaId
                }))[0];

                loadCreateMultiInvoices();
            }

            async function loadCreateMultiInvoices() {
                if (utilityService.validateParmsValue(vm.model.setting.deliverydate_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.invoice_date) !== true) return;

                vm.model.itemsListCreateMultiInvoices = [];

                vm.model.itemsListCreateMultiInvoices = (await invoiceMakeMultiService.listCreateMultiInvoices(vm.model.setting));

                vm.model.itemsListCreateMultiInvoices.forEach((item) => {
                    if (item.item_id !== '0' || item.item_id !== '-1') {
                        vm.model.showSelectAll = false;
                        vm.model.showUnselectAll = true;
                    } else {
                        vm.model.showSelectAll = true;
                        vm.model.showUnselectAll = false;
                    }
                });
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            function rememberFunc(id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'deliverydate_tom':
                    case 'invoice_date':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            }
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            function activateProgress(items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                progress.step = 0;

                function doStep() {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (progress.runProgress === 'ABORT') return;
                        if (progress.step >= progress.max) return;

                        step = progress.step + skip + failed;
                        progress.field = 'ID: ' + items[step].item_id;
                        modalService.progressInfo.field = progress.field;

                        invoiceMakeMultiService.createInvoice({
                            invoicemethod_no: vm.model.setting.invoicemethod_no,
                            invoice_date: vm.model.setting.invoice_date,
                            businessco_no: vm.model.setting.businessco_no,
                            order_internal_no: items[step].order_internal_no,
                            item_id: items[step].item_id
                        }).then((response) => {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: (dialogItself) => {
                                            dialogItself.close();

                                            progress.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                progress.step += increment;
                                modalService.progressInfo.step = progress.step;
                                modalService.progressInfo.finishclose = progress.finishclose;

                                doStep();
                            }
                        });
                    });
                }

                doStep();
            }

            vm.createInvoices = function () {
                let selectedRows = [];

                vm.model.itemsListCreateMultiInvoices.forEach((item) => {
                    if (item.item_is_selected === '1' || item.item_is_selected === true) {
                        vm.model.lockedCreate = true;
                        selectedRows.push(item);
                    }
                });

                if (selectedRows.length === 0) return;

                progress.max = selectedRows.length;
                activateProgress(selectedRows);

                modalService.showProgress(progress).then((value) => {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') {
                        vm.model.lockedCreate = false;
                    }

                    if (progress.runProgress === 'FINISH') {
                        vm.model.lockedCreate = false;
                        loadCreateMultiInvoices();
                    }
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgInvoiceMakeMultiMain = function (value) {
                vm.model.bgInvoiceMakeMultiMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region SELECT ALL LIST BUTTON FUNCTION

            vm.selectAll = function () {
                vm.model.itemsListCreateMultiInvoices.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;

                    item.item_is_selected = '1';
                    vm.model.showSelectAll = false;
                    vm.model.showUnselectAll = true;
                });
            };

            // #endregion SELECT ALL LIST BUTTON FUNCTION

            // #region UNSELECT ALL LIST FUNCTION

            vm.unselectAll = function () {
                vm.model.itemsListCreateMultiInvoices.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;

                    item.item_is_selected = '0';
                    vm.model.showSelectAll = true;
                    vm.model.showUnselectAll = false;
                });
            };

            // #endregion UNSELECT ALL LIST FUNCTION

            // #region TOGGLE FUNCTION
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'deliverydate_tom':
                    case 'invoice_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        loadCreateMultiInvoices();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
