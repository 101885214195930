(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinishCompList', {
        templateUrl: 'views/components/views/poFinishCompList/poFinishCompList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'poFinishCompListService', 'poFinishProdService', 'typeaheadService', 'ttGridFactory', 'layoutService', function ($stateParams, stateService, utilityService, modalService, poFinishCompListService, poFinishProdService, typeaheadService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoFinishProdKeyno = $stateParams.p2_pofinishprod_keyno;
            let onDestroy = [];

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                gridReady: false,
                bgPofinishCompSelection: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'componentpick_not_picked', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = '0' },
                        { id: '1', label: 'componentpick_picked', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = '1' },
                        { id: 'A', label: 'componentpick_all', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = 'A' }
                    ]
                },
                barcodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.barcode = '' },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('barcode') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.scanBarcode() }
                ],
                setting: {
                    barcode: ''
                },
                status: {},
                itemsListFinishedComponents: [],
                lockedFinish: false
            };
            
            vm.grid = new ttGridFactory({
                rememberId: 'pofinishprodcomp_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await poFinishCompListService.getSetting({ p2_pofinishprod_keyno: p2PoFinishProdKeyno }))[0];

                if (vm.model.tabletSize.matches === true) {
                    loadGrid();
                } else {
                    loadFinishedComponents();
                }
            }

            async function loadProductionOrderStatus() {
                vm.model.status = (await poFinishProdService.getProductionOrderStatus({ p2_pofinishprod_keyno: p2PoFinishProdKeyno }))[0];

                if (vm.model.status.finishlocked === '0') {
                    vm.model.status.locked = '1';
                }
            }

            async function loadFinishedComponents() {
                if (utilityService.validateParmsValue(vm.model.setting.p2_pofinishprod_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgPofinishCompSelection.selected) !== true) return;

                vm.model.itemsListFinishedComponents = [];

                vm.model.itemsListFinishedComponents = (await poFinishCompListService.listFinishedComponents({
                    p2_pofinishprod_keyno: vm.model.setting.p2_pofinishprod_keyno,
                    selector: vm.model.bgPofinishCompSelection.selected
                }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                if (utilityService.validateParmsValue(vm.model.setting.p2_pofinishprod_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgPofinishCompSelection.selected) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 3574,
                    parameters: {
                        p2_pofinishprod_keyno: vm.model.setting.p2_pofinishprod_keyno,
                        selector: vm.model.bgPofinishCompSelection.selected
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            function openCalculator(id) {
                modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                    vm.model.setting[id] = value;

                    vm.scanBarcode();
                });
            }

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // #region CLEAR FIELD FUNCTION

            function clearField() {
                if (vm.model.setting.barcode > '') {
                    vm.model.setting.barcode = '';
                }
            }

            // #endregion CLEAR FIELD FUNCTION

            // #region CHANGE FUNCTION

            function changeStockKeyno(stockKeyno) {
                vm.model.setting.stock_keyno = stockKeyno;
                let scanValue = 'st' + vm.model.setting.stock_keyno;

                poFinishCompListService.scanComponent({
                    scantotype: 'p2_pofinishprod_keyno',
                    scantokey: vm.model.setting.p2_pofinishprod_keyno,
                    scanvalue: scanValue
                }).then(function () {
                    clearField();
                    loadProductionOrderStatus();

                    if (vm.model.tabletSize.matches === true) {
                        loadGrid();
                    } else {
                        loadFinishedComponents();
                    }
                });
            }

            // #endregion CHANGE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPofinishCompSelection = function (value) {
                vm.model.bgPofinishCompSelection.selected = value;

                if (vm.model.tabletSize.matches === true) {
                    loadGrid();
                } else {
                    loadFinishedComponents();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region SCAN FUNCTION
            
            vm.scanBarcode = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_pofinishprod_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.barcode, true) !== true) return;

                poFinishCompListService.scanComponent({
                    scantotype: 'p2_pofinishprod_keyno',
                    scantokey: vm.model.setting.p2_pofinishprod_keyno,
                    scanvalue: vm.model.setting.barcode
                }).then(function () {
                    clearField();
                    loadProductionOrderStatus();

                    if (vm.model.tabletSize.matches === true) {
                        loadGrid();
                    } else {
                        loadFinishedComponents();
                    }
                }, function () {
                    clearField();
                });
            };

            // #endregion SCAN FUNCTION

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting[id] = value;

                        return typeaheadService.lookUpProducts({
                            productsearch_searchtext: vm.model.setting[id] || '',
                            functionname: 'stock',
                            stockplace_id: vm.model.setting.stockplace_id
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'searchtext':
                        if (item) {
                            vm.model.setting.stock_keyno = item?.stock_keyno ?? '0';
                            vm.model.setting.searchtext = item?.item_name ?? '';

                            changeStockKeyno(vm.model.setting.stock_keyno);
                        } else {
                            vm.onTypeaheadClear('searchtext');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = value;

                        if (value) {
                            if (item?.stock_keyno) {
                                vm.model.setting.stock_keyno = item.stock_keyno;
                            }
                        } else {
                            vm.onTypeaheadClear('searchtext');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.stock_keyno = '0';
                        vm.model.setting.searchtext = '';

                        if (vm.model.tabletSize.matches === true) {
                            loadGrid();
                        } else {
                            loadFinishedComponents();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.finishChanges = function () {
                vm.model.lockedFinish = true;

                poFinishCompListService.finishProductionOrder({
                    finishfromkey: 'p2_pofinishprod_keyno',
                    keyvalue: p2PoFinishProdKeyno,
                    from_webpagename: stateService.getCurrentName(),
                    from_parms: p2PoFinishProdKeyno
                }).then(function (response) {
                    vm.model.lockedFinish = false;
                    stateService.back('/' + response[0].webpagename + '/' + response[0].webpage_parms);
                }, function () {
                    vm.model.lockedFinish = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON WINDOW RESIZE FUNCTION

            function onWindowResize() {
                if (vm.model.tabletSize.matches === true) {
                    loadGrid();
                } else {
                    loadFinishedComponents();
                }
            };

            // #endregion ON WINDOW RESIZE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductionOrderStatus();
            	window.addEventListener('resize', onWindowResize);
            	onWindowResize();
            };

            // #endregion ON INIT FUNCTION

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                window.removeEventListener('resize', onWindowResize);

                onDestroy.forEach((fn) => {
                    if (typeof fn === 'function') {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION

        }]
    });
})();
