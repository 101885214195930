import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CoreComponentService, LabelView, Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.css'],
})
export class ImageComponent implements OnChanges {
    /**
     * The label or caption to display for the image.
     */
    @Input()
    public ttLabel = 'picture';

    /**
     * The value displayed for label in the template.
     */
    public label = '';

    /**
     * The poistion/view of the label.
     */
    @Input()
    public ttLabelView: LabelView = 'auto';

    /**
     * Whether the given label should be translated, default is true.
     */
    @Input()
    public set ttTranslateLabel(value: BooleanInput) {
        this._translateLabel = coerceBooleanProperty(value);
    }
    public get ttTranslateLabel(): boolean {
        return this._translateLabel;
    }
    private _translateLabel = true;

    /**
     * The url of the image to display.
     */
    @Input()
    public ttSrc = '';

    /**
     * Event emitted when the imaged is clicked.
     */
    @Output()
    public ttClick = new EventEmitter<MouseEvent>();

    /**
     * Event emitted when the placeholder is clicked.
     */
    @Output()
    public ttPlaceholderClick = new EventEmitter<MouseEvent>();

    /**
     * If an image has been succesfully loaded.
     */
    imageLoaded = false;

    /**
     * When an image has been attempted to be loaded, but does not mean it successfully loaded.
     */
    ready = false;

    public class = {
        base: '',
    };

    constructor(layout: LayoutService, coreComponents: CoreComponentService, private translate: TranslateService) {
        layout.layoutChanged.subscribe((info) => {
            this.class.base = coreComponents.getBaseLabelViewClass(info?.labelAlwaysOnTop, this.ttLabelView);
        });
    }

    public onLoad(_: Event) {
        this.imageLoaded = true;
        this.ready = true;
    }

    public onError(_: Event) {
        this.imageLoaded = false;
        this.ready = true;
    }

    private async setLabel() {
        if (this.ttTranslateLabel && this.ttLabel) {
            this.label = await this.translate.translate(this.ttLabel);
        } else {
            this.label = this.ttLabel;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttTranslateLabel']) {
            this.ttTranslateLabel = changes['ttTranslateLabel'].currentValue;
        }

        if (!!changes?.['ttLabel']?.currentValue && typeof changes['ttLabel'].currentValue === 'string' && changes['ttLabel'].currentValue !== changes['ttLabel']?.previousValue) {
            this.setLabel();
        }
    }
    // $onChanges(changes) {
    //     if (changes.ttLabel?.currentValue && this.utilityService.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel?.currentValue !== changes.ttLabel?.previousValue) {
    //         this.#setLabel();
    //     }

    //     if (changes.ttModel?.currentValue || changes.ttModel?.currentValue === '') {
    //         this.error = false;
    //         this.model = this.$sce.trustAsResourceUrl(changes.ttModel.currentValue);

    //         setTimeout(() => {
    //             if (this.#shouldShowButtonsOnMainImage()) {
    //                 this.style.buttons.display = 'flex';
    //             } else {
    //                 this.style.buttons.display = 'none';
    //             }
    //         }, 250);
    //     }

    //     if (this.ttClick && angular.isFunction(this.ttClick)) {
    //         this.style.img.cursor = 'pointer';
    //     } else {
    //         this.style.img.cursor = 'initial';
    //     }

    //     if (this.ttPlaceholderClick && angular.isFunction(this.ttPlaceholderClick)) {
    //         this.style.placeholder.cursor = 'pointer';
    //     } else {
    //         this.style.placeholder.cursor = 'initial';
    //     }

    //     if (changes.ttStyle?.currentValue && angular.isObject(changes.ttStyle.currentValue)) {
    //         this.#setStyle(changes.ttStyle.currentValue);
    //     }
    // }
}

export interface ImageButton {
    id: string;
    icon: string;
    label: string;
    onClick: string;
}
