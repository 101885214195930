import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { formatDate } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DATE_FORMATS } from '@app/core/models/date-adapter';
import { TranslateService } from '@app/core/services/translate.service';

/**
 * Represents a input field (without label) of type date which has a datepicker.
 */
@Component({
    selector: 'tt-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateInputComponent implements AfterViewInit, OnChanges, OnInit {
    @Input()
    public ttId: string = crypto.randomUUID();

    /**
     * Whether to allow a date to be empty.
     */
    @Input()
    set ttAllowEmpty(value: BooleanInput) {
        this._allowEmptyDate = coerceBooleanProperty(value);
    }
    get ttAllowEmpty(): boolean {
        return this._allowEmptyDate;
    }
    _allowEmptyDate = true;

    /**
     * Whether the date-input field can be tabbed to, default is true.
     */
    @Input()
    get ttTabable(): boolean {
        return this._tabable;
    }
    set ttTabable(value: BooleanInput) {
        this._tabable = coerceBooleanProperty(value);
    }
    private _tabable: boolean = true;

    /**
     * Whether the date-input is a readonly. Will be uneditable but readable.
     */
    @Input()
    get ttReadonly(): boolean {
        return this._readonly;
    }
    set ttReadonly(value: BooleanInput) {
        this._readonly = coerceBooleanProperty(value);
    }
    private _readonly: boolean = false;

    /**
     * Whether the date-input is disabled. Will be uneditable and less readable.
     */
    @Input()
    get ttDisabled(): boolean {
        return this._disabled;
    }
    set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled: boolean = false;

    /**
     * The date represented in the inputfield.
     */
    @Input()
    ttModel: Date | null = null;
    model: Date | null | string = null;
    modelView: string = '';

    /**
     * Event emitted when the date is changed from the input field.
     */
    @Output()
    ttModelChange = new EventEmitter<Date | null>();

    /**
     * Reference to the date picker component.
     */
    @ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

    /**
     * Reference to input field responsible for the date input.
     */
    @ViewChild('dateRef') dateRef?: ElementRef;

    restoreFocus = true;

    translations: { [key: string]: string } = {
        placholder: DATE_FORMATS.display.dateInput.format,
    };

    public isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    constructor(private translate: TranslateService) {}

    /**
     * Opens the date picker and focuses the date input.
     */
    openDatepicker() {
        this.picker?.open();
        setTimeout(() => {
            this.dateRef?.nativeElement.focus();
            this.dateRef?.nativeElement.select();
        }, 50);
    }

    /**
     * Handles date changed from the date picker.
     *
     * @param event material datepicker inpute event.
     */
    onDatePickerChanged(event: MatDatepickerInputEvent<any>) {
        if (event.value instanceof Date && event.value.toString() !== 'Invalid Date') {
            var tzo = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

            this.model = new Date(event.value.getTime() - tzo);
            this.onModelChange();
        } else if (!event.value && this.ttAllowEmpty) {
            this.model = null;
            this.onModelChange();
        } else if (!event.value && !this.ttAllowEmpty) {
            this.model = this.ttModel;
            this.onModelChange();
        }
    }

    /**
     * Closes the date picker when the input field is blurred.
     */
    onDateInputBlur() {
        this.restoreFocus = false;
        setTimeout(() => this.picker?.close(), 100);
        if (!this.ttModel && !this.ttAllowEmpty) this.ttModel = new Date();
        if (!this.ttModel && this._allowEmptyDate) this.ttModel = null;
    }

    /**
     * Focuses the date picker on arrow key down event.
     *
     * @param event the key event.
     */
    onDateInputKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();

            if (this.picker?.id) {
                this.picker.open();
                (document.getElementById(this.picker?.id)?.querySelector('.mat-calendar-body-active') as HTMLButtonElement)?.focus();
            }
        } else if (event.key === 'Tab') {
            this.onDateInputBlur();
        } else {
            this.picker?.close();
        }
    }

    onModelChange() {
        if (this.ttDisabled || this.ttReadonly) return;
        let newDate;

        if (typeof this.model === 'string') {
            newDate = new Date(this.model);
        } else {
            newDate = this.model;
        }

        if (newDate instanceof Date && newDate.toString() !== 'Invalid Date') {
            this.ttModel = newDate;
            this.ttModelChange.emit(this.ttModel);
        }
    }

    onMobileDateChanged(event: string) {
        console.log('event :>> ', event);
        this.model = event;

        this.modelView = formatDate(new Date(event), DATE_FORMATS.display.dateInput.format, DATE_FORMATS.display.dateInput.language);
        console.log('this.model :>> ', this.model);
        // @ts-ignore
        this.ttModel = new Date(event);
        this.ttModelChange.emit(this.ttModel);
    }

    public format = DATE_FORMATS.display.dateInput.format;
    public language = DATE_FORMATS.display.dateInput.language;

    async ngOnInit(): Promise<void> {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttAllowEmpty']) {
            this.ttAllowEmpty = changes['ttAllowEmpty'].currentValue;
        }

        if (changes?.['ttTabable']) {
            this.ttTabable = changes['ttTabable'].currentValue;
        }

        if (changes?.['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }

        if (changes?.['ttReadonly']) {
            this.ttReadonly = changes['ttReadonly'].currentValue;
        }

        if (changes?.['ttModel'] && ((changes['ttModel'].currentValue instanceof Date && changes['ttModel'].currentValue.toString() !== 'Invalid Date') || (changes['ttModel'].currentValue === null && this.ttAllowEmpty === true))) {
            if (!this.isMobile) {
                this.ttModel = changes['ttModel'].currentValue;
                this.model = this.ttModel;
                // @ts-ignore
            } else {
                this.ttModel = changes['ttModel'].currentValue;
                // @ts-ignore
                this.model = this.ttModel;
                this.modelView = formatDate(this.ttModel!, DATE_FORMATS.display.dateInput.format, DATE_FORMATS.display.dateInput.language);
            }
        }
    }

    async ngAfterViewInit(): Promise<void> {
        try {
            const dateformat = DATE_FORMATS.display.dateInput.format.replaceAll('MM', 'mm');
            this.translations['placeholder'] = await this.translate.translate(dateformat);
        } catch (error) {
            console.log('error :>> ', error);
        }

        this.picker?.closedStream.subscribe(() => (this.restoreFocus = true));
    }
}
