(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('helpdoc', {
        templateUrl: 'views/components/views/helpdoc/helpdoc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'helpdocService', 'translateListService', function ($stateParams, stateService, helpdocService, translateListService) {

            let vm = this;

            vm.model = {
                argtype: $stateParams.argtype,
                helpdocView: {
                    active: 'GENERALLY',
                    buttons: [
                        { id: 'GENERALLY', label: 'helpdoc_user', onClick: () => selectHelpdocView('GENERALLY') },
                        { id: 'INSTALLATION', label: 'helpdoc_installation', onClick: () => selectHelpdocView('INSTALLATION') },
                    ],
                },
                generallyView: {
                    active: 'VIEW',
                    buttons: [
                        { id: 'VIEW', label: 'bg_mode_generally_view', onClick: () => selectGenerallyView('VIEW') },
                        { id: 'EDIT', label: 'bg_mode_generally_edit', onClick: () => selectGenerallyView('EDIT') },
                    ]
                },
                installationView: {
                    active: 'VIEW',
                    buttons: [
                        { id: 'VIEW', label: 'bg_mode_installation_view', onClick: () => selectInstallationView('VIEW') },
                        { id: 'EDIT', label: 'bg_mode_installation_edit', onClick: () => selectInstallationView('EDIT') },
                    ]
                },
                edit: {},
                setting: {},
                selectListLanguages: [],
                itemsListRelatedDocs: [],
                editorReady: true
            };

            vm.style = {
                editor: {
                    div: {
                        height: '100vh',
                        maxHeight: 'calc(100vh - 400px)'
                    }
                }
            }

            function selectHelpdocView(id) {
                vm.model.helpdocView.active = id;
            };

            function selectGenerallyView(id) {
                vm.model.generallyView.active = id;
            }

            function selectInstallationView(id) {
                vm.model.installationView.active = id;
            }

            vm.onLanguageSelected = function ($value) {
                if (vm.model.setting.language_id !== $value) {
                    vm.model.setting.language_id = $value;
                    loadRelatedDocs();
                    loadHelpDoc();
                }
            }

            vm.addNewRelatedHelpDoc = function () {
                return stateService.go('helpdoc', { argtype: 'helpdoctt_keyno_sub', argvalue: vm.model.edit.helpdoctt_keyno });
            };

            vm.saveHelpDoc = function () {
                vm.model.edit.helpdoc_view = vm.model.helpdocView.active;
                vm.model.edit.bg_mode_generally = vm.model.generallyView.active;

                helpdocService.saveHelpDoc(vm.model.edit).then((_) => stateService.back());
            };

            // #region LOAD FUNCTIONS

            async function loadRelatedDocs() {
                return vm.model.itemsListRelatedDocs = await helpdocService.listRelatedDocs(vm.model.edit.helpdoctt_keyno, vm.model.edit.language_id);
            };

            async function loadSetting() {
                return vm.model.setting = (await helpdocService.getHelpDocSettings($stateParams.argtype, $stateParams.argvalue))[0];
            };

            async function loadHelpDoc() {
                return vm.model.edit = (await helpdocService.getHelpDoc(vm.model.setting))[0];
            };

            async function loadLanguages() {
                return vm.model.selectListLanguages = await translateListService.listLanguages();
            };

            // #endregion LOAD FUNCTIONS

            // #region ANGULAR LIFECYCLE HOOKS

            vm.$onInit = async function () {
                loadLanguages();
                await loadSetting();
                await loadHelpDoc();
                loadRelatedDocs();
            }

            // #endregion ANGULAR LIFECYCLE HOOKS

        }]
    });
})();
