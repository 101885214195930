(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('serviceObjectTypes', {
        templateUrl: 'views/components/views/serviceObjectTypes/serviceObjectTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'serviceObjectTypesService', function (stateService, serviceObjectTypesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.settingTypes.searchtext = '' }
                ],
                settingTypes: {},
                itemsListTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                serviceObjectTypesService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.settingTypes);
                });
            };

            let loadServiceObjectTypes = function () {
                serviceObjectTypesService.listServiceObjectTypes().then(function (list) {
                    angular.copy(list, vm.model.itemsListTypes);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobjecttype':
                        go({ serviceobjecttype_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadServiceObjectTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
