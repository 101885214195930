import { Injectable } from '@angular/core';
import { FieldConfigOptions, RowEditModalComponent, RowEditModalData } from './row-edit-modal/row-edit-modal.component';
import { ColumnLayoutSchema, DataTaskRequest, DBGridButton, GridColumnLayout, GridOptions, GridOptionsData, GridRow, LookupCellEditorParams, LookupConfig, TTGridState } from '../grid.types';
import { LookupCellEditorComponent } from '../cell-editors/lookup-cell-editor/lookup-cell-editor.component';
import { NumberCellEditorComponent } from '../cell-editors/number-cell-editor/number-cell-editor.component';
import { ColDef, GridApi } from '@ag-grid-community/core';
import { DateTimeCellEditorComponent } from '../cell-editors/date-time-cell-editor/date-time-cell-editor.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { GridService } from '../grid.service';
import { PopupTableComponent } from './popup-table/popup-table.component';
import { ColumnLayoutModalComponent, ColumnLayoutModalData } from './column-layout-modal/column-layout-modal.component';
import { GridModalRegistry } from '../grid.decorators';

/**
 * Service for modals used in grid component.
 */
@Injectable({
    providedIn: 'root',
})
export class GridModalService {
    constructor(private dialog: MatDialog, private gridService: GridService) {}

    /**
     * Opens a dialog based on the given button configurations.
     *
     * @param button the button to open a dialog based of.
     * @param data the data passed to the modal.
     */
    public openDialogFromDbButton(button: DBGridButton, data: GridModalData) {
        console.log(data);
        if (button.component) {
            const modalComponent = GridModalRegistry.get(button.component);

            if (modalComponent) {
                const dialog = this.dialog.open(modalComponent, { data: data, panelClass: !!button.modal_size ? 'modal-' + button.modal_size : '' });

                return firstValueFrom(dialog.afterClosed());
            }
        }

        throw Error('Colschema button of type = \'modal\' requires "component" property to be set in colschema. The value of the "component" property should be the value passed to a @GridModalComponent decorator');
    }

    /**
     * Opens a modal displaying a popup table using the given data(rows). Returns a promise which resolves when the modal is closed. If an row was clicked, returns the selected row.
     *
     * @param data the data(rows) to display in the popup table.
     * @returns a promise which resolves when the modal is closed, either resolving `null` or the row which was clicked.
     */
    public async openPopupTableModal(data: Record<string, unknown>[]): Promise<null | Record<symbol | string, unknown>> {
        const dialogRef: MatDialogRef<PopupTableComponent, null | Record<symbol | string, unknown>> = this.dialog.open(PopupTableComponent, { data: data });
        const selectedItem = await firstValueFrom(dialogRef.afterClosed());

        if (selectedItem) {
            return selectedItem;
        }
        return null;
    }

    /**
     * Opens the predfined column layouts modal.
     *
     * @param options the grid-options of the grid for which to open predefined column layouts modal for.
     * @param currentColumnLayout the current column layout.
     * @returns a promise containing the column layout to apply or undefined if modal was dismissed.
     */
    public async openColumnLayoutDialog(options: GridOptions, currentColumnLayout: ColumnLayoutSchema | TTGridState): Promise<GridColumnLayout | undefined> {
        if (options.dataTask?.loadData?.method) {
            const dialogRef = this.dialog.open(ColumnLayoutModalComponent, {
                data: <ColumnLayoutModalData>{
                    loadDataMethod: options.dataTask?.loadData?.method,
                    gridColumnLayout: currentColumnLayout,
                    activeLayoutKeyno: options.data?.layoutKeyno,
                },
                width: '60rem',
            });

            return firstValueFrom(dialogRef.afterClosed());
        }

        return;
    }
    /**
     * Opens modal for editing the given rowdata. If autosave is activiated, will also save changes before returning the edited row.
     *
     * @param options the grid options for the grid the row edit takes place.
     * @param rowData the row data from the grid.
     * @param api the AG-Grid api used to refresh the grid where the save happened.
     * @returns the edited row, null if the user cancelled.
     */
    public async openRowEditModal(options: GridOptions | undefined, rowData: GridRow | undefined, api: GridApi): Promise<GridRow | null | undefined> {
        if (!options?.data || !rowData) return null;

        const dialogRef: MatDialogRef<RowEditModalComponent, GridRow | null> = this.dialog.open(RowEditModalComponent, {
            width: '95vw',
            maxWidth: '1200px',
            data: <RowEditModalData>{
                row: rowData,
                fields: this.mapColDefsToFieldConfigrations(options.data, rowData),
            },
        });

        const data = await firstValueFrom(dialogRef.afterClosed());

        if (!data || !data._dirty) return null;

        options!.data!.changes![data._uuid || ''] = { state: 'update', data: data };

        if (options.dataTask?.saveData?.autoSave) {
            const responses = await this.gridService.saveChanges('update', options, api);

            if (responses instanceof Array) {
                responses?.forEach((response) => {
                    if (!!response?.row && !!response?.savedata) {
                        options.data!.rowData[options.data!.rowData.indexOf(response.row)] = { ...response.row, ...response.savedata };
                        const node = api?.getRowNode(response.row._uuid!);

                        node?.updateData({ ...response.row, ...response.savedata })!;
                    }
                });
            } else {
                options.data!.rowData[options.data!.rowData.findIndex((row) => row._uuid === data._uuid)] = { ...data };
                const node = api?.getRowNode(data._uuid!);
                node?.updateData({ ...data })!;
            }
        }

        return data;
    }

    /**
     * Maps the colschmea of the given grid-options data to field configurations.
     *
     * @param data the grid options data to use for configurating field configurations.
     * @param rowData the row to edit, used for setting initial data.
     * @returns a list of field configuration based on the given grid options data and row.
     */
    private mapColDefsToFieldConfigrations(data: GridOptionsData, rowData: GridRow): FieldConfigOptions[] {
        const fields: FieldConfigOptions[] = [];

        data.columnDefinitions?.forEach((colDef: ColDef) => {
            const columnInfo = data?.columnInfo?.[(colDef as ColDef).field || ''];

            if (colDef.hide !== true && colDef.editable) {
                if (colDef.cellEditor === LookupCellEditorComponent) {
                    const lookupEditorParams: LookupCellEditorParams = colDef.cellEditorParams();

                    fields.push({ type: 'search', label: lookupEditorParams.field, field: rowData![colDef?.colId || ''], method: +lookupEditorParams.method, keyname: lookupEditorParams.keyname, lookupConfig: columnInfo?.editable as LookupConfig });
                } else if (colDef.cellEditor === NumberCellEditorComponent) {
                    fields.push({ type: 'number', label: colDef.colId || '', field: rowData![colDef?.colId || ''], decimals: colDef.cellEditorParams().decimals, formatNumber: true });
                } else if (colDef.cellEditor === 'agCheckboxCellEditor') {
                    fields.push({ type: 'checkbox', label: colDef.colId || '', field: rowData![colDef?.colId || ''] });
                } else if (colDef.cellEditor === DateTimeCellEditorComponent || colDef.cellEditor === 'agDateCellEditor') {
                    fields.push({ type: 'datetime', label: colDef.colId || '', field: rowData![colDef?.colId || ''] });
                } else if (columnInfo?.type === 'text') {
                    if (columnInfo.editType === 'TL') {
                        fields.push({ type: 'textarea', label: colDef.colId || '', field: rowData![colDef?.colId || ''] });
                    } else if (columnInfo.editType === 'DD') {
                        fields.push({ type: 'select', label: colDef.colId || '', field: rowData![colDef?.colId || ''], id: columnInfo.dd_data_id || 'item_id', name: columnInfo.dd_data_name || 'item_name', list: columnInfo.dd_data || [] });
                    } else if (columnInfo.editType === 'DA') {
                        fields.push({ type: 'datetime', label: colDef.colId || '', field: rowData![colDef?.colId || ''] });
                    } else {
                        fields.push({ type: 'input', label: colDef.colId || '', field: rowData![colDef?.colId || ''] });
                    }
                }
            }
        });

        return fields;
    }
}

/**
 * Represents data passed to a grid modal which is opened through db grid buttons.
 */
export interface GridModalData {
    /**
     * The row which triggered the opening of a modal, if applicable.
     */
    row?: GridRow | null;

    /**
     * The selected rows in the grid.
     */
    selectedRows: GridRow[];

    /**
     * The datatask keyno of the load data procedure of the grid.
     */
    loadData?: DataTaskRequest | null;

    /**
     * The remember id value of the grid.
     */
    rememberId?: string | null;
}
