(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupTable', {
        templateUrl: 'views/components/views/usergroupTable/usergroupTable.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'userGroupTableService', 'layoutService', function ($stateParams, userGroupTableService, layoutService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2UserGroupTableKeyno: '',
                itemsListUserGroupTables: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadUserGroupTables = function () {
                userGroupTableService.listUserGroupTables({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListUserGroupTables);

                    angular.forEach(vm.model.itemsListUserGroupTables, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadUserGroupTables();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2UserGroupTableKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadUserGroupTables();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
