(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderHeadSetDisc', {
        templateUrl: 'views/components/views/orderHeadSetDisc/orderHeadSetDisc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'orderHeadSetDiscService', function ($stateParams, stateService, translateService, modalService, orderHeadSetDiscService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALL

            async function loadEdit() {
                vm.model.edit = (await orderHeadSetDiscService.getEdit({ order_internal_no: orderInternalNo }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderHeadSetDiscService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
