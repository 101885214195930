(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productLabel', {
        templateUrl: 'views/components/views/productLabel/productLabel.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'productLabelService', 'logisticService', 'oeeDataService', 'rememberService', 'typeaheadService', function ($q, stateService, utilityService, translateService, modalService, productLabelService, logisticService, oeeDataService, rememberService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                printer_keyno: '',
                p2_reportdef_keyno: ''
            };

            vm.translations = {
                error: '',
                ok: '',
                enter_all_required_fields: ''
            };

            vm.model = {
                edit: {},
                selectListPrinters: [],
                selectListReportDefinitions: [],
                selectListLabours: [],
                lockedPrint: false,
                saveDisabled: true
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                productLabelService.getEdit().then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    $q.all([
                        loadReports()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadPrinters = function () {
                logisticService.listPrinters().then(function (list) {
                    angular.copy(list, vm.model.selectListPrinters);
                });
            };

            let loadRegisteredByLabours = function () {
                oeeDataService.listRegisteredByLabours({ webpagename: 'finishpohscan' }).then(function (list) {
                    angular.copy(list, vm.model.selectListLabours);
                });
            };

            let loadReports = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id, true) !== true) return;

                return productLabelService.listReports({
                    webpage: 'productlabel',
                    argtype: 'prod_id',
                    argvalue: vm.model.edit.prod_id
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListReportDefinitions);
                });
            };

            let retrieveProductId = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return productLabelService.changeProductId({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    vm.model.edit.quantity = response[0].units_pr_carrier;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'printer_keyno':
                    case 'p2_reportdef_keyno':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchProduct({ prod_name: vm.model.edit[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'prod_name':
                        if (item) {
                            vm.model.edit.prod_id = item?.item_id ?? '';
                            vm.model.edit.prod_name = item?.item_name ?? '';

                            retrieveProductId();
                            loadReports();
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        setTimeout(vm.onChange);
                        break;
                    default:
                        break;
                }

            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_id = '';
                        vm.model.edit.prod_name = '';

                        loadReports();
                        break;
                    default:
                        break;
                }
            };

            vm.onChange = function () {
                if (vm.model.edit.prod_id > '' && +vm.model.edit.copies > 0 && (+vm.model.edit.labour_no > 0 || vm.model.edit.labour_required === '0') && +vm.model.edit.printer_keyno > 0 && vm.model.edit.p2_reportdef_keyno > '') {
                    vm.model.saveDisabled = false;
                } else {
                    vm.model.saveDisabled = true;
                }
            }

            // #endregion CLEAR VALUE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'printpreview':
                        go({
                            argtype: 'prod_id',
                            argvalue: vm.model.edit.prod_id,
                            report_keyno: vm.model.edit.p2_reportdef_keyno,
                            printer_keyno: vm.model.edit.printer_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON PRINT PROCEDURE FUNCTION CALL

            vm.printChanges = function () {
                vm.model.lockedPrint = true;

                productLabelService.printObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPrint = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPrint = false;

                        setTimeout(() => { document.querySelector('.tt-button--success')?.focus(); }, 0);
                    }
                });
            };

            // #endregion BUTTON PRINT PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'printer_keyno':
                    case 'p2_reportdef_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        rememberFunc(id);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPrinters();
                loadRegisteredByLabours();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
