import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';

@Component({
    selector: 'tt-color-input',
    templateUrl: './color-input.component.html',
    styleUrls: ['./color-input.component.css'],
})
export class ColorInputComponent extends FormFieldBaseComponent implements OnChanges, OnInit {
    /**
     * The color of the input. Preferably hex.
     */
    @Input()
    public ttModel = '#000000';

    /**
     * Event emitted when the color in the input has changed.
     */
    @Output()
    public ttModelChange = new EventEmitter<string>();

    /**
     * Milliseconds to wait inbetween each change event.
     *
     * @default 250
     */
    @Input()
    public set ttDebounceTime(value: NumberInput) {
        this._debounceTime = coerceNumberProperty(value);
    }
    public get ttDebounceTime(): number {
        return this._debounceTime;
    }
    private _debounceTime = 250;

    /**
     * Observable for changes in model.
     */
    private modelChangeSubject = new Subject<string>();

    /**
     * Subscription for the model change subject.
     */
    private modelChangeSubscription!: Subscription;

    /**
     * Ids of the elements in the component.
     */
    public id = {
        color: crypto.randomUUID(),
    };

    public onModelChange(event: string) {
        this.ttModel = event;
        this.rememberSubject.next(this.ttModel);
        this.modelChangeSubject.next(event);
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.ttModel = (await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue;
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();
        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
        this.modelChangeSubscription = this.modelChangeSubject.pipe(debounceTime(this.ttDebounceTime)).subscribe({ next: () => this.ttModelChange.emit(this.ttModel) });
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes['ttDebounceTime']) {
            this.ttDebounceTime = changes['ttDebounceTime'].currentValue;
            this.modelChangeSubscription?.unsubscribe?.();
            this.modelChangeSubscription = this.modelChangeSubject.pipe(debounceTime(this.ttDebounceTime)).subscribe({ next: () => this.ttModelChange.emit(this.ttModel) });
        }

        if (changes['ttModel'] && typeof changes['ttModel'].currentValue === 'string') {
            this.ttModel = changes['ttModel'].currentValue;
        }
    }
}
