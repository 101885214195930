(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('prodGrpMenu', {
        templateUrl: 'views/components/views/prodGrpMenu/prodGrpMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'prodGrpMenuService', 'ttGridFactory', function ($stateParams, stateService, utilityService, prodGrpMenuService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodGrpId = $stateParams.prodgrp_id;

            vm.model = {
                productGroupNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrplangs', { prodgrp_id: vm.model.menu.prodgrp_id }) }
                ],
                menu: {},
                treeList: [],
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_prodgrpmenu_prodgrp2_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'create_new',
                text: 'create_new',
                func: () => {
                    stateService.go('prodgrp2', {
                        prodgrp_id: vm.model.menu.prodgrp_id,
                        prodgrp2_id: '+'
                    });
                },
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await prodGrpMenuService.getMenu({ prodgrp_id: prodGrpId }))[0];

                loadTreeProductGroups();
                loadGrid();
            }

            async function loadTreeProductGroups() {
                if (utilityService.validateParmsValue(vm.model.menu.prodgrp_id) !== true) return;

                vm.model.treeList = (await prodGrpMenuService.treeListProductGroups({ prodgrp_id: vm.model.menu.prodgrp_id }));
            }
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                vm.grid.dataTask.loadData = {
                    method: 3069,
                    parameters: { prodgrp_id: vm.model.menu.prodgrp_id }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            }
            
            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prodgrp':
                        go({ prodgrp_id: vm.model.menu. prodgrp_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region TREE SELECTION FUNCTION

            vm.selectTreeItem = function (item) {
                stateService.go(item.item_state, item.item_parms);
            };

            // #endregion TREE SELECTION FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadMenu();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
