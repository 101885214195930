import { Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { AgCartesianAxisOptions, AgCartesianChartOptions, AgCartesianSeriesOptions, AgPolarAxisOptions, AgPolarChartOptions, AgPolarSeriesOptions } from 'ag-charts-community';

export interface KpiCompBaseData {
    kpicomp_keyno: string;
    h: string;
    w: string;
    x: string;
    y: string;
}

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    private webpageDashboardWidgetKeynos?: { [key: string]: KpiCompBaseData[] };

    constructor(private datatask: DataTaskService) {}

    public saveWidgetPosition(params: { kpicomp_keyno: number; webpage_name: string; h: number; w: number; x: number; y: number }): Promise<{ errorcode: string; errormessage: string }[]> {
        return this.datatask.Post(3524, params);
    }

    public setWidgetPosition(webpageName: string, params: KpiCompBaseData) {
        const index = this.webpageDashboardWidgetKeynos?.[webpageName]?.findIndex((widg) => widg.kpicomp_keyno === params.kpicomp_keyno);

        if (index !== undefined && index !== -1) {
            this.webpageDashboardWidgetKeynos![webpageName][index] = params;
        }
    }

    public async getWebpageDashboardWidgetKeynos(webpageName?: string) {
        if (!this.webpageDashboardWidgetKeynos) {
            this.webpageDashboardWidgetKeynos = {};

            let columns = 12;

            if (document.body.clientWidth <= 700) {
                columns = 3;
            } else if (document.body.clientWidth <= 1100) {
                columns = 6;
            }

            const kpiKeynos: (KpiCompBaseData & { webpage_name: string })[] = await this.datatask.Post(3517, { layout_columns: columns });
            const webpages = new Set(kpiKeynos.map((kpi) => kpi.webpage_name));

            for (let webpage of webpages) {
                this.webpageDashboardWidgetKeynos[webpage] = kpiKeynos.filter((kpi) => kpi.webpage_name === webpage).map((kpi) => ({ kpicomp_keyno: kpi.kpicomp_keyno, h: kpi.h, w: kpi.w, x: kpi.x, y: kpi.y }));
            }
        }

        if (!!webpageName) {
            return this.webpageDashboardWidgetKeynos[webpageName];
        } else {
            return this.webpageDashboardWidgetKeynos;
        }
    }

    public mapCartesianData(type: 'line' | 'bar', chartData: { xkey: string; ykey: string; yname: string; yvalue: number }[]): Partial<AgCartesianChartOptions> {
        const unqiueSeries = new Set(chartData.map((data) => data.ykey));
        let series: AgCartesianSeriesOptions[] = [];
        let datas: any[] = [];

        for (let key of unqiueSeries) {
            let keyName = chartData.find((data) => data.ykey === key)?.yname;
            series.push(<AgCartesianSeriesOptions>{
                type: type,
                xKey: 'xkey',
                yKey: key,
                yName: keyName ?? key,
                tooltip: {
                    renderer: (params: any) => {
                        return {
                            label: params.yName,
                            content: params.datum['xkey'] + ': ' + (!isNaN(+params.datum['yvalue']) ? Intl.NumberFormat(navigator.language).format(+params.datum['yvalue']) : params.datum['yvalue']),
                        };
                    },
                },
            });

            for (let data of chartData.filter((data) => data.ykey == key)) {
                datas.push({ ...data, [key]: data.yvalue });
            }
        }

        const axes: AgCartesianAxisOptions[] = [
            {
                type: 'category',
                position: 'bottom',
                label: { autoRotate: false },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    autoRotate: false,
                    formatter(params) {
                        const length = Math.floor(document.body.clientWidth / 100);
                        return !isNaN(+params.value) ? Intl.NumberFormat(navigator.language).format(+params.value) : params.value.length > length ? params.value.substring(0, length) + '...' : params.value;
                    },
                },
            },
        ];

        return { axes: axes, series: series, data: datas };
    }

    public mapHorizontalCartesianData(chartData: { xkey: string; ykey: string; yname: string; yvalue: number }[]): Partial<AgCartesianChartOptions> {
        const uniqueSeries = new Set(chartData.map((data) => data.xkey));
        let series: AgCartesianSeriesOptions[] = [];
        let datas: any[] = [];

        for (let key of uniqueSeries) {
            series.push({
                type: 'bar',
                direction: 'horizontal',
                xKey: 'yname',
                xName: 'yname',
                yKey: key,
                yName: key,
                tooltip: {
                    renderer: (params: any) => {
                        return {
                            label: params.yName,
                            content: params.datum.yname + ': ' + (!isNaN(+params.datum['yvalue']) ? Intl.NumberFormat(navigator.language).format(+params.datum['yvalue']) : params.datum['yvalue']),
                        };
                    },
                },
            });

            for (let data of chartData.filter((data) => data.xkey == key)) {
                datas.push({ ...data, [key]: data.yvalue });
            }
        }

        const axes: AgCartesianAxisOptions[] = [
            {
                type: 'category',
                position: 'left',
                label: {
                    autoRotate: false,
                    formatter(params) {
                        const length = Math.floor(document.body.clientWidth / 100);
                        return params.value.length > length ? params.value.substring(0, length) + '...' : params.value;
                    },
                },
            },
            {
                type: 'number',
                position: 'bottom',
                label: {
                    autoRotate: false,
                    formatter(params) {
                        const length = Math.floor(document.body.clientWidth / 100);
                        return !isNaN(+params.value) ? Intl.NumberFormat('no').format(+params.value) : params.value.length > length ? params.value.substring(0, length) + '...' : params.value;
                    },
                },
            },
        ];

        return { axes: axes, series: series, data: datas };
    }

    public mapPolarData(type: 'pie' | 'donut', chartData: { xkey: string; ykey: string; yname: string; yvalue: number }[]): Partial<AgPolarChartOptions> {
        let series: AgPolarSeriesOptions[] = [{ type: type, angleKey: 'yvalue', calloutLabelKey: 'yname', innerRadiusRatio: 0.7 }];
        let datas: any[] = chartData;
        let axes: AgPolarAxisOptions[] = [
            {
                type: 'angle-category',
                label: {
                    formatter(params) {
                        const length = Math.floor(document.body.clientWidth / 100);
                        return params.value.length > length ? params.value.substring(0, length) + '...' : params.value;
                    },
                },
            },
        ];

        return { series: series, data: datas, axes: axes };
    }

    public getChartType(data: string) {
        if (data === 'GRAPH_LINE') {
            return 'line';
        } else if (data === 'PIE') {
            return 'pie';
        } else if (data === 'DONUT') {
            return 'donut';
        } else {
            return 'bar';
        }
    }
}
