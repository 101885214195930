(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageLink', {
        templateUrl: 'views/components/views/webpageLink/webpageLink.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'webpageLinkService', 'ttGridFactory', 'layoutService', function ($stateParams, stateService, modalService, webpageLinkService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2WebpageKeyno = $stateParams.p2_webpage_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            vm.model = {
                tabletSize: tabletSize,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                menuGroupButtons1: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('menugrps') }
                ],
                menuGroupButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.menugrp_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('menugrps') }
                ],
                setting: {},
                selectListMenuGroups: [],
                itemsListWebpageLinks: [],
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_webpagelink_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'webpagelink_set_menugrp_keyno',
                text: 'webpagelink_set_menugrp_keyno',
                func: function () {
                    progressFunc();
                },
                icon: 'fa-plus',
                cssClass: 'btn btn-success im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE CALLS
            
            let loadSetting = function () {
                webpageLinkService.getSetting({ p2_webpage_keyno: p2WebpageKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadMenuGroups = function () {
                webpageLinkService.listMenuGroups().then(function (list) {
                    angular.copy(list, vm.model.selectListMenuGroups);
                });
            };

            let loadWebpageLinks = function () {
                webpageLinkService.listWebpageLinks({ p2_webpage_keyno: p2WebpageKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebpageLinks);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3488,
                    parameters: { p2_webpage_keyno: p2WebpageKeyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                let doStep = function () {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) return;

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].p2_webpagelink_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        webpageLinkService.saveItems({
                            menugrp_keyno: vm.model.setting.menugrp_keyno,
                            p2_webpagelink_keyno: items[step].p2_webpagelink_keyno
                        }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let progressFunc = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                let allRows = vm.grid.gridfunc.getAllRows();
                let selectedRows = [];

                angular.forEach(allRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push(item);
                    }
                });

                if (selectedRows.length === 0) return;

                vm.model.progressInfo.max = selectedRows.length;
                vm.activateProgress(selectedRows);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') return;
                    if (vm.model.runProgress === 'FINISH') loadGrid();
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2webpagelinkedit':
                        go({
                            p2_webpage_keyno_belongto: p2WebpageKeyno,
                            p2_webpagelink_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadMenuGroups();
                loadGrid();
                loadWebpageLinks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
