import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISaveResponse, UserService } from './user.service';
import { DateFormat, IUserDetails, UserDetails } from '../models/user-details';
import { UserSetting } from '../models/user-setting';
import { AppSettingsService } from './app-settings.service';
import { DATE_FORMATS } from '../models/date-adapter';

export interface IIdUser {
    enableSendLoginLink: boolean;
    enablePasswordReset: boolean;
    enableAddUser: boolean;
    enableRemoveUser: boolean;
    enableAddAdmin: boolean;
    enableRemoveAdmin: boolean;
}

export class UserEditCache {
    user: IUserDetails | null = null;
    idUser: IIdUser | null = null;
    translations: Record<string, string> | null = null;
    selectedLabourNo: string = '';
    selectedLabourName: string = '';

    constructor() {}

    public reset() {
        this.user = null;
        this.idUser = null;
        this.translations = null;
        this.selectedLabourNo = '';
        this.selectedLabourName = '';
    }

    public set(user: IUserDetails, idUser: IIdUser, translations: Record<string, string>) {
        this.user = { ...user };
        this.idUser = { ...idUser };
        this.translations = { ...translations };
    }
}

@Injectable({
    providedIn: 'root',
})
export class UserStore {
    //#region Fields

    private _isReady: boolean = false;

    private _userId: string = '';
    private _userIdChanged: BehaviorSubject<string> = new BehaviorSubject(this._userId);

    private _userName: string = '';
    private _userNameChanged: BehaviorSubject<string> = new BehaviorSubject(this._userName);

    private _breadcrumbView: string = 'true';
    private _breadcrumbViewChanged: BehaviorSubject<string> = new BehaviorSubject(this._breadcrumbView);

    private _showBreadcrumbs: boolean = true; // only applicable if breacrumbView is set to 'toggle'.
    private _showBreadcrumbsChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._showBreadcrumbs);

    private _developMode: boolean = false;
    private _developModeChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._developMode);

    private _email: string = '';
    private _emailChanged: BehaviorSubject<string> = new BehaviorSubject(this._email);

    private _fontSize: string = '20px';
    private _fontSizeChanged: BehaviorSubject<string> = new BehaviorSubject(this._fontSize);

    private _fontSizeMobile: string = '20px';
    private _fontSizeMobileChanged: BehaviorSubject<string> = new BehaviorSubject(this._fontSizeMobile);

    private _hexColor: string = '#8ac5d2';
    private _hexColorChanged: BehaviorSubject<string> = new BehaviorSubject(this._hexColor);

    private _inputLayout: string = '0';
    private _inputLayoutChanged: BehaviorSubject<string> = new BehaviorSubject(this._inputLayout);

    private _labelAlwaysOnTop: boolean = false;
    private _labelAlwaysOnTopChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._labelAlwaysOnTop);

    private _labourName: string = '';
    private _labourNameChanged: BehaviorSubject<string> = new BehaviorSubject(this._labourName);

    private _labourNo: number = 0;
    private _labourNoChanged: BehaviorSubject<number> = new BehaviorSubject(this._labourNo);

    private _languageId: string = 'no';
    private _languageIdChanged: BehaviorSubject<string> = new BehaviorSubject(this._languageId);

    private _dateFormat: DateFormat = 'dd/mm/yyyy';
    private _dateFormatChanged: BehaviorSubject<DateFormat> = new BehaviorSubject(this._dateFormat);

    private _phone: string = '';
    private _phoneChanged: BehaviorSubject<string> = new BehaviorSubject(this._phone);

    private _showSettings: boolean = false;
    private _showSettingsChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._showSettings);

    private _themeKeyno: number = 1;
    private _themeKeynoChanged: BehaviorSubject<number> = new BehaviorSubject(this._themeKeyno);

    private _themeKeynoMobile: number = 1;
    private _themeKeynoMobileChanged: BehaviorSubject<number> = new BehaviorSubject(this._themeKeynoMobile);

    private _windowHeadingColor: string = '#fff';
    private _windowHeadingColorChanged: BehaviorSubject<string> = new BehaviorSubject(this._windowHeadingColor);

    private _sideMenuOpen: boolean = true;
    private _sideMenuOpenChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._sideMenuOpen);

    private _useAgGrid: boolean = false;
    private _useAgGridChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._useAgGrid);

    private _showNotificationToasts: boolean = true;
    private _showNotificationToastsChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._showNotificationToasts);

    private _userSettings: Record<string, UserSetting> = {};
    private _userGroups: Record<string, number> = {};

    private _roles: string[] = [];
    private _rolesChanged: BehaviorSubject<string[]> = new BehaviorSubject(this._roles);

    public userEditCache: UserEditCache = new UserEditCache();

    private _isSystemAdmin: boolean = false;
    private _isSystemAdminChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._isSystemAdmin);

    private _isCompanyAdmin: boolean = false;
    private _isCompanyAdminChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._isCompanyAdmin);

    private _isAdmin: boolean = false;
    private _isAdminChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._isAdmin);

    private _isTouchTimeDeveloper: boolean = false;
    private _isTouchTimeDeveloperChanged: BehaviorSubject<boolean> = new BehaviorSubject(this._isTouchTimeDeveloper);

    //#endregion

    //#region Constructors

    constructor(private userService: UserService) {}

    //#endregion

    //#region Property Getters

    public get userId(): string {
        return this._userId;
    }

    public get userIdChanged(): Observable<string> {
        return this._userIdChanged.asObservable();
    }

    public get userName(): string {
        return this._userName;
    }

    public get userNameChanged(): Observable<string> {
        return this._userNameChanged.asObservable();
    }

    public get breadcrumbView(): string {
        return this._breadcrumbView;
    }

    public get breadcrumbViewChanged(): Observable<string> {
        return this._breadcrumbViewChanged.asObservable();
    }

    public get showBreadcrumbs(): boolean {
        return this._showBreadcrumbs;
    }

    public get showBreadcrumbsChanged(): Observable<boolean> {
        return this._showBreadcrumbsChanged.asObservable();
    }

    public get developMode(): boolean {
        return this._developMode;
    }

    public get developModeChanged(): Observable<boolean> {
        return this._developModeChanged.asObservable();
    }

    public get email(): string {
        return this._email;
    }

    public get emailChanged(): Observable<string> {
        return this._emailChanged.asObservable();
    }

    public get fontSize(): string {
        return this._fontSize;
    }

    public get fontSizeChanged(): Observable<string> {
        return this._fontSizeChanged.asObservable();
    }

    public get fontSizeMobile(): string {
        return this._fontSizeMobile;
    }

    public get fontSizeMobileChanged(): Observable<string> {
        return this._fontSizeMobileChanged.asObservable();
    }

    public get hexColor(): string {
        return this._hexColor;
    }

    public get hexColorChanged(): Observable<string> {
        return this._hexColorChanged.asObservable();
    }

    public get inputLayout(): string {
        return this._inputLayout;
    }

    public get inputLayoutChanged(): Observable<string> {
        return this._inputLayoutChanged.asObservable();
    }

    public get labelAlwaysOnTop(): boolean {
        return this._labelAlwaysOnTop;
    }

    public get labelAlwaysOnTopChanged(): Observable<boolean> {
        return this._labelAlwaysOnTopChanged.asObservable();
    }

    public get labourName(): string {
        return this._labourName;
    }

    public get labourNameChanged(): Observable<string> {
        return this._labourNameChanged.asObservable();
    }

    public get labourNo(): number {
        return this._labourNo;
    }

    public get labourNoChanged(): Observable<number> {
        return this._labourNoChanged.asObservable();
    }

    public get languageId(): string {
        return this._languageId;
    }

    public get languageIdChanged(): Observable<string> {
        return this._languageIdChanged.asObservable();
    }

    public get dateFormat(): DateFormat {
        return this._dateFormat;
    }

    public get dateFormatChanged(): Observable<DateFormat> {
        return this._dateFormatChanged.asObservable();
    }

    public get phone(): string {
        return this._phone;
    }

    public get phoneChanged(): Observable<string> {
        return this._phoneChanged.asObservable();
    }

    public get showSettings(): boolean {
        return this._showSettings;
    }

    public get showSettingsChanged(): Observable<boolean> {
        return this._showSettingsChanged.asObservable();
    }

    public get themeKeyno(): number {
        return this._themeKeyno;
    }

    public get themeKeynoChanged(): Observable<number> {
        return this._themeKeynoChanged.asObservable();
    }

    public get themeKeynoMobile(): number {
        return this._themeKeynoMobile;
    }

    public get themeKeynoMobileChanged(): Observable<number> {
        return this._themeKeynoMobileChanged.asObservable();
    }

    public get windowHeadingColor(): string {
        return this._windowHeadingColor;
    }

    public get windowHeadingColorChanged(): Observable<string> {
        return this._windowHeadingColorChanged.asObservable();
    }

    public get sideMenuOpen(): boolean {
        return this._sideMenuOpen;
    }

    public get sideMenuOpenChanged(): Observable<boolean> {
        return this._sideMenuOpenChanged.asObservable();
    }

    public get isSystemAdmin(): boolean {
        return this._isSystemAdmin;
    }

    public get isSystemAdminChanged(): Observable<boolean> {
        return this._isSystemAdminChanged.asObservable();
    }

    public get isCompanyAdmin(): boolean {
        return this._isCompanyAdmin;
    }

    public get isCompanyAdminChanged(): Observable<boolean> {
        return this._isCompanyAdminChanged.asObservable();
    }

    public get isAdmin(): boolean {
        return this._isAdmin;
    }

    public get isAdminChanged(): Observable<boolean> {
        return this._isAdminChanged.asObservable();
    }

    public get isTouchTimeDeveloper(): boolean {
        return this._isTouchTimeDeveloper;
    }

    public get isTouchTimeDeveloperChanged(): Observable<boolean> {
        return this._isTouchTimeDeveloperChanged.asObservable();
    }

    public get useAgGrid(): boolean {
        return this._useAgGrid;
    }

    public get useAgGridChanged(): Observable<boolean> {
        return this._useAgGridChanged.asObservable();
    }

    public get showNotificationToasts(): boolean {
        return this._showNotificationToasts;
    }

    public get showNotificationToastsChanged() {
        return this._showNotificationToastsChanged.asObservable();
    }

    public get userSettings(): Record<string, UserSetting> {
        return this._userSettings;
    }

    public get userGroups(): Record<string, number> {
        return this._userGroups;
    }

    public get roles(): string[] {
        return this._roles;
    }

    //#endregion

    //#region Property Setters

    public setUserId(value: string) {
        this._userId = value;
        this._userIdChanged.next(value);
    }

    public setUserName(value: string) {
        this._userName = value;
        this._userNameChanged.next(value);
    }

    public setBreadcrumbView(value: string) {
        this._breadcrumbView = value;
        this._breadcrumbViewChanged.next(value);
    }

    public setShowBreadcrumbs(value: boolean) {
        this._showBreadcrumbs = value;
        this._showBreadcrumbsChanged.next(value);
    }

    public setDevelopMode(value: boolean) {
        this._developMode = value;
        this._developModeChanged.next(value);
    }

    public setEmail(value: string) {
        this._email = value;
        this._emailChanged.next(this._email);
    }

    public setFontSize(value: string) {
        value = this.validateFontSize(value);

        this._fontSize = value;
        this._fontSizeChanged.next(this._fontSize);
    }

    public setFontSizeMobile(value: string) {
        value = this.validateFontSize(value);

        this._fontSizeMobile = value;
        this._fontSizeMobileChanged.next(value);
    }

    public setHexColor(value: string) {
        this._hexColor = value;
        this._hexColorChanged.next(value);
    }

    public setInputLayout(value: string) {
        this._inputLayout = value;
        this._inputLayoutChanged.next(value);

        this.updateInputStyles();
    }

    public setLabelAlwaysOnTop(value: boolean) {
        this._labelAlwaysOnTop = value;
        this._labelAlwaysOnTopChanged.next(value);
    }

    public setLabourName(value: string) {
        this._labourName = value;
        this._labourNameChanged.next(value);
    }

    public setLabourNo(value: number) {
        this._labourNo = value;
        this._labourNoChanged.next(value);
    }

    public setLanguageId(value: string) {
        this._languageId = value;
        this._languageIdChanged.next(value);
        if (document?.documentElement) document?.documentElement.setAttribute('lang', value);
        DATE_FORMATS.display.dateA11yLabel.language = value === 'gb' ? 'en' : value;
        DATE_FORMATS.display.monthYearLabel.language = value === 'gb' ? 'en' : value;
        DATE_FORMATS.display.monthYearA11yLabel.language = value === 'gb' ? 'en' : value;
        DATE_FORMATS.display.dateInput.language = value === 'gb' ? 'en' : value;
    }

    public setDateFormat(value: DateFormat) {
        this._dateFormat = value;
        DATE_FORMATS.parse.dateInput.format = value?.replaceAll('m', 'M');
        DATE_FORMATS.display.dateInput.format = value?.replaceAll('m', 'M');
        this._dateFormatChanged.next(value);
    }

    public setPhone(value: string) {
        this._phone = value;
        this._phoneChanged.next(value);
    }

    public setShowSettings(value: boolean) {
        this._showSettings = value;
        this._showSettingsChanged.next(value);
    }

    public setThemeKeyno(value: number) {
        this._themeKeyno = value;
        this._themeKeynoChanged.next(value ?? 1);

        if (window.matchMedia('(min-width: 768px)').matches === true) {
            this.setTheme(this._themeKeyno);
        }
    }

    public setThemeKeynoMobile(value: number) {
        this._themeKeynoMobile = value;
        this._themeKeynoMobileChanged.next(value ?? 1);

        if (window.matchMedia('(min-width: 768px)').matches === false) {
            this.setTheme(this._themeKeynoMobile);
        }
    }

    public setWindowHeadingColor(value: string) {
        this._windowHeadingColor = value;
        this._windowHeadingColorChanged.next(value);
    }

    public setSideMenuOpen(value: boolean) {
        this._sideMenuOpen = value;
        this._sideMenuOpenChanged.next(value);
    }

    public setIsSystemAdmin(value: boolean) {
        this._isSystemAdmin = value;
        this._isSystemAdminChanged.next(value);
    }

    public setIsCompanyAdmin(value: boolean) {
        this._isCompanyAdmin = value;
        this._isCompanyAdminChanged.next(value);
    }

    public setIsAdmin(value: boolean) {
        this._isAdmin = value;
        this._isAdminChanged.next(value);
    }

    public setIsTouchTimeDeveloper(value: boolean) {
        this._isTouchTimeDeveloper = value;
        this._isTouchTimeDeveloperChanged.next(value);
    }

    public setRoles(value: string[]) {
        this._roles = value;
        this._rolesChanged.next(value);
    }

    public setUseAgGrid(value: boolean) {
        this._useAgGrid = value;
        this._useAgGridChanged.next(value);
    }

    public setShowNotificationToastsChanged(value: boolean) {
        this._showNotificationToasts = value;
        this._showNotificationToastsChanged.next(value);
    }

    //#endregion

    //#region Public Methods

    public async ensureIsReady(): Promise<void> {
        await this.waitForTrue(() => this._isReady);
    }

    public async init(appSettings: AppSettingsService) {
        if (this._userId?.length > 0) return;

        const self = this;

        let settings = (await this.userService.getSettings(0)) as UserSetting[];
        let details = appSettings.settings.user as UserDetails;
        // // @ts-ignore
        const root = document?.querySelector(':root');

        if (!!root && root.tagName === 'HTML') {
            (root as HTMLHtmlElement).style?.setProperty('--tt-mobile-font-size', details.fontSizeMobile + 'px');
            (root as HTMLHtmlElement).style?.setProperty('--tt-font-size-original', details.fontSize + 'px');
        }
        if (details.labelAlwaysOnTop) {
            document.body.classList.add('tt-label-on-top');
        }

        this.setUserId(details.portalUserKeynoEdit.toString());
        this.setUserName(details.userName);
        this.setBreadcrumbView(details.breadcrumbView);
        this.setDevelopMode(details.developMode);
        this.setEmail(details.myEmail);
        this.setFontSize(details.fontSize + 'px');
        this.setFontSizeMobile(details.fontSizeMobile);
        this.setHexColor(details.hexColor);
        this.setInputLayout(details.inputStyle);
        this.setLabelAlwaysOnTop(details.labelAlwaysOnTop);
        this.setLabourName(details.labourName);
        this.setLabourNo(details.labourNo);
        this.setLanguageId(details.languageId);
        this.setPhone(details.myCellPhoneNo);
        this.setShowSettings(details.showSettings);
        this.setThemeKeyno(details.themeKeyno);
        this.setThemeKeynoMobile(details.themeKeynoMobile);
        this.setWindowHeadingColor(details.windowHeadingColor);
        this.setRoles(details.roles);
        this.setDateFormat(details.dateFormat);
        this.setIsAdmin(details.roles.includes('Administrator'));
        this.setIsSystemAdmin(details.roles.includes('SystemAdmin'));
        this.setIsCompanyAdmin(details.roles.includes('CompanyAdmin'));
        this.setIsTouchTimeDeveloper(details.roles.includes('TouchTimeDeveloper'));
        this.setUseAgGrid(details.useAgGrid);
        this.setShowNotificationToastsChanged(details.showNotificationToasts)

        this._userSettings = {};

        settings.forEach(function (setting: UserSetting) {
            self._userSettings['_' + setting.p2_setting_keyno] = setting;
        });

        const userGroups = details.userGroups.split(',');

        this._userGroups = {};

        userGroups.forEach(function (keyno) {
            self._userGroups['_' + keyno] = parseInt(keyno);
        });

        this._isReady = true;
    }

    public async save(user: IUserDetails): Promise<ISaveResponse> {
        await this.ensureIsReady();

        this.setFontSize(user.fontSize.toString());
        this.setLabelAlwaysOnTop(user.labelAlwaysOnTop);
        this.setBreadcrumbView(user.breadcrumbView);

        return this.userService.save(user);
    }

    //#endregion

    //#region Private Methods

    private waitForTrue(variable: () => boolean, interval: number = 100): Promise<void> {
        return new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (variable()) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, interval);
        });
    }

    private updateInputStyles() {
        const inputStyles = [
            { id: '0', url: 'assets/styles/input-styles/_underline.css' },
            { id: '1', url: 'assets/styles/input-styles/_framed.css' },
        ];

        let userInputStyle = inputStyles.find((style) => style.id === this._inputLayout);

        if (userInputStyle && document?.querySelector('head')) {
            const existingInputLayout = document.getElementById('inputStyle');

            if (existingInputLayout) {
                existingInputLayout.remove();
            }

            document.querySelector('head')?.insertAdjacentHTML('beforeend', `<link id="inputStyle" rel="stylesheet" type="text/css" href="${userInputStyle.url}">`);
        }
    }

    private setTheme(themeId: number) {
        if (!themeId) return;
        if (!document.getElementsByTagName('html')?.[0]) return;

        switch (themeId.toString()) {
            case '2':
                document.getElementsByTagName('html')[0].setAttribute('class', 'dark');
                document.getElementById('kendo-theme')?.setAttribute('href', 'https://kendo.cdn.telerik.com/2019.1.115/styles/kendo.metroblack.min.css');
                break;
            default:
                document.getElementsByTagName('html')[0].setAttribute('class', 'light');
                document.getElementById('kendo-theme')?.setAttribute('href', 'https://kendo.cdn.telerik.com/2019.1.115/styles/kendo.silver.min.css');
                break;
        }
    }

    private validateFontSize(value: string): string {
        value = value.trim();

        if (value.length <= 0) {
            value = '20px';
        }

        if (!value.endsWith('px')) {
            value += 'px';
        }

        return value;
    }

    //#endregion
}
