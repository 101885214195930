import { Injectable } from '@angular/core';
import { Base64UrlService } from '../../services/base64url.service';
import { DataServiceBase } from '../framework/data-service-base';
import { DataTaskService } from "../services/data-task.service";
import { UtilityService } from './utility.service';

interface ResponseItem {
    variablevalue: string;
    [key: string]: any; // Other properties if needed
}

@Injectable({
    providedIn: 'root',
})
export class RememberService extends DataServiceBase {
    constructor(protected _dataTaskService: DataTaskService, private base64: Base64UrlService) { super(); }

    public async remember(variableName: string, variableValue: any): Promise<void> {
        const value = this.convertToJsonIfObject(variableValue);

        await this._dataTaskService.Post(616, { variablename: variableName, variablevalue: value });
    }

    public async getLastStatusEncoded(variablename: string, options: { urlencode_response?: '1' | '0' }) {
        const response = await this._dataTaskService.Post(973, { variablename: variablename, encode_response: true, urlencode_response: '0' });
        //const response = await this._dataTaskService.Post(973, { variablename: variablename, encode_response: true, urlencode_response: options?.urlencode_response ?? '0' });

        if (options?.urlencode_response === '1') {
            let result: string = response[0].variablevalue;

            while (result.endsWith('=')) {
                result = result.substring(0, result.length - 1);
            }

            result = result.replaceAll('+', '-');
            result = result.replaceAll('/', '_');
            response[0].variablevalue = this.base64.urlDecode(result);
        } else {
            response[0].variablevalue = atob(response[0].variablevalue);
        }

        return response;
    }

    public async getLastStatus(variablename: string, isObject: boolean = false, getAll: boolean = false) {
        const response = await this._dataTaskService.Post(973, { variablename: variablename, get_all: getAll });

        if (getAll) {
            return JSON.parse(response[0].variablevalue);
        }

        return isObject ? this.getResponseObject(response) : response;
    }

    private getResponseObject(response: ResponseItem[]): any | null {
        if (!this.isArray(response)) return null;
        if (response.length < 1) return null;
        if (response[0].variablevalue === undefined) return null;
        if (typeof response[0].variablevalue !== 'string') return null;
        if (response[0].variablevalue.length < 1) return null;

        try {
            return JSON.parse(response[0].variablevalue);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
        }
    }
}
