(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('externalApi', {
        templateUrl: 'views/components/views/externalApi/externalApi.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'translateService', 'modalService', 'externalApiService', 'ttGridFactory', 'logisticService', 'ediDestinationsService', function ($stateParams, $q, stateService, translateService, modalService, externalApiService, ttGridFactory, logisticService, ediDestinationsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let externalApiKeyno = $stateParams.external_api_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                gridReady: false,
                externalApiKeyno: externalApiKeyno,
                businessCoButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.businessco_no = '0' }
                ],
                ediDestinationButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.edi_destination_keyno = '0' }
                ],
                externalApiAuthMethodButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.external_api_auth_method = 'none' }
                ],
                edit: {
                    external_api_keyno: '',
                    external_api_name: '',
                    external_api_auth_method: ''
                },
                auth_method_id: 0,
                selectListMethods: [],
                businessCoList: [],
                ediDestinations: [],
                lockedSave: false,
                lockedDelete: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'external_api_log_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.handleMethodChange = handleMethodChange;

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                externalApiService.getEdit({ external_api_keyno: externalApiKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadMethods = function () {
                externalApiService.listMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListMethods);
                });
            };

            async function loadBusinessCoList() {
                vm.model.businessCoList = await logisticService.listBusinessCompanies();
            }

            async function loadEdiDestinations() {
                vm.model.ediDestinations = await ediDestinationsService.loadP2EdiDestinationList();
                console.log(vm.model.ediDestinations);
            }

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3480,
                    parameters: vm.model.edit
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            function handleMethodChange(value) {
                vm.model.auth_method_id = value;

                var findMethod = vm.model.selectListMethods.filter(function (item) {
                    return item.item_id === vm.model.auth_method_id;
                });

                var authMethod;

                if (findMethod.length > 0 && findMethod[0].item_name !== '') {
                    authMethod = findMethod[0].item_name;
                } else {
                    authMethod = 'none';
                }

                vm.model.edit.external_api_auth_method = authMethod;
            }

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                externalApiService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].error_code !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: response[0].error_title,
                            message: response[0].error_message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    externalApiService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].error_code !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].error_message,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadMethods();
                loadBusinessCoList();
                loadEdiDestinations();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
