import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { TranslateService } from '@app/core/services/translate.service';

/**
 * Represents a card/well. Content is passed through the element.
 */
@Component({
    selector: 'tt-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnChanges {
    /** (Optional) a heading for the card. No translation supported yet. */
    @Input()
    public ttHeading?: string;

    /**
     * The headed appended to the template.
     */
    public heading = '';

    /**
     * Whether the heading for the card should be translated or not.
     *
     * @default true
     */
    @Input()
    public set ttTranslateHeading(value: BooleanInput) {
        this._translateHeading = coerceBooleanProperty(value);
    }
    public get ttTranslateHeading(): boolean {
        return this._translateHeading;
    }
    private _translateHeading = true;

    @Input()
    public ttIcon?: string;

    @Input()
    public ttClass?: string;

    constructor(private translate: TranslateService) {
        //     super();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttTranslateHeading']) {
            this.ttTranslateHeading = changes['ttTranslateHeading'].currentValue;
        }

        if (changes['ttHeading']) {
            if (!changes['ttHeading'].currentValue) {
                this.heading = '';
            } else if (typeof changes['ttHeading'].currentValue === 'string' && (changes['ttHeading'].currentValue !== changes['ttHeading'].previousValue || changes['ttHeading'].firstChange)) {
                if (this.ttTranslateHeading) {
                    this.heading = await this.translate.translate(changes['ttHeading'].currentValue);
                } else {
                    this.heading = changes['ttHeading'].currentValue;
                }
            }
        }
    }
}
