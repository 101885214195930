import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'tt-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.css'],
})
export class ChangePasswordDialogComponent {
    /**
     * The new password the user inputed.
     */
    public newPassword: string = '';

    /**
     * The repeated password.
     */
    public repeatPassword: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) data: { password?: string }, private dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
        if (data?.password) {
            this.newPassword = data.password;
            this.repeatPassword = data.password;
        }
    }

    apply() {
        if (this.newPassword === this.repeatPassword) {
            this.dialogRef.close(this.newPassword);
        }
    }

    cancel() {
        this.dialogRef.close();
    }
}
