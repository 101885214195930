import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, Host, Input, Output, SimpleChanges } from '@angular/core';
import { Item } from '../../listbox/listbox.component';
import { ListComponent } from '../list.component';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'tt-list[ttRadioSelection]:not(tt-list[ttCheckboxSelection])',
})
export class ListRadioSelectionDirective<TType extends Item> {
    /**
     * Configures radio selection of the list.
     */
    @Input()
    set ttRadioSelection(value: BooleanInput) {
        this._radioSelection = coerceBooleanProperty(value);
    }
    get ttRadioSelection(): boolean {
        return this._radioSelection;
    }
    _radioSelection = false;

    /**
     * Event emitted when the radio selection of the list has changed.
     */
    @Output()
    ttRadioSelectionChanged = new EventEmitter<TType>();

    private ttRadioSelectionChangedSubscription?: Subscription;

    constructor(@Host() private componentRef: ListComponent<TType>, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.componentRef.ttRadioSelection = this.ttRadioSelection;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttRadioSelection']) {
            this.ttRadioSelection = changes['ttRadioSelection'].currentValue;
            this.componentRef.ttRadioSelection = this.ttRadioSelection;

            if (this.ttRadioSelection) {
                this.ttRadioSelectionChangedSubscription = this.componentRef.ttRadioSelectionChanged.subscribe((value) => this.ttRadioSelectionChanged.emit(value));
            } else {
                this.ttRadioSelectionChangedSubscription?.unsubscribe();
            }

            this.cdr.detectChanges();
        }
    }
}
