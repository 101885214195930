import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridstackComponent, NgGridStackOptions } from 'gridstack/dist/angular';
import { MenuGroup, MenuItem, WebpagemenuService } from './webpagemenu.service';
import { StateService } from '@app/core/services/state.service';
import { WebpagemenuGroupWidget } from './webpagemenu-group-widget/webpagemenu-group-widget.component';
import { GridStack } from 'gridstack';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'tt-webpagemenu',
    templateUrl: './webpagemenu.component.html',
    styleUrls: ['./webpagemenu.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class WebpagemenuComponent implements OnInit, OnChanges, AfterViewInit {
    /**
     * The name of the webpagemenu.
     */
    @Input()
    public ttName: string = '';

    /**
     * All available menu groups. (Includes groups which may not be used by any of the menu items).
     */
    public menuGroups: MenuGroup[] = [];

    /**
     * The menu items for this webpagemenu.
     */
    public menuItems: MenuItem[] = [];

    /**
     * The header of the webpage.
     */
    public header: any = {};

    /**
     * Reference to the gridstack instance.
     */
    private gridRef?: GridStack;

    /**
     * The gristack component used in this webpagemenu.
     */
    @ViewChild(GridstackComponent)
    public gridComp?: GridstackComponent;

    /**
     * Subject for notifying when to remember the state of the menu.
     */
    private remembersubject = new Subject();

    constructor(private webpagemenues: WebpagemenuService, private state: StateService) {}

    /**
     * Confugration of the gridstack object.
     */
    public gridOptions: NgGridStackOptions = {
        sizeToContent: true,
        cellHeightThrottle: 0,
        animate: true,
        margin: 5,
        itemClass: 'tt-webpagemenu-group',
        cellHeight: '1.25rem',
        disableResize: true,
        float: false,
        staticGrid: true,
        columnOpts: {
            layout: 'list',
            breakpoints: [
                { w: 700, c: 4 },
                { w: 1100, c: 8 },
            ],
        },
    };

    /**
     *
     * Retrieves all menu groups as menu-grid widet configurations.
     *
     * @returns list of menu group widgets to be displayed in this webpage menu.
     */
    private getMenuGroupWidgets(state: WebpagemenuGroupWidgetState[] | null) {
        let menuGroupWidgets = [...this.menuGroups.map((group) => this.createWebpageGroupWidget(group, state)), this.createWebpageGroupWidget()]
            .filter((node) => node.input?.['ttMenuGroup'].items.length > 0)
            .sort((a, b) => {
                const sortA = a.input.ttMenuGroup.group?.sortorder;
                const sortB = b.input.ttMenuGroup.group?.sortorder;

                if (sortA === undefined) return 1;
                if (sortB === undefined) return -1;

                return parseFloat(sortA) - parseFloat(sortB);
            });

        if (menuGroupWidgets.length === 1) {
            menuGroupWidgets[0].expandable = false;
        }

        return menuGroupWidgets;
    }

    /**
     * Creates a webpage-group widget configuration based on the given group. If not group is given the object created contain all other menu items which does not belong to a group.
     *
     * @param group the group to create a webpage-group widget for, if any.
     * @returns a webpage-group widget for the given group, or a miscellanious group if no menu group was given.
     */
    private createWebpageGroupWidget(group?: MenuGroup, states?: WebpagemenuGroupWidgetState[] | null): WebpagemenuGroupWidget {
        const id = `${this.ttName}.${group?.menugrp_keyno ?? '0'}`;
        const lastState = states?.find((state) => state.id === id);

        return <WebpagemenuGroupWidget>{
            id: id,
            w: 4,
            expanded: lastState?.expanded ?? true,
            expandable: true,
            sizeToContent: true,
            selector: 'tt-webpagemenu-group-widget',
            input: { ttMenuGroup: { group: group, items: this.getMenuItemsForGroup(group), api: () => this.gridRef, element: () => this.gridComp?.el, stateChange: () => this.remembersubject } },
        };
    }

    selectedWidget: string = '';

    /**
     * **Expects that `menuItems` is loaded in this webpagemenu.** \
     * \
     * Retrieves all the menu-items of this webpagemenu belonging to the given webpage group. If no group is given, than a list of
     * all menu items not belonging to any group is returned.
     *
     * @param group the group to find menu-items for in this webpagemenu.
     * @returns a list of menu-items from this webpagemenu belonging to the given menu-group if any.
     */
    private getMenuItemsForGroup(group?: MenuGroup): MenuItem[] {
        return this.menuItems.filter((item) => item.menugrp_keyno === (group?.menugrp_keyno ?? '0')).sort((a, b) => parseFloat(a.orderby) - parseFloat(b.orderby));
    }
    public editable = false;
    public editing = false;

    private async loadWebpagemneuEdit() {
        this.editable = (await this.webpagemenues.getWebpagemenuEdit())?.editable === '1';
    }

    /**
     * Loads menu items to this webpagemenu.
     */
    private async loadMenuItems() {
        this.menuItems = await this.webpagemenues.getWebpagemenuItems(this.ttName);
    }

    /**
     * Loads menu groups to this webpagemenu.
     */
    private async loadMenuGroups() {
        this.menuGroups = await this.webpagemenues.getWebpagemenuGroups();
    }

    /**
     * Loads all data to this webpagemenu instance.
     */
    private async loadWebpagemenu() {
        if (!!this.ttName && typeof this.ttName === 'string') {
            const result = await Promise.all([this.loadMenuGroups(), this.loadMenuItems(), this.retrieveLastState()]);

            if (!this.gridComp?.grid) return;

            this.remembersubject.pipe(debounceTime(500)).subscribe({ next: () => this.rememberState() });

            this.gridRef = this.gridComp.grid.load(this.getMenuGroupWidgets(result[2]));
            this.gridRef.onResize(document.body.clientWidth);

            setTimeout(() => (this.header = this.state.getCurrentStateHeader()), 50);
        }
    }

    private getState(): WebpagemenuGroupWidgetState[] {
        if (!this.gridRef) throw Error('Cannot get state when grid ref is undefined.');

        const nodes: WebpagemenuGroupWidget[] = this.gridRef.engine.nodes as WebpagemenuGroupWidget[];

        return nodes.map((node: WebpagemenuGroupWidget) => ({ id: node.id!, x: node.x, y: node.y, h: node.h, w: node.w, expanded: node.expanded })) as WebpagemenuGroupWidgetState[];
    }

    private rememberState() {
        this.webpagemenues.setWebpagemenuState(this.getState(), this.ttName);
    }

    private async retrieveLastState(): Promise<WebpagemenuGroupWidgetState[] | null> {
        return this.webpagemenues.getWebpagemenuState(this.ttName);
    }

    ngOnInit(): void {
        this.loadWebpagemneuEdit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttName'] && changes['ttName'].currentValue !== changes['ttName'].previousValue && changes['ttName'].firstChange === false) {
            this.loadWebpagemenu();
        }
    }

    ngAfterViewInit(): void {
        this.loadWebpagemenu();
    }
}

export interface WebpagemenuGroupWidgetState {
    id: string;
    x?: number;
    y?: number;
    h?: number;
    w?: number;
    expanded: boolean;
}
