import imApp from '../app.module.ajs';
import { BjsTestComponent } from '@app/views/components/bjs-test/bjs-test.component';
import { MainMenuComponent } from '@app/views/components/mainmenu/mainmenu.component';
//import { KlnTestComponent } from "../../app/views/kln-test/kln-test.component";
import { JlrTestComponent } from '@app/views/components/jlr-test/jlr-test.component';
import { SirvSetupComponent } from '@app/views/components/sirv-setup/sirv-setup.component';
import { PictureBankComponent } from '@app/views/components/picture-bank/picture-bank.component';
import { PictureBanksComponent } from '@app/views/components/picture-banks/picture-banks.component';
import { StudentTestComponent } from '@app/views/components/student-test/student-test.component';
import { BankReconciliationComponent } from '@app/views/components/bank-reconciliation/bank-reconciliation.component';
import { WebpagemenuComponent } from '@app/core/components/webpagemenu/webpagemenu.component';
import { TranslateEditComponent } from '@app/core/components/translate-edit/translate-edit.component';
import { MyActivitiesComponent } from '@app/views/components/my-activities/my-activities.component';
import { EconomyMenuComponent } from '@app/views/components/economy-menu/economy-menu.component';
import { ProductionMenuComponent } from '@app/views/components/production-menu/production-menu.component';
import { CrmMenuComponent } from '@app/views/components/crm-menu/crm-menu.component';
import { ConsignmentMenuComponent } from '@app/views/components/consignment-menu/consignment-menu.component';
import { StockMenuComponent } from '@app/views/components/stock-menu/stock-menu.component';
import { ProductMenuComponent } from '@app/views/components/product-menu/product-menu.component';
import { PurchaseMenuComponent } from '@app/views/components/purchase-menu/purchase-menu.component';
import { EcommerceMenuComponent } from '@app/views/components/ecommerce-menu/ecommerce-menu.component';
import { TimestampMenuComponent } from '@app/views/components/timestamp-menu/timestamp-menu.component';
import { ExternalProductionMenuComponent } from '@app/views/components/external-production-menu/external-production-menu.component';
import { ToolsMenuComponent } from '@app/views/components/tools-menu/tools-menu.component';
import { DocarticleMenuComponent } from '@app/views/components/docarticle-menu/docarticle-menu.component';
import { MyPageComponent } from '@app/views/components/my-page/my-page.component';
import { DevelopMenuComponent } from '@app/views/components/develop-menu/develop-menu.component';
import { HrMenuComponent } from '@app/views/components/hr-menu/hr-menu.component';
import { BusinesscoSettingSmtpComponent } from '@app/views/components/businessco-setting-smtp/businessco-setting-smtp.component';
import { P2DatataskLogsComponent } from '@app/views/components/p2-datatask-logs/p2-datatask-logs.component';
import { P2DatataskLogComponent } from '@app/views/components/p2-datatask-log/p2-datatask-log.component';
import { P2UpdateScriptEditViewComponent } from '@app/views/components/p2-update-script-edit-view/p2-update-script-edit-view.component';
import { P2UpdateScriptsComponent } from '@app/views/components/p2-update-scripts/p2-update-scripts.component';
import { DispatchPlanningComponent } from '@app/views/components/dispatch-planning/dispatch-planning.component';
import { DeviationSettingsComponent } from '@app/views/components/deviation-settings/deviation-settings.component';
import { DeviationTypeViewComponent } from '@app/views/components/deviation-type-view/deviation-type-view.component';
import { DeviationSourceViewComponent } from '@app/views/components/deviation-source-view/deviation-source-view.component';
import { DeviationSource2ViewComponent } from '@app/views/components/deviation-source2-view/deviation-source2-view.component';
import { ProductSettingMcItemComponent } from '@app/views/components/product-setting-mc-item/product-setting-mc-item.component';
import { AccountingBsComponent } from '@app/views/components/accounting-bs/accounting-bs.component';
import { ConsignmentPlansDeliveryComponent } from '@app/views/components/consignment-plans-delivery/consignment-plans-delivery.component';
// import { SettingsComponent } from "@app/views/components/settings/settings.component";
import { HtmlReportComponent } from '@app/core/components/html-report/html-report.component';
import { SaftExportComponent } from '@app/views/components/saft-export/saft-export.component';

(function () {
    'use strict';

    imApp.config([
        '$stateProvider',
        '$urlRouterProvider',
        '$locationProvider',
        'appConfigProvider',
        'utilityServiceProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider, appConfigProvider, utilityServiceProvider) {
            $locationProvider.hashPrefix('');
            //$locationProvider.html5Mode(true);
            //$locationProvider.html5Mode({
            //    enabled: false,
            //    requireBase: false,
            //    rewriteLinks: true
            //});

            $urlRouterProvider.otherwise('/mainmenu');

            var appConfig = appConfigProvider.$get();
            var us = utilityServiceProvider.$get();

            var version = '?v=' + appConfig.version;

            // BJS 20220707 - Adding dynamic routes from database. These are retrieved in module.js
            //                before bootstrapping the application.
            angular.forEach(appConfig.appRoutes, function (route) {
                var state = {
                    url: route.path,
                    component: route.component,
                };

                // route_id is used with generic components to differentiate
                // between implementations
                if (us.isStringValue(route.routeId, true)) {
                    state.resolve = {
                        route: function () {
                            return route.routeId;
                        },
                    };
                }

                $stateProvider.state(route.id, state);
            });

            $stateProvider
                .state('saftexport', {
                    url: '/saftexport',
                    component: 'saftExportComponent',
                    //component: SaftExportComponent,
                })
                .state('tempstock', {
                    url: '/tempstock',
                    component: 'tempstock',
                })
                .state('bjstestview', {
                    url: '/bjstestview',
                    component: BjsTestComponent,
                })
                //.state('klntestview', {
                //    url: '/klntestview',
                //    component: KlnTestComponent
                //})
                .state('jlrtest', {
                    url: '/jlrtest',
                    component: JlrTestComponent,
                    // component: TranslateEditComponent,
                    // component: DeviationComponent,
                })
                .state('studenttest', {
                    url: '/studenttest',
                    component: StudentTestComponent,
                })
                .state('bankreconciliations', {
                    url: '/bankreconciliations',
                    component: 'bankReconciliations',
                })
                .state('tshtmlreport', {
                    url: '/tshtmlreport/:htmlreport_keyno',
                    component: HtmlReportComponent,
                })
                .state('wbpagemenutest', {
                    url: '/webpagemenutest',
                    component: WebpagemenuComponent,
                })
                .state('bankreconciliation', {
                    url: '/bankreconciliation/:businessco_bankaccount_keyno',
                    component: BankReconciliationComponent,
                })
                .state('bankreconciliationsbt', {
                    url: '/bankreconciliationsbt/:businessco_bankaccount_keyno',
                    component: 'bankReconciliationsBt',
                })
                .state('bankreconciliationsat', {
                    url: '/bankreconciliationsat/:businessco_bankaccount_keyno',
                    component: 'bankReconciliationsAt',
                })
                // .state('jlrtest', {
                //     url: '/jlrtest',
                //     component: 'bislabSetup',
                // })
                .state('bislabsetup', {
                    url: '/bislabsetup/:businessco_no',
                    component: 'bislabSetup',
                })
                .state('kpicomp', {
                    url: '/kpicomp/:kpicomp_keyno',
                    component: 'kpiComp',
                })
                .state('kpicomps', {
                    url: '/kpicomps',
                    component: 'kpiComps',
                })
                .state('collectioncalculation', {
                    url: '/collectioncalculation',
                    component: 'collectionCalculation',
                })
                .state('cuttingplan', {
                    url: '/cuttingplan/:cuttingplan_keyno',
                    component: 'cuttingPlan',
                })
                .state('cuttingplans', {
                    url: '/cuttingplans',
                    component: 'cuttingPlans',
                })
                .state('myactivities', {
                    url: '/myactivities',
                    // component: 'myActivities'
                    component: MyActivitiesComponent,
                })
                .state('gpximport', {
                    url: '/gpximport/:argtype/:argvalue',
                    component: 'gpximport',
                })
                .state('mapview3d', {
                    url: '/mapview3d/:argtype/:argvalue',
                    component: 'mapView3d',
                })
                .state('purchrequestcreate', {
                    url: '/purchrequestcreate/:purch_internal_no',
                    component: 'purchRequestCreate',
                })
                .state('activitymanager', {
                    url: '/activitymanager',
                    component: 'activityManager',
                })
                .state('customerbonus', {
                    url: '/customerbonus/:cust_no',
                    component: 'customerBonus',
                })
                .state('customerclaims', {
                    url: '/customerclaims/:cust_no',
                    component: 'customerClaims',
                })
                .state('prodtmsizestockplace', {
                    url: '/prodtmsizestockplace/:textilemodel_id',
                    component: 'prodTmSizeStockplace',
                })
                .state('mainmenu', {
                    url: '/mainmenu/:companyid/:userid/:pwd',
                    // component: 'mainMenu',
                    component: MainMenuComponent,
                    params: {
                        companyid: { squash: true, value: null },
                        userid: { squash: true, value: null },
                        pwd: { squash: true, value: null },
                    },
                    header: {
                        text: 'main_menu',
                        backButton: {
                            text: 'log_out',
                            visible: false,
                        },
                        homeButton: {
                            visible: false,
                        },
                    },
                })
                .state('carriersbuilder', {
                    url: '/carriersbuilder',
                    component: 'genericTerminalScanA',
                    resolve: {
                        route: function () {
                            return 'carriersbuilder';
                        },
                    },
                })
                .state('orderlinebackorders', {
                    url: '/orderlinebackorders/:backorder_only',
                    component: 'orderlineBackorders',
                })
                .state('ordershipmentstatus', {
                    url: '/ordershipmentstatus/:order_internal_no',
                    component: 'orderShipmentStatus',
                })
                .state('orderspecial', {
                    url: '/orderspecial',
                    component: 'orderSpecial',
                })
                .state('poshipmentplanningcontainers', {
                    url: '/poshipmentplanningcontainers/:poh_keyno',
                    component: 'poShipmentPlanningContainers',
                })
                //.state('printtest', {
                //    url: '/printtest',
                //    templateUrl: 'views/session/printtest.html' + version,
                //    component: 'mainMenu',
                //    header: {
                //        text: 'p2_m_printtest',
                //        backButton: {
                //            text: 'p2_m_developmenu',
                //            fallback: {
                //                state: 'developmenu'
                //            }
                //        }
                //    }
                //})
                .state('printtestclientdetails', {
                    url: '/printtestclientdetails/:printClientKeyno',
                    component: 'printTestClientDetails',
                    header: {
                        text: 'p2_m_printtestclientdetails',
                        backButton: {
                            text: 'p2_m_printtest',
                            fallback: {
                                state: 'printtest',
                            },
                        },
                    },
                })
                .state('printtestclientprinters', {
                    url: '/printtestclientprinters/:printClientKeyno/:printClientName',
                    component: 'printTestClientPrinters',
                    header: {
                        text: 'p2_m_printtestclientprinters',
                        backButton: {
                            text: 'p2_m_printtest',
                            fallback: {
                                state: 'printtest',
                            },
                        },
                    },
                })
                .state('printtestprinterdetails', {
                    url: '/printtestprinterdetails/:printClientKeyno/:printClientName/:printerName',
                    component: 'printTestPrinterDetails',
                    header: {
                        text: 'p2_m_printtestprinterdetails',
                        backButton: {
                            text: 'p2_m_printtestclientprinters',
                            fallback: {
                                state: 'printtestclientprinters',
                            },
                        },
                    },
                })
                .state('printtestprinters', {
                    url: '/printtestprinters/:reportKeyno',
                    component: 'printTestPrinters',
                    header: {
                        text: 'p2_m_printtestprinters',
                        backButton: {
                            text: 'p2_m_printtestreports',
                            fallback: {
                                state: 'printtestreports',
                            },
                        },
                    },
                })
                .state('printtestreports', {
                    url: '/printtestreports',
                    component: 'printTestReports',
                    header: {
                        text: 'p2_m_printtestreports',
                        backButton: {
                            text: 'p2_m_printtest',
                            fallback: {
                                state: 'printtest',
                            },
                        },
                    },
                })
                .state('textilesizes', {
                    url: '/textilesizes/',
                    component: 'textileSizes',
                })
                .state('timesheet', {
                    url: '/timesheet',
                    component: 'timeSheet',
                    header: {
                        text: 'p2_m_timeregister',
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })

                .state('timesheetdiary', {
                    url: '/timesheetdiary/:argtype/:argvalue',
                    component: 'timeSheetDiary',
                })
                .state('ordercustcom', {
                    url: '/ordercustcom/:ordercustcom_keyno',
                    component: 'orderCustCom',
                })
                .state('orderdeliveryinfo', {
                    url: '/orderdeliveryinfo/:argtype/:argvalue',
                    component: 'orderDeliveryInfo',
                })
                .state('orderdialog', {
                    url: '/orderdialog/:order_internal_no',
                    component: 'orderDialog',
                })
                .state('orderpriceupdate', {
                    url: '/orderpriceupdate/',
                    component: 'orderPriceUpdate',
                })
                .state('orderpurch', {
                    url: '/orderpurch/:order_internal_no',
                    component: 'orderPurch',
                })
                .state('orderpurchmenu', {
                    url: '/orderpurchmenu/:order_internal_no',
                    component: 'orderPurchMenu',
                })
                .state('oeemenu', {
                    url: '/oeemenu',
                    component: 'oeeMenu',
                })
                .state('oeestates', {
                    url: '/oeestates',
                    component: 'oeeStates',
                })
                .state('oeestateedit', {
                    url: '/oeestateedit/:oeestate_keyno',
                    component: 'oeeStateEdit',
                })
                .state('oeestate_equipments', {
                    url: '/oeestate_equipments/:argtype/:argvalue',
                    component: 'oeeStateEquipments',
                })
                .state('oeestate_equipment', {
                    url: '/oeestate_equipment/:oeestate_equipment_keyno',
                    component: 'oeeStateEquipment',
                })
                .state('oeestateremarks', {
                    url: '/oeestateremarks/:oeestate_keyno',
                    component: 'oeeStateRemarks',
                })
                .state('oeestateremark', {
                    url: '/oeestateremark/:oeestate_keyno/:oeestateremark_keyno',
                    component: 'oeeStateRemark',
                })
                .state('oeedatacurrent', {
                    url: '/oeedatacurrent',
                    component: 'oeeDataCurrent',
                })
                .state('oeedata', {
                    url: '/oeedata/:oeedata_keyno',
                    component: 'oeeData',
                })
                .state('oeedashboard', {
                    url: '/oeedashboard',
                    component: 'oeeDashBoard',
                })
                .state('oeestatelog', {
                    url: '/oeestatelog/:equipment_id',
                    component: 'oeeStateLog',
                })
                .state('prepickcreate', {
                    url: '/prepickcreate/:argtype/:argvalue',
                    component: 'prePickCreate',
                })
                .state('equipments', {
                    url: '/equipments',
                    component: 'equipments',
                })
                .state('equipmentedit', {
                    url: '/equipmentedit/:equipment_id',
                    component: 'equipmentEdit',
                })
                .state('batchdelivery_list', {
                    url: '/batchdelivery_list',
                    component: 'batchDeliveryList',
                })
                .state('batchdelcontrol', {
                    url: '/batchdelcontrol/:batchdelivery_keyno/:purch_internal_no',
                    component: 'batchDelControl',
                })
                .state('batchdelivery', {
                    url: '/batchdelivery/:batchdelivery_keyno',
                    component: 'batchDelivery',
                })
                .state('batchdelpurchline', {
                    url: '/batchdelpurchline/:batchdelpurchline_keyno',
                    component: 'batchDelPurchLine',
                })
                .state('batchdelpurchlinesb', {
                    url: '/batchdelpurchlinesb/:batchdelpurchlinesb_keyno',
                    component: 'batchDelPurchLinesB',
                })
                .state('batchdelpurchlinesbs', {
                    url: '/batchdelpurchlinesbs/:batchdelpurchline_keyno/:batchdelivery_keyno',
                    component: 'batchDelPurchLinesBs',
                })
                .state('batchdeliveryadd', {
                    url: '/batchdeliveryadd/:batchdelivery_keyno',
                    component: 'batchDeliveryAdd',
                })
                .state('batchdelivery_addpurchline', {
                    url: '/batchdelivery_addpurchline/:supplier_no/:batchdelivery_keyno/:purch_internal_no',
                    component: 'batchDeliveryAddPurchLine',
                })
                .state('batchdelreceivelines', {
                    url: '/batchdelreceivelines/:batchdelivery_keyno/:purch_internal_no/:purchline_keyno/:prodsizecolli_keyno/:stocklocation_keyno/:controlled/:placed',
                    component: 'batchDelReceiveLines',
                })
                .state('batchdelreceiveline', {
                    url: '/batchdelreceiveline/:batchdelreceiveline_keyno',
                    component: 'batchDelReceiveLine',
                })
                .state('batchdelivery_select_purchhead', {
                    url: '/batchdelivery_select_purchhead/:supplier_no/:batchdelivery_keyno',
                    component: 'batchDeliverySelectPurchHead',
                })
                .state('bdscandetail', {
                    url: '/bdscandetail/:batchdelivery_keyno/:purch_internal_no/:purchline_keyno/:prodsizecolli_keyno',
                    component: 'bdScanDetail',
                })
                .state('bireports', {
                    url: '/bireports',
                    component: 'biReports',
                })
                .state('bireportembed', {
                    url: '/bireportembed/:bi_report_keyno',
                    component: 'biReportEmbed',
                })
                .state('bonustypesoverview', {
                    url: '/bonustypesoverview',
                    component: 'bonusTypesOverview',
                })
                .state('brreg', {
                    url: '/brreg',
                    component: 'brReg',
                })
                .state('brregview', {
                    url: '/brregview/:orgno/:resource_id',
                    component: 'brRegView',
                })
                .state('collidispatch', {
                    url: '/collidispatch',
                    component: 'colliDispatch',
                })
                .state('componentalternative', {
                    url: '/componentalternative/:prod_id/:comp_keyno/:componentalternative_keyno',
                    component: 'componentAlternative',
                })
                .state('componentalternatives', {
                    url: '/componentalternatives/:prod_id/:comp_keyno',
                    component: 'componentAlternatives',
                })
                .state('trstart', {
                    url: '/trstart',
                    component: 'timeRegisterStart',
                })
                .state('timestampsettings', {
                    url: '/timestampsettings',
                    component: 'timestampSettings',
                })
                .state('sizematrix', {
                    url: '/sizematrix',
                    component: 'sizematrix',
                })
                .state('stockbatchcorrqty', {
                    url: '/stockbatchcorrqty/:stockbatch_keyno',
                    component: 'stockBatchCorrQty',
                })
                .state('stockbatchsearch', {
                    url: '/stockbatchsearch',
                    component: 'stockBatchSearch',
                })
                .state('stockcorrcode', {
                    url: '/stockcorrcode/:stockcorrcode_keyno',
                    component: 'stockCorrCode',
                })
                .state('stockcorrcodelangs', {
                    url: '/stockcorrcodelangs/:stockcorrcode_keyno',
                    component: 'stockCorrCodeLangs',
                })
                .state('stockcorrcodes', {
                    url: '/stockcorrcodes',
                    component: 'stockCorrCodes',
                })
                .state('stockcorrections', {
                    url: '/stockcorrections/:argtype/:argvalue',
                    component: 'stockCorrections',
                })
                .state('stocklimits', {
                    url: '/stocklimits',
                    component: 'stockLimits',
                })
                .state('operations', {
                    url: '/operations',
                    component: 'operations',
                })
                .state('operation', {
                    url: '/operation/:operation_id',
                    component: 'operation',
                })
                .state('pojoblabours', {
                    url: '/pojoblabours/:argtype/:argvalue',
                    component: 'poJobLabours',
                })
                .state('pojobs', {
                    url: '/pojobs/:argtype/:argvalue',
                    component: 'poJobs',
                })
                .state('poworks', {
                    url: '/poworks/:argtype/:argvalue',
                    component: 'poWorks',
                })
                .state('productdataharvest', {
                    url: '/productdataharvest/:argtype/:argvalue',
                    component: 'productDataHarvest',
                })
                .state('tsmenu', {
                    url: '/tsmenu',
                    component: TimestampMenuComponent,
                    // component: 'tsMenu',
                    header: {
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })

                .state('fossproduction', {
                    url: '/fossproduction/:gridpage_keyno/:p2_datatask_keyno/:eventid/:reportnumber/:proc_parm1/:proc_parm2/:proc_parm3',
                    component: 'fossProduction',
                })
                .state('orderform', {
                    url: '/orderform/:order_internal_no',
                    component: 'orderForm',
                })
                .state('orderhead', {
                    url: '/orderhead/:order_internal_no',
                    component: 'orderhead',
                    title: '',
                })
                .state('payouts', {
                    url: '/payouts',
                    component: 'payouts',
                })
                .state('jobqueue', {
                    url: '/jobqueue/:argtype/:argvalue',
                    component: 'jobqueue',
                    params: {
                        argtype: { squash: true, value: null },
                        argvalue: { squash: true, value: null },
                    },
                })

                .state('jobqueuedetails', {
                    url: '/jobqueuedetails/:jobqueue_keyno',
                    component: 'jobqueueDetails',
                })

                // BJS 20220512
                .state('shipment', {
                    url: '/shipment/:shipmentcontainer_keyno',
                    component: 'shipment',
                    params: {
                        shipmentcontainer_keyno: { squash: true, value: null },
                    },
                })

                .state('textilemodels', {
                    url: '/textilemodels',
                    component: 'textileModels',
                })

                .state('textilemodel', {
                    url: '/textilemodel/:textilemodel_id',
                    component: 'textileModel',
                })

                .state('edisetup', {
                    url: '/edisetup/:argtype/:argvalue/:new_customer',
                    component: 'ediSetup',
                    params: {
                        argtype: { squash: false },
                        argvalue: { squash: false },
                        new_customer: { squash: true, value: null },
                    },
                })

                .state('about', {
                    url: '/about',
                    component: 'about',
                })

                // not in use?
                .state('campaigns', {
                    url: '/campaigns',
                    component: 'campaigns',
                })

                // not in use?
                .state('campaignedit', {
                    url: '/campaignedit/:campaign_keyno',
                    component: 'campaignEdit',
                })

                // not in use?
                .state('campaignproducts', {
                    url: '/campaignproducts/:campaign_keyno',
                    component: 'campaignProducts',
                })

                .state('captaskplans', {
                    url: '/captaskplans/:argtype/:argvalue',
                    component: 'capTaskPlans',
                })

                .state('captaskresource', {
                    url: '/captaskresource/:captaskresource_keyno',
                    component: 'capTaskResource',
                })
                .state('confirmationterminalscan', {
                    url: '/confirmationterminalscan/:mode',
                    component: 'confirmationTerminalScan',
                })
                .state('deliverymethodlangs', {
                    url: '/deliverymethodlangs/:deliverymethod_no',
                    component: 'deliveryMethodLangs',
                })
                .state('factories', {
                    url: '/factories',
                    component: 'factories',
                })
                .state('factoring', {
                    url: '/factoring/:factoring_no',
                    component: 'factoring',
                })
                .state('factorings', {
                    url: '/factorings',
                    component: 'factorings',
                })
                .state('factory', {
                    url: '/factory/:factory_id',
                    component: 'factory',
                })

                .state('factorymenu', {
                    url: '/factorymenu/:factory_id',
                    component: 'factoryMenu',
                })

                .state('operationfactories', {
                    url: '/operationfactories/:argtype/:argvalue',
                    component: 'operationFactories',
                })

                .state('operationfactory', {
                    url: '/operationfactory/:operationfactory_keyno',
                    component: 'operationFactory',
                })

                .state('paperlessproduction', {
                    url: '/paperlessproduction',
                    component: 'paperLessProduction',
                })
                .state('pickjobgrpbatch', {
                    url: '/pickjobgrpbatch/:stocktransfer_keyno',
                    component: 'pickJobGrpBatch',
                })
                .state('pickjobgrpbatchaddo', {
                    url: '/pickjobgrpbatchaddo/:stocktransfer_keyno',
                    component: 'pickJobGrpBatchAddO',
                })
                .state('pickjobgrpbatchaddr', {
                    url: '/pickjobgrpbatchaddr/:stocktransfer_keyno',
                    component: 'pickJobGrpBatchAddr',
                })
                .state('pickjobgrpbatches', {
                    url: '/pickjobgrpbatches',
                    component: 'pickJobGrpBatches',
                })
                .state('pickjobgrpbatchespicker', {
                    url: '/pickjobgrpbatchespicker',
                    component: 'pickJobGrpBatchesPicker',
                })
                .state('postaccrual', {
                    url: '/postaccrual/:acledger_keyno',
                    component: 'postAccrual',
                })
                .state('postplace', {
                    url: '/postplace/:postplace_id',
                    component: 'postPlace',
                })
                .state('postplaces', {
                    url: '/postplaces',
                    component: 'postPlaces',
                })
                .state('projectactivitytype', {
                    url: '/projectactivitytype/:projectactivitytype_keyno',
                    component: 'projectActivityType',
                })
                .state('projectactivitytypes', {
                    url: '/projectactivitytypes/:project_keyno',
                    component: 'projectActivityTypes',
                })
                .state('priceupdate', {
                    url: '/priceupdate',
                    component: 'priceUpdate',
                })

                .state('searchlog', {
                    url: '/searchlog',
                    component: 'searchLog',
                })
                .state('stockplanningpo', {
                    url: '/stockplanningpo',
                    component: 'stockPlanningPo',
                })
                .state('stockreplenishmentdetail', {
                    url: '/stockreplenishmentdetail',
                    component: 'stockReplenishmentDetail',
                })
                .state('stockreplenishmentoverview', {
                    url: '/stockreplenishmentoverview',
                    component: 'stockReplenishmentOverview',
                })
                .state('stocktransferlog', {
                    url: '/stocktransferlog/:stocktransfer_keyno',
                    component: 'stockTransferLog',
                })
                .state('workgrp', {
                    url: '/workgrp/:workgrp_keyno',
                    component: 'workGrp',
                })

                .state('workgrpmenu', {
                    url: '/workgrpmenu/:workgrp_keyno',
                    component: 'workGrpMenu',
                })

                .state('workgrps', {
                    url: '/workgrps',
                    component: 'workGrps',
                })

                .state('orderheadcopy', {
                    url: '/orderheadcopy/:order_internal_no',
                    component: 'orderHeadCopy',
                })
                .state('orderheadmi', {
                    url: '/orderheadmi/:order_internal_no',
                    component: 'orderHeadMi',
                })
                .state('orderheads', {
                    url: '/orderheads/:argtype/:argvalue',
                    component: 'orderHeads',
                })
                .state('orderheadsetdisc', {
                    url: '/orderheadsetdisc/:order_internal_no',
                    component: 'orderHeadSetDisc',
                })
                .state('orderheadcreate', {
                    url: '/orderheadcreate/:argtype/:argvalue/:ordertype_id',
                    component: 'orderHeadCreate',
                })
                .state('orderheadcreateibpurch', {
                    url: '/orderheadcreateibpurch/:order_internal_no',
                    component: 'orderHeadCreateIbPurch',
                })
                .state('orderheadcalcs', {
                    url: '/orderheadcalcs/:order_internal_no',
                    component: 'orderHeadCalcs',
                })
                .state('orderheaddeleteol', {
                    url: '/orderheaddeleteol/:order_internal_no',
                    component: 'orderHeadDeleteOl',
                })
                .state('ordertoconsignments', {
                    url: '/ordertoconsignments/:order_internal_no/:custact_no_consignmentplan',
                    component: 'orderToConsignments',
                })
                .state('selectglyphicon', {
                    url: '/selectglyphicon',
                    component: 'selectGlyphicon',
                })
                .state('selectproject', {
                    url: '/selectproject/:argtype/:argvalue',
                    component: 'selectProject',
                })
                .state('projects', {
                    url: '/projects/:argtype/:argvalue',
                    component: 'projects',
                })
                .state('project', {
                    url: '/project/:project_keyno',
                    component: 'project',
                })
                .state('projectmenu', {
                    url: '/projectmenu/:project_keyno',
                    component: 'projectMenu',
                    title: '',
                })
                .state('projectprocesses', {
                    url: '/projectprocesses/:project_keyno',
                    component: 'projectProcesses',
                })
                .state('projectprocess', {
                    url: '/projectprocess/:project_keyno/:projectprosess_keyno',
                    component: 'projectProcess',
                })
                .state('invoicecustomerbonus', {
                    url: '/invoicecustomerbonus',
                    component: 'invoiceCustomerBonus',
                })
                .state('invoicemakemulti', {
                    url: '/invoicemakemulti/:businessco_no/:invoicemethod_no/:invoice_valuta_id',
                    component: 'invoiceMakeMulti',
                })
                .state('invoicemakemultiorders', {
                    url: '/invoicemakemultiorders/:cust_no/:invoicemethod_no/:businessco_no',
                    component: 'invoiceMakeMultiOrders',
                })
                .state('invoicemakeoverview', {
                    url: '/invoicemakeoverview',
                    component: 'invoiceMakeOverview',
                })
                .state('invoicemethod', {
                    url: '/invoicemethod/:invoicemethod_no',
                    component: 'invoiceMethod',
                })
                .state('invoicemethodlangs', {
                    url: '/invoicemethodlangs/:invoicemethod_no',
                    component: 'invoiceMethodLangs',
                })
                .state('invoicemethods', {
                    url: '/invoicemethods',
                    component: 'invoiceMethods',
                })
                .state('invoicehead', {
                    url: '/invoicehead/:invoice_no',
                    component: 'invoiceHead',
                })
                .state('invoiceheads', {
                    url: '/invoiceheads/:argtype/:argvalue',
                    component: 'invoiceHeads',
                })
                .state('invoices', {
                    url: '/invoices/:argtype/:argvalue',
                    params: {
                        argtype: { squash: true, value: null },
                        argvalue: { squash: true, value: null },
                    },
                    component: 'invoices',
                })
                .state('invoiceheadehf', {
                    url: '/invoiceheadehf/:invoice_no',
                    component: 'invoiceHeadEhf',
                })
                .state('p2exportprocmenu', {
                    url: '/p2exportprocmenu/:p2_exportproc_keyno',
                    component: 'p2ExportProcMenu',
                })
                .state('p2exportprocs', {
                    url: '/p2exportprocs',
                    component: 'p2ExportProcs',
                })
                .state('p2exportprocusergroups', {
                    url: '/p2exportprocusergroups/:p2_exportproc_keyno',
                    component: 'p2ExportProcUserGroups',
                })
                .state('p2exportproc', {
                    url: '/p2exportproc/:p2_exportproc_keyno',
                    component: 'p2ExportProc',
                })
                .state('prodbeomethodlangs', {
                    url: '/prodbeomethodlangs/:prodbeomethod_no',
                    component: 'prodBeoMethodLangs',
                })
                .state('prodbeomethods', {
                    url: '/prodbeomethods',
                    component: 'prodBeoMethods',
                })
                .state('productsearch', {
                    url: '/productsearch/:functionname',
                    component: 'productSearch',
                })
                .state('productcreate', {
                    url: '/productcreate/:argtype/:argvalue',
                    component: 'productCreate',
                })
                .state('productsetting', {
                    url: '/productsetting/:productsetting_keyno',
                    component: 'productSetting',
                })
                .state('productsettingmc', {
                    url: '/productsettingmc/:productsetting_keyno/:productsettingmc_keyno',
                    component: 'productSettingMc',
                })
                .state('productsettingmcitem', {
                    url: '/productsettingmcitem/:productsettingmc_keyno/:productsettingmcitem_keyno',
                    // component: 'productSettingMcItem',
                    component: ProductSettingMcItemComponent,
                })
                .state('productsettings', {
                    url: '/productsettings',
                    component: 'productSettings',
                })
                .state('productwaste', {
                    url: '/productwaste',
                    component: 'productWaste',
                })
                .state('csvexportarg', {
                    url: '/csvexportarg/:argtype/:argvalue/:p2_exportproc_keyno',
                    component: 'csvExportArg',
                })
                .state('csvexports', {
                    url: '/csvexports/:argtype/:argvalue',
                    component: 'csvExports',
                })
                .state('csvimport', {
                    url: '/csvimport',
                    component: 'csvImport',
                })
                .state('excelimport', {
                    url: '/excelimport',
                    component: 'excelImport',
                })
                .state('basecurrencies', {
                    url: '/basecurrencies',
                    component: 'baseCurrencies',
                })
                .state('currencytypes', {
                    url: '/currencytypes/:valuta_id',
                    component: 'currencyTypes',
                })
                .state('currencyexrates', {
                    url: '/currencyexrates/:valuta_id/:currencytype_keyno',
                    component: 'currencyExRates',
                })
                .state('currencyexrate', {
                    url: '/currencyexrate/:currencyexrate_keyno',
                    component: 'currencyExRate',
                })
                .state('currencyexratehist', {
                    url: '/currencyexratehist/:currencyexrate_keyno',
                    component: 'currencyExRateHist',
                });

            $stateProvider
                // NOT IN USE, AND SHOULD NOT BE IN USE JLR, SRS 09202024
                //.state('collectionmining', {
                //    url: '/collectionmining',
                //    component: 'collectionMining'
                //})
                .state('batchdeliveries', {
                    url: '/batchdeliveries/:argtype/:argvalue',
                    component: 'batchDeliveries',
                })
                .state('purchplanning', {
                    url: '/purchplanning',
                    component: 'purchPlanning',
                })
                .state('stockcalc', {
                    url: '/stockcalc',
                    component: 'stockCalc',
                })
                .state('stockminmaxadjust', {
                    url: '/stockminmaxadjust/:stock_keyno',
                    component: 'stockMinMaxAdjust',
                })
                .state('tailoraccess', {
                    url: '/tailoraccess/:tailoraccess_keyno',
                    component: 'tailorAccess',
                })
                .state('tailoraccessdata', {
                    url: '/tailoraccessdata/:tailoraccessdata_keyno/:tailoraccess_keyno',
                    component: 'tailorAccessData',
                })
                .state('tailoraccesses', {
                    url: '/tailoraccesses',
                    component: 'tailorAccesses',
                })
                .state('tailoraccesslang', {
                    url: '/tailoraccesslang/:tailoraccess_keyno',
                    component: 'tailorAccessLang',
                })
                .state('tailoraccessmenu', {
                    url: '/tailoraccessmenu/:tailoraccess_keyno',
                    component: 'tailorAccessMenu',
                })
                .state('deliveryorderstatus', {
                    url: '/deliveryorderstatus',
                    component: 'deliveryOrderStatus',
                })
                .state('invoicedstatus', {
                    url: '/invoicedstatus',
                    component: 'invoicedStatus',
                })
                .state('blkrequisition', {
                    url: '/blkrequisition/:counter',
                    component: 'blkRequisition',
                })
                .state('purchhead', {
                    url: '/purchhead/:purch_internal_no',
                    component: 'purchHead',
                    title: '',
                })
                .state('purchheads', {
                    url: '/purchheads/:argtype/:argvalue',
                    component: 'purchHeads',
                })
                .state('purchheadcreate', {
                    url: '/purchheadcreate/:argtype/:argvalue',
                    component: 'purchHeadCreate',
                })
                .state('purchline', {
                    url: '/purchline/:purch_internal_no/:purchline_keyno',
                    component: 'purchLine',
                })
                .state('stockvariants', {
                    url: '/stockvariants/:prod_id/:functionname',
                    component: 'stockVariants',
                })
                .state('stockplace', {
                    url: '/stockplace/:stockplace_id',
                    component: 'stockPlace',
                })
                .state('stockplaceAccounting', {
                    url: '/stockplaceAccounting',
                    component: 'stockplaceAccounting',
                })
                .state('stockplaces', {
                    url: '/stockplaces',
                    component: 'stockPlaces',
                })
                .state('stockplacedeltime', {
                    url: '/stockplacedeltime/:stockplacedeltime_keyno',
                    component: 'stockPlaceDelTime',
                })
                .state('stockplacedeltimes', {
                    url: '/stockplacedeltimes/:stockplace_id_to',
                    component: 'stockPlaceDelTimes',
                })
                .state('multidoc', {
                    url: '/multidoc/:doctype/:argtype/:argvalue',
                    component: 'multiDoc',
                })
                .state('msgcreate', {
                    url: '/msgcreate',
                    component: 'msgCreate',
                })
                .state('operationlangs', {
                    url: '/operationlangs/:operation_id',
                    component: 'operationLangs',
                })
                .state('orderstatuses', {
                    url: '/orderstatuses',
                    component: 'orderStatuses',
                })
                .state('orderstatuslangs', {
                    url: '/orderstatuslangs/:orderstatus_id',
                    component: 'orderStatusLangs',
                })
                .state('checkinmenu', {
                    url: '/checkinmenu/:argtype/:argvalue',
                    component: 'checkInMenu',
                })
                .state('checkinstatus', {
                    url: '/checkinstatus/:argtype/:argvalue/:viewtype',
                    component: 'checkInStatus',
                })
                .state('checkin', {
                    url: '/checkin/:argtype/:argvalue/:checkin_keyno',
                    component: 'checkIn',
                })
                .state('checkpointheads', {
                    url: '/checkpointheads/:argtype/:argvalue',
                    component: 'checkPointHeads',
                })
                .state('checkpoints', {
                    url: '/checkpoints/:argtype/:argvalue',
                    component: 'checkPoints',
                })
                .state('trmenu', {
                    url: '/trmenu/:labour_no',
                    component: 'timeRegisterMenu',
                })
                .state('labelprint', {
                    url: '/labelprint/:argtype/:argvalue',
                    component: 'labelPrint',
                })
                .state('labourjobnote', {
                    url: '/labourjobnote/:labour_no',
                    component: 'labourJobNote',
                })
                .state('holidaysview', {
                    url: '/holidaysview',
                    component: 'holidaysView',
                })
                .state('holidays', {
                    url: '/holidays/:year/:country_id',
                    component: 'holidays',
                })
                .state('holiday', {
                    url: '/holiday/:p2_holyday_keyno/:country_id',
                    component: 'holiday',
                })
                .state('shifts', {
                    url: '/shifts',
                    component: 'shifts',
                })
                .state('shift', {
                    url: '/shift/:shift_id',
                    component: 'shift',
                })
                .state('shiftday', {
                    url: '/shiftday/:shift_id/:dayno',
                    component: 'shiftDay',
                })
                .state('shiftdaytime', {
                    url: '/shiftdaytime/:shifttime_keyno/:shift_id/:dayno',
                    component: 'shiftDayTime',
                })
                .state('shiftdaybreak', {
                    url: '/shiftdaybreak/:shiftbreak_keyno/:shift_id/:dayno',
                    component: 'shiftDayBreak',
                })
                .state('shiftview', {
                    url: '/shiftview/:labour_no',
                    component: 'shiftView',
                })
                .state('shiftplans', {
                    url: '/shiftplans',
                    component: 'shiftPlans',
                })
                .state('shiftplan', {
                    url: '/shiftplan/:shiftplan_id',
                    component: 'shiftPlan',
                })
                .state('timesheets', {
                    url: '/timesheets/:labour_no',
                    component: 'timeSheets',
                })
                .state('timesheets_status', {
                    url: '/timesheets_status',
                    component: 'timeSheetsStatus',
                })
                .state('timesheet_powtimelist', {
                    url: '/timesheet_powtimelist/:labour_no/:dateselector',
                    component: 'timeSheetPowTimeList',
                })
                .state('p2offdutydatas', {
                    url: '/p2offdutydatas',
                    component: 'p2OffDutyDatas',
                })
                .state('p2offdutydata', {
                    url: '/p2offdutydata/:p2_offdutydata_keyno/:labour_no',
                    component: 'p2OffDutyData',
                })
                .state('trnewjob', {
                    url: '/trnewjob/:labour_no',
                    component: 'timeRegisterNewJob',
                })
                .state('selectshift', {
                    url: '/selectshift/:labour_no',
                    component: 'selectShift',
                })
                .state('trnewjobselect_op', {
                    url: '/trnewjobselect_op/:labour_no',
                    component: 'timeRegisterNewJobSelectOp',
                })
                .state('trnewjobconf', {
                    url: '/trnewjobconf/:labour_no/:argtype/:argvalue',
                    component: 'timeRegisterNewJobConf',
                })
                .state('webpagesettingmenu', {
                    url: '/webpagesettingmenu/:webpage_name',
                    component: 'webpageSettingMenu',
                })
                .state('workingorders', {
                    url: '/workingorders/:ok',
                    component: 'workingOrders',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'timesheet',
                            },
                        },
                    },
                })
                .state('workdetails', {
                    url: '/workdetails/:custact_no',
                    component: 'workDetails',
                    title: '',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'workingorders',
                                parms: { ok: 'L' },
                            },
                        },
                    },
                })
                .state('work', {
                    url: '/work/:prod_id/:work_keyno',
                    component: 'work',
                })
                .state('prepayments', {
                    url: '/prepayments/:argtype/:argvalue',
                    component: 'prePayments',
                })
                .state('paycheck', {
                    url: '/paycheck/:paycheck_keyno',
                    component: 'payCheck',
                })
                .state('paycheckbatch', {
                    url: '/paycheckbatch/:paycheckbatch_keyno',
                    component: 'payCheckBatch',
                })
                .state('paycheckbatches', {
                    url: '/paycheckbatches',
                    component: 'payCheckBatches',
                })
                .state('payment', {
                    url: '/payment/:payment_id',
                    component: 'payment',
                })
                .state('payments', {
                    url: '/payments',
                    component: 'payments',
                })
                .state('prepayment', {
                    url: '/prepayment/:argtype/:argvalue/:prepayment_keyno',
                    component: 'prePayment',
                })
                .state('ftpaccount', {
                    url: '/ftpaccount/:ftpaccount_keyno',
                    component: 'ftpAccount',
                })
                .state('ftpaccounts', {
                    url: '/ftpaccounts',
                    component: 'ftpAccounts',
                })
                .state('selectreport', {
                    url: '/selectreport/:webpage_name/:argtype/:argvalue',
                    component: 'selectReport',
                })
                .state('printarg', {
                    url: '/printarg/:webpage_name/:argtype/:argvalue/:p2_reportdef_keyno',
                    component: 'printArg',
                })
                .state('printdialogue', {
                    url: '/printdialogue/:webpage_name/:argtype/:argvalue/:p2_reportdef_keyno',
                    component: 'printDialogue',
                })
                .state('printpreview', {
                    url: '/printpreview/:argtype/:argvalue/:report_keyno/:printer_keyno',
                    component: 'printPreview',
                })
                .state('email', {
                    url: '/email',
                    component: 'email',
                })
                .state('emailaccountshared', {
                    url: '/emailaccountshared/:current_account_email',
                    component: 'emailAccountShared',
                })
                .state('emailaccountsharededit', {
                    url: '/emailaccountsharededit/:emailaccount_shared_keyno/:current_account_email',
                    component: 'emailAccountSharedEdit',
                })
                .state('emailsmarttags', {
                    url: '/emailsmarttags/:account_keyno/:isclick',
                    component: 'emailSmartTags',
                })
                .state('emailinbox', {
                    url: '/emailinbox/:account_keyno/:isclick',
                    component: 'emailInbox',
                })
                .state('emailadd', {
                    url: '/emailadd/:argtype/:argvalue/:functionname',
                    component: 'emailAdd',
                })
                .state('emailedit', {
                    url: '/emailedit/:message_id',
                    component: 'emailEdit',
                })
                .state('emailcreate', {
                    url: '/emailcreate/:webpage/:argtype/:argvalue',
                    component: 'emailCreate',
                })
                .state('emailtemplate', {
                    url: '/emailtemplate/:emailtemplate_keyno',
                    component: 'emailtemplate',
                })
                .state('emailtemplates', {
                    url: '/emailtemplates',
                    component: 'emailTemplates',
                })
                .state('emailview', {
                    url: '/emailview/:email_keyno',
                    component: 'emailView',
                })
                .state('facts', {
                    url: '/facts',
                    component: 'facts',
                })
                .state('factsedit', {
                    url: '/factsedit/:facts_id',
                    component: 'factsedit',
                })
                .state('persfacts', {
                    url: '/persfacts',
                    component: 'persfacts',
                })
                .state('persfactsedit', {
                    url: '/persfactsedit/:pers_facts_id',
                    component: 'persfactsedit',
                })
                .state('calculationcategory', {
                    url: '/calculationcategory/:calculationhead_keyno',
                    component: 'calculationCategory',
                })
                .state('calculationoverview', {
                    url: '/calculationoverview/:calculationseasonyear_keyno',
                    component: 'calculationOverview',
                })
                .state('calculationseasonyear', {
                    url: '/calculationseasonyear',
                    component: 'calculationSeasonyear',
                })
                .state('calculationtextilemodel', {
                    url: '/calculationtextilemodel/:calculationhead_keyno/:textilemodelcategory7_keyno',
                    component: 'calculationTextilemodel',
                })
                .state('calview', {
                    url: '/calview/:viewtype/:argtype/:argvalue',
                    component: 'calView',
                })
                .state('captasksview', {
                    url: '/captasksview/:argtype/:argvalue',
                    component: 'capTasksView',
                })
                .state('componentreplacements', {
                    url: '/componentreplacements',
                    component: 'componentReplacements',
                })
                .state('componentreplacement', {
                    url: '/componentreplacement/:bom_component_replace_keyno',
                    component: 'componentReplacement',
                })
                .state('custactplanning', {
                    url: '/custactplanning/:argtype/:argvalue',
                    component: 'custactPlanning',
                })
                .state('custactplanningedit', {
                    url: '/custactplanningedit/:custactplanning_keyno',
                    component: 'custactPlanningEdit',
                })
                .state('custactprocess', {
                    url: '/custactprocess/:custact_no',
                    component: 'custactProcess',
                })
                .state('custprodaccbcs', {
                    url: '/custprodaccbcs',
                    component: 'custprodaccbcs',
                })
                .state('pplanning', {
                    url: '/pplanning',
                    component: 'pPlanning',
                })
                .state('dndedit', {
                    url: '/dndedit',
                    component: 'dndEdit',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'testbhw',
                            },
                        },
                    },
                })
                .state('deliverymethods', {
                    url: '/deliverymethods',
                    component: 'deliveryMethods',
                })
                .state('deliverymethod', {
                    url: '/deliverymethod/:deliverymethod_no',
                    component: 'deliveryMethod',
                })
                .state('deliverymethodbusinessco', {
                    url: '/deliverymethodbusinessco/:deliverymethod_no',
                    component: 'deliveryMethodBusinessco',
                })
                .state('deliveryterm', {
                    url: '/deliveryterm/:deliveryterm_no',
                    component: 'deliveryTerm',
                })
                .state('deliverytermlangs', {
                    url: '/deliverytermlangs/:deliveryterm_no',
                    component: 'deliveryTermLangs',
                })
                .state('deliveryterms', {
                    url: '/deliveryterms',
                    component: 'deliveryTerms',
                })
                .state('freightrate', {
                    url: '/freightrate/:freightrate_keyno',
                    component: 'freightRate',
                })
                .state('freightratetype', {
                    url: '/freightratetype/:freightratetype_keyno',
                    component: 'freightRateType',
                })
                .state('freightratetypes', {
                    url: '/freightratetypes',
                    component: 'freightRateTypes',
                })
                .state('freightrateset', {
                    url: '/freightrateset/:freightrateset_keyno',
                    component: 'freightRateSet',
                })
                .state('freightratesetcreate', {
                    url: '/freightratesetcreate/:cust_no',
                    component: 'freightRateSetCreate',
                })
                .state('freightratesets', {
                    url: '/freightratesets/:cust_no',
                    component: 'freightRateSets',
                })
                .state('riskelements', {
                    url: '/riskelements/:argtype/:argvalue',
                    component: 'riskElements',
                })
                .state('riskelement', {
                    url: '/riskelement/:riskelement_keyno/:argtype/:argvalue',
                    component: 'riskElement',
                })
                .state('bsp', {
                    url: '/bsp/:bsp_keyno',
                    component: 'bsp',
                })
                .state('bspmenu', {
                    url: '/bspmenu/:bsp_keyno',
                    component: 'bspMenu',
                    title: '',
                })
                .state('bsps', {
                    url: '/bsps',
                    component: 'bsps',
                })
                .state('bspsetup', {
                    url: '/bspsetup',
                    component: 'bspSetup',
                })
                .state('bspsub', {
                    url: '/bspsub/:bspsub_keyno',
                    component: 'bspSub',
                })
                .state('bspug', {
                    url: '/bspug/:bsp_keyno/:bspug_keyno',
                    component: 'bspUg',
                })
                .state('checklist', {
                    url: '/checklist/:checklist_keyno',
                    component: 'checkList',
                })
                .state('checklists', {
                    url: '/checklists',
                    component: 'checkLists',
                })
                .state('checklistitem', {
                    url: '/checklistitem/:checklistitem_keyno/:checklist_keyno',
                    component: 'checkListItem',
                })
                .state('checklistitemcvs', {
                    url: '/checklistitemcvs/:checklistitem_keyno',
                    component: 'checkListItemCvs',
                })
                .state('checklistitemitems', {
                    url: '/checklistitemitems/:checklistitem_keyno',
                    component: 'checkListItemItems',
                })
                .state('checklistitemitem', {
                    url: '/checklistitemitem/:checklistitem_keyno/:checklistitemitem_keyno',
                    component: 'checkListItemItem',
                })
                .state('checklistitemtw', {
                    url: '/checklistitemtw/:argtype/:argvalue/:checklistitemtw_keyno',
                    component: 'checkListItemTw',
                })
                .state('checklistitemtws', {
                    url: '/checklistitemtws/:argtype/:argvalue',
                    component: 'checkListItemTws',
                })
                .state('checklistdatas', {
                    url: '/checklistdatas/:argtype/:argvalue',
                    component: 'checkListDatas',
                })
                .state('checklistdata', {
                    url: '/checklistdata/:checklistdata_keyno',
                    component: 'checkListData',
                })
                .state('selectchecklistadd', {
                    url: '/selectchecklistadd/:argtype/:argvalue',
                    component: 'selectCheckListAdd',
                })
                .state('timeregister', {
                    url: '/timeregister/:custact_no/:powtime_keyno',
                    component: 'timeRegister',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'workdetails',
                                parms: { custact_no: undefined },
                            },
                        },
                    },
                })
                .state('timeregistergraph', {
                    url: '/timeregistergraph',
                    component: 'timeRegisterGraph',
                    header: {
                        text: 'p2_m_timeregister_graph',
                        backButton: {
                            fallback: {
                                state: 'timesheet',
                            },
                        },
                    },
                })
                .state('timeregistersplit', {
                    url: '/timeregistersplit/:powtime_keyno',
                    component: 'timeRegisterSplit',
                })
                .state('webpagesetting', {
                    url: '/webpagesetting/:argtype/:argvalue',
                    component: 'webpageSetting', //,
                    //header: {
                    //    translate: function (params) {
                    //        return params.argtype === 'webpage_name';
                    //    },
                    //    backButton: {
                    //        fallback: {
                    //            state: { type: 'parm', source: 'argvalue' }
                    //        }
                    //    }
                    //}
                })
                .state('materialregister', {
                    url: '/materialregister/:custact_no/:powtime_keyno/:pkey/:materialtype',
                    component: 'materialRegister',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'workdetails',
                                parms: { custact_no: undefined },
                            },
                        },
                    },
                })
                .state('deviation', {
                    url: '/deviation/:argtype/:argvalue/:custact_no_deviation',
                    component: 'deviation',

                    //header: {
                    //    backButton: {
                    //        fallback: {
                    //            state: 'workdetails',
                    //            parms: { custact_no: undefined }
                    //        }
                    //    }
                    //}
                })
                .state('deviations', {
                    url: '/deviations/:argtype/:argvalue',
                    component: 'deviations',

                    header: {
                        backButton: {
                            fallback: {
                                state: 'workdetails',
                                parms: { custact_no: undefined },
                            },
                        },
                    },
                })
                .state('deviationsettings', {
                    url: '/deviationsettings',
                    component: DeviationSettingsComponent,
                })
                .state('deviationtype', {
                    url: '/deviationtype/:deviationtype_keyno',
                    component: DeviationTypeViewComponent,
                    // component: 'deviationType',
                })
                .state('deviationcause', {
                    url: '/deviationcause/:deviationsource_keyno',
                    component: DeviationSourceViewComponent,
                    // component: 'deviationType',
                })
                .state('deviationsource', {
                    url: '/deviationsource/:deviationsource2_keyno',
                    component: DeviationSource2ViewComponent,
                    // component: 'deviationType',
                })
                .state('activitytypedevtype', {
                    url: '/activitytypedevtype/:activitytypedevtype_keyno',
                    component: 'activityTypeDevType',
                })
                .state('activitytypedevtypes', {
                    url: '/activitytypedevtypes/:argtype/:argvalue',
                    component: 'activityTypeDevTypes',
                })
                .state('activitytypeactstatus', {
                    url: '/activitytypeactstatus/:activitytypeactstatus_keyno',
                    component: 'activityTypeActStatus',
                })
                .state('activitytypeactstatuses', {
                    url: '/activitytypeactstatuses/:argtype/:argvalue',
                    component: 'activityTypeActStatuses',
                })
                .state('actstatus', {
                    url: '/actstatus/:actstatus_keyno',
                    component: 'actStatus',
                })
                .state('actstatuses', {
                    url: '/actstatuses',
                    component: 'actStatuses',
                })
                .state('actstatusmenu', {
                    url: '/actstatusmenu/:actstatus_keyno',
                    component: 'actStatusMenu',
                })
                .state('actstatuslangs', {
                    url: '/actstatuslangs/:actstatus_keyno',
                    component: 'actStatusLangs',
                })
                .state('acvatcodedata', {
                    url: '/acvatcodedata/:acvatcode_keyno/:acvatcodedata_keyno',
                    component: 'acVatCodeData',
                })
                .state('acvatcode', {
                    url: '/acvatcode/:acvatcode_keyno',
                    component: 'acVatCode',
                })
                .state('acvatcodes', {
                    url: '/acvatcodes',
                    component: 'acVatCodes',
                })
                .state('account', {
                    url: '/account/:account_no/:businessco_no',
                    component: 'account',
                })
                .state('accountingbs', {
                    url: '/accountingbs',
                    // component: 'accountingBs',
                    component: AccountingBsComponent,
                })
                .state('accountingbunt', {
                    url: '/accountingbunt/:accountingbunt_keyno',
                    component: 'accountingBunt',
                })
                .state('accountingbunts', {
                    url: '/accountingbunts',
                    component: 'accountingBunts',
                })
                .state('accountingpl', {
                    url: '/accountingpl',
                    component: 'accountingPl',
                })
                .state('accountingprocessing', {
                    url: '/accountingprocessing/:custact_no',
                    component: 'accountingProcessing',
                })
                .state('accounts', {
                    url: '/accounts',
                    component: 'accounts',
                })
                .state('acreportlayoutline', {
                    url: '/acreportlayoutline/:acreportlayout_keyno/:acreportlayoutline_keyno',
                    component: 'acReportLayoutLine',
                })
                .state('acreportlayoutmenu', {
                    url: '/acreportlayoutmenu/:acreportlayout_keyno',
                    component: 'acReportLayoutMenu',
                })
                .state('acreportlayout', {
                    url: '/acreportlayout/:acreportlayout_keyno',
                    component: 'acReportLayout',
                })
                .state('acreportlayouts', {
                    url: '/acreportlayouts',
                    component: 'acReportLayouts',
                })
                .state('customerinvoicing', {
                    url: '/customerinvoicing/:argtype/:argvalue',
                    component: 'customerInvoicing',
                })
                .state('customerinvoicings', {
                    url: '/customerinvoicings',
                    component: 'customerInvoicings',
                })
                .state('dataminingtable', {
                    url: '/dataminingtable/:p2_statsource_keyno/:level_no',
                    component: 'dataMiningTable',
                })
                .state('dataimp', {
                    url: '/dataimp/:dataimp_keyno',
                    component: 'dataImp',
                })
                .state('dataimps', {
                    url: '/dataimps',
                    component: 'dataImps',
                })
                .state('dodataimps', {
                    url: '/dodataimps',
                    component: 'doDataImps',
                })
                .state('dodataimp', {
                    url: '/dodataimp/:dataimp_keyno',
                    component: 'doDataImp',
                })
                .state('dbstation', {
                    url: '/dbstation',
                    component: 'dbStation',
                })
                .state('equipmentgrp', {
                    url: '/equipmentgrp/:equipmentgrp_id',
                    component: 'equipmentGrp',
                })
                .state('equipmentgrplang', {
                    url: '/equipmentgrplang/:equipmentgrp_id',
                    component: 'equipmentGrpLang',
                })

                .state('equipmentgrpmenu', {
                    url: '/equipmentgrpmenu/:equipmentgrp_id',
                    component: 'equipmentGrpMenu',
                })

                .state('equipmentgrps', {
                    url: '/equipmentgrps',
                    component: 'equipmentGrps',
                })

                .state('equipmentlist', {
                    url: '/equipmentlist/:argtype/:argvalue',
                    component: 'equipmentList',
                })

                .state('factoringbunt', {
                    url: '/factoringbunt/:factoringbunt_keyno',
                    component: 'factoringBunt',
                })

                .state('factoringbunts', {
                    url: '/factoringbunts',
                    component: 'factoringBunts',
                })
                .state('furniturefabricscolor', {
                    url: '/furniturefabricscolor/:furniturefabrics_id/:furniturefabricscolor_id',
                    component: 'furnitureFabricsColor',
                })
                .state('furniturefabricscolors', {
                    url: '/furniturefabricscolors/:furniturefabrics_id',
                    component: 'furnitureFabricsColors',
                })
                .state('furniturefabricsmenu', {
                    url: '/furniturefabricsmenu/:furniturefabrics_id',
                    component: 'furnitureFabricsMenu',
                })
                .state('metacolumn', {
                    url: '/metacolumn/:metatable_keyno/:metacolumn_keyno',
                    component: 'metaColumn',
                })
                .state('metacolumns', {
                    url: '/metacolumns/:metatable_keyno',
                    component: 'metaColumns',
                })
                .state('metatable', {
                    url: '/metatable/:metatable_keyno',
                    component: 'metaTable',
                })
                .state('metatabledata', {
                    url: '/metatabledata/:metatable_keyno/:metatableview_keyno',
                    component: 'metaTableData',
                })
                .state('metatabledatadetail', {
                    url: '/metatabledatadetail/:metatable_keyno/:pkey',
                    component: 'metaTableDataDetail',
                })
                .state('metatabledatas', {
                    url: '/metatabledatas',
                    component: 'metaTableDatas',
                })
                .state('metatablemenu', {
                    url: '/metatablemenu/:metatable_keyno',
                    component: 'metaTableMenu',
                })
                .state('metatables', {
                    url: '/metatables',
                    component: 'metaTables',
                })
                .state('operationequipments', {
                    url: '/operationequipments/:argtype/:argvalue',
                    component: 'operationEquipments',
                })
                .state('metatableview', {
                    url: '/metatableview/:metatable_keyno/:metatableview_keyno',
                    component: 'metaTableView',
                })
                .state('metatableviews', {
                    url: '/metatableviews/:metatable_keyno',
                    component: 'metaTableViews',
                })
                .state('orderheadcapjob', {
                    url: '/orderheadcapjob/:order_internal_no',
                    component: 'orderHeadCapJob',
                })

                .state('operationmenu', {
                    url: '/operationmenu/:operation_id',
                    component: 'operationMenu',
                })

                .state('pohtopop', {
                    url: '/pohtopop/:poh_keyno_top',
                    component: 'pohTopOp',
                })
                .state('popurchplanning', {
                    url: '/popurchplanning',
                    component: 'poPurchPlanning',
                })
                .state('pos', {
                    url: '/pos/:argtype/:argvalue',
                    component: 'pos',
                })
                .state('powtimes', {
                    url: '/powtimes/:argtype/:argvalue',
                    component: 'powTimes',
                })
                .state('producthazardlabels', {
                    url: '/producthazardlabels/:prod_id',
                    component: 'productHazardLabels',
                })
                .state('projectinvoicing', {
                    url: '/projectinvoicing/:project_keyno/:invoice_cust_no',
                    component: 'projectInvoicing',
                })
                .state('projectinvoicings', {
                    url: '/projectinvoicings',
                    component: 'projectInvoicings',
                })
                .state('purchpriceupdate', {
                    url: '/purchpriceupdate/:argtype/:argvalue',
                    component: 'purchPriceUpdate',
                })
                .state('deviationtypelangs', {
                    url: '/deviationtypelangs/:deviationtype_keyno',
                    component: 'deviationTypeLangs',
                })
                .state('deviationtypemenu', {
                    url: '/deviationtypemenu/:deviationtype_keyno',
                    component: 'deviationTypeMenu',
                })
                .state('deviationtypes', {
                    url: '/deviationtypes/:activitytype_no',
                    component: 'deviationTypes',
                })
                .state('custactedit', {
                    url: '/custactedit/:custact_no/:filterfield/:filtervalue',
                    component: 'custactEdit',

                    //header: {
                    //    backButton: {
                    //        fallback: {
                    //            state: 'workdetails',
                    //            parms: { custact_no: undefined }
                    //        }
                    //    }
                    //}
                })
                .state('custactdeadlines', {
                    url: '/custactdeadlines/:argtype/:argvalue',
                    component: 'custactDeadLines',
                })
                .state('activitytype', {
                    url: '/activitytype/:activitytype_no',
                    component: 'activityType',
                })
                .state('activitytypelangs', {
                    url: '/activitytypelangs/:activitytype_no',
                    component: 'activityTypeLangs',
                })
                .state('activitytypes', {
                    url: '/activitytypes',
                    component: 'activityTypes',
                })
                .state('powtimegroup', {
                    url: '/powtimegroup/:cust_no/:project_keyno/:projectprosess_keyno/:custact_no/:date_start/:date_end/:grouptype/:equipment_id/:labour_no/:prod_id',
                    component: 'powtimeGroup',
                    header: {
                        backButton: {
                            fallback: {
                                state: 'workdetails',
                                parms: { custact_no: undefined },
                            },
                        },
                    },
                })
                .state('powtimeapproval', {
                    url: '/powtimeapproval/:argtype/:argvalue',
                    component: 'powTimeApproval',
                })
                .state('powtimeapprovals', {
                    url: '/powtimeapprovals',
                    component: 'powTimeApprovals',
                })
                .state('powtimepaycheckls', {
                    url: '/powtimepaycheckls/:argtype/:argvalue',
                    component: 'powTimePayCheckLs',
                })
                .state('timeinouts', {
                    url: '/timeinouts/:argtype/:argvalue',
                    component: 'timeInOuts',
                })
                .state('custactcomp', {
                    url: '/custactcomp/:custact_no/:custactcomp_keyno',
                    component: 'custactComp',
                })
                .state('custactcomps', {
                    url: '/custactcomps/:custact_no',
                    component: 'custactComps',
                })
                .state('custactcompadd', {
                    //    url: '/custactcompadd/:custact_no/:custactcomp_keyno',
                    component: 'custactCompAdd',
                })
                .state('ordercustomerservice', {
                    url: '/ordercustomerservice/:order_internal_no',
                    component: 'orderCustomerService',
                })
                .state('orderline', {
                    url: '/orderline/:order_internal_no/:orderline_keyno',
                    component: 'orderLine',
                })
                .state('orderlinebom', {
                    url: '/orderlinebom/:orderline_keyno',
                    component: 'orderLineBom',
                })
                .state('orderlineedit', {
                    url: '/orderlineedit/:argtype/:argvalue/:orderline_keyno',
                    component: 'orderLineEdit',
                })
                .state('orderlinems', {
                    url: '/orderlinems/:argtype/:headno/:lineno',
                    component: 'orderLineMs',
                })
                .state('orderlineps', {
                    url: '/orderlineps/:argtype/:headno/:lineno/:selected_item_id',
                    component: 'orderLinePs',
                })
                .state('productsconfig', {
                    url: '/productsconfig/:argtype/:headno/:lineno',
                    component: 'productsConfig',
                })
                .state('productbom', {
                    url: '/productbom/:prod_id',
                    component: 'productBom',
                })
                .state('selectprodsize', {
                    url: '/selectprodsize/:webpage_name/:parm1/:parm2/:prod_id',
                    component: 'selectProdsize',
                })
                .state('selectprodchoice', {
                    url: '/selectprodchoice/:choice_keyno/:argtype/:argvalue',
                    component: 'selectProdChoice',
                })
                .state('postcalc', {
                    url: '/postcalc/:argtype/:argvalue',
                    component: 'postCalc',
                })
                .state('custactcalc', {
                    url: '/custactcalc/:argtype/:argvalue',
                    component: 'custactCalc',
                })
                .state('custactcalcadjustdisc', {
                    url: '/custactcalcadjustdisc/:custact_no',
                    component: 'custactCalcAdjustDisc',
                })
                .state('custactcalcedit', {
                    url: '/custactcalcedit/:custact_no',
                    component: 'custactCalcEdit',
                })
                .state('custactcalceditmodal', {
                    url: '/custactcalceditmodal/:custact_no',
                    component: 'custactCalcEditModal',
                })
                .state('custactcalcpick', {
                    url: '/custactcalcpick/:p2_pofinish_keyno/:custact_no',
                    component: 'custactCalcPick',
                })
                .state('custactcalcpickselect', {
                    url: '/custactcalcpickselect/:p2_pofinish_keyno/:custact_no',
                    component: 'custactCalcPickSelect',
                })
                .state('custactcalcjobexit', {
                    url: '/custactcalcjobexit/:labour_no/:custactcalc_custact_no/:function_name',
                    component: 'custactCalcJobExit',
                })
                .state('custactworks', {
                    url: '/custactworks/:custact_no',
                    component: 'custactWorks',
                })
                .state('custactwork', {
                    url: '/custactwork/:custact_no/:custactwork_keyno',
                    component: 'custactWork',
                })
                .state('custactmembers', {
                    url: '/custactmembers/:custact_no',
                    component: 'custactMembers',
                })
                .state('custactmemberedit', {
                    url: '/custactmemberedit/:custact_no/:custact_member_keyno',
                    component: 'custactMemberEdit',
                })
                .state('selectportaluser', {
                    url: '/selectportaluser/:webpage/:arg1',
                    component: 'selectPortalUser',
                })
                .state('custactdialogs', {
                    url: '/custactdialogs/:custact_no',
                    component: 'custactDialogs',
                })
                .state('subtasks', {
                    url: '/subtasks/:custact_no/:retrievetype/:include_top',
                    component: 'subtasks',
                })
                .state('custactgantt', {
                    url: '/custactgantt/:connectto/:connectto_value',
                    component: 'custactGantt',
                })
                .state('categorylistmain', {
                    url: '/categorylistmain',
                    component: 'categoryListMain',
                })
                .state('categorylinks', {
                    url: '/categorylinks/:category_keyno_belongto/:categorylink_keyno',
                    component: 'categoryLinks',
                })
                .state('categorylinkadd', {
                    url: '/categorylinkadd/:category_keyno_belongto',
                    component: 'categoryLinkAdd',
                })
                .state('categorydatas', {
                    url: '/categorydatas/:argtype/:argvalue',
                    component: 'categoryDatas',
                })
                .state('categorydataselect', {
                    url: '/categorydataselect/:argtype/:argvalue',
                    component: 'categoryDataSelect',
                })
                .state('subtaskselect', {
                    url: '/subtaskselect/:argtype/:argvalue/:custact_no_addto',
                    component: 'subtaskSelect',
                })
                .state('consignmentbatches', {
                    url: '/consignmentbatches',
                    component: 'consignmentBatches',
                })
                .state('consignmentbatchconsignments', {
                    url: '/consignmentbatchconsignments/:consignmentbatch_keyno',
                    component: 'consignmentBatchConsignments',
                })
                .state('docarticlemenu', {
                    url: '/docarticlemenu',
                    // component: 'docArticleMenu',
                    component: DocarticleMenuComponent,
                })
                .state('ohdirectdelivery', {
                    url: '/ohdirectdelivery/:order_internal_no',
                    component: 'ohDirectDelivery',
                })
                .state('ohdispatches', {
                    url: '/ohdispatches/:argtype/:argvalue',
                    component: 'ohDispatches',
                })
                .state('productionmenu', {
                    url: '/productionmenu',
                    // component: 'productionMenu',
                    component: ProductionMenuComponent,
                })

                .state('productionconfirmation', {
                    url: '/productionconfirmation',
                    component: 'productionConfirmation',
                })

                .state('epmenu', {
                    url: '/epmenu',
                    // component: 'epMenu',
                    component: ExternalProductionMenuComponent,
                })

                .state('epproductioncompletion', {
                    url: '/epproductioncompletion',
                    component: 'epProductionCompletion',
                })

                .state('epblockorders', {
                    url: '/epblockorders',
                    component: 'epBlockOrders',
                })

                .state('epblockordersfinished', {
                    url: '/epblockordersfinished',
                    component: 'epBlockOrdersFinished',
                })

                .state('epblockordersfinishedshipments', {
                    url: '/epblockordersfinishedshipments/:pohKeyno',
                    component: 'epBlockOrdersFinishedShipments',
                })

                .state('epblockorderedit', {
                    url: '/epblockorder/edit/:pohKeyno',
                    component: 'epBlockOrderEdit',
                })

                .state('epblockorderplan', {
                    url: '/epblockorder/plan/:pohKeyno',
                    component: 'epBlockOrderPlan',
                })

                .state('epshipmentprevious', {
                    url: '/epshipmentprevious',
                    component: 'epShipmentPrevious',
                })

                .state('epshipmentpreviousview', {
                    url: '/epshipmentpreviousview/:shipmentContainerKeyno',
                    component: 'epShipmentPreviousView',
                })

                .state('epshipmentcompletion', {
                    url: '/epshipmentcompletion',
                    component: 'epShipmentCompletion',
                })

                .state('epshipmentcompletionscan', {
                    url: '/epshipmentcompletionscan/:shipmentContainerKeyno',
                    component: 'epShipmentCompletionScan',
                })

                .state('epschenkeroverview', {
                    url: '/epschenkeroverview',
                    component: 'epSchenkerOverview',
                })

                .state('epschenkeroverviewgetedi', {
                    url: '/epschenkeroverviewgetedi/:shipmentContainerKeyno',
                    component: 'epSchenkerOverviewGetEdi',
                })

                .state('favorites', {
                    url: '/favorites',
                    component: 'favorites',
                })

                .state('productionstatus', {
                    url: '/productionstatus',
                    component: 'productionStatus',
                })
                .state('po', {
                    url: '/po/:po_keyno',
                    component: 'po',
                })
                .state('pocreate', {
                    url: '/pocreate/:stock_keyno',
                    component: 'poCreate',
                })
                .state('poh', {
                    url: '/poh/:poh_keyno',
                    component: 'poh',
                })
                .state('pohkeynotopstatus', {
                    url: '/pohkeynotopstatus/:poh_keyno_top',
                    component: 'pohKeynoTopStatus',
                })
                .state('pohtop', {
                    url: '/pohtop/:poh_keyno_top',
                    component: 'pohTop',
                })
                .state('pohtops', {
                    url: '/pohtops/:argtype/:argvalue',
                    component: 'pohTops',
                })
                .state('pickjobgrp', {
                    url: '/pickjobgrp/:stockplace_id/:pickjobgrp_keyno',
                    component: 'pickJobGrp',
                })
                .state('pickjobgrps', {
                    url: '/pickjobgrps/:stockplace_id',
                    component: 'pickJobGrps',
                })
                .state('prodsizes', {
                    url: '/prodsizes/:prod_id',
                    component: 'prodSizes',
                })
                .state('productionhtops', {
                    url: '/productionhtops/:argtype/:argvalue/:counter',
                    component: 'productionhTops',
                })

                .state('productionpo', {
                    url: '/productionpo', // /:argtype/:argvalue/:counter'
                    component: 'productionPo',
                })
                .state('stockplacesto', {
                    url: '/stockplacesto/:stockplace_id_from',
                    component: 'stockPlacesTo',
                })
                .state('vatreport', {
                    url: '/vatreport/:businessco_no/:accounting_year/:vatreport_keyno',
                    component: 'vatReport',
                })
                .state('vatreportmenu', {
                    url: '/vatreportmenu/:vatreport_keyno',
                    component: 'vatReportMenu',
                })
                .state('vatreports', {
                    url: '/vatreports',
                    component: 'vatReports',
                })
                .state('vatspesification', {
                    url: '/vatspesification',
                    component: 'vatSpesification',
                })
                .state('productionhtopscomponents', {
                    url: '/productionhtopscomponents/:argtype/:argvalue/:counter',
                    component: 'productionhTopsComponents',
                })
                .state('orderlinestatus', {
                    url: '/orderlinestatus/:order_internal_no',
                    component: 'orderlineStatus',
                })
                .state('webpagesettingedit', {
                    url: '/webpagesettingedit/:p2_webpage_setting_keyno',
                    component: 'webpageSettingEdit',
                })

                .state('consignmentmenu', {
                    url: '/consignmentmenu',
                    // component: 'consignmentMenu',
                    component: ConsignmentMenuComponent,
                })

                .state('consignmentdelivery', {
                    url: '/consignmentdelivery',
                    component: 'consignmentDelivery',
                })

                .state('consignmentdeliveryactivity', {
                    url: '/consignmentdeliveryactivity/:custact_no/:order_order_no',
                    component: 'consignmentDeliveryActivity',
                })

                .state('consignmentdeliveryconfirm', {
                    url: '/consignmentdeliveryconfirm/:checklistdata_keyno/:custact_no/:order_order_no',
                    component: 'consignmentDeliveryConfirm',
                })

                .state('toolsmenu', {
                    url: '/toolsmenu',
                    // component: 'toolsMenu',
                    component: ToolsMenuComponent,
                })
                .state('p2importprocs', {
                    url: '/p2importprocs',
                    component: 'p2ImportProcs',
                })
                .state('p2importproc', {
                    url: '/p2importproc/:p2_importproc_keyno',
                    component: 'p2ImportProc',
                })
                .state('purchasecreate', {
                    url: '/purchasecreate/:textilemodel_id',
                    component: 'purchaseCreate',
                })
                .state('purchasemenu', {
                    url: '/purchasemenu',
                    // component: 'purchaseMenu',
                    component: PurchaseMenuComponent,
                })
                .state('purchaseplanning', {
                    url: '/purchaseplanning',
                    component: 'purchasePlanning',
                })
                .state('purchaseplanningorderoverviewstyle', {
                    url: '/purchaseplanningorderoverviewstyle/:textilemodel_id',
                    component: 'purchasePlanningOrderOverviewStyle',
                })
                .state('deliverymethodservices', {
                    url: '/deliverymethodservices/:deliverymethod_no',
                    component: 'deliveryMethodServices',
                })
                .state('transcarrierserviceactive', {
                    url: '/transcarrierserviceactive/:transcarrier_service_active_keyno/:deliverymethod_no',
                    component: 'transcarrierServiceActive',
                })
                .state('carrier', {
                    url: '/carrier/:carrier_keyno',
                    component: 'carrier',
                })
                .state('carrierlist', {
                    url: '/carrierlist',
                    component: 'carrierList',
                })
                .state('carriers', {
                    url: '/carriers',
                    component: 'carriers',
                })
                .state('crmmenu', {
                    // SKAL DEFINERE NAMNET PÅ SIDA
                    url: '/crmmenu',
                    // component: 'crmMenu', // SKAL INFORMERE OM NAMNET PÅ COMPONENT HTML-SIDA ER BUNDEN TIL
                    component: CrmMenuComponent,
                    header: {
                        // SKAL DEFINERE FUNKSJONALITETEN I HEADER
                        backButton: {
                            // SKAL DEFINERE TILBAKE-KNAPPEN
                            text: 'main_menu', // SKAL DEKLARERE NAMNET PÅ TILBAKE-KNAPPEN
                            fallback: {
                                // FUNKSJON SOM SKAL DEKLARERE FØRRE SIDE I TILBAKE-KNAPPEN
                                state: 'mainmenu', // SKAL DEKLARERE NAMN PÅ FØRRE SIDE
                            },
                        },
                    },
                })
                .state('ecommercemenu', {
                    url: '/ecommercemenu',
                    component: EcommerceMenuComponent,
                    // component: 'eCommerceMenu',
                    header: {
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })
                .state('settings', {
                    url: '/settings',
                    component: 'settings',
                    // component: SettingsComponent,
                    header: {
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                });

            $stateProvider
                .state('diary', {
                    url: '/diary',
                    component: 'diary',
                })
                .state('subscription', {
                    url: '/subscription/:subscription_keyno',
                    component: 'subscription',
                })
                .state('subscriptiongrid', {
                    url: '/subscriptiongrid/:argtype/:argvalue',
                    component: 'subscriptionGrid',
                })
                .state('subscriptionline', {
                    url: '/subscriptionline/:subscription_keyno/:subscriptionline_keyno',
                    component: 'subscriptionLine',
                })
                .state('subscriptions', {
                    url: '/subscriptions',
                    component: 'subscriptions',
                })
                .state('business', {
                    url: '/business/:business_id',
                    component: 'business',
                })
                .state('businessco', {
                    url: '/businessco/:businessco_no',
                    component: 'businessCo',
                })
                .state('businessco_bankaccount', {
                    url: '/businessco_bankaccount/:businessco_no/:businessco_bankaccount_keyno',
                    component: 'businessCoBankAccount',
                })
                .state('businesscoaccounting', {
                    url: '/businesscoaccounting/:businessco_no',
                    component: 'businesscoaccounting',
                })
                .state('businesscoonboarding', {
                    url: '/businesscoonboarding',
                    component: 'businesscoOnBoarding',
                })
                .state('businesscos', {
                    url: '/businesscos',
                    component: 'businesscos',
                })
                .state('businesscosettings', {
                    url: '/businesscosettings/:businessco_no',
                    component: 'businesscoSettings',
                })
                .state('businesscosettingsmtp', {
                    url: '/businesscosettingsmtp/:businessco_no',
                    // component: 'businesscoSettingSmtp',
                    component: BusinesscoSettingSmtpComponent,
                })
                .state('businesscosettingcargonizer', {
                    url: '/businesscosettingcargonizer/:businessco_no',
                    component: 'businesscoSettingCargonizer',
                })
                .state('businesscosettingsvea', {
                    url: '/businesscosettingsvea/:businessco_no',
                    component: 'businesscoSettingSvea',
                })
                .state('businesslang', {
                    url: '/businesslang/:business_id',
                    component: 'businessLang',
                })
                .state('businessmenu', {
                    url: '/businessmenu/:business_id',
                    component: 'businessMenu',
                })
                .state('businesss', {
                    url: '/businesss',
                    component: 'businessS',
                })
                .state('businesssite', {
                    url: '/businesssite/:businesssite_keyno',
                    component: 'businessSite',
                })
                .state('businesssites', {
                    url: '/businesssites',
                    component: 'businessSites',
                })
                .state('cargonizerprinters', {
                    url: '/cargonizerprinters',
                    component: 'cargonizerPrinters',
                })
                .state('externalapis', {
                    url: '/externalapis',
                    component: 'externalApis',
                })
                .state('externalapi', {
                    url: '/externalapi/:external_api_keyno',
                    component: 'externalApi',
                })
                .state('poplans', {
                    url: '/poplans',
                    component: 'poPlans',
                })
                .state('poplansprod', {
                    url: '/poplansprod/:stock_keyno/:date_to',
                    component: 'poPlansProd',
                })
                .state('cargonizerprinteredit', {
                    url: '/cargonizerprinteredit/:cargonizer_printer_keyno',
                    component: 'cargonizerPrinterEdit',
                })
                .state('portaluserstockplace', {
                    url: '/portaluserstockplace/:portal_user_keyno',
                    component: 'portalUserStockplace',
                })
                .state('portalusersignature', {
                    url: '/portalusersignature/:portal_user_keyno',
                    component: 'portalUserSignature',
                })
                .state('choicepossrule', {
                    url: '/choicepossrule/:choicepossrule_keyno',
                    component: 'choicePossRule',
                })
                .state('choicepossrulesrun', {
                    url: '/choicepossrulesrun',
                    component: 'choicePossRulesRun',
                })
                .state('choicepossrules', {
                    url: '/choicepossrules/:argtype/:argvalue',
                    component: 'choicePossRules',
                })
                .state('choiceprodpricegrp', {
                    url: '/choiceprodpricegrp/:choiceprodpricegrp_keyono/:prod_id_choice',
                    component: 'choiceProdPriceGrp',
                })
                .state('choiceprodpricegrps', {
                    url: '/choiceprodpricegrps/:prod_id',
                    component: 'choiceProdPriceGrps',
                })
                .state('choice', {
                    url: '/choice/:choice_keyno',
                    component: 'choice',
                })
                .state('choicepossibilitylist', {
                    url: '/choicepossibilitylist/:choice_keyno',
                    component: 'choicePossibilityList',
                })
                .state('pricegrid', {
                    url: '/pricegrid',
                    component: 'priceGrid',
                })
                .state('priceindex', {
                    url: '/priceindex/:priceindex_keyno',
                    component: 'priceIndex',
                })
                .state('priceindexes', {
                    url: '/priceindexes',
                    component: 'priceIndexes',
                })
                .state('priceindexmenu', {
                    url: '/priceindexmenu/:priceindex_keyno',
                    component: 'priceIndexMenu',
                })
                .state('activitygrid', {
                    url: '/activitygrid/:argtype/:argvalue',
                    component: 'activityGrid',
                })
                .state('choices', {
                    url: '/choices/:prod_id',
                    component: 'choices',
                })
                .state('collection', {
                    url: '/collection/:collection_keyno',
                    component: 'collection',
                })
                .state('collectionlangs', {
                    url: '/collectionlangs/:collection_keyno',
                    component: 'collectionLangs',
                })
                .state('collectionmenu', {
                    url: '/collectionmenu/:collection_keyno',
                    component: 'collectionMenu',
                })
                .state('collections', {
                    url: '/collections',
                    component: 'collections',
                })
                .state('colorgrpmenu', {
                    url: '/colorgrpmenu/:colorgrp_id',
                    component: 'colorGrpMenu',
                })
                .state('colorgrp', {
                    url: '/colorgrp/:colorgrp_id',
                    component: 'colorGrp',
                })
                .state('colorgrps', {
                    url: '/colorgrps',
                    component: 'colorGrps',
                })
                .state('colorgrplang', {
                    url: '/colorgrplang/:colorgrp_id',
                    component: 'colorGrpLang',
                })
                .state('crmselection', {
                    url: '/crmselection',
                    component: 'crmselection',
                })
                .state('custactacmatch', {
                    url: '/custactacmatch/:custactac_keyno',
                    component: 'custactAcMatch',
                })
                .state('custactcomplaint', {
                    url: '/custactcomplaint/:custact_no',
                    component: 'custactComplaint',
                })
                .state('custactcomplaintline', {
                    url: '/custactcomplaintline/:custactproduct_keyno',
                    component: 'custactComplaintLine',
                })
                .state('custfacts', {
                    url: '/custfacts/:cust_no',
                    component: 'custfacts',
                })
                .state('edidestination', {
                    url: '/edidestination/:edi_destination_keyno',
                    component: 'ediDestination',
                })
                .state('edidestinations', {
                    url: '/edidestinations',
                    component: 'ediDestinations',
                })
                .state('ediexport', {
                    url: '/ediexport/:edi_export_keyno',
                    component: 'ediExport',
                })
                .state('ediexports', {
                    url: '/ediexports',
                    component: 'ediExports',
                })
                .state('ediimport', {
                    url: '/ediimport/:edi_import_keyno',
                    component: 'ediImport',
                })
                .state('ediimports', {
                    url: '/ediimports',
                    component: 'ediImports',
                })
                .state('edimenu', {
                    url: '/edimenu',
                    component: 'ediMenu',
                })
                .state('edisource', {
                    url: '/edisource/:edi_source_keyno',
                    component: 'ediSource',
                })
                .state('edisources', {
                    url: '/edisources',
                    component: 'ediSources',
                })
                .state('model', {
                    url: '/model/:model_id',
                    component: 'model',
                })
                .state('modellangs', {
                    url: '/modellangs/:model_id',
                    component: 'modelLangs',
                })
                .state('models', {
                    url: '/models',
                    component: 'models',
                })
                .state('modelmenu', {
                    url: '/modelmenu/:model_id',
                    component: 'modelMenu',
                })
                .state('newproceduredocumentation', {
                    url: '/newproceduredocumentation',
                    component: 'newProcedureDocumentation',
                })
                .state('p2subscriptions_edit', {
                    url: '/p2subscriptions_edit/:p2_subscription_keyno',
                    component: 'p2SubscriptionsEdit',
                })
                .state('receivables', {
                    url: '/receivables',
                    component: 'receivables',
                })
                .state('subscriptionusergroup', {
                    url: '/subscriptionusergroup/:argtype/:argvalue',
                    component: 'subscriptionUsergroup',
                })
                .state('subscriptionusergroupedit', {
                    url: '/subscriptionusergroupedit/:p2_subscription_usergroup_keyno',
                    component: 'subscriptionUsergroupEdit',
                })
                .state('sractivity', {
                    url: '/sractivity',
                    component: 'srActivity',
                })
                .state('srchecklistcrewchange', {
                    url: '/srchecklistcrewchange/:checklistdata_keyno',
                    component: 'srCheckListCrewChange',
                })
                .state('srchecklistcrews', {
                    url: '/srchecklistcrews/:checklistdata_keyno',
                    component: 'srCheckListCrews',
                })
                .state('srchecklistld', {
                    url: '/srchecklistld/:checklistdata_keyno/:srrefundinput_keyno',
                    component: 'srCheckListLd',
                })
                .state('srchecklistlds', {
                    url: '/srchecklistlds/:checklistdata_keyno',
                    component: 'srCheckListLds',
                })
                .state('srchecklistequipment', {
                    url: '/srchecklistequipment/:checklistdata_keyno/:srrefundtype_id/:srrefundinput_keyno',
                    component: 'srCheckListEquipment',
                })
                .state('srchecklistequipments', {
                    url: '/srchecklistequipments/:checklistdata_keyno/:srrefundtype_id',
                    component: 'srCheckListEquipments',
                })
                .state('srchecklistexpense', {
                    url: '/srchecklistexpense/:checklistdata_keyno/:srrefundinput_keyno',
                    component: 'srCheckListExpense',
                })
                .state('srchecklistexpenses', {
                    url: '/srchecklistexpenses/:checklistdata_keyno',
                    component: 'srCheckListExpenses',
                })
                .state('srcrew', {
                    url: '/srcrew/:resource_id/:srcrew_keyno',
                    component: 'srCrew',
                })
                .state('srcrewmenu', {
                    url: '/srcrewmenu/:resource_id/:srcrew_keyno',
                    component: 'srCrewMenu',
                })
                .state('srcrewresource', {
                    url: '/srcrewresource/:srcrewresource_keyno/:resource_id/:srcrew_keyno',
                    component: 'srCrewResource',
                })
                .state('srcrewresourceview', {
                    url: '/srcrewresourceview',
                    component: 'srCrewResourceView',
                })
                .state('srcrews', {
                    url: '/srcrews/:argtype/:argvalue',
                    component: 'srCrews',
                })
                .state('srcustomeredit', {
                    url: '/srcustomeredit/:cust_no',
                    component: 'srCustomerEdit',
                })
                .state('srequipment', {
                    url: '/srequipment/:resource_id/:srequipment_keyno',
                    component: 'srEquipment',
                })
                .state('srequipmentresourceview', {
                    url: '/srequipmentresourceview',
                    component: 'srEquipmentResourceView',
                })
                .state('srequipments', {
                    url: '/srequipments/:resource_id',
                    component: 'srEquipments',
                })
                .state('srnotcompleted', {
                    url: '/srnotcompleted/:resource_id',
                    component: 'srNotCompleted',
                })
                .state('srnotcompletednew', {
                    url: '/srnotcompletednew/:resource_id',
                    component: 'srNotCompletedNew',
                })
                .state('srexpenses', {
                    url: '/srexpenses/:argtype/:argvalue',
                    component: 'srExpenses',
                })
                .state('srfindreport', {
                    url: '/srfindreport',
                    component: 'srFindReport',
                })
                .state('sroperation', {
                    url: '/sroperation/:custact_no',
                    component: 'srOperation',
                })
                .state('sroperationaddr', {
                    url: '/sroperationaddr/:custact_no',
                    component: 'srOperationAddr',
                })
                .state('sroperationar', {
                    url: '/sroperationar/:custact_no',
                    component: 'srOperationAr',
                })
                .state('sroperationresource', {
                    url: '/sroperationresource/:special_hrs_custactresource_keyno',
                    component: 'srOperationResource',
                })
                .state('sroperations', {
                    url: '/sroperations',
                    component: 'srOperations',
                })
                .state('srrefundbasis', {
                    url: '/srrefundbasis/:custact_no/:checklistdata_keyno',
                    component: 'srRefundBasis',
                })
                .state('srrefundcreate', {
                    url: '/srrefundcreate/:custact_no/:checklistdata_keyno',
                    component: 'srRefundCreate',
                })
                .state('srrefundinputcrew', {
                    url: '/srrefundinputcrew/:checklistdata_keyno/:srrefundinput_keyno',
                    component: 'srRefundInputCrew',
                })
                .state('srreportmenu', {
                    url: '/srreportmenu/:checklistdata_keyno',
                    component: 'srReportMenu',
                })
                .state('srtravelbill', {
                    url: '/srtravelbill/:travelbill_no',
                    component: 'srTravelBill',
                })
                .state('srtravelbillspec', {
                    url: '/srtravelbillspec/:travelbill_no/:argtype/:argvalue',
                    component: 'srTravelBillSpec',
                })
                .state('sruser', {
                    url: '/sruser/:portal_user_keyno',
                    component: 'srUser',
                })
                .state('sruseradmin', {
                    url: '/sruseradmin',
                    component: 'srUserAdmin',
                })
                .state('srusers', {
                    url: '/srusers',
                    component: 'srUsers',
                })
                .state('textileoladd', {
                    url: '/textileoladd/:order_internal_no/:orderline_keyno/:textilemodel_id',
                    component: 'textileOlAdd',
                })
                .state('textilestocksearch', {
                    url: '/textilestocksearch',
                    component: 'textileStockSearch',
                })
                .state('p2clientupdate', {
                    url: '/p2clientupdate',
                    component: 'p2ClientUpdate',
                })
                .state('p2clientupdateedit', {
                    url: '/p2clientupdateedit/:clientupdate_id',
                    component: 'p2ClientUpdateEdit',
                })
                .state('developmenu', {
                    url: '/developmenu',
                    // component: 'developMenu',
                    component: DevelopMenuComponent,
                    header: {
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })
                .state('menugrp', {
                    url: '/menugrp/:menugrp_keyno',
                    component: 'menuGrp',
                })
                .state('menugrps', {
                    url: '/menugrps',
                    component: 'menuGrps',
                })
                .state('p2settings', {
                    url: '/p2settings',
                    component: 'p2Settings',
                })
                .state('p2settingedit', {
                    url: '/p2settingedit/:p2_setting_keyno',
                    component: 'p2SettingEdit',
                })
                .state('p2webpage', {
                    url: '/p2webpage',
                    component: 'p2Webpage',
                })
                .state('p2subscription', {
                    url: '/p2subscription',
                    component: 'p2Subscription',
                })
                .state('p2table', {
                    url: '/p2table',
                    component: 'p2Table',
                })
                .state('p2datatasklogs', {
                    url: '/p2datatasklogs/:portal_user_keyno',
                    // component: 'p2DataTaskLogs',
                    component: P2DatataskLogsComponent,
                })
                .state('p2datatasklog', {
                    url: '/p2datatasklog/:p2_datatasklog_keyno',
                    // component: 'p2DataTaskLog',
                    component: P2DatataskLogComponent,
                })
                .state('p2datatask', {
                    url: '/p2datatask',
                    component: 'p2DataTask',
                })
                .state('p2datataskedit', {
                    url: '/p2datataskedit/:p2_datatask_keyno',
                    component: 'p2DataTaskEdit',
                })
                .state('p2updatescripts', {
                    url: '/p2updatescripts',
                    // component: 'p2UpdateScripts',
                    component: P2UpdateScriptsComponent,
                })
                .state('p2updatescriptedit', {
                    url: '/p2updatescriptedit/:p2_updatescript_keyno',
                    // component: 'p2UpdateScriptEdit',
                    component: P2UpdateScriptEditViewComponent,
                })
                .state('p2releasenote', {
                    url: '/p2releasenote',
                    component: 'p2ReleaseNote',
                })
                .state('p2releasenoteedit', {
                    url: '/p2releasenoteedit/:p2_releasenote_keyno',
                    component: 'p2ReleaseNoteEdit',
                })
                .state('p2updatetables', {
                    url: '/p2updatetables',
                    component: 'p2UpdateTables',
                })
                .state('p2updatetableedit', {
                    url: '/p2updatetableedit/:p2_updatetable_keyno',
                    component: 'p2UpdateTableEdit',
                })
                .state('p2updateprocedures', {
                    url: '/p2updateprocedures',
                    component: 'p2UpdateProcedures',
                })
                .state('p2updateprocedureedit', {
                    url: '/p2updateprocedureedit/:p2_updateprocedure_keyno',
                    component: 'p2UpdateProcedureEdit',
                })
                .state('p2tableedit', {
                    url: '/p2tableedit/:p2_table_keyno',
                    component: 'p2TableEdit',
                })
                .state('p2webpagetable', {
                    url: '/p2webpagetable/:argtype/:argvalue',
                    component: 'p2WebpageTable',
                })
                .state('relatedpurchorders', {
                    url: '/relatedpurchorders/:argtype/:argvalue',
                    component: 'relatedPurchOrders',
                })
                .state('webpagetableedit', {
                    url: '/webpagetableedit/:p2_webpage_table_keyno',
                    component: 'webpageTableEdit',
                })
                .state('p2webpageedit', {
                    url: '/p2webpageedit/:p2_webpage_keyno',
                    component: 'p2WebpageEdit',
                })
                .state('webpagelink', {
                    url: '/webpagelink/:p2_webpage_keyno',
                    component: 'webpageLink',
                })
                .state('p2webpagelinkedit', {
                    url: '/p2webpagelinkedit/:p2_webpage_keyno_belongto/:p2_webpagelink_keyno',
                    component: 'p2WebpageLinkEdit',
                })
                .state('salarytypes', {
                    url: '/salarytypes',
                    component: 'salaryTypes',
                })
                .state('salarytype', {
                    url: '/salarytype/:salarytype_id',
                    component: 'salaryType',
                })
                .state('salarytypelang', {
                    url: '/salarytypelang/:salarytype_id',
                    component: 'salaryTypeLang',
                })
                .state('salesprospects', {
                    url: '/salesprospects/:argtype/:argvalue',
                    component: 'salesProspects',
                })
                .state('saftmaingroup', {
                    url: '/saftmaingroup/:saft_maingroup_no',
                    component: 'saftMainGroup',
                })
                .state('saftmaingroups', {
                    url: '/saftmaingroups',
                    component: 'saftMainGroups',
                })
                .state('scanstocklocation', {
                    url: '/scanstocklocation/:argtype/:argvalue/:functionname/:stock_keyno/:autoscan',
                    component: 'scanStockLocation',
                })
                .state('seasonalities', {
                    url: '/seasonalities',
                    component: 'seasonalities',
                })
                .state('seasonality', {
                    url: '/seasonality/:seasonality_keyno',
                    component: 'seasonality',
                })
                .state('offdutycodes', {
                    url: '/offdutycodes',
                    component: 'offDutyCodes',
                })
                .state('offdutycode', {
                    url: '/offdutycode/:offdutycode_keyno',
                    component: 'offDutyCode',
                })
                .state('selectlabour', {
                    url: '/selectlabour/:webpage_name',
                    component: 'selectLabour',
                })
                .state('selectoeestate', {
                    url: '/selectoeestate/:arrowside',
                    component: 'selectOeeState',
                })
                .state('selectoeestateremark', {
                    url: '/selectoeestateremark/:oeestate_keyno',
                    component: 'selectOeeStateRemark',
                })
                .state('selectlabourpu', {
                    url: '/selectlabourpu/:webpagename',
                    component: 'selectLabourPu',
                })
                .state('selectoperationpu', {
                    url: '/selectoperationpu/:webpagename',
                    component: 'selectOperationPu',
                })
                .state('selectworkingorder', {
                    url: '/selectworkingorder/:webpagename',
                    component: 'selectWorkingOrder',
                })
                .state('selectpofinish', {
                    url: '/selectpofinish/:webpagename/:labour_no',
                    component: 'selectPoFinish',
                })
                .state('selectequipmentpu', {
                    url: '/selectequipmentpu/:webpagename/:operation_id',
                    component: 'selectEquipmentPu',
                })
                .state('paperlesspo', {
                    url: '/paperlesspo',
                    component: 'paperLessPo',
                })
                .state('translate', {
                    url: '/translate',
                    component: 'translate',
                })
                .state('transportarea', {
                    url: '/transportarea/:transportarea_keyno/:stockplace_id',
                    component: 'transportArea',
                })
                .state('transportareapp', {
                    url: '/transportareapp/:transportareapp_keyno',
                    component: 'transportAreaPp',
                })
                .state('transportareappadd', {
                    url: '/transportareappadd/:transportarea_keyno',
                    component: 'transportAreaPpAdd',
                })
                .state('transportareas', {
                    url: '/transportareas/:stockplace_id',
                    component: 'transportAreas',
                })
                .state('transportareamenu', {
                    url: '/transportareamenu/:transportarea_keyno',
                    component: 'transportAreaMenu',
                })
                .state('travelbill', {
                    url: '/travelbill/:argtype/:argvalue/:travelbill_no',
                    component: 'travelBill',
                })
                .state('travelbilllog', {
                    url: '/travelbilllog/:travelbillog_keyno',
                    component: 'travelBillLog',
                })
                .state('travelbilllogs', {
                    url: '/travelbilllogs/:travelbill_no',
                    component: 'travelBillLogs',
                })
                .state('travelbillmenu', {
                    url: '/travelbillmenu/:travelbill_no',
                    component: 'travelBillMenu',
                })
                .state('travelbillreject', {
                    url: '/travelbillreject/:travelbill_no',
                    component: 'travelBillReject',
                })
                .state('travelbills', {
                    url: '/travelbills/:argtype/:argvalue',
                    component: 'travelBills',
                })
                .state('travelexpence', {
                    url: '/travelexpence/:travelbill_no/:travelexpence_keyno',
                    component: 'travelExpence',
                })
                .state('travexptypemenu', {
                    url: '/travexptypemenu/:trextype_id',
                    component: 'travExpTypeMenu',
                })
                .state('travexptype', {
                    url: '/travexptype/:trextype_id',
                    component: 'travExpType',
                })
                .state('travexptypebuscos', {
                    url: '/travexptypebuscos/:trextype_id',
                    component: 'travExpTypeBusCos',
                })
                .state('travexptypes', {
                    url: '/travexptypes',
                    component: 'travExpTypes',
                })
                .state('travelwage', {
                    url: '/travelwage/:travelwage_id',
                    component: 'travelWage',
                })
                .state('travelwagedata', {
                    url: '/travelwagedata/:travelbill_no/:travelwagedata_keyno',
                    component: 'travelWageData',
                })
                .state('travelwagemenu', {
                    url: '/travelwagemenu/:travelwage_id',
                    component: 'travelWageMenu',
                })
                .state('travelwages', {
                    url: '/travelwages',
                    component: 'travelWages',
                })
                .state('travelwageset', {
                    url: '/travelwageset/:travelwageset_keyno',
                    component: 'travelWageSet',
                })
                .state('travelwagesetmenu', {
                    url: '/travelwagesetmenu/:travelwageset_keyno',
                    component: 'travelWageSetMenu',
                })
                .state('travelwagesetrates', {
                    url: '/travelwagesetrates/:travelwageset_keyno',
                    component: 'travelWageSetRates',
                })
                .state('travelwagesets', {
                    url: '/travelwagesets',
                    component: 'travelWageSets',
                })
                .state('verticalpurchasing', {
                    url: '/verticalpurchasing/:order_internal_no',
                    component: 'verticalPurchasing',
                })
                .state('labours', {
                    url: '/labours',
                    component: 'labours',
                })
                .state('labouredit', {
                    url: '/labouredit/:labour_no',
                    component: 'labourEdit',
                })
                .state('laboursalarytype', {
                    url: '/laboursalarytype/:laboursalarytype_keyno',
                    component: 'labourSalaryType',
                })
                .state('laboursalarytypes', {
                    url: '/laboursalarytypes/:argtype/:argvalue',
                    component: 'labourSalaryTypes',
                })
                .state('language', {
                    url: '/language/:language_id',
                    component: 'language',
                })
                .state('languagelangs', {
                    url: '/languagelangs/:language_id',
                    component: 'languageLangs',
                })
                .state('languages', {
                    url: '/languages',
                    component: 'languages',
                })
                .state('selectcity', {
                    url: '/selectcity/:webpage_name/:parm1/:searchtext',
                    component: 'selectCity',
                })
                .state('searchstock', {
                    url: '/searchstock',
                    component: 'searchStock',
                })
                .state('translateedit', {
                    url: '/translateedit/:item_id',
                    component: 'translateEdit',
                })
                .state('case', {
                    url: '/case/:case_keyno',
                    component: 'case',
                })
                .state('counter', {
                    url: '/counter/:counter_keyno',
                    component: 'counter',
                })
                .state('counters', {
                    url: '/counters',
                    component: 'counters',
                })
                .state('createcase', {
                    url: '/createcase/:argtype/:argvalue',
                    component: 'createCase',
                })
                .state('custactcustomer', {
                    url: '/custactcustomer/:custact_no/:custact_customer_keyno',
                    component: 'custactCustomer',
                })
                .state('custactcustomers', {
                    url: '/custactcustomers/:argtype/:argvalue',
                    component: 'custactCustomers',
                })
                .state('customerbusco', {
                    url: '/customerbusco/:customerbusco_keyno',
                    component: 'customerBusCo',
                })
                .state('customerbuscos', {
                    url: '/customerbuscos/:cust_no',
                    component: 'customerBusCos',
                })
                .state('custactsearch', {
                    url: '/custactsearch/:filterfield/:filtervalue/:custacttype',
                    component: 'custactSearch',
                })
                .state('orderlinecredit', {
                    url: '/orderlinecredit/:orderline_keyno',
                    component: 'orderLineCredit',
                })
                .state('orderlinecancelcause', {
                    url: '/orderlinecancelcause/:orderlinecancelcause_keyno',
                    component: 'orderLineCancelCause',
                })
                .state('orderlinecancelcauselang', {
                    url: '/orderlinecancelcauselang/:orderlinecancelcause_keyno',
                    component: 'orderLineCancelCauseLang',
                })
                .state('orderlinecancelcauses', {
                    url: '/orderlinecancelcauses',
                    component: 'orderLineCancelCauses',
                })
                .state('orderlinecreditcause', {
                    url: '/orderlinecreditcause/:orderlinecreditcause_keyno',
                    component: 'orderLineCreditCause',
                })
                .state('orderlinecreditcauselang', {
                    url: '/orderlinecreditcauselang/:orderlinecreditcause_keyno',
                    component: 'orderLineCreditCauseLang',
                })
                .state('orderlinecreditcauses', {
                    url: '/orderlinecreditcauses',
                    component: 'orderLineCreditCauses',
                })
                .state('orderlinecredits', {
                    url: '/orderlinecredits/:argtype/:argvalue',
                    component: 'orderLineCredits',
                })
                .state('orderconfirmation', {
                    url: '/orderconfirmation/:argtype/:argvalue',
                    params: {
                        argtype: { squash: true, value: null },
                        argvalue: { squash: true, value: null },
                    },
                    component: 'orderConfirmation',
                })
                .state('postcalcm', {
                    url: '/postcalcm/:argtype/:argvalue',
                    component: 'postCalcM',
                })
                .state('postcalcw', {
                    url: '/postcalcw/:argtype/:argvalue',
                    component: 'postCalcW',
                })
                .state('stockmenu', {
                    url: '/stockmenu',
                    // component: 'stockMenu',
                    component: StockMenuComponent,
                    header: {
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })
                .state('fabricsgrp', {
                    url: '/fabricsgrp/:fabricsgrp_id',
                    component: 'fabricsGrp',
                })
                .state('fabricsgrps', {
                    url: '/fabricsgrps',
                    component: 'fabricsGrps',
                })
                .state('furniturefabrics', {
                    url: '/furniturefabrics/:furniturefabrics_id',
                    component: 'furnitureFabrics',
                })
                .state('furniturefabricss', {
                    url: '/furniturefabricss',
                    component: 'furnitureFabricsS',
                })
                .state('furniturefabricssuppliers', {
                    url: '/furnituresabricssuppliers',
                    component: 'furnitureFabricsSuppliers',
                })
                .state('findstock', {
                    url: '/findstock',
                    component: 'findStock',
                })
                .state('finishpohscan', {
                    url: '/finishpohscan',
                    component: 'finishPohScan',
                })
                .state('labourposition', {
                    url: '/labourposition/:labourposition_keyno/:argtype/:argvalue',
                    component: 'labourPosition',
                })
                .state('labourpositions', {
                    url: '/labourpositions/:argtype/:argvalue',
                    component: 'labourPositions',
                })
                //.state('pohfinish', {
                //    //    url: '/pohfinish/:poh_keyno',
                //    component: 'pohFinish'
                //})
                .state('stocksearch', {
                    url: '/stocksearch/:functionname',
                    component: 'stockSearch',
                })
                .state('stockbatchonlocation', {
                    url: '/stockbatchonlocation/:stock_keyno/:stocklocation_keyno/:functionname',
                    component: 'stockBatchOnLocation',
                })
                .state('stockassign', {
                    url: '/stockassign/:stock_keyno/:stockbatch_keyno/:stocklocation_keyno/:functionname/:p2_pofinishprodcomp_keyno',
                    component: 'stockAssign',
                })
                .state('stockcontrollist', {
                    url: '/stockcontrollist/:stockplace_id',
                    component: 'stockControlList',
                })
                .state('stockcontrolloclist', {
                    url: '/stockcontrolloclist/:stocklocation_keyno',
                    component: 'stockControlLocList',
                })
                .state('stockcontrollocstock', {
                    url: '/stockcontrollocstock/:stocklocation_keyno/:stock_keyno',
                    component: 'stockControlLocStock',
                })
                .state('stockcontrolsplist', {
                    url: '/stockcontrolsplist',
                    component: 'stockControlSpList',
                })
                .state('stockonlocation', {
                    url: '/stockonlocation/:stock_keyno/:functionname',
                    component: 'stockOnLocation',
                })
                .state('stocksonlocation', {
                    url: '/stocksonlocation/:stocklocation_keyno',
                    component: 'stocksOnLocation',
                })
                .state('coop', {
                    url: '/coop/:coop_id',
                    component: 'coop',
                })
                .state('cooplangs', {
                    url: '/cooplangs/:coop_id',
                    component: 'coopLangs',
                })
                .state('coops', {
                    url: '/coops',
                    component: 'coops',
                })
                .state('stockzones', {
                    url: '/stockzones',
                    component: 'stockZones',
                })
                .state('stockzonemenu', {
                    url: '/stockzonemenu/:stockzone_keyno',
                    component: 'stockZoneMenu',
                })
                .state('stockzone', {
                    url: '/stockzone/:stockplace_id/:stockzone_keyno',
                    component: 'stockZone',
                })
                .state('stocklocation', {
                    url: '/stocklocation/:stockzone_keyno/:stocklocation_keyno',
                    component: 'stockLocation',
                })
                .state('stocklocationctrl', {
                    url: '/stocklocationctrl/:stock_keyno',
                    component: 'stockLocationCtrl',
                })
                .state('stocktransmenu', {
                    url: '/stocktransmenu/:stocktrans_keyno',
                    component: 'stockTransMenu',
                })
                .state('stocktransallocations', {
                    url: '/stocktransallocations/:argtype/:argvalue',
                    component: 'stockTransAllocations',
                })
                .state('stocktransfer', {
                    url: '/stocktransfer/:stocktransfer_keyno',
                    component: 'stockTransfer',
                })
                .state('stocktransfercreate', {
                    url: '/stocktransfercreate/:stockplace_id',
                    component: 'stockTransferCreate',
                })
                .state('stocktransferload', {
                    url: '/stocktransferload/:stocktransfer_keyno',
                    component: 'stockTransferLoad',
                })
                .state('stocktransferloaditem', {
                    url: '/stocktransferloaditem/:stocktransfer_keyno/:stock_keyno/:stockbatch_keyno/:stocklocation_keyno_from/:quantity',
                    component: 'stockTransferLoadItem',
                })
                .state('stocktransferunload', {
                    url: '/stocktransferunload/:stocktransfer_keyno/:stock_keyno/:stockbatch_keyno/:stocklocation_keyno_suggestion',
                    component: 'stockTransferUnLoad',
                })
                .state('stocktransferreception', {
                    url: '/stocktransferreception/:stocklocation_keyno',
                    component: 'stockTransferReception',
                })
                .state('stocktransfers', {
                    url: '/stocktransfers',
                    component: 'stockTransfers',
                })
                .state('inventoryarticlesearch', {
                    url: '/inventoryarticlesearch',
                    component: 'inventoryArticleSearch',
                })
                .state('inventoryscan', {
                    url: '/inventoryscan',
                    component: 'inventoryScan',
                })
                .state('paymentlang', {
                    url: '/paymentlang/:payment_id',
                    component: 'paymentLang',
                })
                .state('paymentlangs', {
                    url: '/paymentlangs/:payment_id',
                    component: 'paymentLangs',
                })
                .state('paymentplan', {
                    url: '/paymentplan/:argtype/:argvalue/:paymentplan_keyno',
                    component: 'paymentPlan',
                })
                .state('paymentplans', {
                    url: '/paymentplans/:argtype/:argvalue',
                    component: 'paymentPlans',
                })
                .state('acledger', {
                    url: '/acledger/:argtype/:argvalue',
                    component: 'acLedger',
                })
                .state('acledgerview', {
                    url: '/acledgerview',
                    component: 'acLedgerView',
                })
                .state('acledgerhistoryimport', {
                    url: '/acledgerhistoryimport',
                    component: 'acledgerhistoryimport',
                })
                .state('acsupptrans', {
                    url: '/acsupptrans/:acsupptrans_keyno',
                    component: 'acSuppTrans',
                })
                .state('acsupptranses', {
                    url: '/acsupptranses/:acc_supp_no',
                    component: 'acSuppTranses',
                })
                .state('accusttrans', {
                    url: '/accusttrans/:accusttrans_keyno',
                    component: 'acCustTrans',
                })
                .state('accusttranses', {
                    url: '/accusttranses/:argtype/:argvalue',
                    component: 'acCustTranses',
                })
                .state('accusttransesage', {
                    url: '/accusttransesage',
                    component: 'acCustTransesAge',
                })
                .state('acsupptransesage', {
                    url: '/acsupptransesage',
                    component: 'acSuppTransesAge',
                })

                //bhw
                .state('bulkreturn', {
                    url: '/bulkreturn/:counter',
                    component: 'bulkReturn',
                })
                .state('bulkmail', {
                    url: '/bulkmail/:bulkmail_keyno',
                    component: 'bulkMail',
                })
                .state('bulkmailmenu', {
                    url: '/bulkmailmenu/:bulkmail_keyno',
                    component: 'bulkMailMenu',
                })
                .state('bulkmails', {
                    url: '/bulkmails',
                    component: 'bulkMails',
                })
                .state('department', {
                    url: '/department/:department_id',
                    component: 'department',
                })
                .state('departmentlang', {
                    url: '/departmentlang/:department_id',
                    component: 'departmentLang',
                })
                .state('departmentmenu', {
                    url: '/departmentmenu/:department_id',
                    component: 'departmentMenu',
                })
                .state('departments', {
                    url: '/departments',
                    component: 'departments',
                })
                .state('dispatchplanning', {
                    url: '/dispatchplanning',
                    // component: 'dispatchPlanning',
                    component: DispatchPlanningComponent,
                })

                .state('dispatchplanningorderlines', {
                    url: '/dispatchplanningorderlines/:argtype/:argvalue/:counter',
                    component: 'dispatchPlanningOrderLines',
                })
                .state('dispatchplanningstock', {
                    url: '/dispatchplanningstock',
                    component: 'dispatchPlanningStock',
                })
                .state('dispatchplanningblockorder', {
                    url: '/dispatchplanningblockorder/:order_internal_no',
                    component: 'dispatchPlanningBlockOrder',
                })
                .state('dispatchplanningconsignment', {
                    url: '/dispatchplanningconsignment/:dispatch_no',
                    component: 'dispatchPlanningConsignment',
                })
                .state('textilemodelcategory3', {
                    url: '/textilemodelcategory3/:textilemodelcategory3_keyno',
                    component: 'textileModelCategory3',
                })
                .state('textilemodelcategory3lang', {
                    url: '/textilemodelcategory3lang/:textilemodelcategory3_keyno',
                    component: 'textileModelCategory3Lang',
                })
                .state('textilemodelcategory3s', {
                    url: '/textilemodelcategory3s',
                    component: 'textileModelCategory3s',
                })
                .state('textilemodelcategory4lang', {
                    url: '/textilemodelcategory4lang/:textilemodelcategory4_keyno',
                    component: 'textileModelCategory4Lang',
                })
                .state('textilemodelcategory4', {
                    url: '/textilemodelcategory4/:textilemodelcategory4_keyno',
                    component: 'textileModelCategory4',
                })
                .state('textilemodelcategory4s', {
                    url: '/textilemodelcategory4s',
                    component: 'textileModelCategory4s',
                })
                .state('textilemodelcategory5lang', {
                    url: '/textilemodelcategory5lang/:textilemodelcategory5_keyno',
                    component: 'textileModelCategory5Lang',
                })
                .state('textilemodelcategory5', {
                    url: '/textilemodelcategory5/:textilemodelcategory5_keyno',
                    component: 'textileModelCategory5',
                })
                .state('textilemodelcategory5s', {
                    url: '/textilemodelcategory5s',
                    component: 'textileModelCategory5s',
                })
                .state('textilemodelcategory6lang', {
                    url: '/textilemodelcategory6lang/:textilemodelcategory6_keyno',
                    component: 'textileModelCategory6Lang',
                })
                .state('textilemodelcategory6', {
                    url: '/textilemodelcategory6/:textilemodelcategory6_keyno',
                    component: 'textileModelCategory6',
                })
                .state('textilemodelcategory6s', {
                    url: '/textilemodelcategory6s',
                    component: 'textileModelCategory6s',
                })
                .state('textilemodelcategory7lang', {
                    url: '/textilemodelcategory7lang/:textilemodelcategory7_keyno',
                    component: 'textileModelCategory7Lang',
                })
                .state('textilemodelcategory7', {
                    url: '/textilemodelcategory7/:textilemodelcategory7_keyno',
                    component: 'textileModelCategory7',
                })
                .state('textilemodelcategory7s', {
                    url: '/textilemodelcategory7s',
                    component: 'textileModelCategory7s',
                })
                .state('textilemodelcategory8lang', {
                    url: '/textilemodelcategory8lang/:textilemodelcategory8_keyno',
                    component: 'textileModelCategory8Lang',
                })
                .state('textilemodelcategory8', {
                    url: '/textilemodelcategory8/:textilemodelcategory8_keyno',
                    component: 'textileModelCategory8',
                })
                .state('textilemodelcategory8s', {
                    url: '/textilemodelcategory8s',
                    component: 'textileModelCategory8s',
                })
                .state('productcompositionlang', {
                    url: '/productcompositionlang/:productcomposition_keyno',
                    component: 'productCompositionLang',
                });

            $stateProvider
                .state('productcomposition', {
                    url: '/productcomposition/:productcomposition_keyno',
                    component: 'productComposition',
                })
                .state('productcompositions', {
                    url: '/productcompositions',
                    component: 'productCompositions',
                })
                .state('productcategorylang', {
                    url: '/productcategorylang/:productcategory_keyno',
                    component: 'productCategoryLang',
                })
                .state('productcategory', {
                    url: '/productcategory/:productcategory_keyno',
                    component: 'productCategory',
                })
                .state('productcategorys', {
                    url: '/productcategorys',
                    component: 'productCategorys',
                })
                .state('listdatalang', {
                    url: '/listdatalang/:listdata_keyno',
                    component: 'listDataLang',
                })
                .state('listdata', {
                    url: '/listdata/:listdata_keyno/:list_keyno',
                    component: 'listData',
                })
                .state('listdatas', {
                    url: '/listdatas/:list_keyno',
                    component: 'listDatas',
                })
                .state('list', {
                    url: '/list/:list_keyno',
                    component: 'list',
                })
                .state('lists', {
                    url: '/lists',
                    component: 'lists',
                })
                .state('selectstocklocation', {
                    url: '/selectstocklocation/:stockplace_id',
                    component: 'selectStockLocation',
                })
                .state('stockcount', {
                    url: '/stockcount/:stockplace_id/:stockcount_keyno',
                    component: 'stockCount',
                })
                .state('stockcountapprove', {
                    url: '/stockcountapprove/:stockcount_keyno',
                    component: 'stockCountApprove',
                })
                .state('stockcounts', {
                    url: '/stockcounts',
                    component: 'stockCounts',
                })
                .state('stockcountmenu', {
                    url: '/stockcountmenu/:stockcount_keyno',
                    component: 'stockCountMenu',
                })
                .state('stockcountrefresh', {
                    url: '/stockcountrefresh/:stockcount_keyno',
                    component: 'stockCountRefresh',
                })
                .state('stockcountresult', {
                    url: '/stockcountresult/:stockcount_keyno',
                    component: 'stockCountResult',
                })
                .state('stockcountdata', {
                    url: '/stockcountdata/:stockcountdata_keyno',
                    component: 'stockCountData',
                })
                .state('stockcountdataitem', {
                    url: '/stockcountdataitem/:stockcountdataitem_keyno/:argtype/:argvalue',
                    component: 'stockCountDataItem',
                })
                .state('stockcountdatas', {
                    url: '/stockcountdatas/:stockcount_keyno',
                    component: 'stockCountDatas',
                })
                .state('consignmentscan', {
                    url: '/consignmentscan',
                    component: 'consignmentScan',
                })
                .state('consignmentserialnumber', {
                    url: '/consignmentserialnumber/:consignment_keyno',
                    component: 'consignmentSerialNumber',
                })
                .state('consignmentplan', {
                    url: '/consignmentplan/:custact_no',
                    component: 'consignmentPlan',
                })
                .state('consignmentplanbuild', {
                    url: '/consignmentplanbuild/:custact_no',
                    component: 'consignmentPlanBuild',
                })
                .state('consignmentplanning', {
                    url: '/consignmentplanning',
                    component: 'consignmentPlanning',
                })
                .state('consignmentplans', {
                    url: '/consignmentplans',
                    component: 'consignmentPlans',
                })
                .state('consignmentplansdelivery', {
                    url: '/consignmentplansdelivery',
                    component: 'consignmentPlansDelivery',
                    //component: ConsignmentPlansDeliveryComponent
                })
                .state('consignmentplansdeliverylabour', {
                    url: '/consignmentplansdeliverylabour',
                    component: 'consignmentPlansDeliveryLabour',
                })
                .state('consignmentplansdeliverylocation', {
                    url: '/consignmentplansdeliverylocation',
                    component: 'consignmentPlansDeliveryLocation',
                })
                .state('consignmentplansconsignments', {
                    url: '/consignmentplansconsignments/:custact_no',
                    component: 'consignmentPlansConsignments',
                })
                .state('consignmentplansorders', {
                    url: '/consignmentplansorders/:argtype/:argvalue',
                    component: 'consignmentPlansOrders',
                })
                .state('consignmentplansconsignmentcontent', {
                    url: '/consignmentplansconsignmentcontent/:consignment_keyno/:custact_no',
                    component: 'consignmentPlansConsignmentContent',
                })
                .state('consignmentplansdeliverydriver', {
                    url: '/consignmentplansdeliverydriver/:argtype/:argvalue',
                    component: 'consignmentPlansDeliveryDriver',
                })
                .state('consignmentplansdeliverysettings', {
                    url: '/consignmentplansdeliverysettings',
                    component: 'consignmentPlansDeliverySettings',
                })
                .state('consignments', {
                    url: '/consignments/:argtype/:argvalue',
                    component: 'consignments',
                })
                .state('consignmentlineassign', {
                    url: '/consignmentlineassign/:consignmentline_keyno',
                    component: 'consignmentLineAssign',
                })
                .state('consignmentlines', {
                    url: '/consignmentlines/:argtype/:argvalue',
                    component: 'consignmentLines',
                })
                .state('consignmentlinesb', {
                    url: '/consignmentlinesb/:consignmentlinesb_keyno',
                    component: 'consignmentLinesB',
                })
                .state('consignmentlinesbs', {
                    url: '/consignmentlinesbs/:argtype/:argvalue',
                    component: 'consignmentLinesBs',
                })
                .state('consignmentline', {
                    url: '/consignmentline/:consignmentline_keyno',
                    component: 'consignmentLine',
                })
                .state('consignmentlineselect', {
                    url: '/consignmentlineselect/:consignment_keyno',
                    component: 'consignmentLineSelect',
                })
                .state('consignmentshipment', {
                    url: '/consignmentshipment/:consignmentshipment_keyno/:stockplace_id',
                    component: 'consignmentShipment',
                })
                .state('consignmentshipments', {
                    url: '/consignmentshipments',
                    component: 'consignmentShipments',
                })
                .state('consignment', {
                    url: '/consignment/:consignment_keyno',
                    component: 'consignment',
                })
                .state('consignmentol', {
                    url: '/consignmentol/:consignmentol_keyno',
                    component: 'consignmentOl',
                })
                .state('consignmentsplit', {
                    url: '/consignmentsplit/:consignment_keyno_splitfrom',
                    component: 'consignmentSplit',
                })
                .state('consignmentsplitedit', {
                    url: '/consignmentsplitedit/:consignment_keyno_splitfrom',
                    component: 'consignmentSplitEdit',
                })
                .state('consignmentsplitline', {
                    url: '/consignmentsplitline/:consignmentline_keyno',
                    component: 'consignmentSplitLine',
                })
                .state('consignmentcollidetail', {
                    url: '/consignmentcollidetail/:consignment_keyno/:consignmentcolli_keyno',
                    component: 'consignmentColliDetail',
                })
                .state('consignmentcarrierone', {
                    url: '/consignmentcarrierone/:consignment_keyno',
                    component: 'consignmentCarrierOne',
                })
                .state('containershipment', {
                    url: '/containershipment',
                    component: 'containerShipment',
                })
                .state('prodsizeedit', {
                    url: '/prodsizeedit/:prodsize_keyno',
                    component: 'prodSizeEdit',
                })
                .state('prodsizecolli', {
                    url: '/prodsizecolli/:prodsize_keyno/:prodsizecolli_keyno',
                    component: 'prodSizeColli',
                })
                .state('prodgrp', {
                    url: '/prodgrp/:prodgrp_id',
                    component: 'prodGrp',
                })
                .state('prodgrp2', {
                    url: '/prodgrp2/:prodgrp_id/:prodgrp2_id',
                    component: 'prodGrp2',
                })
                .state('prodgrp3', {
                    url: '/prodgrp3/:prodgrp2_id/:prodgrp3_id',
                    component: 'prodGrp3',
                })
                .state('prodgrp4', {
                    url: '/prodgrp4/:prodgrp3_id/:prodgrp4_id',
                    component: 'prodGrp4',
                })
                .state('prodgrp2menu', {
                    url: '/prodgrp2menu/:prodgrp2_id',
                    component: 'prodGrp2Menu',
                })
                .state('prodgrp3menu', {
                    url: '/prodgrp3menu/:prodgrp3_id',
                    component: 'prodGrp3Menu',
                })
                .state('prodgrp4menu', {
                    url: '/prodgrp4menu/:prodgrp4_id',
                    component: 'prodGrp4Menu',
                })
                .state('prodgrplangs', {
                    url: '/prodgrplangs/:prodgrp_id',
                    component: 'prodGrpLangs',
                })
                .state('prodgrpmenu', {
                    url: '/prodgrpmenu/:prodgrp_id',
                    component: 'prodGrpMenu',
                })
                .state('prodgrps', {
                    url: '/prodgrps',
                    component: 'prodGrps',
                })
                .state('pocf', {
                    url: '/pocf/:argtype/:argvalue',
                    component: 'pocf',
                })
                .state('pofinishpofinishcomps', {
                    url: '/pofinishpofinishcomps/:p2_pofinish_keyno',
                    component: 'poFinishPoFinishComps',
                })
                .state('p2reportdefs', {
                    url: '/p2reportdefs',
                    component: 'p2ReportDefs',
                })
                .state('p2reportdef', {
                    url: '/p2reportdef/:p2_reportdef_keyno',
                    component: 'p2ReportDef',
                })
                .state('htmlreports', {
                    url: '/htmlreports',
                    component: 'htmlReports',
                })
                .state('htmlreport', {
                    url: '/htmlreport/:htmlreport_keyno',
                    component: 'htmlReport',
                })
                .state('htmlproc', {
                    url: '/htmlproc/:htmlreport_keyno/:htmlproc_keyno_belongto/:htmlproc_keyno',
                    component: 'htmlProc',
                })
                .state('component', {
                    url: '/component/:prod_id/:comp_keyno',
                    component: 'component',
                })
                .state('components', {
                    url: '/components/:prod_id',
                    component: 'components',
                })
                .state('compvalidationset', {
                    url: '/compvalidationset/:comp_keyno',
                    component: 'compValidationSet',
                })
                .state('hrmenu', {
                    url: '/hrmenu',
                    // component: 'hrMenu',
                    component: HrMenuComponent,
                })
                .state('jobqueuemonitor', {
                    url: '/jobqueuemonitor',
                    component: 'jobQueueMonitor',
                })
                .state('jobschedule', {
                    url: '/jobschedule/:jobschedule_keyno',
                    component: 'jobSchedule',
                })
                .state('jobschedules', {
                    url: '/jobschedules',
                    component: 'jobSchedules',
                })
                .state('productcalc', {
                    url: '/productcalc/:prod_id/:argtype/:argvalue',
                    component: 'productCalc',
                })
                .state('product', {
                    url: '/product/:prod_id',
                    component: 'product',
                    title: '',
                })
                .state('productlabel', {
                    url: '/productlabel',
                    component: 'productLabel',
                })
                .state('sirvsetup', {
                    url: '/sirvsetup',
                    //component: 'sirvSetup'
                    component: SirvSetupComponent,
                })
                .state('productean', {
                    url: '/productean/:prod_id/:productean_keyno',
                    component: 'productEan',
                })
                .state('producteans', {
                    url: '/producteans/:prod_id',
                    component: 'productEans',
                })
                .state('productlang', {
                    url: '/productlang/:prodlang_keyno',
                    component: 'productLang',
                })
                .state('productlangs', {
                    url: '/productlangs/:prod_id',
                    component: 'productLangs',
                })
                .state('productlink', {
                    url: '/productlink/:productlink_keyno',
                    component: 'productLink',
                })
                .state('productlinks', {
                    url: '/productlinks/:argtype/:argvalue/:productlinktype_keyno',
                    component: 'productLinks',
                })
                .state('productlinkschange', {
                    url: '/productlinkschange/:argtype/:argvalue/:productlinktype_keyno',
                    component: 'productLinksChange',
                })
                .state('productmultiunit', {
                    url: '/productmultiunit/:prod_id',
                    component: 'productMultiUnit',
                })
                .state('productpic', {
                    url: '/productpic/:productpicturebank_keyno/:prod_id',
                    component: 'productPic',
                })
                .state('productpics', {
                    url: '/productpics/:prod_id',
                    component: 'productPics',
                })
                .state('productprices', {
                    url: '/productprices/:prod_id',
                    component: 'productPrices',
                })
                .state('productview', {
                    url: '/productview/:prod_id',
                    component: 'productView',
                })
                .state('propertyclasses', {
                    url: '/propertyclasses',
                    component: 'propertyClasses',
                })
                .state('propertyclasslangs', {
                    url: '/propertyclasslangs/:propertyclass_id',
                    component: 'propertyClassLangs',
                })
                .state('price', {
                    url: '/price/:pricegrp_keyno/:price_keyno',
                    component: 'price',
                })
                .state('pricelist', {
                    url: '/pricelist/:pricelist_id',
                    component: 'priceList',
                })
                .state('pricelists', {
                    url: '/pricelists',
                    component: 'priceLists',
                })
                .state('pricelistprice', {
                    url: '/pricelistprice/:argtype/:argvalue',
                    component: 'priceListPrice',
                })
                .state('pricelistbusco', {
                    url: '/pricelistbusco/:pricelist_id/:businessco_no',
                    component: 'priceListBusCo',
                })
                .state('pricelistbuscos', {
                    url: '/pricelistbuscos/:pricelist_id',
                    component: 'priceListBusCos',
                })
                .state('pricelistfactors', {
                    url: '/pricelistfactors/:argtype/:argvalue',
                    component: 'priceListFactors',
                })
                .state('skillscertificate', {
                    url: '/skillscertificate/:labour_no/:skillscertificate_keyno',
                    component: 'skillsCertificate',
                })
                .state('skillscertificates', {
                    url: '/skillscertificates/:argtype/:argvalue',
                    component: 'skillsCertificates',
                })
                .state('skillscertificatetable', {
                    url: '/skillscertificatetable',
                    component: 'skillsCertificateTable',
                })
                .state('skillscertificatetype', {
                    url: '/skillscertificatetype/:skillscertificatetype_keyno',
                    component: 'skillsCertificateType',
                })
                .state('skillscertificatetypes', {
                    url: '/skillscertificatetypes',
                    component: 'skillsCertificateTypes',
                })
                .state('timestampstartcorr', {
                    url: '/timestampstartcorr/:labour_no',
                    component: 'timeStampStartCorr',
                })
                .state('purchprice', {
                    url: '/purchprice/:prodsize_keyno/:purchprice_keyno',
                    component: 'purchPrice',
                })
                .state('productunits', {
                    url: '/productunits/:prod_id',
                    component: 'productUnits',
                })
                .state('productunit', {
                    url: '/productunit/:prod_id/:productunit_keyno',
                    component: 'productUnit',
                })
                .state('productunitsedit', {
                    url: '/productunitsedit/:prod_id',
                    component: 'productUnitsEdit',
                })
                .state('productunitdata', {
                    url: '/productunitdata/:productunitdata_keyno',
                    component: 'productUnitData',
                })
                .state('pomenu', {
                    url: '/pomenu/:po_keyno',
                    component: 'poMenu',
                })
                .state('pofinish', {
                    url: '/pofinish/:p2_pofinish_keyno',
                    component: 'poFinish',
                })
                .state('pofinishlist', {
                    url: '/pofinishlist',
                    component: 'poFinishList',
                })
                .state('pofinishprodworks', {
                    url: '/pofinishprodworks/:p2_pofinishprod_keyno',
                    component: 'poFinishProdWorks',
                })
                .state('pofinishprodwork', {
                    url: '/pofinishprodwork/:p2_pofinishprodwork_keyno',
                    component: 'poFinishProdWork',
                })
                .state('pofinishprod', {
                    url: '/pofinishprod/:po_keyno/:p2_pofinishprod_keyno',
                    component: 'poFinishProd',
                })
                .state('suboperation', {
                    url: '/suboperation/:operation_id/:suboperation_keyno',
                    component: 'subOperation',
                })
                .state('suboperations', {
                    url: '/suboperations/:operation_id',
                    component: 'subOperations',
                })
                .state('suboperationlang', {
                    url: '/suboperationlang/:suboperation_keyno',
                    component: 'subOperationLang',
                })
                .state('pofinishprodlist', {
                    url: '/pofinishprodlist/:p2_pofinish_keyno',
                    component: 'poFinishProdList',
                })
                //.state('pohfinishcomp', {
                //    //    url: '/pohfinishcomp/:poh_keyno/:quantity_finished_now',
                //    component: 'pohFinishComp'
                //})
                .state('pofinishprodpack', {
                    url: '/pofinishprodpack/:poh_keyno_top/:counter',
                    component: 'poFinishProdPack',
                })
                .state('pofinishcomplist', {
                    url: '/pofinishcomplist/:p2_pofinishprod_keyno',
                    component: 'poFinishCompList',
                })
                .state('pofinishprodcompassign', {
                    url: '/pofinishprodcompassign/:p2_pofinishprodcomp_keyno',
                    component: 'poFinishProdCompAssign',
                })
                .state('pofinishprodcompsbs', {
                    url: '/pofinishprodcompsbs/:p2_pofinishprodcomp_keyno',
                    component: 'poFinishProdCompsBs',
                })
                .state('pofinishprodcompsb', {
                    url: '/pofinishprodcompsb/:p2_pofinishprodcompsb_keyno',
                    component: 'poFinishProdCompsB',
                })
                .state('pohcompaddscan', {
                    url: '/pohcompaddscan/:poh_keyno',
                    component: 'pohCompAddScan',
                })
                .state('pohcompadd', {
                    url: '/pohcompadd/:poh_keyno/:stock_keyno',
                    component: 'pohCompAdd',
                })
                //.state('pohcompaddquant', {
                //    //    url: '/pohcompaddquant/:poh_keyno/:pohcomp_keyno',
                //    component: 'pohCompAddQuant'
                //})
                .state('pofinishprodcomp', {
                    url: '/pofinishprodcomp/:p2_pofinishprod_keyno/:p2_pofinishprodcomp_keyno',
                    component: 'poFinishProdComp',
                })
                .state('pofinishprodcompqty', {
                    url: '/pofinishprodcompqty/:p2_pofinishprodcomp_keyno',
                    component: 'poFinishProdCompQty',
                })
                .state('pofinishprodcomplistaccs', {
                    url: '/pofinishprodcomplistaccs/:p2_pofinish_keyno',
                    component: 'poFinishProdCompListAccs',
                })
                .state('pofinishprodcomplistacc', {
                    url: '/pofinishprodcomplistacc/:p2_pofinish_keyno/:prodsize_keyno/:prodchoice_keyno',
                    component: 'poFinishProdCompListAcc',
                })
                .state('ni', {
                    url: '/ni',
                    component: 'ni',
                })
                .state('selectarticles', {
                    url: '/selectarticles',
                    component: 'selectArticles',
                })
                .state('custactplan', {
                    url: '/custactplan/:custactplan_keyno',
                    component: 'custactPlan',
                })
                .state('custactplans', {
                    url: '/custactplans',
                    component: 'custactPlans',
                })
                .state('datamining', {
                    url: '/datamining/:p2_statsource_keyno/:level_no',
                    component: 'dataMining',
                })
                .state('dataminingcreate', {
                    url: '/dataminingcreate/:p2_statsource_keyno/:level_no',
                    component: 'dataMiningCreate',
                })
                .state('datamininglist', {
                    url: '/datamininglist',
                    component: 'dataMiningList',
                })
                .state('p2statsource', {
                    url: '/p2statsource',
                    component: 'p2StatSource',
                })
                .state('p2statsourceedit', {
                    url: '/p2statsourceedit/:p2_statsource_keyno',
                    component: 'p2StatSourceEdit',
                })
                .state('interbranchinvoiceline', {
                    url: '/interbranchinvoiceline/:interbranchinvoiceline_keyno',
                    component: 'interBranchInvoiceLine',
                })
                .state('interbranchinvoicelinemultiapproval', {
                    url: '/interbranchinvoicelinemultiapproval',
                    component: 'interbranchInvoicelineMultiApproval',
                })
                .state('interbranchinvoicing', {
                    url: '/interbranchinvoicing',
                    component: 'interBranchInvoicing',
                })
                .state('interbranchinvoicingmi', {
                    url: '/interbranchinvoicingmi/:businessco_no_from/:businessco_no_to',
                    component: 'interBranchInvoicingMi',
                })
                .state('interbranchinvoiceoverview', {
                    url: '/interbranchinvoiceoverview',
                    component: 'interBranchInvoiceOverview',
                })
                .state('interbranchlevel', {
                    url: '/interbranchlevel/:interbranchlevel_keyno',
                    component: 'interBranchLevel',
                })
                .state('interbranchmulti', {
                    url: '/interbranchmulti',
                    component: 'interBranchMulti',
                })
                .state('interbranchmultiget', {
                    url: '/interbranchmultiget/:interbranchmulti_keyno',
                    component: 'interBranchMultiGet',
                })
                .state('interbranchmultiinvoicing', {
                    url: '/interbranchmultiinvoicing',
                    component: 'interbranchMultiInvoicing',
                })
                .state('incomingcall', {
                    url: '/incomingcall/:phoneno',
                    component: 'incomingCall',
                })
                .state('olmrequisition', {
                    url: '/olmrequisition/:olm_keyno',
                    component: 'olmRequisition',
                })
                .state('prepareboms', {
                    url: '/prepareboms',
                    component: 'prepareBoms',
                })
                .state('requisitionplanview', {
                    url: '/requisitionplanview',
                    component: 'requisitionPlanView',
                })
                .state('requisition', {
                    url: '/requisition/:requisition_no',
                    component: 'requisition',
                })
                .state('requisitiontoconsignment', {
                    url: '/requisitiontoconsignment/:stockplace_id_from/:stockplace_id_to/:consignment_keyno',
                    component: 'requisitionToConsignment',
                })
                .state('requisitions', {
                    url: '/requisitions',
                    component: 'requisitions',
                })
                .state('requisitionsbetween', {
                    url: '/requisitionsbetween',
                    component: 'requisitionsBetween',
                })
                .state('requisitioncreate', {
                    url: '/requisitioncreate/:stock_keyno_from/:stock_keyno_to',
                    component: 'requisitionCreate',
                })
                .state('requisitioncreatefrom', {
                    url: '/requisitioncreatefrom/:stock_keyno',
                    component: 'requisitionCreateFrom',
                })
                .state('requisitionplanning', {
                    url: '/requisitionplanning',
                    component: 'requisitionPlanning',
                })
                .state('requisitionsimplified', {
                    url: '/requisitionsimplified',
                    component: 'requisitionSimplified',
                })
                .state('shipmentcontainer', {
                    url: '/shipmentcontainer/:shipmentcontainer_keyno',
                    component: 'shipmentContainer',
                })
                .state('shipmentcontainers', {
                    url: '/shipmentcontainers',
                    component: 'shipmentContainers',
                })
                .state('stockget', {
                    url: '/stockget/:stock_keyno',
                    component: 'stockGet',
                })
                .state('stockforecast', {
                    url: '/stockforecast/:prod_id/:prodsize_keyno/:prodchoice_keyno/:stockplace_id',
                    component: 'stockForecast',
                })
                .state('stockall', {
                    url: '/stockall/:stock_keyno',
                    component: 'stockAll',
                })
                .state('stockcorrection', {
                    url: '/stockcorrection/:stock_keyno/:stocklocation_keyno',
                    component: 'stockCorrection',
                })
                .state('stockstockbatchassigned', {
                    url: '/stockstockbatchassigned/:stock_keyno',
                    component: 'stockStockBatchAssigned',
                })
                .state('stockhistoric', {
                    url: '/stockhistoric',
                    component: 'stockHistoric',
                })
                .state('stockhistory', {
                    url: '/stockhistory/:stock_keyno',
                    component: 'stockHistory',
                })
                .state('stockincoming', {
                    url: '/stockincoming/:stock_keyno',
                    component: 'stockIncoming',
                })
                .state('stockoutgoing', {
                    url: '/stockoutgoing/:stock_keyno',
                    component: 'stockOutgoing',
                })
                .state('createpopu', {
                    url: '/createpopu/:stock_keyno',
                    component: 'createPoPu',
                })
                .state('stockbatchlist', {
                    url: '/stockbatchlist/:stock_keyno',
                    component: 'stockBatchList',
                })
                .state('stockbatchget', {
                    url: '/stockbatchget/:stockbatch_keyno',
                    component: 'stockBatchGet',
                })
                .state('stockbatchtrace', {
                    url: '/stockbatchtrace/:stockbatch_keyno',
                    component: 'stockBatchTrace',
                })
                .state('guardlist', {
                    url: '/guardlist/:argtype/:argvalue',
                    component: 'guardList',
                })
                .state('gisposition', {
                    url: '/gisposition/:gisposition_keyno',
                    component: 'gisPosition',
                })
                .state('gispositionadd', {
                    url: '/gispositionadd/:argtype/:argvalue/:lat/:lng',
                    component: 'gisPositionAdd',
                })
                .state('gridexportarg', {
                    url: '/gridexportarg/:argtype/:argvalue/:p2_exportproc_keyno',
                    component: 'gridExportArg',
                })
                .state('gridexports', {
                    url: '/gridexports/:argtype/:argvalue',
                    component: 'gridExports',
                })
                .state('mapview', {
                    url: '/mapview/:argtype/:argvalue',
                    component: 'mapView',
                })
                .state('myfontsize', {
                    url: '/myfontsize',
                    component: 'myFontSize',
                })
                .state('mypage', {
                    url: '/mypage/:labour_no',
                    // component: 'myPage',
                    component: MyPageComponent,
                })
                .state('myposition', {
                    url: '/myposition',
                    component: 'myPosition',
                })
                .state('position', {
                    url: '/position/:argtype/:argvalue/:position_keyno',
                    component: 'position',
                })
                .state('positions', {
                    url: '/positions/:argtype/:argvalue',
                    component: 'positions',
                })
                .state('bcr', {
                    url: '/bcr/:barcode',
                    component: 'bcr',
                })
                .state('findcustomers', {
                    url: '/findcustomers',
                    component: 'findCustomers',

                    pageChanged: {
                        header: {
                            translate: true,
                        },
                        backButton: {
                            translate: true,
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                        //bigButton: { visible: false },
                        homeButton: { visible: false },
                    },
                })
                .state('accessinfo', {
                    url: '/accessinfo/:argtype/:argvalue',
                    component: 'accessInfo',
                })
                .state('acreminderproposal', {
                    url: '/acreminderproposal',
                    component: 'acReminderProposal',
                })
                .state('acremltr', {
                    url: '/acremltr/:businessco_no/:acremltr_keyno',
                    component: 'acRemLtr',
                })
                .state('findperson', {
                    url: '/findperson',
                    component: 'findPerson',
                })
                .state('forecast', {
                    url: '/forecast/:forecast_keyno',
                    component: 'forecast',
                })
                .state('forecasts', {
                    url: '/forecasts',
                    component: 'forecasts',
                })
                .state('forecastdatagrid', {
                    url: '/forecastdatagrid',
                    component: 'forecastDataGrid',
                })
                .state('tracesb', {
                    url: '/tracesb/:stockbatch_keyno',
                    component: 'tracesB',
                })
                .state('tracesearch', {
                    url: '/tracesearch/:argtype/:argvalue',
                    component: 'traceSearch',
                })
                .state('selectcustomer', {
                    url: '/selectcustomer',
                    component: 'selectCustomer',
                })
                .state('customersmenu', {
                    url: '/customersmenu/:cust_no',
                    component: 'customersMenu',
                    title: '',
                })
                .state('deliverycustomer', {
                    url: '/deliverycustomer/:deliverycustomer_keyno/:cust_no',
                    component: 'deliveryCustomer',
                })
                .state('deliverycustomers', {
                    url: '/deliverycustomers/:cust_no',
                    component: 'deliveryCustomers',
                })
                .state('deliveryplanning', {
                    url: '/deliveryplanning',
                    component: 'deliveryPlanning',
                })
                .state('discgrpcust', {
                    url: '/discgrpcust/:discgrpcust_keyno',
                    component: 'discGrpCust',
                })
                .state('discgrpdatas', {
                    url: '/discgrpdatas',
                    component: 'discGrpDatas',
                })
                .state('discgrpcustlangs', {
                    url: '/discgrpcustlangs/:discgrpcust_keyno',
                    component: 'discGrpCustLangs',
                })
                .state('discgrpcusts', {
                    url: '/discgrpcusts',
                    component: 'discGrpCusts',
                })
                .state('discgrpprod', {
                    url: '/discgrpprod/:discgrpprod_keyno',
                    component: 'discGrpProd',
                })
                .state('discgrpprodlangs', {
                    url: '/discgrpprodlangs/:discgrpprod_keyno',
                    component: 'discGrpProdLangs',
                })
                .state('discgrpprods', {
                    url: '/discgrpprods',
                    component: 'discGrpProds',
                })
                .state('pictures', {
                    url: '/pictures/:argtype/:argvalue',
                    component: 'pictures',
                })
                .state('docpack', {
                    url: '/docpack/:docpack_keyno',
                    component: 'docPack',
                })
                .state('docpackdocadd', {
                    url: '/docpackdocadd/:docpackchapter_keyno',
                    component: 'docPackDocAdd',
                })
                .state('docpacksbadd', {
                    url: '/docpacksbadd/:docpack_keyno',
                    component: 'docPacksBAdd',
                })
                .state('docpacktype', {
                    url: '/docpacktype/:docpacktype_keyno',
                    component: 'docPackType',
                })
                .state('docpacktypechapter', {
                    url: '/docpacktypechapter/:docpacktype_keyno/:docpacktypechapter_keyno',
                    component: 'docPackTypeChapter',
                })
                .state('docpacktypechapters', {
                    url: '/docpacktypechapters',
                    component: 'docPackTypeChapters',
                })
                .state('docpacktypemenu', {
                    url: '/docpacktypemenu/:docpacktype_keyno',
                    component: 'docPackTypeMenu',
                })
                .state('docpacktypes', {
                    url: '/docpacktypes',
                    component: 'docPackTypes',
                })
                .state('documents', {
                    url: '/documents/:argtype/:argvalue',
                    component: 'documents',
                })
                .state('domobject', {
                    url: '/domobject/:domobject_keyno_parent/:domobject_keyno_child',
                    component: 'domObject',
                })
                .state('domobjectchild', {
                    url: '/domobjectchild/:domobject_keyno_parent/:domobjectchild_keyno/:language_id/:domobject_keyno_selected',
                    component: 'domObjectChild',
                })
                .state('domobjectsview', {
                    url: '/domobjectsview/:domobject_keyno',
                    component: 'domObjectsView',
                    title: '',
                })
                .state('domobjecttype', {
                    url: '/domobjecttype/:domobjecttype_keyno',
                    component: 'domObjectType',
                })
                .state('domobjecttypemenu', {
                    url: '/domobjecttypemenu/:domobjecttype_keyno',
                    component: 'domObjectTypeMenu',
                })
                .state('domobjecttypeprop', {
                    url: '/domobjecttypeprop/:domobjecttype_keyno/:domobjecttypeprop_keyno',
                    component: 'domObjectTypeProp',
                })
                .state('domobjecttypes', {
                    url: '/domobjecttypes',
                    component: 'domObjectTypes',
                })
                .state('picturebanks', {
                    url: '/picturebanks/:argtype/:argvalue',
                    //component: 'pictureBanks'
                    component: PictureBanksComponent,
                })
                .state('picturebank', {
                    url: '/picturebank/:picturebank_keyno',
                    //component: 'pictureBank'
                    component: PictureBankComponent,
                })
                .state('selectnewdoctype', {
                    url: '/selectnewdoctype/:argtype/:argvalue',
                    component: 'selectNewDoctype',
                })
                .state('selecttextilemodel', {
                    url: '/selecttextilemodel/:order_internal_no/:orderline_keyno',
                    component: 'selectTextileModel',
                })
                .state('docarc', {
                    url: '/docarc/:argtype/:argvalue/:docarc_keyno',
                    component: 'docArc',
                })
                .state('docarticle', {
                    url: '/docarticle/:docarticle_keyno',
                    component: 'docArticle',
                })
                .state('docarticleedit', {
                    url: '/docarticleedit/:docarticle_keyno',
                    component: 'docArticleEdit',
                })
                .state('docarticleporus', {
                    url: '/docarticleporus/:docarticle_keyno',
                    component: 'docArticlePorus',
                })
                .state('docarticlesearch', {
                    url: '/docarticlesearch',
                    component: 'docArticleSearch',
                })
                .state('docarticletopusergroup', {
                    url: '/docarticletopusergroup/:docarticletopusergroup_keyno',
                    component: 'docArticleTopUserGroup',
                })
                .state('docarticletopusergroups', {
                    url: '/docarticletopusergroups/:docarticle_keyno_top',
                    component: 'docArticleTopUserGroups',
                })
                .state('edgerelations', {
                    url: '/edgerelations',
                    component: 'edgeRelations',
                })
                .state('edgerelation', {
                    url: '/edgerelation/:edgerelation_keyno',
                    component: 'edgeRelation',
                })
                .state('special_cust_prod_calc', {
                    url: '/special_cust_prod_calc/:cust_no',
                    component: 'specialCustProdCalc',
                })
                .state('customer_discgrpprod', {
                    url: '/customer_discgrpprod/:cust_no',
                    component: 'customerDiscGrpProd',
                })
                .state('custgrp', {
                    url: '/custgrp/:custgrp_id',
                    component: 'custGrp',
                })
                .state('custgrplangs', {
                    url: '/custgrplangs/:custgrp_id',
                    component: 'custGrpLangs',
                })
                .state('custgrpmenu', {
                    url: '/custgrpmenu/:custgrp_id',
                    component: 'custGrpMenu',
                })
                .state('custgrps', {
                    url: '/custgrps',
                    component: 'custGrps',
                })
                .state('economymenu', {
                    url: '/economymenu',
                    // component: 'economyMenu',
                    component: EconomyMenuComponent,
                })
                .state('productmenu', {
                    url: '/productmenu',
                    component: ProductMenuComponent,
                    // component: 'productMenu',
                })
                .state('productbulkedit', {
                    url: '/productbulkedit',
                    component: 'productBulkEdit',
                })
                .state('ecommercestatus', {
                    url: '/ecommercestatus',
                    component: 'eCommerceStatus',
                })
                .state('p2notifications', {
                    url: '/p2notifications',
                    component: 'p2Notifications',
                })
                .state('helpdoc', {
                    url: '/helpdoc/:argtype/:argvalue',
                    component: 'helpdoc',
                })
                .state('helpdocsearch', {
                    url: '/helpdocsearch',
                    component: 'helpDocSearch',
                })
                .state('helpdocttchanged', {
                    url: '/helpdocttchanged',
                    component: 'helpdocttChanged',
                })
                .state('p2terminals', {
                    url: '/p2terminals',
                    component: 'p2Terminals',
                })
                .state('p2terminal', {
                    url: '/p2terminal/:p2_terminal_keyno',
                    component: 'p2Terminal',
                })
                .state('customeredit', {
                    url: '/customeredit/:cust_no',
                    component: 'customerEdit',
                })
                .state('customerpersons', {
                    url: '/customerpersons/:cust_no/:new_customer',
                    component: 'customerPersons',
                    params: {
                        cust_no: { squash: false },
                        new_customer: { squash: true, value: null },
                    },
                })
                .state('customstarif', {
                    url: '/customstarif/:customstarif_id',
                    component: 'customsTarif',
                })
                .state('customstariflang', {
                    url: '/customstariflang/:customstarif_id',
                    component: 'customsTarifLang',
                })
                .state('customstarifmenu', {
                    url: '/customstarifmenu/:customstarif_id',
                    component: 'customsTarifMenu',
                })
                .state('customstarifs', {
                    url: '/customstarifs',
                    component: 'customsTarifs',
                })
                .state('person', {
                    url: '/person/:cust_no/:pers_no/:new_customer',
                    component: 'person',
                    params: {
                        cust_no: { squash: false },
                        pers_no: { squash: false },
                        new_customer: { squash: true, value: null },
                    },
                })
                .state('notifications', {
                    url: '/notifications',
                    component: 'notifications',
                })
                .state('rfidconnect', {
                    url: '/rfidconnect/:argtype/:argvalue',
                    component: 'rfidConnect',
                })
                .state('rfidsearch', {
                    url: '/rfidsearch',
                    component: 'rfidSearch',
                })
                .state('timeprojects', {
                    url: '/timeprojects',
                    component: 'timeProjects',
                })
                .state('timeprojectswo', {
                    url: '/timeprojectswo/:project_keyno',
                    component: 'timeProjectsWo',
                })
                .state('timecustomers', {
                    url: '/timecustomers',
                    component: 'timeCustomers',
                })
                .state('timecustomerswo', {
                    url: '/timecustomerswo/:cust_no',
                    component: 'timeCustomersWo',
                })
                .state('selectproduct', {
                    url: '/selectproduct/:webpage_name/:parm1/:parm2',
                    component: 'selectProduct',
                })
                .state('seller', {
                    url: '/seller/:seller_id',
                    component: 'seller',
                })
                .state('sellers', {
                    url: '/sellers',
                    component: 'sellers',
                })
                .state('serviceobject', {
                    url: '/serviceobject/:serviceobject_keyno',
                    component: 'serviceObject',
                })
                .state('serviceobjectmenu', {
                    url: '/serviceobjectmenu/:serviceobject_keyno',
                    component: 'serviceObjectMenu',
                })
                .state('serviceobjects', {
                    url: '/serviceobjects/:argtype/:argvalue',
                    component: 'serviceObjects',
                })
                .state('serviceobjectsop', {
                    url: '/serviceobjectsop/:serviceobjectsop_keyno',
                    component: 'serviceObjectSop',
                })
                .state('serviceobjectnewact', {
                    url: '/serviceobjectnewact/:serviceobject_keyno',
                    component: 'serviceObjectNewAct',
                })
                .state('serviceobjecttype', {
                    url: '/serviceobjecttype/:serviceobjecttype_keyno',
                    component: 'serviceObjectType',
                })
                .state('serviceobjecttypeint', {
                    url: '/serviceobjecttypeint/:serviceobjecttype_keyno/:serviceobjecttypeint_keyno',
                    component: 'serviceObjectTypeInt',
                })
                .state('serviceobjecttypeints', {
                    url: '/serviceobjecttypeints/:serviceobjecttype_keyno',
                    component: 'serviceObjectTypeInts',
                })
                .state('serviceobjecttypemenu', {
                    url: '/serviceobjecttypemenu/:serviceobjecttype_keyno',
                    component: 'serviceObjectTypeMenu',
                })
                .state('serviceobjecttypes', {
                    url: '/serviceobjecttypes',
                    component: 'serviceObjectTypes',
                })
                .state('sop', {
                    url: '/sop/:sop_keyno',
                    component: 'sop',
                })
                .state('sopcl', {
                    url: '/sopcl/:sop_keyno/:sopcl_keyno',
                    component: 'sopCl',
                })
                .state('sopcls', {
                    url: '/sopcls/:sop_keyno',
                    component: 'sopCls',
                })
                .state('sopmenu', {
                    url: '/sopmenu/:sop_keyno',
                    component: 'sopMenu',
                })
                .state('sops', {
                    url: '/sops',
                    component: 'sops',
                })
                .state('sopvalid', {
                    url: '/sopvalid/:sop_keyno',
                    component: 'sopValid',
                })
                .state('p2subscriptionedit', {
                    url: '/p2subscriptionedit/:p2_subscription_keyno',
                    component: 'p2SubscriptionEdit',
                })
                .state('resetPassword', {
                    url: '/resetpassword/:userName',
                    component: 'resetPassword',
                })
                .state('productmovechoice', {
                    url: '/productmovechoice/:prod_id/:comp_keyno',
                    component: 'productMoveChoice',
                })
                .state('productmovechoices', {
                    url: '/productmovechoices/:prod_id',
                    component: 'productMoveChoices',
                })
                .state('srfollowup', {
                    url: '/srfollowup/:experience_keyno',
                    component: 'srFollowUp',
                })
                .state('srfollowupforward', {
                    url: '/srfollowupforward/:experience_keyno',
                    component: 'srFollowUpForward',
                })
                .state('srfollowups', {
                    url: '/srfollowups',
                    component: 'srFollowUps',
                })
                .state('sroperationmanualadd', {
                    url: '/sroperationmanualadd',
                    component: 'srOperationManualAdd',
                })
                .state('srresource', {
                    url: '/srresource/:resource_id_belongto/:resource_id',
                    component: 'srResource',
                })
                .state('srresourceview', {
                    url: '/srresourceview',
                    component: 'srResourceView',
                })
                .state('stockplanning', {
                    url: '/stockplanning',
                    component: 'stockPlanning',
                })
                .state('stockplanningproduction', {
                    url: '/stockplanningproduction',
                    component: 'stockPlanningProduction',
                });

            $stateProvider
                .state('undodeliveryonorder', {
                    url: '/undodeliveryonorder/:order_internal_no',
                    component: 'undoDeliveryOnOrder',
                })
                .state('unit', {
                    url: '/unit/:unit_no',
                    component: 'unit',
                })
                .state('unitlang', {
                    url: '/unitlang/:unit_no',
                    component: 'unitLang',
                })
                .state('units', {
                    url: '/units',
                    component: 'units',
                })
                .state('usergroupedit', {
                    url: '/usergroupedit/:usergroup_keyno',
                    component: 'userGroupEdit',
                })
                .state('users', {
                    url: '/users',
                    component: 'users',
                })
                .state('usergroups', {
                    url: '/usergroups',
                    component: 'userGroups',
                })
                .state('usergroupsetting', {
                    url: '/usergroupsetting/:item_id',
                    component: 'usergroupSetting',
                })
                .state('usergroupsettingedit', {
                    url: '/usergroupsettingedit/:item_id',
                    component: 'usergroupSettingEdit',
                })
                .state('usergrouptable', {
                    url: '/usergrouptable/:argtype/:argvalue',
                    component: 'usergroupTable',
                })
                .state('usergrouptableedit', {
                    url: '/usergrouptableedit/:p2_usergroup_table_keyno',
                    component: 'usergroupTableEdit',
                })
                .state('usersettings', {
                    url: '/usersettings/:userid',
                    component: 'userSettings',
                })
                .state('usersettings_dialogue_userinfo', {
                    url: '/usersettings_dialogue_userinfo/:portalUserKeyno',
                    component: 'userSettingsDialogueUserInfo',
                })
                .state('userusergroup', {
                    url: '/userusergroup/:get_username',
                    component: 'userUsergroup',
                })
                .state('userusergroupedit', {
                    url: '/userusergroupedit/:p2_usergroup_portal_user_keyno',
                    component: 'userUsergroupEdit',
                })
                .state('valuta', {
                    url: '/valuta/:valuta_id',
                    component: 'valuta',
                })
                .state('valutas', {
                    url: '/valutas',
                    component: 'valutas',
                })
                .state('visitor', {
                    url: '/visitor',
                    component: 'visitor',
                    header: {
                        text: 'p2_m_visitor',
                        backButton: {
                            text: 'main_menu',
                            fallback: {
                                state: 'mainmenu',
                            },
                        },
                    },
                })
                .state('visitorconfirm', {
                    url: '/visitorconfirm/:inout/:pers_no',
                    component: 'visitorConfirm',
                })
                .state('webpagesubscription', {
                    url: '/webpagesubscription/:argtype/:argvalue',
                    component: 'webpageSubscription',
                })
                .state('webpagesubscriptionedit', {
                    url: '/webpagesubscriptionedit/:p2_webpage_subscription_keyno',
                    component: 'webpageSubscriptionEdit',
                })
                .state('websitenew', {
                    url: '/websitenew',
                    component: 'webSiteNew',
                })
                .state('websites', {
                    url: '/websites',
                    component: 'webSites',
                });
        },
    ]);
})();
