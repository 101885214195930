<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.checkbox"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="checkbox"
>
    <input
        #checkboxRef
        class="tt-input__form-control tt-input__form-control--checkbox"
        type="checkbox"
        [(ngModel)]="_model"
        (ngModelChange)="onModelChange($event)"
        [attr.name]="id.checkbox"
        [attr.id]="id.checkbox"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
        [ngStyle]="style['checkbox']"
    />
</tt-form-field-base>
