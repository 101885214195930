<h2
    mat-dialog-title
    ttTranslateWord="smtp_change_password"
    class="tt-secondary-background"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
    style="color: white"
></h2>
<mat-dialog-content>
    <div class="col-xs-12 p-12">
        <tt-password-input ttLabel="smtp_new_password" ttLabelView="top" [(ttModel)]="newPassword"></tt-password-input>
        <tt-password-input ttLabel="repeat_password" ttLabelView="top" [(ttModel)]="repeatPassword" ttHideToggleButton></tt-password-input>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <tt-button class="col-xs-6 col-md-2 sp-2 pull-right" ttText="cancel" ttType="secondary" (ttClick)="cancel()"></tt-button>
    <tt-button class="col-xs-6 col-md-2 sp-2 pull-right" ttText="ok" ttType="primary" (ttClick)="apply()" [ttDisabled]="newPassword !== repeatPassword"></tt-button>
</mat-dialog-actions>
