(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('oeeStateLog', {
        templateUrl: 'views/components/views/oeeStateLog/oeeStateLog.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'oeeStateLogService', 'equipmentEditService', function ($stateParams, stateService, oeeStateLogService, equipmentEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let equipmentId = $stateParams.equipment_id;

            vm.model = {
                bgOeeStateLogHistory: {
                    selected: 'A',
                    buttons: [
                        { id: 'A', label: 'oee_active', color: 'primary', onClick: () => vm.model.bgOeeStateLogHistory.selected = 'A' },
                        { id: 'H', label: 'oee_history', color: 'primary', onClick: () => vm.model.bgOeeStateLogHistory.selected = 'H' }
                    ]
                },
                bgOeeStateLogApprove: {
                    selected: 'not_confirmed',
                    buttons: [
                        { id: 'not_confirmed', label: 'oee_not_approved', color: 'primary', onClick: () => vm.model.bgOeeStateLogApprove.selected = 'not_confirmed' },
                        { id: 'confirmed', label: 'oee_approved', color: 'primary', onClick: () => vm.model.bgOeeStateLogApprove.selected = 'confirmed' },
                        { id: 'all', label: 'all', color: 'primary', onClick: () => vm.model.bgOeeStateLogApprove.selected = 'all' }
                    ]
                },
                menu: {},
                setting: {},
                itemsListStateLog: [],
                lockedApprove: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                oeeStateLogService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadMenu = function () {
                equipmentEditService.getEdit({ equipment_id: equipmentId }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            let loadStateLog = function () {
                oeeStateLogService.listStateLog({
                    equipment_id: equipmentId,
                    mainselector: vm.model.bgOeeStateLogHistory.selected,
                    selector: vm.model.bgOeeStateLogApprove.selected
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStateLog);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON RADIO SINGLE FUNCTIONS

            vm.selectBgOeeStateLogHistory = function (value) {
                vm.model.bgOeeStateLogHistory.selected = value;

                loadStateLog();
            };

            vm.selectBgOeeStateLogApprove = function (value) {
                vm.model.bgOeeStateLogApprove.selected = value;

                loadStateLog();
            };

            // #endregion BUTTON RADIO SINGLE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.approveChanges = function () {
                vm.model.lockedApprove = true;

                oeeStateLogService.approveItems({
                    oeedata_keyno: '0',
                    equipment_id: equipmentId
                }).then(function () {
                    vm.model.lockedApprove = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedApprove = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
                loadSetting();
                loadStateLog();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
