(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupTableEdit', {
        templateUrl: 'views/components/views/usergroupTableEdit/usergroupTableEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2UserGroupTableKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'usergroupTableEditService', 'bspMenuService', 'layoutService', function ($stateParams, stateService, usergroupTableEditService, bspMenuService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2UserGroupTableKeyno = vm?.p2UserGroupTableKeyno ?? $stateParams.p2_usergroup_table_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            vm.model = {
                tabletSize: tabletSize,
                recordRightsButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.recordright_no = '10' }
                ],
                edit: {},
                selectListRecordRights: [],
                lockedSave: false

            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                usergroupTableEditService.getEdit({ p2_usergroup_table_keyno: p2UserGroupTableKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadRecordRights = function () {
                bspMenuService.listRecordRights().then(function (list) {
                    angular.copy(list, vm.model.selectListRecordRights);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                usergroupTableEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;

                    if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                        vm.onSave();
                    } else {
                        stateService.back();
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
                //loadRecordRights();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2UserGroupTableKeyno?.currentValue) {
                    p2UserGroupTableKeyno = changes.p2UserGroupTableKeyno.currentValue;
                }

                if (p2UserGroupTableKeyno) {
                    loadEdit();
                    loadRecordRights();
                }
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
