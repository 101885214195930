<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.input"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttTextAlign]="ttTextAlign"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttTabable]="ttTabable"
    [ttStyle]="ttStyle"
>
    <input
        #inputRef
        class="tt-input__form-control"
        type="text"
        [attr.id]="id.input"
        [(ngModel)]="ttModel"
        (ngModelChange)="onModelChanged($event)"
        [maxlength]="ttMaxLength"
        [inputMode]="ttInputMode"
        [placeholder]="placeholder"
        [type]="ttType"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        [ngStyle]="style['input']"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
    />
    <ng-container buttons>
        <tt-form-field-button *ngIf="ttShowClearButton" ttId="$clear" ttIcon="fas fa-eraser" ttType="danger" (ttClick)="clearInput()"></tt-form-field-button>
        <tt-form-field-buttons buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
    </ng-container>
</tt-form-field-base>
