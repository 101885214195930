(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('priceListBusCos', {
        templateUrl: 'views/components/views/priceListBusCos/priceListBusCos.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'priceListBusCosService', function ($stateParams, priceListBusCosService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let priceListId = $stateParams.pricelist_id;

            vm.model = {
                itemsListBusinessCompanies: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            async function loadBusinessCompanies() {
                vm.model.itemsListBusinessCompanies = (await priceListBusCosService.listBusinessCompanies({ pricelist_id: priceListId }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadBusinessCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
