<tt-page *ngIf="!!get">
    <tt-card class="col-xs-12 col-md-6 p-5" style="max-width: 600px">
        <tt-input class="col-xs-12 sp-0" ttLabel="businessco_companyname" ttLabelView="top" [ttModel]="get.businessco_companyname" ttReadonly></tt-input>

        <div class="col-xs-12 sp-0" style="display: flex; gap: 4px; justify-content: space-between; align-items: flex-end">
            <tt-input class="col-xs-8 sp-0" ttLabel="smtp_server" ttLabelView="top" ttRequired [(ttModel)]="get.smtp_server"></tt-input>
            <tt-input-number class="col-xs-4 sp-0" ttLabel="smtp_port" ttLabelView="top" ttRequired [(ttModel)]="get.smtp_port" ttDecimals="0"></tt-input-number>
        </div>

        <tt-checkbox class="col-xs-12 sp-0" ttLabel="auth_required" ttLabelView="top" [(ttModel)]="get.auth_required"></tt-checkbox>

        <div *ngIf="get.auth_required === '1'" class="col-xs-12 p-12 pt-0">
            <tt-input class="col-xs-12 sp-0" ttLabel="smtp_auth_username" ttLabelView="top" ttRequired [ttSublabel]="get.smtp_auth_username_subtext" [(ttModel)]="get.smtp_auth_username"></tt-input>
            <ng-container *ngIf="get.update_password === '1'">
                <tt-password-input class="col-xs-12 sp-0" ttLabel="smtp_auth_password" ttLabelView="top" [(ttModel)]="newPassword" ttReadonly></tt-password-input>
                <tt-button ttType="ghost-danger" ttText="undo_password_change" (ttClick)="undoChangePassword()"></tt-button>
            </ng-container>
            <tt-button class="col-xs-12 col-md-9 sp-0" style="max-width: 250px" ttText="smtp_change_password" (ttClick)="openChangePasswordDialog()"></tt-button>
        </div>

        <tt-input class="col-xs-12 sp-0" ttLabel="email" ttLabelView="top" [(ttModel)]="get.email"></tt-input>
        <tt-input class="col-xs-12 sp-0" ttLabel="email_bcc" ttLabelView="top" [(ttModel)]="get.email_bcc"></tt-input>

        <hr class="col-xs-12 sp-0" />

        <tt-button
            class="col-xs-12 sp-0"
            ttText="test_email_send"
            (ttClick)="sendTestEmail()"
            [ttLoading]="sendingTestEmail"
            ttLoadingText="test_email_send"
            ttIcon="far fa-paper-plane"
            [ttDisabled]="!get.smtp_server || !get.smtp_port || (get.auth_required === '1' ? !get.smtp_auth_username : false)"
        ></tt-button>
        <tt-button-save
            class="col-xs-12 sp-0"
            (ttClick)="save()"
            [ttLoading]="saving"
            [ttDisabled]="!get.smtp_server || !get.smtp_port || (get.auth_required === '1' ? !get.smtp_auth_username : false)"
        ></tt-button-save>
    </tt-card>
</tt-page>
