(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinishProdCompAssign', {
        templateUrl: 'views/components/views/poFinishProdCompAssign/poFinishProdCompAssign.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'poFinishProdCompAssignService', 'ttGridFactory', function ($stateParams, stateService, translateService, modalService, poFinishProdCompAssignService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoFinishProdCompKeyno = $stateParams.p2_pofinishprodcomp_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {}
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_pofinishprodcompassign_stockbatch_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await poFinishProdCompAssignService.getEdit({ p2_pofinishprodcomp_keyno: p2PoFinishProdCompKeyno }))[0];

                loadGrid();
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                vm.grid.dataTask.loadData = {
                    method: 3572,
                    parameters: vm.model.edit
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                const currentData = vm.grid.gridfunc.getAllRows();
                let selectedRows = [];
                let mergeObj = {};

                currentData.forEach((item) => {
                    if (item.quantity_pick_now === 0) return;

                    selectedRows.push(item);
                });

                mergeObj = vm.model.edit;
                mergeObj.records = selectedRows;

                poFinishProdCompAssignService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                        if (window.location.href.includes('?ispopup')) {
                            window.opener.postMessage({
                                type: 'ttData',
                                data: mergeObj
                            });

                            setTimeout(() => {
                                window.close();
                            });
                        } else {
                            vm.model.lockedSave = false;
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
