(function () {
    'use strict';

    class TranslateEditController {

        model;

        static $inject = ['$stateParams', 'stateService', 'translateListEditService', 'userService', 'googleTranslateService'];
        constructor($stateParams, stateService, translateListEditService, userService, googleTranslateService) {
            Object.assign(this, { $stateParams, stateService, translateListEditService, userService, googleTranslateService });

            this.model = {
                itemId: this.$stateParams.item_id,
                get: {},
                translateFromLanguageList: [],
                translateFromLanguageButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', onClick: () => this.model.get.translateedit_translate_from_language = '' }
                ],
                translateEdits: [],
                lockedSave: false,
                translateAll: '1',
            };
        };

        fillField(translation) {
            translation.wordlang_name = translation.translated_name;
        };

        fillAllFields() {
            this.model.translateEdits.forEach((translation, index) => {
                if (translation?.translated_name && index < this.model.translateEdits.length - 1) {
                    translation.wordlang_name = translation.translated_name
                }
            });
        };

        fillAllNotTranslated() {
            this.model.translateEdits.forEach((translation, index) => {
                if (translation?.translated_name && translation.wordlang_name === this.model.itemId && index < this.model.translateEdits.length - 1) {
                    translation.wordlang_name = translation.translated_name;
                }
            });
        };

        onTranslateAllChanged(value) {
            console.dir(value);
            if (value === '1') {
                this.model.translateEdits.forEach((translation) => {
                    translation.toTranslate = '1';
                });
                this.model.translateAll = '1';
            } else if (value === '0') {
                this.model.translateEdits.forEach((translation) => {
                    translation.toTranslate = '0';
                });
                this.model.translateAll = '0';
            } else {
                const isAllChecked = this.model.translateEdits.filter((translation) => translation.toTranslate === '1').length === this.model.translateEdits.length;
                this.model.translateAll = isAllChecked === true ? '1' : '0';
            }
        };


        onToTranslateChanged(value, translation) {
            translation.toTranslate = value;

            this.onTranslateAllChanged();
        }

        async translate() {
            let translateFromLanguageId = null;

            if (this.model.get.translateedit_translate_from_language === '') {
                translateFromLanguageId = await this.googleTranslateService.detectLanguage(this.model.get.translateedit_text_to_translate?.toLowerCase()) ?? null;
            } else {
                translateFromLanguageId = this.model.get.translateedit_translate_from_language?.toLowerCase();
            }

            for (let i = 0; i < this.model.translateEdits.length; i++) {
                if (this.model.translateEdits[i].toTranslate === '1') {
                    if (this.model.translateEdits[i].google_language_id === 'nn' && (translateFromLanguageId === 'no' || translateFromLanguageId === 'sv' || translateFromLanguageId === 'da')) {
                        this.model.translateEdits[i].wordlang_name = await this.#newNorwegianTranslation(this.model.get.translateedit_text_to_translate, translateFromLanguageId);
                    } else if (this.model.translateEdits[i].google_language_id === translateFromLanguageId) {
                        this.model.translateEdits[i].wordlang_name = this.model.get.translateedit_text_to_translate;
                    } else if (this.model.translateEdits[i].language_id !== 'sy' && this.model.translateEdits[i].language_id !== 'nn') {
                        this.model.translateEdits[i].wordlang_name = (await this.googleTranslateService.translateText(this.model.get.translateedit_text_to_translate, this.model.translateEdits[i].google_language_id, translateFromLanguageId))[0].translatedText;
                    }
                }
            }
        };

        saveTranslations() {
            this.model.lockedSave = true;

            const params = { wordlang_names: this.model.translateEdits };

            this.translateListEditService.save(params).then(() => {
                this.model.lockedSave = false;
                this.stateService.back();
            });
        };

        async $onInit() {
            this.model.get = (await this.translateListEditService.getTranslateEdit(this.model.itemId))[0];
            this.model.translateEdits = (await this.translateListEditService.loadTranslateEditList(this.model.itemId)).map((translation) => {
                return { ...translation, toTranslate: '1' }
            });
            this.model.translateFromLanguageList = await this.translateListEditService.loadLanguageList('1');
        };

        async #newNorwegianTranslation(text, translatedFrom) {
            translatedFrom = translatedFrom.toLowerCase();
            if (translatedFrom === 'no' || translatedFrom === 'sv' || translatedFrom === 'da') {

                if (translatedFrom.toString() === 'no') {
                    translatedFrom = 'nob';
                }

                if (translatedFrom.toString() === 'da') {
                    translatedFrom = 'dan';
                }

                if (translatedFrom.toString() === 'sv') {
                    translatedFrom = 'swe';
                }

                const formData = new FormData();
                formData.append('langpair', translatedFrom + '|nno');
                formData.append('markUnknown', 'no');
                formData.append('prefs', '');
                formData.append('q', text);

                let response = await fetch('https://apertium.org/apy/translate', { method: 'POST', body: formData });
                let parsed = await response.json();
                return parsed.responseData.translatedText;
            }
        };
    }

    let module = angular.module('imApp');

    module.component('translateEdit', {
        templateUrl: 'views/components/views/translateEdit/translateEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: TranslateEditController
    });
})();
