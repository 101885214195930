import { Component, Input, OnInit } from '@angular/core';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { StateService } from '@app/core/services/state.service';
import { SirvService } from '@app/services/sirv.service';

@Component({
    selector: 'tt-picture-banks',
    templateUrl: './picture-banks.component.html',
    styleUrls: ['./picture-banks.component.css'],
})
export class PictureBanksComponent implements OnInit {
    @Input()
    public argtype?: string;

    @Input()
    public argvalue?: string;

    /**
     * List of files to be uploaded to sirv/picture-bank.
     */
    public files: File[] = [];

    /**
     * List of pictures stored in sirv/picture-bank.
     */
    public pictures: Item[] = [];

    mobileSizeQuery = window.matchMedia('(max-width: 991px)');

    selectedPictureBank = '0';

    public get = {
        page_heading: '',
        argtype: '',
        argvalue: '',
    };

    public locks = {
        upload: false,
    };

    constructor(private state: StateService, private datatask: DataTaskService, private sirv: SirvService, private modal: ModalService) {}

    public uploadPictures() {
        this.locks.upload = true;

        setTimeout(() => (this.locks.upload = false), 6000);
    }

    public async uploadAndSaveFiles() {
        this.locks.upload = true;

        const progress = this.modal.openProgressDialog(0, this.files.length, 'w_picturebanks');
        let count = 0;

        try {
            const promises = this.files.map(async (file) => {
                const response = await this.uploadImageToSirv(file);
                const data = await this.savePictureBank(response);

                count++;
                progress.updateValue(count);

                return data;
            });

            const responses = await Promise.all(promises);

            const errors = responses.filter((response) => response.errorcode !== '0');

            if (errors.length > 0) {
                progress.updateStatus('invalid');
                progress.updateInvalidText(errors.reduce((message, error) => message + '\n' + error.errormessage, ''));
            } else {
                progress.updateStatus('finish');
                this.files = [];
            }
        } catch (error) {
            let errormessage = !!error && error instanceof Object ? JSON.stringify(error, null, 2) : `${error}`;
            progress.updateStatus('invalid');
            progress.updateInvalidText(errormessage);

            // this.modal.openErrorDialog(errormessage);
        } finally {
            this.loadPictures();
            this.locks.upload = false;
        }
    }

    public gotoPictureBank(item: Item) {
        if (this.mobileSizeQuery.matches && item.item_state) {
            // @ts-ignore
            this.state.go(item.item_state, item.item_parms);
        } else if (!this.mobileSizeQuery.matches) {
            // @ts-ignore
            this.selectedPictureBank = item.item_parms ?? '0';
        }
    }

    public getIconClassesFromItem(item: Item): string {
        if (item?.['item_glyphicon']?.startsWith('fa')) {
            return 'fas fa' + item['item_glyphicon'].replace(item['item_glyphicon'].split('-')[0], '');
        } else if (item?.['item_glyphicon']?.length && item['item_glyphicon'].length > 0) {
            return 'glyphicon ' + item.item_glyphicon;
        }
        return '';
    }

    private uploadImageToSirv(image: File) {
        if (!!image && image instanceof File && !!this.get.argtype) {
            return this.sirv.uploadImage(image, this.get.argtype);
        }

        throw Error('Error occured with image.');
    }

    private async savePictureBank({ image, imageUrl }: { image: Blob; imageUrl: string }): Promise<{ errorcode: string; errormessage: string }> {
        if (!!this.get.argtype && !!this.get.argvalue && !!image && image instanceof Blob && !!imageUrl && typeof imageUrl === 'string') {
            return (await this.datatask.Post(2529, { argtype: this.get.argtype, argvalue: this.get.argvalue, full_url: imageUrl, filesize_mb: (image.size / (1024 * 1024)).toFixed(2) }))[0];
        }

        throw Error('Invalid parameters for persisting a picture-bank record.');
    }

    private async loadPictureBanks() {
        console.log('getting picturebanks');
        if (!!this.argtype && !!this.argvalue) {
            console.log('getting picturebanks');
            this.get = (await this.datatask.Post(2493, { argtype: this.argtype, argvalue: this.argvalue }))[0];
        } else {
            throw Error('Missing argvalue and / or argtype');
        }
    }

    public async loadPictures() {
        if (!!this.argtype && !!this.argvalue) {
            this.pictures = await this.datatask.Post(2531, { argtype: this.argtype, argvalue: this.argvalue });
        }
    }

    async ngOnInit(): Promise<void> {
        if (!this.argtype) {
            this.argtype = this.state.getStateParams()['argtype'];
        }

        if (!this.argvalue) {
            this.argvalue = this.state.getStateParams()['argvalue'];
        }

        this.loadPictureBanks();
        this.loadPictures();
    }
}
