import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Grid } from '@app/core/components/grid/grid.model';
import { GridOptions, GridRow } from '@app/core/components/grid/grid.types';
import { P2UpdateScriptEditDialogComponent, P2UpdateScriptEditDialogData } from '@app/modal/p2-update-script-edit-dialog/p2-update-script-edit-dialog.component';

@Component({
    selector: 'tt-p2-update-scripts',
    templateUrl: './p2-update-scripts.component.html',
    styleUrls: ['./p2-update-scripts.component.css'],
})
export class P2UpdateScriptsComponent {
    constructor(private dialog: MatDialog) {}

    grid: GridOptions = new Grid()
        .setRememberId('p2updatescripts.grid')
        .setLoadDataFunction({ method: 3575, parameters: {} })
        .setToolbar({
            buttons: [
                {
                    name: 'new',
                    icon: 'far fa-plus',
                    text: 'register_new_p2_updatescript',
                    translate: true,
                    cssClass: 'tt-button im-grid-col-btn-xs tt-button--primary',
                    func: () => this.openP2UpdateScriptEditDialog({ p2UpdateScriptKeyno: '0' }),
                },
            ],
        })
        .setSpecialFunc({
            buttons: [
                {
                    name: 'edit',
                    icon: 'far fa-pencil',
                    tooltip: 'edit',
                    translateTooltip: true,
                    func: (row: GridRow) => this.openP2UpdateScriptEditDialog({ p2UpdateScriptKeyno: row['p2_updatescript_keyno'] }),
                },
            ],
        });

    private async openP2UpdateScriptEditDialog(data: P2UpdateScriptEditDialogData) {
        const dialogRef = this.dialog.open(P2UpdateScriptEditDialogComponent, { width: '75rem', maxWidth: '95vw', data: data });

        dialogRef.afterClosed().subscribe({
            next: (value) => {
                if (value === true) this.grid.gridfunc?.read?.();
            },
        });
    }
}
