import { Component, ElementRef, EventEmitter, Input, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { LabelView, Style } from '@app/core/services/core-component.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';

@Component({
    selector: 'tt-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.css'],
})
export class MultiSelectComponent extends FormFieldBaseComponent {
    @Input()
    override ttLabelView: LabelView = 'top';

    /**
     * The list of items that are possible to select in the multi-select.
     */
    @Input()
    ttData: { [key: string]: any }[] = [];

    @Output()
    ttDataChange = new EventEmitter<{ [key: string]: any }[]>();

    /**
     * The key name of the view-value property of the items, default is `'item_name'`.
     */
    @Input()
    ttDataName: string = 'item_name';

    /**
     * The key name of the id property of the items, default is `'item_id'`.
     */
    @Input()
    ttDataId: string = 'item_id';

    /**
     * The key name of the selected property of the items, default is `'is_selected'`.
     */
    @Input()
    ttDataSelected: string = 'is_selected';

    /**
     * The filter-value when filtering through the items.
     */
    @Input()
    ttFilterValue: string = '';

    /**
     * Emits event when the filter value has changed.
     */
    @Output()
    ttFilterValueChange = new EventEmitter<string>();

    /**
     * Whether or not all items in the multi-select are selected.
     */
    @Input()
    get ttAllSelected(): boolean {
        return this._allSelected;
    }
    set ttAllSelected(value: BooleanInput) {
        this._allSelected = coerceBooleanProperty(value);
    }
    private _allSelected = false;

    @Input()
    override ttStyle: Style = {};

    override style: Style = {
        group: {},
        input: {},
        checkbox: {},
        checkboxLabel: {},
    };

    /**
     * Event emitted when an item is selected, returns list of ids as the event.
     */
    @Output()
    ttSelect = new EventEmitter<unknown[]>();

    @Output()
    ttSelectItem = new EventEmitter<{ [key: string]: any }>();

    /**
     * Event emitted when the check all checkbox is toggled. Event returns `true` if all items becomes checked, `false` if all items becomes unchecked.
     */
    @Output()
    ttCheckAll = new EventEmitter<boolean>();

    id = {
        filter: crypto.randomUUID(),
    };

    @ViewChild('inputRef') inputRef?: ElementRef;

    /**
     * Sets the value of `ttAllSelected`.
     *
     * @param event the filter value.
     */
    onFilterChange(event: string) {
        this.ttAllSelected = this.isListChecked();
        this.ttFilterValueChange.emit(event);
    }

    select() {
        setTimeout(() => {
            console.dir(this.inputRef?.nativeElement);
            if (this.inputRef) {
                this.inputRef.nativeElement.select();
            }
        });
    }

    /**
     * Checks whether the filtered list has any unchecked items.
     *
     * @returns `true` if all filtered items are checked, false if not.
     */
    isListChecked() {
        let hasUnchecked = false;

        this.ttData.forEach((item) => {
            if (hasUnchecked) return;

            let itemValues = Object.values({ ...item, [this.ttDataSelected]: '', $$hashKey: '' })
                .filter((value) => typeof value === 'string')
                .toString()
                .toLowerCase();

            if (itemValues.includes(this.ttFilterValue.toLowerCase()) && item[this.ttDataSelected] === false) hasUnchecked = true;
            // if (item[this.ttDataSelected] === false) hasUnchecked = true;
        });

        return !hasUnchecked;
    }

    /**
     * Toggles the selected value of the given check-item.
     *
     * @param event the new checked value of the item.
     * @param item the item to set as checked.
     * @emits `(ttSelect)` event containing list of ids of the selected items.
     */
    toggleCheckItem(event: Event, item: { [key: string]: any }) {
        item[this.ttDataSelected] = (event.target as HTMLInputElement).checked;

        if (this.ttData.filter((item) => item[this.ttDataSelected] === '1').length === this.ttData.length) {
            this.ttAllSelected = true;
        } else {
            this.ttAllSelected = false;
        }

        this.ttSelect.emit(this.ttData.filter((checkitem) => checkitem[this.ttDataSelected] === true).map((checkitem) => checkitem[this.ttDataId]));
        this.ttSelectItem.emit(item);
        this.ttAllSelected = this.isListChecked();
    }

    /**
     * Toggles the selected values of all the items which matches the current filtervalue.
     *
     * @param event the value to toggle the items to.
     * @emits `(ttSelect)` event containing list of ids of the selected items.
     */
    toggleAllCheckItems(event: boolean) {
        this.ttData.forEach((item) => {
            if (this.ttFilterValue) {
                let itemValues = Object.values({ ...item, [this.ttDataSelected]: '', $$hashKey: '' })
                    .filter((value) => typeof value === 'string')
                    .toString()
                    .toLowerCase();

                if (itemValues.includes(this.ttFilterValue.toLowerCase())) {
                    item[this.ttDataSelected] = event ? true : false;
                }
            } else {
                item[this.ttDataSelected] = event ? true : false;
            }
        });

        this.ttSelect.emit(this.ttData.filter((checkitem) => checkitem[this.ttDataSelected] === true).map((checkitem) => checkitem[this.ttDataId]));
        this.ttAllSelected = this.isListChecked();
        this.ttCheckAll.emit(this.ttAllSelected);
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttAllSelected'] && this._allSelected) {
            this.ttData.forEach((item) => (item[this.ttDataSelected] = true));
        }

        this.ttAllSelected = this.isListChecked();
    }

    override ngOnInit(): void {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
                this.style['input'].height = info.height - 1 + 'px';
                this.style['checkbox'].height = info.size + 2 + 'px';
                this.style['checkbox'].width = info.size + 2 + 'px';
                this.style['checkboxLabel'].fontSize = info.fontSizes.textSize;
                this.style['checkboxLabel'].paddingTop = this.style['input'].paddingTop;
                this.style['checkboxLabel'].paddingRight = this.style['input'].paddingRight;
                this.style['checkboxLabel'].paddingLeft = this.style['input'].paddingLeft;
                this.style['checkboxLabel'].paddingBottom = this.style['input'].paddingBottom;
            }
        });
    }
}

@Pipe({
    standalone: true,
    name: 'multiSelectFilter',
})
export class MutliSelectFilter implements PipeTransform {
    transform(value: { [key: string]: any }[], filter: string, dataSelected: string): { [key: string]: any }[] {
        return value.filter((item) => {
            if (filter) {
                let itemValues = Object.values({ ...item, [dataSelected]: '', $$hashKey: '' })
                    .filter((value) => typeof value === 'string')
                    .toString()
                    .toLowerCase();

                if (!filter || itemValues.includes(filter.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });
    }
}
