<tt-page *ngIf="!!get">
    <tt-page-heading class="col-xs-12 sp-0"> <span ttTranslateWord="p2_m_p2datatasklogs"></span>: {{ get.portal_user_keyno }} </tt-page-heading>
    <tt-divider *ngIf="laptopMediaQuery.matches" class="col-xs-12 sp-0 flex-1" ttRememberId="p2_datatask-logs">
        <tt-divider-columns ttId="datatasklogs">
            <tt-divider-pane ttId="listview" ttSize="30">
                <div class="col-xs-12 p-2 flex-column height-full">
                    <ng-container [ngTemplateOutlet]="listview"></ng-container>
                </div>
            </tt-divider-pane>
            <tt-divider-pane *ngIf="laptopMediaQuery.matches" ttId="logview" ttSize="70">
                <tt-p2-datatask-log *ngIf="!!selectedLog" class="col-xs-12 sp-0 height-full" [p2DatataskLogKeyno]="selectedLog" isPageComponent="false"></tt-p2-datatask-log>
            </tt-divider-pane>
        </tt-divider-columns>
    </tt-divider>
    <div *ngIf="!laptopMediaQuery.matches" class="flex-1 flex-column col-xs-12 sp-0">
        <ng-container [ngTemplateOutlet]="listview"></ng-container>
    </div>
</tt-page>

<ng-template #listview>
    <ng-container *ngIf="!!get">
        <tt-card [ttHeading]="laptopMediaQuery.matches ? 'h_data_task_log' : undefined">
            <tt-button
                class="col-xs-12 col-md-6 sp-0"
                ttText="refresh_p2datataskloglist"
                ttIcon="far fa-sync-alt"
                (ttClick)="loadDatataskLogs()"
                ttLoadingText=""
                [ttLoading]="loadingDatataskLogs"
            ></tt-button>
            <tt-input class="col-xs-12 sp-0" ttLabel="searchtext" [(ttModel)]="get.searchtext" ttShowClearButton></tt-input>
        </tt-card>
        <tt-list
            *ngIf="'' + get.portal_user_keyno !== '0'"
            class="flex-1"
            style="overflow: auto"
            [ttData]="logs"
            [ttFilter]="get.searchtext"
            [ttRadioSelection]="laptopMediaQuery.matches"
            (ttRadioSelectionChanged)="onLogSelected($event)"
            [ttGoto]="!laptopMediaQuery.matches"
        ></tt-list>
        <tt-list *ngIf="'' + get.portal_user_keyno === '0'" class="flex-1" style="overflow: auto" [ttData]="logs" [ttFilter]="get.searchtext" [ttGoto]="true"></tt-list>
    </ng-container>
</ng-template>
