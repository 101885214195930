import {
    Component,
    OnInit,
    OnChanges,
    OnDestroy,
    Input,
    SimpleChanges,
    ViewChild,
    ElementRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from '../../../services';
import { LayoutService } from '../../services/layout.service';
import { ResponsiveService } from '../../services/responsive.service';
import { StateService } from '../../services/state.service';
import { UserService } from '../../services/user.service';
import { UserStore } from '../../services/user.store';
import { UtilityService } from '../../services/utility.service';

function uuid(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

// Some helper for checking numeric
function isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function isNullOrWhitespace(input: any) {
    if (typeof input === 'undefined' || input === null) return true;

    return input.toString().trim().length < 1;
}

function escapeRegExp(str: string) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

@Component({
    selector: 'tt-list-legacy',
    templateUrl: './tt-list-legacy.component.html',
    styleUrls: ['./tt-list-legacy.component.css']
})
export class TtListLegacyComponent implements OnInit, OnChanges, OnDestroy {
    // Old directive's scope inputs
    @Input() listItems: any[] | any = [];  // required
    @Input() sortItems?: any[];      // optional
    @Input() dataid?: string;
    @Input() dataname?: string;
    @Input() backitem?: string;
    @Input() ltype?: string;         // "check", "multi", etc.
    @Input() typevar?: string;
    @Input() ptypevar?: string;      // if property is needed
    @Input() mconfig?: any;          // used with ltype="multi"
    @Input() badge?: string;
    @Input() optionfunc?: Function;
    @Input() sortfunc?: Function;
    @Input() designVar?: string;
    @Input() orderby?: string;
    @Input() revOrder?: string;
    @Input() filterVal?: string;
    @Input() filterParm?: string;
    @Input() useDocIcon?: string;
    @Input() hideIcon?: boolean;
    @Input() itemSize?: string;

    // Additional
    @Input() ttClickable?: boolean;
    @Input() ttClick?: Function;
    @Input() ttEditmode?: boolean;
    @Input() ttHideGoto?: boolean;
    @Input() ttDeleteitem?: Function;
    @Input() ttChange?: Function;    // Fires when listItems is modified

    /** The big internal model replicating this.model. */
    public model: any = {
        rootId: uuid(),
        mId: uuid(),
        disableFilter: true,
        listIsDragged: false,
        didUpdate: false,
        sortMode: false,
        resizeMode: false,
        inlineEditMode: false,
        isKeypress: false,
        prosessingKeypress: false,
        gridClicked: false,
        currentInline: null,
        activeInlineId: null,
        elemInFocus: null,
        filterVal: '',
        filterParm: '',
        field: '',
        fieldWidth: '',
        fieldWidthMin: '8',
        sliderMinDefault: 8,
        sliderMin: 8,
        slidersChanging: false,
        colWidthChanging: false,
        validatingEditable: false,
        editableValidated: false,
        inlineEditUpdate: false,
        label: '',
        ltype: 'default',
        initialGlyphColor: '',
        itemLoc: { itemId: null, listFromId: null, listToId: null, listFromIndex: null, listToIndex: null },
        menuList: [],
        sortlist: [],
        glyphlist: [],
        glyphlist2: [],
        secondary: [],
        colList: [],
        numCol: 0,
        currentSize: '',
        appRoot: '',
        listViewTextStyle: {
            fontSize: '',
            paddingLeft: ''
        },
        deleteButtonStyle: {
            fontSize: ''
        },
        thumbnailStyle: {
            aspectRatio: '1 / 1',
            width: '',
            objectFit: 'cover'
        }
    };

    public fontSizes: any = {
        textSizeXL: '',
        textSize: '',
        textSizeS: '',
        textSizeSs: '',
        thumbnail: '50'
    };

    public slider = {
        min: this.model.sliderMin,
        options: {
            start: [20, 70],
            connect: [true],
            margin: this.model.sliderMin,
            padding: this.model.sliderMin,
            step: 0.5,
            tooltips: [true, true],
            range: { min: 0, max: 100 }
        },
        events: {
            slide: function (event: any) { }
        },
        functions: {
            setStarts: () => {
                if (!this.listItems[0] && !this.listItems[0].item_secondary) return;
                if (this.model.colList.length <= 0) return;

                var colCount = this.model.colList.length;

                var totalLength = 0;
                var sliderStarts = [];

                this.model.slidersChanging = true;

                for (var col = 0; col < colCount; col++) {
                    if (col === 0) {
                        if (this.listItems[0].item_col_width && isNumber(this.listItems[0].item_col_width)) {
                            if (parseFloat(this.listItems[0].item_col_width) > 100) {
                                this.listItems[0].item_col_width = 100;
                            }
                            sliderStarts.push(parseFloat(this.listItems[0].item_col_width));
                            totalLength += parseFloat(this.listItems[0].item_col_width);
                        } else {
                            sliderStarts.push(this.slider.min);
                            totalLength += this.slider.min;
                        }

                        if (totalLength >= 100) {
                            break;
                        }
                    }

                    if (this.model.colList[col].visible === true) {
                        if (this.model.colList[col].width > 100) {
                            this.model.colList[col].width = 100;
                        } else if (this.model.colList[col].width < this.slider.min) {
                            this.model.colList[col].width = this.slider.min;
                        }

                        if (col !== colCount - 1) {
                            sliderStarts.push((this.model.colList[col].width + totalLength));
                        }

                        totalLength += this.model.colList[col].width;
                    }
                }

                this.slider.options.start = sliderStarts;
                this.model.slidersChanging = false;
            },
            setConnections: () => {
                var sliderCount = this.slider.options.start.length;
                var sliderConnections = [];

                for (var col = 0; col <= sliderCount; col++) {
                    sliderConnections.push(true);
                }

                this.slider.options.connect = sliderConnections;
            },
            setTooltips: () => {
                var sliderCount = this.slider.options.start.length;
                var sliderTooltips = [];

                for (var col = 0; col < sliderCount; col++) {
                    sliderTooltips.push(true);
                }

                this.slider.options.tooltips = sliderTooltips;
            },
        }
    };

    public sortableOptions = {
        placeholder: "sort-list-placeholder",
        connectWith: ".sort-list-container",
        tolerance: 'pointer',
        start: () => { },
        update: (e: any, ui: any) => {
            this.model.listIsDragged = true;

            this.model.didUpdate = true;

            if (!ui.item.sortable.model) {
                ui.item.sortable.cancel();
            }

            this.model.itemLoc.itemId = ui.item.sortable.model.item_id;
            this.model.itemLoc.listFromIndex = ui.item.sortable.index;
            this.model.itemLoc.listToIndex = ui.item.sortable.dropindex;
            this.model.itemLoc.listFromId = ui.item.sortable.model.item_sort_id;
        },
        stop: (e: any, ui: any) => {
            // this callback has the changed model
            if (this.model.didUpdate === true) {
                this.findItemById(this.model.itemLoc, false);

                if (this.model.itemLoc.listFromId !== this.model.itemLoc.listToId) {
                    this.updateOrder(this.model.itemLoc.listFromId);
                    this.updateItemlist(this.model.itemLoc);
                }
                this.updateOrder(this.model.itemLoc.listToId);

                if (typeof this.sortfunc === 'function') {
                    this.sortfunc({ item: this.model.itemLoc });
                }
            }

            this.model.listIsDragged = false;
            this.model.didUpdate = false;
        }
    };

    private layoutSub?: Subscription;
    private responsiveSub?: Subscription;

    constructor(
        private layoutService: LayoutService,
        private stateService: StateService,
        private responsive: ResponsiveService,
        private utilityService: UtilityService,
        private userService: UserService,
        private userStore: UserStore,
        private appConfig: AppConfigService
    ) { }

    ngOnInit(): void {
        // replicate init logic
        this.model.mId = uuid();
        this.model.hideIcon = false;
        this.model.appRoot = this.appConfig.settings.baseUrl;
        this.model.currentSize = this.responsive.current;

        if (this.revOrder === 'true') {
            this.model.disableFilter = false;
        }

        // Subscribe layout
        this.layoutSub = this.layoutService.layoutChanged.subscribe(info => {
            if (!info) return;

            this.fontSizes = { ...info.fontSizes };

            this.model.deleteButtonStyle.fontSize = info.fontSizes.textSizeSs;

            if (this.itemSize === 'lg') {
                this.model.listViewTextStyle.fontSize = info.fontSizes.textSizeXL;
            } else {
                this.model.listViewTextStyle.fontSize = info.fontSizes.textSize;
            }

            this.model.listViewTextStyle.paddingLeft = info.padding.left + 'px';

            this.model.thumbnailStyle.width = info.fontSizes.thumbnail + 'px';
        });

        // Subscribe responsive
        this.responsiveSub = this.responsive.Current.subscribe(data => {
            this.model.currentSize = data;
        });

        this.slider.functions.setConnections();
    }

    private preventCustomClick: boolean = false;

    // Now all the event handler methods from your directive:
    onDeleteItem(item: any): void {
        this.preventCustomClick = true;

        if (this.ttDeleteitem) {
            this.ttDeleteitem({ $item: item });
        }

        setTimeout(() => {
            this.preventCustomClick = false;
        });
    }

    combineId(index: number | string, role: string) {
        return index + '-' + role + '-' + uuid();
    };

    findItemById(itemLoc: any, idFrom: any): void {
        var foundItem = false;
        if (this.model.sortlist) {
            for (var i = 0; i < this.model.sortlist.length; i++) {
                if (this.model.sortlist[i].items) {
                    for (var l = 0; l < this.model.sortlist[i].items.length; l++) {
                        if (this.model.sortlist[i].items[l].item_id === itemLoc.itemId) {
                            if (idFrom === true) {
                                itemLoc.listFromId = this.model.sortlist[i].key;
                            } else {
                                itemLoc.listToId = this.model.sortlist[i].key;
                            }
                            foundItem = true;
                            break;
                        }
                    }
                }

                if (foundItem === true) {
                    break;
                }
            }
        }
    };

    updateOrder(listId: any): void {
        if (this.model.sortlist) {
            for (var i = 0; i < this.model.sortlist.length; i++) {
                if (this.model.sortlist[i].key === listId && this.model.sortlist[i].items) {
                    for (var l = 0; l < this.model.sortlist[i].items.length; l++) {
                        this.model.sortlist[i].items[l].orderby = i * 100 + l;
                    }
                }
            }
        }
    };

    updateItemlist(itemLoc: any): void {
        if (this.listItems && this.listItems !== null && this.listItems.records && this.listItems.records !== null) {
            for (var i = 0; i < this.listItems.records.length; i++) {
                if (this.listItems.records[i].item_id === itemLoc.itemId) {
                    this.listItems.records[i].item_sort_id = itemLoc.listToId;
                    break;
                }
            }
        } else if (this.listItems && this.listItems !== null) {
            for (var j = 0; j < this.listItems.length; j++) {
                if (this.listItems[j].item_id === itemLoc.itemId) {
                    this.listItems[j].item_sort_id = itemLoc.listToId;
                    break;
                }
            }
        }
    };

    isCustomClick(item: any): boolean {
        if (this.preventCustomClick === true) return true; // BJS 20220731
        if (this.ttClickable === undefined || this.ttClickable === null) return false;
        if (this.ttClickable === true) return false;
        if (this.ttClick) {
            // call user function
            this.ttClick({ item });
        }
        return true;
    }

    goTo(l_item: any): void {
        if (this.isCustomClick(l_item)) return;

        if (l_item.item_state === 'customaction' && typeof l_item.item_action === 'function') {
            l_item.item_action(l_item);
        } else {
            if (!l_item.item_path) return;
            this.stateService.go(l_item.item_state, l_item.item_parms);
        }
    }

    goTab(l_item: any): void {
        if (this.isCustomClick(l_item)) return;
        if (!l_item.item_state) return;
        this.stateService.newTab(l_item.item_state, l_item.item_parms);
    }

    goBack(l_item: any): void {
        if (this.isCustomClick(l_item)) return;
        if (!l_item.item_id) return;
        if (this.optionfunc) {
            const result = this.optionfunc({ item: l_item });
            if (result instanceof Promise) {
                result.then(() => {
                    this.stateService.back();
                });
            } else {
                this.stateService.back();
            }
        } else {
            this.stateService.back();
        }
    }

    goCheck(item: any): void {
        if (this.isCustomClick(item)) return;
        if (item.item_id === '0' || item.item_id === '-1') return;


        if (item.item_glyphicon_color === this.model.initialGlyphColor) {
            if (item.item_glyphicon_2 !== null) {
                item.item_is_selected = '1';
            }
            item.item_glyphicon_color = 'green';
        } else if (this.model.initialGlyphColor !== '') {
            item.item_glyphicon_color = this.model.initialGlyphColor;
            item.item_is_selected = '0';
        } else {
            item.item_glyphicon_color = 'red';
            item.item_is_selected = '0';
        }
    }

    goMark(l_item: any): void {
        if (this.isCustomClick(l_item)) return;
        if (!l_item.item_id || l_item.item_id === '0' || l_item.item_id === '-1') return;

        if (l_item.item_is_selected === '0') {
            l_item.item_is_selected = '1';
        } else {
            l_item.item_is_selected = '0';
        }
        if (this.optionfunc) {
            this.optionfunc({ item: l_item });
        }
    }

    goSwitch(item: any): void {
        if (this.isCustomClick(item)) return;

        if (item && item.item_id && typeof this.mconfig.function === 'function') {
            if (item.item_name === 'sort') {
                this.model.sortMode = !this.model.sortMode;
            }
            if (item.item_name === 'resize') {
                this.model.resizeMode = !this.model.resizeMode;

                if (this.model.inlineEditMode === true) {
                    this.model.inlineEditMode = false;
                    this.mconfig.deactivate('edit');
                }

                if (this.model.resizeMode === true) {
                    this.slider.functions.setStarts();
                    this.slider.functions.setConnections();
                    this.slider.functions.setTooltips();
                }
            }

            if (item.item_name === 'edit') {
                this.model.inlineEditMode = !this.model.inlineEditMode;

                if (this.model.resizeMode === true) {
                    this.model.resizeMode = false;
                    this.mconfig.deactivate('resize');
                }

                if (!this.model.inlineEditMode) {
                    this.model.editableValidated = false;
                }
            }
            this.mconfig.function(item);
        } else if (item === 'switch') {
            this.mconfig.show = !this.mconfig.show;

            if (this.mconfig.show === false) {
                this.model.sortMode = false;
                this.model.resizeMode = false;
                this.model.inlineEditMode = false;
                this.model.editableValidated = false;

                if (typeof this.mconfig.deactivate === 'function') {
                    this.mconfig.deactivate('all');
                }
            } else if (this.mconfig.show === true) {
                if (this.mconfig.records.length > 1) {
                    var recCount = 0;
                    var lastEnabledRec = null;
                    var didActivateARec = false;

                    for (var r = 0; r < this.mconfig.records.length; r++) {
                        if ((this.mconfig.records[r].isIcon !== true)
                            && (this.mconfig.records[r].show === true)
                            && (this.mconfig.records[r].enabled === true)) {
                            recCount++;
                            lastEnabledRec = this.mconfig.records[r];
                            if (lastEnabledRec.isActive === true) {
                                didActivateARec = true;

                                this.mconfig.activate(lastEnabledRec);

                                switch (lastEnabledRec.item_name) {
                                    case 'option':
                                        break;
                                    case 'sort':
                                        this.model.sortMode = true;
                                        break;
                                    case 'resize':
                                        this.model.resizeMode = true;
                                        this.slider.functions.setStarts();
                                        this.slider.functions.setConnections();
                                        this.slider.functions.setTooltips();
                                        break;
                                    case 'edit':
                                        this.model.inlineEditMode = true;
                                        break;
                                }
                            }
                        }
                    }
                    if (recCount === 1 && !didActivateARec) {
                        if (typeof this.mconfig.deactivate === 'function') {
                            this.mconfig.activate(lastEnabledRec);
                        }

                        switch (lastEnabledRec.item_name) {
                            case 'option':
                                break;
                            case 'sort':
                                this.model.sortMode = true;
                                break;
                            case 'resize':
                                this.model.resizeMode = true;
                                this.slider.functions.setStarts();
                                this.slider.functions.setConnections();
                                this.slider.functions.setTooltips();
                                break;
                            case 'edit':
                                this.model.inlineEditMode = true;
                                break;
                        }

                        if (typeof this.mconfig.function === 'function') {
                            this.mconfig.function(lastEnabledRec);
                        }
                    }
                }
            }
        }
    }

    goSort(item: any): void {
        if (this.isCustomClick(item)) return;

        if (this.model.listIsDragged === true || this.model.sortMode === true || item.item_id === '') {
            return;
        }

        if (typeof this.optionfunc === 'function') {
            this.optionfunc({ item });
        }
        if (this.typevar && this.typevar.includes('g')) {
            if (item.item_path === '') return;

            this.stateService.go(item.item_state, item.item_parms);
        }
    }

    goCustom(l_item: any, e?: any): void {
        if (this.isCustomClick(l_item)) return;
        if (l_item.item_id === '') return;

        const target = e.target as HTMLInputElement;

        var child = null;

        if (this.model.inlineEditMode === true) {
            target.blur();

            this.model.currentInline = l_item;
            if (!e) return;

            child = this.findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);

            this.gridClick(child, 'edit');
            return;
        }

        if (this.ltype && this.ltype.indexOf('custom') <= -1 && (!this.typevar || this.typevar.indexOf('g') <= -1) && this.model.inlineEditMode === false) {
            this.model.currentInline = l_item;
            if (!e) return;

            child = this.findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);

            this.gridClick(child, 'item_func');

            if (this.model.gridClicked) {
                return;
            }
        }

        if (typeof this.optionfunc === 'function') {
            this.optionfunc({ item: l_item });
        }

        if (this.typevar && this.typevar.indexOf('g') > -1) {
            if (l_item.item_path === '') return;

            this.stateService.go(l_item.item_state, l_item.item_parms);
        }
    }

    // addMark -> returns a class name if item_is_selected => "gold" or something
    public addMark(item: any): string {
        var markClass = '';

        if (item.item_is_selected === '1') {
            //markClass = 'gold';
        }

        return markClass;
    }

    private cuttingPoints: any[] = [
        { first: 'col-md-11-25 col-sm-11 col-xs-10-75 col-xx-10-25', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-25 col-xs-0-25 col-xx-0-25', noGlyphs: 'col-md-11-5 col-sm-11-5 col-xs-11-25 col-xx-11', moreGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5' },
        { first: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75', noGlyphs: 'col-md-11 col-sm-11 col-xs-10-5 col-xx-10-25', moreGlyphs: 'col-md-10-75 col-sm-10-25 col-xs-9-25 col-xx-8-75' },
        { first: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', second: 'col-xx-0', view: 'col-md-0-5 col-sm-1 col-xs-1 col-xx-1-5', noGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', moreGlyphs: 'col-md-10-5 col-sm-9-75 col-xs-8-75 col-xx-8' },
        { first: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', second: 'col-xx-0', view: 'col-md-0-75 col-sm-1-5 col-xs-1-5 col-xx-2-25', noGlyphs: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', moreGlyphs: 'col-md-10-25 col-sm-9-25 col-xs-8-25 col-xx-7-25' },
        { first: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', second: 'col-xx-0', view: 'col-md-1 col-sm-2 col-xs-2 col-xx-3', noGlyphs: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', moreGlyphs: 'col-md-10 col-sm-8-75 col-xs-7-75 col-xx-6-5' },
        { first: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', second: 'col-xx-0', view: 'col-md-1-25 col-sm-2-5 col-xs-2-5 col-xx-3-75', noGlyphs: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', moreGlyphs: 'col-md-9-75 col-sm-8-25 col-xs-7-25 col-xx-5-75' },
        { first: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-5 col-sm-3 col-xs-3 col-xx-4-5', noGlyphs: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', moreGlyphs: 'col-md-9-5 col-sm-7-75 col-xs-6-75 col-xx-5' },
        { first: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-75 col-sm-3-5 col-xs-3-5 col-xx-4-5', noGlyphs: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-6-5', moreGlyphs: 'col-md-9-25 col-sm-7-25 col-xs-6-25 col-xx-5' },
        { first: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2 col-sm-4 col-xs-4 col-xx-4-5', noGlyphs: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-6-5', moreGlyphs: 'col-md-9 col-sm-6-75 col-xs-5-75 col-xx-5' },
        { first: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-25 col-sm-4-5 col-xs-4-5 col-xx-4-5', noGlyphs: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-6-5', moreGlyphs: 'col-md-8-75 col-sm-6-25 col-xs-5-25 col-xx-5' },
        { first: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-5 col-sm-5 col-xs-5 col-xx-4-5', noGlyphs: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-6-5', moreGlyphs: 'col-md-8-5 col-sm-5-75 col-xs-4-75 col-xx-5' },
        { first: 'col-md-8-5 col-sm-5-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-75 col-sm-5-5 col-xs-5-5 col-xx-4-5', noGlyphs: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8-25 col-sm-5-25 col-xs-4-25 col-xx-5' },
        { first: 'col-md-8-25 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-3 col-sm-6 col-xs-6 col-xx-4-5', noGlyphs: 'col-md-8-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8 col-sm-4-75 col-xs-4-75 col-xx-5' },
        { first: 'col-md-4-75 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-xx-0', noGlyphs: 'col-md-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-4-5 col-sm-4-5 col-xs-4-25 col-xx-5' }
    ];

    findCuttingPoint(cuttingLength: any, condition: any, noGlyphs: any, moreGlyphs: any, colView: any): any {
        var index = Math.floor(cuttingLength / 2 + 0.5);

        if (index < 1 || index > 12) {
            index = 13;
        }

        var first = moreGlyphs === true ? 'moreGlyphs' : noGlyphs === true ? 'noGlyphs' : 'first';
        var second = colView === true ? 'view' : 'second';

        var cuttingPoint1st = this.cuttingPoints[index][first];
        var cuttingPoint2nd = this.cuttingPoints[index][second];

        if (condition === 'cuttingPoint1st') return cuttingPoint1st;

        return condition === 'cuttingPoint2nd' ? cuttingPoint2nd : '';
    };

    addCol(condition: any, item: any): string {
        var allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
        var noGlyphs = true;
        var moreGlyphs = false;
        var cuttingPoint1st = '';
        var cuttingPoint2nd = '';

        if (this.model.glyphlist && this.model.glyphlist.length > 0) {
            for (var i = 0; i < this.model.glyphlist.length; i++) {
                if (this.model.glyphlist[i].length > 0 && this.model.glyphlist[i][0]) {
                    if (this.model.glyphlist[i][0].item_glyphicon === '') continue;

                    noGlyphs = false;

                    if (this.model.glyphlist[i].length > 1) {
                        moreGlyphs = true;

                        break;
                    }
                }
            }
        }

        var fullLength = 0;
        var columnNo = 0;

        if (this.listItems && this.listItems.length > 0) {
            var foundRowCol = false;

            for (var k = 0; k < this.model.item_secondary.length; k++) {
                for (var j = 0; j < this.model.secondary[k].length; j++) {
                    if (item === this.model.secondary[k][j]) {
                        columnNo = j;

                        foundRowCol = true;

                        break;
                    }
                }
                if (foundRowCol === true) {
                    break;
                }
            }

            fullLength = 0;
            var tempLength = 0;

            this.model.colList.sort(function (a: any, b: any) {
                return parseFloat(a.priority) - parseFloat(b.priority);
            });

            for (var l = 0; l < this.model.colList.length; l++) {
                if (this.model.colList[l].visible !== true) continue;

                if (this.model.colList[l].colLength % 2 === 0) {
                    tempLength += this.model.colList[l].colLength;
                } else {
                    tempLength += this.model.colList[l].colLength + 1;
                }

                this.model.colList[l].viewXX = false;
                this.model.colList[l].viewXS = false;
                this.model.colList[l].viewSM = false;
                this.model.colList[l].viewMD = false;
                this.model.colList[l].viewLG = false;

                switch (true) {
                    case tempLength < 12:
                        this.model.colList[l].viewXX = true;
                        this.model.colList[l].viewXS = true;
                        this.model.colList[l].viewSM = true;
                        this.model.colList[l].viewMD = true;
                        this.model.colList[l].viewLG = true;
                        break;
                    case tempLength < 20:
                        this.model.colList[l].viewXS = true;
                        this.model.colList[l].viewSM = true;
                        this.model.colList[l].viewMD = true;
                        this.model.colList[l].viewLG = true;
                        break;
                    case tempLength < 28:
                        this.model.colList[l].viewSM = true;
                        this.model.colList[l].viewMD = true;
                        this.model.colList[l].viewLG = true;
                        break;
                    case tempLength < 45:
                        this.model.colList[l].viewMD = true;
                        this.model.colList[l].viewLG = true;
                        break;
                    case tempLength < 60:
                        this.model.colList[l].viewLG = true;
                        break;
                }
            }

            this.model.colList.sort(function (a: any, b: any) {
                return parseFloat(a.orderby) - parseFloat(b.orderby);
            });

            const self = this;

            for (var m = 0; m < this.model.colList.length; m++) {
                var checkSize = function (size: any) {
                    if (self.model.currentSize !== size) return;

                    self.model.colList[m].view = self.model.colList[m]['view' + size.toUpperCase()] || false;
                };

                checkSize('xx');
                checkSize('xs');
                checkSize('sm');
                checkSize('md');
                checkSize('lg');
            }

            if (condition === 'main') {
                for (var n = 0; n < this.model.colList.length; n++) {
                    if (this.model.colList[n].view === true && this.model.colList[n].visible === true) {
                        if (this.model.colList[n].colLength % 2 === 0) {
                            fullLength += this.model.colList[n].colLength;
                        } else {
                            fullLength += this.model.colList[n].colLength + 1;
                        }
                    }
                }
            } else {
                fullLength = this.model.colList[columnNo].colLength;
            }

        }

        cuttingPoint2nd = this.findCuttingPoint(fullLength, 'cuttingPoint2nd', noGlyphs, moreGlyphs, this.model.colList[columnNo].view);
        cuttingPoint1st = this.findCuttingPoint(fullLength, 'cuttingPoint1st', noGlyphs, moreGlyphs, false);

        if (condition === 'leftGlyph') {
            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
            if (noGlyphs === true && (!this.mconfig || this.mconfig.enabled === false)) {
                allClass = 'col-xx-0 im-no-pad';
            }
            if (moreGlyphs === true || (this.mconfig && this.mconfig.enabled === true)) {
                allClass = 'col-md-0-5 col-sm-0-75 col-xs-1-25 col-xx-1-5 im-no-pad';
            }
        } else if (condition === 'rightGlyph') {
            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';

            if (this.typevar && this.typevar.indexOf('g') > -1) {
                allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 grayish pull-right im-chevron';
            }
        } else if (condition === 'rightGlyph2') {
            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 grayish pull-right im-chevron-2';
        } else if (condition === 'nonGlyph') {
            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';
        } else if (condition === 'main') {
            allClass = cuttingPoint1st + ' im-no-pad listview_text';
        } else if (condition === 'secondary') {
            if (this.model.colList[columnNo].alignment === 'left') {
                allClass = cuttingPoint2nd + ' im-no-pad im-l-c-l pull-right';
            } else if (this.model.colList[columnNo].alignment === 'center') {
                allClass = cuttingPoint2nd + ' im-no-pad im-l-c-c pull-right';
            } else {
                allClass = cuttingPoint2nd + ' im-no-pad im-l-c-r pull-right';
            }
        }

        return allClass;
    };

    addColArea(condition: string, item?: any): string {
        var allClass = '';
        var menuBtnInList = false;
        var glyphCount = 0;
        var leftArea = 'im-no-pad';
        var centerArea = 'im-no-pad';
        var rightArea = ''; //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75

        if (this.mconfig && this.mconfig.enabled === true && this.mconfig.show === false) {
            menuBtnInList = true;
        }

        if (this.model.glyphlist && this.model.glyphlist.length > 0) {
            for (var g = 0; g < this.model.glyphlist.length; g++) {
                if (this.model.glyphlist[g].length > 0 && this.model.glyphlist[g][0]) {
                    if (this.model.glyphlist[g][0].item_glyphicon === '') continue;

                    if (this.model.glyphlist[g].length > glyphCount) {
                        glyphCount = this.model.glyphlist[g].length;
                    }
                }
            }
        }

        if (menuBtnInList === true && glyphCount < 2) {
            glyphCount = 2;
        }

        switch (this.model.currentSize) {
            case 'xx': // 0-75 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                if (glyphCount === 0) {
                    leftArea += ' col-xx-0';
                    centerArea += ' col-xx-11-25';
                } else if (glyphCount === 1) {
                    leftArea += ' col-xx-0-75';
                    centerArea += ' col-xx-10-5';
                } else if (glyphCount === 2) {
                    leftArea += ' col-xx-1-5';
                    centerArea += ' col-xx-9-75';
                } else if (glyphCount === 3) {
                    leftArea += ' col-xx-2-25';
                    centerArea += ' col-xx-9';
                } else {
                    leftArea += ' col-xx-3';
                    centerArea += ' col-xx-8-25';
                }
                rightArea += 'col-xx-0-75';
                break;
            case 'xs': // 0-75 per glyph, 0-5 per glyph, alternating //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                if (glyphCount === 0) {
                    leftArea += ' col-xs-0';
                    centerArea += ' col-xs-11-5';
                } else if (glyphCount === 1) {
                    leftArea += ' col-xs-0-75';
                    centerArea += ' col-xs-10-75';
                } else if (glyphCount === 2) {
                    leftArea += ' col-xs-1-25';
                    centerArea += ' col-xs-10-25';
                } else if (glyphCount === 3) {
                    leftArea += ' col-xs-2';
                    centerArea += ' col-xs-9-5';
                } else {
                    leftArea += ' col-xs-2-5';
                    centerArea += ' col-xs-9';
                }
                rightArea += 'col-xs-0-5';
                break;
            case 'sm': // 0-5, then 0-25, then 0-25, then 0-5 //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                if (glyphCount === 0) {
                    leftArea += ' col-sm-0';
                    centerArea += ' col-sm-11-5';
                } else if (glyphCount === 1) {
                    leftArea += ' col-sm-0-5';
                    centerArea += ' col-sm-11';
                } else if (glyphCount === 2) {
                    leftArea += ' col-sm-0-75';
                    centerArea += ' col-sm-10-75';
                } else if (glyphCount === 3) {
                    leftArea += ' col-sm-1';
                    centerArea += ' col-sm-10-5';
                } else {
                    leftArea += ' col-sm-1-5';
                    centerArea += ' col-sm-10';
                }
                rightArea += 'col-sm-0-5';
                break;
            case 'md':
            case 'lg': // 0-25 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
            default:
                if (glyphCount === 0) {
                    leftArea += ' col-md-0';
                    centerArea += ' col-md-11-75';
                } else if (glyphCount === 1) {
                    leftArea += ' col-md-0-25';
                    centerArea += ' col-md-11-5';
                } else if (glyphCount === 2) {
                    leftArea += ' col-md-0-5';
                    centerArea += ' col-md-11-25';
                } else if (glyphCount === 3) {
                    leftArea += ' col-md-0-75';
                    centerArea += ' col-md-11';
                } else {
                    leftArea += ' col-md-1';
                    centerArea += ' col-md-10-75';
                }
                rightArea += 'col-md-0-25';
        }

        if (condition === 'leftArea' || condition === 'leftAreaSlider') {
            allClass = leftArea;
        } else if (condition === 'centerArea' || condition === 'centerAreaSlider') {
            allClass = centerArea;
        } else if (condition === 'rightArea' || condition === 'rightAreaSlider') {
            allClass = rightArea;

            if (condition !== 'rightAreaSlider' && item.item_id !== '0' && item.item_id !== '-1') {
                if (this.typevar && this.typevar.indexOf('g') > -1) {
                    allClass += ' glyphicon glyphicon-chevron-right grayish pull-right im-chevron';
                } else {
                    allClass += ' glyphicon glyphicon-none grayish pull-right';
                }
            }
        } else if (condition === 'nonGlyph') {
            allClass = leftArea + ' glyphicon glyphicon-none grayish pull-right';
        }

        if (this.model.inlineEditMode) {
            allClass += ' inline-edit-mode-parent';
        }

        return allClass;
    }

    addColItem(item: any): string {
        var allClass = '';

        if (item === 'main') {
            allClass = 'listview_text im-no-pad';

            if (this.model.inlineEditMode) {
                allClass += ' inline-edit-mode';
            }
        } else if (this.listItems && this.listItems.length > 0) {
            var columnNo = 0;
            var foundRowCol = false;

            for (var k = 0; k < this.model.secondary.length; k++) {
                for (var j = 0; j < this.model.secondary[k].length; j++) {
                    if (item === this.model.secondary[k][j]) {
                        columnNo = j;

                        foundRowCol = true;

                        break;
                    }
                }
                if (foundRowCol === true) {
                    break;
                }
            }

            if (this.model.colList[columnNo].alignment === 'left') {
                allClass = 'im-no-pad im-l-c-l pull-right';
            } else if (this.model.colList[columnNo].alignment === 'center') {
                allClass = 'im-no-pad im-l-c-c pull-right';
            } else {
                allClass = 'im-no-pad im-l-c-r pull-right';
            }

            if (this.model.inlineEditMode) {
                allClass += ' inline-edit-mode';
            }
        }

        return allClass;
    }

    public addMenuGlyph(item: any): string {
        var allClass = ''; //default

        if (item === 'switch') {
            allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn';
        } else if (item === 'plus') {
            allClass = 'glyphicon glyphicon-plus btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'minus') {
            allClass = 'glyphicon glyphicon-minus btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'trash') {
            allClass = 'glyphicon glyphicon-trash btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'sort') {
            allClass = 'glyphicon glyphicon-sort btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'resize') {
            allClass = 'glyphicon glyphicon-resize-horizontal btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'columns') {
            allClass = 'fa fa-columns imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item === 'edit') {
            allClass = 'fa fa-edit imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right';
        } else if (item.glyph && item.glyph !== null) {
            var glyphStr = item.glyph;
            if (item.glyph.startsWith('fa-')) {
                allClass = 'im-no-mar imp-12 fa ' + glyphStr;
            } else if (item.glyph.startsWith('fab-')) {
                allClass = 'im-no-mar imp-12 fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
            } else if (item.glyph.startsWith('fad-')) {
                allClass = 'im-no-mar imp-12 fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
            } else if (item.glyph.startsWith('fal-')) {
                allClass = 'im-no-mar imp-12 fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
            } else if (item.glyph.startsWith('far-')) {
                allClass = 'im-no-mar imp-12 far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
            } else if (item.glyph.startsWith('fas-')) {
                allClass = 'im-no-mar imp-12 fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
            } else {
                allClass = 'glyphicon ' + glyphStr;
            }

            if (item.isIcon === true) {
                if (item.animate === true) {
                    allClass += ' fadeinout-animate btn btn-light btn-sm multi-list-menu-icon-warning pull-right no-h';
                } else if (item.animate === false) {
                    allClass = 'glyphicon glyphicon-saved fadeout-animate btn btn-light btn-sm multi-list-menu-icon-success pull-right no-h';
                } else {
                    allClass = 'glyphicon glyphicon-none btn btn-light btn-sm multi-list-menu-icon pull-right no-h';
                }
            } else {
                if (item.canActive === true && item.isActive === true) {
                    allClass += ' btn btn-' + item.colorActive;
                } else {
                    if (item.color) {
                        allClass += ' btn btn-' + item.color;
                    } else {
                        allClass += ' btn btn-primary';
                    }
                }

                allClass += ' btn-sm multi-list-menu-btn pull-right';
            }
        }

        return allClass;
    }

    public addGlyph(item: any): string {
        var allClass = ''; //default

        if (item === 'switch') {
            allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn';
        } else if (item === 'sort') {
            allClass = 'glyphicon glyphicon-sort';
        } else if (this.ltype && this.ltype === 'check' && (!item.item_glyphicon || item.item_glyphicon === null || item.item_glyphicon === '')) {
            item.item_glyphicon = 'glyphicon-save';
        }

        if (item.item_glyphicon && item.item_glyphicon !== null) {
            var glyphStr = '';

            if (item.item_glyphicon_2 && item.item_glyphicon_2 !== null) {
                glyphStr = item.item_glyphicon_2;
                if (item.item_glyphicon_2.startsWith('fa-')) {
                    allClass = 'im-no-mar fa ' + glyphStr;
                } else if (item.item_glyphicon_2.startsWith('fab-')) {
                    allClass = 'im-no-mar fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon_2.startsWith('fad-')) {
                    allClass = 'im-no-mar fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon_2.startsWith('fal-')) {
                    allClass = 'im-no-mar fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon_2.startsWith('far-')) {
                    allClass = 'im-no-mar far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon_2.startsWith('fas-')) {
                    allClass = 'im-no-mar fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else {
                    allClass = 'glyphicon ' + glyphStr;
                }
            } else {
                glyphStr = item.item_glyphicon;
                if (item.item_glyphicon.startsWith('fa-')) {
                    allClass = 'im-no-mar fa ' + glyphStr;
                } else if (item.item_glyphicon.startsWith('fab-')) {
                    allClass = 'im-no-mar fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon.startsWith('fad-')) {
                    allClass = 'im-no-mar fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon.startsWith('fal-')) {
                    allClass = 'im-no-mar fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon.startsWith('far-')) {
                    allClass = 'im-no-mar far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (item.item_glyphicon.startsWith('fas-')) {
                    allClass = 'im-no-mar fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else {
                    allClass = 'glyphicon ' + glyphStr;
                }
            }
        }

        return allClass;
    }

    public addClass(): string {
        // replicate the old logic
        let allClass = 'well well-sq sort-list-container col-xs-12';

        if (this.model.sortlist && this.model.sortlist.length > 0) {
            switch (this.model.sortlist.length) {
                case 2:
                    allClass = 'well well-sq sort-list-container col-xs-6';
                    break;
                case 3:
                    allClass = 'well well-sq sort-list-container col-xx-4';
                    break;
                case 4:
                    allClass = 'well well-sq sort-list-container col-xx-6 col-xs-6 col-sm-3 col-lg-3';
                    break;
                case 6:
                    allClass = 'well well-sq sort-list-container col-xx-4 col-xs-2';
                    break;
                case 8:
                    allClass = 'well well-sq sort-list-container col-xx-1-5';
                    break;
                case 12:
                    allClass = 'well well-sq sort-list-container col-xs-1';
                    break;
                default:
                    allClass = 'well well-sq sort-list-container col-xs-12';
                    break;
            }
        }
        return allClass;
    }

    addThumbClass(item: any): string {
        var allClass = '';

        if (item.item_thumb && item.item_thumb !== null && item.item_thumb.length > 0) {

            allClass = 'im-list-btn--flex';
        }

        return allClass;
    };

    addItemClass(item: any): string {
        var allClass = '';

        allClass = 'sort-list-item btn btn-default listview btn-block btn-lg col-xs-12';

        if (item && item.item_type === 'info') {
            allClass += ' not-sortable';
        }

        return allClass;
    };

    public addStyle(item: any): any {
        var allStyle: any = {
            color: 'black',
            width: '19px'
        }; //default

        if (item.item_glyphicon_color && item.item_glyphicon_color !== null && item.item_glyphicon_color !== "black") {
            allStyle = {
                color: item.item_glyphicon_color,
                width: '19px'
            };

            if (this.model.initialGlyphColor === '') {
                this.model.initialGlyphColor = item.item_glyphicon_color;
            }
        }

        if (item === 'resize') {
            if (!this.model.fieldWidth || !isNumber(this.model.fieldWidth) || parseInt(this.model.fieldWidth) < parseInt(this.model.fieldWidthMin)) {
                allStyle = {
                    fontSize: this.fontSizes.textSize,
                    width: this.model.fieldWidthMin + '%',
                    minWidth: this.model.fieldWidthMin + '%',
                    maxWidth: '100%', textOverflow: 'ellipsis',
                    textAlign: 'center'
                };
            } else {
                allStyle = { fontSize: this.fontSizes.textSize, width: this.model.fieldWidth + '%', minWidth: this.model.fieldWidthMin + '%', maxWidth: '100%', textOverflow: 'ellipsis', textAlign: 'center' };
            }
        }

        return allStyle;
    }

    public addStyleMultiListBtn(item: any): any {
        var allStyle: any = {
            fontSize: this.fontSizes.textSize,
            paddingBottom: 0
        }; //default

        if (item && this.mconfig) {
            if (item.item_id === '0' && this.mconfig.enabled === true && this.mconfig.show === false) {
                allStyle = { fontSize: this.fontSizes.textSize };
            }
        }

        return allStyle;
    }

    addStyleCenterAreaItem(item: any, parentItem?: any): any {
        var allStyle = {
            fontSize: this.fontSizes.textSize,
            display: 'inline-block'
        }; //default

        if (item) {
            if (item.item_id === '0') {
                if (!item.item_col_width || !isNumber(item.item_col_width) || parseInt(item.item_col_width) < parseInt(this.model.fieldWidthMin)) {
                    allStyle = Object.assign(allStyle, { width: this.model.fieldWidthMin + '%', minWidth: this.model.fieldWidthMin + '%' });
                } else {
                    allStyle = Object.assign(allStyle, { width: item.item_col_width + '%', minWidth: this.model.fieldWidthMin + '%' });
                }
            } else {
                var columnNo = 0;
                var foundRowCol = false;

                for (var k = 0; k < this.model.secondary.length; k++) {
                    for (var j = 0; j < this.model.secondary[k].length; j++) {
                        if (item === this.model.secondary[k][j]) {
                            columnNo = j;

                            foundRowCol = true;

                            break;
                        }
                    }
                    if (foundRowCol === true) {
                        break;
                    }
                }

                if (foundRowCol) {
                    if (!this.model.colList[columnNo].width || !isNumber(this.model.colList[columnNo].width) || parseInt(this.model.colList[columnNo].width) < parseInt(this.model.fieldWidthMin)) {
                        allStyle = Object.assign(allStyle, { width: this.model.fieldWidthMin + '%', minWidth: this.model.fieldWidthMin + '%' });
                    } else {
                        allStyle = Object.assign(allStyle, { width: this.model.colList[columnNo].width + '%', minWidth: this.model.fieldWidthMin + '%' });
                    }

                    if (!this.model.colList[columnNo].visible || this.model.colList[columnNo].visible === false) {
                        allStyle = Object.assign(allStyle, { display: 'none' });
                    } else {
                        allStyle = Object.assign(allStyle, { display: 'inline-block' });
                    }
                } else {
                    if (!this.listItems[0].item_col_width || !isNumber(this.listItems[0].item_col_width) || parseInt(this.listItems[0].item_col_width) < parseInt(this.model.fieldWidthMin)) {
                        allStyle = Object.assign(allStyle, { width: this.model.fieldWidthMin + '%', minWidth: this.model.fieldWidthMin + '%' });
                    } else {
                        allStyle = Object.assign(allStyle, { width: this.listItems[0].item_col_width + '%', minWidth: this.model.fieldWidthMin + '%' });
                    }
                }
            }

            if (parentItem) {
                if (this.model.inlineEditMode && parentItem.item_id !== '0' && parentItem.item_id !== '-1' && item.item_editable && item.item_editable === 'true') {
                    allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                }
            } else {
                if (this.model.inlineEditMode && item.item_id !== '0' && item.item_id !== '-1' && item.editable && item.editable === 'true') {
                    allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                }
            }

            if (this.model.inlineEditMode) {
                allStyle = Object.assign(allStyle, { lineHeight: '1' });
            }
        }

        return allStyle;
    }

    addStyleWell(item?: any): any {
        var allStyle = { background: '#f9f9f9', color: 'black' }; //default

        if (item && item.item_glyphicon_color && item.item_glyphicon_color !== null) {
            allStyle = { background: item.item_glyphicon_color, color: 'white' };

            if (this.model.initialGlyphColor === '') {
                this.model.initialGlyphColor = item.item_glyphicon_color;
            }
        }

        return allStyle;
    }

    addStyleSortlist(item?: any): any {
        return { minWidth: '19px' }; //default
    }

    removeHover(item: any): string {
        // replicate your removeHover logic
        if (item?.item_id === '0' || item?.item_id === '-1') {
            return 'no-h';
        } else if (this.model.inlineEditMode) {
            return 'inline-edit-mode-div';
        }
        return '';
    }

    public addInputStyle(): { [key: string]: string } | undefined {
        // If we have no activeInlineId, do nothing
        if (!this.model.activeInlineId) {
            return;
        }

        // If -imp- is in the ID
        if (this.model.activeInlineId.indexOf('-imp-') > -1) {
            // Check if we have an alignment property
            if (!this.model.currentInline?.alignment) {
                return;
            }
            return { textAlign: this.model.currentInline.alignment };
        }

        // If -ims- is in the ID
        if (this.model.activeInlineId.indexOf('-ims-') > -1) {
            // Grab the substring before '-ims-'
            const sIdxString = this.model.activeInlineId.slice(0, this.model.activeInlineId.indexOf('-ims-'));
            const sIdx = parseInt(sIdxString, 10);

            // Check if alignment is defined
            if (!this.model.currentInline?.item_secondary?.[sIdx]?.alignment) {
                return;
            }
            return { textAlign: this.model.currentInline.item_secondary[sIdx].alignment };
        }

        // If neither case matches, do nothing
        return;
    }

    sizeChanged(_: any, data: any) {
        this.model.currentSize = data.current;
    };

    //var dereg = $rootScope.$on('event:responsive-changed', sizeChanged);

    thisFocus(item: any, role: string, index: any): boolean {
        if (!item || item === null
            || !this.model.activeInlineId || this.model.activeInlineId === null
            || !this.model.currentInline || this.model.currentInline === null) return false;

        if (item.item_id === this.model.currentInline.item_id && (this.model.activeInlineId.slice(0, (this.model.activeInlineId.indexOf('-' + role + '-') + 5)) === (index + '-' + role + '-').toString())) {
            return true;
        } else return false;
    }

    inputFocus(e: any): void {
        if (!this.model.elemInFocus || this.model.elemInFocus === null) return;

        this.model.elemInFocus.setSelectionRange(0, this.model.elemInFocus.value.length);
    }

    inputBlur(e: Event): void {
        if (this.model.currentInline === null || this.model.activeInlineId === null) {
            return;
        }

        const target = e.target as HTMLInputElement;

        this.model.inlineEditUpdate = true;
        var foundItem = false;
        let newVal = target.value;

        for (var r = 0; r < this.listItems.length; r++) {
            if (this.model.currentInline.item_id === this.listItems[r].item_id) {
                if (this.model.activeInlineId.indexOf('-imp-') > -1) {
                    this.listItems[r].item_name = newVal;
                    foundItem = true;
                } else if (this.model.activeInlineId.indexOf('-ims-') > -1) {
                    var id = this.model.activeInlineId.slice(0, this.model.activeInlineId.indexOf('-ims-'));
                    this.listItems[r].item_secondary[id].item_col = newVal;
                    foundItem = true;
                }

                if (foundItem) {
                    target.setAttribute('value', newVal);

                    const sibling = target.previousSibling as HTMLElement;

                    if (sibling) {
                        sibling.innerText = newVal;
                    }
                    break;
                }
            }
        }

        this.model.elemInFocus = null;
        this.model.currentInline = null;
        this.model.activeInlineId = null;
        this.model.isKeypress = false;
    }

    populateSortlistFromMenu() {
        this.model.sortlist.length = 0;

        var didChange = false;

        this.model.sortlist.push({ key: '0', keyName: 'sortlist', value: null, valueName: null, item_glyphicon: null, item_glyphicon_color: null, items: [] });

        if (!this.listItems || this.listItems === null) return;

        const self = this;

        if (this.listItems && this.listItems !== null && this.listItems.records && this.listItems.records !== null) {
            this.listItems.records.forEach(function (item: any) {
                if (item) {
                    for (var i = 0; i < self.model.sortlist.length; i++) {
                        if (self.model.sortlist[i].key === '0') {
                            self.model.sortlist[i].items.push(item);

                            didChange = true;

                            break;
                        }
                    }
                }
            });
        } else if (this.listItems && this.listItems !== null) {
            this.listItems.forEach(function (item: any) {
                if (item) {
                    for (var i = 0; i < self.model.sortlist.length; i++) {
                        if (self.model.sortlist[i].key === '0') {
                            self.model.sortlist[i].items.push(item);

                            didChange = true;

                            break;
                        }
                    }
                }
            });
        }
    };

    private populateSortlist(): void {
        if (!this.sortItems || this.sortItems === null || this.sortItems.length === 0) return;

        this.model.sortlist.length = 0;

        var didChange = false;

        var self = this;

        this.sortItems.forEach(function (item) {
            if (item && self.dataid && self.dataname && item[self.dataid] && item[self.dataname] && item[self.dataid] !== '0') {
                self.model.sortlist.push({ key: item[self.dataid], keyName: self.dataid, value: item[self.dataname], valueName: self.dataname, item_glyphicon: item.glyphicon, item_glyphicon_color: item.glyphicon_color, items: [] });
            }
        });

        if (!this.listItems || this.listItems === null) return;

        if (this.listItems && this.listItems !== null && this.listItems.records && this.listItems.records !== null) {
            this.listItems.records.forEach(function (item: any) {
                if (item) {
                    for (var i = 0; i < self.model.sortlist.length; i++) {
                        if (self.model.sortlist[i].key === item.item_sort_id) {
                            self.model.sortlist[i].items.push(item);

                            didChange = true;

                            break;
                        }
                    }
                }
            });
        } else if (this.listItems && this.listItems !== null) {
            this.listItems.forEach(function (item: any) {
                if (item) {
                    for (var i = 0; i < self.model.sortlist.length; i++) {
                        if (self.model.sortlist[i].key === item.item_sort_id) {
                            self.model.sortlist[i].items.push(item);

                            didChange = true;

                            break;
                        }
                    }
                }
            });
        }
    }

    gridClick(child: any, gridMode: any) {
        this.model.gridClicked = false;
        if (!child || child === null) return;
        var id = child.id ? child.id : '';

        if (id === '') {
            if (!child.parentElement || child.parentElement === null) return;
            id = child.parentElement.id ? child.parentElement.id : '';
        }

        if (id === '' || id === this.model.activeInlineId) return;

        var sIdx = null;

        if (gridMode === 'edit') {
            if (id.indexOf('-imp-') > -1) {
                if (this.model.currentInline.editable === 'false') return;
            } else if (id.indexOf('-ims-') > -1) {
                sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));
                if (this.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
            }

            this.model.activeInlineId = id;
            this.contentEditable(child, id);
        } else if (gridMode === 'item_func') {
            this.model.gridClicked = true;
   
            if (id.indexOf('-imp-') > -1) {
                if (this.optionfunc) {
                    this.optionfunc({ item: { item: this.model.currentInline, col: null } });
                }
            } else if (id.indexOf('-ims-') > -1) {
                sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));

                if (this.optionfunc) {
                    this.optionfunc({ item: { item: this.model.currentInline, col: sIdx } });
                }
            }
        } else {
            alert('gridMode not set');
        }
    }

    innerChild(child: any): any {
        var lastChild: any = null;

        if (child.children.length > 1 && child.children[1].nodeName === 'INPUT') {
            lastChild = this.innerChild(child.children[1]);
        } else if (child.children.length > 0 && child.children[0].nodeName !== 'INPUT') {
            lastChild = this.innerChild(child.children[0]);
        } else {
            lastChild = child;
        }

        return lastChild;
    }

    shiftRowChild(child: any, rIdx: any, cIdx: any): any {
        var lastChild: any = null;

        if (!child || !child.offsetParent) {
            return lastChild;
        } else if (child.className === 'col-xs-12' && child.nodeName === 'DIV' && child.children.length > 1) {
            if (cIdx !== 0) cIdx = this.model.currentInline.item_secondary.length + 1 - cIdx;

            lastChild = this.innerChild(child.children[rIdx].children[0].children[0].children[1].children[cIdx]);
        } else {
            if (child.offsetParent === null) {
                lastChild = this.shiftRowChild(child.parentElement.offsetParent, rIdx, cIdx);
            } else {
                lastChild = this.shiftRowChild(child.offsetParent, rIdx, cIdx);
            }
        }

        return lastChild;
    }

    findClosestChild(target: any, point: any) {
        var children = target.children;
        var xPoint = point;
        var closestChild = 0;
        var foundClosest = false;

        if (this.userStore.sideMenuOpen === true) {
            xPoint -= 180;
        }

        for (var c = 0; c < children.length; c++) {
            if (xPoint >= (children[c].offsetLeft) && xPoint <= (children[c].offsetLeft + children[c].offsetWidth)) {
                closestChild = c;
                foundClosest = true;
                break;
            }
        }

        if (!foundClosest) {
            return null;
        } else {
            var lastChild = null;

            lastChild = this.innerChild(children[closestChild]);
            return lastChild;
        }
    }

    inlineEditClick(child: any) {
        if (!child || child === null) return;

        var id = child.id ? child.id : '';

        if (id === '') {
            if (!child.parentElement || child.parentElement === null) return;

            id = child.parentElement.id ? child.parentElement.id : '';
        }

        if (id === '' || id === this.model.activeInlineId) return;

        if (id.indexOf('-imp-') > -1) {
            if (this.model.currentInline.editable === 'false') return;
        } else if (id.indexOf('-ims-') > -1) {
            var sIdx = parseInt(id.slice(0, id.indexOf('-imp-')));
            if (this.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
        }

        this.model.activeInlineId = id;

        this.contentEditable(child, id);
    }

    keyDownEventHandler(e: KeyboardEvent, child: any) {
        if (this.model.prosessingKeypress) return;
        if (this.model.currentInline === null || this.model.activeInlineId === null) return;

        const target = e.target as HTMLInputElement;

        var nextEditable: any = null;
        var rIdx = null;
        var cIdx = null;
        var nIdx = null;
        var nChild: any = null;
        var gridMode = 'edit';

        const self = this;

        if (e.key === 'Escape') {
            this.model.prosessingKeypress = true;
            e.preventDefault();

            this.model.isKeypress = true;
            this.model.gridDirection = 'none';

            target.blur();

            this.model.currentInline = null;
            this.model.activeInlineId = null;
            this.model.prosessingKeypress = false;
        } else if ((e.shiftKey && e.key === 'Tab') || (e.shiftKey && e.key === 'Enter') || (e.altKey && e.key === 'ArrowLeft')) {
            this.model.prosessingKeypress = true;

            e.preventDefault();

            nextEditable = this.findNextEditable(this.model.currentInline, this.model.activeInlineId, 'left');

            rIdx = nextEditable.row;
            cIdx = nextEditable.col;

            if (nextEditable.row === null || nextEditable.col === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            nIdx = cIdx;
            nChild = this.shiftRowChild(child, rIdx, nIdx);

            this.model.isKeypress = true;
            this.model.gridDirection = 'left';

            target.blur();

            this.model.currentInline = this.listItems[rIdx];

            setTimeout(function () {
                self.gridClick(nChild, gridMode);
                self.model.prosessingKeypress = false;
            }, 10);
        } else if (e.altKey && e.key === 'ArrowUp') {
            this.model.prosessingKeypress = true;

            e.preventDefault();

            nextEditable = this.findNextEditable(this.model.currentInline, this.model.activeInlineId, 'up');
            rIdx = nextEditable.row;
            cIdx = nextEditable.col;

            if (nextEditable.row === null || nextEditable.col === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            nIdx = cIdx;
            nChild = this.shiftRowChild(child, rIdx, nIdx);

            if (nChild === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            this.model.isKeypress = true;
            this.model.gridDirection = 'up';

            target.blur();

            this.model.currentInline = this.listItems[rIdx];

            setTimeout(function () {
                self.gridClick(nChild, gridMode);
                self.model.prosessingKeypress = false;
            }, 10);
        } else if ((e.key === 'Tab' || e.key === 'Enter') || (e.altKey && e.key === 'ArrowRight')) {
            this.model.prosessingKeypress = true;
            e.preventDefault();

            nextEditable = this.findNextEditable(this.model.currentInline, this.model.activeInlineId, 'right');
            rIdx = nextEditable.row;
            cIdx = nextEditable.col;

            if (nextEditable.row === null || nextEditable.col === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            nIdx = cIdx;
            nChild = this.shiftRowChild(child, rIdx, nIdx);

            this.model.isKeypress = true;
            this.model.gridDirection = 'right'

            target.blur();

            this.model.currentInline = this.listItems[rIdx];

            setTimeout(function () {
                self.gridClick(nChild, gridMode);
                self.model.prosessingKeypress = false;
            }, 10);
        } else if (e.altKey && e.key === 'ArrowDown') {
            this.model.prosessingKeypress = true;
            e.preventDefault();

            nextEditable = this.findNextEditable(this.model.currentInline, this.model.activeInlineId, 'down');
            rIdx = nextEditable.row;
            cIdx = nextEditable.col;

            if (nextEditable.row === null || nextEditable.col === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            nIdx = cIdx;
            nChild = this.shiftRowChild(child, rIdx, nIdx);

            if (nChild === null) {
                this.model.prosessingKeypress = false;
                return;
            }

            this.model.isKeypress = true;
            this.model.gridDirection = 'down';

            target.blur();

            this.model.currentInline = this.listItems[rIdx];

            setTimeout(function () {
                self.gridClick(nChild, gridMode);
                self.model.prosessingKeypress = false;
            }, 10);
        }
    }

    contentEditable(child: any, id: any) {
        const elem = document.getElementById(id);

        this.model.elemInFocus = elem;

        setTimeout(() => {
            elem?.focus();
        }, 10);

        if (!elem) return;

        const self = this;

        elem.addEventListener('keydown', function (e: KeyboardEvent) {
            self.keyDownEventHandler(e, child);
        }, false);

        this.model.keyDownListeners.push(elem);
    }

    findNextEditable(currentItem: any, activeId: any, direction: any) {
        var isPrime = null;
        var row = null;
        var col = null;

        for (var r = 0; r < this.listItems.length; r++) {
            if (currentItem.item_id === this.listItems[r].item_id) {
                row = r;
                break;
            }
        }

        if (activeId.indexOf('-imp-') > -1) {
            col = 0;

            isPrime = true;
        } else if (activeId.indexOf('-ims-') > -1) {
            col = parseInt(activeId.slice(0, activeId.indexOf('-ims-'))) + 1;

            isPrime = false;
        }

        if (isPrime === null) return;

        var numCols = this.listItems[0].item_secondary.length;
        var rowAdjustment = 0;
        var nextRow = null;
        var nextCol = null;

        if (!col) return;
        if (!row) return;

        switch (direction) {
            case 'left':
                col--;
                if (this.listItems[row + rowAdjustment]) {
                    do {
                        if (!this.listItems[row + rowAdjustment] || this.listItems[row + rowAdjustment].item_id === '0' || this.listItems[row + rowAdjustment].item_id === '-1') {
                            col = -2;
                        } else if (col < 0) {
                            col = numCols;
                            rowAdjustment--;
                        } else {
                            if ((col === 0 && this.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && this.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                col = -2;
                            } else {
                                col--;
                            }
                        }
                    } while (col >= -1);
                } else { }
                break;
            case 'up':
                rowAdjustment--;
                if (this.listItems[row + rowAdjustment]) {
                    do {
                        if (!this.listItems[row + rowAdjustment]) {
                            row = -1;
                        } else if (col === 0) {
                            if (this.listItems[row + rowAdjustment].item_id !== '0' && this.listItems[row + rowAdjustment].item_id !== '-1' && this.listItems[row + rowAdjustment].editable === 'true') {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                row = -1;
                            } else {
                                rowAdjustment--;
                            }
                        } else {
                            if (this.listItems[row + rowAdjustment].item_id !== '0' && this.listItems[row + rowAdjustment].item_id !== '-1' && this.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                row = -1;
                            } else {
                                rowAdjustment--;
                            }
                        }
                    } while (row >= 0);
                } else { }
                break;
            case 'right':
                col++;
                if (this.listItems[row + rowAdjustment]) {
                    do {
                        if (!this.listItems[row + rowAdjustment]) {
                            col = -2;
                        } else if (col > numCols) {
                            col = 0;
                            rowAdjustment++;
                        } else {
                            if ((col === 0 && this.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && this.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                col = -2;
                            } else {
                                col++;
                            }
                        }
                    } while (col >= -1);
                } else { }
                break;
            case 'down':
                rowAdjustment++;
                if (this.listItems[row + rowAdjustment]) {
                    do {
                        if (!this.listItems[row + rowAdjustment]) {
                            row = -1;
                        } else if (col === 0) {
                            if (this.listItems[row + rowAdjustment].item_id !== '0' && this.listItems[row + rowAdjustment].item_id !== '-1' && this.listItems[row + rowAdjustment].editable === 'true') {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                row = -1;
                            } else {
                                rowAdjustment++;
                            }
                        } else {
                            if (this.listItems[row + rowAdjustment].item_id !== '0' && this.listItems[row + rowAdjustment].item_id !== '-1' && this.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                nextRow = row + rowAdjustment;
                                nextCol = col;
                                row = -1;
                            } else {
                                rowAdjustment++;
                            }
                        }
                    } while (row >= 0);
                } else { }
        }

        return { row: nextRow, col: nextCol };
    }

    // Method for validating "editable" from the old code
    private validatingEditable(list: any[]): void {
        if (!list || list.length === 0) return;
        let headerItemIndex: number | null = null;
        for (let i = 0; i < list.length; i++) {
            if (list[i].item_id === '0') {
                headerItemIndex = i;
                break;
            }
        }
        if (headerItemIndex === null) {
            list.forEach(item => {
                if (!item) return;
                item.editable = 'false';
                if (Array.isArray(item.item_secondary)) {
                    item.item_secondary.forEach((s: any) => {
                        s.item_editable = 'false';
                    });
                }
            });
        } else {
            list.forEach(item => {
                if (!item) return;
                if (item.item_id === '0') return;
                if (item.editable === '') {
                    item.editable = list[headerItemIndex!].editable;
                }
                if (Array.isArray(item.item_secondary)) {
                    for (let s = 0; s < item.item_secondary.length; s++) {
                        if (item.item_secondary[s].item_editable === '') {
                            item.item_secondary[s].item_editable = list[headerItemIndex!].item_secondary[s].item_editable;
                        }
                    }
                }
            });
        }
        this.model.editableValidated = true;
    }

    private checkModes(): void {
        // replicates your checkModes function
        if (!this.mconfig || !this.mconfig.show || !Array.isArray(this.mconfig.records)) return;

        // loop over mconfig.records, if item isActive => set sortMode, etc.
        for (var r = 0; r < this.mconfig.records.length; r++) {
            if ((!this.mconfig.records[r].isIcon || this.mconfig.records[r].isIcon === false)
                && (this.mconfig.records[r].show === true)
                && (this.mconfig.records[r].enabled === true)
                && (this.mconfig.records[r].canActive === true)) {

                if (this.mconfig.records[r].isActive === true) {
                    this.mconfig.activate(this.mconfig.records[r]);

                    switch (this.mconfig.records[r].item_name) {
                        case 'option':
                            break;
                        case 'sort':
                            this.model.sortMode = true;
                            break;
                        case 'resize':
                            this.model.resizeMode = true;
                            this.slider.functions.setStarts();
                            this.slider.functions.setConnections();
                            this.slider.functions.setTooltips();
                            break;
                        case 'edit':
                            this.model.inlineEditMode = true;
                            break;
                    }

                    this.mconfig.function(this.mconfig.records[r]);
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if sortItems changes, we can try to populateSortlist
        if (changes['sortItems']) {
            this.populateSortlist();
            this.onCollectionChanged(changes['sortItems'].currentValue, changes['sortItems'].previousValue);
        }

        // if mconfig changes
        if (changes['mconfig']) {
            if (this.mconfig && this.mconfig.records && this.mconfig.records.length > 0) {
                this.checkModes();
            }
        }

        // If ltype changes
        if (changes['ltype'] && changes['ltype'].currentValue && changes['ltype'].currentValue !== null && changes['ltype'].currentValue !== '') {
            this.model.ltype = String(changes['ltype'].currentValue);
        }

        // If ptypevar changes
        if (changes['ptypevar'] && changes['ptypevar'].currentValue) {
            this.typevar = String(changes['ptypevar'].currentValue);
        }

        // If filterVal changes
        if (changes['filterVal'] && !changes['filterVal'].isFirstChange()) {
            this.model.filterVal = this.filterVal || '';
        }

        // If listItems changes (like the old watch)
        if (changes['listItems']) {
            this.onListChanged(changes['listItems'].currentValue, changes['listItems'].previousValue);
        }
    }

    ngOnDestroy(): void {
        if (this.layoutSub) this.layoutSub.unsubscribe();
        if (this.responsiveSub) this.responsiveSub.unsubscribe();
    }

    private groupTimer?: number;

    onCollectionChanged(newValues: any, oldValues: any): void {
        if (!this.sortItems || !this.listItems || this.sortItems === null || this.listItems === null) return;

        var cancelCount = 0;

        if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {
            const self = this;

            var wait = function () {
                if (newValues[0].records && newValues[0].records.length > 0 && newValues[1].length > 0 || cancelCount > 50) {
                    self.populateSortlist();
                    return;
                } else if (newValues[0].length > 0 && newValues[1].length > 0 || cancelCount > 50) {
                    self.populateSortlist();
                    return;
                }

                

                if (self.groupTimer) {
                    cancelCount++;

                    window.clearTimeout(self.groupTimer);
                }

                self.groupTimer = window.setTimeout(wait, 100);
            };

            wait();
        }
    }

    onListChanged(newValue: any, oldValue: any) {
        // BJS 20210806
        if (this.ttChange) {
            // using simplified deep object comparison. I this proves to
            // be too slow consider using deep-equal (https://github.com/inspect-js/node-deep-equal#readme)
            // or similar.
            if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
                // BJS 20220727 - Added oldValue
                this.ttChange({ $value: newValue, $oldValue: oldValue });
            }
        }

        if (this.model.inlineEditUpdate) {
            this.model.inlineEditUpdate = false;
            return;
        }

        if (this.model.validatingEditable) return;

        if (this.model.inlineEditMode) {
            this.model.editableValidated = false;
        }

        if (this.model.colWidthChanging) return;
        if (this.model.slidersChanging) return;
        if (this.listItems && this.listItems.length > 0) {
            var i = 0;
            var j = 0;

            this.model.glyphlist.length = 0;
            this.model.glyphlist2.length = 0;
            this.model.secondary.length = 0;

            if (!this.model.editableValidated && this.listItems && this.listItems.length > 0) {
                this.model.validatingEditable = true;

                this.validatingEditable(this.listItems);

                this.model.validatingEditable = false;
            }

            function isBlack(color: string): boolean {
                return color === 'black' || color === '#000000';
            }

            let self = this;

            this.listItems.forEach(function (item: any, key: any) {
                if (!item) return;

                let c = 0;

                if (item.item_glyphicon && item.item_glyphicon !== null && item.item_glyphicon.length > 0) {
                    self.model.glyphlist.push([]);

                    let tmplist1 = item.item_glyphicon.split(' ');
                    let tmplistColor1 = item.item_glyphicon_color ? item.item_glyphicon_color.split(' ') : self.model.initialGlyphColor.split(' ');

                    tmplist1.forEach(function (gitem: any) {
                        self.model.glyphlist[i].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor1[c]) ? 'var(--tt-text-color)' : tmplistColor1[c], item_parent_id: item.item_id });

                        if (c < tmplistColor1.length - 1) {
                            c++;
                        }
                    });

                    i++;
                }

                if (item.item_glyphicon_2) {
                    self.model.glyphlist2.push([]);

                    let tmplist2 = item.item_glyphicon_2.split(' ');
                    let tmplistColor2 = item.item_glyphicon_2_color ? item.item_glyphicon_2_color.split(' ') : item.item_glyphicon_color ? item.item_glyphicon_color.split(' ') : self.model.initialGlyphColor.split(' ');

                    c = 0;

                    tmplist2.forEach(function (gitem: any) {
                        self.model.glyphlist2[j].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor2[c]) ? 'var(--tt-text-color)' : tmplistColor2[c], item_parent_id: item.item_id });

                        if (c < tmplistColor2.length - 1) {
                            c++;
                        }
                    });

                    j++;
                }

                if (item.item_secondary) {
                    if (self.listItems[key].item_secondary.length > self.model.numCol) {
                        self.model.numCol = self.listItems[key].item_secondary.length;
                    }

                    item.item_secondary.forEach(function (data: any) {

                        if (data) {
                            if (self.model.secondary.length <= key) {
                                self.model.secondary.push([]);
                            }
                            self.model.secondary[key].push(data);

                            self.model.secondary[key].parent_id = item.item_id;
                        }
                    });
                }
            });
        }

        this.model.colList.length = 0;
        var longest = 0;

        for (var o = 0; o < this.model.numCol; o++) {
            var colVis = true;
            var colPrio = o;
            var colOrder = o;
            var colAlign = 'right';
            var colWidth = 0;
            var colEditable = 'false';

            for (var p = 0; p < this.model.secondary.length; p++) {
                if (this.model.secondary[p] && this.model.secondary[p][o]) {
                    if (this.model.secondary[p][o].item_col.length > longest) {
                        longest = this.model.secondary[p][o].item_col.length;
                    }
                }
            }

            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].alignment) {
                colAlign = this.model.secondary[0][o].alignment;
            }
            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].visible) {
                colVis = this.model.secondary[0][o].visible;
            }
            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].priority) {
                colPrio = this.model.secondary[0][o].priority;
            }
            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].orderby) {
                colOrder = this.model.secondary[0][o].orderby;
            }
            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].item_editable) {
                colEditable = this.model.secondary[0][o].item_editable;
            }
            if (this.model.secondary[0] && this.model.secondary[0][o] && this.model.secondary[0][o].item_col_width) {
                if (isNumber(this.model.secondary[0][o].item_col_width))
                    colWidth = parseFloat(this.model.secondary[0][o].item_col_width);
            }

            this.model.colList.push({ colId: o, colLength: longest, width: colWidth, alignment: colAlign, orderby: colOrder, editable: colEditable, priority: colPrio, view: false, viewXX: false, viewXS: false, viewSM: false, viewMD: false, viewLG: false, visible: colVis });
        }

        if (this.model.colList.length > 0 && this.listItems[0]) {
            var colCount = this.model.colList.length;
            var visibleColCount = 0;
            var totalLength = 0;
            var allWidthsUndefined = true;

            for (var col = 0; col < colCount; col++) {
                if (col === 0 && this.listItems[0].item_col_width && isNumber(this.listItems[0].item_col_width)) {
                    allWidthsUndefined = false;
                    break;
                } else if (this.listItems[0].item_secondary[col].item_col_width && isNumber(this.listItems[0].item_secondary[col].item_col_width)) {
                    allWidthsUndefined = false;
                    break;
                }
            }

            this.model.slidersChanging = true;

            if (allWidthsUndefined) {
                var allWidths = [];
                var longestItemName = 0;

                for (var m = 0; m < this.listItems.length; m++) {
                    if (this.listItems[m]) {
                        if (this.listItems[m].item_name.length > longest) {
                            longestItemName = this.listItems[m].item_name.length;
                        }
                    }
                }

                allWidths.push(longestItemName);

                for (col = 0; col < colCount; col++) {
                    allWidths.push(this.model.colList[col].colLength);
                }

                var tempFullLength = 0;
                var flooredLength = 0;
                var awla = 0;
                var awlb = 0;
                var awlf = 0;
                var minLength = 0;

                switch (this.model.currentSize) {
                    case 'xx':
                    case 'xs':
                        minLength = 0;
                        break;
                    case 'sm':
                        minLength = 3;
                        break;
                    case 'md':
                    case 'lg':
                        minLength = 6;
                }

                for (col = 0; col < allWidths.length; col++) {
                    tempFullLength += allWidths[col];
                }

                for (col = 0; col < allWidths.length; col++) {
                    if (col === 0) {
                        awla = (allWidths[col] / tempFullLength) * 100;
                        awlf = Math.floor(awla);
                        this.listItems[0].item_col_width = awlf;
                        flooredLength += awla - awlf;
                    } else {
                        awla = (allWidths[col] / tempFullLength) * 100;
                        awlb = ((allWidths[col] - minLength) / tempFullLength) * 100;
                        if (awlb < this.model.sliderMinDefault) awlb = this.model.sliderMinDefault;
                        awlf = Math.floor(awlb);
                        this.listItems[0].item_secondary[col - 1].item_col_width = awlf;
                        flooredLength += awla - awlf;
                    }
                }

                this.listItems[0].item_col_width += Math.floor(flooredLength);
            }

            for (col = 0; col < colCount; col++) {
                if (col === 0) {
                    visibleColCount++;

                    if (this.listItems[0].item_col_width && isNumber(this.listItems[0].item_col_width)) {
                        if (parseFloat(this.listItems[0].item_col_width) > 100) {
                            this.listItems[0].item_col_width = 100;
                        }
                        totalLength += parseFloat(this.listItems[0].item_col_width);
                    } else {
                        this.listItems[0].item_col_width = this.slider.min;

                        totalLength += this.slider.min;
                    }

                    if (totalLength >= 100) {
                        break;
                    }
                }

                if (this.model.colList[col].visible === true) {
                    visibleColCount++;

                    if (this.model.colList[col].width > 100) {
                        this.model.colList[col].width = 100;
                    } else if (this.model.colList[col].width < this.slider.min) {
                        this.model.colList[col].width = this.slider.min;
                    }

                    if (this.model.colList[col].width + totalLength > 100) {
                        var canShrink = false;
                        var needShrink = false;
                        var totalShrinkage = 0;

                        do {
                            canShrink = false;
                            needShrink = false;

                            if (this.model.colList[col].width > this.slider.min) {
                                this.model.colList[col].width -= this.slider.options.step;
                                totalShrinkage += this.slider.options.step;

                                if (this.model.colList[col].width + totalLength - totalShrinkage > 100) {
                                    needShrink = true;
                                }

                                if (needShrink && this.model.colList[col].width > this.slider.min) {
                                    canShrink = true;
                                }
                            }
                        } while (needShrink && canShrink);

                        totalLength += this.model.colList[col].width - totalShrinkage;
                    } else {
                        totalLength += this.model.colList[col].width;
                    }
                }
            }

            if (totalLength + this.slider.options.step < 100) {
                var remainderLength = 100 - totalLength;
                var colLengthAdjustment = Math.floor(remainderLength / visibleColCount);
                var additionalAdjustments = (remainderLength - (colLengthAdjustment * visibleColCount)) / this.slider.options.step;

                for (col = 0; col < colCount; col++) {
                    if (col === 0) {
                        this.listItems[0].item_col_width = parseFloat(this.listItems[0].item_col_width) + colLengthAdjustment;
                        totalLength += colLengthAdjustment;
                    }

                    if (this.model.colList[col].visible === true) {
                        this.model.colList[col].width += colLengthAdjustment;
                        totalLength += colLengthAdjustment;
                    }
                }

                while (additionalAdjustments > 0) {
                    for (col = colCount; col >= 0; col--) {
                        if (col === 0) {
                            this.listItems[0].item_col_width = parseFloat(this.listItems[0].item_col_width) + this.slider.options.step;
                            totalLength += this.slider.options.step;
                            additionalAdjustments--;
                        } else if (this.model.colList[col - 1].visible === true) {
                            this.model.colList[col - 1].width += this.slider.options.step;
                            totalLength += this.slider.options.step;
                            additionalAdjustments--;
                        }

                        if (additionalAdjustments <= 0) break;
                    }
                }

                for (col = 0; col < colCount; col++) {
                    this.listItems[0].item_secondary[col].item_col_width = this.model.colList[col].width;
                }
            }

            this.model.slidersChanging = false;
        }

        let columnsEqualized = false;

        do {
            columnsEqualized = true;

            var self = this;

            this.model.secondary.forEach(function (data: any) {
                if (data.length < self.model.numCol) {
                    data.push({ item_col: '' });

                    columnsEqualized = false;
                }
            });

        } while (Boolean(columnsEqualized) === false);
    }
}
