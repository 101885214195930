import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { RememberService } from '@app/core/services/remember.service';
import { StateService } from '@app/core/services/state.service';
import { UtilityService } from '@app/core/services/utility.service';
import { BaseWidget, NgCompInputs, NgGridStackWidget } from 'gridstack/dist/angular';
import { AgChartOptions } from 'ag-charts-community';
import { GridStack } from 'gridstack';

@Component({
    selector: 'tt-webpagemenu-dashboard-widget',
    templateUrl: './webpagemenu-dashboard-widget.component.html',
    styleUrls: ['./webpagemenu-dashboard-widget.component.css'],
})
export class WebpagemenuDashboardWidgetComponent extends BaseWidget implements OnInit, OnChanges {
    @Input()
    ttDashboardWidget?: WebpagemenuDashboardWidgetData;

    @Output()
    ttReady = new EventEmitter<any>();

    public override serialize(): NgCompInputs | undefined {
        return this.ttDashboardWidget ? { node: this.ttDashboardWidget } : undefined;
    }

    constructor(public elementRef: ElementRef, private utility: UtilityService, private state: StateService, private remember: RememberService, private datatask: DataTaskService) {
        super();
    }

    graphoption: AgChartOptions = {
        animation: { duration: 0 },
        theme: 'ag-polychroma-dark',
        background: {
            visible: false,
        },
        title: {
            enabled: false,
            // text: 'Test tittel',
        },
        data: [
            {
                quarter: "Q1'18",
                iphone: 140,
                mac: 16,
                ipad: 14,
                wearables: 12,
                services: 20,
            },
            {
                quarter: "Q2'18",
                iphone: 124,
                mac: 20,
                ipad: 14,
                wearables: 12,
                services: 30,
            },
        ],
        series: [
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: 'iphone',
                yName: 'iPhone',
            },
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: 'mac',
                yName: 'Mac',
            },
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: 'ipad',
                yName: 'iPad',
            },
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: 'wearables',
                yName: 'Wearables',
            },
            {
                type: 'bar',
                xKey: 'quarter',
                yKey: 'services',
                yName: 'Services',
            },
        ],
    };

    loading = false;
    async onClick(event: MouseEvent) {
        if (!this.ttDashboardWidget) return;

        this.loading = true;

        try {
            await Promise.all(Object.entries(this.ttDashboardWidget.remember || {}).map(([key, value]) => this.remember.remember(key, value)));

            if (this.utility.isModifierKeyPressed(event)) {
                this.state.newTab(this.ttDashboardWidget!.goto_item_state!, this.ttDashboardWidget.goto_item_parms);
            } else {
                this.state.go(this.ttDashboardWidget!.goto_item_state!, this.ttDashboardWidget.goto_item_parms);
            }
        } finally {
            this.loading = false;
        }
        // setTimeout(() => (this.loading = false), 2000);
    }

    chartReady(event: any) {
        console.log(event);
    }

    async ngOnInit(): Promise<void> {
        if (this.ttDashboardWidget?.kpicomp_keyno) {
            this.loading = true;

            try {
                // @ts-ignore
                this.ttDashboardWidget = await this.datatask.Post(3518, { kpicomp_keyno: '' + this.ttDashboardWidget.kpicomp_keyno }, { returnRawResponse: true });
            } finally {
                this.loading = false;

                setTimeout(() => {
                    this.ttDashboardWidget?.api?.()?.resizeToContent(this.elementRef.nativeElement.parentElement.parentElement);
                    this.ttDashboardWidget?.api?.()?.onResize(document.body.clientWidth);
                });
            }
        }
        this.ttReady.emit(this.serialize);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes :>> ', changes);
        if (changes['ttDashboardWidget']) {
            this.graphoption.theme = changes['ttDashboardWidget'].currentValue?.graphtheme?.toLowerCase() ?? 'ag-polychroma-dark';
            this.graphoption = { ...this.graphoption };
        }
    }
}

export interface WebpagemenuDashboardWidgetData extends NgGridStackWidget {
    remember?: { [key: string]: string };
    kpicomp_keyno?: string | number;
    proc_name?: string;
    reg_datetime?: string;
    mod_datetime?: string;
    is_public?: '1' | '0';
    reg_by_portal_user_keyno?: string | number;
    kpicomp_name_word_id?: string;
    graphdata?: any[];
    graphtheme?: string;
    kpicomp_name?: string;
    kpicomp_description_word_id?: string;
    kpicomp_description?: string;
    goto_item_state?: string;
    goto_item_parms?: string;
    item_glyphicon?: string;
    item_glyphicon_color?: string;
    item_glyphicon_size?: string;
    tile_background_color?: string;
    tile_heading_text?: string;
    tile_heading_text_color?: string;
    tile_heading_text_size?: string;
    tile_subheading_text?: string;
    tile_subheading_text_color?: string;
    tile_subheading_text_size?: string;
    data1_text?: string;
    p2_webpage_keyno?: string | number;

    api?: () => GridStack | undefined;
}
