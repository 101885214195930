(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinishProdWork', {
        templateUrl: 'views/components/views/poFinishProdWork/poFinishProdWork.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdWorkService', function ($stateParams, poFinishProdWorkService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoPinishProdWorkKeyno = $stateParams.p2_pofinishprodwork_keyno;

            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await poFinishProdWorkService.getMenu({ p2_pofinishprodwork_keyno: p2PoPinishProdWorkKeyno }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
