(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('powTimeApproval', {
        templateUrl: 'views/components/views/powTimeApproval/powTimeApproval.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'rememberService', 'economyService', 'powTimeApprovalService', 'logisticService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, rememberService, economyService, powTimeApprovalService, logisticService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let webPage = stateService.getCurrentName();
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                confirm: '',
                error: '',
                ok: ''
            };

            let variableNames = {
                gb_powtimeapproval_function: ''
            };
            
            vm.model = {
                companyButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
                activityButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('workdetails', { custact_no: vm.model.setting.custact_no_move_to }) }
                ],
                projectProcessButtons: [
                    {
                        id: 'list',
                        icon: 'fa fa-solid fa-bars',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('projectprocesses', { project_keyno: vm.model.setting.project_keyno })
                    }
                ],
                projectProcessButtons2: [
                    {
                        id: 'erase',
                        icon: 'fa fa-solid fa-eraser',
                        color: 'danger',
                        type: 'danger',
                        onClick: () => vm.model.setting.projectprosess_keyno = '0'
                    },
                    {
                        id: 'list',
                        icon: 'fa fa-solid fa-bars',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('projectprocesses', { project_keyno: vm.model.setting.project_keyno })
                    },
                    {
                        id: 'goto', icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('projectprocess', {
                            project_keyno: vm.model.setting.project_keyno,
                            projectprosess_keyno: vm.model.setting.projectprosess_keyno
                        })
                    }
                ],
                bgPowTimeApprovalFunction: {
                    selected: 'GOTO',
                    buttons: [
                        { id: 'GOTO', label: 'gb_powtimeapproval_function_goto', color: 'primary', onClick: () => setPowTimeApprovalFunctionView('GOTO') },
                        { id: 'APPROVE', label: 'gb_powtimeapproval_function_approve', color: 'primary', onClick: () => setPowTimeApprovalFunctionView('APPROVE') },
                        { id: 'CHANGE', label: 'gb_powtimeapproval_function_change', color: 'primary', onClick: () => setPowTimeApprovalFunctionView('CHANGE') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListProjectProcesses: [],
                selectListActivities: [],
                itemsListApprovals: [],
                moving: false,
                lockedApprove: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPowTimeApprovalFunctionView = function (id) {
                vm.model.bgPowTimeApprovalFunction.selected = vm.model.setting.gb_powtimeapproval_function = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();

                powTimeApprovalService.getSetting({
                    webpage: webPage,
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setPowTimeApprovalFunctionView(vm.model.setting.gb_powtimeapproval_function);

                    $q.all([
                        loadActivities(),
                        loadProjectProcesses()
                    ]).then(() => {
                        loadApprovals();
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadActivities = function () {
                if (utilityService.validateParmsValue(vm.model.setting.project_keyno) !== true) return;
                
                return powTimeApprovalService.listActivities({ project_keyno: vm.model.setting.project_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListActivities);
                });
            };
            
            let loadProjectProcesses = function () {
                if (utilityService.validateParmsValue(vm.model.setting.project_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.custact_no_move_to) !== true) return;
                
                return economyService.listProjectProcesses({
                    project_keyno: vm.model.setting.project_keyno,
                    show_only_timeentry_allowed: '1',
                    custact_no: vm.model.setting.custact_no_move_to
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListProjectProcesses);
                });
            };
            
            let loadApprovals = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;
                
                vm.model.itemsListApprovals = [];
                
                return powTimeApprovalService.listApprovals(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListApprovals);
                });
            };
            
            // ## REMEMBER VALUE FUNCTIONS ##
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'gb_powtimeapproval_function':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        if (vm.model.setting.businessco_no === '99999') loadApprovals();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPowTimeApprovalFunction = function (value) {
                vm.model.bgPowTimeApprovalFunction.selected = value;
                vm.model.setting.gb_powtimeapproval_function = vm.model.bgPowTimeApprovalFunction.selected;

                rememberFunc('gb_powtimeapproval_function');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosenApprove = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
    
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
    
                if (item.item_id !== '0' && item.item_id !== '-1') {
                    if (item.item_is_selected === '0') {
                        item.item_is_selected = '1';
                    } else if (item.item_is_selected === '1') {
                        item.item_is_selected = '0';
                    }
                }
            };
            
            vm.selectAllApprove = function () {
                angular.forEach(vm.model.itemsListApprovals, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
    				if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === -1) return;
    				if (item.item_id === '0') return;
                    if (item.item_id === '-1') return;
    
                    item.item_is_selected = '1';
    			});
    		};
            
            vm.unselectAllApprove = function () {
                angular.forEach(vm.model.itemsListApprovals, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
    				if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === -1) return;
    				if (item.item_id === '0') return;
    				if (item.item_id === '-1') return;
    
    				item.item_is_selected = '0';
    			});
    		};
            
            vm.toggleChosenChange = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
                
                if (item.item_id !== '0' && item.item_id !== '-1') {
                    if (item.item_is_selected === '0') {
                        item.item_is_selected = '1';
                    } else if (item.item_is_selected === '1') {
                        item.item_is_selected = '0';
                    }
                }
            };
            
            vm.selectAllChange = function () {
                angular.forEach(vm.model.itemsListApprovals, function (item) {
                    if (angular.isUndefined(item.item_filtervalue) === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === -1) return;
                    if (item.item_id === '0') return;
                    if (item.item_id === '-1') return;
    
                    item.item_is_selected = '1';
                });
            };
            
            vm.unselectAllChange = function () {
                angular.forEach(vm.model.itemsListApprovals, function (item) {
                    if (angular.isUndefined(item.item_filtervalue) === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === -1) return;
                    if (item.item_id === '0') return;
                    if (item.item_id === '-1') return;
                    
                    item.item_is_selected = '0';
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.approveSelected = function () {
                vm.model.lockedApprove = true;

                powTimeApprovalService.saveObj({ records: vm.model.itemsListApprovals }).then(function (response) {
                    modalService.show({
                        type: 'success',
                        title: translations.confirm,
                        message: response[0].ws_result_message,
                        buttons: [{
                            label: translations.ok,
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                                vm.model.lockedApprove = false;
                                loadApprovals();
                            }
                        }]
                    });
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // ## SAVE BUTTON FUNCTION ##
            
            vm.moveChanges = function () {
                vm.model.moving = true;
                var selectedItems = [];
                
                angular.forEach(vm.model.itemsListApprovals, function (item) {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0') return;
                    
                    selectedItems.push({
                        item_id: item.item_id,
                        item_is_selected: item.item_is_selected
                    });
                });
                
                powTimeApprovalService.changeItems({
                    projectprosess_keyno: vm.model.setting.projectprosess_keyno,
                    custact_no_move_to: vm.model.setting.custact_no_move_to,
                    records: selectedItems
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                        	type: 'warning',
                        	title: translations.error,
                        	message: response[0].errormessage,
                        	buttons: [{
                        		label: translations.ok,
                        		cssClass: 'btn-warning',
                        		action: function (dialogItself) {
                        			dialogItself.close();
                                    vm.model.moving = false;
                        		}
                        	}]
                        });
                    } else {
                        vm.model.moving = false;
                        loadApprovals();
                    }
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        if (vm.model.setting.businessco_no !== '99999') loadApprovals();
                        break;
                    case 'custact_no_move_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.custact_no_move_to) !== true) return;

                        vm.model.setting.custact_no_move_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.custact_no_move_to) !== true) return;

                        loadProjectProcesses();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadApprovals();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
