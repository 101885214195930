<tt-page *ngIf="!!get">
    <tt-card class="col-xs-12 col-md-4 sp-0">
        <tt-input ttLabel="productsettingmc_keyno" [ttModel]="get.productsettingmc_keyno" ttReadonly></tt-input>
        <tt-input ttLabel="colname" [ttModel]="get.colname" ttReadonly></tt-input>
        <tt-input ttLabel="productsettingmcitem_keyno" [ttModel]="get.productsettingmcitem_keyno" ttReadonly></tt-input>
        <tt-input ttLabel="productsettingmcitem_name" [(ttModel)]="get.productsettingmcitem_name" [ttButtons]="itemNameFormButtons"></tt-input>
        <tt-input-number ttLabel="orderby" [(ttModel)]="get.sortorder" ttDecimals></tt-input-number>
        <tt-button-save ttText="productsettingmc_save" (ttClick)="onSave()" [ttLoading]="saving" ttLoadingText=""></tt-button-save>
        <tt-button
            *ngIf="productSettingMcItemKeyno > '0'"
            ttText="productsettingmc_delete"
            ttType="danger"
            ttIcon="fad fa-trash"
            (ttClick)="onDelete()"
            [ttLoading]="deleting"
            ttLoadingText=""
        ></tt-button>
    </tt-card>
</tt-page>
