import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-tabs tt-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TabComponent implements OnInit, OnChanges {
    /**
     * The id of the tab, must be unique for this tab-set. Used to signalize when this is the selected tab.
     */
    @Input()
    public ttId: string = '';

    /**
     * The label to the display for this tab.
     */
    @Input()
    public ttLabel: string = '';

    /**
     * The displayed label.
     */
    public label = '';

    /**
     * Whether the label for this tab should be translated.
     */
    @Input()
    public set ttTranslateLabel(value: BooleanInput) {
        this._translateLabel = coerceBooleanProperty(value);
    }
    public get ttTranslateLabel(): boolean {
        return this._translateLabel;
    }
    private _translateLabel = true;

    /**
     * The icon to display for this tab.
     */
    @Input()
    public ttIcon?: string = '';

    /**
     * Whether this tab is the active tab.
     */
    @Input()
    public set ttActive(value: BooleanInput) {
        this._active = coerceBooleanProperty(value);
    }
    public get ttActive(): boolean {
        return this._active;
    }
    private _active = false;

    /**
     * Whether this tab is disabled.
     */
    @Input()
    public set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    public get ttDisabled(): boolean {
        return this._disabled;
    }
    private _disabled = false;

    /**
     * Whether to show loading animation for this tab.
     */
    @Input()
    public set ttSkeleton(value: BooleanInput) {
        this._skeleton = coerceBooleanProperty(value);
    }
    public get ttSkeleton(): boolean {
        return this._skeleton;
    }
    private _skeleton = false;

    /**
     * Event emitted when the tab is clicked.
     */
    @Output()
    public ttClick = new EventEmitter<MouseEvent>();

    /**
     * When data for the component is finished loading.
     */
    public ready = false;

    constructor(private translate: TranslateService) {}

    /**
     * Handles click event on tabs.
     *
     * @param event the mouse event for the click event.
     */
    public onClick(event: MouseEvent) {
        this.ttClick.emit(event);
    }

    /**
     * Sets the label of the tab.
     */
    private async setLabel() {
        if (this.ttTranslateLabel) {
            try {
                this.label = await this.translate.translate(this.ttLabel);
            } catch (err) {
                this.label = this.ttLabel;
            }
        } else {
            this.label = this.ttLabel;
        }
    }

    ngOnInit(): void {
        if (!this.ttId) throw Error('A unqiue id is required for tt-tab to work properly.');
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttActive']) {
            this.ttActive = changes['ttActive'].currentValue;
        }

        if (changes['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }

        if (changes['ttSkeleton']) {
            this.ttSkeleton = changes['ttSkeleton'].currentValue;
        }

        if (changes['ttTranslateLabel']) {
            this.ttTranslateLabel = changes['ttTranslateLabel'].currentValue;
        }

        if (!!changes?.['ttLabel']?.currentValue && typeof changes?.['ttLabel']?.currentValue === 'string' && changes?.['ttLabel']?.currentValue !== changes?.['ttLabel']?.previousValue) {
            try {
                await this.setLabel();
            } finally {
                this.ready = true;
            }
        }
    }
}
