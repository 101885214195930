import { ChangeDetectorRef, Directive, Host, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListComponent } from '../list.component';
import { Item } from '../../listbox/listbox.component';

@Directive({
    selector: 'tt-list[ttFilter]',
})
export class ListFilterDirective<TType extends Item> implements OnInit, OnChanges {
    /**
     * Value to filter the item list with using the `item_filtervalue` property from the item.
     */
    @Input()
    ttFilter?: string | null = '';

    /**
     * Defines the behaviour of the filter.
     *  - **'freetext'** matches any items which matches atleast one word in the filter.
     *  - **'fulltext'** matches any items which matches all words in the filter.
     *  - **'phrasetext'** matches any items containing the filter value as is.
     *
     * Default is **'fulltext'**.
     */
    @Input()
    ttFilterType: 'freetext' | 'fulltext' | 'phrasetext' = 'fulltext';

    constructor(@Host() private componentRef: ListComponent<TType>, private change: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.componentRef.filter = this.ttFilter ?? '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttFilter']) {
            this.componentRef.filter = changes['ttFilter'].currentValue ?? '';
            this.change.detectChanges();
        }
    }
}

/**
 * Different types of filter behaviour in tt-list filtering.
 */
export type ListFilterType = 'freetext' | 'fulltext' | 'phrasetext';
