(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bulkMailMenu', {
        templateUrl: 'views/components/views/bulkMailMenu/bulkMailMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'base64', 'stateService', 'bulkMailService', 'modalService', 'emailCreateService', 'graphAuthService', 'graphMailService', function ($stateParams, $q, base64, stateService, bulkMailService, modalService, emailCreateService, graphAuthService, graphMailService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let bulkMailKeyno = $stateParams.bulkmail_keyno;

            vm.model = {
                emailTemplateNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplate', { emailtemplate_keyno: vm.model.menu.emailtemplate_keyno }) }
                ],
                menu: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'bulkmailmenu_bulkmaildata_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'bulkmailmenu_send_to_selected',
                                text: 'bulkmailmenu_send_to_selected',
                                translate: true,
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                icon: 'fa-reply-all',
                                func: sendToSelectedRows,
                                //disabled: sendToSelectedDisabled,
                                //disabled: () => { return 'hidden' }
                            }
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            function checkToolbarButtonsVisibility() {
                vm.grid?.gridfunc?.refreshToolbarBtnDisability();
            }

            function getSelectedRows() {
                return vm.grid?.gridfunc?.getAllRows().filter((row) => row.is_selected === true) ?? [];

            }

            async function sendToSelectedDisabled() {
                try {
                    await graphAuthService.getAccount();
                } catch (error) {
                    console.dir(error);
                    return 'hidden';
                }

                if (getSelectedRows()?.length > 0) {
                    return false;
                }

                return true;
            }


            function sendToSelectedRows() {
                let progress = {
                    runProgress: 'START',
                    label: 'progressbar',
                    field: '',
                    max: getSelectedRows()?.length ?? 0,
                    min: 0,
                    step: 0,
                    finishclose: '1',
                    abortclose: false,
                }

                modalService.progressInfo.max = getSelectedRows()?.length;

                modalService.showProgress(progress).then(function (value) {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') {
                        console.log('ABORT');
                    } else if (progress.runProgress === 'FINISH') {
                        loadGrid();
                    }
                });

                // BJS 20250210 - Modified to run in an async context to enabled checking if user is logged in with graph and
                //                to use default email system if not logged in.
                (async () => {
                    let useGraphEmail = await graphAuthService.isAuthenticated();

                    let graphUserName = useGraphEmail ? (await graphAuthService.getAccount()).username : '';

                    getSelectedRows()?.forEach(async (row) => {
                        let email = await getEmailContent(row.bulkmaildata_keyno);

                        if (useGraphEmail) {
                            email.from_email = graphUserName;
                        }

                        await sendEmail(email, useGraphEmail);

                        modalService.progressInfo.step++;
                        progress.step++;
                    })
                })();
            }


            async function getEmailContent(bulkmaildataKeyno) {
                return (await emailCreateService.getEmailCreate({ webpage: 'bulkmail', argtype: 'bulkmaildata_keyno', argvalue: bulkmaildataKeyno }))[0];
            }

            // BJS 20250210 - Added useGraphEmail parameter. Previously this was hardcoded to use graph.
            async function sendEmail(ttEmail, useGraphEmail) {
                try {
                    ttEmail.send_by_client = useGraphEmail;
                    ttEmail.email_heading = base64.urlencode(ttEmail.email_heading);
                    ttEmail.email_signature = base64.urlencode(ttEmail.email_signature);

                    const data = await emailCreateService.sendEmail(ttEmail);

                    if (data?.mailinfo && data.mailinfo.length > 0) {
                        data.mailinfo.forEach((mailinfo) => {
                            graphMailService.sendMessage({
                                subject: base64.urldecode(mailinfo.subject),
                                content: base64.urldecode(mailinfo.bodyHtml),
                                recipients: mailinfo.to,
                                ccRecipients: mailinfo.cc,
                                bccRecipients: mailinfo.bcc,
                                attachments: mailinfo.attachments,
                                userId: mailinfo.from
                            });
                        })
                    }
                } catch (error) {

                }

            }

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                let deferred = $q.defer();
                
                bulkMailService.getEdit({ bulkmail_keyno: bulkMailKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3105,
                    parameters: { bulkmail_keyno: vm.model.menu.bulkmail_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'bulkmail':
                        go({ bulkmail_keyno: vm.model.menu.bulkmail_keyno });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
                document.querySelector('mgt-login').addEventListener('loginCompleted', checkToolbarButtonsVisibility);
            };

            vm.$onDestroy = function () {
                document.querySelector('mgt-login').removeEventListener('loginCompleted', checkToolbarButtonsVisibility);
            }


            // #endregion ON INIT FUNCTION
        }]
    });
})();
