(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('shipmentContainers', {
        templateUrl: 'views/components/views/shipmentContainers/shipmentContainers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'shipmentContainersService', 'logisticService', function ($q, utilityService, shipmentContainersService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                selectListStatuses: [],
                itemsListContainers: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                shipmentContainersService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces(),
                        loadShipmentContainers()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadStatuses = function () {
                shipmentContainersService.listStatuses().then(function (list) {
                    angular.copy(list, vm.model.selectListStatuses);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no_to, true) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no_to }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };
            
            let loadShipmentContainers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no_to, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.shipmentcontainerstatus_keyno, true) !== true) return;

                vm.model.itemsListContainers = [];

                return shipmentContainersService.listContainers(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListContainers);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no_to) !== true) return;

                        vm.model.setting.businessco_no_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_to, true) !== true) return;

                        loadStockPlaces();
                        loadShipmentContainers();
                        break;
                    case 'stockplace_id_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_to) !== true) return;

                        vm.model.setting.stockplace_id_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to, true) !== true) return;
                        
                        loadShipmentContainers();
                        break;
                    case 'shipmentcontainerstatus_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.shipmentcontainerstatus_keyno) !== true) return;

                        vm.model.setting.shipmentcontainerstatus_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.shipmentcontainerstatus_keyno, true) !== true) return;

                        loadShipmentContainers();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                loadStatuses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
