(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceHead', {
        templateUrl: 'views/components/views/invoiceHead/invoiceHead.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'invoiceHeadService', 'logisticService', 'economyService', 'typeaheadService', function ($stateParams, stateService, utilityService, translateService, modalService, invoiceHeadService, logisticService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let invoiceNo = $stateParams.invoice_no;
            let webPageName = stateService.getCurrentName();

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                webPageName: webPageName,
                bgMainView: {
                    selected: 'INVOICELINE',
                    buttons: [
                        { id: 'INVOICELINE', label: 'invoicehead_invoiceline', color: 'primary', onClick: () => vm.model.bgMainView.selected = 'INVOICELINE' },
                        { id: 'INVOICEHEAD', label: 'invoicehead_invoicehead', color: 'primary', onClick: () => vm.model.bgMainView.selected = 'INVOICEHEAD' },
                        { id: 'FUNCTIONS', label: 'invoicehead_functions', color: 'primary', onClick: () => vm.model.bgMainView.selected = 'FUNCTIONS' }
                    ]
                },
                bgTerms: {
                    selected: 'ADDRESS',
                    buttons: [
                        { id: 'ADDRESS', label: 'invoicehead_address', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'ADDRESS' },
                        { id: 'TERMS', label: 'invoicehead_terms', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'TERMS' },
                        { id: 'STAT', label: 'invoicehead_stat', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'STAT' }
                    ]
                },
                bgDelivery: {
                    selected: 'ADRSTAT',
                    buttons: [
                        { id: 'ADRSTAT', label: 'invoicehead_stat_address', color: 'primary', onClick: () => vm.model.bgDelivery.selected = 'ADRSTAT' },
                        { id: 'ADRDEL', label: 'invoicehead_delivery_address', color: 'primary', onClick: () => vm.model.bgDelivery.selected = 'ADRDEL' },
                        { id: 'ADRINV', label: 'invoicehead_invoice_address', color: 'primary', onClick: () => vm.model.bgDelivery.selected = 'ADRINV' }
                    ]
                },
                statCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: () => stateService.go('customeredit', { cust_no: vm.model.edit.stat_cust_no }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.stat_cust_no }) }
                ],
                deliveryCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: () => stateService.go('customeredit', { cust_no: vm.model.edit.delivery_cust_no }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.delivery_cust_no }) }
                ],
                invoiceCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: () => stateService.go('customeredit', { cust_no: vm.model.edit.invoice_cust_no }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.invoice_cust_no }) }
                ],
                edit: {},
                selectListPayments: [],
                selectListFactoring: [],
                selectListDepartments: [],
                itemsListInvoiceLines: [],
                lockedPrinted: false,
                sendingInvoice: false,
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await invoiceHeadService.getEdit({ invoice_no: invoiceNo }))[0];

                loadDepartments();
                loadInvoiceLines();
            }

            async function loadPayments() {
                vm.model.selectListPayments = (await economyService.listPayments());
            }

            async function loadCustFactorings() {
                vm.model.selectListFactoring = (await economyService.listCustFactorings());
            }

            async function loadDepartments() {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                vm.model.selectListDepartments = (await logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }));
            }

            async function loadInvoiceLines() {
                if (utilityService.validateParmsValue(vm.model.edit.invoice_no) !== true) return;

                vm.model.itemsListInvoiceLines = (await invoiceHeadService.listInvoiceLines({ invoice_no: vm.model.edit.invoice_no }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'project_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpSearchProjects({
                            project_name: vm.model.edit[id] || '',
                            businessco_no: vm.model.edit.businessco_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'project_name':
                        if (item) {
                            vm.model.edit.project_keyno = item?.item_id ?? '0';
                            vm.model.edit.project_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('project_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'project_name':
                        vm.model.edit.project_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.project_keyno = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('project_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'project_name':
                        vm.model.edit.project_keyno = '0';
                        vm.model.edit.project_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgMainView = function (value) {
                vm.model.bgMainView.selected = value;
            };

            vm.selectBgTerms = function (value) {
                vm.model.bgTerms.selected = value;
            };

            vm.selectBgDelivery = function (value) {
                vm.model.bgDelivery.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SET PRINTED OK PROCEDURE FUNCTION CALL

            vm.setPrintedOk = function () {
                vm.model.lockedPrinted = true;
                
                invoiceHeadService.setPrinted({ invoice_no: vm.model.edit.invoice_no }).then(function () {
                    vm.model.lockedPrinted = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedPrinted = false;
                });
            };

            // #endregion BUTTON SET PRINTED OK PROCEDURE FUNCTION CALL

            // #region BUTTON SEND PROCEDURE FUNCTION CALL

            vm.sendChanges = function () {
                vm.model.sendingInvoice = true;

                invoiceHeadService.sendInvoice({ invoice_no: vm.model.edit.invoice_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.sendingInvoice = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.sendingInvoice = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SEND PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                invoiceHeadService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
                loadPayments();
                loadCustFactorings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
