<div role="presentation" class="ag-cell-editor ag-labeled ag-label-align-left ag-date-field ag-input-field" ref="eInput">
    <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-date-field-label" aria-hidden="true" role="presentation" id="ag-526-label"></div>
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-date-field-input-wrapper ag-picker-field-wrapper" role="presentation">
        <input
            #inputRef
            matInput
            [matDatepicker]="picker"
            class="ag-input-field-input ag-date-field-input"
            type="text"
            tabindex="0"
            step="any"
            [placeholder]="datePlaceholder"
            aria-label="Input Editor"
            (keydown)="onDateKeydown($event)"
            [ngModel]="value"
            (ngModelChange)="onDateChanged($event)"
            style="min-width: 10ch; width: 10ch; padding-right: 0"
        />
        <mat-datepicker #picker [panelClass]="'ag-custom-component-popup'"></mat-datepicker>
        <input
            #timeRef
            matInput
            class="ag-input-field-input ag-date-field-input"
            type="text"
            tabindex="0"
            step="any"
            [placeholder]="timePlaceholder"
            aria-label="Input Editor"
            (keydown)="onTimeKeydown($event)"
            [(ngModel)]="time"
            (ngModelChange)="onTimeChanged($event)"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker" [style.fontSize]="'var(--ag-font-size)'" [style.height]="'100%'">
            <span matDatepickerToggleIcon class="far fa-calendar" [style.fontSize]="'var(--ag-font-size)'"></span>
        </mat-datepicker-toggle>
    </div>
</div>
<!-- 
<div role="presentation" class="ag-cell-editor ag-labeled ag-label-align-left ag-date-field ag-input-field" ref="eInput">
    <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-date-field-label" aria-hidden="true" role="presentation" id="ag-526-label"></div>
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-date-field-input-wrapper ag-picker-field-wrapper" role="presentation">
        <input
            #inputRef
            matInput
            [matDatepicker]="picker"
            class="ag-input-field-input ag-date-field-input"
            type="text"
            tabindex="0"
            step="any"
            [placeholder]="placeholder"
            aria-label="Input Editor"
            [ngModel]="value"
            (ngModelChange)="onDateChanged($event)"
        />
        <mat-datepicker-toggle class="ag-custom-component-popup" matIconSuffix [for]="picker" [style.fontSize]="'var(--ag-font-size)'" [style.height]="'100%'">
            <span matDatepickerToggleIcon class="far fa-calendar" [style.fontSize]="'var(--ag-font-size)'"></span>
        </mat-datepicker-toggle>
        <mat-datepicker #picker>
        </mat-datepicker>
    </div>
</div> -->
