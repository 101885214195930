(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2SubscriptionsEdit', {
        templateUrl: 'views/components/views/p2SubscriptionsEdit/p2SubscriptionsEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2SubscriptionsEditService', 'subscriptionUsergroupService', 'ttGridFactory', function ($stateParams, p2SubscriptionsEditService, subscriptionUsergroupService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2SubscriptionKeyno = $stateParams.p2_subscription_keyno;

            vm.model = {
                bgP2SubscriptionsEdit: {
                    selected: 'USERGROUPS',
                    buttons: [
                        { id: 'USERGROUPS', label: 'bg_p2subscriptions_edit_usergroups', color: 'primary', onClick: () => vm.model.bgP2SubscriptionsEdit.selected = 'USERGROUPS' },
                        { id: 'USERS', label: 'bg_p2subscriptions_edit_users', color: 'primary', onClick: () => vm.model.bgP2SubscriptionsEdit.selected = 'USERS' },
                        { id: 'WEBPAGES', label: 'bg_p2subscriptions_edit_webpages', color: 'primary', onClick: () => vm.model.bgP2SubscriptionsEdit.selected = 'WEBPAGES' }
                    ]
                },
                menu: {},
                itemsListSubscriptionUserGroups: [],
                gridUsersReady: false,
				gridWebPagesReady: false
            };

            vm.gridUsers = new ttGridFactory({
                rememberId: 'w_p2subscriptions_edit_users_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridWebPages = new ttGridFactory({
                rememberId: 'w_p2subscriptions_edit_webpages_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                p2SubscriptionsEditService.getSubscriptionsEdit({ p2_subscription_keyno: p2SubscriptionKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            let loadSubscriptionUserGroups = function () {
                subscriptionUsergroupService.listSubscriptionUserGroups({
                    argtype: 'p2_subscription_keyno',
                    argvalue: p2SubscriptionKeyno
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListSubscriptionUserGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridUsers = function () {
                vm.gridUsers.dataTask.loadData = {
                    method: 2873,
                    parameters: { p2_subscription_keyno: p2SubscriptionKeyno }
                };

                vm.model.gridUsersReady = true;

                if (vm.gridUsers.gridfunc !== null) vm.gridUsers.gridfunc.read();
            };

            let loadGridWebPages = function () {
                vm.gridWebPages.dataTask.loadData = {
                    method: 2874,
                    parameters: { p2_subscription_keyno: p2SubscriptionKeyno }
                };

                vm.model.gridWebPagesReady = true;

                if (vm.gridWebPages.gridfunc !== null) vm.gridWebPages.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgP2SubscriptionsEdit = function (value) {
                vm.model.bgP2SubscriptionsEdit.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
                loadSubscriptionUserGroups();
                loadGridUsers();
                loadGridWebPages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
