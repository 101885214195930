(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockControlSpList', {
        templateUrl: 'views/components/views/stockControlSpList/stockControlSpList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'rememberService', 'logisticService', 'stockControlSpListService', function ($q, stateService, utilityService, rememberService, logisticService, stockControlSpListService) {
            
            // ## VARIABLES & DEFINITIONS ##

            const vm = this;

            let variableNames = {
                businessco_no: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                itemsListControls: []
            };
    
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                stockControlSpListService.getSettings().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadControls()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadControls = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                
                vm.model.itemsListControls = [];
                
                return stockControlSpListService.listControls({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.itemsListControls);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadControls();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
