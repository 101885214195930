(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderPurch', {
        templateUrl: 'views/components/views/orderPurch/orderPurch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stateService', 'orderPurchService', 'rememberService', function ($stateParams, utilityService, stateService, orderPurchService, rememberService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let variableNames = {
                displaytype_id: ''
            };

            vm.model = {
                bgOrderPurchaseView: {
                    selected: 'STATUS',
                    buttons: [
                        { id: 'STATUS', label: 'bg_orderpurch_view_status', color: 'primary', onClick: () => vm.model.bgOrderPurchaseView.selected = 'STATUS' },
                        { id: 'PURCHORDERS', label: 'bg_orderpurch_view_purchorders', color: 'primary', onClick: () => vm.model.bgOrderPurchaseView.selected = 'PURCHORDERS' }
                    ]
                },
                bgFunction: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_function_select', color: 'primary', onClick: () => vm.model.bgFunction.selected = 'SELECT' },
                        { id: 'VIEW', label: 'bg_function_view', color: 'primary', onClick: () => vm.model.bgFunction.selected = 'VIEW' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                selectListDeliveryAddresses: [],
                selectListDisplayTypes: [],
                itemsListComponents: [],
                itemsListPurchaseHeads: [],
                showCreatePurchaseOrderBtn: false,
                creatingPurchaseOrder: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await orderPurchService.getSetting({ order_internal_no: orderInternalNo }))[0];

                loadComponents();
                loadPurchaseHeads();
            }

            async function loadDeladronPurchases() {
                vm.model.selectListDeliveryAddresses = (await orderPurchService.listDeladronPurchases());
            }

            async function loadDisplayTypes() {
                vm.model.selectListDisplayTypes = (await orderPurchService.listDisplayTypes());
            }

            async function loadComponents() {
                if (utilityService.validateParmsValue(vm.model.setting.deladronpurch) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.displaytype_id) !== true) return;

                vm.model.itemsListComponents = (await orderPurchService.listComponents(vm.model.setting));

                vm.model.itemsListComponents.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0' || item.item_is_selected === false) return;

                    vm.model.showCreatePurchaseOrderBtn = true;
                });
            }

            async function loadPurchaseHeads() {
                if (utilityService.validateParmsValue(vm.model.setting.order_internal_no) !== true) return;

                vm.model.itemsListPurchaseHeads = (await orderPurchService.listPurchaseHeads({ order_internal_no: vm.model.setting.order_internal_no }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'displaytype_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOrderPurchaseView = function (value) {
                vm.model.bgOrderPurchaseView.selected = value;
            };
            
            vm.selectBgFunction = function (value) {
                vm.model.bgFunction.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region TOGGLE FUNCTIONS
    		
            vm.toggleChosen = function (item) {
                item = item.item ? item.item : item;
                
                if (item.item_id !== '0' && item.item_id !== '-1') {
                    if (item.item_is_selected === '0') {
                        item.item_is_selected = '1';
                        vm.model.showCreatePurchaseOrderBtn = true;
                        //item.item_glyphicon = 'glyphicon-check' + returnGlyph;
                    } else if (item.item_is_selected === '1') {
                        item.item_is_selected = '0';
                        vm.model.showCreatePurchaseOrderBtn = false;
                        //item.item_glyphicon = 'glyphicon-unchecked' + returnGlyph;
                    }
                }
            };
            
            vm.selectAll = function () {
                vm.model.itemsListComponents.forEach((item) => {
                    if ((item.item_filtervalue && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filtervalue.toLowerCase()) !== -1) && (item.item_id !== '0' || item.item_id !== '-1')) {
                        item.item_is_selected = '0';
                        vm.model.showCreatePurchaseOrderBtn = true;

                        vm.toggleChosen(item);
                    }
                });
            };
            
            vm.unselectAll = function () {
                vm.model.itemsListComponents.forEach((item) => {
                    if ((item.item_filtervalue && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filtervalue.toLowerCase()) !== -1) && (item.item_id !== '0' || item.item_id !== '-1')) {
                        item.item_is_selected = '1';
                        vm.model.showCreatePurchaseOrderBtn = false;

                        vm.toggleChosen(item);
                    }
                    //item.item_glyphicon_color = 'black';
                });
            };
            
            vm.setMarkedAsSelected = function () {
                vm.model.creatingPurchaseOrder = true;

                let selectedRows = [];
                let mergeObj = {};
                
                vm.model.itemsListComponents.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0' || item.item_is_selected === false) return;

                    selectedRows.push(item);
                });

                mergeObj = vm.model.setting;
                mergeObj.records = selectedRows;

                orderPurchService.createPurchaseOrder(mergeObj).then(function () {
                    vm.model.creatingPurchaseOrder = false;
                    vm.model.showCreatePurchaseOrderBtn = false;
                    
                    loadComponents();
                    loadPurchaseHeads();
                });
            };

            // #endregion TOGGLE FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'deladronpurch':
                        if (utilityService.validateWatchValue(value, vm.model.setting.deladronpurch) !== true) return;

                        vm.model.setting.deladronpurch = value;

                        if (utilityService.validateParmsValue(vm.model.setting.deladronpurch) !== true) return;

                        loadComponents();
                        break;
                    case 'displaytype_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.displaytype_id) !== true) return;

                        vm.model.setting.displaytype_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.displaytype_id) !== true) return;

                        rememberFunc('displaytype_id');
                        loadComponents();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadDeladronPurchases();
                loadDisplayTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
