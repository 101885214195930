import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { TranslateEditDialogComponent, TranslateEditDialogData } from '@app/modal/translate-edit-dialog/translate-edit-dialog.component';

@Component({
    selector: 'tt-product-setting-mc-item',
    templateUrl: './product-setting-mc-item.component.html',
    styleUrls: ['./product-setting-mc-item.component.css'],
})
export class ProductSettingMcItemComponent implements OnInit {
    productSettingMcKeyno: string;

    productSettingMcItemKeyno: string;

    get?: ProductSettingMcItemGet;

    saving = false;

    deleting = false;

    itemNameFormButtons: FormButton[] = [];

    private translations: { [key: string]: string } = {
        confirm_title: '',
        confirm_message: '',
        confirm_ok_label: '',
        confirm_cancel_label: '',
        error: '',
        ok: '',
    };

    constructor(private state: StateService, private datatask: DataTaskService, private modal: ModalService, private translate: TranslateService, private dialog: MatDialog) {
        this.productSettingMcKeyno = state.getStateParams()['productsettingmc_keyno'];
        this.productSettingMcItemKeyno = state.getStateParams()['productsettingmcitem_keyno'];
    }

    public async onSave() {
        this.saving = true;

        try {
            const response = (await this.datatask.Post(3563, this.get))[0];

            if (response.errorcode !== '0') {
                this.modal.show({ type: 'warning', title: this.translations['error'], message: response.errormessage });
            } else {
                this.state.back();
            }
        } finally {
            this.saving = false;
        }
    }

    public async onDelete() {
        if (!!this.get) {
            this.deleting = true;

            try {
                const doDelete = await this.modal.openConfirmDialog({ type: 'danger', message: this.translations['confirm_message'], title: this.translations['confirm_title'] });

                if (doDelete === true) {
                    const response = (await this.datatask.Post(3564, this.get))[0];

                    if (response.errorcode !== '0') {
                        this.modal.show({ type: 'warning', title: this.translations['error'], message: response.errormessage });
                    } else {
                        this.state.back();
                    }
                }
            } finally {
                this.deleting = false;
            }
        }
    }

    private async loadProductSettingMcItem() {
        this.get = (await this.datatask.Post(3562, { productsettingmc_keyno: this.productSettingMcKeyno, productsettingmcitem_keyno: this.productSettingMcItemKeyno }))[0];

        if (this.get?.show_translation === '1') {
            this.itemNameFormButtons = [{ id: 'translate', type: 'primary', icon: 'far fa-language', tooltip: 'translations', translateTooltip: true, onClick: () => this.openTranslationDialog() }];
        } else {
            this.itemNameFormButtons = [];
        }
    }

    private async translateWords() {
        const response: { [key: string]: string } = await this.translate.translateBatch(Object.keys(this.translations));

        for (let [key, value] of Object.entries(response)) {
            this.translations[key] = value;
        }
    }

    private openTranslationDialog = async () => {
        if (!!this.get) {
            const edits: ProductSettingItemLangEdit[] = await this.datatask.Post(3569, { productsettingmcitem_keyno: this.productSettingMcItemKeyno });
            const dialogRef = this.dialog.open(TranslateEditDialogComponent, {
                width: '120rem',
                maxWidth: '95vw',
                height: '80vh',
                data: <TranslateEditDialogData<ProductSettingItemLangEdit>>{
                    heading: `${this.get.colname}: (${this.get.productsettingmcitem_keyno}) ${this.get.productsettingmcitem_name}`,
                    textToTranslate: this.get.productsettingmcitem_name,
                    edits: edits,
                    save: async (data) => {
                        await this.datatask.Post(3570, { edits: data.edits });
                        await this.loadProductSettingMcItem();
                        dialogRef.close();
                    },
                    wordNameKey: 'productsettingmcitemlang_name',
                    wordIdKey: 'productsettingmcitem_keyno',
                },
            });
        }
    };

    ngOnInit(): void {
        this.translateWords();
        this.loadProductSettingMcItem();
    }
}

interface ProductSettingMcItemGet {
    productsettingmc_keyno: string | number;
    colname: string;
    productsettingmcitem_keyno: string | number;
    productsettingmcitem_name: string;
    sortorder: string | number;
    show_translation: '1' | '0';
}

interface ProductSettingItemLangEdit extends Record<string, string> {
    productsettingmcitem_keyno: string;
    productsettingmcitemlang_name: string;
    language_id: string;
    language_name: string;
    orderby: string;
    google_language_id: string;
}
