(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('serviceObjects', {
        templateUrl: 'views/components/views/serviceObjects/serviceObjects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'rememberService', 'serviceObjectsService', 'ttGridFactory', 'layoutService', function ($stateParams, $q, stateService, utilityService, rememberService, serviceObjectsService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                gridReady: false,
                tabletSize: layoutService.getMediaQueries().tablet,
                argType: argType,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                itemsListServiceObjects: []
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_serviceobjects.grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                serviceObjectsService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadServiceObjects(),
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_all) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 3467,
                    parameters: () => vm.model.setting
                }
                
                vm.grid?.gridfunc?.read?.();
                
                vm.model.gridReady = true;
            }
            
            let loadServiceObjects = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_all) !== true) return;
                
                vm.model.itemsListServiceObjects = [];
                
                return serviceObjectsService.listServiceObjects(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListServiceObjects);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE FUNCTIONS
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE FUNCTIONS

            // #region SEARCH FUNCTION
            
            vm.searchItems = function () {
                rememberFunc('searchtext');
                loadServiceObjects();
                loadGrid();
            };

            // #endregion SEARCH FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobject':
                        go({ serviceobject_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadServiceObjects();
                            loadGrid();
                        }
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadServiceObjects();
                            loadGrid();
                        }
                        break;
                    case 'show_all':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_all) !== true) return;

                        vm.model.setting.show_all = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_all, true) !== true) return;

                        loadServiceObjects();
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
