(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderHeadMi', {
        templateUrl: 'views/components/views/orderHeadMi/orderHeadMi.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'orderHeadMiService', 'ttGridFactory', function ($stateParams, $q, stateService, modalService, translateService, orderHeadMiService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                setting: {},
                gridReady: false,
                lockedInvoiceCreate: false,
                lockedManualCreate: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_orderheadmi_invoice_now_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATIONS

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadSetting = function () {
                const deferred = $q.defer();

                orderHeadMiService.getSetting({ order_internal_no: orderInternalNo }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2916,
                    parameters: { order_internal_no: vm.model.setting.order_internal_no }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region CREATE BUTTONS

            vm.createInvoiceChanges = function () {
                vm.model.lockedInvoiceCreate = true;
                
                orderHeadMiService.createInvoiceObj(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedInvoiceCreate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedInvoiceCreate = false;

                        if (vm.model.setting.setting_goto_invoicehead === '1' && response[0].invoice_no) {
                            stateService.go('invoicehead', { invoice_no: response[0].invoice_no });
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            vm.createManualChanges = function () {
                vm.model.lockedManualCreate = true;

                var currentData = vm.grid.gridfunc.getAllRows();
                var selectedItems = [];

                angular.forEach(currentData, function (item) {
                    selectedItems.push({
                        orderline_keyno: item.orderline_keyno,
                        invoice_quantity_now: item.invoice_quantity_now
                    });
                });

                var mergeObj = angular.copy(vm.model.setting);
                mergeObj.records = selectedItems;

                orderHeadMiService.createManualObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedManualCreate = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedManualCreate = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion CREATE BUTTONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
