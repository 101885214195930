<tt-page *ngIf="!!get">
    <tt-tabs [(ttSelectedId)]="get.selected_tab" (ttSelectedIdChange)="onTabChange($event)" ttRememberId="w_mainmenu.selected_tab" ttOnlyRemember="true">
        <tt-tab ttId="suggestions" ttLabel="history" ttIcon="far fa-history"></tt-tab>
        <tt-tab ttId="mainmenu" ttLabel="mainmenu" ttIcon="far fa-list-ul"></tt-tab>
        <tt-tab ttId="dashboard" ttLabel="dashboard" ttIcon="far fa-analytics"></tt-tab>
    </tt-tabs>

    <ng-container [ngSwitch]="get.selected_tab">
        <div *ngSwitchCase="'suggestions'">
            <ng-container class="col-xs-12 sp-0" [ngTemplateOutlet]="filterView"></ng-container>
            <tt-list class="col-xs-12 col-md-3 sp-0" [ttData]="menuSuggestions" [ttFilter]="get.menuitem_filtervalue"></tt-list>
        </div>
        <div *ngSwitchCase="'mainmenu'">
            <ng-container class="col-xs-12 sp-0" [ngTemplateOutlet]="filterView"></ng-container>
            <ng-container *ngIf="mobileQuery.matches">
                <tt-list
                    *ngFor="let menu of allMenus"
                    class="col-xs-12 sp-0"
                    [ttData]="menu"
                    [ttFilter]="get.menuitem_filtervalue"
                    [style.width]="(allMenus.length > 0 ? 100 / allMenus.length : 100) + '%'"
                ></tt-list>
            </ng-container>
            <tt-list *ngIf="!mobileQuery.matches" class="col-xs-12 sp-0" [ttData]="menus" [ttFilter]="get.menuitem_filtervalue"></tt-list>
        </div>
        <div *ngSwitchCase="'dashboard'">
            <gridstack class="height-full mainmenu__dashboard" [options]="gridOptions">
                <gridstack-item *ngFor="let webpage of webpageDashboards" [options]="{ id: webpage.webpagename, w: 12 }">
                    <tt-webpagemenu-dashboard [ttName]="webpage.webpagename" [ttTitle]="webpage.webpagetitle" (ttEditChange)="onWidgetEdit($event, webpage.webpagename)"></tt-webpagemenu-dashboard>
                </gridstack-item>
            </gridstack>
        </div>
    </ng-container>
</tt-page>

<ng-template #filterView>
    <tt-card *ngIf="!!get" class="col-xs-12 col-md-12 sp-0" style="margin-bottom: 5px">
        <tt-input
            class="col-xs-12 col-md-3 sp-0"
            style="margin-bottom: -7px"
            ttLabelView="none"
            ttPlaceholder="menusearch_placeholder"
            ttTranslatePlaceholder
            [(ttModel)]="get.menuitem_filtervalue"
            ttShowClearButton
        ></tt-input>
    </tt-card>
</ng-template>

<!-- <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
        <div class="col-xs-12 well well-sm">

                <im-input-directive [plabel]="model.setting.gdpr_title" [field]="model.setting.gdpr_message" [type]="'message'"></im-input-directive>
                <tt-button-save class="col-xs-12 sp-0" [ttText]="'gdpr_approve'" [ttLocked]="model.lockedApprove" (ttClick)="approve()" ttTextAlign="center"></tt-button-save>
        </div>
    </form>




<ng-template #mainMenuTemplate>
    <div *ngIf="!model.mobileSize.matches">
        <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
            <div class="col-xs-12 well well-sm">
                <div>
                    <div class="col-xs-6 sp-0">
                        <a href=""
                           (click)="setFavourites()"
                           class="btn btn-default btn-md pull-left col-xs-1 sp-0"
                           [ngStyle]="style.history">
                            <span [ngClass]="
                model.setting.show_suggestions === '1'
                  ? 'fas fa-bars'
                  : 'fas fa-history'
              "
                                  style="width: 14px"></span>
                        </a>
                    </div>

                    <div *ngIf="model.setting.show_global_search === '1' && !model.miniMobileSize.matches">
                    </div>
                </div>

                <div *ngIf="model.setting.show_gdpr_message === '1'">
                    <tt-message [ttLabel]="model.setting.gdpr_title"
                                [ttModel]="model.setting.gdpr_message"
                                [ttIsHtml]="model.isHtml"
                                [ttTranslate]="false"></tt-message>
                    <im-input-directive [plabel]="model.setting.gdpr_title"
                                        sublabel=""
                                        [field]="model.setting.gdpr_message"
                                        myuser=""
                                        type="message"></im-input-directive>
                    <tt-button-save class="col-xs-12 sp-0"
                                    [ttText]="'gdpr_approve'"
                                    [ttLocked]="model.lockedApprove"
                                    (ttClick)="approve()"
                                    [ttTextAlign]="'center'"></tt-button-save>
                </div>
            </div>

            <div *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '1'">
                <app-main-menu-list></app-main-menu-list>
            </div>

            <div class="col-xs-12 sp-0"
                 *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '0'">
                <tt-list class="col-xs-6 sp-0"
                         [listItems]="model.itemsListMyMenus"
                         badge=""
                         ltype=""
                         [designVar]="'s12t0'"
                         [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                <tt-list class="col-xs-6 sp-0"
                         [listItems]="model.criticalProcesses"
                         badge=""
                         ltype=""
                         [designVar]="'s12t0'"
                         [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            </div>
        </form>
    </div>

    <div *ngIf="model.mobileSize.matches">
        <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
      
            <div class="well well-sm col-xs-12" *ngIf="model.setting.show_gdpr_message === '1'">
                <tt-message [ttLabel]="model.setting.gdpr_title"
                            [ttModel]="model.setting.gdpr_message"
                            [ttIsHtml]="model.isHtml"
                            [ttTranslate]="false"></tt-message>
                <im-input-directive [plabel]="model.setting.gdpr_title"
                                    sublabel=""
                                    [field]="model.setting.gdpr_message"
                                    myuser=""
                                    type="message"></im-input-directive>
                <tt-button-save class="col-xs-12 sp-0"
                                [ttText]="'gdpr_approve'"
                                [ttLocked]="model.lockedApprove"
                                (ttClick)="approve()"
                                [ttTextAlign]="'center'"></tt-button-save>
            </div>

            <div *ngIf="model.setting.show_gdpr_message !== '1'">
                <app-main-menu-list-all></app-main-menu-list-all>

                <div class="col-xs-12 sp-0" *ngIf="model.setting.show_suggestions !== '0'">
                    <tt-list class="col-xs-6 sp-0"
                             [listItems]="model.itemsListMyMenus"
                             badge=""
                             ltype=""
                             [designVar]="'s12t0'"
                             [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                    <tt-list class="col-xs-6 sp-0"
                             [listItems]="model.criticalProcesses"
                             badge="badge"
                             ltype=""
                             [designVar]="'s12t0'"
                             [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                </div>
            </div>
        </form>
    </div>
</ng-template>




<ng-template #mainMenuListAllTemplate>
    <div class="col-xs-12 well well-sm" style="position: sticky; z-index: 10; padding-bottom: 0;" [ngStyle]="style.search">
        <div class="col-xs-4 sp-0" style="display: flex; gap: 5px;">
            <a href="#" (click)="setFavourites()" class="btn btn-default btn-md pull-lef " [ngStyle]="style.history">
                <span [ngClass]="model.setting.show_suggestions === '1' ? 'fas fa-bars' : 'fas fa-history'"></span>
            </a>
            <tt-input-buttons class="col-xs-11 sp-0"
                           [ttLabel]="''"
                           [ttHideLabel]="true"
                           [ttFocus]="true"
                           [(ttModel)]="model.setting.menuitem_filtervalue"
                           [ttPlaceholder]="'menusearch_placeholder'"
                           (ttChange)="model.setting.menuitem_filtervalue = $event"
                           (ttModelChange)="model.setting.menuitem_filtervalue = $event"
                           [ttButtons]="model.filterValueButtons">
            </tt-input-buttons>
        </div>
        <span class="col-xs-5 sp-0"></span>
        <div class="col-xs-3 sp-0" *ngIf="model.setting.show_global_search !== '0'">
        </div>
    </div>

    <div class="col-xs-12 sp-0" *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '1'">
        <div *ngFor="let menu of model.menus">
            <div class="col-xs-12 sp-0" [ngStyle]="style.list">
                <tt-list [listItems]="menu" badge="" ltype="" designVar="s12t0" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            </div>
        </div>
    </div>
</ng-template> -->
<!-- </div></ng-container
    ></tt-page
> -->
