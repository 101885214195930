<tt-page>
    <tt-page-heading ttModel="deviationsettings" ttTranslate></tt-page-heading>
    <tt-tabs ttRememberId="deviationsettings_tab" [(ttSelectedId)]="selectedTab" (ttSelectedIdChange)="onTabChanged($event)">
        <tt-tab ttId="deviationtypes" ttLabel="Avvikstyper" ttTranslateLabel="false"></tt-tab>
        <tt-tab ttId="deviationsource" ttLabel="Avviksårsak" ttTranslateLabel="false"></tt-tab>
        <tt-tab ttId="deviationsource2" ttLabel="Avvikskilde" ttTranslateLabel="false"></tt-tab>
    </tt-tabs>
    <ng-container [ngSwitch]="selectedTab">
        <div *ngSwitchCase="'deviationtypes'">
            <div class="col-xs-12 col-md-4 sp-4">
                <tt-card>
                    <tt-input [(ttModel)]="filter"></tt-input>
                    <tt-button ttText="create_deviationtype" (ttClick)="createNewDeviationType()"></tt-button>
                </tt-card>
                <tt-list
                    #deviationTypesList
                    [(ttData)]="deviationTypes"
                    [ttFilter]="filter"
                    [ttRadioSelection]="tabletAndUpQuery.matches"
                    (ttRadioSelectionChanged)="selectedDeviationType = $event.deviationtype_keyno"
                    [ttGoto]="!tabletAndUpQuery.matches"
                ></tt-list>
            </div>
            <tt-deviation-type
                *ngIf="selectedDeviationType && tabletAndUpQuery.matches"
                [deviationTypeKeyno]="selectedDeviationType"
                (onSave)="onDeviationTypeSaved()"
                (onDelete)="onDeviationTypeDeleted()"
            ></tt-deviation-type>
        </div>

        <div *ngSwitchCase="'deviationsource'">
            <div class="col-xs-12 col-md-4 sp-4">
                <tt-card>
                    <tt-input [(ttModel)]="filter"></tt-input>
                    <tt-button ttText="create_deviationsource" (ttClick)="createNewDeviationSource()"></tt-button>
                </tt-card>
                <tt-list
                    #deviationSourcesList
                    [(ttData)]="deviationSources"
                    [ttFilter]="filter"
                    [ttRadioSelection]="tabletAndUpQuery.matches"
                    (ttRadioSelectionChanged)="selectedDeviationSource = $event.item_id"
                    [ttGoto]="!tabletAndUpQuery.matches"
                ></tt-list>
            </div>
            <tt-deviation-source
                *ngIf="selectedDeviationSource && tabletAndUpQuery.matches"
                [deviationSourceKeyno]="selectedDeviationSource"
                (onSave)="onDeviationSourceSaved()"
                (onDelete)="onDeviationSourceDeleted()"
            ></tt-deviation-source>
        </div>

        <div *ngSwitchCase="'deviationsource2'">
            <div class="col-xs-12 col-md-4 sp-4">
                <tt-card>
                    <tt-input [(ttModel)]="filter"></tt-input>
                    <tt-button ttText="create_deviationsource2" (ttClick)="createNewDeviationSource2()"></tt-button>
                </tt-card>
                <tt-list
                    #deviationSources2List
                    [(ttData)]="deviationSources2"
                    [ttFilter]="filter"
                    [ttRadioSelection]="tabletAndUpQuery.matches"
                    (ttRadioSelectionChanged)="selectedDeviationSource2 = $event.item_id"
                    [ttGoto]="!tabletAndUpQuery.matches"
                ></tt-list>
            </div>
            <tt-deviation-source2
                *ngIf="selectedDeviationSource2 && tabletAndUpQuery.matches"
                [deviationSource2Keyno]="selectedDeviationSource2"
                (onSave)="onDeviationSource2Saved()"
                (onDelete)="onDeviationSource2Deleted()"
            ></tt-deviation-source2>
        </div>
    </ng-container>
</tt-page>
