<li
    class="tt-list-item"
    [ngClass]="{ 'tt-list-item--clickable': (ttClickable === true && ttClick.observed === true) || ttGoto, 'tt-list-item--radio-selected': !!ttRadioSelection && ttRadioChecked === ttId }"
>
    <ng-content *ngIf="ttButtonsPosition === 'left'" select="[buttons]"></ng-content>
    <label *ngIf="ttRadioSelection" class="tt-list-item__checkbox-label">
        <input
            type="radio"
            [value]="ttId"
            name="test"
            [checked]="ttRadioChecked === ttId"
            (change)="onRadioChanged($event)"
            class="tt-input__form-control tt-input__form-control--checkbox tt-list-item__checkbox"
        />
    </label>
    <label *ngIf="ttCheckboxSelection" class="tt-list-item__checkbox-label">
        <input type="checkbox" [(ngModel)]="checked" (ngModelChange)="onCheckboxChanged($event)" class="tt-input__form-control tt-input__form-control--checkbox tt-list-item__checkbox" />
    </label>

    <ng-container *ngIf="!ttListItemTemplate">
        <button *ngIf="!ttGoto" class="tt-list-item__button" (click)="onClick($event)">
            <ng-container [ngTemplateOutlet]="listItem"></ng-container>
        </button>
        <a *ngIf="ttGoto" [attr.href]="statepath" class="tt-list-item__button" (click)="onClick($event)">
            <ng-container [ngTemplateOutlet]="listItem"></ng-container>
        </a>
    </ng-container>
    <ng-container *ngIf="ttListItemTemplate">
        <ng-container [ngTemplateOutlet]="ttListItemTemplate"></ng-container>
    </ng-container>

    <tt-form-field-buttons *ngIf="!!ttButtons && ttButtons.length > 0" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
    <ng-content *ngIf="ttButtonsPosition !== 'left'" select="[buttons]"></ng-content>
</li>

<ng-template #listItem>
    <span *ngIf="ttBackStyle" class="fa-fw far fa-chevron-left"></span>
    <span *ngIf="ttThumbnail">
        <img [src]="ttThumbnail + '?thumbnail=' + 32" [alt]="ttTile" />
    </span>
    <span class="tt-list-item__icons" *ngIf="icons.length > 0">
        <span *ngFor="let icon of icons" class="fa-fw" [ngClass]="icon.icon" [style.color]="icon.color"></span>
    </span>
    <span class="tt-list-item__info">
        <span class="tt-list-item__title" *ngIf="!!ttTile">
            <span>{{ ttTile }}</span>
            <span *ngIf="!!ttBadge && !!ttBadge.trim()" class="tt-list-item__badge">{{ ttBadge }}</span>
        </span>
        <span class="tt-list-item__subtitle" *ngIf="!!ttSubtitle"> {{ ttSubtitle }}</span>
        <span class="tt-list-item__subtext" *ngIf="!!ttSubtext">{{ ttSubtext }}</span>
    </span>
</ng-template>
