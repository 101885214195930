(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('relatedPurchOrders', {
        templateUrl: 'views/components/views/relatedPurchOrders/relatedPurchOrders.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'relatedPurchOrdersService', function ($stateParams, relatedPurchOrdersService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                itemsListRelated: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadRelatedPurchaseOrders = function () {
                relatedPurchOrdersService.listRelatedPurchaseOrders({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListRelated);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadRelatedPurchaseOrders();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
