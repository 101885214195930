(function () {
    'use strict';

    angular.module("imApp").factory("p2ReportDefService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 334,
                    parameters: parms || {}
                });
            },
            listWebPages: function (parms) {
                return $ihttp.post({
                    method: 4,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 336,
                    parameters: edit
                });
            },
            listReportTypes: function () {
                return $ihttp.post({
                    method: 3375,
                    parameters: {}
                });
            },
            listHtmlReports: function () {
                return $ihttp.post({
                    method: 3361,
                    parameters: {}
                });
            }
        };

        return service;
    }]);
})();
