(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('operations', {
        templateUrl: 'views/components/views/operations/operations.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'operationsService', 'logisticService', 'ttGridFactory', 'rememberService', 'layoutService', function ($q, stateService, utilityService, operationsService, logisticService, ttGridFactory, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let variableNames = {
                show_only_active: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                bgOperationsSelection: {
                    selected: '1',
                    buttons: [
                        { id: '1', label: 'operation_direct', color: 'primary', onClick: function () { vm.model.bgOperationsSelection.selected = '1'; } },
                        { id: '0', label: 'operation_indirect', color: 'primary', onClick: function () { vm.model.bgOperationsSelection.selected = '0'; } }
                    ]
                },
                setting: {},
                itemsListOperations: [],
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_operations_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                operationsService.getOperations().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadOperations(),
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadOperations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                vm.model.itemsListOperations = [];

                return logisticService.listOperations({
                    direct: vm.model.bgOperationsSelection.selected,
                    show_only_active: vm.model.setting.show_only_active
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOperations);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region LOAD GRID FUNCTION CALL

            let loadGrid = function () {
                let mergeObj = vm.model.setting;
                mergeObj.direct = vm.model.bgOperationsSelection.selected;

                vm.grid.dataTask.loadData = {
                    method: 3472,
                    parameters: mergeObj
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion LOAD GRID FUNCTION CALL

            // #region REMEMBER FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'show_only_active':
                        if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                        variableValue = vm.model.setting.show_only_active;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER FUNCTION CALLS

            // #region BUTTON RADIO FUNCTION

            vm.selectBgOperationsSelection = function (item) {
                vm.model.bgOperationsSelection.selected = item;

                loadOperations();
                loadGrid();
            };

            // #region BUTTON RADIO FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'operation':
                        go({ operation_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'show_only_active':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_only_active) !== true) return;

                        vm.model.setting.show_only_active = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                        rememberFunc('show_only_active');
                        loadOperations();
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
