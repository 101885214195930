(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('forecast', {
        templateUrl: 'views/components/views/forecast/forecast.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'forecastService', 'stockForecastService', function ($stateParams, stateService, forecastService, stockForecastService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let forecastKeyno = $stateParams.forecast_keyno;
            
            vm.model = {
                edit: {},
                selectListForecastTypes: [],
                selectListResolutions: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await forecastService.getEdit({ forecast_keyno: forecastKeyno }))[0];
            }

            async function loadForecastTypes() {
                vm.model.selectListForecastTypes = (await forecastService.listForecastTypes());
            }

            async function loadResolutions() {
                vm.model.selectListResolutions = (await stockForecastService.listResolutions());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                forecastService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadForecastTypes();
                loadResolutions();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
