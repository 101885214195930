(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('priceList', {
        templateUrl: 'views/components/views/priceList/priceList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'priceListService', 'logisticService', 'economyService', function ($stateParams, stateService, utilityService, priceListService, logisticService, economyService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let priceListId = $stateParams.pricelist_id;
            
            vm.model = {
                priceListId: priceListId,
                edit: {},
                selectListCompanies: [],
                selectListCurrencies: [],
                selectListTaxes: [],
                selectListPrices: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await priceListService.getEdit({ pricelist_id: priceListId }))[0];

                loadPrices();
            }

            async function loadCompanies() {
                vm.model.selectListCompanies = (await logisticService.listCompanies());
            }

            async function loadCurrencies() {
                vm.model.selectListCurrencies = (await economyService.listCurrencies());
            }

            async function loadTaxes() {
                vm.model.selectListTaxes = (await economyService.listTaxes());
            }

            async function loadPrices() {
                if (utilityService.validateParmsValue(vm.model.edit.pricelist_id) !== true) return;

                vm.model.selectListPrices = (await economyService.listPrices({
                    factorbased: '1',
                    pricelist_id: vm.model.edit.pricelist_id
                }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE BUTTON FUNCTION
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                priceListService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadCurrencies();
                loadTaxes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
