import { Component, ComponentRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'tt-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.css'],
})
export class PopoverComponent {
    @Input()
    public ttPopover?: TemplateRef<HTMLElement>;

    @Input()
    public ttPopoverBackgroundColor: string = 'var(--tt-modal--color)';

    @Input()
    public ttPosition: 'up' | 'down' | 'left' | 'right' = 'up';

    @Output()
    public ttOutsideClick = new EventEmitter();
}
