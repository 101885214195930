(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockHistoric', {
        templateUrl: 'views/components/views/stockHistoric/stockHistoric.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'stockHistoricService', 'logisticService', 'ttGridFactory', 'rememberService', function ($q, stateService, utilityService, stockHistoricService, logisticService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                historic_stock_date: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                gridReady: false,
                retrieving: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_stockhistoric_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	stockHistoricService.getSetting().then(function (info) {
            		angular.copy(info[0], vm.model.setting);

                    loadStockPlaces()
                    
                    deferred.resolve();
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3456,
                    parameters: vm.model.setting
                };

                vm.model.retrieving = false;
                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'historic_stock_date':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON RETRIEVE PROCEDURE FUNCTION CALL

            vm.retrieveChanges = function () {
                vm.model.retrieving = true;

                loadGrid();
            };

            // #endregion BUTTON RETRIEVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        break;
                    case 'historic_stock_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting.historic_stock_date) !== true) return;

                        vm.model.setting.historic_stock_date = value;

                        if (utilityService.validateParmsValue(vm.model.setting.historic_stock_date) !== true) return;

                        rememberFunc('historic_stock_date');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
