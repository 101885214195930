(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchLine', {
        templateUrl: 'views/components/views/purchLine/purchLine.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'translateService', 'modalService', 'utilityService', 'purchLineService', 'stockAssignService', 'logisticService', 'ttGridFactory', 'typeaheadService', 'eventService', function ($stateParams, $q, $timeout, stateService, translateService, modalService, utilityService, purchLineService, stockAssignService, logisticService, ttGridFactory, typeaheadService, eventService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let purchInternalNo = $stateParams.purch_internal_no;
            let purchLineKeyno = $stateParams.purchline_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_cancel_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                purchLineKeyno: purchLineKeyno,
                bgView: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'purchline_gb_view_basic', color: 'primary', onClick: () => vm.model.bgView.selected = 'BASIC' },
                        { id: 'OTHER', label: 'purchline_gb_view_other', color: 'primary', onClick: () => vm.model.bgView.selected = 'OTHER' }
                    ]
                },
                productSearchButtons: [
                    {
                        id: 'new',
                        icon: 'fa fa-solid fa-plus',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('productcreate', {
                            argtype: '-',
                            argvalue: '-'
                        })
                    }
                ],
                productSearchButtons2: [
                    {
                        id: 'configurate',
                        icon: 'fa fa-solid fa-cubes',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('orderlinems', {
                            argtype: 'purchline',
                            headno: vm.model.edit.purch_internal_no,
                            lineno: '0'
                        })
                    },
                    {
                        id: 'new',
                        icon: 'fa fa-solid fa-plus',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('productcreate', {
                            argtype: '-',
                            argvalue: '-'
                        })
                    }
                ],
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                stockIncomingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockincoming', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                inputPriceOrderValueButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('productprices', { prod_id: vm.model.edit.prod_id }) }
                ],
                stockValueButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                edit: {},
                selectListProductSizes: [],
                selectListChoices: [],
                selectListUnits: [],
                gridReady: false,
                lockedSaveAndNew: false,
                lockedSaveAndExit: false,
                lockedDelete: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_purchline_purchprices_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATESERVICE FUNCTION

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATESERVICE FUNCTION

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                purchLineService.getEdit({
                    purch_internal_no: purchInternalNo,
                    purchline_keyno: purchLineKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                    focusWrapper();

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (vm.model.edit.show_purchprices_from_other_suppliers === '1') {
                    vm.grid.dataTask.loadData = {
                        method: 2859,
                        parameters: vm.model.edit
                    };

                    vm.model.gridReady = true;

                    if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
                }
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE CALLS

            let loadProductSizes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return logisticService.listProductSizes({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    angular.copy(response, vm.model.selectListProductSizes);
                });
            };

            let loadProductChoices = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return logisticService.listProductChoices({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    angular.copy(response, vm.model.selectListChoices);
                });
            };

            let loadProductUnits = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                
                return stockAssignService.listProductUnits({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    angular.copy(response, vm.model.selectListUnits);
                });
            };

            let updateProduct = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return purchLineService.changeProduct({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    vm.model.edit.prod_id = response[0].prod_id;
                    vm.model.edit.prod_name = response[0].prod_name;
                    vm.model.edit.prodsize_keyno = response[0].prodsize_keyno;
                    vm.model.edit.prodchoice_keyno = response[0].prodchoice_keyno;
                    vm.model.edit.unit_name_stock = response[0].unit_name_stock;
                    vm.model.edit.nbrof_prodsize = response[0].nbrof_prodsize;
                    vm.model.edit.nbrof_prodchoice = response[0].nbrof_prodchoice;
                    vm.model.edit.stock_unit_no = response[0].stock_unit_no;
                    vm.model.edit.prod_text3 = response[0].prod_text3;
                    vm.model.edit.input_unit_no = response[0].prod_purch_unit_no;
                    if (response[0].purch_number_default > 0) {
                        vm.model.edit.input_quantity = response[0].purch_number_default;
                    }
                    updatePrice();
                    retrieveExampleData();
                    loadProductSizes();
                    loadProductChoices();
                    loadProductUnits();
                });
            };

            let updateProductSize = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_quantity) !== true) return;

                if (vm.model.edit.purchline_keyno === '0') {
                    return purchLineService.changeProductSize({
                        prodsize_keyno: vm.model.edit.prodsize_keyno,
                        input_unit_no: vm.model.edit.input_unit_no,
                        input_quantity: vm.model.edit.input_quantity
                    }).then(function (response) {
                        vm.model.edit.stock_unit_no = response[0].stock_unit_no;
                        vm.model.edit.purch_number_unit_no = response[0].purch_number_unit_no;
                        vm.model.edit.purch_price_unit_no = response[0].purch_price_unit_no;
                        vm.model.edit.input_unit_no = response[0].purch_number_unit_no;
                        vm.model.edit.exampledata = response[0].exampledata;
                        vm.model.edit.input_quantity = response[0].purch_number_default;

                        updateStockInfo();
                        retrieveExampleData();
                    });
                }
            };

            let updatePrice = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;

                if (vm.model.edit.purchline_keyno === '0') {
                    return purchLineService.getPrice(vm.model.edit).then(function (response) {
                        vm.model.edit.input_price_ordval = response[0].input_price_ordval;
                        vm.model.edit.linediscount_pst = response[0].discount_pst;

                        updateStockInfo();
                        recalcInputAmountOrderValue();
                        recalcInputPriceNetOrderValue();
                    });
                }
            };

            let updateStockInfo = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno) !== true) return;

                return purchLineService.getStockInfo(vm.model.edit).then(function (response) {
                    vm.model.edit.stock_keyno = response[0].stock_keyno;
                    vm.model.edit.stock_incoming = response[0].stock_incoming;
                    vm.model.edit.stock_stockvalue = response[0].stock_stockvalue;
                });
            };

            let retrieveExampleData = function () {
                if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;
                
                return purchLineService.getExampleData(vm.model.edit).then(function (response) {
                    vm.model.edit.exampledata = response[0].exampledata;
                });
            };

            // #endregion UPDATE PROCEDURE CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function () {
                return typeaheadService.searchProduct({
                    prod_name: vm.model.edit.product_search,
                    webpage_name: stateService.getCurrentName(),
                    parm1: vm.model.edit.purch_internal_no,
                    parm2: vm.model.edit.purchline_keyno,
                    supplier_no: vm.model.edit.supplier_no,
                    search_only_this_supplier: vm.model.edit.search_only_this_supplier
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onProductSearchSelected = function (item) {
                if (item) {
                    vm.model.edit.prod_id = item?.item_id ?? '';
                    vm.model.edit.product_search = item?.item_name ?? '';

                    updateProduct();
                } else {
                    vm.onProductSearchClear();
                    updateProduct();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onProductSearchChanged = function (value, item) {
                vm.model.edit.product_search = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.prod_id = item.item_id;
                    }
                } else {
                    vm.onProductSearchClear();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onProductSearchClear = function () {
                vm.model.edit.product_search = '';

                if (vm.model.edit.product_search === '' && vm.model.edit.purchline_keyno === '0') {
                    vm.model.edit.prod_id = '';
                    vm.model.edit.prod_name = '';
                    vm.model.edit.prodsize_keyno = '0';
                    vm.model.edit.prodchoice_keyno = '0';
                    vm.model.edit.unit_name_stock = '';
                    vm.model.edit.nbrof_prodsize = '0';
                    vm.model.edit.nbrof_prodchoice = '0';
                    vm.model.edit.stock_unit_no = '0';
                    vm.model.edit.input_quantity = '0';
                    vm.model.edit.input_price_ordval = '0';
                    vm.model.edit.linediscount_pst = '0';
                    vm.model.selectListUnits = [];
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region RECALCULATION FUNCTIONS

            let recalcInputAmountOrderValue = function () {
                //input_amount * input_quantity * (1 - linediscount_pst / 100)
                vm.model.edit.input_amount_ordval = utilityService.parseNumber(vm.model.edit.input_price_ordval) * utilityService.parseNumber(vm.model.edit.input_quantity) * (1 - utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100);
            };

            let recalcInputPriceNetOrderValue = function () {
                // input_price_net_ordval = input_price_ordval * (1 - ( linediscount_pst / 100))
                vm.model.edit.input_price_net_ordval = utilityService.parseNumber(vm.model.edit.input_price_ordval) * (1 - (utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100));
            };

            // #endregion RECALCULATION FUNCTIONS

            // #region SAVE BUTTON FUNCTION

            vm.saveAndExitChanges = function () {
                vm.model.lockedSaveAndExit = true;

                purchLineService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSaveAndExit = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSaveAndExit = false;
                });
            };

            vm.saveAndNewChanges = function () {
                vm.model.lockedSaveAndNew = true;

                purchLineService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSaveAndNew = false;
                    loadEdit();

                    if (vm.model.edit.show_productconfig === '0') {
                        setSearchFocus();
                    } else {
                        setSearchFocus2();
                    }
                }, function () {
                    vm.model.lockedSaveAndNew = false;

                    if (vm.model.edit.show_productconfig === '0') {
                        setSearchFocus();
                    } else {
                        setSearchFocus2();
                    }
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region BUTTON CANCEL PROCEDURE FUNCTION CALL

            vm.cancelValues = function () {
                vm.model.lockedCancelRest = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_cancel_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    purchLineService.cancelRestPurchaseLine(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                                        vm.model.lockedCancelRest = false;
                        			}
                        		}]
                        	});
                        } else {
                            vm.model.lockedCancelRest = false;
                            loadEdit();
                        }
                    }, function () {
                        vm.model.lockedCancelRest = false;
                    });
                }, function () {
                    vm.model.lockedCancelRest = false;
                });
            };

            // #endregion BUTTON CANCEL PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    purchLineService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgView = function (value) {
                vm.model.bgView.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodsize_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodsize_keyno) !== true) return;

                        vm.model.edit.prodsize_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;

                        updateProductSize();
                        updatePrice();
                        loadGrid();
                        break;
                    case 'prodchoice_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodchoice_keyno) !== true) return;

                        vm.model.edit.prodchoice_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno) !== true) return;

                        updatePrice();
                        loadGrid();
                        break;
                    case 'input_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.input_unit_no) !== true) return;

                        vm.model.edit.input_unit_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;

                        updatePrice();
                        retrieveExampleData();
                        break;
                    case 'input_price_ordval':
                    case 'linediscount_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcInputAmountOrderValue();
                        recalcInputPriceNetOrderValue();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'input_quantity':
                        if (utilityService.validateParmsValue(vm.model.edit.input_quantity) !== true) return;

                        updatePrice();
                        recalcInputAmountOrderValue();
                        retrieveExampleData();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

            // #region SET SEARCH FOCUS FUNCTION

            let setSearchFocus = function (index) {
                if (purchLineKeyno === '0') {
                    if (angular.isUndefined(index)) {
                        index = 0;
                    }

                    document.getElementsByName('formPurchLineSearch')[0][index].focus();
                }
            };

            let setSearchFocus2 = function (index) {
                if (purchLineKeyno === '0') {
                    if (angular.isUndefined(index)) {
                        index = 0;
                    }

                    let element = document.getElementsByName('formPurchLineSearch2')[0];

                    if (null != element) {
                        element[index].focus();
                    }
                }
            };

            // #endregion SET SEARCH FOCUS FUNCTION

            // #region EVENTSERVICE FUNCTION

            let focusWrapper = function () {
                $timeout(function () {
                    if (vm.model.edit.show_productconfig === '0') {
                        setSearchFocus();
                    } else {
                        setSearchFocus2();
                    }
                }, 100);
            };

            eventService.on('$viewContentLoaded', function () {
                focusWrapper();
            });

            // #endregion EVENTSERVICE FUNCTION
        }]
    });
})();
