(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('payCheckBatches', {
        templateUrl: 'views/components/views/payCheckBatches/payCheckBatches.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'payCheckBatchesService', function (stateService, payCheckBatchesService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                setting: {},
    			itemsListPayCheckBatches: []
    		};

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                payCheckBatchesService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadPayCheckBatches = function () {
                payCheckBatchesService.listPayCheckBatches().then(function (list) {
                    angular.copy(list, vm.model.itemsListPayCheckBatches);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'paycheckbatch':
                        go({ paycheckbatch_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadPayCheckBatches();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
