(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderSpecialGrid', {
        templateUrl: 'views/components/views/orderSpecial/orderSpecial.template.grid.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$localStorage', '$q', '$uibModal', 'layoutService', 'utilityService', 'orderSpecialService', 'modalService', 'ttGridFactory', function ($localStorage, $q, $uibModal, layoutService, us, orderSpecialService, modalService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];
            let newRowData = {};
            let isSaveRunning = false;

            //vm.lock = true;

            us.setDefaultValues($localStorage, {
                orderSpecial: {
                    printer: {}
                }
            });

            vm.$storage = $localStorage.orderSpecial;

            vm.style = {
                btn: {
                    paddingTop: '0px'
                },
                input: {
                }
            };

            vm.dateOptions = {
                date: { show: true, disabled: false, align: 'right' },
                hour: { show: true, disabled: false, align: 'right' },
                minute: { show: true, disabled: false, align: 'right', interval: 15 },
            };

            vm.model = {
                selectedCustNo: null,
                selectedCustName: null,
                selectedStockplaceId: null,
                deliveryDatetime: null,
                reference: '',
                deliveryComment: ''
            };

            vm.stockplace = {
                remId: 'w_orderspecial.stockplace',
                data: {
                    stockplace_id: '',
                    stockplace_name: ''
                },
                list: []
            };

            let afterNewRow = function () {
                let dataItem = vm.grid.gridfunc.getDataItem();

                if (Object.hasOwn(dataItem, 'data')) {
                    vm.grid.gridfunc.getGridApi().stopEditing();
                    angular.forEach(newRowData, (value, key) => dataItem.data[key] = value);
                    dataItem.data._dirty = true;
                    dataItem.setData(dataItem.data);
                } else {
                    angular.forEach(newRowData, function (value, key) {
                        if (angular.isDefined(dataItem[key])) {
                            dataItem.dirtyFields[value] = true;
                            dataItem[key] = value;
                        }
                    });
                    dataItem.dirty = true;
                }
            };

            vm.grid = new ttGridFactory({ rememberId: 'w_orderspecial.grid', loadData: { method: 2518, parameters: { cust_no: vm.model.selectedCustNo } } })
                //.setAddRowFunction({
                //    method: null,
                //    parameters: null,
                //    pre: (dataItem) => openAddModal(dataItem),
                //    post: () => afterNewRow(),
                //})
                .setToolbar({ pdfExport: false, excelExport: false, columnVisibility: false, headers: false, layouts: false, addSimple: true })
                .setCustomToolbarButtons([
                    { name: 'saveAndPrint', text: 'save_and_print', func: () => canSave(true, false), icon: 'fal fa-print', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: () => disable(true) },
                    { name: 'saveNotPrint', text: 'save', func: () => canSave(false, false), icon: 'fal fa-save', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: () => disable() },
                    { name: 'directDelivery', text: 'direct_delivery', func: () => canSave(true, true), icon: 'fal fa-truck', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: () => disable() }
                ])
                .setOptionFunc((data) => optionfunc(data.data));

            //vm.grid = {
            //    dataTask: {
            //        rememberId: 'w_orderspecial.grid',                  // Id used to retrieve user configs for the grid
            //        loadData: {                                         // Method id of the get data procedure
            //            method: 2518,
            //            parameters: { cust_no: vm.model.selectedCustNo }
            //        },
            //        addRow: {                                           // 
            //            method: null,
            //            parameters: null,
            //            pre: function (dataItem) {
            //                return openAddModal(dataItem);
            //            },
            //            post: function () {
            //                let dataItem = vm.grid.gridfunc.getDataItem();

            //                angular.forEach(newRowData, function (value, key) {
            //                    if (angular.isDefined(dataItem[key])) {
            //                        dataItem.dirtyFields[value] = true;
            //                        dataItem[key] = value;
            //                    }
            //                });

            //                dataItem.dirty = true;
            //            }
            //        },
            //        removeRow: {                                        // 
            //            method: null,
            //            parameters: null
            //        },
            //        saveData: {
            //            method: null,
            //            parameters: null
            //            ////method: 2217,
            //            //parameters: {
            //            //    datatask_keyno: 2217,
            //            //    order_internal_no: $stateParams.order_internal_no
            //            //},
            //            //autoSave: true,
            //            ////confirm: true
            //        }
            //    },
            //    translations: [],                                       // Comma separated list of words to be translated
            //    config: {                                               // TouchTime parameters configuring the grid
            //        toolbar: {                                          // alternative -> toolbar: false,
            //            hidden: false,                                  // If true, hides the toolbar, default is false
            //            pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
            //            excelExport: false,                             // Adds a predefined btn for exporting grid to excel
            //            filter: true,                                   // Adds a predefined btn for en-/disabling filtering
            //            columnVisibility: false,                        // Adds a predefined btn for deciding which columns to show/hide
            //            headers: false,                                 // Adds a predefined btn for switched between db column names and titles
            //            edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
            //            lock: false,                                    // NOT IMPLEMENTED
            //            add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
            //            addSimple: true,                                // Adds predefined btns for running an add function     // Needs addRow     // Alternative to 'add'
            //            delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
            //            save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
            //            wrapping: false,                                // Adds a predefined btn for en-/disabling wrapping of text in columns
            //            layouts: false,                                 // Adds a predefined btn for adding, setting or removing predefined column layouts
            //            buttons: [                                      // List of custom buttons
            //                { name: 'saveAndPrint', text: 'save_and_print', func: function () { canSave(true, false); }, icon: 'fal fa-print', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return disable(true); } },
            //                { name: 'saveNotPrint', text: 'save', func: function () { canSave(false, false); }, icon: 'fal fa-save', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return disable(); } },
            //                { name: 'directDelivery', text: 'direct_delivery', func: function () { canSave(true, true); }, icon: 'fal fa-truck', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return disable(); } }
            //            ]
            //        },
            //        fixedHeader: true,                                  // Enables header to stay visible when scrolling
            //        keepSortOnAdd: false,                               // Clears sorting when adding a row
            //        keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
            //        keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
            //        rowClick: false,                                    // Enables functionality for drilldown on clicked row
            //        specialFunc: {                                      // Adds additional functionality to the grid_functions column
            //            newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
            //            buttons: [
            //                //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } }
            //            ]
            //        },
            //        navigation: {
            //            altNav: false,                                  // accepts -> true or false, (or undefined)
            //            newLine: false                                  // If true, will add a new line if (Shift+)Enter/Tab pressed at (first/)last editable cell on (first/)last row
            //        },
            //        css: {
            //            altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
            //            textWrapping: false                             // Enables text-wrapping in columns, default is false
            //        },
            //        onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
            //            //console.log('onDataSourceChanges');
            //            //console.dir(e);
            //        }
            //    },
            //    kendo: {                                                // Kendo parameters configuring the grid
            //        height: null,                                       // Sets the height of the grid component
            //        aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
            //        pager: false,                                       // NOT IMPLEMENTED
            //        selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
            //        filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
            //    },
            //    optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
            //    gridfunc: null                                          // Used for referencing functions in the Grid Component
            //};

            // #endregion VARIABLES & DEFINITIONS

            // #region OTHER

            let optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                    //vm.grid.gridfunc.refresh();
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            let openAddModal = async function (dataItem) {
                let modalInstance = $uibModal.open({
                    component: 'orderSpecialGridAdd',
                    resolve: {
                        parameters: function () {
                            return {
                                dataItem: dataItem
                            };
                        }
                    },
                    //size: 'pst-ninetyfive',
                    backdrop: 'static'
                });

                const response = await modalInstance.result;
                newRowData = response;

                return response;
            };

            let openDeliveryModal = function (dataItem) {
                let deferred = $q.defer();

                let modalInstance = $uibModal.open({
                    component: 'orderSpecialGridDelivery',
                    resolve: {
                        parameters: function () {
                            return {
                                dataItem: dataItem
                            };
                        }
                    },
                    //size: 'pst-ninetyfive',
                    backdrop: 'static'
                });

                modalInstance.result.then(function (response) {
                    deferred.resolve(response);
                }, function () {
                    deferred.reject();
                })

                return deferred.promise;
            };

            let makeSaveObject = function (print, deliver) {
                let saveObject = {};
                let allRows = vm.grid.gridfunc.getAllRows();
                let rows = [];

                angular.forEach(allRows, function (row) {
                    if (angular.isDefined(row) && angular.isDefined(row.quantity) && row.quantity > 0) {
                        rows.push({
                            prod_id: row.prod_id,
                            quantity: parseInt(row.quantity),
                            note: row.note
                        });
                    }
                });

                if (rows.length === 0) return null;

                let head = {
                    cust_no: vm.model.selectedCustNo,
                    //stockplace_id: vm.model.selectedStockplaceId,
                    stockplace_id: vm.stockplace.data.stockplace_id,
                    delivery_datetime: vm.model.deliveryDatetime,
                    reference: vm.model.reference,
                    delivery_comment: vm.model.deliveryComment,
                    printer: angular.isDefined(vm.$storage.printer.item_id) ? vm.$storage.printer.item_id : ''
                };

                let driver = {
                    deliverymethod_no: '',
                    delivery_phone: '',
                    equipment_id: '',
                    labour_no: ''
                };

                saveObject = {
                    head: head,
                    rows: rows,
                    print: print,
                    deliver: deliver,
                    driver: driver,
                };

                return saveObject;
            };

            vm.cancel = function () {
                vm.model.selectedCustNo = null;
                vm.model.selectedCustName = null;
                //vm.model.selectedStockplaceId = null;
                //if (vm.gridModalOpen === true) {
                //    //vm.modalInstance.dismiss();
                vm.modalInstance.dismiss();
                //} else {
                //    //stateService.back();
                //}
            };

            vm.onPrinterChanged = function (printer, changed) {
                if (changed !== true)
                    return;

                vm.$storage.printer = printer;

                vm.grid.gridfunc.refresh();
            };

            //vm.print = function () {
            //    if (initialized !== true) {
            //        $timeout(vm.print, 100);
            //        return;
            //    }

            //    modalReportPreviewService.show({
            //        reportKeyno: vm.report.keyno,
            //        parameters: {
            //            poh_keyno: pohKeyno
            //        }
            //    });
            //};

            let findObjectInList = function (list, key, value) {
                let index = list.findIndex(obj => obj?.[key] === value);

                return { index: index, data: list[index] };
            };

            vm.onStockplaceChange = function (value) {
                vm.stockplace.data.stockplace_id = value;

                let selectedItem = findObjectInList(vm.stockplace.list, 'stockplace_id', value);

                if (selectedItem?.data?.stockplace_name) {
                    vm.stockplace.data.stockplace_name = selectedItem.data.stockplace_name;
                    //vm.model.selectedStockplaceId = selectedItem.data.stockplace_name;
                    orderSpecialService.remember(vm.stockplace.remId, vm.stockplace.data.stockplace_id);
                }
            };

            // #endregion OTHER

            // #region TOOLBAR BUTTONS

            let canSave = function (print, deliver) {
                let saveObject = {};

                saveObject = makeSaveObject(print, deliver);

                if (saveObject === null) return;

                isSaveRunning = true;
                vm.grid.gridfunc.refreshToolbarBtnDisability();

                if (deliver) {
                    openDeliveryModal().then(function (result) {
                        //console.log('result');
                        //console.dir(result);
                        saveObject.driver.deliverymethod_no = result.deliverymethod_no;
                        saveObject.driver.delivery_phone = result.phone;
                        saveObject.driver.equipment_id = result.equipment_id;
                        saveObject.driver.labour_no = result.labour_no;
                        save(saveObject);
                        //isSaveRunning = false;
                        //vm.grid.gridfunc.refreshToolbarBtnDisability();
                    }).catch((reason) => {
                        //console.log('reason');
                        //console.dir(reason);
                        isSaveRunning = false;
                        vm.grid.gridfunc.refreshToolbarBtnDisability();
                    }).finally(() => {
                        //isSaveRunning = false;
                        //vm.grid.gridfunc.refreshToolbarBtnDisability();
                    });
                } else {
                    save(saveObject);
                }
            };

            let save = function (saveObject) {
                orderSpecialService.save(saveObject).then(function (result) {
                    isSaveRunning = false;
                    vm.grid.gridfunc.refreshToolbarBtnDisability();

                    switch (result[0].errorcode) {
                        case '0':
                            vm.modalInstance.close();
                            break;
                        default:
                            modalService.show({
                                type: 'danger',
                                title: 'ERROR',
                                message: 'ERRORCODE: ' + result[0].errorcode + '\n' + result[0].errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-danger',
                                    action: function (modalErrorInstance) {
                                        modalErrorInstance.close();
                                    }
                                }]
                            });
                    }
                });
            };

            let disable = function (isPrint) {
                if (isSaveRunning) return true;

                isPrint = angular.isUndefined(isPrint) ? false : isPrint;
                let hasQty = false;
                let allRows = vm.grid.gridfunc.getAllRows();

                for (let r = 0; r < allRows.length; r++) {
                    if (angular.isDefined(allRows[r]) && angular.isDefined(allRows[r].quantity) && allRows[r].quantity > 0) {
                        hasQty = true;
                        break;
                    }
                }

                if (isPrint) {
                    //return vm.lock;
                    if (angular.isDefined(vm.$storage.printer.item_id) && hasQty) return false;
                    else return true;
                } else {
                    if (hasQty) return false;
                    return true;
                }
            };

            // #endregion TOOLBAR BUTTONS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            vm.rememberFunc = function (parm) {
                //vm.model.searchText = parm;
                //orderSpecialService.remember(vm.model.rememberId, vm.model.searchText);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region LAYOUT FUNCTIONS

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.btn.paddingTop = info.fontSizes.textSize;
            });

            // #endregion LAYOUT FUNCTIONS

            // #region ANGULAR FUNCTIONS

            function prepareData() {
                //let deferred = $q.defer();

                let promises = [];

                promises.push(orderSpecialService.getStockplaces());
                promises.push(orderSpecialService.getRemember(vm.stockplace.remId));

                $q.all(promises).then(function (response) {
                    angular.copy(response[0], vm.stockplace.list);

                    if (response[1]?.[0]?.variablevalue && response[1][0].variablevalue !== '') {
                        vm.stockplace.data.stockplace_id = angular.fromJson(response[1][0].variablevalue);
                    }

                    //deferred.resolve(response);
                }, function (error) {
                    console.log('error! error! error! error! error!');

                    if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                        console.log(error.data.message);

                        if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                            console.log(error.data.data.message);
                        }
                    } else {
                        if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                            console.log(error.data.data.message);
                        }
                    }

                    console.dir(error);

                    //deferred.reject(error);
                });

                //return deferred.promise;
            }

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.selectedCustNo) && angular.isDefined(vm.resolve.selectedCustName)) {
                    prepareData();

                    vm.model.selectedCustNo = vm.resolve.selectedCustNo;
                    vm.model.selectedCustName = vm.resolve.selectedCustName;
                    vm.model.deliveryDatetime = moment(new Date()).add(1, 'hour').format('YYYY-MM-DD 00:00');

                    vm.grid.dataTask.loadData.parameters = { cust_no: vm.model.selectedCustNo };
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS

        }]
    });
})();
