import { Injectable } from '@angular/core';
import { DataTaskService } from './data-task.service';
import { UserStore } from './user.store';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilityService } from './utility.service';
import * as uuid from 'uuid';

export interface IMediaQueries {
    tablet: MediaQueryList;
    laptop: MediaQueryList;
    desktop: MediaQueryList;
}

export interface IFieldInfo {
    keyno: number;
    p2_tt_layout_fieldinfo_keyno: number;
    group_id: string;
    field_id: string;
    sizes: string;
    enabled: boolean;
    usergroups: number[];
    remarks: string;
    reg_datetime: Date;
    reg_by_userid: string;
    mod_datetime: Date;
    mod_by_userid: string;
}

export interface IPreloadedFieldInfo {
    state: string;
    fields: IFieldInfo[];
    defers: IDeferredPromise<IFieldInfo[]>[];
}

export interface ILayoutChangedEventArgs {
    size: number;
    labelAlwaysOnTop: boolean;
    breadcrumbView: string;
    showBreadcrumbs: boolean;
    sidemenuOpened: boolean;
    showSettings: boolean;
    windowheadingcolor: string;
    fontSizes: IFontSizes;
    height: number;
    padding: IPadding;
    margin: IMargin;
    mediaQueries: IMediaQueries;

    /**
     * Whether ag-grid should be used over old grid-component.
     */
    useAgGrid: boolean;
    userCanEditLayout: boolean;
}

export interface IFontSizes {
    textSize3XL: string;
    textSizeXXL: string;
    textSizeXL: string;
    textSizeL: string;
    textSize: string;
    textSizeS: string;
    textSizeSs: string;
    textSizeXs: string;
    thumbnail: string;
}

export interface IPadding {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

export interface IMargin {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

interface ILayoutChangedScopeFunction {
    (): void;
    $id: string;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private _preloadedFieldInfo: Record<string, IPreloadedFieldInfo> = {};
    private _onLayoutChanged = new BehaviorSubject<ILayoutChangedEventArgs | null>(null);

    // This can be removed when all components using it in angularjs has been migrated.
    // This SHOLD NEVER by used from angular, only legacy angularjs.
    private _layoutChangedEventHandlers: Record<string, (param: any) => void> = {};

    //#region Constructors

    constructor(private userStore: UserStore, private dataTask: DataTaskService, private us: UtilityService) {
        this.userStore.fontSizeChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.fontSizeMobileChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.breadcrumbViewChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.showBreadcrumbsChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.labelAlwaysOnTopChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.showSettingsChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.windowHeadingColorChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.sideMenuOpenChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
        this.userStore.useAgGridChanged.subscribe({ next: this.layoutChangedHandler.bind(this), error: this.layoutChangedErrorHandler.bind(this) });
    }

    //#endregion

    //#region Public Properties

    // This can be removed when all components using it in angularjs has been migrated.
    // This SHOLD NEVER by used from angular, only legacy angularjs.
    public async onLayoutChanged(scope: any, callback: (param: any) => void): Promise<void> {
        const self = this;

        const fn: ILayoutChangedScopeFunction = () => {
            let id = fn.$id;

            if (!(id in self._layoutChangedEventHandlers)) return;

            delete self._layoutChangedEventHandlers[id];
        };

        fn.$id = uuid.v4();

        if (this.us.isArray(scope)) {
            scope.push(fn);
        } else {
            if (!this.us.isObject(scope)) return;
            if (!this.us.isFunction(scope.$on)) return;

            scope.$on('$destroy', fn);
        }

        this._layoutChangedEventHandlers[fn.$id] = callback;

        await this.userStore.ensureIsReady();

        if (fn.$id in this._layoutChangedEventHandlers) {
            const args = this.buildEventArgs();

            this._layoutChangedEventHandlers[fn.$id](args);
        }
    }

    // In angular always use this to subscribe to changes. DO NOT use the onLayoutChanged method.
    // The onLayoutChanged method is obsolete and will be removed in a future version.
    public get layoutChanged(): Observable<ILayoutChangedEventArgs | null> {
        return this._onLayoutChanged.asObservable();
    }

    //#endregion

    //#region Public Methods

    public onUsergroupSettingChanged() {
        this.layoutChangedHandler();
    }

    public async ensureIsReady(): Promise<void> {
        await this.userStore.ensureIsReady();
    }

    public getMediaQueries(): IMediaQueries {
        return {
            tablet: window.matchMedia('(min-width: 768px)'),
            laptop: window.matchMedia('(min-width: 992px)'),
            desktop: window.matchMedia('(min-width: 1440px)'),
        } as IMediaQueries;
    }

    public getFontSizes() {
        return [
            { item_id: 'var(--tt-font-size-3xs)', item_name: '3X-Small' },
            { item_id: 'var(--tt-font-size-xs)', item_name: 'X-Small' },
            { item_id: 'var(--tt-font-size-sm)', item_name: 'Small' },
            { item_id: 'var(--tt-font-size)', item_name: 'Normal' },
            { item_id: 'var(--tt-font-size-lg)', item_name: 'Large' },
            { item_id: 'var(--tt-font-size-xl)', item_name: 'X-Large' },
            { item_id: 'var(--tt-font-size-xxl)', item_name: 'XX-Large' },
            { item_id: 'var(--tt-font-size-3xl)', item_name: '3X-Large' },
        ];
    }

    public async toggleItemSizeFieldInfo(groupId: string, fieldId?: string, sizes?: string): Promise<IFieldInfo> {
        if (groupId.length < 1) return {} as IFieldInfo;

        const parts = groupId.split('.');

        if (parts.length > 1) {
            if (sizes === undefined) {
                sizes = fieldId;
            }

            groupId = parts[0];
            fieldId = parts[1];
        }

        if (fieldId === undefined || fieldId.length < 1) return {} as IFieldInfo;

        let response = (await this.dataTask.Post(1912, { group_id: groupId, field_id: fieldId, sizes: sizes })) as IFieldInfo;

        response.keyno = response.p2_tt_layout_fieldinfo_keyno;

        return response;
    }

    public getFieldInfo(groupId: string): Promise<IFieldInfo[]> {
        if (groupId in this._preloadedFieldInfo) {
            if (this._preloadedFieldInfo[groupId].state === 'ready') {
                var fields = this._preloadedFieldInfo[groupId].fields.splice(0);

                delete this._preloadedFieldInfo[groupId];

                return Promise.resolve(fields);
            } else {
                let deferred = {} as IDeferredPromise<IFieldInfo[]>;

                let promise = new Promise<IFieldInfo[]>((resolve, reject) => {
                    deferred.resolve = resolve;
                    deferred.reject = reject;
                });

                this._preloadedFieldInfo[groupId].defers.push(deferred);

                return promise;
            }
        } else {
            return this.dataTask.Post(1877, { group_id: groupId });
        }
    }

    public async preloadFieldInfo(groups: string[]): Promise<void> {
        let groupString = '';

        let self = this;

        groups.forEach(function (groupId) {
            if (groupId in self._preloadedFieldInfo) return;

            groupString += groupId + ';';

            self._preloadedFieldInfo[groupId] = {
                state: 'loading',
                fields: [],
                defers: [],
            };
        });

        let response = (await this.dataTask.Post(1889, { groups: groupString })) as Record<string, IFieldInfo[]>;

        let keys = Object.keys(response);

        keys.forEach(function (key: string) {
            if (response[key].length < 1) return;

            self._preloadedFieldInfo[key].fields = response[key];
            self._preloadedFieldInfo[key].state = 'ready';

            if (self._preloadedFieldInfo[key].defers.length === 0) return;

            self._preloadedFieldInfo[key].defers.forEach(function (def) {
                def.resolve(self._preloadedFieldInfo[key].fields);
            });

            delete self._preloadedFieldInfo[key];
        });
    }

    public async updateFieldInfo(fieldInfo: IFieldInfo): Promise<IFieldInfo> {
        //@ts-ignore
        return this.dataTask.Post(1878, fieldInfo, { returnRawResponse: true });
    }

    //#endregion

    //#region Private Methods

    private isOdd(x: number) {
        return x & 1;
    }

    private buildEventArgs(): ILayoutChangedEventArgs {
        const fontSize = this.userStore.fontSize;
        const fontSizeMobile = this.userStore.fontSizeMobile;
        const top = this.userStore.labelAlwaysOnTop;

        let size = parseInt(fontSize.substring(0, fontSize.length - 2));

        if (window.matchMedia('(min-width: 768px)').matches === false) {
            size += Number(fontSizeMobile.replace('px', ''));

            this.userStore.setThemeKeynoMobile(this.userStore.themeKeynoMobile);
        } else {
            this.userStore.setThemeKeyno(this.userStore.themeKeyno);
        }

        const padding1 = size - 10;
        const padding2 = padding1 + 6;

        return {
            size: size,
            labelAlwaysOnTop: top,
            breadcrumbView: this.userStore.breadcrumbView,
            showBreadcrumbs: this.userStore.showBreadcrumbs,
            sidemenuOpened: this.userStore.sideMenuOpen,
            showSettings: this.userStore.showSettings,
            windowheadingcolor: this.userStore.windowHeadingColor,
            fontSizes: {
                textSize3XL: (size + 18).toString() + 'px',
                textSizeXXL: (size + 12).toString() + 'px',
                textSizeXL: (size + 6).toString() + 'px',
                textSizeL: (size + 3).toString() + 'px',
                textSize: size.toString() + 'px',
                textSizeS: (size - 2).toString() + 'px',
                textSizeSs: (size - 3).toString() + 'px',
                textSizeXs: (size - 8).toString() + 'px',
                thumbnail: `${size + 24}`,
            },
            height: size + 16,
            padding: {
                top: padding1 > 5 ? 5 : padding1,
                bottom: padding1 > 5 ? 5 : padding1,
                left: padding2 > 16 ? 16 : padding2,
                right: padding2 > 16 ? 16 : padding2,
            },
            margin: {
                bottom: (this.isOdd(size) ? size - 1 : size) / 2,
            },
            mediaQueries: {
                tablet: window.matchMedia('(min-width: 768px)'),
                laptop: window.matchMedia('(min-width: 992px)'),
                desktop: window.matchMedia('(min-width: 1440px)'),
            },
            useAgGrid: this.userStore.useAgGrid,
            userCanEditLayout: this.us.toBoolean(this.userStore.userSettings['_240'].value, false),
        } as ILayoutChangedEventArgs;
    }

    private layoutChangedHandler() {
        const eventArgs = this.buildEventArgs();

        this._onLayoutChanged.next(eventArgs);

        Object.values(this._layoutChangedEventHandlers).forEach(function (handler) {
            try {
                handler(eventArgs);
            } catch (error) {
                console.dir(handler);
                console.dir(error);
            }
        });
    }

    private layoutChangedErrorHandler() {}

    //#endregion
}

interface IPreloadFieldInfoResponse {
    data: Record<string, IFieldInfo[]>;
}

interface IDeferredPromise<T> {
    resolve: (value: T) => void;
    reject: (reason?: any) => void;
}
