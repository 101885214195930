(function () {
    'use strict';

    angular.module("imApp").factory("webpageLinkService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1244,
                    parameters: parms || {}
                });
            },
            listMenuGroups: function (parms) {
                return $ihttp.post({
                    method: 3485,
                    parameters: parms || {}
                });
            },
            listWebpageLinks: function (parms) {
                return $ihttp.post({
                    method: 16,
                    parameters: parms || {}
                });
            },
            saveItems: function (item) {
                return $ihttp.post({
                    method: 3489,
                    parameters: item
                });
            }
        };

        return service;
    }]);
})();
