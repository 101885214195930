(function () {
    'use strict';

    angular.module("imApp").factory("productUnitsEditService", ['$ihttp', function ($ihttp) {
        let service = {
            getProductUnitsEditSettings: function (parms) {
                return $ihttp.post({
                    method: 585,
                    parameters: parms || {}
                });
            },
            listProductUnitDatas: function (parms) {
                return $ihttp.post({
                    method: 586,
                    parameters: parms || {}
                });
            },
            getExampleData: function (parms) {
                return $ihttp.post({
                    method: 588,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
