// BJS 20250306 - Migrated using chatGPT o1. In time we need to refactor away from this legacy code.

import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service'; // or wherever your "us" service resides

// A simple UUID generator (or import { v4 as uuid } from 'uuid';)
function uuid(): string {
    return Math.random().toString(36).substr(2, 9);
}

/**
 * This service replicates the old AngularJS `eventService`:
 *   - Storing event handlers in an in-memory dictionary
 *   - `on(eventId, handler)` returns a deregistration function
 *   - `trigger(eventId, params, doBroadcast)` calls all handlers, then optionally calls `broadcast`
 *   - `broadcast(eventId, params)` used to call $rootScope.$broadcast in AngularJS; we replicate as a stub
 *   - `destroy(onDestroyArray)` calls each function in the array, if it is a function
 */
@Injectable({
    providedIn: 'root'
})
export class EventService {
    private events: {
        [id: string]: {
            id: string;
            handlers: { [handlerId: string]: (params: any) => void };
        };
    } = {};

    constructor(private utilityService: UtilityService) { }

    /**
     * Subscribe to an event by ID.
     * Returns a function to deregister (like the old function returned by .on()).
     */
    public on(id: string, handler: (params: any) => void): () => void {
        if (typeof id !== 'string') {
            console.error('Error: invalid event name in eventService.on:', id);
            return () => { };
        }
        if (typeof handler !== 'function') {
            console.error('Error: handler is not a function in eventService.on:', handler);
            return () => { };
        }

        if (!this.events[id]) {
            this.events[id] = {
                id,
                handlers: {}
            };
        }

        const handlerId = uuid();
        this.events[id].handlers[handlerId] = handler;

        // Return a "deregister" function
        return () => {
            delete this.events[id].handlers[handlerId];
        };
    }

    /**
     * Trigger all handlers for an event ID with optional parameters.
     * If doBroadcast is true, also call broadcast().
     */
    public trigger(id: string, parms?: any, doBroadcast?: boolean): void {
        const evt = this.events[id];
        if (!evt) {
            return;
        }

        // Call all registered handlers
        Object.values(evt.handlers).forEach(fn => {
            if (typeof fn === 'function') {
                fn(parms);
            }
        });

        // If doBroadcast is truthy, call broadcast
        if (this.utilityService.toBoolean(doBroadcast, false)) {
            this.broadcast(id, parms);
        }
    }

    /**
     * In AngularJS, this did $rootScope.$broadcast(id, parms).
     * Here, we provide a stub or minimal approach (console, or a Subject).
     * For a fully working global broadcast, consider using an RxJS Subject.
     */
    public broadcast(id: string, parms?: any): void {
        // Minimal stub:
        console.log(`[eventService] broadcast(${id}):`, parms,
            '\n(No direct $rootScope.$broadcast equivalent in Angular 15.)');
    }

    /**
     * Just calls each function in the onDestroy array if it’s a function.
     * This was used in old AngularJS to destroy watchers, etc.
     */
    public destroy(onDestroy: Array<(() => void) | undefined>): void {
        onDestroy.forEach(fn => {
            if (typeof fn === 'function') {
                fn();
            }
        });
    }
}
