import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

export type BottomSheetAnchor = 'top' | 'half' | 'bottom';

@Component({
    selector: 'tt-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.css'],
})
export class BottomSheetComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Whether the top anchor of the bottom sheet should be active or not.
     *
     * @default true
     */
    @Input()
    public set ttTopAnchorActive(value: BooleanInput) {
        this._topAnchorActive = coerceBooleanProperty(value);
    }
    public get ttTopAnchorActive(): boolean {
        return this._topAnchorActive;
    }
    private _topAnchorActive = true;

    /**
     * Whether the middle anchor of the bottom sheet should be active or not. Without it the sheet can only fully expand or be at the bottom.
     *
     * @default true
     */
    @Input()
    public set ttHalfAnchorActive(value: BooleanInput) {
        this._halfAnchorActive = coerceBooleanProperty(value);
    }
    public get ttHalfAnchorActive(): boolean {
        return this._halfAnchorActive;
    }
    private _halfAnchorActive = true;

    /**
     * Whether the bottom anchor of the bottom sheet should be active or not.
     *
     * @default true
     */
    @Input()
    public set ttBottomAnchorActive(value: BooleanInput) {
        this._bottomAnchorActive = coerceBooleanProperty(value);
    }
    public get ttBottomAnchorActive(): boolean {
        return this._bottomAnchorActive;
    }
    private _bottomAnchorActive = true;

    /**
     * The minimum height of the bottom sheet, use size notation, not pure number.
     */
    @Input()
    public ttMinHeight: string = '30rem';

    /**
     * Reference to view element of the the container of the bottom sheet (parent to content).
     */
    @ViewChild('containerSheet')
    public containerSheet?: ElementRef<HTMLDivElement>;

    /**
     * Reference to the view element wrapped around the content-slot of the bottom sheet.
     */
    @ViewChild('contentDiv')
    public contentDiv?: ElementRef<HTMLDivElement>;

    /**
     * The active anchor state of the bottom sheet.
     */
    public anchorState: BottomSheetAnchor = 'bottom';

    /**
     * The pixels between the touch y coordinate and the start position of the sheet.
     */
    private dragOffsetYPixels = '';

    /**
     * The start y coordinate of the touch start event.
     */
    private touchStartY: number = 0;

    /**
     * The time when the touch start event happened.
     */
    private touchStartYTime: number = 0;

    /**
     * The scrolltop of the content element when touch event starts.
     */
    private startScrollTop: number = 0;

    /**
     * The style applied ot the outer sheet, for animating drag.
     */
    public outerSheetStyle: Partial<CSSStyleDeclaration> = {};

    constructor(private elementRef: ElementRef) {}

    public onTouchStart(event: TouchEvent) {
        if (!this.containerSheet || !this.contentDiv) return;

        this.touchStartY = event.targetTouches[0].clientY;
        this.touchStartYTime = Date.now();

        const sheetStartPositionY = this.containerSheet.nativeElement.getBoundingClientRect().top;
        this.startScrollTop = this.contentDiv.nativeElement.scrollTop;

        if (this.isValidNumber(this.touchStartY) && this.isValidNumber(sheetStartPositionY)) {
            this.dragOffsetYPixels = `${this.touchStartY - sheetStartPositionY}px`;
        }
    }

    public onTouchMove(event: TouchEvent) {
        if (this.anchorState !== 'top' || this.anchoredToTopAndSwipingDownWithoutScroll(event)) {
            event.preventDefault();
            this.outerSheetStyle.transform = `translateY(calc(${event.targetTouches[0].clientY}px - ${this.dragOffsetYPixels}))`;
        }
    }

    public onTouchEnd(event: TouchEvent) {
        const touchLength = Math.abs(this.touchStartY - event.changedTouches[0]?.clientY);

        if (this.startScrollTop !== 0 || touchLength < 15) return;

        if (this.shouldSnapToTopAnchor(event) && this.ttTopAnchorActive) {
            console.log('snap to top');
            this.snapToAnchor('top');
        } else if (this.shouldSnapToHalfAnchor(event)) {
            console.log('snap to half');
            this.snapToAnchor('half');
        } else {
            console.log('snap to bottom');
            this.snapToAnchor('bottom');
        }
    }

    /**
     * Closes the bottom sheet (anchoring to bottom).
     */
    public closeExpandedSheet() {
        this.snapToAnchor('bottom');
    }

    private shouldSnapToTopAnchor(event: TouchEvent) {
        const touchEndY = event.changedTouches[0]?.clientY;

        if (this.isValidNumber(touchEndY) && this.swipedUp(touchEndY) && (this.swipedFast(touchEndY) || this.isTouchOnTopHalfOfScreen(touchEndY) || this.isSheetOnTopHalfOfScreen())) {
            return true;
        }

        return false;
    }

    private shouldSnapToHalfAnchor(event: TouchEvent) {
        const touchEndY = event?.changedTouches[0]?.clientY;

        if (this.isValidNumber(touchEndY) && (this.slowSwipeUpOnBottomHalfOfScreen(touchEndY) || this.swipeDownOnActiveTopAnchor(touchEndY))) {
            return true;
        }

        return false;
    }

    /**
     * Snaps the bottom sheet to the given anchor point.
     *
     * @param anchor the anchor point to snap the bottom-sheet to.
     */
    private snapToAnchor(anchor: BottomSheetAnchor) {
        this.outerSheetStyle.transition = 'all 0.1s ease-in-out';

        setTimeout(() => {
            let transform = this.outerSheetStyle.transform;

            if (anchor === 'top' && this.ttTopAnchorActive) {
                transform = `translateY(5rem)`;
                this.anchorState = 'top';
            } else if (anchor === 'half' && this.ttHalfAnchorActive) {
                transform = `translateY(calc(50% - 5rem))`;
                this.anchorState = 'half';
            } else if (anchor === 'bottom' && this.ttBottomAnchorActive) {
                transform = `translateY(calc(100% - ${this.ttMinHeight}))`;
                this.anchorState = 'bottom';
            }

            this.outerSheetStyle.transform = transform;
            this.outerSheetStyle.transition = 'none';
        }, 5);
    }

    private isSheetOnTopHalfOfScreen() {
        return !!this.containerSheet && this.containerSheet.nativeElement.getBoundingClientRect().top < window.innerHeight / 2;
    }

    private swipeDownOnActiveTopAnchor(touchEndY: number) {
        return !this.swipedUp(touchEndY) && this.anchorState === 'top';
    }

    private slowSwipeUpOnBottomHalfOfScreen(touchEndY: number): boolean {
        return this.swipedUp(touchEndY) && !this.swipedFast(touchEndY) && !this.isTouchOnTopHalfOfScreen(touchEndY);
    }

    private anchoredToTopAndSwipingDownWithoutScroll(event: TouchEvent): boolean {
        return this.anchorState === 'top' && this.startScrollTop === 0 && !this.swipedUp(event.targetTouches[0].clientY);
    }

    private isValidNumber(value: unknown): value is number {
        return value === 0 || (!!value && !isNaN(+value));
    }

    /**
     * Checks whether the given y coordinate indicates that the user swiped or not.
     *
     * @param touchEndY the y coordinate to get the direction of the swipe from.
     * @returns `true` if the user swiped up, `false` if not.
     */
    private swipedUp(touchEndY: number) {
        if (touchEndY < this.touchStartY) {
            return true;
        }
        return false;
    }

    private swipedFast(touchEndY: number) {
        const touchLengthThreshold = 100;
        const touchLength = Math.abs(this.touchStartY - touchEndY);

        const touchTimeYThreshold = 150;
        const touchTimeY = Date.now() - this.touchStartYTime;

        if (touchTimeY < touchTimeYThreshold && touchLength > touchLengthThreshold) {
            return true;
        }

        return false;
    }

    private isTouchOnTopHalfOfScreen(touchY: number) {
        const navbarHeight = document.querySelector('.tt-navbar')?.clientHeight || 0;

        if (touchY - navbarHeight > window.innerHeight - navbarHeight) {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        document.body.appendChild(this.elementRef.nativeElement);
        const uiContainer = document.querySelector('.cf')?.querySelector('div');
        this.outerSheetStyle.transform = `translateY(calc(100% - ${this.ttMinHeight}))`;

        if (uiContainer) {
            uiContainer.style.paddingBottom = this.ttMinHeight;
        }

        this.anchorState = 'bottom';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttTopAnchorActive']) {
            this.ttTopAnchorActive = changes['ttTopAnchorActive'].currentValue;
        }

        if (changes['ttHalfAnchorActive']) {
            this.ttHalfAnchorActive = changes['ttHalfAnchorActive'].currentValue;
        }

        if (changes['ttBottomAnchorActive']) {
            this.ttBottomAnchorActive = changes['ttBottomAnchorActive'].currentValue;
        }
    }

    ngOnDestroy(): void {
        document.body.removeChild(this.elementRef.nativeElement);
        const uiContainer = document.querySelector('.cf')?.querySelector('div');

        if (uiContainer) {
            uiContainer.style.paddingBottom = '0';
        }
    }
}
