(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('portalUserSignature', {
        templateUrl: 'views/components/views/portalUserSignature/portalUserSignature.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'portalUserSignatureService', function ($stateParams, stateService, portalUserSignatureService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let portalUserKeyno = $stateParams.portal_user_keyno;
            
            vm.model = {
                edit: {},
                readyEmailSignature: true
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await portalUserSignatureService.getEdit({ portal_user_keyno: portalUserKeyno }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE FUNCTION

            vm.saveChanges = function () {
                portalUserSignatureService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #endregion BUTTON SAVE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
