(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('postCalcM', {
        templateUrl: 'views/components/views/postCalcM/postCalcM.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'ttGridFactory', function ($stateParams, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                gridReady: true
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_postcalcm_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                vm.grid.dataTask.loadData = {
                    method: 2519,
                    parameters: {
                        argtype: argType,
                        argvalue: argValue
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
