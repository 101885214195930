(function () {
    'use strict';

    angular.module("imApp").factory("p2UpdateScriptsService", ['$ihttp', function ($ihttp) {
        let service = {
            getUpdatScriptSetting: function (parms) {
                return $ihttp.post({
                    method: 974,
                    parameters: parms || {}
                });
            },
            listRunBatches: function (parms) {
                return $ihttp.post({
                    method: 210,
                    parameters: parms || {}
                });
            },
            listUpdateScripts: function (parms) {
                return $ihttp.post({
                    method: 175,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
