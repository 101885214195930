<ul class="tt-list" [ngClass]="{ 'tt-list--reverse': ttReverse }">
    <tt-list-item
        *ngFor="let item of ttData | listFilter : filter : filterType; trackBy: trackItems"
        ttForm="test"
        [ttId]="'' + item.item_id"
        [ttRadioSelection]="ttRadioSelection"
        [ttRadioChecked]="radioChecked"
        (ttRadioCheckedChanged)="onRadioCheckedChanged($event, item)"
        [ttCheckboxChecked]="item.item_is_selected"
        [ttCheckboxSelection]="ttCheckboxSelection"
        (ttCheckboxCheckedChanged)="onCheckboxSelectionChanged($event, item)"
        [ttTile]="item.item_name"
        [ttSubtitle]="ttHideSubtitle ? '' : item.item_name_sub1"
        [ttSubtext]="ttHideSubtext ? '' : item.item_name_sub2"
        [ttThumbnail]="item.item_thumb"
        [ttIcons]="getListItemIcon(item)"
        [ttIconColors]="item.item_glyphicon_color"
        [ttBadge]="!!ttBadge && !!item[ttBadge] ? '' + item[ttBadge] : ''"
        [ttClickable]="ttClick.observed && (!ttClickable || ttClickable(item))"
        (ttClick)="onClick($event, item)"
        [ttGoto]="!ttClick.observed && ttGoto ? item.item_state : undefined"
        [ttGotoParms]="item.item_parms"
        [ttButtonsPosition]="ttButtonsPosition"
    >
        <ng-template *ngIf="ttListItemTemplateRef" #ttListItemTemplate>
            <ng-container [ngTemplateOutlet]="ttListItemTemplateRef" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </ng-template>
        <tt-form-field-button
            buttons
            *ngFor="let button of buttons"
            [ttId]="button.id"
            [ttIcon]="button.icon"
            [ttTooltip]="button.tooltip"
            [ttTranslateTooltip]="button.translateTooltip"
            [ttType]="button.type"
            (ttClick)="onFormButtonClick($event, button, item)"
            [ttGoto]="button.goto"
            [ttGotoParms]="button.gotoParms"
            [ttButtonParms]="ttButtonParms"
        ></tt-form-field-button>
    </tt-list-item>
    <ng-content></ng-content>
</ul>
