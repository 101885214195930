import { Component, OnInit } from '@angular/core';
import { DateRangeInterval } from '@app/core/components/date-range/date-range.component';
import { Grid } from '@app/core/components/grid/grid.model';
import { GridOptions } from '@app/core/components/grid/grid.types';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { AccountingService, AccountingYearItem } from '@app/services/accounting.service';
import { BusinesscoItem, LogisticService } from '@app/services/logistic.service';

@Component({
    selector: 'tt-accounting-bs',
    templateUrl: './accounting-bs.component.html',
    styleUrls: ['./accounting-bs.component.css'],
})
export class AccountingBsComponent implements OnInit {
    public get?: AccountingBsGet;

    public companies: BusinesscoItem[] = [];

    public reportLayouts: Item[] = [];

    public accountingYears: AccountingYearItem[] = [];

    public grid?: GridOptions;

    public grid12Months?: GridOptions;

    constructor(private datatask: DataTaskService, private logistic: LogisticService, private accounting: AccountingService) {}

    public reloadGridData() {
        if (this.get) {
            if (this.get.show_12months === '1') {
                this.grid12Months?.gridfunc?.read?.();
            } else {
                this.grid?.gridfunc?.read?.();
            }
        }
    }

    private async loadCompanies() {
        this.companies = await this.logistic.getCompanies();
    }

    private async loadReportLayouts() {
        this.reportLayouts = await this.accounting.getReportLayouts('0');
    }

    public async loadAccountingYears() {
        if (!!this.get) {
            this.accountingYears = await this.accounting.getAccountingYears(this.get.businessco_no);
        }
    }

    private async loadPageGet() {
        this.get = (await this.datatask.Post(2817))[0];
    }

    private createGridOptions() {
        if (this.get) {
            this.grid = new Grid().setRememberId(this.get.grid_std_view_id).setLoadDataFunction({ method: +this.get.grid_std_p2_datatask_keyno, parameters: () => this.get });
        }
    }

    private createGrid12MonthsOptions() {
        if (this.get) {
            this.grid12Months = new Grid().setRememberId(this.get.grid_12m_view_id).setLoadDataFunction({ method: +this.get.grid_12m_p2_datatask_keyno, parameters: () => this.get });
        }
    }

    async ngOnInit(): Promise<void> {
        this.loadCompanies();
        this.loadReportLayouts();
        await this.loadPageGet();
        this.loadAccountingYears();
        this.createGridOptions();
        this.createGrid12MonthsOptions();
    }
}

interface AccountingBsGet {
    businessco_no: string;
    date_fom: string;
    date_tom: string;
    dateselector_index: DateRangeInterval;
    date_fomtom: string;
    acreportlayout_keyno: string;
    show_accounts: '1' | '0';
    show_12months: '1' | '0';
    grid_std_p2_datatask_keyno: string | number;
    grid_std_view_id: string;
    grid_12m_p2_datatask_keyno: string | number;
    grid_12m_view_id: string;
    accounting_year: string;
}
