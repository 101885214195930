import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
    transform(items: any[], disable: boolean): any[] {
        if (!items) return [];
        if (disable) return items;
        return items.slice().reverse();
    }
}
