(function () {
    'use strict';

    angular.module("imApp").factory("invoiceMethodLangsService", ['$ihttp', function ($ihttp) {
        let service = {
            listLanguages: function (parms) {
                return $ihttp.post({
                    method: 1765,
                    parameters: parms || {}
                });
            },
            saveLanguages: function (list) {
                return $ihttp.post({
                    method: 1767,
                    parameters: list
                });
            }
        };

        return service;
    }]);
})();
