(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('checkInStatus', {
        templateUrl: 'views/components/views/checkInStatus/checkInStatus.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'checkInStatusService', function ($stateParams, $q, utilityService, checkInStatusService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let viewType = $stateParams.viewtype;

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListCheckInStatuses: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                checkInStatusService.getSetting({
                    argtype: argType,
                    argvalue: argValue,
                    viewtype: viewType
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadCheckInStatuses();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCheckInStatuses = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.viewtype) !== true) return;

                return checkInStatusService.listCheckInStatuses({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue,
                    viewtype: vm.model.setting.viewtype
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCheckInStatuses);
                });
            };

            vm.$onInit = function () {
                loadSetting();
            };
		}]
	});
})();
