(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('priceListFactors', {
        templateUrl: 'views/components/views/priceListFactors/priceListFactors.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'priceListFactorsService', function ($stateParams, stateService, priceListFactorsService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                edit: {},
                inputListFactors: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await priceListFactorsService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }))[0];
            }

            async function loadFactors() {
                vm.model.inputListFactors = (await priceListFactorsService.listFactors({
                    argtype: argType,
                    argvalue: argValue
                }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE BUTTON FUNCTION
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                priceListFactorsService.saveObj({
                    argtype: argType,
                    argvalue: argValue,
                    items: vm.model.inputListFactors
                }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadFactors();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
