<tt-list
    [ttBadge]="ttBadge"
    [ttButtonParms]="ttButtonParms"
    [ttButtons]="ttButtons"
    [ttCheckboxSelection]="true"
    (ttCheckboxSelectionChanged)="ttCheckboxSelectionChanged.emit($event)"
    [ttClickable]="ttClickable"
    [(ttData)]="ttData"
    (ttDataChange)="ttDataChange.emit($event)"
    [ttFilter]="ttFilter"
    [ttGoto]="ttGoto"
    [ttHideSubtext]="ttHideSubtext"
    [ttHideSubtitle]="ttHideSubtitle"
    [ttNewTab]="ttNewTab"
    [ttReverse]="ttReverse"
    [ttShowFileExtensionIcon]="ttShowFileExtensionIcon"
    [ttButtonsPosition]="ttButtonsPosition"
></tt-list>
