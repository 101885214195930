import { EventEmitter, Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ChangeState, CreateColumnLayoutParams, DataTaskRequest, DBGridButton, GridColumn, GridColumnLayout, GridColumnsParams, GridDataTask, GridFilterType, GridOptions, GridRow, GridRowChanges, GridUserSettings, SaveUserSettingsParams, ServerSideRequestParams } from './grid.types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent, ConfirmModalData } from './modals/confirm-modal/confirm-modal.component';
import { firstValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AG_GRID_LOCALE_CN, AG_GRID_LOCALE_DE, AG_GRID_LOCALE_DK, AG_GRID_LOCALE_EN, AG_GRID_LOCALE_ES, AG_GRID_LOCALE_FI, AG_GRID_LOCALE_FR, AG_GRID_LOCALE_IT, AG_GRID_LOCALE_NO, AG_GRID_LOCALE_PL, AG_GRID_LOCALE_SE } from '@ag-grid-community/locale';
import { GridModalService } from './modals/grid-modal.service';
import { ProgressModalComponent } from './modals/progress-modal/progress-modal.component';
import { GridApi } from '@ag-grid-community/core';
import { DatataskEvent, NavigateEvent } from './cell-renderers/grid-functions-cell-renderer/grid-functions-cell-renderer.component';
import { UtilityService } from '@app/core/services/utility.service';
import { StateService } from '@app/core/services/state.service';
import { ModalService } from '../../services/modal.service';

@Injectable({
    providedIn: 'root',
})
export class GridService {
    private loadedDataTaskColumn: { [key: number]: GridColumn[] } = {};
    constructor(private datatask: DataTaskService, private dialog: MatDialog, private utility: UtilityService, private state: StateService, private modalService: ModalService) {}

    /**
     * The id of the last active grid.
     */
    public activeGridId: null | string = null;

    /**
     * Utility function for base64encode with unicode support
     *
     * @param data string to encode with base64 with unicode support.
     * @returns the encoded base64 string.
     */
    public utoa(data: string): string {
        return btoa(unescape(encodeURIComponent(data)));
    }

    /**
     * Utility function for base64decode with unicode support
     *
     * @param data base64 encoded string to be decodd.
     * @returns the string value of the decoded base64 string.
     */
    public atou(data: string): string {
        return decodeURIComponent(escape(atob(data)));
    }
    /**
     * Retrieves the usersettings with the given variablename. If no method is provided, the default usersettings method `973` is used.
     *
     * @param variablename the name for the variable to retrieve user settings with.
     * @param method the method to use for retrieving usersettings, default is `973`.
     * @returns
     */
    public async getRemember(variablename: string, method: number = 973): Promise<GridUserSettings | undefined> {
        const response = await this.datatask.Post(method, { variablename: variablename });

        if (response[0].variablevalue) {
            return JSON.parse(response[0].variablevalue);
        }

        return;
    }

    /**
     * Stores the given variablevalue to the db indexed by the given variablename.
     *
     * @param param0 parameters to configure the saving method with.
     * @returns a void promise.
     */
    public saveUserSettings({ method = 616, variablename, variablevalue, isBase64 = true }: SaveUserSettingsParams): Promise<void> {
        if (!method || !variablename) throw Error('Grid-Service: Missing parameters in saveUserSettings');

        return this.datatask.Post(method, {
            variablename: variablename,
            variablevalue: this.utoa(JSON.stringify(variablevalue)),
            is_base64: !!isBase64 ? 1 : 0,
        });
    }

    /**
     * Retrieves columns for the grid with the given params.
     *
     * @param param0 parameters for retrieving grid columns.
     * @returns a promise containing list of grid columns.
     */
    public async getGridColumns({ method = 1999, loadDataMethod, params, force }: GridColumnsParams): Promise<GridColumn[]> {
        if (!this.loadedDataTaskColumn[loadDataMethod] || force === true) {
            let parameters: { [key: string]: string | number | boolean } = {};
            if (params && typeof params === 'object') parameters = { ...params };

            parameters['p2_datatask_keyno'] = loadDataMethod;

            const response = await this.datatask.Post(method, parameters);
            this.loadedDataTaskColumn[loadDataMethod] = JSON.parse(JSON.stringify(response));

            return response;
        } else {
            return JSON.parse(JSON.stringify(this.loadedDataTaskColumn[loadDataMethod]));
        }
    }

    /**
     * Retrieves the row data with the given load data method and parameters.
     *
     * @param method the load data method for retrieving grid row data.
     * @param params the parameters required for retrieving the row data, if any.
     * @returns a promise containg list of grid rows.
     */
    public async getGridData(method: number, params: any): Promise<{ [key: string]: unknown }[]> {
        if (!!method && !isNaN(Number(method))) {
            try {
                return await this.datatask.Post(method, params || {});
            } catch (error) {
                return [];
            }
        }

        throw Error('Invalid load-data method');
    }

    /**
     * Retrieves row data sending in additional parameters for server-side paging, filtering and sorting. Returns object containing items retrieved and total items available.
     *
     * @param method the load data method for retrieving grid row data.
     * @param params the parameters required for retrieving the row data, if any.
     * @param serverSideParams parameters to use for server-side paging, filtering and sorting.
     * @returns promise containing an object with list of data and the total row count.
     */
    public async getGridDataServerSide(method: number, params: any, serverSideParams: ServerSideRequestParams): Promise<{ items: { [key: string]: unknown }[]; total: number }> {
        if (!!method && !isNaN(Number(method)) && !!serverSideParams) {
            // @ts-ignore datatask options does actually support partial parameters.
            const data = await this.datatask.Post(method, { ...(params || {}), ttGridOptions: serverSideParams }, { returnRawResponse: true });

            return data;
        }

        throw Error('Invalid parameters');
    }

    /**
     * Retrieves list of grid column layouts attached to the grid with the given load data method.
     *
     * @param loadDataMethod the load data method of the grid to get column layouts for.
     * @returns a promise containing a list of grid column layouts.
     */
    public getColumnLayouts(loadDataMethod: number): Promise<GridColumnLayout[]> {
        if (loadDataMethod) {
            return this.datatask.Post(2240, { p2_datatask_keyno: loadDataMethod });
        }

        throw Error('Missing load data method');
    }

    /**
     * Creates a column layout.
     *
     * @param params the column layout to create.
     * @returns a promise returning errorcode '0' if nothing went wrong.
     */
    public createColumnLayout(params: CreateColumnLayoutParams): Promise<{ errorcode: string; errormessage: string }> {
        if (params && !isNaN(Number(params.loadDataMethod)) && params.layoutname?.trim() && params.layout && typeof params.layout === 'object') {
            return this.datatask.Post(2241, {
                gridlayout_keyno: '',
                p2_datatask_keyno: params.loadDataMethod,
                gridlayout_name: params.layoutname,
                layout_schema: this.utoa(JSON.stringify(params.layout)),
                orderby: 0,
                function: 'NEW',
                is_base64: 1,
            });
        }

        throw Error('Invalid parameters');
    }

    /**
     * Updates the given layout with the changes it contains.
     *
     * @param layout the layout to update.
     * @returns a promise returning errorcode '0' if nothing went wrong.
     */
    public updateColumnLayout(layout: GridColumnLayout): Promise<{ errorcode: string; errormessage: string }> {
        if (layout.gridlayout_name?.trim() && layout.gridlayout_keyno?.trim() && layout.layout_schema?.trim()) {
            return this.datatask.Post(2241, {
                function: 'UPDATE',
                gridlayout_keyno: layout.gridlayout_keyno,
                gridlayout_name: layout.gridlayout_name,
                layout_schema: layout.layout_schema,
                orderby: layout.orderby,
                is_base64: 1,
            });
        }

        throw Error('Invalid parameters');
    }

    /**
     * Deletes the given column layout.
     *
     * @param layout the column layout to delete.
     * @returns a promise returning errorcode '0' if nothing went wrong.
     */
    public deleteColumnLayout(layout: GridColumnLayout): Promise<{ errorcode: string; errormessage: string }> {
        if (layout?.gridlayout_keyno) {
            return this.datatask.Post(2241, {
                function: 'DELETE',
                gridlayout_keyno: layout.gridlayout_keyno,
            });
        }

        throw Error('Invalid parameters');
    }

    /**
     * Performs a look up search for the given method, searchfield, searchtext and grid row.
     *
     * @param method the look up method to use.
     * @param searchfield the field searching from.
     * @param searchtext the search text user typed.
     * @param row the grid row the search was called from.
     * @returns a promise containg a list of objects as search results.
     */
    public lookupSearch(method: number, searchfield: string, searchtext: string, row: GridRow): Promise<{ [key: string]: string }[]> {
        return this.datatask.Post(method, { searchfield: searchfield, searchtext: searchtext, row: row });
    }

    /**
     * Retrieves goto parameters for teh givet set of parameters.
     *
     * @param params the parameters used to retireve goto parameters for.
     * @returns an object containing the goto parameters.
     */
    public async getGotoParms(params: { loadDataTask: DataTaskRequest; rememberId: string; field: string; row: GridRow }): Promise<{ item_state: string; item_parms: any; item_path: string }> {
        const parameters = {
            datatask: params.loadDataTask,
            field: params.field,
            row: params.row,
            gridRemId: params.rememberId,
        };

        return (await this.datatask.Post(2837, parameters))[0];
    }

    /**
     * Retrieves the procedure name for the given load data method.
     *
     * @param loadDataMethod the load data method to retrieve.
     * @returns the proc name retrieved.
     */
    public async getLoadMethodName(loadDataMethod: number): Promise<string> {
        if (!!loadDataMethod && !isNaN(Number(loadDataMethod))) {
            // @ts-ignore
            const data = await this.datatask.Post(2530, { p2_datatask_keyno: loadDataMethod }, { returnRawResponse: true });
            return (data?.proc_name as string) || '';
        }

        throw Error('Invalid loadmethod for receiving load method name.');
    }

    /**
     * Saves the changes of the given change state.
     *
     * @param state which state to save.
     * @returns empty promise which fullfills once the changes are persisted.
     */
    public async saveChanges(state: ChangeState, options: GridOptions, gridApi: GridApi | undefined): Promise<unknown> {
        try {
            return await this.customSave(options, state);
        } catch (error) {
            const persistedChangeKeys: string[] = [];
            const dataTaskId = this.getDataTaskId(state);

            if (dataTaskId === null || options.dataTask?.[dataTaskId]?.method instanceof Function) return;

            if (options.dataTask?.saveData?.confirm === true) {
                const result = await this.modalService.openConfirmDialog({ type: 'warning', title: 'ttgrid_modal_save_title', message: 'ttgrid_modal_save_message', ok: 'ttgrid_modal_save_ok', cancel: 'ttgrid_modal_save_cancel' });

                if (result !== true) return;
            }

            this.validateStateMethod(state, options);

            const rowsToSave = Object.entries(options.data!.changes!).filter(([_, value]) => value.state === state);
            const promises = rowsToSave.map(async ([key, value]) => {
                try {
                    persistedChangeKeys.push(key);
                    const response = await this.saveSingleRow({ state: state, row: value.data, options: options, refreshRow: false, gridApi: gridApi });
                    return response;
                } catch (saveError) {
                    console.log(saveError);
                } finally {
                    delete options.data?.changes?.[value.data._uuid!];
                }
            });

            persistedChangeKeys.forEach((key) => delete options.data!.changes![key]);
            const response = await Promise.all(promises);
            const errorResponse = response.find((result) => result?.errorcode < 0);

            if (errorResponse) {
                throw Error(errorResponse.errormessage);
            }

            return response;
        }
    }

    public async saveAllChanges(options: GridOptions, progressModal: ProgressModalComponent | null = null, gridApi: GridApi | undefined) {
        if (Object.keys(options.data!.changes!).length < 1) return;

        let count = 0;
        const rowsToSave = Object.entries(options.data!.changes!).filter(([_, value]) => {
            if (options.dataTask!.saveData!.onlySaveIsSelected === true) {
                return value.data?.['is_selected'] === true;
            } else {
                return true;
            }
        });

        if (progressModal) {
            progressModal.data.max = rowsToSave.length;
        }

        if (options.dataTask?.saveData?.single === true) {
            let error: any = null;

            // for .. of loop are a part of the function scope they reside in, so each iteration will be waited for before next is executed.
            for (let [_, value] of rowsToSave) {
                await this.saveSingleRowAndUpdateProgress({ options: options, value: value, progressModal: progressModal, gridApi: gridApi, count: count, error: this.getErrorInfo(error) });
                count++;
            }

            if (error?.error !== true && progressModal) {
                progressModal?.updateStatus('finish');
            }
        } else {
            const errors: string[] = [];
            // maps the changes to promises, no waiting here.
            const promises = this.mapRowChangesToSavePromises({ options: options, rowChanges: rowsToSave, progressModal: progressModal, count: count, errors: errors, gridApi: gridApi });

            await Promise.allSettled(promises);

            if (errors.length > 0) {
                if (progressModal) {
                    progressModal.updateStatus('invalid');
                    progressModal.updateInvalidText(errors.reduce((message, error) => message + '\n' + error));
                } else {
                    throw Error(errors[0]);
                }
            } else if (progressModal) {
                progressModal.updateStatus('finish');
            }
        }

        if (options.dataTask?.saveData?.readAfterSave !== true) {
            rowsToSave.forEach((row) => {
                delete options.data!.changes![row[0]];
            });
        } else {
            options.data!.changes = {};
        }
    }

    private async saveSingleRowAndUpdateProgress({
        options,
        value,
        count,
        progressModal,
        gridApi,
        error,
    }: {
        options: GridOptions;
        value: GridRowChanges;
        count: number;
        progressModal: ProgressModalComponent | null;
        gridApi: GridApi | undefined;
        error: {
            error: boolean;
            code?: any;
            message?: any;
        };
    }) {
        const response = await this.saveSingleRow({ state: value.state, row: value.data, options: options, gridApi: gridApi, refreshRow: true });
        error = this.getErrorInfo(response);

        if (error?.error === true) {
            if (progressModal) {
                progressModal?.updateInvalidText(this.getErrorInfo(response).message);
                progressModal?.updateStatus('invalid');
            }

            throw Error(error?.message);
        } else if (progressModal) {
            count++;
            progressModal?.updateValue(count);
        }
    }

    private mapRowChangesToSavePromises({ options, rowChanges, progressModal, count, errors, gridApi }: { options: GridOptions; rowChanges: [string, GridRowChanges][]; progressModal: ProgressModalComponent | null; gridApi: GridApi | undefined; count: number; errors: string[] }) {
        return rowChanges.map(async ([_, value]) => {
            const response = await this.saveSingleRow({ state: value.state, row: value.data, options: options, gridApi: gridApi, refreshRow: true });
            const error = this.getErrorInfo(response);

            if (error?.error === true) {
                errors.push(error?.message);
            } else {
                count++;

                if (progressModal) {
                    progressModal.updateValue(count);
                }
            }
        });
    }

    private validateStateMethod(state: ChangeState, options: GridOptions) {
        const dataTaskId = this.getDataTaskId(state);

        if (dataTaskId === null) return;

        if (!options.dataTask || typeof options.dataTask !== 'object') {
            options.dataTask = {};
        }

        options.dataTask[dataTaskId] ??= { method: 2219, parameters: {} };
        options.dataTask[dataTaskId]!.parameters ??= {};

        if (!(options.dataTask[dataTaskId]?.method instanceof Function)) {
            if (!!options.dataTask[dataTaskId]?.method && !isNaN(Number(options.dataTask[dataTaskId]?.method))) {
                options.dataTask[dataTaskId]!.method = parseInt(`${options.dataTask[dataTaskId]?.method}`, 10);
            } else {
                options.dataTask[dataTaskId]!.method = 2219;
            }
        }
    }

    private async getParametersFromDataTaskId(options: GridOptions, datataskId: keyof GridDataTask) {
        const datataskConfig = options.dataTask?.[datataskId];
        let parameters = {};

        if (!datataskConfig || typeof datataskConfig === 'string' || !datataskConfig.parameters) return parameters;

        if (datataskConfig.parameters instanceof Function) {
            const result = datataskConfig.parameters();

            if (result instanceof Promise) {
                parameters = { ...(await result) };
            } else {
                parameters = { ...result };
            }
        } else {
            parameters = { ...datataskConfig.parameters, ...parameters };
        }

        return parameters;
    }

    public async customSave(gridOptions: GridOptions, state: ChangeState) {
        const datataskId = this.getDataTaskId(state);
        const customSaveFunction = datataskId === null ? null : gridOptions?.dataTask?.[datataskId]?.method;

        if (!!customSaveFunction && customSaveFunction instanceof Function) {
            let result = customSaveFunction(state, gridOptions.data?.changes || {});

            if (result instanceof Function) {
                result = result(state, gridOptions.data?.changes || {});
            }

            if (result instanceof Promise) {
                result = await result;
            }

            return result;
        } else {
            throw Error(state + ' method is not a function, could not perform custom-save');
        }
    }

    //     tryCustomSave(state).then(function (hasCustomSave) {
    //         if (hasCustomSave === true) {
    //             angular.forEach(changes, function (value, key) {
    //                 if (value.state === state) {
    //                     delChanges.push(key);
    //                 }
    //             });

    //             autoSaveCompleted();
    //         } else {
    //             validateMethod(state);

    //             var promises = [];

    //             var dataTaskId = getDataTaskId(state);

    //             // doing forEach here but since this method is only meant for autoSave there should never
    //             // be more than one record of the selected state.
    //             angular.forEach(changes, function (value, key) {
    //                 if (value.state === state) {
    //                     var parameters = vm.ttOptions.dataTask[dataTaskId].parameters;

    //                     if (angular.isUndefined(parameters) || parameters === null || parameters === '') {
    //                         parameters = {};
    //                     }

    //                     // BJS 20221125 - Fix for datetime offset.
    //                     let tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

    //                     angular.forEach(value.data, function (d, key) {
    //                         if (angular.isDefined(columnInfo[key]) && columnInfo[key].type === 'date') {
    //                             value.data[key] = moment((new Date(new Date(d) - tzo))).format('YYYY-MM-DD HH:mm');
    //                         }
    //                     });

    //                     parameters.state = state;
    //                     parameters.changes = value.data;
    //                     parameters.load_datatask_keyno = getLoadDataMethod();

    //                     promises.push($ihttp.post({ method: vm.ttOptions.dataTask[dataTaskId].method, parameters: parameters }));

    //                     delChanges.push(key);
    //                 }
    //             });

    //             $q.all(promises).then(function (responses) {
    //                 var msg = '';
    //                 var hasError = false;

    //                 angular.forEach(responses, function (response) {
    //                     if (angular.isDefined(response)) {
    //                         var info = getErrorInfo(response);

    //                         if (info.error === true) {
    //                             msg += info.msg + '\n\r';

    //                             hasError = true;
    //                         }
    //                     }
    //                 });

    //                 if (hasError === true) {
    //                     showErrorMessage(0, msg).then(function () {
    //                         autoSaveCompleted(responses)
    //                     });
    //                 } else {
    //                     autoSaveCompleted(responses);
    //                 }
    //             });
    //         }
    //     });
    // }

    /**
     * Returns the key name for the datatask configuration required by the given state.
     *
     * @param state the state to get the keyname of datatask configuration for.
     * @returns the key name for the datatask configuration or `null` if state not supported.
     */
    private getDataTaskId(state: 'add' | 'all' | 'update' | 'remove'): 'addRow' | 'saveData' | 'removeRow' | null {
        let dataTaskId: keyof GridDataTask | null = null;

        switch (state) {
            case 'add':
                dataTaskId = 'addRow';
                break;
            case 'update':
            case 'all':
                dataTaskId = 'saveData';
                break;
            case 'remove':
                dataTaskId = 'removeRow';
                break;
            default:
                break;
        }

        return dataTaskId;
    }

    /**
     * Saves the changes of the given row using the given state.
     *
     * @param state the change state to save the changes with.
     * @param row the row to save changes on.
     * @returns empty promise which fullfills when the change is persisted.
     */
    private async saveSingleRow({ state, row, options, refreshRow = true, gridApi }: { state: ChangeState; row: GridRow; options: GridOptions; refreshRow: boolean; gridApi: GridApi | undefined }) {
        try {
            const dataTaskId = this.getDataTaskId(state);

            if (dataTaskId === null || !options.dataTask?.[dataTaskId]?.method || options.dataTask?.[dataTaskId]?.method instanceof Function) return;

            this.serializeRow(row, options);

            let parameters = await this.getParametersFromDataTaskId(options, dataTaskId);

            if (options.dataTask.saveData?.saveInclLoadparms === true) {
                parameters = { ...options.dataTask.loadData?.parameters, ...parameters };
            }

            // @ts-ignore
            const response = await this.datatask.Post(options.dataTask![dataTaskId]!.method as number, { ...parameters, state: state, changes: this.getRowData(options, row), load_datatask_keyno: options.dataTask?.loadData?.method }, { returnRawResponse: true });
            const rowToUpdate = options.data?.rowData.find((rowItem) => row._uuid === rowItem._uuid);

            if (options.dataTask.saveData?.refreshRowOnSave && rowToUpdate && !!response?.savedata && refreshRow === true) {
                options.data!.rowData[options.data!.rowData.indexOf(rowToUpdate)] = { ...rowToUpdate, ...response.savedata, _dirty: false };
                gridApi?.getRowNode(row._uuid!)?.updateData({ ...rowToUpdate, ...response.savedata, _dirty: false })!;
            } else if (!!rowToUpdate) {
                const node = gridApi?.getRowNode(row._uuid!);

                if (node) {
                    options.data!.rowData[options.data!.rowData.indexOf(rowToUpdate)] = { ...rowToUpdate, _dirty: false };
                    node.updateData({ ...node.data, _dirty: false })!;
                }
            }

            return { ...response, row: rowToUpdate };
        } catch (error) {
            const message = `${error}`;

            if (!message.includes('"errorcode":0')) throw error;

            const rowToUpdate = options.data?.rowData.find((rowItem) => row._uuid === rowItem._uuid);

            if (!!rowToUpdate) {
                const node = gridApi?.getRowNode(row._uuid!);

                if (node) {
                    options.data!.rowData[options.data!.rowData.indexOf(rowToUpdate)] = { ...rowToUpdate, _dirty: false };
                    node.updateData({ ...node.data, _dirty: false })!;
                }
            }
        }
    }

    /**
     * Maps the data of the given row to db friendly formats.
     *
     * @param row the row to format its data to db friendly formats for.
     */
    private serializeRow(row: GridRow, options: GridOptions) {
        for (let [colname, { type }] of Object.entries(options.data?.columnInfo ?? {})) {
            if (type === 'date') {
                row[colname] = new DatePipe('no').transform(!!row[colname] ? new Date(row[colname]) : new Date(), 'yyyy-MM-dd HH:mm', '+0200');
            } else if (type === 'text') {
                row[colname] = row[colname] !== null ? `${row[colname]}`.replace(/\s/g, ' ') : '';
            }
        }
    }

    /**
     * Removes component specific data from the given row and return a new object without the component specific data.
     *
     * @param row the row to clean of component specific data.
     * @returns a new row without component specific data.
     */
    public getRowData(options: GridOptions, row: GridRow) {
        const rowData = { ...row };

        delete rowData._uuid;
        delete rowData._dirty;
        // delete rowData['is_selected'];
        delete rowData['xxxoptionsxxx'];

        if (options.data?.hasGotoParms) {
            //rowData['item_state'] = '';
            //rowData['item_parms'] = '';
            //rowData['item_path'] = '';
        }

        if (options.data?.hasThumbnails) {
            rowData['item_thumb'] = '';
        }

        return rowData;
    }

    /**
     * Based on the given operator, returns the equivelant filter type for ag-grid.
     *
     * @param operator the filter operator to find equivelant for.
     * @returns the equivelant filter operator compatiable with ag-grid.
     */
    public getFilterType(operator: 'contains' | 'doesnotcontain' | 'eq' | 'neq' | 'startswith' | 'endswith' | 'gt' | 'gte' | 'lt' | 'lte' | 'isempty' | 'isnull' | 'isnullorempty' | 'isnotempty' | 'isnotnull' | 'isnotnullorempty') {
        if (operator === 'contains') {
            return 'contains';
        } else if (operator === 'doesnotcontain') {
            return 'notContains';
        } else if (operator === 'eq') {
            return 'equals';
        } else if (operator === 'neq') {
            return 'notEqual';
        } else if (operator === 'startswith') {
            return 'startsWith';
        } else if (operator === 'endswith') {
            return 'endsWith';
        } else if (operator === 'gt') {
            return 'greaterThan';
        } else if (operator === 'gte') {
            return 'greaterThanOrEqual';
        } else if (operator === 'lt') {
            return 'lessThan';
        } else if (operator === 'lte') {
            return 'lessThanOrEqual';
        } else if (operator === 'isempty' || operator === 'isnull' || operator === 'isnullorempty') {
            return 'blank';
        } else if (operator === 'isnotempty' || operator === 'isnotnull' || operator === 'isnotnullorempty') {
            return 'notBlank';
        }

        return operator;
    }

    /**
     * Based on the given filter type, returns the equivelant filter operator type used for storing in databse.
     */
    public getFilterOperatorType(filterType: string): GridFilterType {
        if (filterType === 'contains') {
            return 'contains';
        } else if (filterType === 'notContains') {
            return 'doesnotcontain';
        } else if (filterType === 'equals') {
            return 'eq';
        } else if (filterType === 'notEqual') {
            return 'neq';
        } else if (filterType === 'startsWith') {
            return 'startswith';
        } else if (filterType === 'endsWith') {
            return 'endswith';
        } else if (filterType === 'greaterThan') {
            return 'gt';
        } else if (filterType === 'greaterThanOrEqual') {
            return 'gte';
        } else if (filterType === 'lessThan') {
            return 'lt';
        } else if (filterType === 'lessThanOrEqual') {
            return 'lte';
        } else if (filterType === 'blank') {
            return 'isnullorempty';
        } else if (filterType === 'notBlank') {
            return 'isnotnullorempty';
        }

        throw Error('filter_not_supported');
    }

    /**
     * Returns the appropriate icon classes of the given icon string. Examples of string values are `'fal-envelope'`, `'glyphicon-lock'`.
     *
     * @param icon the icon string to retrieve icon classes for.
     * @returns classes for the icon provided.
     */
    public getIconClasses(icon?: string) {
        var iconClasses = '';

        if (icon) {
            iconClasses = 'glyphicon ' + icon;

            if (icon.startsWith('fa')) {
                iconClasses = 'font-awesone-5 ' + icon;
            } else if (icon.startsWith('glyphicon-')) {
                iconClasses = 'glyphicon ' + icon;
            } else {
                return 'k-icon k-i-' + icon;
            }
        }

        return iconClasses;
    }

    /**
     * Sets this grid as the currently active grid.
     *
     * @param id the id of the grid to set as active grid.
     */
    public setActiveGrid(id: string) {
        this.activeGridId = id;
    }

    /**
     * Checks whether this grid is the currently active grid (the grid last interacted with).
     *
     * @param id the id of the grid to check if is the active grid.
     * @returns `true` if this grid is the active grid, `false` if not.
     */
    public isActiveGrid(id: string): boolean {
        if (this.activeGridId !== null) {
            return id === this.activeGridId;
        }

        // console.log(document.querySelectorAll('tt-grid')[0].querySelector('div')?.getAttribute('id'));
        return false;
    }

    /**
     * Returns translated text-maps to use for language in a grid.
     *
     * @param languageId the language id to use for retrieving translated text-map for grid.
     */
    public getLocaleText(languageId: string) {
        let localeText = AG_GRID_LOCALE_EN;

        switch ((languageId ?? 'en').toLowerCase()) {
            case 'cn':
                localeText = AG_GRID_LOCALE_CN;
                break;
            case 'de':
                localeText = AG_GRID_LOCALE_DE;
                break;
            case 'dk':
                localeText = AG_GRID_LOCALE_DK;
                break;
            case 'es':
                localeText = AG_GRID_LOCALE_ES;
                break;
            case 'fi':
                localeText = AG_GRID_LOCALE_FI;
                break;
            case 'fr':
                localeText = AG_GRID_LOCALE_FR;
                break;
            case 'it':
                localeText = AG_GRID_LOCALE_IT;
                break;
            case 'nn':
            case 'no':
                localeText = AG_GRID_LOCALE_NO;
                break;
            case 'pl':
                localeText = AG_GRID_LOCALE_PL;
                break;
            case 'se':
                localeText = AG_GRID_LOCALE_SE;
                break;
            case 'li':
            case 'sy':
            case 'gb':
            default:
                localeText = AG_GRID_LOCALE_EN;
                break;
        }

        return localeText;
    }

    private getErrorInfo(response: any) {
        try {
            let message: { error: boolean; code?: number; message?: string } = { error: false };

            if (!!response && response instanceof Array) {
                if (response.length !== 1 || !response[0]?.errorcode || response[0].errorcode === '0') return message;

                return { error: true, code: response[0].errorcode, message: response[0]?.errormessage || '' };
            }

            let errorInfo = message;

            if (!!response?.errorcode && response?.errorcode !== '0' && response?.errormessage !== undefined && response?.errormessage !== null) {
                errorInfo = { error: true, code: response.errorcode, message: response.errormessage };
            } else if (!!response?.data?.message) {
                errorInfo = { error: true, code: 0, message: response.data.message };
            }

            // BJS 20220619 - Handles error messages from grid update.
            if (errorInfo?.error !== true && !!response?.savedata && !!response?.savedata?.errorcode && response?.savedata?.errorcode !== 0) {
                errorInfo = { error: true, code: response.savedata.errorcode, message: response?.savedata?.errormessage };
            }

            return errorInfo;
        } catch (error) {
            console.error(error);
            return { error: false };
        }
    }

    // Used to handle buttons setup in colschema of type `datatask`.
    public async dbButtonDatatask({ button, row = null, parameters, event, navigateEventEmitter, datataskEventEmitter, options }: DbButtonDatataskParams) {
        if (!button.p2_datatask_keyno || button.p2_datatask_keyno < 1) return;

        const datataskEvent: DatataskEvent = {
            preventDefault: () => (datataskEvent.defaultPrevented = true),
            defaultPrevented: false,
            p2_datatask_keyno: button.p2_datatask_keyno,
            newTab: this.utility.isModifierKeyPressed(event),
            rowData: row,
        };

        datataskEventEmitter.emit(datataskEvent);

        if (datataskEvent.defaultPrevented) return;

        try {
            options.gridfunc?.gridProgress(true);
            const response = await this.datatask.Post(button.p2_datatask_keyno, parameters);

            if (response?.[0] && button.p2_datatask_keyno === 2837) {
                const navigateEvent: NavigateEvent = {
                    preventDefault: () => (navigateEvent.defaultPrevented = true),
                    defaultPrevented: false,
                    newTab: this.utility.isModifierKeyPressed(event),
                    rowData: row,
                    itemState: button.state,
                    itemParms: button.state_params,
                };

                navigateEventEmitter.emit(navigateEvent);

                if (!navigateEvent.defaultPrevented) {
                    if (navigateEvent.newTab) {
                        this.state.newTab(navigateEvent.itemState || '', navigateEvent.itemParms);
                    } else {
                        this.state.go(navigateEvent.itemState || '', navigateEvent.itemParms);
                    }
                }
            }
        } catch (error) {
            throw Error(`${error}`);
        } finally {
            await options.gridfunc?.read();
            options.gridfunc?.gridProgress(false);
        }
    }

    public dbButtonGoto({ button, event, navigateEventEmitter }: DbButtonGotoParams) {
        const navigateEvent: NavigateEvent = {
            defaultPrevented: false,
            preventDefault: () => (navigateEvent.defaultPrevented = true),
            newTab: this.utility.isModifierKeyPressed(event),
            rowData: null,
            itemParms: !!button.state_params ? JSON.parse(this.atou(button.state_params || '')) : null,
            itemState: button.state,
        };

        navigateEventEmitter.emit(navigateEvent);

        if (!navigateEvent.defaultPrevented) {
            if (navigateEvent.newTab) {
                this.state.newTab(navigateEvent.itemState || '', navigateEvent.itemParms);
            } else {
                this.state.go(navigateEvent.itemState || '', navigateEvent.itemParms);
            }
        }
    }
}

/**
 * Represents parameters to pass to `gridService.dbButtonDatatask`.
 */
export interface DbButtonDatataskParams {
    /**
     * The buttons that was pressed.
     */
    button: DBGridButton;

    /**
     * The row where this button was pressed, if applicable.
     */
    row?: GridRow | null;

    /**
     * The parameters to pass to the datatask call.
     */
    parameters: Record<string | symbol, any>;

    /**
     * The mouse event of the click event on the button.
     */
    event: MouseEvent;

    /**
     * Event emitter for navigation event in the grid.
     */
    navigateEventEmitter: EventEmitter<NavigateEvent>;

    /**
     * Event emitter for datatask event in the grid.
     */
    datataskEventEmitter: EventEmitter<DatataskEvent>;

    /**
     * The grid options of the grid.
     */
    options: GridOptions;
}

export interface DbButtonGotoParams {
    /**
     * The buttons that was pressed.
     */
    button: DBGridButton;

    /**
     * The mouse event of the click event on the button.
     */
    event: MouseEvent;

    /**
     * Event emitter for navigation event in the grid.
     */
    navigateEventEmitter: EventEmitter<NavigateEvent>;
}
