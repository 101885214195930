(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('operationFactories', {
        templateUrl: 'views/components/views/operationFactories/operationFactories.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'operationFactoriesService', function ($stateParams, $q, utilityService, operationFactoriesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListOperationFactories: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                operationFactoriesService.getOperationFactories({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadOperationFactories()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadOperationFactories = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;

                vm.model.itemsListOperationFactories = [];

                return operationFactoriesService.listOperationFactories({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOperationFactories);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION

        }]
    });
})();
