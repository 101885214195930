import { Component, OnInit } from '@angular/core';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-deviation-source2-view',
    templateUrl: './deviation-source2-view.component.html',
    styleUrls: ['./deviation-source2-view.component.css'],
})
export class DeviationSource2ViewComponent implements OnInit {
    public deviationSource2Keyno: string = '';

    constructor(private state: StateService) {}

    public onDeviationSource2Save() {
        this.state.back();
    }

    public onDeviationSource2Delete() {
        this.state.back();
    }

    ngOnInit(): void {
        this.deviationSource2Keyno = this.state.getStateParams()['deviationsource2_keyno'];
    }
}
