(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockBatchList', {
        templateUrl: 'views/components/views/stockBatchList/stockBatchList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockBatchListService', function ($stateParams, stockBatchListService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                itemsListStockBatches: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadStockBatches = function () {
                stockBatchListService.listStockBatches({ stock_keyno: stockKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockBatches);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockBatches();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
