(function () {
	'use strict';

    angular.module("imApp").factory("payCheckService", ['$ihttp', function ($ihttp) {
		let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 1372,
                    parameters: parms || {}
                });
            },
            listPayCheckLs: function (parms) {
                return $ihttp.post({
                    method: 1373,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
