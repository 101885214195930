(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('labourSalaryTypes', {
        templateUrl: 'views/components/views/labourSalaryTypes/labourSalaryTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'labourSalaryTypesService', function ($stateParams, $q, utilityService, labourSalaryTypesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListSalaryTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                labourSalaryTypesService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadSalaryTypes();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadSalaryTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                return labourSalaryTypesService.listSalaryTypes(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListSalaryTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
