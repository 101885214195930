import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { TabComponent } from './tab/tab.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { debounceTime, Subject } from 'rxjs';
import { RememberService } from '@app/core/services/remember.service';

@Component({
    selector: 'tt-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TabsComponent extends ComponentBaseComponent implements AfterContentInit, OnInit, OnChanges {
    /**
     * Id used for persisting the last selected id of the tabs using `616`.
     */
    @Input()
    public ttRememberId: string = '';

    /**
     * Whether the tabs should not retrieve the last stored selected id upon initialization and only remember the new selected id.
     *
     * @default false
     */
    @Input()
    public set ttOnlyRemember(value: BooleanInput) {
        this._onlyRemember = coerceBooleanProperty(value);
    }
    public get ttOnlyRemember(): boolean {
        return this._onlyRemember;
    }
    private _onlyRemember = false;

    /**
     * The id of the selected tab.
     */
    @Input()
    public ttSelectedId: string = '';

    /**
     * Event emitted when a new tab is selected. Will also emit an event no initial value is given or when retrieving last stored value from 973.
     */
    @Output()
    public ttSelectedIdChange = new EventEmitter<string>();

    /**
     * List of the tabs to display.
     */
    @Input()
    public ttTabs: Tab[] = [];

    /**
     * The tabs passed through the content-slot of this tabs component.
     */
    @ContentChildren(TabComponent)
    public contentTabs?: QueryList<TabComponent>;

    /**
     * Remember subject for remembering the last selected id.
     */
    private rememberSubject = new Subject();

    /**
     * Whether the component is completed loading data.
     */
    public ready = false;

    constructor(private remember: RememberService, private cdr: ChangeDetectorRef) {
        super();
    }

    /**
     * Handles on click event for a tab. `ttModelChange` will be emitted before onClick handler on tab runs.
     *
     * @param tab the tab to perform click event on.
     */
    public onClick(tab: Tab): void {
        this.selectId(tab.id);
        this.rememberSubject.next(this.ttSelectedId);

        if (tab && tab?.onClick && tab.onClick instanceof Function) {
            tab?.onClick();
        }
    }

    /**
     * Sets the given id as the selected id and emits a selected id change event.
     *
     * @emits `ttSelectedIdChange` containing the new id in the event.
     */
    private selectId(id: string) {
        if (id !== this.ttSelectedId) {
            this.ttSelectedId = id;
            this.ttSelectedIdChange.emit(this.ttSelectedId);
        }

        this.updateActivePropForContentTabs();
    }

    /**
     * Checks if the any of the tabs passed through `ttTabs` property or through slot has the id which matched the current selected id.
     *
     * @returns `true` if any of the `ttTabs` or content-slot tabs has an id matching the currently selected id, `false` if not.
     */
    private hasSelectedTab() {
        const selectedContentTab = this.contentTabs?.find((tab) => !tab.ttDisabled && tab.ttId === this.ttSelectedId);
        const selectedModelTab = this.ttTabs.find((tab) => tab.disabled !== true && tab.disabled !== 'hidden' && tab.id === this.ttSelectedId);

        return !!selectedContentTab || !!selectedModelTab;
    }

    /**
     * Updates the active property for the tabs passed throught in the slot of this tabs component.
     */
    private updateActivePropForContentTabs() {
        this.contentTabs?.forEach((tab) => (tab.ttActive = tab.ttId === this.ttSelectedId));
    }

    /**
     * Selects the first available id of the tabs available. Checks tabs passed through `ttTabs` property before checking tabs passed through content.
     */
    private selectFirstAvailableId() {
        let firstAvailableId: string = '';

        if (this.ttTabs.length > 0) {
            firstAvailableId = this.ttTabs.find((tab) => tab.disabled !== true && tab.disabled !== 'hidden')?.id || '';
        }

        if (!firstAvailableId && !!this.contentTabs && this.contentTabs.length > 0) {
            firstAvailableId = this.contentTabs.find((tab) => tab.ttDisabled !== true)?.ttId || '';
        }

        if (!!firstAvailableId) {
            this.selectId(firstAvailableId);
        }
    }

    /**
     * Remembers the currenlty selected id given that an id is selected and remember id provided.
     */
    private rememberSelectedId() {
        if (!!this.ttRememberId && !!this.ttSelectedId) {
            this.remember.remember(this.ttRememberId!, this.ttSelectedId);
        }
    }

    /**
     * Retrieves the last persisted id of this tabs with the remember id passed through `ttRememberId`.
     */
    private async getLastSelectedId() {
        try {
            this.contentTabs?.forEach((tab) => (tab.ttSkeleton = true));
            const lastId = (await this.remember.getLastStatus(this.ttRememberId))?.[0]?.variablevalue;

            if (!!lastId) {
                this.selectId(lastId);
            }
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Sets the tab-components of this tabs components as ready.
     */
    private setTabsAsReady() {
        this.contentTabs?.forEach((tab) => (tab.ttSkeleton = false));
        this.ready = true;
    }

    async ngOnInit(): Promise<void> {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttRememberId']?.currentValue && changes?.['ttRememberId']?.currentValue !== changes?.['ttRememberId']?.previousValue) {
            this.rememberSubject.pipe(debounceTime(500)).subscribe({ next: () => this.rememberSelectedId() });
        }

        if (changes['ttSelectedId']) {
            this.ttSelectedId = changes['ttSelectedId'].currentValue;

            if (!this.hasSelectedTab()) {
                this.selectFirstAvailableId();
            }

            this.updateActivePropForContentTabs();
        }
    }

    async ngAfterContentInit(): Promise<void> {
        this.contentTabs?.forEach((tab) =>
            tab.ttClick.subscribe((_) => {
                this.selectId(tab.ttId);
                this.rememberSubject?.next(this.ttSelectedId);
            })
        );

        if (!this.ttOnlyRemember && !!this.ttRememberId) {
            await this.getLastSelectedId();
        }

        if (!this.hasSelectedTab()) {
            this.selectFirstAvailableId();
        }

        this.updateActivePropForContentTabs();

        this.setTabsAsReady();
    }
}

/**
 * Represents the configuration of a single tab.
 */
export interface Tab {
    /**
     * The id of the tab.
     */
    id: string;

    /**
     * The label of the tab (will be translated).
     */
    label?: string;

    /**
     * Optional click handler of the tab, will fire after `ttModelChanged`.
     */
    onClick?: () => any;

    /**
     * Whether the button is disabled, or hidden.
     */
    disabled?: boolean | 'hidden';

    /**
     * Whether the label should be translated, default is `true`.
     */
    translateLabel?: boolean;

    /**
     * Icon to display in the tab.
     */
    icon?: string;
}
