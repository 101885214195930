import { Injectable } from '@angular/core';
import { DataServiceBase } from '../../../core/framework/data-service-base';
import { DataTaskService } from '../../../core/services/data-task.service';
import { AppSettingsService } from '../../../core/services/app-settings.service';
import { WebpagemenuService } from '@app/core/components/webpagemenu/webpagemenu.service';

export interface IMainMenuItem {
    show_gdpr_message: '1' | '0';
    gdpr_message: string;
    gdpr_title: string;
    menuitem_filtervalue: string;
    show_global_search: '1' | '0';
    global_searchtext: string;
    menusearch_placeholder: string;
    globalsearch_placeholder: string;
    show_suggestions: '1' | '0';
    mainmenu_logo: string;
    selected_tab: string;
}

export interface IMenuItem {
    item_id: number;
    item_name: string;
    item_state: string;
    item_parms: string;
    item_path: string;
    item_glyphicon: string;
    item_glyphicon_color: string;
    item_filtervalue: string;
    orderby: number;
    display_column: number;
}

export interface IWebPageMenuItem extends IMenuItem {
    badgeinfo: string;
}

export interface IOwnKpi extends IMenuItem {
    badge: string;
}

export interface IListWebPageMenuAllOptions {
    webpage_name: string;
    expand_all: string;
    sortorder_belongto: string;
    edit_mode: string;
    url: string;
}

export interface IListMyMenusOptions {
    webpage_name: string;
}

export interface ISearchGlobalAllOptions {
    global_searchtext: string;
}

export interface IApproveGdprResponse {
    errorcode: string;
    errormessage: string;
}

export interface ICheckVersionResponse {
    force_reload: string;
}

@Injectable({
    providedIn: 'root',
})
export class MainMenuService extends DataServiceBase {
    webPageMenuAll: IWebPageMenuItem[] = [];

    constructor(private dt: DataTaskService, private appSettings: AppSettingsService, private webpagemenu: WebpagemenuService) {
        super();
    }

    private async userlinkCheck(): Promise<void> {
        await this.appSettings.ensureIsReady();

        // Get the current URL
        const currentUrl = window.location.href;

        const parts = currentUrl.split('/');

        const index = parts.indexOf('mainmenu');

        if (parts.length !== index + 4 || parts[index + 1] !== 'userlink') return;

        // this is a userlink, redirect to userlink login
        let href = './.auth/login/userlink/' + parts[index + 2] + '/' + parts[index + 3];

        parts.splice(index + 1, 3);
        parts[1] = '/';

        const returnUrl = parts.join('/');
        const encodedUrl = encodeURIComponent(returnUrl);

        href = `${href}?returnUrl=${encodedUrl}&csi=${this.appSettings.clientSessionId}`;

        // Redirect the browser to the login page
        window.location.href = href;
    }

    public async getMainMenu(): Promise<IMainMenuItem[]> {
        // 20241129 - Need to place this check here because this method is called when pressing a stored userlink
        await this.userlinkCheck();

        return await this.dt.Post(2146);
    }

    public async listWebPageMenuItems(parms?: IListWebPageMenuAllOptions): Promise<IWebPageMenuItem[]> {
        if (this.webPageMenuAll.length < 1) {
            const items = await this.dt.Post(2854, parms || {});

            const self = this;

            items.forEach(function (item: any) {
                if (item?.display_column?.menuitem?.length > 0) {
                    self.webPageMenuAll.push(item.display_column.menuitem);
                }
            });
        }

        return this.webPageMenuAll;
    }

    public getMainMenuMenus() {
        return this.webpagemenu.getWebpagemenuItems('mainmenu');
    }

    public async getCriticalProcesses(): Promise<IOwnKpi[]> {
        return this.dt.Post(2977);
    }

    public async listMyMenus(parms: IListMyMenusOptions): Promise<IWebPageMenuItem[]> {
        return this.dt.Post(2857, parms);
    }

    public async searchGlobalAll(setting: ISearchGlobalAllOptions): Promise<IWebPageMenuItem[]> {
        return this.dt.Post(2855, setting);
    }

    public async approveGdpr(): Promise<IApproveGdprResponse> {
        return this.dt.Post(2147);
    }

    public async checkVersion(version: string): Promise<ICheckVersionResponse> {
        return this.dt.Post(2979, {
            tt_client_version: version,
        });
    }
}
