(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('prodGrp', {
        templateUrl: 'views/components/views/prodGrp/prodGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'prodGrpService', 'productService', function ($stateParams, stateService, translateService, modalService, prodGrpService, productService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodGrpId = $stateParams.prodgrp_id;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListDiscGroupProducts: [],
                lockedSave: false,
                lockedDelete: false
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await prodGrpService.getEdit({ prodgrp_id: prodGrpId }))[0];
            }

            async function loadDiscGroupProducts() {
                vm.model.selectListDiscGroupProducts = (await productService.listDiscGroupProducts());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

    		// #region SAVE BUTTON FUNCTION
    		
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                prodGrpService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    prodGrpService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back(2);
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
                loadDiscGroupProducts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
