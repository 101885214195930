(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('freightRateTypes', {
        templateUrl: 'views/components/views/freightRateTypes/freightRateTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'freightRateTypesService', function (stateService, freightRateTypesService) {
            const vm = this;
            
            vm.model = {
                itemsListRateTypes: []
            };

            let loadFreightRateTypes = function () {
                freightRateTypesService.listFreightRateTypes().then(function (list) {
                    angular.copy(list, vm.model.itemsListRateTypes);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'freightratetype':
                        go({ freightratetype_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadFreightRateTypes();
            };
        }]
    });
})();
