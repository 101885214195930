(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productLang', {
        templateUrl: 'views/components/views/productLang/productLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productLangService', function ($stateParams, stateService, productLangService) {
            const vm = this;
            let prodLangKeyno = $stateParams.prodlang_keyno
            
            vm.model = {
                edit: {},
                lockedSave: false
            };
            
            async function loadEdit() {
                vm.model.edit = (await productLangService.getProductLanguage({ prodlang_keyno: prodLangKeyno }))[0];
            }

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productLangService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
