import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'tt-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateRangeComponent extends FormFieldBaseComponent {
    /**
     * Date-string of the end date of this date-range.
     */
    @Input()
    public ttStartDate: string = '';

    /**
     * The date-object for the start date of this date-range.
     */
    public startDate: Date | null = null;

    /**
     * Event emitted when the start date has changed.
     */
    @Output()
    public ttStartDateChange = new EventEmitter<string>();

    /**
     * Date-string of the end date of this date-range.
     */
    @Input()
    public ttEndDate: string = '';

    /**
     * The date-object for the start date of this date-range.
     */
    public endDate: Date | null = null;

    /**
     * Event emitted when the end date has changed.
     */
    @Output()
    public ttEndDateChange = new EventEmitter<string>();

    /**
     * The interval for the date-range.
     */
    @Input()
    public ttInterval: DateRangeInterval = 'day';

    /**
     * Event emitted when the interval of the date-range has changed.
     */
    @Output()
    public ttIntervalChange = new EventEmitter<DateRangeInterval>();

    /**
     * The interval for the date-range. \
     * **NOT RECOMMENDED** - deeply nested objects goes unnoticed by angulars change listeners, a change in this object will not be reflected unless you recreate the object with each change (which is hacky).
     * If you are depending on listening to changes actively use `ttStartDate`, `ttEndDate` and `ttInterval` instead.
     */
    @Input()
    public ttModel: DateModel = { date_fom: '', date_tom: '', dateselector_index: 'day' };

    /**
     * Event emitted when either `ttStartDate`, `ttEndDate` or `ttInterval` has changed.
     */
    @Output()
    ttModelChange = new EventEmitter<DateModel>();

    /**
     * Whether the short label for the date-range intervals should be shown instead of the full label.
     */
    @Input()
    public set ttShowShortLabel(value: BooleanInput) {
        this._showShortLabel = coerceBooleanProperty(value);
    }
    public get ttShowShortLabel(): boolean {
        return this._showShortLabel;
    }
    private _showShortLabel = true;

    /**
     * Ids connected to the template of this component.
     */
    public id = {
        startDate: crypto.randomUUID(),
        endDate: crypto.randomUUID(),
    };

    /**
     * Handles changes in start date.
     *
     * @param event the new date.
     */
    public onStartDateChanged(event: Date | null) {
        if (event !== null) {
            this.startDate = event;

            this.ttStartDate = this.getDateString(this.startDate);
            this.rememberSubject.next(['startdate']);
            this.ttStartDateChange.emit(this.ttStartDate);

            if (!this.endDate || this.startDate > this.endDate) {
                this.onEndDateChanged(this.startDate);
            }
        }
    }

    /**
     * Handles changes in end date.
     *
     * @param event the new end date.
     */
    public onEndDateChanged(event: Date | null) {
        if (event !== null) {
            this.endDate = event;

            this.ttEndDate = this.getDateString(this.endDate);
            this.rememberSubject.next(['enddate']);
            this.ttEndDateChange.emit(this.ttEndDate);

            if (!this.startDate || this.endDate < this.startDate) {
                this.onStartDateChanged(this.endDate);
            }
        }
    }

    /**
     * Handles changes in the interval and updates the start and end-date accordingly.
     *
     * @param event the new date-range.
     */
    public onIntervalChanged(event: DateRangeInterval) {
        if (!!event) {
            const startDate = this.getDateValues(this.startDate ?? this.endDate ?? new Date());
            const endDate = this.getDateValues(this.endDate ?? new Date());
            const today = this.getDateValues(new Date());

            if (this.ttInterval === 'minmax') {
                startDate.year = today.year;
                endDate.year = today.year;
            }

            this.ttInterval = event;
            this.ttIntervalChange.emit(this.ttInterval);
            switch (this.ttInterval) {
                case 'day': // Set to start date
                    this.startDate = new Date(startDate.year, startDate.month, startDate.fullDate, startDate.hours);
                    this.endDate = new Date(this.startDate.getTime());
                    break;
                case 'week': // Set to week of start date
                    this.startDate = new Date(startDate.year, startDate.month, startDate.firstDayOfWeek, startDate.hours);
                    this.endDate = new Date(startDate.year, startDate.month, startDate.lastDayOfWeek, startDate.hours);
                    break;
                case 'month': // Set to month of start date
                    this.startDate = new Date(startDate.year, startDate.month, 1, startDate.hours);
                    this.endDate = new Date(startDate.year, startDate.month + 1, 0, startDate.hours);
                    break;
                case 'quarter': // Set to quarter from start date
                    this.startDate = new Date(startDate.year, startDate.quarter, 1, startDate.hours);
                    this.endDate = new Date(startDate.year, startDate.quarter + 3, 0, startDate.hours);
                    break;
                case 'biannual': // Set biannually from start date
                    this.startDate = new Date(startDate.year, startDate.biannual, 1, startDate.hours);
                    this.endDate = new Date(startDate.year, startDate.biannual + 6, 0, startDate.hours);
                    break;
                case 'year': // Set to year from start date.
                    this.startDate = new Date(startDate.year, 0, 1, startDate.hours);
                    this.endDate = new Date(startDate.year, 12, 0, startDate.hours);
                    break;
                case 'yeartodate': // Set to first day of end year and todays date.
                    this.startDate = new Date(endDate.year, 0, 1, endDate.hours);
                    this.endDate = new Date(endDate.year, today.month, today.fullDate, endDate.hours);
                    break;
                case 'minmax': // Set to "min" and "max" date.
                    this.startDate = new Date(1901, 0, 1, startDate.hours);
                    this.endDate = new Date(2999, 12, 0, startDate.hours);
                    break;
                default:
                    break;
            }
        }

        this.onStartDateChanged(this.startDate);
        this.onEndDateChanged(this.endDate);
        this.rememberSubject.next(['startdate', 'enddate', 'interval']);
    }

    /**
     * Updates the model with the current startdate, enddate and range-interval.
     */
    public onModelChange() {
        if (this.ttModel.date_fom !== this.ttStartDate || this.ttModel.date_tom !== this.ttEndDate || this.ttModel.dateselector_index !== this.ttInterval) {
            this.ttModelChange.emit({ date_fom: this.ttStartDate, date_tom: this.ttEndDate, dateselector_index: this.ttInterval });
        }
    }

    /**
     * Decreases the date-range interval to the next day / week / month, etc. depending on the currently selected interval.
     */
    public decreaseSelectedDateRangeInterval() {
        const startDate = this.getDateValues(this.startDate ?? this.endDate ?? new Date());
        const endDate = this.getDateValues(this.endDate ?? new Date());

        switch (this.ttInterval) {
            case 'day': // Decrease Day
                this.startDate = new Date(startDate.year, startDate.month, startDate.fullDate - 1, startDate.hours);
                this.endDate = new Date(this.startDate.getTime());
                break;
            case 'week': // Decrease Week
                this.startDate = new Date(startDate.year, startDate.month, startDate.firstDayOfWeek - 7, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.month, startDate.lastDayOfWeek - 7, startDate.hours);
                break;
            case 'month': // Decrease Month
                this.startDate = new Date(startDate.year, startDate.month - 1, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.month, 0, startDate.hours);
                break;
            case 'quarter': // Decrease Quarter
                this.startDate = new Date(startDate.year, startDate.quarter - 3, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.quarter, 0, startDate.hours);
                break;
            case 'biannual': // Decrease Biannual
                this.startDate = new Date(startDate.year, startDate.biannual - 6, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.biannual, 0, startDate.hours);
                break;
            case 'year': // Decrease Year
                this.startDate = new Date(startDate.year - 1, 0, 1, startDate.hours);
                this.endDate = new Date(startDate.year - 1, 12, 0, startDate.hours);
                break;
            case 'yeartodate': // Decrease Year but keep the end date the same
                this.startDate = new Date(startDate.year - 1, 0, 1, startDate.hours);
                this.endDate = new Date(endDate.year - 1, endDate.month, endDate.fullDate, endDate.hours);
                break;
            default:
                break;
        }

        this.onStartDateChanged(this.startDate);
        this.onEndDateChanged(this.endDate);
        this.rememberSubject.next(['startdate', 'enddate']);
    }

    /**
     * Increases the date-range interval to the next day / week / month, etc. depending on the currently selected interval.
     */
    public increaseSelectedDateRangeInterval() {
        const startDate = this.getDateValues(this.startDate ?? this.endDate ?? new Date());
        const endDate = this.getDateValues(this.endDate ?? new Date());

        switch (this.ttInterval) {
            case 'day': // Increase Day
                this.startDate = new Date(startDate.year, startDate.month, startDate.fullDate + 1, startDate.hours);
                this.endDate = new Date(this.startDate.getTime());
                break;
            case 'week': // Increase Week
                this.startDate = new Date(startDate.year, startDate.month, startDate.firstDayOfWeek + 7, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.month, startDate.lastDayOfWeek + 7, startDate.hours);
                break;
            case 'month': // Increase Month
                this.startDate = new Date(startDate.year, startDate.month + 1, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.month + 2, 0, startDate.hours);
                break;
            case 'quarter': // Increase Quarter
                this.startDate = new Date(startDate.year, startDate.quarter + 3, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.quarter + 6, 0, startDate.hours);
                break;
            case 'biannual': // Increase Biannual
                this.startDate = new Date(startDate.year, startDate.biannual + 6, 1, startDate.hours);
                this.endDate = new Date(startDate.year, startDate.biannual + 12, 0, startDate.hours);
                break;
            case 'year': // Increase Year
                this.startDate = new Date(startDate.year + 1, 0, 1, startDate.hours);
                this.endDate = new Date(startDate.year + 1, 12, 0, startDate.hours);
                break;
            case 'yeartodate': // Increase Year but keep the end date the same
                this.startDate = new Date(startDate.year + 1, 0, 1, startDate.hours);
                this.endDate = new Date(endDate.year + 1, endDate.month, endDate.fullDate, endDate.hours);
                break;
            default:
                break;
        }

        this.onStartDateChanged(this.startDate);
        this.onEndDateChanged(this.endDate);
        this.rememberSubject.next(['startdate', 'enddate']);
    }

    /**
     * Retrieves different date-range interval values from the given date.
     *
     * @param date the date to retrieve interval values from.
     * @returns an object containing different interval values for the given date.
     */
    private getDateValues(date: Date) {
        const dateCopy = new Date(date.getTime());

        return {
            date: dateCopy,
            year: dateCopy.getFullYear(),
            month: dateCopy.getMonth(),
            fullDate: dateCopy.getDate(),
            day: dateCopy.getDay(),
            firstDayOfWeek: dateCopy.getDate() - dateCopy.getDay() + 1,
            lastDayOfWeek: dateCopy.getDate() - dateCopy.getDay() + 7,
            quarter: Math.floor(dateCopy.getMonth() / 3) * 3,
            biannual: Math.floor(dateCopy.getMonth() / 6) * 6,
            hours: 3,
        };
    }

    /**
     * Creates and returns a date string from the given date. If null or invalid returns a date string of todays date.
     *
     * @param date the date to create a datestring for.
     * @returns a date string representing the given date, or todays date if the given date was null or an invalid date.
     */
    private getDateString(date: Date | null): string {
        if (!!date && date.toString() !== 'Invalid Date') {
            return new Date(date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10);
        } else {
            return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10);
        }
    }

    private getDateFromString(dateString: string): Date | null {
        let date = new Date(dateString);

        if (!date || date.toString() === 'Invalid Date') {
            return new Date();
        }
        return date;
    }

    /**
     * Maps the old index based keys of interval-range settings to the new text ids.
     * If an invalid date-range is given than `'day'` as default is returned.
     */
    private getRangeInterval(interval: string): DateRangeInterval {
        const rangeIntervals: DateRangeInterval[] = ['day', 'week', 'month', 'quarter', 'biannual', 'year', 'yeartodate', 'minmax'];

        if (rangeIntervals.includes(interval as DateRangeInterval)) {
            return interval as DateRangeInterval;
        } else if (!!interval && !isNaN(+interval) && rangeIntervals[+interval - 1]) {
            return rangeIntervals[+interval - 1];
        }

        return 'day';
    }

    private getRangeIntervalAsIndex() {
        const rangeIntervals: DateRangeInterval[] = ['day', 'week', 'month', 'quarter', 'biannual', 'year', 'yeartodate', 'minmax'];
        return rangeIntervals.indexOf(this.ttInterval) !== -1 ? rangeIntervals.indexOf(this.ttInterval) + 1 : '1';
    }

    protected override rememberModel(toRemember: ('startdate' | 'enddate' | 'interval' | 'all')[]): void {
        if (toRemember.includes('startdate')) {
            this.remember.remember(`${this.ttRememberId}.date_fom`, this.ttStartDate);
        }
        if (toRemember.includes('enddate')) {
            this.remember.remember(`${this.ttRememberId}.date_tom`, this.ttEndDate);
        }
        if (toRemember.includes('interval')) {
            this.remember.remember(`${this.ttRememberId}.dateselector_index`, this.getRangeIntervalAsIndex());
        }
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.ttStartDate = (await this.remember.getLastStatus(`${this.ttRememberId}.date_fom`))[0].variablevalue;
            this.ttEndDate = (await this.remember.getLastStatus(`${this.ttRememberId}.date_tom`))[0].variablevalue;
            this.ttInterval = this.getRangeInterval((await this.remember.getLastStatus(`${this.ttRememberId}.dateselector_index`))[0].variablevalue);

            this.ttModel = {
                date_fom: this.ttStartDate,
                date_tom: this.ttEndDate,
                dateselector_index: this.ttInterval,
            };

            this.startDate = this.getDateFromString(this.ttStartDate);
            this.endDate = this.getDateFromString(this.ttEndDate);
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes['ttShowShortLabel']) {
            this.ttShowShortLabel = changes['ttShowShortLabel'].currentValue;
        }

        if (changes['ttStartDate']) {
            this.startDate = this.getDateFromString(changes['ttStartDate'].currentValue);
        }

        if (changes['ttEndDate']) {
            this.endDate = this.getDateFromString(changes['ttEndDate'].currentValue);
        }

        if (changes['ttInterval']) {
            this.ttInterval = this.getRangeInterval(changes['ttInterval'].currentValue);
        }

        if (changes['ttModel']) {
            this.ttModel = { ...changes['ttModel'].currentValue };
            this.startDate = this.getDateFromString(this.ttModel.date_fom);
            this.endDate = this.getDateFromString(this.ttModel.date_tom);
            this.ttInterval = this.getRangeInterval(this.ttModel.dateselector_index);
        }
    }
}

/**
 * Text representing a date-range interval.
 */
export type DateRangeInterval = 'day' | 'week' | 'month' | 'quarter' | 'biannual' | 'year' | 'yeartodate' | 'minmax';

export interface DateModel {
    date_fom: string;
    date_tom: string;
    dateselector_index: string;
}

/*
bindings: {
  ttLabel: '@',           // { string } - the label to display for the date range input fields.
  ttSublabel: '<?',       // { Object: string } - sublabel for the date range input, this is not translated in component.
  ttLabelView: '@',       // { 'top' | 'side' | 'auto' | 'hidden' | 'none' } - the position of the label - 'top', 'side', 'auto', 'hidden', 'none' - null or undefined indicates auto.
  ttStartDate: '<',       // { string } - the start date for the date range.
  ttEndDate: '<',         // { string } - the end date for the date range.
  ttDateIndex: '<',       // { '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' } - the index of the date interval chosen, a stringyfied number between '1' and '6' for [day, week, month, quater, biannual, year, yearToDate, minMax] respectively.
  ttModel: '<?',          // { { date_fom: string; date_tom: string; dateselector_index: string; } } - alternaive to using ttStartDate, ttEndDate and ttDateIndex, a configuration object containing information about the date range: { date_fom: '', date_tom: '', dateselector_index: '1' }.
  ttChange: '&',          // { ($startDate: string, $endDate: string, $dateIndex: string) => void } - callback for the when the values of the date range has changed, takes the parameters ($startDate, $endDate, $dateIndex).
  ttTextAlign: '@?',      // { 'left' | 'right' | 'center' | 'justify' } - the alignment of the text in the input fields.
  ttStyle: '<?',          // { Object } - style object to override default styling.
},

  vm.onDateChanged = function (index) {
      if (index) onDateIntervalChanged(index);

      if ((vm.ttStartDate || vm.ttStartDate?.trim() === '') && (vm.ttEndDate || vm.ttEndDate?.trim() === '')) {
          vm.ttStartDate = dateToString(vm.startDate);
          vm.ttEndDate = dateToString(vm.endDate);
          vm.ttDateIndex = vm.activeIndex;
      }

      if (vm.ttModel) {
          vm.ttModel.date_fom = dateToString(vm.startDate);
          vm.ttModel.date_tom = dateToString(vm.endDate);
          vm.ttModel.dateselector_index = vm.activeIndex;
      }

      let startDate = (vm.ttStartDate ? vm.ttStartDate : vm.ttModel?.date_fom) ?? '';
      let endDate = (vm.ttEndDate ? vm.ttEndDate : vm.ttModel?.date_tom) ?? '';
      let dateIndex = (vm.ttDateIndex ? vm.ttDateIndex : vm.ttModel?.dateselector_index) ?? vm.activeIndex;

      if (angular.isFunction(vm.ttChange)) {
          vm.ttChange({ $startDate: startDate, $endDate: endDate, $dateIndex: dateIndex });
      }
  }

  function getDateValues(date) {
      const dateCopy = angular.copy(date);

      return {
          date: dateCopy,
          year: dateCopy.getFullYear(),
          month: dateCopy.getMonth(),
          fullDate: dateCopy.getDate(),
          day: dateCopy.getDay(),
          firstDayOfWeek: (dateCopy.getDate() - dateCopy.getDay() + 1),
          lastDayOfWeek: (dateCopy.getDate() - dateCopy.getDay() + 7),
          quarter: (Math.floor((dateCopy.getMonth()) / 3) * 3),
          biannual: (Math.floor((dateCopy.getMonth()) / 6) * 6),
          hours: 3,
      }
  }


  let setClasses = function (labelAlwaysOnTop) {
      vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
  };

  let setStyle = function (ttStyle = vm.ttStyle) {
      angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, mainElement: 'date', textAlign: vm.ttTextAlign }), vm.style);
  };

  layoutService.onLayoutChanged(onDestroy, function (info) {
      if (angular.isUndefined(info)) return;

      ttDirectivesService.setLayoutStyle(vm.style, info);
      vm.style.group.height = '100%';

      vm.style.button.fontSize = info.fontSizes.textSize;
      vm.style.button.height = info.height + 'px';
      vm.style.button.paddingTop = info.padding.top + 'px';
      vm.style.button.paddingBottom = '0px';
      vm.style.button.paddingLeft = info.padding.left + 'px';
      vm.style.button.paddingRight = info.padding.right + 'px';

      vm.style.buttonLabel.fontSize = info.fontSizes.textSize;


      setStyle(vm.ttStyle);

      setClasses(info.labelAlwaysOnTop);
  });


  function dateToString(date) {
      if (date && date instanceof Date && date.toString !== 'Invalid Date') {
          return date.toISOString().substring(0, 10);
      } else {
          return '';
      }
  }


  function setDateRange(startDate, endDate, dateIndex) {
      if (typeof startDate === 'string' && startDate?.length > 6) {
          vm.startDate = new Date(startDate);
      } else {
          vm.startDate = new Date();
      }

      if (typeof endDate === 'string' && endDate?.length > 6) {
          vm.endDate = new Date(endDate);
      } else {
          vm.endDate = new Date();
      }

      if (Number(dateIndex) >= 1 || Number(dateIndex) <= vm.intervals.length) {
          vm.activeIndex = dateIndex;
      } else {
          vm.activeIndex = vm.intervals[0].index;
      }
  }


  vm.$onInit = function () {
      setStyle(vm.ttStyle);
      translateService.translateBatch(vm.translations.buttons).then((translations) => {
          vm.translations.buttons = translations;

          angular.forEach(vm.intervals, function (interval) {
              let label_key = interval.id; 
              let title_key = interval.id + '_title';
              if (vm.ttUseShort === 'false')
                  interval.label = vm.translations.buttons[label_key];
              else
                  interval.label = vm.translations.buttons[label_key + '_short'];
              interval.title = vm.translations.buttons[title_key];
          });
      });
  }

  vm.$onChanges = function (changes) {

      if (changes?.ttModel?.currentValue?.date_fom && changes?.ttModel?.currentValue?.date_tom) {
          setDateRange(changes.ttModel.currentValue.date_fom, changes.ttModel.currentValue.date_tom, changes.ttModel.currentValue?.dateselector_index);
      }

      if ((changes?.ttStartDate?.currentValue || changes.ttStartDate?.currentValue?.trim() === '') || (changes?.ttEndDate?.currentValue || changes.ttEndDate?.currentValue?.trim() === '')) {

          let newStart = changes?.ttStartDate?.currentValue ?? vm.ttStartDate;
          let newEnd = changes?.ttEndDate?.currentValue ?? vm.ttEndDate;

          setDateRange(newStart, newEnd, ((changes.ttDateIndex?.currentValue ?? vm.activeIndex) ?? '1'));
      }

      if (changes?.ttStyle?.currentValue) {
          setStyle(changes.ttStyle.currentValue);
      }

      if (changes?.ttTextAlign?.currentValue) setStyle();

      if (changes?.ttLabel?.currentValue && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
          if (vm.ttTranslate === 'false') {
              vm.translations.ttLabel = changes.ttLabel.currentValue;
          } else {
              translateService.translate(changes.ttLabel.currentValue).then((translation) => vm.translations.ttLabel = translation);
          }
      }

      if (changes?.ttRequired?.currentValue) {
          vm.required = us.toBoolean(changes.ttRequired.currentValue);
      }

  };

  vm.$onDestroy = function () {
      ttDirectivesService.onDestroy(onDestroy);
  };
*/
