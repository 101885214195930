(function () {
    'use strict';

    // ReSharper disable once UndeclaredGlobalVariableUsing
    const module = angular.module('imApp');

    module.component('p2UpdateTables', {
        templateUrl: 'views/components/views/p2UpdateTables/p2UpdateTables.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'p2UpdateTablesService', 'rememberService', 'layoutService', function (stateService, utilityService, p2UpdateTablesService, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let variableNames = {
                filtervalue: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2UpdateTableKeyno: '',
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
                setting: {},
                itemsListUpdateTables: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                p2UpdateTablesService.getUpdateTables().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadUpdateTables = function () {
                p2UpdateTablesService.listUpdateTables().then(function (list) {
                    angular.copy(list, vm.model.itemsListUpdateTables);

                    angular.forEach(vm.model.itemsListUpdateTables, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadUpdateTables();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2UpdateTableKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2updatetableedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2updatetableedit') {
                            vm.model.p2UpdateTableKeyno = '0';
                        } else {
                            go({ p2_updatetable_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadUpdateTables();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
