(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2Subscription', {
        templateUrl: 'views/components/views/p2Subscription/p2Subscription.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2SubscriptionService', 'layoutService', function (stateService, p2SubscriptionService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2SubscriptionKeyno: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListSubscriptions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                p2SubscriptionService.getSubscription().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadSubscriptions = function () {
                p2SubscriptionService.listSubscriptions().then(function (list) {
                    angular.copy(list, vm.model.itemsListSubscriptions);

                    angular.forEach(vm.model.itemsListSubscriptions, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadSubscriptions();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2SubscriptionKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2subscriptionedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2subscriptionedit') {
                            vm.model.p2SubscriptionKeyno = '0';
                        } else {
                            go({ p2_subscription_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSubscriptions();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
