import { Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { WebpagemenuGroupWidgetState } from './webpagemenu.component';
import { RememberService } from '@app/core/services/remember.service';

@Injectable({
    providedIn: 'root',
})
export class WebpagemenuService {
    /**
     * Session cache for menu groups.
     */
    private menuGroups?: MenuGroup[];

    /**
     * Session cache for menu groups.
     */
    private webpagemenues: { [key: string]: MenuItem[] } = {};

    /**
     * Session cache for menu states.
     */
    private webpageStates: { [key: string]: WebpagemenuGroupWidgetState[] } = {};

    constructor(private datatask: DataTaskService, private remember: RememberService) {}

    /**
     * Retrieves initial settings for webpagemenues.
     *
     * @returns initial settings for webpagemenues.
     */
    public async getWebpagemenuEdit(): Promise<WebpageMenuGet> {
        return (await this.datatask.Post(3523))[0];
    }

    /**
     * Retrieves list over webpage menu groups.
     *
     * @returns list over webpagemenu groups.
     */
    public async getWebpagemenuGroups() {
        if (!!this.menuGroups) {
            return this.menuGroups;
        }

        return (this.menuGroups = await this.datatask.Post(3485));
    }

    /**
     * Retrieves a list of webpagemenu items for the webpagemenu with the given webpage name.
     *
     * @param webpageName the name of the webpagemenu to retrieve menu items for.
     * @returns a promise containing the webpagemenu items.
     */
    public async getWebpagemenuItems(webpageName: string): Promise<MenuItem[]> {
        if (!webpageName) throw Error('WebpageName is required to retrieve menu items.');

        if (!!this.webpagemenues[webpageName] && Array.isArray(this.webpagemenues[webpageName])) {
            return this.webpagemenues[webpageName];
        }

        return (this.webpagemenues[webpageName] = await this.datatask.Post(53, { webpage_name: webpageName, edit_mode: false }));
    }

    /**
     * Stores the given states keyed by the given webpagename and adds a record to session storage from service.
     *
     * @param states the state to set.
     * @param webpageName the name of the webpagemenu to set it for.
     */
    public async setWebpagemenuState(states: WebpagemenuGroupWidgetState[], webpageName: string): Promise<void> {
        if (this.isWebpagemenuState(states, webpageName)) {
            this.webpageStates[webpageName] = states;
            this.remember.remember(`${webpageName}.webpagemenu`, JSON.stringify(states));
        }
    }

    /**
     * Retrieves the last stored state of the webpagemenu of the given name.
     *
     * @param webpageName the name of the webpage menu to retrieve state for.
     * @returns a promise containing the webpagemenu state if found, if no state available `null` is returned.
     */
    public async getWebpagemenuState(webpageName: string): Promise<WebpagemenuGroupWidgetState[] | null> {
        if (!webpageName) throw Error('WebpageName is required to retrieve webpagemenustates.');

        if (this.isWebpagemenuState(this.webpageStates[webpageName], webpageName)) {
            return this.webpageStates[webpageName];
        }

        let state = (await this.remember.getLastStatus(`${webpageName}.webpagemenu`))?.[0]?.variablevalue ?? null;

        try {
            state = JSON.parse(state);

            if (Array.isArray(state) && state.every((item) => Object.hasOwn(item, 'expanded'))) {
                return state;
            }
        } catch (error) {
            console.log(error);
        }

        return null;
    }

    private isWebpagemenuState(value: unknown, webpageName: string): value is WebpagemenuGroupWidgetState[] {
        return Array.isArray(value) && value.every((state) => Object.hasOwn(state, 'id') && ('' + state.id).startsWith(webpageName) && Object.hasOwn(state, 'expanded') && typeof state.expanded === 'boolean');
    }
}
/**
 * Represents a menu group.
 */
export interface MenuGroup {
    /**
     * The key of the menugroup, is string but contains a number.
     */
    menugrp_keyno: string;

    /**
     * The name of the menu group.
     */
    menugrp_name: string;

    /**
     * The order of the menu group, is string but contains number.
     */
    sortorder: string;

    /**
     * The state to go to the menu group settings.
     */
    item_state: string;

    /**
     * The params to use to go to the menu group settings.
     */
    item_parms: string;
}

export interface MenuItem {
    item_id: string;
    parent_id: string;
    link_id: string;
    item_name: string;
    item_state: string;
    item_parms: string;
    orderby: string;
    item_glyphicon: string;
    item_glyphicon_color: string;
    item_filtervalue: string;
    badgeinfo: string;
    word_id: string;
    webpage_name: string;
    p2_approute_keyno: string;
    menugrp_keyno: string;
}

export interface WebpageMenuGet {
    editable: '1' | '0';
}
