(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderlineStatus', {
        templateUrl: 'views/components/views/orderlineStatus/orderlineStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'orderlineStatusService', function ($stateParams, orderlineStatusService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;
            
            vm.model = {
                orderInternalNo: orderInternalNo,
                itemsListStatuses: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            async function loadStatuses() {
                vm.model.itemsListStatuses = (await orderlineStatusService.listStatuses({ order_internal_no: orderInternalNo }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStatuses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
