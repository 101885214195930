(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('helpDocSearch', {
        templateUrl: 'views/components/views/helpDocSearch/helpDocSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'helpDocSearchService', 'languageService', 'layoutService', function ($q, utilityService, helpDocSearchService, languageService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                selectListLanguages: [],
                itemsListDocuments: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                helpDocSearchService.getHelpDocSearch().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadHelpDocSearch()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadLanguages = function () {
                languageService.list().then(function (list) {
                    angular.copy(list, vm.model.selectListLanguages);
                });
            };

            let loadHelpDocSearch = function () {
                if (utilityService.validateParmsValue(vm.model.setting.language_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListDocuments = [];

                return helpDocSearchService.listHelpDocSearch(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListDocuments);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') loadHelpDocSearch();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.searchItems = function () {
                loadHelpDocSearch();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'language_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.language_id) !== true) return;

                        vm.model.setting.language_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.language_id) !== true) return;

                        loadHelpDocSearch();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') loadHelpDocSearch();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
