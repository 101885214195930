(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('shiftPlans', {
        templateUrl: 'views/components/views/shiftPlans/shiftPlans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'shiftPlansService', function (stateService, shiftPlansService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                itemsListShiftPlans: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadShiftPlans = function () {
                shiftPlansService.listShiftPLans().then(function (list) {
                    angular.copy(list, vm.model.itemsListShiftPlans);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shiftplan':
                        go({ shiftplan_id: '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadShiftPlans();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
