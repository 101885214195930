import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardWidgetChart } from '../dashboard-widget-base/dashboard-widget-base.component';
import { AgCartesianAxisOptions, AgCartesianChartOptions, AgCartesianSeriesOptions, AgChartOptions, AgPolarAxisOptions, AgPolarChartOptions, AgPolarSeriesOptions } from 'ag-charts-community';
import { DashboardService } from '../dashboard.service';

@Component({
    selector: 'tt-dashboard-widget-chart',
    templateUrl: './dashboard-widget-chart.component.html',
    styleUrls: ['./dashboard-widget-chart.component.css'],
})
export class DashboardWidgetChartComponent implements OnChanges {
    @Input()
    public ttDashboardWidget?: DashboardWidgetChart;

    graphoption?: AgChartOptions;

    constructor(private dashboard: DashboardService) {}

    private mapGraphdata() {
        this.graphoption = {
            theme: 'ag-polychroma-dark',
            background: { visible: false },
            title: { enabled: false },
        };

        const chartType = this.getChartType();

        if (chartType === 'donut' || chartType === 'pie') {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapPolarData(chartType, this.ttDashboardWidget?.graphdata ?? []) } as AgPolarChartOptions;
        } else if (this.ttDashboardWidget?.kpicomp_type === 'GRAPH_BARH') {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapHorizontalCartesianData(this.ttDashboardWidget?.graphdata ?? []) } as AgCartesianChartOptions;
        } else {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapCartesianData(chartType, this.ttDashboardWidget?.graphdata ?? []) } as AgCartesianChartOptions;
        }
    }

    private getChartType() {
        if (this.ttDashboardWidget?.kpicomp_type === 'GRAPH_LINE') {
            return 'line';
        } else if (this.ttDashboardWidget?.kpicomp_type === 'PIE') {
            return 'pie';
        } else if (this.ttDashboardWidget?.kpicomp_type === 'DONUT') {
            return 'donut';
        } else {
            return 'bar';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.mapGraphdata();
    }
}
