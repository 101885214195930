(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('interBranchInvoicingMi', {
        templateUrl: 'views/components/views/interBranchInvoicingMi/interBranchInvoicingMi.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'interBranchInvoicingMiService', function ($stateParams, stateService, interBranchInvoicingMiService) {
            const vm = this;
            let businessCoNoFrom = $stateParams.businessco_no_from;
            let businessCoNoTo = $stateParams.businessco_no_to;
            
            vm.model = {
                edit: {},
                lockedCreate: false
            };
            
            async function loadEdit() {
                vm.model.edit = (await interBranchInvoicingMiService.getEdit({
                    businessco_no_from: businessCoNoFrom,
                    businessco_no_to: businessCoNoTo
                }))[0];
            }

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                interBranchInvoicingMiService.createInvoice(vm.model.edit).then(function (response) {
                    vm.model.lockedCreate = false;

                    stateService.go('invoicehead', { invoice_no: response[0].invoice_no });
                });
            };
            
            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
