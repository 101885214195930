import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SwitchComponent implements OnChanges, OnInit {
    /**
     * The id of the switch, must be unique for this switch-set. Used to signalize when this is the selected switch.
     */
    @Input()
    public ttId: string = '';

    /**
     * The text to the display for this switch.
     */
    @Input()
    public ttText: string = '';

    /**
     * The displayed text.
     */
    public text = '';

    /**
     * Whether the text for this switch should be translated.
     */
    @Input()
    public set ttTranslateText(value: BooleanInput) {
        this._translateText = coerceBooleanProperty(value);
    }
    public get ttTranslateText(): boolean {
        return this._translateText;
    }
    private _translateText = true;

    /**
     * The text to the display for this switch.
     */
    @Input()
    public ttTooltipText: string = '';

    /**
     * The displayed text.
     */
    public tooltipText = '';

    /**
     * Whether the text for this switch should be translated.
     */
    @Input()
    public set ttTranslateTooltipText(value: BooleanInput) {
        this._translateTooltipText = coerceBooleanProperty(value);
    }
    public get ttTranslateTooltipText(): boolean {
        return this._translateTooltipText;
    }
    private _translateTooltipText = true;

    /**
     * The icon to display for this switch.
     */
    @Input()
    public ttIcon?: string = '';

    /**
     * Whether this switch is the active switch.
     */
    @Input()
    public set ttActive(value: BooleanInput) {
        this._active = coerceBooleanProperty(value);
    }
    public get ttActive(): boolean {
        return this._active;
    }
    private _active = false;

    /**
     * Whether this switch is should stretch to use all its available place. Default true, if false will be the width of its content.
     */
    @Input()
    public set ttStretchWidth(value: BooleanInput) {
        this._stretchWidth = coerceBooleanProperty(value);
    }
    public get ttStretchWidth(): boolean {
        return this._stretchWidth;
    }
    private _stretchWidth = true;

    /**
     * Whether this switch is disabled.
     */
    @Input()
    public set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    public get ttDisabled(): boolean {
        return this._disabled;
    }
    private _disabled = false;

    /**
     * Whether to show loading animation for this switch.
     */
    @Input()
    public set ttSkeleton(value: BooleanInput) {
        this._skeleton = coerceBooleanProperty(value);
    }
    public get ttSkeleton(): boolean {
        return this._skeleton;
    }
    private _skeleton = false;

    /**
     * Whether the switch can be selected or not.
     */
    @Input()
    public set ttSelectable(value: BooleanInput) {
        this._selectable = coerceBooleanProperty(value);
    }
    public get ttSelectable(): boolean {
        return this._selectable;
    }
    private _selectable = true;

    /**
     * Event emitted when the switch is clicked.
     */
    @Output()
    public ttClick = new EventEmitter<MouseEvent>();

    /**
     * When data for the component is finished loading.
     */
    public ready = false;

    constructor(private translate: TranslateService) {}

    /**
     * Handles click event on tabs.
     *
     * @param event the mouse event for the click event.
     */
    public onClick(event: MouseEvent) {
        this.ttClick.emit(event);
    }

    /**
     * Sets the text of the switch.
     */
    private async setText() {
        if (this.ttTranslateText) {
            try {
                this.text = await this.translate.translate(this.ttText);
            } catch (err) {
                this.text = this.ttText;
            }
        } else {
            this.text = this.ttText;
        }
    }

    /**
     * Sets the tooltip text of the switch.
     */
    private async setTooltipText() {
        if (this.ttTranslateTooltipText) {
            try {
                this.tooltipText = await this.translate.translate(this.ttTooltipText);
            } catch (err) {
                this.tooltipText = this.ttTooltipText;
            }
        } else {
            this.tooltipText = this.ttTooltipText;
        }
    }

    async ngOnInit(): Promise<void> {
        if (!!this.ttText) {
            await this.setText();
        }
        this.ready = true;

        if (!this.ttId) throw Error('A unqiue id is required for tt-switch to work properly.');
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttActive']) {
            this.ttActive = changes['ttActive'].currentValue;
        }

        if (changes['ttStretchWidth']) {
            this.ttStretchWidth = changes['ttStretchWidth'].currentValue;
        }

        if (changes['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }

        if (changes['ttSkeleton']) {
            this.ttSkeleton = changes['ttSkeleton'].currentValue;
        }

        if (changes['ttTranslateText']) {
            this.ttTranslateText = changes['ttTranslateText'].currentValue;
        }

        if (changes['ttTranslateTooltipText']) {
            this.ttTranslateTooltipText = changes['ttTranslateTooltipText'].currentValue;
        }

        if (!!changes?.['ttText']?.currentValue && typeof changes?.['ttText']?.currentValue === 'string' && changes?.['ttText']?.currentValue !== changes?.['ttText']?.previousValue && this.ttTranslateText) {
            this.setText();
        }

        if (!!changes?.['ttTooltipText']?.currentValue && typeof changes?.['ttTooltipText']?.currentValue === 'string' && changes?.['ttTooltipText']?.currentValue !== changes?.['ttTooltipText']?.previousValue && this.ttTranslateTooltipText) {
            this.setTooltipText();
        }
    }
}
