(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('custactCalc', {
        templateUrl: 'views/components/views/custactCalc/custactCalc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stateService', 'modalService', 'translateService', 'custactCalcService', 'ttGridFactory', function ($stateParams, $q, utilityService, stateService, modalService, translateService, custactCalcService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argValue = $stateParams.argvalue;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                bgCustactCalcOrderSelection: {
                    selected: 'SUBTASKS',
                    buttons: [
                        { id: 'SUBTASKS', label: 'custactcalc_subtasks', color: 'primary', onClick: () => vm.model.bgCustactCalcOrderSelection.selected = 'SUBTASKS' },
                        { id: 'O', label: 'custactcalc_order', color: 'primary', onClick: () => vm.model.bgCustactCalcOrderSelection.selected = 'O' }
                    ]
                },
                bgOptionView: {
                    selected: 'INCLUDED',
                    buttons: [
                        { id: 'INCLUDED', label: 'custactcalc_included', color: 'primary', onClick: () => vm.model.bgOptionView.selected = 'INCLUDED' },
                        { id: 'OPTIONS', label: 'custactcalc_options', color: 'primary', onClick: () => vm.model.bgOptionView.selected = 'OPTIONS' }
                    ]
                },
                bgPostView: {
                    selected: 'POST',
                    buttons: [
                        { id: 'POST', label: 'custactcalc_summary', color: 'primary', onClick: () => vm.model.bgPostView.selected = 'POST' },
                        { id: 'TEXT', label: 'custactcalc_text', color: 'primary', onClick: () => vm.model.bgPostView.selected = 'TEXT' }
                    ]
                },
                orderNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('orderhead', { order_internal_no: vm.model.menu.order_internal_no }) }
                ],
                menu: {},
                inputListSubTaskTexts: [],
                gridReady: false,
                updatingCustactCalcPo: false,
                lockedSave: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'custactcalc.grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                const deferred = $q.defer();

                custactCalcService.getSetting({ custact_no: argValue }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadSubTaskTexts = function () {
                if (utilityService.validateParmsValue(vm.model.menu.custact_no) !== true) return;

                return custactCalcService.listSubTaskTexts({
                    custact_no: vm.model.menu.custact_no,
                    optiontype: vm.model.bgOptionView.selected
                }).then(function (list) {
                    angular.copy(list, vm.model.inputListSubTaskTexts);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3413,
                    parameters: vm.model.menu
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON FUNCTIONS

            vm.createFromCustomer = function () {
                custactCalcService.createOrder({
                    cust_no: vm.model.menu.cust_no,
                    custact_no: vm.model.menu.custact_no
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.menu.orderline_keyno = response[0].orderline_keyno;
                        vm.model.menu.order_internal_no = response[0].order_internal_no;
                        vm.model.menu.po_keyno = response[0].po_keyno;
                    }
                });
            };

            vm.updateCustactCalcPo = function () {
                vm.model.updatingCustactCalcPo = true;

                custactCalcService.updatePoComponents({ custact_no: vm.model.menu.custact_no }).then(function () {
                    vm.model.updatingCustactCalcPo = false;
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custactCalcService.saveEditText({ records: vm.model.inputListSubTaskTexts }).then(function () {
                    vm.model.lockedSave = false;
                    loadSubTaskTexts();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'categorydataselect':
                        go({
                            argtype: 'custact',
                            argvalue: vm.model.menu.custact_no
                        });
                        break;
                    case 'custactedit':
                        go({
                            custact_no: '0',
                            filterfield: 'custact_no',
                            filtervalue: vm.model.menu.custact_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCustactCalcOrderSelection = function (value) {
                vm.model.bgCustactCalcOrderSelection.selected = value;

                if (vm.model.bgCustactCalcOrderSelection.selected === 'SUBTASKS' && vm.model.bgOptionView.selected !== '') {
                    loadGrid();
                }
            };

            vm.selectBgOptionView = function (value) {
                vm.model.bgOptionView.selected = value;
                vm.model.menu.optiontype = value;

                if (vm.model.bgCustactCalcOrderSelection.selected === 'SUBTASKS' && vm.model.bgOptionView.selected !== '') {
                    loadGrid();
                }

                if (vm.model.bgPostView.selected === 'TEXT' && vm.model.bgOptionView.selected !== '') {
                    loadSubTaskTexts();
                }
            };

            vm.selectBgPostView = function (value) {
                vm.model.bgPostView.selected = value;

                if (vm.model.bgPostView.selected === 'TEXT' && vm.model.bgOptionView.selected !== '') {
                    loadSubTaskTexts();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
