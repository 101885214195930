<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.color"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="datetime"
>
    <input
        [id]="id.color"
        #checkboxRef
        class="tt-input__form-control tt-input__form-control--color"
        type="color"
        [(ngModel)]="ttModel"
        (ngModelChange)="onModelChange($event)"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
    />
    <label [for]="id.color" class="tt-color-input__view" [style.backgroundColor]="ttModel"></label>
    <input #inputRef (focus)="inputRef.select()" class="tt-input__form-control" [(ngModel)]="ttModel" (ngModelChange)="onModelChange($event)" />
</tt-form-field-base>
