(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2SettingEdit', {
        templateUrl: 'views/components/views/p2SettingEdit/p2SettingEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2SettingKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'p2SettingEditService', 'layoutService', function ($stateParams, stateService, translateService, modalService, p2SettingEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2SettingKeyno = vm?.p2SettingKeyno ?? $stateParams.p2_setting_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                settingNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.word_id }) }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                p2SettingEditService.getEdit({ p2_setting_keyno: p2SettingKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2SettingEditService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].iserror !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;

                        if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                            vm.onSave();
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2SettingKeyno?.currentValue) {
                    p2SettingKeyno = changes.p2SettingKeyno.currentValue;
                }

                if (p2SettingKeyno) loadEdit();
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
