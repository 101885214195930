import { AfterContentInit, Component, ContentChildren, forwardRef, Input, OnInit, QueryList } from '@angular/core';
import { DividerGenericComponent } from './divider-generic.component.';
import { RememberService } from '@app/core/services/remember.service';
import { AppSettingsService } from '@app/core/services/app-settings.service';

/**
 * The root component of any divider component.
 */
@Component({
    selector: 'tt-divider',
    templateUrl: './divider.component.html',
    styleUrls: ['./divider.component.css'],
})
export class DividerComponent implements AfterContentInit, OnInit {
    /**
     * Id to store the state of this divider. \
     * **NOTE** if amount of divider-panes is dynamic, use `ttId` attribute on all `tt-divider-pane` and `tt-divider-columns` and `tt-divider-rows`. Otherwise the stored state will be index based and susceptible to error when the amount of divider-panes changes
     */
    @Input()
    public ttRememberId?: string;

    /**
     * The dividers inside this divider root.
     */
    @ContentChildren(forwardRef(() => DividerGenericComponent), { descendants: true })
    private dividers: QueryList<DividerGenericComponent> = new QueryList<DividerGenericComponent>();

    /**
     * The initialsizes to use when divider component is rendered.
     */
    private initialSizes?: string[][] | { [key: string]: { [key: string]: string } };

    constructor(private remember: RememberService, private app: AppSettingsService) {}

    /**
     * Remembers the current sizes of the divider.
     */
    private rememberDividerState() {
        if (!!this.ttRememberId) {
            let sizes: string[][] | { [key: string]: { [key: string]: string } };

            if (this.allContentChildrenHasIds()) {
                sizes = this.getIdMappedSizes();
            } else {
                sizes = this.getSizeMatrix();
            }

            this.remember.remember(this.ttRememberId, JSON.stringify(sizes));
            this.rememberDividerStateLocal(sizes);
        }
    }

    /**
     * Saves the state of the divider to localstorage. Reason for this is so the initialization of size is instantanious and the dividers will only move from its first rendered position if the user is a new device or browsers.
     *
     * @param sizes the state to save.
     */
    private rememberDividerStateLocal(sizes: string[][] | { [key: string]: { [key: string]: string } }) {
        if (this.app.settings.user && this.ttRememberId) {
            let allStates;

            try {
                allStates = JSON.parse(localStorage.getItem('tt-divider.' + this.app.settings.user.portalUserKeynoEdit) || '{}');
            } finally {
                if (!allStates) {
                    allStates = {};
                }

                allStates[this.ttRememberId] = sizes;
                localStorage.setItem('tt-divider.' + this.app.settings.user.portalUserKeynoEdit, JSON.stringify(allStates));
            }
        }
    }

    /**
     * Creates and retruns a matrix over the sizes of the dividers and their panes sizes. It is indexed based meaning that
     * any changes amount of panes would be susceptible for error.
     *
     * @returns a matrix representing the dividers and the sizes of their panes.
     */
    private getSizeMatrix() {
        return this.dividers.map((divier) => divier.getPaneSizes());
    }

    /**
     * Creates and returns an object mapping the dividers id and the panes id to the sizes.
     *
     * @returns an object mapped by ids containing the sizes of the dividers and their panes.
     */
    private getIdMappedSizes() {
        let sizes: { [key: string]: { [key: string]: string } } = {};

        this.dividers.forEach((divider) => {
            sizes[divider.ttId!] = divider.getIdMappedPaneSizes();
        });

        return sizes;
    }

    /**
     * Checks if all the dividers and panes of this divder-root has an id or not.
     *
     * @returns `true` if all tt-divider-rows and tt-divider-columns and tt-divider-pane inside this tt-divider has an ttId attribute set.
     */
    private allContentChildrenHasIds() {
        return this.dividers.toArray().every((divider) => !!divider.ttId && divider.paneComponents.toArray().every((pane) => !!pane.ttId));
    }

    /**
     * Checks whether the given value is a matrix containing strings representing the size of the panes.
     *
     * @param value the value to check if is a matrix.
     * @returns `true` if the value is a matrix, `false` if not.
     */
    private isSizeMatrix(value: unknown): value is string[][] {
        return !!value && Array.isArray(value) && value.every((item) => Array.isArray(item) && item.every((i) => typeof i === 'string'));
    }

    /**
     * Checks whether the given value is a map, assuming keyed by ids of the dividers, containing a map representing the the dividers pane and their repsective sizes, also assuming mapped by id.
     *
     * @param value the value to check if is a map, containing map as values.
     * @returns `true` if the value is a id mapped sizes object.
     */
    private isIdMappedSizes(value: unknown): value is { [key: string]: { [key: string]: string } } {
        return !!value && !Array.isArray(value) && typeof value === 'object' && Object.values(value).every((item) => !!item && !Array.isArray(item) && typeof item === 'object' && Object.values(item).every((i) => typeof i === 'string'));
    }

    /**
     * Iterates over the dividers and sets the initial size of each of their panes.
     */
    private setInitialSizeOnPanes() {
        this.dividers.forEach((divider, index) => {
            if (this.isSizeMatrix(this.initialSizes)) {
                divider.setPanelSizes(this.initialSizes[index]);
            } else if (this.isIdMappedSizes(this.initialSizes) && divider.ttId) {
                divider.setSizesFromIdMappedPaneSizes(this.initialSizes[divider.ttId]);
            }
        });
    }

    private getLastDividerStateLocal() {
        if (!this.initialSizes && !!this.ttRememberId && !!this.app.settings.user) {
            try {
                const states = JSON.parse(localStorage.getItem('tt-divider.' + this.app.settings.user.portalUserKeynoEdit) || '{}');
                const state = states[this.ttRememberId];

                if (this.isSizeMatrix(state) || this.isIdMappedSizes(state)) {
                    this.initialSizes = state;
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     * Sets the initialsizes to the last divider state stored if any.
     */
    private async getLastDividersState() {
        if (!!this.ttRememberId) {
            const state = JSON.parse((await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue);

            if (this.isSizeMatrix(state) || this.isIdMappedSizes(state)) {
                this.initialSizes = state;
                this.setInitialSizeOnPanes();
            }
        }
    }

    ngAfterContentInit(): void {
        this.getLastDividerStateLocal();
        this.dividers.forEach((divider) => divider.ttResizeEnd.subscribe((_) => this.rememberDividerState()));
        this.setInitialSizeOnPanes();
    }

    ngOnInit(): void {
        this.getLastDividersState();
    }
}
