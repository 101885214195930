// BJS 20250306 - Migrated using chatGPT o1.
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This interface defines the data passed into the dialog:
 */
export interface CalculatorModalData {
    label?: string;
    plabel?: string;
    field?: any;
}

@Component({
    selector: 'app-calculator-modal',
    templateUrl: './calculator-modal.component.html',
    styleUrls: ['./calculator-modal.component.css']
})
export class CalculatorModalComponent {
    constructor(
        public dialogRef: MatDialogRef<CalculatorModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CalculatorModalData
    ) { }

    /**
     * Called by your numpad or any button in the template
     * when the user completes the calculation.
     */
    onEnter(value: any): void {
        // Close the dialog, passing the user’s numeric result
        this.dialogRef.close(value);
    }
}
