(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCountResult', {
        templateUrl: 'views/components/views/stockCountResult/stockCountResult.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stockCountResultService', 'ttGridFactory', function ($stateParams, $q, stockCountResultService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockCountKeyno = $stateParams.stockcount_keyno;

            vm.model = {
                bgStockCountResultMain: {
                    selected: 'DETAILS',
                    buttons: [
                        { id: 'DETAILS', label: 'gb_stockcountresult_main_details', color: 'primary', onClick: () => vm.model.bgStockCountResultMain.selected = 'DETAILS' },
                        { id: 'LOCATIONS', label: 'gb_stockcountresult_main_locations', color: 'primary', onClick: () => vm.model.bgStockCountResultMain.selected = 'LOCATIONS' },
                        { id: 'VALUES', label: 'gb_stockcountresult_main_values', color: 'primary', onClick: () => vm.model.bgStockCountResultMain.selected = 'VALUES' }
                    ]
                },
                setting: {},
                gridDetailsReady: false,
                gridLocationsReady: false,
                gridValuesReady: false
            };

            vm.gridDetails = new ttGridFactory({
                rememberId: 'w_stockcountresult_details_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridLocations = new ttGridFactory({
                rememberId: 'w_stockcountresult_stocklocations_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridValues = new ttGridFactory({
                rememberId: 'w_stockcountresult_values_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	stockCountResultService.getSetting({ stockcount_keyno: stockCountKeyno }).then(function (info) {
            		angular.copy(info[0], vm.model.setting);

                    loadGridDetails();
                    loadGridLocations();
                    loadGridValues();

                    deferred.resolve();
            	});

            	return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridDetails = function () {
                vm.gridDetails.dataTask.loadData = {
                    method: 3451,
                    parameters: vm.model.setting
                };

                vm.model.gridDetailsReady = true;

                if (vm.gridDetails.gridfunc !== null) vm.gridDetails.gridfunc.read();
            };

            let loadGridLocations = function () {
                vm.gridLocations.dataTask.loadData = {
                    method: 3453,
                    parameters: vm.model.setting
                };

                vm.model.gridLocationsReady = true;

                if (vm.gridLocations.gridfunc !== null) vm.gridLocations.gridfunc.read();
            };

            let loadGridValues = function () {
                vm.gridValues.dataTask.loadData = {
                    method: 3454,
                    parameters: vm.model.setting
                };

                vm.model.gridValuesReady = true;

                if (vm.gridValues.gridfunc !== null) vm.gridValues.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockCountResultMain = function (value) {
                vm.model.bgStockCountResultMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
