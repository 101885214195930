import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Item } from '../listbox/listbox.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ListFilterType } from './directives/list-filter.directive';
import { FormButton, FormButtonEvent } from '../form-field-button/form-field-button.component';
import { UtilityService } from '@app/core/services/utility.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ListCheckboxSelectionEvent } from './directives/list-checkbox-selection.directive';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css'],
})
export class ListComponent<TType extends Item> implements OnChanges {
    /**
     * **Reactive** \
     * List of items to display in the list.
     */
    @Input()
    ttData: TType[] = [];

    /**
     * The key of the property in the objects list which has the value to display, if any.
     */
    @Input()
    ttBadge?: string | null = '';

    /**
     * Event emitted when any values in the list of items has changed.
     */
    @Output()
    ttDataChange = new EventEmitter<TType[]>();

    /**
     * Where to place the tt-buttons. Currently only one setting is possible.
     */
    @Input()
    public ttButtonsPosition: 'right' | 'left' = 'right';

    /**
     * Whether the list items should be selectable with a checkbox-selection.
     */
    // @Input()
    set ttRadioSelection(value: BooleanInput) {
        this._radioSelection = coerceBooleanProperty(value);
    }
    get ttRadioSelection(): boolean {
        return this._radioSelection;
    }
    _radioSelection = false;

    /**
     * Emits an event when the radio selection has changed. Returns the id of the selected item in the event.
     */
    // @Output()
    public ttRadioSelectionChanged = new EventEmitter<TType>();

    /**
     * Whether the list items should be selectable with a checkbox-selection.
     */
    // @Input()
    set ttCheckboxSelection(value: BooleanInput) {
        this._checkboxSelection = coerceBooleanProperty(value);
    }
    get ttCheckboxSelection(): boolean {
        return this._checkboxSelection;
    }
    _checkboxSelection = false;

    public ttCheckboxSelectionChanged = new EventEmitter<ListCheckboxSelectionEvent>();

    /**
     * Reverses the order of the list items.
     */
    @Input()
    set ttReverse(value: BooleanInput) {
        this._reverse = coerceBooleanProperty(value);
    }
    get ttReverse(): boolean {
        return this._reverse;
    }
    _reverse = false;

    /**
     * Displays a icon for the extension type for each item given that the property `extension` is provided for the item.
     */
    @Input()
    set ttShowFileExtensionIcon(value: BooleanInput) {
        this._showFileExtensionIcon = coerceBooleanProperty(value);
    }
    get ttShowFileExtensionIcon(): boolean {
        return this._showFileExtensionIcon;
    }
    _showFileExtensionIcon = false;

    /**
     * Whether to hide the subtitle for the items (value of the property `'item_name_sub1'`).
     */
    @Input()
    set ttHideSubtitle(value: BooleanInput) {
        this._hideSubtitle = coerceBooleanProperty(value);
    }
    get ttHideSubtitle(): boolean {
        return this._hideSubtitle;
    }
    _hideSubtitle = false;

    /**
     * Whether to hide the subtext for the items (value of the property `'item_name_sub2'`).
     */
    @Input()
    set ttHideSubtext(value: BooleanInput) {
        this._hideSubtext = coerceBooleanProperty(value);
    }
    get ttHideSubtext(): boolean {
        return this._hideSubtext;
    }
    _hideSubtext = false;

    /**
     * Whether to add goto-functionality from the datasource set. Default is `true`,
     * meaning any `ttData` with item_state will have link functionality.
     */
    @Input()
    set ttGoto(value: BooleanInput) {
        this._goto = coerceBooleanProperty(value);
    }
    get ttGoto(): boolean {
        return this._goto;
    }
    _goto = true;

    /**
     * Whether to display a button for opening the state of item in a new tab.
     * Requires that the item has `'item_state'` and that `ttGoto` is `true`.
     */
    @Input()
    set ttNewTab(value: BooleanInput) {
        this._newTab = coerceBooleanProperty(value);
    }
    get ttNewTab(): boolean {
        return this._newTab;
    }
    _newTab = false;

    /**
     * Event emitted when any list item is clicked. If ttClick is provided it will disable the default goto functionality.
     */
    @Output()
    ttClick = new EventEmitter<{ item: Item; event: MouseEvent }>();

    /**
     * A function called for each item to check whether it can be clicked or not. If not provided it is clickable as long as a ttClick is provided.
     */
    @Input()
    ttClickable?: ((item: Item) => boolean) | null;

    /**
     * List of buttons to display for each list item passed through the `ttData` attribute.
     */
    @Input()
    ttButtons?: ListButton[] | undefined;

    /**
     * Additional parameters to pass to the onClick function of the list buttons.
     */
    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    @ContentChild('ttListItemTemplate')
    ttListItemTemplateRef?: TemplateRef<any>;

    /**
     * The value to filter the list with (set through `ListFilterDirective`).
     */
    filter = '';

    /**
     * The behaviour of the filter (set through `ListFilterDirective`).
     */
    filterType: ListFilterType = 'fulltext';

    /**
     * List of buttons displayed for each list item.
     */
    buttons: FormButton[] = [];

    radioChecked = '';

    onRadioCheckedChanged(value: string, item: Item) {
        this.radioChecked = value;
        this.ttRadioSelectionChanged.emit(item as TType);
    }

    onCheckboxSelectionChanged(value: '1' | '0', item: Item) {
        item.item_is_selected = value;

        this.ttDataChange.emit(this.ttData);
        this.ttCheckboxSelectionChanged.emit({ selected: value === '1', item: item as TType });
    }

    constructor(private utility: UtilityService, private state: StateService) {}

    /**
     * Returns unique value for which to track the items in the list with.
     *
     * @param _ index of the item in the list.
     * @param item hte item in the list.
     * @returns the unique identifier to use.
     */
    public trackItems(_: number, item: Item) {
        return item.item_id;
    }

    public onClick(event: MouseEvent, item: Item) {
        this.ttClick.emit({ item: item, event: event });
    }

    /**
     * Handles click event for form button to trigger the click handler for the list button.
     *
     * @param event the form button event for the button which was clicked.
     * @param button the button which was clicked.
     * @param item the item which the button was clicked on for.
     */
    public onFormButtonClick(event: FormButtonEvent, button: FormButton, item: Item) {
        if (button.id === '$newtab' && !!item.item_state) {
            this.state.newTab(item.item_state, '' + item.item_parms);
        } else {
            let listButton = this.ttButtons?.find((btn) => btn.id === button.id);

            if (!!listButton?.onClick && listButton.onClick instanceof Function) {
                listButton.onClick({ item: item, event: event });
            }
        }
    }

    /**
     * Gets the string to use as icon for the given item.
     *
     * @param item the item to get the icon string for.
     * @returns a string of the icons to display on the list item.
     */
    public getListItemIcon(item: Item) {
        if (this.ttShowFileExtensionIcon) {
            if (item.extension) {
                return '';
            } else {
                return 'fas fa-document';
            }
        } else {
            return item.item_glyphicon;
        }
    }

    /**
     * Removes incompatible types between list button and form button.
     *
     * @param buttons list buttons to make compatible with form-buttons.
     */
    private mapFormButtons(buttons: ListButton[]) {
        this.buttons = buttons.map((button) => {
            return <FormButton>{
                ...button,
                onClick: undefined,
            };
        });

        if (this.ttNewTab && this.ttGoto && this.ttData.some((item) => !!item.item_state)) {
            this.buttons.push({
                id: '$newtab',
                icon: 'fas fa-external-link',
                type: 'secondary',
                tooltip: 'open_in_new_tab',
            });
        }
    }

    private async mapData(data: TType[]) {
        this.ttData = [];

        for (let item of data) {
            this.ttData.push(<TType>{ ...item, item_thumb: this.ttShowFileExtensionIcon ? await this.utility.getFileExtensionTypeIcon(item.extension ?? '', '32') : item.item_thumb });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttCheckboxSelection']) {
            this.ttReverse = changes['ttCheckboxSelection'].currentValue;
        }

        if (changes['ttReverse']) {
            this.ttReverse = changes['ttReverse'].currentValue;
        }

        if (changes['ttShowFileExtensionIcon']) {
            this.ttShowFileExtensionIcon = changes['ttShowFileExtensionIcon'].currentValue;
        }

        if (changes['ttHideSubtitle']) {
            this.ttHideSubtitle = changes['ttHideSubtitle'].currentValue;
        }

        if (changes['ttHideSubtext']) {
            this.ttHideSubtext = changes['ttHideSubtext'].currentValue;
        }

        if (changes['ttNewTab']) {
            this.ttNewTab = changes['ttNewTab'].currentValue;
        }

        if (changes['ttData']) {
            this.mapData(changes['ttData'].currentValue);
        }

        if (changes['ttButtons']) {
            this.mapFormButtons(changes['ttButtons'].currentValue);
        }
    }
}

/**
 * Represents a single action button displayed on a list item, comfigured through tt-list.
 */
export interface ListButton extends Omit<FormButton, 'onClick'> {
    /**
     * Handler for on click events on list item. Passes the item it was clicked on and the form-button event.
     *
     * @param param0 object containing the item clicked and the form-button event.
     */
    onClick: ({ item, event }: { item: Item; event: FormButtonEvent }) => unknown;
}
