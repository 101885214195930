(function () {
    'use strict';

    angular.module("imApp").factory("orderPurchService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 993,
                    parameters: parms || {}
                });
            },
            listDeladronPurchases: function (parms) {
                return $ihttp.post({
                    method: 994,
                    parameters: parms || {}
                });
            },
            listDisplayTypes: function (parms) {
                return $ihttp.post({
                    method: 996,
                    parameters: parms || {}
                });
            },
            listComponents: function (parms) {
                return $ihttp.post({
                    method: 995,
                    parameters: parms || {}
                });
            },
            listPurchaseHeads: function (parms) {
                return $ihttp.post({
                    method: 1000,
                    parameters: parms || {}
                });
            },
            createPurchaseOrder: function (items) {
                return $ihttp.post({
                    method: 998,
                    parameters: items
                });
            }
        };

        return service;
    }]);
})();
