(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinishList', {
        templateUrl: 'views/components/views/poFinishList/poFinishList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'poFinishListService', 'logisticService', 'ttGridFactory', 'rememberService', 'layoutService', function ($q, stateService, utilityService, translateService, modalService, poFinishListService, logisticService, ttGridFactory, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            let variableNames = {
                pofinishstatus: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                businessco_no: '',
                stockplace_id: ''
            };

            vm.model = {
                gridInProgressionReady: false,
                gridFinishedReady: false,
                tabletSize: tabletSize,
                bgPoFinishStatus: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'in_progress', color: 'primary', onClick: () => setPoFinishStatusView('0') },
                        { id: '1', label: 'finished', color: 'primary', onClick: () => setPoFinishStatusView('1') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListPoFinishes: [],
                progressInfo: {
                	label: '',
                	plabel: '',
                	field: '',
                	remField: '',
                	min: 0,
                	max: 100,
                	step: 0,
                	finishclose: ''
                },
                runProgress: ''
            };

            vm.gridInProgression = new ttGridFactory({
                rememberId: 'pofinish_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'pofinishlist_delete_pofinish',
                text: 'pofinishlist_delete_pofinish',
                func: () => progressFunc(),
                icon: 'fa-trash',
                cssClass: 'btn btn-danger im-grid-btn-xs-r',
                translate: true
            }]);

            vm.gridFinished = new ttGridFactory({
                rememberId: 'pofinish_finished_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATION SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPoFinishStatusView = function (id) {
                vm.model.bgPoFinishStatus.selected = vm.model.setting.pofinishstatus = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                poFinishListService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    setPoFinishStatusView(vm.model.setting.pofinishstatus);

                    loadStockPlaces();

                    if (vm.model.tabletSize.matches === true) {
                        if (vm.model.bgPoFinishStatus.selected === '0') {
                            loadGridInProgression();
                        } else {
                            loadGridFinished();
                        }
                    } else {
                        loadPoFinishes();
                    }

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };

            let loadPoFinishes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.pofinishstatus) !== true) return;

                return poFinishListService.listPoFinishes({ pofinishstatus: vm.model.setting.pofinishstatus }).then(function (list) {
                    angular.copy(list, vm.model.itemsListPoFinishes);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridInProgression = function () {
                vm.gridInProgression.dataTask.loadData = {
                    method: 3476,
                    parameters: vm.model.setting
                };

                vm.model.gridInProgressionReady = true;

                if (vm.gridInProgression.gridfunc !== null) vm.gridInProgression.gridfunc.read();
            };

            let loadGridFinished = function () {
                vm.gridFinished.dataTask.loadData = {
                    method: 3478,
                    parameters: vm.model.setting
                };

                vm.model.gridFinishedReady = true;

                if (vm.gridFinished.gridfunc !== null) vm.gridFinished.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'pofinishstatus':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    if (vm.model.tabletSize.matches === true) {
                        if (vm.model.bgPoFinishStatus.selected === '0') {
                            loadGridInProgression();
                        } else {
                            loadGridFinished();
                        }
                    } else {
                        loadPoFinishes();
                    }
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();

                        if (vm.model.tabletSize.matches === true) {
                            if (vm.model.bgPoFinishStatus.selected === '0') {
                                loadGridInProgression();
                            } else {
                                loadGridFinished();
                            }
                        } else {
                            loadPoFinishes();
                        }
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');

                        if (vm.model.tabletSize.matches === true) {
                            if (vm.model.bgPoFinishStatus.selected === '0') {
                                loadGridInProgression();
                            } else {
                                loadGridFinished();
                            }
                        } else {
                            loadPoFinishes();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                let doStep = function () {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) return;

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].p2_pofinish_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        poFinishListService.deleteItems({ p2_pofinish_keyno: items[step].p2_pofinish_keyno }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let progressFunc = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                let currentData = vm.gridInProgression.gridfunc.getAllRows();
                let selectedRows = [];

                angular.forEach(currentData, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedRows.push(item);
                });

                if (selectedRows.length === 0) return;

                vm.model.progressInfo.max = selectedRows.length;
                vm.activateProgress(selectedRows);

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    modalService.showProgress(vm.model.progressInfo).then(function (value) {
                        vm.model.runProgress = value;

                        if (vm.model.runProgress === 'ABORT') return;
                        if (vm.model.runProgress === 'FINISH') loadGridInProgression();
                    });
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPoFinishStatus = function (value) {
                vm.model.bgPoFinishStatus.selected = value;
                vm.model.setting.pofinishstatus = vm.model.bgPoFinishStatus.selected;

                rememberFunc('pofinishstatus');

                if (vm.model.tabletSize.matches === true) {
                    if (vm.model.bgPoFinishStatus.selected === '0') {
                        loadGridInProgression();
                    } else {
                        loadGridFinished();
                    }
                } else {
                    loadPoFinishes();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON WINDOW RESIZE FUNCTION

            let onWindowResize = function () {
                if (vm.model.tabletSize.matches === true) {
                    loadGridInProgression();
                } else {
                    loadPoFinishes();
                }
            };

            // #endregion ON WINDOW RESIZE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();

                vm.model.tabletSize.addEventListener('change', onWindowResize);
                onWindowResize();
            };

            // #endregion ON INIT FUNCTION

            // #region ON DESTROY FUNCTION

            vm.$onDestroy = function () {
                vm.model.tabletSize.removeEventListener('change', onWindowResize);
            };

            // #endregion ON DESTROY FUNCTION

        }]
    });
})();
