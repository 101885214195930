(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('furnitureFabricsS', {
        templateUrl: 'views/components/views/furnitureFabricsS/furnitureFabricsS.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'furnitureFabricsSService', 'rememberService', function (stateService, utilityService, furnitureFabricsSService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                gb_furniturefabricss_status: ''
            };

            vm.model = {
                bgFurnitureFabricssStatus: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'gb_furniturefabricss_status_active', color: 'primary', onClick: () => setFurnitureFabricssStatusView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'gb_furniturefabricss_status_notactive', color: 'primary', onClick: () => setFurnitureFabricssStatusView('NOTACTIVE') },
                        { id: 'ALL', label: 'gb_furniturefabricss_status_all', color: 'primary', onClick: () => setFurnitureFabricssStatusView('ALL') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListFurnitureFabrics: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setFurnitureFabricssStatusView = function (id) {
                vm.model.bgFurnitureFabricssStatus.selected = vm.model.setting.gb_furniturefabricss_status = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await furnitureFabricsSService.getSetting())[0];

                setFurnitureFabricssStatusView(vm.model.setting.gb_furniturefabricss_status);
                loadFurnitureFabrics();
            }

            async function loadFurnitureFabrics() {
                if (utilityService.validateParmsValue(vm.model.setting.gb_furniturefabricss_status) !== true) return;
                
                vm.model.itemsListFurnitureFabrics = [];
                
                if (vm.model.setting.gb_furniturefabricss_status === 'ACTIVE') {
                    vm.model.itemsListFurnitureFabrics = (await furnitureFabricsSService.listFurnitureFabrics({
                        isactive: '1',
                        show_all: '-'
                    }));
                } else if (vm.model.setting.gb_furniturefabricss_status === 'NOTACTIVE') {
                    vm.model.itemsListFurnitureFabrics = (await furnitureFabricsSService.listFurnitureFabrics({
                        isactive: '0',
                        show_all: '-'
                    }));
                } else if (vm.model.setting.gb_furniturefabricss_status === 'ALL') {
                    vm.model.itemsListFurnitureFabrics = (await furnitureFabricsSService.listFurnitureFabrics({
                        isactive: '1',
                        show_all: 'ALL'
                    }));
                }
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            function rememberFunc(id) {
                Object.keys(variableNames).forEach((key) => {
                    switch (key) {
                        case 'gb_furniturefabricss_status':
                            variableNames[key] = key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_furniturefabricss_status':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            }

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgFurnitureFabricssStatus = function (value) {
                vm.model.bgFurnitureFabricssStatus.selected = value;
                vm.model.setting.gb_furniturefabricss_status = vm.model.bgFurnitureFabricssStatus.selected;

                rememberFunc('gb_furniturefabricss_status');
                loadFurnitureFabrics();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'furniturefabrics':
                        go({ furniturefabrics_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
