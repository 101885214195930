(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('salaryTypeLang', {
        templateUrl: 'views/components/views/salaryTypeLang/salaryTypeLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'salaryTypeLangService', function ($stateParams, stateService, salaryTypeLangService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let salaryTypeId = $stateParams.salarytype_id;

            vm.model = {
                inputListLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadTranslations = function () {
                salaryTypeLangService.listTranslations({ salarytype_id: salaryTypeId }).then(function (list) {
                    angular.copy(list, vm.model.inputListLanguages);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                salaryTypeLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTranslations();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
