import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { Observable, debounceTime, switchMap, shareReplay, filter } from 'rxjs';
import { GridService } from '../../grid.service';
import { LookupCellEditorParams } from '../../grid.types';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';
import { ListboxStyle } from '@app/core/components/listbox/listbox.component';
import { PopupService } from '@app/core/services/popup.service';
import { DataTaskService } from '@app/core/services/data-task.service';

/**
 * Custom cell-editor component for lookup (search) cells.
 */
@Component({
    selector: 'tt-lookup-cell-editor',
    templateUrl: './lookup-cell-editor.component.html',
    styleUrls: ['./lookup-cell-editor.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class LookupCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    /**
     * Cell editor params passed from ag-grid.
     */
    public params?: LookupCellEditorParams;

    /**
     * Form-control for the search field.
     */
    public searchControl: FormControl<string | { [key: string]: string }> = new FormControl();

    /**
     * Whether the value in the cell has been edited since editing started.
     */
    public hasBeenEdited: boolean = false;

    /**
     * Whether change-event emitting should be suppressed, like when selecting an option.
     */
    private suppressValueChanges = false;

    /**
     * List of options to display from the search.
     */
    public filteredOptions?: Observable<{ [key: string]: string }[]>;

    /**
     * Whether forced search was triggered since the last change.
     */
    public _forcedSearch = false;

    /**
     * Element reference to the input element.
     */
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    /**
     * Reference to the trigger for the listbox displaying search options.
     */
    @ViewChild(MatAutocompleteTrigger)
    public autoComplete?: MatAutocompleteTrigger;

    /**
     * Whether the edited value in the cell should be cancelled or not.
     */
    private cancelEdit = false;

    /**
     * Forces search on key arrow down, closes listbox on key arrow up. Cancels editing when escape key is pressed.

     * @param event the keydown event that happened on the component.
     */
    @HostListener('keydown', ['$event'])
    public async keydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            if (this.autoComplete?.panelOpen) {
                this.autoComplete.closePanel();
            } else {
                this.params?.api.stopEditing(true);
                this.params?.api.setFocusedCell(this.params.rowIndex, this.params.field);
            }
        } else if (typeof this.searchControl?.value === 'string' && this.searchControl?.value.trim() === '' && event.key === 'ArrowDown') {
            this.forceSearch();
        } else if (event.ctrlKey && event.key === 'ArrowUp') {
            this.autoComplete?.closePanel();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();

            if (this.searchControl.value === '+') {
                const response = await this.datatask.Post(2837, { datatask: this.params?.options.dataTask?.loadData, field: `lookup:${this.params?.colDef.colId}`, row: this.params?.data, gridRemId: this.params?.options.dataTask?.rememberId });

                if (!!response[0].item_state) {
                    await this.popup.openPopup(response[0].item_state + (!!response[0].item_parms ? '/' + response[0].item_parms : ''), { rememberId: !!this.params?.options.dataTask?.rememberId ? `${this.params?.options.dataTask?.rememberId}.popup:${this.params?.colDef.colId}` : undefined });

                    await new Promise<void>((resolve) => {
                        window.addEventListener('message', (event) => {
                            if (Object.hasOwn(event.data, 'type') && event.data.type === 'ttData') {
                                // this.params?.api.setFocusedCell(this.params.rowIndex, this.params.field);
                                const data = event.data.data;

                                this.setDataThroughRelations(data);

                                resolve();
                            }
                        });
                    });
                }
            }
        } else if (event.key === 'Tab') {
            this.cancelEdit = true;
        }

        setTimeout(() => {
            if (this.suppressValueChanges !== true) {
                this.params?.onKeyDown(event);
            }
        });
    }

    /**
     * Styles for the elements in the template.
     */
    public style: Style = {
        input: {},
        listbox: {},
        option: {},
    };

    public listboxStyle: Partial<ListboxStyle> = {
        selectedOption: {
            background: 'var(--tt-primary-color)',
            color: 'var(--tt-primary-text-color)',
        },
    };

    public id = {
        wrapper: crypto.randomUUID(),
        input: crypto.randomUUID(),
    };

    constructor(layoutService: LayoutService, private gridService: GridService, private popup: PopupService, private datatask: DataTaskService) {
        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['input'].fontSize = info.fontSizes.textSize;
                this.style['option'].fontSize = info.fontSizes.textSize;
                this.style['option'].minHeight = `calc(${info.fontSizes.textSize} + 5px)`;
                this.style['listbox'].margin = 'var(--ag-grid-size) 0';
                this.style['listbox'].minWidth = '25rem';
            }
        });
    }

    /**
     * Sets the same value again to trigger a change and thus a search to be called.
     */
    private forceSearch() {
        if (this._forcedSearch === true) return;
        this._forcedSearch = true;

        setTimeout(() => this.searchControl.setValue(this.searchControl.value), 250);
    }

    /**
     * Performs a search with the values passed in params and the given search string.
     *
     * @param search the filter-text to use for the search request.
     * @returns a list containing the result of the search request.
     */
    private async lookupSearch(search: string): Promise<{ [key: string]: string }[]> {
        if (search === '' && !this._forcedSearch) {
            this.autoComplete?.closePanel();
        } else if (this.params?.method && !isNaN(Number(this.params.method)) && this.params.field && this.params?.node.data) {
            return this.gridService.lookupSearch(Number(this.params.method), this.params.field, search, this.params?.node.data);
        }

        this._forcedSearch = false;

        return [];
    }

    /**
     * Sets the selected value and stops the cell editing.
     *
     * @param event the event transmitted for the selected option.
     */
    public onOptionSelected(event: ListboxSelectEvent<{ [key: string]: string }>) {
        this.suppressValueChanges = true;
        this.searchControl.setValue(event.item, { onlySelf: true, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false });

        setTimeout(() => {
            this.params?.api.stopEditing();
            this.params?.api.setFocusedCell(this.params.rowIndex, this.params.field);

            if (event.event.type === 'keydown') {
                this.params?.onKeyDown(event.event as KeyboardEvent);
            }
        });
    }

    public agInit(params: LookupCellEditorParams): void {
        this.params = params;
        this.style['input'].width = this.params.column.getActualWidth() + 'px';

        this.searchControl.setValue(this.params.value ?? '');
        this.searchControl.valueChanges.subscribe((value) => {
            if (!value && !this._forcedSearch) {
                this.autoComplete?.closePanel();
            } else if (!!value) {
                this._forcedSearch = false;
            }
        });

        this.filteredOptions = this.searchControl.valueChanges.pipe(
            filter(() => !this.suppressValueChanges),
            debounceTime(250),
            switchMap((result) => (typeof result === 'string' ? this.lookupSearch(result) : this.lookupSearch(result[this.params!.colDef.colId!]))),
            shareReplay(1)
        ) as Observable<{ [key: string]: string }[]>;
    }

    public setValue(value: string) {
        this.hasBeenEdited = true;
        this.searchControl.setValue(value, { emitEvent: false });
    }

    isCancelAfterEnd(): boolean {
        return this.cancelEdit || this.searchControl.value === '+';
    }

    private setDataThroughRelations(data: any) {
        if (!!this.params && this.params.relations.every((relation) => relation.key !== this.params!.colDef!.colId!) && Object.keys(data).some((key) => key === 'item_name')) {
            this.params?.node.setDataValue(this.params!.colDef!.colId!, this.params.parseValue(data['item_name']));
        }

        if (!!data) {
            for (let { key: colKey, value: searchKey } of this.params!.relations) {
                let value = data?.[searchKey] ?? '';

                if (colKey === this.params!.colDef.colId) {
                    this.params?.node.setDataValue(colKey, this.params.parseValue(data[searchKey]));
                } else {
                    let colDef = this.params?.api.getColumnDef(colKey);

                    if (colDef?.cellDataType === 'number') {
                        if (value !== '' && +value !== null && !isNaN(+value)) {
                            this.params?.node.setDataValue(colKey, +value);
                        } else {
                            this.params?.node.setDataValue(colKey, null);
                        }
                    } else {
                        this.params?.node.setDataValue(colKey, data[searchKey]);
                    }
                }
            }
        }
    }

    public getValue() {
        if (typeof this.searchControl.value === 'object') {
            this.setDataThroughRelations(this.searchControl.value);

            return this.params?.node.data[this.params!.colDef.colId!];
        }

        return this.params!.parseValue(this.searchControl.value);
    }

    // public ngAfterViewInit(): void {
    //     setTimeout(() => this.inputRef?.nativeElement.select(), 10);
    // }
    ngAfterViewInit() {
        window.setTimeout(() => {
            if (!!this.params && this.inputRef && (this.params.eventKey ?? '').length === 1) {
                this.inputRef.nativeElement.value = this.params.eventKey;
                this.inputRef?.nativeElement.focus();
            } else {
                this.inputRef?.nativeElement.focus();
                this.inputRef?.nativeElement.select();
            }
        });
    }
}
