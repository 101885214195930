(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinish', {
        templateUrl: 'views/components/views/poFinish/poFinish.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishService', function ($stateParams, poFinishService) {
            const vm = this;
            let p2PofinishKeyno = $stateParams.p2_pofinish_keyno;

            vm.model = {
                menu: {}
            };

            let loadMenu = function () {
                poFinishService.getMenu({ p2_pofinish_keyno: p2PofinishKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
