(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderLineCredit', {
        templateUrl: 'views/components/views/orderLineCredit/orderLineCredit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'orderLineCreditService', 'logisticService', 'typeaheadService', 'translateService', function ($stateParams, stateService, utilityService, modalService, orderLineCreditService, logisticService, typeaheadService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var orderLineKeyno = $stateParams.orderline_keyno;

            var translations = {
                orderlinecredit_save_show_title: 'Varsel',
                orderlinecredit_save_show_ok_label: 'OK',
                orderlinecredit_approve_show_title: 'Varsel',
                orderlinecredit_approve_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'orderhead':
                        stateService.go(state, {
                            order_internal_no: vm.model.edit.order_internal_no
                        });
                        break;
                    case 'pictures':
                        stateService.go(state, {
                            argtype: 'orderline_keyno',
                            argvalue: vm.model.edit.orderline_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                orderOrderNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderhead' } }
                ],
                edit: {},
                selectListCauses: [],
                selectListStockPlaces: [],
                lockedSave: false,
                lockedApprove: false
            };

            vm.options = {
                quantity: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            orderLineCreditService.getOrderLineCredit({ orderline_keyno: orderLineKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);

                loadStocks();
            });

            orderLineCreditService.listOrderLineCreditCauses().then(function (result) {
                angular.copy(result, vm.model.selectListCauses);
            });

            var loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.businesssite_keyno, true) !== true) return;

                logisticService.listStocks({
                    businessco_no: vm.model.edit.businessco_no,
                    businesssite_keyno: vm.model.edit.businesssite_keyno
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            var loadStockPlace = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id_to, true) !== true) return;

                logisticService.getStockPlace({ stockplace_id: vm.model.edit.stockplace_id_to }).then(function (response) {
                    vm.model.edit.floating_location = response[0].floating_location;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearchStockLocations = function (value) {
                return typeaheadService.lookUpStockLocations({
                    stocklocation_name_search: value || '',
                    stockplace_id: vm.model.edit.stockplace_id_to
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setStockLocationKeynoTo = function (item) {
                //vm.model.edit.stockplace_id_to = angular.isUndefined(item) ? '0' : item.item_id;
                vm.model.edit.stocklocation_name_search = angular.isUndefined(item) ? '' : item.stocklocation_name;
                vm.model.edit.stocklocation_keyno_to = angular.isUndefined(item) ? '0' : item.stocklocation_keyno;
            };

		    // #endregion SET VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'stockplace_id_to':
                        vm.model.edit.stockplace_id_to = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stockplace_id_to, true) !== true) return;

                        loadStockPlace();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderLineCreditService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.orderlinecredit_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.orderlinecredit_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	//stateService.back();		
                    }
                });
            };

            vm.approveChanges = function () {
                vm.model.lockedApprove = true;

                orderLineCreditService.approveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.orderlinecredit_approve_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.orderlinecredit_approve_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedApprove = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
