import { Injectable } from '@angular/core';
import { Attachment, MailFolder, Recipient, type Message } from '@microsoft/microsoft-graph-types';
import { GraphAuthService } from '../auth/services/graph-auth.service';

@Injectable({
    providedIn: 'root',
})
export class GraphMailService {

    constructor(private graphAuthService: GraphAuthService) { }

    private getCommaSeperatedListAsRecipients(addresslist: string): Recipient[] {
        return addresslist.split('; ').filter((address) => address.length > 3).map((recipient) => {
            return {
                emailAddress: {
                    address: recipient
                }
            }
        });
    };

    public async sendMessage({ subject, contentType = 'html', content, recipients, ccRecipients = '', bccRecipients = '', saveToSentItems = true, attachments, userId }: { subject: string; contentType: 'html' | 'text'; content: string; recipients: string; ccRecipients: string; bccRecipients: string; saveToSentItems: boolean; attachments?: TTAttachment[], userId?: string }) {
        const mail: { message: Message, saveToSentItems: boolean } = {
            saveToSentItems: saveToSentItems,
            message: {
                subject: subject,
                body: {
                    contentType: contentType,
                    content: content,
                },
                toRecipients: this.getCommaSeperatedListAsRecipients(recipients),
            }
        }

        if (ccRecipients) {
            mail.message.ccRecipients = this.getCommaSeperatedListAsRecipients(ccRecipients);
        };

        if (bccRecipients) {
            mail.message.bccRecipients = this.getCommaSeperatedListAsRecipients(bccRecipients);
        };

        if (attachments) {
            mail.message.attachments = attachments.map((attachment) => {
                return {
                    "@odata.type": "#microsoft.graph.fileAttachment",
                    "name": attachment.fileName,
                    "contentType": attachment.contentType,
                    "contentBytes": attachment.contentBytes
                }
            });
        }

        let apiEndpoint = '/me/sendMail';

        if (userId) {
            apiEndpoint = `/users/${userId}/sendMail`;
        }

        return (await this.graphAuthService.getGraphClient())?.api(apiEndpoint).post(mail);
    }

    public async markAsSpam(messageId: string, recipientAddress: string) {
        if (!messageId || !recipientAddress) throw Error('Invalid arguments');
        const message = {
            moveToJunk: true
        };

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${recipientAddress}/messages/${messageId}/markAsJunk`).version('beta').post(message);
    }

    /**
     * Gets the message with the given message id.
     * 
     * @param messageId the id of the message to get.
     * @returns a promise with the message.
     */
    public async getMessage(messageId: string, userId?: string) {
        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}`).get();
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}`).get();
    }

    /**
     * Updates the message with the given message id. Updates only the given parameters with exists in Message type.
     * 
     * @param messageId the id of the message to update.
     * @param params the parameters of the message to update.
     * @returns a promise.
     */
    public async updateMessage(messageId: string, params: Partial<Message>, userId?: string) {
        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}`).update(params);
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}`).update(params);
    }

    /**
     * Moved the message with the given message id to the deleted items folder.
     * 
     * @param messageId the id of the message to move to deleted items.
     * @returns the message moved.
     */
    public async moveMessageToDeletedItems(messageId: string, userId?: string): Promise<Message> {
        const message = {
            destinationId: 'deleteditems'
        };

        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}/move`).post(message);
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}/move`).post(message);
    }

    /**
     * Moves the message with the given message id to the folder with the given folder id or `well-known` name.
     * 
     * @param messageId the id of the message to move.
     * @param folderId the id of the folder to move the message to.
     * @returns
     */
    public async moveMessageToFolder(messageId: string, folderId: string, userId?: string) {
        const message = {
            destinationId: folderId
        };

        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}/move`).post(message);
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}/move`).post(message);
    }

    /**
     * Deletes the message with the given message id.
     * 
     * @param messageId the id of the message to delete.
     * @returns a void promise which resolves if deletion went through.
     */
    public async deleteMessage(messageId: string, userId?: string): Promise<void> {
        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}`).delete();
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}`).delete();
    }

    public async searchMessages({ folderId, limit = 20, userId, filter = '', searchtext = '' }: { folderId?: string; limit: number; userId?: string; filter: string; searchtext: string; }) {
        let apiEndpoint = `/me`;

        if (userId) {
            apiEndpoint = `/users/${userId}`
            //return (await this.graphAuthService.getGraphClient())?.api(`/me/mailFolders/${folderId}/messages`).top(limit).get();
        }

        if (folderId) {
            apiEndpoint += `/mailFolders/${folderId}`
        }

        return (await this.graphAuthService.getGraphClient())?.api(`${apiEndpoint}/messages`).search(searchtext).filter(filter).top(limit).get();
    }


    /**
     * Retrieves messages from the mailfolder with the given folder id.
     * 
     * @param folderId the id of the mailfolder to retreieve message from.
     * @param limit the number of messages to get in the request, defaults to 50.
     * @returns {Promise<{value: Message[]; '@odata.nextLink'?: string; '@odata.context': string}>} object containing values and links to access remaining context and values.
     */
    public async getMessagesFromFolder({ folderId, limit = 20, userId, filter = '', orderBy = '', search = '' }: { folderId: string; limit: number; userId?: string; filter: string, orderBy: string, search: string }): Promise<{ value: Message[]; '@odata.nextLink'?: string; '@odata.context': string }> {
        let apiEndpoint = `/me/mailFolders/${folderId}/messages`;

        if (userId) {
            apiEndpoint = `/users/${userId}/mailFolders/${folderId}/messages`
            //return (await this.graphAuthService.getGraphClient())?.api(`/me/mailFolders/${folderId}/messages`).top(limit).get();
        }

        if (search) {
            return (await this.graphAuthService.getGraphClient())?.api(apiEndpoint).top(limit).search(search).get();
        }
        let date = new Date();
        date.setFullYear(2000);

        let filters = filter.split(' ')

        return (await this.graphAuthService.getGraphClient())?.api(apiEndpoint).filter(filter).orderby((!!orderBy ? orderBy + ',' : '') + 'sentDateTime desc').top(limit).get();
        //return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/mailFolders/${folderId}/messages`).top(limit).filter(filter).get();
    }

    public async getAllMessages({ limit = 20, userId, filter = '', search = '', }: { limit: number; userId?: string; filter: string, search: string }): Promise<{ value: Message[]; '@odata.nextLink'?: string; '@odata.context': string }> {
        let apiEndpoint = `/me/messages`;

        if (userId) {
            apiEndpoint = `/users/${userId}/messages`
            //return (await this.graphAuthService.getGraphClient())?.api(`/me/mailFolders/${folderId}/messages`).top(limit).get();
        }

        return (await this.graphAuthService.getGraphClient())?.api(apiEndpoint).top(limit).filter(filter).search(search).get();
        //return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/mailFolders/${folderId}/messages`).top(limit).filter(filter).get();
    }

    /**
     * Retrieves all the attachments from the message with the given message id.
     * 
     * @param messageId the id of the message to retrieve attachments for.
     * @returns a promise containing the a list of attachments and context url.
     */
    public async getAttachmentsFromMessageId(messageId: string, userId?: string): Promise<{ value: Attachment[]; '@odata.context': string; }> {
        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/messages/${messageId}/attachments`).get();
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/messages/${messageId}/attachments`).get();
    }

    /**
     * Gets the folder with the given folder-id or `well-known` name.
     * 
     * @param folderId id of the folder id to get.
     * @returns a promise containing the folder with the given folder id.
     */
    public async getMailFolder(folderId: string, userId?: string): Promise<MailFolder> {
        if (!userId) {
            return (await this.graphAuthService.getGraphClient())?.api(`/me/mailFolders/${folderId}`).get();
        }

        return (await this.graphAuthService.getGraphClient())?.api(`/users/${userId}/mailFolders/${folderId}`).get();
    }

    /**
     * Retrieves all folders at every level and maps the MailFolder list to list item objects.
     * 
     * @returns {Promise<Object[]>} a promise of all folders at every level as list item objects.
     */
    public async getAllMailFoldersAsItemList(userId?: string): Promise<Object[]> {
        let mailFolders: MailFolder[] = []
        await this.getMailFolders({ folders: mailFolders, userId: userId });

        return mailFolders.map((folder, index) => {
            return {
                orderby: index,
                isexpanded: '1',
                item_id: folder.id,
                item_keyno: folder.id,
                item_name: folder.displayName,
                item_id_parent: folder.parentFolderId,
                item_glyphicon: "fas-folder",
                item_glyphicon_color: "orange",
                child_count: folder.childFolderCount
            };
        });
    }

    /**
     * Modifies the given list of folders to include folders of all levels.
     * 
     * @param folders list of folders to add mailfolders of every level to.
     * @param folderId the id of a parent folder, used to get child folders.
     */
    private async getMailFolders({ folders, folderId, userId }: { folders: MailFolder[]; folderId?: string; userId?: string; }) {
        let endpoint = "/me/mailFolders";

        if (userId) {
            endpoint = `/users/${userId}/mailFolders`
        }

        if (folderId && !userId) {
            endpoint = `/me/mailFolders/${folderId}/childFolders`;
        } else if (folderId && userId) {
            endpoint = `/users/${userId}/mailFolders/${folderId}/childFolders`;
        }

        const response = await (await this.graphAuthService.getGraphClient())?.api(endpoint).top(200).get()

        for (let folder of response.value) {
            folders.push(folder);

            if (folder.childFolderCount > 0) {
                await this.getMailFolders({ folders: folders, folderId: folder.id, userId: userId });
            }
        }
    }


    public async getMailList(accessToken: string) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch('https://graph.microsoft.com/v1.0/me/messages', options);

        console.log('messages');
        console.dir(response);

        let client = (await response.json());

        console.dir(client);
        return client;
    }
}


interface TTAttachment {
    fileName: string;
    contentType: string;
    contentBytes: string;
}
