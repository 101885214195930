(function () {
    'use strict';

    angular.module("imApp").factory("p2TerminalService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 342,
                    parameters: parms || {}
                });
            },
            listTerminalTypes: function (parms) {
                return $ihttp.post({
                    method: 429,
                    parameters: parms || {}
                });
            },
            listNotificationTypes: function (parms) {
                return $ihttp.post({
                    method: 430,
                    parameters: parms || {}
                });
            },
            listPartDeliveryTypes: function (parms) {
                return $ihttp.post({
                    method: 431,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 343,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 344,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
