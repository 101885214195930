(function () {
    'use strict';

    angular.module("imApp").factory("stockControlSpListService", ['$ihttp', function ($ihttp) {
        let service = {
            getSettings: function (parms) {
                return $ihttp.post({
                    method: 1700,
                    parameters: parms || {}
                });
            },
            listControls: function (parms) {
                return $ihttp.post({
                    method: 1701,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
