(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('businesscos', {
        templateUrl: 'views/components/views/businesscos/businesscos.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'businesscosService', 'ttGridFactory', 'layoutService', function (stateService, businesscosService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                gridReady: true,
                setting: {},
                itemsListBusinesses: []
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w.businesscos.businessco_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'businesscos_new',
                text: 'businesscos_new',
                func: () => stateService.go('businesscoonboarding'),
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true,
                disabled: function () {
                    if (vm.model.setting.show_businesscos_new === '1') {
                		return false;
                	} else {
                		return 'hidden';
                	}
                }
            }]);

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                businesscosService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadBusinesses = function () {
                businesscosService.listBusinesses().then(function (list) {
                    angular.copy(list, vm.model.itemsListBusinesses);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3255,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadBusinesses();
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
