(function () {
    'use strict';

    angular.module("imApp").factory("factoringService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 3464,
                    parameters: parms || {}
                });
            },
            listAccounts: function (parms) {
                return $ihttp.post({
                    method: 3466,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3465,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
