(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('kpiComps', {
        templateUrl: 'views/components/views/kpiComps/kpiComps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ttGridFactory', function (stateService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'kpicomp_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'kpicomps_new',
                text: 'kpicomps_new',
                func: () => stateService.go('kpicomp', { kpicomp_keyno: '0' }),
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true,
                //disabled: function () { return; }
                //disabled: function () {
                //	if (vm.model.setting.show === '1') {
                //		return false;
                //	} else {
                //		return 'hidden';
                //	}
                //}
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3513,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
