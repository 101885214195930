(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('factorings', {
        templateUrl: 'views/components/views/factorings/factorings.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['ttGridFactory', function (ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_factorings_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3463,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
