import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentBaseComponent } from '@app/core/components/component-base/component-base.component';
import { IMainMenuItem, MainMenuService } from './mainmenu.service';
import { Item } from '@app/core/components/listbox/listbox.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Base64UrlService } from '@app/services/base64url.service';
import { LayoutService } from '@app/core/services/layout.service';
import { AppConfigService } from '@app/services';
import { GridstackComponent, NgGridStackOptions } from 'gridstack/dist/angular';
import { DashboardService } from '@app/core/components/webpagemenu/webpagemenu-dashboard/dashboard.service';

@Component({
    selector: 'tt-mainmenu',
    templateUrl: './mainmenu.component.html',
    styleUrls: ['./mainmenu.component.css'],
})
export class MainMenuComponent extends ComponentBaseComponent implements OnInit, OnDestroy {
    /**
     * Initial page load for mainmenu.
     */
    public get?: IMainMenuItem;

    /**
     * Suggestion/history menus for the user.
     */
    public menuSuggestions: Item[] = [];

    /**
     * Matrix over all menu items for each webpagemenu to displayed on large screens.
     */
    public allMenus: Item[][] = [];

    /**
     * Only top level menu items for mobile-screens.
     */
    public menus: Item[] = [];

    /**
     * Whether the dashboard data is ready.
     */
    public dashboardReady?: Promise<void[]>;

    /**
     * List over all webpage-dashboards.
     */
    public webpageDashboards: { webpagename: string; webpagetitle: string }[] = [];

    /**
     * Gridstack configuration for the dashboard.
     */
    public gridOptions: NgGridStackOptions;

    /**
     * The gridstack component.
     */
    @ViewChild(GridstackComponent)
    public gridComp?: GridstackComponent;

    /**
     * If matches, the media-screen is larger than a mobile screen.
     */
    public mobileQuery: MediaQueryList;

    constructor(private mainmenu: MainMenuService, private dialog: MatDialog, private base64: Base64UrlService, layout: LayoutService, private app: AppConfigService, private dashboard: DashboardService) {
        super();

        this.mobileQuery = layout.getMediaQueries().tablet;

        if (app.settings.user?.portalUserKeynoEdit === 142) {
            this.gridOptions = {
                sizeToContent: true,
                cellHeightThrottle: 0,
                animate: true,
                margin: 20,
                class: 'well',
                cellHeight: '5rem',
                disableResize: false,
                float: false,
                staticGrid: false,
                // // @ts-ignore
                // children: [{ id: 'fi', selector: 'tt-webpagemenu-dashboard', input: { name: 'crmmenu' } }],
                columnOpts: {
                    layout: 'none',
                    breakpoints: [
                        { w: 700, c: 3 },
                        { w: 1000, c: 6 },
                    ],
                },
            };
        } else {
            this.gridOptions = {
                sizeToContent: true,
                cellHeightThrottle: 0,
                animate: true,
                margin: 20,
                class: 'well',
                cellHeight: '5rem',
                disableResize: true,
                float: false,
                staticGrid: true,
                // // @ts-ignore
                // children: [{ id: 'fi', selector: 'tt-webpagemenu-dashboard', input: { name: 'crmmenu' } }],
                columnOpts: {
                    layout: 'none',
                    breakpoints: [
                        { w: 700, c: 3 },
                        { w: 1000, c: 6 },
                    ],
                },
            };
        }
    }

    public onTabChange(event: string) {
        console.log('event :>> ', event);

        if (event === 'dashboard') {
            setTimeout(() => {
                console.log('this.gridComp :>> ', this.gridComp);
                this.gridComp?.grid?.onResize(document.body.clientWidth);
            });
        }
    }

    onWidgetEdit(event: boolean, name: string) {
        console.log(this.gridComp);

        const node = this.gridComp?.grid?.engine.nodes.find((node) => node.id === name);
        console.log('node :>> ', node);
        if (!!node) {
            const gridStackItem = this.gridComp?.el.querySelector('gridstack-item[gs-id="' + name + '"]');
            this.gridComp?.grid?.update(gridStackItem as any, { ...node, noMove: event });
            // node.noMove = event;
        }
    }

    private async loadMainmenuGet() {
        this.get = (await this.mainmenu.getMainMenu())[0];

        if (this.get.show_gdpr_message === '1') {
            this.dialog.open(GdprDialogComponent, {
                data: { title: this.base64.urlDecode(this.get.gdpr_title), message: this.base64.urlDecode(this.get.gdpr_message) },
                disableClose: true,
                closeOnNavigation: false,
                maxWidth: '130rem',
            });
        }
    }

    private async loadMenuSuggestions() {
        this.menuSuggestions = (await this.mainmenu.listMyMenus({ webpage_name: 'mainmenu' })) as unknown as Item[];
    }

    private async loadAllMenus() {
        this.allMenus = (await this.mainmenu.listWebPageMenuItems()) as unknown as Item[][];
    }

    private async loadMainMenuMenus() {
        this.menus = (await this.mainmenu.getMainMenuMenus()) as unknown as Item[];
        this.loadWebpageDashboards();
    }

    private async loadWebpageDashboards() {
        const response = await this.dashboard.getWebpageDashboardWidgetKeynos();

        for (let [key, _] of Object.entries(response)) {
            const webpagetitle = this.menus.find((item) => item['webpage_name'] === key)?.item_name;

            this.webpageDashboards.push({ webpagename: key, webpagetitle: webpagetitle ?? key });
        }

        this.webpageDashboards = this.webpageDashboards.sort((a, b) => {
            const orderbyA = this.menus.find((menu) => menu['webpage_name'] === a.webpagename)!.orderby;
            const orderbyB = this.menus.find((menu) => menu['webpage_name'] === b.webpagename)!.orderby;

            return +orderbyA! - +orderbyB!;
        });
    }

    private async checkVersion() {
        try {
            const data = await this.mainmenu.checkVersion(this.app.settings.version);

            if (data?.force_reload === '1') {
                window.location.reload();
            }
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    async ngOnInit(): Promise<void> {
        this.checkVersion();
        this.loadAllMenus();
        this.loadMenuSuggestions();

        await Promise.all([this.loadMainmenuGet(), this.loadMainMenuMenus()]);

        setTimeout(() => this.gridComp?.grid?.onResize(document.body.clientWidth), 50);
    }

    ngOnDestroy(): void {}
}

@Component({
    selector: 'tt-gdpr-dialog',
    template: ` <h2 matDialogTitle class="tt-secondary-background">{{ data.title }}</h2>
        <mat-dialog-content>
            <div [innerHTML]="data.message" style="font-size: var(--tt-font-size); color: var(--tt-body-text-color); font-family: var(--tt-body-font-family); padding: 3rem 0"></div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <tt-button style="min-width: 10rem" ttText="gdpr_approve" (ttClick)="approveGdpr()" [ttLoading]="saving" ttLoadingText=""></tt-button>
        </mat-dialog-actions>`,
})
export class GdprDialogComponent {
    public saving = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }, private mainmenu: MainMenuService, public dialogRef: MatDialogRef<GdprDialogComponent>) {}

    public async approveGdpr() {
        this.saving = true;

        try {
            await this.mainmenu.approveGdpr();
            this.dialogRef.close();
        } finally {
            this.saving = false;
        }
    }
}

//             let initModel = function () {
//                 vm.model = {
//                     name: stateService.getCurrentName(),
//                     miniMobileSize: window.matchMedia('(min-width: 768px)'),
//                     mobileSize: window.matchMedia('(min-width: 992px)'),
//                     filterValueButtons: [
//                         { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.menuitem_filtervalue = '' },
//                         { id: 'filter', icon: 'fa fa-filter', color: 'success', type: 'success', onClick: function () { return; } }
//                     ],
//                     globalSearchTextButtons: [
//                         { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetGlobalSearchValue } },
//                         { id: 'filter', icon: 'fa fa-binoculars', color: 'success', type: 'success', onClick: () => loadGlobalAll() }
//                     ],
//                     setting: {
//                         show_gdpr_message: '0',
//                         gdpr_message: '',
//                         gdpr_title: '',
//                         menuitem_filtervalue: '',
//                         show_global_search: '',
//                         global_searchtext: ''
//                     },
//                     menus: [],
//                     itemsListMyMenus: [],
//                     criticalProcesses: [],
//                     isHtml: true,
//                     lockedApprove: false,
//                     searchResult: [],
//                     searchModalOpen: false
//                 };

//                 vm.id = {
//                     modal: uuid(),
//                     searchingGlobal: uuid(),
//                     searchingGlobalMobile: uuid()
//                 };
//             }

//             // #endregion BUTTON LIST FUNCTIONS

//             // #region SAVE BUTTON FUNCTION

//             // #region ANGULAR FUNCTIONS

//             vm.$onInit = function () {
//                 initModel();

//                 mainMenuService.getMainMenu().then(onGetMainMenu);

//                 mainMenuService.listMyMenus({ webpage_name: stateService.getCurrentName() }).then(onListMyMenus);

//                 p2NotificationsService.loadUnreadNotifications().then(onLoadUnreadNotifications);

//                 loadWebPageMenuAll();

//                 getCriticalProcesses();

//                 document.addEventListener('click', toggleModalOnEvent);

//                 setModalPosition();

//                 mainMenuService.checkVersion(module.version).then(function (data) {
//                     if (data?.[0]?.force_reload && data[0].force_reload === '1') window.location.reload(true);
//                 });

//                 translateService.translateBatch(translations).then(onTranslate);

//                 layoutService.onLayoutChanged(onDestroy, onLayoutChanged);
//             };

//             // #endregion ANGULAR FUNCTIONS

//             // #region DESTROY FUNCTION

//             vm.$onDestroy = function () {
//                 document.removeEventListener('click', toggleModalOnEvent);
//                 angular.forEach(onDestroy, function (fn) {
//                     if (angular.isFunction(fn) === true) {
//                         fn();
//                     }
//                 });
//             };

//             // #endregion DESTROY FUNCTION

//         }]
//     });
// })();

// import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
// import { ILayoutChangedEventArgs, LayoutService } from '../../../core/services/layout.service';
// import { ModalService } from '../../../core/services/modal.service';
// import { INotification, NotificationsService } from '../../../core/services/notifications.service';
// import { RememberService } from '../../../core/services/remember.service';
// import { StateService } from '../../../core/services/state.service';
// import { TranslateService } from '../../../core/services/translate.service';
// import { IListWebPageMenuAllOptions, MainMenuService } from './mainmenu.service';
// import { Subscription } from 'rxjs';
// import { filter } from 'rxjs/operators';
// import { v4 as uuid } from 'uuid';
// import { ComponentBaseComponent } from '../../../core/components/component-base/component-base.component';
// import { AppSettingsService } from '../../../core/services/app-settings.service';

// @Component({
//   selector: 'tt-mainmenu',
//   templateUrl: './mainmenu.component.html',
//   styleUrls: ['./mainmenu.component.css']
// })
// export class MainMenuComponent extends ComponentBaseComponent implements OnInit, OnDestroy {
//     // #region VARIABLES & DEFINITIONS
//     version = '';

//     onDestroySubscriptions: Subscription[] = [];

//     variableNames: Record<string,string> = {
//         show_suggestions: ''
//     };

//     translations: Record<string,string> = {
//         error: '',
//         ok: ''
//     };

//     model: any = {};
//     style: any = {};
//     id: any = {};

//     p2NotificationsListUnread: INotification[] = [];

//     constructor(
//         private stateService: StateService,
//         private modalService: ModalService,
//         private translateService: TranslateService,
//         private mainMenuService: MainMenuService,
//         private notificationsService: NotificationsService,
//         private rememberService: RememberService,
//         private layoutService: LayoutService,
//         private appSettingsService: AppSettingsService
//     ) {
//         super();

//         this.version = this.appSettingsService.settings.version;
//     }

//     ngOnInit() {
//         this.initModel();
//         this.loadData();
//         this.setupEventListeners();
//     }

//     ngOnDestroy() {
//         this.cleanupEventListeners();
//         this.onDestroySubscriptions.forEach(sub => sub.unsubscribe());
//     }

//     // #endregion VARIABLES & DEFINITIONS

//     // #region INIT MODEL

//     private initModel() {
//         console.log('initModel');

//         this.model = {
//             name: this.stateService.getCurrentName(),
//             miniMobileSize: window.matchMedia('(min-width: 768px)'),
//             mobileSize: window.matchMedia('(min-width: 992px)'),
//             filterValueButtons: [
//                 {
//                     id: 'erase',
//                     icon: 'fa fa-solid fa-eraser',
//                     color: 'danger',
//                     type: 'danger',
//                     onClick: () => (this.model.setting.menuitem_filtervalue = '')
//                 },
//                 {
//                     id: 'filter',
//                     icon: 'fa fa-filter',
//                     color: 'success',
//                     type: 'success',
//                     onClick: () => { }
//                 }
//             ],
//             globalSearchTextButtons: [
//                 {
//                     id: 'erase',
//                     icon: 'glyphicon glyphicon-erase',
//                     color: 'danger',
//                     type: 'danger',
//                     onClick: () => this.resetGlobalSearchValue()
//                 },
//                 {
//                     id: 'filter',
//                     icon: 'fa fa-binoculars',
//                     color: 'success',
//                     type: 'success',
//                     onClick: () => this.loadGlobalAll()
//                 }
//             ],
//             setting: {
//                 show_gdpr_message: '0',
//                 gdpr_message: '',
//                 gdpr_title: '',
//                 menuitem_filtervalue: '',
//                 show_global_search: '',
//                 global_searchtext: '',
//                 show_suggestions: ''
//             },
//             menus: [],
//             itemsListMyMenus: [],
//             criticalProcesses: [],
//             isHtml: true,
//             lockedApprove: false,
//             searchResult: [],
//             searchModalOpen: false,
//             breadcrumbsView: 'toggle' // Assuming default value
//         };

//         this.style = {
//             search: {
//                 top: ''
//             },
//             list: {
//                 width: '100%'
//             },
//             modal: {
//                 position: 'absolute',
//                 top: '93px',
//                 right: '10px',
//                 width: '30vh',
//                 backgroundColor: 'white',
//                 borderRadius: '6px',
//                 pointerEvents: 'all',
//                 maxHeight: '40vh',
//                 maxWidth: '90vw',
//                 overflowY: 'scroll',
//                 zIndex: '1000000000000000'
//             },
//             history: {
//                 width: '25px',
//                 height: '16px',
//                 fontSize: '12px',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center'
//             }
//         };

//         this.id = {
//             modal: uuid(),
//             searchingGlobal: uuid(),
//             searchingGlobalMobile: uuid()
//         };
//     }

//     // #endregion INIT MODEL

//     // #region LOAD DATA

//     private loadData() {
//         this.loadMainMenu();
//         this.listMyMenus();
//         this.loadUnreadNotifications();

//         this.loadWebPageMenuAll();
//         this.getCriticalProcesses();

//         // Check for version updates
//         this.checkVersion();

//         // Translate service
//         this.translate();
//     }

//     // #endregion LOAD DATA

//     // #region EVENT LISTENERS

//     private setupEventListeners() {
//         document.addEventListener('click', this.toggleModalOnEvent.bind(this));

//         const layoutSub = this.layoutService.layoutChanged
//             .pipe(
//                 filter((eventArgs): eventArgs is ILayoutChangedEventArgs => eventArgs !== null)
//             )
//             .subscribe(eventArgs => {
//                 this.onLayoutChanged(eventArgs);
//             });

//         this.onDestroySubscriptions.push(layoutSub);
//     }

//     private cleanupEventListeners() {
//         document.removeEventListener('click', this.toggleModalOnEvent.bind(this));
//     }

//     // #endregion EVENT LISTENERS

//     // #region METHODS

//     private resetGlobalSearchValue() {
//         this.model.setting.global_searchtext = '';
//         this.model.searchResult = [];
//     }

//     private async translate() {
//         const data = await this.translateService.translateBatch(Object.keys(this.translations));

//         Object.keys(this.translations).forEach(key => {
//             if (data[key] !== undefined) {
//                 this.translations[key] = data[key];
//             }
//         });
//     }

//     private onLayoutChanged(info: any) {
//         this.style.history.fontSize = info.fontSizes.textSize;
//         this.style.history.height = `${info.height}px`;
//         this.style.history.width = `${Number(info.height) + 8}px`;

//         if (
//             (info?.showBreadcrumbs && this.model.breadcrumbsView === 'toggle') ||
//             this.model.breadcrumbsView === 'true'
//         ) {
//             this.style.search.top = '58px';
//         } else if (
//             (!info?.showBreadcrumbs && this.model.breadcrumbsView === 'toggle') ||
//             this.model.breadcrumbsView === 'false'
//         ) {
//             this.style.search.top = '34px';
//         }
//     }

//     private async loadMainMenu(): Promise<void> {
//         const data = await this.mainMenuService.getMainMenu();

//         console.log('loadMainMenu');
//         console.dir(data);

//         const menuData = data[0];

//         console.dir(menuData);

//         this.model.setting.show_gdpr_message = menuData.show_gdpr_message;
//         this.model.setting.show_global_search = menuData.show_global_search;
//         this.model.setting.show_suggestions = menuData.show_suggestions;

//         if (menuData.gdpr_title && menuData.gdpr_message) {
//             console.log('decoding');
//             console.log(this.base64UrlDecode(menuData.gdpr_title));

//             this.model.setting.gdpr_title = this.base64UrlDecode(menuData.gdpr_title);
//             this.model.setting.gdpr_message = this.base64UrlDecode(menuData.gdpr_message);
//         }

//         console.dir(this.model);
//     }

//     private async listMyMenus(): Promise<void> {
//         const data = await this.mainMenuService.listMyMenus({ webpage_name: this.stateService.getCurrentName() })

//         this.model.itemsListMyMenus = [...data];
//     }

//     private async loadUnreadNotifications() {
//         const data = await this.notificationsService.loadUnreadNotifications();

//         this.p2NotificationsListUnread = data.records;
//     }

//     private async checkVersion() {
//         const data = await this.mainMenuService.checkVersion(this.version);

//         if (data?.force_reload === '1') {
//             window.location.reload();
//         }
//     }

//     private setModalPosition() {
//         const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
//         const globalSearchInput = document.getElementById(this.id.searchingGlobalMobile);
//         const globalSearchInputRect = globalSearchInput?.getBoundingClientRect();

//         if (globalSearchInputRect) {
//             this.style.modal.top = `${globalSearchInputRect.bottom - 7}px`;
//             this.style.modal.width = `${document.documentElement.clientWidth - globalSearchInputRect.left}px`;
//         }

//         this.style.modal.right = `${scrollbarWidth}px`;
//     }

//     private async loadWebPageMenuAll() {
//         const response = await this.mainMenuService.listWebPageMenuItems({} as IListWebPageMenuAllOptions);

//         this.model.menus = [...response];
//         this.menuWidthCalc();
//     }

//     private menuWidthCalc() {
//         const ul = this.model.menus.length > 0 ? 100 / this.model.menus.length : 100;
//         this.style.list.width = `${ul}%`;
//     }

//     private async loadGlobalAll() {
//         const result = await this.mainMenuService.searchGlobalAll({ global_searchtext: this.model.setting.global_searchtext });

//         this.model.searchResult = result;

//         if (result.length > 0) {
//             this.model.searchModalOpen = true;
//             this.setModalPosition();
//         }
//     }

//     private async getCriticalProcesses() {
//         this.model.criticalProcesses = await this.mainMenuService.getCriticalProcesses();
//     }

//     @HostListener('document:click', ['$event'])
//     private toggleModalOnEvent(event: MouseEvent) {
//         const globalSearch = document.getElementById(this.id.searchingGlobal);
//         const globalSearchMobile = document.getElementById(this.id.searchingGlobalMobile);

//         if (
//             (this.isElementInTarget(globalSearch, event.target) ||
//                 this.isElementInTarget(globalSearchMobile, event.target)) &&
//             this.model.setting.global_searchtext.length > 0 &&
//             !this.model.searchModalOpen &&
//             this.model.searchResult.length > 0
//         ) {
//             this.model.searchModalOpen = true;
//             this.setModalPosition();
//             return;
//         }

//         const modal = document.getElementById(this.id.modal);
//         let shouldModalClose = false;

//         if (modal && globalSearch) {
//             shouldModalClose = !this.isElementInTarget(modal, event.target) && !this.isElementInTarget(globalSearch, event.target);
//         }

//         this.model.searchModalOpen = shouldModalClose;
//     }

//     private isElementInTarget(element: HTMLElement | null, target: EventTarget | null): boolean {
//         if (!element) return false;
//         if (element === target) return true;

//         for (let i = 0; i < element.children.length; i++) {
//             const child = element.children[i] as HTMLElement;
//             if (this.isElementInTarget(child, target)) {
//                 return true;
//             }
//         }
//         return false;
//     }

//     setFavourites() {
//         this.model.setting.show_suggestions = this.model.setting.show_suggestions === '0' ? '1' : '0';
//         this.rememberFunc('show_suggestions');
//     }

//     private rememberFunc(id: string) {
//         Object.keys(this.variableNames).forEach(key => {
//             this.variableNames[key] = `w_${this.stateService.getCurrentName()}.${key}`;
//         });

//         if (!this.validateParmsValue(this.variableNames[id])) return;

//         let variableValue = null;

//         switch (id) {
//             case 'show_suggestions':
//                 if (!this.validateParmsValue(this.model.setting[id], true)) return;
//                 variableValue = this.model.setting[id];
//                 break;
//             default:
//                 break;
//         }

//         return this.rememberService.remember(this.variableNames[id], variableValue);
//     }

//     public async approve() {
//         this.model.lockedApprove = true;

//         const response = await this.mainMenuService.approveGdpr();

//         if (response.errorcode !== '0') {
//             this.modalService.show({
//                 type: 'warning',
//                 title: this.translations['error'],
//                 message: response.errormessage,
//                 buttons: [
//                     {
//                         label: this.translations['ok'],
//                         type: 'warning',
//                         action: (dialogItself: any) => {
//                             dialogItself.close();
//                             this.model.lockedApprove = false;
//                         }
//                     }
//                 ]
//             });
//         } else {
//             this.model.lockedApprove = false;
//             this.model.setting.show_gdpr_message = '0';
//         }
//     }

//     // #endregion METHODS

//     // #region BUTTON LIST FUNCTIONS

//     btnListFuncGlobalSearchText(item_func: string) {
//         switch (item_func) {
//             case 'erase_item':
//                 this.model.setting.global_searchtext = '';
//                 this.model.searchResult = [];
//                 this.model.searchModalOpen = false;
//                 break;
//             case 'search_item':
//                 this.searchItems();
//                 break;
//             default:
//                 break;
//         }
//     }

//     searchItems() {
//         this.loadGlobalAll();
//     }

//     // #endregion BUTTON LIST FUNCTIONS

// }
