import { Component, forwardRef } from '@angular/core';
import { DividerGenericComponent } from '../divider-generic.component.';

/**
 * Represents dividers between the rows.
 */
@Component({
    selector: 'tt-divider-rows',
    templateUrl: './divider-rows.component.html',
    styleUrls: ['./divider-rows.component.css'],
    providers: [{ provide: DividerGenericComponent, useExisting: forwardRef(() => DividerRowsComponent) }],
})
export class DividerRowsComponent extends DividerGenericComponent {
    override resizeCursor: 'row-resize' | 'col-resize' = 'row-resize';

    override sliderClass: string = 'tt-divider__gutter--row';

    override getPointerPosition(event: PointerEvent): number {
        return event.pageY;
    }

    override getDividerSize(): number {
        if (this.divider) {
            const elementRef = this.divider.nativeElement;
            let height = elementRef.offsetHeight;
            let style = getComputedStyle(elementRef);

            return height - (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth));
        }
        return 0;
    }

    override getOuterSize(elementRef: HTMLElement) {
        const height = elementRef.getBoundingClientRect().height || elementRef.offsetHeight;
        const style = getComputedStyle(elementRef);

        return height + (parseFloat(style.marginTop) + parseFloat(style.marginBottom));
    }
}
