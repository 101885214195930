(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignment', {
        templateUrl: 'views/components/views/consignment/consignment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$window', 'consignmentService', 'stateService', 'utilityService', 'cargonizerApiService', 'modalService', 'ieScreenBlockService', 'printService', 'fileService', 'translateService', 'ttGridFactory', 'layoutService', function ($stateParams, $timeout, $window, consignmentService, stateService, utilityService, cargonizerApiService, modalService, ieScreenBlockService, printService, fileService, translateService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            //var translations = {
            //    consignment_api_create_show_title: 'Varsel',
            //    consignment_api_create_show_ok_label: 'OK',
            //    consignment_print_label_show_title: 'Varsel',
            //    consignment_print_label_show_ok_label: 'OK'
            //};

            let progress = {
                runProgress: 'START',
                label: 'progressbar',
                field: '',
                max: 0,
                min: 0,
                step: 0,
                finishclose: '1',
                abortclose: false
            };

            let translations = {
                error: '',
                ok: '',
                consignment_api_create_show_title: 'Varsel',
                consignment_api_create_show_ok_label: 'OK',
                consignment_print_label_show_title: 'Varsel',
                consignment_print_label_show_ok_label: 'OK'
            };

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'stocksonlocation':
                        go({ stocklocation_keyno: vm.model.p2ConsignmentGet.stocklocation_keyno });
                        break;
                    case 'consignmentcarrierone':
                    case 'consignmentlineselect':
                        go({ consignment_keyno: vm.model.p2ConsignmentGet.consignment_keyno });
                        break;
                    case 'consignmentsplit':
                        go({ consignment_keyno_splitfrom: vm.model.p2ConsignmentGet.consignment_keyno_splitfrom });
                        break;
                    case 'consignmentsplitedit':
                        go({ consignment_keyno_splitfrom: vm.model.p2ConsignmentGet.consignment_keyno_splitfrom });
                        break;
                    case 'consignmentserialnumber':
                        go({ consignment_keyno: vm.model.p2ConsignmentGet.consignment_keyno });
                        break;
                    case 'rfidconnect':
                        go({
                            argtype: 'consignment_keyno',
                            argvalue: vm.model.p2ConsignmentGet.consignment_keyno
                        });
                        break;
                }
            };

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                gridConsignmentLineReady: false,
                tmp_deliverymethod_no: 0,
                validateStatus: 0,
                isDirty: false,
                cargonizerPrinters: {
                    records: []
                },
                p2ConsignmentGet: {},
                p2ConsignmentConsignmentOlList: {},
                p2ConsignmentConsignmentLineList: {},
                p2ConsignmentColliList: {},
                p2ConsignmentConsignmentLinePickList: {
                    records: []
                },
                p2PortalUserVariableSetWs: {},
                p2ConsignmentAssignSb: {},
                p2ConsignmentRefreshWeightVolum: {},
                p2ConsignmentSelectedToPick: {},
                deliverConsignment: {},
                listSelectStockLocationConsignment: [],
                listStockTransferLoadConsignmentPrePick: [],
                listConsignmentShipmentSelect: [],
                consignmentShipmentKeyNoBtnList: [
                    { item_id: '0', item_name: 'new', item_func: 'new_item', glyph: 'glyphicon-plus', color: 'primary' },
                    { item_id: '1', item_name: 'justify', item_func: 'justify_item', glyph: 'glyphicon-align-justify', color: 'primary' },
                    { item_id: '2', item_name: 'goto', item_func: 'goto_item', glyph: 'glyphicon-chevron-right', color: 'primary' }
                ],
                consignmentSelection: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'consignment_products', item_func: 'p' },
                        { item_id: '1', item_name: 'consignment_head', item_func: 'h' },
                        { item_id: '2', item_name: 'consignment_packing', item_func: 'packing' }
                    ]
                },
                bgConsignmentOther: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_consignment_other', item_func: 'OTHER' },
                        { item_id: '1', item_name: 'bg_consignment_requisitions', item_func: 'REQUISITIONS' },
                        { item_id: '2', item_name: 'bg_consignment_orderlines', item_func: 'ORDERLINES' }
                    ]
                },
                //consignmentOrderSelection: {
                //    item_default: '0',
                //    item_selected: '',
                //    records: [
                //        { item_id: '1', item_name: 'consignment_stock_keynos', item_func: 's' },
                //        { item_id: '2', item_name: 'consignment_requisitions', item_func: 'r' },
                //        { item_id: '3', item_name: 'consignment_orderlines', item_func: 'o' }
                //    ]
                //},
                consignmentAddressSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '1', item_name: 'consignment_address', item_func: 'a' },
                        { item_id: '2', item_name: 'consignment_other', item_func: 'o' }
                    ]
                },
                consignmentAddressInfoSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '1', item_name: 'consignment_address_info', item_func: 'i' },
                        { item_id: '2', item_name: 'consignment_contact_info', item_func: 'c' }
                    ]
                },
                consignmentPackingSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignment_packing_send', item_func: 'packing_send' },
                        { item_id: '1', item_name: 'consignment_packing_merge', item_func: 'packing_merge' },
                        { item_id: '2', item_name: 'consignment_packing_details', item_func: 'packing_details' },
                    ]
                },
                bgConsignmentPickSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignment_locationpick', item_func: 'LOCATIONPICK', hidden: true },
                        //{ item_id: '0', item_name: 'consignment_locationpick', item_func: 'LOCATIONPICK' },
                        { item_id: '1', item_name: 'consignment_autopicked', item_func: 'AUTOPICKED' },
                        { item_id: '2', item_name: 'consignment_manualpick', item_func: 'MANUALPICK' }
                    ]
                },
                gbAuto: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignment_gb_auto_select', item_func: 'SELECT' },
                        { item_id: '1', item_name: 'consignment_gb_auto_goto', item_func: 'GOTO' }
                    ]
                },
                consignmentCarriersButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goTo, state: 'consignmentcarrierone' } }
                ],
                p2ConsignmentSave: {
                    saveok: '0'
                },
                locked: '0',
                is_cargonizer: 0,
                lockedPrePickFinished: false,
                loadingPicked: false,
                lockedRollback: false
            };

            vm.deliveryMethods = [];
            vm.cargonizerEnabled = 0;

            vm.gridConsignmentLine = new ttGridFactory({
                rememberId: 'w_consignment_consignmentline_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([
                {
                    name: 'consigment_consignmentline_delete_assignments',
                    text: 'consigment_consignmentline_delete_assignments',
                    func: () => progressDeleteFunc(),
                    icon: 'fa-trash-alt',
                    cssClass: 'btn btn-danger im-grid-btn-xs-r',
                    translate: true,
                    disabled: () => {
                        return canDeleteRows() ? false : true;
                    }
                },
                {
                    name: 'consignment_assign',
                    text: 'consignment_assign',
                    func: () => {
                        consignmentService.assignItems({ consignment_keyno: vm.model.p2ConsignmentGet.consignment_keyno }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            loadGridConsignmentLine();
                                        }
                                    }]
                                });
                            } else {
                                loadGridConsignmentLine();
                            }
                        });
                    },
                    icon: 'fa-sync-alt',
                    cssClass: 'btn btn-primary im-grid-btn-xs-r',
                    translate: true
                }
            ]);

            function canDeleteRows() {
                const selectedRows = vm.gridConsignmentLine.gridfunc.getIsSelectedRows();
                return selectedRows?.length && selectedRows.filter(e => e.is_selected === '0' || e.is_selected === false).length === 0;
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
            	let data = await translateService.translateBatch(translations);

            	Object.keys(translations).forEach((key) => {
            		if (data?.[key]) translations[key] = data[key];
            	});
            }

            // #endregion TRANSLATE SERVICE

            // ######################
            // BUTTON GROUP FUNCTIONS
            // ######################
    
            vm.selectConsignmentView = function (lsConsignmentItem) {
                vm.model.consignmentSelection.item_selected = lsConsignmentItem;

                if (lsConsignmentItem === 'packing'){
                    vm.getP2ConsignmentColliList();
                }

                 rememberConsignmentSelection();
            };
    
            vm.selectBgConsignmentOther = function (item) {
                vm.model.bgConsignmentOther.item_selected = item;
            };
    
            //vm.selectConsignmentOrderView = function (lsConsignmentOrderItem) {
            //    vm.model.consignmentOrderSelection.item_selected = lsConsignmentOrderItem;
            //};
    
            vm.selectConsignmentAddressView = function (lsConsignmentAddressItem) {
                vm.model.consignmentAddressSelection.item_selected = lsConsignmentAddressItem;
            };
    
            vm.selectConsignmentAddressInfoView = function (lsConsignmentAddressInfoItem) {
                vm.model.consignmentAddressInfoSelection.item_selected = lsConsignmentAddressInfoItem;
            };
    
            vm.selectConsignmentPackingView = function (lsConsignmentPackingItem) {
                vm.model.consignmentPackingSelection.item_selected = lsConsignmentPackingItem;            
                vm.getP2ConsignmentColliList();
            };
    
            vm.selectBgConsignmentPickView = function (item) {
                vm.model.bgConsignmentPickSelection.item_selected = item;
            };
    
            vm.selectGbAuto = function (item) {
                vm.model.gbAuto.item_selected = item;
            };
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
    		var initLoadDone = false;
    		var timerInitLoadDone = null;
    
            vm.loadConsignment = function () {
                consignmentService.loadP2ConsignmentGet($stateParams.consignment_keyno).then(function () {
                    vm.model.p2ConsignmentGet = consignmentService.p2ConsignmentGet;
                    vm.model.isDirty = true;
                    vm.model.tmp_deliverymethod_no = vm.model.p2ConsignmentGet.deliverymethod_no;

                    if (vm.model.p2ConsignmentGet.floating_location === '1') {
                        vm.model.bgConsignmentPickSelection.records[0].hidden = false;
                        vm.model.bgConsignmentPickSelection.item_default = '0';
                    } else {
                        vm.model.bgConsignmentPickSelection.records[0].hidden = true;
                        vm.model.bgConsignmentPickSelection.item_default = '1';
                    }

                    //console.log('vm.model.bgConsignmentPickSelection.records[0].hidden: ' + vm.model.bgConsignmentPickSelection.records[0].hidden);

                    angular.forEach(vm.model.consignmentSelection.records, function (data) {
                        if (angular.isDefined(vm.model.p2ConsignmentGet.bg_consignmentselection) && angular.isDefined(data)) {
                            if (vm.model.p2ConsignmentGet.bg_consignmentselection === data.item_func) {
                                vm.model.consignmentSelection.item_default = data.item_id;
                                
                                if (vm.model.p2ConsignmentGet.bg_consignmentselection === 'packing') {
                                    angular.forEach(vm.model.consignmentPackingSelection.records, function (data) {
                                        if (vm.model.p2ConsignmentGet.bg_consignment_packing_selection === data.item_func) {
                                            vm.model.consignmentPackingSelection.item_default = data.item_id;
                                            vm.model.consignmentPackingSelection.item_selected = vm.model.p2ConsignmentGet.bg_consignment_packing_selection;
                                            vm.getP2ConsignmentColliList();
                                        }
                                    });
                                } else if (vm.model.p2ConsignmentGet.bg_consignmentselection === 'p') {
                                    angular.forEach(vm.model.bgConsignmentPickSelection.records, function (data) {
                                        if (vm.model.p2ConsignmentGet.bg_ConsignmentPickSelection === data.item_func) {
                                            vm.model.bgConsignmentPickSelection.item_default = data.item_id;
                                            vm.model.bgConsignmentPickSelection.item_selected = vm.model.p2ConsignmentGet.bg_ConsignmentPickSelection;
                                        }
                                    });
                                }
                            }
                        }
                    });
    
                    if (angular.isDefined(timerInitLoadDone)) {
                        $timeout.cancel(timerInitLoadDone);
                    }
    
                    timerInitLoadDone = $timeout(function () {
                        initLoadDone = true;
                    }, 50);
    
                    vm.deliveryMethodsGet();
                    vm.validateConsignment();
                    loadSelectStockLocationConsignmentList();
                    loadStockTransferLoadConsignmentPrePick();
    
                    $timeout(function () {
                        vm.model.isDirty = false;
                    }, 300);
                }).then(() => loadConsignmentShipmentSelect());
    
                consignmentService.loadP2ConsignmentConsignmentOlList($stateParams.consignment_keyno).then(function () {
                    vm.model.p2ConsignmentConsignmentOlList = consignmentService.p2ConsignmentConsignmentOlList;
                });
    
                consignmentService.loadP2ConsignmentConsignmentLineList($stateParams.consignment_keyno).then(function () {
                    vm.model.p2ConsignmentConsignmentLineList = consignmentService.p2ConsignmentConsignmentLineList;
                });
            };
    
            vm.loadConsignment();
    
            vm.getP2ConsignmentSelectedToPick = function () {
                vm.model.loadingPicked = true;
    
                consignmentService.genericFunction(755, vm.model.p2ConsignmentConsignmentLineList).then(function (data) {
                    vm.model.p2ConsignmentSelectedToPick = angular.copy(data[0]);
    
                    if (vm.model.p2ConsignmentSelectedToPick.completed_ok === '1') {
                        vm.model.loadingPicked = false;
                        vm.consignmentAssign();
                    } else {
                        vm.model.loadingPicked = false;
                        vm.consignmentAssign();
                    }
                });
            };
    
            var loadSelectStockLocationConsignmentList = function () {
                if (angular.isDefined(vm.model.p2ConsignmentGet.stockplace_id_from) && vm.model.p2ConsignmentGet.stockplace_id_from !== '') {
                    consignmentService.loadSelectStockLocationConsignmentList(vm.model.p2ConsignmentGet.stockplace_id_from).then(function (data) {
                        angular.copy(data, vm.model.listSelectStockLocationConsignment);
                    });
                }
            };
            
            var loadStockTransferLoadConsignmentPrePick = function () {
                if (angular.isDefined(vm.model.p2ConsignmentGet.stocktransfer_keyno) && vm.model.p2ConsignmentGet.stocktransfer_keyno > 0) {
                    consignmentService.loadStockTransferLoadConsignmentPrePickList(vm.model.p2ConsignmentGet.stocktransfer_keyno).then(function (data) {
                        angular.copy(data, vm.model.listStockTransferLoadConsignmentPrePick);
                    });
                }
            };
    
            var loadConsignmentShipmentSelect = function () {
                if (angular.isUndefined(vm.model.p2ConsignmentGet.stockplace_id_from) === true) return;
                if (vm.model.p2ConsignmentGet.stockplace_id_from === null) return;
                if (vm.model.p2ConsignmentGet.stockplace_id_from.trim().length < 1) return;
    
                consignmentService.loadConsignmentShipmentSelectList(vm.model.p2ConsignmentGet.stockplace_id_from).then(function (data) {
                    angular.copy(data, vm.model.listConsignmentShipmentSelect);
                });
            };

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGridConsignmentLine() {
                vm.gridConsignmentLine.dataTask.loadData = {
                    method: 3556,
                    parameters: { consignment_keyno: $stateParams.consignment_keyno }
                };

                vm.model.gridConsignmentLineReady = true;

                if (vm.gridConsignmentLine.gridfunc !== null) vm.gridConsignmentLine.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // ######################
            // ASSIGN BUTTON FUNCTION
            // ######################
    
            vm.consignmentAssign = function (index) {
                consignmentService.getP2ConsignmentAssignSb(vm.model.p2ConsignmentGet.consignment_keyno).then(function () {
                    vm.model.p2ConsignmentAssignSb = consignmentService.p2ConsignmentAssignSb;
    
                    if (vm.model.p2ConsignmentAssignSb.errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: vm.model.p2ConsignmentAssignSb.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingPicked = false;

                                    consignmentService.loadP2ConsignmentConsignmentLineList($stateParams.consignment_keyno).then(function () {
                                        vm.model.p2ConsignmentConsignmentLineList = consignmentService.p2ConsignmentConsignmentLineList;
                                    });
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingPicked = false;
    
                        consignmentService.loadP2ConsignmentConsignmentLineList($stateParams.consignment_keyno).then(function () {
                            vm.model.p2ConsignmentConsignmentLineList = consignmentService.p2ConsignmentConsignmentLineList;
                        });
                    }
                });
            };
            
            vm.loadP2ConsignmentConsignmentLinePickList = function () {
                consignmentService.loadP2ConsignmentConsignmentLinePickList($stateParams.consignment_keyno).then(function (data) {
                    vm.model.p2ConsignmentConsignmentLinePickList = consignmentService.p2ConsignmentConsignmentLinePickList;
                });
            };
    
            vm.loadP2ConsignmentConsignmentLinePickList();
    
            // #######################
            // COLLI DETAILS FUNCTION
            // #######################
            
            vm.goColliDetails = function (){
                //console.log('Jepp');
            };
            
            vm.returnFirstValue = function (){
    
            };
    
            // #######################
            // LOAD CONSIGNMENT COLLI
            // #######################
    
            vm.getP2ConsignmentColliList = function () {
                if ($stateParams.consignment_keyno > 0) {
                    consignmentService.loadP2ConsignmentColliList($stateParams.consignment_keyno, vm.model.consignmentPackingSelection.item_selected).then(function () {
                        vm.model.p2ConsignmentColliList = consignmentService.p2ConsignmentColliList;
                        
                        if (initLoadDone){
                            consignmentService.remember('w_consignment.consignmentpackingselection', vm.model.consignmentPackingSelection.item_selected);
                        }

                        var wgt = 0;  
                        var vol = 0;  
                        var colli = 0;

                        angular.forEach(vm.model.p2ConsignmentColliList.records, function (item){
                            if (parseFloat(item.item_weight)) {
                                wgt += parseFloat(item.item_weight);
                            }

                            if (parseFloat(item.item_volume)) {
                                vol += parseFloat(item.item_volume);
                            }

                            colli += 1;
                        });
                        
                        vm.model.p2ConsignmentGet.weight = wgt;

                        if (vm.model.p2ConsignmentGet.show_xyz === '0') vm.model.p2ConsignmentGet.volum = vol;

                        vm.model.p2ConsignmentGet.colli = colli;
                    });
                }
            };
    
            // #######################
            // MERGE CONSIGNMENT COLLI
            // #######################
           
            vm.mergeConsignmentCollies = function () {
                var parms = {
                    consignment_keyno: $stateParams.consignment_keyno,
                    data: vm.model.p2ConsignmentColliList
                };

                consignmentService.mergeConsignmentCollies(parms).then(function () {
                    vm.getP2ConsignmentColliList();
                });
            };
    
            // #######################
            // SPLIT CONSIGNMENT COLLI
            // #######################
           
            vm.splitConsignmentCollies = function () {
                var parms = {
                    consignment_keyno: $stateParams.consignment_keyno,
                    data: vm.model.p2ConsignmentColliList
                };

                consignmentService.splitConsignmentCollies(parms).then(function () {
                    vm.getP2ConsignmentColliList();
                });
            };
    
            // ########################
            // ## BUTTON LIST FUNCTIONS
            // ########################
    
            vm.consignmentShipmentKeyNoBtnListFunc = function (item_func) {
                if (item_func === 'new_item') {
                    stateService.go('consignmentshipment', {
                        consignmentshipment_keyno: '0',
                        stockplace_id: vm.model.p2ConsignmentGet.stockplace_id_from
                    });
                } else if (item_func === 'justify_item') {
                    stateService.go('consignmentshipments');
                } else if (item_func === 'goto_item') {
                    if (vm.model.p2ConsignmentGet.consignmentshipment_keyno > 0) {
                        stateService.go('consignmentshipment', {
                            consignmentshipment_keyno: vm.model.p2ConsignmentGet.consignmentshipment_keyno,
                            stockplace_id: vm.model.p2ConsignmentGet.stockplace_id_from
                        });
                    }
                }
            };
    
            // ###################
            // CHECK ALL FUNCTION
            // ###################
    
            vm.showChecked = false;
    
            vm.checkButtonClick = function () {
                if (!vm.showChecked) {
                    vm.showChecked = true;
                    vm.selectAll();
                } else {
                    vm.showChecked = false;
                    vm.unselectAll();
                }
            };
    
            vm.selectAll = function () {
                angular.forEach(vm.model.p2ConsignmentConsignmentLineList.records, function (item) {
                    item.item_is_selected = '1';
                    //item.item_glyphicon_color = 'green';
                });
            };
    
            vm.unselectAll = function () {
                angular.forEach(vm.model.p2ConsignmentConsignmentLineList.records, function (item) {
                    item.item_is_selected = '0';
                    //item.item_glyphicon_color = 'black';
                });
            };
    
            // #####################################
            // CHECK DELIVERY METHOD BUTTON FUNCTION
            // #####################################
    
            vm.checkDeliveryMethod = function (index) {
                $timeout(function () {
                    angular.forEach(vm.deliveryMethods.records, function (item) {
                        if (vm.model.p2ConsignmentGet.deliverymethod_no === item.deliverymethod_no) {
                            if (item.deliverymethod_cargonizer != '') {
                                vm.cargonizerEnabled = 0;
                                vm.cargonizerEnabled = 1;
                            }

                            return 0;
                        }
                    });
    
                    if (vm.model.p2ConsignmentGet.deliverymethod_no === null) {
                        vm.model.p2ConsignmentGet.deliverymethod_no = vm.model.tmp_deliverymethod_no;
                    } //else {

                    vm.model.is_cargonizer = 0;

                    if (angular.isDefined(vm.deliveryMethods.indexed_records)){
                        var chk = vm.deliveryMethods.indexed_records[vm.model.p2ConsignmentGet.deliverymethod_no];

                        if (chk){
                            if ((chk.deliverymethod_cargonizer !== '0') && (chk.deliverymethod_cargonizer !== '')){
                                vm.model.is_cargonizer = 1;
                            } 
                        }
                    }

                    vm.validateConsignment();

                    //}
                }, 100);
            };
    
            // ####################################
            // VALIDATE CONSIGNMENT BUTTON FUNCTION
            // ####################################
    
            vm.validateConsignment = function () {
                
            };
            
            vm.manualPick = function (item) {
                consignmentService.getP2PortalUserVariableSetWs('active_consignmentline_keyno', item.item_id).then(function () {
                    vm.model.p2PortalUserVariableSetWs = consignmentService.p2PortalUserVariableSetWs;
    
                    stateService.go(item.item_state, item.item_parms);
                });
            };
            
            // ###########################
            // ## REMEMBER VALUE FUNCTIONS
            // ###########################
    
            var rememberConsignmentSelection = function () {
                var variableNameConsignmentSelection = 'w_consignment.consignmentselection';
                
                if (angular.isDefined(variableNameConsignmentSelection) && angular.isDefined(vm.model.consignmentSelection.item_selected) && initLoadDone === true) {
                    consignmentService.remember(variableNameConsignmentSelection, vm.model.consignmentSelection.item_selected);
                }
            };
    
            // ############################
            // GET WAY BILL BUTTON FUNCTION
            // ############################
    
            vm.getWaybill = function (url) {
                var tab = $window.open('_blank');
                tab.location = url;
                /*var aurl = 'http://www.vg.no';
                cargonizerApiService.getCargonizerWaybill($stateParams.consignment_keyno,aurl).then(function(){
    
                });*/
            };
    
            // #####################
            // PRINT BUTTON FUNCTION
            // #####################
    
            //vm.printConsignment = function () {
            //    cargonizerApiService.printCargonizerConsignment($stateParams.consignment_keyno, vm.model.p2ConsignmentGet.cargonizer_printer_id).then(function () {
            //        vm.model.p2ConsignmentGet = consignmentService.p2ConsignmentGet;
            //        vm.model.isDirty = true;
            //        vm.deliveryMethodsGet();
            //        vm.model.locked = '0';
            //        ieScreenBlockService.stop();
            //    });
            //};
    
            vm.printCargonizerReport = function (docarcFileName) {
                cargonizerApiService.printCargonizerConsignmentReport($stateParams.consignment_keyno, vm.model.p2ConsignmentGet.cargonizer_printer_id, docarcFileName).then(function () {
                    vm.model.p2ConsignmentGet = consignmentService.p2ConsignmentGet;
                    vm.deliveryMethodsGet();
                    vm.model.locked = '0';
                    ieScreenBlockService.stop();
                });
            };
    
            // ##################################
            // CREATE CONSIGNMENT BUTTON FUNCTION
            // ##################################

            var loadPrintLabelApi = function () {
                consignmentService.printLabelCargonizerApi({
                    consignment_keyno: vm.model.p2ConsignmentGet.consignment_keyno,
                    cargonizer_printer_id: vm.model.p2ConsignmentGet.cargonizer_printer_id
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        ieScreenBlockService.stop();

                        modalService.show({
                            type: 'warning',
                            title: translations.consignment_print_label_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.consignment_print_label_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = '0';
                                }
                            }]
                        });
                    } else {
                        ieScreenBlockService.stop();
                        vm.model.locked = '0';
                    }
                });
            };


            vm.createConsignment = function () {
                vm.model.locked = '1';
    
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Validating consignment.');

                consignmentService.saveConsignmentHead(vm.model.p2ConsignmentGet).then(function () {
                    cargonizerApiService.validateCargonizerConsignment($stateParams.consignment_keyno, vm.model.p2ConsignmentGet.deliverymethod_no, vm.model.p2ConsignmentGet.colli, vm.model.p2ConsignmentGet.weight, vm.model.p2ConsignmentGet.volum).then(function (data) {
                        vm.model.validateStatus = data[0].status;
                        vm.model.validateMessage = data[0].status_message;
                        //vm.model.validateStatus = cargonizerApiService.validateStatus.status;
                        
                        if (vm.model.validateStatus === '0') {
                            ieScreenBlockService.setMessage('Validering OK (' + vm.model.p2ConsignmentGet.colli + '/' + vm.model.p2ConsignmentGet.weight + '/' + vm.model.p2ConsignmentGet.volum + ')');
                            vm.model.tmp_deliverymethod_no = vm.model.p2ConsignmentGet.deliverymethod_no;
    
                            /*$timeout(function () {
                                ieScreenBlockService.stop();
                                vm.model.locked = '0';
                            }, 1500);*/
                            
                            if (vm.model.p2ConsignmentGet.tracking_url !== ''){
                                ieScreenBlockService.setMessage('Printing labels....');
                                //vm.printConsignment();
                                loadPrintLabelApi();
                            } else {
                                ieScreenBlockService.setMessage('Webshop Pre Validate');

                                consignmentService.consignmentWebshopPreValidate({consignment_keyno: $stateParams.consignment_keyno}).then(function(data){
                                    if (data[0].status_no == '0') {
                                        consignmentService.createCargonizerConsignment($stateParams.consignment_keyno, vm.model.p2ConsignmentGet.deliverymethod_no, vm.model.p2ConsignmentGet.colli, vm.model.p2ConsignmentGet.weight, vm.model.p2ConsignmentGet.volum).then(function (response) {
                                            ieScreenBlockService.stop();

                                            if (response[0].errorcode !== '0') {
                                                modalService.show({
                                                    type: 'warning',
                                                    title: translations.consignment_api_create_show_title,
                                                    message: response[0].errormessage,
                                                    buttons: [{
                                                        label: translations.consignment_api_create_show_ok_label,
                                                        cssClass: 'btn-warning',
                                                        action: function (dialogItself) {
                                                            dialogItself.close();
                                                            vm.model.locked = '0';
                                                        }
                                                    }]
                                                });
                                            } else {
                                                if (response[0].consignment_keyno > 0) {
                                                    consignmentService.updateCargonizerConsignment(response[0].consignment_keyno).then(function (response) {

                                                    });
                                                }

                                                consignmentService.loadP2ConsignmentGet($stateParams.consignment_keyno).then(function (data) {
                                                    ieScreenBlockService.setMessage('Updating external party');

                                                    consignmentService.completeConsignmentInWebshop({ consignment_keyno: $stateParams.consignment_keyno }).then(function () {
                                                        //if (vm.model.deliverymethod_own_label === '1') {
                                                        if (0 > 1) {
                                                            ieScreenBlockService.setMessage('Generating label..');
                                                            var instanceId = uuid();

                                                            printService.archive({
                                                                //reportId: 'SlettvollConsignmentColli.trdp',
                                                                reportId: 'DefaultConsignmentColli.trdp',
                                                                parameters: {
                                                                    consignment_keyno: $stateParams.consignment_keyno
                                                                }
                                                            }).then(function (fileItem) {
                                                                ieScreenBlockService.setMessage('Getting file details...');

                                                                fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }).then(function (dr) {
                                                                    ieScreenBlockService.setMessage('Printing labels....');
                                                                    vm.printCargonizerReport(dr.fileName);

                                                                    //vm.model.p2ConsignmentGet = consignmentService.p2ConsignmentGet;
                                                                    //vm.deliveryMethodsGet();
                                                                    //vm.model.locked = '0';
                                                                });
                                                            }).catch(function (err) {
                                                                console.log('report error:', err);
                                                            });
                                                        } else {
                                                            ieScreenBlockService.setMessage('Printing labels....');
                                                            //vm.printConsignment();
                                                            loadPrintLabelApi();
                                                        }
                                                    });
                                                });
                                            }
                                        });
                                    } else {
                                        vm.model.locked = '0';
                                        ieScreenBlockService.stop();

                                        modalService.show({
                                            type: 'warning',
                                            title: 'Warning',
                                            message: data[0].status_message,
                                            buttons: [{
                                                label: 'OK',
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();
                                                }
                                            }]
                                        });
                                    }
                                });
                            }
                        } else {
                            vm.model.locked = '0';
                            ieScreenBlockService.stop();
                            
                            modalService.show({
                                type: 'danger',
                                title: 'danger',
                                message: data[0].status_message,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                    });
                });
            };

            //translateService.translateBatch(translations).then(function (data) {
            //    angular.forEach(translations, function (_, key) {
            //        if (angular.isDefined(data[key])) {
            //            translations[key] = data[key];
            //        }
            //    });
            //});
            
            vm.confirmConsignment = function () {
                vm.model.locked = '1';
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Prevalidering..');
    
                consignmentService.consignmentWebshopPreValidate({consignment_keyno: $stateParams.consignment_keyno}).then(function(data){
                    if (data[0].status_no == '0') {
                        consignmentService.deliverConsignment($stateParams.consignment_keyno).then(function () {
                            vm.model.deliverConsignment = consignmentService.p2DeliverConsignment;

                            if (vm.model.deliverConsignment.validationcode === '0') {
                                ieScreenBlockService.setMessage('Updating external party');

                                consignmentService.completeConsignmentInWebshop({consignment_keyno: $stateParams.consignment_keyno}).then(function(){
                                    vm.model.locked = '0';
                                    ieScreenBlockService.stop();
                                    stateService.back();
                                });                            
                            } else if (vm.model.deliverConsignment.validationcode !== '0') {
                                vm.model.locked = '0';
                                ieScreenBlockService.stop();

                                modalService.show({
                                    type: 'warning',
                                    title: 'Warning',
                                    message: vm.model.deliverConsignment.validationmessage,
                                    buttons: [{
                                        cssClass: 'btn-warning',
                                        label: 'OK',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            }
                        });
                    } else {
                        vm.model.locked = '0';
                        ieScreenBlockService.stop();

                        modalService.show({
                            type: 'warning',
                            title: 'Warning',
                            message: data[0].status_message,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                });
    
                //var l_s_label_ok = 'OK';
                //var l_s_label_cancel = 'Cancel';
    
                //modalService.show({
                //    type: 'danger',
                //    title: 'Confirmation',
                //    message: 'Vil du virkelig slette personen?',
                //    buttons: [{
                //        label: l_s_label_ok,
                //        cssClass: 'btn-warning',
                //        action: function (dialogItself) {
                //            dialogItself.close();
    
                //            personService.deletePerson().then(function () {
                //                stateService.back();
                //            });
                //        }
                //    }, {
                //        label: l_s_label_cancel,
                //        close: true
                //    }]
                //});
            };
    
            // ######################
            // DELETE BUTTON FUNCTION
            // ######################
    
            vm.deleteConsignment = function () {
                vm.model.tmp_deliverymethod_no = vm.model.p2ConsignmentGet.deliverymethod_no;
    
                cargonizerApiService.deleteCargonizerConsignment($stateParams.consignment_keyno).then(function () {
                    consignmentService.loadP2ConsignmentGet($stateParams.consignment_keyno).then(function () {
                        vm.model.p2ConsignmentGet = consignmentService.p2ConsignmentGet;
                        vm.model.isDirty = true;
                        vm.deliveryMethodsGet();
                    });
                });
            };
    
            vm.deleteP2Consignment = function () {
                consignmentService.genericFunction(758, {
                    consignment_keyno: $stateParams.consignment_keyno
                }).then(function (data) {
                    stateService.back();
                });
            };

            vm.rollbackValues = function () {
                vm.model.lockedRollback = true;
                
                consignmentService.rollbackItem({ consignment_keyno: vm.model.p2ConsignmentGet.consignment_keyno }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedRollback = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedRollback = false;
                        vm.loadConsignment();
                    }
                });
            };

            // ###################################
            // GET DELIVERY METHOD BUTTON FUNCTION
            // ###################################
    
            vm.deliveryMethodsGet = function () {
                vm.model.tmp_deliverymethod_no = vm.model.p2ConsignmentGet.deliverymethod_no;
                vm.deliveryMethods = [];
    
                consignmentService.deliveryMethodsList($stateParams.consignment_keyno).then(function () {
                    vm.deliveryMethods = consignmentService.deliveryMethods;
    
                    vm.checkDeliveryMethod();
    
                    cargonizerApiService.getCargonizerPrintersLocal($stateParams.consignment_keyno).then(function () {
                        vm.model.cargonizerPrinters = cargonizerApiService.cargonizerPrinters;
                    });
                });
            };
    
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
    
            vm.saveConsignmentHead = function () {
                //console.log('stocklocation_keyno: ' + vm.model.p2ConsignmentGet.stocklocation_keyno);
                consignmentService.saveConsignmentHead(vm.model.p2ConsignmentGet).then(function () {
                    vm.model.p2ConsignmentSave = consignmentService.p2ConsignmentSave;
    
                    if (vm.model.p2ConsignmentSave.saveok === '1') {
                        //vm.formConsignment.$dirty = false;
                    }
    
                    $timeout(function () {
                        vm.model.isDirty = false;
                    }, 300);
                });
            };

            // ###################################
            // ## FINISHED PREPICK BUTTON FUNCTION
            // ###################################
    
            vm.prePickFinished = function () {
                vm.model.lockedPrePickFinished = true;
    
                consignmentService.unloadStockTransferAll(vm.model.p2ConsignmentGet.stocktransfer_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Feilmelding',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPrePickFinished = false;
                                }
                            }]
                        });
                    } else {
                        consignmentService.getP2ConsignmentAssignSb(vm.model.p2ConsignmentGet.consignment_keyno).then(function () {
                            vm.model.p2ConsignmentAssignSb = consignmentService.p2ConsignmentAssignSb;

                            if (vm.model.p2ConsignmentAssignSb.errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Feilmelding',
                                    message: vm.model.p2ConsignmentAssignSb.errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedPrePickFinished = false;
                                            vm.loadConsignment();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.lockedPrePickFinished = false;
                                vm.loadConsignment();
                            }
                        });
                    }
                });
            };
    
            // ###########################
            // $WATCH & $TIMEOUT FUNCTIONS
            // ###########################

            $timeout(function() {
                if (vm.formConsignment !== undefined) {
                    vm.checkDeliveryMethod();
                }
            });

            vm.onP2ConsigmentGetChange = function () {
                vm.model.isDirty = true;
            }

            vm.onP2ConsigmentGetChangeStockLocation = function () {
                vm.model.isDirty = true;

                $timeout(function () {
                    vm.saveConsignmentHead();
                }, 50);
            };

            // ######################################
            // DIRECTION BUTTON TO BATCHDELIVERY_LIST
            // ######################################
    
            vm.goToBatchDeliveryList = function (index) {
                stateService.go('batchdelivery_list');
            };
    
            vm.goToSelectReport = function (index) {
                stateService.go('selectreport', {
                    webpage_name: stateService.getCurrentName(),
                    argtype: 'consignment_keyno',
                    argvalue: $stateParams.consignment_keyno
                });
            };
    
            vm.goToEmailCreate = function () {
                stateService.go('emailcreate', {
                    webpage: stateService.getCurrentName(),
                    argtype: 'consignment_keyno',
                    argvalue: vm.model.p2ConsignmentGet.consignment_keyno
                });
            };
    
            vm.goToDocuments = function () {
                stateService.go('documents', {
                    argtype: 'consignment_keyno',
                    argvalue: vm.model.p2ConsignmentGet.consignment_keyno
                });
            };
    
            vm.goToPrePickCreate = function () {
                stateService.go('prepickcreate', {
                    argtype: 'consignment_keyno',
                    argvalue: vm.model.p2ConsignmentGet.consignment_keyno
                });
            };
    
            vm.goToStockTransfer = function () {
                stateService.go('stocktransfer', {
                    stocktransfer_keyno: vm.model.p2ConsignmentGet.stocktransfer_keyno
                });
            };
    
            vm.goToOrderHead = function () {
                stateService.go('orderhead', {
                    order_internal_no: vm.model.p2ConsignmentGet.order_internal_no_first
                });
            };
            
            let recalcVolumeM3 = function () {
                if (vm.model.p2ConsignmentGet.setting_use_colli_dimensions !== '1') {
                    vm.model.p2ConsignmentGet.volum = (utilityService.parseNumber(vm.model.p2ConsignmentGet.height_cm) * utilityService.parseNumber(vm.model.p2ConsignmentGet.length_cm) * utilityService.parseNumber(vm.model.p2ConsignmentGet.width_cm)) / 1000000;
                }
            };

            vm.checkAll = function () {
                angular.forEach(vm.model.p2ConsignmentColliList.records, function (record) {
                    record.item_is_selected = '1';
                });
            };

            vm.uncheckAll = function () {
                angular.forEach(vm.model.p2ConsignmentColliList.records, function (record) {
                    record.item_is_selected = '0';
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'width_cm':
                    case 'height_cm':
                    case 'length_cm':
                        if (utilityService.validateWatchValue(value, vm.model.p2ConsignmentGet[id]) !== true) return;

                        vm.model.p2ConsignmentGet[id] = value;
                        
                        if (utilityService.validateParmsValue(vm.model.p2ConsignmentGet[id], true) !== true) return;

                        vm.model.isDirty = true;
                        recalcVolumeM3();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region CARGONIZER FUNCTIONS

            vm.prodIDSearch = function (value) {
                value ??= '';

                return consignmentService.getProdKeyname(value);
            };

            vm.selectProdID = function (item, model) {
                vm.model.p2ConsignmentGet.prod_id_freight = '';

                if (item?.prod_id && item.prod_id !== '') {
                    vm.model.p2ConsignmentGet.prod_id_freight = item.prod_id;

                    consignmentService.getOrderlineProdSum(vm.model.p2ConsignmentGet.consignment_keyno, item.prod_id).then(function (result) {
                        if (result?.[0]?.sum_amount_net_locval) {
                            vm.model.p2ConsignmentGet.order_freight = result[0].sum_amount_net_locval;
                        }
                    });
                }
            };

            vm.checkFreight = function () {
                if (utilityService.parseNumber(vm.model.p2ConsignmentGet.order_freight) === 0) {
                    return false;
                } else return true;
            };

            vm.getEstimatedFreight = function () {
                consignmentService.getEstimatedFreight(vm.model.p2ConsignmentGet.consignment_keyno, vm.model.p2ConsignmentGet.deliverymethod_no, vm.model.p2ConsignmentGet.colli, vm.model.p2ConsignmentGet.weight, vm.model.p2ConsignmentGet.volum).then(function (result) {
                    if (result[0].errorcode === '0') {
                        vm.model.p2ConsignmentGet.est_freight = result[0].estimated_cost;
                    } else {
                        modalService.show({
                            type: 'danger',
                            title: 'ERROR',
                            message: result[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-danger',
                                action: function (modalInstanceError) {
                                    modalInstanceError.close();
                                }
                            }]
                        });
                    }
                });
            };

            vm.handleEstimatedFreight = function () {
                consignmentService.handleEstimatedFreight(vm.model.p2ConsignmentGet.consignment_keyno, vm.model.p2ConsignmentGet.prod_id_freight, vm.model.p2ConsignmentGet.est_freight).then(function (result) {
                    if (!result.iserror) vm.loadConsignment();
                });
            };

            // #endregion CARGONIZER FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            function activateDeleteProgress(items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                progress.step = 0;

                function doStep() {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (progress.runProgress === 'ABORT') return;
                        if (progress.step >= progress.max) return;

                        step = progress.step + skip + failed;
                        progress.field = 'ID: ' + items[step].consignmentline_keyno;
                        modalService.progressInfo.field = progress.field;

                        consignmentService.deleteAssign({ consignmentline_keyno: items[step].consignmentline_keyno }).then((response) => {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: (dialogItself) => {
                                            dialogItself.close();

                                            progress.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                progress.step += increment;
                                modalService.progressInfo.step = progress.step;
                                modalService.progressInfo.finishclose = progress.finishclose;

                                doStep();
                            }
                        });
                    });
                }

                doStep();
            }

            function progressDeleteFunc() {
                let allRows = vm.gridConsignmentLine.gridfunc.getAllRows();
                let selectedRows = [];

                allRows.forEach((item) => {
                    if (item.is_selected === '1' || item.is_selected === true) selectedRows.push(item);
                });

                if (selectedRows.length === 0) return;

                progress.max = selectedRows.length;
                activateDeleteProgress(selectedRows);

                modalService.showProgress(progress).then(function (value) {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') return;
                    if (progress.runProgress === 'FINISH') loadGridConsignmentLine();
                });
            }

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadGridConsignmentLine();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
