import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('accountingProcessing', {
        templateUrl: 'views/components/views/accountingProcessing/accountingProcessing.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: [
            '$stateParams',
            '$timeout',
            '$uibModal',
            'appConfig',
            'stateService',
            'utilityService',
            'translateService',
            'rememberService',
            'economyService',
            'logisticService',
            'accountingProcessingService',
            'accountingProcessingAttestationModalService',
            'consignmentPlanService',
            'timeRegisterService',
            'typeaheadService',
            'modalService',
            'ttGridFactory',
            'fileService',
            'ieScreenBlockService',
            'graphAuthService',
            function ($stateParams, $timeout, $uibModal, appConfig, stateService, us, translateService, rememberService, economyService, logisticService, accountingProcessingService, accountingProcessingAttestationModalService, consignmentPlanService, timeRegisterService, typeaheadService, modalService, ttGridFactory, fileService, ieScreenBlockService, graphAuthService) {
                let translations = {
                    error: '',
                    ok: '',
                    remove_popup_window_blocker: ''
                };

                let vm = this;
                vm.version = module.version;

                vm.id = {
                    accountingprocessingApprove: uuid(),
                    accountingprocessingBookkeep: uuid(),
                    accountingprocessingReverse: uuid(),
                    accountingprocessingDisapprove: uuid(),
                    accountingprocessingHistory: uuid(),
                };

                /** State over modals for approving, bookkeeping, history and rejecting. */
                vm.modal = {
                    accountingprocessingApprove: false,
                    accountingprocessingBookkeep: false,
                    accountingprocessingReverse: false,
                    accountingprocessingDisapprove: false,
                    accountingprocessingHistory: false,
                };

                vm.reverseVoucherModel = {};

                /** Opens the approve modal. */
                vm.openAccountingprocessingApproveModal = function () {
                    vm.modal.accountingprocessingApprove = !vm.modal.accountingprocessingApprove;
                };

                /** Opens the bookkeep modal. */
                vm.openAccountingprocessingBookkeepModal = function () {
                    vm.modal.accountingprocessingBookkeep = !vm.modal.accountingprocessingBookkeep;
                };

                /** Opens the bookkeep modal. */
                vm.openAccountingprocessingReverseModal = function () {
                    vm.modal.accountingprocessingReverse = !vm.modal.accountingprocessingReverse;
                };

                /** Opens the reject modal. */
                vm.openAccountingprocessingDisapproveModal = function () {
                    vm.modal.accountingprocessingDisapprove = !vm.modal.accountingprocessingDisapprove;
                };

                /** Opens the history/log modal. */
                vm.openAccountingprocessingHistoryModal = function () {
                    if (vm.model.custact.custact_no === vm.model.get.custact_no_active) {
                        vm.modal.accountingprocessingHistory = !vm.modal.accountingprocessingHistory;
                    };
                };


                /**
                 * Continues to the next account or goes back to list if account list is empty.
                 */
                vm.nextAccount = async function () {
                    await getCustacts();

                    if ($stateParams.custact_no > 0) {
                        return stateService.back();
                    }

                    const currentIndex = vm.carousel.index;

                    if (vm.model.custacts.length === 0) {
                        return onListViewSelected();
                    } else if (vm.model.custacts.length <= currentIndex) {
                        vm.carousel.index = vm.model.custacts.length - 1;
                        selectVoucher(vm.carousel.index);
                    } else {
                        vm.carousel.index = currentIndex;
                        selectVoucher(vm.carousel.index);
                    }
                };

                vm.tabsListOrShow = [
                    {
                        id: 'LIST',
                        label: 'bg_accountingprocessing_main_list',
                        onClick: onListViewSelected,
                    },
                    {
                        id: 'SHOW',
                        label: 'bg_accountingprocessing_main_show',
                        onClick: onCarouselViewSelected,
                    },
                ];


                vm.model = {
                    custactNo: $stateParams.custact_no,
                    tabs: {
                        selected: 'LIST',
                        buttons: vm.tabsListOrShow,
                    },
                    accountingFunctionTabs: {
                        selected: 'send',
                        buttons: [],
                    },
                    emailButtons: [
                        {
                            id: 'open',
                            title: 'open_email_in_tab',
                            icon: 'fas fa-external-link-alt',
                            onClick: () => {
                                gotoEmailview();
                            },
                        },
                    ],
                    searchButtons: [
                        {
                            id: 'erase',
                            icon: 'fas fa-eraser',
                            color: 'danger',
                            onClick: () => vm.onSearchTextChanged(''),
                        },
                    ],
                    documentButtons: [
                        {
                            id: 'sendemail',
                            icon: 'fas fa-envelope',
                            type: 'primary',
                            onClick: gotoNewEmail,
                        },
                        {
                            id: 'refresh',
                            icon: 'fas fa-sync',
                            type: 'primary',
                            onClick: () => getCustactDocuments(),
                        },
                        {
                            id: 'gotodoc',
                            icon: 'fas fa-search',
                            type: 'primary',
                            onClick: gotoCustactDocuments,
                        },
                        {
                            id: 'gotodocarc',
                            icon: 'fas fa-chevron-right',
                            type: 'primary',
                            onClick: gotoDocarc,
                        },
                    ],
                    customerButtons: [
                        {
                            id: 'goto',
                            icon: 'fas fa-chevron-right',
                            type: 'primary',
                            onClick: gotoCustomersMenu,
                        },
                    ],
                    supplierEmptyButtons: [
                        {
                            id: 'new',
                            icon: 'fas fa-plus',
                            type: 'primary',
                            onClick: openNewCustomerModal,
                        },
                    ],
                    supplierButtons: [
                        {
                            id: 'goto',
                            icon: 'fas fa-chevron-right',
                            type: 'primary',
                            onClick: gotoCustomersMenu,
                        },
                    ],
                    equipmentButtons: [
                        {
                            id: 'list',
                            icon: 'fas fa-bars',
                            color: 'primary',
                            onClick: gotoEquipments,
                        },
                    ],
                    labourButtons: [
                        {
                            id: 'erase',
                            icon: 'fas fa-eraser',
                            type: 'danger',
                            onClick: () => vm.onLabourChanged('0'),
                        },
                    ],
                    departmentButtons: [
                        {
                            id: 'erase',
                            icon: 'fas fa-eraser',
                            type: 'danger',
                            onClick: () => vm.onDepartmentChanged('0'),
                        },
                    ],
                    get: {},
                    custact: {},
                    custactDocuments: [],
                    custacts: [],
                    custactsDocuments: [],
                    businessCompanies: [],
                    accountingFunctions: [],
                    voucherTypes: [],
                    labours: [],
                    departments: [],
                    valutas: [],
                    history: [],
                    tabletQuery: window.matchMedia('(min-width: 768px)'),
                    showComments: true,
                    ready: false,
                };


                vm.locks = {
                    save: false,
                    delete: false,
                    sendApproval: false,
                };

                vm.numberOptions = {
                    valuta: { autoselect: true, decimals: 0 },
                    amount: { autoselect: true, decimals: 2 },
                    exchangeFactor: { autoselect: true, decimals: 6 },
                };

                vm.dateOptions = {
                    voucherdate: {
                        date: { show: true, disabled: false, align: 'left' },
                        hour: { show: false, disabled: false, align: 'right' },
                        minute: {
                            show: false,
                            disabled: false,
                            align: 'right',
                            interval: 1,
                        },
                    },
                    transdate: {
                        date: { show: true, disabled: false, align: 'left' },
                        hour: { show: false, disabled: false, align: 'right' },
                        minute: {
                            show: false,
                            disabled: false,
                            align: 'right',
                            interval: 1,
                        },
                    },
                    duedate: {
                        date: { show: true, disabled: false, align: 'left' },
                        hour: { show: false, disabled: false, align: 'right' },
                        minute: {
                            show: false,
                            disabled: false,
                            align: 'right',
                            interval: 1,
                        },
                    },
                };

                vm.isCommentsOpen = function () {
                    return vm.model.history.filter((comment) => comment.open !== false).length === 0;
                };

                vm.toggleCommentsView = function (setting) {
                    if (setting === '1') {
                        vm.model.custact.show_comments = '1';
                        vm.model.history = vm.model.history.map((comment) => ({ ...comment, open: true }))
                    } else {
                        vm.model.custact.show_comments = '0';
                    }
                }

                function onListViewSelected() {
                    vm.model.tabs.selected = 'LIST';
                    vm.model.tabs.buttons[1].label = 'bg_accountingprocessing_main_show';
                    vm.model.tabs.buttons = [...vm.model.tabs.buttons];

                    if (vm.popup) {
                        closePopup();
                    }

                    getAccountingFunctions();
                    return getCustacts();
                }

                function onCarouselViewSelected() {
                    vm.model.tabs.selected = 'SHOW';
                    vm.model.tabs.buttons[1].label = 'accountingprocessing_show_' + vm.model.get.accountingfunction;
                    vm.model.tabs.buttons = [...vm.model.tabs.buttons];
                    if (!vm.model.get.custact_no_active || vm.model.get.custact_no_active === '0') vm.model.get.custact_no_active = vm.model.custacts?.[0]?.item_id;
                    selectCustact(vm.model.get.custact_no_active);
                }

                vm.openImportModal = function (item) {
                    const modalRef = $uibModal.open({
                        component: 'accountingProcessingImportModal',
                        resolve: {
                            custactAcKeyno: function () {
                                return vm.model.custact.custactac_keyno;
                            },
                        },
                        size: 'pst-ninety',
                    });

                    modalRef.closed.then(function () {
                        vm.grid.gridfunc.rebind();
                        setSumDebitCredit();
                    });
                };

                function getCustactMatchUrl() {
                    return /*stateService.getHost() +*/ '/#/custactacmatch/' + vm.model.custact.custactac_keyno + '?ispopup'
                }

                async function openCustactMatchPopup() {
                    if (!vm.popup) { // if not already open
                        const size = await getCustactMatchPopupSize()

                        vm.popup = window.open(getCustactMatchUrl(), 'mozillaWindow', 'popup,width=' + size.width + ',height=' + size.height + ',screenX=' + size.x + ',screenY=' + size.y);

                        if (vm.popup) {

                            vm.popup.onresize = function (event) {
                                const width = event.target.innerWidth;
                                const height = event.target.innerHeight;
                                rememberCustactMatchPopupSize(width, height, event.target.screenX, event.target.screenY);
                            }

                            vm.popup.onbeforeunload = function (event) {
                            }

                            let intervalId = setInterval(async () => {
                                if (!vm.popup) {
                                    clearInterval(intervalId);
                                }



                                if (vm.popup?.closed) {
                                    vm.grid.gridfunc.rebind();
                                    clearInterval(intervalId);
                                    vm.popup = null;
                                }
                            }, 2000);
                        } else {
                            const modalRef = $uibModal.open({
                                component: 'custactAcMatch',
                                resolve: {
                                    custactAcKeyno: function () {
                                        return vm.model.custact.custactac_keyno;
                                    },
                                },
                                size: 'pst-ninety',
                            });

                            modalRef.closed.then(function () {
                                vm.grid.gridfunc.rebind();
                                setSumDebitCredit();
                            });

                            //modalService.show({
                            //    type: 'danger',
                            //    title: translations.error,
                            //    message: translations.remove_popup_window_blocker,
                            //    buttons: [{
                            //        label: translations.ok,
                            //        cssClass: 'btn-danger',
                            //        action: function (dialogItself) {
                            //            dialogItself.close();
                            //            vm.model.lockedSave = false;
                            //        }
                            //    }]
                            //});
                        }
                    }
                }



                async function selectCustact(custact_no) {
                    vm.model.ready = false;
                    vm.model.get.custact_no_active = custact_no;

                    if (vm.model.get.custact_no_active > '0') {
                        await Promise.all([getCustactDocuments(), getCustact()]);
                        setSumDebitCredit();
                        getLabours();
                        getDepartments();

                        if (!vm.model.custact) return;

                        if (vm.popup) {
                            vm.popup.location.replace(getCustactMatchUrl())
                        }

                        vm.model.get.docarc_keyno = vm.model.custactDocuments?.[0]?.item_id ?? '0';

                        vm.onDocarcKeynoChanged(vm.model.get.docarc_keyno);

                        vm.grid
                            .setLoadDataFunction({
                                method: 2089,
                                parameters: {
                                    custactac_keyno: vm.model.custact.custactac_keyno,
                                },
                            })
                            .setAddRowFunction({
                                method: null,
                                parameters: {
                                    custactac_keyno: vm.model.custact.custactac_keyno,
                                },
                                post: () => setSumDebitCredit()
                            })
                            .setSaveDataFunction({
                                method: null,
                                parameters: {
                                    custactac_keyno: vm.model.custact.custactac_keyno,
                                },
                                post: () => setSumDebitCredit()
                            })
                            .setRemoveRowFunction({
                                method: null,
                                parameters: {
                                    custactac_keyno: vm.model.custact.custactac_keyno,
                                },
                                post: () => setSumDebitCredit()
                            })
                            .setCustomToolbarButtons([
                                {
                                    name: 'import_voucherlines',
                                    text: 'w_accountingprocessing_import_voucherlines',
                                    func: vm.openImportModal,
                                    icon: 'far fa-file-import',
                                    cssClass: 'tt-button tt-button--secondary im-grid-btn-xs-r',
                                    translate: true,
                                    disabled: () => (vm.model.custact.acvoucherbunt_keyno > '0' ? 'hidden' : false),
                                },
                                {
                                    name: 'custactac_match',
                                    text: 'custactac_match',
                                    func: openCustactMatchPopup,
                                    icon: 'far fa-plus',
                                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                    translate: true,
                                    disabled: () => (vm.model.custact.acvouchertype_id !== 'IF' || vm.model.get.show_purchase_match !== '1' ? 'hidden' : false),
                                },
                            ]);

                        if (vm.model.custact.acvoucherbunt_keyno > '0') {
                            vm.grid.setNavigation({
                                newLine: false,
                                altNav: true,
                            });
                            vm.grid.setConfig({ shortcuts: false });
                        } else {
                            vm.grid.setNavigation({
                                newLine: true,
                                altNav: true,
                            });
                            vm.grid.setConfig({ shortcuts: true });
                        }

                        if (vm.model.custact.custact_no === vm.model.get.custact_no_active) setTimeout(() => (vm.model.ready = true), 100);
                    } else {
                        vm.model.custact = {};
                        vm.model.custactDocuments = [];
                        vm.model.ready = false;
                    }
                }

                // #region CAROUSEL

                vm.carousel = {
                    index: 0,
                    showDocument: false,
                };

                vm.isPreviousButtonDisabled = function () {
                    return vm.carousel.index <= 0;
                };

                vm.isNextButtonDisabled = function () {
                    return vm.carousel.index >= vm.model.custacts.length - 1;
                };

                vm.nextVoucher = async function () {
                    if (isIndexWithinRange(vm.model.custacts, vm.carousel.index + 1)) {
                        vm.carousel.index++;
                        selectVoucher(vm.carousel.index);
                    }
                };

                vm.previousVoucher = async function () {
                    if (isIndexWithinRange(vm.model.custacts, vm.carousel.index - 1)) {
                        vm.carousel.index--;
                        selectVoucher(vm.carousel.index);
                    }
                };

                async function selectVoucher(index) {
                    if (vm.model.get.custact_no_active !== vm.model.custacts[index]?.item_id && vm.model.get.custact_no_active > '0' && isIndexWithinRange(vm.model.custacts, index)) {
                        await selectCustact(vm.model.custacts[index]?.item_id);
                    }
                };

                function isIndexWithinRange(array, index) {
                    return array && array instanceof Array && index >= 0 && index < array.length;
                };

                // #endregion CAROUSEL

                // #region BOXES

                vm.boxes = {
                    height: '',
                    rememberId: 'accountingprocessing.boxes',
                    hasBtnGroup: true,
                    boxes: [
                        { item_id: uuid(), item_name: 'headview', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'bottomview', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'headview.carousel', type: 'v', size: '75', depth: 1 },
                        { item_id: uuid(), item_name: 'headview.head', type: 'v', size: '25', depth: 1 },
                        { item_id: uuid(), item_name: 'bottomview.grid', type: 'v', size: '100', depth: 2 },
                    ],
                };

                // #endregion BOXES

                // #region MULTIBUTTON

                vm.sendApproval = function () {
                    if (vm.model.get?.custact_no_active && vm.model.get.custact_no_active !== '0') {
                        vm.locks.sendApproval = true;

                        accountingProcessingService.sendApproval(vm.model.get.custact_no_active, 'approve', vm.model.get.accountingfunction).then((response) => {
                            if (response && response[0] && response[0].errorcode && response[0].errorcode !== '0') {
                                modalService.show({
                                    type: type || 'warning',
                                    title: title || '',
                                    message: message,
                                    buttons: [
                                        {
                                            label: label || 'OK',
                                            cssClass: cssClass || 'btn-warning',
                                            action: (dialogItself) => {
                                                dialogItself.close();
                                                vm.locks.sendApproval = false;
                                            },
                                        },
                                    ],
                                });
                            } else {
                                vm.nextAccount();
                            }
                        });
                    } else {
                        modalService.show({
                            type: 'warning',
                            title: 'warning',
                            message: '',
                            buttons: [
                                {
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: (dialogItself) => {
                                        dialogItself.close();
                                        vm.locks.sendApproval = false;
                                    },
                                },
                            ],
                        });
                    }

                    vm.locks.sendApproval = false;
                };

                vm.multiButton = [
                    {
                        label: 'accountingprocessing_approve',
                        id: uuid(),
                        isMain: true,
                        disabled: () => vm.locks.sendApproval,
                        onClick: vm.sendApproval,
                    },
                    {
                        label: 'accountingprocessing_approve',
                        id: uuid(),
                        icon: 'far fa-comment-alt-check',
                        isSecondary: true,
                        disabled: function () {
                            return false;
                        },
                        hidden: false,
                        onClick: vm.openAccountingprocessingApproveModal,
                    },
                ];

                // #endregion MULTIBUTTON

                // #region GRID

                vm.grid = new ttGridFactory({
                    rememberId: 'w_accountingprocessing.accountgrid',
                })
                    .setToolbar({
                        pdfExport: false,
                        excelExport: false,
                        add: true,
                        deleteBtn: true,
                        save: true,
                    })
                    .setConfig({
                        shortcuts: true,
                        navigation: { altNav: true, newLine: true },
                    })
                    .setKendoConfig({ aggregate: false })
                    .setOptionFunc((data) => optionfunc(data.data));

                function optionfunc(data) {
                    if (angular.isDefined(data.func) && data.func === 'OnCellClose' && ((Object.hasOwn(data, 'postSave') && data.postSave === true) || Object.hasOwn(data, 'postSave') === false)) {
                        setSumDebitCredit();
                    }
                }

                vm.gridAll = new ttGridFactory({
                    rememberId: 'w_accountingprocessing.custactgrid',
                }).setLoadDataFunction({
                    method: 3469,
                    parameters: () => ({ businessco_no: vm.model.get.businessco_no })
                });

                async function redrawGrid() {
                    let gridData = [];
                    if (vm.model.custact?.custactac_keyno) {
                        gridData = await accountingProcessingService.getGridData(vm.model.custact?.custactac_keyno);
                    }

                    let dataItems = [];
                    if (vm.grid?.gridfunc) {
                        dataItems = [...angular.copy(vm.grid.gridfunc.getDataItems())];
                    }

                    if (vm.model.custact?.custactac_keyno && vm.model.custact?.custactac_keyno === vm.grid?.dataTask?.loadData?.parameters?.custactac_keyno && vm.grid?.gridfunc && gridData.length > 0 && gridData.length === dataItems.length && Number(gridData[0]['custactac_keyno']) === Number(dataItems[0]['custactac_keyno'])) {
                        const newData = dataItems.map((item) => {
                            const gridDataRow = gridData.find((row) => Number(row.custactacline_keyno) === Number(item.custactacline_keyno));

                            if (gridDataRow) {
                                return { ...item, ...gridDataRow };
                            } else {
                                modalService.show({
                                    type: 'warning',
                                    title: '',
                                    message: '',
                                    buttons: [
                                        {
                                            label: 'ok',
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            },
                                        },
                                    ],
                                });

                                return { ...item };
                            }
                        });

                        vm.grid.gridfunc.setDataSource(newData);
                        vm.grid.gridfunc.refresh();
                    }
                }

                // #endregion GRID

                // #region GOTO

                function gotoNewEmail() {
                    stateService.newTab('emailcreate', {
                        webpage: 'workdetails',
                        argtype: 'custact_no',
                        argvalue: vm.model.custact.custact_no,
                    });
                }

                function gotoEmailview() {
                    stateService.newTab('emailview', {
                        email_keyno: vm.model.custact.custactDocument.item_id,
                    });
                }

                function gotoCustactDocuments() {
                    stateService.newTab('documents', {
                        argtype: 'custact_no',
                        argvalue: vm.model.get.custact_no_active,
                    });
                }

                function gotoDocarc() {
                    stateService.newTab('docarc', {
                        argtype: 'custact_no',
                        argvalue: vm.model.get.custact_no_active,
                        docarc_keyno: vm.model.get.docarc_keyno,
                    });
                }

                function openNewCustomerModal() {
                    const modalRef = $uibModal.open({
                        component: 'customerEdit',
                        resolve: {
                            custNo: () => '0',
                        },
                        size: 'pst-ninetyfive',
                        openedClass: 'modal-content',
                    });

                    modalRef.result.then((data) => {
                        if (!!data) {
                            vm.model.custact.cust_no = '' + data;
                            return economyService.getCustomerEdit({ cust_no: vm.model.custact.cust_no }).then(function (response) {
                                vm.model.custact.cust_no = response[0]?.cust_no ?? '';
                                vm.model.custact.acc_supp_no = response[0]?.acc_supp_no ?? '';
                                vm.model.custact.supplier_name = response[0]?.cust_name ?? '';
                                vm.model.custact.bankaccount = response[0]?.bankaccount ?? '';
                                vm.model.custact.bic = response[0]?.bic ?? '';
                                vm.model.custact.iban = response[0]?.iban ?? '';

                                //vm.model.custact.supplier_name = item?.item_name ?? '';
                                //vm.model.custact.cust_no = item?.item_id ?? '0';
                                //vm.model.custact.acc_supp_no = item?.acc_supp_no ?? '0';

                                //if (item) {
                                //    vm.model.custact.bankaccount = item.bankaccount;
                                //    vm.model.custact.bic = item.bic;
                                //    vm.model.custact.iban = item.iban;
                                //}

                                vm.saveCustactAccount();
                            });
                        }
                    });
                }

                function gotoFindCustomers() {
                    stateService.go('findcustomers');
                }

                function gotoCustomersMenu() {
                    stateService.go('customersmenu', {
                        cust_no: vm.model.custact.cust_no,
                    });
                }

                function gotoEquipments() {
                    stateService.go('equipments', {});
                }

                vm.gotoVoucherImports = function () {
                    stateService.go('documents', {
                        argtype: 'voucherimport',
                        argvalue: '0',
                    });
                };

                // #endregion GOTO

                // #region SEARCH CUSTOMER

                vm.searchCustomer = function () {
                    const parms = {
                        cust_name: vm.model.custact.cust_name,
                        filtertype: '',
                        filtervalue: '',
                        custtype: 'C',
                        lastselect: 'SEARCH',
                    };

                    return typeaheadService.lookUpCustomerAccount(parms);
                };

                vm.onCustomerSelected = function (item) {
                    vm.model.custact.cust_name = item?.item_name ?? '';
                    vm.model.custact.cust_no = item?.item_id ?? '0';
                    vm.model.custact.acc_cust_no = item?.acc_cust_no ?? '9';
                    custactColChanged('cust_no');
                    //vm.saveCustactAccount();
                };

                // #endregion SEARCH CUSTOMER

                // #region SEARCH SUPPLIER

                vm.searchSupplier = function () {
                    const parms = {
                        cust_name: vm.model.custact.supplier_name,
                        filtertype: '',
                        filtervalue: '',
                        custtype: 'S',
                        lastselect: 'SEARCH',
                    };

                    return typeaheadService.lookUpCustomerAccount(parms);
                };

                vm.onSupplierSelected = function (item) {
                    vm.model.custact.supplier_name = item?.item_name ?? '';
                    vm.model.custact.cust_no = item?.item_id ?? '0';
                    vm.model.custact.acc_supp_no = item?.acc_supp_no ?? '0';

                    if (item) {
                        vm.model.custact.bankaccount = item.bankaccount;
                        vm.model.custact.bic = item.bic;
                        vm.model.custact.iban = item.iban;
                    }

                    custactColChanged('cust_no');
                    //vm.saveCustactAccount();
                };

                // #endregion SEARCH SUPPLIER

                // #region SEARCH EQUIPMENT

                vm.searchEquipment = function () {
                    return consignmentPlanService.searchEquipments(vm.model.custact.equipment_name, vm.model.custact.businessco_no);
                };

                vm.onEquipmentSelected = function (item) {
                    vm.model.custact.equipment_name = item?.item_name ?? '';
                    vm.model.custact.equipment_id = item?.item_id ?? '0';
                    vm.saveCustactAccount();
                };

                // #endregion SEARCH EQUIPMENT

                // #region SEARCH PROJECT

                vm.searchProject = function () {
                    const parms = {
                        project_name: vm.model.custact.project_name,
                        businessco_no: vm.model.custact.businessco_no,
                    };

                    return accountingProcessingService.lookUpSearchProjects(parms);
                };

                vm.onProjectSelected = function (item) {
                    vm.model.custact.project_name = item?.item_name ?? '';
                    vm.model.custact.project_keyno = item?.item_id ?? '';
                    vm.saveCustactAccount();
                };

                // #endregion SEARCH EQUIPMENT

                // #region CHANGE

                vm.onBusinessCoChanged = async function (value) {
                    if (vm.model.get.business_no !== value) {
                        vm.model.get.businessco_no = value;

                        (async function () {
                            await rememberBusinessCo();
                            getAccountingFunctions();
                        })();

                        if (vm.model.get.accountingfunction === 'all') {
                            vm.gridAll.gridfunc?.read?.();
                        } else {
                            await getCustacts();
                        }

                        selectCustact(vm.model.custacts?.[0]?.item_id ?? '0');
                    }
                };

                vm.onAccountingFunctionChanged = function (value) {
                    if (vm.model.get?.accountingfunction !== value) {
                        vm.model.get.accountingfunction = value;
                        vm.carousel.index = 0;

                        if (value) {
                            rememberAccountingFunction();

                            if (value === 'all') {
                                vm.model.tabs.buttons = [vm.tabsListOrShow[0]];
                            } else {
                                vm.model.tabs.buttons = [...vm.tabsListOrShow];
                                getCustacts();
                            }
                        }
                    }
                };

                vm.onSearchTextChanged = function (value) {
                    vm.model.get.searchtext = value;
                    rememberSearchText();
                };

                vm.onDocarcKeynoChanged = async function (value) {
                    vm.model.get.docarc_keyno = value;

                    vm.carousel.showDocument = false;

                    let custactDocument = vm.model.custactDocuments.find((doc) => doc.item_id === value);

                    if (custactDocument) {
                        if (custactDocument.extension !== 'msg') {
                            custactDocument = {
                                ...custactDocument,
                                ...(await fileService.getFileDetails({
                                    fileName: custactDocument.docfilename,
                                    maxWidth: 1200,
                                    maxHeight: 600,
                                })),
                            };
                        }

                        vm.model.custact.custactDocument = custactDocument;
                        vm.carousel.showDocument = true;
                    }
                };

                vm.onVoucherTypeChanged = async function (value) {
                    if (vm.model.custact.acvouchertype_id !== value) {
                        vm.model.custact.acvouchertype_id = value;
                        await getVoucherType();
                        vm.grid.gridfunc.refreshToolbarBtnDisability();

                        const rebind = vm.grid.gridfunc.rebind();

                        if (rebind instanceof Promise) {
                            await rebind;
                        }

                        vm.saveCustactAccount();
                    }
                };

                vm.onCustactBusinessCoChanged = async function (value) {
                    if (vm.model.custact.businessco_no !== value) {
                        vm.model.custact.businessco_no = value;
                        vm.saveCustactAccount();
                    }
                };

                vm.onLabourChanged = function (value) {
                    if (vm.model.custact?.labour_no !== value) {
                        vm.model.custact.labour_no = value;
                        vm.saveCustactAccount();
                    }
                };

                vm.onDepartmentChanged = function (value) {
                    if (vm.model.custact?.department_id !== value) {
                        vm.model.custact.department_id = value;
                        vm.saveCustactAccount();
                    }
                };

                vm.onValutaChanged = async function (value) {
                    if (vm.model.custact?.valuta_id !== value) {
                        vm.model.custact.valuta_id = value;
                        await getExchangeRate();
                        vm.saveCustactAccount();
                    }
                };

                vm.onAmountInvvalAbsChanged = function (value) {
                    if (vm.model.custact?.amount_invval_abs !== us.parseNumber(value) && parseFloat(vm.model.custact?.amount_invval_abs).toString() !== 'NaN') {
                        vm.model.custact.amount_invval_abs = us.parseNumber(value);
                        recalcAmountLocValAbs();
                        vm.saveCustactAccount();
                    }
                };

                vm.onExchangeRateChanged = function (value) {
                    if (vm.model.custact?.exchange_rate !== us.parseNumber(value) && parseFloat(vm.model.custact?.exchange_rate).toString() !== 'NaN') {
                        vm.model.custact.exchange_rate = us.parseNumber(value);
                        recalcExchangeFactor();
                        recalcAmountLocValAbs();
                        vm.saveCustactAccount();
                    }
                };

                let voucherDateTimer = null;

                vm.onVoucherDateChanged = async function (value) {

                    vm.model.custact.voucherdate = value;

                    if (value) {
                        custactColChanged('voucherdate');
                    }
                };

                vm.onTransDateChanged = function (value) {
                    vm.model.custact.transdate = value;

                    if (value) {
                        custactColChanged('transdate');
                        //vm.saveCustactAccount();
                    }
                };

                vm.onDueDateChanged = function (value) {
                    vm.model.custact.duedate = value;

                    if (value) {
                        //custactColChanged('duedate');
                        vm.saveCustactAccount();
                    }
                };

                let colChangedTimer = null;

                async function custactColChanged(colname) {
                    if (colChangedTimer) {
                        clearTimeout(colChangedTimer);
                        colChangedTimer = null;
                    }

                    colChangedTimer = setTimeout(async () => {

                        const data = (await accountingProcessingService.sendCustactAcColchanged({ ...vm.model.custact, colname_changed: colname }))[0];

                        vm.model.custact = { ...vm.model.custact, ...data };

                        vm.saveCustactAccount();
                    }, 50)
                }

                // #endregion CHANGE

                // #region RECALCULATION FUNCTIONS

                function recalcAmountLocValAbs() {
                    vm.model.custact.amount_locval_abs = (us.parseNumber(vm.model.custact.amount_invval_abs) * us.parseNumber(vm.model.custact.exchange_factor)).toFixed(2);
                }

                function recalcExchangeFactor() {
                    vm.model.custact.exchange_factor = (us.parseNumber(vm.model.custact.exchange_rate) / us.parseNumber(vm.model.custact.valuta_pr)).toFixed(6);
                }

                async function setSumDebitCredit() {
                    const data = await accountingProcessingService.getSumDebitCredit({
                        custact_no: vm.model.get.custact_no_active,
                    });
                    const sum = parseFloat(data[0].amount);
                    const sum_vat = parseFloat(data[0].amount_vat);

                    const formatter = Intl.NumberFormat('nb', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });

                    vm.model.custact.sum_debit_credit = formatter.format(sum).replace(',', '.');

                    vm.model.custact.sum_vat = formatter.format(sum_vat).replace(',', '.');
                }
                // #endregion RECALCULATION FUNCTIONS

                // #region REMEMBER

                async function rememberBusinessCo() {
                    if (vm.model.get?.businessco_no > '0') {
                        return rememberService.remember('w_accountingprocessing.businessco_no', vm.model.get.businessco_no);
                    }
                }

                async function rememberAccountingFunction() {
                    if (vm.model.get?.accountingfunction) {
                        return rememberService.remember('w_accountingprocessing.accountingfunction', vm.model.get.accountingfunction);
                    }
                }

                async function rememberSearchText() {
                    if (vm.model.get?.searchtext !== null && vm.model.get?.searchtext !== undefined) {
                        return rememberService.remember('w_accountingprocessing.searchtext', vm.model.get.searchtext);
                    }
                }

                let rememberCustactMatchPopupSizeTimer = null;

                async function rememberCustactMatchPopupSize(width, height, x, y) {
                    if (rememberCustactMatchPopupSizeTimer) {
                        clearTimeout(rememberCustactMatchPopupSizeTimer);
                        rememberCustactMatchPopupSizeTimer = null;
                    }

                    rememberCustactMatchPopupSizeTimer = setTimeout(() => {

                        if (!isNaN(Number(width)) && Number(width) >= 0 && !isNaN(Number(height)) && Number(height) >= 0 && !isNaN(Number(x)) && Number(x) >= 0 && !isNaN(Number(y)) && Number(x) >= 0) {
                            const widthPercentage = width / window.screen.availWidth * 100;
                            const heightPercentage = height / window.screen.availHeight * 100;
                            const xPercentage = x / window.screen.availWidth * 100;
                            const yPercentage = y / window.screen.availHeight * 100;

                            //const sizeString = btoa(JSON.stringify({ width: widthPercentage, height: heightPercentage, x: xPercentage, y: yPercentage }));
                            const sizeString = btoa(JSON.stringify({ width: width, height: height, x: x, y: y }));

                            return rememberService.remember('w_accountingprocessing.custactmatch_popup_size.' + screen.width + screen.height, sizeString);
                        }
                    }, 500);
                }

                async function getCustactMatchPopupSize() {
                    const response = (await rememberService.getLastStatus('w_accountingprocessing.custactmatch_popup_size.' + screen.width + screen.height))[0].variablevalue;

                    if (response && typeof response === 'string') {
                        const size = JSON.parse(atob(response));

                        const widthPixel = !!size && !isNaN(Number(size.width)) ? Math.round(Number(size.width) * window.screen.availWidth / 100) : 1100;
                        const heightPixel = !!size && !isNaN(Number(size.height)) ? Math.round(Number(size.height) * window.screen.availHeight / 100) : 720;
                        const xPixel = !!size && !isNaN(Number(size.x)) && size.x >= 0 ? Math.round(Number(size.x) * window.screen.availWidth / 100) : 150;
                        const yPixel = !!size && !isNaN(Number(size.y)) && size.y >= 0 ? Math.round(Number(size.y) * window.screen.availHeight / 100) : 50;
                        return size;
                        //return {
                        //    width: widthPixel,
                        //    height: heightPixel,
                        //    x: xPixel,
                        //    y: yPixel,
                        //}
                    } else {
                        return {
                            width: 1100,
                            height: 720,
                            x: 150,
                            y: 50,
                        }
                    }
                }

                // #endregion REMEMBER

                // #region SAVE FUNCTIONS

                vm.changeAndSaveCustact = function (value, model) {
                    if (vm.model.custact[model] !== value) {
                        if (saveTimer) {
                            clearTimeout(saveTimer);
                            saveTimer = null;
                        }

                        vm.model.custact[model] = value;
                        /* custactColChanged(model);*/
                        vm.saveCustactAccount();
                    }
                };

                let saveTimer = null;

                vm.saveCustactAccount = function (config) {
                    if (!vm.model.ready || !vm.model.custact || !vm.model.custact?.custactac_keyno || vm.model.custact.acvoucherbunt_keyno !== '0') return;
                    const custactCopy = angular.copy(vm.model.custact);
                    vm.model.lockedSave = true;

                    return new Promise((resolve) => {
                        if (saveTimer) {
                            clearTimeout(saveTimer);
                            saveTimer = null;
                        }

                        saveTimer = setTimeout(async () => {
                            try {
                                const data = await accountingProcessingService.saveObj(custactCopy);

                                if (data[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: 'Varsel',
                                        message: data[0].errormessage,
                                        buttons: [
                                            {
                                                label: 'OK',
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();
                                                },
                                            },
                                        ],
                                    });
                                } else {
                                    vm.model.custact.validationmessage = data[0].validationmessage;
                                    setSumDebitCredit();
                                    if (config?.rebind === true) {
                                        vm.grid.gridfunc.rebind();
                                    } else {
                                        //redrawGrid(); ag grid can do this.
                                        setTimeout(redrawGrid, 150); // but kendo needs this.
                                    }
                                }
                                return resolve();
                            } catch (error) {
                                //console.dir(error);
                            } finally {
                                vm.locks.save = false;
                            }
                        }, 250);
                    });
                };

                vm.deleteCustact = async function () {
                    vm.locks.delete = true;

                    try {
                        const response = await accountingProcessingService.deleteItem(vm.model.custact);

                        if (response[0]?.errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [
                                    {
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        },
                                    },
                                ],
                            });
                        } else {
                            stateService.back();
                        }
                    } finally {
                        vm.locks.delete = false;
                    }
                };

                vm.duplicateVoucher = function () {
                    vm.model.locked = true;

                    accountingProcessingService.duplicateVoucher(vm.model.custact?.custactac_keyno).then(function (data) {
                        if (data[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: 'Varsel',
                                message: data[0].errormessage,
                                buttons: [
                                    {
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.locked = false;
                                        },
                                    },
                                ],
                            });
                        } else {
                            //stateService.back();
                            modalService.show({
                                type: 'success',
                                title: 'Varsel',
                                message: data[0].errormessage,
                                buttons: [
                                    {
                                        label: 'OK',
                                        cssClass: 'btn-success',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.locked = false;
                                        },
                                    },
                                ],
                            });

                            vm.model.locked = false;
                        }
                    });
                };

                vm.showDocument = function () {
                    if (vm.carousel.showDocument !== true) {
                        $timeout(vm.showDocument, 200);

                        return;
                    }

                    if (vm.model.custact.custactDocument?.contentType?.substring(0, 16) === 'application/vnd.') {
                        if (angular.isFunction(vm.carousel.view.officeControl.open) !== true) return;

                        // BJS 20241213 - Lagt til clientSessionId
                        vm.carousel.view.officeControl.open(vm.model.custact.custactDocument.fileItemId, 'view', appConfig.clientSessionId);
                    } else if (vm.model.custact.custactDocument?.extension === 'msg') {
                        gotoEmailview();
                    } else {
                        var tokenLink = fileService.getTokenLink(vm.model.custact.custactDocument.imageLink);

                        switch (vm.model.custact.custactDocument.contentType) {
                            case 'application/pdf':
                                window.open(tokenLink);
                                break;
                            default:
                                var win = window.open('');

                                win.document.body.innerHTML = '<img src="' + tokenLink + '"/>';
                                break;
                        }
                    }
                };

                vm.reverseVoucher = function () {
                    vm.reverseVoucherModel = {
                        custactac_keyno: vm.model.custact.custactac_keyno,
                        transdate: vm.model.custact.transdate,
                        voucherdate: vm.model.custact.voucherdate,
                        acvouchertype_id: vm.model.custact.acvouchertype_id,
                    };

                    setTimeout(vm.openAccountingprocessingReverseModal, 50);
                };

                // #endregion SAVE FUNCTION

                // #region GETTERS

                async function getAccountingProcessing() {
                    vm.model.get = (await accountingProcessingService.loadAccountingProcessingGet(vm.model.custactNo))[0];
                }

                async function getCustact() {
                    vm.model.custact = null;

                    if (vm.model.get.custact_no_active > '0') {
                        const res = (await accountingProcessingService.getAccountingProcessingCustomerActivity(vm.model.get.custact_no_active))[0];

                        if (res.custact_no === vm.model.get.custact_no_active) {
                            vm.model.custact = res;
                            if (vm.model.custact.nbr_of_custactdialogs > '0') {
                                getCustactHistory(vm.model.custact.custact_no);
                            }
                        }

                    }
                }

                async function getCustactHistory(custactNo) {
                    vm.model.history = [];
                    let comments = (await accountingProcessingAttestationModalService.getAttestantsLog(custactNo)).filter((event) => !!event.messagetext).map((event) => { event.open = true; return event; });
                    vm.model.history = comments;
                }

                async function getCustacts() {
                    vm.model.custacts = [];

                    if (vm.model?.get?.businessco_no > '0' && vm.model.get?.accountingfunction?.trim()) {
                        vm.model.custacts = await accountingProcessingService.loadAccountingProcessingCustactList(vm.model.get);
                    }
                }

                async function getCustactDocuments(custactNo) {
                    if (custactNo) {
                        return await accountingProcessingService.loadDocumentsList('custact_no', custactNo, '', 'a', '0');
                    }

                    const res = await accountingProcessingService.loadDocumentsList('custact_no', vm.model.get.custact_no_active, '', 'a', '0');

                    if (res?.[0]?.custact_no === vm.model.get.custact_no_active) {
                        vm.model.custactDocuments = res;
                    }
                }


                async function getAccountingFunctions() {
                    vm.model.accountingFunctions = await accountingProcessingService.listAccountingProcessingFunctions();
                    vm.model.accountingFunctionTabs.buttons = [...vm.model.accountingFunctions.map((func) => {
                        return {
                            id: func.accountingfunction_id,
                            label: func.accountingfunction_name,
                            onClick: () => (vm.model.get.custact_no_active = '0'),
                        };
                    }),
                    {

                        id: 'all',
                        label: 'all',
                        onClick: () => (vm.model.get.custact_no_active = '0'),
                    }
                    ];
                }

                async function getBusinessCompanies() {
                    vm.model.businessCompanies = await logisticService.listBusinessCompanies();
                }

                async function getVoucherTypes() {
                    vm.model.voucherTypes = await accountingProcessingService.listAcVoucherTypes();
                }

                async function getVoucherType() {
                    if (vm.model.custact.acvouchertype_id > '0') {
                        const voucherTypeData = (await accountingProcessingService.changeAcVoucherType(vm.model.custact.acvouchertype_id))[0];

                        if (voucherTypeData) {
                            vm.model.custact = {
                                ...vm.model.custact,
                                ...voucherTypeData,
                            };
                        }
                    }
                }

                async function getLabours() {
                    vm.model.labours = [];

                    if (vm.model.custact?.businessco_no > '0') {
                        vm.model.labours = await timeRegisterService.loadTimeRegisterLabourList(vm.model.custact.businessco_no);
                    }
                }


                async function getDepartments() {
                    vm.model.departments = [];

                    if (vm.model.custact?.businessco_no > '0') {
                        vm.model.departments = await logisticService.listDepartments(vm.model.custact.businessco_no);
                    }
                }

                async function getValutas() {
                    vm.model.valutas = await economyService.listValutas();
                }

                async function getExchangeRate() {
                    if (vm.model.custact?.base_currency_id > '0' && vm.model.custact?.valuta_id && vm.model.custact?.valuta_id > '0') {
                        vm.model.custact = {
                            ...vm.model.custact,
                            ...(await accountingProcessingService.getExchangeRate(vm.model.custact.base_currency_id, vm.model.custact.valuta_id))[0],
                        };
                        recalcAmountLocValAbs();
                    }
                }

                // #endregion GETTERS

                async function translate() {
                    let data = await translateService.translateBatch(translations);

                    Object.keys(translations).forEach((key) => {
                        if (data?.[key]) translations[key] = data[key];
                    });
                }

                async function closePopup() {
                    if (vm.popup) {
                        await rememberCustactMatchPopupSize(vm.popup.innerWidth, vm.popup.innerHeight, vm.popup.screenX, vm.popup.screenY);
                        vm.popup.close();
                    }
                }

                async function popupClosed(event) {
                    if (event.origin === window.location.origin && event.data === 'popupClosed') {
                        await rememberCustactMatchPopupSize(vm.popup.innerWidth, vm.popup.innerHeight, vm.popup.screenX, vm.popup.screenY);
                        vm.popup = null;
                        vm.grid.gridfunc.rebind();
                        setSumDebitCredit();
                    }
                }

                vm.$onInit = async function () {
                    translate();
                    getAccountingFunctions();
                    getBusinessCompanies();
                    getVoucherTypes();
                    getValutas();
                    await getAccountingProcessing();

                    if (vm.model.get.accountingfunction === 'all') {
                        vm.model.tabs.buttons = [vm.tabsListOrShow[0]];
                    }

                    vm.grid.setRememberId(vm.model.get.grid_view_id);

                    await getCustacts();

                    if (vm.model.get.custact_no_active === '0') {
                        vm.model.get.custact_no_active = vm.model.custacts?.[0]?.item_id ?? '0';
                    }

                    if (vm.model.get.custact_no_active > '0') {
                        await selectCustact(vm.model.get.custact_no_active);
                    }

                    window.addEventListener('beforeunload', closePopup);
                    window.addEventListener('message', popupClosed);
                };

                vm.$onDestroy = function () {
                    window.removeEventListener('beforeunload', closePopup);
                    window.removeEventListener('message', popupClosed);
                    closePopup();
                }

                // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
            },
        ],
    });
})();
