import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Directive({
    selector: '[ttTranslateWord]',
})
export class TranslateWordDirective implements OnInit, OnChanges {
    /**
     * The words to be translated and appended to the element.
     */
    @Input()
    public ttTranslateWord: string = '';
    private id = crypto.randomUUID();

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private translate: TranslateService) {}

    private async addTranslationAsElementChild() {
        let translation = await this.translate.translate(this.ttTranslateWord);
        let textSpan: HTMLSpanElement | null = document.getElementById(this.id);

        if (!textSpan) {
            textSpan = this.renderer.createElement('span');
            this.renderer.setAttribute(textSpan, 'id', this.id);
        } else {
            this.renderer.removeChild(this.elementRef.nativeElement, textSpan);
        }

        this.renderer.setProperty(textSpan, 'textContent', translation);
        this.renderer.appendChild(this.elementRef.nativeElement, textSpan);
    }

    ngOnInit(): void {
        this.addTranslationAsElementChild();
    }

    ngOnChanges(changes: SimpleChanges): void {}
}
