import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { AfterContentInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Class, CoreComponentService, LabelView, Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { RememberService } from '@app/core/services/remember.service';

/**
 * Represents a form field with frequently used and similar attributes between the different fields. Does NOT contain a field itself, but used to create specific field.
 */
@Component({
    selector: 'tt-form-field-base',
    templateUrl: './form-field-base.component.html',
    styleUrls: ['./form-field-base.component.css'],
})
export class FormFieldBaseComponent extends ComponentBaseComponent implements OnInit, OnChanges, AfterContentInit {
    /**
     * The id to store the model with using 616. If no id is provided the model will not be stored.
     */
    @Input()
    public ttRememberId?: string;

    /**
     * Milliseconds between the storing of each change if ttRememberId is provided.
     *
     * @default 500
     */
    @Input()
    public set ttRememberDebounceTime(value: NumberInput) {
        this._rememberDebounceTime = coerceNumberProperty(value);
    }
    public get ttRememberDebounceTime(): number {
        return this._rememberDebounceTime;
    }
    private _rememberDebounceTime: number = 500;

    /**
     * Whether the field should not retrieve the last stored selected id upon initialization and only remember the new selected id.
     *
     * @default true
     */
    @Input()
    public set ttOnlyRemember(value: BooleanInput) {
        this._onlyRemember = coerceBooleanProperty(value);
    }
    public get ttOnlyRemember(): boolean {
        return this._onlyRemember;
    }
    private _onlyRemember = true;

    /**
     * The label to display for the form-field. Always required, use label-view to hide a label.
     */
    @Input()
    ttLabel: string = '';

    /**
     * The view of the label.
     */
    @Input()
    ttLabelView: LabelView = 'auto';

    /**
     * Whether or not the main label of the form-field should be translated.
     *
     * @default true
     */
    @Input()
    get ttTranslateLabel(): boolean {
        return this._translateLabel;
    }
    set ttTranslateLabel(value: BooleanInput) {
        this._translateLabel = coerceBooleanProperty(value);
    }
    private _translateLabel: boolean = true;

    /**
     * Secondary label for the form-field, typically helper text.
     */
    @Input()
    ttSublabel: string = '';

    /**
     * Whether or not the sub-label of the form-field should be translated.
     *
     * @default false
     */
    @Input()
    get ttTranslateSublabel(): boolean {
        return this._translateSublabel;
    }
    set ttTranslateSublabel(value: BooleanInput) {
        this._translateSublabel = coerceBooleanProperty(value);
    }
    private _translateSublabel: boolean = false;

    /**
     * Text to display in a toggle tip next to the label.
     */
    @Input()
    public ttToggleTipText: string = '';

    /**
     * Whether the text provided in the toggle tip should be translated.
     *
     * @default true
     */
    @Input()
    get ttTranslateToggleTipText(): boolean {
        return this._translateToggleTipText;
    }
    set ttTranslateToggleTipText(value: BooleanInput) {
        this._translateToggleTipText = coerceBooleanProperty(value);
    }
    private _translateToggleTipText: boolean = true;

    /**
     * The element id on the form field element. Used to linking label to field.
     */
    @Input()
    ttFieldId: string = '';

    /**
     * Whether the form-field is required or not, displays a red start in label if it is.
     */
    @Input()
    get ttRequired(): boolean {
        return this._required;
    }
    set ttRequired(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }
    private _required: boolean = false;

    /**
     * Whether the form-field is a readonly. Will be uneditable but readable.
     */
    @Input()
    get ttReadonly(): boolean {
        return this._readonly;
    }
    set ttReadonly(value: BooleanInput) {
        this._readonly = coerceBooleanProperty(value);
    }
    private _readonly: boolean = false;

    /**
     * Whether the form-field is disabled. Will be uneditable and less readable.
     */
    @Input()
    get ttDisabled(): boolean {
        return this._disabled;
    }
    set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled: boolean = false;

    /**
     * Whether the form fields state is invalid or not.
     */
    @Input()
    get ttInvalid(): boolean {
        return this._invalid;
    }
    set ttInvalid(value: BooleanInput) {
        this._invalid = coerceBooleanProperty(value);
    }
    private _invalid: boolean = false;

    /**
     * Text to display when the form-field state is invalid.
     */
    @Input()
    public ttInvalidText: string = '';

    /**
     * Whether to translate the invalid text of the form-field.
     */
    @Input()
    get ttTranslateInvalidText(): boolean {
        return this._translateInvalidText;
    }
    set ttTranslateInvalidText(value: BooleanInput) {
        this._translateInvalidText = coerceBooleanProperty(value);
    }
    private _translateInvalidText: boolean = false;

    /**
     * Whether the input field can be tabbed to, default is true.
     */
    @Input()
    get ttTabable(): boolean {
        return this._tabable;
    }
    set ttTabable(value: BooleanInput) {
        this._tabable = coerceBooleanProperty(value);
    }
    private _tabable: boolean = true;

    @Input()
    ttInputType: 'progress' | 'input' | 'select' | 'checkbox' | 'textarea' | 'multi-select' | 'datetime' | 'file-uploader' | 'date-range' = 'input';

    /**
     * Custom styling for the form-field element.
     */
    @Input()
    ttStyle?: Style;

    @ViewChild('groupRef')
    public groupRef?: ElementRef<HTMLDivElement>;

    /**
     * Translations for the different text-values used in form-field.
     */
    translations: { [key: string]: string } = {
        label: '',
        sublabel: '',
        invalidtext: '',
        toggletipText: '',
    };

    /**
     * Inline style for various different elements of the form field.
     */
    style: Style = {
        container: {},
        group: {},
        base: {},
        label: {},
        sublabel: {},
        invalidtext: {},
        icon: {},
    };

    /**
     * Classes for various different elements of the form field.
     */
    class: Class = {
        container: '',
        group: '',
        base: '',
        label: '',
        sublabel: '',
        invalidtext: '',
    };

    labelOnTop = false;

    public rememberSubject = new Subject<unknown>();

    private rememberSubscription?: Subscription;

    constructor(public layoutService: LayoutService, public coreComponentService: CoreComponentService, public translateService: TranslateService, public remember: RememberService) {
        super();
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
                this.style['group'] = {};
                this.style['group'].fontSize = info.fontSizes.textSize;
                this.labelOnTop = info.labelAlwaysOnTop;
                this.setClasses(info.labelAlwaysOnTop);
            }
        });
    }

    protected rememberModel(value: unknown) {
        if (!!this.ttRememberId) {
            this.remember.remember(this.ttRememberId, value);
        }
    }

    /**
     * Sets the inline style of the component.
     *
     * @param ttStyle the customizable inline style.
     */
    public setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, mainElement: 'input' });
    }

    private setClasses(labelAlwaysOnTop: boolean) {
        this.class['base'] = this.coreComponentService.getBaseLabelViewClass(labelAlwaysOnTop, this.ttLabelView);
    }

    ngAfterContentInit(): void {
        this.setStyle();
    }
    ngOnInit(): void {
        this.setStyle();

        // console.log('this.ttInvalid :>> ', this.ttInvalid);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttOnlyRemember']) {
            this.ttOnlyRemember = changes['ttOnlyRemember'].currentValue;
        }

        if (changes['ttRememberDebounceTime']) {
            this.ttRememberDebounceTime = changes['ttRememberDebounceTime'].currentValue;
        }

        if (changes['ttRememberId'] || changes['ttRememberDebounceTime']) {
            this.rememberSubscription?.unsubscribe?.();

            if (this.ttRememberId) {
                this.rememberSubscription = this.rememberSubject.pipe(debounceTime(this.ttRememberDebounceTime)).subscribe({ next: (value) => this.rememberModel(value) });
            }
        }

        if (changes?.['ttTranslateLabel']) {
            this.ttTranslateLabel = changes['ttTranslateLabel'].currentValue;
        }

        if (changes?.['ttTranslateSublabel']) {
            this.ttTranslateSublabel = changes['ttTranslateSublabel'].currentValue;
        }

        if (changes?.['ttTranslateInvalidText']) {
            this.ttTranslateInvalidText = changes['ttTranslateInvalidText'].currentValue;
        }

        if (changes?.['ttTranslateToggleTipText']) {
            this.ttTranslateToggleTipText = changes['ttTranslateToggleTipText'].currentValue;
        }

        if (changes?.['ttLabel']?.currentValue && typeof changes['ttLabel'].currentValue === 'string' && changes['ttLabel'].currentValue !== changes['ttLabel']?.previousValue) {
            if (this.ttTranslateLabel) {
                this.translations['label'] = await this.translateService.translate(changes['ttLabel'].currentValue);
            } else {
                this.translations['label'] = changes['ttLabel'].currentValue;
            }
        }

        if (changes?.['ttLabelView']?.currentValue) {
            this.setClasses(this.labelOnTop);
        }

        if (changes?.['ttSublabel']?.currentValue && typeof changes['ttSublabel'].currentValue === 'string' && changes['ttSublabel'].currentValue !== changes['ttSublabel']?.previousValue) {
            if (this.ttTranslateSublabel) {
                this.translations['sublabel'] = await this.translateService.translate(changes['ttSublabel'].currentValue);
            } else {
                this.translations['sublabel'] = changes['ttSublabel'].currentValue;
            }
        }

        if (changes?.['ttInvalidText']?.currentValue && typeof changes['ttInvalidText'].currentValue === 'string' && changes['ttInvalidText'].currentValue !== changes['ttInvalidText']?.previousValue) {
            if (this.ttTranslateInvalidText) {
                this.translations['invalidtext'] = await this.translateService.translate(changes['ttInvalidText'].currentValue);
            } else {
                this.translations['invalidtext'] = changes['ttInvalidText'].currentValue;
            }
        }

        if (changes?.['ttToggleTipText']?.currentValue && typeof changes['ttToggleTipText'].currentValue === 'string' && changes['ttToggleTipText'].currentValue !== changes['ttToggleTipText']?.previousValue) {
            if (this.ttTranslateToggleTipText) {
                this.translations['toggletipText'] = await this.translateService.translate(changes['ttToggleTipText'].currentValue);
            } else {
                this.translations['toggletipText'] = changes['ttToggleTipText'].currentValue;
            }
        }

        // if (changes['ttInvalid']) {
        //     this.ttInvalid = changes['ttInvalid'].currentValue;
        // }

        if (changes['ttStyle']) {
            this.setStyle(changes['ttStyle'].currentValue);
        }
    }
}
