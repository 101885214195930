(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2DataTask', {
        templateUrl: 'views/components/views/p2DataTask/p2DataTask.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'modalService', 'ieScreenBlockService', 'p2DataTaskService', 'rememberService', 'layoutService', function ($q, stateService, utilityService, modalService, ieScreenBlockService, p2DataTaskService, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2DataTaskKeyno: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator().then(function () { vm.searchItems() }) },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                itemsListProcedureNames: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                p2DataTaskService.getP2DataTask().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    ieScreenBlockService.start();

                    $q.all([
                        load()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let load = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListProcedureNames = [];
                
                return p2DataTaskService.list({ searchtext: vm.model.setting.searchtext }).then(function (list) {
                    ieScreenBlockService.stop();
                    angular.copy(list, vm.model.itemsListProcedureNames);

                    angular.forEach(vm.model.itemsListProcedureNames, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER FUNCTION

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER FUNCTION

            // #region OPEN CALCULATOR FUNCTION

            let openCalculator = function (value) {
                const deferred = $q.defer();
    		    
                modalService.showCalculator(vm.model.setting.searchtext).then(function (value) {
                    vm.model.setting.searchtext = value;
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };

            // #endregion OPEN CALCULATOR FUNCTION

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            ieScreenBlockService.start();
                            load();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                ieScreenBlockService.start();
                load();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2DataTaskKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI BUTTON DIRECTION FUNCTION

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2datataskedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2datataskedit') {
                            vm.model.p2DataTaskKeyno = '0';
                        } else {
                            go({ p2_datatask_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI BUTTON DIRECTION FUNCTION

            // #region SEARCH FUNCTION

            vm.searchItems = function () {
                rememberFunc('searchtext');
                ieScreenBlockService.start();
                load();
            };

            // #endregion SEARCH FUNCTION

            // #region ON CHANGED FUNCTION

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            ieScreenBlockService.start();
                            load();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion ON CHANGED FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
