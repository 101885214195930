import { Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { BusinesscoItem } from '@app/model/businessso-item.model';

@Injectable({
    providedIn: 'root',
})
export class SaftExportService {
    constructor(private datatask: DataTaskService) { }

    public Initialize(): Promise<InitData> {
        return this.datatask.Post(3528, {});
    }
}

export interface InitData {
    businesscoList: BusinesscoItem[]
}
