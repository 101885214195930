(function () {
    'use strict';

    angular.module("imApp").factory("orderHeadMiService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 740,
                    parameters: parms || {}
                });
            },
            createInvoiceObj: function (setting) {
                return $ihttp.post({
                    method: 741,
                    parameters: setting
                });
            },
            createManualObj: function (setting) {
                return $ihttp.post({
                    method: 2918,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
