(function () {
    'use strict';

    angular.module("imApp").factory("serviceObjectTypeIntService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1745,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1746,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 1747,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
