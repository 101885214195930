(function () {
	'use strict';

    angular.module("imApp").factory("payCheckBatchService", ['$ihttp', function ($ihttp) {
		let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1368,
                    parameters: parms || {}
                });
            },
            exportToPowerOffice: function (edit) {
                return $ihttp.post({
                    method: 1523,
                    parameters: edit
                });
            },                  
            updateCheck: function (edit) {
                return $ihttp.post({
                    method: 1370,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1369,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
