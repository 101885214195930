<tt-page *ngIf="!!get">
    <tt-page-heading class="col-xs-12 sp-0" ttModel="p2_m_dispatchplanning" ttTranslate></tt-page-heading>
    <tt-tabs [(ttSelectedId)]="get.tab" ttRememberId="dispatchplanning.tab" ttOnlyRemember>
        <tt-tab ttId="planning" ttLabel="planning"></tt-tab>
        <tt-tab ttId="delivered" ttLabel="delivered"></tt-tab>
    </tt-tabs>
    <div [hidden]="get.tab === 'delivered'" class="flex-1 flex-column">
        <tt-card class="col-xs-12 col-md-12 sp-0" style="max-width: 1250px">
            <tt-select
                class="col-xs-12 col-md-6 sp-2"
                ttRememberId="dispatchplanning.deliverymethod_no"
                ttLabel="deliverymethods"
                ttLabelView="top"
                [(ttModel)]="get.deliverymethod_no"
                (ttModelChange)="readPlanningGrid()"
                [ttData]="deliverymethods"
                ttShowClearButton
            ></tt-select>
            <tt-select
                class="col-xs-12 col-md-6 sp-2"
                ttRememberId="dispatchplanning.stockplace_id"
                ttLabel="stockplace_id"
                ttLabelView="top"
                [(ttModel)]="get.stockplace_id"
                (ttModelChange)="readPlanningGrid()"
                [ttData]="stockplaces"
                ttDataName="stockplace_name"
                ttDataId="stockplace_id"
            ></tt-select>
            <tt-date-range
                class="col-xs-12 col-md-12 sp-2"
                ttRememberId="dispatchplanning"
                ttLabel="date_fom_tom"
                ttLabelView="top"
                [(ttStartDate)]="get.date_fom"
                [(ttEndDate)]="get.date_tom"
                [(ttInterval)]="get.dateselector_index"
                (ttModelChange)="readPlanningGrid()"
            ></tt-date-range>
        </tt-card>
        <tt-grid *ngIf="!!grid" class="flex-1" [(ttOptions)]="grid"></tt-grid>
    </div>
    <div [hidden]="get.tab === 'planning'" class="flex-1 flex-column">
        <tt-card class="col-xs-12 col-md-12 sp-0" style="max-width: 1250px">
            <form (submit)="readDeliveredGrid()">
                <tt-input
                    class="col-xs-12 col-md-6 sp-2"
                    ttRememberId="dispatchplanning.order_order_no"
                    ttLabel="order_order_no"
                    ttLabelView="top"
                    [(ttModel)]="get.order_order_no"
                    [ttButtons]="orderInternalNoButtons"
                ></tt-input>
            </form>
            <tt-select
                class="col-xs-12 col-md-6 sp-2"
                ttRememberId="dispatchplanning.stockplace_id"
                ttLabel="stockplace_id"
                ttLabelView="top"
                [(ttModel)]="get.stockplace_id"
                (ttModelChange)="readDeliveredGrid()"
                [ttData]="stockplaces"
                ttDataName="stockplace_name"
                ttDataId="stockplace_id"
            ></tt-select>
            <tt-date-range
                class="col-xs-12 col-md-12 sp-2"
                ttRememberId="dispatchplanning"
                ttLabel="date_fom_tom"
                ttLabelView="top"
                [(ttStartDate)]="get.date_fom"
                [(ttEndDate)]="get.date_tom"
                [(ttInterval)]="get.dateselector_index"
                (ttModelChange)="readDeliveredGrid()"
            ></tt-date-range>
        </tt-card>
        <tt-grid *ngIf="!!deliveredGrid" class="flex-1" [(ttOptions)]="deliveredGrid"></tt-grid>
    </div>
    <tt-card class="col-xs-12 sp-0">
        <tt-button-settings></tt-button-settings>
    </tt-card>
</tt-page>
