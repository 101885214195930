import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { FormButton } from '../form-field-button/form-field-button.component';
import { Style } from '@app/core/services/core-component.service';

@Component({
    selector: 'tt-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.css'],
})
export class TimeComponent extends FormFieldBaseComponent implements OnInit, FormFieldButtons {
    /**
     * A string of format hh:mm representing a time.
     */
    @Input()
    ttModel: string = '';

    /**
     * Emits event when the time has changed, gives the time in the event.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * Whether to allow a time to empty (empty string).
     */
    @Input()
    set ttAllowEmpty(value: BooleanInput) {
        this._allowEmpty = coerceBooleanProperty(value);
    }
    get ttAllowEmpty(): boolean {
        return this._allowEmpty;
    }
    _allowEmpty = true;

    /**
     * Whether seconds should be shown in time input.
     *
     * @default false
     */
    @Input()
    public set ttShowSeconds(value: BooleanInput) {
        this._showSeconds = coerceBooleanProperty(value);
    }
    public get ttShowSeconds(): boolean {
        return this._showSeconds;
    }
    private _showSeconds = false;

    /**
     * The minute precision of the time input, default is `5`.
     */
    @Input()
    set ttMinutePrecision(value: NumberInput) {
        this._minutePrecision = coerceNumberProperty(value);
    }
    get ttMinutePrecision(): number {
        return this._minutePrecision;
    }
    _minutePrecision = 5;

    /**
     * Whether to round the time the user inputed. If true the time is rounded to closest minute precision, if `'up'` the time
     * is rounded to the next minute precision. If `'down'` the time is always rounded down the previous minute-precision.
     */
    @Input()
    set ttRoundTime(value: BooleanInput | 'up' | 'down') {
        if (value === 'up') {
            this._roundTime = 'up';
        } else if (value === 'down') {
            this._roundTime = 'down';
        } else {
            this._roundTime = coerceBooleanProperty(value);
        }
    }
    get ttRoundTime(): boolean | 'up' | 'down' {
        return this._roundTime;
    }
    _roundTime: boolean | 'up' | 'down' = false;

    @Input()
    public override style: Style = {
        input: {},
    };

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    @Input()
    ttButtons?: FormButton[] | undefined;

    /**
     * Ids of elements in the component.
     */
    id = {
        input: crypto.randomUUID(),
        inputGroup: crypto.randomUUID(),
    };

    /**
     * Handles changes in the date values, and emits the `ttModelChange` event with the new date-string.
     *
     * @emits `(ttModelChange)` event emitted with the new date string.
     */
    onModelChange(event: string) {
        this.ttModel = event;
        this.rememberSubject.next(this.ttModel);
        this.ttModelChange.emit(this.ttModel);
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.ttModel = (await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue;
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes?.['ttAllowEmpty']) {
            this.ttAllowEmpty = changes['ttAllowEmpty'].currentValue;
        }

        if (changes?.['ttShowSeconds']) {
            this.ttShowSeconds = changes['ttShowSeconds'].currentValue;
        }

        if (changes?.['ttModel']) {
            this.ttModel = changes['ttModel'].currentValue;
        }
    }
}
