(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("userService", ['$rootScope', 'userStore', 'ngUserService', '$q', '$ihttp', '$timeout', 'appConfig', function ($rootScope, userStore, ngUserService, $q, $ihttp, $timeout, appConfig) {
        var service = {
            userId: userStore.userId,
            fontSize: userStore.fontSize,
            fontSizeMobile: userStore.fontSizeMobile,
            labelAlwaysOnTop: userStore.labelAlwaysOnTop,
            developMode: userStore.developMode,
            showSettings: userStore.showSettings,
            windowheadingcolor: userStore.windowHeadingColor,
            theme_keyno: userStore.themeKeyno,
            theme_keyno_mobile: userStore.themeKeynoMobile,
            inputLayout: userStore.inputLayout,
            breadcrumbView: userStore.breadcrumbView,
            sidemenuOpened: userStore.sidemenuOpen,
            showBreadcrumbs: userStore.showBreadcrumbs, // only applicable if breacrumbView is set to 'toggle'.
            languageId: userStore.languageId,
            useAgGrid: userStore.useAgGrid,
            userSettings: userStore.userSettings,
            userGroups: userStore.userGroups,
            showNotificationToasts: userStore.showNotificationToasts,

            ensureIsReady: function () {
                return userStore.ensureIsReady();
            },

            init: function () {
                // BJS 20240307 - Obsolete, included for legacy support
                return $q.resolve();
            },

            // used for editing users
            userEditCache: userStore.userEditCache,

            list: function (activestatus, usertype, filtervalue) {
                return ngUserService.getList(activestatus, usertype, filtervalue);
            },

            details: function (portalUserKeyno, labourNo) {
                return ngUserService.userDetails(portalUserKeyno | 0, labourNo | 0);
            },

            getSettings: function (portalUserKeyno) {
                return ngUserService.getSettings(portalUserKeyno);
            },

            save: function (user) {
                // User format
                //{
                //    fontsize: userSettings.fontsize,
                //    portal_user_keyno_edit: service.user_settings.portal_user_keyno_edit,
                //    user_id_portal: service.user_settings.user_id_portal,
                //    user_name: service.user_settings.user_name,
                //    labour_password: service.user_settings.labour_password,
                //    labour_no: validate(searchService.selLabour.selectedLabourNo, service.user_settings.labour_no),
                //    labour_name: validate(searchService.selLabour.selectedLabourName, service.user_settings.labour_name),
                //    user_id_ie: service.user_settings.user_id_ie,
                //    language_id: service.user_settings.language_id,
                //    usergroup_keyno: service.user_settings.usergroup_keyno,
                //    mobilephone: service.user_settings.mobilephone,
                //    email: service.user_settings.email,
                //    last_login_date: service.user_settings.last_login_date
                //    develop_mode: 1/0
                //}
                return ngUserService.save(user);
            },

            updateShowBreadcrumbs: function (state) {
                userStore.setShowBreadcrumbs(state);
            },

            updateSideMenu: function (state) {
                userStore.setSideMenuOpen(state);
            },

            updateInputStyles: function (inputLayoutId) {
                userStore.setInputLayout(inputLayoutId);
            },
            updateUseAgGrid: function (useAgGrid) {
                userStore.setUseAgGrid(useAgGrid === '1');
            },
            updateTheme: function (themeId) {
                userStore.setThemeKeyno(themeId);
            },
            updateMobileTheme: function (themeId) {
                userStore.setThemeKeynoMobile(themeId);
            },
            generateUserLink: function (userName) {
                return ngUserService.generateUserLink(userName);
            }
        };

        ////userId: ''
        userStore.userIdChanged.subscribe(
            data => { service.userId = data; },
            error => { }
        );
        var dereg1 = $rootScope.$watch(function () { return service.userId; }, function () {
            $rootScope.$broadcast('userService:userId:changed', { userId: service.userId });
        });

        //fontSize: '20px',
        userStore.fontSizeChanged.subscribe(
            data => {
                service.fontSize = data;
            },
            error => { }
        );
        var dereg2 = $rootScope.$watch(function () { return service.fontSize; }, function () {
            $rootScope.$broadcast('userService:fontSize:changed', { fontSize: service.fontSize });
        });

        //fontSizeMobile: '20px',
        userStore.fontSizeMobileChanged.subscribe(
            data => { service.fontSizeMobile = data; },
            error => { }
        );
        var dereg3 = $rootScope.$watch(function () { return service.fontSizeMobile; }, function () {
            $rootScope.$broadcast('userService:fontSizeMobile:changed', { fontSizeMobile: service.fontSizeMobile });
        });

        //labelAlwaysOnTop: false,
        userStore.labelAlwaysOnTopChanged.subscribe(
            data => { service.labelAlwaysOnTop = data; },
            error => { }
        );
        var dereg4 = $rootScope.$watch(function () { return service.labelAlwaysOnTop; }, function () {
            $rootScope.$broadcast('userService:labelAlwaysOnTop:changed', { labelAlwaysOnTop: service.labelAlwaysOnTop });
        });


        //developMode: false,
        userStore.developModeChanged.subscribe(
            data => { service.developMode = data; },
            error => { }
        );
        var dereg5 = $rootScope.$watch(function () { return service.developMode; }, function () {
            $rootScope.$broadcast('userService:developMode:changed', { developMode: service.developMode });
        });

        //showSettings: '',
        userStore.showSettingsChanged.subscribe(
            data => { service.showSettings = data; },
            error => { }
        );
        var dereg6 = $rootScope.$watch(function () { return service.showSettings; }, function () {
            $rootScope.$broadcast('userService:showSettings:changed', { showSettings: service.showSettings });
        });

        //windowheadingcolor: '#fff',
        userStore.windowHeadingColorChanged.subscribe(
            data => { service.windowheadingcolor = data; },
            error => { }
        );
        var dereg7 = $rootScope.$watch(function () { return service.windowheadingcolor; }, function () {
            $rootScope.$broadcast('userService:windowheadingcolor:changed', { windowheadingcolor: service.windowheadingcolor });
        });

        //theme_keyno: '1',
        userStore.themeKeynoChanged.subscribe(
            data => { service.theme_keyno = data; },
            error => { }
        );
        var dereg8 = $rootScope.$watch(function () { return service.theme_keyno; }, function () {
            $rootScope.$broadcast('userService:theme_keyno:changed', { theme_keyno: service.theme_keyno });
        });

        //theme_keyno_mobile: '1',
        userStore.themeKeynoMobileChanged.subscribe(
            data => { service.theme_keyno_mobile = data; },
            error => { }
        );
        var dereg9 = $rootScope.$watch(function () { return service.theme_keyno_mobile; }, function () {
            $rootScope.$broadcast('userService:theme_keyno_mobile:changed', { theme_keyno_mobile: service.theme_keyno_mobile });
        });

        //inputLayout: '0',
        userStore.inputLayoutChanged.subscribe(
            data => { service.inputLayout = data; },
            error => { }
        );
        var dereg10 = $rootScope.$watch(function () { return service.inputLayout; }, function () {
            $rootScope.$broadcast('userService:inputLayout:changed', { inputLayout: service.inputLayout });
        });

        //breadcrumbView: 'true',
        userStore.breadcrumbViewChanged.subscribe(
            data => { service.breadcrumbView = data; },
            error => { }
        );
        var dereg11 = $rootScope.$watch(function () { return service.breadcrumbView; }, function () {
            $rootScope.$broadcast('userService:breadcrumbView:changed', { breadcrumbView: service.breadcrumbView });
        });

        //sidemenuOpened: true,
        userStore.sideMenuOpenChanged.subscribe(
            data => { service.sidemenuOpened = data; },
            error => { }
        );
        var dereg12 = $rootScope.$watch(function () { return service.sidemenuOpened; }, function () {
            $rootScope.$broadcast('userService:sidemenuOpened:changed', { sidemenuOpened: service.sidemenuOpened });
        });

        //showBreadcrumbs: true, // only applicable if breacrumbView is set to 'toggle'.
        userStore.showBreadcrumbsChanged.subscribe(
            data => { service.showBreadcrumbs = data; },
            error => { }
        );
        var dereg13 = $rootScope.$watch(function () { return service.showBreadcrumbs; }, function () {
            $rootScope.$broadcast('userService:showBreadcrumbs:changed', { breadcrumbView: service.breadcrumbView });
        });

        //languageId: '',
        userStore.languageIdChanged.subscribe(
            data => { service.languageId = data; },
            error => { }
        );
        var dereg14 = $rootScope.$watch(function () { return service.languageId; }, function () {
            $rootScope.$broadcast('userService:languageId:changed', { languageId: service.languageId });
        });

        //languageId: '',
        userStore.useAgGridChanged.subscribe(
            data => { service.useAgGrid = data; },
            error => { }
        );
        var dereg15 = $rootScope.$watch(function () { return service.useAgGrid; }, function () {
            $rootScope.$broadcast('userService:useAgGrid:changed', { useAgGrid: service.useAgGrid });
        });

        $rootScope.$on('$destroy', function (event) {
            if (angular.isFunction(dereg1)) { dereg1(); }
            if (angular.isFunction(dereg2)) { dereg2(); }
            if (angular.isFunction(dereg3)) { dereg3(); }
            if (angular.isFunction(dereg4)) { dereg4(); }
            if (angular.isFunction(dereg5)) { dereg5(); }
            if (angular.isFunction(dereg6)) { dereg6(); }
            if (angular.isFunction(dereg7)) { dereg7(); }
            if (angular.isFunction(dereg8)) { dereg8(); }
            if (angular.isFunction(dereg9)) { dereg9(); }
            if (angular.isFunction(dereg10)) { dereg10(); }
            if (angular.isFunction(dereg11)) { dereg11(); }
            if (angular.isFunction(dereg12)) { dereg12(); }
            if (angular.isFunction(dereg13)) { dereg13(); }
            if (angular.isFunction(dereg14)) { dereg14(); }
            if (angular.isFunction(dereg15)) { dereg15(); }
        });

        return service;
    }]);
})();
