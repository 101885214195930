import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormFieldBaseComponent } from '@app/core/components/form-field-base/form-field-base.component';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../form-field-button/form-field-button.component';
import { Style } from '@app/core/services/core-component.service';
import { SearchItem } from '../search/search.component';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

// export type DatetimeString = `${number}-${number}-${number} ${number}:${number}` | '';

@Component({
    selector: 'tt-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DatetimeComponent extends FormFieldBaseComponent implements FormFieldButtons {
    /**
     * A date string of the format `YYYY-MM-DD HH:MM`.
     */
    @Input()
    ttModel = '';

    /**
     * Object representing the date given in ttModel, internal use within the component.
     */
    _model: { date: null | Date; time: string } = {
        date: null,
        time: '',
    };

    /**
     * Emits event with a string representation of the new date given in the event if the format `YYYY-MM-DD HH:MM`.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * The minute precision of the time input, default is `5`.
     */
    @Input()
    set ttMinutePrecision(value: NumberInput) {
        this._minutePrecision = coerceNumberProperty(value);
    }
    get ttMinutePrecision(): number {
        return this._minutePrecision;
    }
    _minutePrecision = 5;

    /**
     * Whether to round the time the user inputed. If true the time is rounded to closest minute precision, if `'up'` the time
     * is rounded to the next minute precision. If `'down'` the time is always rounded down the previous minute-precision.
     */
    @Input()
    set ttRoundTime(value: BooleanInput | 'up' | 'down') {
        if (value === 'up') {
            this._roundTime = 'up';
        } else if (value === 'down') {
            this._roundTime = 'down';
        } else {
            this._roundTime = coerceBooleanProperty(value);
        }
    }
    get ttRoundTime(): boolean | 'up' | 'down' {
        return this._roundTime;
    }
    _roundTime: boolean | 'up' | 'down' = false;

    /**
     * Whether seconds should be shown in time input.
     *
     * @default false
     */
    @Input()
    public set ttShowSeconds(value: BooleanInput) {
        this._showSeconds = coerceBooleanProperty(value);
    }
    public get ttShowSeconds(): boolean {
        return this._showSeconds;
    }
    private _showSeconds = false;

    /**
     * Whether to allow a date to be empty (empty string).
     */
    @Input()
    set ttAllowEmptyDate(value: BooleanInput) {
        this._allowEmptyDate = coerceBooleanProperty(value);
    }
    get ttAllowEmptyDate(): boolean {
        return this._allowEmptyDate;
    }
    _allowEmptyDate = false;

    /**
     * Whether to allow a time to be empty (empty string).
     */
    @Input()
    set ttAllowEmptyTime(value: BooleanInput) {
        this._allowEmptyTime = coerceBooleanProperty(value);
    }
    get ttAllowEmptyTime(): boolean {
        return this._allowEmptyTime;
    }
    _allowEmptyTime = false;

    /**
     * Whether the date field should be disabled.
     */
    @Input()
    set ttDateDisabled(value: BooleanInput) {
        this._dateDisabled = coerceBooleanProperty(value);
    }
    get ttDateDisabled(): boolean {
        return this._dateDisabled;
    }
    _dateDisabled = false;

    /**
     * Whether the date field should be readonly.
     */
    @Input()
    set ttDateReadonly(value: BooleanInput) {
        this._dateReadonly = coerceBooleanProperty(value);
    }
    get ttDateReadonly(): boolean {
        return this._dateReadonly;
    }
    _dateReadonly = false;

    /**
     * Whether the date field should be disabled.
     */
    @Input()
    set ttTimeDisabled(value: BooleanInput) {
        this._timeDisabled = coerceBooleanProperty(value);
    }
    get ttTimeDisabled(): boolean {
        return this._timeDisabled;
    }
    _timeDisabled = false;

    /**
     * Whether the date field should be readonly.
     */
    @Input()
    set ttTimeReadonly(value: BooleanInput) {
        this._timeReadonly = coerceBooleanProperty(value);
    }
    get ttTimeReadonly(): boolean {
        return this._timeReadonly;
    }
    _timeReadonly = false;

    @Input()
    public override style: Style = {
        date: {},
        hour: {},
        minute: {},
    };

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    @Input()
    ttButtons?: FormButton[] | undefined;

    /**
     * Ids of elements in the component.
     */
    id = {
        dateInput: crypto.randomUUID(),
        timeGroup: crypto.randomUUID(),
        timeInput: crypto.randomUUID(),
    };

    timeOptions: SearchItem[] = [];

    onDateChanged(event: null | Date) {
        this._model.date = event;

        this.onModelChange();
    }

    onTimeChanged(event: string) {
        this._model.time = event;

        this.onModelChange();
    }

    /**
     * Handles changes in the date values, puts together a date string based on the `_model` object, and emits the `ttModelChange` event with the new date-string.
     *
     * @emits `(ttModelChange)` event emitted with the new date string.
     */
    onModelChange() {
        console.log('this._model.time :>> ', this._model.time);
        if (this.ttDisabled || this.ttReadonly) return;

        if (this._model.date) {
            const dateString = this._model.date.toISOString().substring(0, 10) + ' ' + this._model.time;
            console.log('dateString :>> ', dateString);
            if (dateString !== this.ttModel) {
                this.ttModel = dateString;
                this.rememberSubject.next(this.ttModel);
                this.ttModelChange.emit(dateString);
            }
        } else if (!this._model.date) {
            this.ttModel = '';
            this.rememberSubject.next(this.ttModel);
            this.ttModelChange.emit('');
        }
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            const last = (await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue;

            this.setDateFromDateString(last);
        }
    }

    private setDateFromDateString(dateString: string) {
        this.ttModel = dateString;
        const dateValue = this.ttModel.split(' ')[0];
        let timeValue = this.ttModel.split(' ')[1];

        if (this.ttShowSeconds) {
            timeValue = timeValue.substring(0, 8);
        } else {
            timeValue = timeValue.substring(0, 5);
        }

        if (dateValue && new Date(dateValue).toString() !== 'Invalid Date') {
            this._model.date = new Date(dateValue);
        }

        this._model.time = timeValue;
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);
        if (changes?.['ttMinutePrecision']) {
            this.ttMinutePrecision = changes['ttMinutePrecision'].currentValue;
        }

        if (changes?.['ttRoundTime']) {
            this.ttRoundTime = changes['ttRoundTime'].currentValue;
        }

        if (changes?.['ttAllowEmptyDate']) {
            this.ttAllowEmptyDate = changes['ttAllowEmptyDate'].currentValue;
        }

        if (changes?.['ttAllowEmptyTime']) {
            this.ttAllowEmptyTime = changes['ttAllowEmptyTime'].currentValue;
        }

        if (changes?.['ttDateDisabled']) {
            this.ttDateDisabled = changes['ttDateDisabled'].currentValue;
        }

        if (changes?.['ttDateReadonly']) {
            this.ttDateReadonly = changes['ttDateReadonly'].currentValue;
        }

        if (changes?.['ttTimeDisabled']) {
            this.ttTimeDisabled = changes['ttTimeDisabled'].currentValue;
        }

        if (changes?.['ttTimeReadonly']) {
            this.ttTimeReadonly = changes['ttTimeReadonly'].currentValue;
        }

        if (changes?.['ttShowSeconds']) {
            this.ttShowSeconds = changes['ttShowSeconds'].currentValue;
        }

        if (changes?.['ttModel']) {
            this.setDateFromDateString(this.ttModel);
            // const dateValue = this.ttModel.split(' ')[0];
            // let timeValue = this.ttModel.split(' ')[1];

            // if (this.ttShowSeconds) {
            //     timeValue = timeValue.substring(0, 8);
            // } else {
            //     timeValue = timeValue.substring(0, 5);
            // }

            // if (dateValue && new Date(dateValue).toString() !== 'Invalid Date') {
            //     this._model.date = new Date(dateValue);
            // }

            // this._model.time = timeValue;
        }
    }
}
