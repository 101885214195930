(function () {
    'use strict';

    angular.module("imApp").factory("p2DataTaskService", ['$ihttp', function ($ihttp) {
        let service = {
            getP2DataTask: function (parms) {
                return $ihttp.post({
                    method: 2157,
                    parameters: parms || {}
                });
            },
            list: function (parms) {
                return $ihttp.post({
                    method: 10,
                    parameters: parms || {}
                });
            },
            call: function (method, parameters) {
                var parms = {
                    method: method,
                    parameters: parameters || {}
                };

                return $ihttp.post(parms);
            }
        };

        return service;
    }]);
})();
