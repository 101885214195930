import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormFieldButtons } from '../../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../../form-field-button/form-field-button.component';
import { StateService } from '@app/core/services/state.service';
import { StateService as $StateService } from '@uirouter/angularjs';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
    selector: 'tt-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ListItemComponent implements OnChanges, FormFieldButtons {
    /**
     * The main text to display in the list item.
     */
    @Input()
    public ttId?: string = '';

    @Input()
    public ttForm = '';

    /**
     * The main text to display in the list item.
     */
    @Input()
    public ttTile?: string = '';

    /**
     * Additional text to provide with the main text / title.
     */
    @Input()
    public ttSubtitle?: string = '';

    /**
     * Additional text to provide with the subtitle.
     */
    @Input()
    public ttSubtext?: string = '';

    /**
     * Text to display on the badge of the list item.
     */
    @Input()
    public ttBadge?: string = '';

    /**
     * The img source of the thumbnail to add to the list item.
     * **NB** only supports sirv.
     */
    @Input()
    public ttThumbnail?: string = '';

    /**
     * Space seperated list of icons to display on the list item.
     */
    @Input()
    public ttIcons?: string = '';

    /**
     * Space seperated list of colors to display on the icons in the same position as the icon list.
     */
    @Input()
    public ttIconColors?: string = '';

    @ContentChild('ttListItemTemplate')
    public ttListItemTemplate?: TemplateRef<any>;

    @Input()
    public ttButtons?: FormButton[] | undefined;

    @Input()
    public ttButtonParms?: { [key: string]: any } | undefined;

    /**
     * Default `true`. Prevent ttClick from emitting and styles the list item as a non clickable item.
     */
    @Input()
    set ttClickable(value: BooleanInput) {
        this._clickable = coerceBooleanProperty(value);
    }
    get ttClickable(): boolean {
        return this._clickable;
    }
    _clickable = true;

    /**
     * Emits click event. If provided `ttClick` events will stop `ttCheckboxCheckedChanged` (provided that `ttCheckboxSelection` is turned on) events and stop navigation to the configured `ttGoto` unless `ttClickable` is set to false..
     */
    @Output()
    public ttClick = new EventEmitter<MouseEvent>();

    @Input()
    public set ttRadioSelection(value: BooleanInput) {
        this._radioSelection = coerceBooleanProperty(value);
    }
    public get ttRadioSelection(): boolean {
        return this._radioSelection;
    }
    _radioSelection = false;

    /**
     * The state route to go to if provided.
     */
    @Input()
    public ttGoto?: string = '';

    /**
     * The parameters to use for navigating to the given `ttGoto`.
     */
    @Input()
    public ttGotoParms?: Record<string | symbol, unknown> | string | number | null = '';

    /**
     * The id of the currently checked item in the tt-list.
     */
    @Input()
    public ttRadioChecked: string = '';

    /**
     * Where to place the tt-buttons. Currently only one setting is possible.
     */
    @Input()
    public ttButtonsPosition: 'right' | 'left' = 'right';

    /**
     * Event emitted when theres been a change in the checkbox selection.
     */
    @Output()
    public ttRadioCheckedChanged = new EventEmitter<string>();

    @Input()
    public set ttCheckboxSelection(value: BooleanInput) {
        this._checkboxSelection = coerceBooleanProperty(value);
    }
    public get ttCheckboxSelection(): boolean {
        return this._checkboxSelection;
    }
    _checkboxSelection = false;

    /**
     * Whether the checkbox for checkbox-selection should be checked or not.
     */
    @Input()
    public set ttCheckboxChecked(value: undefined | '1' | '0') {
        this.checked = value === '1';
    }
    public get ttCheckboxChecked(): '1' | '0' {
        return this.checked ? '1' : '0';
    }
    public checked = false;

    /**
     * Event emitted when theres been a change in the checkbox selection.
     */
    @Output()
    public ttCheckboxCheckedChanged = new EventEmitter<'1' | '0'>();

    /**
     * Styles the list item so it indicated it will lead back to a previous page.
     */
    @Input()
    set ttBackStyle(value: BooleanInput) {
        this._backStyle = coerceBooleanProperty(value);
    }
    get ttBackStyle(): boolean {
        return this._backStyle;
    }
    _backStyle = false;

    /**
     * List of icons to display for the list-item.
     */
    public icons: { icon: string; color: string }[] = [];

    /**
     * The url of the state to navigate to if `ttGoto` is provided.
     */
    public statepath = '';

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private state: StateService, private utility: UtilityService, private $state: $StateService) {}

    onRadioChanged(event: Event) {
        this.ttRadioChecked = '' + this.ttId;
        this.ttRadioCheckedChanged.emit(this.ttRadioChecked);
    }
    /**
     * Emits `ttCheckboxCheckedChanged` when the checkbox selection has been changed for this list item.
     *
     * @param event the new value of the checkbox.
     */
    public onCheckboxChanged(event: boolean) {
        this.ttCheckboxChecked = event ? '1' : '0';
        this.ttCheckboxCheckedChanged.emit(this.ttCheckboxChecked);
    }

    /**
     * Handles click events on the button element of the list item.
     *
     * @param event the mouse event of the click.
     */
    public onClick(event: MouseEvent) {
        event.preventDefault();

        if (this.ttClick.observed && this.ttClickable) {
            this.ttClick.emit(event);
        } else if (this.ttGoto) {
            if (this.utility.isModifierKeyPressed(event)) {
                this.state.newTab(this.ttGoto, this.ttGotoParms);
            } else {
                this.state.go(this.ttGoto, this.ttGotoParms);
            }
        } else if (this.ttCheckboxSelection) {
            this.ttCheckboxChecked = this.ttCheckboxChecked === '1' ? '0' : '1';
            this.ttCheckboxCheckedChanged.emit(this.ttCheckboxChecked);
        } else if (this.ttRadioSelection) {
            this.ttRadioChecked = '' + this.ttId;
            this.ttRadioCheckedChanged.emit(this.ttRadioChecked);
        }
    }

    /**
     * Retrieves the icon classes required for displaying the given icon-code.
     *
     * @param icon the icon to retrieve icon classes for.
     * @returns a string containing the necessary css classes for the displaying the given icon-code.
     */
    private getIcon(icon: string) {
        if (!!icon && typeof icon === 'string') {
            if (icon.startsWith('fa') && [2, 3].includes(icon.indexOf('-'))) {
                icon = `${icon.slice(0, icon.indexOf('-'))} fa${icon.slice(icon.indexOf('-'))}`;
            } else {
                icon = 'glyphicon ' + icon;
            }
        }

        return icon;
    }

    /**
     * Maps the exisitng list values of `ttIcons` and `ttIconColors` to the `icons` list, containing the configuration of the icons in each object of the list.
     */
    private mapIconsAndIconColors() {
        const icons = this.ttIcons?.split(' ');
        const iconColors = this.ttIconColors?.split(' ');

        if (!!icons && !!iconColors) {
            this.icons = icons.map((icon, index) => ({
                icon: this.getIcon(icon),
                color: iconColors[index] ?? 'var(--tt-text-color)',
            }));
        }
    }

    private generateStatePath() {
        if (this.ttGoto) {
            this.statepath = '/#/' + this.ttGoto;

            if (!!this.ttGotoParms && (typeof this.ttGotoParms === 'string' || typeof this.ttGotoParms === 'number')) {
                this.statepath += '/' + this.ttGotoParms;
            } else if (typeof this.ttGotoParms === 'object' && this.ttGotoParms instanceof Object) {
                let parameters = this.$state.get(this.ttGoto!).url?.split('/:').slice(1) ?? [''];

                for (let param of parameters) {
                    this.statepath += '/' + (this.ttGotoParms[param] ?? '-');
                }
            }
        } else {
            this.statepath = '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttIcons'] || changes?.['ttIconColors']) {
            this.mapIconsAndIconColors();
        }

        if (changes?.['ttBackStyle']) {
            this.ttBackStyle = changes['ttBackStyle'].currentValue;
        }

        if (changes?.['ttClickable']) {
            this.ttClickable = changes['ttClickable'].currentValue;
        }

        if (changes?.['ttCheckboxSelection']) {
            this.ttCheckboxSelection = changes['ttCheckboxSelection'].currentValue;
        }

        if (changes?.['ttCheckboxChecked']) {
            this.ttCheckboxChecked = changes['ttCheckboxChecked'].currentValue;
        }

        // console.log('changes :>> ', changes);
        this.generateStatePath();
    }
}

// @Directive({
//     selector: 'tt-list-item[ttClick]',
// })
// export class ListItemClickDirective {
//     @Output()
//     public ttClick = new EventEmitter();
//     constructor(private el: ElementRef, private renderer: Renderer2) {
//         // Add styling on initialization
//         this.renderer.addClass(this.el.nativeElement, 'tt-danger-background');
//     }

//     @HostListener('click', ['event'])
//     public onClick() {
//         this.ttClick.emit();
//     }
// }

// /**
//  * Applies goto-functionality to a list item-
//  */
// @Directive({
//     selector: 'tt-list-item[ttGoto]',
// })
// export class ListItemGotoDirective<TType extends string | Record<string | symbol, any> | null | undefined = ''> {
//     /**
//      * The route to navigate to.
//      */
//     @Input()
//     public ttGoto: string = '';

//     /**
//      * The parameters for navigating to the given route, if any.
//      */
//     @Input()
//     public ttGotoParms?: TType;

//     constructor(private el: ElementRef, private renderer: Renderer2, private state: StateService) {
//         this.renderer.addClass(this.el.nativeElement, 'tt-danger-background');
//     }

//     @HostListener('click', ['$event'])
//     public onClick(event: MouseEvent) {
//         if (event.ctrlKey) {
//             this.state.newTab(this.ttGoto, this.ttGotoParms);
//         } else {
//             this.state.go(this.ttGoto, this.ttGotoParms);
//         }
//     }
// }
