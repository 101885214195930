import { Component, OnInit } from '@angular/core';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-deviation-type-view',
    templateUrl: './deviation-type-view.component.html',
    styleUrls: ['./deviation-type-view.component.css'],
})
export class DeviationTypeViewComponent implements OnInit {
    public deviationTypeKeyno: string = '';

    constructor(private state: StateService) {}

    public onDeviationTypeSave() {
        this.state.back();
    }

    public onDeviationTypeDelete() {
        this.state.back();
    }

    ngOnInit(): void {
        this.deviationTypeKeyno = this.state.getStateParams()['deviationtype_keyno'];
    }
}
