(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('requisitionCreateFrom', {
        templateUrl: 'views/components/views/requisitionCreateFrom/requisitionCreateFrom.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'requisitionCreateFromService', function ($stateParams, requisitionCreateFromService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;
            
            vm.model = {
                itemsListStocks: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadStocks = function () {
                requisitionCreateFromService.listStocks({ stock_keyno_to: stockKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStocks);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStocks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
