<div [id]="id['grid']" class="col-xs-12 sp-0" [style.height]="'100%'" (click)="gridService.setActiveGrid(id['grid'])">
    <ag-grid-angular
        *ngIf="rowModelType && gridStateReady"
        [initialState]="gridState"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [domLayout]="domLayout"
        [sideBar]="sidebarDefinition"
        [defaultColDef]="defaultColumnDefinitions"
        [dataTypeDefinitions]="dataTypeDefinitions"
        [excelStyles]="excelStyles"
        [enableRangeSelection]="true"
        columnMenu="new"
        [components]="components"
        [columnTypes]="columnTypes"
        [columnDefs]="options.data!.columnDefinitions"
        [copyHeadersToClipboard]="false"
        [groupDisplayType]="'multipleColumns'"
        [localeText]="localeText"
        [rowSelection]="rowSelection"
        [rowModelType]="rowModelType"
        [getRowClass]="getRowClass"
        [serverSideDatasource]="serverSideDataSource"
        [cacheBlockSize]="cacheBlockSize"
        [singleClickEdit]="true"
        [suppressCopySingleCellRanges]="false"
        [getRowId]="getRowId"
        [pagination]="true"
        [paginationPageSize]="20"
        [paginationPageSizeSelector]="[5, 10, 15, 20, 25, 50, 100, 250, 500]"
        [enterNavigatesVerticallyAfterEdit]="this.options.config?.navigation?.altNav !== true ? true : false"
        [stopEditingWhenCellsLoseFocus]="true"
        [loadingOverlayComponent]="loadingOverlayComponent"
        (filterChanged)="onFilterChanged($event)"
        (cellKeyDown)="onCellKeyDown($event)"
        (cellClicked)="onCellClicked($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (cellEditingStarted)="onCellEditingStarted($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
        [undoRedoCellEditing]="true"
        [undoRedoCellEditingLimit]="25"
        (rowSelected)="onRowSelected($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (modelUpdated)="onModelUpdated($event)"
        (gridReady)="onGridReady($event)"
        (stateUpdated)="onStateUpdated($event)"
        [processCellForClipboard]="processCellForClipboard"
        [processCellFromClipboard]="processCellFromClipboard"
        [processDataFromClipboard]="processDataFromClipboard"
        [ngClass]="gridTheme"
        [ngStyle]="style['grid']"
        [style.--ag-font-size]="'var(--tt-font-size)'"
    >
    </ag-grid-angular>

    <div
        #toolbar
        [hidden]="options.config?.toolbar?.hidden !== false"
        class="ag-header ag-header-row tt-grid__toolbar"
        role="row"
        aria-rowindex="0"
        style="display: block; top: 0; left: 0; height: fit-content; min-height: 3.2rem; width: 100%"
    >
        <ng-container *ngIf="options.data!.columnDefinitions.length > 0 && initializationComplete">
            <button
                *ngFor="let button of toolbarButtons"
                [hidden]="(button.disabled$ | async) === 'hidden'"
                [matTooltip]="button.translate === true && !!button.ariaLabel ? translations[button.ariaLabel] : !!button.ariaLabel ? button.ariaLabel : ''"
                [matTooltipShowDelay]="500"
                class="ag-button ag-floating-filter-button-button tt-grid__toolbar-button"
                (click)="button.func($event)"
            >
                <span [ngClass]="gridService.getIconClasses(button.icon)" style="font-size: 2rem"></span>
                <span *ngIf="!!button.secondIcon" class="fa-fw" [ngClass]="button.secondIcon"></span>
            </button>

            <ng-container *ngFor="let button of options.config?.toolbar?.buttons">
                <button
                    *ngIf="button.name.startsWith('§print')"
                    [hidden]="(button.disabled$ | async) == 'hidden'"
                    [matTooltip]="button?.ariaLabel || ''"
                    [matTooltipShowDelay]="500"
                    class="tt-grid__toolbar-button--custom"
                    [ngClass]="!!button.cssClass ? button.cssClass : 'ag-button ag-floating-filter-button-button tt-grid__toolbar-button'"
                    [disabled]="button.disabled$ | async"
                    (click)="openPrintDialog(button)"
                    [style.fontSize]="'calc(var(--ag-font-size) - 2px)'"
                >
                    <span class="fa-fw" [ngClass]="'fal fa-print'" style="font-size: 2em"></span>
                    <span [style.fontSize]="'var(----ag-grid-fontSize)'" *ngIf="button.text">{{ button.translate === true ? translations[button.text] : button.text }}</span>
                </button>
                <button
                    *ngIf="!button.name.startsWith('§print')"
                    [hidden]="(button.disabled$ | async) == 'hidden'"
                    [matTooltip]="button?.ariaLabel || ''"
                    [matTooltipShowDelay]="500"
                    class="tt-grid__toolbar-button--custom"
                    [ngClass]="!!button.cssClass ? button.cssClass : 'ag-button ag-floating-filter-button-button tt-grid__toolbar-button'"
                    [disabled]="button.disabled$ | async"
                    (click)="button._func?.($event)"
                    [style.fontSize]="'calc(var(--ag-font-size) - 2px)'"
                >
                    <span
                        class="fa-fw"
                        [ngClass]="gridService.getIconClasses(button.icon) + (button.spinOnAction === true && button._processing === true ? ' fa-spin ' : '')"
                        style="font-size: 1.6rem"
                    ></span>
                    <span [style.fontSize]="'var(----ag-grid-fontSize)'" *ngIf="button.text">{{ button.translate === true ? translations[button.text] : button.text }}</span>
                </button>
            </ng-container>

            <ng-container *ngFor="let toggle of options.config?.toolbar?.toggles">
                <button
                    [hidden]="(toggle.disabled$ | async) == 'hidden'"
                    class="tt-grid__toolbar-button--custom"
                    [ngClass]="!!toggle.cssClass ? toggle.cssClass : 'ag-button ag-floating-filter-button-button tt-grid__toolbar-button'"
                    [disabled]="toggle.disabled$ | async"
                    (click)="toggle.states[toggle.state]._func?.()"
                    [style.fontSize]="'calc(var(--ag-font-size) - 2px)'"
                >
                    <span class="fa-fw" [ngClass]="gridService.getIconClasses(toggle.states[toggle.state].icon)" style="font-size: 1.6rem"></span>
                    <span [style.fontSize]="'var(----ag-grid-fontSize)'" *ngIf="toggle.states[toggle.state].text">{{
                        toggle.states[toggle.state].translate !== false ? translations[toggle.states[toggle.state].text] : toggle.states[toggle.state].text
                    }}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
