import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

/**
 * Custom implmentation of angular/material cdk date adaptper.
 */

@Injectable({ providedIn: 'root' })
export class TTDateAdapter extends NativeDateAdapter {
    override parse(value: any, parseFormat?: any): Date | null {
        if (typeof value === 'string') {
            if (this.isDateMonthYearOrder(parseFormat.format)) {
                return this.newDateFromDateMonthYearOrder(value);
            } else if (this.isMonthDateYearOrder(parseFormat.format)) {
                return this.newDateFromMonthDateYearOrder(value);
            } else if (this.isYearMonthDateOrder(parseFormat.format)) {
                return this.newDateFromYearMonthDateOrder(value);
            }
        }

        return null;
    }

    override format(date: Date, displayFormat: any): string {
        if (date) {
            if (displayFormat.language === 'dk') displayFormat.language = 'da';
            if (displayFormat.language === 'li') displayFormat.language = 'lt';
            if (displayFormat.language === 'cn') displayFormat.language = 'zh';
            if (displayFormat.language === 'sy') displayFormat.language = 'no';
            return formatDate(date, displayFormat.format, displayFormat.language);
        }

        return '';
    }

    override getFirstDayOfWeek(): number {
        return 1;
    }

    /**
     * Creates a new Date type date from the given parts of a date. If a date could not be created, null is returned.
     *
     * @param year the year of the date to be created.
     * @param month the month of the date to be created, not zero-based.
     * @param date the date of the month of the date to be created, not zero-based.
     * @returns the new date that was created or `null` if a date could not be created.
     */
    private createNewDateFromParts(year: string | number, month: string | number, date: string | number): Date | null {
        const dateYear = Number(year.toString().replace(/^(\d{1,2})$/, (m, p1) => `${2000 + Number(p1)}`));
        const dateMonth = Number(month) - 1;
        const dateOfMonth = Number(date);

        if (!isNaN(Number(dateYear)) && !isNaN(Number(dateMonth)) && !isNaN(Number(dateOfMonth))) {
            const newDate = new Date();

            newDate.setFullYear(dateYear);
            newDate.setMonth(dateMonth, dateOfMonth);

            if (this.validateDate(newDate, { year: `${year}`, month: `${month}`, date: `${date}` })) {
                return newDate;
            }
        }
        return null;
    }

    /**
     * Creates a new date from the given date string, assuming the format is of date - month - year.
     *
     * @param dateString the date string to create a date of.
     * @returns the new date or null if a date could not be created.
     */
    private newDateFromDateMonthYearOrder(dateString: string): Date | null {
        const seperator = dateString.match(/\D/)?.[0] ?? '';
        const dateParts = dateString.split(seperator);

        if (seperator) {
            if (dateParts.length === 3 && dateParts.filter((part) => isNaN(Number(part))).length === 0) {
                return this.createNewDateFromParts(dateParts[2], dateParts[1], dateParts[0]);
            }
        } else if (seperator === '' && dateString.length >= 4) {
            let year = dateString.substring(4);
            let month = '';
            let date = '';

            let datePartString = dateString.split(' ')[0];

            if (datePartString.length === 4) {
                year = '20' + datePartString.substring(2);
                month = datePartString.substring(1, 2);
                date = datePartString.substring(0, 1);
            } else if (datePartString.length === 5) {
                year = '20' + datePartString.substring(3, 5);
                month = datePartString.substring(1, 3);
                date = datePartString.substring(0, 1);
            } else if (datePartString.length === 6) {
                year = '20' + datePartString.substring(4, 6);
                month = datePartString.substring(2, 4);
                date = datePartString.substring(0, 2);
            } else if (datePartString.split(' ')[0].length >= 7) {
                month = datePartString.substring(2, 4);
                date = datePartString.substring(0, 2);
            }

            return this.createNewDateFromParts(year, month, date);
        }

        return null;
    }

    /**
     * Creates a new date from the given date string, assuming the format is of month - date - year.
     *
     * @param dateString the date string to create a date of.
     * @returns the new date or null if a date could not be created.
     */
    private newDateFromMonthDateYearOrder(dateString: string): Date | null {
        const seperator = dateString.match(/\D/)?.[0] ?? '';
        const dateParts = dateString.split(seperator);

        if (seperator) {
            if (dateParts.length === 3 && dateParts.filter((part) => isNaN(Number(part))).length === 0) {
                return this.createNewDateFromParts(dateParts[2], dateParts[0], dateParts[1]);
            }
        } else if (seperator === '' && dateString.length >= 4) {
            let year = dateString.substring(4);
            let month = '';
            let date = '';

            if (dateString.length === 4) {
                year = '20' + dateString.substring(2);
                month = dateString.substring(0, 1);
                date = dateString.substring(1, 2);
            } else if (dateString.length === 5) {
                year = '20' + dateString.substring(3, 5);
                month = dateString.substring(0, 2);
                date = dateString.substring(2, 3);
            } else if (dateString.length === 6) {
                year = '20' + dateString.substring(4, 6);
                month = dateString.substring(0, 2);
                date = dateString.substring(2, 4);
            } else if (dateString.length >= 7) {
                month = dateString.substring(0, 2);
                date = dateString.substring(2, 4);
            }

            return this.createNewDateFromParts(year, month, date);
        }

        return null;
    }

    /**
     * Creates a new date from the given date string, assuming the format is of year - month - date.
     *
     * @param dateString the date string to create a date of.
     * @returns the new date or null if a date could not be created.
     */
    private newDateFromYearMonthDateOrder(dateString: string): Date | null {
        const seperator = dateString.match(/\D/)?.[0] ?? '';
        const dateParts = dateString.split(seperator);

        if (seperator) {
            if (dateParts.length === 3 && dateParts.filter((part) => isNaN(Number(part))).length === 0) {
                return this.createNewDateFromParts(dateParts[0], dateParts[1], dateParts[2]);
            }
        } else if (seperator === '' && dateString.length >= 4) {
            let year = dateString.substring(0, 4);
            let month = '';
            let date = '';

            if (dateString.length === 4) {
                year = '20' + dateString.substring(0, 2);
                month = dateString.substring(2, 3);
                date = dateString.substring(3);
            } else if (dateString.length === 5 || dateString.length === 6) {
                year = '20' + dateString.substring(0, 2);
                month = dateString.substring(2, 4);
                date = dateString.substring(4);
            } else if (dateString.length >= 7) {
                month = dateString.substring(4, 6);
                date = dateString.substring(6);
            }

            return this.createNewDateFromParts(year, month, date);
        }

        return new Date();
    }

    /**
     * Checks if the given date format has the order of date - month - year.
     *
     * @param dateFormat the date format to check the order for.
     * @returns `true` if the given date format has the order of date - month - year, `false` if not.
     */
    private isDateMonthYearOrder(dateFormat: string): boolean {
        const dateIndex = dateFormat.indexOf('d');
        const monthIndex = dateFormat.indexOf('M');
        const yearIndex = dateFormat.indexOf('y');

        if (dateIndex < monthIndex && monthIndex < yearIndex) {
            return true;
        }
        return false;
    }

    /**
     * Checks if the given date format has the order of month - date - year.
     *
     * @param dateFormat the date format to check the order for.
     * @returns `true` if the given date format has the order of month - date - year, `false` if not.
     */
    private isMonthDateYearOrder(dateFormat: string): boolean {
        const dateIndex = dateFormat.indexOf('d');
        const monthIndex = dateFormat.indexOf('M');
        const yearIndex = dateFormat.indexOf('y');

        if (monthIndex < dateIndex && dateIndex < yearIndex) {
            return true;
        }
        return false;
    }

    /**
     * Checks if the given date format has the order of year - month - date.
     *
     * @param dateFormat the date format to check the order for.
     * @returns `true` if the given date format has the order of year - month - date, `false` if not.
     */
    private isYearMonthDateOrder(dateFormat: string): boolean {
        const dateIndex = dateFormat.indexOf('d');
        const monthIndex = dateFormat.indexOf('M');
        const yearIndex = dateFormat.indexOf('y');

        if (yearIndex < monthIndex && monthIndex < dateIndex) {
            return true;
        }
        return false;
    }

    /**
     * Checks whether the parameters used to create a new date is equal to the values of the new date. If they are not equal, this
     * indicates that the user typed in an invalid date, like month = 25, which causes the date to be a lot futher in the future. If that's the
     * case than it should not be a valid date.
     *
     * @param date the date that was created from the parameters.
     * @param params the parameters that was used to create a date.
     * @returns `true` if the date matches the values of the parameters, `false` if not.
     */
    private validateDate(date: Date | null, params: { year: string; month: string; date: string }): boolean {
        if (!!date && date.toString() !== 'Invalid Date' && date?.getFullYear().toString().includes(params.year) && date.getMonth() === Number(params.month) - 1 && date.getDate() === Number(params.date)) {
            return true;
        }
        return false;
    }
}

/**
 * Represents different date elements of material ui components and how they should be formatted in the date-adapter.
 */
export const DATE_FORMATS = {
    parse: { dateInput: { format: 'dd/MM/yyyy' } },
    display: {
        dateInput: {
            format: 'dd/MM/yyyy',
            language: navigator.language || navigator.languages[0] || 'nb-NO',
        },
        monthYearLabel: {
            format: 'MMM YYYY',
            language: navigator.language || navigator.languages[0] || 'nb-NO',
        },
        dateA11yLabel: {
            format: 'd MMMM YYYY',
            language: navigator.language || navigator.languages[0] || 'nb-NO',
        },
        monthYearA11yLabel: {
            format: 'MMMM YYYY',
            language: navigator.language || navigator.languages[0] || 'nb-NO',
        },
    },
};
