(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('htmlProc', {
        templateUrl: 'views/components/views/htmlProc/htmlProc.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            htmlprocKeyno: '<?',
            htmlreportKeyno: '<?',
            htmlprocKeynoBelongto: '<?',
            htmlprocChange: '&'
        },
        controller: ['$stateParams', 'htmlProcService', 'languageService', 'ttGridFactory', '$element', 'translateService', 'stateService', 'base64', 'modalService', function (stateParams, htmlProcService, languageService, ttGridFactory, $element, translateService, stateService, base64, modalService) {
            const vm = this;

            vm.model = {
                paperWidthCM: 21,
                paperWidth: 805,
                paperHeight: 1000,
                scale: 1,
                htmlProc: {},
                htmlProcHtml: '',
                language_id: '',
                gridReady: false,
                lockedSave: false,
                lockedDelete: false,
                lockedHtmlRefresh: false,
                presentationTypeList: [],
                languageList: [],
                htmlProcList: [],
                translations: {
                    sub_procedures: 'sub_procedures',
                    preview: 'preview',
                    error: 'error',
                    ok: 'ok'
                },
                tabs: {
                    selected: 'HTMLPROC',
                    buttons: [
                        { id: 'HTMLPROC', label: 'procedure', color: 'primary', onClick: () => vm.model.selected = 'HTMLPROC' },
                        { id: 'COLUMNS', label: 'columns', color: 'primary', onClick: () => vm.model.selected = 'COLUMNS' },
                        { id: 'PREVIEW', label: 'preview', color: 'primary', onClick: () => vm.onPreview() },
                    ]
                }
            };

            vm.grid = new ttGridFactory({
                rememberId: 'htmlproc.grid',
            }).setOptionFunc((event) => {
                if (event.data.func === "OnCellClose" || event.data.func === "CheckboxBoxClick") {
                    vm.refreshReportPreview();
                }
            });

            let baseHtmlProcChangeTimer = null;

            vm.refreshReportPreview = async function () {
                if (baseHtmlProcChangeTimer !== null) {
                    clearTimeout(baseHtmlProcChangeTimer);
                    baseHtmlProcChangeTimer = null;
                };
                baseHtmlProcChangeTimer = setTimeout(() => { vm.htmlprocChange?.(); }, 600);
            };

            
            let load = function () {
                if (!vm.htmlprocKeyno) {
                    vm.htmlprocKeyno = stateParams.htmlproc_keyno;
                }
                if (!vm.htmlreportKeyno) {
                    vm.htmlreportKeyno = stateParams.htmlreport_keyno;
                }
                if (!vm.htmlprocKeynoBelongto) {
                    vm.htmlprocKeynoBelongto = stateParams.htmlproc_keyno_belongto;
                }

                if (vm.htmlprocKeyno) {
                    htmlProcService.getHtmlProc({ htmlproc_keyno: vm.htmlprocKeyno, htmlreport_keyno: vm.htmlreportKeyno }).then(function (result) {
                        angular.copy(result[0], vm.model.htmlProc);
                    });

                    htmlProcService.getHtmlProcList(vm.htmlprocKeyno).then(function (result) {
                        angular.copy(result, vm.model.htmlProcList);
                    });

                    vm.grid.dataTask.loadData = {
                        method: 3369,
                        parameters: { htmlproc_keyno: vm.htmlprocKeyno, language_id: vm.model.language_id }
                    };



                    vm.model.gridReady = true;
                }
                

                htmlProcService.getPresentationTypeList().then(function (result) {
                    console.dir(result);
                    angular.copy(result, vm.model.presentationTypeList);
                });

                languageService.list().then(function (result) {
                    angular.copy(result, vm.model.languageList);

                    const language = result.find(l => l.currentlanguage == 1);
                    if (language) vm.model.language_id = language.item_id;

                    vm.getHtmlPreview();
                });

                translateService.translateBatch(vm.model.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.model.translations[key] = val;
                    });
                });
                
                window.addEventListener('resize', vm.onResize);
                vm.onResize();
            };

            vm.getHtmlPreview = function () {
                vm.model.lockedHtmlRefresh = true;
                htmlProcService.getHtmlProcHtml({
                    htmlproc_keyno: vm.htmlprocKeyno,
                    language_id: vm.model.language_id,
                    jsonparms: ''
                }).then(function (result) {
                    if (angular.isDefined(result[0].htmlstring)) {
                        vm.model.htmlProcHtml = base64.urldecode(result[0].htmlstring);

                        let element = $element.find('#htmlProcPreview')[0];
                        if (angular.isDefined(element) && element !== null) {
                            element.innerHTML = vm.model.htmlProcHtml;
                        }
                    }
                    vm.model.lockedHtmlRefresh = false;
                });
            }

            vm.onChange = function(name, value) {
                switch (name) {
                    case 'language_id': {
                        if (value !== vm.model.language_id) {
                            vm.model.language_id = value;

                            vm.grid.dataTask.loadData = {
                                method: 3369,
                                parameters: { htmlproc_keyno: vm.htmlprocKeyno, language_id: vm.model.language_id }
                            };
                            vm.grid.gridfunc.read();

                            vm.getHtmlPreview();
                        }
                    }
                }
            }

            vm.rotatePreview = function () {
                vm.model.paperWidthCM = vm.model.paperWidthCM === 21 ? 29.7 : 21;
            }

            vm.onPreview = function () {
                vm.model.selected = 'PREVIEW';
                vm.getHtmlPreview();
            }

            vm.onResize = function () {
                const wrapper = document.getElementById('htmlProcPreviewWrapper');
                const preview = document.getElementById('htmlProcPreview');
                if (wrapper && wrapper.clientWidth > 0) {
                    vm.model.scale = vm.model.paperWidth < wrapper.clientWidth ? 1 : wrapper.clientWidth / vm.model.paperWidth;
                    //vm.model.scale = wrapper.clientWidth / vm.model.paperWidth;
                }

            }

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                vm.model.htmlProc.htmlreport_keyno = vm.htmlreportKeyno;
                vm.model.htmlProc.htmlproc_keyno_belongto = vm.htmlprocKeynoBelongto;
                htmlProcService.saveHtmlProc(vm.model.htmlProc).then(async function (response) {
                    console.dir(response);
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.model.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.model.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        if (vm.model.htmlProc.htmlproc_keyno == 0) {
                            stateService.back();
                        } else {
                            await vm.grid.gridfunc.read();
                            await vm.refreshReportPreview();//vm.getHtmlPreview();
                        }
                    }

                    vm.model.lockedSave = false;
                    
                }, function () {
                    vm.model.lockedSave = false;  
                });              
            };

            vm.delete = function () {
                vm.model.lockedDelete = true;

                htmlProcService.deleteHtmlProc(vm.model.htmlProc.htmlproc_keyno).then(function () {
                    vm.model.lockedDelete = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedDelete = false;
                });
            }

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                load();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION
            vm.$onChanges = function (changes) {
                if (changes.htmlprocKeyno && !changes.htmlprocKeyno.isFirstChange()) {
                    // Handle the change, reload data
                    load();
                    vm.grid.gridfunc.read();
                }
            };
            // #endregion ON CHANGES FUNCTION
        }]
    });
})();
