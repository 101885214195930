(function () {
    'use strict';

    angular.module("imApp").factory("accountingProcessingAttestationModalService", ['$q', '$ihttp', function ($q, $ihttp) {
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        let utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        let service = {
            /**
             * Retrieves a list of all possible attestants. 
             * 
             * @param {string} custactNo
             */
            getPossibleAttestants: (custactNo, accountingFunction, type) => call(2662, { custact_no: custactNo, accountingfunction: accountingFunction, type: type }),

            /**
             * Retrieves list of attestans for the given customer account number.
             * 
             * @param {string} custactNo customer account number.
             */
            getAttestants: (custactNo, accountingFunction, type) => call(2735, { custact_no: custactNo, accountingfunction: accountingFunction, type: type }),

            /** 
             *  Retrieves the log of actions from attestants.
             *  
             *  @param {string} custactNo the customer account to send to attestation. 
             */
            getAttestantsLog: (custactNo) => call(2663, { custact_no: custactNo }),

            /**
             * Sends the data to attestation.
             * 
             * @param {string} custact_no the customer account to send to attestation.
             * @param {object} data the data needed to send customer account to attestants.
             * @param {string} type the type sent to attestation, 'send', 'approve' or 'reject'.
             */
            sendToAttestation: (custact_no, data, type, accountingfunction, sendByClient) => {
                return call(2672, {
                    custact_no: custact_no,
                    attestants: data.attestants.data.list.filter((attestant) => attestant.is_selected === true),
                    comment: utoa(data.comment),
                    type: type,
                    accountingfunction: accountingfunction,
                    send_by_client: sendByClient
                });
            },

            reverseVoucher: function (parms) {
                return call(3012, parms);
            },

            /**
             * Performs accounting for the given customer account.
             * 
             * @param custactacKeyno the customer account to perform accounting on.
             */
            performAccounting: (custactacKeyno, comment, accountingfunction) => {
                return call(2674, { custactac_keyno: custactacKeyno, comment: utoa(comment), accountingfunction: accountingfunction });
            }
        };

        return service;
    }]);
})();
