(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('externalApis', {
        templateUrl: 'views/components/views/externalApis/externalApis.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'externalApisService', 'layoutService', function (stateService, externalApisService, layoutService) {
            const vm = this;

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {
                    searchtext: ''
                },
                itemsListExternalApis: []
        	};

            let loadExternalApis = function () {
                externalApisService.listExternalApis().then(function (list) {
                    angular.copy(list, vm.model.itemsListExternalApis);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'externalapi':
                        go({ external_api_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadExternalApis();
            };
        }]
    });
})();
