(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('oeeStateEdit', {
        templateUrl: 'views/components/views/oeeStateEdit/oeeStateEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'oeeStateEditService', function ($stateParams, stateService, oeeStateEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let oeeStateKeyno = $stateParams.oeestate_keyno;

            vm.model = {
                edit: {},
                selectListColours: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                oeeStateEditService.getEdit({ oeestate_keyno: oeeStateKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadColours = function () {
                oeeStateEditService.listColours().then(function (list) {
                    angular.copy(list, vm.model.selectListColours);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                oeeStateEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadColours();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
