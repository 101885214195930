(function () {
    'use strict';

    angular.module("imApp").factory("oeeDashBoardService", ['$ihttp', function ($ihttp) {
        let service = {
            reloaded: false,
            listOeeDashboard: function (parms) {
                return $ihttp.post({
                    method: 256,
                    parameters: parms || {}
                });
            },
            reloadOeeDashboard: function () {
                service.reloaded = false;

                setTimeout(() => {
                    service.reloaded = true;
                }, 30000);

                return service.reloaded;
            }
        };

        return service;
    }]);
})();
