(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('sopMenu', {
        templateUrl: 'views/components/views/sopMenu/sopMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'sopService', 'sopMenuService', function ($stateParams, $q, sopService, sopMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let sopKeyno = $stateParams.sop_keyno;
            
            vm.model = {
                menu: {},
                selectListFieldTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadMenu = function () {
                sopMenuService.getMenu({ sop_keyno: sopKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            let loadFieldTypes = function () {
                const deferred = $q.defer();

                sopService.listFieldTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListFieldTypes);

                    loadMenu();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadFieldTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
