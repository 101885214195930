import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../../listbox/listbox.component';
import { ListFilterType } from '../directives/list-filter.directive';

/**
 * Filters a list of items (of `Item` type).
 */
@Pipe({
    name: 'listFilter',
})
export class ListFilterPipe<TType extends Item> implements PipeTransform {
    /**
     * Filters through the given list of items using the given filter.
     * If strict is not set then it matches with items containing all of space seperated words, if strict is `true` then it will only match with items containing the phrase as a whole.
     *
     * @param items the list of items to filter through.
     * @param filter the filter to apply to the items.
     * @param filterType the behavior of the filter, default is 'fulltext'.
     * @returns list of filtered items.
     */
    transform(items: TType[], filter?: string, filterType?: ListFilterType): TType[] {
        if (!filter) return items;

        return items.filter((item: Item) => {
            let words: string[] = [];
            let match = false;

            if (filterType === 'phrasetext') {
                words = [filter];
            } else {
                words = filter.split(' ');
            }

            let filtervalue = '';

            if (Object.hasOwn(item, 'item_filtervalue')) {
                filtervalue = item.item_filtervalue!.toLowerCase();
            } else {
                filtervalue = Object.values(item)
                    .map((val) => '' + val)
                    .reduce((acc, value) => acc + ' ' + value, '')
                    .toLowerCase();
            }

            if (filterType === 'freetext') {
                match = words.some((word) => filtervalue.indexOf(word.toLowerCase()) !== -1 || item.item_id === '0' || item.item_id === '-1');
            } else {
                match = words.every((word) => filtervalue.indexOf(word.toLowerCase()) !== -1 || item.item_id === '0' || item.item_id === '-1');
            }

            return !!match;
        });
    }
}
