(function () {
    'use strict';

    angular.module("imApp").factory("selectLabourPuService", ['$ihttp', function ($ihttp) {
        let service = {
            savingToPreP2SelectLabourPuSave: false,
            listRegisteredByLabours: function (parms) {
                return $ihttp.post({
                    method: 195,
                    parameters: parms || {}
                });
            },
            saveItem: function (item) {
                return $ihttp.post({
                    method: 196,
                    parameters: item
                });
            }
        };

        return service;
    }]);
})();
