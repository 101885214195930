(function () {
    'use strict';

    angular.module("imApp").factory("salaryTypeService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 482,
                    parameters: parms || {}
                });
            },
            listExtraQuantityTypes: function (parms) {
                return $ihttp.post({
                    method: 3422,
                    parameters: parms || {}
                });
            },
            listImpactTimeAccount: function (parms) {
                return $ihttp.post({
                    method: 2124,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 483,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 484,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
