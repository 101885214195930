(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageSubscriptionEdit', {
        templateUrl: 'views/components/views/webpageSubscriptionEdit/webpageSubscriptionEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2WebpageSubscriptionKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'webpageSubscriptionEditService', 'layoutService', function ($stateParams, stateService, webpageSubscriptionEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2WebpageSubscriptionKeyno = vm?.p2WebpageSubscriptionKeyno ?? $stateParams.p2_webpage_subscription_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                webpageSubscriptionEditService.getEdit({ p2_webpage_subscription_keyno: p2WebpageSubscriptionKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                webpageSubscriptionEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;

                    if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                        vm.onSave();
                    } else {
                        stateService.back();
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2WebpageSubscriptionKeyno?.currentValue) {
                    p2WebpageSubscriptionKeyno = changes.p2WebpageSubscriptionKeyno.currentValue;
                }

                if (p2WebpageSubscriptionKeyno) loadEdit();
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
