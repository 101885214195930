(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2WebpageLinkEdit', {
        templateUrl: 'views/components/views/p2WebpageLinkEdit/p2WebpageLinkEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'p2WebpageLinkEditService', 'p2WebpageService', 'layoutService', function ($stateParams, $q, stateService, p2WebpageLinkEditService, p2WebpageService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2WebpageKeynoBelongto = $stateParams.p2_webpage_keyno_belongto;
            let p2WebpageLinkKeyno = $stateParams.p2_webpagelink_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                selectListWebPages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                p2WebpageLinkEditService.getEdit({
                    p2_webpage_keyno_belongto: p2WebpageKeynoBelongto,
                    p2_webpagelink_keyno: p2WebpageLinkKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            let loadWebpages = function () {
                const deferred = $q.defer();

                p2WebpageService.listWebpages().then(function (list) {
                    angular.copy(list, vm.model.selectListWebPages);

                    loadEdit();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2WebpageLinkEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;

                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadWebpages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
