import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterSecondList' })
export class FilterSecondListPipe implements PipeTransform {
    transform(items: any[], parentItem: any): any[] {
        //if (!items || !parentItem) return [];

        //let filtered: any[] = [];

        //items.forEach((value) => {
        //    if (value.parent_id && parentItem.item_id && value.parent_id === parentItem.item_id) {
        //        filtered = value;
        //    }
        //});

        //const response = filtered.slice().reverse();

        //return response;

        // original code sets `filtered = value` if value.parent_id === parentItem.item_id
        // then returns filtered.slice().reverse()
        let filtered: any = [];

        items.forEach(value => {
            if (
                value.parent_id !== undefined &&
                parentItem.item_id !== undefined &&
                value.parent_id === parentItem.item_id
            ) {
                // the old code overwrote `filtered` with a single "value"
                filtered = value;
            }
        });

        // if filtered is an array, return reversed, else return empty?
        if (Array.isArray(filtered)) {
            return filtered.slice().reverse();
        } else {
            // Possibly you'd want `[filtered].reverse()`, but the original code was ambiguous.
            return [];
        }
    }
}
