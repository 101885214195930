(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('furnitureFabricsMenu', {
        templateUrl: 'views/components/views/furnitureFabricsMenu/furnitureFabricsMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'furnitureFabricsMenuService', function ($stateParams, furnitureFabricsMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let furnitureFabricsId = $stateParams.furniturefabrics_id;

            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await furnitureFabricsMenuService.getMenu({ furniturefabrics_id: furnitureFabricsId }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
