(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('labourEdit', {
        templateUrl: 'views/components/views/labourEdit/labourEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'labourEditService', 'stateService', 'utilityService', 'modalService', 'selectCityService', 'ieScreenBlockService', 'translateService', 'trNewJobConfService', 'labourPositionService', 'logisticService', 'economyService', 'typeaheadService', function ($stateParams, labourEditService, stateService, utilityService, modalService, selectCityService, ieScreenBlockService, translateService, trNewJobConfService, labourPositionService, logisticService, economyService, typeaheadService) {
            const vm = this;
            let labourNo = $stateParams.labour_no || '0';
    		
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                ok_label: '',
                password_mismatch: '',
                validation_error: '',
                confirmation: ''
            };
            
            vm.model = {
                newLabour: false,
                portalUserButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('usersettings', { userid: vm.model.edit.portal_user_keyno }) }
                ],
                offDutyCodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.offdutycode_keyno = '0' }
                ],
                accountingCustomerButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('findcustomers') }
                ],
                accountingCustomerButtons2: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.accounting_cust_no }) }
                ],
                edit: {},
                selectListCompanies: [],
                selectListDepartments: [],
                selectListShifts: [],
                selectListOffDutyCodes: [],
                selectListFactories: [],
                stockplaces: [],
                lockedSave: false
            };
            
            vm.model.newLabour = labourNo === '0';
            
            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            var showMessage = function (message, title, type) {
                modalService.show({
                    type: type || 'default',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: translations.ok_label,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }]
                });
            };

            // #region LOAD PROCEDURE FUNCTION CALLS

            let load = function () {
                labourEditService.getCachedOrLoad(labourNo).then(function (data) {
                    data.password_repeat = data.password;
    
                    angular.copy(data, vm.model.edit);

                    loadSelectFactories();
    
                    if (selectCityService.selectedPostplace.id.isNullOrWhiteSpace() !== true && selectCityService.selectedPostplace.name.isNullOrWhiteSpace() !== true) {
                        vm.model.edit.labour_postplace_id = selectCityService.selectedPostplace.id;
                        vm.model.edit.postplace_name = selectCityService.selectedPostplace.name;
                    }
                });
            };
                    
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadDepartment = function () {
                logisticService.listDepartment().then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };
            
            let loadShifts = function () {
                economyService.listShifts({ only_active: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListShifts);
                });
            };

            let loadSelectOffDutyCodes = function () {
                trNewJobConfService.listSelectOffDutyCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListOffDutyCodes);
                });
            };

            let loadSelectFactories = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                vm.model.selectListFactories = [];

                return labourPositionService.listSelectFactories({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };

            async function loadStockplaces() {
                vm.model.stockplaces = await labourEditService.getStockplaces();
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shiftview':
                        labourEditService.cache.labour = vm.model.edit;

                        go({ labour_no: 'L' + vm.model.edit.labour_no });
                        break;
                    case 'calview':
                        labourEditService.cache.labour = vm.model.edit;

                        go({
                            viewtype: 'absence',
                            argtype: 'labour_no',
                            argvalue: vm.model.edit.labour_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            vm.saveChanges = function () {
                if (vm.model.edit.password !== vm.model.edit.password_repeat) {
                    showMessage(translations.password_mismatch, translations.validation_error, 'warning');
                    return;
                }
                
                var validationResult = labourEditService.validate(vm.model.edit);
                
                if (validationResult.isError === true) {
                    showMessage(validationResult.message, translations.validation_error, 'warning');
                    return;
                }
                
                ieScreenBlockService.start();
                
                vm.model.lockedSave = true;
                
                labourEditService.save(vm.model.edit).then(function (labourSave) {
                    ieScreenBlockService.stop();
    
                    vm.model.lockedSave = false;
    
                    if (labourSave.iserror === '1') {
                        showMessage(labourSave.errormessage, translations.confirmation, 'danger');
                    } else {
                        stateService.back();
                    }
                }, function () {
                    ieScreenBlockService.stop();
    
                    vm.model.lockedSave = false;
                });
            };
            
            // #region BUTTON DELETE PROCEDURE FUNCTION CALL
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    labourEditService.delete(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL
            
            vm.typeaheadSearchSupplier = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelectedSupplier = function (item) {
                if (item) {
                    vm.model.edit.supplier_no = item?.item_id ?? '0';
                    vm.model.edit.searchtext_supplier = item?.item_name ?? '';
                } else {
                    vm.onClearSupplier();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedSupplier = function (value, item) {
                vm.model.edit.searchtext_supplier = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.supplier_no = item.item_id;
                    }
                } else {
                    vm.onClearSupplier();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClearSupplier = function () {
                vm.model.edit.supplier_no = '0';
                vm.model.edit.searchtext_supplier = '';
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'postplace_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpStockPlaces({  searchtext: vm.model.edit[id] || '' });
                        break;
                    case 'accounting_cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'postplace_name':
                        if (item) {
                            vm.model.edit.labour_postplace_id = item?.item_id ?? '0';
                            vm.model.edit.postplace_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('postplace_name');
                        }
                        break;
                    case 'accounting_cust_name':
                        if (item) {
                            vm.model.edit.accounting_cust_no = item?.item_id ?? '0';
                            vm.model.edit.accounting_cust_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('accounting_cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'postplace_name':
                        vm.model.edit.postplace_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.labour_postplace_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('postplace_name');
                        }
                        break;
                    case 'accounting_cust_name':
                        vm.model.edit.accounting_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.accounting_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('accounting_cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'postplace_name':
                        vm.model.edit.labour_postplace_id = '0';
                        vm.model.edit.postplace_name = '';
                        break;
                    case 'accounting_cust_name':
                        vm.model.edit.accounting_cust_no = '0';
                        vm.model.edit.accounting_cust_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadSelectFactories();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                load();
                loadCompanies();
                loadDepartment();
                loadShifts();
                loadSelectOffDutyCodes();
                loadStockplaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
