(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2DataTaskEdit', {
        templateUrl: 'views/components/views/p2DataTaskEdit/p2DataTaskEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2DataTaskKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'p2DataTaskEditService', 'layoutService', function ($stateParams, stateService, translateService, modalService, p2DataTaskEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2DataTaskKeyno = vm?.p2DataTaskKeyno ?? $stateParams.p2_datatask_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION

            let loadEdit = function () {
                p2DataTaskEditService.getEdit({ p2_datatask_keyno: p2DataTaskKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2DataTaskEditService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].iserror !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                        
                        if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                            vm.onSave();
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2DataTaskKeyno?.currentValue) p2DataTaskKeyno = changes.p2DataTaskKeyno.currentValue;

                if (p2DataTaskKeyno) {
                    loadEdit();
                }
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
