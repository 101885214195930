import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { P2UpdateScriptEditComponent } from '@app/views/components/p2-update-script-edit/p2-update-script-edit.component';

@Component({
    selector: 'tt-p2-update-script-edit-dialog',
    templateUrl: './p2-update-script-edit-dialog.component.html',
    styleUrls: ['./p2-update-script-edit-dialog.component.css'],
})
export class P2UpdateScriptEditDialogComponent {
    @ViewChild(P2UpdateScriptEditComponent)
    public editComponent?: P2UpdateScriptEditComponent;

    public p2UpdateScriptKeyno: string | number;

    public saving = false;

    constructor(public dialog: MatDialogRef<P2UpdateScriptEditDialogComponent>, @Inject(MAT_DIALOG_DATA) data: P2UpdateScriptEditDialogData) {
        this.p2UpdateScriptKeyno = data?.p2UpdateScriptKeyno ?? '0';
    }

    public async save() {
        this.saving = true;

        try {
            await this.editComponent?.save();
            this.dialog.close(true);
        } finally {
            this.saving = false;
        }
    }
}

export interface P2UpdateScriptEditDialogData {
    p2UpdateScriptKeyno: string | number;
}
