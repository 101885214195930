(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceMakeMultiOrders', {
        templateUrl: 'views/components/views/invoiceMakeMultiOrders/invoiceMakeMultiOrders.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'invoiceMakeMultiOrdersService', function ($stateParams, invoiceMakeMultiOrdersService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custNo = $stateParams.cust_no;
            let invoiceMethodNo = $stateParams.invoicemethod_no;
            let businessCoNo = $stateParams.businessco_no;
            
            vm.model = {
                menu: {},
                itemsListMultiOrders: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await invoiceMakeMultiOrdersService.getMenu({
                    cust_no: custNo,
                    invoicemethod_no: invoiceMethodNo,
                    businessco_no: businessCoNo
                }))[0];

                loadMultiOrders();
            }

            async function loadMultiOrders() {
                vm.model.itemsListMultiOrders = (await invoiceMakeMultiOrdersService.listMultiOrders(vm.model.menu));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
