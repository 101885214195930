<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="tt-secondary-background">
    <span ttTranslateWord="tt_grid_print_title"></span>
</h2>
<mat-dialog-content style="display: flex; padding: 0; height: 100%; flex: 1; max-height: none; font-family: var(--tt-body-font-family)">
    <div class="tt-print-modal__preview">
        <span *ngIf="!previewLink || generating" class="loader" [ngClass]="generating ? 'loader-animate' : ''"></span>
        <iframe *ngIf="previewLink && !generating" class="col-xs-12 sp-0" style="height: 100%; width: 100%; border: none" [src]="previewLink"></iframe>
        <!-- <ng-container *ngIf="generating" [ngTemplateOutlet]="spinner"></ng-container> -->
    </div>
    <div style="width: 30rem; padding: 0 2rem">
        <tt-select ttLabel="printers" [(ttModel)]="printerId" (ttModelChange)="onPrinterChanged($event)" [ttData]="printers" ttDataId="item_id" ttDataName="item_name"></tt-select>
        <tt-select ttLabel="report" [(ttModel)]="reportKeyno" (ttModelChange)="onReportChanged($event)" [ttData]="reports" ttDataId="keyno" ttDataName="label"></tt-select>
        <div *ngIf="selectedRows.length > 0">
            <span class="tt-input__label"> <span ttTranslateWord="row_count"></span>: {{ selectedRows.length }} </span>
            <tt-checkbox ttLabel="preview_book" [(ttModel)]="previewBook"></tt-checkbox>
        </div>
        <tt-button ttText="generate_preview" ttIcon="far fa-file-search" (ttClick)="preview()" [ttLoading]="generating" ttLoadingText=""></tt-button>
        <!-- <hr class="col-xs-12 p-0" style="margin: 1.2rem 0; width: 100%" />
        <tt-button-goto></tt-button-goto> -->
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <tt-button class="col-xs-6 col-md-2 sp-4" ttText="tt_dismiss" ttType="secondary" (ttClick)="cancel()"></tt-button>
    <tt-button class="col-xs-6 col-md-2 sp-4" ttText="tt_confirm" ttType="success" (ttClick)="confirm()" [ttLoading]="printing"></tt-button>
</mat-dialog-actions>

<ng-template #spinner>
    <span class="loader loader-animate"></span>
    <!-- <tt-grid-loading-overlay class="tt-grid-modal__no-layout" style="width: 100%; display: flex; justify-content: center"></tt-grid-loading-overlay> -->
</ng-template>
<!-- <tt-select ttLabel="report" [ttData]="reports" ttDataId="keyno" ttDataName="label" [ttButtons]="reportButtons"></tt-select> -->
<!-- <div class="bootstrap-dialog type-info">
    <div class="modal-content">
        <div class="modal-header">
            <div class="bootstrap-dialog-header">
              
                <div class="bootstrap-dialog-title" ng-style="{'fontSize': vm.fontSize}">{{vm.translations.tt_grid_print_title}}</div>
            </div>
        </div>
        <div class="modal-body">
            <div class="bootstrap-dialog-body">
                <tt-select-printer class="col-xs-4 sp-0" tt-model="vm.$storage.printer" tt-change="vm.onPrinterChanged($printer, $changed)" ng-style="{'padding-top': vm.style.btn.paddingTop}"></tt-select-printer>
                <tt-select-buttons class="col-xs-8 sp-0"
                                   tt-label="report"
                                   tt-model="vm.report.keyno"
                                   tt-change="vm.handleReportChange($value)"
                                   tt-data="vm.reportList"
                                   tt-data-id="keyno"
                                   tt-data-name="label"
                                   tt-buttons="vm.reportListButtons"
                                   tt-style="vm.style.input"
                                   ng-style="{'padding-left': vm.style.select.paddingLeft}"></tt-select-buttons>
                <div class="col-xs-12 sp-0" style="display: flex; flex-direction: row">
                    <label ng-if="vm.selectedRows.length > 1" class="col-xs-4 sp-0 tt-input__label" style="font-size:14px;">{{vm.translations.row_count}}: {{vm.rowCount}}</label>
                    <tt-checkbox ng-if="vm.selectedRows.length > 1" class="col-xs-8 sp-0" tt-label="preview_book" tt-model="vm.previewBook" tt-change="vm.previewBook = $value" tt-style=""></tt-checkbox>
                </div>
    
            </div>
        </div>
        <div class="modal-footer" style="display: flow-root;">
            <div class="bootstrap-dialog-footer">
                <div class="bootstrap-dialog-footer-buttons">
                    <div class="col-xs-12 sp-0">
                        <tt-button class="col-xs-6 sp-0" style="padding-right: 2px;" tt-type="default" tt-text="tt_dismiss" tt-click="vm.dismiss()"></tt-button>

                        <tt-button class="col-xs-6 sp-0" style="padding-left: 2px;" tt-type="success" tt-text="tt_confirm" tt-click="vm.finish()"></tt-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
