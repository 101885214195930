(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('traceSearch', {
        templateUrl: 'views/components/views/traceSearch/traceSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'traceSearchService', 'ttGridFactory', 'rememberService', function ($stateParams, $q, stateService, utilityService, modalService, traceSearchService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let variableNames = {
                stockbatch_keyno: '',
                suppliers_batchno: '',
                purch_order_no: '',
                invoice_no: ''
            };
            
            vm.model = {
                gridReady: false,
                stockBatchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('stockbatch_keyno') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('stockbatch_keyno') }
                ],
                purchaseOrderButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('purch_order_no') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('purch_order_no') }
                ],
                invoiceButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('invoice_no') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('invoice_no') }
                ],
                setting: {},
                retrieving: false
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                traceSearchService.getTraceSearch({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;
                
                switch (id) {
                    case 'stockbatch_keyno':
                    case 'suppliers_batchno':
                    case 'purch_order_no':
                    case 'invoice_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'stockbatch_keyno':
                        vm.model.setting.stockbatch_keyno = '0';

                        if (vm.model.setting.stockbatch_keyno === '0') rememberFunc('stockbatch_keyno');
                        break;
                    case 'purch_order_no':
                        vm.model.setting.purch_order_no = '0';

                        if (vm.model.setting.purch_order_no === '0') rememberFunc('purch_order_no');
                        break;
                    case 'invoice_no':
                        vm.model.setting.invoice_no = '0';

                        if (vm.model.setting.invoice_no === '0') rememberFunc('invoice_no');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                switch (id) {
                    case 'stockbatch_keyno':
                        modalService.showCalculator(vm.model.setting.stockbatch_keyno).then(function (value) {
                            vm.model.setting.stockbatch_keyno = value;
                            
                            if (vm.model.setting.stockbatch_keyno > '' || vm.model.setting.stockbatch_keyno > 0) rememberFunc('stockbatch_keyno');
                        });
                        break;
                    case 'purch_order_no':
                        modalService.showCalculator(vm.model.setting.purch_order_no).then(function (value) {
                            vm.model.setting.purch_order_no = value;
                            
                            if (vm.model.setting.purch_order_no > '' || vm.model.setting.purch_order_no > 0) rememberFunc('purch_order_no');
                        });
                        break;
                    case 'invoice_no':
                        modalService.showCalculator(vm.model.setting.invoice_no).then(function (value) {
                            vm.model.setting.invoice_no = value;

                            if (vm.model.setting.invoice_no > '' || vm.model.setting.invoice_no > 0) rememberFunc('invoice_no');
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // #region BUTTON RETRIEVE PROCEDURE FUNCTION CALL

            vm.retrieveChanges = function () {
                vm.model.retrieving = true;

                vm.grid.gridfunc.read().then(function () {
                    vm.model.retrieving = false;
                });
            };

		    // #endregion BUTTON RETRIEVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockbatch_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockbatch_keyno) !== true) return;

                        vm.model.setting.stockbatch_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockbatch_keyno, true) !== true) return;

                        if (vm.model.setting.stockbatch_keyno === '' || vm.model.setting.stockbatch_keyno === '0') rememberFunc('stockbatch_keyno');
                        break;
                    case 'suppliers_batchno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.suppliers_batchno) !== true) return;

                        vm.model.setting.suppliers_batchno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.suppliers_batchno, true) !== true) return;

                        if (vm.model.setting.suppliers_batchno === '') rememberFunc('suppliers_batchno');
                        break;
                    case 'purch_order_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.purch_order_no) !== true) return;

                        vm.model.setting.purch_order_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.purch_order_no, true) !== true) return;

                        if (vm.model.setting.purch_order_no === '') rememberFunc('purch_order_no');
                        break;
                    case 'invoice_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.invoice_no) !== true) return;

                        vm.model.setting.invoice_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.invoice_no, true) !== true) return;

                        if (vm.model.setting.invoice_no === '') rememberFunc('invoice_no');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'stockbatch_keyno':
                        if (utilityService.validateParmsValue(vm.model.setting.stockbatch_keyno, true) !== true) return;

                        if (vm.model.setting.stockbatch_keyno > '' || vm.model.setting.stockbatch_keyno > 0) rememberFunc('stockbatch_keyno');
                        break;
                    case 'suppliers_batchno':
                        if (utilityService.validateParmsValue(vm.model.setting.suppliers_batchno, true) !== true) return;

                        if (vm.model.setting.suppliers_batchno > '') rememberFunc('suppliers_batchno');
                        break;
                    case 'purch_order_no':
                        if (utilityService.validateParmsValue(vm.model.setting.purch_order_no, true) !== true) return;

                        if (vm.model.setting.purch_order_no > '' || vm.model.setting.purch_order_no > 0) rememberFunc('purch_order_no');
                        break;
                    case 'invoice_no':
                        if (utilityService.validateParmsValue(vm.model.setting.invoice_no, true) !== true) return;

                        if (vm.model.setting.invoice_no > '' || vm.model.setting.invoice_no > 0) rememberFunc('invoice_no');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
