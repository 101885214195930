(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectOperationPu', {
        templateUrl: 'views/components/views/selectOperationPu/selectOperationPu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectOperationPuService', function ($stateParams, selectOperationPuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let webPageName = $stateParams.webpagename;
            
            vm.model = {
                bgOptions: {
                    selected: '1',
                    buttons: [
                        { id: '1', label: 'operation_direct', color: 'primary', onClick: () => vm.model.bgOptions.selected = '1' },
                        { id: '0', label: 'operation_indirect', color: 'primary', onClick: () => vm.model.bgOptions.selected = '0' }
                    ]
                },
                itemsListOperations: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadOperations = function () {
                vm.model.itemsListOperations = [];

                selectOperationPuService.listOperations({
                    webpagename: webPageName,
                    direct: vm.model.bgOptions.selected
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOperations);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region SAVE FUNCTION 

            vm.saveToPrevious = function (item) {
                selectOperationPuService.saveItem({
                    operation_id: item.item_id,
                    webpagename: webPageName
                });
            };

            // #endregion SAVE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOptions = function (value) {
                vm.model.bgOptions.selected = value;

                loadOperations();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadOperations();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
