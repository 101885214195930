(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2Webpage', {
        templateUrl: 'views/components/views/p2Webpage/p2Webpage.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'rememberService', 'p2WebpageService', 'layoutService', function ($q, stateService, utilityService, rememberService, p2WebpageService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            const rememberId = 'p2Webpage.config';
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2WebpageKeyno: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                itemsListWebPages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                p2WebpageService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadWebpages();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadWebpages = function () {
                p2WebpageService.listWebpages().then(function (list) {
                    angular.copy(list, vm.model.itemsListWebPages);

                    angular.forEach(vm.model.itemsListWebPages, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER FUNCTION CALLS

            let loadLastStatus = function () {
                rememberService.getLastStatus(rememberId).then(function (lastState) {
                    if (angular.isArray(lastState) !== true) return;
                    if (lastState.length < 1) return;
                    if (angular.isUndefined(lastState[0].variablevalue)) return;
                    if (angular.isString(lastState[0].variablevalue) !== true) return;
                    if (lastState[0].variablevalue.length < 1) return;

                    var state = angular.fromJson(lastState[0].variablevalue);

                    if (angular.isDefined(state.searchText)) {
                        vm.model.setting.searchtext = state.searchText;
                    }
                });
            };

            let rememberFunc = function () {
                rememberService.remember(rememberId, angular.toJson({
                    searchText: vm.model.setting.searchtext
                }));
            };

            // #endregion REMEMBER FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadWebpages();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2WebpageKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2webpageedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2webpageedit') {
                            vm.model.p2WebpageKeyno = '0';
                        } else {
                            go({ p2_webpage_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLastStatus();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
