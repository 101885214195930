<tt-bottom-sheet ttTopAnchorActive="false" ttBottomAnchorActive="true"> testtihi {{ date }} </tt-bottom-sheet>
<tt-toast></tt-toast>
<tt-toast ttType="success" ttTitle="data_saved" ttMessage="data_saved">
    <tt-overflow-menu>
        <tt-overflow-menu-item ttText="user" ttIcon="far fa-user" (ttClick)="log('clicked')"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="settings" ttIcon="far fa-cog" (ttClick)="log('clicked')"></tt-overflow-menu-item>
        <tt-overflow-menu-item [ttHasDivider]="true" ttText="delete" [ttDanger]="true" ttIcon="far fa-backspace" (ttClick)="log('clicked')"></tt-overflow-menu-item>
    </tt-overflow-menu>
</tt-toast>
<tt-toast ttType="warning" ttTitle="message0036" ttMessage="message0036"></tt-toast>
<tt-toast ttType="danger" ttTitle="message0036" ttMessage="message0036"></tt-toast>
<tt-slider ttLabel="user"></tt-slider>
<tt-date-range
    ttRememberId="jlr-test"
    ttOnlyRemember="false"
    ttLabel="date_fom_tom"
    [ttStartDate]="dateString"
    (ttStartDateChange)="dateString = $event"
    [ttShowShortLabel]="false"
    (ttEndDateChange)="log('endate changed >>: ' + $event)"
    [ttModel]="dateModel"
    (ttModelChange)="dateModel = $event; log('dateModelChange'); log(dateModel)"
    ttToggleTipText="user"
></tt-date-range>
<tt-color-input ttRememberId="jlr-test" ttOnlyRemember="false" ttLabel="tile_background_color" (ttModelChange)="log($event)" [ttDebounceTime]="50"></tt-color-input>
<tt-date-range [ttShowShortLabel]="true" ttInterval="month"></tt-date-range>
<tt-input [(ttModel)]="filter"></tt-input>
<tt-content-switcher ttRememberId="jlr-test" [ttStretchWidth]="false" (ttSelectedIdChange)="log('selected id from content switcher changed >>: ' + $event)">
    <tt-switch ttId="list" ttIcon="fa-fw fas fa-list"></tt-switch>
    <tt-switch ttId="grid" ttIcon="fa-fw fas fa-th-large"></tt-switch>
    <tt-switch ttId="columns" ttIcon="fa-fw fas fa-line-columns"></tt-switch>
</tt-content-switcher>
<tt-image
    ttLabel="picture"
    ttLabelView="top"
    ttTranslateLabel="false"
    ttSrc="https://insoft.sirv.com/TouchTime/prod_id/2022/12/IMG_5895_2022-12-22111448.JPG?scale.height=220"
    (ttClick)="log('ttClick from tt-image')"
    (ttPlaceholderClick)="log('ttPlaceholderClick from tt-image')"
></tt-image>
<tt-divider ttRememberId="jlr-test-dividers">
    <tt-divider-columns ttId="testcol">
        <tt-divider-pane ttId="pane1" ttSize="40">
            <tt-divider-rows ttId="testNestedDivRows" (ttResizeEnd)="log('resize end >>:' + $event)">
                <tt-divider-pane ttId="pane1"></tt-divider-pane>
                <tt-divider-pane ttId="pane2"></tt-divider-pane>
                <tt-divider-pane ttId="pane3">
                    <tt-card class="col-xs-12 p-10" ttHeading="Datetime">
                        {{ date | date }}
                        <tt-datetime
                            ttRememberId="jlr-test"
                            ttOnlyRemember="false"
                            ttLabel="user"
                            [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
                            ttTranslateToggleTipText="false"
                            [ttModel]="date"
                            (ttModelChange)="date = $event; log($event)"
                            ttMinutePrecision="30"
                            ttAllowEmptyDate="true"
                        ></tt-datetime>

                        <!-- <tt-datetime ttLabel="user" [ttModel]="date" (ttModelChange)="log($event)" ttMinutePrecision="30"></tt-datetime> -->
                        <!-- <tt-datetime ttInvalid ttTabable="false" ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="up"></tt-datetime>-->
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="down"></tt-datetime>
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="true" ttAllowEmptyTime="true"></tt-datetime>
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttReadonly></tt-datetime>
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttDisabled></tt-datetime>
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideTime></tt-datetime>
                        <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideDate></tt-datetime>
                    </tt-card>
                </tt-divider-pane>
                <tt-divider-pane ttId="pane4">
                    <tt-tabs ttRememberId="jlr-test" [(ttSelectedId)]="selectedTab" (ttSelectedIdChange)="log($event)">
                        <tt-tab ttId="test1" ttLabel="test1"></tt-tab>
                        <tt-tab ttId="test2" ttLabel="test2"></tt-tab>
                        <tt-tab ttId="test3" ttLabel="test3" ttDisabled></tt-tab>
                    </tt-tabs>
                    <div *ngIf="selectedTab === 'test1'">test1 tab</div>
                    <div *ngIf="selectedTab === 'test2'">test2 tab</div>
                    <div *ngIf="selectedTab === 'test3'">test3 tab</div>
                    ttlist
                    <tt-list [ttData]="items" [ttFilter]="filter" ttShowFileExtensionIcon [ttButtons]="formButtons" [ttButtonParms]="formButtons" [ttCheckboxSelection]="true" ttHideSubtitle></tt-list>
                </tt-divider-pane>
            </tt-divider-rows>
            <!-- <div style="background: salmon; height: 100%; width: 100%"></div> -->
        </tt-divider-pane>
        <tt-divider-pane ttId="pane2" ttSize="20">
            <!-- <div style="background: yellowgreen; height: 100%; width: 100%"></div> -->
        </tt-divider-pane>
        <tt-divider-pane ttId="pane3" ttSize="40">
            <!-- <div style="background: skyblue; height: 100%; width: 100%"></div> -->
        </tt-divider-pane>
    </tt-divider-columns>
</tt-divider>
<tt-translate-edit class="col-xs-12 sp-0" [ttTranslateEdits]="lis" ttGoogleLanguageIdKey="id"></tt-translate-edit>
<!-- <tt-tabs [ttTabs]="tabs"> </tt-tabs> -->

<!-- <ng-template #ttListItemTemplate let-item="item">
        {{ item.item_name }}
    </ng-template> -->
<!-- <tt-list-item></tt-list-item>
</tt-list>

<tt-button-goto ttText="product" ttType="danger" ttGoto="product" [ttGotoParms]="'__prod_keyno4159'" [ttDisabled]="true" (ttClick)="log('link clicked')"></tt-button-goto>
<tt-button-goto ttText="product" ttGoto="product" [ttGotoParms]="'__prod_keyno4159'" (ttClick)="log('link clicked')"></tt-button-goto>
<tt-button-goto ttText="product" ttGoto="product" [ttGotoParms]="['__prod_keyno4159']" (ttClick)="log('link clicked')"></tt-button-goto>
<tt-button-goto ttText="product" ttGoto="product" [ttGotoParms]="{ prod_id: '__prod_keyno4159' }" (ttClick)="log('link clicked')"></tt-button-goto>
<tt-button-goto ttText="product" (ttClick)="log('link clicked')"></tt-button-goto> -->
<!-- <tt-list [ttData]="items" [ttFilter]="filter" [ttButtons]="formButtons"></tt-list> -->
<!-- ttlist reverse -->
<!-- <tt-list [ttData]="items" ttReverse></tt-list> -->
<!-- <br />
<p>check</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="check"></im-list>
<br />
<p>check+</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="check+"></im-list>
<br />
<p>back</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="back"></im-list>
<br />
<p>mark</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="mark"></im-list>
<br />
<p>multi</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="multi"></im-list>
<br />
<p>sortable</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="sortable"></im-list>
<br />
<p>custom</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="custom"></im-list>
<br />
<p>default</p>
<im-list [listItems]="items" designVar="s12t0" [filterVal]="filter" ltype="default"></im-list> -->

<!-- <tt-grid [ttOptions]="invoices"></tt-grid>
<tt-grid [(ttOptions)]="stockMinMaxAdjustGrid"></tt-grid> -->
<!-- <tt-grid [(ttOptions)]="stockMinMaxAdjustGrid"></tt-grid> -->
<!-- <h2 class="tt-header" style="font-size: 2.4rem; padding: 0; border: none; padding-left: 10px">Testside</h2> -->
<!-- <tt-grid [ttOptions]="laboursGrid"></tt-grid> -->
<!-- <tt-card class="col-xs-12 p-10" ttHeading="Grid"> </tt-card> -->
<!-- <h3 class="col-xs-12">Grid -Aktivitetsstyring</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="activityManagementGrid"></tt-grid> -->
<!-- 
<h3 class="col-xs-12">Grid - Restordre</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="gridOrderlineBackorders"></tt-grid> -->
<!-- 
<!-- <h3 class="col-xs-12">Grid - Leverandørreskontrok</h3> -->
<!-- <tt-grid class="col-xs-12 sp-0" [ttOptions]="acsupptranses" (ttOpenModal)="log($event)"></tt-grid> -->
<!-- <button (click)="logStuff()">dirty payouts</button>
<h3 class="col-xs-12">Grid - Utbetalinger</h3>
<tt-grid class="col-xs-12 sp-0" [(ttOptions)]="payouts"></tt-grid>

<h3 class="col-xs-12">Grid - Lagereksport test</h3>
<div>
    <tt-grid class="col-xs-12 sp-0" [ttOptions]="gridxexport"></tt-grid>
</div>

<h3 class="col-xs-12">Grid - Kontroll av timer</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="powtimesGrid"></tt-grid> -->

<!-- <button (click)="hideButton = hideButton === true ? 'hidden' : !hideButton">{{ hideButton ? "Skjul" : "Vis" }}</button> -->
<!-- <button (click)=? 'hidden' : !hideButton">{{ hideButton ? "Skjul" : "Vis" }}</button> -->
<!-- <button (click)=? 'h[idden' ]: !hideButton">{{ hideButton ? "Skjul" : "Vis" }}</button> -->
<!-- <button (click)=? 'hidde{prod_id: ' '}: !hideButton">{{ hideButton ? "Skjul" : "Vis" }}</button> -->
<!-- <button (hideButton ? "Skjul" : "Vis" }}</button> -->
<!-- 
<h3 class="col-xs-12">Grid - Bilagsbehandling</h3>
<tt-grid class="col-xs-12 sp-0" [(ttOptions)]="accountingprocessingGrid"></tt-grid> -->
<!-- 
<h3 class="col-xs-12">Grid - Aktiviteter</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="activityGrid"></tt-grid> -->

<ng-template #helloWorld><h1>Hello world!</h1></ng-template>
<button [ttPopover]="helloWorld">click</button>
<!--<tt-card class="col-xs-12 p-10" ttHeading="ID-porten">
    <tt-button class="col-xs-4 sp-2" ttText="Bank Id logg inn" (ttClick)="log(accountingprocessingGrid)" [ttTranslateText]="false"></tt-button>
    <tt-button class="col-xs-4 sp-2" larttText="Hent bruker" (ttClick)="getUser()" [ttTranslateText]="false"></tt-button>
    <tt-button class="col-xs-4 sp-2" ttText="Bank Id logg ut" (ttClick)="logoutIdPorten()" [ttTranslateText]="false"></tt-button>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="ButtonMulti">
    <tt-button-multi class="col-xs-4 sp-0" ttText="save">
        <tt-button ttIcon="far fa-cog"></tt-button>
        <tt-overflow-menu>
            <tt-overflow-menu-item ttText="user" ttIcon="far fa-user" (ttClick)="log('clicked')"></tt-overflow-menu-item>
            <tt-overflow-menu-item ttText="settings" ttIcon="far fa-cog" (ttClick)="log('clicked')"></tt-overflow-menu-item>
            <tt-overflow-menu-item [ttHasDivider]="true" ttText="delete" [ttDanger]="true" ttIcon="far fa-backspace" (ttClick)="log('clicked')"></tt-overflow-menu-item>
        </tt-overflow-menu>
    </tt-button-multi>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="ButtonSave">
    <tt-button-save ttPrinter="print"></tt-button-save>
    <tt-button-save ttText="save" ttBadge="52" ttTextAlign="left"></tt-button-save>
    <tt-button-save [ttStyle]="{ button: { backgroundColor: 'red' } }" ttDisabled></tt-button-save>
    <tt-button-save></tt-button-save>
    <tt-button-save ttBadge="2" ttIcon="fas fa-pencil" ttIconPullRight></tt-button-save>
    <tt-button-save ttBadge="2" ttIcon="fas fa-pencil" ttIconAlign="left"></tt-button-save>
    <tt-button-save ttIcon="fas fa-pencil" ttSpin></tt-button-save>
    <tt-button-save ttLoading></tt-button-save>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Button">
    <div class="col-xs-12 sp-0">
        <tt-button class="col-xs-2 sp-2" ttType="primary" ttText="Primary" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="secondary" ttText="Secondary" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="danger" ttText="Danger" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="success" ttText="Success" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="warning" ttText="Warning" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="link" ttText="Link" ttTranslateText="false"></tt-button>
    </div>
    <tt-button ttPrinter="print"></tt-button>
    <tt-button ttText="save" ttBadge="52" ttTextAlign="left"></tt-button>
    <tt-button [ttStyle]="{ button: { backgroundColor: 'red' } }" ttDisabled></tt-button>
    <tt-button></tt-button>
    <tt-button ttBadge="2" ttIcon="fas fa-pencil" ttIconPullRight></tt-button>
    <tt-button ttBadge="2" ttIcon="fas fa-pencil" ttIconAlign="left"></tt-button>
    <tt-button ttIcon="fas fa-pencil" ttSpin></tt-button>
    <tt-button ttLoading></tt-button>
</tt-card>

    -->
<tt-card class="col-xs-12 p-10" ttHeading="Textarea">
    <tt-textarea
        ttRememberId="jlr-test"
        ttOnlyRemember="false"
        ttLabel="user"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        [(ttModel)]="textarea"
        (ttModelChange)="log(textarea)"
        [ttButtons]="formsButtons"
    ></tt-textarea>
    <tt-textarea ttLabel="user" ttReadonly [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
    <tt-textarea ttLabel="user" ttSublabel="Hjelpetekst" ttDisabled [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
    <tt-textarea ttLabel="user" ttInvalid ttInvalidText="Invalid" [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
</tt-card>
<!--
<tt-card class="col-xs-12 p-10" ttHeading="Select">-->
<tt-select
    ttRememberId="jlr-test"
    ttOnlyRemember="false"
    ttLabel="user"
    ttSublabel="help"
    [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
    ttTranslateToggleTipText="false"
    ttModel="2"
    [ttData]="selectData"
    ttDataName="name"
    ttDataId="id"
    [ttStyle]="{ label: { color: 'red' } }"
></tt-select>
<<!--
    <tt-select ttLabel="user" ttSublabel="help" ttInvalid ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttSublabel="help" [ttInvalid]="true" ttInvalidText="Invalid" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttSublabel="help" ttInvalidText="Invalid" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" ttHideArrow></tt-select>
    <tt-select ttLabel="user" ttDisabled="true" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttReadonly="true" ttModel="2" (ttModelChange)="log($event)" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Fileuploader">
    <tt-file-uploader
        ttLabel="attachment"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        ttSublabel=".jpg, .png, .webp"
        ttTranslateSublabel="false"
        ttAccept=".pdf"
    ></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttMultiple></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttMultiple ttDropZone></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttAccept=".pdf" ttMultiple ttDropZone></tt-file-uploader>
</tt-card>
- -->

<!-- <h3>Grid: Kontroll av timer</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="grid"></tt-grid> -->

<!-- <tt-card class="col-xs-12 p-10" ttHeading="OverflowMenu">
    <tt-overflow-menu class="pull-right">
        <tt-overflow-menu-item ttText="user" ttIcon="fal fa-user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="settings" ttIcon="fal fa-cog"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="delete" ttIcon="fal fa-backspace" ttHasDivider ttDanger (ttClick)="log('clicked')"></tt-overflow-menu-item>
    </tt-overflow-menu>
</tt-card>

<tt-card>
    <tt-progress-bar
        ttLabel="user"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        ttLabelView="top"
        ttSublabel="16 av 100"
        [ttTranslateSublabel]="false"
        [ttModel]="text4"
    ></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" [ttTranslateSublabel]="false" [ttModel]="text4"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" [ttTranslateSublabel]="false" ttIndeterminate [ttModel]="columns"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" ttStatus="finish" [ttTranslateSublabel]="false" [ttModel]="text4"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" ttStatus="invalid" [ttTranslateSublabel]="false" ttIndeterminate [ttModel]="columns"></tt-progress-bar>
    <tt-progress-bar
        ttLabel="user"
        ttSublabel="16 av 100"
        ttStatus="invalid"
        ttInvalidText="Noe gikk galt"
        [ttTranslateInvalidText]="false"
        [ttTranslateSublabel]="false"
        ttIndeterminate
        [ttModel]="columns"
    ></tt-progress-bar>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="MultiSelect"> -->
<!-- <tt-multi-select
        ttLabel="user"
        [ttData]="this.selectData"
        [ttFilterValue]="textValue"
        (ttFilterValueChange)="textValue = $event"
        ttDataId="id"
        ttDataName="name"
        ttDataSelected="selected"
        ttAllSelected="false"
    ></tt-multi-select> -->
<!-- <tt-multi-select
        ttLabel="user"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        ttInvalid
        [ttData]="this.selectData"
        [(ttFilterValue)]="textValue"
        ttDataId="id"
        ttDataName="name"
        ttDataSelected="selected"
        ttAllSelected
    ></tt-multi-select>
    <tt-multi-select ttLabel="user" [ttData]="this.selectData" [(ttFilterValue)]="textValue" ttDataId="id" ttDataName="name" ttDataSelected="selected" ttAllSelected></tt-multi-select>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Time">
    {{ textValue }}-->
<tt-time
    ttRememberId="jlr-test"
    ttOnlyRemember="false"
    ttLabel="user"
    [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
    ttTranslateToggleTipText="false"
    [ttModel]="textValue"
    (ttModelChange)="textValue = $event; log($event)"
    ttMinutePrecision="30"
    ttAllowEmpty="true"
></tt-time>
<!---  <tt-time ttLabel="user" [ttModel]="textValue" (ttModelChange)="textValue = $event; log($event)" ttMinutePrecision="5"></tt-time>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="date">
    {{ date | date }} -->
<tt-date ttRememberId="jlr-test" ttOnlyRemember="false" ttLabel="user" [ttModel]="date" (ttModelChange)="date = $event; log($event)"></tt-date>
<!--
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Datetime">
    {{ date | date }}
    <tt-datetime
        ttLabel="user"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        [ttModel]="date"
        (ttModelChange)="date = $event; log($event)"
        ttMinutePrecision="30"
        ttAllowEmptyDate="true"
    ></tt-datetime> -->
<!-- <tt-datetime ttLabel="user" [ttModel]="date" (ttModelChange)="log($event)" ttMinutePrecision="30"></tt-datetime> -->
<!-- <tt-datetime ttInvalid ttTabable="false" ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="up"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="down"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="true" ttAllowEmptyTime="true"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttReadonly></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttDisabled></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideTime></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideDate></tt-datetime>
</tt-card> -->

<!-- <tt-card class="col-xs-12 p-10" ttHeading="Tabs">
    <tt-tabs [ttTabs]="tabs" [(ttModel)]="selectedTab" (ttModelChange)="onTabChange($event)"></tt-tabs>
    <ng-container [ngSwitch]="selectedTab">
        <div *ngSwitchCase="'EDIT'">
            <h2>Rediger</h2>
        </div>
        <div *ngSwitchCase="'CONTACTS'">
            <h2>Kontaktpersoner</h2>
        </div>
        <div *ngSwitchCase="'EMAIL'">
            <h2>E-poster</h2>
        </div>
        <div *ngSwitchCase="'PROJECTS'">
            <h2>Prosjekter</h2>
        </div>
    </ng-container>
</tt-card> -->
<!-- 
<tt-card class="col-xs-12 p-10" ttHeading="Search"> -->
<p>Live søk for kunde:</p>
<tt-search
    ttRememberId="jlr-test"
    ttOnlyRemember="false"
    ttInvalid
    [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
    ttTranslateToggleTipText="false"
    ttLabel="search"
    [(ttModel)]="customerSearchtext"
    [ttSearch]="search"
    ttSearchParmName="cust_name"
    (ttOnSelected)="onListboxSelect($event)"
    (ttOnClear)="onClear()"
    ttFilterOperation="none"
    [ttButtonParms]="newVariables"
></tt-search>
<!--
    <p>Filtrere statisk data (typeahead):</p>
    <tt-search ttLabel="search" [(ttModel)]="textValue" [ttData]="data" ttDataName="text" ttDebounceTime="0" ttMinLength="0" ttFilterOperation="filter"></tt-search>

    <p>Sortere statisk data (typeahead):</p>
    <tt-search ttLabel="search" [(ttModel)]="textValue" [ttData]="data" ttDataName="text" ttDebounceTime="0" ttMinLength="0" ttFilterOperation="sort"></tt-search>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Checkbox">
    <p>{{ check === "1" ? "Checkbox med enveisbinding er avkrysset, verdi er: " + check : "Checkbox med enveisbinding er ikke avkrysset, verdi er: " + check }}</p>
    <tt-checkbox
        ttLabel="user"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        [ttModel]="check"
    ></tt-checkbox>
    <p>{{ check2 === "true" ? "Checkbox med toveisbinding er avkrysset, verdi er: " + check2 : "Checkbox med toveisbinding er ikke avkrysset, verdi er: " + check2 }}</p>-->
<tt-checkbox ttRememberId="jlr-test" ttOnlyRemember="false" ttLabel="user" [(ttModel)]="check2"></tt-checkbox>
<!--   <tt-checkbox ttInvalid ttLabel="user" [(ttModel)]="check2" ttTrueValue="true" ttFalseValue="false"></tt-checkbox>
</tt-card>-->
<tt-card class="col-xs-12 p-10" ttHeading="Input">
    <tt-input
        ttRememberId="jlr-test"
        ttOnlyRemember="false"
        ttInvalid
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttBindFocus="false"
        ttMaxLength="20"
        [ttToggleTipText]="'Her kan en hjelptetekst med litt mere tekst enn sublabel som kan gi en bedre forklaring for hva som forventes for dette feltet her'"
        ttTranslateToggleTipText="false"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
    ></tt-input>
    <tt-input class="col-xs-12 sp-0" ttLabel="user" ttRequired [(ttModel)]="text1" (ttModelChange)="onTextChanged($event)" ttFormatNumber="true" ttAllowEmpty="false" ttDecimals="2"></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttLabelView="top"
        ttTranslateLabel="false"
        ttDisabled
        [(ttModel)]="text2"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="false"
        [ttButtonParms]="newVariables"
    ></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttSublabel="user"
        ttTranslateSublabel
        ttRequired
        ttReadonly
        [(ttModel)]="text3"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="true"
        ttDecimals="-3"
        [ttButtons]="formsButtons"
        [ttButtonParms]="newVariables"
    ></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        ttShowClearButton
        [ttButtons]="formsButtons"
        [ttButtonParms]="newVariables"
    ></tt-input>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="InputNumber">
    <tt-input-number
        ttInvalid
        ttRememberId="jlr-test"
        ttOnlyRemember="false"
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttBindFocus="false"
        [(ttModel)]="check2"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttRequired
        [(ttModel)]="text1"
        ttRememberId="jlr-test"
        ttOnlyRemember="false"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="false"
        ttDecimals="2"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttLabelView="top"
        ttTranslateLabel="false"
        ttDisabled
        [(ttModel)]="text2"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="false"
        [ttButtonParms]="newVariables"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttSublabel="user"
        ttTranslateSublabel
        ttRequired
        ttReadonly
        [(ttModel)]="text3"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="true"
        ttDecimals="-3"
        [ttButtonParms]="newVariables"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
    ></tt-input-number>
    <!-- [ttButtons]="formButtons" -->
    <!-- <!-- </tt-card> -->

    <tt-card class="col-xs-12 p-10" ttHeading="Listbox">
        <p>Listbox popup med toveisbinding</p>
        <input aria-label="test1" ttListboxPopup ttLabel="test" [(ngModel)]="textValue" (ngModelChange)="textValue = $event" [ttData]="data" ttMinLength="3" />

        <p>Listbox popup med enveisbidning</p>
        <input aria-label="test2" ttListboxPopup ttLabel="test" [ttData]="data" [ngModel]="textValue" (ngModelChange)="textValue = $event" />

        <p>Listbox component</p>
        <tt-listbox ttLabel="test" [ttData]="data" ttDataName="text"></tt-listbox>
    </tt-card>
    -->

    <!-- <button class="col-xs-2" class="tt-button tt-button--primary" (click)="toggleEditMode()">{{ editMode ? "Avbryt" : "Rediger" }}</button> -->
    <!-- 
<div class="tt-input__group">
    <input class="col-xs-2" [(ngModel)]="variables.testVariable" (ngModelChange)="onVariableChange($event)" />
    <input class="col-xs-2" [(ngModel)]="newVariables['testVariable_2'].value" (ngModelChange)="onVariable2Change($event)" type="number" />
    <input
        *ngIf="widgetService.isWidgetNumberVariable(newVariables['testVariable_2'])"
        class="col-xs-2"
        [(ngModel)]="newVariables['testVariable_2'].decimals"
        (ngModelChange)="onVariable3Change($event)"
        type="text"
    />
    <select *ngIf="widgetService.isWidgetNumberVariable(newVariables['testVariable_2'])" [(ngModel)]="newVariables['testVariable_2'].displayUnits" (ngModelChange)="onVariable4Change($event)">
        <option>none</option>
        <option>thousands</option>
        <option>millions</option>
        <option>billions</option>
        <option>trillions</option>
    </select>
</div> -->
    <!-- <tt-dashboard
    class="col-xs-12 sp-0"
    [(widgets)]="widgets"
    (widgetsChange)="onWidgetsChange($event)"
    [numberOfColumns]="columns"
    [numberOfRows]="rows"
    [editMode]="editMode"
    [rowHeight]="rowHeight"
></tt-dashboard> -->
    <!-- </tt-page> -->
</tt-card>
