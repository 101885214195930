(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageSetting', {
        templateUrl: 'views/components/views/webpageSetting/webpageSetting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'webpageSettingService', 'layoutService', function ($stateParams, webpageSettingService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                p2WebpageSettingKeyno: '',
                itemState: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListWebpageSettings: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                webpageSettingService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadWebpageSettings = function () {
                webpageSettingService.listWebpageSettings({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebpageSettings);

                    angular.forEach(vm.model.itemsListWebpageSettings, function (item) {
                        vm.model.itemState = item.item_state;
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadWebpageSettings();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2WebpageSettingKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadWebpageSettings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
