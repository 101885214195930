import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListComponent } from '../list.component';
import { ListCheckboxSelectionEvent } from '../directives/list-checkbox-selection.directive';
import { Item } from '../../listbox/listbox.component';

/**
 * DO NOT USE THIS COMPONENT IN TS, THIS IS NOT FOR TS. DO. NOT. USE.
 *
 * **explanation**: downgraded components cannot have attribute directive, so a listcomponent cannot be made checkbox-list in js, thus a wrapper component is required.
 * If you are needing a checkbox list in ts use `<tt-list ttCheckboxSelection>`
 * This component will be deleted in the near future.
 */
@Component({
    selector: 'tt-checkbox-list',
    templateUrl: './checkbox-list.component.html',
    styleUrls: ['./checkbox-list.component.css'],
})
export class CheckboxListComponent<TType extends Item> extends ListComponent<TType> {
    /**
     * DO NOT USE THIS COMPONENT, DO NOT, DO NOT. DO.NOT.
     */
    @Input()
    ttFilter: string = '';

    @Output()
    override ttDataChange: EventEmitter<TType[]> = new EventEmitter();

    @Output()
    public override ttCheckboxSelectionChanged: EventEmitter<ListCheckboxSelectionEvent> = new EventEmitter();
}
