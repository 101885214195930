<div [ngClass]="addGroup()" [id]="model.mId" style="height: 230px;">
    <!-- LABELS -->
    <label *ngIf="label !== undefined && label !== ''"
           class="name-label col-xx-12 im-no-pad"
           [ngStyle]="{'fontSize': fontSizes.textSizeS}">
        {{ label }}
    </label>

    <label *ngIf="plabel !== undefined && plabel !== ''"
           class="name-label col-xx-12 im-no-pad"
           [ngStyle]="{'fontSize': fontSizes.textSizeS}">
        {{ plabel }}
    </label>

    <!-- INPUT DIV -->
    <div [ngClass]="addClass('inputDiv')">
        <input [ngClass]="addClass('input')"
               type="text"
               [(ngModel)]="model.field"
               [ngStyle]="{'fontSize': fontSizes.textSize}"
               readonly />
    </div>

    <!-- ROW 1 -->
    <div [ngClass]="addClass()">
        <button (click)="goNum('7')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                [style.text-align]="model.textalign"
                style="padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">7</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('8')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                [style.text-align]="model.textalign"
                style="padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">8</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('9')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                [style.text-align]="model.textalign"
                style="padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">9</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('del')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass('danger')"
                [style.text-align]="model.textalign"
                style="padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="fa fa-backspace"
                  [ngStyle]="{'fontSize': fontSizes.textSize}"
                  style="color: white; top: 0"></span>
        </button>
    </div>

    <!-- ROW 2 -->
    <div [ngClass]="addClass()">
        <button (click)="goNum('4')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">4</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('5')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">5</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('6')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">6</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('minus')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass('warning')"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="fa fa-minus"
                  [ngStyle]="{'fontSize': fontSizes.textSize}"
                  style="color: white; top: 0"></span>
        </button>
    </div>

    <!-- ROW 3 -->
    <div [ngClass]="addClass()">
        <button (click)="goNum('1')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">1</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('2')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">2</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('3')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">3</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('pluss')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass('warning')"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="fa fa-plus"
                  [ngStyle]="{'fontSize': fontSizes.textSize}"
                  style="color: white; top: 0"></span>
        </button>
    </div>

    <!-- ROW 4 -->
    <div [ngClass]="addClass()">
        <button (click)="goNum('0')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">0</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('dot')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass()"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSize}">.</span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('clear')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass('danger')"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="fa fa-eraser"
                  [ngStyle]="{'fontSize': fontSizes.textSize}"
                  style="color: white; top: 0"></span>
        </button>
    </div>

    <div [ngClass]="addClass()">
        <button (click)="goNum('enter')"
                [attr.type]="model.type"
                [ngClass]="addBtnClass('success')"
                style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;"
                [ngStyle]="{'fontSize': fontSizes.textSize}">
            <span class="fa fa-check"
                  [ngStyle]="{'fontSize': fontSizes.textSize}"
                  style="color: white; top: 0"></span>
        </button>
    </div>
</div>
