(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('pos', {
        templateUrl: 'views/components/views/pos/pos.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'posComponentService', 'rememberService', function ($stateParams, $q, $timeout, stateService, utilityService, modalService, posComponentService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                postatus_no: ''
            };

            vm.model = {
                productionOrderStatusButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('postatus_no') }
                ],
                setting: {},
                selectListPoStatuses: [],
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: '',
                lockedDelete: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'pos_set_new_status_on_selected',
                                text: 'pos_set_new_status_on_selected',
                                func: function () {
                                    progressSetNewPoStatusFunc();
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true
                            },
                            {
                                name: 'pos_new_po',
                                text: 'pos_new_po',
                                func: function () {
                                    stateService.go('pocreate', { stock_keyno: '0' });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '500px',
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            //#region SHOW DIALOGUE BOX FUNCTION

            let showMessage = function (type, title, message, label, cssClass) {
                const deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            //#endregion SHOW DIALOGUE BOX FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                posComponentService.getPos({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadPoStatuses = function () {
                posComponentService.listPoStatuses({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListPoStatuses);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.setting.postatus_no) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'postatus_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'postatus_no':
                        vm.model.setting.postatus_no = 'ALL';

                        if (vm.model.setting.postatus_no === 'ALL') {
                            rememberFunc('postatus_no');
                            loadGrid();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadGrid();
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'postatus_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.postatus_no) !== true) return;

                        vm.model.setting.postatus_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.postatus_no) !== true) return;

                        rememberFunc('postatus_no');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgressDelete = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].po_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        posComponentService.deleteObj({ po_keyno: items[step].po_keyno }).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                                    modalService.progressInfo.finishclose = '1';
                                    vm.model.runProgress = 'ABORT';
                                    modalService.progressInfo.abortclose = true;

                                    doStep();
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let progressFuncDelete = function () {
                vm.model.lockedDelete = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var currentData = vm.grid.gridfunc.getAllRows();
                var selectedItems = [];
                //var tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

                angular.forEach(currentData, function (item) {
                    if (item.is_selected === true) {
                        //item.po_proposal_date = (new Date(item.po_proposal_date - tzo)).toISOString().replace('T', ' ').slice(0, -1);
                        selectedItems.push(item);
                    }
                });

                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgressDelete(selectedItems);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.lockedDelete = false;

                        loadGrid();
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.lockedDelete = false;

                        loadGrid();
                    }
                });
            };

		    // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgressSetNewPoStatus = function (items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                let doStep = function () {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) return;

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].po_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        posComponentService.setNewPoStatus({
                            postatus_no_selected: vm.model.setting.postatus_no_selected,
                            po_keyno: items[step].po_keyno
                        }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let progressSetNewPoStatusFunc = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                let allRows = vm.grid.gridfunc.getAllRows();
                let selectedRows = [];

                angular.forEach(allRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push(item);
                    }
                });

                if (selectedRows.length === 0) return;

                vm.model.progressInfo.max = selectedRows.length;
                vm.activateProgressSetNewPoStatus(selectedRows);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') return;
                    if (vm.model.runProgress === 'FINISH') loadGrid();
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region DELETE BUTTON FUNCTIONS

            vm.deleteValues = function () {
                progressFuncDelete();
            };

		    // #endregion DELETE BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadPoStatuses();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
