(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stateService", ['$state', '$q', '$rootScope', '$timeout', '$location', '$stateParams', '$sessionStorage', '$ihttp', 'utilityService', 'ieScreenBlockService', 'imHeaderService', 'headerModel', 'appConfig', function ($state, $q, $rootScope, $timeout, $location, $stateParams, $sessionStorage, $ihttp, utilityService, ieScreenBlockService, imHeaderService, headerModel, appConfig) {
        var stateChain = [];
        var index = -1;
        var isBack = false;
        let lastTitle = '';
        let goBackTo = false;
        let goBackToState = null;
        let currentHeader = null;

        let removeCharAt = function (str, pos) {
            let front = str.substr(0, pos);
            let back = str.substr(pos + 1, str.length);

            return front + back;
        }

        var hasAnyProperty = function (obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return true;
            }

            return false;
        };


        var getDefaultBackState = function () {
            var dbs = {
                name: 'mainmenu',
                parms: {}
            };

            if (angular.isDefined($state.current.header) && angular.isDefined($state.current.header.backButton) && angular.isDefined($state.current.header.backButton.fallback)) {
                if (angular.isDefined($state.current.header.backButton.fallback.state)) {
                    dbs.name = $state.current.header.backButton.fallback.state;
                }

                if (angular.isDefined($state.current.header.backButton.fallback.parms)) {
                    dbs.parms = $state.current.header.backButton.fallback.parms;
                }
            }

            return dbs;
        };

        var onStateChangeSuccess = async function (toState, toParms) {
            if (angular.isUndefined(toState)) {
                toState = $state.current;
                toParms = $stateParams;
            }

            // BJS 20190203
            if (toState.name === '') return;

            var path = $location.path();

            if (isBack === true) {
                if (index >= 0) {
                    var toPath = toState.url;

                    if (toState.name === 'mainmenu') {
                        toPath = '/mainmenu';
                    } else {
                        angular.forEach(toParms, function (value, key) {
                            if (value === null) {
                                let index = toPath.indexOf(':' + key);
                                if (index !== -1) {
                                    toPath = removeCharAt(toPath, index - 1);
                                    toPath = toPath.replace(':' + key, '');
                                } else {
                                    toPath = toPath.replace(':' + key, value);
                                }
                            } else {
                                toPath = toPath.replace(':' + key, value);
                            }
                        });
                    }

                    for (var i = stateChain.length - 1; i >= 0; i--) {
                        if (toPath === stateChain[i].path) {
                            index = i;
                            break;
                        }

                        if (stateChain[stateChain.length - 1].name !== 'mainmenu') {
                            stateChain.pop();
                        } else if (service.pinned.some((page) => page.pinned_url === path)) {
                            service.pinIndex = service.pinned.findIndex((page) => page.pinned_url === path);
                        }
                    }

                    if (stateChain.length === 0) {
                        index = -1;
                        stateChain.push({
                            name: toState.name,
                            path: path,
                            title: '',
                            state: toState,
                            parms: toParms,
                            date: new Date().toISOString(),
                        });

                        index++;
                    }
                } else {
                    console.log('index not big enough');
                }
            } else if (goBackTo && goBackToState) {
                stateChain = [...$sessionStorage.stateChain.filter((item) => item.date < goBackToState.date)];
                let stateChainAfter = [];

                if (goBackToState.name !== 'mainmenu') {
                    stateChainAfter = [...$sessionStorage.stateChain.filter((item) => item.date > goBackToState.date && item.pinned === true)];
                }

                stateChain.push(goBackToState);

                stateChain = [...stateChain, ...stateChainAfter];
                index = stateChain.indexOf(goBackToState);
                goBackTo = false;
                goBackToState = null;
            } else {
                if (stateChain.length > 0 && toState.name === stateChain[stateChain.length - 1].name && angular.toJson(toParms) === angular.toJson(stateChain[stateChain.length - 1].parms)) {
                    // BJS 20190203 - refresh - RHE 20190213 - back within same state but =/= parms, and refresh
                    index = stateChain.length - 1;
                } else {
                    if (toState.name === 'mainmenu') {
                        stateChain = [];
                        index = -1;
                    } else if (stateChain.length > 1 && toState.name === stateChain[stateChain.length - 2].name && angular.toJson(toParms) === angular.toJson(stateChain[stateChain.length - 2].parms)) {
                        stateChain = [...$sessionStorage.stateChain];
                        if (stateChain[index].pinned !== true) {
                            stateChain.pop();           // Added to work with browser back-button
                            index--;
                        } else {
                            index--;
                        }

                        if (stateChain[index].pinned !== true) {
                            stateChain.pop();           // This will get readded below with new date
                            index--;
                        } else {
                            index--;
                        }
                    }

                    const state = {
                        name: toState.name,
                        pageTitle: lastTitle ?? '...',
                        path: path,
                        title: '',
                        state: toState,
                        parms: toParms,
                        date: new Date().toISOString(),
                    }

                    stateChain = [...stateChain, state];
                    index = stateChain.indexOf(state);
                }
            }

            currentHeader = headerModel.build($state.current.header, $stateParams);

            var dbs = getDefaultBackState();

            // BJS 20180118 - Lagt til sjekk om verdier finnes før setCachedState for å unngå feilmeldinger i console.
            var dbsName = '';
            var dbsPath = '';

            if (angular.isDefined(dbs)) {
                dbsName = dbs.name;
                dbsPath = $state.href(dbs.name, dbs.parms, { absolute: true });
            }

            var chainName = '';
            var chainPath = '';

            if (index > 0 && angular.isArray(stateChain) === true && stateChain.length >= index - 1) {
                chainName = stateChain[index - 1]?.name ?? '';
                chainPath = stateChain[index - 1]?.path ?? '';
            }

            currentHeader.backButton.setCachedState({
                name: index > 0 ? chainName : dbsName,
                path: index > 0 ? chainPath : dbsPath
            });

            if (currentHeader.text.length === 0) {
                currentHeader.text = 'p2_m_' + toState.name;
            }

            if (service && index !== null && index >= 0) {
                service.pinIndex = null;
            }

            if (service?.pinned.some((page) => page.pinned_url === stateChain[index]?.path)) {
                service.pinIndex = service.pinned.findIndex((page) => page.pinned_url === stateChain[index].path);
                stateChain.splice(index, 1);
                index = null;
            }

            isBack = false;

            // kopier til session storage

            imHeaderService.stateChanged(currentHeader);

            let title = null;

            if ($state.current?.title) title = await imHeaderService.setDocumentTitle($state.current.title, true);
            else if ($state.current.title !== '') title = await imHeaderService.setDocumentTitle();

            if (title && stateChain[index] && !stateChain[index].title) stateChain[index].title = title;

            $sessionStorage.stateChain = angular.copy(stateChain);
            $sessionStorage.stateServiceIndex = angular.copy(index);
            if (service) {
                $sessionStorage.pinIndex = angular.copy(service.pinIndex);
            }
            $rootScope.$broadcast('stateChainChanged');
        };

        $rootScope.$on('$stateChangeSuccess', function (_, toState, toParms, __, ___) {
            // BJS 20210528 - Running on timeout with 0 delay because this fixes problem
            //                where $location.path() returns the old path.
            $timeout(onStateChangeSuccess, 0, true, toState, toParms);
        });

        //$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        //    console.log('$stateChangeStart');
        //    console.dir(event);
        //});


        if (angular.isDefined($sessionStorage.stateChain) && $sessionStorage.stateChain.length > 0) {
            stateChain = angular.copy($sessionStorage.stateChain);
        }

        if (angular.isDefined($sessionStorage.stateServiceIndex)) {
            index = $sessionStorage.stateServiceIndex;
        }

        onStateChangeSuccess();

        let postGoto = function (state, parms) {
            //let parmsStr = '';
            //angular.forEach(parms, function (parm) {
            //    parmsStr += parm + '/';
            //});

            //if (parmsStr.length > 0) parmsStr = parmsStr.slice(0, -1);

            $ihttp.post({
                method: 2856,
                parameters: {
                    state: state,
                    //parms: parmsStr
                    parms: angular.isObject(parms) ? Object.values(parms).join('/')
                        : angular.isString(parms) ? parms : ''
                }
            });
        }

        var go = function (to, parms, options) {
            var deferred = $q.defer();

            if (hasAnyProperty(parms) !== true) {
                parms = undefined;
            }

            postGoto(to, parms);

            //lastTitle = angular.copy(imHeaderService.getDocumentTitle());

            //console.dir(lastTitle);

            $timeout(function () {
                $state.go(to, parms, options).then(function () {
                    deferred.resolve();
                }, (reason) => { console.log(reason); $location.path(to) });
            }, 200);

            return deferred.promise;
        };

        var service = {
            pinned: [],
            pinIndex: null,
            go: function (to, parms, options) {
                lastTitle = imHeaderService.getCurrentHeader().text;
                // BJS 20210217
                if (angular.isNumber(parms) === true) {
                    parms = parms.toString();
                }

                if (angular.isString(parms) === true && parms.length > 0) {
                    var state = $state.get(to);

                    var parmParts = parms.split('/');
                    var urlParts = state.url.split('/');

                    var toIndex = 0;

                    var _parms = {};

                    for (var i = 0; i < urlParts.length; i++) {
                        if (toIndex >= parmParts.length) break;
                        if (urlParts[i].substr(0, 1) !== ':') continue;

                        _parms[urlParts[i].substr(1)] = parmParts[toIndex];

                        toIndex++;
                    }

                    if (toIndex > 0) {
                        parms = _parms;
                    }
                }

                if(to !== '') go(to, parms, options);
            },

            pinnedGo: function (url) {
                $location.path(url);
            },

            goBackTo: function (state) {
                goBackTo = true;
                goBackToState = state;
                $location.path(state.path);
            },

            signOut: function () {
                // BJS 20240926
                // BJS 20241128 - Added csi query parameter
                window.location.href = '.auth/end-session' + `?csi=${appConfig.clientSessionId}`;;
            },

            back: function (to) {
                isBack = true;

                if ($state.current.name === 'mainmenu') {
                    $sessionStorage.stateChain.length = 0;
                } else {
                    if (index > 0) {
                        var steps = 1;

                        if (angular.isDefined(to)) {
                            if (utilityService.isNumber(to)) {
                                steps = to;
                            } else {
                                var toIndex = -1;

                                for (var i = stateChain.length - 1; i >= 0; i--) {
                                    if (stateChain[i].path !== to) continue;

                                    toIndex = i;
                                    break;
                                }

                                if (toIndex >= 0) {
                                    steps = stateChain.length - toIndex - 1;
                                }
                            }
                        }


                        $timeout(async function () {
                            if (service?.pinned.length > 0) {
                                for (let i = 0; i < steps; i++) {
                                    await history.back();
                                }
                            } else if (angular.isDefined(stateChain[index - steps]) && angular.isDefined(stateChain[index - steps].path)) {
                                $location.path(stateChain[index - steps].path);
                            } else if (angular.isDefined(stateChain[stateChain.length - 2]) && angular.isDefined(stateChain[stateChain.length - 2].path)) {
                                $location.path(stateChain[stateChain.length - 2].path);
                            } else {
                                var dbs = getDefaultBackState();

                                go(dbs.name, dbs.parms);
                            }
                        }, 0); // BJS 20210528 - Modified delay from 250 to 0. Primary reason for running in $timeout is to let other "process"
                        //                finish before running this. A delay of 0 will pause processing here and let the other one run
                        //                before this one continues.
                    } else {
                        if (typeof to === 'string' && !stateChain.some((state) => state.path === to) && service.pinned.some((pin) => pin.pinned_url === to)) {
                            $location.path(service.pinned.find((pin) => pin.pinned_url === to).pinned_url);
                        } else {
                            var dbs = getDefaultBackState();

                            go(dbs.name, dbs.parms);
                        }
                    }
                }
            },

            newTab: function (state, parms) {
                // RHE 20220114
                var newTabUrl = '#/' + state;

                postGoto(state, parms);

                if (parms) {
                    if (angular.isString(parms) && parms !== '') {
                        newTabUrl += '/' + parms;
                    } else if (angular.isObject(parms)) {
                        newTabUrl += '/' + Object.values(parms).join('/');
                    }
                }

                // KLN 20230906 - Timing issue, timeout fixes inconsistant new tab focus
                $timeout(function () {
                    window.open(newTabUrl, '_blank');
                }, 0);
            },

            getCurrentName: function () {
                return $state.current.name;
            },

            getCurrentPath: function () {
                return $location.path();
            },

            getHost: function () {
                return $location.host();
            },

            setPinnedList: function (list) {
                service.pinned = list;

                if (service?.pinned.some((page) => page.pinned_url === stateChain[index]?.path)) {
                    service.pinIndex = service.pinned.findIndex((page) => page.pinned_url === stateChain[index].path);
                    stateChain.splice(index, 1);
                    index = null;

                    $sessionStorage.stateChain = angular.copy(stateChain);
                    $sessionStorage.stateServiceIndex = angular.copy(index);
                    $sessionStorage.pinIndex = angular.copy(service.pinIndex);
                    setTimeout(() => $rootScope.$broadcast('stateChainChanged'));
                }
            },

            setPin: function (state, pinned) {
                const stateToFind = stateChain.find((stateToFind) => state === stateToFind);
                const sessionStateToFind = $sessionStorage.stateChain.find((stateToFind) => state === stateToFind);

                if (stateToFind && sessionStateToFind) {
                    stateToFind = pinned;
                    sessionStateToFind = pinned;
                }
            },

            setTitleOverride: function (title, translate) {

                imHeaderService.setDocumentTitle(title, translate).then((newTitle) => {
                    if (newTitle && index >= 0 && !!$sessionStorage.stateChain[index]) $sessionStorage.stateChain[index].title = newTitle;
                    if (newTitle && index >= 0 && !!stateChain[index]) stateChain[index].title = newTitle;
                });
            },

            getCurrentStateHeader: function () {
                return currentHeader;
            }

        };

        return service;
    }]);
})();
