(function () {
	'use strict';

    angular.module("imApp").factory("sellersService", ['$ihttp', function ($ihttp) {
		let service = {
            getSeller: function (parms) {
                return $ihttp.post({
                    method: 1385,
                    parameters: parms || {}
                });
            },
            listSellers: function (parms) {
                return $ihttp.post({
                    method: 1386,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
