(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('capTasksView', {
        templateUrl: 'views/components/views/capTasksView/capTasksView.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', '$timeout', 'capTasksViewService', function($stateParams, $timeout, capTasksViewService) {

            // ## VARIABLES & DEFINITIONS ##
            
            var vm = this;

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                setting: {},
                capTasksViewSetup: {
                    //date_fom: (new Date(y, m, f, 2)).toISOString().substring(0, 10),
                    date_fom: (new Date(2020, 5, 17, 2)).toISOString().substring(0, 10),
                    selectedview: 'timeline',
                    portal_user_keyno: '1',
                    templateStyle: 'alt',
                    //colorByGroup: false
                    functions: [
                        { name: 'add', func: function (data) { schedulerFunctions(this.name, data); } },
                        { name: 'update', func: function (data) { schedulerFunctions(this.name, data); } },
                        { name: 'delete', func: function (data) { schedulerFunctions(this.name, data); } },
                        { name: 'newDate', func: function (data) { schedulerFunctions(this.name, data); } }
                    ]
                },
                testSelect: null,
                test: [],
                tasksList: []
            };

            // #################################
            // #region scheduler functions
            // #################################

            var schedulerFunctions = function (name, data) {
                switch (name) {
                    case 'add':
                        break;
                    case 'update':
                        capTasksViewService.updateMovedItem(data).then(function (response) {
                            loadCapTasksViews();
                        });
                        break;
                    case 'delete':
                        break;
                    case 'newDate':
                        vm.model.setting.date_fom = data.fom;
                        vm.model.setting.date_tom = data.tom;//.toISOString().substring(0, 10);
                        vm.model.setting.selectedview = data.view;
                        break;
                    default:
                        break;
                }
            };

            // #################################
            // #endregion scheduler functions
            // #################################

            // ## IF CONDITIONS FUNCTION ##

            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;

                return true;
            };

            // ## LOAD PROCEDURE CALLS ##

            capTasksViewService.loadCapTasksViewGet(vm.model.argType, vm.model.argValue).then(function (data) {
                vm.model.setting = angular.copy(data[0]);

                if (angular.isDefined(vm.model.setting.date_fom)) {
                    vm.model.capTasksViewSetup.date_fom = vm.model.setting.date_fom;
                }

                if (angular.isDefined(vm.model.setting.selectedview)) {
                    vm.model.capTasksViewSetup.selectedview = vm.model.setting.selectedview;
                }

                loadCapTasksViews();
            });

            var loadCapTasksViews = function () {
                if (validateParmsWithValue('argtype') !== true) return;
                if (validateParmsWithValue('argvalue') !== true) return;

                vm.model.tasksList = [];

                capTasksViewService.loadCapTasksViewData(vm.model.setting.argtype, vm.model.setting.argvalue).then(function (data) {
                    angular.copy(data[0], vm.model.tasksList);

                    // #################################
                    // #region testdata
                    // #################################

                    //angular.copy({
                    //    items: [{
                    //        item_id: "125",
                    //        item_name: "Test",
                    //        item_state: "workdetails",
                    //        item_parms: "125",
                    //        item_path: "workdetails/125",
                    //        item_dim: "custact_keyno",
                    //        dims: [{
                    //            custact_keyno: ""
                    //        }, {
                    //            equipment_id: "G01"
                    //        }, {
                    //            cust_no: "10000"
                    //        }],
                    //        color: "blue",
                    //        orderby: "1",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Test"
                    //    }, {
                    //        item_id: "126",
                    //        item_name: "Test 2",
                    //        item_state: "workdetails",
                    //        item_parms: "126",
                    //        item_path: "workdetails/126",
                    //        item_dim: "custact_keyno",
                    //        dims: [{
                    //            custact_keyno: ""
                    //        }, {
                    //            equipment_id: "G02"
                    //        }, {
                    //            cust_no: "10100"
                    //        }],
                    //        color: "blue",
                    //        orderby: "2",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Test 2"
                    //    }, {
                    //        item_id: "127",
                    //        item_name: "Test 3",
                    //        item_state: "workdetails",
                    //        item_parms: "127",
                    //        item_path: "workdetails/127",
                    //        item_dim: "custact_keyno",
                    //        dims: [{
                    //            custact_keyno: ""
                    //        }, {
                    //            equipment_id: "G01"
                    //        }, {
                    //            cust_no: "10000"
                    //        }],
                    //        color: "blue",
                    //        orderby: "3",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Test 3"
                    //    }, {
                    //        item_id: "128",
                    //        item_name: "Test 4",
                    //        item_state: "workdetails",
                    //        item_parms: "128",
                    //        item_path: "workdetails/128",
                    //        item_dim: "custact_keyno",
                    //        dims: [{
                    //            custact_keyno: ""
                    //        }, {
                    //            equipment_id: "L01"
                    //        }, {
                    //            cust_no: "10100"
                    //        }],
                    //        color: "blue",
                    //        orderby: "4",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Test 4"
                    //    }, {
                    //        item_id: "G01",
                    //        item_name: "Gravemaskin 1",
                    //        item_state: "equipmentdetails",
                    //        item_parms: "G01",
                    //        item_path: "equipmentdetails/G01",
                    //        item_dim: "equipment_id",
                    //        dims: [{
                    //            custact_keyno: ["125", "127"]
                    //        }, {
                    //            equipment_id: ""
                    //        }, {
                    //            cust_no: "10000"
                    //        }],
                    //        color: "red",
                    //        orderby: "5",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Gravemaskin 1"
                    //    }, {
                    //        item_id: "G02",
                    //        item_name: "Gravemaskin 2",
                    //        item_state: "equipmentdetails",
                    //        item_parms: "G02",
                    //        item_path: "equipmentdetails/G02",
                    //        item_dim: "equipment_id",
                    //        dims: [{ custact_keyno: "126" },
                    //        { equipment_id: "" },
                    //        { cust_no: "10100" }],
                    //        color: "red",
                    //        orderby: "6",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Gravemaskin 2"
                    //    }, {
                    //        item_id: "L01",
                    //        item_name: "Lastebil 1",
                    //        item_state: "equipmentdetails",
                    //        item_parms: "L01",
                    //        item_path: "equipmentdetails/L01",
                    //        item_dim: "equipment_id",
                    //        dims: [{
                    //            custact_keyno: "128"
                    //        }, {
                    //            equipment_id: ""
                    //        }, {
                    //            cust_no: "10100"
                    //        }],
                    //        color: "blue",
                    //        orderby: "7",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Lastebil 1"
                    //    }, {
                    //        item_id: "10000",
                    //        item_name: "Ekornes",
                    //        item_state: "custdetails",
                    //        item_parms: "10000",
                    //        item_path: "custdetails/10000",
                    //        item_dim: "cust_no",
                    //        dims: [{
                    //            custact_keyno: ["125", "127"]
                    //        }, {
                    //            equipment_id: "G01"
                    //        }, {
                    //            cust_no: ""
                    //        }],
                    //        color: "green",
                    //        orderby: "8",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Ekornes"
                    //    }, {
                    //        item_id: "10100",
                    //        item_name: "Sykkylven Kommune",
                    //        item_state: "custdetails",
                    //        item_parms: "10100",
                    //        item_path: "custdetails/10100",
                    //        item_dim: "cust_no",
                    //        dims: [{
                    //            custact_keyno: ["126", "128"]
                    //        }, {
                    //            equipment_id: ["G02", "L01"]
                    //        }, {
                    //            cust_no: ""
                    //        }],
                    //        color: "green",
                    //        orderby: "9",
                    //        start_dt: "2020-06-17 09:00:00.000",
                    //        end_dt: "2020-06-17 15:00:00.000",
                    //        description: "Sykkylven Kommune"
                    //    }],
                    //    groups: [{
                    //        item_id: "0",
                    //        item_dim: "custact_keyno",
                    //        isgroup: "false"
                    //    }, {
                    //        item_id: "1",
                    //        item_dim: "equipment_id",
                    //        isgroup: "true"
                    //    }, {
                    //        item_id: "2",
                    //        item_dim: "cust_no",
                    //        isgroup: "true"
                    //    }],
                    //    resources: []
                    //}, vm.model.tasksList);

                    // #################################
                    // #endregion testdata
                    // #################################

                    var toldDim = false;

                    $timeout(function () {
                        angular.forEach(vm.model.tasksList.groups, function (item) {
                            var containsItemDim = false;
                            var i = 0;
                            if (angular.isDefined(item) && angular.isDefined(item.item_dim) && item.item_dim !== '') {
                                for (i = 0; i < vm.model.test.length; i++) {
                                    if (item.item_dim === vm.model.test[i].item_name) {
                                        containsItemDim = true;
                                        break;
                                    }
                                }
                                if (!containsItemDim) {
                                    vm.model.test.push({ item_id: vm.model.test.length, item_name: item.item_dim })
                                }
                            }
                            if (item.isgroup === 'false' && !toldDim) {
                                //vm.model.testSelect = i;
                                vm.onTestSelectChanged(i) 
                                toldDim = true;
                            }
                        });
                    }, 100);
                });
            };

            vm.onTestSelectChanged = function (value) {
                vm.model.testSelect = value;

                if (value) {
                    let currentDim = '';
                    let testItem = vm.model.test.find((item) => value === item.item_id);

                    if (testItem) currentDim = testItem.item_name;

                    vm.model.tasksList.forEach((item) => {
                        if (item && item.item_dim === currentDim) {
                            item.isgroup = false;
                        } else {
                            item.isgroup = true;
                        }
                    });
                }
            }
		}]
	});
})();
