import { Component, AfterContentInit, AfterViewInit, Output, EventEmitter, ContentChildren, QueryList, ViewChild, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { DividerPaneComponent } from './divider-pane/divider-pane.component';

/**
 * Generic component for creating dividers.
 */

@Component({
    selector: 'tt-divider-generic',
    template: '',
})
export abstract class DividerGenericComponent implements AfterContentInit, AfterViewInit {
    @Input()
    public ttId?: string;

    /**
     * The cursor to use when a pane is being resized.
     */
    abstract resizeCursor: 'row-resize' | 'col-resize';

    /**
     * The slider class to append to the sliders of the divider this divider is implemented for.
     */
    abstract sliderClass: string;

    public newNextPanelSize?: number;

    public newPrevPanelSize?: number;

    /**
     * Gets the pointer position of the mouse event (x or y coordinate accordingly to the direction the divider is implemented for).
     *
     * @param event the event to retrieve pointer poisiton from.
     * @returns the x or y page coordination of the pointer from the given event.
     */
    abstract getPointerPosition(event: PointerEvent): number;

    /**
     * Gets the dividers size (height or width accordingly to the direction the divider is implemented for).
     */
    abstract getDividerSize(): number;

    /**
     * Gets the outer size of the given element (height or width accordingly to the direction the divider is implemented for),
     *
     * @param elementRef the element to the get the outersize of.
     */
    abstract getOuterSize(elementRef: HTMLElement): number;

    /**
     * Event emitted when resizing in the divider is completed.
     */
    @Output()
    public ttResizeEnd: EventEmitter<{ pane: DividerPaneComponent | null; originalEvent: PointerEvent }> = new EventEmitter();

    /**
     * List over slider inserted between each pane.
     */
    private sliders: HTMLDivElement[] = [];

    /**
     * Whether this divider component has already been initialized, used to prevent race conditions between view init and content init.
     */
    private dividerInitialized = false;

    /**
     * The size of the divider (container of the panes).
     */
    private dividerSize?: number;

    /**
     * Whether a pane is actively being dragged.
     */
    private dragging?: boolean;

    /**
     * The cursor coordinate for the start position when starting to resize a pane.
     */
    private startPosition?: number;

    /**
     * The size of the pane placed before the divider-slider.
     */
    private previousPaneSize?: number;

    /**
     * The size of the pane placed after the divider-slider.
     */
    private nextPaneSize?: number;

    /**
     * The index of the pane placed before the divider slider.
     */
    private previousPaneIndex?: number;

    /**
     * List over the pane components passed through the content slot of this divider component.
     */
    @ContentChildren(DividerPaneComponent)
    public paneComponents: QueryList<DividerPaneComponent> = new QueryList();

    /**
     * The divider (container of the panes) element of this divider component.
     */
    @ViewChild('divider')
    public divider?: ElementRef<HTMLDivElement>;

    constructor(public renderer: Renderer2) {}

    public getPaneSizes(): string[] {
        return this.paneComponents.map((pane) => this.getPaneElement(pane).style.flexBasis);
    }

    public getIdMappedPaneSizes(): { [key: string]: string } {
        if (!this.paneComponents.toArray().every((pane) => !!pane.ttId)) throw Error('All panes need id to get id mapped pane sizes object.');

        const sizes: { [key: string]: string } = {};

        for (let pane of this.paneComponents) {
            sizes[pane.ttId!] = this.getPaneElement(pane).style.flexBasis;
        }

        return sizes;
    }

    public setPanelSizes(sizes: string[]) {
        sizes.forEach((size, index) => {
            this.setPaneSize(this.getPaneElementAtIndex(index), size);
            // this.renderer.setStyle(this.getPaneElementAtIndex(index), 'flexBasis', size);

            // this.getPaneElementAtIndex(index)
            // this.paneComponents.get(index)
        });
        // return this.paneComponents.map((pane) => this.getPaneElement(pane).style.flexBasis);
    }

    public setSizesFromIdMappedPaneSizes(sizes: { [key: string]: string }) {
        for (let [id, size] of Object.entries(sizes)) {
            const pane = this.paneComponents.find((pane) => pane.ttId === id);

            if (!!pane) {
                this.setPaneSize(this.getPaneElement(pane), size);
                // this.renderer.setStyle(this.getPaneElement(pane), 'flexBasis', size);
            }
        }
    }

    /**
     * Starts resizing the pane at the given index.
     *
     * @param event the pointerdown event on the slider.
     * @param index the index of the pane to resize.
     */
    private onSliderPointerdown(event: PointerEvent, index: number) {
        event.preventDefault();

        if (!!this.divider?.nativeElement) {
            this.setResizingState(event, index);

            this.addResizingStyling(index);
        }
    }

    /**
     * Handles resizing.
     *
     * @param event pointer event of resize drag.
     */
    @HostListener('document:pointermove', ['$event'])
    public onDocumentPointermove(event: PointerEvent) {
        if (this.dragging && this.previousPaneIndex !== undefined && this.previousPaneSize !== undefined && this.nextPaneSize !== undefined) {
            event.preventDefault();

            const newPosition = (this.getPointerPosition(event) * 100) / this.dividerSize! - (this.startPosition! * 100) / this.dividerSize!;
            this.newPrevPanelSize = this.previousPaneSize + newPosition;
            this.newNextPanelSize = this.nextPaneSize - newPosition;
            this.resizePanes(this.previousPaneIndex, this.previousPaneSize + newPosition, this.nextPaneSize - newPosition);
        }
    }

    /**
     * Handles stop of resize.
     *
     * @param event pointer event of resize end.
     */
    @HostListener('document:pointerup', ['$event'])
    public onDocumentPointerup(event: PointerEvent) {
        if (this.dragging) {
            this.dragging = false;

            this.ttResizeEnd.emit({ originalEvent: event, pane: this.previousPaneIndex !== undefined ? this.paneComponents.get(this.previousPaneIndex) || null : null });
            this.removeResizingStyling();
            this.resetResizingState();
        }
    }

    /**
     * Resizes the panes at and after the given index. Resizes the pane at the given index to the new prev pane size, and the pane after the index to the new next pane size.
     *
     * @param index the index of the first pane to be resized.
     * @param newPrevPanelSize the new size of the pane at the index.
     * @param newNextPanelSize the new size of the next pane.
     */
    private resizePanes(index: number, newPrevPanelSize: number, newNextPanelSize: number) {
        this.previousPaneIndex = index;

        this.dividerSize = this.getDividerSize();

        this.setPaneSize(this.getPaneElementAtIndex(this.previousPaneIndex), this.getPaneSize(newPrevPanelSize));
        this.setPaneSize(this.getPaneElementAtIndex(this.previousPaneIndex + 1), this.getPaneSize(newNextPanelSize));

        // this.renderer.setStyle(this.getPaneElementAtIndex(this.previousPaneIndex), 'flexBasis', this.getPaneSize(newPrevPanelSize));
        // this.renderer.setStyle(this.getPaneElementAtIndex(this.previousPaneIndex + 1), 'flexBasis', this.getPaneSize(newNextPanelSize));
    }

    /**
     * Sets the resizing state of this divider according to the given pointer event which initiated the resizing.
     *
     * @param event the pointerevent which initiated the resizing.
     * @param index the index of the pane to resize.
     */
    private setResizingState(event: PointerEvent, index: number) {
        this.dividerSize = this.getDividerSize();
        this.dragging = true;
        this.startPosition = this.getPointerPosition(event);
        this.previousPaneSize = (100 * this.getOuterSize(this.getPaneElementAtIndex(index))) / (this.dividerSize - 4);
        this.nextPaneSize = (100 * this.getOuterSize(this.getPaneElementAtIndex(index + 1))) / (this.dividerSize - 4);
        this.previousPaneIndex = index;
    }

    /**
     * Resets variables in the component used to keep state when resising.
     */
    private resetResizingState() {
        this.dividerSize = undefined;
        this.dragging = undefined;
        this.startPosition = undefined;
        this.previousPaneSize = undefined;
        this.nextPaneSize = undefined;
        this.previousPaneIndex = undefined;
    }

    /**
     * Initializes the divider and inserts sliders between the panes.
     */
    private initializeDivider() {
        if (!!this.divider && !this.dividerInitialized && !!this.divider) {
            this.dividerInitialized = true;

            this.paneComponents.changes.subscribe({
                next: (panes) => this.setDividerSliderForPanes(panes),
            });

            this.setDividerSliderForPanes(this.paneComponents.toArray());
        }
    }

    /**
     * Sets a slider inbetween all the panes in the given list.
     *
     * @param panes the panes to insert slider inbetween for.
     */
    private setDividerSliderForPanes(panes: DividerPaneComponent[]) {
        if (!this.divider) return;

        for (let [index, pane] of panes.entries()) {
            this.setInitialPaneSize(pane);

            if (index !== 0) {
                if (!this.paneHasPreviousElementSlider(pane)) {
                    this.createSlider(pane);
                }

                this.sliders[index - 1].onpointerdown = (event) => this.onSliderPointerdown(event, index - 1);
            }
        }
    }

    /**
     * Sets the initial pane size on the given pane component.
     *
     * @param pane the pane to set initial pane size on.
     */
    private setInitialPaneSize(pane: DividerPaneComponent) {
        if (!this.getPaneElement(pane).style.flexBasis) this.setPaneSize(this.getPaneElement(pane), !!pane.ttSize ? this.getPaneSize(+pane.ttSize) : this.getPaneSize(100 / this.paneComponents.length));
        // if (!this.getPaneElement(pane).style.flexBasis) this.renderer.setStyle(this.getPaneElement(pane), 'flexBasis', !!pane.ttSize ? this.getPaneSize(+pane.ttSize) : this.getPaneSize(100 / this.paneComponents.length));
    }

    /**
     * Checks if the given pane has a slider-controller position as the previous sibling element.
     *
     * @param pane the pane to check if already has a slider-controller.
     * @returns `true` if the given pane has a slider-controller, `false` if not.
     */
    private paneHasPreviousElementSlider(pane: DividerPaneComponent) {
        return this.getPaneElement(pane).role === 'separator';
    }

    /**
     * Creates a an html element representing a slider in the divider.
     *
     * @returns the html element created.
     */
    private createSlider(pane: DividerPaneComponent): HTMLDivElement {
        const slider: HTMLDivElement = this.renderer.createElement('div');
        slider.classList.add('tt-divider__gutter', this.sliderClass);
        slider.role = 'separator';

        if (this.divider) {
            this.sliders.push(slider);
            this.renderer.insertBefore(this.divider.nativeElement, slider, this.getPaneHostElement(pane));
        }

        return slider;
    }

    private setPaneSize(paneElement: HTMLDivElement, size: string) {
        this.renderer.setStyle(paneElement, 'flexBasis', size);
    }

    /**
     * Retrieves the css-style size to set for the pane for the component.
     *
     * @param size the size to retrieve calculates css style size for.
     * @returns a string to be used as the css-style for the size.
     */
    private getPaneSize(size: number) {
        const numberOfPanes = this.paneComponents.length;
        const numberOfSliders = numberOfPanes - 1;
        const widthOfSliders = 4;

        return 'calc(' + size + '% - ' + (numberOfSliders * widthOfSliders) / numberOfPanes + 'px)';
    }

    /**
     * Gets the pane element from the pane component at the given index in the content children..
     *
     * @param index the index of the pane component to retrieve.
     * @returns the pane element from the given index.
     */
    private getPaneElementAtIndex(index: number): HTMLDivElement {
        const pane = this.paneComponents.get(index);

        if (!pane) throw Error('Could not find pane at given index');

        return this.getPaneElement(pane);
    }

    /**
     * Gets the pane element from the given pane component.
     *
     * @param pane the pane component to get the pane element from.
     * @returns the html div used as pane element from the given pane component.
     */
    private getPaneElement(pane: DividerPaneComponent): HTMLDivElement {
        const paneElement: Element | null | undefined = this.getPaneHostElement(pane).firstElementChild;

        if (!paneElement || paneElement?.tagName !== 'DIV') throw Error('Could not find pane-element');

        return <HTMLDivElement>paneElement;
    }

    /**
     * Gets the host element of the given pane component.
     *
     * @param pane the pane component to retrieve host element from.
     * @returns the host element of the given pane component.
     */
    private getPaneHostElement(pane: DividerPaneComponent): HTMLElement {
        const paneElement: Element | null | undefined = pane.elementRef.nativeElement;

        if (!paneElement || paneElement?.tagName !== 'TT-DIVIDER-PANE') throw Error('Could not find pane-host-element');

        return <HTMLElement>paneElement;
    }

    /**
     * Adds styling which indicates that resize event is occurring.
     */
    private addResizingStyling(index: number) {
        document.body.style.touchAction = 'none';
        document.body.style.cursor = this.resizeCursor;
        this.renderer.addClass(this.getPaneElementAtIndex(this.previousPaneIndex!)!, 'tt-divider-pane--resizing');
        this.renderer.addClass(this.getPaneElementAtIndex(this.previousPaneIndex! + 1)!, 'tt-divider-pane--resizing');
    }

    /**
     * Removes styling which indicates that a resize event is occuring.
     */
    private removeResizingStyling() {
        document.body.style.touchAction = 'auto';
        document.body.style.cursor = 'initial';
        this.renderer.removeClass(this.getPaneElementAtIndex(this.previousPaneIndex!)!, 'tt-divider-pane--resizing');
        this.renderer.removeClass(this.getPaneElementAtIndex(this.previousPaneIndex! + 1)!, 'tt-divider-pane--resizing');
    }

    public ngAfterContentInit(): void {
        this.initializeDivider();
    }

    public ngAfterViewInit(): void {
        this.initializeDivider();
    }
}
