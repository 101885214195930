(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('custactSearch', {
        templateUrl: 'views/components/views/custactSearch/custactSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: [
            '$stateParams',
            '$timeout',
            '$location',
            'custactSearchService',
            'stateService',
            'utilityService',
            'modalService',
            'typeaheadService',
            'rememberService',
            'ttGridFactory',
            'layoutService',
            function ($stateParams, $timeout, $location, custactSearchService, stateService, utilityService, modalService, typeaheadService, rememberService, ttGridFactory, layoutService) {

                // #region VARIABLES SETUP

                const vm = this;

                let variableNames = {
                    reg_of_portal_user_keyno: '',
                    cust_no: ''
                };

                vm.model = {
            
                    get: {},
                    mainTabs: [
                        { id: 'LAST', label: 'gb_main_last' },
                        { id: 'SEARCH', label: 'gb_main_search' },
                    ],
                    dataViewTabs: [
                        { id: 'list', label: 'custactsearch_list' },
                        { id: 'kanban', label: 'custactsearch_kanban' },
                    ],
                    memberPortalUserButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.onMemberPortalUserKeynoChanged('0') },
                    ],
                    subCrmButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('reg_of_portal_user_keyno') }
                    ],
                    searchTextButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.onSearchTextChanged('') },
                        { id: 'search', icon: 'fa fa-solid fa-search', color: 'primary', type: 'primary', onClick: () => vm.searchCustactFind() },
                    ],
                    activityTypeButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.onActivityTypeChanged('0') }
                    ],
                    activityStatusEmptyButtons: [
                        { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: gotoActivityStatuses }
                    ],
                    activityStatusButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.onFilterFieldChanged('0', 'actstatus_keyno') },
                        { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: gotoActivityStatuses },
                        { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: gotoActivityStatus },
                    ],
                    activityTypes: [],
                    activityStatuses: [],
                    okStatuses: [],
                    portalUsers: [],
                    selectListSubCrms: [],
                    gridActivityReady: false,
                    ready: false,
                    mobileSize: layoutService.getMediaQueries().tablet
                };

                // #endregion VARIABLES SETUP

                // #region GRID


                vm.grid = new ttGridFactory({ rememberId: 'w_activitygrid.grid' })
                    .setRememberId('w_activitygrid.grid')
                    .setKendoConfig({ height: '100%' })
                    .setToolbar({ wrapping: true });

                //vm.grid.config.primaryKey = 'custact_no';

                // #endregion GRID

                // #region REMEMBER FUNCTIONS

                function _rememberDataViewTab() {
                    if (vm.model.ready && vm.model.get?.gb_displayBtnGrp?.trim()) {
                        rememberService.remember('w_custactsearch.gb_displayBtnGrp', vm.model.get.gb_displayBtnGrp);
                    }
                };

                function _rememberMainTab() {
                    if (vm.model.ready && vm.model.get?.bg_main?.trim()) {
                        rememberService.remember(`${$location.path().substring(1)}.bg_main`, vm.model.get.bg_main);
                    }
                };

                function _rememberMemberPortalUserKeyno() {
                    if (vm.model.ready && vm.model.get?.member_portal_user_keyno?.trim()) {
                        rememberService.remember(stateService.getCurrentName() + '/-/-/A' + '.member_portal_user_keyno', vm.model.get.member_portal_user_keyno);
                    }
                };

                function _rememberActivityType() {
                    if (vm.model.ready && vm.model.get?.activitytype_no?.trim()) {
                        rememberService.remember(`${$location.path().substring(1)}.activitytype_no`, vm.model.get.activitytype_no);
                    }
                };

                function _rememberSearchText() {
                    if (vm.model.get.searchtext !== undefined && vm.model.get.searchtext !== null) {
                        rememberService.remember(`${$location.path().substring(1)}.searchtext`, vm.model.get.searchtext);
                    }
                };

                // #endregion REMEMBER FUNCTIONS

                // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

                let rememberFunc = function (id) {
                    angular.forEach(variableNames, function (_, key) {
                        switch (key) {
                            case 'cust_no':
                                variableNames[key] = stateService.getCurrentName() + '/-/-/A' + '.' + key;
                                break;
                            default:
                                variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                                break;
                        }
                    });

                    if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                    let variableValue = null;

                    switch (id) {
                        case 'reg_of_portal_user_keyno':
                        case 'cust_no':
                            if (utilityService.validateParmsValue(vm.model.get[id], true) !== true) return;

                            variableValue = vm.model.get[id];
                            break;
                        default:
                            break;
                    }

                    return rememberService.remember(variableNames[id], variableValue);
                };

                // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

                let loadSubCrms = function () {
                    custactSearchService.listSubCrms({ add_all: '1' }).then(function (result) {
                        angular.copy(result, vm.model.selectListSubCrms);
                    });
                };

                // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

                vm.typeaheadSearch = function (value) {
                    return typeaheadService.searchCustomer({
                        cust_name: value || '',
                        filtertype: '',
                        filtervalue: '',
                        custtype: 'C',
                        lastselect: 'SEARCH'
                    });
                };

                // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

                // #region SELECTED VALUE FUNCTION

                vm.onSelectedTypeahead = function (item) {
                    if (item) {
                        vm.model.get.cust_no = item?.item_id ?? '0';
                        vm.model.get.cust_name = item?.item_name ?? '';

                        rememberFunc('cust_no');
                        _loadGridActivity();
                        if (vm.grid?.gridfunc?.read) vm.grid.gridfunc.read();
                    } else {
                        reset('cust_name');
                    }
                };

                // #endregion SELECTED VALUE FUNCTION

                // #region CHANGED VALUE FUNCTION

                vm.onChangedTypeahead = function (value, item) {
                    vm.model.get.cust_name = value;

                    if (value) {
                        if (item?.item_id) {
                            vm.model.get.cust_no = item.item_id;
                        }
                    } else {
                        reset('cust_name');
                    }
                };

                // #endregion CHANGED VALUE FUNCTION

                // #region CLEAR VALUE FUNCTION

                vm.onClearTypeahead = function () {
                    reset('cust_name');
                    rememberFunc('cust_no');
                    _loadGridActivity();
                    if (vm.grid?.gridfunc?.read) vm.grid.gridfunc.read();
                };

                // #endregion CLEAR VALUE FUNCTION

                // #region BUTTON HANDLERS

                vm.addNewActivity = function () {
                    stateService.go('custactedit', {
                        custact_no: 0,
                        filterfield: $stateParams.filterfield,
                        filtervalue: $stateParams.filtervalue,
                    });
                };

                function gotoActivityStatuses() {
                    stateService.go('activitytypeactstatuses', { argtype: 'activitytype_no', argvalue: vm.model.get.activitytype_no });
                };

                function gotoActivityStatus() {
                    stateService.go('actstatus', { actstatus_keyno: vm.model.get.actstatus_keyno });
                };

                let timerSearch = null;

                vm.searchCustactFind = function () {
                    if (angular.isDefined(timerSearch)) {
                        $timeout.cancel(timerSearch);
                    }

                    timerSearch = $timeout(function () {
                        _loadCustactsData();
                        _rememberSearchText();
                    }, 500);
                };

                // #endregion FORM-FIELD BUTTON HANDLERS

                // #region RESET BUTTON FUNCTION

                let reset = function (id) {
                    switch (id) {
                        case 'reg_of_portal_user_keyno':
                            vm.model.get.reg_of_portal_user_keyno = '0';

                            rememberFunc('reg_of_portal_user_keyno');
                            _loadGridActivity();
                            if (vm.grid?.gridfunc?.read) vm.grid.gridfunc.read();
                            break;
                        case 'cust_name':
                            vm.model.get.cust_no = '0';
                            vm.model.get.cust_name = '';
                            break;
                        default:
                            break;
                    }
                };

                // #endregion RESET BUTTON FUNCTION

                // #region KANBAN

                vm.gotoActivity = function (item) {
                    stateService.go(item.item_state, item.item_parms);
                };

                vm.saveKanbanSort = async function (item) {
                    if (item?.itemId && item.itemId.trim() && item?.listToId && item.listToId.trim() && item?.listToIndex !== undefined && item?.listToIndex !== null) {
                        const data = await custactSearchService.save(item.itemId, item.listToId, item.listToIndex);

                        if (data[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: 'Varsel',
                                message: data[0].errormessage,
                                buttons: [
                                    { label: 'OK', cssClass: 'btn-warning', action: (dialogItself) => dialogItself.close() },
                                ],
                            })
                        }
                    }
                };

                // #endregion KANBAN

                // #region CHANGE FUNCTIONS

                vm.onMainTabChanged = function (value) {
                    vm.model.get.bg_main = value;
                    _rememberMainTab();
                };

                vm.onDataViewTabChanged = function (value) {
                    vm.model.get.gb_displayBtnGrp = value;
                    _rememberDataViewTab();
                };

                vm.onSearchTextChanged = function (value) {
                    if (vm.model.get.searchtext !== value) {
                        vm.model.get.searchtext = value;
                    }

                    if (value === '') vm.searchCustactFind();
                };

                vm.onFilterFieldChanged = function (value, key) {
                    if (vm.model.get[key] !== value) {
                        vm.model.get[key] = value;
                        _loadCustactsData();
                    }
                };

                vm.onActivityTypeChanged = function (value) {
                    if (vm.model.get.activitytype_no !== value) {
                        vm.model.get.activitytype_no = value;
                        _loadActivityStatuses();
                        _rememberActivityType();
                        _loadCustactsData();
                    }
                };

                vm.onMemberPortalUserKeynoChanged = function (value) {
                    if (vm.model.get.member_portal_user_keyno !== value) {
                        vm.model.get.member_portal_user_keyno = value;
                        _loadCustactsData();
                        _rememberMemberPortalUserKeyno()
                    }
                };

                // #endregion CHANGE FUNCTIONS

                // #region LOAD FUNCTIONS

                async function _loadPageGet() {
                    vm.model.get = (await custactSearchService.genericFunction(839, {
                        webpage: $location.path().substring(1),
                        filterfield: $stateParams.filterfield,
                        filtervalue: $stateParams.filtervalue,
                        custacttype: $stateParams.custacttype,
                    }))[0]
                };

                async function _loadGridActivity() {
                    const activityParameters = {
                        searchtext: vm.model.get.searchtext,
                        ok: vm.model.get.ok,
                        filterfield: $stateParams.filterfield,
                        filtervalue: $stateParams.filtervalue,
                        custacttype: $stateParams.custacttype,
                        acttype: 'all',
                        activitytype_no: vm.model.get.activitytype_no,
                        actstatus_keyno: vm.model.get.actstatus_keyno,
                        bg_main: vm.model.get.bg_main,
                        bg_search: 'RESULT',
                        member_portal_user_keyno: vm.model.get.member_portal_user_keyno,
                        reg_of_portal_user_keyno: vm.model.get.reg_of_portal_user_keyno,
                        cust_no: vm.model.get.cust_no
                    };

                    vm.grid.setLoadDataFunction({ method: 2452, parameters: activityParameters })

                    vm.model.gridActivityReady = true;
                };


                async function _loadActivityTypes() {
                    vm.model.activityTypes = await custactSearchService.loadP2ActivityTypeList('1', $stateParams.custacttype);
                };

                async function _loadActivityStatuses() {
                    vm.model.activityStatuses = await custactSearchService.loadActStatusList(vm.model.get.activitytype_no, '1');
                    vm.model.get.actstatus_keyno = '0';
                };

                async function _loadPortalUsers() {
                    vm.model.portalUsers = await custactSearchService.loadPortalUserSubCrmList('1');
                };

                async function _loadOkStatuses() {
                    vm.model.okStatuses = await custactSearchService.loadCustactSearchOkList();
                };

                async function _loadCustactsList() {
                    if (vm.model.get.searchtext !== undefined && vm.model.get.searchtext !== null && vm.model.get.ok && vm.model.get.activitytype_no && vm.model.get.actstatus_keyno && vm.model.get.member_portal_user_keyno) {
                        vm.model.custacts = await custactSearchService.loadCustactFindList(vm.model.get.searchtext, vm.model.get.ok, $stateParams.filterfield, $stateParams.filtervalue, $stateParams.custacttype, 'all', vm.model.get.activitytype_no, vm.model.get.actstatus_keyno, vm.model.get.bg_main, 'RESULT', vm.model.get.member_portal_user_keyno);
                    }
                };

                async function _loadCustactsData() {
                    _loadCustactsList();

                    if (vm.model.mobileSize.matches === true) {
                        _loadGridActivity();
                        if (vm.grid?.gridfunc?.read) vm.grid.gridfunc.read();
                    }
                };

                // #endregion LOAD FUNCTIONS

                // #region FIELD CHANGE MULTI FUNCTIONS

                vm.onChanged = function (value, id) {
                    switch (id) {
                        case 'reg_of_portal_user_keyno':
                            if (utilityService.validateWatchValue(value, vm.model.get.reg_of_portal_user_keyno) !== true) return;

                            vm.model.get.reg_of_portal_user_keyno = value;

                            if (utilityService.validateParmsValue(vm.model.get.reg_of_portal_user_keyno, true) !== true) return;

                            rememberFunc('reg_of_portal_user_keyno');
                            _loadGridActivity();
                            if (vm.grid?.gridfunc?.read) vm.grid.gridfunc.read();
                            break;
                        default:
                            utilityService.setValueToVariable(value);
                            break;
                    }
                };

                // #endregion FIELD CHANGE MULTI FUNCTIONS

                // #region ANGULAR LIFECYCLE

                function onWindowResize() {
                    _loadCustactsData();
                };

                vm.$onInit = async function () {
                    loadSubCrms();

                    await Promise.all([
                        _loadActivityTypes(),
                        _loadPortalUsers(),
                        _loadPageGet(),
                        _loadOkStatuses(),
                    ]);

                    vm.model.ready = true;
                    _loadActivityStatuses();
                    _loadCustactsData();

                    vm.model.mobileSize.addEventListener('change', onWindowResize);
                    onWindowResize();
                };

                vm.$onDestroy = function () {
                    vm.model.mobileSize.removeEventListener('change', onWindowResize);
                };

                // #endregion ANGULAR LIFECYCLE
            },
        ],
    });
})();

//test: {
//    records: [
//        {
//            item_id: '622513',
//            item_name: '2017 Nytt tilbud til Hole Maskiner Krokbil Volvo (JGH/RH/ODR) I arbeid',
//            ok: '0',
//            is_deviation: '0',
//            item_path: 'custactedit/622513/-/-',
//            item_state: 'workdetails',
//            item_parms: '622513',
//            item_glyphicon: 'glyphicon-unchecked fas-digging',
//            item_glyphicon_color: 'black #5cb85c',
//            item_sort_id: '100',
//            orderby: 1,
//            item_name_sub1: 'Ekornes ASA',
//        },
//        {
//            item_id: '622514',
//            item_name: '2017 Nytt tilbud 2',
//            ok: '0',
//            is_deviation: '0',
//            item_path: 'custactedit/622513/-/-',
//            item_state: 'workdetails',
//            item_parms: '622513',
//            item_glyphicon: 'glyphicon-unchecked fas-digging',
//            item_glyphicon_color: 'black #5cb85c',
//            item_sort_id: '100',
//            orderby: 1,
//            item_name_sub1: 'Ekornes ASA',
//        },
//        {
//            item_id: '622515',
//            item_name: '2017 Nytt tilbud 3',
//            ok: '0',
//            is_deviation: '0',
//            item_path: 'custactedit/622513/-/-',
//            item_state: 'workdetails',
//            item_parms: '622513',
//            item_glyphicon: 'glyphicon-unchecked fas-digging',
//            item_glyphicon_color: 'black #5cb85c',
//            item_sort_id: '100',
//            orderby: 1,
//            item_name_sub1: 'Ekornes ASA',
//        },
//        {
//            item_id: '622516',
//            item_name: '2017 Nytt tilbud 4',
//            ok: '0',
//            is_deviation: '0',
//            item_path: 'custactedit/622513/-/-',
//            item_state: 'workdetails',
//            item_parms: '622513',
//            item_glyphicon: 'glyphicon-unchecked fas-digging',
//            item_glyphicon_color: 'black #5cb85c',
//            item_sort_id: '300',
//            orderby: 1,
//            item_name_sub1: 'Ekornes ASA',
//        },
//        {
//            item_id: '622517',
//            item_name: '2017 Nytt 5',
//            ok: '0',
//            is_deviation: '0',
//            item_path: 'custactedit/622513/-/-',
//            item_state: 'workdetails',
//            item_parms: '622513',
//            item_glyphicon: 'glyphicon-unchecked fas-digging',
//            item_glyphicon_color: 'black #5cb85c',
//            item_sort_id: '300',
//            orderby: 1,
//            item_name_sub1: 'Ekornes ASA',
//        },
//    ],
//                    },
