<!-- (click)="onClick($event)" -->
<div
    class="tt-webpagemenu-dashboard-widget"
    [ngClass]="{ 'tt-webpagemenu-dashboard-widget__loading': loading }"
    [style.backgroundColor]="ttDashboardWidget?.tile_background_color"
    [style.color]="ttDashboardWidget?.tile_heading_text_color"
>
    <header class="tt-dashboard-widget__header">
        <span
            *ngIf="!!ttDashboardWidget?.item_glyphicon"
            class="fa-fw"
            [ngClass]="ttDashboardWidget?.item_glyphicon"
            [style.color]="ttDashboardWidget?.item_glyphicon_color"
            [style.fontSize]="ttDashboardWidget?.item_glyphicon_size?.toLowerCase()"
        ></span>
        <span class="tt-dashoboard-widget__headings">
            <span
                class="tt-webpagemenu-dashboard-widget__label"
                [style.color]="ttDashboardWidget?.tile_heading_text_color"
                [style.fontSize]="ttDashboardWidget?.tile_heading_text_size?.toLowerCase()"
                >{{ ttDashboardWidget?.tile_heading_text ?? "" }}</span
            >
            <span
                class="tt-webpagemenu-dashboard-widget__subheading"
                [style.color]="ttDashboardWidget?.tile_subheading_text_color"
                [style.fontSize]="ttDashboardWidget?.tile_subheading_text_size?.toLowerCase()"
                >{{ ttDashboardWidget?.tile_subheading_text ?? "" }}</span
            >
        </span>
    </header>
    <span class="tt-dashboard-widget__header">
        <span *ngIf="!!ttDashboardWidget?.item_glyphicon" class="fa-fw" [style.fontSize]="ttDashboardWidget?.item_glyphicon_size?.toLowerCase()"></span>
        <span class="tt-webpagemenu-dashboard-widget__value" [style.color]="ttDashboardWidget?.tile_heading_text_color">{{ ttDashboardWidget?.data1_text ?? "" }}</span>
    </span>
    <!-- <ag-charts-angular (onChartReady)="chartReady($event)" style="width: 100%; height: 30rem" [options]="graphoption"></ag-charts-angular> -->
    <!-- <span class="tt-webpagemenu-dashboard-widget__label"> Lorem ipsum dolor sit amet consectetur </span>
    <span class="tt-webpagemenu-dashboard-widget__value"> 9</span> -->
</div>
