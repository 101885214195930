import { Component } from '@angular/core';
import { SaftExportService } from './saft-export.service';
import { BusinesscoItem } from '@app/model/businessso-item.model';

@Component({
  selector: 'tt-saft-export',
  templateUrl: './saft-export.component.html',
  styleUrls: ['./saft-export.component.css']
})
export class SaftExportComponent {
    constructor(private saftExportService: SaftExportService) {


    }

    public model: ViewModel = {
        businesscoNo: '0',
        businesscoList: []
    };

    public async businesscoChanged() {
        console.log('businessco: ' + this.model.businesscoNo);

        //if (!!this.model.get?.show_transaction_details) {
        //    this.remember.remember('w_bankreconciliation.show_transaction_details', this.model.get.show_transaction_details);
        //}
    }

    async ngOnInit(): Promise<void> {
        let data = await this.saftExportService.Initialize();

        console.log('init');
        console.dir(data);


        this.model.businesscoList = { ...this.model.businesscoList, ...data.businesscoList };

        console.dir(this.model);

        //await this.getPageLoad();
        //this.loadDatataskLogs();
    }
}

interface ViewModel {
    businesscoNo: string,
    businesscoList: BusinesscoItem[]
}
