<tt-page *ngIf="!!get">
    <tt-divider *ngIf="laptopMediaQuery.matches" class="col-xs-12 sp-0 height-full" ttRememberId="p2_datatask-log">
        <tt-divider-columns>
            <tt-divider-pane ttSize="60">
                <ng-container [ngTemplateOutlet]="jsonView"></ng-container>
            </tt-divider-pane>
            <tt-divider-pane ttSize="40">
                <ng-container [ngTemplateOutlet]="infoView"></ng-container>
            </tt-divider-pane>
        </tt-divider-columns>
    </tt-divider>
    <ng-container *ngIf="!laptopMediaQuery.matches">
        <ng-container [ngTemplateOutlet]="jsonView"></ng-container>
        <ng-container [ngTemplateOutlet]="infoView"></ng-container>
    </ng-container>
</tt-page>

<ng-template #jsonView>
    <tt-card *ngIf="!!get" class="col-xs-12 p-2" ttHeading="h_json_data">
        <tt-button class="col-xs-3 sp-2 pull-right" [ttText]="prettier ? 'Raw' : 'Prettier'" [ttTranslateText]="false" ttType="secondary" (ttClick)="prettier = !prettier"></tt-button>
        <tt-button *ngIf="!prettier" class="col-xs-3 sp-2 pull-right" ttText="p2datatasklog_format" ttType="secondary" (ttClick)="formatJsonDataIn(); formatJsonDataOut()"></tt-button>
        <tt-textarea *ngIf="!prettier" ttLabel="jsondata_in" [ttModel]="get.jsondata_in"></tt-textarea>
        <tt-textarea *ngIf="!prettier" ttLabel="jsondata_out" [ttModel]="get.jsondata_out"></tt-textarea>

        <ng-container *ngIf="prettier">
            <h4 class="col-xs-12 p-0 tt-input__label" ttTranslateWord="jsondata_in" style="white-space: pre-wrap"></h4>
            <pre class="col-xs-12 sp-0" [innerHTML]="get.jsondata_in | prettyjson : [true, 3]"></pre>
            <h4 class="col-xs-12 p-0 tt-input__label" ttTranslateWord="jsondata_out"></h4>
            <pre class="col-xs-12 sp-0" [innerHTML]="get.jsondata_out | prettyjson : [true, 3]" style="white-space: pre-wrap"></pre>
        </ng-container>
    </tt-card>
</ng-template>

<ng-template #infoView>
    <tt-card *ngIf="!!get" class="col-xs-12 p-2" ttHeading="h_info">
        <tt-datetime ttLabel="reg_datetime" [ttModel]="get.reg_datetime" ttReadonly></tt-datetime>
        <tt-input ttLabel="portal_user_name" [ttModel]="get.portal_user_name" ttReadonly></tt-input>
        <tt-input-number ttLabel="p2_datatask_keyno" [ttModel]="get.p2_datatask_keyno" ttFormatNumber ttReadonly></tt-input-number>
        <tt-input ttLabel="proc_name" [ttModel]="get.proc_name" ttReadonly></tt-input>
    </tt-card>
</ng-template>
