(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectProject', {
        templateUrl: 'views/components/views/selectProject/selectProject.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stateService', 'selectProjectService', 'rememberService', function ($stateParams, $q, utilityService, stateService, selectProjectService, rememberService) {

    		// #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                bgProjectsActiveStatus: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'bg_projects_activestatus_active', color: 'primary', onClick: () => setProjectsActiveStatusView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'bg_projects_activestatus_notactive', color: 'primary', onClick: () => setProjectsActiveStatusView('NOTACTIVE') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
    			itemsListProjects: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProjectsActiveStatusView = function (id) {
                vm.model.bgProjectsActiveStatus.selected = vm.model.setting.bg_projects_activestatus = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                selectProjectService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    setProjectsActiveStatusView(vm.model.setting.bg_projects_activestatus);

                    $q.all([
                        loadProjects()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
    		
            let loadProjects = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_projects_activestatus) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListProjects = [];
                
                return selectProjectService.listProjects(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListProjects);
                });
    		};
    
    		// #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProjectsActiveStatus = function (value) {
                vm.model.bgProjectsActiveStatus.selected = value;
                vm.model.setting.bg_projects_activestatus = vm.model.bgProjectsActiveStatus.selected;

                loadProjects();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
    		// #region SAVE & GO BACK TO PREVIOUS PAGE FUNCTION
            
            vm.goToPre = function (item) {
                rememberService.remember('w_' + stateService.getCurrentName() + '.' + 'project_keyno', item.item_id).then(function () {
                    stateService.back();
                }); 
            };
            
    		// #endregion SAVE & GO BACK TO PREVIOUS PAGE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
