import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DashboardWidgetChart } from '../dashboard-widget-base/dashboard-widget-base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgCartesianChartOptions, AgChartOptions, AgPolarChartOptions } from 'ag-charts-community';
import { DashboardService } from '../dashboard.service';

@Component({
    selector: 'tt-dashboard-chart-modal',
    templateUrl: './dashboard-chart-modal.component.html',
    styleUrls: ['./dashboard-chart-modal.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardChartModalComponent {
    constructor(public dialogRef: MatDialogRef<DashboardChartModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DashboardChartModalData, private dashboard: DashboardService) {
        this.mapGraphdata();
    }

    private mapGraphdata() {
        this.graphoption = {
            theme: 'ag-polychroma-dark',
            background: { visible: false },
            title: { enabled: false },
        };

        const chartType = this.getChartType();

        if (chartType === 'donut' || chartType === 'pie') {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapPolarData(chartType, this.data?.graphdata ?? []) } as AgPolarChartOptions;
        } else if (this.data?.kpicomp_type === 'GRAPH_BARH') {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapHorizontalCartesianData(this.data?.graphdata ?? []) } as AgCartesianChartOptions;
        } else {
            this.graphoption = { ...this.graphoption, ...this.dashboard.mapCartesianData(chartType, this.data?.graphdata ?? []) } as AgCartesianChartOptions;
        }
    }

    private getChartType() {
        if (this.data?.kpicomp_type === 'GRAPH_LINE') {
            return 'line';
        } else if (this.data?.kpicomp_type === 'PIE') {
            return 'pie';
        } else if (this.data?.kpicomp_type === 'DONUT') {
            return 'donut';
        } else {
            return 'bar';
        }
    }

    // mapGraphdata() {
    //     const unqiueSeries = new Set(this.data?.graphdata?.map((data) => data.ykey));
    //     let series: any[] = [];
    //     let datas: any[] = [];

    //     for (let key of unqiueSeries) {
    //         let keyName = this.data?.graphdata?.find((data) => data.ykey === key)?.yname;
    //         const chartType = this.getChartType();

    //         if (chartType === 'bar' || chartType === 'line') {
    //             series.push({ type: this.getChartType(), xKey: 'xkey', yKey: key, yName: keyName ?? key });
    //         } else {
    //             series.push({ type: this.getChartType(), angleKey: 'yvalue', calloutLabelKey: 'yname' });
    //             if (chartType === 'donut') {
    //                 series = series.map((serie) => ({ ...serie, innerRadiusRatio: 0.7 }));
    //             }
    //         }

    //         for (let data of (this.data?.graphdata ?? []).filter((data) => data.ykey == key)) {
    //             datas.push({ ...data, [key]: data.yvalue });
    //         }
    //     }

    //     if (this.data?.kpicomp_type === 'GRAPH_BARH') {
    //         series = series.map((serie) => ({ ...serie, direction: 'horizontal' }));
    //     }

    //     this.graphoption = {
    //         theme: 'ag-polychroma-dark',
    //         background: {
    //             visible: false,
    //         },
    //         title: {
    //             enabled: false,
    //         },

    //         data: datas,
    //         series: series,
    //     };

    //     console.log('this.graphoption :>> ', this.graphoption);
    // }

    // private getChartType() {
    //     if (this.data?.kpicomp_type === 'GRAPH_LINE') {
    //         return 'line';
    //     } else if (this.data?.kpicomp_type === 'PIE') {
    //         return 'pie';
    //     } else if (this.data?.kpicomp_type === 'DONUT') {
    //         return 'donut';
    //     } else {
    //         return 'bar';
    //     }
    // }

    graphoption?: AgChartOptions;
}

export interface DashboardChartModalData extends DashboardWidgetChart {
    graphoption: any;
}
