(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderhead', {
        templateUrl: 'views/components/views/orderhead/orderhead.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', '$uibModal', 'responsive', 'stateService', 'layoutService', 'eventService', 'translateService', 'utilityService', 'p2WebpageService', 'rememberService', 'consignmentPlansService', 'orderHeadService', 'modalService', 'ieScreenBlockService', 'orderHeadComponentService', 'orderHeadStateService', 'pickJobGrpsService', 'typeaheadService', 'economyService', 'ttGridFactory', 'modalDynamicViewFactory', function ($stateParams, $q, $timeout, $uibModal, responsive, stateService, layoutService, eventService, translateService, us, p2WebpageService, rememberService, consignmentPlansService, orderHeadService, modalService, ieScreenBlockService, orderHeadComponentService, orderHeadStateService, pickJobGrpsService, typeaheadService, economyService, ttGridFactory, modalDynamicViewFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let onDestroy = [];
            vm.gridReady = false;

            var initLoadDone = false;

            var variableNames = {
                gb_row_1: ''
            };

            vm.version = module.version;

            vm.goTo = function (state, args) {
                // BHW 19.06.2022 - nuller ut modell variabel for textilskjermbildet
                if (state == 'textileoladd') {
                    rememberService.remember('textileoladd.textilemodel_id_last', '-').then(function () {
                        orderHeadStateService.goTo(state, vm.model.orderhead, args);
                    });
                } else {
                    orderHeadStateService.goTo(state, vm.model.orderhead, args);
                }
            };

            vm.dialogOptions = {
                view: { modal: true },
                //keynames: {
                //    commentKeyno: 'custactdialog_keyno',
                //},
                fetch: {
                    save: {
                        method: 1280,
                        parameters: () => ({
                            is_base64: 1,
                            order_internal_no: $stateParams.order_internal_no
                        })
                    },
                    get: {
                        method: 1281,
                        parameters: { order_internal_no: $stateParams.order_internal_no }
                    },
                    //delete: {
                    //    method: 3001
                    //}
                },
            };

            vm.model = {
                isDirty: false,
                style: {
                    labelAlwaysOnTop: {}
                },
                viewSize: '',
                views: [
                    { id: 'ohAddress', url: 'views/components/views/orderhead/orderhead.template.packery.address-all.html?v=' + module.version },
                    { id: 'ohDelivery', url: 'views/components/views/orderhead/orderhead.template.packery.delivery.html?v=' + module.version },
                    { id: 'ohCustomer', url: 'views/components/views/orderhead/orderhead.template.packery.customer.html?v=' + module.version },
                    { id: 'ohInfo', url: 'views/components/views/orderhead/orderhead.template.packery.info.html?v=' + module.version },
                    { id: 'ohPrice', url: 'views/components/views/orderhead/orderhead.template.packery.price.html?v=' + module.version },
                    { id: 'ohFunctions', url: 'views/components/views/orderhead/orderhead.template.packery.functions.html?v=' + module.version },
                    { id: 'ohAdrDel', url: 'views/components/views/orderhead/orderhead.template.packery.address.delivery.html?v=' + module.version },
                    { id: 'ohAdrInv', url: 'views/components/views/orderhead/orderhead.template.packery.address.invoice.html?v=' + module.version },
                    { id: 'ohAdrStat', url: 'views/components/views/orderhead/orderhead.template.packery.address.stat.html?v=' + module.version },
                    { id: 'ohAll', url: 'views/components/views/orderhead/orderhead.template.packery.all.html?v=' + module.version }
                ],
                translations: {
                    h_address: '',
                    h_address_delivery: '',
                    h_address_invoice: '',
                    h_address_stat: '',
                    h_customer: '',
                    h_delivery: '',
                    h_info: '',
                    h_ordering: '',
                    h_orderer: '',
                    h_price: ''
                },
                statCustNoSearchButtons: [
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', onClick: { click: vm.goTo, state: 'customeredit1' } },
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', onClick: { click: vm.goTo, state: 'customeredit2' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'customersmenu1' } }
                ],
                invoiceCustNoSearchButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'customersmenu2' } }
                ],
                projectNameButtons: [
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', onClick: { click: vm.goTo, state: 'selectproject' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'projectmenu' } }
                ],
                consernOrderButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'orderhead' } }
                ],
                orderValutaButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'valuta', args: { type: 'order' } } }
                ],
                invoiceValutaButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, state: 'valuta', args: { type: 'invoice' } } }
                ],
                contactPersonButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, type: 'customerpersons' } }
                ],
                statBusinessIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, type: 'businesss' } }
                ],
                deliveryBusinessIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, type: 'businesss' } }
                ],
                invoiceBusinessIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goTo, type: 'businesss' } }
                ],
                serviceObjectNameButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('serviceobjects', { argtype: '-', argvalue: '-' }) }
                ],
                deliveryZipCodeAndStreetButtons: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => stateService.go('postplace', { postplace_id: '0' }) }
                ],
                collectionButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('collections', {}) }
                ],
                mconfig: {
                    enabled: true,      // Is the menu enabled
                    show: true,        // Is the menu visible or not
                    activate: (item) => mconfigActivate(item),
                    deactivate: (item) => mconfigDeactivate(item),
                    function: (item) => mconfigFunction(item),
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', enabled: true, show: false },
                        { item_id: '1', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '2', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '4', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '5', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '6', item_name: 'edit', glyph: 'fa-edit', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                mainView: {
                    selected: 'orderhead_orderline',
                    lessButtons: [
                        { id: 'orderhead_orderline', label: 'orderhead_orderline', color: 'primary', onClick: { click: setMainView, id: 'orderhead_orderline' } },
                        { id: 'orderhead_orderhead', label: 'orderhead_orderhead', color: 'primary', onClick: { click: setMainView, id: 'orderhead_orderhead' } },
                    ],
                    moreButtons: [
                        { id: 'orderhead_orderline', label: 'orderhead_orderline', color: 'primary', onClick: { click: setMainView, id: 'orderhead_orderline' } },
                        { id: 'orderhead_orderhead', label: 'orderhead_orderhead', color: 'primary', onClick: { click: setMainView, id: 'orderhead_orderhead' } },
                        { id: 'orderhead_functions', label: 'orderhead_functions', color: 'primary', onClick: { click: setMainView, id: 'orderhead_functions' } },
                    ]
                },
                btnAdrTrmAdm: {
                    selected: 'address',
                    buttons: [
                        { id: 'address', label: 'orderhead_address', color: 'primary', onClick: function () { vm.model.btnAdrTrmAdm.selected = 'address'; } },
                        { id: 'terms', label: 'orderhead_terms', color: 'primary', onClick: function () { vm.model.btnAdrTrmAdm.selected = 'terms'; } },
                        { id: 'adm', label: 'orderhead_adm', color: 'primary', onClick: function () { vm.model.btnAdrTrmAdm.selected = 'adm'; } }
                    ]
                },
                btnDelPriceOther: {
                    selected: 'del',
                    buttons: [
                        { id: 'del', label: 'orderhead_termdelivery', color: 'primary', onClick: function () { vm.model.btnDelPriceOther.selected = 'del'; } },
                        { id: 'price', label: 'orderhead_termprice', color: 'primary', onClick: function () { vm.model.btnDelPriceOther.selected = 'price'; } }
                    ]
                },
                address: {
                    selected: 'stat',
                    buttons: [
                        { id: 'stat', label: 'orderhead_stat_address', onClick: function () { vm.model.address.selected = 'stat'; } },
                        { id: 'delivery', label: 'orderhead_delivery_address', onClick: function () { vm.model.address.selected = 'delivery'; } },
                        { id: 'invoice', label: 'orderhead_invoice_address', onClick: function () { vm.model.address.selected = 'invoice'; } }
                    ]
                },
                bgOrderLineDisplayType: {
                    selected: 'std',
                    buttons: [
                        { id: 'std', label: 'gb_orderline_displaytype_std', onClick: function () { vm.model.bgOrderLineDisplayType.selected = 'std'; } },
                        { id: 'change', label: 'gb_orderline_displaytype_changeprovidemethod', onClick: function () { vm.model.bgOrderLineDisplayType.selected = 'change'; } },
                    ]
                },
                orderhead: {},
                orderheadCalculation: {
                    selected_orderline_keyno: null,
                    pricelist_id: null,
                    pricelist_id2: null,
                    purchase_pricelist_id: null,
                    purchase_pricelist_id2: null,
                    stockplace_id: null
                },
                orderlines: [],
                orderlinesGrid: [],
                data: {
                    customerPersons: [],
                    sellers: [],
                    stockplaces: [],
                    tax: [],
                    businesscos: [],
                    businesses: [],
                    coops: [],
                    countries: [],
                    deliveryMethods: [],
                    deliveryTerms: [],
                    departments: [],
                    factoring: [],
                    invoiceMethods: [],
                    orderDialogs: [],
                    orderMethods: [],
                    payments: [],
                    portalUserOrders: [],
                    pricelists: [],
                    specialDiscount2: [],
                    valuta: [],
                    cancelCauses: [],
                    collection: [],
                },
                p2CustomerGetEdit: {},
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: '',
                pushingOrders: false,
                lockedConvertOrder: false,
                loadingConfirm: false,
                loadingRegretConfirm: false,
                loadingGenerate: false,
                useTabletLayout: false,
                lockedSave: false,
                generatingErrorCheck: false,
                lockedDelivery: false,
                lockedDelete: false,
                lockedCancel: false
            };

            vm.translations = {
                //h_address: '',
                //h_address_delivery: '',
                //h_address_invoice: '',
                //h_address_stat: '',
                //h_customer: '',
                //h_delivery: '',
                //h_info: '',
                //h_ordering: '',
                h_orderer: '',
                //h_price: '',
                orderinfo: '',
                changes: '',
                delivery: '',
                note: '',
                calc: '',
                tt_delete_grid_row_title: '',
                tt_delete_grid_row_warning: '',
                tt_delete_grid_row_confirm: '',
                tt_delete_grid_row_abort: '',
                tt_svea_payment_modal_title: '',
                tt_svea_payment_modal_confirm: '',
                tt_delete_orderhead_title: '',
                tt_delete_orderhead_warning: '',
                tt_delete_orderhead_confirm: '',
                tt_delete_orderhead_abort: '',
                tt_cancel_orderlines_title: '',
                tt_cancel_orderhead_title: '',
                tt_cancel_orderhead_warning: '',
                tt_cancel_orderhead_confirm: '',
                tt_cancel_orderhead_abort: '',
                //tt_orderer: '',
                //tt_order_info: '',
                //tt_changes: '',
                //tt_delivery: '',
                //tt_note: '',
                //tt_calculation: '',
                tt_dialog: '',
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.options = {
                freight: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            //const customDeleteSpecialFuncButton = {
            //    name: 'customDelete',
            //    text: '',
            //    icon: 'far-trash-alt',
            //    type: 'danger',
            //    func: function (data) {
            //        modalService.show({
            //            type: 'warning',
            //            title: vm.translations.tt_delete_grid_row_title,
            //            message: vm.translations.tt_delete_grid_row_warning,
            //            buttons: [{
            //                label: vm.translations.tt_delete_grid_row_abort,
            //                cssClass: 'btn-warning',
            //                action: function (dialogItself) {
            //                    dialogItself.close();
            //                }
            //            }, {
            //                label: vm.translations.tt_delete_grid_row_confirm,
            //                cssClass: 'btn-danger',
            //                action: function (dialogItself) {
            //                    vm.grid.gridfunc.removeRow(data);
            //                    vm.grid.gridfunc.saveChanges(false);
            //                    dialogItself.close();
            //                }
            //            }]
            //        });
            //    }
            //};

            // #endregion VARIABLES & DEFINITIONS

            // #region GRIDS

            //vm.grid = new ttGridFactory({ rememberId: 'w_orderline.grid', loadData: { method: 2645, parameters: { order_internal_no: $stateParams.order_internal_no } } })
            //    .setAddRowFunction({ method: null, parameters: { order_internal_no: $stateParams.order_internal_no } })
            //    .setSaveDataFunction({ method: null, parameters: { order_internal_no: $stateParams.order_internal_no } })
            //    .setToolbar({ wrapping: true })
            //    .setConfig({ shortcuts: true }).setSpecialFunc({ buttons: [customDeleteSpecialFuncButton] })
            //    .setNavigation({ altNav: true, newLine: true })
            //    .setKendoConfig({ height, sortable, aggregate, pager, selectable, filterable })
            //    .setOptionFunc((data) => optionfunc(data.data));
            vm.dynamicGrid = new ttGridFactory({
                dataTask: {
                    rememberId: 'w_orderline_dynamic.grid',
                    loadData: {
                        method: 3185,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    },
                    addRow: {                                           // 
                        method: null,
                        //parameters: null,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    }
                }
            }).setKendoConfig(
                { aggregate: [] }
            ).setSpecialFunc({
                buttons: [
                    {
                        name: 'customDelete',
                        text: '',
                        icon: 'far-trash-alt',
                        type: 'danger',
                        disabled: () => vm.model.orderhead.show_button_deleteorderlines !== '1' ? 'hidden' : false,
                        func: function (data) {
                            modalService.show({
                                type: 'warning',
                                title: vm.translations.tt_delete_grid_row_title,
                                message: vm.translations.tt_delete_grid_row_warning,
                                buttons: [{
                                    label: vm.translations.tt_delete_grid_row_abort,
                                    cssClass: 'tt-button--warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }, {
                                    label: vm.translations.tt_delete_grid_row_confirm,
                                    cssClass: 'tt-button--danger',
                                    action: async function (dialogItself) {
                                        await vm.dynamicGrid.gridfunc.removeRow(data);
                                        vm.dynamicGrid.gridfunc.saveChanges(false);
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                    }
                ]
            }).setToolbar({
                buttons: [
                    {
                        name: 'orderline_cancel',
                        text: 'tt_orderline_grid_cancel',
                        func: function (data) { vm.cancelSelectedLines() }, // vm.dynamicGrid.gridfunc.getIsSelectedRows
                        icon: 'none', cssClass: 'tt-button tt-button--danger im-grid-btn-xs-r',
                        translate: true,
                        disabled: function () {
                            return vm.model.orderhead.setting_enable_oh_cancel !== '1' ? 'hidden' : vm.model.lockedCancel ? true : vm.canCancelRows() ? false : true;
                        }
                    }
                ]
            });

            vm.canCancelRows = function () {
                const selectedRows = vm.dynamicGrid.gridfunc.getIsSelectedRows();
                return selectedRows?.length && selectedRows.filter(e => e.delivered_quantity >= e.ordered_quantity || e.invoiced_quantity > e.delivered_quantity).length === 0;
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_orderline.grid',              // Id used to retrieve user configs for the grid
                    loadData: {                                         // Method id of the get data procedure
                        method: 2645,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    },
                    addRow: {                                           // 
                        method: null,
                        //parameters: null,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        },
                        //post: function (response) {
                        //    //if (angular.isDefined(response) && angular.isDefined(response.orderline_keyno) && response.orderline_keyno !== null && response.orderline_keyno !== 0 && response.orderline_keyno !== '0') {
                        //    //    let rows = vm.grid.gridfunc.getAllRows();
                        //    //    for (let r = 0; r < rows.length; r++) {
                        //    //        if (angular.isDefined(rows[r].orderline_keyno) && rows[r].orderline_keyno === response.orderline_keyno) {
                        //    //            setTimeout(function () {
                        //    //                vm.grid.gridfunc.editCell(r);
                        //    //            }, 2000);
                        //    //            break;
                        //    //        }
                        //    //    }
                        //    //} else {
                        //    //    vm.grid.gridfunc.editCell(0);
                        //    //}
                        //}
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'updateBonusType', text: 'tt_update_bonus_type', func: function (data) { updateBonusType(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling, 'limit' will trigger only headers, and not toolbar
                    keepSortOnAdd: false,                                // Keeps sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    shortcuts: true,                                    // Enables shortcuts (Ctrl+i, Ctrl+d, ...)
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            {
                                name: 'customDelete',
                                text: '',
                                icon: 'far-trash-alt',
                                type: 'danger',
                                func: function (data) {
                                    modalService.show({
                                        type: 'warning',
                                        title: vm.translations.tt_delete_grid_row_title,
                                        message: vm.translations.tt_delete_grid_row_warning,
                                        buttons: [{
                                            label: vm.translations.tt_delete_grid_row_abort,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }, {
                                            label: vm.translations.tt_delete_grid_row_confirm,
                                            cssClass: 'btn-danger',
                                            action: async function (dialogItself) {
                                                await vm.grid.gridfunc.removeRow(data);
                                                vm.grid.gridfunc.saveChanges(false);
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                }
                            }
                        ]
                    },
                    navigation: {
                        altNav: true,
                        newLine: true
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            vm.ttGrid = {
                dataTask: {
                    rememberId: 'orderline.list.ttGrid',                // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2215,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null
                    },
                    removeRow: {
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        //method: 2217,
                        parameters: {
                            datatask_keyno: 2217,
                            order_internal_no: $stateParams.order_internal_no
                        },
                        autoSave: true,
                        //confirm: true
                    }
                },
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                               // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                             // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('orderhead onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [                                        // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                        //{ field: 'stock_stockvalue', aggregate: 'sum' }
                    ],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { gridOptionFunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null
            };

            vm.ttGridChange = {
                dataTask: {
                    rememberId: 'orderline.list.ttGrid',                // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2215,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null
                    },
                    removeRow: {
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        //method: 2217,
                        parameters: {
                            datatask_keyno: 2217,
                            order_internal_no: $stateParams.order_internal_no
                        },
                        autoSave: true,
                        //confirm: true
                    }
                },
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                               // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                             // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: true,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('orderhead onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [                                        // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                        //{ field: 'stock_stockvalue', aggregate: 'sum' }
                    ],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { gridChangeOptionFunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null
            };

            // #endregion GRIDS

            // #region OPTIONFUNC

            let optionfunc = function (data) {
                //console.log('optionfunc');
                //console.log(data);
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data.clickedCell.dataItem);
                    if (vm.model.orderhead.setting_show_orderline_calculation !== '1') return;

                    //vm.model.orderheadCalculation.selected_orderline_keyno = data.clickedCell.dataItem.orderline_keyno;
                    loadOrderlineCalculation(data.clickedCell.dataItem.orderline_keyno);
                }

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            var gridOptionFunc = function (data) {
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    //console.log('gridChangeOptionFunc - CellClickHandler');
                //    //console.dir(data);

                //    var parms = {
                //        item_id: data.clickedCell.dataItem.item_id,
                //        provide_method_id: data.clickedCell.dataItem.provide_method_id
                //    };

                //    vm.toggleChosen(parms);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //	console.log('gridChangeOptionFunc - OnCellClose');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //	console.log('gridChangeOptionFunc - LookupCellEditor');
                //	console.dir(data);
                //}
            };

            var gridChangeOptionFunc = function (data) {
                //console.log('gridChangeOptionFunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('gridChangeOptionFunc - CellClickHandler');
                    //console.dir(data);

                    var parms = {
                        item_id: data.clickedCell.dataItem.item_id,
                        provide_method_id: data.clickedCell.dataItem.provide_method_id
                    };

                    vm.toggleChosen(parms);
                }

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //	console.log('gridChangeOptionFunc - OnCellClose');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //	console.log('gridChangeOptionFunc - LookupCellEditor');
                //	console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.postplaceSearch = function (value) {
                return orderHeadComponentService.getPostplaceKeyname(value);
            };

            vm.selectPostplace = function (item, model) {
                //console.dir(item);
                if (item?.postplace_id) {
                    if (item.postplace_id === '') {
                        vm.model.orderhead.delivery_postplace_name_street = '';
                        vm.model.orderhead.delivery_postplace_id_street = '';
                    } else {
                        vm.model.orderhead.delivery_postplace_name_street = item.postplace_name;
                        vm.model.orderhead.delivery_postplace_id_street = item.postplace_id;
                    }
                }

                //rememberFunc();
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            var mconfigActivate = function (item) {
                if (item === 'all') {
                    angular.forEach(vm.model.mconfig.records, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.canActive === true) {
                                data.isActive = true;
                            }
                        }
                    });
                } else if (angular.isDefined(item)) {
                    vm.model.mconfig.records[item.item_id].isActive = true;
                } else {
                    vm.model.mconfig.records[4].isActive = true;
                }
            };

            var mconfigDeactivate = function (item) {
                if (item === 'all') {
                    angular.forEach(vm.model.mconfig.records, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.item_name === 'resize' && data.isActive) {
                                orderLineListSort();
                            }

                            if (data.canActive === true) {
                                data.isActive = false;
                            }
                        }
                    });
                } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                    vm.model.mconfig.records[item.item_id].isActive = false;
                } else if (angular.isDefined(item)) {
                    for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                        if (vm.model.mconfig.records[r].item_name === item) {
                            vm.model.mconfig.records[r].isActive = false;
                            break;
                        }
                    }
                } else {
                    vm.model.mconfig.records[4].isActive = false;
                }
            };

            const mconfigFunction = function (item) {
                if (item === 'deactivate') {
                    vm.model.mconfig.records[4].isActive = false;
                }

                if (item.canActive === true) {
                    item.isActive = !item.isActive;
                }

                if (item.item_name === 'resize') {
                    if (item.isActive !== true) {
                        orderLineListSort();
                    }
                }

                if (item.item_id === '0') {
                    if (item.isActive === true) {
                        vm.model.mconfig.records[1].show = true;
                        vm.model.mconfig.records[2].show = true;
                    } else {
                        vm.model.mconfig.records[1].show = false;
                        vm.model.mconfig.records[2].show = false;
                    }
                }

                if (item.item_id === '6') {
                    if (item.isActive === false) {
                        //Kjøre funksjon som lagrer data via prosedyre
                    }
                }
            };

            var setMainView = function (id) {
                vm.model.mainView.selected = vm.model.orderhead.gb_row_1 = id;
            };

            var deregTranslations = translateService.on(vm.model.translations, 'changed', function (result) {
                angular.copy(result, vm.model.translations);

                deregTranslations();
            });

            p2WebpageService.preloadDetails(stateService.getCurrentName());

            // if more tt-item-manager groups are added to view then add groupId here to enable preloading fields.
            layoutService.preloadFieldInfo(['ohWell', 'ohAdm', 'ohAdrStat', 'ohAdrDel', 'ohAdrInv', 'ohTermDelivery', 'ohTermPrice', 'ohAddressDelivery', 'ohAddressInvoice', 'ohAddressStat', 'ohCustomer', 'ohDelivery', 'ohInfo', 'ohPrice', 'ohAddress']);

            var onSizeChanged = function (newSize) {
                vm.model.viewSize = newSize;

                vm.model.useTabletLayout = newSize === 'xx' || newSize === 'xs' || newSize === 'sm' || newSize === 'md';
            };

            onSizeChanged(responsive.current);

            onDestroy.push(eventService.on('event:responsive-breakpoint', function (data) {
                onSizeChanged(data.newSize);
            }));

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (info.labelAlwaysOnTop === true) {
                    vm.model.style.labelAlwaysOnTop.marginTop = '10px';
                } else {
                    if (angular.isDefined(vm.model.style.labelAlwaysOnTop.marginTop)) {
                        delete vm.model.style.labelAlwaysOnTop.marginTop;
                    }
                }
            });

            orderHeadComponentService.loadOrderhead($stateParams.order_internal_no).then(function (orderhead) {
                angular.copy(orderhead, vm.model.orderhead);
                stateService.setTitleOverride(vm.model.orderhead.orderhead_heading);
                loadPickJobGroups();

                orderHeadComponentService.loadAllOtherData($stateParams.order_internal_no, orderhead).then(function (data) {
                    //bhw
                    if (vm.model.orderhead.setting_orderlines_use_grid === '0') {
                        angular.copy(data.order.orderlines, vm.model.orderlines);
                    }

                    if (vm.model.orderhead.setting_oh_show_dynamic_special === '1') {
                        vm.model.mainView.lessButtons[2].disabled = false;
                        vm.model.mainView.moreButtons[3].disabled = false;

                        orderHeadComponentService.getDynamicSpecial($stateParams.order_internal_no).then(function (response) {
                            angular.forEach(response[0], function (value, key) {
                                if (vm.model.orderhead[key]) return;

                                vm.model.orderhead[key] = value;
                            });
                        });
                    } else {
                        if (vm.model.orderhead.gb_row_1 === 'orderhead_dynamic_special')
                            vm.model.orderhead.gb_row_1 = 'orderhead_orderline';
                    }
                    angular.copy(data.order.customerPersons, vm.model.data.customerPersons);
                    angular.copy(data.order.sellers, vm.model.data.sellers);
                    angular.copy(data.order.stockplaces, vm.model.data.stockplaces);
                    angular.copy(data.order.tax, vm.model.data.tax);

                    angular.copy(data.general.businesscos, vm.model.data.businesscos);
                    angular.copy(data.general.businesses, vm.model.data.businesses);
                    angular.copy(data.general.coops, vm.model.data.coops);
                    angular.copy(data.general.countries, vm.model.data.countries);
                    angular.copy(data.general.deliveryMethods, vm.model.data.deliveryMethods);
                    angular.copy(data.general.deliveryTerms, vm.model.data.deliveryTerms);
                    angular.copy(data.general.departments, vm.model.data.departments);
                    angular.copy(data.general.factoring, vm.model.data.factoring);
                    angular.copy(data.general.invoiceMethods, vm.model.data.invoiceMethods);
                    angular.copy(data.general.orderDialogs, vm.model.data.orderDialogs);
                    angular.copy(data.general.orderMethods, vm.model.data.orderMethods);
                    angular.copy(data.general.payments, vm.model.data.payments);
                    angular.copy(data.general.portalUserOrders, vm.model.data.portalUserOrders);
                    angular.copy(data.general.pricelists, vm.model.data.pricelists);
                    angular.copy(data.general.specialDiscount2, vm.model.data.specialDiscount2);
                    angular.copy(data.general.valuta, vm.model.data.valuta);
                    angular.copy(data.general.collection, vm.model.data.collection);
                    angular.copy(data.general.cancelCauses, vm.model.data.cancelCauses);

                    if (data.order.payments.length > 0) {
                        vm.model.orderhead.iscashpayment = data.order.payments[0].iscashpayment;
                    }

                    setMainView(vm.model.orderhead.gb_row_1);
                    initLoadDone = true;
                });
            });

            //orderHeadComponentService.loadAllData($stateParams.order_internal_no).then(function (data) {
            //    angular.copy(data.order.order, vm.model.orderhead);
            //    stateService.setTitleOverride(vm.model.orderhead.orderhead_heading);
            //    loadPickJobGroups();

            //    //console.log('allData');
            //    //console.dir(data);

            //    //bhw
            //    if (vm.model.orderhead.setting_orderlines_use_grid === '0') {
            //        angular.copy(data.order.data.orderlines, vm.model.orderlines);
            //    }

            //    if (vm.model.orderhead.setting_oh_show_dynamic_special === '1') {
            //        vm.model.mainView.lessButtons[2].disabled = false;
            //        vm.model.mainView.moreButtons[3].disabled = false;

            //        orderHeadComponentService.getDynamicSpecial($stateParams.order_internal_no).then(function (response) {
            //            //console.log('response');
            //            //console.dir(response[0]);

            //            angular.forEach(response[0], function (value, key) {
            //                if (vm.model.orderhead[key]) return;

            //                vm.model.orderhead[key] = value;
            //            });
            //        });
            //    } else {
            //        if (vm.model.orderhead.gb_row_1 === 'orderhead_dynamic_special')
            //            vm.model.orderhead.gb_row_1 = 'orderhead_orderline';
            //    }

            //    //if (vm.model.orderhead.setting_orderlines_use_grid == '1'){
            //    //    angular.copy(data.order.data.orderlinesGrid, vm.model.orderlinesGrid);

            //    //    $timeout(function(){
            //    //        console.log('Loading grid..');
            //    //        loadGrid();
            //    //    },500);
            //    //}

            //    angular.copy(data.order.data.customerPersons, vm.model.data.customerPersons);
            //    angular.copy(data.order.data.sellers, vm.model.data.sellers);
            //    angular.copy(data.order.data.stockplaces, vm.model.data.stockplaces);
            //    angular.copy(data.order.data.tax, vm.model.data.tax);

            //    angular.copy(data.general.businesscos, vm.model.data.businesscos);
            //    angular.copy(data.general.businesses, vm.model.data.businesses);
            //    angular.copy(data.general.coops, vm.model.data.coops);
            //    angular.copy(data.general.countries, vm.model.data.countries);
            //    angular.copy(data.general.deliveryMethods, vm.model.data.deliveryMethods);
            //    angular.copy(data.general.deliveryTerms, vm.model.data.deliveryTerms);
            //    angular.copy(data.general.departments, vm.model.data.departments);
            //    angular.copy(data.general.factoring, vm.model.data.factoring);
            //    angular.copy(data.general.invoiceMethods, vm.model.data.invoiceMethods);
            //    angular.copy(data.general.orderDialogs, vm.model.data.orderDialogs);
            //    angular.copy(data.general.orderMethods, vm.model.data.orderMethods);
            //    angular.copy(data.general.payments, vm.model.data.payments);
            //    angular.copy(data.general.portalUserOrders, vm.model.data.portalUserOrders);
            //    angular.copy(data.general.pricelists, vm.model.data.pricelists);
            //    angular.copy(data.general.specialDiscount2, vm.model.data.specialDiscount2);
            //    angular.copy(data.general.valuta, vm.model.data.valuta);
            //    angular.copy(data.general.cancelCauses, vm.model.data.cancelCauses);

            //    if (data.order.data.payments.length > 0) {
            //        vm.model.orderhead.iscashpayment = data.order.data.payments[0].iscashpayment;
            //    }

            //    //console.dir(vm.model.orderhead);

            //    setMainView(vm.model.orderhead.gb_row_1);
            //    initLoadDone = true;
            //});

            var loadOrderhead = function () {
                orderHeadComponentService.loadOrderhead($stateParams.order_internal_no).then(function (orderhead) {
                    angular.copy(orderhead, vm.model.orderhead);
                    stateService.setTitleOverride(vm.model.orderhead.orderhead_heading);
                });
            };

            var loadOrderlines = function () {
                if (vm.model.orderhead.setting_orderlines_use_grid === '0') {
                    orderHeadComponentService.loadOrderlines($stateParams.order_internal_no).then(function (orderlines) {
                        angular.copy(orderlines, vm.model.orderlines);
                    });
                } else {
                    // Refresh grid to refetch data if new grid is enabled
                    vm.ttGridChange.gridfunc.read();
                }

            };

            var loadPickJobGroups = function () {
                if (us.validateParmsValue(vm.model.orderhead.stockplace_id) !== true) return;

                vm.model.selectListPickJobGroups = [];

                pickJobGrpsService.listPickJobGroups({ stockplace_id: vm.model.orderhead.stockplace_id }).then(function (data) {
                    angular.copy(data, vm.model.selectListPickJobGroups);
                });
            };

            var loadDeliveryMethod = function () {
                if (us.validateParmsValue(vm.model.orderhead.deliverymethod_no) !== true) return;

                orderHeadComponentService.changeDeliveryMethod({ deliverymethod_no: vm.model.orderhead.deliverymethod_no }).then(function (data) {
                    vm.model.orderhead.pickjobgrp_keyno = data[0].pickjobgrp_keyno;
                });
            };

            let loadOrderlineCalculation = function (orderlineKeyno) {
                orderHeadService.loadOrderlineCalculation(orderlineKeyno).then((response) => {
                    vm.model.orderheadCalculation = {
                        selected_orderline_keyno: orderlineKeyno,
                        pricelist_id: null,
                        pricelist_id2: null,
                        purchase_pricelist_id: null,
                        purchase_pricelist_id2: null,
                        stockplace_id: null
                    };
                    angular.forEach(response[0], function (value, key) {
                        vm.model.orderheadCalculation[key] = value;
                    });
                });
            };

            function gridButtons() {
                return [
                    /*{ name: 'customFilter', text: '', func: function () { vm.customFilter(); }, icon: 'filter' },
                    { name: 'customCreate', text: '', func: function () { vm.create() }, icon: 'save' }*/
                ];
            }

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (us.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'gb_row_1':
                        if (initLoadDone !== true) return;
                        if (us.validateParmsValue(vm.model.orderhead.gb_row_1) !== true) return;

                        variableValue = vm.model.orderhead.gb_row_1;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            var orderLineListSort = function () { //DENNE BURDE FÅ NYTT NAVN!!!!!!!!!!!!!!!!!!!!!!
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateList = [];

                if (angular.isDefined(vm.model.orderlines[0]) && angular.isDefined(vm.model.orderlines[0].item_secondary)) {
                    for (var r = 0; r < vm.model.orderlines[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.orderlines[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.orderlines[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.orderlines[0].item_secondary[r].item_col_width })
                        }
                    }
                }

                var updateParms = {
                    view_id: 'w_orderhead.orderline',
                    order_internal_no: vm.model.orderhead.order_internal_no,
                    records: updateList
                };

                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    loadOrderlines();
                                }
                            }]
                        });
                    } else {
                        loadOrderlines();

                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
                });
            };

            vm.confirmPickJobStatus = function () {
                if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                vm.model.loadingConfirm = true;

                orderHeadService.confirmPickJob(vm.model.orderhead.order_internal_no).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingConfirm = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingConfirm = false;
                        loadOrderhead();
                        loadOrderlines();
                    }
                });
            };

            vm.regretConfirmPickJobStatus = function () {
                if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                vm.model.loadingRegretConfirm = true;

                orderHeadService.regretConfirmPickJob(vm.model.orderhead.order_internal_no).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingRegretConfirm = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingRegretConfirm = false;
                        loadOrderhead();
                        loadOrderlines();
                    }
                });
            };

            vm.generatePickJobStatus = function () {
                if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                vm.model.loadingGenerate = true;

                orderHeadService.generatePickJob(vm.model.orderhead.order_internal_no).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingGenerate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingGenerate = false;
                        loadOrderhead();
                        loadOrderlines();
                    }
                });
            };

            // #region BUTTON PUSH PROCEDURE FUNCTION CALL

            vm.pushOrder = function () {
                if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                vm.model.pushingOrders = true;

                orderHeadService.loadOrderHeadOrderPush(vm.model.orderhead.order_internal_no).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.pushingOrders = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.pushingOrders = false;
                    }
                });
            };

            // #endregion BUTTON PUSH PROCEDURE FUNCTION CALL

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'serviceobject_name':
                        vm.model.orderhead[id] = value;

                        return typeaheadService.lookUpServiceObjects({ searchtext: vm.model.orderhead[id] || '' });
                        break;
                    case 'delivery_postplace_id_and_name_street':
                    case 'delivery_postplace_id_and_name':
                        vm.model.orderhead[id] = value;

                        return typeaheadService.lookUpStockPlaces({ searchtext: vm.model.orderhead[id] || '' });
                        break;
                    case 'stat_cust_name':
                    case 'invoice_cust_name':
                        vm.model.orderhead[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.orderhead[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'C',
                            lastselect: 'SEARCH'
                        });
                        break;
                    case 'delivery_cust_name':
                        if (vm.model.orderhead.delivery_cust_unreg === '1') return;

                        vm.model.orderhead.delivery_cust_name = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.orderhead.delivery_cust_name || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'D',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'serviceobject_name':
                        if (item) {
                            vm.model.orderhead.serviceobject_keyno = item?.item_id ?? '0';
                            vm.model.orderhead.serviceobject_name = item?.item_name ?? '';
                            vm.model.isDirty = true;
                        } else {
                            vm.onTypeaheadClear('serviceobject_name');
                        }
                        break;
                    case 'delivery_postplace_id_and_name_street':
                        if (item) {
                            vm.model.orderhead.delivery_postplace_id_street = item?.item_id ?? '0';
                            vm.model.orderhead.delivery_postplace_id_and_name_street = item?.item_name ?? '';
                            vm.model.isDirty = true;
                        } else {
                            vm.onTypeaheadClear('delivery_postplace_id_and_name_street');
                        }
                        break;
                    case 'delivery_postplace_id_and_name':
                        if (item) {
                            vm.model.orderhead.delivery_postplace_id = item?.item_id ?? '0';
                            vm.model.orderhead.delivery_postplace_id_and_name = item?.item_name ?? '';
                            vm.model.isDirty = true;
                        } else {
                            vm.onTypeaheadClear('delivery_postplace_id_and_name');
                        }
                        break;
                    case 'stat_cust_name':
                        if (item) {
                            vm.model.orderhead.stat_cust_no = item?.item_id ?? '0';
                            vm.model.orderhead.stat_cust_name = item?.item_name ?? '';
                            vm.model.isDirty = true;

                            loadCustomerPersons();
                        } else {
                            vm.onTypeaheadClear('stat_cust_name');
                        }
                        break;
                    case 'invoice_cust_name':
                        if (item) {
                            vm.model.orderhead.invoice_cust_no = item?.item_id ?? '0';
                            vm.model.orderhead.invoice_cust_name = item?.item_name ?? '';
                            vm.model.isDirty = true;
                        } else {
                            vm.onTypeaheadClear('invoice_cust_name');
                        }
                        break;
                    case 'delivery_cust_name':
                        if (item) {
                            vm.model.orderhead.delivery_cust_no = item?.item_id ?? '0';
                            vm.model.orderhead.delivery_cust_name = item?.item_name ?? '';
                            vm.model.isDirty = true;
                        } else {
                            vm.onTypeaheadClear('delivery_cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'serviceobject_name':
                        vm.model.orderhead.serviceobject_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.serviceobject_keyno = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('serviceobject_name');
                        }
                        break;
                    case 'delivery_postplace_id_and_name_street':
                        vm.model.orderhead.delivery_postplace_id_and_name_street = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.delivery_postplace_id_street = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('delivery_postplace_id_and_name_street');
                        }
                        break;
                    case 'delivery_postplace_id_and_name':
                        vm.model.orderhead.delivery_postplace_id_and_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.delivery_postplace_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('delivery_postplace_id_and_name');
                        }
                        break;
                    case 'stat_cust_name':
                        vm.model.orderhead.stat_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.stat_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('stat_cust_name');
                        }
                        break;
                    case 'invoice_cust_name':
                        onInvoiceCustNameChanged(value, vm.model.orderhead.invoice_cust_name);

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.invoice_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('invoice_cust_name');
                        }
                        break;
                    case 'delivery_cust_name':
                        onDeliveryCustNameChanged(value, vm.model.orderhead.delivery_cust_name);

                        if (value) {
                            if (item?.item_id) {
                                vm.model.orderhead.delivery_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('delivery_cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'serviceobject_name':
                        vm.model.orderhead.serviceobject_keyno = '0';
                        vm.model.orderhead.serviceobject_name = '';
                        vm.model.isDirty = true;
                        break;
                    case 'delivery_postplace_id_and_name_street':
                        vm.model.orderhead.delivery_postplace_id_street = '0';
                        vm.model.orderhead.delivery_postplace_id_and_name_street = '';
                        vm.model.isDirty = true;
                        break;
                    case 'delivery_postplace_id_and_name':
                        vm.model.orderhead.delivery_postplace_id = '0';
                        vm.model.orderhead.delivery_postplace_id_and_name = '';
                        vm.model.isDirty = true;
                        break;
                    case 'stat_cust_name':
                        vm.model.orderhead.stat_cust_no = '0';
                        vm.model.orderhead.stat_cust_name = '';
                        vm.model.isDirty = true;

                        loadCustomerPersons();
                        break;
                    case 'invoice_cust_name':
                        vm.model.orderhead.invoice_cust_no = '0';
                        vm.model.orderhead.invoice_cust_name = '';
                        vm.model.isDirty = true;

                        setInvoiceCustInfo();
                        break;
                    case 'delivery_cust_name':
                        vm.model.orderhead.delivery_cust_no = '0';
                        vm.model.orderhead.delivery_cust_name = '';
                        vm.model.isDirty = true;

                        setDeliveryCustInfo();
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.generateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'Name: ' + items[step].item_name;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        orderHeadComponentService.generateItems({ orderline_keyno: items[step].item_id }).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;
                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;
                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            vm.generateCheck = function () {
                vm.model.generatingErrorCheck = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var allData = vm.model.orderlines;
                var currentData = [];

                angular.forEach(allData, function (item) {
                    if (angular.isDefined(item.item_id) && item.item_id > 0 && item.item_id < 9999999990) {
                        currentData.push(item);
                    }
                });

                vm.model.progressInfo.max = currentData.length;
                vm.generateProgress(currentData);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.generatingErrorCheck = false;
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.generatingErrorCheck = false;
                    }
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region FUNCTIONS

            vm.convertToOrder = function () {
                vm.model.lockedConvertOrder = true;
                ieScreenBlockService.start();

                orderHeadService.genericFunction(642, vm.model.orderhead).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        us.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedConvertOrder = false;
                            ieScreenBlockService.stop();
                        });
                    } else {
                        orderHeadService.genericFunction(890, vm.model.orderhead).then(function (data) {
                            ieScreenBlockService.stop();

                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedConvertOrder = false;
                                        }
                                    }]
                                });
                            } else {
                                loadOrderhead();
                                loadOrderlines();
                                vm.model.lockedConvertOrder = false;
                            }
                        });
                    }
                });
            };

            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                ieScreenBlockService.start();

                orderHeadService.loadOrderLineChangeMethodProvide(item.item_id, item.provide_method_id).then(function (data) {
                    ieScreenBlockService.stop();

                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        loadOrderlines();
                    }
                });
            };

            vm.openDialog = function () {
                let modalRef = $uibModal.open({
                    component: 'ttDialog',
                    size: 'lg',
                    resolve: vm.dialogOptions
                });

                modalRef.closed.then(() => {
                    orderHeadService.loadOrderDialogs($stateParams.order_internal_no).then((response) => {
                        angular.copy(response, vm.model.data.orderDialogs);
                    });
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderHeadService.saveObj(vm.model.orderhead).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    vm.model.isDirty = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        vm.model.isDirty = false;
                        loadOrderhead();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.cancelOrderhead = async function () {
                vm.model.lockedCancel = true;

                const cancellationModal = modalDynamicViewFactory.$create({
                    //url: '',
                    type: 'danger',
                    title: vm.translations.tt_cancel_orderhead_title,
                    cancel: 'all',
                    footer: true,
                    buttons: [{
                        label: vm.translations.tt_cancel_orderhead_abort,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.cancel();
                        }
                    }, {
                        label: vm.translations.tt_cancel_orderhead_confirm,
                        cssClass: 'btn-danger',
                        action: function (dialogItself) {
                            //console.log('Kansellering bekreftet');
                        }
                    }],
                    data: [
                        {
                            name: 'cancel_cause',
                            info: {
                                label: 'orderlinecancelcause_name',
                                type: 'ttselect'
                            },
                            data: {
                                list: vm.model.data.cancelCauses,
                                field: '',
                                id: 'item_parms',
                                name: 'item_name'
                            }
                        },
                        {
                            name: 'cancellation_note',
                            info: {
                                label: 'orderhead_cancellation_note',
                                placeholder: '',
                                type: 'elastic',
                                translate: false
                            },
                            data: {
                                field: ''
                            },
                            disabled: function () { return false; }
                        }
                    ]
                });

                try {
                    const response = await cancellationModal.show();

                    //console.log('Gjennomfører kansellering');

                    const cancellation_note = response.find(e => e.name === 'cancellation_note').data.field;
                    const orderlinecancelcause_keyno = response.find(e => e.name === 'cancel_cause').data.field;

                    const cancelResponse = await orderHeadService.genericFunction(3321, {
                        order_internal_no: $stateParams.order_internal_no,
                        orderlinecancelcause_keyno: orderlinecancelcause_keyno ?? 0,
                        cancellation_note: cancellation_note ?? ''
                    });

                    if (cancelResponse[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: cancelResponse[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                } catch (error) {
                    //console.log(error);
                } finally {
                    vm.model.lockedCancel = false;
                }
            }

            vm.cancelSelectedLines = async function () {
                vm.model.lockedCancel = true;

                const cancellationModal = modalDynamicViewFactory.$create({
                    type: 'danger',
                    title: vm.translations.tt_cancel_orderlines_title,
                    cancel: 'all',
                    footer: true,
                    buttons: [{
                        label: vm.translations.tt_cancel_orderhead_abort,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.cancel();
                        }
                    }, {
                        label: vm.translations.tt_cancel_orderhead_confirm,
                        cssClass: 'btn-danger',
                        action: function (dialogItself) {
                            //console.log('Kansellering bekreftet');
                        }
                    }],
                    data: [
                        {
                            name: 'cancel_cause',
                            info: {
                                label: 'orderlinecancelcause_name',
                                type: 'ttselect'
                            },
                            data: {
                                list: vm.model.data.cancelCauses,
                                field: '',
                                id: 'item_parms',
                                name: 'item_name'
                            }
                        },
                        {
                            name: 'cancellation_note',
                            info: {
                                label: 'orderhead_cancellation_note',
                                placeholder: '',
                                type: 'elastic',
                                translate: false,
                            },
                            data: {
                                field: '',
                            },
                            disabled: function () { return false; }
                        }
                    ]
                });

                try {
                    const response = await cancellationModal.show();


                    const orderlinecancelcause_keyno = response.find(e => e.name === 'cancel_cause').data.field;
                    const cancellation_note = response.find(e => e.name === 'cancellation_note').data.field;

                    const cancelResponse = await orderHeadService.genericFunction(3325, {
                        orderlines: vm.dynamicGrid.gridfunc.getIsSelectedRows().map(e => e.orderline_keyno),
                        orderlinecancelcause_keyno: orderlinecancelcause_keyno ?? 0,
                        cancellation_note: cancellation_note ?? ''
                    });
                    console.dir(vm.dynamicGrid.gridfunc.getIsSelectedRows().map(e => e.orderline_keyno));
                    if (cancelResponse[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: cancelResponse[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.dynamicGrid.gridfunc.rebind();
                    }
                } catch (error) {
                    //console.log(error);
                } finally {
                    vm.model.lockedCancel = false;
                }
            }

            vm.deleteOrderhead = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: vm.translations.confirm_title,
                    message: vm.translations.confirm_message,
                    okLabel: vm.translations.confirm_ok_label,
                    cancelLabel: vm.translations.confirm_cancel_label
                }).then(function () {
                    orderHeadService.genericFunction(747, { order_internal_no: $stateParams.order_internal_no }).then(function (response) {

                        if ((Object.hasOwn(response[0] ?? {}, 'errorcode') && response?.[0]?.errorcode !== '0') || (Object.hasOwn(response, 'iserror') && response.iserror !== false)) {
                            modalService.show({
                                type: 'warning',
                                title: vm.translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: vm.translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.cancelSearch = function () {
                vm.model.orderhead.stat_search_active = '0';
            }

            vm.deliverValues = function () {
                vm.model.lockedDelivery = true;

                orderHeadService.genericFunction(642, vm.model.orderhead).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        us.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelivery = false;
                            vm.model.isDirty = false;
                        });
                    } else {
                        vm.model.isDirty = false;

                        orderHeadService.deliverOrder({ order_internal_no: vm.model.orderhead.order_internal_no }).then(function () {
                            vm.model.lockedDelivery = false;
                            loadOrderhead();
                        });
                    }
                });
            };

            var setInvoiceCustInfo = function (info) {
                if (angular.isUndefined(info)) {
                    info = {
                        cust_no: 0,
                        cust_name: '',
                        cust_address: '',
                        cust_business_id: '',
                        cust_coop_id: ''
                    };
                }

                vm.model.orderhead.invoice_cust_no = info.cust_no;
                vm.model.orderhead.invoice_cust_name = info.cust_name;
                vm.model.orderhead.invoice_cust_address = info.cust_address;
                vm.model.orderhead.invoice_business_id = info.cust_business_id;
                vm.model.orderhead.invoice_coop_id = info.cust_coop_id;

                vm.model.isDirty = true;
            };

            var onInvoiceCustNameChanged = function (newValue, oldValue) {
                if (us.validateWatchValue(newValue, oldValue) !== true) return;

                if (angular.isUndefined(typeaheadService.selectListCustomers) || newValue === '') {
                    setInvoiceCustInfo();
                } else {
                    for (var i = 0; i < typeaheadService.selectListCustomers.length; i++) {
                        var data = typeaheadService.selectListCustomers[i];

                        if (data.item_name !== newValue)
                            continue;

                        vm.model.orderhead.invoice_cust_name = newValue;

                        vm.model.orderhead.invoice_cust_no = data.item_id;

                        orderHeadService.loadCustomerGetEdit(vm.model.orderhead.invoice_cust_no).then(function (response) {
                            setInvoiceCustInfo(response[0]);
                        });

                        break;
                    }
                }
            };

            var setDeliveryCustInfo = function (info) {
                if (angular.isUndefined(info)) {
                    info = {
                        cust_no: 0,
                        cust_name: '',
                        cust_address: '',
                        cust_telephone: '',
                        cust_email: '',
                        cust_business_id: '',
                        cust_coop_id: '',
                        postplace_id_street: '',
                        postplace_id: '',
                        cust_adr_street: '',
                        cust_adr_mail: '',
                        postplace_name_onlyname: '',
                        postplace_name_street_onlyname: ''
                    };
                }

                vm.model.orderhead.delivery_cust_no = info.cust_no;
                vm.model.orderhead.delivery_cust_name = info.cust_name;
                vm.model.orderhead.delivery_cust_address = info.cust_address;
                vm.model.orderhead.delivery_cust_phone = info.cust_telephone;
                vm.model.orderhead.delivery_email = info.cust_email;
                vm.model.orderhead.delivery_business_id = info.cust_business_id;
                vm.model.orderhead.delivery_coop_id = info.cust_coop_id;
                vm.model.orderhead.delivery_postplace_id_street = info.postplace_id_street;
                vm.model.orderhead.delivery_postplace_id = info.postplace_id;
                vm.model.orderhead.delivery_adr_street = info.cust_adr_street;
                vm.model.orderhead.delivery_adr_mail = info.cust_adr_mail;
                vm.model.orderhead.delivery_postplace_name = info.postplace_name_onlyname;
                vm.model.orderhead.delivery_postplace_name_street = info.postplace_name_street_onlyname;

                vm.model.isDirty = true;
            };

            var onDeliveryCustNameChanged = function (newValue, oldValue) {
                if (us.validateWatchValue(newValue, oldValue) !== true) return;

                if (vm.model.orderhead.delivery_cust_unreg !== '0') {
                    vm.model.orderhead.delivery_cust_name = newValue;
                } else {
                    if (angular.isUndefined(typeaheadService.selectListCustomers) || newValue === '') {
                        setDeliveryCustInfo();
                    } else {
                        for (var i = 0; i < typeaheadService.selectListCustomers.length; i++) {
                            var data = typeaheadService.selectListCustomers[i];

                            if (angular.isUndefined(data))
                                continue;
                            if (data.item_name !== newValue)
                                continue;

                            vm.model.orderhead.delivery_cust_name = newValue;

                            vm.model.orderhead.orderhead_delivery_cust_no = data.item_id;

                            if (us.validateParmsValue(vm.model.orderhead.orderhead_delivery_cust_no) !== true) return;

                            orderHeadService.loadCustomerGetEdit(vm.model.orderhead.orderhead_delivery_cust_no).then(function (response) {
                                setDeliveryCustInfo(response[0]);
                            });

                            break;
                        }
                    }
                }
            };

            // #endregion FUNCTIONS

            // #region DYNAMIC CALCULATION

            vm.dynamicCalculationCalculateOrder = function () {
                vm.model.orderheadCalculation.special_function = 'special_f_vartdal_db2';
                commonCallDynamicCalculationFunctions();
            };

            vm.dynamicCalculationUpdateOrderPrices = function () {
                vm.model.orderheadCalculation.special_function = 'special_update_orderprices';
                commonCallDynamicCalculationFunctions();
            };

            let commonCallDynamicCalculationFunctions = function () {
                if (vm.model.orderheadCalculation.selected_orderline_keyno === null) {
                    vm.model.orderheadCalculation.special_function = '';
                    return;
                }
                orderHeadComponentService.callDynamicSpecialFunctions(vm.model.orderheadCalculation).then(function (response) {
                    //console.log('response');
                    //console.dir(response[0]);
                }).finally(() => {
                    vm.model.orderheadCalculation.special_function = '';
                    loadOrderlineCalculation(vm.model.orderheadCalculation.selected_orderline_keyno);
                });
            };

            // #endregion DYNAMIC CALCULATION

            // #region DYNAMIC SPECIAL

            vm.dynamicSpecialConfirmRXOrder = function () {
                vm.model.orderhead.special_function = 'special_orderhead_construction_orderconfirm';
                commonCallDynamicSpecialFunctions();
            };

            vm.dynamicSpecialPicklist = function () {
                vm.model.orderhead.special_function = 'special_orderhead_construction_picklist';
                commonCallDynamicSpecialFunctions();
            };

            vm.dynamicSpecialSave = function () {
                vm.model.orderhead.special_function = 'special_orderhead_construction_save';
                commonCallDynamicSpecialFunctions();
            };

            let commonCallDynamicSpecialFunctions = function () {
                orderHeadComponentService.callDynamicSpecialFunctions(vm.model.orderhead).then(function (response) {
                    //console.log('response');
                    //console.dir(response[0]);
                }).finally(() => {
                    vm.model.orderhead.special_function = '';
                });
            };

            // #endregion DYNAMIC SPECIAL

            // #region CALCULATION BUTTONS

            vm.testButton = function () {
                console.log('testBtn');
            };

            // #endregion CALCULATION BUTTONS

            //var onDeliveryPostplaceIdAndNameChanged = function (newValue, oldValue) {
            //    vm.model.searchingDeliveryPostPlaceName = false;

            //    if (angular.isUndefined(newValue) === true) return;
            //    if (angular.isUndefined(oldValue) === true) return;
            //    if (newValue === oldValue) return;

            //    for (var i = 0; i < orderHeadService.selectPostPlaceList.length; i++) {
            //        var data = orderHeadService.selectPostPlaceList[i];

            //        if (angular.isUndefined(data))
            //            continue;
            //        if (data.item_name !== newValue)
            //            continue;

            //        vm.model.orderhead.delivery_postplace_id = data.postplace_id;
            //        vm.model.orderhead.delivery_postplace_name = data.postplace_name;

            //        break;
            //    }
            //};

            //var onDeliveryPostplaceIdAndNameStreetChanged = function (newValue, oldValue) {
            //    vm.model.searchingDeliveryPostPlaceStreet = false;

            //    if (angular.isUndefined(newValue) === true) return;
            //    if (angular.isUndefined(oldValue) === true) return;
            //    if (newValue === oldValue) return;

            //    for (var i = 0; i < orderHeadService.selectPostPlaceList.length; i++) {
            //        var data = orderHeadService.selectPostPlaceList[i];

            //        if (angular.isUndefined(data))
            //            continue;
            //        if (data.item_name !== newValue)
            //            continue;

            //        vm.model.orderhead.delivery_postplace_id_street = data.postplace_id;
            //        vm.model.orderhead.delivery_postplace_name_street = data.postplace_name;

            //        break;
            //    }
            //};

            var loadCustomerEdit = function (cust_no) {
                if (us.validateParmsValue(cust_no) !== true) return;
                economyService.getCustomerEdit({ cust_no: vm.model.orderhead.stat_cust_no }).then(function (result) {
                    vm.model.orderhead.stat_cust_name = result[0].cust_name;
                    vm.model.orderhead.stat_cust_address = result[0].cust_address;
                    vm.model.orderhead.invoice_cust_no = result[0].invoice_cust_no;
                    vm.model.orderhead.delivery_cust_no = result[0].delivery_cust_no;
                });
            };

            vm.onOrderlineChanged = function (orderlines) {
                if (vm.model.mconfig.records[4].isActive !== true) return;

                vm.model.mconfig.enabled = false;

                if (angular.isUndefined(orderlines))
                    return;
                if (orderlines.length < 1)
                    return;

                for (var i = 0; i < orderlines.length; i++) {
                    if (orderlines[i].item_type === 'info')
                        continue;

                    vm.model.mconfig.enabled = true;

                    break;
                }

                let orderlineNoUpdates = [];

                for (var i = 1; i < orderlines.length - 3; i++) {
                    let prevName = orderlines[i].item_name;
                    let new_orderline_no = String(i).padStart(4, '0');
                    orderlines[i].orderby = i;
                    orderlines[i].item_name = new_orderline_no + orderlines[i].item_name.slice(4);

                    if (prevName !== orderlines[i].item_name) {
                        orderlineNoUpdates.push({ id: orderlines[i].item_id, index: i });
                    }
                }

                angular.forEach(orderlineNoUpdates, function (update) {
                    orderHeadComponentService.updateOrderlineNo(update.id, update.index);
                });
            };

            vm.onRadioButtonsChanged = function (value, id) {
                if (id.startsWith('vm.') !== true)
                    return;

                eval(id + " = '" + value + "'");

                if (id === 'vm.model.mainView.selected') {
                    vm.model.orderhead.gb_row_1 = value;

                    rememberFunc('gb_row_1');
                }
            };

            vm.onOrderheadChanged = function (value, id) {
                if (id.startsWith('vm.') !== true)
                    return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'deliverymethod_no':
                        vm.model.orderhead.deliverymethod_no = value;
                        loadDeliveryMethod();
                        break;
                    case 'stockplace_id':
                        vm.model.orderhead.stockplace_id = value;
                        loadPickJobGroups();
                        break;
                    default:
                        us.setValueToVariable(vm, parts, value);
                }

                vm.model.isDirty = true;
            };

            vm.onOrderheadCalculationChanged = function (value, id) {
                if (id.startsWith('vm.') !== true)
                    return;

                var parts = id.split('.');

                us.setValueToVariable(vm, parts, value);
            };

            vm.checkboxChecker = function (value, id) {
                if (id.startsWith('vm.') !== true)
                    return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'customer_self_pickup':
                        vm.model.orderhead.customer_self_pickup = value;
                        if (vm.model.orderhead.deliver_to_customer_stock === '1') vm.model.orderhead.deliver_to_customer_stock = '0';
                        break;
                    case 'deliver_to_customer_stock':
                        vm.model.orderhead.deliver_to_customer_stock = value;
                        if (vm.model.orderhead.customer_self_pickup === '1') vm.model.orderhead.customer_self_pickup = '0';
                        break;
                    case 'special_construction06':
                        vm.model.orderhead.special_construction06 = value;
                        if (vm.model.orderhead.special_construction07 === '1') vm.model.orderhead.special_construction07 = '0';
                        break;
                    case 'special_construction07':
                        vm.model.orderhead.special_construction07 = value;
                        if (vm.model.orderhead.special_construction06 === '1') vm.model.orderhead.special_construction06 = '0';
                        break;
                }
            };

            vm.sveaCreatePaymentLink = function () {
                orderHeadComponentService.sveaCreatePaymentLink($stateParams.order_internal_no).then(function (response) {
                    modalService.show({
                        type: response[0].errorcode === '0' ? 'success' : 'danger',
                        title: vm.translations.tt_svea_payment_modal_title,
                        message: response[0].errormessage,
                        buttons: [{
                            label: vm.translations.tt_svea_payment_modal_confirm,
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                                loadOrderhead();
                            }
                        }]
                    });
                });
            };

            let loadCustomerPersons = function () {
                if (us.validateParmsValue(vm.model.orderhead.stat_cust_no) !== true) return;

                return orderHeadService.loadCustomerPersonsList(vm.model.orderhead.stat_cust_no).then(function (result) {
                    angular.copy(result, vm.model.data.customerPersons);
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'orderheadcalcs':
                        go({ order_internal_no: vm.model.orderhead.order_internal_no });
                        break;
                    case 'prepayment':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: vm.model.orderhead.order_internal_no,
                            prepayment_keyno: '0'
                        });
                        break;
                    case 'textileoladd':
                        go({
                            order_internal_no: vm.model.orderhead.order_internal_no,
                            orderline_keyno: '0',
                            textilemodel_id: '-'
                        });
                        break;
                    case 'printpreview':
                        orderHeadService.genericFunction(642, vm.model.orderhead).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                us.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning');
                            } else {
                                go({
                                    argtype: 'order_internal_no',
                                    argvalue: vm.model.orderhead.order_internal_no,
                                    report_keyno: vm.model.orderhead.orderconfirm_reportdef_keyno,
                                    printer_keyno: '0'
                                });
                            }
                        });
                        break;
                    case 'createConsignment':
                        if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                        return orderHeadService.scanConsignment({ barcode: 'oi' + vm.model.orderhead.order_internal_no }).then(function (response) {
                            state = response[0].webpagename;

                            go(response[0].parms);
                        });
                        break;
                    case 'consignmentAssembly':
                        state = vm.model.orderhead.consignmentassembly_webpage_name;

                        go(vm.model.orderhead.consignmentassembly_parms);
                        break;
                    case 'orderline':
                        if (us.validateParmsValue(vm.model.orderhead.order_internal_no) !== true) return;

                        orderHeadService.loadOrderHeadLockStatusWs(vm.model.orderhead.order_internal_no).then(function (data) {
                            if (data[0].lockstatusid === '1') {
                                modalService.show({
                                    type: 'warning',
                                    title: vm.translations.error,
                                    message: data[0].lockstatusinfo,
                                    buttons: [{
                                        label: vm.translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else if (data[0].lockstatusid === '2') {
                                modalService.show({
                                    type: 'warning',
                                    title: vm.translations.error,
                                    message: data[0].lockstatusinfo,
                                    buttons: [{
                                        label: vm.translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            go({
                                                order_internal_no: vm.model.orderhead.order_internal_no,
                                                orderline_keyno: '0'
                                            });
                                        }
                                    }]
                                });
                            } else {
                                go({
                                    order_internal_no: vm.model.orderhead.order_internal_no,
                                    orderline_keyno: '0'
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onSpecialOrderHeadChanged = function (value, id) {
                switch (id) {
                    case 'stat_cust_no':
                        if (us.validateWatchValue(value, vm.model.orderhead.stat_cust_no) !== true) return;

                        vm.model.orderhead.stat_cust_no = value;

                        if (us.validateParmsValue(vm.model.orderhead.stat_cust_no, true) !== true) return;

                        loadCustomerPersons();
                        loadCustomerEdit(vm.model.orderhead.stat_cust_no);
                        break;
                    default:
                        us.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #endregion WATCH FUNCTIONS

            // #region TRANSLATION

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATION

            //function prepareLists() {
            //    let deferred = $q.defer();
            //    let promises = [];

            //    promises.push(bonusTypesOverviewService.getCustomersKeynameList());
            //    promises.push(bonusTypesOverviewService.getOrdersKeynameList());

            //    $q.all(promises).then(function (response) {
            //        angular.copy(response[0], vm.customers.list);
            //        angular.copy(response[1], vm.orders.list);

            //        deferred.resolve(response);
            //    }, function (error) {
            //        console.log('error! error! error! error! error!');

            //        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
            //            console.log(error.data.message);

            //            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
            //                console.log(error.data.data.message);
            //            }
            //        } else {
            //            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
            //                console.log(error.data.data.message);
            //            }
            //        }

            //        console.dir(error);

            //        deferred.reject(error);
            //    });

            //    return deferred.promise;
            //}

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                //prepareLists().then(function (data) {
                //    //console.dir(data);
                //    vm.gridReady = true;
                //});

                //vm.gridReady = true;

                vm.model.mainView.lessButtons.push({ id: 'orderhead_dynamic_special', label: 'orderhead_dynamic_special', color: 'primary', onClick: { click: setMainView, id: 'orderhead_dynamic_special' }, disabled: 'hidden' });
                vm.model.mainView.moreButtons.push({ id: 'orderhead_dynamic_special', label: 'orderhead_dynamic_special', color: 'primary', onClick: { click: setMainView, id: 'orderhead_dynamic_special' }, disabled: 'hidden' });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
