import { Component, forwardRef } from '@angular/core';
import { DividerGenericComponent } from '../divider-generic.component.';

/**
 * Represents dividiers between columns.
 */
@Component({
    selector: 'tt-divider-columns',
    templateUrl: './divider-columns.component.html',
    styleUrls: ['./divider-columns.component.css'],
    providers: [{ provide: DividerGenericComponent, useExisting: forwardRef(() => DividerColumnsComponent) }],
})
export class DividerColumnsComponent extends DividerGenericComponent {
    override resizeCursor: 'row-resize' | 'col-resize' = 'col-resize';

    public override sliderClass: string = 'tt-divider__gutter--column';

    override getPointerPosition(event: PointerEvent): number {
        return event.pageX;
    }

    override getDividerSize(): number {
        if (this.divider) {
            const elementRef = this.divider.nativeElement;
            let width = elementRef.offsetWidth;
            let style = getComputedStyle(elementRef);

            return width - (parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) + parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth));
        }
        return 0;
    }

    override getOuterSize(elementRef: HTMLElement) {
        let width = elementRef.getBoundingClientRect().width || elementRef.offsetWidth;
        const style = getComputedStyle(elementRef);

        return width + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
    }
}
