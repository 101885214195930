import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CoreComponentService, TextAlign } from '@app/core/services/core-component.service';
import { GotoParms, StateService } from '@app/core/services/state.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
    selector: 'tt-button-goto',
    templateUrl: './button-goto.component.html',
    styleUrls: ['./button-goto.component.css'],
})
export class ButtonGotoComponent<TType extends any> extends ButtonComponent {
    public override ttType: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'link' = 'link';

    public override ttIcon: string = 'far fa-arrow-right';

    public override ttIconAlign: 'left' | 'right' = 'left';

    public override ttTextAlign?: TextAlign | undefined = 'left';

    /**
     * Event emitted when link is clicked, but only if `ttGoto` is not provided and `ttDisabled` is `false`.
     */
    @Output()
    public override ttClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    /**
     * The name of the route to navigate. \
     * **NB.** only the name, exclude any parameters.
     */
    @Input()
    public ttGoto: string = '';

    @Input()
    public ttGotoParms?: GotoParms<TType>;

    public statePath = '';

    constructor(private state: StateService, private utility: UtilityService, layout: LayoutService, translate: TranslateService, coreComponents: CoreComponentService) {
        super(layout, translate, coreComponents);
    }

    onClick(event: MouseEvent) {
        event.preventDefault();

        if (this.ttDisabled) {
            // do nothing
        } else if (!!this.ttGoto && typeof this.ttGoto === 'string') {
            if (this.utility.isModifierKeyPressed(event)) {
                this.state.newTab(this.ttGoto, this.ttGotoParms);
            } else {
                this.state.go(this.ttGoto, this.ttGotoParms);
            }
        } else {
            this.ttClick.emit(event);
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes['ttGoto']) {
            this.statePath = this.utility.generateStatePath(this.ttGoto, this.ttGotoParms);
        }
    }
}

// if (angular.isDefined(vm.ttGotoParms)) {
//     if (e.ctrlKey) {
//         stateService.newTab(vm.ttGoto, vm.ttGotoParms);
//     } else {
//         stateService.go(vm.ttGoto, vm.ttGotoParms);
//     }
// } else {
//     if (e.ctrlKey) {
//         stateService.newTab(vm.ttGoto);
//     } else {
//         stateService.go(vm.ttGoto);
//     }
// }
