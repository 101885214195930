<tt-page *ngIf="!!this.get">
    <tt-page-heading ttModel="p2_m_accountingbs" ttTranslate></tt-page-heading>
    <tt-card ttClass="form-field-group">
        <tt-select
            ttRememberId="w_accountingprocessing.businessco_no"
            ttLabel="businessco"
            [(ttModel)]="get.businessco_no"
            (ttModelChange)="loadAccountingYears(); reloadGridData()"
            [ttData]="companies"
            ttDataId="businessco_no"
            ttDataName="company_name"
        ></tt-select>
        <tt-select
            ttRememberId="w_accountingbs.acreportlayout_keyno"
            ttLabel="acreportlayout"
            [(ttModel)]="get.acreportlayout_keyno"
            (ttModelChange)="reloadGridData()"
            [ttData]="reportLayouts"
            ttDataId="item_id"
            ttDataName="item_name"
        ></tt-select>
        <tt-checkbox ttRememberId="w_accountingbs.show_accounts" ttLabel="show_accounts" [(ttModel)]="get.show_accounts" (ttModelChange)="reloadGridData()"></tt-checkbox>
        <div>
            <tt-checkbox ttRememberId="w_accountingbs.show_12months" ttLabel="show_12months" [(ttModel)]="get.show_12months" (ttModelChange)="reloadGridData()"></tt-checkbox>
            <tt-date-range
                [hidden]="get.show_12months === '1'"
                ttRememberId="w_accountingbs"
                [(ttStartDate)]="get.date_fom"
                [(ttEndDate)]="get.date_tom"
                [(ttInterval)]="get.dateselector_index"
                (ttModelChange)="get.date_fomtom = get.date_fom + get.date_tom; reloadGridData()"
            ></tt-date-range>
            <tt-select
                [hidden]="get.show_12months === '0'"
                ttRememberId="w_accountingbs.show_12months"
                ttLabel="accounting_year"
                [(ttModel)]="get.accounting_year"
                (ttModelChange)="reloadGridData()"
                [ttData]="accountingYears"
                ttDataId="transyear"
                ttDataName="transyear_name"
            ></tt-select>
        </div>
    </tt-card>
    <tt-grid [hidden]="get.show_12months === '0'" *ngIf="!!this.grid12Months" class="flex-1" [ttOptions]="grid12Months"></tt-grid>
    <tt-grid [hidden]="get.show_12months === '1'" *ngIf="!!this.grid" class="flex-1" [ttOptions]="grid"></tt-grid>
</tt-page>
