(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2StatSourceEdit', {
        templateUrl: 'views/components/views/p2StatSourceEdit/p2StatSourceEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2StatSourceEditService', 'tailorAccessesService', function ($stateParams, stateService, p2StatSourceEditService, tailorAccessesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2StatSourceKeyno = $stateParams.p2_statsource_keyno;

            vm.model = {
                statSourceHeadingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.word_id_heading }) }
                ],
                statSourceDescriptionButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.word_id_description }) }
                ],
                tailorAccessButtons1: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('tailoraccesses') }
                ],
                tailorAccessButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.tailoraccess_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('tailoraccesses') }
                ],
                edit: {},
                selectListTailorAccesses: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                p2StatSourceEditService.getEdit({ p2_statsource_keyno: p2StatSourceKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadTailorAccesses = function () {
                tailorAccessesService.listTailorAccesses().then(function (list) {
                    angular.copy(list, vm.model.selectListTailorAccesses);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2StatSourceEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadTailorAccesses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
