(function () {
    'use strict';

    angular.module("imApp").factory("p2ImportProcsService", ['$ihttp', function ($ihttp) {
        let service = {
            listImportProcedures: function (parms, config) {
                return $ihttp.post({
                    method: 877,
                    parameters: parms || {},
                    config: config || {}
                });
            }
        };

        return service;
    }]);
})();
