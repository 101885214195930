import { Component } from '@angular/core';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-my-page',
    templateUrl: './my-page.component.html',
    styleUrls: ['./my-page.component.css'],
})
export class MyPageComponent {
    /**
     * The name of the my page webpagemenu to load into generic webpagemnu component.
     */
    public webpageName = '';

    constructor(private state: StateService) {
        this.webpageName = this.state.getCurrentName();
    }
}
