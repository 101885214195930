(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderHeadCopy', {
        templateUrl: 'views/components/views/orderHeadCopy/orderHeadCopy.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'logisticService', 'orderHeadCopyService', 'typeaheadService', function ($stateParams, stateService, utilityService, translateService, modalService, logisticService, orderHeadCopyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                copy: {},
                selectListOrderTypes: [],
                copying: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadCopy() {
                vm.model.copy = (await orderHeadCopyService.getCopy({ order_internal_no: orderInternalNo }))[0];

                loadOrderTypes();
            }

            async function loadOrderTypes() {
                if (utilityService.validateParmsValue(vm.model.copy.ordertype_id_new) !== true) return;

                vm.model.selectListOrderTypes = (await logisticService.listOrderTypes({ ordertype_id: vm.model.copy.ordertype_id_new }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH FUNCTION

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'stat_cust_name':
                        return typeaheadService.searchCustomer({
                            cust_name: value || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'C',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.onClearTypeahead = function (id) {
                switch (id) {
                    case 'stat_cust_name':
                        vm.model.copy.stat_cust_no = '0';
                        vm.model.copy.stat_cust_name = '';
                        break;
                    default:
                        break;
                }
            };

            vm.onChangedTypeahead = function (value, item, id) {
                switch (id) {
                    case 'stat_cust_name':
                        vm.model.copy.stat_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.copy.stat_cust_no = item.item_id;
                            }
                        } else {
                            vm.onClearTypeahead(id);
                        }
                        break;
                    default:
                        break;
                }
            };

            vm.onSelectedTypeahead = function (item, id) {
                switch (id) {
                    case 'stat_cust_name':
                        if (item) {
                            vm.model.copy.stat_cust_no = item?.item_id ?? '0';
                            vm.model.copy.stat_cust_name = item?.item_name ?? '';
                        } else {
                            vm.onClearTypeahead(id);
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.copyChanges = function () {
                vm.model.copying = true;

                orderHeadCopyService.copyOrder(vm.model.copy).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.copying = false;

                                    if (response[0].follow > 0 && response[0].order_internal_no_new > 0) {
                                        stateService.go('orderhead', { order_internal_no: response[0].order_internal_no_new });
                                    }
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.copying = false;

                        if (response[0].follow > 0 && response[0].order_internal_no_new > 0) {
                            stateService.go('orderhead', { order_internal_no: response[0].order_internal_no_new });
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadCopy();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
