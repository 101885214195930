(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectNewDoctype', {
        templateUrl: 'views/components/views/selectNewDoctype/selectNewDoctype.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectNewDoctypeService', function ($stateParams, selectNewDoctypeService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                itemsListDocarcTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadDocarcTypes = function () {
                selectNewDoctypeService.listDocarcTypes({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListDocarcTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadDocarcTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
