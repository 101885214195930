<!-- <tt-button
    class="col-xs-12 sp-0"
    [ttBadge]="ttBadge"
    [ttButtonType]="ttButtonType"
    [ttDisabled]="ttDisabled"
    [ttIcon]="ttIcon"
    [ttIconAlign]="ttIconAlign"
    [ttIconPullRight]="ttIconPullRight"
    [ttLoading]="ttLoading"
    [ttLoadingText]="ttLoadingText"
    [ttTranslateLoadingText]="ttTranslateLoadingText"
    [ttPrinter]="ttPrinter"
    [ttSpin]="ttSpin"
    [ttStyle]="ttStyle"
    [ttText]="ttText"
    [ttTranslateText]="ttTranslateText"
    [ttTooltipText]="ttTooltipText"
    [ttTranslateTooltipText]="ttTranslateTooltipText"
    [ttTextAlign]="ttTextAlign"
    [ttType]="ttType"
></tt-button> -->

<ng-container *ngIf="!ttLoading">
    <a
        class="col-xs-12 sp-0 tt-button"
        [href]="statePath"
        [attr.title]="translations['tooltiptext']"
        [attr.type]="ttButtonType"
        (click)="onClick($event)"
        [ngClass]="'tt-button--' + ttType + ' ' + (!!ttPrinter ? 'tt-button--printer' : '') + ' ' + (ttDisabled ? 'tt-button--disabled' : '')"
        [ngStyle]="style['button']"
    >
        <ng-container *ngIf="ttIconAlign === 'left'" [ngTemplateOutlet]="icon"></ng-container>
        <span *ngIf="!!ttPrinter">{{ ttPrinter }}</span>
        <span *ngIf="!!ttText && !ttPrinter">{{ translations["text"] }}</span>
        <span *ngIf="!!ttBadge" class="tt-button__badge" [ngStyle]="style['badge']">{{ ttBadge }}</span>
        <ng-container *ngIf="ttIconAlign === 'right'" [ngTemplateOutlet]="icon"></ng-container>
    </a>
    <!-- <button
        class="col-xs-12 sp-0 tt-button"
        [attr.title]="translations['tooltiptext']"
        [attr.type]="ttButtonType"
        (click)="onClick($event)"
        [ngClass]="'tt-button--' + ttType + ' ' + (!!ttPrinter ? 'tt-button--printer' : '') + ' ' + (ttDisabled ? 'tt-button--disabled' : '')"
        [ngStyle]="style['button']"
    >
        <ng-container *ngIf="ttIconAlign === 'left'" [ngTemplateOutlet]="icon"></ng-container>
        <span *ngIf="!!ttPrinter">{{ ttPrinter }}</span>
        <span *ngIf="!!ttText && !ttPrinter">{{ translations["text"] }}</span>
        <span *ngIf="!!ttBadge" class="tt-button__badge" [ngStyle]="style['badge']">{{ ttBadge }}</span>
        <ng-container *ngIf="ttIconAlign === 'right'" [ngTemplateOutlet]="icon"></ng-container>
    </button> -->
</ng-container>

<ng-container *ngIf="ttLoading">
    <button [attr.title]="translations['tooltip']" class="col-xs-12 sp-0 tt-button" [ngClass]="'tt-button--' + ttType" [ngStyle]="style['lockedButton']" disabled>
        <ng-conatiner *ngIf="ttIconAlign === 'left'" [ngTemplateOutlet]="loadingIcon"></ng-conatiner>
        <span>{{ translations["loadingtext"] }}</span>
        <ng-container *ngIf="ttIconAlign === 'right'" [ngTemplateOutlet]="loadingIcon"></ng-container>
    </button>
</ng-container>

<ng-template #icon>
    <span class="tt-button__icon" [ngClass]="ttIcon + (ttSpin === true ? ' fa-spin' : '') + (ttIconPullRight === true ? ' tt-button__icon--pull-right' : '')"></span>
</ng-template>

<ng-template #loadingIcon>
    <span
        *ngIf="ttIconAlign === 'right'"
        class="tt-button__icon"
        [ngClass]="'fa-fw fas fa-spin fa-spinner-third' + (ttIconPullRight === true ? ' tt-button__icon--pull-right' : '')"
        [ngStyle]="style['icon']"
    ></span>
</ng-template>
