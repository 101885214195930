(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('seasonality', {
        templateUrl: 'views/components/views/seasonality/seasonality.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'seasonalityService', function ($stateParams, stateService, utilityService, seasonalityService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let seasonalityKeyno = $stateParams.seasonality_keyno;
    		
            vm.model = {
                edit: {},
                inputListMonths: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await seasonalityService.getEdit({ seasonality_keyno: seasonalityKeyno }))[0];

                loadMonths();
            }
            
            async function loadMonths() {
                if (utilityService.validateParmsValue(vm.model.edit.seasonality_keyno) !== true) return;

                vm.model.inputListMonths = (await seasonalityService.listMonths({ seasonality_keyno: vm.model.edit.seasonality_keyno }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region RECALCULATION FUNTION

            function recalcTotal() {
                let total = 0;

                vm.model.inputListMonths.forEach((item) => {
                    total += utilityService.parseNumber(item.pst);
                }); 

                vm.model.edit.total = total;
            }

            // #endregion RECALCULATION FUNTION

            // #region BUTTON SAVE FUNCTION

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                let saveObject = vm.model.edit;
                saveObject.records = vm.model.inputListMonths;

                seasonalityService.saveObj(saveObject).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlurMonths = function (item) {
                if (utilityService.validateParmsValue(item.pst, true) !== true) return;

                recalcTotal();
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
