import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { WidgetService } from './widget.service';
import { Item } from '@app/core/components/listbox/listbox.component';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { SearchItem } from '@app/core/components/search/search.component';
import { GridOptions } from '@app/core/components/grid/grid.types';
import { IdportenService } from '../../../services/idporten.service';
import { PdfService } from '@app/services/pdf.service';
import { Base64UrlService } from '@app/services/base64url.service';
import { IRowNode } from '@ag-grid-community/core';
import { StateService } from '@app/core/services/state.service';
import { Grid } from '@app/core/components/grid/grid.model';
import { WidgetTextConfig, WidgetTextLine } from './widget-text/widget-text.component';
import { WidgetConfig } from './dashboard/dashboard.component';
import { ListButton } from '@app/core/components/list/list.component';
import { Tab } from '@app/core/components/tabs/tabs.component';
import { DateRangeInterval } from '@app/core/components/date-range/date-range.component';
import { ToastService } from '@app/core/services/toast.service';

@Component({
    selector: 'tt-jlr-test',
    templateUrl: './jlr-test.component.html',
    styleUrls: ['./jlr-test.component.css'],
})
export class JlrTestComponent {
    loginIdPorten() {
        this.idporten.login();
    }

    filter = '';

    logoutIdPorten() {
        this.idporten.logout();
    }

    timerFinish = false;

    getUser() {
        console.dir(this.idporten.getUser()?.access_token);
    }

    dateModel = {
        date_fom: '2024-10-31',
        date_tom: '2025-01-02',
        dateselector_index: 'week',
    };

    dateString = '2025-01-01 +inval';

    onDateStringChange(event: string) {
        console.log('onDateStringChange');

        this.dateString = event + ' nononoinvalid';
    }

    date = '2024-01-02 08:30';
    columns = 16;
    rows = 12;
    rowHeight = 8;
    customerSearchtext = '';
    textValue = 'hel';
    text1 = 1000;
    text2 = 1000;
    text3 = 1000;
    text4 = 1000;
    editMode = false;
    check = '1';
    check2 = 'true';
    textarea = 'hei';
    selectedTab = '';

    files: File[] = [new File([], 'test.jpg'), new File([], 'test2.jpg'), new File([], 'test4.jpg')];

    onTabChange(event: string) {
        console.log('changed to: ', event);
    }
    documents: Item[] = [
        {
            item_id: '130800',
            item_name: '2024-06-13 test 11 ()',
            item_state: 'emailview',
            item_parms: '130800',
            item_path: 'emailview/130800',
            custact_no: '0',
            orderby: '13',
            docfilename: '757900.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-06-13 test 11 ()',
        },
        {
            item_id: '130600',
            item_name: '2024-05-05 Email mottatt 2024-05-05 17:32 fra Hege Lauvik: Re: Anleggsregister AVA  ()',
            item_state: 'emailview',
            item_parms: '130600',
            item_path: 'emailview/130600',
            custact_no: '623744',
            orderby: '14',
            docfilename: '756700.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-05-05 Email mottatt 2024-05-05 17:32 fra Hege Lauvik: Re: Anleggsregister AVA  ()',
        },
        {
            item_id: '755300',
            item_name: '2024-03-27 Eko_1.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/755300',
            item_path: 'docarc/cust_no/15230/755300',
            custact_no: '623700',
            orderby: '15',
            docfilename: '2024-03-27_12_18_39_954_Eko_1.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-03-27 Eko_1.pdf (PDF)',
        },
        {
            item_id: '123100',
            item_name: '2024-01-18 Bekreftelse på kansellering av ordre [#insoftmpsdev_oi635300] ()',
            item_state: 'emailview',
            item_parms: '123100',
            item_path: 'emailview/123100',
            custact_no: '0',
            orderby: '16',
            docfilename: '745300.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-01-18 Bekreftelse på kansellering av ordre [#insoftmpsdev_oi635300] ()',
        },
        {
            item_id: '123500',
            item_name: '2024-01-18 Faktura nr 140900 from Company AS ()',
            item_state: 'emailview',
            item_parms: '123500',
            item_path: 'emailview/123500',
            custact_no: '0',
            orderby: '17',
            docfilename: '745700.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-01-18 Faktura nr 140900 from Company AS ()',
        },
        {
            item_id: '743900',
            item_name: '2024-01-10 11102023134429_jgh.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/743900',
            item_path: 'docarc/cust_no/15230/743900',
            custact_no: '598100',
            orderby: '18',
            docfilename: '11102023134429_jgh.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '46ED5EA368455C9A0867BBC3E1A9B87CDF5FB6D0415E7CFF64CB7E152DC8FF37',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2024-01-10 11102023134429_jgh.pdf (PDF)',
        },
        {
            item_id: '740200',
            item_name: '2023-12-26 2024-01-03_12_21_53_250_email_PDF-dokument.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/740200',
            item_path: 'docarc/cust_no/15230/740200',
            custact_no: '623607',
            orderby: '19',
            docfilename: '2024-01-03_12_21_53_250_email_PDF-dokument.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-12-26 2024-01-03_12_21_53_250_email_PDF-dokument.pdf (PDF)',
        },
        {
            item_id: '740300',
            item_name: '2023-12-26 2024-01-03_12_21_53_251_email_7-50-4.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/740300',
            item_path: 'docarc/cust_no/15230/740300',
            custact_no: '623607',
            orderby: '20',
            docfilename: '2024-01-03_12_21_53_251_email_7-50-4.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-12-26 2024-01-03_12_21_53_251_email_7-50-4.pdf (PDF)',
        },
        {
            item_id: '121100',
            item_name: '2023-12-26 Email mottatt 2023-12-26 09:30 fra Stig Stuen: Prosjektregnskap ()',
            item_state: 'emailview',
            item_parms: '121100',
            item_path: 'emailview/121100',
            custact_no: '623607',
            orderby: '21',
            docfilename: '740100.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-12-26 Email mottatt 2023-12-26 09:30 fra Stig Stuen: Prosjektregnskap ()',
        },
        {
            item_id: '727800',
            item_name: '2023-12-11 2023-12-11_14_25_39_883_1215652_standard_ehf_invoice_generated.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/727800',
            item_path: 'docarc/cust_no/15230/727800',
            custact_no: '623552',
            orderby: '22',
            docfilename: '2023-12-11_14_25_39_883_1215652_standard_ehf_invoice_generated.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-12-11 2023-12-11_14_25_39_883_1215652_standard_ehf_invoice_generated.pdf (PDF)',
        },
        {
            item_id: '727700',
            item_name: '2023-12-11 2023-12-11_14_25_39_883_1215652.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/727700',
            item_path: 'docarc/cust_no/15230/727700',
            custact_no: '623552',
            orderby: '23',
            docfilename: '2023-12-11_14_25_39_883_1215652.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-12-11 2023-12-11_14_25_39_883_1215652.pdf (PDF)',
        },
        {
            item_id: '116600',
            item_name: '2023-11-23 Takk for din ordre [#insoftmpsdev_oi651600] ()',
            item_state: 'emailview',
            item_parms: '116600',
            item_path: 'emailview/116600',
            custact_no: '0',
            orderby: '24',
            docfilename: '724800.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-11-23 Takk for din ordre [#insoftmpsdev_oi651600] ()',
        },
        {
            item_id: '94400',
            item_name: '2023-10-13 Order 604600 from Company AS  ()',
            item_state: 'emailview',
            item_parms: '94400',
            item_path: 'emailview/94400',
            custact_no: '0',
            orderby: '59',
            docfilename: '695300.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-13 Order 604600 from Company AS  ()',
        },
        {
            item_id: '94500',
            item_name: '2023-10-13 Order 604600 from Company AS  ()',
            item_state: 'emailview',
            item_parms: '94500',
            item_path: 'emailview/94500',
            custact_no: '0',
            orderby: '60',
            docfilename: '695400.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-13 Order 604600 from Company AS  ()',
        },
        {
            item_id: '695500',
            item_name: '2023-10-13 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/695500',
            item_path: 'docarc/cust_no/15230/695500',
            custact_no: '0',
            orderby: '61',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-13 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '695800',
            item_name: '2023-10-13 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/695800',
            item_path: 'docarc/cust_no/15230/695800',
            custact_no: '0',
            orderby: '62',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-13 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '693800',
            item_name: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/693800',
            item_path: 'docarc/cust_no/15230/693800',
            custact_no: '0',
            orderby: '63',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '694000',
            item_name: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/694000',
            item_path: 'docarc/cust_no/15230/694000',
            custact_no: '0',
            orderby: '64',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '694200',
            item_name: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/694200',
            item_path: 'docarc/cust_no/15230/694200',
            custact_no: '0',
            orderby: '65',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '694400',
            item_name: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/694400',
            item_path: 'docarc/cust_no/15230/694400',
            custact_no: '0',
            orderby: '66',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-11 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '93000',
            item_name: '2023-10-10 Order 604600 from Company AS  ()',
            item_state: 'emailview',
            item_parms: '93000',
            item_path: 'emailview/93000',
            custact_no: '0',
            orderby: '67',
            docfilename: '690700.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-10 Order 604600 from Company AS  ()',
        },
        {
            item_id: '692600',
            item_name: '2023-10-10 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/692600',
            item_path: 'docarc/cust_no/15230/692600',
            custact_no: '0',
            orderby: '68',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-10 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '690000',
            item_name: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/690000',
            item_path: 'docarc/cust_no/15230/690000',
            custact_no: '0',
            orderby: '69',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '689400',
            item_name: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/689400',
            item_path: 'docarc/cust_no/15230/689400',
            custact_no: '0',
            orderby: '70',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '689600',
            item_name: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/689600',
            item_path: 'docarc/cust_no/15230/689600',
            custact_no: '0',
            orderby: '71',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '689800',
            item_name: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/689800',
            item_path: 'docarc/cust_no/15230/689800',
            custact_no: '0',
            orderby: '72',
            docfilename: 'Ordrebekreftelse.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '451F70845D48D5303A209F45F7A070875A1B562E6D188986C67D31E69B436042',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-10-05 Ordrebekreftelse.pdf (PDF)',
        },
        {
            item_id: '686800',
            item_name: '2023-09-15 Eko_1.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/686800',
            item_path: 'docarc/cust_no/15230/686800',
            custact_no: '623378',
            orderby: '73',
            docfilename: 'Eko_1.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: 'C00924A6B854240DE95F91A653E153797548FEC9B26847B66753A1D156D155F0',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-09-15 Eko_1.pdf (PDF)',
        },
        {
            item_id: '685800',
            item_name: '2023-08-22 20230822T175643_Klipe_ProjectNo=11500_CustNo=15230_PUserName=Jan-Gunnar_Hole.gpx ()',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/685800',
            item_path: 'docarc/cust_no/15230/685800',
            custact_no: '622271',
            orderby: '74',
            docfilename: '20230822T175643 - Klipe - ProjectNo=11500 - CustNo=15230 - PUserName=Jan-Gunnar Hole.gpx',
            extension: 'gpx',
            oleapplication_id: '',
            item_glyphicon: 'glyphicon-unchecked fas-question-square',
            item_glyphicon_color: 'black blue',
            checksum: 'A6BAB1ACF0739A35D9A2180821F654304A2763C2CE20CBDE21B6B7B411744C3C',
            item_glyphicon_2: 'glyphicon-check fas-question-square',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-08-22 20230822T175643_Klipe_ProjectNo=11500_CustNo=15230_PUserName=Jan-Gunnar_Hole.gpx ()',
        },
        {
            item_id: '683800',
            item_name: '2023-04-14 Test EUR TEST FAKT 704808 - Copy.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/683800',
            item_path: 'docarc/cust_no/15230/683800',
            custact_no: '623206',
            orderby: '75',
            docfilename: 'Test EUR TEST FAKT 704808 - Copy.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '05006D9E99FFA9F8781FF84D587976D57C6657218D9BA116D6C39671883D54EC',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2023-04-14 Test EUR TEST FAKT 704808 - Copy.pdf (PDF)',
        },
        {
            item_id: '675300',
            item_name: '2022-06-16  149800 til Ekornes ASA (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/675300',
            item_path: 'docarc/cust_no/15230/675300',
            custact_no: '0',
            orderby: '76',
            docfilename: 'docarc_73272486891d4afdb7022f20e6915eac.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2022-06-16  149800 til Ekornes ASA (PDF)',
        },
        {
            item_id: '675100',
            item_name: '2022-06-15 defaultinvoicesingle_e77989ed21_name 149800 til Ekornes ASA, sendt som EHF. (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/675100',
            item_path: 'docarc/cust_no/15230/675100',
            custact_no: '0',
            orderby: '77',
            docfilename: 'docarc_f75559f6f07f4174acedc59a9ebf8df4.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2022-06-15 defaultinvoicesingle_e77989ed21_name 149800 til Ekornes ASA, sendt som EHF. (PDF)',
        },
        {
            item_id: '675200',
            item_name: '2022-06-15 defaultinvoicesingle_e77989ed21_name 149800 til Ekornes ASA, sendt som EHF. (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/675200',
            item_path: 'docarc/cust_no/15230/675200',
            custact_no: '0',
            orderby: '78',
            docfilename: 'docarc_7ec20017421e43b4ad4b15197ba27dee.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2022-06-15 defaultinvoicesingle_e77989ed21_name 149800 til Ekornes ASA, sendt som EHF. (PDF)',
        },
        {
            item_id: '724700',
            item_name: '2021-12-16 2023-11-23_10_27_58_071_email_1647448.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/724700',
            item_path: 'docarc/cust_no/15230/724700',
            custact_no: '623526',
            orderby: '79',
            docfilename: '2023-11-23_10_27_58_071_email_1647448.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-12-16 2023-11-23_10_27_58_071_email_1647448.pdf (PDF)',
        },
        {
            item_id: '116500',
            item_name: '2021-12-16 Email mottatt 2021-12-16 15:36 fra Torhild Ekornes Brandal: Ordrebekreftelse 1647448 ()',
            item_state: 'emailview',
            item_parms: '116500',
            item_path: 'emailview/116500',
            custact_no: '623526',
            orderby: '80',
            docfilename: '724600.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-12-16 Email mottatt 2021-12-16 15:36 fra Torhild Ekornes Brandal: Ordrebekreftelse 1647448 ()',
        },
        {
            item_id: '665600',
            item_name: '2021-12-01 Innerdalen.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/665600',
            item_path: 'docarc/cust_no/15230/665600',
            custact_no: '0',
            orderby: '81',
            docfilename: 'Innerdalen.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '87DFB4F001BB1F9C7CEF039F46EE3EC06EFC126B56FC41ABDFE9117FB3B95BA7',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-12-01 Innerdalen.pdf (PDF)',
        },
        {
            item_id: '666100',
            item_name: '2021-12-01 Salgsfaktura 122506.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/666100',
            item_path: 'docarc/cust_no/15230/666100',
            custact_no: '623206',
            orderby: '82',
            docfilename: 'Salgsfaktura 122506.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: 'DA899FAAF515D885A04447F8CD4584D5697154EB845ECAA523B19761569A9E04',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-12-01 Salgsfaktura 122506.pdf (PDF)',
        },
        {
            item_id: '664300',
            item_name: '2021-11-05 Ladalen Velforening V7.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/664300',
            item_path: 'docarc/cust_no/15230/664300',
            custact_no: '0',
            orderby: '83',
            docfilename: 'Ladalen Velforening V7.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: 'E111E633CA1FC50E4D22B727CB325A035B540B972DE958C90FF6D880901D0F03',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-11-05 Ladalen Velforening V7.pdf (PDF)',
        },
        {
            item_id: '663900',
            item_name: '2021-10-26 2021-10-26 12:53:36.430 623180 ()',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/663900',
            item_path: 'docarc/cust_no/15230/663900',
            custact_no: '623180',
            orderby: '84',
            docfilename: '2021-10-26 12:53:36.430 623180',
            extension: '430 623180',
            oleapplication_id: '',
            item_glyphicon: 'glyphicon-unchecked fas-question-square',
            item_glyphicon_color: 'black blue',
            checksum: '08D832465BE87792E083C13F127E7A0E8F19B672705A38350AA83A68F54CD497',
            item_glyphicon_2: 'glyphicon-check fas-question-square',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 2021-10-26 12:53:36.430 623180 ()',
        },
        {
            item_id: '664100',
            item_name: '2021-10-26 623180 ()',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/664100',
            item_path: 'docarc/cust_no/15230/664100',
            custact_no: '623180',
            orderby: '85',
            docfilename: '623180',
            extension: '623180',
            oleapplication_id: '',
            item_glyphicon: 'glyphicon-unchecked fas-question-square',
            item_glyphicon_color: 'black blue',
            checksum: 'B407DA95BA5F8B07D204454D667291627DEF2D693D6796EE08595EE8983628E6',
            item_glyphicon_2: 'glyphicon-check fas-question-square',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 623180 ()',
        },
        {
            item_id: '663100',
            item_name: '2021-10-26 docarc_53a2edfae92f47dc96e60be6164fc7ee.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/663100',
            item_path: 'docarc/cust_no/15230/663100',
            custact_no: '623180',
            orderby: '86',
            docfilename: 'docarc_53a2edfae92f47dc96e60be6164fc7ee.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '95736EBE994340444147B01AC0DAE33A89CA023F28BEFB0215391187E6AA6863',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 docarc_53a2edfae92f47dc96e60be6164fc7ee.pdf (PDF)',
        },
        {
            item_id: '86600',
            item_name: '2021-10-26 NOTAT, kva skal gjerast? ()',
            item_state: 'emailview',
            item_parms: '86600',
            item_path: 'emailview/86600',
            custact_no: '623180',
            orderby: '87',
            docfilename: '663200.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 NOTAT, kva skal gjerast? ()',
        },
        {
            item_id: '87000',
            item_name: '2021-10-26 NOTAT, kva skal gjerast? ()',
            item_state: 'emailview',
            item_parms: '87000',
            item_path: 'emailview/87000',
            custact_no: '623180',
            orderby: '88',
            docfilename: '664000.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 NOTAT, kva skal gjerast? ()',
        },
        {
            item_id: '87100',
            item_name: '2021-10-26 NOTAT, kva skal gjerast? ()',
            item_state: 'emailview',
            item_parms: '87100',
            item_path: 'emailview/87100',
            custact_no: '623180',
            orderby: '89',
            docfilename: '664200.',
            extension: 'msg',
            oleapplication_id: 'insoft.email',
            item_glyphicon: 'glyphicon-unchecked fas-envelope',
            item_glyphicon_color: 'black blue',
            checksum: '',
            item_glyphicon_2: 'glyphicon-check fas-envelope',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-26 NOTAT, kva skal gjerast? ()',
        },
        {
            item_id: '661300',
            item_name: '2021-10-19 01 (1).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661300',
            item_path: 'docarc/cust_no/15230/661300',
            custact_no: '0',
            orderby: '90',
            docfilename: '01 (1).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '73AA6F09812C6D47CDAE6CD53A671373A5852394B8BCDF9A3F62D6C8325FC87D',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 01 (1).pdf (PDF)',
        },
        {
            item_id: '661200',
            item_name: '2021-10-19 01.pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661200',
            item_path: 'docarc/cust_no/15230/661200',
            custact_no: '0',
            orderby: '91',
            docfilename: '01.pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '57A45A52073A0085DA21408A91275D1354B788E3D0E63855B114E3D58587CEBB',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 01.pdf (PDF)',
        },
        {
            item_id: '661400',
            item_name: '2021-10-19 test01 (1).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661400',
            item_path: 'docarc/cust_no/15230/661400',
            custact_no: '0',
            orderby: '92',
            docfilename: 'test01 (1).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '2DB61BA27C0F843F73E985F26395857770ABA435E190497A7EAE166F0A52AB52',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (1).pdf (PDF)',
        },
        {
            item_id: '662100',
            item_name: '2021-10-19 test01 (10).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/662100',
            item_path: 'docarc/cust_no/15230/662100',
            custact_no: '0',
            orderby: '93',
            docfilename: 'test01 (10).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '81D61E7FBA3453C0DF078FC5E5EDDED0C26389985A0835D4027C6CEEE13CB776',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (10).pdf (PDF)',
        },
        {
            item_id: '662700',
            item_name: '2021-10-19 test01 (16).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/662700',
            item_path: 'docarc/cust_no/15230/662700',
            custact_no: '0',
            orderby: '94',
            docfilename: 'test01 (16).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: 'BD0F062303525D8BB50C8535D75A59C6848B36C57E7CC5C88CBC14998B33D4FA',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (16).pdf (PDF)',
        },
        {
            item_id: '661500',
            item_name: '2021-10-19 test01 (2).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661500',
            item_path: 'docarc/cust_no/15230/661500',
            custact_no: '0',
            orderby: '95',
            docfilename: 'test01 (2).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '605FF55D6A8B455A3CE276F1E827CA9BEF677BBE66E9343D93FF8C0DD809EF70',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (2).pdf (PDF)',
        },
        {
            item_id: '661600',
            item_name: '2021-10-19 test01 (3).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661600',
            item_path: 'docarc/cust_no/15230/661600',
            custact_no: '0',
            orderby: '96',
            docfilename: 'test01 (3).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '5424CCC262E6F1DA0BF686DD5C051BAB9E8E659F54C6E1C470D95A801734AA3F',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (3).pdf (PDF)',
        },
        {
            item_id: '661700',
            item_name: '2021-10-19 test01 (4).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661700',
            item_path: 'docarc/cust_no/15230/661700',
            custact_no: '0',
            orderby: '97',
            docfilename: 'test01 (4).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '47A6EDF8FCD9FEF3EDF05A5B98E5D00CC687FE710AECECB1C3826EA83D51D881',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (4).pdf (PDF)',
        },
        {
            item_id: '661800',
            item_name: '2021-10-19 test01 (5).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661800',
            item_path: 'docarc/cust_no/15230/661800',
            custact_no: '0',
            orderby: '98',
            docfilename: 'test01 (5).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '32F39DE47132CF47DC6C57C18B565F47C94FD608BD4BC25A63C7D85B42340B02',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (5).pdf (PDF)',
        },
        {
            item_id: '661900',
            item_name: '2021-10-19 test01 (6).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/661900',
            item_path: 'docarc/cust_no/15230/661900',
            custact_no: '0',
            orderby: '99',
            docfilename: 'test01 (6).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: '3BEFAF325CA2C9AEF47F8A371B89C908CD17412694E8ACBD9E1D06D37AF4C936',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (6).pdf (PDF)',
        },
        {
            item_id: '662000',
            item_name: '2021-10-19 test01 (9).pdf (PDF)',
            item_state: 'docarc',
            item_parms: 'cust_no/15230/662000',
            item_path: 'docarc/cust_no/15230/662000',
            custact_no: '0',
            orderby: '100',
            docfilename: 'test01 (9).pdf',
            extension: 'pdf',
            oleapplication_id: 'acrobat.application',
            item_glyphicon: 'glyphicon-unchecked fas-file-pdf',
            item_glyphicon_color: 'black blue',
            checksum: 'CC89D1295279D90F34D96F994BBE813A4579B92E9FC18DC2DEBECDE7716BE834',
            item_glyphicon_2: 'glyphicon-check fas-file-pdf',
            item_is_selected: '0',
            item_glyphicon_color_2: 'black blue',
            item_filtervalue: '2021-10-19 test01 (9).pdf (PDF)',
        },
    ];

    items: Item[] = [
        {
            item_id: 664600,
            item_name: '2024-12-14 ( O: 626100) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/664600',
            item_parms: 664600,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 1,
            badgeinfo: '690.42',
            item_name_sub1: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_name_sub2: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_filtervalue: '2024-12-14 ( O: 626100) Espentest 6012 IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)1010 General White nr 5,1010 General White nr 5,1010 General White nr 5,test test1',
        },
        {
            item_id: 664500,
            item_name: '2024-12-14 ( O: 626000) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/664500',
            item_parms: 664500,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 2,
            badgeinfo: '690.42',
            item_name_sub1: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_name_sub2: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_filtervalue: '2024-12-14 ( O: 626000) Espentest 6012 IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)1010 General White nr 5,1010 General White nr 5,1010 General White nr 5,test test1',
        },
        {
            item_id: 664400,
            item_name: '2024-12-14 ( O: 625900) BJS TEST 44 6011',
            item_state: 'orderhead',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_path: 'orderhead/664400',
            item_parms: 664400,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 3,
            badgeinfo: '641.50',
            item_name_sub1: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_name_sub2: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)',
            item_filtervalue: '2024-12-14 ( O: 625900) BJS TEST 44 6011 IKKE BEKREFTET PÅ DATO     (Bindetråd, Skuffer Og Skap)1010 General White nr 5,1010 General White nr 5,1010 General White nr 5,test test1',
        },
        {
            item_id: 664300,
            item_name: '2024-12-12 (T: 53300) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/664300',
            item_parms: 664300,
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 4,
            badgeinfo: '186.89',
            item_name_sub1: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Dummyprodukter)',
            item_name_sub2: 'IKKE BEKREFTET PÅ DATO     (Bindetråd, Dummyprodukter)',
            item_filtervalue: '2024-12-12 (T: 53300) Espentest 6012 IKKE BEKREFTET PÅ DATO     (Bindetråd, Dummyprodukter)1010 General White nr 5,1011 Skruf white S3',
        },
        {
            item_id: 664200,
            item_name: '2024-12-12 (T: 53200) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/664200',
            item_parms: 664200,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            orderby: 5,
            badgeinfo: '171.76',
            item_name_sub1: 'Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)',
            item_name_sub2: 'Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)',
            item_filtervalue: '2024-12-12 (T: 53200) Espentest 6012 Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)1010 General White nr 5,1011 Skruf white S3',
        },
        {
            item_id: 664100,
            item_name: '2024-12-12 ( O: 625800) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/664100',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_parms: 664100,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 6,
            badgeinfo: '171.76',
            item_name_sub1: 'Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)',
            item_name_sub2: 'Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)',
            item_filtervalue: '2024-12-12 ( O: 625800) Espentest 6012 Bekreftet: 2024-12-12 Ref: test    (Bindetråd, Dummyprodukter)1010 General White nr 5,1011 Skruf white S3',
        },
        {
            item_id: 664000,
            item_name: '2024-12-06 ( O: 625700) Stig test 6017',
            item_state: 'orderhead',
            item_path: 'orderhead/664000',
            item_parms: 664000,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            orderby: 7,
            badgeinfo: '137.50',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_name_sub1: 'Bekreftet: 2024-12-06 Ref: test    (Bindetråd)',
            item_name_sub2: 'Bekreftet: 2024-12-06 Ref: test    (Bindetråd)',
            item_filtervalue: '2024-12-06 ( O: 625700) Stig test 6017 Bekreftet: 2024-12-06 Ref: test    (Bindetråd)1010 General White nr 5',
        },
        {
            item_id: 663900,
            item_name: '2024-12-06 ( O: 625600) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/663900',
            item_parms: 663900,
            item_glyphicon: 'fas-cube fas-cube glyphicon-bullhorn',
            item_glyphicon_color: 'green orange red',
            orderby: 8,
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            badgeinfo: '0.00',
            item_name_sub1: 'Bekreftet: 2024-12-06 Ref: test    ()',
            item_name_sub2: 'Bekreftet: 2024-12-06 Ref: test    ()',
            item_filtervalue: '2024-12-06 ( O: 625600) Espentest 6012 Bekreftet: 2024-12-06 Ref: test    ()',
        },
        {
            item_id: 663700,
            item_name: '2024-12-03 ( O: 625400) Stig test 6017',
            item_state: 'orderhead',
            item_path: 'orderhead/663700',
            item_parms: 663700,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            item_glyphicon_color: 'green orange red',
            orderby: 10,
            badgeinfo: '0.00',
            item_name_sub1: 'Bekreftet: 2024-12-03 Ref: test    ()',
            item_name_sub2: 'Bekreftet: 2024-12-03 Ref: test    ()',
            item_filtervalue: '2024-12-03 ( O: 625400) Stig test 6017 Bekreftet: 2024-12-03 Ref: test    ()',
        },
        {
            item_id: 663800,
            item_name: '2024-12-05 ( O: 625500) Espentest 6012',
            item_state: 'orderhead',
            item_path: 'orderhead/663800',
            item_parms: 663800,
            item_glyphicon: 'fas-cube fas-cube glyphicon-dashboard',
            item_glyphicon_color: 'green orange red',
            item_thumb: 'https://insoft.sirv.com/test-janita/New%20Project3.png',
            orderby: 9,
            badgeinfo: '220.02',
            item_name_sub1: 'Bekreftet: 2024-12-05 Ref: test av flere ordrelinjer med samme produkt    (Bindetråd)',
            item_name_sub2: 'Bekreftet: 2024-12-05 Ref: test av flere ordrelinjer med samme produkt    (Bindetråd)',
            item_filtervalue: '2024-12-05 ( O: 625500) Espentest 6012 Bekreftet: 2024-12-05 Ref: test av flere ordrelinjer med samme produkt    (Bindetråd)1010 General White nr 5,1010 General White nr 5',
        },
    ];

    log(value: any) {
        // const params = {
        //     argtype: 'custact_no',
        //     argvalue: '623098',
        //     // records: ['su'],
        // };

        // this.state.go('documents', params);

        console.dir(value);
    }

    tabs: Tab[] = [
        { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', onClick: () => console.log('EDIT'), disabled: 'hidden' },
        { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', onClick: () => console.log('CONTACTS') },
        { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', onClick: () => console.log('EMAIL') },
        { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', onClick: () => console.log('PROJECTS') },
        // { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => console.log('CUSTACTS') },
        // { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => console.log('ORDERS') },
        // { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => console.log('DOCUMENTS') },
        // { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => console.log('POWTIMES') },
        // { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => console.log('FUNCTIONS') },
    ];

    data: SearchItem[] = [
        { item_id: '1', text: 'hello', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello2', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello3', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello23', item_glyphicon: '', item_glyphicon_color: '' },
    ];

    selectData = [
        { id: '1', name: 'tes t', selected: '1' },
        { id: '2', name: 'test 2 ', selected: '0' },
        { id: '3', name: 'test 2', selected: '0' },
        { id: '4', name: 'hello test', selected: '0' },
    ];

    onListboxSelect(event: Item) {
        console.dir(event);
    }

    onClear() {
        console.log('********* input cleared **********');
        console.log(this.customerSearchtext);
    }

    search: () => Promise<Item[]> = () => {
        return this.datataskService.Post(56, {
            cust_name: this.customerSearchtext,
            custtype: 'C',
            filtertype: '',
            filtervalue: '',
            lastselect: 'SEARCH',
        });
    };
    lis = [
        {
            id: 'sendemail',
            icon: 'fas fa-trash',
            type: 'danger',
            tooltip: 'delete',
        },
        {
            id: 'sendemail',
            icon: 'fas fa-external-link',
            type: 'secondary',
            tooltip: 'secondary',
        },
    ];
    formButtons: ListButton[] = [
        {
            id: 'sendemail',
            icon: 'fas fa-trash',
            type: 'danger',
            tooltip: 'delete',
            onClick: ({ event, item }) => {
                console.log('event :>> ', event);
                console.log('button :>> ', item);
                console.log('sendemail');
                alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n item: \n' + JSON.stringify(item, null, 2));
            },
        },
        {
            id: 'sendemail',
            icon: 'fas fa-external-link',
            type: 'secondary',
            tooltip: 'secondary',
            onClick: ({ event, item }) => {
                console.log('event :>> ', event);
                console.log('button :>> ', item);
                console.log('sendemail');
                alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n item: \n' + JSON.stringify(item, null, 2));
            },
        },
    ];

    formsButtons: FormButton[] = [
        {
            id: 'sendemail',
            icon: 'fas fa-trash',
            type: 'danger',
            tooltip: 'delete',
            onClick: ({ event }) => {
                console.log('event :>> ', event);
                console.log('sendemail');
            },
        },
        {
            id: 'sendemail',
            icon: 'fas fa-external-link',
            type: 'secondary',
            tooltip: 'secondary',
            onClick: ({ event }) => {
                console.log('event :>> ', event);
                console.log('sendemail');
            },
        },
    ];

    // formButtons: FormButton[] = [
    //     {
    //         id: 'sendemail',
    //         icon: 'fas fa-envelope',
    //         type: 'primary',
    //         onClick: ({ event, buttonId, options }) => {
    //             console.log('event :>> ', event);
    //             console.log('button :>> ', buttonId);
    //             console.log('options :>> ', options);
    //             console.log('sendemail');
    //             alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n Button: \n' + JSON.stringify(buttonId, null, 2) + '\n\n Parameters: \n' + JSON.stringify(options, null, 2));
    //         },
    //     },
    //     {
    //         id: 'refresh',
    //         icon: 'fas fa-sync',
    //         type: 'primary',
    //         onClick: () => alert('2'),
    //     },
    // ];

    hideButton: boolean | 'hidden' = false;

    accountingprocessingGrid = new Grid()
        .setRememberId('w_accountingprocessing.accountgrid')
        .setLoadDataFunction({
            method: 2089,
            parameters: {
                custact_no: '623409',
                custactac_keyno: '127',
            },
        })
        .setSaveDataFunction({ method: null, parameters: { custactac_keyno: '127' } })
        .setAddRowFunction({ method: null, parameters: { custactac_keyno: '127' } })
        .setToolbar({ pdfExport: false, excelExport: false, add: true, rebind: true, delete: true, save: true })
        .setCustomToolbarButtons([
            { name: 'import_voucherlines', text: 'w_accountingprocessing_import_voucherlines', func: () => alert('yolo') },
            { name: 'custactac_match', text: 'custactac_match', func: () => alert('yolo again') },
        ])
        .setSpecialFunc({ edit: true, newTab: true })
        .setNavigation({ newLine: true, altNav: true })
        .setKendoConfig({ height: '800px' })
        .build();

    // columns = 16;
    // rows = 12;
    // rowHeight = 8;
    // customerSearchtext = '';
    // textValue = 'hel';
    // text1 = 1000;
    // text2 = 1000;
    // text3 = 1000;
    // text4 = 1000;
    // editMode = false;
    // check = '1';
    // check2 = 'true';

    // selectedTab = '';

    // onTabChange(event: string) {
    //     console.log('changed to: ', event);
    // }

    // log(value: any) {
    //     console.dir(value);
    // }

    // tabs = [
    //     { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', color: 'primary', onClick: () => console.log('EDIT') },
    //     { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', color: 'primary', onClick: () => console.log('CONTACTS') },
    //     { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary', onClick: () => console.log('EMAIL') },
    //     { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', color: 'primary', onClick: () => console.log('PROJECTS') },
    //     // { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => console.log('CUSTACTS') },
    //     // { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => console.log('ORDERS') },
    //     // { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => console.log('DOCUMENTS') },
    //     // { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => console.log('POWTIMES') },
    //     // { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => console.log('FUNCTIONS') },
    // ];

    // data: SearchItem[] = [
    //     { item_id: '1', text: 'hello', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello2', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello3', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello23', item_glyphicon: '', item_glyphicon_color: '' },
    // ];

    // selectData = [
    //     { id: '1', name: 'tes t', selected: '1' },
    //     { id: '2', name: 'test 2 ', selected: '0' },
    //     { id: '3', name: 'test 2', selected: '0' },
    //     { id: '4', name: 'hello test', selected: '0' },
    // ];

    // onListboxSelect(event: SearchItem) {
    //     console.dir(event);
    // }

    // onClear() {
    //     console.log('********* input cleared **********');
    //     console.log(this.customerSearchtext);
    // }

    // search: () => Promise<Item[]> = () => {
    //     return this.datataskService.Post(56, {
    //         cust_name: this.customerSearchtext,
    //         custtype: 'C',
    //         filtertype: '',
    //         filtervalue: '',
    //         lastselect: 'SEARCH',
    //     });
    // };

    // formButtons: FormButton[] = [
    //     {
    //         id: 'sendemail',
    //         icon: 'fas fa-envelope',
    //         type: 'primary',
    //         onClick: ({ event, buttonId, options }) => {
    //             console.log('event :>> ', event);
    //             console.log('button :>> ', buttonId);
    //             console.log('options :>> ', options);
    //             console.log('sendemail');
    //             alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n Button: \n' + JSON.stringify(buttonId, null, 2) + '\n\n Parameters: \n' + JSON.stringify(options, null, 2));
    //         },
    //     },
    //     {
    //         id: 'refresh',
    //         icon: 'fas fa-sync',
    //         type: 'primary',
    //         onClick: () => alert('2'),
    //     },
    //     {
    //         id: 'gotodoc',
    //         icon: 'fas fa-search',
    //         type: 'primary',
    //         onClick: () => alert('3'),
    //     },
    //     {
    //         id: 'gotodocarc',
    //         icon: 'fas fa-chevron-right',
    //         type: 'primary',
    //         onClick: () => alert('4'),
    //     },
    // ];
    // variables = {
    //     testVariable: 'HEIHEI',
    //     testVariable_2: 'HeiPåDeg!',
    // };

    newVariables: { [key: string]: WidgetVariable | WidgetNumberVariable } = {
        testVariable: {
            id: crypto.randomUUID(),
            value: 'HEIHEI',
            type: 'text',
        },
        testVariable_2: {
            id: crypto.randomUUID(),
            value: 5320000,
            type: 'number',
            format: 'thousand-separator',
            displayUnits: 'none',
            decimals: 2,
        },
    };

    spin = true;

    logStuff() {
        this.accountingprocessingGrid.gridfunc!.getGridApi().getDisplayedRowAtIndex(0)!.data['note'] = 'Testing updaterow';
        this.accountingprocessingGrid.gridfunc?.updateRow(null, 0);
    }

    constructor(public widgetService: WidgetService, private cdr: ChangeDetectorRef, public datataskService: DataTaskService, private idporten: IdportenService, private pdfService: PdfService, base64Service: Base64UrlService, public state: StateService, private toast: ToastService) {
        setTimeout(async () => {
            this.timerFinish = true;
            this.toast.addToast({ id: '1', title: 'success', message: 'success message' });
        }, 10000);
        this.toast.addToast({ id: '2', title: 'success', message: 'success message', type: 'primary' });
        this.toast.addToast({ id: '3', title: 'success', message: 'success message', type: 'danger' });
        this.toast.addToast({ id: '4', title: 'Du har Bilag til godkjenning, klikk på denne meldingen for å se bilagene dine', translateTitle: false, message: '10.02.2025', translateMessage: false, type: 'primary', state: 'accountingprocessing', parms: '0', hideDelay: null });
        this.toast.addToast({ id: '5', title: 'success', message: 'success message', type: 'warning' });
    }

    _arrayBufferToBase64(buffer: ArrayBufferLike) {
        try {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        } catch (error) {
            console.log('error :>> ', error);
            throw Error('lol');
        }
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    onWidgetsChange(event: any) {
        // console.dir(event);
    }

    onTextChanged(event: any) {
        console.log('event :>> ', event);
        console.log('this.text1 :>> ', this.text1);
        // console.log('this.text2 :>> ', this.text2);
        // console.log('this.text3 :>> ', this.text3);
        // console.log('this.text4 :>> ', this.text4);
    }

    // updateWidgetInList(widgetId: string, newData: any) {
    //     const index = this.widgets.findIndex((widget) => widget.id === widgetId);
    //     if (index !== -1) {
    //         const updatedWidget = { ...this.widgets[index], ...newData };
    //         this.widgets = [...this.widgets.slice(0, index), updatedWidget, ...this.widgets.slice(index + 1)];
    //     }
    // }

    // onVariableChange(event: any) {
    //     console.dir(event);
    //     this.newVariables['testVariable'].value = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable2Change(event: any) {
    //     console.dir(event);
    //     this.newVariables['testVariable_2'].value = Number(event);
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable3Change(event: any) {
    //     console.dir(event);
    //     if (this.widgetService.isWidgetNumberVariable(this.newVariables['testVariable_2'])) this.newVariables['testVariable_2'].decimals = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable4Change(event: any) {
    //     console.dir(event);
    //     if (this.widgetService.isWidgetNumberVariable(this.newVariables['testVariable_2'])) this.newVariables['testVariable_2'].displayUnits = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    widgets: WidgetConfig[] = [
        {
            id: crypto.randomUUID() as string,
            mainHeading: '1. Lorem ipsum dolor de amet',
            subHeading: 'Donec dictum tristique porta etiam convallis',
            value: {
                chartType: 'line',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
                    datasets: [
                        {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            label: 'Series A',
                            //  backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
                        },
                        {
                            data: [28, 48, 40, 19, 86, 27, 90],
                            label: 'Series B',
                            // backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
                        },
                    ],
                },
            },
            icon: 'fal fa-usd-circle',
            iconColor: '#B5FFC1',
            type: 'chart',
            rowStart: 2,
            rowSpan: 3,
            columnStart: 8,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '2. Lorem ipsum dolor de amet',
            subHeading: 'Donec dictum tristique porta etiam convallis',
            value: {
                chartType: 'doughnut',
                data: {
                    labels: ['Red', 'Blue', 'Yellow'],
                    datasets: [
                        {
                            label: 'My First Dataset',
                            data: [300, 50, 100],
                            // backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                            // borderColor: ['rgba(255, 99, 132, 0)', 'rgba(54, 162, 235, 0)', 'rgba(255, 205, 86, 0)'],
                            hoverOffset: 4,
                        },
                    ],
                },
            },
            icon: 'fal fa-file-invoice-dollar',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 2,
            rowSpan: 3,
            columnStart: 14,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '3. Lorem ipsum dolor de amet',
            value: {
                options: {
                    indexAxis: 'y',
                },
                chartType: 'bar',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
                    datasets: [
                        { data: [65, 59, 80, 81, 56, 55, 40, 28, 48, 40, 19, 86, 27, 90], label: 'Series A' },
                        { data: [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56, 55, 40], label: 'Series B' },
                    ],
                },
            },
            icon: 'fal fa-usd-circle',
            iconColor: '#B5FFC1',
            type: 'chart',

            rowStart: 2,
            rowSpan: 11,
            columnStart: 1,
            columnSpan: 4,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '4. Lorem ipsum dolor de amet',
            value: {
                chartType: 'bar',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
                    datasets: [
                        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
                        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
                    ],
                },
            },
            icon: 'fal fa-file-invoice-dollar',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 2,
            rowSpan: 3,
            columnStart: 5,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '5. Lorem ipsum dolor de amet',
            value: {
                model: 'Test av [[testVariable]] i tekststreng, og enda en [[testVariable]] og en annerledes [[testVariable_2]]',
                variables: () => this.newVariables,
            } as WidgetTextConfig,
            icon: 'fal fa-unicorn',
            iconColor: '#E475F6',
            type: 'text',
            rowStart: 2,
            rowSpan: 3,
            columnStart: 11,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            value: {
                model: [
                    {
                        value: '[[testVariable]][[testVariable]] og en [[testVariable_2]]',
                        fontSize: '3.8rem',
                        fontWeight: '900',
                        textAlign: 'center',
                        alignSelf: 'center',
                    } as WidgetTextLine,
                ],
                variables: () => this.newVariables,
            } as WidgetTextConfig,
            type: 'text',
            rowStart: 1,
            rowSpan: 1,
            columnStart: 1,
            columnSpan: this.columns,
            style: {} as Partial<CSSStyleDeclaration>,
        },

        {
            id: crypto.randomUUID() as string,
            mainHeading: '5. Lorem ipsum dolor de amet',
            value: {
                chartType: 'line',
                data: {
                    datasets: [
                        {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            label: 'Series A',
                            fill: 'origin',
                        },
                        {
                            data: [28, 48, 40, 19, 86, 27, 90],
                            label: 'Series B',
                            fill: 'origin',
                        },
                        {
                            data: [180, 480, 770, 90, 1000, 270, 400],
                            label: 'Series C',
                            yAxisID: 'y1',
                            fill: 'origin',
                        },
                    ],
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                },
            },
            icon: 'fal fa-unicorn',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 5,
            rowSpan: 8,
            columnStart: 5,
            columnSpan: 12,
            style: {} as Partial<CSSStyleDeclaration>,
        },
    ];
}

export interface WidgetVariable {
    id: string;
    value: number | string;
    type: 'text' | 'number';
}

export interface WidgetNumberVariable extends WidgetVariable {
    type: 'number';
    format?: 'standard' | 'thousand-separator';
    decimals?: number;
    displayUnits?: 'none' | 'thousands' | 'millions' | 'billions' | 'trillions';
}
