import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Base64UrlService } from '../../services/base64url.service';
//import { IS_PUBLIC_API } from '../httpcontexttokens/public-api.token';
import { UtilityService } from './utility.service';
import { AppSettingsService } from './app-settings.service';
import { ModalService, ModalType } from './modal.service';

export interface IRequestOptions {
    headers?: HttpHeaders | { [header: string]: string | string[] };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    transferCache?: { includeHeaders?: string[] } | boolean;
    body?: any;
}

export interface IDataTaskOptions {
    escapeRequest: boolean;
    encodeRequest: boolean;
    decodeResponse: boolean;
    returnRawResponse: boolean;
    confirmMessage: string | Function;
    suppressErrorMessage: boolean;
}

export interface IDataTaskResponse {
    isError: boolean;
    message: string;
    data: any;
}

interface IResponse {
    status: number;
}

@Injectable({
    providedIn: 'root',
})
export class DataTaskService {
    private api: string = '';
    private clientIp: string = '';
    private modal?: ModalService;
    constructor(private http: HttpClient, private appSettings: AppSettingsService, private base64url: Base64UrlService, private us: UtilityService) {}

    public async init(modal: ModalService) {
        await this.appSettings.ensureIsReady();
        this.modal = modal;

        this.api = this.appSettings.settings.dataUrl;

        if (!this.api?.startsWith('https')) {
            while (this.api?.startsWith('/')) {
                this.api = this.api.substring(1);
            }

            this.api = 'https://' + this.api;
        }

        if (!this.api.endsWith('/')) {
            this.api += '/';
        }

        this.api += 'sp_ws_p2_datatask';
    }

    /**
     * @param {number} method
     * @param {Object | string} params
     */
    public async Get(method: number, params: Object | string, options?: IDataTaskOptions | undefined) {
        options = this.validateDataTaskOptions(options);

        let self = this;
        let url = this.api;
        let qs = '';

        if (this.us.isString(params)) {
            qs = params as string;
        } else if (this.us.isObject(params)) {
            qs = this.toQueryString(params);
        }

        if (qs.length > 0) {
            if (options.encodeRequest) {
                let parts = qs.split('/');

                qs = '';

                parts.forEach(function (p) {
                    if (qs.length > 0) {
                        qs += '/';
                    }

                    qs += self.base64url.Encode(p);
                });
            } else {
                if (options.escapeRequest) {
                    qs = encodeURIComponent(qs);
                }
            }

            if (qs.length > 0 && !qs.startsWith('/')) {
                qs = '/' + qs;
            }
        }

        url += '/' + method + qs;

        let response: Object | null = null;

        try {
            const data$ = this.http.get(url);

            response = await lastValueFrom(data$);
        } catch (error: any) {
            if (error.status === 401) {
                window.location.href = this.appSettings.settings.loginUrl;
            } else {
                if (error instanceof HttpErrorResponse) {
                    this.onError(new Error(error.message), options);
                } else {
                    this.onError(new Error(error), options);
                }
            }
        }

        return this.onResponse(response, options);
    }

    /**
     * POST request
     * @param method
     * @param params
     * @param options
     * @returns
     */
    public async Post(method: number, params?: Object | string, options?: IDataTaskOptions | undefined): Promise<any> {
        if (params === undefined) {
            params = {};
        }

        options = this.validateDataTaskOptions(options);

        let paramsData: any | undefined = params;

        if (options.encodeRequest) {
            // Ignores escapeRequest option if encodeRequest is true.
            let paramsJson: string = this.us.isString(params) ? (params as string) : JSON.stringify(params);

            paramsData = this.base64url.Encode(paramsJson);
        } else {
            if (options.escapeRequest) {
                if (this.us.isString(params)) {
                    paramsData = (params as string).replace(/\'/g, "''");
                } else {
                    paramsData = params as Object;

                    let property: keyof typeof paramsData;

                    for (property in paramsData) {
                        if (this.us.isString(paramsData[property])) {
                            paramsData[property] = paramsData[property].replace(/\'/g, "''");
                        }
                    }
                }
            }
        }

        let response: Object | null = null;

        try {
            if (options.decodeResponse) {
                const data$ = this.http.post(
                    this.api,
                    {
                        method: method,
                        clientip: await this.getClientIp(),
                        parameters: paramsData,
                    },
                    {
                        responseType: 'text',
                    }
                );

                let rawResponse = await lastValueFrom(data$);

                let jsonResponse = this.base64url.Decode(rawResponse as string);

                response = JSON.parse(jsonResponse);
            } else {
                const data$ = this.http.post(this.api, {
                    method: method,
                    clientip: await this.getClientIp(),
                    parameters: paramsData,
                });

                response = await lastValueFrom(data$);
            }
        } catch (error: any) {
            console.log('error');
            console.dir(error);

            if (error.status === 401) {
                window.location.href = this.appSettings.settings.loginUrl;
            } else {
                if (error instanceof HttpErrorResponse) {
                    if (error.error?.text) {
                        error = error.error.text;
                    } else {
                        error = error.message;
                    }
                }

                this.onError(new Error(error), options);
            }
        }

        var rs = response as IResponse;

        if (rs?.status === 401) {
            window.location.href = '.auth/login';
        }

        return this.onResponse(response, options);
    }

    private onResponse(response: any, options: IDataTaskOptions): any {
        let dtResponse = this.processResponse(response, options);

        if (dtResponse.isError === true) {
            console.dir(dtResponse.message);
            this.onError(new Error((dtResponse.message as any) instanceof Object ? JSON.stringify(dtResponse.message, null, 2) : dtResponse.message), options);
        } else if (response.validation === true && !!response.message) {
            this.modal?.show({
                title: response.encoded ? this.base64url.urlDecode(response.title) : response.title,
                message: response.encoded ? this.base64url.urlDecode(response.message) : response.message,
                type: 'warning',
            });
        }

        if (response.message === true && !!response.messages && response.messages.length > 0) {
            this.showMessage(response);
        } else {
            this.showConfirmationMessage(response, options);
        }

        // BJS 20250305 - Fixed a bug where in cases where the response object did not contain a data property
        //                this would return null because dtResponse.data would be null.
        return options.returnRawResponse ? response : dtResponse.data === null ? response : dtResponse.data;
    }

    private showMessage(response: { message: boolean; messages: DbModalMessage[] }) {
        response.messages.forEach((message: DbModalMessage) => {
            this.modal?.show({ title: message.title, message: message.message, type: message.class as ModalType });
        });
    }

    private showConfirmationMessage(response: any, options: IDataTaskOptions) {
        let message = '';

        if (this.us.isFunction(options.confirmMessage)) {
            let messageFunc = options.confirmMessage as Function;

            message = messageFunc(response);
        } else {
            message = options.confirmMessage as string;
        }

        if (this.us.isString(message) !== true && !message && !!response.message) {
            let title = response.title;
            let message = response.message;
            let type = response.class;

            if (response.encoded === true) {
                title = this.base64url.urlDecode(response.title);
                message = this.base64url.urlDecode(response.message);
                type = this.base64url.urlDecode(response.class);
            }

            this.modal?.show({ title: title, message: message, type: type as ModalType });
        }
    }

    private onError(error: Error, options: IDataTaskOptions) {
        if (options.suppressErrorMessage !== true) {
            console.dir(error);

            // hide json syntax error when the procedure succesfully completed.
            if (!`${error}`.includes('"errorcode":0')) {
                this.modal?.openErrorDialog(error.message);
            }
            // TODO - BJS 20231218 - Show error modal;
        }

        throw error;
    }

    private processResponse(response: any, options: IDataTaskOptions): IDataTaskResponse {
        //console.log('datatask response');
        //console.dir(response);
        //console.dir(options);

        if (this.us.isArray(response)) {
            return {
                isError: false,
                message: '',
                data: response,
            } as IDataTaskResponse;
        }

        let keys = Object.keys(response);

        function getResponseValue(props: string[], defaultValue: any): any {
            let retval: any = defaultValue;

            props.every(function (p) {
                if (keys.includes(p)) {
                    retval = response[p];
                    return false;
                }

                return true;
            });

            return retval;
        }

        let dtResponse = {
            isError: getResponseValue(['error', 'Error', 'iserror', 'isError'], false),
            message: getResponseValue(['message', 'Message', 'msg', 'Msg'], ''),
            data: getResponseValue(['data', 'Data'], null),
        } as IDataTaskResponse;

        return dtResponse;
    }

    private validateDataTaskOptions(options: Partial<IDataTaskOptions> | undefined): IDataTaskOptions {
        if (options === undefined) {
            options = { escapeRequest: false, encodeRequest: false, decodeResponse: false, confirmMessage: '', suppressErrorMessage: false, returnRawResponse: false };
        } else {
            options = { escapeRequest: false, encodeRequest: false, decodeResponse: false, confirmMessage: '', suppressErrorMessage: false, returnRawResponse: false, ...options! };
        }

        return options as IDataTaskOptions;
    }

    async getClientIp() {
        if (this.clientIp.length > 0) return this.clientIp;

        const data$ = this.http.get<any>('https://ipify.insoft.net');

        let clientIpData = await lastValueFrom(data$);

        return clientIpData.ip;
    }

    toQueryString(o: any) {
        let qs = '';

        Object.keys(o).forEach((k) => {
            if (typeof o[k] === 'object') {
                return this.toQueryString(o[k]);
            }

            if (qs.length > 0) {
                qs += '/';
            }

            return (qs += o[k]);
        });

        return qs;
    }
}

interface DbModalMessage {
    title: string;
    message: string;
    class: string;
    data: string;
}
