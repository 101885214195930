(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('ftpAccount', {
        templateUrl: 'views/components/views/ftpAccount/ftpAccount.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'ftpAccountService', function ($stateParams, stateService, translateService, modalService, ftpAccountService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let ftpAccountKeyno = $stateParams.ftpaccount_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                ftpAccountKeyno: ftpAccountKeyno,
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATION SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await ftpAccountService.getEdit({ ftpaccount_keyno: ftpAccountKeyno }))[0];

                vm.model.edit.remotepass = atob(vm.model.edit.remotepass);
                vm.model.edit.remotefolder = atob(vm.model.edit.remotefolder);
                vm.model.edit.localfolder = atob(vm.model.edit.localfolder);
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                ftpAccountService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    ftpAccountService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
