(function () {
    'use strict';

    angular.module("imApp").factory("checkInService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 509,
                    parameters: parms || {}
                });
            },
            listCheckInSkillsCertificates: function (parms) {
                return $ihttp.post({
                    method: 2506,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 510,
                    parameters: edit
                });
            },
            checkInOutOther: function (edit) {
                return $ihttp.post({
                    method: 511,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
