(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('furnitureFabricsColor', {
        templateUrl: 'views/components/views/furnitureFabricsColor/furnitureFabricsColor.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'furnitureFabricsColorService', function ($stateParams, $q, stateService, modalService, translateService, furnitureFabricsColorService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let furnitureFabricsId = $stateParams.furniturefabrics_id;
            let furnitureFabricsColorId = $stateParams.furniturefabricscolor_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListLights: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            //#region SHOW DIALOGUE BOX FUNCTION

            function showMessage(type, title, message, label, cssClass) {
                const deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });
                
                return deferred.promise;
            }

            //#endregion SHOW DIALOGUE BOX FUNCTION

            // #region TRANSLATION FUNCTION

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATION FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await furnitureFabricsColorService.getEdit({
                    furniturefabrics_id: furnitureFabricsId,
                    furniturefabricscolor_id: furnitureFabricsColorId
                }))[0];
            }

            async function loadFurnitureFabricsLights() {
                vm.model.selectListLights = (await furnitureFabricsColorService.listFurnitureFabricsLights());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                furnitureFabricsColorService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
                loadFurnitureFabricsLights();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
