(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('gridExportArg', {
        templateUrl: 'views/components/views/gridExportArg/gridExportArg.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'rememberService', 'logisticService', 'economyService', 'gridExportArgService', 'validationService', 'ttGridFactory', function ($stateParams, stateService, utilityService, rememberService, logisticService, economyService, gridExportArgService, validationService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            let variableNames = {
                orderstatus_id: '',
                ordertype_id: '',
                businessco_no: '',
                stockplace_id: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                searchtext: '',
                supplier_no: '',
                project_keyno: '',
                cust_no: '',
                labour_no: '',
                businesssite_keyno: '',
                order_internal_no: '',
                order_order_no_and_cust_name: '',
                collection_keyno: '',
                collection_name: '',
                coop_id: '',
                activestatus_id: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                p2ExportProcKeyNo: $stateParams.p2_exportproc_keyno,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchGrid() }
                ],
                setting: {},
                selectedRow: null,
                selectedRow2: null,
                selectListOrderStatuses: [],
                selectListOrderTypes: [],
                selectListCompanies: [],
                selectListStockPlaces: [],
                selectListBusinessSites: [],
                selectListCoops: [],
                selectListActiveStatuses: [],
                gridTable: [],
                gridReady: false,
                grid2Ready: false,
                grid3Ready: false,
                searching: false,
                loading: {
                    execute: false
                },
                gridExportArgView2Boxes: {
                    //height: '25vh',
                    rememberId: 'gridexportarg.' + $stateParams.argtype + '.' + $stateParams.argvalue + '.2boxes',
                    boxes: [
                        //{ item_id: uuid(), item_name: 'filter', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'grid', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'grid2', type: 'h', size: '50', depth: 0 }
                    ],
                },
                gridExportArgView3Boxes: {
                    //height: '25vh',
                    rememberId: 'gridexportarg.' + $stateParams.argtype + '.' + $stateParams.argvalue + '.3boxes',
                    boxes: [
                        //{ item_id: uuid(), item_name: 'filter', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'grid', type: 'h', size: '33', depth: 0 },
                        { item_id: uuid(), item_name: 'grid2', type: 'h', size: '33', depth: 0 },
                        { item_id: uuid(), item_name: 'grid3', type: 'h', size: '33', depth: 0 }
                    ],
                },

            };

            //vm.grid = new ttGridFactory({ rememberId: 'w_gridexportarg.stockplace_id' })
            //    .setSpecialFunc({ newTab: true })
            //    .setOptionFunc((data) => optionfunc(data.data));

            vm.grid = {
                dataTask: {
                    rememberId: 'w_gridexportarg.' + $stateParams.argtype + '.' + $stateParams.argvalue + '.grid',        // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        //method: 1863,
                        //parameters: { p2_exportproc_keyno: '5' }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                        ////method: 2217,
                        //parameters: {
                        //    datatask_keyno: 2217,
                        //    order_internal_no: $stateParams.order_internal_no
                        //},
                        //autoSave: true,
                        ////confirm: true
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        //hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'customInvoice', text: 'LAG FAKTURA', func: function (data) { testItems(data); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' },
                            //{ name: 'customInvoice', text: 'create_invoice', func: function (data) { testItems(data); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'customInvoice', text: 'create_invoice', func: function (data) { testItems(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'customInvoice2', text: 'create_invoice', func: function (data) { testItems(data); }, icon: 'null', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'customInvoice3', text: 'create_invoice', func: function (data) { testItems(data); }, cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'customTest', text: 'Dette er en tekst', func: function () { testItems(); }, icon: 'star' }
                            //{ name: 'test', text: 'test', func: function () { testValidation(); }, icon: 'fal fa-ballot-check', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: false, disabled: function () { return false; } },
                        ],
                        //toggles: [
                        //    {
                        //        name: 'test',
                        //        text: 'test',
                        //        state: 0,
                        //        states: [
                        //            { text: 'test0', func: function () { testToggle(0) }, icon: 'fal fa-ballot-check' },
                        //            { text: 'test1', func: function () { testToggle(1) }, icon: 'fal fa-ballot' },
                        //            { text: 'test2', func: function () { testToggle(2) }, icon: 'fal fa-ballot-check' },
                        //            { text: 'test3', func: function () { testToggle(3) }, icon: 'fal fa-ballot' },
                        //        ],
                        //        icon: 'fal fa-ballot-check',
                        //        disabled: function () { return false; }
                        //    }
                        //]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } },
                            //{ name: 'test2', text: '', func: function (data) { testItems(data); }, icon: 'fas-save', disabled: function (data) { return test2Hidden(data); } }
                        ]
                    },
                    navigation: {
                        altNav: false                                   // accepts -> true or false, (or undefined)
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false,                            // Enables text-wrapping in columns, default is false
                        height: 'fill'
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }, //'multiple, row'                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null,                                         // Used for referencing functions in the Grid Component
                onSelect: (event) => {
                    if (event.$item) {
                        vm.model.selectedRow = event.$item;

                        loadGrid2();
                    }
                },
            };

            vm.grid2 = {
                dataTask: {
                    rememberId: 'w_gridexportarg.' + $stateParams.argtype + '.' + $stateParams.argvalue + '.grid2',        // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        //method: 1863,
                        //parameters: { p2_exportproc_keyno: '5' }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                        ////method: 2217,
                        //parameters: {
                        //    datatask_keyno: 2217,
                        //    order_internal_no: $stateParams.order_internal_no
                        //},
                        //autoSave: true,
                        ////confirm: true
                    }
                },
                translations: [],                                       
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: []
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: []
                    },
                    navigation: {
                        altNav: false                                   // accepts -> true or false, (or undefined)
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false,                            // Enables text-wrapping in columns, default is false
                        height: 'fill'
                    },
                    onDataSourceChanges: function (e) {}
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    },
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null,                                         // Used for referencing functions in the Grid Component
                onSelect: (event) => {
                    if (event.$item) {
                        vm.model.selectedRow2 = event.$item;

                        loadGrid3();
                    }
                },
            };

            vm.grid3 = new ttGridFactory({
                rememberId: 'w_gridexportarg.' + $stateParams.argtype + '.' + $stateParams.argvalue + '.grid3',
            });//.setCss({ altColor: true, textWrapping: false, height: 'fill'});

            // #endregion VARIABLES & DEFINITIONS

            // #region OTHER

            var optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            var testValidation = function () {
                console.log('Test run successfully');
                //console.dir();
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
                //dynamicValidationModal: function (p2_datatask_keyno, dataItem, deferred)
                validationService.dynamicValidationModal(2604, { gln_number: "", businessco_no: 0, cust_name: "" }, 1);
                //validationService.dynamicValidationModal(2604, { gln_number: "", businessco_no: 0, cust_name: "" }).then(function (result) {
                //    console.dir(result);
                //});
            };

            var testToggle = function (index) {
                console.log('Test run successfully - ', index);

                //(dirty, filtered, sorted, viewOnly);
                switch (index) {
                    case 0:
                        console.dir(vm.grid.gridfunc.getRows());
                        break;
                    case 1:
                        console.dir(vm.grid.gridfunc.getRows(true, false, true));
                        break;
                    case 2:
                        console.dir(vm.grid.gridfunc.getRows(false, false, false, true));
                        break;
                    case 3:
                        console.dir(vm.grid.gridfunc.getRows(false, true));
                        break;
                }

            };

            var testItems = function (data) {
                console.log('Test run successfully');
                console.dir(data);
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
            };

            var testHidden = function (data) {
                //console.log('Test run successfully');
                //console.dir(data);

                if (angular.isUndefined(data)) return false;

                if (data.item_parms === '6570100' || data.item_parms === '6666000') return true;

                if (data.item_parms === '7477700') return 'hidden';

                return false;
            };

            var test2Hidden = function (data) {
                //console.log('Test run successfully');
                //console.dir(data);

                if (angular.isUndefined(data)) return false;

                if (data.item_parms === '6857000') return true;

                if (data.item_parms === '7161000' || data.item_parms === '7029200') return 'hidden';

                return false;
            };

            var loadGrid2 = function () {
                if (vm.model.setting.grid_count >= 2 && utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno_2) === true && utilityService.validateParmsValue(vm.model.setting.p2_exportproc_keyno) === true) {
                    vm.grid2.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno_2,
                        parameters: vm.model.selectedRow
                    };
                    vm.model.grid2Ready = true;
                }
                if (vm.grid2.gridfunc !== null && vm.model.grid2Ready) {
                    vm.grid2.gridfunc.read();                    
                }
                
            };

            var loadGrid3 = function () {
                if (vm.model.setting.grid_count >= 3 && utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno_3) === true && utilityService.validateParmsValue(vm.model.setting.p2_exportproc_keyno) === true) {
                    vm.grid3.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno_3,
                        parameters: vm.model.selectedRow2
                    };
                    vm.model.grid3Ready = true;
                }
                if (vm.grid3.gridfunc !== null && vm.model.grid3Ready) {
                    vm.grid3.gridfunc.read();
                }
                
            };

            vm.onGridBoxResized = function () {
                vm.grid?.gridfunc?.resize();
                vm.grid2?.gridfunc?.resize();
                vm.grid3?.gridfunc?.resize();
            }

            // #endregion OTHER

            // #region LOAD PROCEDURE CALLS

            angular.element(document).ready(function () {
                gridExportArgService.loadGridExportArgGet(vm.model.argType, vm.model.argValue, vm.model.p2ExportProcKeyNo).then(function (data) {
                    angular.copy(data[0], vm.model.setting);
                    stateService.setTitleOverride(vm.model.setting.p2_exportproc_name);
                    console.dir('exportgrid: ', data[0])
                    if (angular.isDefined(vm.model.setting.view_id) && vm.model.setting.view_id !== '') {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.setting.p2_exportproc_keyno) === true) {

                        vm.grid.dataTask.loadData = {
                            method: vm.model.setting.p2_datatask_keyno,
                            parameters: { p2_exportproc_keyno: vm.model.setting.p2_exportproc_keyno, ...vm.model.selectedRow }
                        }
                        vm.model.gridReady = true;
                    }

                    if (vm.model.setting.grid_count < 3 && !!vm.grid2?.config?.kendo) {
                        vm.grid2.config.kendo.selectable = null;
                    }

                    if (vm.model.setting.grid_count < 2 && !!vm.grid?.config?.kendo) {
                        vm.grid.config.kendo.selectable = null;
                    }

                    loadOrderStatuses();
                    loadOrderTypes();
                    loadCompanies();
                    loadStockPlaces();
                    loadBusinessSites();
                    loadCoops();
                    loadActiveStatuses();

                    //if (vm.model.setting.autoretrieve === '1') {
                    //    vm.executeGridExportArg();
                    //}
                });
            });


            let loadOrderStatuses = function () {
                logisticService.listOrderStatuses({ add_all: '1' }).then(function (result) {
                    console.log('ORDERSTATUS', result);
                    angular.copy(result, vm.model.selectListOrderStatuses);
                });
            };

            let loadOrderTypes = function () {
                logisticService.listOrderTypes({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListOrderTypes);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return logisticService.listStocks({
                    businessco_no: vm.model.setting.businessco_no,
                    add_all: '1'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            var loadBusinessSites = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.model.selectListBusinessSites = [];

                economyService.listBusinessSites({
                    businessco_no: vm.model.setting.businessco_no,
                    add_all: '1'
                }).then(function (data) {
                    angular.copy(data, vm.model.selectListBusinessSites);
                });
            };
            let loadCoops = function () {
                gridExportArgService.genericFunction(639, { add_all: '1' }).then(function (data) {
                    angular.copy(data, vm.model.selectListCoops);
                });
            };

            let loadActiveStatuses = function () {
                gridExportArgService.genericFunction(3450, { add_all: '1' }).then(function (data) {
                    angular.copy(data, vm.model.selectListActiveStatuses);
                });
            }

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '_' + vm.model.setting.p2_exportproc_keyno;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'coop_id':
                    case 'orderstatus_id':
                    case 'ordertype_id':
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'supplier_no':
                    case 'project_keyno':
                    case 'cust_no':
                    case 'labour_no':
                    case 'businesssite_keyno':
                    case 'order_internal_no':
                    case 'collection_keyno':
                    case 'activestatus_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                    case 'order_order_no_and_cust_name':
                    case 'collection_name':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;

                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON SEARCH FUNCTION

            vm.searchGrid = function () {
                vm.model.searching = true;

                rememberFunc('searchtext');
                vm.executeGridExportArg();
            };

            // #endregion BUTTON SEARCH FUNCTION

            // #region EXECUTE BUTTON FUNCTION

            vm.executeGridExportArg = function () {
                if (vm.model.searching) {
                    vm.model.searching = false;
                }
                console.dir(vm.grid.gridfunc.getSelectedRows());

                vm.grid.gridfunc.read();
            };

            // #endregion EXECUTE BUTTON FUNCTION

            // #region LOOKUPSEARCH FUNCTIONS

            vm.searchOrder = function (value) {
                return gridExportArgService.searchOrderKeyname(value);
            };

            // #endregion LOOKUPSEARCH FUNCTIONS

            // #region SELECTED VALUE FUNCTION

            vm.selectOrderItem = function (item, model) {
                if (item?.order_id && item.order_id !== '') {
                    console.dir(item);
                    vm.model.setting.order_internal_no = item.order_id;
                    vm.model.setting.order_order_no_and_cust_name = item.order_keyname;

                    if (vm.model.setting.order_order_no_and_cust_name > '') {
                        rememberFunc('order_order_no_and_cust_name');
                        rememberFunc('order_internal_no');
                        vm.grid.gridfunc.read();
                    } else {
                        vm.onClearOrderSearch();
                    }
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedOrderSearch = function (value, item) {
                vm.model.setting.order_order_no_and_cust_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.setting.order_internal_no = item.item_id;
                    }
                } else {
                    vm.onClearOrderSearch();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClearOrderSearch = function () {
                vm.model.setting.order_internal_no = '0';
                vm.model.setting.order_order_no_and_cust_name = '';

                if (vm.model.setting.order_order_no_and_cust_name === '') {
                    rememberFunc('order_order_no_and_cust_name');
                    vm.grid.gridfunc.read();
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            vm.searchCollection = function (value) {
                return gridExportArgService.searchCollectionKeyname(value);
            };

            vm.selectCollectionItem = function (item, model) {
                if (item?.collection_id && item.collection_id !== '') {
                    console.dir(item)
                    vm.model.setting.collection_keyno = item.collection_id;
                    vm.model.setting.collection_name = item.collection_keyname;

                    if (vm.model.setting.collection_name > '') {
                        rememberFunc('collection_name');
                        vm.grid.gridfunc.read();
                    } else {
                        vm.onClearCollection();
                    }
                }
            };

            // #region CHANGED VALUE FUNCTION

            vm.onChangedCollection = function (value, item) {
                vm.model.setting.collection_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.setting.collection_keyno = item.item_id;
                    }
                } else {
                    vm.onClearCollection();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClearCollection = function () {
                vm.model.setting.collection_keyno = '0';
                vm.model.setting.collection_name = '';

                if (vm.model.setting.collection_name === '') {
                    rememberFunc('collection_name');
                    vm.grid.gridfunc.read();
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'supplier_no':
                    case 'project_keyno':
                    case 'cust_no':
                    case 'labour_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);

                        if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();

                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        loadBusinessSites();

                        if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();

                        break;
                    case 'stockplace_id':
                    case 'coop_id':
                    case 'activestatus_id':
                    case 'orderstatus_id':
                    case 'ordertype_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        
                        if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();
                        
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.setting.autoretrieve === '1') vm.executeGridExportArg();
                        }
                        break;
                    case 'businesssite_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businesssite_keyno) !== true) return;

                        vm.model.setting.businesssite_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businesssite_keyno) !== true) return;

                        rememberFunc('businesssite_keyno');
                        vm.grid.gridfunc.read();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

        }]
    });
})();
