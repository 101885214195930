(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2DataTaskLog', {
        templateUrl: 'views/components/views/p2DataTaskLog/p2DataTaskLog.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2DataTaskLogKeyno: '<?'
        },
        controller: ['$stateParams', 'translateService', 'p2DataTaskLogService', function ($stateParams, translateService, p2DataTaskLogService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2DataTaskLogKeyno = vm?.p2DataTaskLogKeyno ?? $stateParams.p2_datatasklog_keyno;
            
            let translations = {
                copy_to_clipboard: ''
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            vm.model = {
                //highLightSearchButtons: [
                //    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.highlight.highlight_search = '' },
                //    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => highlightSearch() }
                //],
                p2DataTaskKeynoButtons: [
                    { id: 'copy', icon: 'fa fa-solid fa-copy', color: 'primary', type: 'primary', onClick: () => window.prompt(translations.copy_to_clipboard, vm.model.info.p2_datatask_keyno) }
                ],
                procedureNameButtons: [
                    { id: 'copy', icon: 'fa fa-solid fa-copy', color: 'primary', type: 'primary', onClick: () => window.prompt(translations.copy_to_clipboard, vm.model.info.proc_name) }
                ],
                info: {},
                //highlight: {
                //    access_highlight_search: '0',
                //    highlight_search: ''
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadInfo = function () {
                p2DataTaskLogService.getInfo({ p2_datatasklog_keyno: p2DataTaskLogKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.info);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            //let highlightSearch = function () {
            //    let searchTerm = vm.model.highlight.highlight_search.trim();
            //    let jsonDataIn = vm.model.info.jsondata_in;

            //    if (searchTerm) {
            //        // Create a regular expression to find all occurrences of the search term
            //        let regex = new RegExp(`(${searchTerm})`, 'gi');
            //        let highLightedJsonDataIn = jsonDataIn.replace(regex, '<span class="highlight">' + jsonDataIn + '</span>');

            //        console.log('highLightedJsonDataIn: ' + highLightedJsonDataIn);
                    
            //        //jsonDataIn = highLightedJsonDataIn;
            //        //vm.model.info.jsondata_in = jsonDataIn;
            //    }
            //};

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadInfo();
            };

            // #region ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2DataTaskLogKeyno?.currentValue) p2DataTaskLogKeyno = changes.p2DataTaskLogKeyno.currentValue;

                if (p2DataTaskLogKeyno) {
                    loadInfo();
                }
            };

            // #endregion ON CHANGES FUNCTION
        }]
    });
})();
