(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('paperLessPo', {
        templateUrl: 'views/components/views/paperLessPo/paperLessPo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'stateService', 'paperLessPoService', 'ttGridFactory', function ($q, utilityService, stateService, paperLessPoService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                gridReady: false,
                orderSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('ordersearch') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadGrid() }
                ],
                productSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('prodsearch') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadGrid() }
                ],
                setting: {}
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
		    // #endregion VARIABLES & DEFINITIONS

            // #region PROCEDURE CALL & RETRIEVE

            let loadSetting = function () {
                const deferred = $q.defer();

                paperLessPoService.getPaperLessPo().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion PROCEDURE CALL & RETRIEVE

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'ordersearch':
                    case 'prodsearch':
                        vm.model.setting[id] = '';

                        if (vm.model.setting[id] === '') loadGrid();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTIONS

            vm.searchOrders = function () {
                loadGrid();
            };

            vm.searchProductOrders = function () {
                loadGrid();
            };

            // #endregion SEARCH FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'ordersearch':
                    case 'prodsearch':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        if (vm.model.setting[id] === '') loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'pocreate':
                        go({ stock_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
