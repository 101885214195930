(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('postCalc', {
        templateUrl: 'views/components/views/postCalc/postCalc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'postCalcService', function ($stateParams, stateService, utilityService, postCalcService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                bgPostCalcMain: {
                    selected: 'SUM',
                    buttons: [
                        { id: 'SUM', label: 'bg_postcalc_main_sum', color: 'primary', onClick: () => vm.model.bgPostCalcMain.selected = 'SUM' },
                        { id: 'WORK', label: 'bg_postcalc_main_work', color: 'primary', onClick: () => vm.model.bgPostCalcMain.selected = 'WORK' }
                    ]
                },
                materialCostLocValButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('postcalcm', {
                            argtype: vm.model.edit.argtype,
                            argvalue: vm.model.edit.argvalue
                        })
                    }
                ],
                workHoursButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('postcalcw', {
                            argtype: vm.model.edit.argtype,
                            argvalue: vm.model.edit.argvalue
                        })
                    }
                ],
                edit: {},
                itemsListPostCalcWorks: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS
            
            async function loadEdit() {
                vm.model.edit = (await postCalcService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }))[0];
            }
            
            async function loadWorks() {
                vm.model.itemsListPostCalcWorks = (await postCalcService.listWorks({
                    argtype: argType,
                    argvalue: argValue
                }));
            }

            // #endregion LOAD PROCEDURE CALLS

            // #region RECALC FUNCTIONS
            
            let recalcMaterialCostLocValTotal = function () {
                vm.model.edit.material_cost_locval_total = utilityService.parseNumber(vm.model.edit.material_cost_locval) + utilityService.parseNumber(vm.model.edit.material_cost_corr_locval);

                if (angular.isDefined(vm.model.edit.material_cost_locval_total) && vm.model.edit.material_cost_locval_total !== '') {
                    recalcMaterialAmountLocValTotal();
                }
            };
            
            let recalcMaterialAmountLocValTotal = function () {
                vm.model.edit.material_amount_locval_total = utilityService.parseNumber(vm.model.edit.material_cost_locval_total) + (utilityService.parseNumber(vm.model.edit.material_cost_locval_total) * utilityService.parseNumber(vm.model.edit.material_addition_pst / 100));
            };
            
            let recalcWorkHoursTotal = function () {
                vm.model.edit.work_hours_total = utilityService.parseNumber(vm.model.edit.work_hours) + utilityService.parseNumber(vm.model.edit.work_hours_corr);

                if (angular.isDefined(vm.model.edit.work_hours_total)) {
                    recalcWorkCostLocVal();
                    recalcWorkAmountLocVal();

                    if (vm.model.edit.work_hours_total > 0) {
                        recalcWorkPricePrHourWorkFocus();
                    } else {
                        vm.model.edit.work_hours_total = 0;
                    }
                }
            };
            
            let recalcWorkCostLocVal = function () {
                // work_cost_locval = work_hours_total * work_cost_rate_locval
                vm.model.edit.work_cost_locval = utilityService.parseNumber(vm.model.edit.work_hours_total) * utilityService.parseNumber(vm.model.edit.work_cost_rate_locval);

                if (angular.isDefined(vm.model.edit.work_cost_locval)) {
                    recalcWorkMarginAmountLocVal();
                }
            };
            
            let recalcWorkAmountLocVal = function () {
                // work_amount_locval = work_hours_total * work_rate_locval
                vm.model.edit.work_amount_locval = utilityService.parseNumber(vm.model.edit.work_hours_total) * utilityService.parseNumber(vm.model.edit.work_rate_locval);

                if (angular.isDefined(vm.model.edit.work_amount_locval)) {
                    recalcWorkMarginAmountLocVal();
                }
            };
            
            let recalcWorkPricePrHourWorkFocus = function () {
                if (vm.model.edit.work_hours_total > 0) {
                    // work_price_pr_hour_workfocus = ( amount_order_locval - material_cost_locval_total ) / work_hours_total
                    vm.model.edit.work_price_pr_hour_workfocus = (utilityService.parseNumber(vm.model.edit.amount_order_locval) - utilityService.parseNumber(vm.model.edit.material_cost_locval_total)) / utilityService.parseNumber(vm.model.edit.work_hours_total);
                }
            };
            
            let recalcWorkMarginAmountLocVal = function () {
                // work_margin_amount_locval = work_amount_locval - work_cost_locval
                vm.model.edit.work_margin_amount_locval = utilityService.parseNumber(vm.model.edit.work_amount_locval) - utilityService.parseNumber(vm.model.edit.work_cost_locval);
            };
            
            let recalcMachineHoursTotal = function () {
                // machine_hours_total = machine_hours + machine_hours_corr
                vm.model.edit.machine_hours_total = utilityService.parseNumber(vm.model.edit.machine_hours) + utilityService.parseNumber(vm.model.edit.machine_hours_corr);

                if (angular.isDefined(vm.model.edit.machine_hours_total)) {
                    recalcMachineCostLocValTotal();
                    recalcMachineAmountLocVal();
                }
            };
            
            let recalcMachineCostLocValTotal = function () {
                // machine_cost_locval_total = machine_hours_total * machine_cost_rate_locval
                vm.model.edit.machine_cost_locval_total = utilityService.parseNumber(vm.model.edit.machine_hours_total) * utilityService.parseNumber(vm.model.edit.machine_cost_rate_locval);

                if (angular.isDefined(vm.model.edit.machine_cost_locval_total)) {
                    recalcMachineMarginAmountLocVal();
                }
            };
            
            let recalcMachineAmountLocVal = function () {
                // machine_amount_locval =  machine_hours_total * machine_rate_locval
                vm.model.edit.machine_amount_locval = utilityService.parseNumber(vm.model.edit.machine_hours_total) * utilityService.parseNumber(vm.model.edit.machine_rate_locval);

                if (angular.isDefined(vm.model.edit.machine_amount_locval)) {
                    recalcMachineMarginAmountLocVal();
                }
            };
            
            let recalcMachineMarginAmountLocVal = function () {
                // machine_margin_amount_locval = machine_amount_locval - machine_cost_locval_total
                vm.model.edit.machine_margin_amount_locval = utilityService.parseNumber(vm.model.edit.machine_amount_locval) - utilityService.parseNumber(vm.model.edit.machine_cost_locval_total);
            };

            // #endregion RECALC FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPostCalcMain = function (value) {
                vm.model.bgPostCalcMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'material_cost_corr_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.material_cost_corr_locval) !== true) return;

                        vm.model.edit.material_cost_corr_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.material_cost_corr_locval) !== true) return;

                        recalcMaterialCostLocValTotal();
                        break;
                    case 'material_cost_locval_total':
                        if (utilityService.validateWatchValue(value, vm.model.edit.material_cost_locval_total) !== true) return;

                        vm.model.edit.material_cost_locval_total = value;

                        if (utilityService.validateParmsValue(vm.model.edit.material_cost_locval_total) !== true) return;

                        recalcWorkPricePrHourWorkFocus();
                        break;
                    case 'material_addition_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit.material_addition_pst) !== true) return;

                        vm.model.edit.material_addition_pst = value;

                        if (utilityService.validateParmsValue(vm.model.edit.material_addition_pst) !== true) return;

                        recalcMaterialAmountLocValTotal();
                        break;
                    case 'work_hours_corr':
                        if (utilityService.validateWatchValue(value, vm.model.edit.work_hours_corr) !== true) return;

                        vm.model.edit.work_hours_corr = value;

                        if (utilityService.validateParmsValue(vm.model.edit.work_hours_corr) !== true) return;

                        recalcWorkHoursTotal();
                        break;
                    case 'work_rate_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.work_rate_locval) !== true) return;

                        vm.model.edit.work_rate_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.work_rate_locval) !== true) return;

                        recalcWorkAmountLocVal();
                        break;
                    case 'machine_hours_corr':
                        if (utilityService.validateWatchValue(value, vm.model.edit.machine_hours_corr) !== true) return;

                        vm.model.edit.machine_hours_corr = value;

                        if (utilityService.validateParmsValue(vm.model.edit.machine_hours_corr) !== true) return;

                        recalcMachineHoursTotal();
                        break;
                    case 'machine_cost_rate_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.machine_cost_rate_locval) !== true) return;

                        vm.model.edit.machine_cost_rate_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.machine_cost_rate_locval) !== true) return;

                        recalcMachineCostLocValTotal();
                        break;
                    case 'machine_rate_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.machine_rate_locval) !== true) return;

                        vm.model.edit.machine_rate_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.machine_rate_locval) !== true) return;

                        recalcMachineAmountLocVal();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadWorks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
