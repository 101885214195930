(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('serviceObjectTypeInts', {
        templateUrl: 'views/components/views/serviceObjectTypeInts/serviceObjectTypeInts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'serviceObjectTypeIntsService', function ($stateParams, stateService, serviceObjectTypeIntsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let serviceObjectTypeKeyno = $stateParams.serviceobjecttype_keyno;
            
            vm.model = {
                setting: {},
                itemsListIntervals: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                serviceObjectTypeIntsService.getSetting({ serviceobjecttype_keyno: serviceObjectTypeKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadIntervals = function () {
                serviceObjectTypeIntsService.listIntervals({ serviceobjecttype_keyno: serviceObjectTypeKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListIntervals);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobjecttypeint':
                        go({
                            serviceobjecttype_keyno: serviceObjectTypeKeyno,
                            serviceobjecttypeint_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadIntervals();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
