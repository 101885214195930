(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2Terminals', {
        templateUrl: 'views/components/views/p2Terminals/p2Terminals.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2TerminalsService', function (stateService, p2TerminalsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                itemsListTerminals: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadTerminals = function () {
                p2TerminalsService.listTerminals().then(function (list) {
                    angular.copy(list, vm.model.itemsListTerminals);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2terminal':
                        go({ p2_terminal_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTerminals();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
