(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('projects', {
        templateUrl: 'views/components/views/projects/projects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$window', 'stateService', 'utilityService', 'projectsService', 'logisticService', 'typeaheadService', 'ttGridFactory', 'rememberService', 'layoutService', 'ttPopoverModel', function ($stateParams, $q, $window, stateService, utilityService, projectsService, logisticService, typeaheadService, ttGridFactory, rememberService, layoutService, ttPopoverModel) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let variableNames = {
                bg_projects_activestatus: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                searchtext: '',
                project_keynos: ''
            };

            vm.model = {
                gridReady: false,
                gridEconomyReady: false,
                mobileSize: layoutService.getMediaQueries().tablet,
                bgProjectsActiveStatus: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'bg_projects_activestatus_active', color: 'primary', onClick: () => setProjectsActiveStatusView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'bg_projects_activestatus_notactive', color: 'primary', onClick: () => setProjectsActiveStatusView('NOTACTIVE') },
                        { id: 'ECONOMY', label: 'bg_projects_activestatus_economy', color: 'primary', onClick: () => setProjectsActiveStatusView('ECONOMY') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => onSearch('searchtext') }
                ],
                projectsButtons: [
                    {
                        id: 'erase',
                        icon: 'fas fa-eraser',
                        type: 'danger',
                        color: 'danger',
                        onClick: () => {
                            reset('project_keynos');
                        }
                    },
                    { id: 'select', icon: 'fas fa-list', type: 'primary', color: 'primary', onClick: () => console.log('open popover') }
                ],
                setting: {},
                selectListCompanies: [],
                itemsListProjects: []
            };

            vm.popoverProjects = new ttPopoverModel('projectspopoverselect').addChecklist({
                title: 'new Title',
                required: false,
                list: [],
                onChecklistChanged: (list, selectedIds) => {
                    vm.model.setting.project_keynos = createSelectedString(selectedIds);
                    //rememberFunc('project_keynos');
                }
            }).onClose(() => {
                rememberFunc('project_keynos');

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                        loadGridEconomy();
                    } else {
                        loadGrid();
                    }
                } else {
                    loadProjects();
                }
            });

            function createSelectedString(list) {
                let selectedString = '';
                list.forEach((id) => selectedString += id + ',')
                return selectedString;
            }

            vm.grid = new ttGridFactory({
                rememberId: 'w_projects_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            vm.gridEconomy = new ttGridFactory({
                rememberId: 'w_projects_economy_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProjectsActiveStatusView = function (id) {
                vm.model.bgProjectsActiveStatus.selected = vm.model.setting.bg_projects_activestatus = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                projectsService.getProjectSettings({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    setProjectsActiveStatusView(vm.model.setting.bg_projects_activestatus);
                    loadPopoverProjects();

                    if (vm.model.mobileSize.matches === true) {
                        if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                            loadGridEconomy();
                        } else {
                            loadGrid();
                        }
                    } else {
                        loadProjects();
                    }

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadProjects = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_projects_activestatus) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListProjects = [];

                return projectsService.listProjects(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListProjects);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2748,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadGridEconomy = function () {
                vm.gridEconomy.dataTask.loadData = {
                    method: 3482,
                    parameters: vm.model.setting
                };

                vm.model.gridEconomyReady = true;

                if (vm.gridEconomy.gridfunc !== null) vm.gridEconomy.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            async function loadPopoverProjects() {
                vm.popoverProjects.data[0].data.list = (await typeaheadService.lookUpProjectNames({ "bg_projects_activestatus": "ALL", businessco_no: vm.model.setting.businessco_no, "searchtext": '', "argtype": "0", "argvalue": "0" })).map((item) => ({ ...item, is_selected: false }));
            }

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '/' + argType + '/' + argValue + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_projects_activestatus':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                    case 'project_keynos':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.fetchData = function (model) {
                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                        loadGridEconomy();
                    } else {
                        loadGrid();
                    }
                } else {
                    loadProjects();
                }
            }

            // #region BUTTON GROUP FUNCTION

            vm.selectBgProjectsActiveStatus = function (value) {
                vm.model.bgProjectsActiveStatus.selected = value;
                vm.model.setting.bg_projects_activestatus = vm.model.bgProjectsActiveStatus.selected;

                rememberFunc('bg_projects_activestatus');

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                        loadGridEconomy();
                    } else {
                        loadGrid();
                    }
                } else {
                    loadProjects();
                }
            };

            // #region BUTTON GROUP FUNCTION

            // #region BUTTON LIST SINGLE FUNCTION

            vm.searchItems = function () {
                rememberFunc('searchtext');

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                        loadGridEconomy();
                    } else {
                        loadGrid();
                    }
                } else {
                    loadProjects();
                }
            };

            // #endregion BUTTON LIST SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'project':
                        go({ project_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    if (vm.model.mobileSize.matches === true) {
                        if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                            loadGridEconomy();
                        } else {
                            loadGrid();
                        }
                    } else {
                        loadProjects();
                    }
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.mobileSize.matches === true) {
                                if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                                    loadGridEconomy();
                                } else {
                                    loadGrid();
                                }
                            } else {
                                loadProjects();
                            }
                        }
                        break;
                    case 'project_keynos':
                        if (utilityService.validateWatchValue(value, vm.model.setting.project_keynos) === true) {

                            vm.model.setting.project_keynos = value;

                            selectIdsInList(vm.popoverProjects.data[0].data.list, value);

                            if (utilityService.validateParmsValue(vm.model.setting.project_keynos) !== true) return;

                            if (vm.model.setting.project_keynos > '') {
                                rememberFunc('project_keynos');

                                if (vm.model.mobileSize.matches === true) {
                                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                                        loadGridEconomy();
                                    } else {
                                        loadGrid();
                                    }
                                } else {
                                    loadProjects();
                                }
                            }
                        }
                        break;
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        //rememberFunc('businessco_no');

                        if (vm.model.mobileSize.matches === true) {
                            if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                                loadGridEconomy();
                            } else {
                                loadGrid();
                            }
                        } else {
                            loadProjects();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            let onSearch = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.searchItems();
                        break;
                    default:
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.mobileSize.matches === true) {
                                if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                                    loadGridEconomy();
                                } else {
                                    loadGrid();
                                }
                            } else {
                                loadProjects();
                            }
                        }
                        break;
                    case 'project_keynos':
                        vm.model.setting.project_keynos = '';

                        if (vm.model.setting.project_keynos === '') {
                            rememberFunc('project_keynos');

                            if (vm.model.mobileSize.matches === true) {
                                if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                                    loadGridEconomy();
                                } else {
                                    loadGrid();
                                }
                            } else {
                                loadProjects();
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region ON INIT FUNCTION

            let onWindowResize = function () {
                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgProjectsActiveStatus.selected === 'ECONOMY') {
                        loadGridEconomy();
                    } else {
                        loadGrid();
                    }
                } else {
                    loadProjects();
                }
            };

            let onDestroy = [];

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                $window.addEventListener('resize', onWindowResize);

                // JLR 20240927 - no need to call this here.
                //onWindowResize();
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
