(function () {
	'use strict';

	var module = angular.module("imApp");

    module.factory("consignmentPlansOrdersService", ['$q', '$timeout', 'p2DataTaskService', function ($q, $timeout, p2DataTaskService) {
		var service = {
            loadOrders: function (parms) {
                var deferred = $q.defer();

                p2DataTaskService.call(1753, parms)
                    .then(function (response) {
                        deferred.resolve(response);
                    }, function (error) {
                        console.log('error');
                        console.dir(error);

                        deferred.resolve();
                    });

                return deferred;
			},
            linkOrder: function (parms) {
				return p2DataTaskService.call(1754, parms);
			},
            createConsignment: function (parms) {
				return p2DataTaskService.call(1755, parms);
			},
			updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
			}
		};

		return service;
	}]);
})();
