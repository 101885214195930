import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '@app/core/components/component-base/component-base.component';
import { DataTaskService } from '@app/core/services/data-task.service';

@Component({
    selector: 'tt-p2-update-script-edit',
    templateUrl: './p2-update-script-edit.component.html',
    styleUrls: ['./p2-update-script-edit.component.css'],
})
export class P2UpdateScriptEditComponent extends ComponentBaseComponent implements OnInit, OnChanges {
    /**
     * The keyno of the update script to edit.
     */
    @Input()
    public p2UpdateScriptKeyno?: number | string;

    /**
     * Whether the save button is displayed in the form or not.
     */
    @Input()
    public get hideSaveButton(): boolean {
        return this._hideSaveButton;
    }
    public set hideSaveButton(value: BooleanInput) {
        this._hideSaveButton = coerceBooleanProperty(value);
    }
    private _hideSaveButton = false;

    /**
     * Event emitted when the update script has successfully been saved.
     */
    @Output()
    public onSave = new EventEmitter();

    public get?: P2UpdateScriptEditGet;

    public runbatchList: RunbatchItem[] = [];

    public saving = false;

    constructor(private datatask: DataTaskService) {
        super();
    }

    public async save() {
        if (!!this.get) {
            this.saving = true;

            try {
                const encodedGet: Partial<P2UpdateScriptEditGet> = {};

                for (let [key, value] of Object.entries(this.get)) {
                    encodedGet[key as keyof P2UpdateScriptEditGet] = this.base64UrlEncodeUnicode(value);
                }

                await this.datatask.Post(192, encodedGet);

                this.onSave.emit();
            } finally {
                this.saving = false;
            }
        }
    }

    public async loadRunbatchList() {
        this.runbatchList = await this.datatask.Post(210, { add_all: '0' });
    }

    public async loadUpdateScript() {
        if (this.p2UpdateScriptKeyno !== undefined && this.p2UpdateScriptKeyno !== null) {
            this.get = (await this.datatask.Post(191, { p2_updatescript_keyno: this.p2UpdateScriptKeyno }))[0];
            this.get!.runbatch = this.base64UrlDecode(this.get?.runbatch || '');
            this.get!.updatescript = this.base64UrlDecode(this.get?.updatescript || '');
        }
    }

    ngOnInit(): void {
        this.loadRunbatchList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['p2UpdateScriptKeyno'] && changes['p2UpdateScriptKeyno'].currentValue !== changes['p2UpdateScriptKeyno'].previousValue) {
            this.loadUpdateScript();
        }
    }
}

interface P2UpdateScriptEditGet {
    p2_updatescript_keyno: string;
    updatescript: string;
    runorder: string;
    runbatch: string;
    remarks: string;
    reg_datetime: string;
    mod_datetime: string;
}

interface RunbatchItem {
    runbatch: string;
    runbatch_name: string;
    orderby: number;
}
