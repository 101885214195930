<!-- Outer container, applying group-level styles -->
<div class="tt-input__container form-group imrl-0i col-xs-12 sp-0"
     [ngStyle]="model.groupStyle">

    <div class="tt-input__base col-xs-12 sp-0" ng-class="model.baseClasses">
        <!-- LABELS -->
        <label *ngIf="label"
               class="tt-input__label"
               [ngClass]="model.baseClasses">
            {{ label }}
            <span *ngIf="hasQFlag" class="tt-input__label--required">*</span>
        </label>

        <label *ngIf="plabel"
               class="tt-input__label"
               [ngClass]="model.baseClasses">
            {{ plabel }}
            <span *ngIf="hasQFlag" class="tt-input__label--required">*</span>
        </label>

        <label *ngIf="sublabel"
               class="tt-input__sublabel"
               [ngClass]="model.baseClasses">
            {{ sublabel }}
        </label>

        <!-- Main group wrapper -->
        <div class="tt-input__group"
             [ngClass]="model.baseClasses"
             [class.tt-input__group--readonly]="hasRFlag">

            <!-- ============= SELECT ============= -->
            <div *ngIf="type === 'select'"
                 class="col-xs-12 sp-0 im-no-pad">
                <select id="{{ model.mId }}"
                        class="tt-input__form-control tt-input__form-control--select"
                        [ngStyle]="model.selectStyle"
                        [disabled]="hasRFlag"
                        [ngModel]="model.field"
                        (ngModelChange)="onSelectChange($event)">
                    <option *ngFor="let item of data"
                            [ngValue]="item[dataid || 'id']">
                        {{ item[dataname || 'name'] }}
                    </option>
                </select>
            </div>

            <!-- ============= TEXTAREA ============= -->
            <div *ngIf="type === 'textarea'"
                 class="im-no-pad">
                <textarea #textareaRef
                          [id]="model.mId"
                          class="tt-input__form-control tt-input__form-control--textarea msd-elastic"
                          [ngStyle]="model.inputStyle"
                          [readOnly]="hasRFlag"
                          [value]="model.field"
                          (focus)="onFocus($event)"
                          (blur)="onBlur($event)"
                          (input)="onInputChange($event)"
                          rows="2"></textarea>
            </div>

            <!-- ============= MESSAGE / "type === 'message'" ============= -->
            <div *ngIf="type === 'message'"
                 class="im-no-pad">
                <div class="tt-input__message"
                     [class.tt-input__message--current-user]="myuser === '1'"
                     [innerHTML]="model.field"
                     [ngStyle]="model.inputStyle">
                </div>
            </div>

            <!-- ============= CHECKBOX (large or small) ============= -->
            <div *ngIf="type === 'checkbox' || type === 'checkbox_s'"
                 class="im-no-pad">
                <input id="{{ model.mId }}"
                       type="checkbox"
                       class="tt-input__form-control tt-input__form-control--checkbox"
                       [ngStyle]="model.checkboxStyle"
                       [checked]="model.field == ieTrueValue"
                       [disabled]="hasRFlag"
                       (change)="onCheckboxChangeLegacy($event)" />
            </div>

            <!-- ============= SEARCH ============= -->
            <div *ngIf="type === 'search'"
                 class="search-field"
                 style="display: flex;">
                <!-- If 'f' => typeahead, else normal search -->
                <input [id]="model.mId"
                       type="search"
                       [value]="model.field"
                       [placeholder]="placeholder"
                       [readOnly]="hasRFlag"
                       (focus)="onFocus($event)"
                       (blur)="onBlur($event)"
                       (input)="onSearchChange($event)"
                       [ngStyle]="model.inputStyle" />

                <!-- Buttons, if 'o', 'b', 'k', 'c' etc. -->
                <!-- Example: clear button if 'c' -->
                <button *ngIf="hasCFlag && !hasRFlag"
                        class="tt-input__button tt-input__button--danger"
                        type="button"
                        (click)="clearInput()">
                    <span [ngClass]="addGlyph('remove')"></span>
                </button>

                <!-- Example: barcode if 'k' -->
                <button *ngIf="hasKFlag && !hasOFlag"
                        class="tt-input__button tt-input__button--primary"
                        type="button"
                        (click)="onBarcodeClick()">
                    <span [ngClass]="addGlyph('barcode')"></span>
                </button>

                <!-- Example: dynamic lines if 'o' -->
                <ng-container *ngIf="hasBFlag && hasOFlag">
                    <button *ngFor="let line of btnList; let i = index"
                            [ngClass]="addBtnStyle(i)"
                            type="button"
                            (click)="goToButton(i)">
                        <span [ngClass]="addGlyph((line.glyph ?? ''))" class="fa-fw"></span>
                    </button>
                </ng-container>
            </div>

            <!-- ============= SIGNATURE ============= -->
            <div *ngIf="type === 'signature'"
                 class="im-no-pad"
                 style="cursor: pointer"
                 (click)="onSignatureClick()">
                <ng-container *ngIf="!model.field || model.field === ''">
                    <input [id]="model.mId"
                           [readOnly]="hasRFlag ? '' : null"
                           [placeholder]="placeholder"
                           class="tt-input__form-control"
                           [ngStyle]="model.inputStyle"
                           (focus)="onFocus($event)"
                           (blur)="onBlur($event)"
                           (click)="onSignatureClick()" />
                </ng-container>
                <ng-container *ngIf="model.field && model.field !== ''">
                    <img [src]="model.field" style="max-height: 92px;">
                </ng-container>
            </div>

            <!-- ============= HTML ============= -->
            <div *ngIf="type === 'html'"
                 class="im-no-pad"
                 [ngStyle]="model.inputStyle">
                <div [innerHTML]="model.field"></div>
            </div>

            <!-- ============= DATETIME ============= -->
            <div *ngIf="type === 'datetime'"
                 style="display: flex; gap: 5px;">
                <input #dtDate
                       [id]="model.mId"
                       type="date"
                       [disabled]="hasRFlag"
                       (change)="onDatetimeDateChange(dtDate.value)"
                       [value]="(dateValue ? (dateValue | date:'yyyy-MM-dd') : '')"
                       [ngStyle]="model.inputStyle"
                       style="width: 150px;" />

                <select [disabled]="hasRFlag"
                        [(ngModel)]="hourValue"
                        (ngModelChange)="onDatetimeHourChange($event)"
                        [ngStyle]="model.selectStyle">
                    <option *ngFor="let hh of model.hours"
                            [value]="hh">
                        {{ hh }}
                    </option>
                </select>

                <select [disabled]="hasRFlag"
                        [(ngModel)]="minuteValue"
                        (ngModelChange)="onDatetimeMinuteChange($event)"
                        [ngStyle]="model.selectStyle">
                    <option *ngFor="let mm of minutesList"
                            [value]="mm">
                        {{ mm }}
                    </option>
                </select>
            </div>

            <!-- ============= PLAIN INPUT (DEFAULT) ============= -->
            <div *ngIf="type === 'input'"
                 class="im-no-pad col-xs-12"
                 style="display: flex; align-items: center; gap: 5px;"
                 [ngClass]="addClass('inputStart')">

                <ng-container *ngIf="!hasBFlag">
                    <!--Input, plain, with clear and/or select //typevar = null / 'c' / 's' //'c' does not work with 'b'-->
                    <input [id]="model.mId"
                           [type]="hasPFlag ? 'password' : 'text'"
                           class="tt-input__form-control"
                           [ngClass]="addClass()"
                           [(ngModel)]="model.field"
                           (input)="onInputChange($event)"
                           [ngStyle]="model.inputStyle"
                           [placeholder]="model.placeholder"
                           [readOnly]="hasRFlag"
                           (focus)="onFocus($event)"
                           (blur)="onBlur($event)"
                           (tabindex)="hasRFlag ? -1 : null" />
                </ng-container>

                <ng-container *ngIf="hasBFlag">
                    <div class="col-xs-12 sp-0" style="display: flex;">
                        <ng-container *ngIf="hasUFlag">
                            <!-- up/down for 'u' -->
                            <button class="tt-input__button tt-input__button--primary"
                                    [ngStyle]="{'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize']}"
                                    (click)="goDown()">
                                <span [ngClass]="addGlyph('down')"></span>
                            </button>
                        </ng-container>

                        <span *ngIf="icons"
                              class="tt-input__icon">
                            <span [ngClass]="addGlyph('icons')" class="fa-fw"></span>
                        </span>

                        <input [id]="model.mId"
                               [ngClass]="addClass()"
                               [type]="hasPFlag ? 'password' : 'text'"
                               [(ngModel)]="model.field"
                               (input)="onInputChange($event)"
                               [ngStyle]="model.inputStyle"
                               [placeholder]="model.placeholder"
                               (focus)="onFocus($event)"
                               (blur)="onBlur($event)"
                               [readOnly]="hasRFlag"
                               (tabindex)="hasRFlag ? -1 : null"
                               (click)="(!hasPFlag && hasRFlag) ? goTo() : null" />

                        <ng-container *ngIf="hasUFlag">
                            <button class="tt-input__button tt-input__button--primary"
                                    [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                    (click)="goUp()">
                                <span [ngClass]="addGlyph('up')"></span>
                            </button>
                        </ng-container>

                        <!-- clear => c -->
                        <button *ngIf="hasCFlag && !hasRFlag"
                                class="tt-input__button tt-input__button--danger"
                                type="button"
                                [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                (click)="clearInput()">
                            <span class="fa-fw" [ngClass]="addGlyph('remove')"></span>
                        </button>

                        <!-- barcode => k -->
                        <button *ngIf="hasKFlag && !hasRFlag"
                                class="tt-input__button tt-input__button--primary"
                                type="button"
                                [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                (click)="onBarcodeClick()">
                            <span class="fa-fw" [ngClass]="addGlyph('barcode')"></span>
                        </button>

                        <!-- n => calc -->
                        <button *ngIf="hasNFlag && !hasRFlag && !hasOFlag"
                                class="tt-input__button tt-input__button--primary"
                                type="button"
                                [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                (click)="onCalcClick()">
                            <span class="fa-fw" [ngClass]="addGlyph('calc')"></span>
                        </button>

                        <!-- 'o' => multiple lines from btnList -->
                        <ng-container *ngIf="hasOFlag">
                            <button *ngFor="let line of btnList; let i = index"
                                    [ngClass]="addBtnStyle(i)"
                                    type="button"
                                    [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                    (click)="goToButton(i)">
                                <span *ngIf="hasGFlag"
                                      [ngClass]="addGlyph((line.glyph ?? ''))"
                                      class="fa-fw"
                                      [ngStyle]="{'fontSize': model.inputStyle['fontSize']}">
                                </span>
                            </button>
                        </ng-container>

                        <button *ngIf="!hasOFlag && hasGFlag"
                                class="tt-input__button tt-input__button--primary"
                                type="button"
                                [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }"
                                (click)="goTo()">
                            <span [ngClass]="addGlyph()"
                                  class="fa-fw">
                            </span>
                        </button>

                        <!-- 't' => telephone link, 'e' => email link -->
                        <a *ngIf="hasTFlag"
                           class="tt-input__button tt-input__button--primary"
                           [href]="'tel:' + model.field"
                           [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }">
                            <span class="glyphicon glyphicon-phone"
                                  [ngStyle]="{'fontSize': model.inputStyle['fontSize']}"></span>
                        </a>

                        <a *ngIf="hasEFlag"
                           class="tt-input__button tt-input__button--primary"
                           [href]="'mailto:' + model.field"
                           [ngStyle]="{ 'height': model.inputStyle['height'], 'fontSize': model.inputStyle['fontSize'] }">
                            <span class="glyphicon glyphicon-envelope"
                                  [ngStyle]="{'fontSize': model.inputStyle['fontSize']}"></span>
                        </a>
                    </div>
                </ng-container>
            </div>

        </div> <!-- end tt-input__group -->
    </div> <!-- end tt-input__base -->
</div> <!-- end tt-input__container -->
