<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.textarea"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="textarea"
>
    <div>
        <textarea
            #inputRef
            [attr.id]="id.textarea"
            matInput
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="1"
            class="tt-input__form-control tt-input__form-control--textarea"
            (focus)="selectText()"
            (blur)="ttOnBlur.emit()"
            [(ngModel)]="ttModel"
            (ngModelChange)="onModelChange($event)"
            [readOnly]="ttReadonly"
            [disabled]="ttDisabled"
            [ngStyle]="style['textarea']"
        ></textarea>
        <span *ngIf="ttInvalid" class="fa-fw fas fa-exclamation-circle tt-input__icon tt-input__icon--invalid tt-input__icon-textarea--invalid"></span>
    </div>
</tt-form-field-base>
