(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectLabourPu', {
        templateUrl: 'views/components/views/selectLabourPu/selectLabourPu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectLabourPuService', function ($stateParams, selectLabourPuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let webPageName = $stateParams.webpagename;

            vm.model = {
                itemsListRegisteredByLabours: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadRegisteredByLabours = function () {
                selectLabourPuService.listRegisteredByLabours({ webpagename: webPageName }).then(function (list) {
                    angular.copy(list, vm.model.itemsListRegisteredByLabours);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region SAVE FUNCTION

            vm.saveToPrevious = function (item) {
                if (webPageName === 'oeedata') {
                    selectLabourPuService.savingToPreP2SelectLabourPuSave = true;

                    selectLabourPuService.saveItem({
                        labour_no: item.item_id,
                        webpagename: webPageName
                    });
                } else {
                    selectLabourPuService.saveItem({
                        labour_no: item.item_id,
                        webpagename: webPageName
                    });
                }
            };

            // #endregion SAVE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadRegisteredByLabours();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
