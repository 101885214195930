import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';


(function () {
    'use strict';

    let module = angular.module('imApp');

    module.directive('accountingProcessingAttestationModal', ['$stateParams', 'layoutService', 'utilityService', 'modalService', 'accountingProcessingAttestationModalService', 'translateService', 'graphAuthService', 'graphMailService', 'base64', function ($stateParams, layoutService, us, modalService, accountingProcessingAttestationModalService, translateService, graphAuthService, graphMailService, base64) {
        return {
            restrict: 'E',
            templateUrl: 'views/components/views/accountingProcessing/accountingProcessing.template.attestationModal.html?v=' + module.version,
            scope: {
                ttRelativeElementId: '@',   // Id of the element to place the popover relative to.
                ttOpen: '=',                // Whether or not the popover is visible.
                ttModel: '<',               // Data needed for the modal.
                ttType: '@',                // 'bookeep', 'send', 'approve', 'history' or 'reject'.
                ttAttestantsRequired: '<',  // Whether it is required to add attestants.
                ttShowAttestants: '<',      // Whether to show the list of attestants.
                ttParameter: '<',           // The paramterer, a custact_no, used for procedure calls.
                ttIsAccountant: '&',        // Whether the current user is an accountant.
                ttOnSuccess: '&',           // Function to call when requests goes through successfully
                ttAccountingFunction: '<',
            },
            link: function (scope, element) {
                //controller: ['$stateParams', '$window', '$element', 'layoutService', 'utilityService', 'modalService', 'accountingProcessingAttestationModalService', 'translateService', function ($stateParams, $window, $element, layoutService, us, modalService, accountingProcessingAttestationModalService, translateService) {

                // #region VARIABLES & DEFINITIONS

                //let vm = scope;
                let onDestroy = [];

                scope.id = {
                    modal: uuid(),
                }

                scope.style = {
                    modal: {},
                    arrow: {},
                    label: {
                        fontSize: '',
                    },
                    input: {
                        textarea: { padding: '4px' },
                    },
                    message: {
                        label: {
                            size: 'textSizeS'
                        },
                        sublabel: {
                            size: 'textSizeS'
                        },
                        message: {
                            size: 'textSize',
                            padding: '4px',
                        },
                    },
                    checklist: {
                        minHeight: '50px',
                        maxHeight: '250px',
                    }
                };

                scope.class = {
                    label: '',
                    arrow: '',
                };

                scope.translations = {
                    attestants: 'attestants'
                }

                scope.model = {
                    custactac_keyno: scope.ttModel?.custactac_keyno,
                    filterplaceholder: 'filterplaceholder',
                    comments: [],
                    comment: '',
                    attestants: {
                        name: 'attestant',
                        info: {
                            label: 'attestant_label',
                            placeholder: '',
                            type: 'list',
                            translate: false,
                        },
                        data: {
                            list: [],
                            id: 'item_id',
                            name: 'item_name',
                            filter: '',
                            allSelected: false
                        },
                    },
                    sendToAttestationLocked: true,
                    approveAttestationLocked: false,
                    bookkeepLocked: false,
                    reverseLocked: false,
                    reverse_voucherdate: scope.ttModel?.voucherdate ?? new Date().toISOString().substring(0, 10),
                    reverse_transdate: scope.ttModel?.transdate ?? new Date().toISOString().substring(0, 10),
                    acvouchertype_id: scope.ttModel?.acvouchertype_id,
                    no_accrual_reverse: false,
                    rejectAttestationLocked: true,
                };

                scope.dateOptions = {
                    voucher: {
                        date: { show: true, disabled: false, align: 'left' },
                        hour: { show: false, disabled: false, align: 'right' },
                        minute: { show: false, disabled: false, align: 'right', interval: 1 },
                    },
                }

                // #endregion VARIABLES & DEFINITIONS


                /**
                 * Searches recursively through children of the given target to find
                 * the given element. Returns true if the element is in the target's tree
                 * false if not
                 * 
                 * @param element the element to find in the target's tree.
                 * @param target the target to search in.
                 * @returns {boolean} true if the element is in the target's tree false if not.
                 */
                function isElementInTarget(element, target) {
                    if (element === target) return true;

                    for (let i = 0; i < element.children.length; i++) {
                        let child = element.children[i];
                        let found = isElementInTarget(child, target);

                        if (found) {
                            return true;
                        }
                    };

                    return false;
                }

                /**
                 * Resets the values in the modal.
                 */
                function resetModal() {
                    scope.model.attestants.data.list = scope.model.attestants.data.list.map((attestant) => attestant.is_selected = false);
                    scope.model.comments = [];
                    scope.model.comment = '';
                    scope.toggleLocks();

                }

                scope.onValuesChanged = function (value, modelId) {
                    scope.model[modelId] = value;
                    scope.toggleLocks();
                }

                scope.toggleLocks = function () {
                    scope.model.sendToAttestationLocked = scope.model.attestants.data.list.filter((attestant) => attestant.is_selected === true).length === 0;
                    scope.model.rejectAttestationLocked = scope.model.comment.trim().length === 0;
                }

                /**
                 * Closes the modal if the even happened outside of the modal element.
                 */
                scope.closeModal = function (event) {
                    let modal = document.getElementById(`${scope.id.modal}`);
                    let targetInModal = false;

                    if (!event) {
                        resetModal();
                        scope.ttOpen = false;
                        return;
                    }

                    if (modal !== null && modal !== undefined) {
                        targetInModal = isElementInTarget(modal, event.target);
                    }

                    !targetInModal && resetModal();
                    scope.ttOpen = targetInModal;
                }

                scope.isListChecked = function (data) {
                    var hasUnchecked = false;

                    angular.forEach(data.list, function (row) {
                        if (hasUnchecked) return;
                        angular.forEach(row, function (value, key) {
                            if (hasUnchecked || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                            var val = value.toLowerCase();
                            var fltr = data.filter.toLowerCase();

                            if (val.includes(fltr)) {
                                if (angular.isUndefined(row.is_selected) || row.is_selected === false) {
                                    hasUnchecked = true;
                                }
                            }
                        });
                    });

                    data.hasUnchecked = hasUnchecked;

                    return !hasUnchecked;
                };

                scope.goCheckToggle = function (e, item, index) {
                    item.is_selected = angular.isUndefined(item.is_selected) ? true : !item.is_selected;
                    scope.toggleLocks()
                };

                scope.goCheckToggleAll = function (e, data) {
                    angular.forEach(data.list, function (row) {
                        if (data.filter !== '') {
                            var continueLoop = true;
                            angular.forEach(row, function (value, key) {
                                if (!continueLoop || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                                var val = value.toLowerCase();
                                var fltr = data.filter.toLowerCase();

                                if (val.includes(fltr)) {
                                    row.is_selected = data.hasUnchecked;
                                    continueLoop = false;
                                }
                            });
                        } else {
                            row.is_selected = data.hasUnchecked;
                        }
                    });
                    scope.toggleLocks();
                };

                /**
                 * Performs the given type of attestation on the customer account.
                 * 
                 * @param {'send' | 'approve' | 'reject'} type the type of attestation to perform.
                 */
                scope.doTypeOfAttestation = async function (type) {
                    let parameter = isStateParameterValid() ? $stateParams.custact_no : scope.ttParameter;

                    scope.model.sendToAttestationLocked = true;
                    scope.model.rejectAttestationLocked = true;
                    scope.model.approveAttestationLocked = true;
                    let sendByClient = '1';

                    try {
                        await graphAuthService.getAccount();
                    } catch (error) {
                        sendByClient = '0';
                    }

                    accountingProcessingAttestationModalService.sendToAttestation(parameter, scope.model, type, scope.ttAccountingFunction, sendByClient).then((response) => {

                        if (response && response[0] && response[0].errorcode && response[0].errorcode !== '0') {
                            modalService.show({
                                type: type || 'warning',
                                title: title || '',
                                message: message,
                                buttons: [{
                                    label: label || 'OK',
                                    cssClass: cssClass || 'btn-warning',
                                    action: (dialogItself) => {
                                        dialogItself.close();
                                        scope.model.sendToAttestationLocked = false;
                                        scope.model.rejectAttestationLocked = false;
                                        scope.model.approveAttestationLocked = false;
                                    }
                                }]
                            });
                        } else {
                            if (angular.isFunction(scope.ttOnSuccess)) {
                                scope.ttOnSuccess();
                            }

                            if (sendByClient === '1' && response[0].send_email_office === '1') {
                                try {
                                    const data = JSON.parse(response[0].emailconfig);

                                    graphMailService.sendMessage({ subject: base64.urldecode(data.subject), content: base64.urldecode(data.bodyHtml), recipients: data.to, userId: data.from });

                                } catch (error) {
                                    console.log(error);
                                }
                            }
                            //try {
                            //    angular.forEach(data.mailinfo, function (mi) {
                            //        graphMailService.sendMessage({ subject: base64.urldecode(mi.subject), content: base64.urldecode(mi.bodyHtml), recipients: mi.to, ccRecipients: mi.cc, bccRecipients: mi.bcc, attachments: mi.attachments, userId: mi.from });
                            //    });
                            //} catch (error) {
                            //    modalService.show({
                            //        type: 'warning',
                            //        title: 'Varsel',
                            //        message: 'An error occured',
                            //        buttons: [{
                            //            label: 'OK',
                            //            cssClass: 'btn-warning',
                            //            action: function (dialogItself) {
                            //                dialogItself.close();
                            //            }
                            //        }]
                            //    });

                            //    deferred.reject(error);
                            //}
                            scope.model.sendToAttestationLocked = false;
                            scope.model.rejectAttestationLocked = false;
                            scope.model.approveAttestationLocked = false;
                            scope.closeModal();
                        }
                    });
                }

                /**
                 * Performs accounting on the customer account.
                 */
                scope.performAccounting = function () {
                    scope.model.bookkeepLocked = true;

                    if (scope.ttParameter && scope.ttParameter !== 0 && scope.ttParameter !== '0') {
                        accountingProcessingAttestationModalService.performAccounting(scope.ttParameter, scope.model.comment, scope.ttAccountingFunction).then((response) => {
                            if (response && response[0] && response[0].errorcode && response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning', //type || 'warning',
                                    title: 'ERROR', //title || '',
                                    message: response[0].errormessage, //message,
                                    buttons: [{
                                        label: 'OK', //label || 'OK',
                                        cssClass: 'btn-warning', //cssClass || 'btn-warning',
                                        action: (dialogItself) => {
                                            dialogItself.close();
                                            scope.model.bookkeepLocked = false;
                                        }
                                    }]
                                });
                            } else {
                                scope.closeModal();
                                scope.model.bookkeepLocked = false;

                                if (angular.isFunction(scope.ttOnSuccess)) {
                                    scope.ttOnSuccess();
                                }
                            }
                        });
                    }
                }

                scope.reverse = async function () {
                    scope.model.reverseLocked = true;

                    const data = await accountingProcessingAttestationModalService.reverseVoucher({ custactac_keyno: scope.ttModel.custactac_keyno, comment: scope.model.comment, reverse_transdate: scope.model.reverse_transdate, reverse_voucherdate: scope.model.reverse_voucherdate, override_voucher_dates: (scope.model.override_voucher_dates ?? '0'), no_accrual_reverse: (scope.model.no_accrual_reverse ?? '0') });

                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        modalService.show({
                            type: 'success',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [
                                {
                                    label: 'OK',
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    },
                                },
                            ],
                        });
                    }

                    scope.model.reverseLocked = false;

                    if (angular.isFunction(scope.ttOnSuccess)) {
                        scope.ttOnSuccess();
                    }
                }

                /**
                 * Checks if the stateparameters are valid or not.
                 */
                function isStateParameterValid() {
                    return $stateParams.custact_no !== undefined && $stateParams.custact_no !== null && $stateParams.custact_no !== 0 && $stateParams.custact_no !== '0';
                }

                /**
                 * Positions the modal and arrow of the modal according to the position of the
                 * given element.
                 * 
                 * @param {HTMLElement} relativeElement the element to position the modal relative to.
                 */
                function positionModal(relativeElement) {
                    if (!relativeElement) return;

                    let position = relativeElement.getBoundingClientRect();

                    let leftMargin = 0;
                    let modalWidth = $(`#${scope.id.modal}`).css('width')?.replace('px', '') ?? 300;
                    let defaultRightModalPosition = position.right - position.width / 2 + modalWidth / 2;
                    let defaultLeftModalPosition = Number((position.left + position.width / 2) - (modalWidth / 2));

                    if (defaultLeftModalPosition < 0) {
                        leftMargin = Math.abs(defaultLeftModalPosition) + 5;
                    } else if (defaultRightModalPosition > window.innerWidth) {
                        leftMargin = window.innerWidth - defaultRightModalPosition - 15;
                    }

                    setTimeout(() => {
                        let modal = element.find(`#${scope.id.modal}`)[0];
                        if (!modal) return;

                        if (position.top < window.innerHeight - position.bottom) {
                            if (position.bottom + modal.clientHeight > window.innerHeight) {
                                scope.style.checklist.height = window.innerHeight - position.bottom - 200 + 'px';
                            }

                            scope.class.arrow = 'tt-popover__arrow--up';
                            scope.style.modal['top'] = position.bottom + 20 + 'px';
                            scope.style.modal['transform'] = 'translate(-50%, 0)';
                            scope.style.arrow['top'] = position.bottom - 18 + 'px';
                            if (scope.ttType === 'history') {
                                scope.style.modal['maxHeight'] = 'min(100vh - ' + (position.bottom + 50) + 'px, 500px)';
                            }
                        } else {
                            if (position.top < modal.clientHeight) {
                                scope.style.checklist.height = window.innerHeight - position.top - 200 + 'px';
                            }
                            scope.class.arrow = 'tt-popover__arrow--down';
                            scope.style.modal['top'] = (position.top - 20) + 'px';
                            scope.style.modal['transform'] = 'translate(-50%, -100%)';
                            scope.style.arrow['top'] = (position.top - 42) + 'px';
                            if (scope.ttType === 'history') {
                                scope.style.modal['maxHeight'] = 'min(100vh - ' + (window.innerHeight - position.top + 50) + 'px, 500px)';
                            }
                        }

                        scope.style.modal['left'] = (position.x + position.width / 2) + leftMargin + 'px';
                        scope.style.arrow['left'] = position.x + position.width / 2 - 40 + 'px';
                    }, 50);
                }


                /**
                 * Finds the relative element and positions the modal relative to the
                 * element found.
                 */
                const findElementAndSetPosition = function () {
                    let elementId = scope.ttRelativeElementId;
                    let relativeElement = document.getElementById(`${elementId}`);

                    if (relativeElement === null || relativeElement === undefined) {
                        setTimeout(() => {
                            relativeElement = document.getElementById(`${elementId}`);
                            positionModal(relativeElement);
                        }, 100);
                    } else {
                        positionModal(relativeElement);
                    }
                }


                layoutService.onLayoutChanged(onDestroy, function (info) {
                    if (angular.isUndefined(info)) return;

                    scope.style.label.fontSize = info.fontSizes.textSizeS;
                });

                /**
                 * Adds the given list of attestants to the list of attestant displayed in the popover modal.
                 * 
                 * @param attestants the list of attestants to add to the displayed list.
                 */
                function setAttestantsToList(attestants) {
                    scope.model.attestants.data.list = attestants.map((attestant) => ({ ...attestant, is_selected: false }));
                    if (scope.model.attestants.data.list.length > 6) scope.style.checklist.height = '250px';
                }

                /**
                 * Loads data into scope.model
                 */
                async function loadData() {
                    let parameter = isStateParameterValid() ? $stateParams.custact_no : scope.ttParameter;

                    if (scope.ttType === 'reject') {
                        return accountingProcessingAttestationModalService.getAttestants(parameter, scope.ttAccountingFunction, scope.ttType).then((response) => setAttestantsToList(response));
                    } else if (scope.ttType === 'approve' || scope.ttType === 'send' || scope.ttShowAttestants === true) {
                        return accountingProcessingAttestationModalService.getPossibleAttestants(parameter, scope.ttAccountingFunction, scope.ttType).then((response) => setAttestantsToList(response));
                    } else if (scope.ttType === 'reverse') {
                        return;
                    }

                    return accountingProcessingAttestationModalService.getAttestantsLog(parameter).then((response) => {
                        angular.copy(response, scope.model.comments);
                        scope.model.comments.map((comment) => comment.heading = `${comment.heading} ${comment.approved_by}`);
                    });
                }

                // #region ANGULAR FUNCTIONS
                angular.element(document.body).append(element);
                window.addEventListener('scroll', findElementAndSetPosition);

                scope.$watch('ttOpen', function (newVal, oldVal) {
                    if (newVal === true && newVal !== oldVal) {
                        findElementAndSetPosition();
                        loadData().then(() => {
                            findElementAndSetPosition();
                            scope.toggleLocks();
                            scope.model.approveAttestationLocked = false;
                            //ready = true
                        });
                    }
                });

                scope.$on('$destroy', function () {
                    window.removeEventListener('scroll', findElementAndSetPosition);
                    angular.forEach(onDestroy, (fn) => angular.isFunction(fn) && fn());
                    element.remove();
                });


                // #endregion ANGULAR FUNCTIONS
            }
        }
    }]);
})();

//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('accountingProcessingAttestationModal', {
//        templateUrl: 'views/components/views/accountingProcessing/accountingProcessing.template.attestationModal.html?v=' + module.version,
//        controllerAs: 'vm',
//        bindings: {
//            ttRelativeElementId: '@',   // Id of the element to place the popover relative to.
//            ttOpen: '=',                // Whether or not the popover is visible.
//            ttType: '@',                // 'bookeep', 'send', 'approve', 'history' or 'reject'.
//            ttAttestantsRequired: '<',  // Whether it is required to add attestants.
//            ttShowAttestants: '<',      // Whether to show the list of attestants.
//            ttParameter: '<',           // The paramterer, a custact_no, used for procedure calls.
//            ttIsAccountant: '&',        // Whether the current user is an accountant.
//            ttOnSuccess: '&',           // Function to call when requests goes through successfully
//        },
//        controller: ['$stateParams', '$window', '$element', 'layoutService', 'utilityService', 'modalService', 'accountingProcessingAttestationModalService', 'translateService', function ($stateParams, $window, $element, layoutService, us, modalService, accountingProcessingAttestationModalService, translateService) {

//            // #region VARIABLES & DEFINITIONS

//            let vm = this;
//            let onDestroy = [];

//            let ready = false;

//            vm.id = {
//                modal: uuid(),
//            }

//            vm.style = {
//                modal: {
//                    'padding': '6px',
//                    'position': 'absolute',
//                    'width': '300px',
//                    'background-color': 'white',
//                    'border-radius': '6px',
//                    'pointer-events': 'all',
//                    'max-height': '500px',
//                    'overflow-y': 'scroll',
//                    'z-index': '1000000000000000',
//                },
//                arrow: {},
//                label: {
//                    fontSize: '',
//                },
//                input: {
//                    textarea: { padding: '4px' },
//                },
//                message: {
//                    label: {
//                        size: 'textSizeS'
//                    },
//                    sublabel: {
//                        size: 'textSizeS'
//                    },
//                    message: {
//                        size: 'textSize',
//                        padding: '4px',
//                    },
//                },
//                checklist: {
//                    minHeight: '80px',
//                    maxHeight: '250px',
//                }
//            };

//            vm.class = {
//                label: '',

//            };

//            vm.translations = {
//                attestants: 'attestants'
//            }

//            vm.model = {
//                filterplaceholder: 'filterplaceholder',
//                comments: [],
//                comment: '',
//                attestants: {
//                    name: 'attestant',
//                    info: {
//                        label: 'attestant_label',
//                        placeholder: '',
//                        type: 'list',
//                        translate: false,
//                    },
//                    data: {
//                        list: [],
//                        id: 'item_id',
//                        name: 'item_name',
//                        filter: '',
//                        allSelected: false
//                    },
//                },
//                sendToAttestationLocked: true,
//                approveAttestationLocked: false,
//                bookkeepLocked: false,
//                rejectAttestationLocked: true,
//            };

//            // #endregion VARIABLES & DEFINITIONS


//            /**
//             * Searches recursively through children of the given target to find
//             * the given element. Returns true if the element is in the target's tree
//             * false if not
//             *
//             * @param element the element to find in the target's tree.
//             * @param target the target to search in.
//             * @returns {boolean} true if the element is in the target's tree false if not.
//             */
//            function isElementInTarget(element, target) {
//                if (element === target) return true;

//                for (let i = 0; i < element.children.length; i++) {
//                    let child = element.children[i];
//                    let found = isElementInTarget(child, target);

//                    if (found) {
//                        return true;
//                    }
//                };

//                return false;
//            }

//            /**
//             * Resets the values in the modal.
//             */
//            function resetModal() {
//                vm.model.attestants.data.list.map((attestant) => attestant.is_selected = false);
//                vm.model.comment = '';
//            }

//            /**
//             * Closes the modal if the even happened outside of the modal element.
//             */
//            vm.closeModal = function (event) {
//                let modal = document.getElementById(`${vm.id.modal}`);
//                let targetInModal = false;

//                if (!event) {
//                    resetModal();
//                    vm.ttOpen = false;
//                    return;
//                }

//                if (modal !== null && modal !== undefined) {
//                    targetInModal = isElementInTarget(modal, event.target);
//                }

//                !targetInModal && resetModal();
//                vm.ttOpen = targetInModal;
//            }

//            vm.isListChecked = function (data) {
//                var hasUnchecked = false;

//                angular.forEach(data.list, function (row) {
//                    if (hasUnchecked) return;
//                    angular.forEach(row, function (value, key) {
//                        if (hasUnchecked || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
//                        var val = value.toLowerCase();
//                        var fltr = data.filter.toLowerCase();

//                        if (val.includes(fltr)) {
//                            if (angular.isUndefined(row.is_selected) || row.is_selected === false) {
//                                hasUnchecked = true;
//                            }
//                        }
//                    });
//                });

//                data.hasUnchecked = hasUnchecked;

//                return !hasUnchecked;
//            };

//            vm.goCheckToggle = function (e, item, index) {
//                item.is_selected = angular.isUndefined(item.is_selected) ? true : !item.is_selected;
//            };

//            vm.goCheckToggleAll = function (e, data) {
//                angular.forEach(data.list, function (row) {
//                    if (data.filter !== '') {
//                        var continueLoop = true;
//                        angular.forEach(row, function (value, key) {
//                            if (!continueLoop || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
//                            var val = value.toLowerCase();
//                            var fltr = data.filter.toLowerCase();

//                            if (val.includes(fltr)) {
//                                row.is_selected = data.hasUnchecked;
//                                continueLoop = false;
//                            }
//                        });
//                    } else {
//                        row.is_selected = data.hasUnchecked;
//                    }
//                });
//            };

//            /**
//             * Performs the given type of attestation on the customer account.
//             *
//             * @param {'send' | 'approve' | 'reject'} type the type of attestation to perform.
//             */
//            vm.doTypeOfAttestation = function (type) {
//                let parameter = isStateParameterValid() ? $stateParams.custact_no : vm.ttParameter;

//                vm.model.sendToAttestationLocked = true;
//                vm.model.rejectAttestationLocked = true;
//                vm.model.approveAttestationLocked = true;

//                accountingProcessingAttestationModalService.sendToAttestation(parameter, vm.model, type).then((response) => {

//                    if (response && response[0] && response[0].errorcode && response[0].errorcode !== '0') {
//                        modalService.show({
//                            type: type || 'warning',
//                            title: title || '',
//                            message: message,
//                            buttons: [{
//                                label: label || 'OK',
//                                cssClass: cssClass || 'btn-warning',
//                                action: (dialogItself) => {
//                                    dialogItself.close();
//                                    vm.model.sendToAttestationLocked = false;
//                                    vm.model.rejectAttestationLocked = false;
//                                    vm.model.approveAttestationLocked = false;
//                                }
//                            }]
//                        });
//                    } else {
//                        vm.closeModal();
//                        vm.model.sendToAttestationLocked = false;
//                        vm.model.rejectAttestationLocked = false;
//                        vm.model.approveAttestationLocked = false;

//                        if (angular.isFunction(vm.ttOnSuccess)) {
//                            vm.ttOnSuccess();
//                        }
//                    }
//                });
//            }

//            /**
//             * Performs accounting on the customer account.
//             */
//            vm.performAccounting = function () {
//                vm.model.bookkeepLocked = true;

//                if (vm.ttParameter && vm.ttParameter !== 0 && vm.ttParameter !== '0') {
//                    accountingProcessingAttestationModalService.performAccounting(vm.ttParameter, vm.model.comment).then((response) => {
//                        if (response && response[0] && response[0].errorcode && response[0].errorcode !== '0') {
//                            modalService.show({
//                                type: 'warning', //type || 'warning',
//                                title: 'ERROR', //title || '',
//                                message: response[0].errormessage, //message,
//                                buttons: [{
//                                    label: 'OK', //label || 'OK',
//                                    cssClass: 'btn-warning', //cssClass || 'btn-warning',
//                                    action: (dialogItself) => {
//                                        dialogItself.close();
//                                        vm.model.bookkeepLocked = false;
//                                    }
//                                }]
//                            });
//                        } else {
//                            vm.closeModal();
//                            vm.model.bookkeepLocked = false;

//                            if (angular.isFunction(vm.ttOnSuccess)) {
//                                vm.ttOnSuccess();
//                            }
//                        }
//                    });
//                }
//            }

//            /**
//             * Checks if the stateparameters are valid or not.
//             */
//            function isStateParameterValid() {
//                return $stateParams.custact_no !== undefined && $stateParams.custact_no !== null && $stateParams.custact_no !== 0 && $stateParams.custact_no !== '0';
//            }

//            /** Style object for arrow to point upwards. */
//            let upArrow = {
//                'position': 'absolute',
//                'top': '0',
//                'width': '0',
//                'height': '0',
//                'border-left': '20px solid transparent',
//                'border-right': '20px solid transparent',
//                'border-bottom': '20px solid white',
//                'border-radius': '6px',
//                'margin': '2rem',
//            }

//            /** Style object for arrow to point downwards. */
//            let downArrow = {
//                'position': 'absolute',
//                'top': '0',
//                'width': '0',
//                'height': '0',
//                'border-left': '20px solid transparent',
//                'border-right': '20px solid transparent',
//                'border-top': '20px solid white',
//                'border-radius': '6px',
//                'margin': '2rem',
//            }

//            /**
//             * Positions the modal and arrow of the modal according to the position of the
//             * given element.
//             *
//             * @param {HTMLElement} element the element to position the modal relative to.
//             */
//            function positionModal(element) {
//                if (!element) return;

//                let position = element.getBoundingClientRect();

//                let leftMargin = 0;
//                let modalWidth = parseInt(vm.style.modal['width'].replace('px', ''));
//                let defaultRightModalPosition = position.right - position.width / 2 + modalWidth / 2;
//                let defaultLeftModalPosition = Number((position.left + position.width / 2) - (modalWidth / 2));

//                if (defaultLeftModalPosition < 0) {
//                    leftMargin = Math.abs(defaultLeftModalPosition) + 5;
//                } else if (defaultRightModalPosition > window.innerWidth) {
//                    leftMargin = window.innerWidth - defaultRightModalPosition - 15;
//                }

//                // om position.bottom er større enn

//                const goUnder = (position.top + vm.style.modal['max-height'].replace('px', '')) < (window.innerHeight - position.bottom);

//                if (goUnder) {
//                    vm.style.arrow = upArrow;
//                    vm.style.modal['top'] = position.bottom + 20 + 'px';
//                    vm.style.modal['transform'] = 'translate(-50%, 0)';
//                    vm.style.arrow['top'] = position.bottom - 18 + 'px';
//                } else {
//                    vm.style.arrow = downArrow;
//                    vm.style.modal['top'] = position.top - 20 + 'px';
//                    vm.style.modal['transform'] = 'translate(-50%, -100%)';
//                    vm.style.arrow['top'] = parseInt(vm.style.modal['top'] + vm.style.modal['height']) - 22 + 'px';
//                }

//                vm.style.modal['left'] = (position.x + position.width / 2) + leftMargin + 'px';
//                vm.style.arrow['left'] = position.x + position.width / 2 - 40 + 'px';
//            }


//            /**
//             * Finds the relative element and positions the modal relative to the
//             * element found.
//             */
//            const findElementAndSetPosition = function () {
//                let elementId = vm.ttRelativeElementId;
//                let relativeElement = document.getElementById(`${elementId}`);

//                if (relativeElement === null || relativeElement === undefined) {
//                    setTimeout(() => {
//                        relativeElement = document.getElementById(`${elementId}`);
//                        positionModal(relativeElement);
//                    }, 100);
//                } else {
//                    positionModal(relativeElement);
//                }
//            }

//            /**
//             * MutationObserver used to position the modal if there are changes to the site.
//             */
//            const observer = new MutationObserver((mutationList, observer) => {
//                findElementAndSetPosition();
//                vm.model.sendToAttestationLocked = vm.model.attestants.data.list.filter((attestant) => attestant.is_selected === true).length === 0;
//                vm.model.rejectAttestationLocked = vm.model.comment.trim().length === 0;
//            });

//            let setClasses = function () {
//                vm.class.label = labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1';
//                vm.class.input = labelAlwaysOnTop === true || vm.hideLabel || vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11';
//            };

//            layoutService.onLayoutChanged(onDestroy, function (info) {
//                if (angular.isUndefined(info)) return;

//                vm.style.label.fontSize = info.fontSizes.textSizeS;
//                vm.style.btn.paddingTop = info.fontSizes.textSize;

//                setClasses();
//            });

//            /**
//             * Adds the given list of attestants to the list of attestant displayed in the popover modal.
//             *
//             * @param attestants the list of attestants to add to the displayed list.
//             */
//            function setAttestantsToList(attestants) {
//                angular.copy(attestants, vm.model.attestants.data.list);

//                vm.model.attestants.data.list.map((attestant) => attestant.is_selected = false);
//                if (vm.model.attestants.data.list.length > 6) vm.style.checklist.height = '250px';
//            }

//            /**
//             * Loads data into vm.model
//             */
//            function loadData() {
//                let parameter = isStateParameterValid() ? $stateParams.custact_no : vm.ttParameter;

//                if (vm.ttType === 'reject' && vm.ttIsAccountant && vm.ttShowAttestants === true) {
//                    return accountingProcessingAttestationModalService.getAttestants(parameter).then((response) => setAttestantsToList(response));
//                } else if (vm.ttType === 'approve' || vm.ttType === 'send' || vm.ttShowAttestants === true) {
//                    return accountingProcessingAttestationModalService.getPossibleAttestants(parameter).then((response) => setAttestantsToList(response));
//                }

//                return accountingProcessingAttestationModalService.getAttestantsLog(parameter).then((response) => {
//                    angular.copy(response, vm.model.comments);
//                    vm.model.comments.map((comment) => comment.heading = `${comment.heading} ${comment.approved_by}`);
//                });
//            }

//            // #region ANGULAR FUNCTIONS

//            vm.$onInit = function () {
//                observer.observe(document.body, { childList: true, subtree: true, attributes: true });
//                $window.addEventListener('scroll', findElementAndSetPosition);
//                findElementAndSetPosition();
//                loadData().then(() => ready = true);
//            };

//            vm.$onChanges = (changes) => {
//                translateService.translate(vm.translations.attestants).then((translation) => vm.translations.attestants = translation);

//                if (changes?.ttParameter?.currentValue && typeof changes.ttParameter.previousValue === 'string' && changes.ttParameter.previousValue !== changes.ttParameter.currentValue && ready) {
//                    loadData();
//                }
//            }

//            vm.$onDestroy = () => {
//                observer.disconnect();
//                $window.removeEventListener('scroll', findElementAndSetPosition);
//                angular.forEach(onDestroy, (fn) => angular.isFunction(fn) && fn());
//            };

//            //if (angular.isFunction(vm.ttChange) && angular.isDefined(changes.ttOpen)) {
//            //   //console.log('TTchange');
//            //    vm.ttChange({ $value: changes.ttOpen.currentValue, $args: vm.ttChangeArgs });
//            //}
//            // #endregion ANGULAR FUNCTIONS

//        }]
//    });
//})();
