(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('tailorAccesses', {
        templateUrl: 'views/components/views/tailorAccesses/tailorAccesses.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'tailorAccessesService', function (stateService, tailorAccessesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                itemsListTailorAccesses: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadTailorAccesses = function () {
                tailorAccessesService.listTailorAccesses().then(function (list) {
                    angular.copy(list, vm.model.itemsListTailorAccesses);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'tailoraccess':
                        go({ tailoraccess_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTailorAccesses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
