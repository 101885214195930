import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnChanges, OnInit, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-p2-datatask-log',
    templateUrl: './p2-datatask-log.component.html',
    styleUrls: ['./p2-datatask-log.component.css'],
})
export class P2DatataskLogComponent implements OnChanges, OnInit {
    /**
     * The keyno of the datatask log to display.
     */
    @Input()
    public p2DatataskLogKeyno?: string | number;

    /**
     * Whether this component is used as a page/view component, (meaning not an inline component in another parent component).
     *
     * @default true
     */
    @Input()
    public set isPageComponent(value: BooleanInput) {
        this._isPageComponent = coerceBooleanProperty(value);
    }
    public get isPageComponent(): boolean {
        return this._isPageComponent;
    }
    private _isPageComponent = true;

    /**
     * Page load to display in component.
     */
    public get?: P2DatataskLogGet;

    /**
     * Whether to display pretty formatting of the json data.
     */
    public prettier = false;

    /**
     * Mediaquery for laptop size (min-width).
     */
    public laptopMediaQuery: MediaQueryList;

    constructor(private datatask: DataTaskService, private state: StateService,  layout: LayoutService) {
        this.laptopMediaQuery = layout.getMediaQueries().laptop;
    }

    private async getPageLoad() {
        this.get = (await this.datatask.Post(354, { p2_datatasklog_keyno: this.p2DatataskLogKeyno }))[0];
    }

    public formatJsonDataIn() {
        if (!!this.get) {
            try {
                this.get.jsondata_in = this.formatJson(this.get.jsondata_in);
            } catch (error) {
                console.log('error :>> ', error);
            }
        }
    }

    public formatJsonDataOut() {
        if (!!this.get) {
            try {
                this.get.jsondata_out = this.formatJson(this.get.jsondata_out);
            } catch (error) {
                console.log('error :>> ', error);
            }
        }
    }

    private formatJson(jsonString: string) {
        const indexOfLastCurlyBracket = jsonString.lastIndexOf('}');
        const indexOfLastSquareBracket = jsonString.lastIndexOf(']');
        const indexOfLastBracket = Math.max(indexOfLastCurlyBracket, indexOfLastSquareBracket);

        if (indexOfLastCurlyBracket !== -1) {
            try {
                let endOfJson = jsonString.substring(indexOfLastBracket + 1);
                let json = JSON.parse(jsonString.substring(0, indexOfLastBracket + 1));

                return JSON.stringify(json, null, 2) + endOfJson;
            } catch (error) {
                throw error;
            }
        }

        throw Error('invalid json');
    }

    ngOnInit(): void {
        if (this.isPageComponent) {
            this.p2DatataskLogKeyno = this.state.getStateParams()['p2_datatasklog_keyno'];
            this.getPageLoad();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.isPageComponent) {
            if (changes['p2DatataskLogKeyno'] && !!changes['p2DatataskLogKeyno'].currentValue && changes['p2DatataskLogKeyno'].currentValue !== changes['p2DatataskLogKeyno'].previousValue) {
                this.getPageLoad();
            }
        }
    }
}

export interface P2DatataskLogGet {
    p2_datatasklog_keyno: string | number;
    p2_datatask_keyno: string | number;
    proc_name: string;
    portal_user_name: string;
    reg_datetime: string;
    jsondata_in: string;
    jsondata_out: string;
}

// borrowed from: https://stackoverflow.com/a/68233195.
@Pipe({
    name: 'prettyjson',
    pure: true,
})
export class PrettyJsonPipe implements PipeTransform {
    transform(value: any, args: any[]): any {
        try {
            const indexOfLastCurlyBracket = value.lastIndexOf('}');
            const indexOfLastSquareBracket = value.lastIndexOf(']');
            const indexOfLastBracket = Math.max(indexOfLastCurlyBracket, indexOfLastSquareBracket);

            let json = JSON.parse(value.substring(0, indexOfLastBracket + 1));

            return this.applyColors(json, args[0], args[1]);
        } catch (e) {
            return this.applyColors({ error: 'Invalid JSON' }, args[0], args[1]);
        }
    }

    applyColors(obj: any, showNumeberLine: boolean = false, padding: number = 4) {
        let line = 1;

        if (typeof obj != 'string') {
            obj = JSON.stringify(obj, undefined, 3);
        }

        /**
         * Converts special charaters like &, <, > to equivalent HTML code of it
         */
        obj = obj.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        /* taken from https://stackoverflow.com/a/7220510 */

        /**
         * wraps every datatype, key for e.g
         * numbers from json object to something like
         * <span class="number" > 234 </span>
         * this is why needed custom themeClass which we created in _global.css
         * @return final bunch of span tags after all conversion
         */
        obj = obj.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match: any) => {
            // class to be applied inside pre tag
            let themeClass = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    themeClass = 'key';
                } else {
                    themeClass = 'string';
                }
            } else if (/true|false/.test(match)) {
                themeClass = 'boolean';
            } else if (/null/.test(match)) {
                themeClass = 'null';
            }
            return '<span class="' + themeClass + '">' + match + '</span>';
        });

        /**
         * Regex for the start of the line, insert a number-line themeClass tag before each line
         */
        return showNumeberLine ? obj.replace(/^/gm, () => `<span class="number-line pl-3 select-none" >${String(line++).padEnd(padding)}</span>`) : obj;
    }
}
