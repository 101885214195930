(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('menuGrps', {
        templateUrl: 'views/components/views/menuGrps/menuGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ttGridFactory', function (stateService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'menugrp_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'menugrps_new',
                text: 'menugrps_new',
                func: () => stateService.go('menugrp', { menugrp_keyno: '0' }),
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3485,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
