(function () {
    'use strict';

	let module = angular.module("imApp");

	module.factory("typeaheadService", ['$q', '$ihttp', function ($q, $ihttp) {
		let service = {
			selectListCustomers: [],
			selectListProducts: [],
            selectProductList: [],
            selectListPersonNames: [],
			selectListPersonNames: [],
			selectListProjectNames: [],
            selectListServiceObjects: [],
            selectAccountList: [],
            suggestListProjects: [],
			selectCustactList: [],
			selectListCustomersAccount: [],
			selectListInitiatedByPersonNames: [],
            selectListResources: [],
            selectEquipmentList: [],
            selectListSuggestions: [],
			selectListPortalUsers: [],
			selectListOrders: [],
            selectStockLocationList: [],
            selectListTables: [],
            selectListAccruals: [],
			isSearchingCustomers: false,
			isSearchingProducts: false,
            isSearchingProductLists: false,
            isSearchingStockPlaces: false,
			isSearchingPersonNames: false,
			isSearchingProjectNames: false,
            isSearchingServiceObjects: false,
            isSearchingAccounts: false,
            isSearchingProjects: false,
			isSearchingCustacts: false,
			isSearchingCustomersAccount: false,
			isSearchingInitiatedByPersonNames: false,
            isSearchingResources: false,
            isSearchingEquipments: false,
            isSearchingSuggestions: false,
			isSearchingPortalUsers: false,
			isSearchingOrders: false,
            isSearchingStockLocations: false,
            isSearchingTables: false,
            isSearchingAccruals: false,
			searchCustomer: function (parms) { // cust_name, filtertype, filtervalue, custtype, lastselect
				let deferred = $q.defer();

				service.isSearchingCustomers = true;

				$ihttp.post({
					method: 56,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListCustomers);

					service.isSearchingCustomers = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			searchProduct: function (parms) { // prod_name, webpage_name, parm1, parm2, supplier_no, search_only_this_supplier, businessco_no, onlyonstock, model_id
				let deferred = $q.defer();

				service.isSearchingProducts = true;

				$ihttp.post({
					method: 78,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListProducts);

					service.isSearchingProducts = false;

					deferred.resolve(data);
				});

				return deferred.promise;
            },
            lookUpStockPlaces: function (parms) {
                var deferred = $q.defer();

                service.isSearchingStockPlaces = true;

                $ihttp.post({
                    method: 103,
                    parameters: parms
                }).then(function (response) {
                    angular.copy(response, service.selectStockPlacesList);

                    service.isSearchingStockPlaces = false;

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
			lookUpPersonNames: function (parms) { // pers_name
				let deferred = $q.defer();

				service.isSearchingPersonNames = true;

				$ihttp.post({
					method: 117,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListPersonNames);

					service.isSearchingPersonNames = false;

					deferred.resolve(data);
				});

				return deferred.promise;
            },
            lookUpProducts: function (parms) { // parameters":{"productsearch_searchtext":"","functionname":"stock","stockplace_id":"0"}
                const deferred = $q.defer();

                service.isSearchingProductLists = true;

                $ihttp.post({
                    method: 462,
                    parameters: parms
                }).then(function (response) {
                    angular.copy(response, service.selectProductList);

                    service.isSearchingProductLists = false;

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
			lookUpProjectNames: function (parms) { // parameters":{"bg_projects_activestatus":"ACTIVE","searchtext":"test","argtype":"0","argvalue":"0"}
				let deferred = $q.defer();

				service.isSearchingProjectNames = true;

				$ihttp.post({
					method: 902,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListProjectNames);

					service.isSearchingProjectNames = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			lookUpStockLocations: function (parms) {
                let deferred = $q.defer();

                service.isSearchingStockLocations = true;

                $ihttp.post({
					method: 1029,
                    parameters: parms
                }).then(function (data) {
					angular.copy(data, service.selectStockLocationList);

                    service.isSearchingStockLocations = false;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
			lookUpInitiatedByPersonNames: function (parms) { // searchtext
				let deferred = $q.defer();

				service.isSearchingInitiatedByPersonNames = true;

				$ihttp.post({
					method: 1125,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListInitiatedByPersonNames);

					service.isSearchingInitiatedByPersonNames = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			lookUpResources: function (parms) { // resource_searchtext
				let deferred = $q.defer();

				service.isSearchingResources = true;

				$ihttp.post({
					method: 1321,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListResources);

					service.isSearchingResources = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			lookUpEquipments: function (parms) { // equipment_name, businessco_no
				let deferred = $q.defer();

				service.isSearchingEquipments = true;

				$ihttp.post({
					method: 1365,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectEquipmentList);

					service.isSearchingEquipments = false;

					deferred.resolve(data);
				});

				return deferred.promise;
            },
            lookUpSuggestions: function (edit) {
                let deferred = $q.defer();

                service.isSearchingSuggestions = true;

                $ihttp.post({
                    method: 1499,
                    parameters: edit
                }).then(function (response) {
                    angular.copy(response, service.selectListSuggestions);

                    service.isSearchingSuggestions = false;

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
			lookUpServiceObjects: function (parms) { // searchtext
				let deferred = $q.defer();

				service.isSearchingServiceObjects = true;

				$ihttp.post({
					method: 1733,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListServiceObjects);

					service.isSearchingServiceObjects = false;

					deferred.resolve(data);
				});

				return deferred.promise;
            },
            lookUpSearchAccounts: function (parms) {
                let deferred = $q.defer();

                service.isSearchingAccounts = true;

                $ihttp.post({
                    method: 1900,
                    parameters: parms
                }).then(function (response) {
                    angular.copy(response, service.selectAccountList);

                    service.isSearchingAccounts = false;

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
            lookUpSearchProjects: function (parms) {
                const deferred = $q.defer();

                service.isSearchingProjects = true;

                $ihttp.post({
                    method: 1974,
                    parameters: parms
                }).then(function (data) {
                    angular.copy(data, service.suggestListProjects);

                    service.isSearchingProjects = false;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            lookUpCustact: function (parms) {
                let deferred = $q.defer();

                service.isSearchingCustacts = true;

                $ihttp.post({
					method: 2724,
                    parameters: parms
                }).then(function (data) {
                    angular.copy(data, service.selectCustactList);

                    service.isSearchingCustacts = false;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
			lookUpCustomerAccount: function (parms) { // cust_name, filtertype, filtervalue, custtype, lastselect
				let deferred = $q.defer();

				service.isSearchingCustomersAccount = true;

				$ihttp.post({
					method: 2090,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListCustomersAccount);

					service.isSearchingCustomersAccount = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			lookUpPortalUser: function (parms) { // portal_user_name
				let deferred = $q.defer();

				service.isSearchingPortalUsers = true;

				$ihttp.post({
					method: 2102,
					parameters: parms
				}).then(function (data) {
					angular.copy(data, service.selectListPortalUsers);

					service.isSearchingPortalUsers = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
            lookUpOrder: function (parms) {
                let deferred = $q.defer();

                service.isSearchingOrders = true;

                $ihttp.post({
					method: 2772,
                    parameters: parms
                }).then(function (result) {
					angular.copy(result, service.selectListOrders);

                    service.isSearchingOrders = false;

                    deferred.resolve(result);
                });

                return deferred.promise;
            },
            lookUpTable: function (parms) {
                let deferred = $q.defer();

                service.isSearchingTables = true;

                $ihttp.post({
                    method: 2936,
                    parameters: parms
                }).then(function (result) {
                    angular.copy(result, service.selectListTables);

                    service.isSearchingTables = false;

                    deferred.resolve(result);
                });

                return deferred.promise;
            },
            lookUpAccrualAccount: function (parms) {
                var deferred = $q.defer();

                service.isSearchingAccruals = true;

                $ihttp.post({
                    method: 3190,
                    parameters: parms
                }).then(function (response) {
                    angular.copy(response, service.selectListAccruals);

                    service.isSearchingAccruals = false;

                    deferred.resolve(response);
                });

                return deferred.promise;
            }
		};

		return service;
	}]);
})();
