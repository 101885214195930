import { Component, OnInit } from '@angular/core';
import { DateModel, DateRangeInterval } from '@app/core/components/date-range/date-range.component';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { Grid } from '@app/core/components/grid/grid.model';
import { DBGridButton, GridOptions, GridRow } from '@app/core/components/grid/grid.types';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { RememberService } from '@app/core/services/remember.service';
import { LogisticService, StockplaceItem } from '@app/services/logistic.service';
import { StateService } from '@uirouter/angularjs';

@Component({
    selector: 'tt-dispatch-planning',
    templateUrl: './dispatch-planning.component.html',
    styleUrls: ['./dispatch-planning.component.css'],
})
export class DispatchPlanningComponent implements OnInit {
    public get?: DispatchplanningGet;

    public deliverymethods: Item[] = [];

    public stockplaces: StockplaceItem[] = [];

    public grid?: GridOptions;

    public deliveredGrid?: GridOptions;

    public orderInternalNoButtons: FormButton[] = [{ id: 'search', icon: 'fas fa-search', onClick: () => this.readDeliveredGrid(), type: 'primary' }];

    constructor(private datatask: DataTaskService, private logistic: LogisticService, private remember: RememberService, private state: StateService) {}

    public readPlanningGrid() {
        this.grid?.gridfunc?.read?.();
    }

    public readDeliveredGrid() {
        this.deliveredGrid?.gridfunc?.read?.();
    }

    private async loadDeliveryMethods() {
        this.deliverymethods = await this.datatask.Post(1557);
    }

    private async loadStockplaces() {
        this.stockplaces = await this.logistic.getStockplaces({ add_all: '0' });
    }

    private async loadPageGet() {
        this.get = (await this.datatask.Post(1562))[0];
        console.log('this.get :>> ', this.get);
        this.grid = new Grid()
            .setRememberId('dispatchplanning.grid.state')
            .setLoadDataFunction({ method: 3512, parameters: () => this.get! })
            .setConfig({ readAfterPrint: this.get?.refresh_after_print === '1' })
            .setSpecialFunc({ buttons: [{ name: 'create_consignment', icon: 'fas fa-plus', func: this.createConsignment, cssClass: 'tt-button tt-button--primary' }] });

        this.deliveredGrid = new Grid()
            .setRememberId('dispatchplanning.grid.delivered.state')
            .setLoadDataFunction({ method: 3522, parameters: () => this.get! })
            .setConfig({ readAfterPrint: this.get?.refresh_after_print === '1' });
    }

    private createConsignment = async (row: GridRow, button?: DBGridButton, event?: MouseEvent) => {
        const consignmentKeyno = (await this.datatask.Post(1755, { order_internal_no: row['order_internal_no'] }))[0].consignment_keyno;
        this.state.go('consignmentplansconsignmentcontent', { consignment_keyno: consignmentKeyno, custact_no: '0' });
    };

    ngOnInit(): void {
        this.loadPageGet();
        this.loadStockplaces();
        this.loadDeliveryMethods();
    }
}

interface DispatchplanningGet {
    deliverymethod_no: string;
    stockplace_id: string;
    date_fom: string;
    date_tom: string;
    dateselector_index: DateRangeInterval;
    filter: string;
    tab: string;
    order_order_no: string;
    refresh_after_print: '1' | '0';
}
