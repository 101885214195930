<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.startDate"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="date-range"
>
    <div class="tt-input__form-controls--date-range">
        <div class="tt-date-range__input-group">
            <span class="tt-input__icon--time fa-fw fal fa-calendar"></span>
            <tt-date-input
                [ttId]="id.startDate"
                [ttTabable]="ttTabable"
                [(ttModel)]="startDate"
                (ttModelChange)="onStartDateChanged($event); onModelChange()"
                [ttDisabled]="ttDisabled"
                [ttReadonly]="ttReadonly"
                [ttAllowEmpty]="false"
            ></tt-date-input>
        </div>
        <div class="tt-date-range__input-group">
            <span class="tt-input__icon--time fa-fw fal fa-calendar"></span>
            <tt-date-input
                [ttId]="id.endDate"
                [ttTabable]="ttTabable"
                [(ttModel)]="endDate"
                (ttModelChange)="onEndDateChanged($event); onModelChange()"
                [ttDisabled]="ttDisabled"
                [ttReadonly]="ttReadonly"
                [ttAllowEmpty]="false"
            ></tt-date-input>
        </div>
    </div>
    <div class="tt-date-range__buttons">
        <tt-content-switcher [ttSelectedId]="ttInterval" (ttSelectedIdChange)="onIntervalChanged($event); onModelChange()" style="flex: 1">
            <div class="tt-date-range__buttons-container">
                <div class="tt-date-range__buttons-nested-container">
                    <tt-switch
                        ttId="previous"
                        ttIcon="far fa-chevron-left"
                        [ttSelectable]="false"
                        [ttDisabled]="ttInterval === 'minmax'"
                        [ttTooltipText]="!!ttInterval ? 'tt_previous_' + ttInterval : 'previous'"
                        (ttClick)="decreaseSelectedDateRangeInterval(); onModelChange()"
                    ></tt-switch>
                    <tt-switch ttId="day" [ttText]="ttShowShortLabel ? 'tt_daterange_day_short' : 'tt_daterange_day'" ttTooltipText="tt_daterange_day_title"></tt-switch>
                    <tt-switch ttId="week" [ttText]="ttShowShortLabel ? 'tt_daterange_week_short' : 'tt_daterange_week'" ttTooltipText="tt_daterange_week_title"></tt-switch>
                    <tt-switch ttId="month" [ttText]="ttShowShortLabel ? 'tt_daterange_month_short' : 'tt_daterange_month'" ttTooltipText="tt_daterange_month_title"></tt-switch>
                    <tt-switch ttId="quarter" [ttText]="ttShowShortLabel ? 'tt_daterange_quarter_short' : 'tt_daterange_quarter'" ttTooltipText="tt_daterange_quarter_title"></tt-switch>
                </div>
                <div class="tt-date-range__buttons-nested-container">
                    <tt-switch ttId="biannual" [ttText]="ttShowShortLabel ? 'tt_daterange_biannual_short' : 'tt_daterange_biannual'" ttTooltipText="tt_daterange_biannual_title"></tt-switch>
                    <tt-switch ttId="year" [ttText]="ttShowShortLabel ? 'tt_daterange_year_short' : 'tt_daterange_year'" ttTooltipText="tt_daterange_year_title"></tt-switch>
                    <tt-switch
                        ttId="yeartodate"
                        [ttText]="ttShowShortLabel ? 'tt_daterange_year_to_date_short' : 'tt_daterange_year_to_date'"
                        ttTooltipText="tt_daterange_year_to_date_title"
                    ></tt-switch>
                    <tt-switch ttId="minmax" [ttText]="ttShowShortLabel ? 'tt_daterange_min_max_short' : 'tt_daterange_min_max'" ttTooltipText="tt_daterange_min_max_title"></tt-switch>
                    <tt-switch
                        ttId="next"
                        ttIcon="far fa-chevron-right"
                        [ttSelectable]="false"
                        [ttDisabled]="ttInterval === 'minmax'"
                        [ttTooltipText]="!!ttInterval ? 'tt_next_' + ttInterval : 'next'"
                        (ttClick)="increaseSelectedDateRangeInterval(); onModelChange()"
                    ></tt-switch>
                </div>
            </div>
        </tt-content-switcher>
    </div>
</tt-form-field-base>
