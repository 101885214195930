<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.range"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="checkbox"
>
    <span style="display: flex; align-items: center">
        <span style="padding: 0 0.8rem">{{ ttMin }}</span>
        <input [id]="id.range" [attr.id]="id.range" type="range" [(ngModel)]="ttModel" (ngModelChange)="onSliderChanged($event)" [min]="ttMin" [max]="ttMax" [step]="ttStep" />
        <span style="padding: 0 0.8rem">{{ ttMax }}</span>
        <!-- <span style="padding: 0 0.8rem">{{ ttModel }}</span> -->
        <tt-input-number style="width: 6rem; margin-bottom: -7px" ttLabelView="none" [ttModel]="ttModel" (ttModelChange)="onNumberInputChanged($event)" [ttDecimals]="ttStep % 1 !== 0 ? 2 : 0" />
    </span>
</tt-form-field-base>
