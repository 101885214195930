(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderForm', {
        templateUrl: 'views/components/views/orderForm/orderForm.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'translateService', 'modalService', 'orderFormService', 'economyService', 'ttGridFactory', function ($stateParams, utilityService, translateService, modalService, orderFormService, economyService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let translations = {
                error: '',
                ok: ''
            };

            let progress = {
                runProgress: 'START',
                label: 'progressbar',
                field: '',
                max: 0,
                min: 0,
                step: 0,
                finishclose: '1',
                abortclose: false
            };

            vm.model = {
                gridReady: false,
                setting: {},
                selectListPrices: []
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_orderform_price_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'orderform_add_to_order',
                text: 'orderform_add_to_order',
                func: () => progressFunc(),
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION FUNCTION

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATION FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await orderFormService.getSetting({ order_internal_no: orderInternalNo }))[0];

                loadGrid();
            }

            async function loadPrices() {
                vm.model.selectListPrices = (await economyService.listPrices());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                vm.grid.dataTask.loadData = {
                    method: 3548,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            function activateProgress(items) {
                let increment = 1, skip = 0, failed = 0, step = 0;
                progress.step = 0;

                function doStep() {
                    setTimeout(() => {
                        modalService.progressInfo.finishclose = '0';

                        if (progress.runProgress === 'ABORT') return;
                        if (progress.step >= progress.max) return;

                        step = progress.step + skip + failed;
                        progress.field = 'ID: ' + items[step].prod_id;
                        modalService.progressInfo.field = progress.field;

                        orderFormService.addToOrderItems(items[step]).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            progress.runProgress = 'ABORT';
                                            modalService.progressInfo.finishclose = '1';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                progress.step += increment;
                                modalService.progressInfo.step = progress.step;
                                modalService.progressInfo.finishclose = progress.finishclose;

                                doStep();
                            }
                        });
                    });
                }

                doStep();
            }

            function progressFunc() {
                let allRows = vm.grid.gridfunc.getAllRows();
                let selectedRows = [];

                allRows.forEach((item) => {
                    if (item.quantity !== 0) selectedRows.push(item);
                });

                if (selectedRows.length === 0) return;

                progress.max = selectedRows.length;
                activateProgress(selectedRows);

                modalService.showProgress(progress).then((value) => {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') return;
                    if (progress.runProgress === 'FINISH') loadGrid();
                });
            }

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'pricelist_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.pricelist_id) !== true) return;

                        vm.model.setting.pricelist_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.pricelist_id, true) !== true) return;

                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadSetting();
                loadPrices();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
