(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('sellers', {
        templateUrl: 'views/components/views/sellers/sellers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'sellersService', 'logisticService', 'rememberService', 'layoutService', function ($q, stateService, utilityService, sellersService, logisticService, rememberService, layoutService) {
            
    		// #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let variableNames = {
                gb_sellers_main: '',
                businessco_no: '',
                filtervalue: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                sellerId: '',
                bgSellersMain: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'gb_sellers_main_active', color: 'primary', onClick: () => setSellersMainView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'gb_sellers_main_notactive', color: 'primary', onClick: () => setSellersMainView('NOTACTIVE') },
                        { id: 'ALL', label: 'gb_sellers_main_all', color: 'primary', onClick: () => setSellersMainView('ALL') }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
    			setting: {},
    			selectListBusinessCompanies: []
    		};

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setSellersMainView = function (id) {
                vm.model.bgSellersMain.selected = vm.model.setting.gb_sellers_main = id;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                sellersService.getSeller().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    setSellersMainView(vm.model.setting.gb_sellers_main);

                    $q.all([
                        loadSellers()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListBusinessCompanies);
                });
            };

            let loadSellers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.gb_sellers_main) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;
                
    			vm.model.itemsListSellers = [];
                
                return sellersService.listSellers(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListSellers);

                    angular.forEach(vm.model.itemsListSellers, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
    			});
    		};

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_sellers_main':
                    case 'businessco_no':
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadSellers();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.sellerId = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

    		// ## BUTTON GROUP FUNCTIONS ##
            
    		vm.selectBgSellersMain = function (value) {
                vm.model.bgSellersMain.selected = value;
                vm.model.setting.gb_sellers_main = vm.model.bgSellersMain.selected;

                rememberFunc('gb_sellers_main');
                loadSellers();
    		};
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'seller':
                        if (tabletSize.matches === true && vm.model.itemState === 'seller') {
                            vm.model.sellerId = '0';
                        } else {
                            go({ seller_id: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadSellers();
                        break;
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

		    // #endregion ON INIT FUNCTION
    	}]
    });
})();
