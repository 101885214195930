(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productUnits', {
        templateUrl: 'views/components/views/productUnits/productUnits.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productUnitsService', function ($stateParams, stateService, productUnitsService) {
            const vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                itemsListProductUnits: []
            };

            let loadProductUnits = function () {
                productUnitsService.listProductUnits({ prod_id: prodId }).then(function (list) {
                    angular.copy(list, vm.model.itemsListProductUnits);
                });
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'productunit':
                        stateService.go(state, {
                            prod_id: prodId,
                            productunit_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadProductUnits();
            };
        }]
    });
})();
