import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { CellValueChangedEvent, IHeaderParams, IRowNode, SortDirection } from '@ag-grid-community/core';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { GridOptions, GridRow } from '../../grid.types';
import { GridService } from '../../grid.service';

@Component({
    selector: 'tt-checkbox-column-header',
    templateUrl: './checkbox-column-header.component.html',
    styleUrls: ['./checkbox-column-header.component.css'],
})
export class CheckboxColumnHeaderComponent implements IHeaderAngularComp, OnDestroy {
    /**
     * Header parameters and grid-options for this column header component.
     */
    public params?: IHeaderParams & { ttGridOptions: GridOptions };

    /**
     * The check value of the header checkbox.
     */
    public headerChecked = false;

    /**
     * The current sort direction of the column.
     */
    public sort: SortDirection | undefined;

    /**
     * The sort index of the column.
     */
    public sortIndex: number | null | undefined;

    /**
     * The number of active sort applied to the grid.
     */
    public numberOfActiveSorts: number = 0;

    /**
     * Whether the column is editable and should show a checkbox header.
     */
    public editable: boolean = false;

    /**
     * Event emitted when the checkbox value of the header checkbox has changed.
     */
    @Output()
    checkboxChanged = new EventEmitter<string>();

    constructor(private gridService: GridService) {}

    /**
     * Handles processing of sort for this column header.
     *
     * @param event the mouse event which triggered the sorting.
     */
    public toggleSort(event: MouseEvent) {
        this.params?.progressSort(event.shiftKey);
        this.setSort();
    }

    /**
     * Checks all boxes to the value of the header checkbox.
     *
     * @returns empty promise which fullfils once all changes are saved (if auto-save is applied).
     */
    public async checkAll() {
        if (!this.params) return;

        this.headerChecked = !this.headerChecked;
        const itemsToUpdate: GridRow[] = this.getRowsWithUpdatedCheckValue();

        this.params?.api.applyTransactionAsync({ update: itemsToUpdate }, async (nodes) => {
            if (!this.params) return;

            this.saveChanges();

            this.checkboxChanged.emit(this.params.column.getColId());
        });
    }

    /**
     * Returns a list of of grid-rows whose check value of this column this header is applied for has been updated.
     *
     * @returns list of grid-rows with updated check value for the current column..
     */
    private getRowsWithUpdatedCheckValue() {
        const itemsToUpdate: GridRow[] = [];

        if (this.params?.api.isAnyFilterPresent()) {
            this.params.api.forEachNodeAfterFilter((node) => {
                this.updateRowNodeData(node);
                itemsToUpdate.push(node.data);
            });
        } else {
            this.params?.api.forEachNode((node: IRowNode) => {
                this.updateRowNodeData(node);
                itemsToUpdate.push(node.data);
            });
        }

        this.params?.ttGridOptions.gridfunc?.refreshToolbarBtnDisability();
        return itemsToUpdate;
    }

    /**
     * Handles saving of changes.
     */
    private async saveChanges() {
        if (this.params?.ttGridOptions.dataTask?.saveData?.autoSave) {
            if (this.params?.ttGridOptions.dataTask.saveData.hideRefreshSpinner !== true) {
                this.params.api.showLoadingOverlay();
            }

            try {
                await this.gridService.saveChanges('update', this.params.ttGridOptions, this.params.api);
            } catch (error) {
                console.log(error);
            } finally {
                if (this.params.ttGridOptions.dataTask.saveData.readAfterSave === true && !!this.params.ttGridOptions.gridfunc) {
                    await this.params.ttGridOptions.gridfunc?.read();
                    this.updateHeaderCheckboxBasedOnDisplayedRows();
                }
                this.params.api.hideOverlay();
            }
        }
    }

    /**
     * Updates the check value of the given node.
     *
     * @param node the node to update.
     * @returns the data of the updated node.
     */
    private updateRowNodeData(node: IRowNode) {
        const data = node.data;

        if (data) {
            if (!this.params?.column.getColId() || !this.params?.ttGridOptions.data?.changes || !node?.id || (this.headerChecked === true && ['1', 1, true].includes(data?.[this.params.column.getColId()])) || (this.headerChecked === false && ['0', 0, false].includes(data?.[this.params.column.getColId()]))) return;

            data[this.params.column.getColId()] = this.headerChecked;
            data._dirty = true;

            let editedRow = this.params.ttGridOptions.data?.rowData.findIndex((row) => row[this.params!.ttGridOptions.dataTask?.loadData?.primaryKey || '_uuid'] === data[this.params!.ttGridOptions.dataTask?.loadData?.primaryKey || '_uuid']);

            if (editedRow !== -1) {
                this.params.ttGridOptions.data!.rowData[editedRow!] = data;
            }

            if (this.params?.column.getColId() !== 'is_selected') {
                this.params!.ttGridOptions.data.changes[node.id] = { state: 'update', data: data };
            }
        }

        return data;
    }

    /**
     * Sets the sort values for this component
     */
    private setSort() {
        if (!this.params) return;

        this.sort = this.params.column.getSort();
        this.sortIndex = this.params.column.getSortIndex();
        this.numberOfActiveSorts = this.params.api.getColumnState().filter((state) => !!state.sort).length;
    }

    agInit(params: IHeaderParams<any, any> & { ttGridOptions: GridOptions; checkboxChanged: (colId: string) => void }): void {
        this.params = params;
        this.setSort();
        this.editable = !!params.ttGridOptions.data?.columnInfo?.[params.column.getColId()]?.editable;
        this.params.api.setGridOption('onSortChanged', () => this.setSort());
        this.checkboxChanged.subscribe((event: string) => params.checkboxChanged(event));
        this.params.api.addEventListener('cellValueChanged', this.updateHeaderCheckboxBasedOnDisplayedRows);
        this.params.api.addEventListener('rowDataUpdated', this.updateHeaderCheckboxBasedOnDisplayedRows);
        // this.params.api.setGridOption('onCellValueChanged', )
    }

    private updateHeaderCheckboxBasedOnDisplayedRows = () => {
        if (!!this.params) {
            this.headerChecked = this.allRowsChecked(this.params!.column.getColId());
        } else {
            this.headerChecked = false;
        }
    };

    private allRowsChecked(colId: string) {
        const checkedRows: GridRow[] = [];
        let currentRowCount = 0;

        if (this.params?.api.isAnyFilterPresent()) {
            this.params.api.forEachNodeAfterFilter((node) => {
                if (node.group === true || ['1', 1, true].includes(node?.data?.[colId])) {
                    checkedRows.push(node.data);
                }
                currentRowCount++;
            });
        } else {
            this.params?.api.forEachNode((node: IRowNode) => {
                if (node.group === true || ['1', 1, true].includes(node?.data?.[colId])) {
                    checkedRows.push(node.data);
                }
                currentRowCount++;
            });
        }

        return checkedRows.length === currentRowCount;
    }

    refresh(_: IHeaderParams<any, any>): boolean {
        return false;
    }

    ngOnDestroy(): void {
        this.params?.api.removeEventListener?.('cellValueChanged', this.updateHeaderCheckboxBasedOnDisplayedRows);
        this.params?.api.addEventListener('rowDataUpdated', this.updateHeaderCheckboxBasedOnDisplayedRows);
    }
}
