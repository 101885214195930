(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('docArc', {
        templateUrl: 'views/components/views/docArc/docArc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$rootScope', '$timeout', '$stateParams', 'appConfig', 'companyService', 'docarcService', 'userService', 'fileService', 'ieScreenBlockService', 'stateService', function ($rootScope, $timeout, $stateParams, appConfig, companyService, docarcService, userService, fileService, ieScreenBlockService, stateService) {
            var vm = this;

            vm.model = {
                fontSize: userService.fontSize,
                docarcSelection: {
                    default: '0',
                    selected: 'p',
                    records: [
                        { item_id: '1', item_name: 'docarc_show', item_func: 'p' },
                        { item_id: '2', item_name: 'docarc_info', item_func: 'i' }
                    ]
                },
                docarcBoxes: [
                    { item_id: uuid(), type: 'v', size: 0 },
                    { item_id: uuid(), type: 'v', size: 33 },
                    { item_id: uuid(), type: 'v', size: 67 }
                ],
                fileItem: {},
                showDocument: false,
                canShowDocument: false,
                viewerOptions: {
                    wopiServerUrl: '',
                    documentHeight: 800,
                    maxWidth: 800,
                    maxHeight: 800,
                    canConvert: true,
                    officeControl: {},
                    showIcons: false,
                    clientSessionId: appConfig.clientSessionId
                }
            };

            var onConvert = function (data) {
                fileService.isConvertCompleted({ fileItemId: data.fileItemId, fileItemKeyno: data.fileItemKeyno }).then(function (result) {
                    if (result.isCompleted === true) {
                        // converted, rename document in docarc
                        docarcService.update({
                            docarcKeyno: vm.model.fileItem.docarc_keyno,
                            docFileName: result.fileName
                        });
                    } else {
                        // not finished converting
                        data.fileItemKeyno = result.fileItemKeyno;

                        $timeout(onConvert, 200, true, data);
                    }
                });
            };

            var dereg = $rootScope.$on('ieOfficeOnline-onConvert', function (event, data) {
                // We need to watch the fileitem and modify the filename in docarc when convert is completed.
                // To do this we poll the fileitem and when fileItem.IsConverted == true we can update docarc.
                data.fileItemKeyno = 0;

                onConvert(data);
            });

            var canShowDocument = function () {
                if (fileService.isOfficeContent(vm.model.fileItem.contentType) === true) return true;
                if (vm.model.fileItem.localFileName.toLowerCase().endsWith('.pdf')) return true;

                var fileExtension = vm.model.fileItem.localFileName.split('.').pop().toLowerCase();
                var thumbFileName = vm.model.fileItem.thumbnailLink.split('/').pop();
                var thumbFileNameWithoutExtension = thumbFileName.split('.').slice(0, -1).join('.').toLowerCase();

                // if thumb filename without extension is same as extension then thumb is just a placeholder icon.
                return thumbFileNameWithoutExtension !== fileExtension;
            };

            var load = function () {
                ieScreenBlockService.start();

                vm.model.viewerOptions.wopiServerUrl = companyService.fileServerUrl;

                docarcService.loadP2DocarcGet($stateParams.docarc_keyno).then(function (fileItem) {
                    angular.copy(fileItem, vm.model.fileItem);

                    fileService.getFileDetails({ fileName: vm.model.fileItem.docfilename, maxWidth: 1200, maxHeight: 600 }).then(function (result) {
                        angular.extend(vm.model.fileItem, result);

                        vm.model.showDocument = true;

                        vm.model.canShowDocument = canShowDocument();

                        ieScreenBlockService.stop();
                    });
                });
            };

            var dereg2;

            if (companyService.isReady === true) {
                load();
            } else {
                dereg2 = companyService.on('datachanged', function () {
                    if (companyService.isReady !== true) return;

                    dereg2();
                    load();
                });
            }

            vm.$onDestroy = function () {
                if (angular.isFunction(dereg)) {
                    dereg();
                }

                if (angular.isFunction(dereg)) {
                    dereg();
                }
            };

            vm.selectDocArcView = function (docarcView) {
                vm.model.docarcSelection.selected = docarcView;
            };

            vm.getActiveFileLink = function () {
                return fileService.getTokenLink(vm.model.fileItem.imageLink);
            };

            vm.showDocument = function () {
                if (vm.model.showDocument !== true) {
                    $timeout(vm.showDocument, 200);

                    return;
                }

                if (vm.model.fileItem.contentType.substring(0, 16) === 'application/vnd.') {
                    if (angular.isFunction(vm.model.viewerOptions.officeControl.open) !== true) return;

                    // BJS 20241213 - Lagt til clientSessionId
                    vm.model.viewerOptions.officeControl.open(vm.model.fileItem.fileItemId, 'view', appConfig.clientSessionId);
                } else {
                    var tokenLink = fileService.getTokenLink(vm.model.fileItem.imageLink);

                    switch (vm.model.fileItem.contentType) {
                        case 'application/pdf':
                            window.open(tokenLink);
                            break;
                        default:
                            let urlExternal = '';

                            // RHE Added to work with goNative (ios app)
                            if (window.navigator.userAgent.indexOf('gonative') > 0) {
                                urlExternal = 'external:';
                            }

                            var win = window.open(urlExternal);

                            win.document.body.innerHTML = '<img src="' + tokenLink + '"/>';
                            break;
                    }
                }
            };

            vm.downloadDocument = function () {
                let target = '_self';

                // RHE Added to work with goNative (ios app)
                if (window.navigator.userAgent.indexOf('gonative') > 0) {
                    target = '_blank';
                }

                let fileSrc = vm.getActiveFileLink();

                let redirectWindow = window.open(fileSrc, target);
                redirectWindow.location;
            };

            vm.saveP2DocArc = function () {
                var datatosave = vm.model.fileItem;
                datatosave.wopiIsConverted = 0;

                docarcService.saveP2DocArc(datatosave).then(function () {
                    stateService.back();
                });
            };

            vm.deleteDocArc = function () {
                docarcService.genericFunction(434, {
                    docarcKeyno: $stateParams.docarc_keyno
                }).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();
