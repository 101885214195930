(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('serviceObjectTypeInt', {
        templateUrl: 'views/components/views/serviceObjectTypeInt/serviceObjectTypeInt.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'economyService', 'serviceObjectTypeIntService', function ($stateParams, stateService, translateService, modalService, economyService, serviceObjectTypeIntService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let serviceObjectTypeKeyno = $stateParams.serviceobjecttype_keyno;
            let serviceObjectTypeIntKeyno = $stateParams.serviceobjecttypeint_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListActivities: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                serviceObjectTypeIntService.getSetting({
                    serviceobjecttype_keyno: serviceObjectTypeKeyno,
                    serviceobjecttypeint_keyno: serviceObjectTypeIntKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadActivityTypes = function () {
                economyService.listActivitityTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListActivities);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                serviceObjectTypeIntService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    serviceObjectTypeIntService.deleteItem({ serviceobjecttypeint_keyno: vm.model.edit.serviceobjecttypeint_keyno }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadActivityTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
