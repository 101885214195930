<tt-page>
    <tt-page-heading ttModel="h_update_scripts" ttTranslate></tt-page-heading>
    <tt-grid class="flex-1 p-2" [(ttOptions)]="grid"></tt-grid>
</tt-page>
<!-- 
<div ng-if="vm.model.tabletSize.matches === true">
    <tt-card class="col-xs-12 col-md-9 p-5" ng-if="vm.model.itemState === 'p2updatescriptedit'">
        <tt-page-heading class="col-xs-12 sp-0" tt-model="h_update_scripts" tt-translate="true"></tt-page-heading>
    </tt-card>

    <div class="col-xs-12 sp-0">
        <tt-card class="col-xs-12 col-md-3 p-5" ng-show="vm.model.setting.runbatch === 'all'">
            <tt-select class="col-xs-12 sp-0" tt-label="runbatch" tt-model="vm.model.setting.runbatch" tt-change="vm.onChanged($value, 'runbatch')" tt-data="vm.model.selectListRunBatches" tt-data-id="runbatch" tt-data-name="runbatch_name"></tt-select>
        </tt-card>

        <tt-card class="col-xs-12 col-md-3 p-5" ng-show="vm.model.setting.runbatch !== 'all'">
            <tt-select-buttons class="col-xs-12 sp-0" tt-label="runbatch" tt-model="vm.model.setting.runbatch" tt-change="vm.onChanged($value, 'runbatch')" tt-data="vm.model.selectListRunBatches" tt-data-id="runbatch" tt-data-name="runbatch_name" tt-buttons="vm.model.runBatchButtons"></tt-select-buttons>
        </tt-card>

        <tt-card class="col-xs-12 col-md-3 p-5">
            <tt-input-buttons class="col-xs-12 sp-0" tt-label="search" tt-model="vm.model.setting.filtervalue" tt-change="vm.model.setting.filtervalue = $value" tt-on-blur="vm.onBlur('filtervalue')" tt-placeholder="" tt-buttons="vm.model.searchTextButtons" tt-readonly="false"></tt-input-buttons>
        </tt-card>

        <tt-card class="col-xs-12 col-md-3 p-5">
            <tt-button class="col-xs-12 sp-0" tt-text="register_new_p2_updatescript" tt-click="vm.goToFunc('p2updatescriptedit')" tt-text-align="left" tt-icon="fa fa-solid fa-plus" tt-icon-pull-right="true"></tt-button>
        </tt-card>
    </div>

    <div ng-if="vm.model.itemState !== 'p2updatescriptedit'">
        <tt-list class="col-xs-12 sp-0" list-items="vm.model.itemsListUpdateScripts" badge="" ltype="" design-var="s12t0" filter-val="vm.model.setting.filtervalue"></tt-list>
    </div>

    <div ng-if="vm.model.itemState === 'p2updatescriptedit'">
        <div ng-show="vm.model.p2UpdateScriptKeyno === ''">
            <tt-list class="col-xs-12 col-md-12 p-5" list-items="vm.model.itemsListUpdateScripts" badge="" ltype="custom" design-var="s12t0" filter-val="vm.model.setting.filtervalue" optionfunc="vm.openScript(item)"></tt-list>
        </div>

        <div ng-show="vm.model.p2UpdateScriptKeyno === '0' || vm.model.p2UpdateScriptKeyno > 0">
            <tt-list class="col-xs-12 col-md-6 p-5" list-items="vm.model.itemsListUpdateScripts" badge="" ltype="custom" design-var="s12t0" filter-val="vm.model.setting.filtervalue" optionfunc="vm.openScript(item)"></tt-list>
            <p2-update-script-edit class="col-xs-12 col-md-6 p-5" p2-update-script-keyno="vm.model.p2UpdateScriptKeyno" on-save="vm.updateScripts()"></p2-update-script-edit>
        </div>
    </div>
</div> -->
