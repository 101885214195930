import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../form-field-button/form-field-button.component';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'tt-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
    // providers: [
    //     {
    //         provide: NG_VALUE_ACCESSOR,
    //         useExisting: forwardRef(() => SelectComponent),
    //         multi: true,
    //     },
    // ],
})
export class SelectComponent<TData = { [key: string]: string | number | boolean }> extends FormFieldBaseComponent implements OnInit, OnChanges, FormFieldButtons {
    value: any = '';

    // Implement the ControlValueAccessor interface methods

    // onChange: any = () => {};
    // onTouched: any = () => {};

    // writeValue(value: any): void {
    //     this.value = value;
    // }

    // registerOnChange(fn: any): void {
    //     this.onChange = fn;
    // }

    // registerOnTouched(fn: any): void {
    //     this.onTouched = fn;
    // }
    // setDisabledState?(isDisabled: boolean): void {
    //     // Optionally handle the disabled state
    // }
    /**
     * List of objects to display in the select list.
     */
    @Input()
    public ttData: TData[] = [];

    /**
     * The key of the attribute in the objects to use as display name, default is `'item_name'`.
     */
    @Input()
    public ttDataName: keyof TData = 'item_name' as keyof TData;

    /**
     * The id attribute of the objects in the list, default is `'item_id'`.
     */
    @Input()
    public ttDataId: keyof TData = 'item_id' as keyof TData;

    /**
     * The id of the selected object.
     */
    @Input()
    public ttModel: string = '';

    /**
     * The name of the selected object, used for readonly.
     */
    _viewValue: string = '';

    /**
     * Event emitted when the value of `ttModel` changes, with the new value given in event.
     */
    @Output()
    public ttModelChange = new EventEmitter<string>();

    /**
     * Event emitted when the input field is blurred (loses focus).
     */
    @Output()
    public ttOnBlur = new EventEmitter();

    /**
     * Whether to hide the arrow normally displayed on a select.
     */
    @Input()
    public get ttHideArrow(): boolean {
        return this._hideArrow;
    }
    public set ttHideArrow(value: BooleanInput) {
        this._hideArrow = coerceBooleanProperty(value);
    }
    private _hideArrow = false;

    @Input()
    public ttButtonParms?: { [key: string]: any };

    @Input()
    public ttButtons?: FormButton[] = [];

    /**
     * Whether to display a generic clear button next to the form field.
     *
     * @emits ttModelChange with an empty string.
     * @default false
     */
    @Input()
    public get ttShowClearButton(): boolean {
        return this._showClearButton;
    }
    public set ttShowClearButton(value: BooleanInput) {
        this._showClearButton = coerceBooleanProperty(value);
    }
    private _showClearButton = false;

    @Input()
    public ttClearValue = '0';

    override style: Style = {
        select: {},
    };

    /**
     * The ids of elements in the component.
     */
    id = {
        select: crypto.randomUUID(),
    };

    // constructor(layoutService: LayoutService, coreComponentService: CoreComponentService, translateService: TranslateService, remember: Remem) {
    //     super(layoutService, coreComponentService, translateService);

    //     // layoutService.layoutChanged.subscribe((info) => {
    //     //     if (info) {
    //     //         this.coreComponentService.setLayoutStyle(this.style, info);
    //     //     }
    //     // });
    // }

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, mainElement: 'select' });
    }

    /**
     * Handles the change of model value.
     *
     * @param event the new model value.
     */
    public onModelChanged(event: any) {
        this._viewValue = this.ttData?.find((item) => item?.[this.ttDataId] === event)?.[this.ttDataName]?.toString() ?? '';
        this.rememberSubject.next(event);
        this.ttModelChange.emit(event);
    }

    public clearInput() {
        if (this.ttModel !== this.ttClearValue) {
            this.ttModel = this.ttClearValue;
            this.ttModelChange.emit(this.ttModel);
        }
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.ttModel = (await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue;
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!!this.ttModel && this.ttData instanceof Array && this.ttData.length > 0) {
            this._viewValue = this.ttData?.find((item) => item?.[this.ttDataId] === this.ttModel)?.[this.ttDataName]?.toString() ?? '';
        }

        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes['ttData']?.currentValue instanceof Array) {
            this._viewValue = this.ttData?.find((item) => item?.[this.ttDataId] === this.ttModel)?.[this.ttDataName]?.toString() ?? '';
        }

        if (changes['ttHideArrow']) {
            this.ttHideArrow = changes['ttHideArrow'].currentValue;
        }
    }
}
