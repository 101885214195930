import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'tt-divider-pane',
    templateUrl: './divider-pane.component.html',
    styleUrls: ['./divider-pane.component.css'],
})
export class DividerPaneComponent {
    @Input()
    public ttId?: string;

    @Input()
    public set ttSize(value: NumberInput) {
        this._size = coerceNumberProperty(value);
    }
    public get ttSize(): number {
        return this._size;
    }
    private _size: number = 0;

    constructor(public elementRef: ElementRef) {}
}
