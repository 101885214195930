(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectLabour', {
        templateUrl: 'views/components/views/selectLabour/selectLabour.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'economyService', 'userService', function (stateService, economyService, userService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                bgLabourSelection: {
                    selected: 'ACT',
                    buttons: [
                        { id: 'ACT', label: 'active', color: 'primary', onClick: () => vm.model.bgLabourSelection.selected = 'ACT' },
                        { id: 'NOT_ACT', label: 'not_active', color: 'primary', onClick: () => vm.model.bgLabourSelection.selected = 'NOT_ACT' },
                        { id: 'ALL', label: 'all', color: 'primary', onClick: () => vm.model.bgLabourSelection.selected = 'ALL' },
                    ]
                },
                itemsListLabours: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadLabours = function () {
                vm.model.itemsListLabours = [];

                economyService.listLabours({ activestatus: vm.model.bgLabourSelection.selected }).then(function (list) {
                    angular.copy(list, vm.model.itemsListLabours);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgLabourSelection = function (value) {
                vm.model.bgLabourSelection.selected = value;

                loadLabours();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToPreUserSettings = function (item) {
                // BJS 20180525 - Endret fra searchService til userService
                userService.userEditCache.selectedLabourNo = item.item_id;
                userService.userEditCache.selectedLabourName = item.item_name;
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'labouredit':
                        go({ labour_no: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadLabours();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
