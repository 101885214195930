(function () {
    'use strict';
    var module = angular.module("imApp");

    module.factory("logisticService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            listEquipments: function (parms) {
                return $ihttp.post({
                    method: 30,
                    parameters: parms || {}
                });
            },
            listProductGroups: function (parms) {
                return $ihttp.post({
                    method: 41,
                    parameters: parms || {}
                });
            },

            // If you find this procedure named logisticService.loadBusinessCoList() in any controller/component, you should rename it to logisticService.listBusinessCompanies(). - ODL
            loadBusinessCoList: function (add_all) {
                return p2DataTaskService.call(104, {
                    add_all: add_all || ''
                });
            },

            listBusinessCompanies: function (addAll) {
                return p2DataTaskService.call(104, {
                    add_all: addAll || ''
                });
            },

            // Use this procedure name instead of loadBusinessCoList. - ODL
            listCompanies: function (parms) { // add_all || {}
                return $ihttp.post({
                    method: 104,
                    parameters: parms || {}
                });
            },

            listDepartments: function (businessCoNo) {
                return p2DataTaskService.call(105, {
                    businessco_no: businessCoNo || ''
                });
            },
            listDepartment: function (parms) {
                return $ihttp.post({
                    method: 105,
                    parameters: parms || {}
                });
            },
            listProductSizes: function (parms) {
                return $ihttp.post({
                    method: 135,
                    parameters: parms || {}
                });
            },
            listPohCompletionMethods: function (parms) {
                return $ihttp.post({
                    method: 216,
                    parameters: parms || {}
                });
            },
            listCompletionMethods: function (parms) {
                return $ihttp.post({
                    method: 216,
                    parameters: parms || {}
                });
            },
            listPohCompletionPrinciples: function (parms) {
                return $ihttp.post({
                    method: 217,
                    parameters: parms || {}
                });
            },
            listCompletionPrinciples: function (parms) {
                return $ihttp.post({
                    method: 217,
                    parameters: parms || {}
                });
            },
            listUnits: function (parms) {
                return $ihttp.post({
                    method: 218,
                    parameters: parms || {}
                });
            },
            listCountries: function (parms) {
                return $ihttp.post({
                    method: 220,
                    parameters: parms || {}
                });
            },
            listOperations: function (parms) {
                return $ihttp.post({
                    method: 265,
                    parameters: parms || {}
                });
            },
            listPrinters: function (parms) {
                return $ihttp.post({
                    method: 337,
                    parameters: parms || {}
                });
            },
            listProductChoices: function (parms) {
                return $ihttp.post({
                    method: 366,
                    parameters: parms || {}
                });
            },
            listDeliveryMethods: function (parms) {
                return $ihttp.post({
                    method: 385,
                    parameters: parms || {}
                });
            },
            listCargonizerPrinters: function (parms) {
                return $ihttp.post({
                    method: 423,
                    parameters: parms || {}
                });
            },
            listStockOnLocations: function (parms) {
                return $ihttp.post({
                    method: 459,
                    parameters: parms || {}
                });
            },
            listStockLocation: function (parms) { // stockzoneclass_keyno, factory_id
                return $ihttp.post({
                    method: 472,
                    parameters: parms || {}
                });
            },
            listPlanPolicies: function (parms) {
                return $ihttp.post({
                    method: 494,
                    parameters: parms || {}
                });
            },
            //loadOrderStatusList: function (orderstatus_id) {
                //return p2DataTaskService.call(620, {
                    //orderstatus_id: orderstatus_id
                //});
            //},

            //loadOrderTypes: function (ordertype_id) {
                //return p2DataTaskService.call(612, {
                    //ordertype_id: ordertype_id
                //});
            //},
            listOrderStatuses: function (parms) {
                return $ihttp.post({
                    method: 620,
                    parameters: parms || {}
                });
            },
            listOrderTypes: function (parms) {
                return $ihttp.post({
                    method: 612,
                    parameters: parms || {}
                });
            },

            // Used in orderhead_copy
            //loadOrderTypeList: function (ordertype_id) {
            //    return p2DataTaskService.call(612, {
            //        ordertype_id: ordertype_id
            //    });
            //},


            // If you find this procedure named logisticService.loadStockPlaceList() in any controller/component, you should rename it to logisticService.listStockPlaces(). - ODL
            loadStockPlaceList: function (businessco_no, add_all) {
                return p2DataTaskService.call(622, {
                    businessco_no: businessco_no,
                    add_all: add_all || ''
                });
            },

            listStockPlaces: function (businessco_no, add_all) {
                return p2DataTaskService.call(622, {
                    businessco_no: businessco_no,
                    add_all: add_all || ''
                });
            },

            // Use this procedure name instead of loadStockPlaceList. - ODL
            listStocks: function (parms) { // businessco_no, add_all || {}
                return $ihttp.post({
                    method: 622,
                    parameters: parms || {}
                });
            },
            listDeliveryTerms: function (parms) {
                return $ihttp.post({
                    method: 623,
                    parameters: parms || {}
                });
            },
            listBusinesses: function (parms) {
                return $ihttp.post({
                    method: 638,
                    parameters: parms || {}
                });
            },
            listCoops: function (parms) {
                return $ihttp.post({
                    method: 639,
                    parameters: parms || {}
                });
            },
            listSuppliers: function (parms) {
                return $ihttp.post({
                    method: 653,
                    parameters: parms || {}
                });
            },
            listProductPlaces: function (parms) {
                return $ihttp.post({
                    method: 679,
                    parameters: parms || {}
                });
            },
            listProductionPlaces: function (parms) {
                return $ihttp.post({
                    method: 679,
                    parameters: parms || {}
                });
            },
            listPortalUserPurchases: function (parms) {
                return $ihttp.post({
                    method: 684,
                    parameters: parms || {}
                });
            },
            getNextCounterNumber: function (parms) {
                return $ihttp.post({
                    method: 678,
                    parameters: parms || {}
                });
            },
            getStockPlace: function (parms) {
                return $ihttp.post({
                    method: 738,
                    parameters: parms || {}
                });
            },
            loadProdSizeColliList: function (prodsize_keyno) {
                return p2DataTaskService.call(913, {
                    prodsize_keyno: prodsize_keyno
                });
            },
            listStockZones: function (parms) {
                return $ihttp.post({
                    method: 929,
                    parameters: parms || {}
                });
            },
            listProductCycles: function (parms) {
                return $ihttp.post({
                    method: 960,
                    parameters: parms || {}
                });
            },
            listProdBeoMethods: function (parms) {
                return $ihttp.post({
                    method: 997,
                    parameters: parms || {}
                });
            },
            listProductionOrderMethods: function (parms) {
                return $ihttp.post({
                    method: 997,
                    parameters: parms || {}
                });
            },
            listStockLocations: function (parms) {
                return $ihttp.post({
                    method: 1051,
                    parameters: parms || {}
                });
            },
            listTrolleys: function (parms) { // stockplace_id
                return $ihttp.post({
                    method: 1058,
                    parameters: parms || {}
                });
            },
            listStockCorrectionCodes: function (parms) {
                return $ihttp.post({
                    method: 1071,
                    parameters: parms || {}
                });
            },
            listCounties: function (parms) {
                return $ihttp.post({
                    method: 1307,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
