(function() {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderHeads', {
        templateUrl: 'views/components/views/orderHeads/orderHeads.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$localStorage', 'utilityService', 'stateService', 'orderHeadsService', 'logisticService', 'rememberService', 'layoutService', function ($stateParams, $q, $localStorage, utilityService, stateService, orderHeadsService, logisticService, rememberService, layoutService) {
            const vm = this;
            var onDestroy = [];
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let webPage = stateService.getCurrentName() + '/' + $stateParams.argtype + '/' + $stateParams.argvalue;

            let variableNames = {
                ordersearch: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            vm.onGridBoxResized = function () {
                vm.grid?.gridfunc?.resize();
                vm.gridOrderLine?.gridfunc?.resize();
            }

            vm.model = {
                argType: argType,
                mobileSize: layoutService.getMediaQueries().tablet,
                bgOrderHeadsRelatedSelection: {
                    selected: 'orderheads_custact_related',
                    buttons: [
                        { id: 'orderheads_custact_related', label: 'orderheads_custact_related', color: 'primary', onClick: () => vm.model.bgOrderHeadsRelatedSelection.selected = 'orderheads_custact_related' },
                        { id: 'orderheads_customer_related', label: 'orderheads_customer_related', color: 'primary', onClick: () => vm.model.bgOrderHeadsRelatedSelection.selected = 'orderheads_customer_related' }
                    ]
                },
                companyButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                orderSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('ordersearch') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => vm.searchItems() }
                ],
                customerNumberAndNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.setting.cust_no }) }
                ],
                customerNumberAndNameButtons2: [
                    //{ id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('cust_no_and_name') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.setting.cust_no }) }
                ],
                customerNumberButtons: [
                    //{ id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('cust_no') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.setting.cust_no }) }
                ],
                orderTypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('ordertype_id') }
                ],
                orderStatusButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('orderstatus_id') }
                ],
                sellerButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('seller_id') }
                ],
                registeredByPortalUserButtons: [
                    { id: 'erase', icon: 'fas fa-user', color: 'success', type: 'success', onClick: () => setCurrentPortalUser() }
                ],
                registeredByPortalUserButtons2: [
                    { id: 'erase', icon: 'fas fa-user', color: 'success', type: 'success', onClick: () => setCurrentPortalUser() },
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('reg_by_portal_user_keyno') }
                ],
                orderHeadsViewBoxes: {
                    //height: '25vh',
                    rememberId: 'orderheads.boxes',
                    boxes: [
                        { item_id: uuid(), item_name: 'orderhead', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'orderlines', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'filters', type: 'v', size: '25', depth: 1 },
                        { item_id: uuid(), item_name: 'orderheadgrid', type: 'v', size: '75', depth: 1 }
                    ],
                },
                setting: {
                    cust_no: '',
                    cust_name: ''
                },
                selectListCompanies: [],
                selectListOrderTypes: [],
                selectListOrderStatuses: [],
                selectListPortalUsers: [],
                selectListSellers: [],
                itemsListOrders: {
                    total: 0,
                    records: []
                },
                buttonStyle: {},
                gridReady: false,
                gridOrderLineReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_orderheads_orderhead_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        height: 'fill'
                    },
                    onDataSourceChanges: function (e) {
                        console.log('onDatasourcechanges');
                        //setTimeout(() => {
                        //    vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                        //})
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                onDataBound: () => {
                    setTimeout(() => {
                        try {
                            vm.grid.gridfunc.selectRow(0)
                            //vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                        } catch (error) {

                        }
                    }, 100);
                },
                //optionfunc: function (data) { vm.optionfunc(data.data); },
                onSelect: (event) => {
                    if (event.$item) {
                        vm.model.setting.selected_order_internal_no = event.$item.order_internal_no;

                        loadGridOrderLine();
                    }
                },

                gridfunc: null
            };

            vm.gridOrderLine = {
                dataTask: {
                    rememberId: 'w_orderheads_orderline_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        height: 'fill'
                    },
                    onDataSourceChanges: function (e) {
                      
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                onReady: () => setTimeout(() => vm.gridOrderLine?.gridfunc?.resize(), 250),
                optionfunc: null,
                gridfunc: null
            };

            // #region EDIT GRID ROW & COLUMN FUNCTION

            vm.optionfunc = function (data) {
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    vm.model.setting.selected_order_internal_no = data.clickedCell.dataItem.order_internal_no;

                    loadGridOrderLine();
                }
            };

		    // #endregion EDIT GRID ROW & COLUMN FUNCTION

            utilityService.setDefaultValues($localStorage, {
                orderHeadsCtrl: {
                    page: 1,
                    pageSize: 10
                }
            });
    
            vm.$storage = $localStorage;
    
            layoutService.onLayoutChanged(onDestroy, function (info) {
                vm.model.buttonStyle.fontSize = info.fontSizes.textSize;
                vm.model.buttonStyle.height = info.height + 'px';
                vm.model.buttonStyle.padding = '0px 5px'
            });

            let loadSetting = function () {
                const deferred = $q.defer();

                orderHeadsService.getSetting({
                    argtype: argType,
                    argvalue: argValue,
                    webpage: webPage
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadPortalUsers()
                    ]).then(() => {
                        loadOrdersPaged();
                        loadGrid();
                        loadGridOrderLine();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadOrderTypes = function () {
                logisticService.listOrderTypes({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListOrderTypes);
                });
            };

            let loadOrderStatuses = function () {
                logisticService.listOrderStatuses({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListOrderStatuses);
                });
            };

            let loadSellers = function () {
                orderHeadsService.listSellers({ add_all: '1', businessco_no: vm.model.setting.businessco_no != 99999 ? vm.model.setting.businessco_no : 0 }).then(function (list) {
                    angular.copy(list, vm.model.selectListSellers);
                });
            }

            let loadPortalUsers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return orderHeadsService.listPortalUsers({
                    businessco_no: vm.model.setting.businessco_no,
                    add_all: '1'
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListPortalUsers);
                });
            };

            var loadGrid = function () {
                console.log('loadGrid');

                vm.model.setting.selected_order_internal_no = '0';

                var mergeObj = angular.copy(vm.model.setting);
                mergeObj.page = vm.$storage.orderHeadsCtrl.page;
                mergeObj.pageSize = vm.$storage.orderHeadsCtrl.pageSize;
                mergeObj.argtype = argType;
                mergeObj.argvalue = argValue;
                mergeObj.gb_orderheads_related = vm.model.bgOrderHeadsRelatedSelection.selected;
                mergeObj.webpage = webPage;

                console.log('setting grid datatask');

                vm.grid.dataTask.loadData = {
                    method: 2852,
                    parameters: mergeObj
                };

                console.log('reading grid');

                //if (vm.grid?.gridfunc?.rebind && vm.model.gridReady) vm.grid.gridfunc.rebind();
                if (vm.grid.gridfunc !== null && vm.model.gridReady) vm.grid.gridfunc.read();

                console.log('grid ready');

                vm.model.gridReady = true;
            };

            var loadGridOrderLine = function () {
                vm.gridOrderLine.dataTask.loadData = {
                    method: 2853,
                    parameters: {
                        order_internal_no: vm.model.setting.selected_order_internal_no
                    }
                };

                //if (vm.gridOrderLine?.gridfunc?.rebind && vm.model.gridOrderLineReady) vm.gridOrderLine.gridfunc.rebind();
                if (vm.gridOrderLine.gridfunc !== null && vm.model.gridOrderLineReady) vm.gridOrderLine.gridfunc.read();

                vm.model.gridOrderLineReady = true;
            };

            let loadOrdersPaged = function () {
                if (utilityService.validateParmsValue(vm.model.setting.cust_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.orderstatus_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.ordertype_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.reg_by_portal_user_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_all_orders) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.ordersearch, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_dateselector, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgOrderHeadsRelatedSelection.selected) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_orderstatus, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_ordertype, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_orderdate, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_regby, true) !== true) return;
                
                vm.model.itemsListOrders.records = [];

                return orderHeadsService.listOrdersPaged({
                    page: vm.$storage.orderHeadsCtrl.page,
                    pageSize: vm.$storage.orderHeadsCtrl.pageSize,
                    argtype: argType,
                    argvalue: argValue,
                    cust_no: vm.model.setting.cust_no,
                    gb_orderheads_related: vm.model.bgOrderHeadsRelatedSelection.selected,
                    gb_orderstatus: vm.model.setting.gb_orderstatus,
                    orderstatus_id: vm.model.setting.orderstatus_id,
                    gb_ordertype: vm.model.setting.gb_ordertype,
                    gb_orderdate: vm.model.setting.gb_orderdate,
                    filterval: vm.model.setting.filterval,
                    ordertype_id: vm.model.setting.ordertype_id,
                    webpage: webPage,
                    businessco_no: vm.model.setting.businessco_no,
                    show_all_orders: vm.model.setting.show_all_orders,
                    ordersearch: vm.model.setting.ordersearch,
                    gb_regby: vm.model.setting.gb_regby,
                    reg_by_portal_user_keyno: vm.model.setting.reg_by_portal_user_keyno,
                    date_fom: vm.model.setting.date_fom,
                    date_tom: vm.model.setting.date_tom,
                    show_dateselector: vm.model.setting.show_dateselector
                }).then(function (list) {
                    vm.model.itemsListOrders.total = list.total;
                    
                    angular.copy(list.items, vm.model.itemsListOrders.records);
                });
            };

            let setCurrentPortalUser = function () {
                vm.model.setting.reg_by_portal_user_keyno = vm.model.setting.current_portal_user_keyno;

                loadOrdersPaged();
                loadGrid();
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'ordersearch':
                        case 'date_fom':
                        case 'date_tom':
                        case 'dateselector_index':
                            variableNames[key] = stateService.getCurrentName() + '/' + argType + '/' + argValue + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'ordersearch':
                        if (utilityService.validateParmsValue(vm.model.setting.ordersearch, true) !== true) return;

                        variableValue = vm.model.setting.ordersearch;
                        break;
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        if (vm.model.setting.businessco_no === '99999') {
                            loadPortalUsers();
                            loadOrdersPaged();
                            loadGrid();
                        }
                        break;
                    case 'ordersearch':
                        vm.model.setting.ordersearch = '';

                        if (vm.model.setting.ordersearch === '') vm.searchItems();
                        break;
                    //case 'cust_no_and_name':
                    //    vm.model.setting.cust_no_and_name = '';

                    //    if (vm.model.setting.cust_no_and_name === '') vm.model.setting.cust_no = '0';
                    //    break;
                    //case 'cust_no':
                    //    vm.model.setting.cust_no = '0';

                    //    if (vm.model.setting.cust_no === '0') vm.model.setting.cust_no_and_name = '';
                    //    break;
                    case 'ordertype_id':
                        vm.model.setting.ordertype_id = 'All';

                        if (vm.model.setting.ordertype_id === 'All') {
                            loadOrdersPaged();
                            loadGrid();
                        }
                        break;
                    case 'orderstatus_id':
                        vm.model.setting.orderstatus_id = '0';

                        if (vm.model.setting.orderstatus_id === '0') {
                            loadOrdersPaged();
                            loadGrid();
                        }
                        break;
                    case 'seller_id':
                        vm.model.setting.seller_id = '0';

                        if (vm.model.setting.seller_id === '0') {
                            loadOrdersPaged();
                            loadGrid();
                        }
                        break;
                    case 'reg_by_portal_user_keyno':
                        vm.model.setting.reg_by_portal_user_keyno = '0';

                        if (vm.model.setting.reg_by_portal_user_keyno === '0') {
                            loadOrdersPaged();
                            loadGrid();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.pageChanged = function () {
                loadOrdersPaged();
            };
    
            vm.setPageSize = function (size) {
                vm.$storage.orderHeadsCtrl.pageSize = size;
    
                vm.pageChanged();
            };

            vm.searchItems = function () {
                rememberFunc('ordersearch');
                loadOrdersPaged();
                loadGrid();
                loadGridOrderLine();
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'orderheadcreate':
                        go({
                            argtype: argType,
                            argvalue: argValue,
                            ordertype_id: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadOrdersPaged();
                    loadGrid();
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOrderHeadsRelatedSelection = function (value) {
                vm.model.bgOrderHeadsRelatedSelection.selected = value;

                loadOrdersPaged();
                loadGrid();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            // #region ON BLUR MULTI FUNCTIONS

            //vm.onBlur = function (id) {
            //    switch (id) {
            //        case 'ordersearch':
            //            if (utilityService.validateParmsValue(vm.model.setting.ordersearch) !== true) return;

            //            if (vm.model.setting.ordersearch > '') vm.searchItems();
            //            break;
            //        default:
            //            break;
            //    }
            //};

            // #endregion ON BLUR MULTI FUNCTIONS
            
            // #region FIELD CHANGE FUNCTION

            //vm.onChangedCustNo = function (value) {
            //    if (utilityService.validateWatchValue(value, vm.model.setting.cust_no) !== true) return;

            //    vm.model.setting.cust_no = value;

            //    if (utilityService.validateParmsValue(vm.model.setting.cust_no) !== true) return;

            //    loadOrdersPaged();

            //    vm.model.setting.cust_no_and_name = vm.model.setting.cust_no + ' ' + vm.model.setting.cust_name;

            //    if ($stateParams.argtype === 'cust_no') vm.model.setting.cust_no = $stateParams.argvalue;
            //};

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadPortalUsers();
                        loadSellers();
                        loadOrdersPaged();
                        loadGrid();
                        break;
                    case 'ordersearch':
                        if (utilityService.validateWatchValue(value, vm.model.setting.ordersearch) !== true) return;

                        vm.model.setting.ordersearch = value;

                        if (utilityService.validateParmsValue(vm.model.setting.ordersearch, true) !== true) return;

                        //if (vm.model.setting.ordersearch === '') vm.searchItems();
                        vm.searchItems();
                        break;
                    case 'ordertype_id':
                    case 'orderstatus_id':
                    case 'seller_id':
                    case 'reg_by_portal_user_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        loadOrdersPaged();
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                loadOrderTypes();
                loadOrderStatuses();
                loadSellers();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
