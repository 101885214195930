(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2Terminal', {
        templateUrl: 'views/components/views/p2Terminal/p2Terminal.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'p2TerminalService', 'logisticService', function ($stateParams, stateService, translateService, modalService, p2TerminalService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2TerminalKeyno = $stateParams.p2_terminal_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                p2TerminalKeyno: p2TerminalKeyno,
                edit: {},
                selectListCompanies: [],
                selectListTerminalTypes: [],
                selectListEquipments: [],
                selectListNotificationTypes: [],
                selectListPartDeliveryTypes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS
            
            let loadEdit = function () {
                p2TerminalService.getEdit({ p2_terminal_keyno: p2TerminalKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadTerminalTypes = function () {
                p2TerminalService.listTerminalTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListTerminalTypes);
                });
            }

            let loadEquipments = function () {
                logisticService.listEquipments().then(function (list) {
                    angular.copy(list, vm.model.selectListEquipments);
                });
            };

            let loadNotificationTypes = function () {
                p2TerminalService.listNotificationTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListNotificationTypes);
                });
            };

            let loadPartDeliveryTypes = function () {
                p2TerminalService.listPartDeliveryTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListPartDeliveryTypes);
                });
            };

            // #region LOAD PROCEDURE CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2TerminalService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    p2TerminalService.deleteItem({ p2_terminal_keyno: vm.model.edit.p2_terminal_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadTerminalTypes();
                loadEquipments();
                loadNotificationTypes();
                loadPartDeliveryTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
