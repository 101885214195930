(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceHeadEhf', {
        templateUrl: 'views/components/views/invoiceHeadEhf/invoiceHeadEhf.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'invoiceHeadEhfService', function ($stateParams, invoiceHeadEhfService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let invoiceNo = $stateParams.invoice_no;

            vm.model = {
                linkId: 'formInvoiceHeadEhfLink',
                edit: {},
                creatingEhf: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await invoiceHeadEhfService.getEdit({ invoice_no: invoiceNo }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON CREATE EHF FILE FUNCTION

            vm.createEhfFile = function () {
                vm.model.creatingEhf = true;
                
                invoiceHeadEhfService.createEhf(vm.model.edit).then(function (response) {
                    vm.model.creatingEhf = false;
                    vm.model.edit.ehf_content = response[0].ehf_content;
                }, function () {
                    vm.model.creatingEhf = false;
                });
            };

            // #endregion BUTTON CREATE EHF FILE FUNCTION

            // #region BUTTON SAVE TO FILE FUNCTION

            vm.saveToFile = function () {
                let elem = document.getElementById(vm.model.linkId);
                let text = vm.model.edit.ehf_content;

                let xmlData = new Blob([text], { type: 'text/xml' });
                let xmlUrl = URL.createObjectURL(xmlData);

                elem.setAttribute('href', xmlUrl);
                elem.setAttribute('download', vm.model.edit.filename);
                elem.click();
            };

            // #endregion BUTTON SAVE TO FILE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
