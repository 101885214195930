(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('timeRegisterNewJob', {
        templateUrl: 'views/components/views/trNewJob/trNewJob.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$state', 'stateService', 'translateService', 'modalService', 'trNewJobService', 'trNewJobConfService', 'timeRegisterService', function ($stateParams, $state, stateService, translateService, modalService, trNewJobService, trNewJobConfService, timeRegisterService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let labourNo = $stateParams.labour_no;
            let webPageName = stateService.getCurrentName();

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                labourNo: labourNo,
                shiftNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectshift', { labour_no: vm.model.trMenu.labour_no }) }
                ],
                productionOrderButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectpofinish', { webpagename: webPageName, labour_no: vm.model.trMenu.labour_no }) }
                ],
                operationNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectoperationpu', { webpagename: webPageName, labour_no: vm.model.trMenu.labour_no }) }
                ],
                equipmentNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectequipmentpu', { webpagename: webPageName, operation_id: vm.model.newJobMenu.newjob_operation_id }) }
                ],
                customerActivityNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => goToFunc('selectworkingorder') }
                ],
                oldCustomerActivityNoteButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('labourjobnote', { labour_no: vm.model.trMenu.labour_no }) }
                ],
                offDutyCodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('offdutycode_keyno') }
                ],
                bgNewJob: {
                    selected: 'trnewjob_job',
                    buttons: [
                        { id: 'trnewjob_job', label: 'trnewjob_job', color: 'primary', onClick: () => vm.model.bgNewJob.selected = 'trnewjob_job' },
                        { id: 'trnewjob_extra', label: 'trnewjob_extra', color: 'primary', onClick: () => vm.model.bgNewJob.selected = 'trnewjob_extra' }
                    ]
                },
                trMenu: {},
                newJobMenu: {},
                selectListOffDutyCodes: [],
                itemsListExtraPowTimes: [],
                searching: false,
                lockedConfirmEnter: false,
                lockedConfirmSwitch: false,
                lockedSaveAndFinishPo: false,
                lockedReset: false,
                lockedCorrectionNextJob: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadTrMenu = function () {
                trNewJobService.getTrMenu({ labour_no: labourNo }).then(function (info) {
                    angular.copy(info[0], vm.model.trMenu);

                    loadNewJobMenu();
                });
            };

            let loadNewJobMenu = function () {
                trNewJobService.getNewJobMenu({ labour_no: labourNo }).then(function (info) {
                    angular.copy(info[0], vm.model.newJobMenu);

                    loadExtraPowTimes();

                    if (vm.model.newJobMenu.barcode !== '') {
                        vm.sendInputCode();
                    }
                });
            };

            let loadSelectOffDutyCodes = function () {
                trNewJobConfService.listSelectOffDutyCodes().then(function (list) {
                    angular.copy(list, vm.model.selectListOffDutyCodes);
                });
            };

            let loadExtraPowTimes = function () {
                return timeRegisterService.listExtraPowTimes(vm.model.newJobMenu).then(function (list) {
                    angular.copy(list, vm.model.itemsListExtraPowTimes);
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectworkingorder':
                        trNewJobService.resetNewJob({ labour_no: vm.model.trMenu.labour_no }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedSave = false;
                                        }
                                    }]
                                });
                            } else {
                                trNewJobService.getNewJobMenu({ labour_no: labourNo }).then(function (info) {
                                    angular.copy(info[0], vm.model.newJobMenu);

                                    go({ webpagename: webPageName });
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'barcode':
                        vm.model.newJobMenu.barcode = '';
                        break;
                    case 'offdutycode_keyno':
                        vm.model.newJobMenu.offdutycode_keyno = '0';
                        vm.model.newJobMenu.offdutycode_specification = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SCAN FUNCTION

            vm.sendInputCode = function () {
                vm.model.searching = true;

                trNewJobService.scanProductionOrder({ barcode: vm.model.newJobMenu.barcode }).then(function (response) {
                    if (response[0].p2_pofinish_keyno > 0) {
                        trNewJobService.savePoFinish(response[0].p2_pofinish_keyno, $state.current.name).then(function () {
                            trNewJobService.getNewJobMenu({ labour_no: labourNo }).then(function (info) {
                                angular.copy(info[0], vm.model.newJobMenu);
                            });
                        });
                    } else {
                        trNewJobService.getNewJobMenu({ labour_no: labourNo }).then(function (info) {
                            angular.copy(info[0], vm.model.newJobMenu);
                        });
                    }

                    vm.model.searching = false;
                    reset('barcode');
                });

                vm.model.searching = false;
                reset('barcode');
            };
            
            // #endregion SCAN FUNCTION

            // #region RESET BUTTON PROCEDURE FUNCTION

            vm.newJobReset = function () {
                vm.model.lockedReset = true;

                trNewJobService.resetNewJob({ labour_no: vm.model.trMenu.labour_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedReset = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedReset = false;

                        trNewJobService.getNewJobMenu({ labour_no: labourNo }).then(function (info) {
                            angular.copy(info[0], vm.model.newJobMenu);
                        });

                        loadTrMenu();
                    }
                });
            };

            // #endregion RESET BUTTON PROCEDURE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.confirmEnterChanges = function () {
                vm.model.lockedConfirmEnter = true;

                let parms = {
                    labour_no: labourNo,
                    argtype: 'conf',
                    argvalue: '0',
                    salary_extra_01: vm.model.newJobMenu.salary_extra_01,
                    salary_extra_02: vm.model.newJobMenu.salary_extra_02,
                    salary_extra_03: vm.model.newJobMenu.salary_extra_03,
                    time_newjob: vm.model.newJobMenu.time_newjob,
                    offdutycode_keyno: vm.model.newJobMenu.offdutycode_keyno,
                    offdutycode_specification_required: vm.model.newJobMenu.offdutycode_specification_required,
                    offdutycode_specification: vm.model.newJobMenu.offdutycode_specification,
                    delayed_time_from: vm.model.newJobMenu.delayed_time_from,
                    require_offdutycode: vm.model.newJobMenu.require_offdutycode,
                    p2_pofinish_keyno: vm.model.trMenu.p2_pofinish_keyno,
                    prod_id: vm.model.trMenu.prod_id,
                    prod_name: vm.model.trMenu.prod_name,
                    oldjob_quantity_on_po: vm.model.trMenu.oldjob_quantity_on_po,
                    oldjob_quantity_finished_before: vm.model.trMenu.oldjob_quantity_finished_before,
                    oldjob_quantity_finished_now: vm.model.trMenu.oldjob_quantity_finished_now
                };

                if (vm.model.newJobMenu.custactcalc_custact_no > 0 && vm.model.newJobMenu.stamped === '1') {
                    trNewJobService.saveObj().then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedConfirmEnter = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedConfirmEnter = false;
                            stateService.back(2);
                        }
                    });
                } else {
                    trNewJobService.saveObj(parms).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedConfirmEnter = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedConfirmEnter = false;
                            stateService.back();
                        }
                    });
                }
            };

            vm.confirmSwitchChanges = function () {
                vm.model.lockedConfirmSwitch = true;

                let mergeObj = {};
                
                let parms = {
                    labour_no: labourNo,
                    argtype: 'conf',
                    argvalue: '0',
                    salary_extra_01: vm.model.newJobMenu.salary_extra_01,
                    salary_extra_02: vm.model.newJobMenu.salary_extra_02,
                    salary_extra_03: vm.model.newJobMenu.salary_extra_03,
                    time_newjob: vm.model.newJobMenu.time_newjob,
                    offdutycode_keyno: vm.model.newJobMenu.offdutycode_keyno,
                    offdutycode_specification_required: vm.model.newJobMenu.offdutycode_specification_required,
                    offdutycode_specification: vm.model.newJobMenu.offdutycode_specification,
                    delayed_time_from: vm.model.newJobMenu.delayed_time_from,
                    require_offdutycode: vm.model.newJobMenu.require_offdutycode,
                    p2_pofinish_keyno: vm.model.trMenu.p2_pofinish_keyno,
                    prod_id: vm.model.trMenu.prod_id,
                    prod_name: vm.model.trMenu.prod_name,
                    oldjob_quantity_on_po: vm.model.trMenu.oldjob_quantity_on_po,
                    oldjob_quantity_finished_before: vm.model.trMenu.oldjob_quantity_finished_before,
                    oldjob_quantity_finished_now: vm.model.trMenu.oldjob_quantity_finished_now
                };

                mergeObj = angular.copy(parms);
                mergeObj.powtimeextras = vm.model.itemsListExtraPowTimes;

                if (vm.model.newJobMenu.custactcalc_custact_no > 0 && vm.model.newJobMenu.stamped === '1') {
                    trNewJobService.saveObj(mergeObj).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedConfirmSwitch = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedConfirmSwitch = false;
                            stateService.back(2);
                        }
                    });
                } else {
                    trNewJobService.saveObj(mergeObj).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedConfirmSwitch = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedConfirmSwitch = false;
                            stateService.back();
                        }
                    });
                }
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveAndFinishPoChanges = function () {
                vm.model.lockedSaveAndFinishPo = true;

                trNewJobService.saveObj({
                    labour_no: labourNo,
                    argtype: 'conf',
                    argvalue: '0',
                    salary_extra_01: vm.model.newJobMenu.salary_extra_01,
                    salary_extra_02: vm.model.newJobMenu.salary_extra_02,
                    salary_extra_03: vm.model.newJobMenu.salary_extra_03,
                    time_newjob: vm.model.newJobMenu.time_newjob,
                    offdutycode_keyno: vm.model.newJobMenu.offdutycode_keyno,
                    offdutycode_specification_required: vm.model.newJobMenu.offdutycode_specification_required,
                    offdutycode_specification: vm.model.newJobMenu.offdutycode_specification,
                    delayed_time_from: vm.model.newJobMenu.delayed_time_from,
                    require_offdutycode: vm.model.newJobMenu.require_offdutycode,
                    p2_pofinish_keyno: vm.model.trMenu.p2_pofinish_keyno,
                    prod_id: vm.model.trMenu.prod_id,
                    prod_name: vm.model.trMenu.prod_name,
                    oldjob_quantity_on_po: vm.model.trMenu.oldjob_quantity_on_po,
                    oldjob_quantity_finished_before: vm.model.trMenu.oldjob_quantity_finished_before,
                    oldjob_quantity_finished_now: vm.model.trMenu.oldjob_quantity_finished_now
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSaveAndFinishPo = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedSaveAndFinishPo = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON CORRECTION NEXT JOB PROCEDURE FUNCTION CALL

            vm.correctionNewJobConf = function () {
                vm.model.lockedCorrectionNextJob = true;

                trNewJobService.correctionNextJob({ labour_no: vm.model.trMenu.labour_no }).then(function () {
                    vm.model.lockedCorrectionNextJob = false;
                    stateService.back('trstart');
                }, function () {
                    vm.model.lockedCorrectionNextJob = false;
                });
            };

            // #endregion BUTTON CORRECTION NEXT JOB PROCEDURE FUNCTION CALL
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgNewJob = function (value) {
                vm.model.bgNewJob.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'trnewjobconf':
                        go({
                            labour_no: labourNo,
                            argtype: 'exit',
                            argvalue: '0'
                        });
                        break;
                    case 'trstart':
                        if (vm.model.newJobMenu.custactcalc_custact_no > 0) {
                            stateService.back(2);
                        } else {
                            stateService.back();
                        }
                        break;
                    case 'poFinish':
                        state = vm.model.trMenu.pofinish_item_state;

                        go(vm.model.trMenu.pofinish_item_parms);
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTrMenu();
                //loadNewJobMenu();
                loadSelectOffDutyCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
