(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('seller', {
        templateUrl: 'views/components/views/seller/seller.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            sellerId: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'sellerService', 'logisticService', 'layoutService', function ($stateParams, stateService, modalService, translateService, sellerService, logisticService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let sellerId = vm?.sellerId ?? $stateParams.seller_id;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let translations = {
                error: '',
                ok: ''
            };

    		vm.model = {
                sellerId: sellerId,
                tabletSize: tabletSize,
                edit: {},
    			selectListBusinessCompanies: [],
    			lockedSave: false
    		};

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

    		// ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                sellerService.getSeller({ seller_id: sellerId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '0' }).then(function (list) {
                    angular.copy(list, vm.model.selectListBusinessCompanies);
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                sellerService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;

                        if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                            vm.onSave();
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
                //loadCompanies();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.sellerId?.currentValue) {
                    sellerId = changes.sellerId.currentValue;
                }

                if (sellerId) {
                    loadEdit();
                    loadCompanies();
                }
            };

            // #endregion ON CHANGES FUNCTION

    	}]
    });
})();
