(function () {
    'use strict';

    angular.module("imApp").factory("saftExportService", ['$q', '$ihttp', 'base64', function ($q, $ihttp, base64) {
        var service = {
            init: function () {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 3528,
                    parameters: {}
                }).then(function (response) {
                    response.statusMessage = base64.urldecode(response.statusMessage)

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
            refresh: function () {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 3528,
                    parameters: {
                        state: 'refresh'
                    }
                }).then(function (response) {
                    response.statusMessage = base64.urldecode(response.statusMessage)

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
            get: function () {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 3526,
                    parameters: {}
                }).then(function (response) {
                    deferred.resolve(response[0]);
                });

                return deferred.promise;
            },
            startGeneratingFile: function (parms) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 3527,
                    parameters: parms
                }).then(function (response) {
                    deferred.resolve(response[0]);
                });

                return deferred.promise;
            },
            getFilename: function (parms) {
                return $ihttp.post({
                    method: 3532,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();
