import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridStack } from 'gridstack';
import { GridstackComponent, NgGridStackOptions, NgGridStackWidget, nodesCB } from 'gridstack/dist/angular';
import { DashboardService, KpiCompBaseData } from './dashboard.service';
import { WebpagemenuDashboardWidgetData, WidgetTypes } from './dashboard-widget-base/dashboard-widget-base.component';
import { debounceTime, Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
    selector: 'tt-webpagemenu-dashboard',
    templateUrl: './webpagemenu-dashboard.component.html',
    styleUrls: ['./webpagemenu-dashboard.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class WebpagemenuDashboardComponent implements AfterViewInit, OnChanges, OnInit {
    /**
     * The name of the wbpagemenu dashboard.
     */
    @Input()
    ttName!: string;

    /**
     * Title to display for the dashboard, if any.
     */
    @Input()
    ttTitle?: string;

    /**
     * Whether the dashboard is currently editable.
     */
    @Input()
    public get ttEdit(): boolean {
        return this._editing;
    }
    public set ttEdit(value: BooleanInput) {
        this._editing = coerceBooleanProperty(value);
    }
    private _editing = false;

    /**
     * Whether the dashboard is currently editable.
     */
    @Output()
    public ttEditChange = new EventEmitter<boolean>();

    /**
     * The selected widget when the dashboard is editable.
     */
    @Input()
    public ttSelectedWidget: string = '';

    /**
     * The the current selected widget has changed.
     */
    @Output()
    public ttSelectedWidgetChange = new EventEmitter<string>();

    /**
     * The gridstack component.
     */
    @ViewChild(GridstackComponent)
    public gridComp?: GridstackComponent;

    /**
     * The list over keynos for the kpi-comps to render of on this webpagemenu dashboard.
     */
    private kpiCompKeynos: KpiCompBaseData[] = [];

    // @ts-ignore
    public widgets: WebpagemenuDashboardWidgetData[] = [];

    /**
     * Reference to the gridstack instance.
     */
    private gridRef?: GridStack;

    /**
     * Observable for which widget was last clicked.
     */
    private widgetClicked = new Subject<WidgetTypes>();

    /**
     * Initial configuration of the gridstack.
     */
    public gridOptions: NgGridStackOptions = {
        sizeToContent: false,
        cellHeightThrottle: 0,
        animate: true,
        margin: 20,
        class: 'well',
        cellHeight: '5rem',
        disableResize: true,
        float: false,
        staticGrid: true,
        columnOpts: {
            layout: 'none',
            breakpointForWindow: true,
            breakpoints: [
                { w: 700, c: 3 },
                { w: 1100, c: 6 },
            ],
        },
    };

    constructor(private elementRef: ElementRef, private dashboard: DashboardService, private utility: UtilityService) {}

    /**
     * Creates grid-stack item options for a dashboardwidget of the given kpi-comp keyno.
     *
     * @param kpiCompKeyno the kpi comp keyno of the widget.
     * @returns configuration option for a grid-stack item rendering the given kpi-comp keyno widget.
     */
    private createDashboardWidget(kpiComp: KpiCompBaseData) {
        return <NgGridStackWidget>{
            id: `${this.ttName}.${kpiComp.kpicomp_keyno}`,
            selector: 'tt-dashboard-widget-base',
            h: !!kpiComp.h ? +kpiComp.h : 3,
            w: !!kpiComp.w ? +kpiComp.w : 3,
            x: !!kpiComp.x ? +kpiComp.x : undefined,
            y: !!kpiComp.y ? +kpiComp.y : undefined,
            input: { ttDashboardWidget: { kpicomp_keyno: kpiComp.kpicomp_keyno, api: () => this.gridRef, update: () => this.widgetClicked } },
        };
    }

    /**
     * Loads the kpi comp keynos to this component.
     */
    private async loadKpiCompKeynos() {
        this.kpiCompKeynos = (await this.dashboard.getWebpageDashboardWidgetKeynos(this.ttName)) as KpiCompBaseData[];
    }

    /**
     * Loads the widgets to the gridstack. Assumes that the keynos are already loaded.
     */
    private loadWidgets() {
        if (!!this.gridComp?.grid) {
            this.gridRef?.removeAll();

            this.widgets = (this.kpiCompKeynos.map((data) => this.createDashboardWidget(data)) as WidgetTypes[]) ?? [];

            this.gridRef = this.gridComp.grid.load(this.widgets);
            this.gridRef.onResize(document.body.clientWidth);
        }
    }

    public editing = false;

    public stopEditing() {
        setTimeout(() => (this.editing = false));
    }

    async persistChange(event: nodesCB) {
        if (this.editing === true) {
            await Promise.all(
                event.nodes.map(async (node: NgGridStackWidget) => {
                    const kpiPosition = { kpicomp_keyno: node.input!['ttDashboardWidget']!.kpicomp_keyno!, webpage_name: this.ttName, h: node.h!, w: node.w!, x: node.x!, y: node.y!, layout_columns: this.gridRef?.engine.column };

                    this.dashboard.setWidgetPosition(this.ttName, { kpicomp_keyno: '' + kpiPosition.kpicomp_keyno, h: '' + kpiPosition.h, w: '' + kpiPosition.w, x: '' + kpiPosition.x, y: '' + kpiPosition.y });
                    this.dashboard.saveWidgetPosition(kpiPosition);
                })
            );
        }
    }

    /**
     * Handles when a widget for inside this webpage-dashboard has been clicked.
     *
     * @param widget the widget which was clicked.
     */
    private onWidgetClicked(widget: WidgetTypes) {
        if (this.ttEdit) {
            this.ttSelectedWidget = '' + widget.kpicomp_keyno!;
            this.ttSelectedWidgetChange.emit(this.ttSelectedWidget);
        }
    }

    public onEditChange(event: BooleanInput) {
        this.ttEdit = event;

        if (this.ttEdit) {
            this.gridRef?.setStatic(false);
            this.gridRef?.enableResize(true);
            this.gridRef?.enableMove(true);
        } else {
            this.gridRef?.setStatic(true);
            this.gridRef?.enableResize(false);
            this.gridRef?.enableMove(false);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttEdit']) {
            this.onEditChange(changes['ttEdit'].currentValue);
            // this.ttEdit = changes['ttEdit'].currentValue;

            // if (this.ttEdit) {
            //     this.gridRef?.setStatic(false);
            //     this.gridRef?.enableResize(true);
            //     this.gridRef?.enableMove(true);
            // } else {
            //     this.gridRef?.setStatic(true);
            //     this.gridRef?.enableResize(false);
            //     this.gridRef?.enableMove(false);
            // }
        }
    }

    async ngOnInit(): Promise<void> {
        this.widgetClicked.pipe(debounceTime(250)).subscribe({ next: (widget) => this.onWidgetClicked(widget) });
        await this.loadKpiCompKeynos();
        this.loadWidgets();
    }

    async ngAfterViewInit(): Promise<void> {
        // this.widgetClicked.pipe(debounceTime(250)).subscribe({ next: (widget) => this.onWidgetClicked(widget) });
        // await this.loadKpiCompKeynos();
        // this.loadWidgets();
    }
}
