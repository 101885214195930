(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('checkInMenu', {
        templateUrl: 'views/components/views/checkInMenu/checkInMenu.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'checkInMenuService', function ($stateParams, checkInMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                argType: argType,
                argValue: argValue,
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await checkInMenuService.getMenu({
                    argtype: argType,
                    argvalue: argValue
                }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region CHECK IN BUTTON FUNCTIONS

            vm.checkIn = function () {
                checkedIn('I');
            };

            vm.checkOut = function () {
                checkedIn('O');
            };

            vm.checkedInGuard = function () {
                checkedIn('G');
            };

            vm.checkedOutGuard = function () {
                checkedIn('F');
            };

            // #endregion CHECK IN BUTTON FUNCTIONS

            // #region CHECK IN MULTI PROCEDURE FUNCTION CALLS

            function checkedIn(status) {
                switch (status) {
                    case 'I':
                    case 'O':
                    case 'G':
                    case 'F':
                        navigator.geolocation.getCurrentPosition(function (data) {
                            checkInMenuService.checkInOutPortalUser({
                                inout: status,
                                argtype: argType,
                                argvalue: argValue,
                                latitude: data.coords.latitude,
                                longitude: data.coords.longitude,
                                accurancy: data.coords.accuracy
                            }).then(function () {
                                loadMenu();
                            });
                        });
                        break;
                    default:
                        break;
                }
            }

            // #endregion CHECK IN MULTI PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
