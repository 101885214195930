(function () {
    'use strict';

    angular.module("imApp").factory("productLinkService", ['$ihttp', function ($ihttp) {
        let service = {
            getProductLink: function (parms) {
                return $ihttp.post({
                    method: 1853,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1854,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
