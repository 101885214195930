import { ApplicationRef, ComponentRef, createComponent, EnvironmentInjector, Injectable } from '@angular/core';
import { ToastComponent, ToastType } from '../components/toast/toast.component';
import { ttOverflowMenuOptions } from '../components/button-multi/button-multi.component';
import { RememberService } from './remember.service';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastContainer?: HTMLDivElement;

    private toasts: ComponentRef<ToastComponent>[] = [];

    private transitionDelay = 2500;

    private lastStatusMinimizePromise?: Promise<any>;

    constructor(private appRef: ApplicationRef, private injector: EnvironmentInjector, private remember: RememberService) {
        this.lastStatusMinimizePromise = this.getLastMinimizeState();
    }

    /**
     * Adds a toast to upper right of the screen.
     *
     * @param params the data to configure the toast to be displayed with.
     * @returns the newly created and displayed toast.
     */
    public async addToast(params: ToastData) {
        if (!!this.toasts.some((toast) => toast.instance.ttId === params.id)) return;
        const container = await this.getToastContainer();

        const toast = this.createToastComponent(params);
        container.appendChild(toast.location.nativeElement);
        this.appRef.attachView(toast.hostView);

        setTimeout(() => container.scrollTo({ top: -1000000000, behavior: 'smooth' }), 300);

        this.toasts.push(toast);

        return toast;
    }

    /**
     * Returns all toasts still available in the app context.
     *
     * @returns all toasts still available in the app context.
     */
    public getAllToasts(): ComponentRef<ToastComponent>[] {
        return this.toasts;
    }

    /**
     * Animates hiding of the toasts and fires onClose/ttClose for all toasts.
     */
    public closeAllToasts() {
        this.toasts.forEach((t) => t.instance.ttClose.next(t.instance));
    }

    /**
     * Animates the hiding of the given toast and deletes it from the app context once the animation is completed.
     *
     * @param toast the toast to animate hide for.
     * @param transitionDelay the transition delay for the animation, by default uses standard set in service.
     * @param playState forces the playstate for the animation.
     */
    public animateHide(toast: ComponentRef<ToastComponent>, transitionDelay?: number, playState?: 'running' | 'paused') {
        if (!!playState) {
            toast.location.nativeElement.style.animationPlayState = playState;
        }
        //console.log('toast.location.nativeElement.style.animationPlayState :>> ', toast.location.nativeElement.style.animationPlayState);
        toast.location.nativeElement.style.animation = `${transitionDelay ?? this.transitionDelay}ms ease-in 1 forwards ${toast.location.nativeElement.style.animationPlayState ?? 'running'} toast_fade_out`;
        (toast.location.nativeElement as HTMLElement).onanimationend = () => setTimeout(() => this.deleteToast(toast));
    }

    /**
     * Removes the toast component from the dom, app and toasts stack. Does not animate the removal (`animateHide` animate the fading out of the toast and deletes it.)
     *
     * @param toast the toast to remove from the app context.
     */
    public async deleteToast(toast: ComponentRef<ToastComponent>) {
        (await this.getToastContainer()).removeChild(toast.location.nativeElement);
        this.appRef.detachView(toast.hostView);
        toast.destroy();
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    /**
     * Deletes all toasts from the app context.
     */
    public deleteAllToasts() {
        this.toasts.forEach((toast) => this.deleteToast(toast));
    }

    /**
     * Creates and configures a toast according to the given toast data.
     *
     * @param params the toast data to configure the toast with.
     * @returns the newly create toast.
     */
    private createToastComponent(params: ToastData): ComponentRef<ToastComponent> {
        const toast = createComponent(ToastComponent, { environmentInjector: this.injector });
        const toastHostElement: HTMLElement = toast.location.nativeElement;
        let closing = false;
        let closingAll = false;

        params.overflow = {
            items: [
                {
                    text: 'expand',
                    icon: 'fal fa-th-list',
                    click: () => this.setMinimizeState(false),
                },
                {
                    text: 'minimize',
                    icon: 'fal fa-layer-group',
                    click: () => this.setMinimizeState(true),
                },
                {
                    text: 'close_all',
                    icon: 'fal fa-times',
                    hasDivider: true,
                    click: () => {
                        closingAll = true;
                        this.closeAllToasts();
                    },
                },
            ],
        };

        toast.setInput('ttId', params.id);
        toast.setInput('ttTitle', params.title);
        toast.setInput('ttTranslateTitle', params.translateTitle ?? true);
        toast.setInput('ttMessage', params.message);
        toast.setInput('ttTranslateMessage', params.translateMessage ?? true);
        toast.setInput('ttType', params.type ?? 'primary');
        toast.setInput('ttGoto', params.state);
        toast.setInput('ttGotoParms', params.parms);
        toast.setInput('ttOverflow', params.overflow);

        if (!!params.onClick && params.onClick instanceof Function) {
            const onClick = params.onClick;
            toast.instance.ttClick.subscribe({ next: ($event: { event: MouseEvent; toast: ToastComponent }) => params.onClick?.({ event: $event.event, toast: toast }) });
        }

        toast.instance.ttClose.subscribe({
            next: () => {
                closing = true;
                params.onClose?.();
                this.animateHide(toast, 500, 'running');
            },
        });

        toastHostElement.style.animation = `1500ms ease-in 1 forwards toast_fade_in`;
        // toastHostElement.style.order = params.order ?? `-${this.toasts.length}`;
        toastHostElement.onanimationend = () => {
            toastHostElement.style.animation = ``;

            toast.instance.ttMouseEnter.subscribe({ next: () => closingAll === false && (toastHostElement.style.animationPlayState = 'paused') });
            toast.instance.ttMouseLeave.subscribe({ next: () => closingAll === false && (toastHostElement.style.animationPlayState = 'running') });

            if (params.hideDelay !== null) {
                setTimeout(() => {
                    if (!closing) this.animateHide(toast);
                }, params.hideDelay ?? 7000);
            }
        };

        return toast;
    }

    /**
     * Creates a toast container if it doesnt exist and returns the toast container.
     *
     * @returns the toast container holding all toasts.
     */
    private async getToastContainer(): Promise<HTMLDivElement> {
        if (!this.minimized) {
            await this.lastStatusMinimizePromise;
        }

        if (!this.toastContainer) {
            this.toastContainer = document.createElement('div');
            this.toastContainer.classList.add('tt-scrollbar', 'tt-toast-container');

            if (this.minimized === '1') this.toastContainer.classList.add('tt-toast-container--minimize');

            document.body.appendChild(this.toastContainer);
        }

        return this.toastContainer;
    }

    minimized?: '1' | '0';

    private async getLastMinimizeState() {
        this.minimized = (await this.remember.getLastStatus('toastservice.minimized'))?.[0]?.variablevalue;
    }

    private async setMinimizeState(minimized: boolean) {
        if (minimized) {
            this.toastContainer?.classList.add('tt-toast-container--minimize');
            this.remember.remember('toastservice.minimized', '1');
        } else {
            this.toastContainer?.classList.remove('tt-toast-container--minimize');
            this.remember.remember('toastservice.minimized', '0');
            this.toastContainer?.scrollTo({ top: -100000000000 });
        }
    }
}

export interface ToastData {
    /**
     * Unique id for the toast. If a toast with this id already exists in service, a new one will not be added.
     */
    id: string;

    /**
     * The title to display for the toast.
     */
    title: string;

    /**
     * Whether the title of the toast should be translated.
     *
     * @default true
     */
    translateTitle?: boolean;

    /**
     * The message context for the toast.
     */
    message?: string;

    /**
     * Whether the message of the toast should be translated.
     *
     * @default true
     */
    translateMessage?: boolean;

    /**
     * The type of the toast to be displayed, by default `'primary'` which signify info.
     */
    type?: ToastType;

    /**
     * The state to link to if any.
     */
    state?: string;

    /**
     * The params of the state to link to, if any.
     */
    parms?: string | Record<string, string | number | boolean> | null;

    /**
     * Event to fire when the toast is clicked, if any.
     */
    onClick?: ((params: { event: MouseEvent; toast: ComponentRef<ToastComponent> }) => unknown) | ((params: { event: MouseEvent; toast: ComponentRef<ToastComponent> }) => Promise<unknown>);

    onClose?: (() => void) | (() => Promise<void>);

    /**
     * The ms before the toast becomes hidden. If the toast should not be automatically hidden, set to null.
     *
     * @default 7000
     */
    hideDelay?: number | null;

    /**
     * The order to position the toast in the toast list.
     */
    order?: string;

    /**
     * Configuration of overflow menu.
     */
    overflow?: ttOverflowMenuOptions;
}
