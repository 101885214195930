(function () {
    'use strict';

    // ReSharper disable once UndeclaredGlobalVariableUsing
    const module = angular.module('imApp');

    module.component('p2UpdateScripts', {
        templateUrl: 'views/components/views/p2UpdateScripts/p2UpdateScripts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'stateService', 'p2UpdateScriptsService', 'rememberService', 'layoutService', function ($q, utilityService, stateService, p2UpdateScriptsService, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;
    		
            let variableNames = {
                runbatch: '',
                filtervalue: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2UpdateScriptKeyno: '',
                runBatchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('runbatch') }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
                setting: {},
                selectListRunBatches: [],
                itemsListUpdateScripts: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                p2UpdateScriptsService.getUpdatScriptSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadUpdateScripts()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadRunBatches = function () {
                p2UpdateScriptsService.listRunBatches({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListRunBatches);
                });
            };
            
            let loadUpdateScripts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.runbatch, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                vm.model.itemsListUpdateScripts = [];

                return p2UpdateScriptsService.listUpdateScripts(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListUpdateScripts);

                    angular.forEach(vm.model.itemsListUpdateScripts, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'runbatch':
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'runbatch':
                        vm.model.setting.runbatch = 'all';

                        if (vm.model.setting.runbatch === 'all') {
                            rememberFunc('runbatch');
                            loadUpdateScripts();
                        }
                        break;
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateScripts = function () {
                vm.model.p2UpdateScriptKeyno = '';
                loadUpdateScripts();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openScript = function (item) {
                vm.model.p2UpdateScriptKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2updatescriptedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2updatescriptedit') {
                            vm.model.p2UpdateScriptKeyno = '0';
                        } else {
                            go({ p2_updatescript_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'runbatch':
                        if (utilityService.validateWatchValue(value, vm.model.setting.runbatch) !== true) return;

                        vm.model.setting.runbatch = value;

                        if (utilityService.validateParmsValue(vm.model.setting.runbatch, true) !== true) return;

                        rememberFunc('runbatch');
                        loadUpdateScripts();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion FIELD ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadRunBatches();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
