(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2Table', {
        templateUrl: 'views/components/views/p2Table/p2Table.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2TableService', 'layoutService', function (stateService, p2TableService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2TableKeyno: '',
                itemsListTables: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadTables = function () {
                p2TableService.listTables().then(function (list) {
                    angular.copy(list, vm.model.itemsListTables);

                    angular.forEach(vm.model.itemsListTables, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadTables();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2TableKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2tableedit':
                        if (tabletSize.matches === true && vm.model.itemState === 'p2tableedit') {
                            vm.model.p2TableKeyno = '0';
                        } else {
                            go({ p2_table_keyno: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTables();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
