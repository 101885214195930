import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateEditDialogComponent, TranslateEditDialogData } from '@app/modal/translate-edit-dialog/translate-edit-dialog.component';

@Component({
    selector: 'tt-deviation-source',
    templateUrl: './deviation-source.component.html',
    styleUrls: ['./deviation-source.component.css'],
})
export class DeviationSourceComponent implements OnChanges {
    @Input()
    public deviationSourceKeyno: string = '';

    public deviationSource?: DeviationSource;

    public deviationTypes: Item[] = [];

    public deviationSources2: Item[] = [];

    public loading = false;

    public saving = false;

    public deleting = false;

    selectedtab = '';

    nameButtons: FormButton[] = [
        {
            id: 'translate',
            icon: 'far fa-language',
            type: 'primary',
            onClick: () => this.openTranslationDialog(),
        },
    ];

    @Output()
    public onSave = new EventEmitter<DeviationSource>();

    @Output()
    public onDelete = new EventEmitter<DeviationSource>();

    constructor(private datatask: DataTaskService, private dialog: MatDialog) {}

    public async openTranslationDialog() {
        if (!!this.deviationSource) {
            const edits = await this.datatask.Post(3552, { deviationsource_keyno: this.deviationSource.deviationsource_keyno });
            const dialogRef = this.dialog.open(TranslateEditDialogComponent, {
                width: '120rem',
                maxWidth: '95vw',
                height: '80vh',
                data: <TranslateEditDialogData<DeviationSourceLangEdit>>{
                    heading: `(${this.deviationSource.deviationsource_keyno}) ${this.deviationSource.deviationsource_name}`,
                    textToTranslate: this.deviationSource.deviationsource_name,
                    edits: edits,
                    save: async (data) => {
                        await this.datatask.Post(3553, { edits: data.edits });
                        await this.loadDeviationSource();
                        dialogRef.close();
                        this.onSave.emit(this.deviationSource);
                    },
                    wordNameKey: 'deviationsource_name',
                    wordIdKey: 'deviationsource_keyno',
                },
            });
        }
    }

    public async save() {
        if (!!this.deviationSource) {
            try {
                this.saving = true;

                const response = await this.datatask.Post(3541, this.deviationSource);

                if (!response || response.errorcode === '0') {
                    this.onSave.emit(this.deviationSource);
                }
            } finally {
                this.saving = false;
            }
        }
    }

    public async delete() {
        if (this.deviationSourceKeyno > '0' && !!this.deviationSource) {
            try {
                this.deleting = true;

                await this.datatask.Post(3543, this.deviationSource);

                this.onDelete.emit(this.deviationSource);
            } finally {
                this.deleting = false;
            }
        }
    }

    public onDeviationTypeRelationChanged(item: { [key: string]: any }) {
        item['is_active'] = item['is_selected'] === true ? '1' : '0';

        this.updateDeviationTypeRelation({ deviationsource_keyno: this.deviationSourceKeyno, deviationtype_keyno: item['deviationtype_keyno'], is_active: item['is_active'] });
    }

    public onAllDeviationTypesChecked(event: boolean) {
        const itemsToUpdate = this.deviationTypes.filter((item) => item['is_active'] !== (event === true ? '1' : '0'));

        itemsToUpdate.map((item) => this.onDeviationTypeRelationChanged(item));
    }

    public onDeviationSource2RelationChanged(item: { [key: string]: any }) {
        item['is_active'] = item['is_selected'] === true ? '1' : '0';

        this.updateDeviationSource2Relation({ deviationsource_keyno: this.deviationSourceKeyno, deviationsource2_keyno: item['item_id'], is_active: item['is_selected'] === true ? '1' : '0' });
    }

    public onAllDeviationSource2Checked(event: boolean) {
        const itemsToUpdate = this.deviationSources2.filter((item) => item['is_active'] !== (event === true ? '1' : '0'));

        itemsToUpdate.map((item) => this.onDeviationSource2RelationChanged(item));
    }

    private async loadDeviationSource() {
        if (!!this.deviationSourceKeyno) {
            this.deviationSource = (await this.datatask.Post(3536, { deviationsource_keyno: this.deviationSourceKeyno ?? '0' }))[0];
        }
    }

    private async loadDeviationTypes() {
        if (this.deviationSourceKeyno > '0') {
            this.deviationTypes = (await this.datatask.Post(3546, { deviationsource_keyno: this.deviationSourceKeyno })).map((item: Item) => ({ ...item, is_selected: item['is_active'] === '1' ? true : false }));
        }
    }

    private async updateDeviationTypeRelation(params: { deviationsource_keyno: string; deviationtype_keyno: string; is_active: '0' | '1' }) {
        return this.datatask.Post(3539, params);
    }

    private async loadDeviationSources2() {
        if (this.deviationSourceKeyno > '0') {
            this.deviationSources2 = (await this.datatask.Post(3538, { deviationsource_keyno: this.deviationSourceKeyno })).map((item: Item) => ({ ...item, is_selected: item!['is_active'] === '1' ? true : false }));
        }
    }

    private async updateDeviationSource2Relation(params: { deviationsource_keyno: string; deviationsource2_keyno: string; is_active: '0' | '1' }) {
        return this.datatask.Post(3540, params);
    }

    private async loadData() {
        try {
            this.loading = true;
            await Promise.all([this.loadDeviationSource(), this.loadDeviationTypes(), this.loadDeviationSources2()]);
        } finally {
            this.loading = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['deviationSourceKeyno']?.currentValue && changes['deviationSourceKeyno'].currentValue !== changes['deviationSourceKeyno'].previousValue) {
            this.loadData();
        }
    }
}

interface DeviationSource {
    deviationsource_keyno: string;
    deviationsource_name: string;
}

interface DeviationSourceLangEdit extends Record<string, string> {
    deviationsource_keyno: string;
    deviationsource_name: string;
    language_id: string;
    language_name: string;
    orderby: string;
    google_language_id: string;
}
