import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ListboxPopupDirective, ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';
import { SearchItem } from '../search/search.component';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
    selector: 'tt-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.css'],
})
export class TimeInputComponent implements OnInit, OnChanges {
    @Input()
    public ttId = crypto.randomUUID();

    /**
     * The id of the element to position the listbox relative to.
     */
    @Input()
    public ttRelativeElement: string = '';

    /**
     * Whether the date-input field can be tabbed to, default is true.
     */
    @Input()
    get ttTabable(): boolean {
        return this._tabable;
    }
    set ttTabable(value: BooleanInput) {
        this._tabable = coerceBooleanProperty(value);
    }
    private _tabable: boolean = true;

    /**
     * Whether seconds should be shown in time input.
     *
     * @default false
     */
    @Input()
    public set ttShowSeconds(value: BooleanInput) {
        this._showSeconds = coerceBooleanProperty(value);
    }
    public get ttShowSeconds(): boolean {
        return this._showSeconds;
    }
    private _showSeconds = false;

    /**
     * Whether to allow a time to be empty (empty string).
     */
    @Input()
    set ttAllowEmpty(value: BooleanInput) {
        this._allowEmpty = coerceBooleanProperty(value);
    }
    get ttAllowEmpty(): boolean {
        return this._allowEmpty;
    }
    _allowEmpty = true;

    /**
     * Whether the date-input is a readonly. Will be uneditable but readable.
     */
    @Input()
    get ttReadonly(): boolean {
        return this._readonly;
    }
    set ttReadonly(value: BooleanInput) {
        this._readonly = coerceBooleanProperty(value);
    }
    private _readonly: boolean = false;

    /**
     * Whether the date-input is disabled. Will be uneditable and less readable.
     */
    @Input()
    get ttDisabled(): boolean {
        return this._disabled;
    }
    set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled: boolean = false;

    /**
     * The date represented in the inputfield.
     */
    @Input()
    ttModel: string = '08:00';
    model = '08:00';

    /**
     * Event emitted when the date is changed from the input field.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * The minute precision of the time input, default is `5`.
     */
    @Input()
    set ttMinutePrecision(value: NumberInput) {
        this._minutePrecision = coerceNumberProperty(value);
    }
    get ttMinutePrecision(): number {
        return this._minutePrecision;
    }
    _minutePrecision = 5;

    /**
     * Whether the current device is a mobile device.
     */
    isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    /**
     * Whether to round the time the user inputed. If true the time is rounded to closest minute precision, if `'up'` the time
     * is rounded to the next minute precision. If `'down'` the time is always rounded down the previous minute-precision.
     */
    @Input()
    set ttRoundTime(value: BooleanInput | 'up' | 'down') {
        if (value === 'up') {
            this._roundTime = 'up';
        } else if (value === 'down') {
            this._roundTime = 'down';
        } else {
            this._roundTime = coerceBooleanProperty(value);
        }
    }
    get ttRoundTime(): boolean | 'up' | 'down' {
        return this._roundTime;
    }
    _roundTime: boolean | 'up' | 'down' = true;

    /**
     * Reference to the input field responsible for the time input.
     */
    @ViewChild('timeRef')
    public timeRef?: ElementRef;

    /**
     * Reference to the listbox directive.
     */
    @ViewChild('timeListbox')
    public timeListbox?: ListboxPopupDirective;

    /**
     * List of time options for the user to select.
     */
    timeOptions: SearchItem[] = [];

    /**
     * Property to control whether listbox for time options should show or not.
     */
    showTimes = false;

    constructor(private utility: UtilityService, private elementRef: ElementRef) {
        this.keyStack.push = (...item: string[]) => {
            if (this.keyStack.length >= 5) {
                this.keyStack.pop();
            }

            return this.keyStack.unshift(...item);
        };
    }

    public search = async () => {
        // return this.timeOptions.filter((item) => {
        //     console.log('item["item_id"].replaceAll(":", "") :>> ', item['item_id'].replaceAll(':', ''));
        //     console.log('this.model.replaceAll(":", "") :>> ', this.model.replaceAll(':', ''));
        //     return item['item_id'].replaceAll(':', '').startsWith(this.model.replaceAll(':', ''));
        // });

        return this.timeOptions;
        // return this.timeOptions.filter((item) => (!!this.model ? this.retrieveTimeWithSeconds(item['item_id']).hour === this.retrieveTimeWithSeconds(this.model).hour : true));
    };

    /**
     * Opens the listbox with time intervals and focuses the time input.
     */
    openTimeListbox() {
        console.log('this :>> ', this);
        console.log(this.showTimes);
        // if (!this.showTimes) {
        this.timeRef?.nativeElement.focus();
        this.timeRef?.nativeElement.select();
        // }
        this.showTimes = !this.showTimes;
    }

    onModelChange() {
        if (this.ttDisabled || this.ttReadonly) return;

        this.ttModel = this.model;
        this.ttModelChange.emit(this.ttModel);
    }

    modelChanged = false;

    /**
     * Parses input changes from time input.
     *
     * @param event value from the change event.
     */
    onTimeChanged(event: string) {
        if (this.ttShowSeconds) {
            this.model = event.replace(/[^0-9:]/g, '').substring(0, 8);
        } else {
            this.model = event.replace(/[^0-9:]/g, '').substring(0, 5);
        }

        this.modelChanged = true;
    }

    private keyStack: string[] = [];

    onTimeInputKeydown(event: KeyboardEvent) {
        this.keyStack.push(event.key);
        // if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
        //     this.modelChanged = false;
        //     this.deferOptionSelectChange = false;
        // }

        // if (event.key === 'Escape') this.showTimes = false;

        if (event.key === 'Enter') {
            if (this.modelChanged) {
                this.onTimeInputBlur();
            }
        }
    }

    /**
     * Handles time changes.
     *
     * @param option the time option selected.
     */
    onTimeOptionSelect(event: ListboxSelectEvent<SearchItem>) {
        console.log('event :>> ', event);
        console.log('this.showTimes :>> ', this.showTimes);
        if (!!this.showTimes) {
            if (this.deferOptionSelectChange) {
                this.deferOptionSelectChange = false;
            } else if (this.keyStack[1] !== 'Enter') {
                // select as long as the two latest key events are not enter.
                if (typeof event.item !== 'string' && this.isValidTimeFormat(event.item['item_id'])) {
                    this.model = event.item['item_id'];
                } else {
                    this.model = this.ttModel.split(' ')[1] ?? (this.ttModel || '08:00');
                }

                this.onModelChange();
                this.showTimes = false;
            }
        }
    }

    deferOptionSelectChange = false;

    /**
     * Parses the inputed value to a valid time format when the input field is blurred.
     */
    onTimeInputBlur(ev?: Event) {
        console.log('ev :>> ', ev);
        try {
            if (this.model.trim() === '' && !this.ttAllowEmpty) {
                this.model = this.ttModel ?? '08:00';
                this.ttModel = this.model;
                this.modelChanged = false;
                this.onModelChange();
                this.deferOptionSelectChange = true;
            }

            if (this.model === this.ttModel) return;

            if (this.isValidTimeFormat(this.model)) {
                // let hour = 8;
                // let minute = 0;
                // let seconds = 0;

                // if (this.model.includes(':')) {
                //     hour = Number(this.model.split(':')[0]);
                //     minute = Number(this.model.split(':')[1]);
                // } else if (this.model.length > 0 && this.model.length < 3) {
                //     hour = Number(this.model);
                // } else if (this.model.length === 3) {
                //     hour = Number(this.model.substring(0, 1));
                //     minute = Number(this.model.substring(1, 3));
                // } else if (this.model.length === 4) {
                //     hour = Number(this.model.substring(0, 2));
                //     minute = Number(this.model.substring(2));
                // }

                // this.setHourAndMinuteToTime(hour, minute);
                // if (this.ttShowSeconds) {
                const time = this.retrieveTimeWithSeconds(this.model);
                this.setHourAndMinuteToTime(time.hour, time.minute, time.seconds);

                // } else {
                // this.retrieveTime();
                // }
            } else {
                this.model = this.ttModel;

                if (this.model.trim() === '' && !this.ttAllowEmpty) {
                    this.model = '08:00';
                }
            }

            this.ttModel = this.model;
            this.modelChanged = false;
            this.onModelChange();
            this.deferOptionSelectChange = true;
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    private retrieveTime() {
        let hour = 8;
        let minute = 0;

        if (this.model.includes(':')) {
            hour = Number(this.model.split(':')[0]);
            minute = Number(this.model.split(':')[1]);
        } else if (this.model.length > 0 && this.model.length < 3) {
            hour = Number(this.model);
        } else if (this.model.length === 3) {
            hour = Number(this.model.substring(0, 1));
            minute = Number(this.model.substring(1, 3));
        } else if (this.model.length === 4) {
            hour = Number(this.model.substring(0, 2));
            minute = Number(this.model.substring(2));
        }

        this.setHourAndMinuteToTime(hour, minute);
    }

    private retrieveTimeWithSeconds(model: string) {
        let hour = 8;
        let minute = 0;
        let seconds = 0;

        if (model.includes(':')) {
            hour = Number(model.split(':')[0]);
            minute = Number(model.split(':')[1]);
            seconds = Number(model.split(':')[2] ?? '0');
        } else if (model.length > 0 && model.length < 3) {
            hour = Number(model);
        } else if (model.length === 3) {
            hour = Number(model.substring(0, 1));
            minute = Number(model.substring(1, 3));
            seconds = 0;
        } else if (model.length === 4) {
            hour = Number(model.substring(0, 2));
            minute = Number(model.substring(2));
            seconds = 0;
        } else if (model.length === 5) {
            hour = Number(model.substring(0, 1));
            minute = Number(model.substring(1, 3));
            seconds = Number(model.substring(3, 5));
        } else if (model.length === 6) {
            hour = Number(model.substring(0, 2));
            minute = Number(model.substring(2, 4));
            seconds = Number(model.substring(4, 6));
        }
        return { hour: hour, minute: minute, seconds: seconds };
        // this.setHourAndMinuteToTime(hour, minute, seconds);
    }

    /**
     * Takes the raw values of hour and minute and changes it according to minute precision and rounding.
     *
     * @param hour the raw hour value the user inputted.
     * @param minute the raw minute value the user inputted.
     */
    private setHourAndMinuteToTime(hour: number, minute: number, seconds?: number): void {
        const remainder = minute % this.ttMinutePrecision;

        if (this.ttRoundTime === true) {
            if (remainder !== 0) {
                const roundingUp = this.ttMinutePrecision - remainder;
                const roundingDown = -remainder;

                if (remainder >= this.ttMinutePrecision / 2) {
                    minute += roundingUp;
                } else {
                    minute += roundingDown;
                }
            }
        } else if (this.ttRoundTime === 'up') {
            if (remainder !== 0) {
                minute += this.ttMinutePrecision - remainder;
            }
        } else if (this.ttRoundTime === 'down') {
            minute -= remainder;
        }

        if (minute >= 60) {
            hour += 1;
            minute -= 60;
        } else if (minute < 0) {
            hour -= 1;
            minute += 60;
        }

        if (hour >= 24) {
            hour -= 24;
        } else if (hour < 0) {
            hour += 24;
        }

        if (this.ttShowSeconds) {
            this.model = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${(seconds ?? 0) < 10 ? '0' + (seconds ?? 0) : seconds ?? 0}`;
        } else {
            this.model = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
        }
    }

    /**
     * Checks whether the given value is of a valid time format.
     *
     * @param value the value to check if is a valid timeformat.
     * @returns `true` if the given value is a valid time format, `false` if not.
     */
    private isValidTimeFormat(value: string): boolean {
        if (value.includes(':') && value.length > 2 && this.isValidHourValue(value.split(':')[0]) && this.isValidMinuteValue(value.split(':')[1])) {
            return true;
        } else if (value.length > 0 && value.length < 3 && this.isValidHourValue(value)) {
            return true;
        } else if (value.length === 3 && this.isValidHourValue(value.substring(0, 1)) && this.isValidMinuteValue(value.substring(1))) {
            return true;
        } else if (value.length === 4 && this.isValidHourValue(value.substring(0, 2)) && this.isValidMinuteValue(value.substring(3))) {
            return true;
        }

        if (value.includes(':') && value.length > 2 && this.isValidHourValue(value.split(':')[0]) && this.isValidMinuteValue(value.split(':')[1]) && this.isValidMinuteValue(value.split(':')[2])) {
            return true;
        } else if (value.length === 5 && this.isValidHourValue(value.substring(0, 1)) && this.isValidMinuteValue(value.substring(1, 3)) && this.isValidMinuteValue(value.substring(3, 5))) {
            return true;
        } else if (value.length === 6 && this.isValidHourValue(value.substring(0, 2)) && this.isValidMinuteValue(value.substring(2, 4)) && this.isValidMinuteValue(value.substring(4, 6))) {
            return true;
        } else if (value.includes(':') && value.length > 2 && this.isValidHourValue(value.split(':')[0]) && this.isValidMinuteValue(value.split(':')[1])) {
            return true;
        }

        return false;
    }

    /**
     * Checks wheter the given hour is of a valid hour value.
     *
     * @param hour the hour value to check if is a valid hour value.
     * @returns `true` if the given value is a valid hour value, `false` if not.
     */
    private isValidHourValue(hour: string | number): boolean {
        let hourValue = Number(hour);

        if (!isNaN(hourValue) && hourValue >= 0 && hourValue < 24) {
            return true;
        }
        return false;
    }

    /**
     * Checks whether the given minute if of a valid minute value.
     *
     * @param minute the minute value to check if is a valid minute value.
     * @returns `true` if the given value is a valid minute value, `false` if not.
     */
    private isValidMinuteValue(minute: string | number): boolean {
        let minuteValue = Number(minute);

        if (!isNaN(minuteValue) && minuteValue >= 0 && minuteValue < 60) {
            return true;
        }
        return false;
    }

    /**
     * Creates and sets the `timeOptions` list with times spaced out by the given minute interval.
     */
    private createTimeOptions(minutePrecision: number = 15) {
        this.timeOptions = [];

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += minutePrecision) {
                this.timeOptions.push({
                    item_name: this.getTimeItemName(hour, minute),
                    item_id: this.getTimeItemName(hour, minute),
                });
                // item_name: `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`,
                // item_id: `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`,
            }
        }
    }

    private getTimeItemName(hour: number, minute: number) {
        if (this.ttShowSeconds) {
            return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:00`;
        }
        return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    ngOnInit(): void {
        this.createTimeOptions(this.ttMinutePrecision);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes :>> ', changes);
        if (changes['ttMinutePrecision'] || changes?.['ttShowSeconds']) {
            this.ttMinutePrecision = changes['ttMinutePrecision'].currentValue;
            this.createTimeOptions(this.ttMinutePrecision);
        }

        if (changes['ttRoundTime']) {
            this.ttRoundTime = changes['ttRoundTime'].currentValue;
        }

        if (changes?.['ttTabable']) {
            this.ttTabable = changes['ttTabable'].currentValue;
        }

        if (changes?.['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }

        if (changes?.['ttReadonly']) {
            this.ttReadonly = changes['ttReadonly'].currentValue;
        }

        if (changes?.['ttAllowEmpty']) {
            this.ttAllowEmpty = changes['ttAllowEmpty'].currentValue;
        }

        if (changes?.['ttShowSeconds']) {
            this.ttShowSeconds = changes['ttShowSeconds'].currentValue;
        }

        if (changes['ttModel'] && typeof changes['ttModel']?.currentValue === 'string') {
            const newModel = changes['ttModel'].currentValue;
            if (new Date(newModel).toString() !== 'Invalid Date') {
                if (!!this.ttShowSeconds) {
                    this.model = newModel.split(' ')[1].substring(0, 8);
                } else {
                    this.model = newModel.split(' ')[1].substring(0, 5);
                }
            } else {
                this.model = this.ttShowSeconds && newModel.length === 5 ? newModel + ':00' : newModel;
            }

            this.ttModel = this.model;
        }
    }
}
