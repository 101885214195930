<figure class="tt-input__container col-xs-12 sp-0">
    <div class="tt-input__base col-xs-12 sp-0" [class]="class.base">
        <figcaption class="tt-input__label">{{ label }}</figcaption>
        <div class="tt-input__input">
            <div *ngIf="!imageLoaded && !ready" class="tt-skeleton tt-skeleton--image"></div>
            <div [hidden]="!imageLoaded || !ready" tabindex="0" (click)="ttClick.emit($event)" class="tt-image__img-container">
                <img [src]="ttSrc" [alt]="ttLabel" (error)="onError($event)" (load)="onLoad($event)" />
            </div>
            <button *ngIf="!imageLoaded && ready && ttPlaceholderClick.observed" (click)="ttPlaceholderClick.emit($event)" class="tt-image__placeholder">
                <ng-container *ngTemplateOutlet="placeholder"></ng-container>
            </button>
            <div *ngIf="!imageLoaded && ready && !ttPlaceholderClick.observed" class="tt-image__placeholder">
                <ng-container *ngTemplateOutlet="placeholder"></ng-container>
            </div>
        </div>
    </div>
</figure>

<ng-template #placeholder>
    <span class="tt-image__placeholder-icon fad fa-camera-alt"></span>
    <span>No image...</span>
</ng-template>
