// BJS 20250306 - Migrated using chatGPT o1.
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { LayoutService, ILayoutChangedEventArgs } from '../../services/layout.service';

@Component({
    selector: 'tt-numpad-legacy',
    templateUrl: './tt-numpad-legacy.component.html',
    styleUrls: ['./tt-numpad-legacy.component.css']
})
export class TtNumpadLegacyComponent implements OnInit, OnChanges, OnDestroy {

    // Inputs from old AngularJS scope
    @Input() label?: string;
    @Input() plabel?: string;
    @Input() field?: string; // two-way with model.field
    @Output() enter = new EventEmitter<string>(); // replaces onEnter callback

    // The "model"
    model = {
        mId: '',
        name: '',
        field: '',
        type: 'button',
        textalign: 'center',
        isDirty: false
    };

    fontSizes: any = {};

    private layoutSub?: Subscription;

    constructor(private layoutService: LayoutService) { }

    ngOnInit(): void {
        // generate ID
        this.model.mId = uuidv4();
        // init from input
        this.model.field = this.field || '';

        // subscribe to layout changes
        this.layoutSub = this.layoutService.layoutChanged.subscribe((info: ILayoutChangedEventArgs | null) => {
            if (!info) return;
            this.fontSizes = { ...info.fontSizes };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['field'] && !changes['field'].isFirstChange()) {
            this.model.field = this.field || '';
        }
    }

    ngOnDestroy(): void {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    private dirtyCheck(value: string): void {
        if (!this.model.isDirty) {
            // clear existing
            this.model.field = '';
            this.model.isDirty = true;
        }
        if (value === '.') {
            if (!this.model.field.includes('.') && !this.model.field.includes(',')) {
                this.model.field += value;
            }
        } else {
            this.model.field += value;
        }
    }

    /**
     * The main method handling button clicks.
     */
    goNum(input: string): void {
        switch (input) {
            case '1': case '2': case '3':
            case '4': case '5': case '6':
            case '7': case '8': case '9':
            case '0':
                this.dirtyCheck(input);
                break;

            case 'dot':
                this.dirtyCheck('.');
                break;

            case 'clear':
                this.model.isDirty = true;
                this.model.field = '';
                break;

            case 'del':
                this.model.isDirty = true;
                this.model.field = this.model.field.slice(0, -1);
                break;

            case 'minus':
                this.model.isDirty = true;
                this.model.field = (Number(this.model.field) - 1).toString();
                break;

            case 'pluss':
                this.model.isDirty = true;
                this.model.field = (Number(this.model.field) + 1).toString();
                break;

            case 'enter':
                this.model.isDirty = false;
                if (!this.model.field) {
                    this.model.field = '0';
                }
                // update our input property
                this.field = this.model.field;
                // emit final value
                this.enter.emit(this.field);
                break;
        }
    }

    /**
     * Replicates addGroup()
     */
    addGroup(): string {
        return 'form-group im-no-mar';
    }

    /**
     * Replicates addClass(btnsize)
     */
    addClass(btnsize?: string): string {
        let allClass = 'col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xx-3 im-no-pad';
        if (btnsize === 'inputDiv') {
            allClass = 'col-xx-12 im-no-pad';
        } else if (btnsize === 'input') {
            allClass = 'form-control input-lg input-field input-text-right';
        }
        return allClass;
    }

    /**
     * Replicates addBtnClass(type)
     */
    addBtnClass(type?: string): string {
        // default
        let allClass = 'btn btn-primary btn-block btn-lg im-btn';
        switch (type) {
            case 'danger':
                allClass = 'btn btn-danger btn-block btn-lg im-btn';
                break;
            case 'warning':
                allClass = 'btn btn-warning btn-block btn-lg im-btn';
                break;
            case 'success':
                allClass = 'btn btn-success btn-block btn-lg im-btn';
                break;
        }
        return allClass;
    }
}
