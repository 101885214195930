(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactComp', {
        templateUrl: 'views/components/views/custactComp/custactComp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'custactCompService', 'stateService', 'searchService', 'utilityService', 'custactCompsService', 'typeaheadService', function ($stateParams, $timeout, custactCompService, stateService, searchService, us, custactCompsService, typeaheadService) {

            let vm = this;

            vm.model = {
                custactNo: $stateParams.custact_no,
                custactcompKeyno: $stateParams.custactcomp_keyno,
                custact: {},
                component: {},
                componentGetRadioButtons: {
                    selected: 'basic_info',
                    buttons: [
                        { id: 'basic_info', label: 'custactcomp_basic_info', color: 'primary', onClick: () => { } },
                        { id: 'details', label: 'custactcomp_details', color: 'primary', onClick: () => { } },
                    ]
                },
                productSearchText: '',
                productSearchButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoSelectProduct }
                ],
                inputUnitsList: [],
                productButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoProduct }
                ],
                productSizeList: [],
                productChoiceList: [],
                componentAddRadioButtons: {
                    selected: 'sales',
                    buttons: [
                        { id: 'sales', label: 'custactcomp_sales', color: 'primary', onClick: () => { } },
                        { id: 'purchase', label: 'custactcomp_purchase', color: 'primary', onClick: () => { } },
                        { id: 'db', label: 'custactcomp_db', color: 'primary', onClick: () => { } },
                    ]
                },
                valutaList: [],
            };

            vm.dateOptions = {
                date: {
                    show: true, disabled: true, align: 'right'
                },
                hour: {
                    show: true, disabled: true, align: 'right'
                },
                minute: {
                    show: true, disabled: true, align: 'right', interval: 1
                }
            }

            function setSearchFocus() {
                setTimeout(() => document.getElementsByName('formCustactComp_search')[0]?.[0]?.focus(), 50);
            };

            function rememberProductSearch() {
                if (vm.model.productSearchText?.trim() && vm.model.custact?.custact_no?.trim()) {
                    custactCompService.remember(`w_selectproduct.custactcomp/${vm.model.custact.custact_no}.searchtext`, vm.model.productSearchText);
                }
            };

            // #region LOAD FUNCTIONS

            function loadCustact() {
                return custactCompsService.loadP2CustactGet($stateParams.custact_no).then((data) => vm.model.custact = data);
            }

            function loadCustactComponent() {
                return custactCompService.loadP2CustactCompAdd($stateParams.custact_no, $stateParams.custactcomp_keyno).then((data) => {
                    vm.model.component = data[0];

                    if (vm.model.component.prod_id && vm.model.component.prod_id !== '0') vm.onProductSelected({ item_id: vm.model.component.prod_id, item_name: vm.model.component.prodname });
                });
            }

            function loadValutaList() {
                return custactCompService.loadP2ValutaList().then((data) => vm.model.valutaList = data);
            }

            // #endregion LOAD FUNCTIONS

            // # region TT BUTTONS FUNCTIONS

            function gotoProduct() {
                stateService.go('product', { prod_id: vm.model.component.prod_id });
            };

            function gotoSelectProduct() {
                custactCompService.selectingProduct = true;
                rememberProductSearch();

                stateService.go('selectproduct', {
                    webpage_name: stateService.getCurrentName(),
                    parm1: $stateParams.custact_no,
                    parm2: '0'
                });
            }

            // #endregion TT BUTTONS FUNCTIONS

            // #region PRODUCT SEARCH

            vm.onProductSearchTextChanged = function (value) {
                vm.model.productSearchText = value;
                if (!value?.trim()) vm.onProductSearchClear();
            }
            
            vm.onProductSearch = function () {
                return typeaheadService.searchProduct({
                    prodgrp_id: 'ALL',
                    prod_name: vm.model.productSearchText,
                    webpage_name: 'custactcomp',
                    parm1: $stateParams.custact_no,
                    parm2: $stateParams.custactcomp_keyno
                }).then((data) => {
                    if (data.length === 1) vm.onProductSelected(data.length[0]);
                    return data;
                });
            }

            vm.onProductSelected = function (item) {
                vm.model.component.prod_id = item?.item_id ?? '';
                vm.model.component.prodname = item?.item_name ?? '';

                if (vm.model.component.prod_id) {
                    custactCompService.loadP2ProductProdsizeList(vm.model.component.prod_id).then((data) => vm.model.productSizeList = data);
                    custactCompService.loadP2ProductProdchoiceList(vm.model.component.prod_id).then((data) => vm.model.productChoiceList = data);
                    custactCompService.loadP2CustactCompAddProduct(vm.model.component.prod_id).then((data) => setComponentProduct(data[0]));
                }

                custactCompService.selectingProduct = false;
            }

            vm.onProductSearchClear = function () {
                vm.model.productSearchText = '';
                Object.keys(vm.model.component).forEach((key) => vm.model.component[key] = '');
                vm.model.inputUnitsList = [];
            }

            async function setComponentProduct(product) {
                Object.keys(product).forEach((key) => vm.model.component[key] = product[key]);

                vm.model.inputUnitsList = await custactCompService.genericFunction('599', { prod_id: vm.model.component.prod_id });

                if (!vm.model.component.input_unit_no?.trim() && vm.model.component.prod_stock_unit_no) {
                    vm.onInputUnitChanged(vm.model.component.prod_stock_unit_no);
                } else if (vm.model.inputUnitsList?.length > 0) {
                    vm.onInputUnitChanged(vm.model.inputUnitsList[0].unit_no);
                }

                await vm.refreshPrice();
            }

            // #endregion PRODUCT SEARCH

            // #region CALCULATE FUNCTIONS

            function computeQuantity() {
                if (vm.model.component.prod_id !== '' && vm.model.component.input_unit_no > 0 && vm.model.component.prod_stock_unit_no > 0) {
                    custactCompService.genericFunction('600', {
                        prodsize_keyno: vm.model.component.prodsize_keyno,
                        unit_no_from: vm.model.component.input_unit_no, //vm.model.p2CustactCompAdd.prod_stock_unit_no,
                        unit_no_to: vm.model.component.prod_stock_unit_no, //vm.model.p2CustactCompAdd.input_unit_no,
                        numbertocalc: vm.model.component.input_quantity
                    }).then((data) => vm.onQuantityChanged(data[0]?.numberresult ?? vm.model.component.quantity));
                };
            };

            function recalcLengthWidth() {
                let l_n_result = 0;

                if (vm.model.component.input_unit_no == '122') {
                    l_n_result = (us.parseNumber(vm.model.component.helpcalc_width_mm) / 1000 * us.parseNumber(vm.model.component.helpcalc_length_mm) / 1000 * (1 + us.parseNumber(vm.model.component.helpcalc_wastage_pst) / 100) * us.parseNumber(vm.model.component.helpcalc_qty));

                    vm.onInputQuantityChanged(l_n_result);
                }
            };

            function calculateCost() {
                vm.model.component.cost_price_locval = us.parseNumber(vm.model.component.cost_price_invval) * us.parseNumber(vm.model.component.cost_exchangefactor);
                vm.model.component.cost_locval = us.parseNumber(vm.model.component.cost_price_locval) * us.parseNumber(vm.model.component.quantity);
                vm.model.component.cost_invval = us.parseNumber(vm.model.component.cost_price_invval) * us.parseNumber(vm.model.component.quantity);
            };

            function calculateSalesAmounts() {
                vm.model.component.ordval_price_net =
                    us.parseNumber(vm.model.component.ordval_price_brt) -
                    (us.parseNumber(vm.model.component.ordval_price_brt) * us.parseNumber(vm.model.component.orderdiscount_pst) / 100);
                vm.model.component.ordval_amount_net = us.parseNumber(vm.model.component.ordval_price_net) * us.parseNumber(vm.model.component.quantity);
                //Valutaomregning
                vm.model.component.locval_price_brt = us.parseNumber(vm.model.component.ordval_price_brt) * us.parseNumber(vm.model.component.ordval_exchangefactor);
                vm.model.component.locval_price_net = us.parseNumber(vm.model.component.ordval_price_net) * us.parseNumber(vm.model.component.ordval_exchangefactor);
                vm.model.component.locval_amount_net = us.parseNumber(vm.model.component.ordval_amount_net) * us.parseNumber(vm.model.component.ordval_exchangefactor);
                vm.model.component.db_locval = us.parseNumber(vm.model.component.locval_amount_net) - us.parseNumber(vm.model.component.cost_locval);

                if (vm.model.component.db_locval > '0.00' || vm.model.component.locval_amount_net > '0.00') {
                    vm.model.component.db_pst = (us.parseNumber(vm.model.component.db_locval) * 100) / us.parseNumber(vm.model.component.locval_amount_net);
                }
            };

            // #endregion CALCULATE FUNCTIONS

            // #region BUTTON FUNCTIONS

            vm.refreshPrice = async function () {
                if (vm.model.component.prod_id !== '' && vm.model.component.prodsize_keyno > 0 && vm.model.component.prodchoice_keyno > 0 && vm.model.component.prod_stock_unit_no > 0 && vm.model.component.input_unit_no > 0) {
                    let data = await custactCompService.loadP2CustactCompGetPrice(vm.model.component.prod_id, vm.model.component.prodsize_keyno, vm.model.component.prodchoice_keyno, $stateParams.custact_no, vm.model.component.prod_stock_unit_no);
                    Object.keys(data).forEach((key => vm.model.component[key] = data[key]));
                    vm.onCostValutaChanged(vm.model.component.cost_valuta_id);
                }
            };

            function validStringField(field) {
                if (typeof field === 'string' && field?.trim()) {
                    return true;
                }
                return false;
            }

            function validateAndCleanComponentProduct() {
                vm.model.component.prod_id = validStringField(searchService.custactComp.selectedProdId) ? searchService.custactComp.selectedProdId : vm.model.component.prod_id;
                vm.model.component.prodname = validStringField(searchService.custactComp.selectedProdName) ? searchService.custactComp.selectedProdName : vm.model.component.prodname;
                searchService.custactComp.selectedProdId = '';
                searchService.custactComp.selectedProdName = '';
            }

            vm.saveAndNewComponent = async function () {
                validateAndCleanComponentProduct();
                await custactCompService.saveNewP2CustactCompAdd(vm.model.component);
                vm.onProductSearchClear();
                await loadCustactComponent();
                setSearchFocus();
            }

            vm.saveComponent = function () {
                validateAndCleanComponentProduct();
                custactCompService.saveNewP2CustactCompAdd(vm.model.component).then(() => stateService.back());
            }

            vm.deleteComponent = function () {
                custactCompService.deleteP2CustactComp().then(() => stateService.back());
            };

            // #endregion BUTTON FUNCTIONS

            // #region TT-CHANGE FUNCTIONS

            let computeQuantityTimer = null;

            vm.onInputUnitChanged = function (value) {
                vm.model.component.input_unit_no = value;

                if (computeQuantityTimer) {
                    $timeout.cancel(computeQuantityTimer);
                }

                computeQuantityTimer = $timeout(computeQuantity, 300);
            }

            vm.onInputQuantityChanged = function (value) {
                vm.model.component.input_quantity = value;;

                if (computeQuantityTimer) {
                    $timeout.cancel(computeQuantityTimer);
                }

                computeQuantityTimer = $timeout(computeQuantity, 300);
            }

            let recalcLengthWidthTimer = null;

            vm.onHelpCalcWidthMmChanged = function (value) {
                vm.model.component.helpcalc_width_mm = value;

                if (recalcLengthWidthTimer) {
                    $timeout.cancel(recalcLengthWidthTimer);
                }

                recalcLengthWidthTimer = $timeout(recalcLengthWidth, 300)
            }

            vm.onHelpCalcLengthMmChanged = function (value) {
                vm.model.component.helpcalc_length_mm = value;

                if (recalcLengthWidthTimer) {
                    $timeout.cancel(recalcLengthWidthTimer);
                }

                recalcLengthWidthTimer = $timeout(recalcLengthWidth, 300)
            }

            vm.onHelpCalcWastageChanged = function (value) {
                vm.model.component.helpcalc_wastage_pst = value;

                if (recalcLengthWidthTimer) {
                    $timeout.cancel(recalcLengthWidthTimer);
                }

                recalcLengthWidthTimer = $timeout(recalcLengthWidth, 300)
            }

            vm.onHelpCalcQuantityChanged = function (value) {
                vm.model.component.helpcalc_qty = value;

                if (recalcLengthWidthTimer) {
                    $timeout.cancel(recalcLengthWidthTimer);
                }

                recalcLengthWidthTimer = $timeout(recalcLengthWidth, 300)
            }

            vm.onProdsizeChanged = function (value) {
                vm.model.component.prodsize_keyno = value;
                if (value) vm.refreshPrice();
            }

            vm.onProdchoiceChanged = function (value) {
                vm.model.component.prodchoice_keyno = value;
                if (value) vm.refreshPrice();
            }

            vm.onOrdvalPriceBrtChanged = function (value) {
                vm.model.component.ordval_price_brt = value;
                calculateSalesAmounts();
            }

            vm.onOrderDiscountPstChanged = function (value) {
                vm.model.component.orderdiscount_pst = value;
                calculateSalesAmounts();
            }

            vm.onCostPriceInvvalChanged = function (value) {
                vm.model.component.cost_price_invval = value;
                calculateCost();
                calculateSalesAmounts();
            }

            vm.onQuantityChanged = function (value) {
                vm.model.component.quantity = value;
                calculateCost();
                calculateSalesAmounts();
            }

            vm.onCostValutaChanged = function (value) {
                vm.model.component.cost_valuta_id = value;

                if (value) {
                    const valuta = vm.model.valutaList.find((valuta) => valuta.valuta_id === vm.model.component.cost_valuta_id);
                    vm.model.component.cost_exchangerate = valuta.valuta_exchangerate;
                    vm.model.component.cost_exchangefactor = valuta.valuta_exchangefactor;

                    calculateCost();
                    calculateSalesAmounts();
                }
            }

            // #endregion TT-CHANGE FUNCTIONS

            // #region ANGULAR LIFECYCLE

            vm.$onInit = async function () {
                await Promise.all([loadCustact(), loadCustactComponent(), loadValutaList()]);
                vm.refreshPrice();
                setSearchFocus();
            };

            // #endregion ANGULAR LIFECYCLE

        }]
    });
})();
