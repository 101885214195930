import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { DBGridButton, GridButton, GridOptions, GridRow, GridSpecialFunctions } from '../../grid.types';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { GridModalService } from '../../modals/grid-modal.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { from, of } from 'rxjs';
import { StateService } from '@app/core/services/state.service';
import { UtilityService } from '@app/core/services/utility.service';
import { GridService } from '../../grid.service';
import { ModalService } from '../../../../services/modal.service';
import { TranslateService } from '@app/core/services/translate.service';
import { PopupService } from '@app/core/services/popup.service';

@Component({
    selector: 'tt-grid-functions-cell-renderer',
    templateUrl: './grid-functions-cell-renderer.component.html',
    styleUrls: ['./grid-functions-cell-renderer.component.css'],
})
export class GridFunctionsCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    /**
     * List of buttons to show in the grid function column.
     */
    public buttons: DBGridButton[] = [];

    /**
     * Whether the colschema contains item path.
     */
    public hasItemPath = false;

    /**
     * Whether special func edit is true.
     */
    public hasSpecialFuncEdit = false;

    /**
     * The cell parameters for this cell.
     */
    public params?: GridFunctionsCellRendererComponentParams;

    constructor(private popup: PopupService, private modalService: ModalService, private gridService: GridService, private gridModalService: GridModalService, private translate: TranslateService, private datatask: DataTaskService, private state: StateService, private utility: UtilityService) {}
    public async openPopup(button: GridButton) {
        const response = await this.datatask.Post(2837, { datatask: this.params?.options.dataTask?.loadData, field: `popup:${button.name}`, row: this.params?.data, gridRemId: this.params?.options.dataTask?.rememberId });
        // const response = await this.datatask.Post(2837, { datatask: this.params?.options.dataTask?.loadData, field: `popup:voucher_no`, row: this.params?.data, gridRemId: this.params?.options.dataTask?.rememberId });

        const popup = await this.popup.openPopup(response[0].item_state + '/' + response[0].item_parms, { rememberId: !!this.params?.options.dataTask?.rememberId ? `${this.params?.options.dataTask?.rememberId}.popup:${button.name}` : undefined });

        popup?.closed.subscribe({
            next: (closed) => {
                if (button.read_on_close !== false) {
                    if (closed) this.params?.options.gridfunc?.read();
                }
            },
        });
    }

    /**
     * Handles navigation of default goto button.
     *
     * @param event the native mouse event.
     * @fires `ttNavigate` angular preventable event.
     */
    public defaultGoto(event: MouseEvent) {
        const navigateEvent: NavigateEvent = {
            preventDefault: () => (navigateEvent.defaultPrevented = true),
            defaultPrevented: false,
            newTab: this.utility.isModifierKeyPressed(event),
            rowData: this.params?.data,
            itemState: this.params?.data?.['item_state'],
            itemParms: this.params?.data?.['item_parms'],
        };

        this.params?.navigateEventEmitter.emit(navigateEvent);

        if (navigateEvent.defaultPrevented) return;

        if (navigateEvent.newTab) {
            this.state.newTab(navigateEvent.itemState || '', navigateEvent.itemParms);
        } else {
            this.state.go(navigateEvent.itemState || '', navigateEvent.itemParms);
        }
    }

    /**
     * Handles navigation for new tab button.
     *
     * @fires `ttNavigate` angular preventable event.
     */
    public openInNewTab() {
        const navigateEvent: NavigateEvent = {
            preventDefault: () => (navigateEvent.defaultPrevented = true),
            defaultPrevented: false,
            newTab: true,
            rowData: this.params?.data,
            itemState: this.params?.data?.['item_state'],
            itemParms: this.params?.data?.['item_parms'],
        };

        this.params?.navigateEventEmitter.emit(navigateEvent);

        if (navigateEvent.defaultPrevented) return;

        this.state.newTab(navigateEvent.itemState || '', navigateEvent.itemParms);
    }

    /**
     * Handles click event of a special func button set up from datatask.
     *
     * @param event the native mouse click event.
     * @param button the button to handle click event for.
     */
    public specialFuncButtonClick(event: MouseEvent, button: DBGridButton) {
        if (!!button.func && button.func instanceof Function) {
            button.func(this.params?.data, button, event);
        } else {
            if (!button.type) {
            } else if (button.type === 'modal') {
                this.openModalBySpecialButton(button);
            } else if (button.type === 'goto') {
                this.navigateBySpecialButton(event, button);
            } else if (button.type === 'datatask') {
                this.runDatataskBySpecialButton(event, button);
            } else if (button.type === 'popup') {
                this.openPopup(button);
            }
        }
    }

    /**
     * Opens a modal configured by the given special func button.
     *
     * @param button the button to configure and open a modal for.
     * @fires `ttOpenModal` angular preventable event.
     */
    private async openModalBySpecialButton(button: DBGridButton) {
        const modalEvent: ModalEvent = {
            preventDefault: () => (modalEvent.defaultPrevented = true),
            defaultPrevented: false,
            modalComponent: button.component || '',
            modalSize: button.modal_size || 'pst-ninetyfive',
            rowData: this.params?.data,
        };

        this.params?.openModalEventEmitter.emit(modalEvent);

        if (modalEvent.defaultPrevented) return;

        await this.gridModalService.openDialogFromDbButton(button, {
            loadData: this.params?.options.dataTask?.loadData,
            rememberId: this.params?.options.dataTask?.rememberId,
            selectedRows: this.params?.options.gridfunc?.getIsSelectedRows() || [],
            row: this.params?.data,
        });

        this.params?.options.gridfunc?.read();
    }

    /**
     * Navigates using configuration provided in the given special func button.
     *
     * @param event the native browser click event, for handling new tab logic.
     * @param button the special func button to use for navigation.
     * @fires `ttNavigate` angular preventable event.
     */
    private navigateBySpecialButton(event: MouseEvent, button: DBGridButton) {
        if (!!this.params) {
            this.gridService.dbButtonGoto({ button: button, event: event, navigateEventEmitter: this.params.navigateEventEmitter });
        }
    }

    private async runDatataskBySpecialButton(event: MouseEvent, button: DBGridButton) {
        if (!button.p2_datatask_keyno || button.p2_datatask_keyno < 1 || !this.params) return;

        const datataskEvent: DatataskEvent = {
            preventDefault: () => (datataskEvent.defaultPrevented = true),
            defaultPrevented: false,
            p2_datatask_keyno: button.p2_datatask_keyno,
            newTab: this.utility.isModifierKeyPressed(event),
            rowData: this.params?.data,
        };

        this.params?.datataskEventEmitter.emit(datataskEvent);

        if (datataskEvent.defaultPrevented) return;

        try {
            this.gridService.dbButtonDatatask({
                button: button,
                parameters: {
                    dataItem: this.params.data,
                    rows: this.params.api?.getSelectedRows(),
                    isSelected: this.params.options.data?.rowData.filter((data) => data['is_selected'] === true),
                },
                datataskEventEmitter: this.params.datataskEventEmitter,
                navigateEventEmitter: this.params.navigateEventEmitter,
                event: event,
                options: this.params.options,
            });
        } catch (error) {
            this.modalService.openErrorDialog(`${error}`);
        }
    }

    /**
     * Opens row edit modal.
     */
    public async editRow() {
        await this.gridModalService.openRowEditModal(this.params?.options, this.params?.node.data, this.params!.api);
    }

    /**
     * Returns the appropriate icon css classes based on the given icon string.
     *
     * @param icon the string to get css classes for.
     * @returns a string containing icon classes for the given icon.
     */
    private getIconClasses(icon: string) {
        let iconClasses = '';

        if (icon === 'goTab') {
        } else if (!!icon) {
            iconClasses = 'glyphicon ' + icon;

            if (icon.startsWith('fa')) {
                iconClasses = icon.split('-')[0] + ' fa' + icon.substring(icon.indexOf('-'));
            }
        }

        return iconClasses;
    }

    public hasValidGotoParameters() {
        return !!this.params && this.hasItemPath && ((!!this.params.data['item_state'] && !!this.params?.data['item_parms']) || !!this.params?.data['item_path']);
    }

    agInit(params: GridFunctionsCellRendererComponentParams): void {
        this.params = params;

        if (this.params.options?.data?.hasGotoParms) {
            this.hasItemPath = true;
        }

        if (this.params.options?.data?.hasSpecialFuncEdit) {
            this.hasSpecialFuncEdit = true;
        }

        this.buttons = [...(params.specialFunc?.buttons?.filter((button) => !button.type || (button.type !== 'noclick' && button.type !== 'cell')).map((button) => ({ ...button, icon: this.getIconClasses(button.icon || '') })) || [])];
        this.buttons = this.buttons.sort((a, b) => {
            const aType = !!a.btn_type ? a.btn_type : a.type;
            const bType = !!b.btn_type ? b.btn_type : b.type;

            if (aType === 'danger' && bType !== 'danger') return 1;
            if (bType === 'danger' && aType !== 'danger') return -1;

            return this.buttons.indexOf(a) - this.buttons.indexOf(b);
        });

        this.buttons.forEach(async (button) => {
            if (button.disabled && typeof button.disabled === 'string' && button.disabled !== 'hidden' && params.data && !params.node.group && !params.node.isRowPinned()) {
                let disabledCondition: string = button.disabled;

                for (let [key, value] of Object.entries(params.data)) {
                    if (typeof value === 'string') {
                        disabledCondition = disabledCondition.replaceAll(key, `"${value}"`);
                    } else {
                        disabledCondition = disabledCondition.replaceAll(key, `${value}`);
                    }
                }

                let disabled: boolean | 'hidden' = false;

                try {
                    disabled = Function('return ' + disabledCondition)();
                } catch (error) {
                    disabled = false;
                } finally {
                    button.disabled$ = of(disabled);
                }
            }

            if (button.disabled && button.disabled instanceof Function) {
                // @ts-ignore
                let result = button.disabled(this.params?.data);

                if (result instanceof Promise) {
                    button.disabled$ = from(result);
                } else {
                    button.disabled$ = of(result);
                }
            }

            if (button.tooltip && button.translateTooltip) {
                button.tooltip = await this.translate.translate(button.tooltip);
            }

            if (button.translate === true && !!button.text) {
                button.text = await this.translate.translate(button.text);
            }
        });
    }

    refresh(_: ICellRendererParams<any, any, any>): boolean {
        return false;
    }

    ngOnDestroy(): void {
        this.popup.closeAllPopups();
    }
}

export type GridFunctionsCellRendererComponentParams = ICellRendererParams & { specialFunc?: GridSpecialFunctions; options: GridOptions; openModalEventEmitter: EventEmitter<ModalEvent>; navigateEventEmitter: EventEmitter<NavigateEvent>; datataskEventEmitter: EventEmitter<DatataskEvent> };

/**
 * Represents navigation event from special func buttons.
 */
export interface NavigateEvent extends CustomEvent {
    rowData: GridRow | null;
    newTab: boolean;
    itemState?: string;
    itemParms?: string | { [key: string]: string };
}

/**
 * Represents open-modal event from special func buttons.
 */
export interface ModalEvent extends CustomEvent {
    modalComponent: string;
    modalSize: string;

    /**
     *
     */
    rowData: GridRow | null;
}

/**
 * Represents a datatask event from special func buttons.
 */
export interface DatataskEvent extends CustomEvent {
    /**
     * The data of the row on which the button was pressed.
     */
    rowData: GridRow | null;

    /**
     * The datatask keyno to run.
     */
    p2_datatask_keyno: number;

    /**
     * If the keyno is `2837` (goto), should the navigation happen in a new tab.
     */
    newTab: boolean;
}

/**
 * Represents a custom
 */
export interface CustomEvent {
    preventDefault: () => void;
    defaultPrevented: boolean;
}
