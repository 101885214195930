import { Injectable } from '@angular/core';
import { DataTaskService } from '../../../core/services/data-task.service';

@Injectable({
    providedIn: 'root'
})
export class ConsignmentPlansDeliveryService {
    constructor(private datatask: DataTaskService) { }

    public async loadDeliveryMethods(): Promise<any[]> {
        return this.datatask.Post(1693, {});
    }

    public async loadSettings(): Promise<any> {
        return this.datatask.Post(1692);
    }

    public async loadPlans(parms: any): Promise<any[]> {
        return this.datatask.Post(1679, parms);
    }

    public async remember(variablename: string, variablevalue: string): Promise<void> {
        return this.datatask.Post(616, {
            variablename: variablename,
            variablevalue: variablevalue
        });
    }

    public async updateColumnResize(parms: any): Promise<any[]> {
        return this.datatask.Post(1457, parms);
    }
}
