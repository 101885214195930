import { AddRowDataTaskRequest, DataTaskRequest, GridButton, GridColumn, GridColumnInfo, GridConfig, GridDataTask, GridEvents, GridExcelExportConfig, GridFunctions, GridNavigation, GridOptions, GridOptionsData, GridPredefinedCSS, GridRow, GridSpecialFunctions, GridToolbar, GridToolbarToggle, KendoConfig, RemoveRowDataTaskRequest, SaveDataTaskRequest, ToggleButton } from './grid.types';
import { ColDef, RowSelectedEvent } from '@ag-grid-community/core';

/**
 * Represents a grid configuration object.
 */
export class Grid implements GridOptions {
    data?: GridOptionsData | undefined;

    events?: GridEvents | undefined;

    onSelect?: ((event: { $event: RowSelectedEvent; $item?: GridRow }) => unknown) | undefined;

    onSetup?: ((schema: GridColumn[], fields: { [key: string]: GridColumnInfo }, columns: ColDef[], userTranslations: { [key: string]: string }) => Promise<void> | void) | undefined;

    reports: any[];

    config: GridConfig;

    excelExportConfig: GridExcelExportConfig;

    dataTask: GridDataTask;

    translations: string[];

    kendo: KendoConfig;

    optionfunc?: any;

    gridfunc?: GridFunctions | null;

    constructor() {
        const defaultGrid = DEFAULT_GRID_OPTIONS();

        this.config = defaultGrid.config!;
        this.excelExportConfig = defaultGrid.excelExportConfig!;
        this.dataTask = defaultGrid.dataTask!;
        this.translations = defaultGrid.translations!;
        this.kendo = defaultGrid.kendo!;
        this.gridfunc = defaultGrid.gridfunc;
        this.reports = defaultGrid.reports!;
        this.data = defaultGrid.data;
        this.events = defaultGrid.events;
        this.onSelect = defaultGrid.onSelect;
        this.onSetup = defaultGrid.onSetup;
    }

    /**
     * Sets the id to use for storing remember variables of the grid with.
     *
     * @param id the id to set.
     * @returns this grid instance.
     */
    public setRememberId(id: string): Grid {
        this.dataTask.rememberId = id;

        return this;
    }

    /**
     * Sets the given datatask request to the load-data datatask request of the grid.
     *
     * @param params the datatask request definition to use for loading data to the grid.
     * @returns this grid instance.
     */
    public setLoadDataFunction(params: DataTaskRequest): Grid {
        this.dataTask.loadData = { ...this.dataTask.loadData, ...params };

        return this;
    }

    /**
     * Sets the given add row datatask request to the grid.
     *
     * @param params the addrow data task request definition to use for adding new rows to the grid.
     * @returns this grid instance.
     */
    public setAddRowFunction(params: AddRowDataTaskRequest): Grid {
        this.dataTask.addRow = { ...this.dataTask.addRow, ...params };

        return this;
    }

    /**
     * Sets the given remove row data request to the grid.
     *
     * @param params the remove row data request definition to use for removing rows from the grid.
     * @returns this grid instance.
     */
    public setRemoveRowFunction(params: RemoveRowDataTaskRequest): Grid {
        this.dataTask.removeRow = { ...this.dataTask.removeRow, ...params };

        return this;
    }

    /**
     * Sets the given save data datatask request to the grid.
     *
     * @param params the save data datatask request definition to use for removing rows from the grid.
     * @returns this grid instance.
     */
    public setSaveDataFunction(params: SaveDataTaskRequest): Grid {
        console.log(params.confirm);
        this.dataTask.saveData = { ...this.dataTask.saveData, ...params };
        console.log(this.dataTask.saveData.confirm);

        return this;
    }

    /**
     * Sets configuration of the datatask object defining how to load data, save data, remove rows etc.
     *
     * @param params the datatask object to use.
     * @returns this grid instance.
     */
    public setDataTaskConfig(params: Partial<GridDataTask>): Grid {
        this.dataTask = { ...this.dataTask, ...params };

        return this;
    }

    /**
     * Sets the list of words to translate-
     *
     * @param translations list of words to translate.
     * @returns this grid instance.
     */
    public setTranslations(translations: string[]): Grid {
        this.translations = translations;

        return this;
    }

    /**
     * Set the toolbar configuration of this grid.
     *
     * @param params the toolbar configuration to use on this grid.
     * @returns this grid instance.
     */
    public setToolbar(params: Partial<GridToolbar>): Grid {
        this.config.toolbar = { ...this.config.toolbar, ...params };

        return this;
    }

    /**
     * Sets the list of custom toolbar buttons in the grid.
     *
     * @param buttons list of custom toolbar buttons in the grid.
     * @returns this grid instance.
     */
    public setCustomToolbarButtons(buttons: GridButton[]): Grid {
        this.config.toolbar!.buttons = buttons;

        return this;
    }

    /**
     * Sets the list of custom toolbar buttons in the grid.
     *
     * @param buttons list of custom toolbar buttons in the grid.
     * @returns this grid instance.
     */
    public setToggleButtons(toggles: ToggleButton[]): Grid {
        this.config.toolbar!.toggles = toggles;

        return this;
    }

    /**
     * Sets the special function configuration of the grid to the given special function to set.
     *
     * @param params the special functions to set.
     * @returns this grid instance.
     */
    public setSpecialFunc(params: Partial<GridSpecialFunctions>): Grid {
        this.config.specialFunc = { ...this.config.specialFunc, ...params };

        return this;
    }

    /**
     * Sets the navigation configuration of the grid to the given configuration.
     *
     * @param params the configuration to set on the grid.
     * @returns this grid instace.
     */
    public setNavigation(params: Partial<GridNavigation>): Grid {
        this.config.navigation = { ...this.config.navigation, ...params };

        return this;
    }

    /**
     * Sets the configuration for using predefined css.
     *
     * @param params the configuration for predefined css to use.
     * @returns this grid instance.
     */
    public setCSSOptions(params: Partial<GridPredefinedCSS>): Grid {
        this.config.css = { ...this.config.css, ...params };

        return this;
    }

    /**
     * Sets the configuration of the grid overriding defaults using the given configuration.
     *
     * @param params the configuration to use for the grid.
     * @returns this grid instance.
     */
    public setConfig(params: Partial<GridConfig>): Grid {
        this.config = { ...this.config, ...params };

        return this;
    }

    public setExcelExportConfig(params: Partial<GridExcelExportConfig>): Grid {
        this.excelExportConfig = { ...this.excelExportConfig, ...params };

        return this;
    }

    /**
     * Overrides the kendo configuration using the given configuration.
     *
     * @param params the configuration to use for kendo.
     * @returns this grid instance.
     */
    public setKendoConfig(params: Partial<KendoConfig>): Grid {
        this.kendo = { ...this.kendo, ...params };
        return this;
    }

    /**
     * Sets the optionfunc of this grid.
     *
     * @param optionfunc the optionfunc.
     * @returns this grid instance.
     */
    public setOptionFunc(optionfunc: Function): Grid {
        this.optionfunc = optionfunc;

        return this;
    }

    /**
     * Sets a function to call when grid has changes in selection.
     *
     * @returns this grid instance.
     */
    public setOnSelect(onSelect: (event: { $event: RowSelectedEvent; $item?: GridRow }) => unknown): Grid {
        this.onSelect = onSelect;

        return this;
    }

    /**
     * Returns this grid instance as a grid options object.
     *
     * @returns this grid instance as a grid options object.
     */
    public build(): GridOptions {
        return this;
    }
}

export function DEFAULT_GRID_OPTIONS(): GridOptions {
    return {
        data: {
            columnDefinitions: [],
            columnInfo: {},
            rowData: [],
            layoutKeyno: null,
            changes: {},
            hasGotoParms: false,
            hasSpecialFuncEdit: false,
            hasSpecialFunc: false,
            hasGridGlyphs: false,
            hasThumbnails: false,
        },
        dataTask: {
            rememberId: '',
            loadSetupId: null,
            loadData: {
                method: null,
                parameters: null,
            },
            addRow: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
                openEdit: false,
                pre: null,
                post: null,
            },
            removeRow: {
                method: null,
                parameters: null,
                post: null,
                autoSave: false,
                confirm: true,
            },
            saveData: {
                method: null,
                parameters: null,
                autoSave: false,
                autoSaveChanges: false,
                confirm: false,
                hideRefreshSpinner: null,
            },
        },
        translations: [],
        config: {
            editColumns: [],
            toolbar: {
                hidden: false,
                pdfExport: true,
                excelExport: true,
                filter: true,
                columnVisibility: true,
                headers: true,
                edit: false,
                lock: false,
                add: false,
                addSimple: false,
                delete: false,
                save: false,
                wrapping: true,
                layouts: true,
                refresh: false,
                read: true,
                rebind: false,
                print: false,
                buttons: [],
                toggles: [],
            },
            sidebar: {
                hidden: false,
                hideColumnsPanel: false,
                hideFilterPanel: false,
                hidePivotMode: false,
                hideRowGroupPane: false,
                hideValuesPane: false,
            },
            fixedHeader: true,
            keepSortOnAdd: false,
            keepSortOnCheckbox: false,
            keepSortOnIsSelected: false,
            rowClick: false,
            shortcuts: false,
            specialFunc: {
                newTab: false,
                buttons: [],
            },
            navigation: {
                altNav: false,
                newLine: false,
            },
            css: {
                altColor: true,
                textWrapping: false,
            },
            serverSideHandling: false,
            onDataSourceChanges: null,
        },
        excelExportConfig: {
            useColnameForHeaders: false,
            excludedColumns: [],
        },
        onDataBound: null,
        kendo: {
            height: '100%',
            sortable: true,
            aggregate: false,
            pager: false,
            pageable: true,
            selectable: false,
            filterable: false,
        },
        reports: undefined,
        optionfunc: null,
        gridfunc: null,
    };
}
