(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCountData', {
        templateUrl: 'views/components/views/stockCountData/stockCountData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'stockCountDataService', function ($stateParams, $q, stateService, utilityService, stockCountDataService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let stockCountDataKeyno = $stateParams.stockcountdata_keyno;

            vm.model = {
                stockValueStockButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.menu.stock_keyno }) }
                ],
                menu: {},
                itemsListStockCountDatas: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS
            
            let loadMenu = function () {
                const deferred = $q.defer();

                stockCountDataService.getMenu({ stockcountdata_keyno: stockCountDataKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);

                    loadStockCountDatas();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadStockCountDatas = function () {
                if (utilityService.validateParmsValue(vm.model.menu.stockcountdata_keyno) !== true) return;

                return stockCountDataService.listStockCountDatas({ stockcountdata_keyno: vm.model.menu.stockcountdata_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockCountDatas);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockcountdataitem':
                        go({
                            stockcountdataitem_keyno: '0',
                            argtype: 'stock_keyno',
                            argvalue: vm.model.menu.stock_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
