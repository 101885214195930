import { Component } from '@angular/core';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-p2-update-script-edit-view',
    templateUrl: './p2-update-script-edit-view.component.html',
    styleUrls: ['./p2-update-script-edit-view.component.css'],
})
export class P2UpdateScriptEditViewComponent {
    public p2UpdateScriptKeyno: string;

    constructor(private state: StateService) {
        this.p2UpdateScriptKeyno = state.getStateParams()['p2_updatescript_keyno'];
    }

    public onSave() {
        this.state.back();
    }
}
