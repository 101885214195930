import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseWidget, NgCompInputs, NgGridStackWidget } from 'gridstack/dist/angular';
import { MenuGroup, MenuItem } from '../webpagemenu.service';
import { GridStack } from 'gridstack';
import { Subject } from 'rxjs';

@Component({
    selector: 'tt-webpagemenu-group-widget',
    templateUrl: './webpagemenu-group-widget.component.html',
    styleUrls: ['./webpagemenu-group-widget.component.css'],
})
export class WebpagemenuGroupWidgetComponent extends BaseWidget implements OnChanges, OnInit, AfterViewInit {
    override widgetItem?: WebpagemenuGroupWidget | undefined;

    public override serialize(): NgCompInputs | undefined {
        return this.ttMenuGroup ? { node: this.ttMenuGroup } : undefined;
    }

    /**
     * The data to be used in this webpagemenu-group widget.
     */
    @Input()
    public ttMenuGroup!: WebpagemenuGroupData;

    /**
     * The transition animation used for expanding/minimizing.
     */
    public transitionAnimation?: string | undefined;

    /**
     * The max height for the link container, used for toggling expansion/minimizing.
     */
    public maxHeight?: string;

    constructor(public elementRef: ElementRef) {
        super();
    }

    /**
     * Retrieves the icon classes for the given icon.
     *
     * @param icon the icon to retrieve additional icon classes for.
     * @returns a string of classes to use for displaying the given icon string.
     */
    public getIcon(icon: string) {
        var iconClasses = '';

        if (icon) {
            iconClasses = 'glyphicon ' + icon;

            if (icon.startsWith('fa')) {
                iconClasses = 'far' + ' fa' + icon.substring(icon.indexOf('-'));
            }
        }

        return iconClasses;
    }

    /**
     * Toggles expansion and minimizing of the widget links.
     */
    public toggleExpansion() {
        this.widgetItem!.expanded = !this.widgetItem!.expanded;

        setTimeout(() => this.resizeAndCompactColumnsPosition(), 100);

        this.ttMenuGroup.stateChange().next(this.widgetItem!);
    }

    /**
     * Resizes this widget to its content and moves around on the other tiles to make sure every thing is compact.
     */
    private resizeAndCompactColumnsPosition() {
        this.ttMenuGroup.api?.()?.resizeToContent(this.elementRef.nativeElement.parentElement.parentElement);
        this.ttMenuGroup.api?.()?.onResize(document.body.clientWidth);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // this.maxHeight = ((this.elementRef.nativeElement as HTMLElement).offsetHeight || (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().height) + 'px';
            // this.maxHeight = '100vh';
            // this.transitionAnimation = 'transition: max-height 0.3s 0s linear';
        }, 50);
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {}
}

/**
 * Represents configuration for a webpagemneu-group widget for gridstack.js.
 */
export interface WebpagemenuGroupWidget extends NgGridStackWidget {
    /**
     * The selector for the webpage menu group widget.
     */
    selector: 'tt-webpagemenu-group-widget';

    /**
     * Whether the tile is expanded or not.
     */
    expanded?: boolean;

    /**
     * Whether the tile in this webpagemenu-group widget can be expanded/minimized.
     */
    expandable?: boolean;

    /**
     * The input parameters for the widget component.
     */
    input: WebpagemenuGroupInput;
}

/**
 * Represents input parameters for the webpagemenugroup widget.
 */
export interface WebpagemenuGroupInput {
    /**
     * The input parameters for the widget component.
     */
    ttMenuGroup: WebpagemenuGroupData;
}

/**
 * Represents data in the webpgagemenugroup widget.
 */
export interface WebpagemenuGroupData {
    /**
     * The webpage menu group represented in this webpagemenu group data, if any.
     */
    group?: MenuGroup;

    /**
     * The items belonging to this webpagemenu group.
     */
    items: MenuItem[];

    /**
     * Reference to the gridstack object to access its api from widget components.
     *
     * @returns the gridstack object.
     */
    api?: () => GridStack | undefined;

    /**
     * Reference to state changed observable to notify when this widget has changes its state.
     *
     * @returns the state changed observable.
     */
    stateChange: () => Subject<any>;
}
