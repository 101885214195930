<!-- [ttData]="timeOptions" -->
<ng-container *ngIf="!isMobile">
    <input
        #timeRef
        [attr.id]="ttId"
        [tabIndex]="ttTabable ? '0' : '-1'"
        class="tt-input__form-control tt-input__form-control--time"
        (focus)="timeRef.select()"
        (blur)="onTimeInputBlur($event)"
        inputmode="numeric"
        [(ngModel)]="model"
        (ngModelChange)="onTimeChanged($event)"
        (keydown)="onTimeInputKeydown($event)"
        [placeholder]="ttShowSeconds ? '--:--:--' : '--:--'"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
    />
</ng-container>
<input
    *ngIf="isMobile && !ttShowSeconds"
    #timeRef
    [attr.id]="ttId"
    [tabIndex]="ttTabable ? '0' : '-1'"
    class="tt-input__form-control tt-input__form-control--time"
    (focus)="timeRef.select()"
    (blur)="onTimeInputBlur($event)"
    type="time"
    [attr.step]="ttMinutePrecision * 600"
    [(ngModel)]="model"
    (ngModelChange)="onTimeChanged($event)"
    [placeholder]="ttShowSeconds ? '--:--:--' : '--:--'"
    [readOnly]="ttReadonly"
    [disabled]="ttDisabled"
    [style.appearance]="'none'"
/>
<input
    *ngIf="isMobile && ttShowSeconds"
    #timeRef
    #timeListbox="listbox-popup"
    [attr.id]="ttId"
    [tabIndex]="ttTabable ? '0' : '-1'"
    [ttListboxPopup]="!ttReadonly && !ttDisabled"
    (ttSelect)="onTimeOptionSelect($event)"
    [ttData]="search"
    [ttRelativeElement]="ttRelativeElement || ttId"
    ttFilterOperation="none"
    [(ttShow)]="showTimes"
    ttPreventDefaultShow
    (ttShowChange)="showTimes = $event"
    class="tt-input__form-control tt-input__form-control--time"
    (focus)="timeRef.select()"
    (blur)="onTimeInputBlur($event)"
    inputmode="numeric"
    [(ngModel)]="model"
    (ngModelChange)="onTimeChanged($event)"
    (keydown)="onTimeInputKeydown($event)"
    [placeholder]="ttShowSeconds ? '--:--:--' : '--:--'"
    [readOnly]="ttReadonly"
    [disabled]="ttDisabled"
    ttMaxHeight="200px"
/>

<!-- [ngStyle]="style['date']" -->
