(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('productCalc', {
        templateUrl: 'views/components/views/productCalc/productCalc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'productCalcService', 'translateService', 'rememberService', '$q', function ($stateParams, stateService, utilityService, modalService, productCalcService, translateService, rememberService, $q) {

            // #region VARIABLES & DEFINITIONS

            var translations = {
                Varsel: '',
            };

            var rememberIdPricelistId = 'w_productCalcPricelistId';

            var vm = this;
            let prodId = $stateParams.prod_id;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'productbom':
                        go(vm.model.setting.prod_keyno);
                    case 'orderlinebom':
                        go({
                            orderline_keyno: vm.model.setting.orderline_keyno
                        });
                        break;
                    case 'productsconfig':
                        go({
                            argtype: 'productcalc',
                            headno: '0',
                            lineno: vm.model.setting.orderline_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                prodIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', vm.model.setting.prod_keyno) }
                ],
                orderLineButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderlinebom' } }
                ],
                selectListPriceList: [],
                pricelist_id: '',
                setting: {
                    pricelist_id: '',
                    show_product_structure: '0'
                },
                creatingOrderLine: false,
                //retrievingGrid: false,
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [{ name: 'toggle_product_structure', text: 'toggle_product_structure', func: function (data) { toggleProductStructure(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } }
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //	console.log('optionfunc - CellClickHandler');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //	console.log('optionfunc - OnCellClose');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //	console.log('optionfunc - LookupCellEditor');
                //	console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS
            var remember = function () {
                rememberService.remember(rememberIdPricelistId, '{ "pricelist_id": "' + vm.model.pricelist_id + '" }');
                console.log(vm.model.pricelist_id)
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });

            });

            var loadProductCalc = function () {
                productCalcService.getProductCalc({
                    prod_id: prodId,
                    argtype: argType,
                    argvalue: argValue
                }).then(function (data) {
                    angular.copy(data[0], vm.model.setting);
                    if (vm.model.setting.nbr_of_orderlines > 0) {
                        loadGrid();
                    }
                });
            };

            var loadSelectListPriceList = function () {
                productCalcService.listPriceList(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.selectListPriceList);
                });
            };


            vm.onChangePriceList = function (pricelist_id) {
                vm.model.pricelist_id = pricelist_id;
                remember();
            };

            

            var loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                	vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                	vm.grid.dataTask.loadData = {
                		method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };

                    if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
                }

                vm.model.gridReady = true;
                //vm.model.retrievingGrid = false;
            };


            var load = function () {

                var deferred = $q.defer();
                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdPricelistId, true));

                $q.all(promises).then(function (response) {
                    vm.model.pricelist_id = response[0] ? String(response[0].pricelist_id) : '';

                });

                return deferred.promise;
            };


            vm.$onInit = function () {
                loadSelectListPriceList()
                loadProductCalc()
                load();
            };


            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON CREATE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.creatingOrderLine = true;
                vm.model.setting.pricelist_id = vm.model.pricelist_id;

                productCalcService.createOrderLine(vm.model.setting).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'danger',
                            title: translations.Varsel,
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingOrderLine = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingOrderLine = false;
                        loadProductCalc();
                        //vm.grid.gridfunc.rebind();
                    }
                });
            };

            var toggleProductStructure = function () {

                if (vm.model.setting.show_product_structure === '1') {
                    vm.model.setting.show_product_structure = '0';
                } else {
                    vm.model.setting.show_product_structure = '1';
                }

                vm.grid.gridfunc.rebind();


            };

		    // #endregion BUTTON CREATE PROCEDURE FUNCTION CALL

            //vm.retriveGrid = function () {
            //    vm.model.retrievingGrid = true;
            //    loadGrid();
            //    vm.grid.gridfunc.rebind();
            //};
        }]
    });
})();
