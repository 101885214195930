import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';

import { TtListLegacyComponent } from './tt-list-legacy.component';
import { ReversePipe } from './reverse.pipe';
import { FilterListPipe } from './filter-list.pipe';
import { FilterSecondListPipe } from './filter-second-list.pipe';
import { FilterGlyphPipe } from './filter-glyph.pipe';

@NgModule({
    declarations: [
        TtListLegacyComponent,
        ReversePipe,
        FilterListPipe,
        FilterSecondListPipe,
        FilterGlyphPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        NouisliderModule
    ],
    exports: [
        TtListLegacyComponent,
        ReversePipe,
        FilterListPipe,
        FilterSecondListPipe,
        FilterGlyphPipe
    ]
})
export class TtListLegacyModule { }
