import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { SwitchComponent } from './switch/switch.component';
import { RememberService } from '@app/core/services/remember.service';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'tt-content-switcher',
    templateUrl: './content-switcher.component.html',
    styleUrls: ['./content-switcher.component.css'],
})
export class ContentSwitcherComponent<TType extends string> implements OnChanges, OnInit, AfterContentInit {
    /**
     * Id used for persisting the last selected id of the switch using `616`.
     */
    @Input()
    public ttRememberId: string = '';

    /**
     * Whether the content switch should not retrieve the last stored selected id upon initialization and only remember the new selected id.
     */
    @Input()
    public set ttOnlyRemember(value: BooleanInput) {
        this._onlyRemember = coerceBooleanProperty(value);
    }
    public get ttOnlyRemember(): boolean {
        return this._onlyRemember;
    }
    private _onlyRemember = false;

    /**
     * The id of the selected switch.
     */
    @Input()
    public ttSelectedId?: TType;

    /**
     * Event emitted when a new switch is selected. Will also emit an event if no initial value is given or when retrieving last stored value from 973.
     */
    @Output()
    public ttSelectedIdChange = new EventEmitter<TType>();

    /**
     * Whether this content-switcher should stretch to use all its available place. Default true, if false will be the width of its content.
     */
    @Input()
    public set ttStretchWidth(value: BooleanInput) {
        this._stretchWidth = coerceBooleanProperty(value);
    }
    public get ttStretchWidth(): boolean {
        return this._stretchWidth;
    }
    private _stretchWidth = true;

    /**
     * The switched passed through the content-slot of this content-switch component.
     */
    @ContentChildren(SwitchComponent, { descendants: true })
    public contentSwitches?: QueryList<SwitchComponent>;

    /**
     * Remember subject for remembering the last selected id.
     */
    private rememberSubject = new Subject();

    constructor(private remember: RememberService) {}

    /**
     * Sets the given id as the selected id and emits a selected id change event.
     *
     * @emits `ttSelectedIdChange` containing the new id in the event.
     */
    private selectId(id: TType) {
        if (id !== this.ttSelectedId) {
            this.ttSelectedId = id;
            this.ttSelectedIdChange.emit(this.ttSelectedId);
        }

        this.updateActivePropForContentSwitches();
    }

    private updateActivePropForContentSwitches() {
        this.contentSwitches?.forEach((item) => (item.ttActive = this.ttSelectedId === item.ttId));
    }

    private updateStretchWidthOfContentSwitch() {
        this.contentSwitches?.forEach((item) => (item.ttStretchWidth = this.ttStretchWidth));
    }

    /**
     * Retrieves the last persisted id of this content switcher with the remember id passed through `ttRememberId`.
     */
    private async getLastSelectedId() {
        try {
            this.contentSwitches?.forEach((item) => (item.ttSkeleton = true));
            const lastId = (await this.remember.getLastStatus(this.ttRememberId))?.[0]?.variablevalue;

            if (!!lastId) {
                this.selectId(lastId);
            }
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Checks if the any of the switches has the id which matched the current selected id.
     *
     * @returns `true` if any of the switches has an id matching the currently selected id, `false` if not.
     */
    private hasSelectedSwitch() {
        const selectedContentSwitch = this.contentSwitches?.find((item) => !item.ttDisabled && item.ttId === this.ttSelectedId && item.ttSelectable === true);

        return !!selectedContentSwitch;
    }

    /**
     * Selects the first available id of the switches available.
     */
    private selectFirstAvailableId() {
        let firstAvailableId: string = '';

        if (!firstAvailableId && !!this.contentSwitches && this.contentSwitches.length > 0) {
            firstAvailableId = this.contentSwitches.find((item) => item.ttDisabled !== true && item.ttSelectable === true)?.ttId || '';
        }

        if (!!firstAvailableId) {
            this.selectId(firstAvailableId as TType);
        }
    }

    /**
     * Sets the switch-components of this content-switch components as ready.
     */
    private setContentSwitchAsReady() {
        this.contentSwitches?.forEach((item) => (item.ttSkeleton = false));
    }

    /**
     * Remembers the currenlty selected id given that an id is selected and remember id provided.
     */
    private rememberSelectedId() {
        if (!!this.ttRememberId && !!this.ttSelectedId) {
            this.remember.remember(this.ttRememberId!, this.ttSelectedId);
        }
    }

    ngOnInit(): void {}

    async ngAfterContentInit(): Promise<void> {
        this.updateStretchWidthOfContentSwitch();

        this.contentSwitches
            ?.filter((item) => item.ttSelectable === true)
            .forEach((item) =>
                item.ttClick.subscribe((_) => {
                    this.selectId(item.ttId as TType);
                    this.rememberSubject?.next(this.ttSelectedId);
                })
            );

        if (!this.ttOnlyRemember && !!this.ttRememberId) {
            await this.getLastSelectedId();
        }

        if (!this.hasSelectedSwitch()) {
            this.selectFirstAvailableId();
        }

        this.updateActivePropForContentSwitches();

        this.setContentSwitchAsReady();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttSelectedId']) {
            this.ttSelectedId = changes['ttSelectedId'].currentValue;

            if (!this.hasSelectedSwitch()) {
                this.selectFirstAvailableId();
            }

            this.updateActivePropForContentSwitches();
        }

        if (changes['ttStretchWidth']) {
            this.ttStretchWidth = changes['ttStretchWidth'].currentValue;
            this.updateStretchWidthOfContentSwitch();
        }

        if (changes?.['ttRememberId']?.currentValue && changes?.['ttRememberId']?.currentValue !== changes?.['ttRememberId']?.previousValue) {
            this.rememberSubject.pipe(debounceTime(500)).subscribe({ next: () => this.rememberSelectedId() });
        }
    }
}
