(function () {
    'use strict';

    angular.module("imApp").factory("invoiceHeadService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 751,
                    parameters: parms || {}
                });
            },
            listInvoiceLines: function (parms) {
                return $ihttp.post({
                    method: 752,
                    parameters: parms || {}
                });
            },
            setPrinted: function (edit) {
                return $ihttp.post({
                    method: 828,
                    parameters: edit
                });
            },
            sendInvoice: function (edit) {
                return $ihttp.post({
                    method: 2284,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2306,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
