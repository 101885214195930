(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('sopCls', {
        templateUrl: 'views/components/views/sopCls/sopCls.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'sopClsService', function ($stateParams, $q, stateService, utilityService, sopClsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let sopKeyno = $stateParams.sop_keyno;
            
            vm.model = {
                setting: {},
                itemsListChoices: []
            };

            // #region VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                sopClsService.getSetting({ sop_keyno: sopKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadChoices();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadChoices = function () {
                if (utilityService.validateParmsValue(vm.model.setting.sop_keyno) !== true) return;
                
                vm.model.itemsListChoices = [];
                
                return sopClsService.listChoices({ sop_keyno: vm.model.setting.sop_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListChoices);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'sopcl':
                        go({
                            sop_keyno: vm.model.setting.sop_keyno,
                            sopcl_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
