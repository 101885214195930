(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('sopValid', {
        templateUrl: 'views/components/views/sopValid/sopValid.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'sopValidService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, sopValidService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let sopKeyno = $stateParams.sop_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                menu: {},
                itemsListValids: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                sopValidService.getMenu({ sop_keyno: sopKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);

                    loadSopValids();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadSopValids = function () {
                if (utilityService.validateParmsValue(vm.model.menu.sop_keyno) !== true) return;
                
                vm.model.itemsListValids = [];
                
                return sopValidService.listSopValids({ sop_keyno: vm.model.menu.sop_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListValids);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region TOGGLE FUNCTIONS
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let currentData = vm.model.itemsListValids;
                let selectedItems = [];

                angular.forEach(currentData, function (item) {
                    selectedItems.push({
                        item_id: item.item_id,
                        item_is_selected: item.item_is_selected
                    });
                });

                sopValidService.saveObj({ records: selectedItems }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
