(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("productService", ['$ihttp', function ($ihttp) {
        let service = {
            getProduct: function (parms) {
                return $ihttp.post({
                    method: 215,
                    parameters: parms || {}
                });
            },
            listProductGroups: function (parms) {
                return $ihttp.post({
                    method: 915,
                    parameters: parms || {}
                });
            },
            listProductGroup2s: function (parms) {
                return $ihttp.post({
                    method: 961,
                    parameters: parms || {}
                });
            },
            listModels: function (parms) {
                return $ihttp.post({
                    method: 1160,
                    parameters: parms || {}
                });
            },
            listColourGroups: function (parms) {
                return $ihttp.post({
                    method: 1199,
                    parameters: parms || {}
                });
            },
            listCustomsTarifs: function (parms) {
                return $ihttp.post({
                    method: 1725,
                    parameters: parms || {}
                });
            },
            listCollections: function (parms) {
                return $ihttp.post({
                    method: 1218,
                    parameters: parms || {}
                });
            },
            listAssortments: function (parms) {
                return $ihttp.post({
                    method: 1962,
                    parameters: parms || {}
                });
            },
            listServicetypes: function (parms) {
                return $ihttp.post({
                    method: 3276,
                    parameters: parms || {}
                });
            },
            listProductTypes: function (parms) {
                return $ihttp.post({
                    method: 219,
                    parameters: parms || {}
                });
            },
            listOwnKpis: function (parms) {
                return $ihttp.post({
                    method: 2661,
                    parameters: parms || {}
                });
            },
            listDiscGroupProducts: function (parms) {
                return $ihttp.post({
                    method: 3117,
                    parameters: parms || {}
                });
            },
            listProductSettingMcs: function (parms) {
                return $ihttp.post({
                    method: 3138,
                    parameters: parms || {}
                });
            },
            getSelectListProductSettingMcs: function (parms) {
                return $ihttp.post({
                    method: 3568,
                    parameters: parms || {}
                });
            },
            getNextNumber: function (productsettingmc_keyno) {
                return $ihttp.post({
                    method: 3558,
                    parameters: {
                        productsettingmc_keyno: productsettingmc_keyno
                    }
                });
            },
            listHazardClasses: function (parms) {
                return $ihttp.post({
                    method: 3149,
                    parameters: parms || {}
                });
            },
            listTunnelCodes: function (parms) {
                return $ihttp.post({
                    method: 3148,
                    parameters: parms || {}
                });
            },
            listBookinggrps: function (parms) {
                return $ihttp.post({
                    method: 3277,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 226,
                    parameters: edit
                });
            },
            savePimDataObj: function (metacolumns) {
                return $ihttp.post({
                    method: 3139,
                    parameters: metacolumns
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 746,
                    parameters: edit
                });
            },
            searchStock: function (edit) {
                return $ihttp.post({
                    method: 455,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
