(function () {
	'use strict';

    angular.module("imApp").factory("powTimePayCheckLsService", ['$ihttp', function ($ihttp) {
		let service = {
            listPayCheckLs: function (parms) {
                return $ihttp.post({
                    method: 1374,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
