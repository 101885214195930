<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.select"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="select"
>
    <div *ngIf="!ttReadonly" style="width: 100%; position: relative">
        <select
            [attr.id]="id.select"
            class="tt-input__form-control tt-input__form-control--select"
            [ngClass]="ttHideArrow ? 'tt-select__hide-arrow' : ''"
            type="text"
            [(ngModel)]="ttModel"
            (ngModelChange)="onModelChanged($event)"
            (blur)="ttOnBlur.emit()"
            [disabled]="ttDisabled"
            [ngStyle]="style['select']"
        >
            <option *ngFor="let item of ttData" [value]="'' + item[ttDataId]">{{ item[ttDataName] }}</option>
        </select>
        <span
            *ngIf="ttInvalid"
            class="tt-input__icon tt-input__icon--invalid fa-fw fas fa-exclamation-circle"
            style="position: absolute; right: 22px; top: 50%; transform: translateY(-50%)"
            [ngStyle]="style['icon']"
        ></span>
        <!-- <span style="position: absolute; right: 0; top: 50%; transform: translateY(-50%); background: transparent" class="tt-input__icon fa-fw fal fa-chevron-down" [ngStyle]="style['icon']"></span> -->
    </div>

    <input
        *ngIf="ttReadonly"
        [attr.id]="id.select"
        class="tt-input__form-control tt-input__form-control--select"
        type="text"
        [value]="_viewValue"
        (blur)="ttOnBlur.emit()"
        [disabled]="ttDisabled"
        [ngStyle]="style['select']"
        readonly
    />
    <ng-container buttons>
        <tt-form-field-button *ngIf="ttShowClearButton" ttId="$clear" ttIcon="fas fa-eraser" ttType="danger" (ttClick)="clearInput()"></tt-form-field-button>
        <tt-form-field-buttons buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
    </ng-container>
</tt-form-field-base>
