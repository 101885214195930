import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Grid } from '@app/core/components/grid/grid.model';
import { GridOptions } from '@app/core/components/grid/grid.types';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
    selector: 'tt-kpi-comp',
    templateUrl: './kpi-comp.component.html',
    styleUrls: ['./kpi-comp.component.css'],
})
export class KpiCompComponent implements OnChanges, OnInit {
    @Input()
    kpiCompKeyno?: string;

    @Output()
    onSave = new EventEmitter<any>();

    get?: any;

    kpiTypes: any[] = [];
    fontSizes: any[] = [];
    webpages: any[] = [];

    kpiCompNameButtons = [];

    kpiCompDescriptionButtons = [];

    saving = false;

    grid: GridOptions = new Grid()
        .setRememberId('kpicomp_usergroup_grid')
        .setLoadDataFunction({ method: 3520, parameters: () => ({ kpicomp_keyno: this.kpiCompKeyno }) })
        .setCSSOptions({ height: '100%', minHeight: '500px' });

    gridReady = false;

    constructor(private datatask: DataTaskService, layout: LayoutService) {
        this.fontSizes = layout.getFontSizes();
    }

    public async save() {
        this.saving = true;

        try {
            await this.datatask.Post(3515, this.get);
            this.onSave.emit(this.get);
        } finally {
            this.saving = false;
        }
    }

    private async loadKpiComp() {
        this.get = (await this.datatask.Post(3514, { kpicomp_keyno: this.kpiCompKeyno }))[0];

        this.gridReady = true;
        this.grid?.gridfunc?.read?.();
    }

    private async loadKpiTypes() {
        this.kpiTypes = await this.datatask.Post(3521);
    }

    private async loadWebpages() {
        this.webpages = await this.datatask.Post(1890);
    }

    ngOnInit(): void {
        this.loadKpiTypes();
        this.loadWebpages();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['kpiCompKeyno']) {
            this.loadKpiComp();
        }
    }
}

// (function () {
//   'use strict';

//   const module = angular.module('imApp');

//   module.component('kpiComp', {
//       templateUrl: 'views/components/views/kpiComp/kpiComp.template.html?v=' + module.version,
//       controllerAs: 'vm',
//       controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'kpiCompService', 'ttGridFactory', 'layoutService', function ($stateParams, stateService, translateService, modalService, kpiCompService, ttGridFactory, layoutService) {

//           // #region VARIABLES & DEFINITIONS

//           const vm = this;
//           let kpiCompKeyno = $stateParams.kpicomp_keyno;

//           let translations = {
//               error: '',
//               ok: ''
//           };

//           vm.update = function () {
//               vm.model.edit = { ...vm.model.edit };
//           }

//           vm.model = {
//               kpiCompKeyno: kpiCompKeyno,
//               gridKpiReady: false,
//               kpiCompNameButtons: [
//                   { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.kpicomp_name_word_id }) }
//               ],
//               kpiCompDescriptionButtons: [
//                   { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.kpicomp_description_word_id }) }
//               ],
//               edit: {},
//               selectListWebpages: [],
//               selectListKpiTypes: [],
//               fontSizes: layoutService.getFontSizes(),
//               lockedSave: false
//           };

//           vm.gridKpi = new ttGridFactory({
//               rememberId: 'kpicomp_usergroup_grid',
//               kendo: {
//                   height: '100%',
//                   sortable: true,
//                   aggregate: true,
//                   pager: false,
//                   selectable: false,
//                   filterable: false
//               }
//           });

//           // #endregion VARIABLES & DEFINITIONS

//           // #region TRANSLATE SERVICE

//           translateService.translateBatch(translations).then(function (data) {
//               angular.forEach(translations, function (_, key) {
//                   if (angular.isDefined(data[key])) {
//                       translations[key] = data[key];
//                   }
//               });
//           });

//           // #endregion TRANSLATE SERVICE

//           // #region LOAD PROCEDURE FUNCTION CALLS

//           let loadEdit = function () {
//               kpiCompService.getEdit({ kpicomp_keyno: kpiCompKeyno }).then(function (info) {
//                   angular.copy(info[0], vm.model.edit);

//                   loadGridKpi();
//               });
//           };

//           let loadWebpages = function () {
//               kpiCompService.listWebpages().then(function (list) {
//                   angular.copy(list, vm.model.selectListWebpages);
//               });
//           };

//           async function loadKpiTypes() {
//               vm.model.selectListKpiTypes = (await kpiCompService.listKpiTypes());
//           };

//           // #endregion LOAD PROCEDURE FUNCTION CALLS

//           // #region GRID PROCEDURE FUNCTION CALLS

//           async function loadGridKpi() {
//               vm.gridKpi.dataTask.loadData = {
//                   method: 3520,
//                   parameters: { kpicomp_keyno: vm.model.edit.kpicomp_keyno }
//               };

//               vm.model.gridKpiReady = true;

//               if (vm.gridKpi.gridfunc !== null) vm.gridKpi.gridfunc.read();
//           }

//           // #endregion GRID PROCEDURE FUNCTION CALLS

//           // #region BUTTON SAVE PROCEDURE FUNCTION CALL

//           vm.saveChanges = function () {
//               vm.model.lockedSave = true;

//               kpiCompService.saveObj(vm.model.edit).then(function (response) {
//                   if (response[0].errorcode !== '0') {
//                       modalService.show({
//                           type: 'warning',
//                           title: translations.error,
//                           message: response[0].errormessage,
//                           buttons: [{
//                               label: translations.ok,
//                               cssClass: 'btn-warning',
//                               action: function (dialogItself) {
//                                   dialogItself.close();
//                                   vm.model.lockedSave = false;
//                               }
//                           }]
//                       });
//                   } else {
//                       vm.model.lockedSave = false;
//                       stateService.back();
//                   }
//               });
//           };

//           // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

//           // #region ON INIT FUNCTION

//           vm.$onInit = function () {
//               loadEdit();
//               loadWebpages();
//               loadKpiTypes();
//           };

//           // #endregion ON INIT FUNCTION

//       }]
//   });
// })();
