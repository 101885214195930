(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('powTimePayCheckLs', {
        templateUrl: 'views/components/views/powTimePayCheckLs/powTimePayCheckLs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'powTimePayCheckLsService', 'ttGridFactory', 'layoutService', function ($stateParams, powTimePayCheckLsService, ttGridFactory, layoutService) {

    		// #region VARIABLES & DEFINITIONS

            const vm = this;
            let desktopSize = layoutService.getMediaQueries().desktop;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                desktopSize: desktopSize,
                itemsListPayCheckLs: [],
                gridReady: false
    		};

            vm.grid = new ttGridFactory({
                rememberId: 'powtimepaycheckls_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

    		// #region LOAD PROCEDURE CALL

            let loadPayCheckLs = function () {
                powTimePayCheckLsService.listPayCheckLs({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListPayCheckLs);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3507,
                    parameters: {
                        argtype: argType,
                        argvalue: argValue
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadPayCheckLs();
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
