import { Injectable } from '@angular/core';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';

@Injectable({
    providedIn: 'root',
})
export class LogisticService {
    constructor(private datatask: DataTaskService) {}

    /**
     * Retrieves a list of stockplaces for the given business-co, on the given businessco-site.
     *
     * @param params the settings for the stockplaces call.
     * @returns a promise containing a list of stockplaces.
     */
    public getStockplaces(params?: Partial<StockplaceParams>): Promise<StockplaceItem[]> {
        return this.datatask.Post(622, { businessco_no: params?.businessco_no, businesssite_keyno: params?.businesssite_keyno, add_all: params?.add_all || '' });
    }

    /**
     * Retrieves list of businesses and / or companies.
     *
     * @param add_all whether an option for selecting all businesscos should be included in the list.
     * @returns list of businesses and / or companies.
     */
    public getCompanies(add_all: '1' | '0' = '0'): Promise<BusinesscoItem[]> {
        return this.datatask.Post(104, { add_all: add_all });
    }
}

/**
 * Represents parameters used for retrieving list of stockplaces.
 */
export interface StockplaceParams {
    /**
     * The businessco to filter the stockplaces list with, if undefined all stockplaces are retrieved.
     */
    businessco_no?: string;

    /**
     * The businessco site to filter the stockplaces list with, if undefined all stockplaces across all sites are retrieved.
     */
    businesssite_keyno?: string;

    /**
     * Whether an item for viewing all stockplaces should be included in the stockplaces list.
     */
    add_all?: 'ALL' | '1' | '0' | '';
}

/**
 * Represents a stockplace item.
 */
export interface StockplaceItem extends Item {
    /**
     * The id of the stockplace.
     */
    stockplace_id: string;

    /**
     * The displayed name of the stockplace.
     */
    stockplace_name: string;

    /**
     * the order place of this stockplace item.
     */
    orderby: string;
}

/**
 * Represents a business or company item.
 */
export interface BusinesscoItem {
    /**
     * The id of the businessco.
     */
    businessco_no: string;

    /**
     * The name of the businessco.
     */
    company_name: string;

    /**
     * The order plave of this businessco.
     */
    orderby: string;
}
