import { Injectable } from '@angular/core';
import { RememberService } from './remember.service';
import { Subject, debounceTime, BehaviorSubject } from 'rxjs';

/**
 * Service for streamlining popup handling.
 */
@Injectable({
    providedIn: 'root',
})
export class PopupService {
    /**
     * Observable used for delaying saving of the popup window size options.
     */
    private remember$ = new Subject<PopupWindowOptions>();

    /**
     * List over popup windows opened in the current browser session.
     */
    private popups: Window[] = [];

    constructor(private remember: RememberService) {
        this.remember$.pipe(debounceTime(500)).subscribe((options: PopupWindowOptions) => this.rememberPopupWindowSize(options));
    }

    /**
     * Opens a popup using the given link. Returns `null` if a popup could not be opened.
     *
     * @param link the link to open in the popup window.
     * @param options the popup-window configuration options.
     * @returns a promise containing a reference to the opened popup window.
     */
    public async openPopup(link: string, options: Partial<PopupWindowOptions> = { height: 720, width: 1100, x: 150, y: 50 }): Promise<{ popup: Window; closed: BehaviorSubject<boolean> } | null> {
        options = { ...options, ...(await this.getPreviousPopupWindowSize(options.rememberId)) };

        const popup = window.open(`/#/${link}?ispopup`, crypto.randomUUID(), 'popup,width=' + options.width + ',height=' + options.height + ',screenX=' + options.x + ',screenY=' + options.y);

        if (!popup) return null;

        popup.onload = () => (popup.onbeforeunload = (event) => this.onPopupResize(event.currentTarget as Window, options.rememberId));
        popup.onresize = (event: Event) => this.onPopupResize(event.target as Window, options.rememberId);

        this.popups.push(popup);

        const closed = new BehaviorSubject(false);

        const closedInterval = setInterval(() => {
            if (popup.closed) {
                closed.next(true);
                clearInterval(closedInterval);
            }
        }, 750);

        return {
            popup: popup,
            closed: closed,
        };
    }

    /**
     * Handles resizing and unloading of the popup-window.
     *
     * @param window the popup window that was resized or unloaded.
     * @param rememberId the id to use to remember the last configured popup-window options.
     */
    private onPopupResize(window: Window, rememberId?: string) {
        if (!!rememberId && !!window) {
            const width = window.innerWidth;
            const height = window.innerHeight;
            this.remember$.next({ rememberId: rememberId, width: width, height: height, x: window.screenX, y: window.screenY });
            // this.rememberPopupWindowSize({ rememberId: rememberId, width: width, height: height, x: window.screenX, y: window.screenY });
        }
    }

    /**
     * Remembers the given popup-window options object if all properties are valid.
     *
     * @param options the popup-window options to remember.
     */
    private rememberPopupWindowSize(options: PopupWindowOptions): void {
        if (!!options.rememberId && this.isValidCoordinate(options.width) && this.isValidCoordinate(options.height) && this.isValidCoordinate(options.x) && this.isValidCoordinate(options.y)) {
            this.remember.remember(this.getRememberId(options.rememberId), { width: options.width, height: options.height, x: options.x, y: options.y });
        }
    }

    /**
     * Retrieves the previous size of the popup-window of the context of the given remember id.
     *
     * @param rememberId the remember id to retrieve last size of popup of.
     * @returns a promise containing the last popup-window options or `undefined` if remember id was not provided.
     */
    private async getPreviousPopupWindowSize(rememberId?: string): Promise<PopupWindowOptions | undefined> {
        if (!!rememberId) {
            const response = await this.remember.getLastStatus(this.getRememberId(rememberId), true);
            return response;
        }

        return;
    }

    /**
     * Checks whether the given coordinate is valid by being a number without being NaN and being greater than 0.
     *
     * @param coordinate the coordinate to check.
     * @returns `true` if the given coordinate is valid, `false` if not.
     */
    private isValidCoordinate(coordinate: string | number): boolean {
        return !isNaN(+coordinate) && Number(coordinate) >= 0;
    }

    /**
     * Creates a remember id for the popupwindow based on the current screensize and the provided remember id.
     *
     * @param rememberId the remember id to create a screen-unique id for.
     * @returns remember id unique for the screen size.
     */
    private getRememberId(rememberId: string): string {
        return `${rememberId}.${screen.width}.${screen.height}`;
    }

    /**
     * Closes all popups.
     *
     * **NB. If the page has been refreshed the link to the popups in nulled and thus won't close**.
     */
    public closeAllPopups() {
        this.popups.forEach((popup) => popup.close());
    }
}

/**
 * Represents configuration options of a popup-window opened through the `PopupService`.
 */
export interface PopupWindowOptions {
    /**
     * The width of the popup to opened.
     */
    width: number;

    /**
     * The height of the popup to be opened.
     */
    height: number;

    /**
     * The x coordinate of the popup to opened.
     */
    x: number;

    /**
     * The y coordinate of the popup to be opened.
     */
    y: number;

    /**
     * Id to use for remembering the coordinates and size of popup. If provided the last stored value will take precedence over coordinates and size provided in this options object.
     */
    rememberId?: string;
}
