<div *ngIf="mconfig?.show === true" class="well well-sm col-xs-12">
    <div *ngIf="model.ltype !== 'default'" class="col-xs-1 sp-0">
        <div [ngClass]="addMenuGlyph('switch')" type="button" (click)="goSwitch('switch')"></div>
    </div>
    <div *ngIf="model.ltype === 'default'" class="col-xs-1 sp-0"></div>

    <div *ngIf="!mconfig?.records" class="col-xs-11 sp-0">
        <div [ngClass]="addMenuGlyph('sort')" type="button" (click)="goSwitch('sort')"></div>
        <div [ngClass]="addMenuGlyph('resize')" type="button" (click)="goSwitch('resize')"></div>
        <div [ngClass]="addMenuGlyph('columns')" type="button" (click)="goSwitch('columns')"></div>
        <div [ngClass]="addMenuGlyph('edit')" type="button" (click)="goSwitch('edit')"></div>
    </div>

    <div *ngIf="mconfig?.records?.length > 0" class="col-xs-11 sp-0">
        <div *ngFor="let mitem of (mconfig.records | reverse : model.disableFilter)">
            <div *ngIf="mitem.show === undefined || mitem.show === true"
                 [ngClass]="addMenuGlyph(mitem)"
                 type="button"
                 (click)="goSwitch(mitem)"></div>
        </div>
    </div>
</div>

<div *ngIf="model.resizeMode === true" class="well well-sm col-xs-12" [ngStyle]="{'paddingRight': '.2em'}">
    <div [ngClass]="addColArea('leftAreaSlider')"></div>
    <div [ngClass]="addColArea('centerAreaSlider')">
        <nouislider class="sp-0" [config]="slider.options" (ngModelChange)="slider.events.slide($event)"></nouislider>
    </div>
    <div [ngClass]="addColArea('rightAreaSlider')"></div>
</div>

<div class="col-xs-12" style="padding: 0;">
    <!-- Non-sortable, not in sortMode -->
    <div *ngFor="let item of (listItems | reverse : model.disableFilter | filterList : model.filterVal : filterParm); let $index = index">
        <ng-container *ngIf="ltype !== 'sortable' && model.sortMode === false">
            <!-- BACKITEM === 'true' & default -->
            <div *ngIf="backitem === 'true' && model.ltype === 'default'" class="col-xs-12 im-no-pad">
                <button [attr.data-testid]="'item_button_' + item.item_id"
                        (click)="goBack(item)"
                        type="button"
                        class="btn btn-default listview btn-block btn-lg col-xs-12"
                        [ngStyle]="{'fontSize': fontSizes.textSize}">
                    <div class="glyphicon glyphicon-chevron-left grayish pull-left" style="padding: 0; text-align:left; color:#B8B8B8"></div>
                    <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                         class="pull-left"
                         [ngStyle]="addStyle(gitem)"
                         [ngClass]="addGlyph(gitem)"></div>
                    <div class="col-xs-11 listview_text" [ngStyle]="model.listViewTextStyle">
                        {{ item.item_name }}
                        <span *ngIf="badge !== undefined && badge !== ''"
                              class="spin-text"
                              [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                        <span class="badge" [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                            {{ badge ? item[badge] : '' }}
                        </span>
                    </div>
                </button>
                <button *ngIf="((!designVar) || (designVar ?? '').indexOf('t') > -1) && (ttHideGoto !== true)"
                        [attr.data-testid]="'item_button_' + item.item_id + '_newtab'"
                        (click)="goTab(item)"
                        [ngStyle]="model.listViewTextStyle"
                        type="button"
                        class="fas fa-window-restore pull-right im-newtab">
                    <span *ngIf="!designVar || (designVar ?? '').indexOf('t0') <= -1"
                          class="im-tooltiptext"
                          im-translate="new_tab"></span>
                </button>
            </div>

            <!-- backitem != 'true' & default -->
            <div *ngIf="backitem !== 'true' && model.ltype === 'default'"
                 class="col-xs-12 im-no-pad">
                <button style="padding: 0; width: 100%;"
                        [attr.data-testid]="'item_button_' + item.item_id"
                        (click)="goTo(item)"
                        type="button"
                        class="btn btn-default listview btn-block btn-lg col-xs-12 im-list-btn--flex"
                        [ngStyle]="{'fontSize': (itemSize === 'lg' ? fontSizes.textSizeXL : fontSizes.textSize )}">
                    <img *ngIf="item.item_thumb !== undefined && item.item_thumb !== null && item.item_thumb.length > 0"
                         style="margin-left: 9px"
                         [src]="item.item_thumb + '?thumbnail=' + fontSizes.thumbnail"
                         [ngStyle]="model.thumbnailStyle" />
                    <div class="im-list-btn--flex"
                         style="z-index: 1; padding: .5em .2em .5em .2em; width: 100%"
                         [ngStyle]="{'padding': (itemSize === 'lg' ? '1.2em 0.2em' : '0.5em 0.2em')}">
                        <!-- HideIcon != true & useDocIcon != 'true' => glyph loop -->
                        <ng-container *ngIf="hideIcon !== true && useDocIcon !== 'true'">
                            <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                                 style="z-index: 1;"
                                 class="pull-left"
                                 [ngStyle]="addStyle(gitem)"
                                 [ngClass]="addGlyph(gitem)"></div>
                        </ng-container>
                        <!-- docIcon? -->
                        <img *ngIf="hideIcon !== true && useDocIcon === 'true'"
                             class="pull-left"
                             [src]="model.appRoot + '/images/icons/32px/' + item.extension + '.png'"
                             style="margin:auto; padding-left: 5px; margin-top: -6px; height: 32px;" />

                        <div class="listview_text" [ngStyle]="model.listViewTextStyle" style="width: 100%;">
                            <div class="col-xs-11 im-no-pad" [ngStyle]="{'overflow': 'inherit'}">
                                {{item.item_name}}
                                <span *ngIf="badge"
                                      class="spin-text"
                                      [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                                <span class="badge im-list-badge"
                                      [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                    {{ badge ? item[badge] : '' }}
                                </span>
                                <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                                     class="col-xs-12 im-no-pad"
                                     [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                                    {{ item.item_name_sub1 }}
                                </div>
                                <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                                     class="col-xs-12 im-no-pad"
                                     style="color:#909090;"
                                     [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                    {{ item.item_name_sub2 }}
                                </div>
                            </div>
                            <div class="col-xs-1 im-no-pad">
                                <!-- delete button if editmode -->
                                <div class="col-xs-12 sp-0"
                                     *ngIf="(ttHideGoto || false) !== true">
                                    <div *ngIf="(ttEditmode || false) === true"
                                         (click)="onDeleteItem(item)"
                                         [ngStyle]="model.deleteButtonStyle"
                                         type="button"
                                         class="btn btn-danger btn-sm glyphicon glyphicon-remove pull-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
                <!-- newTab -->
                <button *ngIf="((!designVar) || (designVar ?? '').indexOf('t') > -1) && item.item_state !== 'customaction' && ttClickable === true && (ttHideGoto || false) !== true"
                        [attr.data-testid]="'item_button_' + item.item_id + '_newtab'"
                        (click)="goTab(item)"
                        [ngStyle]="model.listViewTextStyle"
                        type="button"
                        class="fas fa-window-restore pull-right im-newtab">
                    <span *ngIf="(!designVar) || (designVar ?? '').indexOf('t0') <= -1"
                          class="im-tooltiptext"
                          im-translate="new_tab"></span>
                </button>
            </div>

            <!-- ltype === 'check' & item_id != '0' and '1' -->
            <div *ngIf="ltype === 'check' && item.item_id !== '0' && item.item_id !== '-1'" class="col-xs-12 im-no-pad">
                <button (click)="goCheck(item)"
                        type="button"
                        class="btn btn-default listview btn-block btn-lg col-xs-12"
                        [ngStyle]="{'fontSize': fontSizes.textSize}">
                    <ng-container *ngIf="item.item_is_selected !== '1'">
                        <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <ng-container *ngIf="item.item_is_selected === '1'">
                        <div *ngFor="let gitem of (model.glyphlist2 | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <div class="listview_text" [ngStyle]="model.listViewTextStyle">
                        <div class="col-xs-11 im-no-pad" [ngStyle]="{'overflow': 'inherit'}">
                            {{item.item_name}}
                            <span *ngIf="badge"
                                  class="spin-text"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                            <span class="badge im-list-badge"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ badge ? item[badge] : '' }}
                            </span>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                                 class="col-xs-12 im-no-pad"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                                {{ item.item_name_sub1 }}
                            </div>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                                 class="col-xs-12 im-no-pad"
                                 style="color:#909090;"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ item.item_name_sub2 }}
                            </div>
                        </div>
                    </div>
                </button>
            </div>

            <!-- ltype === 'mark' ... -->
            <div *ngIf="ltype === 'mark' && item.item_id !== '0' && item.item_id !== '-1'" class="col-xs-12 im-no-pad">
                <button (click)="goMark(item)"
                        type="button"
                        class="btn btn-default listview btn-block btn-lg col-xs-12"
                        [ngClass]="addMark(item)"
                        [ngStyle]="{'fontSize': fontSizes.textSize}">
                    <ng-container *ngIf="item.item_is_selected !== '1'">
                        <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <ng-container *ngIf="item.item_is_selected === '1'">
                        <div *ngFor="let gitem of (model.glyphlist2 | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <div class="listview_text" [ngStyle]="model.listViewTextStyle">
                        <div class="col-xs-11 im-no-pad" [ngStyle]="{'overflow': 'inherit'}">
                            {{ item.item_name }}
                            <span *ngIf="badge"
                                  class="spin-text"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                            <span class="badge im-list-badge"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ badge ? item[badge] : '' }}
                            </span>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                                 class="col-xs-12 im-no-pad"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                                {{ item.item_name_sub1 }}
                            </div>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                                 class="col-xs-12 im-no-pad"
                                 style="color:#909090;"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ item.item_name_sub2 }}
                            </div>
                        </div>
                    </div>
                </button>
            </div>

            <!-- ltype === 'multi' & sortMode === false -->
            <div *ngIf="ltype === 'multi' && model.sortMode === false" class="col-xs-12 im-ct im-no-pad">
                <button (click)="goCustom(item, $event)"
                        type="button"
                        [ngClass]="removeHover(item)"
                        class="btn btn-default listview btn-block btn-lg col-xs-12"
                        [ngStyle]="addStyleMultiListBtn(item)">
                    <div [ngClass]="addColArea('leftArea')">
                        <div *ngIf="(item.item_id === '0' && mconfig?.enabled === true && mconfig.show === false)"
                             [ngClass]="addGlyph('switch')"
                             type="button"
                             (click)="goSwitch('switch')"></div>
                        <div *ngIf="(mconfig === undefined || mconfig.enabled === false || mconfig.show === true || item.item_id !== '0')">
                            <ng-container *ngIf="item.item_is_selected !== '1'">
                                <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                                     class="pull-left"
                                     [ngStyle]="addStyle(gitem)"
                                     [ngClass]="addGlyph(gitem)"></div>
                            </ng-container>
                            <ng-container *ngIf="item.item_is_selected === '1'">
                                <div *ngFor="let gitem of (model.glyphlist2 | filterGlyph : item)"
                                     class="pull-left"
                                     [ngStyle]="addStyle(gitem)"
                                     [ngClass]="addGlyph(gitem)"></div>
                            </ng-container>
                        </div>
                    </div>

                    <div [ngClass]="addColArea('centerArea')">
                        <div [ngClass]="addColItem('main')" [ngStyle]="addStyleCenterAreaItem(item)">
                            <div *ngIf="item.item_id === '0' || item.item_id === '-1'" class="multi-list-col-text">
                                <b>{{ item.item_name }}</b>
                            </div>
                            <div *ngIf="item.item_id !== '0' && item.item_id !== '-1'" class="multi-list-col-text">
                                <div [hidden]="thisFocus(item, 'imp', '{{item.item_id}}') === true">
                                    {{ item.item_name }}
                                </div>
                                <input [hidden]="!thisFocus(item, 'imp', '{{item.item_id}}')"
                                       [id]="item.item_id + '-imp-' + item.item_id + '-' + model.mId"
                                       [value]="item.item_name"
                                       (focus)="inputFocus($event)"
                                       (blur)="inputBlur($event)"
                                       [ngStyle]="addInputStyle()" />
                            </div>
                            <span *ngIf="badge" class="spin-text" [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                            <span *ngIf="badge" class="badge im-list-badge" [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ item[badge] }}
                            </span>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1 && model.inlineEditMode === false"
                                 class="col-xs-12 im-no-pad"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                                {{ item.item_name_sub1 }}
                            </div>
                        </div>

                        <div *ngFor="let sitem of (model.secondary | reverse : model.disableFilter | filterSecondList : item )" [ngClass]="addColItem(sitem)" [ngStyle]="addStyleCenterAreaItem(sitem, item)">
                            <div *ngIf="item.item_id === '0'" class="multi-list-col-text">
                                <b>{{ sitem.item_col }}</b>
                            </div>
                            <div *ngIf="item.item_id !== '0'" class="multi-list-col-text">
                                <div [hidden]="thisFocus(item, 'ims', (item.item_secondary.length - $index - 1)) === true">
                                    {{ sitem.item_col }}
                                </div>
                                <input [hidden]="!thisFocus(item, 'ims', (item.item_secondary.length - $index - 1))"
                                       [id]="(item.item_secondary.length - $index - 1) + '-ims-' + item.item_id + '-' + model.mId"
                                       [value]="sitem.item_col"
                                       (focus)="inputFocus($event)"
                                       (blur)="inputBlur($event)"
                                       [ngStyle]="addInputStyle()" />
                            </div>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1 && model.inlineEditMode === false"
                                 class="col-xs-12 im-no-pad"
                                 style="color:#909090;"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ item.item_name_sub2 }}
                            </div>
                        </div>
                    </div>

                    <div [ngClass]="addColArea('rightArea', item)"></div>
                </button>
            </div>

            <!-- ltype === 'custom' -->
            <div *ngIf="ltype === 'custom'" class="col-xs-12 im-no-pad">
                <button (click)="goCustom(item)"
                        type="button"
                        class="btn btn-default listview btn-block btn-lg col-xs-12"
                        [ngStyle]="{'fontSize': fontSizes.textSize}">
                    <div *ngIf="backitem === 'true'"
                         class="glyphicon glyphicon-chevron-left grayish pull-left"
                         style="padding: 0; text-align:left; color:#B8B8B8"></div>
                    <ng-container *ngIf="item.item_is_selected !== '1'">
                        <div *ngFor="let gitem of (model.glyphlist | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <ng-container *ngIf="item.item_is_selected === '1'">
                        <div *ngFor="let gitem of (model.glyphlist2 | filterGlyph : item)"
                             class="pull-left"
                             [ngStyle]="addStyle(gitem)"
                             [ngClass]="addGlyph(gitem)"></div>
                    </ng-container>
                    <div class="listview_text" [ngStyle]="model.listViewTextStyle">
                        <div class="col-xs-11 im-no-pad" [ngStyle]="{'overflow': 'inherit'}">
                            {{ item.item_name }}
                            <span *ngIf="badge"
                                  class="spin-text"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}"> </span>
                            <span *ngIf="badge"
                                  class="badge im-list-badge"
                                  [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ badge ? item[badge] : '' }}
                            </span>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                                 class="col-xs-12 im-no-pad"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                                {{ item.item_name_sub1 }}
                            </div>
                            <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                                 class="col-xs-12 im-no-pad"
                                 style="color:#909090;"
                                 [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                                {{ item.item_name_sub2 }}
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </ng-container>
    </div>

    <!-- Sortable mode -->
    <div [id]="model.mId"
         *ngIf="ltype === 'sortable'"
         class="col-xs-12 im-no-pad">
        <div *ngFor="let group of model.sortlist">
            <div ui-sortable="sortableOptions"
                 [ngClass]="addClass()"
                 [ngStyle]="addStyleWell(group)"
                 [(ngModel)]="group.items">
                <div class="pull-left name-label sortable__label col-xs-1-5 im-sort-pad"
                     [ngStyle]="addStyleSortlist(group)"
                     [ngClass]="addGlyph(group)"></div>
                <label class="name-label sortable__label col-xs-10-5 im-sort-pad"
                       [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                    {{ group.value }}
                </label>
                <div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12"
                     *ngFor="let item of group.items"
                     (click)="goSort(item)"
                     [ngStyle]="{'fontSize': fontSizes.textSize}">
                    {{ item.item_name }}
                    <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                         class="col-xs-12 im-no-pad"
                         [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                        {{ item.item_name_sub1 }}
                    </div>
                    <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                         class="col-xs-12 im-no-pad"
                         style="color:#909090;"
                         [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                        {{ item.item_name_sub2 }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ltype === 'multi' or default, but sortMode === true -->
    <div [id]="model.mId"
         *ngIf="(ltype === 'multi' || model.ltype === 'default') && model.sortMode === true"
         class="col-xs-12 im-no-pad">
        <div ui-sortable="sortableOptions"
             [ngClass]="addClass()"
             [ngStyle]="addStyleWell()"
             [(ngModel)]="listItems">
            <div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12"
                 *ngFor="let item of listItems"
                 (click)="goSort(item)"
                 [ngStyle]="{'fontSize': fontSizes.textSize}"
                 [hidden]="item.item_type === 'info'">
                {{ item.item_name }}
                <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('1') > -1"
                     class="col-xs-12 im-no-pad"
                     [ngStyle]="{'fontSize': fontSizes.textSizeS}">
                    {{ item.item_name_sub1 }}
                </div>
                <div *ngIf="(designVar ?? '').indexOf('s') > -1 && (designVar ?? '').indexOf('2') > -1"
                     class="col-xs-12 im-no-pad"
                     style="color:#909090;"
                     [ngStyle]="{'fontSize': fontSizes.textSizeSs}">
                    {{ item.item_name_sub2 }}
                </div>
            </div>
        </div>
    </div>
</div>
