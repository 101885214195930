(function () {
    'use strict';

    angular.module("imApp").factory("p2ExportProcUserGroupsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1892,
                    parameters: parms || {}
                });
            },
            listExportProcUserGroups: function (parms) {
                return $ihttp.post({
                    method: 1893,
                    parameters: parms || {}
                });
            },
            saveObj: function (list) {
                return $ihttp.post({
                    method: 1894,
                    parameters: list
                });
            }
        };

        return service;
    }]);
})();
