<div class="tt-bottom-sheet__header" [ngClass]="anchorState === 'top' ? 'tt-bottom-sheet__header--show' : 'tt-bottom-sheet__header--hidden'">
    <button type="button" class="tt-bottom-sheet__close-button" (click)="closeExpandedSheet()">
        <span class="fa-fw far fa-chevron-down"></span>
    </button>
</div>
<div #outerSheet class="tt-bottom-sheet__outer" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)" [ngStyle]="outerSheetStyle">
    <div #containerSheet class="tt-bottom-sheet__container">
        <button class="tt-bottom-sheet__expand" type="button"></button>
        <div #contentDiv class="tt-bottom-sheet__content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
<!-- 
<div class="tt-bottom-sheet__header" ng-class="model.activeAnchor === 'expanded' ? 'tt-bottom-sheet__header--show' : 'tt-bottom-sheet__header--hidden'">
    <button type="button" class="tt-bottom-sheet__close-button" ng-click="closeExpandedSheet()">
        <span class="fa-fw far fa-chevron-down"></span>
    </button>
</div>
<div id="{{id.outer}}" class="tt-bottom-sheet__outer" ng-on-touchstart="onTouchStart($event)" ng-on-touchmove="onTouchMove($event)" ng-on-touchend="onTouchEnd($event)" ng-style="style.sheet">
    <div id="{{id.container}}" class="tt-bottom-sheet__container">
        <button class="tt-bottom-sheet__expand" type="button"></button>
        <div class="tt-bottom-sheet__content" ng-class="{ 'tt-bottom-sheet__content--expanded': model.activeAnchor === 'expanded' }" ng-transclude></div>
    </div>
</div> -->
