import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { GotoParms, StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { UtilityService } from '@app/core/services/utility.service';
import { ttOverflowMenuOptions } from '../button-multi/button-multi.component';
import { OverflowMenuComponent } from '../overflow-menu/overflow-menu.component';

@Component({
    selector: 'tt-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnChanges {
    @Input()
    ttId: string = '';

    /**
     * The type of the toast being displayed. `'primary'` indicates information.
     *
     * @default 'primary'
     */
    @Input()
    public ttType: ToastType = 'primary';

    /**
     * The title text or translate-word to display in the toast.
     */
    @Input()
    public ttTitle: string = '';

    /**
     * The title text prop connected to the template.
     */
    public title = '';

    /**
     * Whether the title of the toast should be translated.
     *
     * @default true
     */
    @Input()
    public set ttTranslateTitle(value: BooleanInput) {
        this._translateTitle = coerceBooleanProperty(value);
    }
    public get ttTranslateTitle(): boolean {
        return this._translateTitle;
    }
    private _translateTitle = true;

    /**
     * The message text or translate-word to display in the toast, if any.
     */
    @Input()
    public ttMessage: string = '';

    /**
     * The message prop connected to template.
     */
    public message = '';

    /**
     * Whether the message displayed in the toast should be translated.
     *
     * @default true
     */
    @Input()
    public set ttTranslateMessage(value: BooleanInput) {
        this._translateMessage = coerceBooleanProperty(value);
    }
    public get ttTranslateMessage(): boolean {
        return this._translateMessage;
    }
    private _translateMessage = true;

    /**
     * The state to go to when the toast gets clicked if any. If ttClick event is listened to, it will take precedence over goto.
     */
    @Input()
    public ttGoto?: string;

    /**
     * The params to use for the goto state.
     */
    @Input()
    public ttGotoParms?: GotoParms<any>;

    @Input()
    public ttOverflow?: ttOverflowMenuOptions;

    @ContentChild(OverflowMenuComponent)
    public contentOverflowMenu?: OverflowMenuComponent;

    @ViewChild(OverflowMenuComponent)
    public viewOverflowMenu?: OverflowMenuComponent;

    /**
     * Event emitted when the toast title is clicked. Adding an eventlistener will style the toast to look like it has a link.
     * Click event will take precedence over goto.
     */
    @Output()
    public ttClick = new EventEmitter<{ event: MouseEvent; toast: ToastComponent }>();

    /**
     * Event emitted when the mouse enter the toast element.
     */
    @Output()
    public ttMouseEnter = new EventEmitter<ToastComponent>();

    /**
     * Event emitted when the mouse leaves the toast element.
     */
    @Output()
    public ttMouseLeave = new EventEmitter<ToastComponent>();

    /**
     * Event emitted when the toast has been dismissed.
     */
    @Output()
    public ttClose = new EventEmitter<ToastComponent>();

    constructor(private translate: TranslateService, public state: StateService, public utility: UtilityService) {}

    /**
     * Redirects the user to the goto state of this toast.
     *
     * @param event the mouse event on the anchor.
     */
    public goto(event: MouseEvent) {
        event.preventDefault();

        if (!!this.ttGoto) {
            if (this.utility.isModifierKeyPressed(event)) {
                this.state.newTab(this.ttGoto, this.ttGotoParms);
            } else {
                this.state.go(this.ttGoto, this.ttGotoParms);
            }
        }
    }

    /**
     * Sets the title of the toast.
     */
    private async setTitle() {
        if (!!this.ttTitle) {
            if (this.ttTranslateTitle) {
                this.title = await this.translate.translate(this.ttTitle);
            } else {
                this.title = this.ttTitle;
            }
        } else {
            this.title = '';
        }
    }

    /**
     * Sets the message of the toast.
     */
    private async setMessage() {
        if (!!this.ttMessage) {
            if (this.ttTranslateMessage) {
                this.message = await this.translate.translate(this.ttMessage);
            } else {
                this.message = this.ttMessage;
            }
        } else {
            this.message = '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttTranslateTitle']) {
            this.ttTranslateTitle = changes['ttTranslateTitle'].currentValue;
        }

        if (changes['ttTranslateMessage']) {
            this.ttTranslateMessage = changes['ttTranslateMessage'].currentValue;
        }

        if (changes['ttTitle']) {
            this.setTitle();
        }

        if (changes['ttMessage']) {
            this.setMessage();
        }
    }
}

/**
 * Represents the different types of toast. `'primary'` signify information.
 */
export type ToastType = 'primary' | 'success' | 'warning' | 'danger';
