(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("furnitureFabricsService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1182,
                    parameters: parms || {}
                });
            },
            loadProductChoiceList: function () {
                return p2DataTaskService.call(1159, {});
            },
            loadFabricsGrpList: function () {
                return p2DataTaskService.call(1240, {});
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1217,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
