(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productBom', {
        templateUrl: 'views/components/views/productBom/productBom.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'productBomService', 'ttGridFactory', 'fileService', function ($stateParams, stateService, utilityService, translateService, modalService, productBomService, ttGridFactory, fileService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                prodId: $stateParams.prod_id,
                bgProductBomList: {
                    selected: 'COMPONENT',
                    buttons: [
                        { id: 'COMPONENT', label: 'gb_productbom_list_component', color: 'primary', onClick: () => setProductBomListView('COMPONENT') },
                        { id: 'WORK', label: 'gb_productbom_list_work', color: 'primary', onClick: () => setProductBomListView('WORK') },
                        { id: 'OTHER', label: 'gb_productbom_list_other', color: 'primary', onClick: () => setProductBomListView('OTHER') },
                        { id: 'DOCUMENTS', label: 'documents_documents', color: 'primary', onClick: () => setProductBomListView('DOCUMENTS') },
                        { id: 'PICTURES', label: 'documents_pictures', color: 'primary', onClick: () => setProductBomListView('PICTURES') },
                        { id: 'VIDEOS', label: 'documents_videos', color: 'primary', onClick: () => setProductBomListView('VIDEOS') }
                    ]
                },
                bgProductBomOther: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_productbom_other_picture', item_func: 'PICTURE' },
                        { item_id: '1', item_name: 'gb_productbom_other_includedin', item_func: 'INCLUDEDIN' }
                    ]
                },
                productSelectedButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => vm.goToFunc('product') }
                ],
                setting: {},
                listProductBom: [],
                listIncludedIn: [],
                selectListAssemblyGroups: [],
                itemsListOwns: [],
                selectedProdBom: null,
                gridReadyComponents: false,
                gridReady: false,
                checkingError: false,
                documents: [],
                pictures: [],
                videos: []
            };

            vm.style = {
                container: {},
                img: {},
                placeholder: {}
            }

            //vm.gridComponents = new ttGridFactory({
            //    rememberId: '',
            //    kendo: {
            //        height: null,
            //        sortable: true,
            //        aggregate: true,
            //        pager: false,
            //        selectable: false,
            //        filterable: false
            //    }
            //});

            //vm.grid = new ttGridFactory({
            //    rememberId: '',
            //    kendo: {
            //        height: null,
            //        sortable: true,
            //        aggregate: true,
            //        pager: false,
            //        selectable: false,
            //        filterable: false
            //    }
            //});
            
            vm.gridComponents = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'components_add',
                                text: 'components_add',
                                func: function () {
                                    stateService.go('component', {
                                        prod_id: vm.model.setting.prod_keyno_selected,
                                        comp_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ],
                        toggles: [
                            {
                                name: 'test',
                                text: 'test',
                                state: 0,
                                states: [
                                    { text: 'bom_verified_unchecked', func: function () { bomVerificationCheck(0) }, icon: 'fal fa-square' },
                                    { text: 'bom_verified_checked', func: function () { bomVerificationCheck(1) }, icon: 'fal fa-check-square' },
                                ],
                                cssClass: 'tt-grid-btn-xs tt-button--primary',
                                icon: 'fal fa-ballot-check',
                                disabled: function () { return false; }
                            }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        //minHeight: '500px',
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '700px',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'productbom_work_new',
                                text: 'productbom_work_new',
                                func: function () {
                                    stateService.go('work', {
                                        prod_id: vm.model.setting.prod_keyno_selected,
                                        work_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        //minHeight: '500px',
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '700px',
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple, row',
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');

                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            const bomVerificationCheck = function (state) {
                vm.model.selectedProdBom.bom_verified = state;

                productBomService.bomVerificationCheck({ prod_id: vm.model.setting.prod_id_selected, bom_verified: state }).then(function (response) {
                    //console.dir(response);
                });
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProductBomListView = function (id) {
                vm.model.bgProductBomList.selected = vm.model.setting.gb_productbom_list = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON GROUP FUNCTIONS

            vm.selectBgProductBomList = function (item) {
                vm.model.bgProductBomList.selected = item;
                vm.model.setting.gb_productbom_list = vm.model.bgProductBomList.selected;

                if (utilityService.validateParmsValue(vm.model.setting.prod_id_selected) === true && vm.model.bgProductBomList.selected === 'COMPONENT') vm.model.gridReadyComponents = true;
                if (utilityService.validateParmsValue(vm.model.setting.prod_id_selected) === true && vm.model.bgProductBomList.selected === 'WORK') vm.model.gridReady = true;

                rememberBgProductBomList();
            };

            vm.selectBgProductBomOther = function (item) {
                vm.model.bgProductBomOther.item_selected = item;
                vm.model.setting.gb_productbom_other = vm.model.bgProductBomOther.item_selected;

                rememberBgProductBomOther();
            };

            // #endregion BUTTON GROUP FUNCTIONS

            // #region TREE SELECTION FUNCTION

            vm.selectTreeItem = function (item) {
                vm.model.selectedProdBom = item;
                vm.model.setting.prod_id_selected = item.item_id;

                if (vm.model.selectedProdBom?.bom_verified && (vm.model.selectedProdBom.bom_verified === '1' || vm.model.selectedProdBom.bom_verified === 1)) vm.gridComponents.config.toolbar.toggles[0].state = 1;
                else vm.gridComponents.config.toolbar.toggles[0].state = 0;
                loadGridComponents();
                loadGrid();
                if (vm.model.bgProductBomList.selected === 'COMPONENT' && vm.model.gridReadyComponents === true) vm.gridComponents.gridfunc.rebind();
                if (vm.model.bgProductBomList.selected === 'WORK' && vm.model.gridReady === true) vm.grid.gridfunc.rebind();
                loadIncludedIn();
                loadProdIdSelected();
                rememberProdIdSelected();

            };

            // #endregion TREE SELECTION FUNCTION

            // #region LOAD PROCEDURE CALLS

            productBomService.loadProductBomGet(vm.model.prodId).then(function (data) {
                vm.model.setting = angular.copy(data[0]);
                vm.gridComponents.dataTask.rememberId = 'w_productbom_components';
                vm.grid.dataTask.rememberId = 'w_productbom_operations';
                
                setProductBomListView(vm.model.setting.gb_productbom_list);

                angular.forEach(vm.model.bgProductBomOther.records, function (data) {
                    if (angular.isDefined(vm.model.setting.gb_productbom_other) && angular.isDefined(data)) {
                        if (vm.model.setting.gb_productbom_other === data.item_func) {
                            vm.model.bgProductBomOther.item_default = data.item_id;
                        }
                    }
                });


                loadProductBoms();
                loadIncludedIn();
                loadOwns();
                loadGridComponents();
                loadGrid();
                loadProdIdSelected();
                loadAssemblyGroups();
                setImageHeight();


            });

            var getDocumentsList = function () {

                vm.model.documents = [];
                vm.model.pictures = [];
                vm.model.videos = [];

                productBomService.loadP2DocumentsList('prod_id', vm.model.setting.prod_keyno_selected, '', 'd').then(function (items) {
                    angular.copy(items, vm.model.documents);

                });
                productBomService.loadP2DocumentsList('prod_id', vm.model.setting.prod_keyno_selected, '', 'p').then(function (items) {
                    angular.copy(items, vm.model.pictures);
                });
                productBomService.loadP2DocumentsList('prod_id', vm.model.setting.prod_keyno_selected, '', 'v').then(function (items) {
                    angular.copy(items, vm.model.videos);
                });

                //console.log(vm.model.documents);

            }

            var loadGridComponents = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prod_id_selected) !== true) return;

                vm.gridComponents.dataTask.loadData = {
                    method: '2479',
                    parameters: { prod_id: vm.model.setting.prod_id_selected }
                };

                if (vm.model.bgProductBomList.selected === 'COMPONENT') vm.model.gridReadyComponents = true;
            };

            var loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prod_id_selected) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: '2312',
                    parameters: { prod_id: vm.model.setting.prod_id_selected }
                };

                if (vm.model.bgProductBomList.selected === 'WORK') vm.model.gridReady = true;
            };

            var loadProductBoms = function () {
                if (angular.isUndefined(vm.model.setting.prod_id) === true) return;
                if (vm.model.setting.prod_id === null) return;
                if (vm.model.setting.prod_id.trim().length < 1) return;

                productBomService.loadProductBomList(vm.model.setting.prod_id).then(function (data) {
                    angular.copy(data, vm.model.listProductBom);
                    vm.model.selectedProdBom = vm.model.listProductBom.find(item => item.item_id === vm.model.setting.prod_id_selected);

                    if (vm.model.selectedProdBom?.bom_verified && (vm.model.selectedProdBom.bom_verified === '1' || vm.model.selectedProdBom.bom_verified === 1)) vm.gridComponents.config.toolbar.toggles[0].state = 1;
                    else vm.gridComponents.config.toolbar.toggles[0].state = 0;

                    loadGrid();
                    if (vm.model.gridReady === true) vm.grid.gridfunc.rebind();
                    loadIncludedIn();
                });
            };

            var loadIncludedIn = function () {
                if (angular.isUndefined(vm.model.setting.prod_id_selected) === true) return;
                if (vm.model.setting.prod_id_selected === null) return;
                if (vm.model.setting.prod_id_selected.trim().length < 1) return;

                productBomService.loadIncludedInList(vm.model.setting.prod_id_selected).then(function (data) {
                    angular.copy(data, vm.model.listIncludedIn);
                });
            };

            let loadOwns = function () {
                vm.model.itemsListOwns = [];

                return productBomService.listOwns(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListOwns);
                });
            };

            var loadProdIdSelected = function () {
                if (angular.isUndefined(vm.model.setting.prod_id_selected) === true) return;
                if (vm.model.setting.prod_id_selected === null) return;
                if (vm.model.setting.prod_id_selected.trim().length < 1) return;

                productBomService.loadProdIdSelectedGet(vm.model.setting.prod_id_selected).then(function (data) {
                    vm.model.setting.prod_name_selected = data[0].prod_name_selected;
                    vm.model.setting.prod_keyno_selected = data[0].prod_keyno_selected;
                    vm.model.setting.url_picture = data[0].url_picture;
                    vm.model.setting.product_main_image = data[0].product_main_image;
                    vm.model.setting.assemblygroup_id = data[0].assemblygroup_no;
                    //console.log(vm.model.setting.assemblygroup_id);

                    // Load product image
                    var product_main_image = vm.model.setting.product_main_image;
                    
                    
                    if (typeof (product_main_image) === 'string' && product_main_image !== '') {
                        //ieScreenBlockService.start();

                        fileService.getFileInfo({
                            selectOnlyFromFiles: true,
                            files: [{
                                "fileName": product_main_image
                            }]//,
                            //page: 1,
                            //pageSize: 1
                        }).then(function (result) {
                            if (typeof (result) === 'object' && "files" in result && result.files.length > 0) {

                                // We only want first image for the first version of this feature
                                var fileData = result.files[0];
                                var thumbUrl = fileData.thumbUrl;

                                var decoded = decodeURIComponent(thumbUrl);

                                var qsIndex = decoded.indexOf('{');

                                var url = decoded.substr(0, qsIndex);

                                var qs = decoded.substr(qsIndex);

                                qs = qs.substr(0, qs.indexOf(',"width"')) + ',"width":133,"height":100}';
                                qs = encodeURIComponent(qs);

                                // Insert link to image for use in HTML
                                vm.model.setting.productImageUrl = url + qs;

                            } else {

                                // NO IMAGE RETURNED FROM FILESERVER, HANDLE ERROR
                            }

                            //ieScreenBlockService.stop();
                        }).catch(function () {
                            // ERROR API CALL, HANDLE ERROR

                            //ieScreenBlockService.stop();
                        });
                    }

                    getDocumentsList();
                    setImageHeight();

                });
            };

            var loadAssemblyGroups = function () {
                productBomService.listAssemblyGroups().then(function (result) {
                    angular.copy(result, vm.model.selectListAssemblyGroups);
                    //console.log(vm.model.selectListAssemblyGroups);
                });
            };

            vm.saveAssemblyGroup = function (assemblygroup_id) {
                //console.log('here');
                vm.model.setting.assemblygroup_id = assemblygroup_id;
                productBomService.saveAssemblyGroup(vm.model.setting.prod_id_selected, assemblygroup_id);
            }


            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE FUNCTIONS

            var rememberProdIdSelected = function () {
                var variableNameProdIdSelected = 'w_productbom.' + vm.model.setting.prod_id;

                if (angular.isUndefined(variableNameProdIdSelected) === true) return;
                if (variableNameProdIdSelected === null) return;
                if (variableNameProdIdSelected === '') return;

                if (angular.isUndefined(vm.model.setting.prod_id) === true) return;
                if (vm.model.setting.prod_id === null) return;
                if (vm.model.setting.prod_id === '') return;
                if (vm.model.setting.prod_id === '0') return;

                if (angular.isUndefined(vm.model.setting.prod_id_selected) === true) return;
                if (vm.model.setting.prod_id_selected === null) return;
                if (vm.model.setting.prod_id_selected === '') return;
                if (vm.model.setting.prod_id_selected === '0') return;

                productBomService.remember(variableNameProdIdSelected, vm.model.setting.prod_id_selected);
            };

            var rememberBgProductBomList = function () {
                var variableNameBgProductBomList = 'w_productbom.gb_productbom_list.' + vm.model.setting.prod_id;

                if (angular.isUndefined(variableNameBgProductBomList) === true) return;
                if (variableNameBgProductBomList === null) return;
                if (variableNameBgProductBomList === '') return;

                if (angular.isUndefined(vm.model.setting.prod_id) === true) return;
                if (vm.model.setting.prod_id === null) return;
                if (vm.model.setting.prod_id === '') return;
                if (vm.model.setting.prod_id === '0') return;

                if (utilityService.validateParmsValue(vm.model.setting.gb_productbom_list) !== true) return; 

                productBomService.remember(variableNameBgProductBomList, vm.model.setting.gb_productbom_list);
            };

            var rememberBgProductBomOther = function () {
                var variableNameBgProductBomOther = 'w_productbom.gb_productbom_other.' + vm.model.setting.prod_id;

                if (angular.isUndefined(variableNameBgProductBomOther) === true) return;
                if (variableNameBgProductBomOther === null) return;
                if (variableNameBgProductBomOther === '') return;

                if (angular.isUndefined(vm.model.setting.prod_id) === true) return;
                if (vm.model.setting.prod_id === null) return;
                if (vm.model.setting.prod_id === '') return;
                if (vm.model.setting.prod_id === '0') return;

                if (angular.isUndefined(vm.model.bgProductBomOther.item_selected) === true) return;
                if (vm.model.bgProductBomOther.item_selected === null) return;
                if (vm.model.bgProductBomOther.item_selected === '') return;
                if (vm.model.bgProductBomOther.item_selected === '0') return;

                productBomService.remember(variableNameBgProductBomOther, vm.model.bgProductBomOther.item_selected);
            };

            // #endregion REMEMBER VALUE FUNCTIONS

            // #region CHECK BUTTON FUNCTION

            vm.checkError = function () {
                vm.model.checkingError = true;

                productBomService.checkProduct({ prod_id: vm.model.setting.prod_id }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.checkingError = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.checkingError = false;
                    }
                });
            };

            // #endregion CHECK BUTTON FUNCTION

            // #region DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productmovechoices':
                        go({ prod_id: vm.model.prodId });
                        break;
                    case 'product':
                    case 'productpics':
                        go({ prod_id: vm.model.setting.prod_keyno_selected });
                        break;
                    case 'productdataharvest':
                        go({
                            argtype: 'prod_id',
                            argvalue: vm.model.setting.prod_keyno_selected
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion DIRECTION BUTTON FUNCTIONS

            vm.goToPictureBank = function () {
                if (!vm.model.setting.url_picture) {
                    stateService.go('picturebanks', {
                        argtype: 'prod_id',
                        argvalue: vm.model.setting.prod_keyno_selected
                    });
                }
            }

            function setImageHeight() {
                setTimeout(() => {
                    let fieldsDiv = document.querySelector('.productbom__fields');
                    let firstChild = fieldsDiv?.children?.[0];

                    if (firstChild) {
                        vm.style.container.marginBottom = '0';
                        vm.style.img.minHeight = `70px`;
                        vm.style.placeholder.minHeight = `70px`;
                        vm.style.img.height = `${firstChild.clientHeight - (10.111 * 4 + 7)}px`;
                        vm.style.placeholder.height = `${firstChild.clientHeight - (10.111 * 4 + 7)}px`;

                        vm.style = { ...vm.style };
                    }
                }, 50);
            }
        }]
    });
})();
