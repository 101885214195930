(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('powTimeApprovals', {
        templateUrl: 'views/components/views/powTimeApprovals/powTimeApprovals.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['powTimeApprovalsService', function (powTimeApprovalsService) {
            const vm = this;
            
            vm.model = {
                itemsListApprovals: []
            };

            async function loadApprovals() {
                vm.model.itemsListApprovals = (await powTimeApprovalsService.listApprovals());
            }

            vm.$onInit = function () {
                loadApprovals();
            };
        }]
    });
})();
