(function () {
    'use strict';

    angular.module("imApp").factory("purchPriceService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 652,
                    parameters: parms || {}
                });
            },
            getSalesPrice: function (edit) {
                return $ihttp.post({
                    method: 832,
                    parameters: edit
                });
            },
            changePurchasePrice: function (edit) {
                return $ihttp.post({
                    method: 658,
                    parameters: edit
                });
            },
            changeCurrency: function (edit) {
                return $ihttp.post({
                    method: 3319,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 654,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 657,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
