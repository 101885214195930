import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridService } from '../../grid.service';
import { LayoutService } from '@app/core/services/layout.service';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { TranslateService } from '@app/core/services/translate.service';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { GridRow, LoadDataTaskRequest } from '../../grid.types';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { Base64UrlService } from '@app/services/base64url.service';
import { SetValueModel } from '@ag-grid-enterprise/set-filter/dist/types/src/setFilter/setValueModel';
import { PrintService } from '@app/core/services/print.service';
import { PdfService } from '@app/services/pdf.service';
import { DomSanitizer, platformBrowser, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'tt-print-modal',
    templateUrl: './print-modal.component.html',
    styleUrls: ['./print-modal.component.css'],
})
export class PrintModalComponent {
    /**
     * Whether data loading is completed or not.
     */
    public ready: boolean = true;

    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {};

    public reportButtons: FormButton[] = [{ id: 'preview', icon: 'fal fa-file-search', type: 'primary', onClick: () => this.preview() }];

    public printers: Item[] = [];

    public printerId: string = '';

    public selectedPrinter?: Item;

    public reports: (GridReport & { printServerKeyno: number })[] = [];

    public reportKeyno: string = '';

    public selectedReport?: GridReport & { printServerKeyno: number };

    /**
     * List of the selected rows in the grid, prioritizes is_selected value.
     */
    public selectedRows: GridRow[] = [];

    public previewBook: '1' | '0' = '0';

    public previewLink: SafeResourceUrl = '';

    public generating = false;

    public printing = false;

    constructor(public dialogRef: MatDialogRef<PrintModalComponent>, @Inject(MAT_DIALOG_DATA) public data: PrintDialogData, private gridService: GridService, private translateService: TranslateService, private datatask: DataTaskService, private base64: Base64UrlService, private print: PrintService, private pdf: PdfService, private sanitizer: DomSanitizer) {
        this.selectedRows = [...(data.selected.isSelected.length > 0 ? data.selected.isSelected : data.selected.rows)];
        this.mapReports(data.reports);
        this.getLastSelectedPrinter();
    }

    public onPrinterChanged(event: string) {
        this.selectedPrinter = this.printers.find((printer) => '' + printer.item_id === '' + event);

        localStorage.setItem('ngStorage-gridPrint', JSON.stringify({ printer: this.selectedPrinter ?? {} }));
    }

    public onReportChanged(event: any) {
        this.selectedReport = this.reports.find((report) => '' + report.keyno === '' + event);
    }

    public async confirm() {
        if (!!this.selectedReport) {
            try {
                this.printing = true;

                let reportParms = {
                    p2_reportdef_keyno: this.selectedReport.p2_reportdef_keyno,
                    //p2_reportdef_keyno: response.printServerKeyno > 0 ? response.printServerKeyno : response.p2_reportdef_keyno,
                    report_type_keyno: this.selectedReport.report_type_keyno,
                    p2_datatask_keyno: this.data.load.method,
                    parms: this.data.load.parameters,
                    filename: this.selectedReport.filename,
                    printer: this.printerId,
                    //report_name: response.report_name,
                    selected: this.data.selected,
                };

                console.dir(reportParms);

                if (this.selectedReport.report_type_keyno === 2) {
                    const parameters: any[] = this.data.selected.isSelected;

                    if (parameters.length == 0) {
                        parameters.push(this.data.load.parameters);
                    }

                    await this.pdf.printPDFBook({
                        reportKeyno: this.selectedReport.p2_reportdef_keyno,
                        printerKeyno: +this.printerId,
                        parameters: parameters,
                    });
                } else {
                    await this.datatask.Post(3003, reportParms);
                    // ttGridService.gridPrint(reportParms);
                }

                this.dialogRef.close(true);
            } finally {
                this.printing = false;
            }
            // print
        }
    }

    public cancel() {
        this.dialogRef.close();
    }

    public async preview() {
        if (!!this.selectedReport && (this.selectedReport.printServerKeyno !== -1 || this.isHTMLReport(this.selectedReport))) {
            this.generating = true;

            try {
                const reportKeyno = this.getReportKeyno();
                const rows = this.getRowsToPreview();
                let url = '';

                if (this.isHTMLReport(this.selectedReport)) {
                    url = await this.getHTMLReportPreviewURL(reportKeyno, rows);
                } else {
                    url = await this.getPrintServerPreviewURL(reportKeyno, rows);
                }

                if (url) this.previewLink = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            } catch (error) {
                console.log('error :>> ', error);
            } finally {
                this.generating = false;
            }
        }
    }

    /**
     * Retrieves the preview link for previewing the html-report of the given keyno.
     *
     * @param reportKeyno the keyno of the html-report to display.
     * @param rows the rows to display in the preview.
     * @returns a string containing the preview-url.
     */
    private getHTMLReportPreviewURL(reportKeyno: number, rows: GridRow[]): Promise<string> {
        const parameters = this.getPdfPreviewParameters(reportKeyno, rows);

        return this.pdf.previewPDFBook(parameters);
    }

    /**
     * Retrieves the preview link for previewing the print-server report of the given keyno.
     *
     * @param reportKeyno the keyno of the report from the print-server to retrieve preview-url for.
     * @param rows the rows to display in the preview.
     * @returns a string containing the preview-url.
     */
    private async getPrintServerPreviewURL(reportKeyno: number, rows: GridRow[]): Promise<string> {
        const parameters = await this.getPrintServerReportPreviewParameters(reportKeyno, rows);
        const timeoutPromise = new Promise<void>((_, reject) => setTimeout(() => reject('timeout'), 120000));
        let previewPromse: Promise<string>;

        if (parameters.reports || this.previewBook === '1') {
            previewPromse = this.print.print.previewBook(parameters);
        } else {
            previewPromse = this.print.print.preview(parameters);
        }

        const response = await Promise.race([previewPromse, timeoutPromise]);

        if (typeof response === 'string') {
            return response;
        }

        throw Error('timeout');
    }

    /**
     * Checks whether the given report is an html report.
     *
     * @param report the report to check.
     * @returns `true` if it is a html-report, `false` if not.
     */
    private isHTMLReport(report: GridReport & { printServerKeyno: number }) {
        return +report.report_type_keyno === 2;
    }

    /**
     * Retrieves the report keyno to use.
     *
     * @returns the report keyno to use.
     */
    private getReportKeyno(): number {
        if (this.selectedReport) {
            return this.selectedReport.printServerKeyno > 0 ? +this.selectedReport.printServerKeyno : +this.selectedReport.p2_reportdef_keyno;
        }

        throw Error('A report must be selected');
    }

    /**
     * Retrieves a list of rows to display in the preview.
     *
     * @returns list of rows to display in the preview.
     */
    private getRowsToPreview(): GridRow[] {
        if (this.previewBook === '1') {
            return this.selectedRows;
        } else {
            return [this.selectedRows.length > 0 ? this.selectedRows[0] : {}];
        }
    }

    /**
     * Retrieves parameters object for previewing the report of the given keyno from html reports.
     *
     * @param reportKeyno the keyno of the report to preview from the html reports.
     * @param rows the rows to preview in the report.
     * @returns object containing necessary parameters to preview the html report of the given keyno.
     */
    private getPdfPreviewParameters(reportKeyno: number, rows: GridRow[]): { reportKeyno: number; parameters: any[] } {
        if (this.selectedReport) {
            const parameters = this.data.load.parameters;
            const parms = Object.keys(rows[0] ?? {}).map((e) => ({ parmname: e }));
            const parametersList: any[] = [];

            rows.forEach((row) => {
                parms.forEach((parm) => this.addPrintParmValueToParameters(parm, row, parameters));

                parametersList.push(parameters);
            });

            return { reportKeyno: reportKeyno, parameters: parametersList };
        }

        throw Error('A report must be selected');
    }

    /**
     * Retrieves parameters object for previewing the report of the given keyno from the print-server.
     *
     * @param reportKeyno the keyno of the report to preview from the print server,
     * @param rows the rows to preview in the report.
     * @returns a promise containing an object containing necessary parameters to preview the print-server report of the given keyno.
     */
    private async getPrintServerReportPreviewParameters(reportKeyno: number, rows: GridRow[]): Promise<{ reportKeyno: number; reports: { reportKeyno: number; parametersList: string[] }[] }> {
        if (this.selectedReport) {
            const parametersList: string[] = [];
            const parameters: any = {};
            let parms: ServerParms[];

            if (this.selectedReport.parms.length > 0) {
                parms = this.selectedReport.parms;
            } else {
                parms = await this.print.print.getParameters({ reportKeyno: this.selectedReport.printServerKeyno });
            }

            rows.forEach((row) => {
                parms.forEach((parm) => this.addPrintParmValueToParameters(parm, row, parameters));

                parametersList.push(JSON.stringify(parameters));
            });

            return {
                reportKeyno: reportKeyno,
                reports: [{ reportKeyno: reportKeyno, parametersList: parametersList }],
            };
        }

        throw Error('A report must be selected');
    }

    /**
     * Adds value to the parameters object based of the configuration of the given print-server parameters and the values in the given grid row.
     *
     * @param parm the print-server paramaeters to add parameter value based on.
     * @param row the row to retrieve parameter value from.
     * @param parameters the parameters object to add the parameter to.
     */
    private addPrintParmValueToParameters(parm: { parmname: string }, row: GridRow, parameters: any) {
        const parmName = parm.parmname.charAt(0) === '@' ? parm.parmname.slice(1) : parm.parmname;
        parameters[parmName] = !!row[parmName] ? row[parmName] : '';
    }

    private async loadPrinters() {
        this.printers = await this.datatask.Post(337);
    }

    private getLastSelectedPrinter() {
        const print = localStorage.getItem('ngStorage-gridPrint');

        this.selectedPrinter = !!print ? JSON.parse(print).printer : undefined;

        if (this.selectedPrinter?.item_id) this.printerId = '' + this.selectedPrinter.item_id;
    }

    private async mapReports(reports: GridReport[]) {
        const serverReports: ServerReport[] = (await this.print.print.getReports()).items;

        this.reports = reports.map((report) => {
            let printServerKeyno = serverReports.find((serverReport: any) => serverReport.reportId === report.filename)?.reportKeyno;

            return { ...report, label: this.base64.urlDecode(report.label), printServerKeyno: printServerKeyno ?? -1 };
        });

        this.selectedReport = this.reports[0];
        this.reportKeyno = this.selectedReport?.keyno ? '' + this.selectedReport.keyno : '';
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.translations));

        Object.keys(this.translations).forEach((key) => {
            if (translations[key]) {
                this.translations[key] = translations[key];
            }
        });
    }

    ngOnInit(): void {
        this.translate();
        this.loadPrinters();
    }
}

// public async preview() {
//     this.generating = true;

//     try {
//         let selectedItem = this.selectedRows.length > 0 ? this.selectedRows[0] : {};
//         let rows: GridRow[];

//         if (this.previewBook === '1') {
//             rows = this.selectedRows;
//         } else {
//             rows = [selectedItem];
//         }

//         if (!!this.selectedReport && (this.selectedReport.printServerKeyno !== -1 || this.selectedReport.report_type_keyno === 2)) {
//             const reportKeyno = this.selectedReport.printServerKeyno > 0 ? this.selectedReport.printServerKeyno : this.selectedReport.p2_reportdef_keyno;

//             let parms: any[] = [];
//             let parameters: any = {};
//             let parametersList: any[] = [];

//             const modalParameters = {
//                 useBookPreview: this.previewBook === '1',
//                 reportTypeKeyno: this.selectedReport.report_type_keyno,
//                 reportKeyno: reportKeyno,
//                 parameters: null as any,
//                 reports: [] as any[],
//             };

//             if (this.selectedReport.report_type_keyno === 2) {
//                 parameters = this.data.load.parameters;
//                 parms = Object.keys(selectedItem ?? {}).map((e) => ({ parmname: e }));
//             } else if (this.selectedReport.parms.length > 0) {
//                 parms = this.selectedReport.parms;
//             } else {
//                 parms = await this.print.print.getParameters({ reportKeyno: this.selectedReport.printServerKeyno });
//             }

//             rows.forEach((row) => {
//                 selectedItem = row;

//                 parms.forEach((parm) => {
//                     const parmName = parm.parmname.charAt(0) === '@' ? parm.parmname.slice(1) : parm.parmname;
//                     parameters[parmName] = !!selectedItem[parmName] ? selectedItem[parmName] : '';
//                 });

//                 parametersList.push(JSON.stringify(parameters));
//             });

//             if (this.selectedReport.report_type_keyno === 2) {
//                 modalParameters.parameters = parametersList;
//             } else {
//                 modalParameters.reports = [{ reportKeyno: reportKeyno, parametersList: parametersList }];
//             }

//             let url = '';

//             switch ('' + modalParameters.reportTypeKeyno) {
//                 case '2':
//                     url = await this.pdf.previewPDFBook(modalParameters);
//                     break;
//                 default:
//                     const pa = await this.getPrintServerReportPreviewParameters(reportKeyno, rows);
//                     // ieScreenBlockService.start();

//                     // BJS 20250117 - Satt tilbake til å bruke preview (ikke previewBook) som default og bruk av previewBook må aktiveres med en paramter fordi
//                     //                disse funksjonene ikke er helt likestilte. preview inneholder blant annet comprimering av bilder i rapporten og det gjøres ikke med previewBook
//                     if (modalParameters?.reports || modalParameters?.useBookPreview) {
//                         url = await this.print.print.previewBook(pa);
//                     } else {
//                         url = await this.print.print.preview(pa);
//                     }
//                     break;
//             }

//             console.log('url :>> ', url);

//             if (url) {
//                 this.previewLink = this.sanitizer.bypassSecurityTrustResourceUrl(url);
//             }
//         }
//     } catch (error) {
//         console.log('error :>> ', error);
//     } finally {
//         this.generating = false;
//     }
// }
export interface PrintDialogData {
    load: LoadDataTaskRequest;
    reports: GridReport[];
    selected: PrintDialogSelectedData;
}

export interface PrintDialogSelectedData {
    rows: GridRow[];
    isSelected: GridRow[];
}

export interface GridReport {
    keyno: number;
    label: string;
    filename: string;
    type: string;
    report_type_keyno: number;
    p2_reportdef_keyno: number;
    parms: any[];
}

export interface ServerReport {
    reportKeyno: number;
    reportId: string;
    fileNameTemplate: string;
    isActive: boolean;
    remarks: string;
    regByUserId: string;
    modByUserId: string;
    regDateTime: string;
    modDateTime: string;
    status: string;
    isMissing: boolean;
}

export interface ServerParms {
    default: string;
    dotNetType: string;
    javaScriptType: string;
    length: number;
    parmdomain: string;
    parmid: string;
    parmmode: string;
    parmname: string;
    parmtype: string;
    scale: number;
}

// if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
//     vm.modalInstance.dismiss();
// }

// parameters = vm.resolve.parameters;
// //ieScreenBlockService.start('progress');
// //ieScreenBlockService.setProgressAndMessage(0, `Generating report`);

// if (angular.isDefined(parameters.showPrintButton)) {
//     vm.showPrintButton = parameters.showPrintButton || false;

//     delete parameters.showPrintButton;
// }

// let url = '';

// let progress = modalProgressFactory.$create({
//     label: 'generating preview',
//     min: 0,
//     max: parameters?.parameters?.length ? parameters?.parameters?.length * 2 : 0
// });

// switch (parameters.reportTypeKeyno) {
//     case "2":
//     case 2:
//         url = await pdfService.previewPDFBook(parameters, progress);
//         break;
//     default:
//         ieScreenBlockService.start();

//         // BJS 20250117 - Satt tilbake til å bruke preview (ikke previewBook) som default og bruk av previewBook må aktiveres med en paramter fordi
//         //                disse funksjonene ikke er helt likestilte. preview inneholder blant annet comprimering av bilder i rapporten og det gjøres ikke med previewBook
//         if (parameters?.reports || parameters?.useBookPreview) {
//             url = await printService.previewBook(parameters);
//         } else {
//             url = await printService.preview(parameters);
//         }
//         break;
// }

// if (url) {
//     vm.previewLink = $sce.trustAsResourceUrl(url);
// } else {
//     vm.modalInstance.dismiss();
// }
// ieScreenBlockService.stop();
// };

// let vm = this;
// let onDestroy = [];

// us.setDefaultValues($localStorage, {
//     gridPrint: {
//         printer: {}
//     }
// });

// vm.$storage = $localStorage.gridPrint;

// vm.previewBook = '0';
// vm.selectedRows = [];
// vm.rowCount = 0;
// vm.reportList = [];
// vm.report = {};
// vm.reportListButtons = [{ id: 'preview', icon: 'fal fa-file-search', type: 'primary', onClick: () => vm.preview() }];

// vm.translations = {
//     tt_grid_print_title: '',
//     tt_generic_modal_warning_title: '',
//     tt_preview_unavailable: '',
//     row_count: ''
// };

// vm.finish = function () {
//     vm.report.printer = angular.isDefined(vm.$storage.printer.item_id) ? vm.$storage.printer.item_id : '';

//     vm.modalInstance.close(vm.report);

// };

// vm.dismiss = function () {
//     vm.modalInstance.dismiss();
// };

// vm.preview = async function () {

//     let rows = [];
//     let selectedItem = vm.selectedRows.length > 0 ? vm.selectedRows[0] : {};

//     if (vm.previewBook === '1') {
//         rows = vm.selectedRows;
//     } else {
//         rows = [selectedItem]
//     }

//     if (vm.report.printServerKeyno !== -1 || vm.report.report_type_keyno == 2) {
//         const reportKeyno = vm.report.printServerKeyno > 0 ? vm.report.printServerKeyno : vm.report.p2_reportdef_keyno;
//         let parms = [];
//         let parameters = {};
//         let parametersList = [];
//         const modalParameters = {
//             useBookPreview: vm.previewBook === '1',
//             reportTypeKeyno: vm.report.report_type_keyno ?? 1,
//             reportKeyno: reportKeyno
//         };

//         if (vm.report.report_type_keyno == 2) {
//             parameters = vm.resolve.parameters.load.parms;
//             parms = Object.keys(selectedItem ?? {}).map(e => { return { parmname: e } });
//         } else if (vm.report.parms.length > 0) {
//             parms = vm.report.parms;
//         } else {
//             parms = await ttGridService.getReportParameters(vm.report.printServerKeyno);
//         };

//         angular.forEach(rows, function (item) {
//             selectedItem = item;
//             angular.forEach(parms, function (parm) {
//                 const parmName = parm.parmname.charAt(0) === '@' ? parm.parmname.slice(1) : parm.parmname;
//                 const value = angular.isDefined(selectedItem[parmName]) ? selectedItem[parmName] : '';
//                 parameters[parmName] = value;
//             });
//             let newParameters = angular.toJson(angular.copy(parameters));
//             parametersList.push(newParameters);
//         });

//         if (vm.report.report_type_keyno == 2) {
//             modalParameters.parameters = parametersList;
//         } else {
//             modalParameters.reports = [{
//                 reportKeyno: reportKeyno,
//                 parametersList: parametersList
//             }];
//         }
//         modalReportPreviewService.show(modalParameters);

//     } else {
//         modalService.show({
//             type: 'warning',
//             title: vm.translations.tt_generic_modal_warning_title,
//             message: vm.translations.tt_preview_unavailable,
//             buttons: [{
//                 label: 'OK',
//                 cssClass: 'btn-warning',
//                 action: function (dialogItself) {
//                     dialogItself.close();
//                 }
//             }]
//         });
//     }
// };

// vm.handleReportChange = async function (value) {
//     if (value !== vm.report.keyno) {
//         vm.report = vm.reportList.find(e => e.keyno == value);
//     }
// };

// vm.onPrinterChanged = function (printer, changed) {
//     if (changed !== true)
//         return;

//     vm.$storage.printer = printer;
// };

// translateService.translateBatch(vm.translations).then(function (data) {
//     angular.forEach(vm.translations, function (_, key) {
//         if (angular.isDefined(data[key])) {
//             vm.translations[key] = data[key];
//         }
//     });
// });

// vm.$onInit = function () {
//     vm.selectedRows = vm.resolve.parameters.selected.isSelected.length > 0 ? vm.resolve.parameters.selected.isSelected : vm.resolve.parameters.selected.rows;
//     vm.rowCount = vm.selectedRows.length;
//     vm.printBook = false;

//     angular.copy(vm.resolve.parameters.reports, vm.reportList);

//     angular.forEach(vm.reportList, function (item) {
//         item.label = base64.urldecode(item.label);
//     });

//     vm.reportList.sort(function (a, b) {
//         return a.keyno - b.keyno;
//     });

//     ttGridService.getPrintServerReports().then(function (result) {
//         angular.forEach(vm.reportList, function (item) {
//             item.printServerKeyno = -1;
//             for (let i = 0; i < result.length; i++) {
//                 if (item.filename === result[i].reportId) {
//                     item.printServerKeyno = result[i].reportKeyno;
//                     break;
//                 }
//             }
//         });
//         if (vm.reportList.length > 0) vm.handleReportChange(vm.reportList[0].keyno);
//     });

// };

// vm.$onDestroy = function () {
//     angular.forEach(onDestroy, function (fn) {
//         if (angular.isFunction(fn) === true) {
//             fn();
//         }
//     });
// };
