<tt-button
    *ngIf="showSettings"
    class="col-xs-12 sp-0"
    [ttBadge]="ttBadge"
    [ttButtonType]="ttButtonType"
    [ttDisabled]="ttDisabled"
    [ttIcon]="ttIcon"
    [ttIconAlign]="ttIconAlign"
    [ttIconPullRight]="ttIconPullRight"
    [ttLoading]="ttLoading"
    [ttLoadingText]="ttLoadingText"
    [ttPrinter]="ttPrinter"
    [ttSpin]="ttSpin"
    [ttStyle]="ttStyle"
    [ttText]="ttText"
    [ttTextAlign]="ttTextAlign"
    [ttTooltipText]="ttTooltipText"
    [ttTranslateLoadingText]="ttTranslateLoadingText"
    [ttTranslateText]="ttTranslateText"
    [ttTranslateTooltipText]="ttTranslateTooltipText"
    [ttType]="ttType"
    (ttClick)="onClick($event)"
></tt-button>
<!-- <div class="col-xs-12 sp-0" ng-if="vm.show">
    <tt-button class="col-xs-12 sp-0" tt-item-id="{{vm.ttItemId}}" tt-locked="vm.ttLocked" tt-locked-text="{{vm.ttLockedText}}" tt-badge="{{vm.ttBadge}}" tt-type="{{vm.type}}" tt-disabled="vm.ttDisabled" tt-click="vm.onClick()" tt-icon="{{vm.icon}}" tt-icon-align="{{vm.ttIconAlign}}" tt-icon-pull-right="{{vm.iconPullRight}}" tt-text="{{vm.text}}" tt-text-align="{{vm.textAlign}}" tt-style="vm.ttStyle"></tt-button>
</div> -->
