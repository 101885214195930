(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('helpdocttChanged', {
        templateUrl: 'views/components/views/helpdocttChanged/helpdocttChanged.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'helpdocttChangedService', 'translateListService', function ($q, utilityService, helpdocttChangedService, translateListService) {

    		// #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
                bgHelpdocttChanged: {
                    selected: 'TODO',
                    buttons: [
                        { id: 'TODO', label: 'bg_helpdocttchanged_todo', color: 'primary', onClick: () => vm.model.bgHelpdocttChanged.selected = 'TODO' },
                        { id: 'CHANGED', label: 'bg_helpdocttchanged_changed', color: 'primary', onClick: () => vm.model.bgHelpdocttChanged.selected = 'CHANGED' }
                    ]
                },
                setting: {},
                selectListLanguages: [],
                itemsListHelpDocChanged: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
    		// #region PROCEDURE FUNCTION CALLS
            
            let loadSetting = function () {
                const deferred = $q.defer();

                helpdocttChangedService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    
                    loadHelpDocChanged();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadLanguages = function () {
                translateListService.listLanguages().then(function (list) {
                    angular.copy(list, vm.model.selectListLanguages);
                });
            };
            
            let loadHelpDocChanged = function () {
                if (utilityService.validateParmsValue(vm.model.setting.language_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgHelpdocttChanged.selected) !== true) return;
                
                let sendObject = vm.model.setting;
                sendObject.bg_helpdocttchanged = vm.model.bgHelpdocttChanged.selected;

                vm.model.itemsListHelpDocChanged = [];
                
                return helpdocttChangedService.listHelpDocChanged(sendObject).then(function (list) {
                    angular.copy(list, vm.model.itemsListHelpDocChanged);
                });
            };
            
    		// #endregion PROCEDURE FUNCTION CALLS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgHelpdocttChanged = function (value) {
                vm.model.bgHelpdocttChanged.selected = value;

                loadHelpDocChanged();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'language_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.language_id) !== true) return;

                        vm.model.setting.language_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.language_id) !== true) return;

                        loadHelpDocChanged();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
