(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('positions', {
        templateUrl: 'views/components/views/positions/positions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'positionsService', function ($stateParams, stateService, positionsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListPositions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await positionsService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }))[0];
            }

            async function loadPositions() {
                vm.model.itemsListPositions = (await positionsService.listPositions({
                    argtype: argType,
                    argvalue: argValue
                }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'position':
                        go({
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue,
                            position_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadPositions();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
