(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productWaste', {
        templateUrl: 'views/components/views/productWaste/productWaste.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'productWasteService', 'productCreateService', 'ttGridFactory', 'rememberService', function ($q, stateService, utilityService, productWasteService, productCreateService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                prodtype_no: '',
                searchtext: ''
            };

            vm.model = {
                gridReady: false,
                productButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                selectListProdType: []
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_productWasteGrid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                productWasteService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadProductTypes = function () {
                productCreateService.listProductTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListProdType);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3419,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'prodtype_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        rememberFunc('searchtext');
                        loadGrid();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTION

            vm.searchItems = function () {
                rememberFunc('searchtext');
                loadGrid();
            };

            // #endregion SEARCH FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodtype_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.prodtype_no) !== true) return;

                        vm.model.setting.prodtype_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.prodtype_no) !== true) return;

                        rememberFunc('prodtype_no');
                        loadGrid();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadGrid();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
