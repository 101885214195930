(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('doDataImpImportModal', {
        templateUrl: 'views/components/views/doDataImp/doDataImp.template.importModal.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<',
            close: '&',
        },
        controller: ['base64', 'utilityService', 'modalService', 'excelImportService', 'typeaheadService', 'acledgerhistoryimportImportModalService', 'doDataImpService', function (base64, utilityService, modalService, excelImportService, typeaheadService, acledgerhistoryimportImportModalService, doDataImpService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let onDestroy = [];

            vm.cancel = function () {
                vm.modalInstance.close();
            };

            vm.model = {
                tableNameDestination: '',
                spinImport: false,
                locked: false,
                importSettings: {
                    p2_importproc_keyno: '0',
                    p2_exportproc_keyno: '0',
                    encodingtype: ''
                },
                spreadsheetHeaders: {},
                spreadsheetData: {
                    filename: '',
                    encoding: '',
                    records: []
                },
            };



            vm.importData = function () {
                vm.model.spinImport = true;
                console.dir(vm.model.spreadsheetData.records);
                if (vm.model.spreadsheetData.filename !== '') {
                    var stringified = '', order = [], keys = [], k;

                    angular.forEach(vm.model.spreadsheetHeaders, function (h) {
                        order.push(h.value);
                    });
                    angular.copy(order, keys);
                    //keys = Object.keys(vm.model.spreadsheetData.records[0]);

                    keys.sort(function (a, b) {
                        return order.indexOf(a) - order.indexOf(b);
                    });

                    angular.forEach(vm.model.spreadsheetData.records, function (row) {
                        for (var i = 0; i < keys.length; i++) {
                            k = keys[i];
                            stringified += row[k] + '|§|';
                        }

                        stringified = stringified.slice(0, -3);
                        stringified += '|#|';
                    });

                    stringified = stringified.slice(0, -3);

                    doDataImpService.excelImport({
                        headerdata: base64.encode(angular.toJson(order)),
                        records: base64.encode(stringified),
                        table_name_destination: vm.model.tableNameDestination,
                    }).then((data) => {
                        vm.model.spinImport = false;
                        vm.model.p2WExcelImportImport = angular.copy(data[0]);

                        var ok = 'OK';

                        if (angular.isDefined(vm.model.p2WExcelImportImport.exec_resultcode) && vm.model.p2WExcelImportImport.exec_resultcode == '-1') {
                            modalService.show({
                                type: 'danger',
                                title: 'Advarsel',
                                message: vm.model.p2WExcelImportImport.exec_message,
                                buttons: [{
                                    label: ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            vm.modalInstance.close();
                        }
                    });

                    //excelImportService.genericFunction(876, {
                    //    p2_importproc_keyno: vm.model.importSettings.p2_importproc_keyno,
                    //    table_name_destination: vm.model.importSettings.table_name_destination,
                    //    show_table_name_destination: vm.model.importSettings.show_table_name_destination,
                    //    filename: vm.model.spreadsheetData.filename,
                    //    verify: verify,
                    //    businessco_no: vm.model.businessCoNo,
                    //    accounting_year: vm.model.accountingYear
                    //}).then(function (data) {
                    //    vm.model.spinImport = false;
                    //    vm.model.p2WExcelImportImport = angular.copy(data[0]);

                    //    var ok = 'OK';

                    //    if (angular.isDefined(vm.model.p2WExcelImportImport.exec_resultcode) && vm.model.p2WExcelImportImport.exec_resultcode == '-1') {
                    //        modalService.show({
                    //            type: 'danger',
                    //            title: 'Advarsel',
                    //            message: vm.model.p2WExcelImportImport.exec_message,
                    //            buttons: [{
                    //                label: ok,
                    //                cssClass: 'btn-warning',
                    //                action: function (dialogItself) {
                    //                    dialogItself.close();
                    //                }
                    //            }]
                    //        });
                    //    } else {
                    //        modalService.show({
                    //            type: 'primary',
                    //            title: 'Informasjon',
                    //            message: vm.model.p2WExcelImportImport.exec_message,
                    //            buttons: [{
                    //                label: ok,
                    //                cssClass: 'btn-success',
                    //                action: function (dialogItself) {
                    //                    dialogItself.close();
                    //                }
                    //            }]
                    //        });
                    //    }
                    //}, { escape: true, encodedParms: true }).then(function (data) {
                    //    if (verify == 0)
                    //        console.log('imported');
                    //    else if (verify == 1)
                    //        console.log('verified');
                    //    else
                    //        console.log('neither 1 or 0');
                    //}).finally(() => {
                    //    vm.model.spinImport = false;
                    //    vm.model.locked = false;
                    //});
                }
            };

            vm.$onInit = function () {
                if (vm.resolve?.tableNameDestination) {
                    vm.model.tableNameDestination = vm.resolve.tableNameDestination;
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
