<div [hidden]="widgets.length === 0 && !ttEdit" class="tt-webpagemenu-dashboard" [ngClass]="{ 'tt-webpagemenu-dashboard--editable': ttEdit }">
    <h3 *ngIf="!!ttTitle" class="tt-card__heading" style="display: flex; gap: 0.6rem; margin: 0; padding: 1.4rem 2rem 0">
        {{ ttTitle }}
        <span class="fa-fw" (click)="ttEdit = !ttEdit; onEditChange(ttEdit); ttEditChange.emit(ttEdit)" style="margin-left: 0rem; position: relative; cursor: pointer">
            <span class="fa-fw fal fa-pencil" style="position: absolute; top: 0; left: 0"></span>
            <span *ngIf="ttEdit" class="fa-fw fal fa-slash" style="position: absolute; top: 0; left: 0"></span>
        </span>
    </h3>
    <gridstack
        *ngIf="!!gridOptions"
        [options]="gridOptions"
        (resizeStartCB)="editing = true"
        (resizeStopCB)="stopEditing()"
        (dragStartCB)="editing = true"
        (dragStopCB)="stopEditing()"
        (changeCB)="persistChange($event)"
    ></gridstack>
</div>
