(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('modalReportPreview', {
        templateUrl: 'views/components/directives/modalReportPreview/modalReportPreview.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$sce', 'ieScreenBlockService', 'printService', 'htmlReportService', 'printPreviewService', 'pdfService', 'base64', 'modalProgressFactory', function ($sce, ieScreenBlockService, printService, htmlReportService, printPreviewService, pdfService, base64, modalProgressFactory) {
            var vm = this;

            vm.previewLink = '';

            var parameters;

            vm.showPrintButton = false;

            vm.$onInit = async function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                parameters = vm.resolve.parameters;
                //ieScreenBlockService.start('progress');
                //ieScreenBlockService.setProgressAndMessage(0, `Generating report`);

                if (angular.isDefined(parameters.showPrintButton)) {
                    vm.showPrintButton = parameters.showPrintButton || false;

                    delete parameters.showPrintButton;
                }

                let url = '';

                let progress = modalProgressFactory.$create({
                    label: 'generating preview',
                    min: 0,
                    max: parameters?.parameters?.length ? parameters?.parameters?.length * 2 : 0
                });

                switch (parameters.reportTypeKeyno) {
                    case "2":
                    case 2:
                        url = await pdfService.previewPDFBook(parameters, progress);
                        break;
                    default:
                        ieScreenBlockService.start();

                        // BJS 20250117 - Satt tilbake til å bruke preview (ikke previewBook) som default og bruk av previewBook må aktiveres med en paramter fordi
                        //                disse funksjonene ikke er helt likestilte. preview inneholder blant annet comprimering av bilder i rapporten og det gjøres ikke med previewBook
                        if (parameters?.reports || parameters?.useBookPreview) {
                            url = await printService.previewBook(parameters);
                        } else {
                            url = await printService.preview(parameters);
                        }
                        break;
                }

                if (url) {
                    vm.previewLink = $sce.trustAsResourceUrl(url);
                } else {
                    vm.modalInstance.dismiss();
                }
                ieScreenBlockService.stop();
            };

            vm.print = function () {
                ieScreenBlockService.start();

                printService.print(parameters).then(function () {
                    ieScreenBlockService.stop();

                    vm.modalInstance.dismiss();
                });
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };
        }]
    });
})();
