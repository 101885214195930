import { Component, OnInit, ViewChild } from '@angular/core';
import { ListComponent } from '@app/core/components/list/list.component';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { StateService } from '@app/core/services/state.service';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
    selector: 'tt-deviation-settings',
    templateUrl: './deviation-settings.component.html',
    styleUrls: ['./deviation-settings.component.css'],
})
export class DeviationSettingsComponent implements OnInit {
    selectedTab = '';

    filter = '';

    @ViewChild('deviationTypesList')
    public deviationTypesList?: ListComponent<DeviationTypeItem>;

    @ViewChild('deviationSourcesList')
    public deviationSourcesList?: ListComponent<DeviationTypeItem>;

    @ViewChild('deviationSources2List')
    public deviationSources2List?: ListComponent<DeviationTypeItem>;

    tabletAndUpQuery: MediaQueryList;

    constructor(private datatask: DataTaskService, private state: StateService, private utility: UtilityService, layout: LayoutService) {
        this.tabletAndUpQuery = layout.getMediaQueries().laptop;
    }

    deviationTypes: DeviationTypeItem[] = [];

    deviationSources: DeviationSourceItem[] = [];

    deviationSources2: DeviationSourceItem[] = [];

    selectedDeviationType?: string;

    selectedDeviationSource?: string;

    selectedDeviationSource2?: string;

    public onTabChanged(_: string) {
        if (this.selectedDeviationType === '0') this.selectedDeviationType = '';
        if (this.selectedDeviationSource === '0') this.selectedDeviationSource = '';
        if (this.selectedDeviationSource2 === '0') this.selectedDeviationSource2 = '';

        setTimeout(() => {
            if (this.deviationTypesList) this.deviationTypesList.radioChecked = this.selectedDeviationType ?? '';
            if (this.deviationSourcesList) this.deviationSourcesList.radioChecked = this.selectedDeviationSource ?? '';
            if (this.deviationSources2List) this.deviationSources2List.radioChecked = this.selectedDeviationSource2 ?? '';
        });
    }

    public createNewDeviationType() {
        if (this.tabletAndUpQuery.matches) {
            this.selectedDeviationType = '0';
            this.deviationTypesList!.radioChecked = '';
        } else {
            this.state.go('deviationtype', { deviationtype_keyno: '0' });
        }
    }

    public createNewDeviationSource() {
        if (this.tabletAndUpQuery.matches) {
            this.selectedDeviationSource = '0';
            this.deviationSourcesList!.radioChecked = '';
        } else {
            this.state.go('deviationcause', { deviationsource_keyno: '0' });
        }
    }

    public createNewDeviationSource2() {
        if (this.tabletAndUpQuery.matches) {
            this.selectedDeviationSource2 = '0';
            this.deviationSources2List!.radioChecked = '';
        } else {
            this.state.go('deviationsource', { deviationsource2_keyno: '0' });
        }
    }

    public async onDeviationTypeSaved() {
        const oldList = [...this.deviationTypes];

        await this.loadDeviationTypes();

        if (this.selectedDeviationType === '0') {
            const newItem = this.findNewId(this.deviationTypes, oldList);

            if (newItem && this.deviationTypesList) {
                this.deviationTypesList.radioChecked = '' + newItem;
                this.selectedDeviationType = '' + newItem;
            }
        }
    }

    public onDeviationTypeDeleted() {
        this.selectedDeviationType = '';
        this.loadDeviationTypes();
    }

    public async onDeviationSourceSaved() {
        const oldList = [...this.deviationSources];

        await this.loadDeviationSources();

        if (this.selectedDeviationSource === '0') {
            const newItem = this.findNewId(this.deviationSources, oldList);

            if (newItem && this.deviationSourcesList) {
                this.deviationSourcesList.radioChecked = '' + newItem;
                this.selectedDeviationSource = '' + newItem;
            }
        }
    }

    public onDeviationSourceDeleted() {
        this.selectedDeviationSource = '';
        this.loadDeviationSources();
    }

    public async onDeviationSource2Saved() {
        const oldList = [...this.deviationSources2];

        await this.loadDeviationSources2();

        if (this.selectedDeviationSource2 === '0') {
            const newItem = this.findNewId(this.deviationSources2, oldList);

            if (newItem && this.deviationSources2List) {
                this.deviationSources2List.radioChecked = '' + newItem;
                this.selectedDeviationSource2 = '' + newItem;
            }
        }
    }

    public onDeviationSource2Deleted() {
        this.selectedDeviationSource2 = '';
        this.loadDeviationSources2();
    }

    /**
     * Finds the first new id of the newlist.Returns the item_id of the new item, or undefined if no new item was found.
     *
     * @param newList the newlist which may contain a new item.
     * @param oldList the old list to check against.
     * @returns the id of the new item in the list, or undefined if no new item was found.
     */
    private findNewId(newList: Item[], oldList: Item[]) {
        return newList.map((item) => item.item_id).find((id) => !oldList.map((item) => item.item_id).includes(id));
    }

    private async loadDeviationTypes() {
        this.deviationTypes = await this.datatask.Post(3546);
    }

    private async loadDeviationSources() {
        this.deviationSources = await this.datatask.Post(3537);
    }

    private async loadDeviationSources2() {
        this.deviationSources2 = await this.datatask.Post(3538);
    }

    ngOnInit(): void {
        this.loadDeviationTypes();
        this.loadDeviationSources();
        this.loadDeviationSources2();
    }
}

interface DeviationTypeItem extends Item {
    deviationtype_keyno: string;
    deviationtype_name: string;
}

export interface DeviationSourceItem extends Item {
    deviationtype_keyno: string;
    deviationtype_name: string;
    deviationsource_keyno: string;
    deviationsource_name: string;
    is_active: '1' | '0';
}

export interface DeviationType {
    deviationtype_keyno: string;
    deviationtype_name: string;
    isactive: '1' | '0';
}
