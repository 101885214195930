(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('portalUserStockplace', {
        templateUrl: 'views/components/views/portalUserStockplace/portalUserStockplace.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'portalUserStockplaceService', function ($stateParams, portalUserStockplaceService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let portalUserKeyno = $stateParams.portal_user_keyno;
            
            vm.model = {
                itemsListStockPlaces: {
                    records: []
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            async function loadStockPlaces() {
                portalUserStockplaceService.portalUserStockplaceList(portalUserKeyno).then(function () {
                    vm.model.itemsListStockPlaces = portalUserStockplaceService.dataSource;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region TOGGLE FUNCTION

            vm.toggleItem = function (item) {
                var active = '1';

                if (item.access_planning !== '0') {
                    active = '0';
                }

                portalUserStockplaceService.toggleItem({
                    portal_user_keyno: portalUserKeyno,
                    stockplace_id: item.item_id,
                    access_planning: active
                }).then(function () {
                    loadStockPlaces();
                });
            };

            // #endregion TOGGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockPlaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
