(function () {
    'use strict';

    angular.module("imApp").factory("shipmentContainerService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 774,
                    parameters: parms || {}
                });
            },
            listStockLocations: function (parms) {
                return $ihttp.post({
                    method: 1029,
                    parameters: parms || {}
                });
            },
            listContents: function (parms) {
                return $ihttp.post({
                    method: 777,
                    parameters: parms || {}
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            createObj: function (edit) {
                return $ihttp.post({
                    method: 775,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
