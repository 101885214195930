import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { TranslateEditDialogComponent, TranslateEditDialogData } from '@app/modal/translate-edit-dialog/translate-edit-dialog.component';

@Component({
    selector: 'tt-deviation-source2',
    templateUrl: './deviation-source2.component.html',
    styleUrls: ['./deviation-source2.component.css'],
})
export class DeviationSource2Component implements OnChanges {
    @Input()
    public deviationSource2Keyno: string = '';

    public deviationSource2?: DeviationSource2;

    public deviationSources: Item[] = [];

    public loading = false;

    public saving = false;

    public deleting = false;

    selectedtab = '';

    nameButtons: FormButton[] = [
        {
            id: 'translate',
            icon: 'far fa-language',
            type: 'primary',
            onClick: () => this.openTranslationDialog(),
        },
    ];

    @Output()
    public onSave = new EventEmitter<DeviationSource2>();

    @Output()
    public onDelete = new EventEmitter<DeviationSource2>();

    constructor(private datatask: DataTaskService, private dialog: MatDialog) {}

    public async openTranslationDialog() {
        if (!!this.deviationSource2) {
            const edits = await this.datatask.Post(3554, { deviationsource2_keyno: this.deviationSource2.deviationsource2_keyno });
            const dialogRef = this.dialog.open(TranslateEditDialogComponent, {
                width: '120rem',
                maxWidth: '95vw',
                height: '80vh',
                data: <TranslateEditDialogData<DeviationSource2LangEdit>>{
                    heading: `(${this.deviationSource2.deviationsource2_keyno}) ${this.deviationSource2.deviationsource2_name}`,
                    textToTranslate: this.deviationSource2.deviationsource2_name,
                    edits: edits,
                    save: async (data) => {
                        await this.datatask.Post(3555, { edits: data.edits });
                        await this.loadDeviationSource2();
                        dialogRef.close();
                        this.onSave.emit(this.deviationSource2);
                    },
                    wordNameKey: 'deviationsource2_name',
                    wordIdKey: 'deviationsource2_keyno',
                },
            });
        }
    }

    public async save() {
        if (!!this.deviationSource2) {
            try {
                this.saving = true;

                const response = await this.datatask.Post(3542, this.deviationSource2);

                if (!response || response.errorcode === '0') {
                    this.onSave.emit(this.deviationSource2);
                }
            } finally {
                this.saving = false;
            }
        }
    }

    public async delete() {
        if (this.deviationSource2Keyno > '0' && !!this.deviationSource2 && '' + this.deviationSource2Keyno === '' + this.deviationSource2.deviationsource2_keyno) {
            try {
                this.deleting = true;

                await this.datatask.Post(3544, this.deviationSource2);

                this.onDelete.emit(this.deviationSource2);
            } finally {
                this.deleting = false;
            }
        }
    }

    public onDeviationSourceRelationChanged(item: { [key: string]: any }) {
        item['is_active'] = item['is_selected'] === true ? '1' : '0';

        this.updateDeviationSourceRelation({ deviationsource2_keyno: this.deviationSource2Keyno, deviationsource_keyno: item['item_id'], is_active: item['is_selected'] === true ? '1' : '0' });
    }

    public onAllDeviationSourceChecked(event: boolean) {
        const itemsToUpdate = this.deviationSources.filter((item) => item['is_active'] !== (event === true ? '1' : '0'));

        itemsToUpdate.map((item) => this.onDeviationSourceRelationChanged(item));
    }

    private async loadDeviationSource2() {
        if (!!this.deviationSource2Keyno) {
            this.deviationSource2 = (await this.datatask.Post(3545, { deviationsource2_keyno: this.deviationSource2Keyno ?? '0' }))[0];
        }
    }

    private async loadDeviationSources() {
        if (this.deviationSource2Keyno > '0') {
            this.deviationSources = (await this.datatask.Post(3537, { deviationsource2_keyno: this.deviationSource2Keyno })).map((item: Item) => ({ ...item, is_selected: item!['is_active'] === '1' ? true : false }));
        }
    }

    private async updateDeviationSourceRelation(params: { deviationsource_keyno: string; deviationsource2_keyno: string; is_active: '0' | '1' }) {
        return this.datatask.Post(3540, params);
    }

    private async loadData() {
        try {
            this.loading = true;
            await Promise.all([this.loadDeviationSource2(), this.loadDeviationSources()]);
        } finally {
            this.loading = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['deviationSource2Keyno']?.currentValue && changes['deviationSource2Keyno'].currentValue !== changes['deviationSource2Keyno'].previousValue) {
            this.loadData();
        }
    }
}

interface DeviationSource2 {
    deviationsource2_keyno: string;
    deviationsource2_name: string;
}

interface DeviationSource2LangEdit extends Record<string, string> {
    deviationsource2_keyno: string;
    deviationsource2_name: string;
    language_id: string;
    language_name: string;
    orderby: string;
    google_language_id: string;
}
