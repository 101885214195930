(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2DataTaskLogs', {
        templateUrl: 'views/components/views/p2DataTaskLogs/p2DataTaskLogs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'rememberService', 'p2DataTaskLogsService', 'layoutService', function ($stateParams, $q, stateService, utilityService, modalService, rememberService, p2DataTaskLogsService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let portalUserKeyno = $stateParams.portal_user_keyno;
            
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                itemState: '',
                p2DataTaskLogKeyno: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('searchtext') }
                ],
                setting: {},
                itemsListDataTaskLogs: [],
                refreshing: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                p2DataTaskLogsService.getDataTaskLogs({ portal_user_keyno: portalUserKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadDataTaskLogs();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadDataTaskLogs = function () {
                if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;
                
                vm.model.refreshing = true;
                vm.model.itemsListDataTaskLogs = [];
                
                return p2DataTaskLogsService.loadP2DataTaskLogList(vm.model.setting.portal_user_keyno).then(function (list) {
                    vm.model.refreshing = false;
                    angular.copy(list, vm.model.itemsListDataTaskLogs);

                    angular.forEach(vm.model.itemsListDataTaskLogs, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    }); 
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + vm.model.setting.portal_user_keyno + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                            vm.model.setting[id] = '(' + value + ')';

                            if (vm.model.setting[id] > '') rememberFunc(id);
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // #region REFRESH FUNCTION
            
            vm.refreshChanges = function () {
                loadDataTaskLogs();
            };

            // #endregion REFRESH FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openDataTaskLog = function (item) {
                vm.model.p2DataTaskLogKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION

        }]
    });
})();
