(function () {
    'use strict';

    angular.module("imApp").factory("p2UpdateScriptEditService", ['$ihttp', function ($ihttp) {
        let service = {
            getUpdateScript: function (edit) {
                let parms = {
                    method: 191,
                    parameters: edit || {}
                };

                return $ihttp.post(parms, { encodedData: true });
            },
            saveObj: function (edit) {
                let parms = {
                    method: 192,
                    parameters: edit
                };

                return $ihttp.post(parms, { encodedParms: true });
            }
        };

        return service;
    }]);
})();
