(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productUnitsEdit', {
        templateUrl: 'views/components/views/productUnitsEdit/productUnitsEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'productUnitsEditService', 'logisticService', function ($stateParams, $q, utilityService, productUnitsEditService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;

            vm.model = {
                setting: {},
                selectListProductSizes: [],
                itemsListProductUnitDatas: [],
                example: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadSetting = function () {
                const deferred = $q.defer();
                
                productUnitsEditService.getProductUnitsEditSettings({ prod_id: prodId }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadProductUnitDatas(),
                        loadExampleData()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadProductSizes = function () {
                logisticService.listProductSizes({ prod_id: prodId }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductSizes);
                });
            };
            
            let loadProductUnitDatas = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                vm.model.itemsListProductUnitDatas = [];

                return productUnitsEditService.listProductUnitDatas({ prodsize_keyno: vm.model.setting.prodsize_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListProductUnitDatas);
                });
            };

            let loadExampleData = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                return productUnitsEditService.getExampleData({ prodsize_keyno: vm.model.setting.prodsize_keyno }).then(function (response) {
                    angular.copy(response[0], vm.model.example);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodsize_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.prodsize_keyno) !== true) return;

                        vm.model.setting.prodsize_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno, true) !== true) return;

                        loadProductUnitDatas();
                        loadExampleData();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductSizes();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
