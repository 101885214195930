(function () {
    'use strict';

    angular.module("imApp").factory("invoiceHeadEhfService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 909,
                    parameters: parms || {}
                });
            },
            createEhf: function (edit) {
                return $ihttp.post({
                    method: 910,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
