(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageTableEdit', {
        templateUrl: 'views/components/views/webpageTableEdit/webpageTableEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            p2WebpageTableKeyno: '<?',
            onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'webpageTableEditService', 'layoutService', function ($stateParams, stateService, translateService, modalService, webpageTableEditService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2WebpageTableKeyno = vm?.p2WebpageTableKeyno ?? $stateParams.p2_webpage_table_keyno;
            let tabletSize = layoutService.getMediaQueries().tablet;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                tabletSize: tabletSize,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                webpageTableEditService.getEdit({ p2_webpage_table_keyno: p2WebpageTableKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                webpageTableEditService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].iserror !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;

                        if (angular.isFunction(vm.onSave) && tabletSize.matches === true) {
                            vm.onSave();
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION

            vm.$onChanges = function (changes) {
                if (changes?.p2WebpageTableKeyno?.currentValue) {
                    p2WebpageTableKeyno = changes.p2WebpageTableKeyno.currentValue;
                }

                if (p2WebpageTableKeyno) loadEdit();
            };

            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
