import { Component, Input } from '@angular/core';
import { DashboardWidgetBasic } from '../dashboard-widget-base/dashboard-widget-base.component';
import { BaseWidget, NgCompInputs } from 'gridstack/dist/angular';

@Component({
    selector: 'tt-dashboard-widget-basic',
    templateUrl: './dashboard-widget-basic.component.html',
    styleUrls: ['./dashboard-widget-basic.component.css'],
})
export class DashboardWidgetBasicComponent  {

    @Input()
    ttDashboardWidget?: DashboardWidgetBasic;
}
