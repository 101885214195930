<tt-card *ngIf="deviationSource2" class="col-md-4 sp-4" [ttHeading]="deviationSource2Keyno === '0' ? 'create_deviationsource2' : 'edit_deviationsource2'">
    <tt-input ttLabel="deviationsource_keyno" [ttModel]="deviationSource2.deviationsource2_keyno" ttReadonly></tt-input>
    <tt-input *ngIf="deviationSource2Keyno > '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationSource2.deviationsource2_name" [ttButtons]="nameButtons"></tt-input>
    <tt-input *ngIf="deviationSource2Keyno === '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationSource2.deviationsource2_name"></tt-input>
    <tt-button-save ttText="deviationtype_save" (ttClick)="save()" [ttLoading]="saving" [ttDisabled]="deleting"></tt-button-save>
    <ng-container *ngIf="deviationSource2Keyno > '0'">
        <tt-button ttText="deviationtypemenu_delete" ttType="danger" (ttClick)="delete()" [ttLoading]="deleting"></tt-button>
        <tt-tabs [(ttSelectedId)]="selectedtab">
            <tt-tab ttId="deviationsource_relation" ttLabel="deviationsources2_deviationsource"></tt-tab>
        </tt-tabs>
        <tt-multi-select
            [ttData]="deviationSources"
            (ttSelectItem)="onDeviationSourceRelationChanged($event)"
            (ttCheckAll)="onAllDeviationSourceChecked($event)"
            ttAllSelected="false"
        ></tt-multi-select>
    </ng-container>
</tt-card>
