(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('ttDocumentViewerModal', {
        //templateUrl: 'views/components/views/gridBookingModal/gridBookingModal.template.html?v=' + module.version,
        template: `
        <div class="bootstrap-dialog">
            <div class="modal-content col-xs-12 sp-0" style="max-height: 95vh; height: -webkit-fill-available; display: flex; flex-direction: column;">
                <div class="modal-header col-xs-12" style="position: sticky; left: 0; top: 0; z-index: 231232; background-color: var(--tt-modal--color);">
                    <div class="bootstrap-dialog-header">
                        <h3 style="padding: 0; margin: 0; display: inline-block;">{{vm.ttModel.item_name}}</h3>
                    <div class="bootstrap-dialog-close-button" style="display: block;">
                        <button class="close" ng-click="vm.dismiss()" ng-style="{'fontSize': vm.fontSize}">×</button>
                    </div>
                </div>
            </div>
        <div class="modal-body col-xs-12" style="overflow: auto; height: -webkit-fill-available; padding-top: 0;">
            <tt-document-viewer ng-if="vm.ready === true" style="display: block; height: 80vh;" tt-model="vm.ttModel" tt-is-email="vm.ttIsEmail" tt-email-show-sent-data="vm.ttEmailShowSentData" tt-email-reply-view="vm.ttEmailReplyView" tt-email-attachments="vm.ttEmailAttachments" tt-email-attachment-click="vm.ttEmailAttachmentClick" tt-email-user-sender-fallback="vm.ttEmailUserSenderFallback" tt-email-buttons="vm.ttEmailButtons" tt-show-document="vm.ttShowDocument"></tt-document-viewer>
        </div>
        <div class="modal-footer col-xs-12" style="display: block; position: sticky; bottom: 0; right: 0; z-index: 12;">
        </div>
    </div>
</div>

        `,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', 'translateService', '$ihttp', '$uibModal', 'modalService', 'accountingProcessingService', 'logisticService', 'rememberService', 'gridBookingModalService', 'ttGridFactory', 'fileService', function (layoutService, translateService, $ihttp, $uibModal, modalService, accountingProcessingService, logisticService, rememberService, gridBookingModalService, ttGridFactory, fileService) {
            const vm = this;


            vm.ttModel;
            vm.ttIsEmail;
            vm.ttEmailShowSentData;
            vm.ttEmailReplyView;
            vm.ttEmailAttachmentsView;
            vm.ttEmailAttachments;
            vm.ttEmailAttachmentClick;
            vm.ttEmailUserSenderFallback;
            vm.ttEmailButtons;
            vm.ttShowDocument;

            vm.ready = false;

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            }

            vm.$onInit = async function () {
                console.log(vm.resolve);
                for (let [key, value] of Object.entries(vm.resolve)) {
                    vm[key] = value;
                }

                if (vm.ttModel && vm.ttModel.extension !== 'msg') {
                    vm.ttModel = {
                        ...vm.ttModel, ...(await fileService.getFileDetails({
                            fileName: vm.ttModel.docfilename,
                            maxWidth: 1200,
                            maxHeight: 600,
                        }))
                    };
                }

                console.log(JSON.stringify(vm.ttModel, null, 2));
                //vm.ttIsEmail = vm.ttModel.extension === 'msg';

                vm.ready = true;
                //if (vm.resolve.ttModel) {
                //    vm.ttModel = vm.resolve;
                //}
                //    ttModel: '<',
                //        ttIsEmail: '@?',
                //            ttEmailShowSentData: '@?',
                //                ttEmailReplyView: '@?',      // 'inline' | 'bottom' | 'none' 
                //                    ttEmailAttachmentsView: '@?',      // 'inline' | 'bottom' | 'none'
                //                        ttEmailAttachments: '<?',
                //                            ttEmailAttachmentClick: '&?',
                //                                ttEmailUserSenderFallback: '@?',
                //                                    ttEmailButtons: '<?',
                //                                        ttShowDocument: '='
            }
        }]
    });
})();
