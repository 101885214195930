<tt-page-heading [ttModel]="header.text" [ttTranslate]="header.translate">
    <span class="fa-fw" (click)="editing = !editing; !editing && (selectedWidget = '')" style="font-size: var(--tt-font-size-xl); margin-left: 1.2rem; position: relative; cursor: pointer">
        <span class="fa-fw fal fa-pencil" style="position: absolute; top: 0"></span>
        <span *ngIf="editing" class="fa-fw fal fa-slash" style="position: absolute; top: 0"></span>
    </span>
</tt-page-heading>

<div style="display: flex; flex: 1; height: 100%; overflow: auto">
    <div style="flex: 1; height: 100%; overflow: auto">
        <gridstack [options]="gridOptions"></gridstack>
        <tt-webpagemenu-dashboard style="margin-top: 3rem" *ngIf="!!ttName" [ttEdit]="editable && editing" [ttName]="ttName" [(ttSelectedWidget)]="selectedWidget"></tt-webpagemenu-dashboard>
    </div>
    <!-- <div *ngIf="selectedWidget && editable && editing" style="flex-shrink: 0; width: 35rem; height: 100%; overflow: auto">
        <tt-button-goto ttText="Gå til kpi-komponent" ttTranslateText="false" ttGoto="kpicomp" [ttGotoParms]="selectedWidget"></tt-button-goto>
        <tt-kpi-comp [kpiCompKeyno]="selectedWidget"></tt-kpi-comp>
    </div> -->
</div>
