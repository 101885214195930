(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('guardList', {
        templateUrl: 'views/components/views/guardList/guardList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'guardListService', function ($stateParams, guardListService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                itemsListGuards: []
            };
            
            async function loadGuards() {
                vm.model.itemsListGuards = (await guardListService.listGuards({
                    argtype: argType,
                    argvalue: argValue
                }));
            }

            vm.$onInit = function () {
                loadGuards();
            };
        }]
    });
})();
