<h2
    mat-dialog-title
    class="tt-secondary-background"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
    style="color: white"
    [ttTranslateWord]="!p2UpdateScriptKeyno || p2UpdateScriptKeyno === '0' ? 'h_update_script_new' : 'h_update_script_edit'"
></h2>

<mat-dialog-content style="max-height: none; height: 100%; flex: 1">
    <tt-p2-update-script-edit [p2UpdateScriptKeyno]="p2UpdateScriptKeyno" hideSaveButton></tt-p2-update-script-edit>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <tt-button class="col-md-2 col-xs-6 sp-2" ttText="cancel" ttType="secondary" (ttClick)="dialog.close()"></tt-button>
    <tt-button class="col-md-2 col-xs-6 sp-2" ttText="save" ttType="success" (ttClick)="save()" [ttLoading]="saving" ttLoadingText=""></tt-button>
</mat-dialog-actions>
