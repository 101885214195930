(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('shifts', {
        templateUrl: 'views/components/views/shifts/shifts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'shiftsService', 'layoutService', function (stateService, shiftsService, layoutService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                shiftId: '',
                itemsListShifts: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadShifts = function () {
                shiftsService.listShifts().then(function (list) {
                    angular.copy(list, vm.model.itemsListShifts);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region UPDATE SHIFT LIST FUNCTION

            vm.updateShifts = function () {
                loadShifts();
            };

            // #endregion UPDATE SHIFT LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openShift = function (item) {
                vm.model.shiftId = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shift':
                        if (tabletSize.matches === true) {
                            vm.model.shiftId = '0';
                        } else {
                            go({ shift_id: '0' });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadShifts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
