(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('salaryTypes', {
        templateUrl: 'views/components/views/salaryTypes/salaryTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'economyService', 'ttGridFactory', 'layoutService', function (stateService, economyService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                desktopSize: layoutService.getMediaQueries().desktop,
                itemsListSalaryTypes: [],
                gridReady: false
        	};

            vm.grid = new ttGridFactory({
                rememberId: 'salarytype_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'create_new_salarytype',
                text: 'create_new_salarytype',
                func: () => stateService.go('salarytype', { salarytype_id: '0' }),
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadSalaryTypes = function () {
                economyService.listSalaryTypes().then(function (list) {
                    angular.copy(list, vm.model.itemsListSalaryTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3516,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'salarytype':
                        go({ salarytype_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSalaryTypes();
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
