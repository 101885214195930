(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('tracesB', {
        templateUrl: 'views/components/views/tracesB/tracesB.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'tracesBService', 'ttGridFactory', function ($stateParams, $q, stateService, utilityService, tracesBService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;

            vm.model = {
                gridReady: false,
                stockBatchButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockbatchget', { stockbatch_keyno: vm.model.setting.stockbatch_keyno }) }
                ],
                bgTracesBMain: {
                    selected: 'B',
                    buttons: [
                        { id: 'B', label: 'gb_tracesb_main_backward', color: 'primary', onClick: function () { setTracesBMainView('B'); } },
                        { id: 'F', label: 'gb_tracesb_main_forward', color: 'primary', onClick: function () { setTracesBMainView('F'); } }
                    ]
                },
                setting: {}
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setTracesBMainView = function (id) {
                vm.model.bgTracesBMain.selected = vm.model.setting.gb_tracesb_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                tracesBService.getTracesB({ stockbatch_keyno: stockBatchKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    setTracesBMainView(vm.model.setting.gb_tracesb_main);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgTracesBMain = function (value) {
                vm.model.bgTracesBMain.selected = value;
                vm.model.setting.gb_tracesb_main = vm.model.bgTracesBMain.selected;

                loadGrid();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
