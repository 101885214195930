(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('selectProduct', {
        templateUrl: 'views/components/views/selectProduct/selectProduct.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'selectProductService', 'prodGrpsService', 'typeaheadService', 'rememberService', function ($stateParams, $q, stateService, utilityService, selectProductService, prodGrpsService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let webPageName = $stateParams.webpage_name;
            let parm1 = $stateParams.parm1;
            let parm2 = $stateParams.parm2;

            let variableNames = {
                prodgrp_id: '',
                searchtext: ''
            };

            vm.model = {
                webPageName: webPageName,
                bgSelectSearch: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'select', color: 'primary', onClick: () => vm.model.bgSelectSearch.selected = 'SELECT' },
                        { id: 'SEARCH', label: 'search', color: 'primary', onClick: () => vm.model.bgSelectSearch.selected = 'SEARCH' }
                    ]
                },
                bgProject: {
                    selected: 'PJ',
                    buttons: [
                        { id: 'PJ', label: 'project', color: 'primary', onClick: () => vm.model.bgProject.selected = 'PJ' },
                        { id: 'JOB', label: 'custact', color: 'primary', onClick: () => vm.model.bgProject.selected = 'JOB' }
                    ]
                },
                bgSearchSelect: {
                    selected: 'SEARCH',
                    buttons: [
                        { id: 'SEARCH', label: 'search', color: 'primary', onClick: () => vm.model.bgSearchSelect.selected = 'SEARCH' },
                        { id: 'SELECT', label: 'select', color: 'primary', onClick: () => vm.model.bgSearchSelect.selected = 'SELECT' }
                    ]
                },
                productGroupButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('prodgrp_id') }
                ],
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                selectListProductGroups: [],
                itemsListProducts: [],
                itemsListComponentProducts: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS
            
            let loadSetting = function () {
                let deferred = $q.defer();

                selectProductService.getSetting({
                    webpage_name: webPageName,
                    parm1: parm1,
                    parm2: parm2
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    if (vm.model.setting.prodgrp_id !== 'ALL') {
                        loadProducts();
                    } else if (vm.model.setting.searchtext !== '') {
                        loadProducts();
                    }

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadProductGroups = function () {
                prodGrpsService.listProductGroups({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroups);
                });
            };
            
            let loadProducts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodgrp_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListProducts = [];

                return typeaheadService.searchProduct({
                    prodgrp_id: vm.model.setting.prodgrp_id,
                    prod_name: vm.model.setting.searchtext,
                    webpage_name: webPageName,
                    parm1: parm1,
                    parm2: parm2
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProducts);

                    if (webPageName === 'custactcomp') {
                        if (vm.model.itemsListProducts.length === 1) {
                            vm.goToPre(vm.model.itemsListProducts[0]);
                            stateService.back();
                        }
                    }
                });
            };
            
            let loadComponentProducts = function () {
                if (utilityService.validateParmsValue(vm.model.bgProject.selected) !== true) return;

                vm.model.itemsListComponentProducts = [];

                return selectProductService.listComponentProducts({
                    custact_no: parm1,
                    select_status2: vm.model.bgProject.selected,
                    include_top: '1',
                    retrievetype: 'child'
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListComponentProducts);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'prodgrp_id':
                    	case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + webPageName + '/' + parm1 + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'prodgrp_id':
                        if (utilityService.validateParmsValue(vm.model.setting.prodgrp_id) !== true) return;

                        variableValue = vm.model.setting.prodgrp_id;
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'prodgrp_id':
                        vm.model.setting.prodgrp_id = 'ALL';

                        if (vm.model.setting.prodgrp_id === 'ALL') {
                            rememberFunc('prodgrp_id');
                            loadProducts();
                        }
                        break;
                    case 'searchtext':
                        vm.model.setting.searchtext = '';
                        
                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.setting.prodgrp_id !== 'ALL') {
                                loadProducts();
                            } else {
                                vm.model.itemsListProducts = [];
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgSelectSearch = function (value) {
                vm.model.bgSelectSearch.selected = value;
                
                if (vm.model.bgSelectSearch.selected === 'SELECT' && (vm.model.bgProject.selected === 'JOB' || vm.model.bgProject.selected === 'PJ')) loadComponentProducts();
            };
            
            vm.selectBgProject = function (value) {
                vm.model.bgProject.selected = value;
                
                if ((vm.model.bgSelectSearch.selected === 'SELECT' || vm.model.bgSearchSelect.selected === 'SELECT') && (vm.model.bgProject.selected === 'JOB' || vm.model.bgProject.selected === 'PJ')) loadComponentProducts();
            };
            
            vm.selectBgSearchSelect = function (value) {
                vm.model.bgSearchSelect.selected = value;

                if (vm.model.bgSearchSelect.selected === 'SELECT' && (vm.model.bgProject.selected === 'JOB' || vm.model.bgProject.selected === 'PJ')) loadComponentProducts();
            };
            
            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.searchItems = function () {
                if (vm.model.setting.searchtext > '') {
                    rememberFunc('searchtext');
                    loadProducts();
                }
            };
            
            // #region SAVE & GO BACK TO PREVIOUS PAGE
    
            vm.goToPre = async function (item) {
                var rememberProdId = function () {
                    var variableNameProdId = 'w_selectproduct.prod_id';
                    
                    if (utilityService.validateParmsValue(item.item_id) !== true) return;
                    
                    return rememberService.remember(variableNameProdId, item.item_id);
                };
                
                if (['materialregister', 'orderlineedit', 'custactcomp', 'equipmentedit'].includes(webPageName)) await rememberProdId()
            };

            // #endregion SAVE & GO BACK TO PREVIOUS PAGE

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.prodgrp_id) !== true) return;

                        vm.model.setting.prodgrp_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.prodgrp_id) !== true) return;

                        rememberFunc('prodgrp_id');
                        loadProducts();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            
                            if (vm.model.setting.prodgrp_id !== 'ALL') {
                                loadProducts();
                            } else {
                                vm.model.itemsListProducts = [];
                            }
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductGroups();
                loadComponentProducts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
