(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchHead', {
        templateUrl: 'views/components/views/purchHead/purchHead.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'modalService', 'stateService', 'translateService', 'purchHeadService', 'logisticService', 'economyService', 'purchHeadsService', 'departmentService', 'ttGridFactory', 'watcherFactory', function ($stateParams, $q, utilityService, modalService, stateService, translateService, purchHeadService, logisticService, economyService, purchHeadsService, departmentService, ttGridFactory, watcherFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            vm.version = module.version;

            let purchInternalNo = $stateParams.purch_internal_no;
            let timerLoadComplete = null;
    
    		var watcher = watcherFactory.$create(vm);
    
            var reload = false;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                isChanged: false,
                loadComplete: false,
                mobileSize: window.matchMedia('(min-width: 992px)'),
                lapTopSize: window.matchMedia('(min-width: 1440px)'),
                deskTopSize: window.matchMedia('(min-width: 1920px)'),
                bgMain: {
                    selected: 'PURCHLINES',
                    buttons: [
                        { id: 'PURCHLINES', label: 'purchhead_gb_main_purchlines', color: 'primary', onClick: () => vm.model.bgMain.selected = 'PURCHLINES' },
                        { id: 'PURCHHEAD', label: 'purchhead_gb_main_purchhead', color: 'primary', onClick: () => vm.model.bgMain.selected = 'PURCHHEAD' },
                        { id: 'DOCUMENTS', label: 'purchhead_documents', color: 'primary', onClick: () => vm.model.bgMain.selected = 'DOCUMENTS' },
                        { id: 'FUNCTIONS', label: 'purchhead_functions', color: 'primary', onClick: () => vm.model.bgMain.selected = 'FUNCTIONS' }
                    ]
                },
                bgHead: {
                    selected: 'ADDRESS',
                    buttons: [
                        { id: 'ADDRESS', label: 'purchhead_address', color: 'primary', onClick: () => vm.model.bgHead.selected = 'ADDRESS' },
                        { id: 'TERMS', label: 'purchhead_terms', color: 'primary', onClick: () => vm.model.bgHead.selected = 'TERMS' },
                        { id: 'STAT', label: 'purchhead_stat', color: 'primary', onClick: () => vm.model.bgHead.selected = 'STAT' }
                    ]
                },
                bgAddress: {
                    selected: 'SUPPLIER',
                    buttons: [
                        { id: 'SUPPLIER', label: 'purchhead_gb_address_supplier', color: 'primary', onClick: () => vm.model.bgAddress.selected = 'SUPPLIER' },
                        { id: 'RECEIVER', label: 'purchhead_gb_address_receiver', color: 'primary', onClick: () => vm.model.bgAddress.selected = 'RECEIVER' }
                    ]
                },
                bgTerms: {
                    selected: 'DELIVERY',
                    buttons: [
                        { id: 'DELIVERY', label: 'purchhead_gb_terms_delivery', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'DELIVERY' },
                        { id: 'ECONOMY', label: 'purchhead_gb_terms_economy', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'ECONOMY' },
                        { id: 'OTHER', label: 'purchhead_gb_terms_other', color: 'primary', onClick: () => vm.model.bgTerms.selected = 'OTHER' }
                    ]
                },
                dateTimeFormat: {
                    date: { show: true, disabled: false, align: 'left' },
                    hour: { show: false },
                    minute: { show: false }
                },
                dateTimeFormatNullable: {
                    nullable: true,
                    date: { show: true, disabled: false, align: 'left' },
                    hour: { show: false },
                    minute: { show: false }
                },
                supplierNoButtons: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => stateService.go('customeredit', { cust_no: '0' }) },
                    { id: 'edit', icon: 'fa fa-solid fa-pen', color: 'primary', type: 'primary', onClick: () => stateService.go('customeredit', { cust_no: vm.model.edit.supplier_no }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.supplier_no }) }
                ],
                projectNameButtons: [
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectproject', { argtype: '-', argvalue: '-' }) }
                ],
                projectNameButtons2: [
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectproject', { argtype: '-', argvalue: '-' }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('projectmenu', { project_keyno: vm.model.edit.project_keyno }) }
                ],
                supplierPersonsButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customerpersons', { cust_no: vm.model.edit.supplier_no }) }
                ],
                supplierPersonsButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('supplier_pers_no') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customerpersons', { cust_no: vm.model.edit.supplier_no }) }
                ],
                freightRateSetsButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => {
                            purchHeadService.saveObj(vm.model.edit).then(function (response) {
                                if (response[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.error,
                                        message: response[0].errormessage,
                                        buttons: [{
                                            label: translations.ok,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    stateService.go('freightratesets', { cust_no: vm.model.edit.supplier_no });
                                }
                            });
                        }
                    }
                ],
                valutaExchangeRateNowButtons: [
                    {
                        id: 'list',
                        icon: 'fa fa-solid fa-bars',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => {
                            stateService.go('currencyexrates', {
                                valuta_id: vm.model.edit.basecurrency_id,
                                currencytype_keyno: '1'
                            });
                        }
                    }
                ],
                priceListButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.pricelist_id = '' }
                ],
                departmentsButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('departments') }
                ],
                departmentsButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('departments') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('department', { department_id: vm.model.edit.department_id }) }
                ],
                orderOrderNoButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('orderhead', { order_internal_no: vm.model.edit.order_internal_no }) }
                ],
                trackTraceUrlButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-link', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.edit.tracktrace_url) }
                ],
                consernOrderOrderNoButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('orderhead', { order_internal_no: vm.model.edit.consernorder_internal_no }) }
                ],
                edit: {},
                selectListCountries: [],
                selectListPortalUsers: [],
                selectListInitiatedBy: [],
                selectListDeliveryTerms: [],
                selectListPurchaseStatuses: [],
                selectListDeliveryMethods: [],
                selectListCustomerPersons: [],
                selectListStockPlaces: [],
                selectListDepartments: [],
                selectListRateSets: [],
                selectListFreightRates: [],
                selectListPrices: [],
                gridPurchaseLinesReady: false,
                gridDocumentsReady: false,
                updatingFreight: false,
                updatingPurchaseOrder: false,
                updatingCancelRest: false,
                convertingToChanges: false,
                lockedSave: false,
                lockedDelete: false
            };

            vm.gridPurchaseLines = new ttGridFactory({
                rememberId: 'w_purchhead.purchline_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            vm.gridDocuments = new ttGridFactory({
                rememberId: 'w_purchhead.documents_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD COMPLETE FUNCTION

            let setLoadComplete = function () {
                if (timerLoadComplete) clearTimeout(timerLoadComplete);

                timerLoadComplete = setTimeout(() => {
                    vm.model.loadComplete = true;
                }, 500);
            };

            // #endregion LOAD COMPLETE FUNCTION

            // #region LOAD PROCEDURE CALLS
            
            let loadEdit = function () {
                const deferred = $q.defer();

                purchHeadService.getEdit({ purch_internal_no: purchInternalNo }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                    stateService.setTitleOverride(vm.model.edit.purch_heading);
                    
                    loadDepartments();
                    loadStockPlaces();
                    loadCustomerPersons();
                    loadFreightRateSets();
                    loadFreightRateSelect();
                    loadPortalUsers();
                    loadGridPurchaseLines();
                    loadGridDocuments();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadCountries = function () {
                logisticService.listCountries().then(function (list) {
                    angular.copy(list, vm.model.selectListCountries);

                    setLoadComplete();
                });
            };
            
            let loadInitiatedBy = function () {
                purchHeadService.listInitiatedBy().then(function (list) {
                    angular.copy(list, vm.model.selectListInitiatedBy);

                    setLoadComplete();
                });
            };

            let loadDeliveryTerms = function () {
                logisticService.listDeliveryTerms().then(function (list) {
                    angular.copy(list, vm.model.selectListDeliveryTerms);

                    setLoadComplete();
                });
            };

            let loadPurchaseStatuses = function () {
                purchHeadsService.listPurchaseStatuses().then(function (list) {
                    angular.copy(list, vm.model.selectListPurchaseStatuses);

                    setLoadComplete();
                });
            };

            let loadDeliveryMethods = function () {
                logisticService.listDeliveryMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListDeliveryMethods);

                    setLoadComplete();
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (list) {
                    angular.copy(list, vm.model.selectListPrices);
                });
            };

            let loadDepartments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListDepartments);
                });
            };

            let loadPortalUsers = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listPortalUserPurchases({ businessco_no: vm.model.edit.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListPortalUsers);

                    setLoadComplete();
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };
            
            let loadCustomerPersons = function () {
                if (utilityService.validateParmsValue(vm.model.edit.supplier_no) !== true) return;
                
                return economyService.listCustomerPersons({ cust_no: vm.model.edit.supplier_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListCustomerPersons);
                });
            };
            
            let loadFreightRateSets = function () {
                if (utilityService.validateParmsValue(vm.model.edit.supplier_no) !== true) return;
                
                return purchHeadService.listFreightRateSets({ cust_no: vm.model.edit.supplier_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListRateSets);
                });
            };
            
            let loadFreightRateSelect = function () {
                if (utilityService.validateParmsValue(vm.model.edit.freightrateset_keyno) !== true) return;
                
                return purchHeadService.listFreightRates({ freightrateset_keyno: vm.model.edit.freightrateset_keyno }).then(function (list) {
                    angular.copy(list, vm.model.selectListFreightRates);
                });
            };

            let retrieveDepartmentInfo = function () {
                if (utilityService.validateParmsValue(vm.model.edit.department_id) !== true) return;

                return departmentService.getDepartment({ department_id: vm.model.edit.department_id }).then(function (response) {
                    if (response[0].delivery_cust_no > 0) {
                        vm.model.edit.delivery_cust_no = response[0].delivery_cust_no;
                        vm.model.edit.delivery_name = response[0].delivery_cust_name;
                        vm.model.edit.delivery_address = response[0].delivery_address;
                    }
                });
            };

            let retrieveProgression = function () {
                if (utilityService.validateParmsValue(vm.model.edit.purch_internal_no) !== true) return;

                if (vm.model.edit.show_progress_text_purchorder_to_salesorder === '1') {
                    return purchHeadService.progressToSalesOrder({ purch_internal_no: vm.model.edit.purch_internal_no }).then(function (response) {
                        vm.model.edit.progress_text_purchorder_to_salesorder = response[0].progress_text_purchorder_to_salesorder;
                    });
                }
            };

            let retrievePersonInfo = function () {
                if (utilityService.validateParmsValue(vm.model.edit.supplier_pers_no) !== true) return;

                return purchHeadService.getPersonInfo({ pers_no: vm.model.edit.supplier_pers_no }).then(function (response) {
                    vm.model.edit.supplier_pers_telephone = response[0].pers_telephones;
                    vm.model.edit.supplier_pers_email = response[0].pers_email;
                });
            };

            let retrieveConversion = function () {
                return purchHeadService.convertToPurchaseOrders(vm.model.edit).then(function () {
                    vm.model.isChanged = false;
                    vm.model.edit = {};
                    vm.model.loadComplete = false;
                    loadEdit();
                });
            };

            let retrieveFreightRate = function () {
                if (utilityService.validateParmsValue(vm.model.edit.freightrate_keyno) !== true) return;
    
                return purchHeadService.getFreightRate({ freightrate_keyno: vm.model.edit.freightrate_keyno }).then(function (response) {
                    vm.model.edit.freight_main_rate_locval = response[0].freight_main_rate_locval;
                    vm.model.edit.freight_last_rate_locval = response[0].freight_last_rate_locval;
                    vm.model.edit.customs_last_rate_locval = response[0].customs_last_rate_locval;
                });
            };
            
            let refreshFreight = function () {
                if (utilityService.validateParmsValue(vm.model.edit.purch_internal_no) !== true) return;
                
                return purchHeadService.updatePurchaseFreight({ purch_internal_no: vm.model.edit.purch_internal_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        loadGridPurchaseLines();
                    }
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridPurchaseLines = function () {
                vm.gridPurchaseLines.dataTask.loadData = {
                    method: 3000,
                    parameters: { purch_internal_no: vm.model.edit.purch_internal_no }
                };

                vm.model.gridPurchaseLinesReady = true;

                if (vm.gridPurchaseLines.gridfunc !== null) vm.gridPurchaseLines.gridfunc.read();
            };

            let loadGridDocuments = function () {
                vm.gridDocuments.dataTask.loadData = {
                    method: 3484,
                    parameters: { purch_internal_no: vm.model.edit.purch_internal_no }
                };

                vm.model.gridDocumentsReady = true;

                if (vm.gridDocuments.gridfunc !== null) vm.gridDocuments.gridfunc.read();
            };
            

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'supplier_pers_no':
                        vm.model.edit.supplier_pers_no = '0';

                        retrievePersonInfo();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## RECALCULATION FUNCTIONS ##
    
            var recalcValutaExchangeFactorLocked = function () {
                vm.model.edit.valuta_exchangefactor_locked = utilityService.parseNumber(vm.model.edit.valuta_exchangerate_locked) / utilityService.parseNumber(vm.model.edit.order_valuta_pr);
            };
    
            // ## UPDATE PURCHASE ORDER TO SALES ORDER FUNCTION ##

            vm.convertToChanges = function () {
                vm.model.convertingToChanges = true;

                purchHeadService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.convertingToChanges = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.convertingToChanges = false;
                        retrieveConversion();
                    }
                });
            };

            vm.updatePurchaseOrder = function () {
                if (utilityService.validateParmsValue(vm.model.edit.purch_internal_no) !== true) return;
    
                vm.model.updatingPurchaseOrder = true;
                vm.model.edit.show_progress_text_purchorder_to_salesorder = '1';
    
                // Påfølgjande kode er truleg overflødig sidan det er watch på show_progress_text_purchorder_to_salesorder.
                if (vm.model.edit.show_progress_text_purchorder_to_salesorder === '1') {
                    reload = purchHeadService.reloadProgressPurchHeadToSalesOrder('');
                }
    
                purchHeadService.convertToSalesOrders({ purch_internal_no: vm.model.edit.purch_internal_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingPurchaseOrder = false;
                                    vm.model.edit.show_progress_text_purchorder_to_salesorder = '0';
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingPurchaseOrder = false;
                        vm.model.edit.show_progress_text_purchorder_to_salesorder = '0';
                        loadEdit();
                    }
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'purchline':
                        go({
                            purch_internal_no: vm.model.edit.purch_internal_no,
                            purchline_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // ## REFRESH BUTTON FUNCTION ##
            
            vm.updateFreight = function () {
                vm.model.updatingFreight = true;
                
                purchHeadService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingFreight = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingFreight = false;
                        refreshFreight();
                    }
                });
            };
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                purchHeadService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    vm.model.isChanged = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedSave = false;
                        vm.model.isChanged = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    purchHeadService.deleteItem({ purch_internal_no: vm.model.edit.purch_internal_no }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region CANCEL REST BUTTON FUNCTION
    
            vm.cancelRest = function () {
                if (utilityService.validateParmsValue(vm.model.edit.purch_internal_no) !== true) return;
    
                vm.model.updatingCancelRest = true;
                
                purchHeadService.cancelRestPurchHead({ purch_internal_no: vm.model.edit.purch_internal_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingCancelRest = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingCancelRest = false;
                        loadEdit();
                    }
                });
            };
    
    		// #endregion CANCEL REST BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgMain = function (value) {
                vm.model.bgMain.selected = value;
            };

            vm.selectBgHead = function (value) {
                vm.model.bgHead.selected = value;
            };

            vm.selectBgAddress = function (value) {
                vm.model.bgAddress.selected = value;
            };

            vm.selectBgTerms = function (value) {
                vm.model.bgTerms.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'supplier_pers_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.supplier_pers_no) !== true) return;

                        vm.model.edit.supplier_pers_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.supplier_pers_no) !== true) return;

                        retrievePersonInfo();
                        break;
                    case 'department_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.department_id) !== true) return;

                        vm.model.edit.department_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.department_id) !== true) return;

                        retrieveDepartmentInfo();
                        break;
                    case 'freightrateset_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.freightrateset_keyno) !== true) return;

                        vm.model.edit.freightrateset_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.freightrateset_keyno) !== true) return;

                        loadFreightRateSelect();
                        break;
                    case 'freightrate_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.freightrate_keyno) !== true) return;

                        vm.model.edit.freightrate_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.freightrate_keyno) !== true) return;

                        retrieveFreightRate();
                        break;
                    case 'valuta_exchangerate_locked':
                        if (utilityService.validateWatchValue(value, vm.model.edit.valuta_exchangerate_locked) !== true) return;

                        vm.model.edit.valuta_exchangerate_locked = value;

                        if (utilityService.validateParmsValue(vm.model.edit.valuta_exchangerate_locked) !== true) return;

                        recalcValutaExchangeFactorLocked();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region WATCH FUNCTIONS
            
            vm.onDateChanged = function (value, modelId) {
                const id = modelId.substr(modelId.lastIndexOf('.') + 1);
                vm.model.edit[id] = value;
            };
            
            watcher.$watch(function () {
                return vm.model.edit;
            }, function (newVal, oldVal) {
                if (vm.model.loadComplete !== true)
                    return;
    
                var diff = utilityService.getDiff(newVal, oldVal);
    
                if (diff.length > 0) {
                    if (angular.isDefined(oldVal.purch_internal_no)) {
                        vm.model.isChanged = true;
                    }
                }
            }, true);
            
            watcher.$watch(function () { return vm.model.edit.show_progress_text_purchorder_to_salesorder; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                if (vm.model.edit.show_progress_text_purchorder_to_salesorder === '1') {
                    purchHeadService.reloaded = false;
                    reload = purchHeadService.reloadProgressPurchHeadToSalesOrder('');
                    retrieveProgression();
                }
            });
    
            watcher.$watch(function () { return purchHeadService.reloaded; }, function (newValue) {
                if (newValue === false) return;
    
                if (vm.model.edit.show_progress_text_purchorder_to_salesorder === '1') {
                    purchHeadService.reloaded = false;
                    reload = purchHeadService.reloadProgressPurchHeadToSalesOrder(Date.now());
                    retrieveProgression();
                }
            });
    
            // #endregion WATCH FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCountries();
                loadDeliveryTerms();
                loadDeliveryMethods();
                loadPurchaseStatuses();
                loadInitiatedBy();
                loadPrices();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
