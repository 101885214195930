import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../core/services/state.service';
import { ConsignmentPlansDeliveryService } from './consignment-plans-delivery.service';

interface MenuItem {
    item_id: string;
    item_name: string;
    glyph: string;
    color: string;
    colorActive?: string;
    canActive?: boolean;
    isActive?: boolean;
    isIcon?: boolean;
    enabled?: boolean;
    show?: boolean;
    animate?: boolean | null;
}

@Component({
    selector: 'app-consignment-plans-delivery',
    templateUrl: './consignment-plans-delivery.component.html',
    styleUrls: ['./consignment-plans-delivery.component.css']
})
export class ConsignmentPlansDeliveryComponent implements OnInit {

    model: any = {
        mconfig: {
            enabled: true,
            show: true,
            activate: (item: any) => {
                if (item === 'all') {
                    this.model.mconfig.records.forEach((data: any) => {
                        if (data && data.canActive === true) {
                            data.isActive = true;
                        }
                    });
                } else if (item && item.item_id) {
                    this.model.mconfig.records[item.item_id].isActive = false;
                } else if (item) {
                    for (var r = 0; r < this.model.mconfig.records.length; r++) {
                        if (this.model.mconfig.records[r].item_name === item && this.model.mconfig.records[r].canActive === true) {
                            this.model.mconfig.records[r].isActive = true;
                            break;
                        }
                    }
                }
            },
            deactivate: (item: any) => {
                if (item === 'all') {
                    this.model.mconfig.records.forEach((data: any) => {
                        if (data) {
                            if (data.item_name === 'resize' && data.isActive) {
                                this.consignmentPlansDeliveryColumnResize();
                            }
                            if (data.canActive === true) {
                                data.isActive = false;
                            }
                        }
                    });
                } else if (item && item.item_id) {
                    this.model.mconfig.records[item.item_id].isActive = false;
                } else if (item) {
                    for (var r = 0; r < this.model.mconfig.records.length; r++) {
                        if (this.model.mconfig.records[r].item_name === item) {
                            this.model.mconfig.records[r].isActive = false;
                            break;
                        }
                    }
                }
            },
            function: (item: any) => {
                if (item) {
                    if (item.canActive === true) {
                        item.isActive = !item.isActive;
                    }

                    if (item.item_name === 'settings') {
                        this.stateService.go('consignmentplansdeliverysettings', {});
                    }

                    if (item.item_name === 'refresh') {
                        this.stateService.go('consignmentplansdelivery', {
                            counter: 1
                        }, { reload: true, inherit: false });
                    }
                }

                /* RESIZING COLUMNS */
                if (item.item_name === 'resize') {
                    if (item.isActive) {
                        this.resizing = true;
                    } else {
                        this.consignmentPlansDeliveryColumnResize();
                    }
                }

                if (item.item_name === 'plus') {
                    this.stateService.go('consignmentplansorders', {
                        argtype: 'direct',
                        argvalue: 0
                    });
                }
            },
            records: <MenuItem[]>[
                { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: true },
                { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                { item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                { item_id: '5', item_name: 'refresh', glyph: 'glyphicon-refresh', color: 'primary', enabled: true, show: true },
                { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: false, show: true },
                { item_id: '7', item_name: 'edit', glyph: 'fa-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: true, enabled: false, show: false },
                { item_id: '10', item_name: 'uncheck', glyph: 'glyphicon-unchecked', color: 'primary', show: false },
                { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
            ]
        },
        plans: [],
        plansRaw: [],
        settings_mode: 0,
        deliverymethod_no: 0,
        deliverymethods: [],
        portal_user_keyno: '',
        portal_user_name: '',
        portalusers: [],
        loadingzone: '',
        loaded: false,
        labour_no: 0,
        labour_name: '',
        stocklocation_keyno: '',
        stocklocation_name: '',
    };

    resizing = false;
    editing = false;

    constructor(
        private consignmentPlansDeliveryService: ConsignmentPlansDeliveryService,
        private stateService: StateService
    ) { }

    async ngOnInit(): Promise<void> {
        await this.loadInitialData();
    }

    async loadInitialData(): Promise<void> {
        const settings = await this.consignmentPlansDeliveryService.loadSettings();

        this.model.labour_no = settings.labour_no;
        this.model.labour_name = settings.labour_name;
        this.model.stocklocation_keyno = settings.stocklocation_keyno;
        this.model.stocklocation_name = settings.stocklocation_name;

        this.model.deliverymethods = await this.consignmentPlansDeliveryService.loadDeliveryMethods();

        const plans = await this.consignmentPlansDeliveryService.loadPlans({});

        const smallDataLoad = plans.map((plan: any) => ({
            ...plan,
            item_id: this.illegalCharRegExp(plan.item_id)
        }));

        this.model.plans = smallDataLoad;
        this.model.plansRaw = smallDataLoad;
        this.model.loaded = true;
    }

    saveSettings(): void {
        this.consignmentPlansDeliveryService.remember('consignmentplansdelivery.deliverymethod_no', this.model.deliverymethod_no);
        this.consignmentPlansDeliveryService.remember('consignmentplansdelivery.loadingzone', this.model.loadingzone);
        this.model.settings_mode = this.model.settings_mode === 1 ? 0 : 1;
    }

    cancelSettings(): void {
        this.model.settings_mode = this.model.settings_mode === 1 ? 0 : 1;
    }

    illegalCharRegExp(str: string): string {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
    }

    goToSelectLabour(): void {
        this.stateService.go('consignmentplansdeliverylabour', {});
    }

    goToSelectLocation(): void {
        this.stateService.go('consignmentplansdeliverylocation', {});
    }

    gridClick(item: any): void {
        const baseItem = item.item ?? item;
        const currentItem = item.col !== null ? baseItem.item_secondary[item.col] : baseItem;

        if (baseItem.item_id !== 0) {
            if (currentItem && currentItem.item_func === 'car') {
                this.stateService.go('consignmentplansdeliverydriver', {
                    argtype: 'custact',
                    argvalue: baseItem.item_id
                });
            } else {
                this.stateService.go(baseItem.item_state, baseItem.item_parms); 
            }
        }
    }

    async consignmentPlansDeliveryColumnResize(): Promise<void> {
        const saveButton = this.model.mconfig.records.find((item: MenuItem) => item.item_id === '99');

        if (saveButton) saveButton.animate = true;

        const updateList = this.model.plans[0]?.item_secondary?.map((secItem: any, index: number) => ({
            item_id: index + 1,
            item_col_width: secItem.item_col_width
        })) || [];

        if (this.model.plans[0]) {
            updateList.unshift({ item_id: 0, item_col_width: this.model.plans[0].item_col_width });
        }

        const updateParams = { view_id: 'consignmentplansdelivery.list', records: updateList };

        const response = await this.consignmentPlansDeliveryService.updateColumnResize(updateParams);

        if (response[0]?.errorcode !== '0') {
            //modalService.show({
            //    type: 'warning',
            //    title: 'Varsel',
            //    message: data[0].errormessage,
            //    buttons: [{
            //        label: 'OK',
            //        cssClass: 'btn-warning',
            //        action: function (dialogItself) {
            //            dialogItself.close();
            //        }
            //    }]
            //});
        } else {
            if (saveButton) saveButton.animate = false;

            setTimeout(() => {
                if (saveButton) saveButton.animate = null;
            }, 2000);
        }

        this.resizing = false;
    }
}
