import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CoreComponentService, TextAlign } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { UserStore } from '@app/core/services/user.store';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-button-settings',
    templateUrl: './button-settings.component.html',
    styleUrls: ['./button-settings.component.css'],
})
export class ButtonSettingsComponent extends ButtonComponent {
    @Input()
    public override ttText: string = 'settings';

    @Input()
    public override ttType: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'link' = 'warning';

    @Input()
    public override ttIcon: string = 'far fa-cog';

    @Input()
    public override ttIconAlign: 'left' | 'right' = 'left';

    @Input()
    public override ttTextAlign?: TextAlign | undefined = 'left';

    @Output()
    public override ttClick: EventEmitter<MouseEvent> = new EventEmitter();

    public showSettings = false;

    constructor(layout: LayoutService, translate: TranslateService, coreComponents: CoreComponentService, private user: UserStore, private state: StateService) {
        super(layout, translate, coreComponents);
        this.user.showSettingsChanged.subscribe({ next: (value) => (this.showSettings = value) });
    }

    public onClick(event: MouseEvent) {
        this.ttClick.emit(event);

        if (event.defaultPrevented !== true) {
            this.state.go('webpagesettingmenu', { webpage_name: this.state.getCurrentName() });
        }
    }
}
