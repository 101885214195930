(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('invoiceMethod', {
        templateUrl: 'views/components/views/invoiceMethod/invoiceMethod.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'invoiceMethodService', function ($stateParams, stateService, translateService, modalService, invoiceMethodService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let invoiceMethodNo = $stateParams.invoicemethod_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                invoiceMethodNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('invoicemethodlangs', { invoicemethod_no: vm.model.edit.invoicemethod_no }) }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await invoiceMethodService.getEdit({ invoicemethod_no: invoiceMethodNo }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                invoiceMethodService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
