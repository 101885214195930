<tt-page *ngIf="get">
    <tt-card class="col-xs-12 p-4">
        <tt-input class="col-xs-12 sp-0" ttLabel="kpicomp_keyno" [(ttModel)]="get.kpicomp_keyno" ttReadonly></tt-input>
        <div *ngIf="kpiCompKeyno === '0'">
            <tt-input class="col-xs-12 sp-0" ttLabel="kpicomp_name" [(ttModel)]="get.kpicomp_name"></tt-input>
            <tt-textarea class="col-xs-12 sp-0" ttLabel="kpicomp_description" [(ttModel)]="get.kpicomp_description"></tt-textarea>
        </div>
        <div *ngIf="!!kpiCompKeyno && +kpiCompKeyno > 0">
            <tt-input-buttons class="col-xs-12 sp-0" ttLabel="kpicomp_name" [(ttModel)]="get.kpicomp_name" [ttButtons]="kpiCompNameButtons"></tt-input-buttons>
            <tt-input-buttons class="col-xs-12 sp-0" ttLabel="kpicomp_description" [(ttModel)]="get.kpicomp_description" [ttButtons]="kpiCompDescriptionButtons"></tt-input-buttons>
        </div>
        <tt-color-input class="col-xs-12 sp-0" ttLabel="tile_background_color" [(ttModel)]="get.tile_background_color" ttDebounceTime="50"></tt-color-input>
        <tt-input class="col-xs-12 sp-0" ttLabel="data1_text" [(ttModel)]="get.data1_text"></tt-input>
        <tt-select class="col-xs-12 sp-0" ttLabel="p2_webpage" [(ttModel)]="get.p2_webpage_keyno" [ttData]="webpages"></tt-select>
        <tt-input class="col-xs-12 sp-0" ttLabel="proc_name" [(ttModel)]="get.proc_name"></tt-input>
        <tt-checkbox class="col-xs-12 sp-0" ttLabel="is_public" [(ttModel)]="get.is_public"></tt-checkbox>
        <tt-select class="col-xs-12 sp-0" ttLabel="kpicomp_type" [(ttModel)]="get.kpicomp_type" [ttData]="kpiTypes" ttDataId="color_id" ttDataName="color_name"></tt-select>
        <tt-button-save class="col-xs-12 sp-0" ttText="kpicomp_save" [ttLoading]="saving" (ttClick)="save()"></tt-button-save>
    </tt-card>

    <tt-card class="col-xs-12 p-4" ttHeading="link" ttIcon="far fa-link">
        <tt-input ttLabel="goto_item_state" [(ttModel)]="get.goto_item_state"></tt-input>
        <tt-input ttLabel="goto_item_parms" [(ttModel)]="get.goto_item_parms"></tt-input>
    </tt-card>

    <tt-card class="col-xs-12 p-4" ttHeading="tile_heading_text" ttIcon="far fa-h1">
        <tt-input ttLabel="tile_heading_text" [(ttModel)]="get.tile_heading_text"></tt-input>
        <tt-color-input ttLabel="tile_heading_text_color" [(ttModel)]="get.tile_heading_text_color" tt-debounce-time="50"></tt-color-input>
        <tt-select ttLabel="fontsize" [(ttModel)]="get.tile_heading_text_size" [ttData]="fontSizes"></tt-select>
    </tt-card>

    <tt-card class="col-xs-12 p-4" ttHeading="tile_subheading_text" ttIcon="far fa-h2">
        <tt-input ttLabel="tile_subheading_text" [(ttModel)]="get.tile_subheading_text"></tt-input>
        <tt-color-input ttLabel="tile_subheading_text_color" [(ttModel)]="get.tile_subheading_text_color" ttDebounceTime="50"></tt-color-input>
        <tt-select ttLabel="fontsize" [(ttModel)]="get.tile_subheading_text_size" [ttData]="fontSizes"></tt-select>
    </tt-card>

    <tt-card class="col-xs-12 p-4" ttHeading="item_glyphicon" ttIcon="far fa-icons">
        <tt-input class="kpicomp__heading-input" ttLabel="item_glyphicon" [(ttModel)]="get.item_glyphicon"></tt-input>
        <tt-color-input ttLabel="item_glyphicon_color" [(ttModel)]="get.item_glyphicon_color" ttDebounceTime="50"></tt-color-input>
        <tt-select ttLabel="fontsize" [(ttModel)]="get.item_glyphicon_size" [ttData]="fontSizes"></tt-select>
    </tt-card>
    <tt-grid *ngIf="gridReady" class="col-xs-12 sp-0 flex-1" [(ttOptions)]="grid"></tt-grid>
</tt-page>
