(function () {
	'use strict';

    const module = angular.module('imApp');

    module.component('payCheckBatch', {
        templateUrl: 'views/components/views/payCheckBatch/payCheckBatch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'base64', 'modalService', 'translateService', 'payCheckBatchService', 'logisticService', 'emailCreateService', 'ttGridFactory', 'graphAuthService', 'graphMailService', 'rememberService', function ($stateParams, $q, stateService, utilityService, base64, modalService, translateService, payCheckBatchService, logisticService, emailCreateService, ttGridFactory, graphAuthService, graphMailService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let payCheckBatchKeyno = $stateParams.paycheckbatch_keyno;

            let variableNames = {
                gb_paycheckbatch_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                emailTemplateButtons1: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplates') },
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplate', { emailtemplate_keyno: '0' }) }
                ],
                emailTemplateButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplates') },
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplate', { emailtemplate_keyno: '0' }) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplate', { emailtemplate_keyno: vm.model.edit.emailtemplate_keyno }) }
                ],
                bgPayCheckBatchMain: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'gb_paycheckbatch_main_basic', color: 'primary', onClick: () => setPayCheckBatchMainView('BASIC') },
                        { id: 'PAYCHECKS', label: 'gb_paycheckbatch_main_paychecks', color: 'primary', onClick: () => setPayCheckBatchMainView('PAYCHECKS') },
                        { id: 'SALARYTOEXCEL', label: 'gb_paycheckbatch_main_salarytoexcel', color: 'primary', onClick: () => setPayCheckBatchMainView('SALARYTOEXCEL') },
                        { id: 'EXCEL', label: 'gb_paycheckbatch_main_excel', color: 'primary', onClick: () => setPayCheckBatchMainView('EXCEL') },
                        { id: 'SENDMAIL', label: 'gb_paycheckbatch_main_sendmail', color: 'primary', onClick: () => setPayCheckBatchMainView('SENDMAIL') },
                        { id: 'FUNCTIONS', label: 'gb_paycheckbatch_main_functions', color: 'primary', onClick: () => setPayCheckBatchMainView('FUNCTIONS') }
                    ]
                },
                edit: {},
    			selectListCompanies: [],
                selectListEmailTemplates: [],
                gridPayCheckReady: false,
                gridSpecificationsReady: false,
                gridAccountingSystemsReady: false,
                gridSendMailsReady: false,
    			exportingToFile: false,
    			updatingPayCheck: false,
                lockedSave: false
    		};

            vm.gridPayCheck = new ttGridFactory({
                rememberId: 'paycheck_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            vm.gridSpecifications = new ttGridFactory({
                rememberId: 'paycheckbatch_spesification_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridAccountingSystems = new ttGridFactory({
                rememberId: 'w_paycheckbatch_to_accountingsystem_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridSendMails = new ttGridFactory({
                rememberId: 'w_paycheckbatch_paycheck_sendmail_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'paycheckbatch_send_email_to_selected',
                text: 'paycheckbatch_send_email_to_selected',
                func: () => sendToSelectedRows(),
                icon: 'fa-reply-all',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPayCheckBatchMainView = function (id) {
                vm.model.bgPayCheckBatchMain.selected = vm.model.edit.gb_paycheckbatch_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                payCheckBatchService.getEdit({ paycheckbatch_keyno: payCheckBatchKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                    setPayCheckBatchMainView(vm.model.edit.gb_paycheckbatch_main);

                    loadGridPayCheck();
                    loadGridSpecifications();
                    loadGridAccountingSystems();
                    loadGridSendMails();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadEmailTemplates = function () {
                emailCreateService.listEmailTemplates({ webpage: 'paycheckbatch' }).then(function (list) {
                    angular.copy(list, vm.model.selectListEmailTemplates);
                });
            };

    		let updatePayCheck = function () {
    			vm.model.updatingPayCheck = true;
                
                payCheckBatchService.updateCheck({ paycheckbatch_keyno: vm.model.edit.paycheckbatch_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingPayCheck = false;
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        vm.model.updatingPayCheck = false;
                        
                        loadGridPayCheck();
                    }
    			});
    		};

            // #endregion LOAD PROCEDURE CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridPayCheck = function () {
                vm.gridPayCheck.dataTask.loadData = {
                    method: 3494,
                    parameters: vm.model.edit
                };

                vm.model.gridPayCheckReady = true;

                if (vm.gridPayCheck.gridfunc !== null) vm.gridPayCheck.gridfunc.read();
            };

            let loadGridSpecifications = function () {
                vm.gridSpecifications.dataTask.loadData = {
                    method: 3442,
                    parameters: vm.model.edit
                };

                vm.model.gridSpecificationsReady = true;

                if (vm.gridSpecifications.gridfunc !== null) vm.gridSpecifications.gridfunc.read();
            };

            let loadGridAccountingSystems = function () {
                vm.gridAccountingSystems.dataTask.loadData = {
                    method: 3443,
                    parameters: vm.model.edit
                };

                vm.model.gridAccountingSystemsReady = true;

                if (vm.gridAccountingSystems.gridfunc !== null) vm.gridAccountingSystems.gridfunc.read();
            };

            let loadGridSendMails = function () {
                vm.gridSendMails.dataTask.loadData = {
                    method: 3495,
                    parameters: vm.model.edit
                };

                vm.model.gridSendMailsReady = true;

                if (vm.gridSendMails.gridfunc !== null) vm.gridSendMails.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_paycheckbatch_main':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPayCheckBatchMain = function (value) {
                vm.model.bgPayCheckBatchMain.selected = value;
                vm.model.edit.gb_paycheckbatch_main = vm.model.bgPayCheckBatchMain.selected;

                rememberFunc('gb_paycheckbatch_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// #region UPDATE BUTTON FUNCTION
            
    		vm.exportToFile = function () {
    			vm.model.exportingToFile = true;
                
                payCheckBatchService.exportToPowerOffice({ paycheckbatch_keyno: vm.model.edit.paycheckbatch_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.exportingToFile = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.exportingToFile = false;

                        var elem = document.getElementById('formPayCheckBatchLink');
                        var text = base64.urldecode(response[0].filecontent);

                        var txtData = new Blob([text], { type: 'text/txt' });
                        var txtUrl = URL.createObjectURL(txtData);

                        elem.setAttribute('href', txtUrl);
                        elem.setAttribute('download', response[0].filename);
                        elem.click();
                    }
                });
    		};

            vm.updateChanges = function () {
    			if (vm.model.lockedSave === true) {
    				return;
    			}
    			
    			vm.model.lockedSave = true;
                
                payCheckBatchService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        updatePayCheck();
                    }
                });
    		};

            // #endregion UPDATE BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'paycheckbatch_keyno',
                            argvalue: vm.model.edit.paycheckbatch_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                payCheckBatchService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region GET SELECTED ROWS FUNCTION

            function getSelectedRows() {
                return vm.gridSendMails?.gridfunc?.getAllRows().filter((row) => row.is_selected === true) ?? [];
            }

            // #endregion GET SELECTED ROWS FUNCTION

            // #region SEND TO SELECTED ROWS FUNCTION

            function sendToSelectedRows() {
                let progress = {
                    runProgress: 'START',
                    label: 'progressbar',
                    field: '',
                    max: getSelectedRows()?.length ?? 0,
                    min: 0,
                    step: 0,
                    finishclose: '1',
                    abortclose: false,
                }

                modalService.progressInfo.max = getSelectedRows()?.length;

                modalService.showProgress(progress).then(function (value) {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') {
                        console.log('ABORT');
                    } else if (progress.runProgress === 'FINISH') {
                        loadGridSendMails();
                    }
                });

                (async () => {
                    let useGraphEmail = await graphAuthService.isAuthenticated();
                    let graphUserName = useGraphEmail ? (await graphAuthService.getAccount()).username : '';
                    
                    getSelectedRows()?.forEach(async (row) => {
                        let email = await getEmailContent(row.paycheck_keyno);

                        if (useGraphEmail) {
                            email.from_email = graphUserName;
                        }

                        await sendEmail(email, useGraphEmail);

                        modalService.progressInfo.step++;
                        progress.step++;
                    })
                })();
            }

            // #endregion SEND TO SELECTED ROWS FUNCTION

            // #region GET EMAIL CONTENT FUNCTION CALL

            async function getEmailContent(payCheckKeyno) {
                return (await emailCreateService.getEmailCreate({ webpage: 'paycheckbatch', argtype: 'paycheck_keyno', argvalue: payCheckKeyno }))[0];
            }

            // #endregion GET EMAIL CONTENT FUNCTION CALL

            // #region SEND EMAIL FUNCTION CALL

            async function sendEmail(ttEmail, useGraphEmail) {
                try {
                    ttEmail.send_by_client = useGraphEmail;
                    ttEmail.email_heading = base64.urlencode(ttEmail.email_heading);
                    ttEmail.email_signature = base64.urlencode(ttEmail.email_signature);

                    const data = await emailCreateService.sendEmail(ttEmail);

                    if (data?.mailinfo && data.mailinfo.length > 0) {
                        data.mailinfo.forEach((mailinfo) => {
                            graphMailService.sendMessage({
                                subject: base64.urldecode(mailinfo.subject),
                                content: base64.urldecode(mailinfo.bodyHtml),
                                recipients: mailinfo.to,
                                ccRecipients: mailinfo.cc,
                                bccRecipients: mailinfo.bcc,
                                attachments: mailinfo.attachments,
                                userId: mailinfo.from
                            });
                        })
                    }
                } catch (error) {

                }
            }

            // #endregion SEND EMAIL FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadEmailTemplates();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
