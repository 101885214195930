(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("productCreateService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 672,
                    parameters: parms || {}
                });
            },
            getProductSetting: function (parms) {
                return $ihttp.post({
                    method: 674,
                    parameters: parms || {}
                });
            },
            getCopyFrom: function (parms) {
                return $ihttp.post({
                    method: 867,
                    parameters: parms || {}
                });
            },
            listProductSettingMcs: function (parms) {
                return $ihttp.post({
                    method: 3065,
                    parameters: parms || {}
                });
            },
            getSelectListProductSettingMcs: function (parms) {
                return $ihttp.post({
                    method: 3568,
                    parameters: parms || {}
                });
            },
            listUnits: function (parms) {
                return $ihttp.post({
                    method: 3046,
                    parameters: parms || {}
                });
            },
            listProductGroup3s: function (parms) {
                return $ihttp.post({
                    method: 3085,
                    parameters: parms || {}
                });
            },
            listProductGroup4s: function (parms) {
                return $ihttp.post({
                    method: 3086,
                    parameters: parms || {}
                });
            },
            listProductTypes: function (parms) {
                return $ihttp.post({
                    method: 219,
                    parameters: parms || {}
                });
            },
            calcSalesPrice: function (edit) {
                return $ihttp.post({
                    method: 3095,
                    parameters: edit
                });
            },
            getExistingSetting: function (edit) {
                return $ihttp.post({
                    method: 3096,
                    parameters: edit
                });
            },
            getProductId: function (edit) {
                return $ihttp.post({
                    method: 3372,
                    parameters: edit
                });
            },
            getNextNumber: function (productsettingmc_keyno) {
                return $ihttp.post({
                    method: 3558,
                    parameters: {
                        productsettingmc_keyno: productsettingmc_keyno
                    }
                });
            },
            createObj: function (edit) {
                return $ihttp.post({
                    method: 677,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
