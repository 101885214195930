(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('priceListPrice', {
        templateUrl: 'views/components/views/priceListPrice/priceListPrice.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$localStorage', '$stateParams', 'utilityService', 'translateService', 'priceListPriceService', 'logisticService', function ($q, $localStorage, $stateParams, utilityService, translateService, priceListPriceService, logisticService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                pricelistprice_recalc_processing: ''
            };

            vm.model = {
                bgPriceListPrice1: {
                    selected: 'SETTINGS',
                    buttons: [
                        { id: 'SETTINGS', label: 'pricelistprice_settings', color: 'primary', onClick: () => vm.model.bgPriceListPrice1.selected = 'SETTINGS' },
                        { id: 'FACTORS', label: 'pricelistprice_factors', color: 'primary', onClick: () => vm.model.bgPriceListPrice1.selected = 'FACTORS' },
                        { id: 'APPROVE', label: 'pricelistprice_approve_gbtext', color: 'primary', onClick: () => vm.model.bgPriceListPrice1.selected = 'APPROVE' }
                    ]
                },
                supplierButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.supplier_no = '' }
                ],
                priceTypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.pricelist_id_cost = '' }
                ],
                priceList1Buttons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.pricelist_id1 = '' }
                ],
                priceList2Buttons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.pricelist_id2 = '' }
                ],
                priceList3Buttons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.pricelist_id3 = '' }
                ],
                valueTypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.valuetype_id = '' }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => { return; } }
                ],
                setting: {},
                selectListSuppliers: [],
                selectListPriceTypes: [],
                selectListPriceTypes2: [],
                selectListPriceTypes3: [],
                selectListPriceTypes4: [],
                selectListPriceTypes5: [],
                selectListPriceTypes6: [],
                selectListPriceTypes7: [],
                selectListPriceTypes8: [],
                selectListValueTypes: [],
                itemsListPriceRelatedToPriceLists: {
                    records: [],
                    pageItems: []
                },
                inputListPriceListFactors: [],
                itemsListSelectPurchaseLines: [],
                showSelectAll: true,
                showUnselectAll: false,
                showApproveAndRegret: false,
                retrievingPriceLists: false,
                recalculatingPriceLists: false,
                approvingPriceLists: false,
                regretingPriceLists: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION FUNCTION

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATION FUNCTION

            utilityService.setDefaultValues($localStorage, {
                priceListPriceCtrl: {
                    page: 1,
                    pageSize: 25
                }
            });
            
            vm.$storage = $localStorage;
            
            vm.pageChanged = function () {
                var page = vm.$storage.priceListPriceCtrl.page;
                var pageSize = vm.$storage.priceListPriceCtrl.pageSize;
                
                var pagedData = vm.model.itemsListPriceRelatedToPriceLists.records.slice(
                    (page - 1) * pageSize,
                    page * pageSize
                );
                
                angular.copy(pagedData, vm.model.itemsListPriceRelatedToPriceLists.pageItems);
            };
            
            vm.setPageSize = function (size) {
                vm.$storage.priceListPriceCtrl.pageSize = size;
                
                vm.pageChanged();
            };
            
            // #region LOAD PROCEDURES

            let loadSetting = function () {
                const deferred = $q.defer();
                
                priceListPriceService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };

            let loadSuppliers = function () {
                const deferred = $q.defer();

                logisticService.listSuppliers().then(function (list) {
                    angular.copy(list, vm.model.selectListSuppliers);

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadPriceTypes = function () {
                const deferred = $q.defer();
                
                priceListPriceService.listPriceTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListPriceTypes);
                    angular.copy(list, vm.model.selectListPriceTypes2);
                    angular.copy(list, vm.model.selectListPriceTypes3);
                    angular.copy(list, vm.model.selectListPriceTypes4);
                    angular.copy(list, vm.model.selectListPriceTypes5);
                    angular.copy(list, vm.model.selectListPriceTypes6);
                    angular.copy(list, vm.model.selectListPriceTypes7);
                    angular.copy(list, vm.model.selectListPriceTypes8);
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };
            
            let loadValueTypes = function () {
                const deferred = $q.defer();
                
                priceListPriceService.listValueTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListValueTypes);
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };
            
            let loadSelectPurchaseLines = function () {
                const deferred = $q.defer();
                
                priceListPriceService.listSelectPurchaseLines().then(function (list) {
                    angular.copy(list, vm.model.itemsListSelectPurchaseLines);
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };
            
            let loadPriceListFactors = function () {
                const deferred = $q.defer();
                
                priceListPriceService.listPriceListFactors({
                    argtype: 'edit',
                    argvalue: 'edit'
                }).then(function (list) {
                    angular.copy(list, vm.model.inputListPriceListFactors);
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };
            
            let loadPriceRelatedToPriceLists = function () {
                const deferred = $q.defer();
                let pobject = {};
                
                angular.copy(vm.model.setting, pobject);
                pobject.argtype = argType;
                pobject.argvalue = argValue;

                vm.model.itemsListPriceRelatedToPriceLists.records = [];

                return priceListPriceService.listPriceRelatedToPriceLists(pobject).then(function (list) {
                    vm.model.retrievingPriceLists = false;
                    angular.copy(list, vm.model.itemsListPriceRelatedToPriceLists.records);
                    
                    vm.pageChanged();
                    deferred.resolve();
                });
                
                return deferred.promise;
            };

            let recalculateProductPrices = function () {
                const deferred = $q.defer();
                vm.model.setting.statusmessage = translations.pricelistprice_recalc_processing;
                
                return priceListPriceService.recalculateItems({
                    items: vm.model.inputListPriceListFactors,
                    listitems: vm.model.itemsListPriceRelatedToPriceLists.records
                }).then(function () {
                    vm.model.recalculatingPriceLists = false;
                    vm.model.setting.statusmessage = '';

                    loadPriceRelatedToPriceLists();
                    deferred.resolve();
                });
                
                return deferred.promise;
            };
            
            let activatePlannedPriceToActivePrice = function (action) {
                const deferred = $q.defer();
                
                return priceListPriceService.approveProductPrices({
                    items: vm.model.itemsListSelectPurchaseLines,
                    listitems: vm.model.itemsListPriceRelatedToPriceLists.records,
                    way: action
                }).then(function () {
                    vm.model.approvingPriceLists = false;
                    vm.model.regretingPriceLists = false;

                    loadPriceRelatedToPriceLists();
                    deferred.resolve();
                });
                
                return deferred.promise;
            };

            // #endregion LOAD PROCEDURES

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPriceListPrice1 = function (value) {
                vm.model.bgPriceListPrice1.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ITEM IS SELECTED FUNCTION

            let itemIsSelectedFunc = function (item) {
                if (item.item_id === '0' || item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                    item.item_glyphicon_2 = 'glyphicon-check';
                    item.item_glyphicon_color = 'green';
                    vm.model.showUnselectAll = true;
                    vm.model.showApproveAndRegret = true;
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon = 'glyphicon-unchecked';
                    item.item_glyphicon_color = 'black';
                    //vm.model.showApproveAndRegret = false;
                }
            };

            // #endregion ITEM IS SELECTED FUNCTION

            // #region TOGGLE LIST MULTI FUNCTIONS

            vm.toggleChosen = function (id, item) {
                item = angular.isDefined(item.item) ? item.item : item;

                switch (id) {
                    case 'itemsListSelectPurchaseLines':
                        itemIsSelectedFunc(item);
                        break;
                    default:
                        break;
                }
            };

            // #endregion TOGGLE LIST MULTI FUNCTIONS

            // #region ALL IS SELECTED FUNCTION

            let allIsSelectedFunc = function (id) {
                switch (id) {
                    case 'itemsListSelectPurchaseLines':
                        angular.forEach(vm.model.itemsListSelectPurchaseLines, function (item) {
                            if (item.item_id === '0' || item.item_id === '-1') return;

                            item.item_is_selected = '1';
                            item.item_glyphicon_2 = 'glyphicon-check';
                            item.item_glyphicon_color = 'green';
                            vm.model.showSelectAll = false;
                            vm.model.showUnselectAll = true;
                            vm.model.showApproveAndRegret = true;
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion ALL IS SELECTED FUNCTION

            // #region SELECT ALL LIST MULTI FUNCTIONS

            vm.selectAll = function (id) {
                switch (id) {
                    case 'itemsListSelectPurchaseLines':
                        allIsSelectedFunc(id);
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECT ALL LIST MULTI FUNCTIONS

            // #region ALL IS SELECTED FUNCTION

            let allIsUnSelectedFunc = function (id) {
                switch (id) {
                    case 'itemsListSelectPurchaseLines':
                        angular.forEach(vm.model.itemsListSelectPurchaseLines, function (item) {
                            if (item.item_id === '0' || item.item_id === '-1') return;

                            item.item_is_selected = '0';
                            item.item_glyphicon = 'glyphicon-unchecked';
                            item.item_glyphicon_color = 'black';
                            vm.model.showSelectAll = true;
                            vm.model.showUnselectAll = false;
                            vm.model.showApproveAndRegret = false;
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion ALL IS SELECTED FUNCTION

            // #region UNSELECT ALL LIST BUTTON MULTI FUNCTIONS

            vm.unselectAll = function (id) {
                switch (id) {
                    case 'itemsListSelectPurchaseLines':
                        allIsUnSelectedFunc(id);
                        break;
                    default:
                        break;
                }
            };

            // #endregion UNSELECT ALL LIST BUTTON MULTI FUNCTIONS

            // #region RETRIEVE BUTTON FUNCTION

            vm.retrievePriceLists = function () {
                vm.model.retrievingPriceLists = true;
                loadPriceRelatedToPriceLists();
            };

            // #endregion RETRIEVE BUTTON FUNCTION

            // #region RECALCULATE BUTTON FUNCTION
            
            vm.recalculatePriceLists = function () {
                vm.model.recalculatingPriceLists = true;
                recalculateProductPrices();
            };
            
            vm.approvePriceLists = function () {
                vm.model.approvingPriceLists = true;
                activatePlannedPriceToActivePrice('APPROVE');
            };
            
            vm.regretPriceLists = function () {
                vm.model.regretingPriceLists = true;
                activatePlannedPriceToActivePrice('ROLLBACK');
            };

            // #endregion RECALCULATE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            let load = function () {
                let promises = [];
                
                promises.push(loadSetting());
                promises.push(loadSuppliers());
                promises.push(loadPriceTypes());
                promises.push(loadValueTypes());
                promises.push(loadSelectPurchaseLines());
                promises.push(loadPriceListFactors());
                
                $q.all(promises).then(function () {
                    vm.retrievePriceLists();
                });
            };
            
            vm.$onInit = function () {
                load();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
