(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockTransferReception', {
        templateUrl: 'views/components/views/stockTransferReception/stockTransferReception.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'stockTransferReceptionService', 'layoutService', function ($stateParams, $q, stateService, utilityService, stockTransferReceptionService, layoutService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let stockLocactionKeyno = $stateParams.stocklocation_keyno;
            
            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                bgStockTransferReceptionFunction: {
                    selected: 'ONLOCATION',
                    buttons: [
                        { id: 'ONLOCATION', label: 'bg_stocktransferreception_function_onlocation', color: 'primary', onClick: () => vm.model.bgStockTransferReceptionFunction.selected = 'ONLOCATION' },
                        { id: 'STARTED', label: 'bg_stocktransferreception_function_started', color: 'primary', onClick: () => vm.model.bgStockTransferReceptionFunction.selected = 'STARTED' },
                        { id: 'NEW', label: 'bg_stocktransferreception_function_new', color: 'primary', onClick: () => vm.model.bgStockTransferReceptionFunction.selected = 'NEW' }
                    ]
                },
                setting: {},
                selectListPicking: [],
                itemsListStockTransfers: [],
                itemsListOnLocations: [],
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS
            
            let loadSetting = function () {
                const deferred = $q.defer();

                stockTransferReceptionService.getSetting({ stocklocation_keyno: stockLocactionKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadPicking();
                    loadStockTransfers();
                    loadOnLocations();

                    deferred.resolve();
                });

                return deferred.promise;
            };
    		
            let loadPicking = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return stockTransferReceptionService.listPicking({ stockplace_id: vm.model.setting.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListPicking);
                });
            };
            
            let loadStockTransfers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stocklocation_keyno) !== true) return;
                
                return stockTransferReceptionService.listStockTransfers({ stocklocation_keyno: vm.model.setting.stocklocation_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockTransfers);
                });
            };
            
            let loadOnLocations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stocklocation_keyno) !== true) return;
                
                return stockTransferReceptionService.listOnLocations({ stocklocation_keyno: vm.model.setting.stocklocation_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOnLocations);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockTransferReceptionFunction = function (value) {
                vm.model.bgStockTransferReceptionFunction.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region CREATE NEW BUTTON FUNCTION
            
            vm.createNewChanges = function () {
                vm.model.lockedCreate = true;

                stockTransferReceptionService.createNewItem({
                    stocklocation_keyno: vm.model.setting.stocklocation_keyno,
                    stocklocation_keyno_create: vm.model.setting.stocklocation_keyno_create
                }).then(function (response) {
                    vm.model.lockedCreate = false;
                    stateService.go('stocktransfer', { stocktransfer_keyno: response[0].stocktransfer_keyno });
                }, function () {
                    vm.model.lockedCreate = false;
                });
            };

            // #endregion CREATE NEW BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
