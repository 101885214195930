(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageSubscription', {
        templateUrl: 'views/components/views/webpageSubscription/webpageSubscription.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'webpageSubscriptionService', 'layoutService', function ($stateParams, webpageSubscriptionService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let tabletSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                tabletSize: tabletSize,
                itemState: '',
                p2WebpageSubscriptionKeyno: '',
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListWebPageSubscriptions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                webpageSubscriptionService.getWebPageSubscription().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadWebPageSubscriptions = function () {
                webpageSubscriptionService.listWebPageSubscriptions({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebPageSubscriptions);

                    angular.forEach(vm.model.itemsListWebPageSubscriptions, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadWebPageSubscriptions();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2WebpageSubscriptionKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadWebPageSubscriptions();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
