(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('shiftDay', {
        templateUrl: 'views/components/views/shiftDay/shiftDay.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'shiftDayService', 'shiftService', 'layoutService', function ($stateParams, stateService, shiftDayService, shiftService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let shiftId = $stateParams.shift_id;
            let dayNo = $stateParams.dayno;
            
            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                bgTimeBreak: {
                    selected: 'workhours',
                    buttons: [
                        { id: 'workhours', label: 'workhours', color: 'primary', onClick: () => vm.model.bgTimeBreak.selected = 'workhours' },
                        { id: 'break', label: 'break', color: 'primary', onClick: () => vm.model.bgTimeBreak.selected = 'break' }
                    ]
                },
                menu: {
                    shift_id: shiftId,
                    dayno: dayNo,
                    dayname: '',
                    time_used: ''
                },
                itemsListShiftHours: [],
                itemsListShiftBreaks: []
        	};

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadMenu = function () {
                let itemsListWeekDays = [];

                shiftService.listWeekDays({ shift_id: shiftId }).then(function (list) {
                    angular.forEach(list, function (item) {
                        itemsListWeekDays[item.item_id] = item;
                    });

                    vm.model.menu.dayname = itemsListWeekDays[dayNo].item_name;
                    vm.model.menu.time_used = itemsListWeekDays[dayNo].item_timeused.replace('.000', '');
                });
            };

            let loadShiftHours = function () {
                shiftDayService.listShiftHours({
                    shift_id: shiftId,
                    dayno: dayNo
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListShiftHours);
                });
            };
            
            let loadShiftBreaks = function () {
                shiftDayService.listShiftBreaks({
                    shift_id: shiftId,
                    dayno: dayNo
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListShiftBreaks);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgTimeBreak = function (value) {
                vm.model.bgTimeBreak.selected = value;

                if (vm.model.bgTimeBreak.selected === 'workhours') {
                    loadShiftHours();
                } else {
                    loadShiftBreaks();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shiftdaytime':
                        go({
                            shifttime_keyno: '0',
                            shift_id: shiftId,
                            dayno: dayNo
                        });
                        break;
                    case 'shiftdaybreak':
                        go({
                            shiftbreak_keyno: '0',
                            shift_id: shiftId,
                            dayno: dayNo
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
                loadShiftHours();
                loadShiftBreaks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
