(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2UpdateScriptEdit', {
        templateUrl: 'views/components/views/p2UpdateScriptEdit/p2UpdateScriptEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
        	p2UpdateScriptKeyno: '<?',
        	onSave: '&'
        },
        controller: ['$stateParams', 'stateService', 'p2UpdateScriptEditService', 'p2UpdateScriptsService', 'layoutService', function ($stateParams, stateService, p2UpdateScriptEditService, p2UpdateScriptsService, layoutService) {
            const vm = this;
            let p2UpdateScriptKeyno = vm?.p2UpdateScriptKeyno ?? $stateParams.p2_updatescript_keyno;
            let mobileSize = layoutService.getMediaQueries().tablet;

            vm.model = {
                mobileSize: mobileSize,
                edit: {},
                selectListRunBatches: [],
                lockedSave: false
            };

            let loadEdit = function () {
                p2UpdateScriptEditService.getUpdateScript({ p2_updatescript_keyno: p2UpdateScriptKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            let loadRunBatches = function () {
                p2UpdateScriptsService.listRunBatches({ add_all: '0' }).then(function (list) {
                    angular.copy(list, vm.model.selectListRunBatches);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2UpdateScriptEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    
                    if (angular.isFunction(vm.onSave) && mobileSize.matches === true) {
                        vm.onSave();
                    } else {
                        stateService.back();
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                //loadEdit();
                //loadRunBatches();
            };

            vm.$onChanges = function (changes) {
                if (changes?.p2UpdateScriptKeyno?.currentValue) p2UpdateScriptKeyno = changes.p2UpdateScriptKeyno.currentValue;

                if (p2UpdateScriptKeyno) {
                    loadEdit();
                    loadRunBatches();
                }
            };
        }]
    });
})();
