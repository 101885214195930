(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('interBranchMultiGet', {
        templateUrl: 'views/components/views/interBranchMultiGet/interBranchMultiGet.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'interBranchMultiGetService', function ($stateParams, utilityService, interBranchMultiGetService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let interBranchMultiKeyno = $stateParams.interbranchmulti_keyno;
            
            vm.model = {
                menu: {},
                itemsListLevels: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await interBranchMultiGetService.getMenu({ interbranchmulti_keyno: interBranchMultiKeyno }))[0];

                loadLevels();
            }

            async function loadLevels() {
                if (utilityService.validateParmsValue(vm.model.menu.interbranchmulti_keyno) !== true) return;

                vm.model.itemsListLevels = (await interBranchMultiGetService.listLevels({ interbranchmulti_keyno: vm.model.menu.interbranchmulti_keyno }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
