(function () {
    'use strict';

    angular.module("imApp").factory("selectOperationPuService", ['$ihttp', function ($ihttp) {
        let service = {
            listOperations: function (parms) {
                return $ihttp.post({
                    method: 199,
                    parameters: parms || {}
                });
            },
            saveItem: function (item) {
                return $ihttp.post({
                    method: 200,
                    parameters: item
                });
            }
        };

        return service;
    }]);
})();
