(function() {
    'use strict';

    angular.module("imApp").factory("requisitionSimplifiedService", ['$ihttp', function ($ihttp) {
        var service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3505,
                    parameters: parms || {}
                });
            },
            listStockplaceFrom: function (parms) {
                return $ihttp.post({
                    method: 3501,
                    parameters: parms || {}
                });
            },
            listStockplaceTo: function (parms) {
                return $ihttp.post({
                    method: 3502,
                    parameters: parms || {}
                });
            },
            listLabour: function (parms) {
                return $ihttp.post({
                    method: 3503,
                    parameters: parms || {}
                });
            },
            listStatus: function (parms) {
                return $ihttp.post({
                    method: 3504,
                    parameters: parms || {}
                });
            },
            send: function (parms) {
                return $ihttp.post({
                    method: 3508,
                    parameters: parms || {}
                });
            },
            receive: function (parms) {
                return $ihttp.post({
                    method: 3509,
                    parameters: parms || {}
                });
            },
            undoSend: function (parms) {
                return $ihttp.post({
                    method: 3510,
                    parameters: parms || {}
                });
            },
            undoReceive: function (parms) {
                return $ihttp.post({
                    method: 3511,
                    parameters: parms || {}
                });
            },

        }

        return service;
    }]);
})();
