import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Style } from '@app/core/services/core-component.service';

@Component({
    selector: 'tt-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.css'],
})
export class FileUploaderComponent extends FormFieldBaseComponent {
    /**
     * Fileuploader does not support remember.
     */
    public override ttRememberId?: undefined;

    /**
     * Fileuploader does not support remember.
     */
    public override set ttOnlyRemember(value: boolean) {}
    public override get ttOnlyRemember(): true {
        return true;
    }

    /**
     * Whether to allow multiple files to imported or not.
     */
    @Input()
    get ttMultiple(): boolean {
        return this._multiple;
    }
    set ttMultiple(value: BooleanInput) {
        this._multiple = coerceBooleanProperty(value);
    }
    private _multiple: boolean = false;

    /**
     * Whether the file uploader should display a drop-zone instead of the default button.
     */
    @Input()
    get ttDropZone(): boolean {
        return this._dropzone;
    }
    set ttDropZone(value: BooleanInput) {
        this._dropzone = coerceBooleanProperty(value);
    }
    private _dropzone: boolean = false;

    /**
     * Comma seperated string containing what file types to accept, can be file extension starting with . like `'.jpg, .png'`., a MIME type or `'image/*'`, `'audio/*'`, `'video/*'`.
     * If null or not defined will indicate any file type.
     */
    @Input()
    public ttAccept?: string | null;

    /**
     * **Reactive** The selected files in the file uploader.
     */
    @Input()
    ttFiles: File[] = [];
    files: File[] = [];

    /**
     * Event emitted when there are changes in the selected files.
     */
    @Output()
    ttFilesChange = new EventEmitter<File[]>();

    /**
     * The input field of type file.
     */
    @ViewChild('inputRef') inputRef?: ElementRef;

    /**
     * Ids for elements in the component.
     */
    public id = {
        input: crypto.randomUUID(),
    };

    /**
     * Translations for words used in the component.
     */
    public filetranslations: { [key: string]: string } = {
        select_file: '',
        select_files: '',
        drag_and_drop_or_click: '',
    };

    /**
     * Styling for elements in the component.
     */
    override style: Style = {
        dropzone: {},
        dropzone_label: {},
    };

    /**
     * Sets the selected file/files from the event to the list.
     *
     * @param event the change event of an input field of type file.
     */
    public onFileChanged(event: Event) {
        if (!!event?.target && (event.target as HTMLInputElement)?.files && (event.target as HTMLInputElement).files !== null) {
            this.updateFilesList(Array.from((event.target as HTMLInputElement).files!));
            this.ttFilesChange.emit(this.files);
        }
    }

    /**
     * Handles dragover events over the dropzone.
     *
     * @param event the dragevent from dragover.
     */
    public onDragover(event: DragEvent) {
        event.preventDefault();

        this.style['dropzone'].outline = 'solid 0.2rem var(--tt-primary-color)';
        this.style['dropzone'].outlineOffset = '-0.2rem';
    }

    /**
     * Handles dragleave events from the dropzone.
     *
     * @param event the dragevent from the dragleave.
     */
    public onDragleave(event: DragEvent) {
        event.preventDefault();

        delete this.style['dropzone'].outline;
        delete this.style['dropzone'].outlineOffset;
    }

    /**
     * Handles file drop in the drop zone.
     *
     * @param event the dragevent from the file drop.
     */
    public onFilesDropped(event: DragEvent) {
        event.preventDefault();

        if (event?.dataTransfer?.items) {
            const files: File[] = Array.from(event.dataTransfer.items)
                .map((item) => item.getAsFile())
                .filter((item) => item !== null)
                .filter((file) => this.isFileTypeAccepted(file!)) as File[];

            this.updateFilesList(files);
        }

        this.ttFilesChange.emit(this.files);

        delete this.style['dropzone'].outline;
        delete this.style['dropzone'].outlineOffset;
    }

    /**
     * Checks whether the given file has a file type which is accepted.
     *
     * @param file the file to check if is accepted.
     * @returns `true` if the given file's file type is accepted, `false` if not.
     */
    private isFileTypeAccepted(file: File): boolean {
        if (!this.ttAccept) {
            return true;
        }
        const acceptedFileTypesArray = this.ttAccept.split(',').map((type) => type.trim());
        return acceptedFileTypesArray.some((type) => {
            if (type === '*/*') {
                return true;
            }

            if (type.endsWith('/*')) {
                const baseType = type.replace('/*', '');
                return file.type.startsWith(baseType);
            }

            return file.type === type || file.name.toLowerCase().endsWith(type.toLowerCase());
        });
    }

    /**
     * Updates the list of files with the given list of files, keeping filenames unique.
     *
     * @param files the files to update the file list in the component with.
     * @emits ttFilesChange
     */
    private updateFilesList(files: File[]) {
        if (this._multiple) {
            for (let file of files) {
                if (!this.files.map((file) => file.name).includes(file.name)) {
                    this.files.push(file);
                }
            }
        } else {
            this.files = [files![0]];
        }
    }

    /**
     * Empties the files in the input field itself.
     */
    private resetFileInput() {
        if (!!this.inputRef?.nativeElement) {
            this.inputRef.nativeElement.value = '';
        }
    }

    /**
     * Removes the given file from the list of selected files.
     *
     * @param file the file to remove.
     * @emits ttFileRemoved
     * @emits ttFilesChange
     */
    public removeFile(file: File) {
        this.files.splice(this.files.indexOf(file), 1);
        this.resetFileInput();
        this.ttFilesChange.emit(this.files);
    }

    /**
     * Translates the words used in component.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.filetranslations));

        Object.keys(translations).forEach((key) => {
            if (translations[key]) {
                this.filetranslations[key] = translations[key];
            }
        });
    }

    override ngOnInit(): void {
        this.translate();
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['dropzone_label'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.['ttFiles']?.currentValue) {
            this.files = [...changes['ttFiles'].currentValue];
            this.resetFileInput();
        }
    }
}
