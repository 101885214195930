(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('poFinishProdCompListAcc', {
        templateUrl: 'views/components/views/poFinishProdCompListAcc/poFinishProdCompListAcc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poFinishProdCompListAccService', 'poFinishCompListService', 'niService', function ($stateParams, stateService, poFinishProdCompListAccService, poFinishCompListService, niService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoFinishKeyno = $stateParams.p2_pofinish_keyno;
            let prodSizeKeyno = $stateParams.prodsize_keyno;
            let prodChoiceKeyno = $stateParams.prodchoice_keyno;
            let webPageName = stateService.getCurrentName();

            vm.model = {
                p2PoFinishKeyno: p2PoFinishKeyno,
                bgDistribution: {
                    selected: 'last_picked_info',
                    buttons: [
                        { id: 'last_picked_info', label: 'last_picked_info', color: 'primary', onClick: () => vm.model.bgDistribution.selected = 'last_picked_info' },
                        { id: 'component_distribution', label: 'component_distribution', color: 'primary', onClick: () => vm.model.bgDistribution.selected = 'component_distribution' }
                    ]
                },
                quantityToPickButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('ni') }
                ],
                menu: {},
                status: {},
                itemsListLastPicked: [],
                itemsListDistributed: [],
                lockedFinish: false
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await poFinishProdCompListAccService.getMenu({
                    p2_pofinish_keyno: p2PoFinishKeyno,
                    prodsize_keyno: prodSizeKeyno,
                    prodchoice_keyno: prodChoiceKeyno
                }))[0];

                if (niService.inputNumber.number > '') {
                    vm.model.menu.quantity_topick = niService.inputNumber.number;

                    recalcQuantityToPick();

                    niService.inputNumber.number = '';
                }
            }

            async function loadStatus() {
                vm.model.status = (await poFinishProdCompListAccService.getStatus({ p2_pofinish_keyno: p2PoFinishKeyno }))[0];
            }

            async function loadLastPicked() {
                vm.model.itemsListLastPicked = (await poFinishProdCompListAccService.listLastPicked({
                    prodsize_keyno: prodSizeKeyno,
                    prodchoice_keyno: prodChoiceKeyno
                }));
            }

            async function loadAccumulatedComponents() {
                vm.model.itemsListDistributed = (await poFinishProdCompListAccService.listAccumulatedComponents({
                    p2_pofinish_keyno: p2PoFinishKeyno,
                    prodsize_keyno: prodSizeKeyno,
                    prodchoice_keyno: prodChoiceKeyno
                }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region RECALC FUNCTION
            
            function recalcQuantityToPick() {
                poFinishProdCompListAccService.updateQuantityToPick({
                    p2_pofinish_keyno: p2PoFinishKeyno,
                    prodsize_keyno: vm.model.menu.prodsize_keyno,
                    prodchoice_keyno: vm.model.menu.prodchoice_keyno,
                    quantity_topick: vm.model.menu.quantity_topick
                }).then(function () {
                    loadMenu();
                    loadStatus();
                    loadAccumulatedComponents();
                });
            }

            // #endregion RECALC FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDistribution = function (value) {
                vm.model.bgDistribution.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON FINISH

            vm.finishChanges = function () {
                vm.model.lockedFinish = true;
                
                poFinishCompListService.finishProductionOrder({
                    finishfromkey: 'p2_pofinish_keyno',
                    keyvalue: p2PoFinishKeyno,
                    from_webpagename: webPageName,
                    from_parms: p2PoFinishKeyno
                }).then(function (response) {
                    vm.model.lockedFinish = false;

                    if (response[0]) {
                        stateService.back('/' + response[0].webpagename + '/' + response[0].webpage_parms);
                    }
                }, function () {
                    vm.model.lockedFinish = false;
                });
            };

            // #endregion BUTTON FINISH

            // #region CANCEL BUTTON
            
            vm.abortChanges = function () {
                poFinishProdCompListAccService.abortFinishing({ p2_pofinish_keyno: p2PoFinishKeyno }).then(function (response) {
                    stateService.back('/' + response.webpagename + '/' + response.webpage_parms);
                });
            };

            // #endregion CANCEL BUTTON

            // #region SAVE BUTTON
            
            vm.saveChanges = function () {
                stateService.back();
            };

            // #region SAVE BUTTON

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
                loadStatus();
                loadLastPicked();
                loadAccumulatedComponents();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
