(function () {
    'use strict';

    angular.module("imApp").factory("userGroupTableService", ['$ihttp', function ($ihttp) {
        let service = {
            listUserGroupTables: function (parms) {
                return $ihttp.post({
                    method: 40,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
