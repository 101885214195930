import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { RememberService } from '@app/core/services/remember.service';
import { StateService } from '@app/core/services/state.service';
import { UtilityService } from '@app/core/services/utility.service';
import { BaseWidget, NgCompInputs, NgGridStackWidget } from 'gridstack/dist/angular';
import { AgChartOptions } from 'ag-charts-community';
import { GridStack } from 'gridstack';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { DashboardChartModalComponent } from '../dashboard-chart-modal/dashboard-chart-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'tt-dashboard-widget-base',
    templateUrl: './dashboard-widget-base.component.html',
    styleUrls: ['./dashboard-widget-base.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardWidgetBaseComponent extends BaseWidget {
    public override serialize(): NgCompInputs | undefined {
        return this.ttDashboardWidget ? { node: this.ttDashboardWidget } : undefined;
    }

    @Input()
    ttDashboardWidget?: WidgetTypes & { update?: () => any | undefined };

    @Output()
    ttDashboardWidgetChange = new EventEmitter<WidgetTypes>();

    @Output()
    ttReady = new EventEmitter<any>();

    // public override serialize(): NgCompInputs | undefined {
    //     return this.ttDashboardWidget ? { node: this.ttDashboardWidget } : undefined;
    // }

    constructor(public elementRef: ElementRef, private utility: UtilityService, private state: StateService, private remember: RememberService, private datatask: DataTaskService, private dialog: MatDialog) {
        super();
    }

    public isBasicWidget(value: WidgetTypes): value is DashboardWidgetBasic {
        return value.kpicomp_type === 'BASIC';
    }

    public isChartWidget(value: WidgetTypes): value is DashboardWidgetChart {
        return value.kpicomp_type === 'GRAPH_BAR' || value.kpicomp_type === 'GRAPH_BARH' || value.kpicomp_type === 'GRAPH_LINE' || value.kpicomp_type === 'PIE' || value.kpicomp_type === 'DONUT';
    }

    loading = false;
    async onClick(event: MouseEvent) {
        if (!this.ttDashboardWidget) return;
        if (this.isChartWidget(this.ttDashboardWidget)) {
            this.dialog.open(DashboardChartModalComponent, { data: this.ttDashboardWidget, panelClass: 'tt-dashboard-chart-modal-panel', maxWidth: '100vw', maxHeight: '95vh', width: '120rem', height: '60rem' });
        } else {
            this.loading = true;

            try {
                await Promise.all(Object.entries(this.ttDashboardWidget.remember || {}).map(([key, value]) => this.remember.remember(key, value)));

                if (this.utility.isModifierKeyPressed(event)) {
                    this.state.newTab(this.ttDashboardWidget!.goto_item_state!, this.ttDashboardWidget.goto_item_parms);
                } else {
                    this.state.go(this.ttDashboardWidget!.goto_item_state!, this.ttDashboardWidget.goto_item_parms);
                }
            } finally {
                this.loading = false;
            }
        }
        // setTimeout(() => (this.loading = false), 2000);
    }

    chartReady(event: any) {
        console.log(event);
    }

    // private getInitialSize(): Partial<NgGridStackWidget> {
    //     if (this.ttDashboardWidget?.kpicomp_type === 'GRAPH_BARH') {
    //         return { h: 12 };
    //     } else if (this.ttDashboardWidget?.kpicomp_type === 'DONUT' || this.ttDashboardWidget?.kpicomp_type === 'PIE') {
    //         return { h: 6 };
    //     } else if (this.ttDashboardWidget?.kpicomp_type === 'GRAPH_BAR' || this.ttDashboardWidget?.kpicomp_type === 'GRAPH_LINE') {
    //         return { h: 6 };
    //     }

    //     return { h: 3 };
    // }

    async ngOnInit(): Promise<void> {
        if (this.ttDashboardWidget?.kpicomp_keyno) {
            this.loading = true;

            try {
                // @ts-ignore
                this.ttDashboardWidget = { ...this.ttDashboardWidget, ...(await this.datatask.Post(3518, { kpicomp_keyno: '' + this.ttDashboardWidget.kpicomp_keyno }, { returnRawResponse: true })) };
                // this.ttDashboardWidget?.api?.()?.update(this.elementRef.nativeElement.parentElement.parentElement, this.getInitialSize());
                this.ttDashboardWidgetChange.emit(this.ttDashboardWidget);
            } finally {
                this.loading = false;

                // setTimeout(() => {
                //     // @ts-ignore
                //     console.log('this.ttDashboardWidget?.api?.() :>> ', this.ttDashboardWidget);
                //     console.log('this.ttDashboardWidget?.api?.() :>> ', this.ttDashboardWidget?.api?.());
                // this.ttDashboardWidget?.api?.()?.resizeToContent(this.elementRef.nativeElement.parentElement.parentElement);
                // this.ttDashboardWidget?.api?.()?.onResize(document.body.clientWidth);
                // }, 5500);
            }
        }
        // this.ttReady.emit(this.serialize);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes :>> ', changes);
        if (changes['ttDashboardWidget']) {
            // this.graphoption.theme = changes['ttDashboardWidget'].currentValue?.graphtheme?.toLowerCase() ?? 'ag-polychroma-dark';
            // this.graphoption = { ...this.graphoption };
        }
    }
}

export interface WebpagemenuDashboardWidgetData<TType extends string> extends NgGridStackWidget {
    remember?: { [key: string]: string };
    kpicomp_keyno?: string | number;
    proc_name?: string;
    reg_datetime?: string;
    mod_datetime?: string;
    is_public?: '1' | '0';
    reg_by_portal_user_keyno?: string | number;
    kpicomp_name_word_id?: string;
    // graphdata?: any[];
    // graphtheme?: string;
    kpicomp_name?: string;
    kpicomp_description_word_id?: string;
    kpicomp_description?: string;
    goto_item_state?: string;
    goto_item_parms?: string;
    item_glyphicon?: string;
    item_glyphicon_color?: string;
    item_glyphicon_size?: string;
    tile_background_color?: string;
    tile_heading_text?: string;
    tile_heading_text_color?: string;
    tile_heading_text_size?: string;
    tile_subheading_text?: string;
    tile_subheading_text_color?: string;
    tile_subheading_text_size?: string;
    // data1_text?: string;
    p2_webpage_keyno?: string | number;
    kpicomp_type: TType;
    api?: () => GridStack | undefined;
    ready?: boolean;
}

export interface GraphData {
    xkey: string;
    xorderby: number;
    ykey: string;
    yname: string;
    yvalue: number;
}

export interface DashboardWidgetBasic extends WebpagemenuDashboardWidgetData<'BASIC'> {
    data1_text?: string;
}

export interface DashboardWidgetChart extends WebpagemenuDashboardWidgetData<'GRAPH_BAR' | 'GRAPH_BARH' | 'GRAPH_LINE' | 'PIE' | 'DONUT'> {
    graphdata?: GraphData[];
    graphtheme?: string;
}

export type WidgetTypes = DashboardWidgetBasic | DashboardWidgetChart;

const type: WidgetTypes = {
    kpicomp_type: 'GRAPH_BAR',
    graphdata: [],
};
