(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productUnit', {
        templateUrl: 'views/components/views/productUnit/productUnit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'productUnitService', function ($stateParams, stateService, translateService, modalService, productUnitService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;
            let productUnitKeyno = $stateParams.productunit_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListNewProductUnits: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                productUnitService.getProductUnit({
                    prod_id: prodId,
                    productunit_keyno: productUnitKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            let loadNewProductUnits = function () {
                productUnitService.listNewProductUnits({ prod_id: prodId }).then(function (list) {
                    angular.copy(list, vm.model.selectListNewProductUnits);
                });
            };

            // #endregion PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE FUNCTION

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productUnitService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE FUNCTION

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    productUnitService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadNewProductUnits();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
