(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2WebpageTable', {
        templateUrl: 'views/components/views/p2WebpageTable/p2WebpageTable.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2WebpageTableService', 'layoutService', function ($stateParams, p2WebpageTableService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                itemState: '',
                p2WebpageTableKeyno: '',
                itemsListWebPageTables: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadWebPageTables = function () {
                p2WebpageTableService.listWebPageTables({
                    argtype: argType,
                    argvalue: argValue,
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebPageTables);

                    angular.forEach(vm.model.itemsListWebPageTables, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadWebPageTables();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2WebpageTableKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadWebPageTables();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
