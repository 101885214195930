<tt-form-field-base
    #baseRef
    [ttFieldId]="id.input"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttTextAlign]="ttTextAlign"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
>
    <!-- [ttLabel]="translations['label']" -->
    <input
        class="tt-input__form-control"
        type="search"
        #inputRef
        #listboxPopupRef="listbox-popup"
        [attr.id]="id.input"
        ttListboxPopup
        [ttMinLength]="ttMinLength"
        [ttDebounceTime]="ttDebounceTime"
        [ttData]="onSearch"
        [ttDataName]="ttDataName"
        [ttDataId]="ttDataId"
        [ttRelativeElement]="baseRef?.groupRef"
        [ttFilterOperation]="ttFilterOperation"
        (ttSelect)="onSelect($event)"
        [(ngModel)]="ttModel"
        (ngModelChange)="onModelChanged($event)"
        [ngStyle]="style['input']"
        (focus)="selectText()"
        (blur)="onBlur($event)"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
    />
    <ng-container buttons>
        <tt-form-field-buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
        <tt-form-field-button ttIcon="fas fa-eraser" ttType="danger" (ttClick)="onClear($event)"></tt-form-field-button>
        <tt-form-field-button [ttIcon]="searching ? 'fas fa-spinner fa-spin' : 'fas fa-search'" ttType="success" (ttClick)="manualSearch()"></tt-form-field-button>
    </ng-container>
</tt-form-field-base>
