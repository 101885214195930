(function () {
	'use strict';

    angular.module("imApp").factory("payCheckBatchesService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3493,
                    parameters: parms || {}
                });
            },
            listPayCheckBatches: function (parms) {
                return $ihttp.post({
                    method: 1367,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
