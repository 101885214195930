(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('productLinksChange', {
        templateUrl: 'views/components/views/productLinksChange/productLinksChange.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'productLinksChangeService', function ($stateParams, stateService, utilityService, modalService, translateService, productLinksChangeService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let productLinkTypeKeyno = $stateParams.productlinktype_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgProductLinksChangeFunction: {
                    selected: 'ADD',
                    buttons: [
                        { id: 'ADD', label: 'bg_productlinkschange_function_add', color: 'primary', onClick: () => setProductLinksChangeFunctionView('ADD') },
                        { id: 'REMOVE', label: 'bg_productlinkschange_function_remove', color: 'primary', onClick: () => setProductLinksChangeFunctionView('REMOVE') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchChanges() }
                ],
                setting: {},
                itemsListChanges: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            async function setProductLinksChangeFunctionView(id) {
                vm.model.bgProductLinksChangeFunction.selected = vm.model.setting.bg_productlinkschange_function = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE CALLS

            async function loadSetting() {
                vm.model.setting = (await productLinksChangeService.getSetting({
                    argtype: argType,
                    argvalue: argValue,
                    productlinktype_keyno: productLinkTypeKeyno
                }))[0];

                setProductLinksChangeFunctionView(vm.model.setting.bg_productlinkschange_function);
                loadProductLinksChanges();
            }
    
            async function loadProductLinksChanges() {
                if (utilityService.validateParmsValue(vm.model.setting.bg_productlinkschange_function) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListChanges = (await productLinksChangeService.listProductLinksChanges(vm.model.setting));
            }

            // #endregion LOAD PROCEDURE CALLS

            // #region RESET BUTTON FUNCTION

            function reset(id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') loadProductLinksChanges();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProductLinksChangeFunction = function (value) {
                vm.model.bgProductLinksChangeFunction.selected = value;
                vm.model.setting.bg_productlinkschange_function = vm.model.bgProductLinksChangeFunction.selected;

                loadProductLinksChanges();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region SEARCH FUNCTION
            
            vm.searchChanges = function () {
                loadProductLinksChanges();
            };

            // #endregion SEARCH FUNCTION

            // #region TOGGLE FUNCTION
    
            vm.toggleChosen = function (item) {
                item = item?.item ? item.item : item;
    
                if (item.item_id === '0' || item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                let selectedItems = [];

                vm.model.itemsListChanges.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0') return;

                    selectedItems.push({ item_id: item.item_id });
                });

                productLinksChangeService.saveObj({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue,
                    productlinktype_keyno: vm.model.setting.productlinktype_keyno,
                    bg_productlinkschange_function: vm.model.setting.bg_productlinkschange_function,
                    records: selectedItems
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') loadProductLinksChanges();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
