import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterList' })
export class FilterListPipe implements PipeTransform {
    transform(items: any[], filterval: string, filterparm?: string): any[] {
        if (!items) return [];
        if (!filterval) return items;

        const filtered: any[] = [];
        let individual: string[] = [];

        // "strict" => treat as single token
        if (filterparm && filterparm.includes('strict')) {
            individual.push(filterval);
        } else {
            individual = filterval.split(' ');
        }

        items.forEach(value => {
            let addToFilter = true;
            individual.forEach(indv => {
                if (
                    value.item_filtervalue &&
                    !value.item_filtervalue.toLowerCase().includes(indv.toLowerCase()) &&
                    value.item_id !== '0' &&
                    value.item_id !== '-1'
                ) {
                    addToFilter = false;
                }
            });

            // prevent duplicates
            if (filtered.includes(value)) {
                addToFilter = false;
            }

            if (addToFilter) {
                filtered.push(value);
            }
        });

        return filtered;
    }
}
