(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('factoring', {
        templateUrl: 'views/components/views/factoring/factoring.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'factoringService', 'economyService', 'typeaheadService', function ($stateParams, stateService, translateService, modalService, factoringService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let factoringNo = $stateParams.factoring_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListCurrencies: [],
                selectListAccounts: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	factoringService.getEdit({ factoring_no: factoringNo }).then(function (info) {
            		angular.copy(info[0], vm.model.edit);
            	});
            };

            let loadCurrencies = function () {
                economyService.listCurrencies().then(function (list) {
                    angular.copy(list, vm.model.selectListCurrencies);
                });
            };

            let loadAccounts = function () {
                factoringService.listAccounts().then(function (list) {
                    angular.copy(list, vm.model.selectListAccounts);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'A',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'cust_name':
                        if (item) {
                            vm.model.edit.factoring_cust_no = item?.item_id ?? '';
                            vm.model.edit.cust_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.factoring_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.factoring_cust_no = '';
                        vm.model.edit.cust_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                factoringService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
                loadCurrencies();
                loadAccounts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
