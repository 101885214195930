import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { Item } from '../listbox/listbox.component';
import { GoogleTranslateService } from '@app/services/google-translate.service';

@Component({
    selector: 'tt-translate-edit',
    templateUrl: './translate-edit.component.html',
    styleUrls: ['./translate-edit.component.css'],
})
export class TranslateEditComponent<TType extends Record<string, string>> implements OnInit, OnChanges {
    /**
     * The text to translate \
     * **Reactive**
     */
    @Input()
    ttTextToTranslate: string = '';

    @Output()
    ttTextToTranslateChange = new EventEmitter<string>();

    /**
     * The language to translate from.
     * **Reactive**
     * @default '' - detects the language from the text.
     */
    @Input()
    ttTranslateFromLanguage?: string = '';

    @Output()
    ttTranslateFromLanguageChange = new EventEmitter<string | undefined>();

    /**
     * The list of translate edits that for the translation.
     */
    @Input()
    ttTranslateEdits: TType[] = [];

    @Input()
    ttSave?: (edits: TType[]) => unknown;

    /**
     * The key of the property in the edits identifying the google language id, necesearry for using google translation api,
     *
     * @default 'google_language_id'
     */
    @Input()
    ttGoogleLanguageIdKey: keyof TType = 'google_language_id' as keyof TType;

    /**
     * The key of the property in the edit items identifying the language id for the edit items.
     *
     * @default 'language_id'
     */
    @Input()
    ttLanguageIdKey: keyof TType = 'language_id' as keyof TType;

    /**
     * The key of the property in the edit items identifying the language name for the edit items.
     *
     * @default 'language_name'
     */
    @Input()
    ttLanguageNameKey: keyof TType = 'language_name' as keyof TType;

    /**
     * The key of the property in the edit items identifying the id of word for the edit items.
     *
     * @default 'word_id'
     */
    @Input()
    ttWordIdKey: keyof TType = 'word_id' as keyof TType;

    /**
     * The key of the property in the edit items identifying the translated name of edit if any.
     *
     * @default 'wordlang_name'
     */
    @Input()
    ttWordNameKey: keyof TType = 'wordlang_name' as keyof TType;

    translateFromLanguages: LanguageItem[] = [];

    translateToAllLanguages: '1' | '0' = '1';

    translateEdits: (TType & { toTranslate: '1' | '0' })[] = [];

    constructor(private datatask: DataTaskService, private googleTranslate: GoogleTranslateService) {}

    public onTranslateToAllLanguagesChange(_: '1' | '0') {
        if (this.translateToAllLanguages === '1') {
            this.translateEdits.forEach((edit) => (edit.toTranslate = '1'));
        } else if (this.translateToAllLanguages) {
            this.translateEdits.forEach((edit) => (edit.toTranslate = '0'));
        }
    }

    public updateTranslateToAllLanguages() {
        this.translateToAllLanguages = this.translateEdits.every((edit) => edit.toTranslate === '1') ? '1' : '0';
    }

    public onSave() {
        if (!!this.ttSave && this.ttSave instanceof Function) {
            this.ttSave(this.translateEdits);
        }
    }

    async translate() {
        let translateFromLanguageId = null;

        if (!this.ttTranslateFromLanguage) {
            translateFromLanguageId = (await this.googleTranslate.detectLanguage(this.ttTextToTranslate.toLowerCase())) ?? null;
        } else {
            translateFromLanguageId = this.ttTranslateFromLanguage.toLowerCase();
        }

        for (let i = 0; i < this.translateEdits.length; i++) {
            if (this.translateEdits[i].toTranslate === '1') {
                if (this.translateEdits[i][this.ttGoogleLanguageIdKey] === 'nn' && (translateFromLanguageId === 'no' || translateFromLanguageId === 'sv' || translateFromLanguageId === 'da')) {
                    this.translateEdits[i][this.ttWordNameKey] = await this.newNorwegianTranslation(this.ttTextToTranslate, translateFromLanguageId);
                } else if (this.translateEdits[i][this.ttGoogleLanguageIdKey] === translateFromLanguageId) {
                    // @ts-ignore
                    this.translateEdits[i][this.ttWordNameKey] = this.ttTextToTranslate;
                } else if (!['sy', 'nn'].includes(this.translateEdits[i][this.ttLanguageIdKey].toLowerCase())) {
                    // @ts-ignore
                    this.translateEdits[i][this.ttWordNameKey] = (await this.googleTranslate.translateText(this.ttTextToTranslate, this.translateEdits[i][this.ttGoogleLanguageIdKey], translateFromLanguageId))[0].translatedText;
                }
            }
        }
    }

    private async newNorwegianTranslation(text: string, translatedFrom: string) {
        translatedFrom = translatedFrom.toLowerCase();

        if (translatedFrom === 'no' || translatedFrom === 'sv' || translatedFrom === 'da') {
            if (translatedFrom.toString() === 'no') {
                translatedFrom = 'nob';
            }

            if (translatedFrom.toString() === 'da') {
                translatedFrom = 'dan';
            }

            if (translatedFrom.toString() === 'sv') {
                translatedFrom = 'swe';
            }

            const formData = new FormData();

            formData.append('langpair', translatedFrom + '|nno');
            formData.append('markUnknown', 'no');
            formData.append('prefs', '');
            formData.append('q', text);

            let response = await fetch('https://apertium.org/apy/translate', { method: 'POST', body: formData });
            let parsed = await response.json();
            return parsed.responseData.translatedText;
        }
    }

    private async loadTranslateFromLanguages() {
        this.translateFromLanguages = await this.datatask.Post(57, { add_detect: '1' });
    }

    ngOnInit(): void {
        // this.loadTranslateEdits();
        this.loadTranslateFromLanguages();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttTranslateEdits'] && Array.isArray(changes['ttTranslateEdits'].currentValue) && changes['ttTranslateEdits'].currentValue !== changes['ttTranslateEdits'].previousValue) {
            this.translateEdits = changes['ttTranslateEdits'].currentValue.map((edit: any) => ({ ...edit, toTranslate: '1' }));
        }
    }

    // public ttWordId: string = 'generate_preview';

    // ttTranslateEditDatatask = 61;

    // private async loadTranslateEdits() {
    //     if (!!this.ttTranslateEditDatatask && !!this.ttWordId) {
    //         this.translateEdits = (await this.datatask.Post(this.ttTranslateEditDatatask, { word_id: this.ttWordId })).map((edit: any) => ({ ...edit, toTranslate: '1' }));
    //     }
    // }
}

interface LanguageItem extends Item {
    google_language_id: string;
}

// {
//   "word_id": "generate_preview",
//   "wordlang_name": "Generer forhåndsvisning",
//   "language_id": "no",
//   "language_name": "Norsk",
//   "keeptext": "0",
//   "orderby": "1",
//   "google_language_id": "no"
// }
export interface TranslateEditSchema<TType extends any> {
    googleLanguageId: keyof TType;
    languageId: keyof TType;
    languageName: keyof TType;
    wordId: keyof TType;
    word: keyof TType;
}

type DefaultTranslateEditSchema = {
    googleLanguageId: 'google_language_id';
    languageId: 'language_id';
    languageName: 'language_name';
    wordId: 'word_id';
    word: 'wordlang_name';
};
