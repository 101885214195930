import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, Host, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Item } from '../../listbox/listbox.component';
import { ListComponent } from '../list.component';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'tt-list[ttCheckboxSelection]:not(tt-list[ttRadioSelection])',
})
export class ListCheckboxSelectionDirective<TType extends Item> implements OnInit, OnChanges {
    /**
     * Configures checkbox selection to elements of the tt-list.
     */
    @Input()
    set ttCheckboxSelection(value: BooleanInput) {
        this._checkboxSelection = coerceBooleanProperty(value);
    }
    get ttCheckboxSelection(): boolean {
        return this._checkboxSelection;
    }
    _checkboxSelection = false;

    /**
     * Event emitted when the checkbox selection of the list items has changed.
     */
    @Output()
    ttCheckboxSelectionChanged = new EventEmitter<ListCheckboxSelectionEvent>();

    private ttCheckboxSelectionChangedSubscription?: Subscription;

    constructor(@Host() private componentRef: ListComponent<TType>, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.componentRef.ttCheckboxSelection = this.ttCheckboxSelection;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttCheckboxSelection']) {
            this.ttCheckboxSelection = changes['ttCheckboxSelection'].currentValue;
            this.componentRef.ttCheckboxSelection = this.ttCheckboxSelection;

            if (this.ttCheckboxSelection) {
                this.ttCheckboxSelectionChangedSubscription = this.componentRef.ttCheckboxSelectionChanged.subscribe((value) => this.ttCheckboxSelectionChanged.emit(value));
            } else {
                this.ttCheckboxSelectionChangedSubscription?.unsubscribe();
            }

            this.cdr.detectChanges();
        }
    }
}

export interface ListCheckboxSelectionEvent {
    /**
     * Whether the item was selected `true`, or deselected `false`.
     */
    selected: boolean;

    /**
     * The item which had the checkbox selection changed.
     */
    item: Item;
}
