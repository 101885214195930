(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('prodBeoMethodLangs', {
        templateUrl: 'views/components/views/prodBeoMethodLangs/prodBeoMethodLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'prodBeoMethodLangsService', function ($stateParams, stateService, translateService, modalService, prodBeoMethodLangsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodBeoMethodNo = $stateParams.prodbeomethod_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                inputListLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadLanguages = function () {
            	prodBeoMethodLangsService.listLanguages({ prodbeomethod_no: prodBeoMethodNo }).then(function (list) {
            		angular.copy(list, vm.model.inputListLanguages);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                prodBeoMethodLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
