(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2OffDutyDatas', {
        templateUrl: 'views/components/views/p2OffDutyDatas/p2OffDutyDatas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'p2OffDutyDatasService', 'economyService', function ($q, stateService, utilityService, p2OffDutyDatasService, economyService) {
            const vm = this;
    		
            vm.model = {
            	setting: {},
            	selectListLabours: [],
                itemsListOffDutyDatas: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                p2OffDutyDatasService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadOffDutyDatas()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadLabours = function () {
                economyService.listLabours({ activestatus: 'ACT' }).then(function (list) {
                    angular.copy(list, vm.model.selectListLabours);
                });
            };
            
            let loadOffDutyDatas = function () {
                if (utilityService.validateParmsValue(vm.model.setting.labour_no) !== true) return;

                return p2OffDutyDatasService.listOffDutyDatas({ labour_no: vm.model.setting.labour_no }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOffDutyDatas);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2offdutydata':
                        go({
                            p2_offdutydata_keyno: '0',
                            labour_no: vm.model.setting.labour_no
                        });
                        break;
                    default:
                        break;
                }
            };
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'labour_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.labour_no) !== true) return;

                        vm.model.setting.labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.labour_no) !== true) return;
                        
                        loadOffDutyDatas();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
                loadLabours();
            };
        }]
    });
})();
