(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderHeadDeleteOl', {
        templateUrl: 'views/components/views/orderHeadDeleteOl/orderHeadDeleteOl.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'orderHeadDeleteOlService', function ($stateParams, stateService, utilityService, translateService, modalService, orderHeadDeleteOlService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListDeleteOrderLines: [],
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATION SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await orderHeadDeleteOlService.getSetting({ order_internal_no: orderInternalNo }))[0];

                loadDeleteOrderLines();
            }

            async function loadDeleteOrderLines() {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListDeleteOrderLines = (await orderHeadDeleteOlService.listDeleteOrderLines(vm.model.setting));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TOGGLE FUNCTIONS
            
            vm.toggleChosen = function (item) {
                item = item?.item ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
            vm.selectAll = function () {
                vm.model.itemsListDeleteOrderLines.forEach((item) => {
                    if (!item.item_filtervalue === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.searchtext.toLowerCase()) <= -1) return;
                    if (item.item_id === '0' || item.item_id === '-1') return;

                    item.item_is_selected = '1';
                });
    		};
            
            vm.unselectAll = function () {
                vm.model.itemsListDeleteOrderLines.forEach((item) => {
                    if (!item.item_filtervalue === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
                    
    				item.item_is_selected = '0';
    			});
    		};

            // #endregion TOGGLE FUNCTIONS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteSelected = function () {
                vm.model.lockedDelete = true;
                let selectedRows = [];

                vm.model.itemsListDeleteOrderLines.forEach((item) => {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0' || item.item_is_selected === false) return;

                    selectedRows.push({
                        item_id: item.item_id,
                        item_is_selected: item.item_is_selected
                    });
                });

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    orderHeadDeleteOlService.deleteItems({ records: selectedRows }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
