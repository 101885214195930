import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, EventEmitter } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { DBGridButton, DataTaskRequest, GridRow } from '../../grid.types';
import { GridService } from '../../grid.service';
import { NavigateEvent } from '../grid-functions-cell-renderer/grid-functions-cell-renderer.component';
import { UtilityService } from '@app/core/services/utility.service';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-link-cell-renderer',
    templateUrl: './link-cell-renderer.component.html',
    styleUrls: ['./link-cell-renderer.component.css'],
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
    /**
     * The text to display for this cell button.
     */
    public text: string = '';

    /**
     * Parameters configuring this cell button.
     */
    private params?: LinkCellRendererParams;

    constructor(private gridService: GridService, private datatask: DataTaskService, private utility: UtilityService, private state: StateService) {}

    /**
     * Handles goto for click events on the cell button.
     *
     * @param event the mouse event clicking on the button.
     */
    public async onClick(event: MouseEvent) {
        console.log('this.params :>> ', this.params);
        event.preventDefault();
        if (!this.params) return;
        let state: string = '';
        let parms: { [key: string]: string } | string = '';

        if (this.params?.button?.p2_datatask_keyno === 0) {
            const colId = this.params.column?.getColId().replace('ag-Grid-AutoColumn-', '');
            const data = !!this.params.data ? this.params.data : this.params.node.allLeafChildren[0].data;

            if (!!data && !!this.params.loadData && !!colId) {
                const response = await this.gridService.getGotoParms({ row: data, loadDataTask: this.params?.loadData, rememberId: this.params?.rememberId, field: colId });

                state = response.item_state;
                parms = response.item_parms;
            }
        } else if (!!this.params?.button?.p2_datatask_keyno) {
            await this.datatask.Post(this.params.button.p2_datatask_keyno, this.params?.data);

            if (this.params.button.state) {
                state = this.params.button.state;
                parms = JSON.parse(this.gridService.atou(this.params.button.state_params || ''));
            }
        }

        const navigateEvent: NavigateEvent = {
            defaultPrevented: false,
            preventDefault: () => (navigateEvent.defaultPrevented = true),
            newTab: this.utility.isModifierKeyPressed(event),
            rowData: this.params!.data!,
            itemState: state,
            itemParms: parms,
        };

        this.params?.navigateEventEmitter.emit(navigateEvent);

        if (navigateEvent.defaultPrevented) return;

        if (navigateEvent.newTab) {
            this.state.newTab(navigateEvent.itemState || '', navigateEvent.itemParms);
        } else {
            this.state.go(navigateEvent.itemState || '', navigateEvent.itemParms);
        }
    }

    agInit(params: LinkCellRendererParams): void {
        this.params = params;

        if (params.column?.getColId()) {
            this.text = this.params.valueFormatted ?? this.params.data?.[params.column?.getColId()!] ?? '';
        }
    }

    refresh(_: ICellRendererParams<any, any, any>): boolean {
        return false;
    }
}

/**
 * Represents data required for configuring a link cell renderer.
 */
export interface LinkCellRendererParams extends ICellRendererParams<GridRow, any, any> {
    /**
     * The configuration of the link button.
     */
    button: DBGridButton;

    /**
     * The load data task configuration from the grid.
     */
    loadData: DataTaskRequest;

    /**
     * The remember id from the grid, leave as empty string of not available.
     */
    rememberId: string;

    /**
     * Navigation event emitter for when navigation happens from link cell.
     */
    navigateEventEmitter: EventEmitter<NavigateEvent>;
}
