import { ColDef, ColumnState, FilterModel, FirstDataRenderedEvent, GridApi, GridState, ICellEditorParams, IRowNode, ModelUpdatedEvent, RowSelectedEvent, SetFilterModel, SortState, ToolPanelDef } from '@ag-grid-community/core';
import { Observable } from 'rxjs';

export type ChangeState = 'add' | 'all' | 'update' | 'remove';

/**
 * Represents the changes of a grid row.
 */
export interface GridRowChanges {
    /**
     * The state of the change.
     */
    state: ChangeState;

    /**
     * The row to change with updated values if any.
     */
    data: GridRow;
}

/**
 * Represents general info of a column.
 */
export interface GridColumnInfo {
    /**
     * The id of the column.
     */
    colname: string;

    /**
     * The name of the column the info is based on.
     *
     * @deprecated use `colname` instead.
     */
    from?: string;

    /**
     * The data type displayed in the cells of the column.
     */
    type: CellDataTypes | null;

    /**
     * The edit type used for specialfunc edit.
     */
    editType?: string | null;

    /**
     * Editable configuration for the column.
     */
    editable?: boolean | null | LookupConfig | string;

    /**
     * Whether the grid should skip this column when navigating in editmode.
     */
    clickonly?: null | boolean;

    /**
     * The set of options used for the select edittype.
     */
    dd_data?: { [key: string]: string }[] | null;

    /**
     * The key of the id property for the set of options used for the select edittype.
     */
    dd_data_id?: string | null;

    /**
     * The key of the name property for the set of options used for the select edittype.
     */
    dd_data_name?: string | null;

    /**
     * The translated title of the column.
     */
    title: string | null;

    /**
     * In a db decimal type defined by (x, y), x is this width.
     */
    decimalWidth: number | null;

    /**
     * Whether the column should be saved in usersettings.
     */
    remember: boolean;
}

/**
 * Represents data used in the grid component.
 */
export interface GridOptionsData {
    /**
     * The column definitions of the grid.
     */
    columnDefinitions: ColDef[];

    /**
     * Extra column information for the columns.
     */
    columnInfo?: { [key: string]: GridColumnInfo };

    /**
     * The row data in the grid.
     */
    rowData: GridRow[];

    /**
     * The active layout keyno chosen during this session.
     */
    layoutKeyno?: string | null;

    /**
     * The unsaved changes of data in the grid rows.
     */
    changes?: { [key: string]: GridRowChanges };

    /**
     * Whether the grid data has any grid glyphs.
     */
    hasGridGlyphs?: boolean;

    /**
     * Whether the grid has a column which should be locked visible.
     */
    hasLockVisibleColumn?: boolean;

    /**
     * Whether the grid has any special function buttons.
     */
    hasSpecialFunc?: boolean;

    /**
     * Whether the grid uses special func edit (edit modal generated based on colschema).
     */
    hasSpecialFuncEdit?: boolean;

    /**
     * Whether the grid has an `is_selected` column.
     */
    hasSelection?: boolean;

    /**
     * Whether the grid data has any goto parameters.
     */
    hasGotoParms?: boolean;

    /**
     * Whether the grid data has any thumbnails.
     */
    hasThumbnails?: boolean;
}

/**
 * Represents an options configuration object to initialize a grid with.
 */
export interface GridOptions {
    /**
     * The data-task configuration for a grid.
     */
    dataTask?: GridDataTask;

    /**
     * Represents data displayed in the grid.
     * *For internal use in the component, or special cases*.
     */
    data?: GridOptionsData;

    /**
     * Comma seperated list of words to translate.
     */
    translations?: string[];

    /**
     * Configuration for the grid.
     */
    config?: GridConfig;

    /**
     * @deprecated use `config` instead.
     */
    kendo?: KendoConfig;

    events?: GridEvents;
    reports?: any[];
    optionfunc?: any;

    /**
     * Configuration of the excel export.
     */
    excelExportConfig?: GridExcelExportConfig;

    /**
     * Callback to call when setup of initial grid data has completed.
     *
     * @param schema the columns of the grid.
     * @param fields the additional data about the columns.
     * @param columns the column definitions used in the grid.
     * @param userTranslations translations from the grid.
     * @returns either nothing or an empty promise.
     */
    onSetup?: (schema: GridColumn[], fields: { [key: string]: GridColumnInfo }, columns: ColDef[], userTranslations: { [key: string]: string }) => Promise<void> | void;

    onSelect?: (event: { $event: RowSelectedEvent; $item?: GridRow }) => unknown;

    /**
     * Function to call when data is loaded to the grid.
     * **IMPORTANT** with ag-grid the event is only called when the data is loaded to the grid for the first time, no sequental times.
     */
    onDataBound?: null | ((event?: FirstDataRenderedEvent) => void);

    /**
     * Function to call when data model of the grid is updated.
     *
     * @deprecated use `ttModelUpdated` event of grid component instead. Like `<tt-grid ... (ttModelUpdated)="update()"></tt-grid>`
     */
    onDataBinding?: null | ((event?: ModelUpdatedEvent) => void);

    /**
     * Object containing additional functions for interacting with the grid.
     */
    gridfunc?: null | GridFunctions;
}

/**
 * Represents object for configuring the excel export.
 */
export interface GridExcelExportConfig {
    /**
     * Whether the colname should be used for header-names when export to excel.
     * By default it is false and the grid uses the language of the user in the export.
     *
     * @default false
     */
    useColnameForHeaders?: boolean;

    /**
     * List of colnames of the columns to exclude from the excel export.
     */
    excludedColumns?: string[];
}

export interface KendoConfig {
    /**
     * The fixed height of the grid, if null or undefined, the grid will autosize to fit the all the rows in the pagesize of the pagination.
     */
    height?: null | number | string;
    sortable?: boolean;

    /**
     * Whether to aggregate or not. If `true` it will calculate the sum of all decimals with length of 17.
     */
    aggregate?: boolean | null | TTGridAggregate[];
    pager?: false;
    pageable?: boolean;
    selectable?: boolean | 'multiple' | 'single';
    filterable?: GridFilterable;
}

export interface GridEvents {
    /**
     * Callback to call when setup of initial grid data has completed.
     *
     * @param schema the columns of the grid.
     * @param fields the additional data about the columns.
     * @param columns the column definitions used in the grid.
     * @param userTranslations translations from the grid.
     * @returns either nothing or an empty promise.
     */
    onSetup?: (schema: GridColumn[], fields: { [key: string]: GridColumnInfo }, columns: ColDef[], userTranslations: { [key: string]: string }) => Promise<void> | void;
}

/**
 * Represents filerable settings of the grid.
 */
export type GridFilterable = boolean | { mode: 'row' };

/**
 * Represents an aggregrate function.
 */
export type TTAggregateFunction = 'count' | 'sum' | 'average' | 'min' | 'max' | 'custom';

/**
 * Represents a aggregate configuration.
 */
export interface TTGridAggregate {
    /**
     * The column field to make an aggregate for.
     */
    field: string;

    /**
     * The aggregate function to calculate for the field.
     */
    aggregate: TTAggregateFunction;

    /**
     * A string which holds formula to calculate aggregate function based on value of other aggregates. Values of other aggregates can be retrieved by using their colname.
     */
    aggregate_function?: string;
}

/**
 * Represents data task configuration for a grid.
 */
export interface GridDataTask {
    /**
     * **Recommended**, human-readible unqiue id used for remembering layout settings for this grid per user.
     */
    rememberId?: string | null;

    /**
     * Method to use instead of the default `1999` grid columns method, for setting up the colschema.
     */
    loadSetupId?: string | null;

    /**
     * The data-task for loading data into the grid.
     */
    loadData?: LoadDataTaskRequest | null;

    /**
     * The data-task for adding a new row into the grid.
     */
    addRow?: AddRowDataTaskRequest | null;

    /**
     * The data-task for deleting a row from the grid.
     */
    removeRow?: RemoveRowDataTaskRequest | null;

    /**
     * The data-task for saving changes done in the grid.
     */
    saveData?: SaveDataTaskRequest | null;
}

/**
 * Checks whether the given value matches the interface of a `DataTaskRequest`.
 *
 * @param value the value to check if is a data task request.
 * @returns `true` if the value matches the interface of a `DataTaskRequest`, `false` if not.
 */
export function isDataTaskRequest(value: unknown): value is DataTaskRequest {
    if (!!value && typeof value === 'object' && Object.hasOwn(value, 'method') && Object.hasOwn(value, 'parameters')) {
        return true;
    }
    return false;
}

/**
 * Represents a data task request.
 */
export interface DataTaskRequest {
    /**
     * The registered procedure number of the datatask request.
     */
    method: number | ((state?: ChangeState, changes?: { [key: string]: GridRowChanges }) => unknown) | null;

    /**
     * The parameters to send with the datatask request.
     */
    parameters: object | null;
}

/**
 * Represents data task request configuration for load data procedure of the grid.
 */
export interface LoadDataTaskRequest extends DataTaskRequest {
    /**
     * The keyname of the property to use as the id for rows, the value of this property must be unique for the row.
     * Setting the primary key of a row helps the grid not flicker when reloading data as it can keep a reference and can simply redraw the row, instead of deleting and inserting a new.
     */
    primaryKey?: string | null;

    /**
     * Whether it us a grid with dynamically rendered columns or not, required as dynamically rendered grids needs to wait for each load function to complete before continuing the next.
     */
    dynamic?: boolean;
}

export interface AddRowDataTaskRequest extends DataTaskRequest {
    /**
     * Reference to function to call after a new row has been inserted.
     *
     * @param row the newly added row.
     */
    post?: ((row?: GridRow) => unknown) | null;

    /**
     * Whether new rows should be auto-saved.
     */
    autoSave?: boolean | null;

    /**
     * Whether a user should confirm before adding a new row.
     */
    confirm?: boolean | null;

    /**
     * Reference to the function to call before a row is to be inserted.
     *
     * @param row the row to be inserted.
     */
    pre?: ((row?: GridRow) => unknown) | null;

    /**
     * Wheather a edit modal should be opened after inserting a new row, default is `false`.
     */
    openEdit?: boolean;
}

export interface RemoveRowDataTaskRequest extends DataTaskRequest {
    /**
     * Reference to function to call after a rows have been removed.
     *
     * @param rows the removed rows.
     */
    post?: ((rows?: GridRow[]) => unknown) | null;

    /**
     * Whether removal of rows should be auto-saved.
     */
    autoSave?: boolean | null;

    /**
     * Whether the user should have to confirm before removing a row.
     */
    confirm?: boolean | null;
}

export type SaveDataTaskRequest = DataTaskRequest & SaveDataConfiguration;

/**
 *
 */
export interface GridConfig {
    /**
     * Configuration of editable columns.
     *
     * @deprecated use edit configuration from colschema datatask procedures.
     */
    editColumns?: EditColumn[];
    fixedHeader?: boolean;
    toolbar?: GridToolbar;
    keepSortOnAdd?: boolean;
    keepSortOnCheckbox?: boolean;
    keepSortOnIsSelected?: boolean;
    rowClick?: boolean;

    readAfterPrint?: boolean;

    /**
     * Configuration of the side-bar.
     */
    sidebar?: GridSidebarConfig;

    /**
     * Whether shortcuts should be enabled in te grid, currently supports `Ctrl + I` / `Ctrl + Shift + I` for inserting new row and `Ctrl + D` for deleting a row.
     */
    shortcuts?: boolean;
    specialFunc?: GridSpecialFunctions;

    /**
     * Navigation settings for the grid.
     */
    navigation?: GridNavigation;
    css?: GridPredefinedCSS;
    onDataSourceChanges?: null;

    onDataChanged?: null | ((event?: any) => void);

    /**
     * Whether paging, filtering and sorting should be handled server side
     *
     * **NB: editing is not supported with server side handling yet**.
     */
    serverSideHandling?: boolean | null;
}

/**
 * Represents configuration option of the side-bar.
 */
export interface GridSidebarConfig {
    /**
     * Whether the sidebar should be hidden.
     */
    hidden?: boolean | null;

    /**
     * Whether the columns panel should be hidden from the side-bar.
     */
    hideColumnsPanel?: boolean | null;

    /**
     * Whether the filter panel should be hidden from the side-bar.
     */
    hideFilterPanel?: boolean | null;

    /**
     * Whether the row group pane should be hidden in the columns panel. **NB: does not disable rowgrouping**.
     */
    hideRowGroupPane?: boolean | null;

    /**
     * Whether the values pane should be hidden in the columns panel.
     */
    hideValuesPane?: boolean | null;

    /**
     * Whether pivot mode toggle should be hidden in the columns panel.
     */
    hidePivotMode?: boolean | null;

    /**
     * Custom toolbar panels, see [ag-grid documentation](https://www.ag-grid.com/angular-data-grid/component-tool-panel/) for implemenation.
     */
    customPanels?: ToolPanelDef[];
}

/**
 * Represents configuration of a grid's toolbar.
 */
export interface GridToolbar {
    /**
     * Whether the toolbar should be hidden or not.
     */
    hidden?: boolean;

    /**
     * Whether pdf-export button should be displayed or not.
     */
    pdfExport?: boolean;

    /**
     * Whether excel-export button should be displayed or not.
     */
    excelExport?: boolean;

    /**
     * Whether filter button, to toggle floating-filters, should be displayed or not.
     */
    filter?: boolean;

    /**
     * Whether button for controlling column visibility should be displayed or not.
     *
     * @deprecated column visibility is not the side panel.
     */
    columnVisibility?: boolean;

    /**
     * Whether button for toggling colnames should be displayed or not.
     */
    headers?: boolean;

    // TODO: whats this?
    edit?: boolean;

    // TODO: whats this?
    lock?: boolean;

    /**
     * Whether buttons for adding row either above or below the selected row, or on top/bottom in the grid should be displayed.
     */
    add?: boolean;

    /**
     * Whether button for adding a row above the selected, or in the top of the grid should be displayed.
     */
    addSimple?: boolean;

    /**
     * Whether button for delete should be displayed or not.
     */
    delete?: boolean;

    /**
     * Whether button for manual save should be displayed or not.
     */
    save?: boolean;

    /**
     * Whether button for toggling text-wrapping should be displayed or not.
     */
    wrapping?: boolean;

    /**
     * Whether button for custom column-layouts should be displayed or not.
     */
    layouts?: boolean;

    /**
     * Whether button for re-rendering rows should be displayed or not.
     */
    refresh?: boolean;

    /**
     * Whether button for reloading grid-data should be displayed or not.
     */
    read?: boolean;

    /**
     * Whether button for completely reinitializing grid should be displayed or not.
     */
    rebind?: boolean;

    /**
     * Whether button for printing reports should be displayed or not.
     */
    print?: boolean;

    /**
     * List of custom toolbar buttons.
     */
    buttons?: (GridButton | DBGridButton)[];

    /**
     * List of custom toolbar toggles.
     */
    toggles?: ToggleButton[];
}

/**
 * Represents a button displayed in the grid.
 */
export interface GridButton {
    /**
     * Name of the button, used in grid component.
     */
    name: string;

    /**
     * Text to display in the button.
     */
    text?: string;

    ariaLabel?: string;

    /**
     * Event handler for the button.
     *
     * @param event the mouse event from the click.
     */
    func(event: MouseEvent): any;

    /**
     * Event handler for the button.
     *
     * @param row the row for which this button was clicked..
     * @param button the button configuration for this button.
     * @param event the mouse event for the button click.
     */
    func(row: GridRow, button: DBGridButton, event: MouseEvent): unknown | Promise<unknown>;

    /**
     * The class for the icon to display.
     */
    icon?: string;

    secondIcon?: string;

    /**
     * Classes to apply to the button.
     */
    cssClass?: string;

    /**
     * Whether the grid should reload data when a popup is closed.
     */
    read_on_close?: boolean;

    /**
     * Whether the icon appended to this toolbarbutton should spin while the action (func) of the button is processing.
     */
    spinOnAction?: boolean;

    /**
     * Whether to translate the text of the button or not, default is true.
     */
    translate?: boolean;

    /**
     * Whether the button should be disabled or hidden.
     */
    disabled?: () => (boolean | 'hidden') | Promise<boolean | 'hidden'>;

    disabled$?: Observable<boolean | 'hidden'>;

    tooltip?: string;

    translateTooltip?: boolean;

    /**
     * Internal use for grid component, do not set value.
     */
    _processing?: boolean;

    _func?: (event: MouseEvent) => any;
}

/**
 * Represents a button set up from colschema.
 */
export interface DBGridButton extends GridButton {
    /**
     * The modal component to open, if type is modal.
     */
    component?: string;

    /**
     * The icon to display for the button.
     */
    icon?: string;

    /**
     * The id of the button.
     */
    keyno?: number;

    /**
     * If type is modal, this is the size of the modal.
     */
    modal_size?: 'pst-ninetyfive' | 'sm' | 'md' | 'lg';

    /**
     * The datatask procedure to call if type is datatask.
     */
    p2_datatask_keyno?: number;

    /**
     * If type is modal and component = ttDynamicView then this is the route to send to the component.
     */
    route_id?: string;

    /**
     * If type is goto, this is the state to go to.
     */
    state?: string;

    /**
     * If type is goto, this the parms to use for the state to go to.
     */
    state_params?: string;

    /**
     * The type of button.
     */
    type?: 'statigoto' | 'goto' | 'datatask' | 'modal' | 'cell' | 'noclick' | 'print' | 'popup';

    /**
     * The button type for the button if its not cell type.
     */
    btn_type?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning';

    disable_if_no_selected?: null | 'true' | 'false' | boolean | '1' | '0' | 1 | 0;
}

/**
 *
 */
export interface GridToolbarToggle {}

export interface ToggleState {
    text: string;
    translate?: boolean;
    func: (state: number) => void;
    _func?: () => void;
    icon: string;
}

export interface ToggleButton {
    /**
     * The name of the button, used to identify the button.
     */
    name: string;

    /**
     * Index of the initial state.
     */
    state: number;

    /**
     * The states of the toggle.
     */
    states: ToggleState[];

    /**
     * Css classes to apply to the button.
     */
    cssClass: string;

    /**
     * Whether the button should be disabled or hidden.
     */
    disabled?: () => (boolean | 'hidden') | Promise<boolean | 'hidden'>;

    disabled$?: Observable<boolean | 'hidden'>;
}

export interface GridSpecialFunctions {
    /**
     * Whether to make the goto button added to each row if it has goto parms to a new tab button.
     */
    newTab?: boolean;

    /**
     * Whether to edit button to row buttons opening a default edit modal.
     */
    edit?: boolean | null;

    /**
     * Custom buttons to add to each row.
     */
    buttons?: DBGridButton[];
}

/**
 * Represents configuration of navigation in grid.
 */
export interface GridNavigation {
    /**
     * Alternative navigation, whether `Enter` key should behave like `Tab`.
     */
    altNav?: boolean;

    /**
     * Whether a new row should be added when pressing `Enter` on the last editable cell or the first editable cell.
     */
    newLine?: boolean;
}

/**
 * Represents configuration for predefined css settings.
 */
export interface GridPredefinedCSS {
    /**
     * Whether to alternate colors or not.
     */
    altColor?: boolean;

    /**
     * Whether text should wrap or not, default is false.
     */
    textWrapping?: boolean;

    height?: string | 'fill';

    minHeight?: string;
}

/**
 * Represents an object used in a grid row.
 */
export type GridRow = { [key: string]: any } & { _dirty?: boolean; _uuid?: string };

/**
 * Represents cell editor configuration for a look-up cell.
 */
export interface LookupCellEditorParams extends ICellEditorParams {
    /**
     * The datatask procedure to use for searching.
     */
    method: string | number;

    /**
     * The colname for the cell where the search is happening.
     */
    field: string;

    /**
     * The key name for the display value of the objects retrieved from the lookup procedure.
     */
    keyname: string;

    /**
     * The key of the id property of the lookup result objects.
     */
    key: string;

    options: GridOptions;

    /**
     * List of objects containing relations keyname for the key (the data key of the cell) to be updated,
     * and the value (the data key of the list from lookup) to update with.
     */
    relations: { key: string; value: string }[];

    autoSelectFirst: boolean;
}

/**
 * Checks whether the given column-editable property of a gridcolumn is of type LookupConfig.
 *
 * @param editable the editable property of a column to check if is a LookupConfig.
 * @returns true if the editable is a LookupConfig, false if not.
 */
export function isEditableLookup(editable: unknown): editable is LookupConfig {
    if (editable && typeof editable === 'object' && Object.hasOwn(editable, 'lookup') && (editable as LookupConfig).lookup !== null && !isNaN(Number((editable as LookupConfig).lookup))) {
        return true;
    }
    return false;
}

export function isEditableDropdown(editable: unknown): editable is DropdownConfig {
    return isEditableLookup(editable) && editable.type === 'dropdown';
}

/**
 * Represents user settings for the grid.
 */
export interface GridUserSettings {
    /**
     * The currently chosen page the user last selected.
     */
    page?: number;

    /**
     * The page size the user last used.
     */
    pageSize?: number;
    /**
     * Map of columns, where column field is key and valye is the respective column settings.
     */
    columns: { [key: string]: GridColumnSetting };

    /**
     * Contains a filters objects which holds a list over the last used filters.
     */
    filter: { filters: GridFilterSetting[]; base64Encoded?: boolean };

    /**
     * List over sort settings.
     */
    sort?: GridSortSetting[];

    /**
     * Setting for last used filter method.
     */
    filterable?: null | GridFilterable;
}

/**
 * Represents a sort setting for a column.
 */
export interface GridSortSetting {
    /**
     * The field name for the column with the sort setting.
     */
    field: string;

    /**
     * The direction of the sort setting.
     */
    dir: 'asc' | 'desc';

    /**
     * The index of the sort, if multiple sorts are applied.
     */
    index?: number | null;
}

/**
 * Represents a filter setting for a column.
 */
export interface GridFilterSetting {
    /**
     * The field name of the column to apply this filter to.
     */
    field: string;

    /**
     * The operator for the filter type on the filter.
     */
    operator: GridFilterType;

    /**
     * The value of filter.
     */
    value: string;

    base64Encoded?: boolean;

    set?: string[];
}

export type GridFilterType = 'contains' | 'doesnotcontain' | 'eq' | 'neq' | 'startswith' | 'endswith' | 'gt' | 'gte' | 'lt' | 'lte' | 'isempty' | 'isnull' | 'isnullorempty' | 'isnotempty' | 'isnotnull' | 'isnotnullorempty';

/**
 * Represents settings for a single column.
 */
export interface GridColumnSetting {
    /**
     * Whether the column should be hidden or not.
     * If not defined the column will be visible.
     */
    hidden?: boolean;

    /**
     * The order of the column where 0 is left and increasing number goes toward the right.
     */
    order?: number;

    /**
     * The width of the column.
     */
    width?: number | null;

    /**
     * Whether the column is pinned or not.
     */
    pinned?: boolean | 'left' | 'right' | null;

    /**
     * The filter string to apply to the columns filter.
     */
    filter?: GridFilterSetting | null;
}

/**
 * Represents a column layout schema.
 */
export type ColumnLayoutSchema = { [key: string]: GridColumnSetting } & { tt_grid_filterable?: boolean };

/**
 * Represents a grid column configuration.
 */
export interface GridColumn {
    /**
     * The field name of the column.
     */
    colname: string;

    /**
     * The db data type of the column.
     */
    coltype: string | null;

    /**
     * In a db decimal type defined by (x, y), x is this width.
     */
    width: number | null;

    /**
     * How many decimals to append to a column of the type `'decimal'`.
     */
    decimals: number | null;

    /**
     * How to align the content in the columns cell.
     */
    align: 'right' | 'left' | 'center' | null;

    /**
     * The translate id of the title.
     */
    title_id: string | null;

    /**
     * The translated title to display.
     */
    title: string | null;

    /**
     * Whether the cells in this columns are editable, or configuration for editing.
     */
    editable: boolean | string | null | LookupConfig;

    /**
     * How the cells in this column should be edited.
     */
    edittype: string | null;

    /**
     * Whether this cilumn should be visible initially before user settings set in.
     */
    visibility: string | null;

    /**
     * The initial width of the column, before usersettings set in.
     */
    colwidth: any;

    /**
     * The initial order of the column, before usersettings set in.
     */
    colorder: null;

    /**
     * Whether the column is sortable or not.
     */
    sortable: null | '1' | '0';

    /**
     * Whether the column is filterable or not.
     */
    filterable: null | '1' | '0';

    /**
     * Stringified function statement to call to style the cells in the column.
     */
    style_script: null;

    /**
     * The set of options to display in the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data: null | { [key: string]: string }[];

    /**
     * The key of the id property of the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data_id: null | string;

    /**
     * The key of the name property of the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data_name: null | string;

    /**
     * The sort to apply on the column.
     */
    sort?: 'asc' | 'desc' | null;

    /**
     * The sort index to apply on the column if theres multiple sorts.
     */
    sort_index?: number | null;

    /**
     * Grid-options set up from database, are only applied if the colname is `'xxxoptionsxxx'`.
     */
    gridoptions: null | DBGridOptions;

    /**
     *
     */
    buttonoptions: null | object;
}

/**
 * Represents configuration for a cell which has a search-field.
 */
export interface LookupConfig {
    /**
     * The id key name for the id property of the the values retrieved from lookup.
     */
    key: string;

    /**
     * The datatask procedure number to use for the seatch.
     */
    lookup: string | number;

    //hhuh
    optionfunc: string | boolean;

    /**
     * The key name for the display proprty of the values retrieved from lookup.
     */
    datatextfield: string | null;

    /**
     * List of objects containing relations keyname for the key (the data key of the cell) to be updated,
     * and the value (the data key of the list from lookup) to update with.
     */
    relations: { key: string; value: string }[];

    /**
     *
     */
    clickonly: boolean;

    autoselectfirst: boolean;

    type: null | 'dropdown';
}

export interface DropdownConfig extends LookupConfig {
    type: 'dropdown';
}

/**
 * Represents configuration of save data procedure.
 */
export interface SaveDataConfiguration {
    /**
     * Whether changes should be confirmed.
     */
    confirm?: null | boolean;

    /**
     * Whether changes to data should be auto-saved, (this one is used internally).
     */
    autoSave?: boolean | null;

    /**
     * Whether changes to data should be auto-saved.
     */
    autoSaveChanges?: null | boolean;

    /**
     * Whether to wait for each save to finish before saving next, and stopping saving when an error occurs.
     */
    single?: null | boolean;

    /**
     * Whether to refresh row after data is saved.
     */
    refreshRowOnSave?: true;

    /**
     * Whether the refresh spinner should be hidden while saving data.
     */
    hideRefreshSpinner?: null | boolean;

    /**
     * Whether to reload the all grid data rows after changes are saved.
     */
    readAfterSave?: null | boolean;

    /**
     * Whether only rows where `is_selected` row is checked should be saved.
     */
    onlySaveIsSelected?: null | boolean;

    /**
     * Whether to include the load data parameters in the save procedure.
     */
    saveInclLoadparms?: null | boolean;
}

/**
 * Represents grid options retrieved from db in colschema.
 */
export interface DBGridOptions {
    load?: {
        /**
         * The keyname of the property to use as the id for rows, the value of this property must be unique for the row.
         * Setting the primary key of a row helps the grid not flicker when reloading data as it can keep a reference and can simply redraw the row, instead of deleting and inserting a new.
         */
        primaryKey?: string | null;
    };

    add?: {
        confirm?: null | boolean;
        autoSave?: null | boolean;
    };

    remove?: {
        confirm?: null | boolean;
        autoSave?: null | boolean;
    };

    /**
     * Configuration for the save data procedure.
     */
    save?: SaveDataConfiguration;

    /**
     * Toolbar buttons from colschema setup.
     */
    toolbar?: GridToolbar;

    sidebar?: GridSidebarConfig;

    aggregates?: boolean | null | TTGridAggregate[];

    /**
     * Configuration of excel exports for this grid.
     */
    excelExportConfig?: GridExcelExportConfig;

    kendo?: {
        selectable?: boolean | 'multiple' | 'single' | 'na';
        sortable?: 'na' | boolean;
        filterable?: null | boolean | 'row' | 'na';
    };

    specialFunc?: {
        newTab?: false;
        buttons?: DBGridButton[];
    };

    reports?: [];
}

/**
 * Represents a column layout used in the grid.
 */
export interface GridColumnLayout {
    /**
     * The id of the column layout.
     */
    gridlayout_keyno: string;

    /**
     * The name of the column layout.
     */
    gridlayout_name: string;

    /**
     * Base64 encoded object of the user settings for this column layout.
     */
    layout_schema: string;

    /**
     * The order placement of this layout in a layouts list.
     */
    orderby: string;

    /**
     * Whether this layout is the current layout applied to the grid.
     */
    isActive?: boolean;

    /**
     * Whether this column layout is getting deleted.
     */
    willGetDeleted?: boolean;
}

/**
 * Represents parameters required for retrieving grid columns.
 */
export interface GridColumnsParams {
    /**
     * The method to use for retrieving columns, defaults to `1999` if not specified.
     */
    method?: number;

    /**
     * The method to use for loading grid row data.
     */
    loadDataMethod: number;

    /**
     * Parameters to use for retrieving row data and columns.
     */
    params: { [key: string]: any } | null;

    force?: boolean;
}

/**
 * Represents parameters used for saving usersettings.
 */
export interface SaveUserSettingsParams {
    /**
     * The method to use for storing user variables, defaults to `616` if not specified.
     */
    method?: number;

    /**
     * The name of the variable to index the value with.
     */
    variablename: string;

    /**
     * The value to store, is stringified to JSON when stored.
     */
    variablevalue: any;

    /**
     * Whether the value should be converted to base 64.
     */
    isBase64?: boolean;
}

/**
 * Represents parameters required for saving a new column layout.
 */
export interface CreateColumnLayoutParams {
    /**
     * The grids load data method which the column layout should be connected to.
     */
    loadDataMethod: number;

    /**
     * The name of the new column layout.
     */
    layoutname: string;

    /**
     * The state of the user settings to be stored as a column layout.
     */
    layout: ColumnLayoutSchema | TTGridState;
}

/**
 * Represent configuration for edit behaviour of a column.
 */
export interface EditColumn {
    /**
     * The colname of the column to configure edit-behaviour of, if only this property is then the column with the given key will be editable.
     */
    key: string;

    /**
     * Whether the column can only be edited by clicking the cell.
     */
    clickonly?: boolean | null;

    /**
     * Lookup configuration for the column if applicable.
     */
    lookup?: null | LookupConfig;
}

/**
 * Represents parameters in datatask requests for server side handling of data.
 */
export interface ServerSideRequestParams {
    /**
     * The index of the last row to retrieve.
     */
    take?: number;

    /**
     * Number of rows to skip past in the request.
     */
    skip?: number;

    /**
     * The filter model to retrieve rows based on.
     */
    filter?: { filters: GridFilterSetting[] };

    /**
     * The sort model to retrieve rows based on.
     */
    sort?: GridSortSetting[];

    /**
     * The current page to retrieve rows for.
     */
    page?: number;

    /**
     * The current page size to retrieve rows for.
     */
    pageSize?: number;
}

/**
 * Represents additional functions to use for interacting with the grid.
 */
export interface GridFunctions {
    /**
     * Redraws in DOM the currently loaded data in the grid.
     */
    refresh: () => void;

    /**
     * Fetches grid data again and reload the rows in the grid.
     */
    read: () => Promise<void>;

    /**
     * Forces a check of the disabling and visibility of toolbar buttons.
     */
    refreshToolbarBtnDisability: () => void;

    /**
     * Forces a recalculation of aggregates.
     */
    refreshAggregates: () => void;

    /**
     * Opens a modal displaying a table of containing the given list of records. The headers of the table are equal to the keynames of the first object in the list.
     */
    callPopupTable: (data: Record<string, unknown>[]) => Promise<null | Record<string | symbol, unknown>>;

    /**
     * Sets the cell at the given row index and column index to the focused cell.
     *
     * @param rowIndex the index of the row where to set the focused cell.
     * @param colIndex the index of the column (zero-based, only visible) for where to set the focused cell.
r     */
    setFocusToCell: (rowIndex: number, colIndex: number) => void;

    /**
     * Starts cell editing on the cell with the given row-index and given column-index if the column is editable and visible.
     *
     * @param rowIndex the row index to start editing on.
     * @param colIndex the column index to start editing on.
     */
    editCell: (rowIndex: number, colIndex: number) => void;

    /**
     * Clears the current filters from the grid.
     */
    clearFilter: () => void;

    /**
     * Clears the current sortings from the grid.
     */
    clearSorting: () => void;

    /**
     * Inserts a row before the row at the given index, containing the given data-item values.
     *
     * @param atIndex the index of the row to insert the new row before.
     * @param dataItem the row data to insert into the row.
     * @returns a promise containing the newly added row once the add operation is complete.
     */
    addRowBefore: (atIndex: number, dataItem?: GridRow) => Promise<GridRow | undefined>;

    /**
     * Inserts a row after the row at the given index, containing the given data-item values.
     *
     * @param atIndex the index of the row to insert the new row after.
     * @param dataItem the row data to insert into the row.
     * @returns a promise containing the newly added row once the add operation is complete.
     */
    addRowAfter: (atIndex: number, dataItem?: GridRow) => Promise<GridRow | undefined>;

    /**
     * @deprecated not supported anymore after switch to ag-grid, also was not used anywhere.
     */
    getColumnFormatType: (key: string) => string;

    /**
     * @deprecated used to return list of responses from an `Promise.all`, but the list is not the same in ag-grid and it was also never used anywhere.
     */
    getResponse: () => void;

    /**
     * @deprecated not used anywhere, and key information about columns is found in `getGridColumn` and `getColumnSchema`.
     */
    getResponseColumns: () => GridColumn[];

    /**
     * Redraws the given row, or the given row at the given row index.
     *
     * @param row the row to redraw, set as `null` if using row index to update row instead.
     * @param rowIdx the index of the row to update.
     */
    updateRow: (row: GridRow | null, rowIdx?: number) => void;

    /**
     * Removes the given data-item (grid-row). The item must contain its assigned _uuid.
     *
     * @param dataItem the grid row to remove.
     * @returns promise which resolves when removal has finished. Promise contains the removed row or `null` if the removal was cancelled
     */
    removeRow: (dataItem: GridRow) => Promise<GridRow | null>;

    /**
     * Remove currently selected (selected with native select) rows.
     *
     * @returns promise which resolves when row removal has finished. Promise contains removed rows or `null` if the removal was cancelled.
     */
    removeRows: () => Promise<GridRow[] | null>;

    /**
     * Returns a list over edited (dirty) rows.
     *
     * @returns list over edited (dirty) rows.
     */
    getDirtyRows: () => GridRow[];

    /**
     * Returns currently selected rows based on ag-grid native row selection.
     *
     * @returns list of currently selected rows based on ag-grid native row selection.
     * @deprecated use `getSelectedRows` instead.
     */
    getSelectedRow: () => GridRow[];

    /**
     * Returns currently selected rows based on ag-grid native row selection.
     *
     * @returns list of currently selected rows based on ag-grid native row selection.
     */
    getSelectedRows: () => GridRow[];

    getIsSelectedRows: () => GridRow[];

    /**
     * Retrieves all rows from the grid, also non-displayed rows.
     *
     * @returns all rows from the grid, also non-displayed rows.
     */
    getAllRows: () => GridRow[];

    /**
     * Retrieves rows from the grid, also non-displayed rows. If dirty is true then only dirty rows are retrieved.
     * If sorted is true the rows are returned in the order of the current sort settings.
     *
     * @param dirty whether to filter out to retrieve only dirty rows.
     * @param sorted whether to retrieve the rows in the order of the current sort settings.
     * @returns rows from the grid.
     */
    getRows: (dirty?: boolean, filtered?: boolean, sorted?: boolean) => GridRow[];

    /**
     * Retrieves the row at the given index.
     *
     * @param index the index of the row to retrieve.
     * @returns the row at the given index, or `null` if there are no rows at the index.
     */
    getRowAt: (index: number) => IRowNode | null;

    /**
     * @deprecated kendo grid object doesnt exist in ag-grid `:)` use other gridfunction or try getGridApi for special cases.
     */
    getGrid: () => {};

    /**
     * Returns the ag-grid grid-api.
     *
     * @returns the ag-grid grid-api.
     */
    getGridApi: () => GridApi;

    /**
     * Retrieves the row at the given index.
     *
     * @param atIndex the index of the row to retrieve.
     * @returns the row at the given index, or `null` if there are no rows at the index.
     * @deprecated use `getRowAt` instead.
     */
    getDataItem: (atIndex?: number) => GridRow | null;

    /**
     * Returns list over all displayed rows in the grid.
     *
     * @returns list of all displayed rows in the grid.
     */
    getDataItems: () => GridRow[];

    /**
     * Returns object containing information about datasource.
     *
     * @returns object containing information about datasource.
     * @deprecated due to upgrade of grid the object is incomplete and no longer in use.
     */
    getDataSource: () => { sort: () => { field: string; dir: 'asc' | 'desc' }[] };

    /**
     * Returns a list of the current column states.
     *
     * @returns a list over current column states.
     */
    getColumnState: () => ColumnState[];

    /**
     * Sets the given new data source as data in the grid.
     *
     * @param newDataSource list of rows to set as data in the grid.
     */
    setDataSource: (newDataSource: GridRow[]) => void;

    /**
     * Returns list of column defintions of the columns in the grid.
     *
     * @returns list of column definitions of the columns in the grid.
     */
    getGridColumns: () => ColDef[];

    /**
     * Returns object containing information about each column in the grid, where the key is the colname of each column.
     *
     * @returns object containing information about each column in the grid.
     */
    getColumnSchema: () => { [key: string]: GridColumnInfo };

    /**
     * TODO: find out what it does and document.
     */
    viewMatching: (criteria: string | undefined, value: unknown) => void;

    /**
     * Saves all current changes in the grid. Returns a promise which resolves when all save operations are completed.
     *
     * @param showProgress whether to show loading overlay while saving changes or not.
     * @returns a void promise which resolves when the load operation is completed.
     */
    saveChanges: (showProgress?: boolean) => Promise<void>;

    /**
     * Completely removes the grid from DOM and refetches all data for the grid. Resolves a void promise when rebind is completed.
     * **nb** if multiple rebind is called for the same grid, only the last rebind call will resolve.
     *
     * @returns void promise that resolves when the rebind is complete.
     */
    rebind: () => Promise<void>;

    /**
     * Redraws the row.
     *
     * @param rowElement not in use. do not use. dont expect anything from using this.
     * @param row the grid row object to update.
     * @deprecated use updaterow.
     */
    redrawRow: (rowElement: HTMLElement, row: GridRow) => void;

    /**
     * Start or stop the grid loading overlay.
     *
     * @param spin `true` if loading overlay should show, `false` if the loading overlay should be hidden.
     */
    gridProgress: (spin?: boolean) => void;

    /**
     * Selects the row at the given index of currently visible rows.
     *
     * @param index the row index of the row to select.
     */
    selectRow: (index: number) => void;

    /**
     * Returns whether the grid is finished loading and ready for use.
     *
     * @returns `true` if the grid is ginished loading and ready for use, `false` if not.
     */
    isReady: () => boolean;

    /**
     * Returns whether the grid has any row data or not.
     *
     * @returns `true` if the grid has row data, `false` if not.
     */
    hasRows: () => boolean;

    /**
     * Resizes the grid elements.
     */
    resize: () => void;

    /**
     * Test function.
     *
     * @param p1 parameter 1.
     * @param p2 parameter 2.
     */
    test: (p1: unknown, p2: unknown) => string;
}

export type CellDataTypes = 'text' | 'number' | 'boolean' | 'date' | 'currency' | 'datetime' | 'time' | 'alt' | 'icon';

/**
 * Represents options for the initialization of the grid.
 */
export interface InitGridParams {
    /**
     * The options to initialize the grid with.
     */
    options?: GridOptions;

    /**
     * Whether to show a loading overlay for the grid while initializing.
     */
    showLoading?: boolean;

    /**
     * Whether to setup the ttOptions object again for the initialization of the grid.
     */
    setupTTOptions?: boolean;

    /**
     * Whether to fetch new datatask columns for initialization of the grid.
     */
    newDataTaskColumns?: boolean;

    /**
     * Function to call when the intialization is complete.
     *
     * @param value
     * @returns
     */
    resolve?: (value?: unknown) => void;

    /**
     * Function to call if the initialization failed.
     *
     * @param reason
     * @returns
     */
    reject?: (reason?: unknown) => void;
}

/**
 * Maps the given grid-usersettings object to a grid-state object used for setting the initial state of ag-grid.
 *
 * @param settings the grid-usersettings object to map to an ag-grid initial grid-state object.
 * @param columns the column-definitions of the grid for which to map the settings object to. Required for mapping filters, if not provided all filters will be empty.
 * @returns an ag-grid grid-state object.
 */
export function userSettingsToGridState(settings: GridUserSettings, columns?: ColDef[]): TTGridState {
    let state: TTGridState = { floatingFilter: !!settings?.filterable, agGrid: true };

    function addHiddenColumn(colId: string) {
        if (!state.columnVisibility) {
            state.columnVisibility = { hiddenColIds: [] };
        }

        state.columnVisibility.hiddenColIds.push(colId);
    }

    function addColumnWidth(colId: string, width: number) {
        if (!state.columnSizing) {
            state.columnSizing = { columnSizingModel: [] };
        }

        state.columnSizing.columnSizingModel.push({ colId: colId, width: width });
    }

    function addColumnPinned(colId: string, pinned: 'left' | 'right' | boolean) {
        if (!state.columnPinning) {
            state.columnPinning = { rightColIds: [], leftColIds: [] };
        }

        if (pinned === 'left') {
            state.columnPinning.leftColIds.push(colId);
        } else if (pinned === 'right' || pinned === true) {
            state.columnPinning.rightColIds.push(colId);
        }
    }

    for (let [key, colSetting] of Object.entries(settings.columns)) {
        if (colSetting.hidden) addHiddenColumn(key);
        if (colSetting.width) addColumnWidth(key, colSetting.width);
        if (colSetting.pinned) addColumnPinned(key, colSetting.pinned);
    }

    if (settings.page || settings.pageSize) {
        if (!state.pagination) {
            state.pagination = { page: (settings.page ?? 1) - 1, pageSize: settings.pageSize };
        }
    }

    if (settings.sort) {
        if (!state.sort) {
            state.sort = { sortModel: [] };
        }

        state.sort.sortModel = settings.sort.sort((a, b) => (a.index || 0) - (b.index || 0)).map((sortSetting) => ({ sort: sortSetting.dir, colId: sortSetting.field }));
    }

    if (settings.filter?.filters && !!columns) {
        if (!state.filter) {
            state.filter = { filterModel: {} };
        }

        for (let filter of settings.filter.filters) {
            let column = columns.find((col) => col.colId === filter.field);
            console.log('column :>> ', column);
            if (column) {
                state.filter.filterModel![filter.field] = getFilerModelFromGridFilterSetting(filter, column);
                console.log('state.filter.filterModel![filter.field] :>> ', state.filter.filterModel![filter.field]);
            }
        }
    }

    if (Object.values(settings.columns).some((column) => column.order !== undefined)) {
        if (!state.columnOrder) {
            state.columnOrder = { orderedColIds: [] };
        }

        state.columnOrder.orderedColIds = Object.entries(settings.columns)
            .sort(([_a, a], [_b, b]) => {
                if (a.order === undefined || a.order === null) return 1;
                if (b.order === undefined || b.order === null) return 1;
                return (a.order ?? -1) - (b.order ?? -1);
            })
            .map(([key, _]) => key);
    }

    return state;
}

/**
 * Retrieves the filtermodel
 */
function getFilerModelFromGridFilterSetting(filter: GridFilterSetting, column: ColDef) {
    let filterModel: any = {};
    try {
        if (column.cellDataType === 'boolean') {
            filterModel = getTextFilterModel(filter);
        } else {
            filterModel = {
                filterType: 'multi',
                filterModels: [],
            };

            switch (column.cellDataType) {
                case 'date':
                case 'datetime':
                    filterModel.filterModels.push(getDateFilterModel(filter));
                    break;
                default:
                    filterModel.filterModels.push(getTextFilterModel(filter));
                    break;
            }

            if (!!filter.set && filter.set.length > 0) {
                filterModel.filterModels.push(getSetFilterModel(filter));
            } else {
                filterModel.filterModels.push(null);
            }
        }
    } catch (error) {
        console.log(error);
    }

    return filterModel;
}

/**
 * Creates and returns the filter model for a date filter based on the provided filter setting.
 *
 * @param filter the filter setting to create a date filter model of.
 * @returns a date filter model based on the given filter setting.
 */
function getDateFilterModel(filter: GridFilterSetting): FilterModel {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;

    return {
        dateFrom: filter.value ? new Date((new Date(getFilterValueFromGridFilterSetting(filter)) as any) - timezoneOffset).toISOString().substring(0, 10).replace('T', ' ') : null,
        dateTo: null,
        filterType: 'date',
        type: getFilterType(filter.operator) ?? '',
    };
}

/**
 * Creates and return the text filter model based on the provided filter setting.
 *
 * @param filter the filter setting to create a text filter model of.
 * @returns a text filter model based on the provided filter setting.
 */
function getTextFilterModel(filter: GridFilterSetting): FilterModel {
    return {
        type: getFilterType(filter.operator) ?? '',
        filterType: 'text',
        filter: getFilterValueFromGridFilterSetting(filter),
    };
}

/**
 * Retrieves the filter value of the given filter setting.
 *
 * @param filter the filter setting to retrieve the filter value of.
 * @returns the filter value of the given filter setting.
 */
function getFilterValueFromGridFilterSetting(filter: GridFilterSetting) {
    return filter.base64Encoded !== true ? filter.value : !!filter.value ? atou(filter.value) : '';
}

/**
 * Creates and return a set filter model based of the provided filter setting.
 *
 * @param filter the filter setting to create a set filter model of.
 * @returns a set filter model based on hte provided filter setting.
 */
function getSetFilterModel(filter: GridFilterSetting): SetFilterModel {
    return {
        filterType: 'set',
        values: filter.set ?? [],
    };
}

export function gridStateToColumnLayout(state: TTGridState): ColumnLayoutSchema {
    let schema: ColumnLayoutSchema = {};

    function addPropertyToColumnSetting(colId: string, setting: Partial<GridColumnSetting>) {
        if (colId === 'ag-Grid-AutoColumn') return;

        if (!schema[colId]) {
            schema[colId] = {};
        }

        schema[colId] = { hidden: false, filter: null, width: null, ...schema[colId], ...setting };
    }

    state.columnVisibility?.hiddenColIds.forEach((colId) => addPropertyToColumnSetting(colId, { hidden: true }));
    state.columnSizing?.columnSizingModel.forEach((colState) => addPropertyToColumnSetting(colState.colId, { width: colState.width }));
    state.columnOrder?.orderedColIds.forEach((colId, index) => addPropertyToColumnSetting(colId, { order: index }));

    if (state.filter?.filterModel) {
        schema.tt_grid_filterable = true;
        const filters = getFilterModelAsGridFilterSettings(state.filter.filterModel, false, true).filters;

        for (let filter of filters) {
            addPropertyToColumnSetting(filter.field, { filter: filter });
        }
    }

    return schema;
}

/**
 * Converts the given grid state to a grid user settings object.
 *
 * @returns the grid user settings object.
 */
export function gridStateToUserSettings(state: TTGridState, serverSide?: boolean | undefined, decode: boolean = false): GridUserSettings {
    let settings: GridUserSettings = {
        columns: {},
        filter: {
            filters: [],
            base64Encoded: undefined,
        },
        sort: [],
        filterable: state.floatingFilter === true ? { mode: 'row' } : false,
    };

    function addPropertyToColumnSetting(colId: string, setting: Partial<GridColumnSetting>) {
        if (!settings.columns[colId]) {
            settings.columns[colId] = {};
        }
        settings.columns[colId] = { ...settings.columns[colId], ...setting };
    }

    state.columnVisibility?.hiddenColIds.forEach((colId) => addPropertyToColumnSetting(colId, { hidden: true }));
    state.columnSizing?.columnSizingModel.forEach((colState) => addPropertyToColumnSetting(colState.colId, { width: colState.width }));
    state.columnOrder?.orderedColIds.forEach((colId, index) => addPropertyToColumnSetting(colId, { order: index }));
    state.columnPinning?.leftColIds.forEach((colId) => addPropertyToColumnSetting(colId, { pinned: 'left' }));
    state.columnPinning?.rightColIds.forEach((colId) => addPropertyToColumnSetting(colId, { pinned: 'right' }));

    if (state.sort?.sortModel) {
        settings.sort = getSortStateAsGridSortSettings(state.sort);
    }

    if (state.filter?.filterModel) {
        settings.filterable = true;
        settings.filter = getFilterModelAsGridFilterSettings(state.filter.filterModel, serverSide, decode);
    }

    if (state.pagination) {
        settings.page = state.pagination.page;
        settings.pageSize = state.pagination.pageSize;
    }

    return settings;
}

/**
 * Maps the given sort state to grid sort settings, used for user-settings for kendo grid and for serverside parameters.
 *
 * @param sortState the sort state to map into grid sort settings.
 * @returns a list containing the grid sort settings.
 */
export function getSortStateAsGridSortSettings(sortState?: SortState): GridSortSetting[] {
    return sortState?.sortModel?.map((sort, index) => ({ field: sort.colId, dir: sort.sort, index: index })) || [];
}

/**
 * Maps the given filterModel to a grid filter settings.
 *
 * @param filterModel the filter model to map to a grid filter setting.
 * @param serverSide whether the filter model is used for a serverside grid or not, default is ` false`.
 * @returns the filter model mapped to grid filter settings.
 */
export function getFilterModelAsGridFilterSettings(filterModel?: FilterModel, serverSide = false, decode: boolean = false): { filters: GridFilterSetting[] } {
    if (!filterModel) return { filters: [] };
    let settings: { filters: GridFilterSetting[] } = { filters: [] };

    for (let key in filterModel) {
        try {
            let operator;
            let value;
            let set: string[] = [];

            if (filterModel[key].filterType === 'multi') {
                const filterModels = filterModel[key].filterModels;

                if (filterModels[0]?.type) {
                    operator = getFilterOperatorType(filterModels[0].type);
                    value = getFilterValue(filterModels[0]);
                }

                if (filterModels[1]?.filterType === 'set') {
                    set = filterModels[1]?.values ?? [];
                }
            } else {
                operator = getFilterOperatorType(filterModel[key].type);
                value = getFilterValue(filterModel[key]);
            }

            let filter: GridFilterSetting;

            if (serverSide !== true) {
                if (decode) {
                    let decodedValue = value;

                    try {
                        decodedValue = atou(value);
                    } catch (_) {
                        decodedValue = value;
                    }

                    filter = { field: key, operator: operator as GridFilterType, value: decodedValue };
                } else {
                    filter = { field: key, operator: operator as GridFilterType, base64Encoded: true, value: !!value ? utoa(value) : value, set: [] };
                }
            } else {
                filter = { field: key, operator: operator as GridFilterType, value: value, set: [] };
            }
            settings.filters.push(filter);
        } catch (error) {
            console.log(error);
        }
    }

    return settings;
}

/**
 * Maps the given the operator filter type to a filter type used in ag-grid,
 *
 * @param operator the operator filter type to map to an ag-grid filter type.
 * @returns the ag-grid filter type.
 */
export function getFilterType(operator: GridFilterType): string {
    if (operator === 'contains') {
        return 'contains';
    } else if (operator === 'doesnotcontain') {
        return 'notContains';
    } else if (operator === 'eq') {
        return 'equals';
    } else if (operator === 'neq') {
        return 'notEqual';
    } else if (operator === 'startswith') {
        return 'startsWith';
    } else if (operator === 'endswith') {
        return 'endsWith';
    } else if (operator === 'gt') {
        return 'greaterThan';
    } else if (operator === 'gte') {
        return 'greaterThanOrEqual';
    } else if (operator === 'lt') {
        return 'lessThan';
    } else if (operator === 'lte') {
        return 'lessThanOrEqual';
    } else if (operator === 'isempty' || operator === 'isnull' || operator === 'isnullorempty') {
        return 'blank';
    } else if (operator === 'isnotempty' || operator === 'isnotnull' || operator === 'isnotnullorempty') {
        return 'notBlank';
    }

    return operator;
}

/**
 * Maps the given ag-grid filter type to a filter-operator type used in grid usersettings.
 *
 * @param filterType the ag-grid filter type to map to an operator type used in grid-usersettings.
 * @returns the operator type.
 */
export function getFilterOperatorType(filterType: string): GridFilterType {
    if (filterType === 'contains') {
        return 'contains';
    } else if (filterType === 'notContains') {
        return 'doesnotcontain';
    } else if (filterType === 'equals') {
        return 'eq';
    } else if (filterType === 'notEqual') {
        return 'neq';
    } else if (filterType === 'startsWith') {
        return 'startswith';
    } else if (filterType === 'endsWith') {
        return 'endswith';
    } else if (filterType === 'greaterThan') {
        return 'gt';
    } else if (filterType === 'greaterThanOrEqual') {
        return 'gte';
    } else if (filterType === 'lessThan') {
        return 'lt';
    } else if (filterType === 'lessThanOrEqual') {
        return 'lte';
    } else if (filterType === 'blank') {
        return 'isnullorempty';
    } else if (filterType === 'notBlank') {
        return 'isnotnullorempty';
    }

    throw Error('filter_not_supported');
}

/**
 * Gets the filter value from the given filter model.
 *
 * @param filterModel the filter model to get the filter value from.
 * @returns the filter value from the given filter model.
 */
export function getFilterValue(filterModel: FilterModel): any {
    if (filterModel['filterType'] === 'date') {
        return new Date(new Date(filterModel['dateFrom']).getTime() - new Date().getTimezoneOffset() * 60000);
    } else if (filterModel['filterType'] === 'multi') {
        return filterModel['filterModels'][0]['filter'];
    }

    return filterModel['filter'];
}

/**
 * Base64 encodes the given string.
 *
 * @param data the string to encode.
 * @returns the encoded the string.
 */
export function utoa(data: string): string {
    return btoa(unescape(encodeURIComponent(data)));
}

/**
 * Base64 decodes the diven string.
 *
 * @returns the base64 decoded string.
 */
export function atou(data: string): string {
    return decodeURIComponent(escape(atob(data)));
}

/**
 * Returns the cell data type based on the given column schema.
 *
 * @param column the column schema to get cell data for.
 * @returns the cell data type for the given column schema.
 */
export function getCellDataType(column: GridColumn): CellDataTypes {
    switch (column.coltype) {
        case 'currency':
            return 'currency';
        case 'decimal':
        case 'integer':
        case 'number':
        case 'numeric':
        case 'tinyint':
        case 'smallint':
        case 'unsigned int':
            return 'number';
        case 'date':
            return 'date';
        case 'datetime':
        case 'timestamp':
            return 'datetime';
        case 'time':
            return 'time';
        case 'bit':
        case 'bool':
        case 'boolean':
            return 'boolean';
        default:
            return 'text';
    }
}

/**
 * Represents a grid state object for ag-grid used in touchtime.
 */
export interface TTGridState extends GridState {
    /**
     * Whether the floating filter is visible or not.
     */
    floatingFilter?: boolean;

    /**
     * Whether this state object is using ag grids own state interface.
     */
    agGrid: true;
}

/**
 * If the given filtermodel has a text filter value, this value becomes encoded. If there is nothing to encode, the given filtermodel is simply returned.
 *
 * @param filterModel the filtermodel to encode.
 * @returns the encoded filtermodel if it has a text filter to encode.
 */
export function getEncodedFilterModel(filterModel?: FilterModel, serverSide: boolean = false) {
    if (serverSide !== true && !!filterModel) {
        for (let key of Object.keys(filterModel)) {
            if (filterModel[key].filterType === 'multi') {
                let filter = filterModel[key].filterModels[0];

                if (!!filter && Object.hasOwn(filter, 'filter')) {
                    filterModel[key].filterModels[0] = { ...filter, filter: !!filter.filter ? utoa(filter.filter) : filter.filter, encoded: !!filter.filter };
                }
            }
        }
    }

    return filterModel;
}

/**
 * If the given filtermodel has a text filter value which has been decoded, the value is decoded and set back to the filtermodel which is then returned.
 *
 * @param filterModel the filtermodel to decode.
 * @returns the decoded filtermodel if it has a text filter to encode.
 */
export function getDecodedFilterModel(filterModel?: FilterModel, serverSide: boolean = false) {
    if (serverSide !== true && !!filterModel) {
        for (let key of Object.keys(filterModel)) {
            if (filterModel[key].filterType === 'multi') {
                let filter = filterModel[key].filterModels[0];

                if (!!filter && Object.hasOwn(filter, 'filter')) {
                    filterModel[key].filterModels[0] = { ...filter, filter: !!filter.filter && filter.encoded === true ? atou(filter.filter) : filter.filter, encoded: undefined };
                }
            }
        }
    }

    return filterModel;
}
