(function () {
    'use strict';

    //Vartdal pakkseddel: p2_reportdef_keyno = 19 , navn: VartdalPlastPakkSeddel1.trdp
    //Vartdal pakkseddel: p2_reportdef_keyno = 22 , navn: VartdalPlastPakkSeddel3Copies.trdp

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansConsignmentContent', {
        templateUrl: 'views/components/views/consignmentPlansConsignmentContent/consignmentPlansConsignmentContent.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', '$stateParams', 'consignmentPlansConsignmentContentService', 'rememberService', 'utilityService', 'dbPrinterService', 'printPreviewService', 'printService', 'ieScreenBlockService', 'modalService', '$uibModal', function ($timeout, stateService, $stateParams, consignmentPlansConsignmentContentService, rememberService, utilityService, dbPrinterService, printPreviewService, printService, ieScreenBlockService, modalService, $uibModal) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            var resizing = false;
            var editing = true;
            var onDestroy = [];

            var variableNames = {
                printer: ''
            };

            vm.model = {
                consignment_keyno: $stateParams.consignment_keyno,
                custact_no: $stateParams.custact_no,
                //counter: $stateParams.counter,
                labourNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.saveTemporaryQuantities(function () { stateService.go('consignmentplansdeliverylabour', {}) }) }
                ],
                stockLocationNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.saveTemporaryQuantities(function () { stateService.go('consignmentplansdeliverylocation', {}) }) }
                ],
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.consignmentPlansConsignmentContentColumnResize();
                                    }

                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }

                        /** UNCHECK ALL */

                        /*if (item.item_name === 'uncheck') {
                            vm.productionhTopsComponentsColumnUncheckAll();
                        }*/
                        if (item.item_name === 'details') {
                            stateService.go('consignment', {
                                consignment_keyno: $stateParams.consignment_keyno
                            });
                        }

                        if (item.item_name === 'menu') {
                            stateService.go('consignmentplansdelivery', {});
                        }

                        if (item.item_name === 'plus') {
                            //stateService.go('consignmentplansdelivery',{});
                        }

                        if (item.item_name === 'check') {
                            //checkLines();
                        }

                        if (item.item_name === 'edit') {
                            //console.log('enabled: ' + item.enabled);
                            //console.log('colorActive: ' + item.colorActive);
                            //console.log('isActive: ' + item.isActive);
                            if (item.isActive === false) {
                                console.log('Endret modus av');
                                vm.saveTemporaryQuantities(function () {
                                    loadLines();
                                });
                            }
                        }

                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.consignmentPlansConsignmentContentColumnResize();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        //{ item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        //{ item_id: '5', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: false },
                        { item_id: '5', item_name: 'menu', glyph: 'glyphicon-align-justify', color: 'primary', enabled: true, show: true },
                        { item_id: '6', item_name: 'details', glyph: 'glyphicon-new-window', color: 'primary', enabled: false, show: true },
                        { item_id: '7', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '8', item_name: 'edit', glyph: 'glyphicon-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '9', item_name: 'check', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '10', item_name: 'uncheck', glyph: 'glyphicon-unchecked', color: 'primary', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                lines: [],
                linesRaw: [],
                settings_mode: 0,
                deliverymethod_no: 0,
                deliverymethods: [],
                delivery_note: '',
                loadingzone: '',
                loaded: false,
                selectedCount: 0,
                canComplete: true,
                showComplete: 0,
                checkMode: 0,
                order_internal_no: 0,
                driver_button_label: '',
                stocklocation_keyno: 0,
                stocklocation_name: '',
                labour_no: '',
                labour_name: '',
                is_last: '0',
                printer: 0,
                printers: [],
                reportdef_keyno: 0,
                reportdef_keyno_waybill: 0,
                p2_reportdef_keyno: 0,
                p2_reportdef_test_keyno: 0,
                lockedModus: false
            };

            vm.labourers = {
                list: []
            };

            /*if (parseInt(vm.model.counter) > 0){
                vm.model.counter = parseInt(vm.model.counter) + 1;
            } else {
                vm.model.counter = 1;
            }*/

            vm.saveSettings = function () {
                consignmentPlansConsignmentContentService.remember('consignmentplansdelivery.deliverymethod_no', vm.model.deliverymethod_no);
                consignmentPlansConsignmentContentService.remember('consignmentplansdelivery.loadingzone', vm.model.loadingzone);

                if (vm.model.settings_mode === 1) {
                    vm.model.settings_mode = 0;
                } else {
                    vm.model.settings_mode = 1;
                }
            };

            vm.cancelSettings = function () {
                if (vm.model.settings_mode === 1) {
                    vm.model.settings_mode = 0;
                } else {
                    vm.model.settings_mode = 1;
                }
            };

            let findObjectInList = function (list, key, value) {
                let index = list.findIndex(obj => obj?.[key] === value);

                return { index: index, data: list[index] };
            };

            vm.changeDriver = function () {
                vm.saveTemporaryQuantities(function () {
                    stateService.go('consignmentplansdeliverydriver', {
                        argtype: 'order',
                        argvalue: vm.model.order_internal_no
                    });
                });
            };

            vm.onLabourerChange = function (value) {
                let selectedItem = findObjectInList(vm.labourers.list, 'item_id', value);

                if (selectedItem?.data?.item_name) {
                    vm.model.labour_no = selectedItem.data.item_id;
                    vm.model.labour_name = selectedItem.data.item_name;
                    rememberService.remember('consignmentplansdelivery.labour_no', vm.model.labour_no);
                    rememberService.remember('consignmentplansdelivery.labour_name', vm.model.labour_name);
                }
            };

            vm.updateModus = function () {
                vm.model.lockedModus = true;

                var parms = {
                    consignment_keyno: vm.model.consignment_keyno,
                    item_is_selected: '0'
                };

                consignmentPlansConsignmentContentService.switchModus(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedModus = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedModus = false;
                        loadLines();
                    }
                });
            };

            var reportDefinitions = {};

            vm.loadPrinters = function () {
                dbPrinterService.list().then(function (data) {
                    angular.copy(data, vm.model.printers);
                    vm.model.printers.push({ item_id: 9999999999, item_name: 'debug no print' });
                    /*
                    bulkReturnService.getDefaultPrinter()
                        .then(function(data){
                            vm.model.printer = data[0].variablevalue;
                        });
                    */
                });
            };

            vm.loadPrinters();

            vm.printPicklist = function () {

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return '';
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) return;

                    ieScreenBlockService.start();
                    console.dir(vm.model.order_internal_no)
                    console.dir(vm.model.printer)

                    consignmentPlansConsignmentContentService.printPicklist(vm.model.order_internal_no, vm.model.printer).then(function (result) {
                        ieScreenBlockService.stop();

                        if (result[0].errorcode !== '0') {
                            modalService.show({
                                type: 'danger',
                                title: 'Varsel',
                                message: result[0].errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-danger',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                        else {
                            modalService.show({
                                type: 'success',
                                title: 'Varsel',
                                message: 'Done',
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }

                        vm.gridOptions.gridfunc.read();
                    });
                });



            }


            var loadSettings = function () {
                consignmentPlansConsignmentContentService.loadSettings({
                    consignment_keyno: vm.model.consignment_keyno
                }).then(function (data) {
                    vm.model.loadingzone = data.loadingzone;
                    vm.model.deliverymethod_no = data.deliverymethod_no;
                    vm.model.order_internal_no = data.order_internal_no;
                    vm.model.driver_button_label = data.delivery_button_label;

                    if (!parseInt(vm.model.deliverymethod_no) > 0) {
                        vm.model.settings_mode = 1;
                    }

                    vm.model.printer = data.printer;
                    vm.model.labour_no = data.labour_no;
                    vm.model.labour_name = data.labour_name;
                    vm.model.stocklocation_keyno = data.stocklocation_keyno;
                    vm.model.stocklocation_name = data.stocklocation_name;
                    vm.model.reportdef_keyno = data.reportdef_keyno;
                    vm.model.reportdef_keyno_waybill = data.reportdef_keyno_waybill;
                    vm.model.delivery_note = data.delivery_note;
                    vm.model.delivery_information = data.delivery_information;

                    vm.labourers.item_id = vm.model.labour_no;
                    vm.labourers.item_name = vm.model.labour_name;
                });
            };

            var loadDeliveryMethods = function () {
                consignmentPlansConsignmentContentService.loadDeliveryMethods().then(function (data) {
                    angular.copy(data, vm.model.deliverymethods);
                    loadSettings();
                });
            };

            loadDeliveryMethods();

            var loadLines = function () {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');

                consignmentPlansConsignmentContentService.loadLines({
                    consignment_keyno: vm.model.consignment_keyno
                }).then(function (data) {
                    var smallDataLoad = [];

                    for (var i = 0; i < data.length; i++) {
                        if (i == 0) {
                            vm.model.is_last = data[i].is_last;
                        }

                        data[i].item_id = illegalCharRegExp(data[i].item_id);
                        smallDataLoad.push(data[i]);
                    }

                    angular.copy(smallDataLoad, vm.model.lines);
                    angular.copy(smallDataLoad, vm.model.linesRaw);
                    vm.loaded = true;

                    ieScreenBlockService.stop();
                    validateLines();
                });
            };

            loadLines();

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    //variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;

                    switch (key) {
                        case 'printer':
                            variableNames[key] = 'consignmentplansdelivery.printer';
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'printer':
                        if (utilityService.validateParmsValue(`${vm.model.printer}`) !== true) return;

                        variableValue = vm.model.printer;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            var illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            };

            vm.saveTemporaryQuantities = function (callback) {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Lagrer antall');

                var records = [];

                angular.forEach(vm.model.lines, function (item) {
                    if (item.item_id > 0) {
                        records.push({
                            ordered_quantity: item.item_secondary[2].item_col,
                            consignmentline_keyno: item.item_id,
                            item_is_selected: item.item_is_selected,
                            cancel_rest: item.item_secondary[6].item_col
                        });
                    }
                });

                consignmentPlansConsignmentContentService.remember('consignmentplansdeliverycontent.' + vm.model.consignment_keyno + '.delivery_note', vm.model.delivery_note).then(function (data) {
                    consignmentPlansConsignmentContentService.temporaryQuantities(records).then(function (data) {
                        ieScreenBlockService.stop();

                        if (callback) {
                            callback();
                        }
                    });
                });
            };

            vm.consignmentPlansConsignmentContentColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateList = [];

                if (angular.isDefined(vm.model.lines[0]) && angular.isDefined(vm.model.lines[0].item_secondary)) {
                    for (var r = 0; r < vm.model.lines[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.lines[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.lines[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.lines[0].item_secondary[r].item_col_width })
                        }
                    }
                }

                var updateParms = {
                    view_id: 'consignmentplansconsignmentcontent.list',
                    records: updateList
                };

                consignmentPlansConsignmentContentService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        console.dir(data);

                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }

                    resizing = false;
                });
            };

            var updateSelectedLineCount = function () {
                vm.model.selectedCount = 0;
                angular.forEach(vm.model.lines, function (item) {
                    if (item.item_is_selected !== '0') {
                        vm.model.selectedCount = vm.model.selectedCount + 1;
                    }
                });
            };

            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id === '0' || item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                    item.item_secondary[2].item_col = item.item_secondary[3].item_col;
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                    item.item_secondary[2].item_col = '0.00';
                }
            };

            //vm.gridClick = function(item){
            /*var baseItem = angular.isDefined(item.item) ? item.item : item;
            var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;

            if (baseItem.item_id != 0){
                if (baseItem.item_is_selected != '0'){
                    baseItem.item_is_selected = '0';
                } else {
                    //baseItem.item_is_selected = '1';
                    selectItem(baseItem);
                }
            }
            
            updateSelectedLineCount();*/
            //};

            /*var checkLines = function(){
                if (vm.model.checkMode > 0){
                    //uncheck all
                    angular.forEach(vm.model.lines, function(item){
                        item.item_is_selected = '0';
                    });
                    vm.model.checkMode = 0;
                } else {
                    //check all
                    angular.forEach(vm.model.lines, function(item){
                        selectItem(item);
                    });
                    vm.model.checkMode = 1;
                }
                updateSelectedLineCount();
            };
    
            var selectItem = function(item){
                if (item.item_picked == '0'){
                    item.item_is_selected = '1';
                }
            };*/


            vm.complete = function (with_waybill) {
                var confirmationModal = null;

                console.dir(vm.model.lines);

                const dates = new Set();

                for (var i = 1; i < vm.model.lines.length; i++) {
                    var line = vm.model.lines[i];
                    if (line.item_is_selected == 1) {
                        dates.add(line.item_secondary[5].item_col);
                    }
                };

                if (dates.size === 1) {
                    confirmationModal = {
                        type: 'info',
                        title: 'Bekreft',
                        message: 'Er du sikker på at du vil fullføre disse linjene?',
                        okLabel: 'Ok',
                        cancelLabel: 'Avbryt'
                    };
                } else {
                    confirmationModal = {
                        type: 'danger',
                        title: 'Advarsel',
                        message: 'Det er flere forskjellige leveringsdatoer på linjene du har valgt.  Skal disse virkelig leveres nå?',
                        okLabel: 'Ok',
                        cancelLabel: 'Avbryt'
                    };
                }

                modalService.confirm(confirmationModal).then(function () {
                    if (vm.model.is_last == '1') {
                        if (vm.model.printer > 0) {
                            // If printer equals the special value: no printing, just complete delivery.
                            if (vm.model.printer === 9999999999) {
                                consignmentPlansConsignmentContentService.remember('consignmentplansdelivery.printer', vm.model.printer);
                                ieScreenBlockService.start('message');
                                ieScreenBlockService.setMessage('Fullfører valgte linjer uten utskrift');

                                var records = [];
                                angular.forEach(vm.model.lines, function (item) {
                                    if (item.item_id > 0) {
                                        records.push({
                                            ordered_quantity: item.item_secondary[2].item_col,
                                            consignmentline_keyno: item.item_id,
                                            cancel_rest: item.item_secondary[6].item_col
                                        });
                                    }
                                });

                                consignmentPlansConsignmentContentService.completeDelivery(records, vm.model.delivery_note)
                                    .then(function (data) {
                                        ieScreenBlockService.setMessage('Printing');
                                        ieScreenBlockService.setMessage('Printing completed');
                                        ieScreenBlockService.stop();
                                        stateService.go('consignmentplansdelivery', {});
                                    });
                            } else {
                                // Regular printing branch
                                consignmentPlansConsignmentContentService.remember('consignmentplansdelivery.printer', vm.model.printer);
                                ieScreenBlockService.start('message');
                                ieScreenBlockService.setMessage('Fullfører valgte linjer');

                                var records = [];
                                angular.forEach(vm.model.lines, function (item) {
                                    if (item.item_id > 0) {
                                        records.push({
                                            ordered_quantity: item.item_secondary[2].item_col,
                                            consignmentline_keyno: item.item_id,
                                            cancel_rest: item.item_secondary[6].item_col
                                        });
                                    }
                                });

                                consignmentPlansConsignmentContentService.completeDelivery(records, vm.model.delivery_note).then(function (data) {
                                    var reportKeyno = vm.model.reportdef_keyno;
                                    return printPreviewService.loadP2ReportDef(reportKeyno);
                                }).then(function (data) {
                                    var reportDefinitions = angular.copy(data[0]);
                                    ieScreenBlockService.setMessage('Printing');

                                    var parms = {
                                        reportKeyno: reportDefinitions.printserver_keyno,
                                        printerKeyno: vm.model.printer,
                                        parameters: {
                                            consignment_keyno: vm.model.consignment_keyno
                                        },
                                        printerProperties: {
                                            isLandscape: reportDefinitions.isLandscape,
                                            pageAutoSize: true,
                                            copies: 1
                                        }
                                    };

                                    if (reportDefinitions.paper_size_id === 'custom') {
                                        parms.printerProperties.pageAutoSize = false;
                                        parms.printerProperties.scale = 'none';
                                        parms.printerProperties.paperSize = {
                                            width: reportDefinitions.width,
                                            height: reportDefinitions.height
                                        };
                                    }
                                    //console.log('PARMS_before: ', parms)
                                    return printService.print(parms).then(function () {
                                        console.log('PARMS: ', parms)
                                        // Check if we also need to print a waybill.
                                        if (with_waybill === true) {
                                            var reportKeynoWaybill = vm.model.reportdef_keyno_waybill;
                                            return printPreviewService.loadP2ReportDef(reportKeynoWaybill).then(function (data) {
                                                var reportDefinitionsWaybill = angular.copy(data[0]);
                                                ieScreenBlockService.setMessage('Printing waybill');

                                                var parmsWaybill = {
                                                    reportKeyno: reportDefinitionsWaybill.printserver_keyno,
                                                    printerKeyno: vm.model.printer,
                                                    parameters: {
                                                        consignment_keyno: vm.model.consignment_keyno
                                                    },
                                                    printerProperties: {
                                                        isLandscape: reportDefinitionsWaybill.isLandscape,
                                                        pageAutoSize: true,
                                                        copies: 1
                                                    }
                                                };

                                                if (reportDefinitionsWaybill.paper_size_id === 'custom') {
                                                    parmsWaybill.printerProperties.pageAutoSize = false;
                                                    parmsWaybill.printerProperties.scale = 'none';
                                                    parmsWaybill.printerProperties.paperSize = {
                                                        width: reportDefinitionsWaybill.width,
                                                        height: reportDefinitionsWaybill.height
                                                    };
                                                }

                                                return printService.print(parmsWaybill);
                                            });
                                        }
                                    });
                                }).then(function () {
                                    ieScreenBlockService.setMessage('Printing completed');
                                    ieScreenBlockService.stop();
                                    stateService.go('consignmentplansdelivery', {});
                                });
                            }
                        } else {
                            modalService.show({
                                type: 'danger',
                                title: 'Printer ikke valgt',
                                message: 'Printer ikke valgt',
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                    } else {
                        ieScreenBlockService.start('message');
                        ieScreenBlockService.setMessage('Fullfører valgte linjer');

                        var records = [];
                        angular.forEach(vm.model.lines, function (item) {
                            if (item.item_id > 0) {
                                records.push({
                                    ordered_quantity: item.item_secondary[2].item_col,
                                    consignmentline_keyno: item.item_id
                                });
                            }
                        });

                        consignmentPlansConsignmentContentService.completeDelivery(records, vm.model.delivery_note)
                            .then(function (data) {
                                ieScreenBlockService.stop();
                                if (vm.model.custact_no > 0) {
                                    stateService.go('consignmentplansconsignments', {
                                        custact_no: vm.model.custact_no
                                    });
                                } else {
                                    stateService.go('consignmentplansdelivery', {});
                                }
                            });
                    }
                });
            };

            vm.reloadPage = function () {
                var con_keyno = $stateParams.consignment_keyno;
                //var cnt = vm.model.counter;

                var parms = {
                    consignment_keyno: con_keyno
                    //counter: cnt
                };

                $timeout(function () {
                    stateService.go('consignmentplansconsignmentcontent', parms, { reload: true, inherit: false });
                }, 500);
            };

            vm.parseInt = function (aNum) {
                var out = parseInt(aNum);

                if (!out >= 0) {
                    out = 0;
                }

                return out;
            };

            /*modalService.show({
                type: type || 'default',
                title: title || '',
                message: message,
                buttons: [{
                    label: label,
                    cssClass: cssClass,
                    action: function (dialogItself) {
                        dialogItself.close();
                        deferred.resolve();
                    }
                }]
            });*/

            var validateLines = function () {
                var errorLines = 1;
                vm.model.showComplete = 0;

                if (vm.model.lines.length > 1) {
                    errorLines = 0;
                }

                angular.forEach(vm.model.lines, function (item) {
                    if (item.item_id > 0) {
                        var avail = parseFloat(item.item_secondary[3].item_col);
                        var requested = parseFloat(item.item_secondary[2].item_col);

                        if (avail >= requested) {
                            //line is ok
                        } else {
                            //errorLines += 1;
                        }
                    }
                });

                if (errorLines == 0) {
                    vm.model.showComplete = 1;
                }
            };

            var detectChanges = function () {
                $timeout(function () {
                    validateLines();
                    detectChanges();
                }, 1000);
            };

            detectChanges();

            /*watcher.$watch(function () { 
                return vm.model.lines; 
            }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                if (vm.model.lines.length > 1){
                    validateLines();
                }
            });*/

            vm.onPrinterChanged = function (value) {
                vm.model.printer = value;

                if (value) {
                    rememberFunc('printer');
                }
            };

            vm.$onInit = function () {
                consignmentPlansConsignmentContentService.loadLabourers().then(function (data) {
                    angular.copy(data, vm.labourers.list);
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
