import { Component, Inject, OnInit } from '@angular/core';
import { GridService } from '../../grid.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutService } from '@app/core/services/layout.service';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { TranslateService } from '@app/core/services/translate.service';
import { ColumnLayoutSchema, GridColumnLayout, TTGridState } from '../../grid.types';

@Component({
    selector: 'tt-column-layout-modal',
    templateUrl: './column-layout-modal.component.html',
    styleUrls: ['./column-layout-modal.component.css'],
})
export class ColumnLayoutModalComponent implements OnInit {
    /**
     * List of available column layouts.
     */
    public columnLayouts: GridColumnLayout[] = [];

    /**
     * Whether data loading is completed or not.
     */
    public ready: boolean = false;

    /**
     * The layoutname entered into the input field, either to create new layout or to change name.
     */
    public layoutname: string = '';

    /**
     * Error message when trying to create new layout.
     */
    public errormessage: string = '';

    /**
     * Whether one of the column layouts matches the current layout of the grid.
     */
    public hasCurrentLayout: boolean = false;

    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {
        ttgrid_modal_layouts_heading: '',
        ttgrid_modal_layouts_placeholder: '',
        ttgrid_modal_layouts_placeholder_error: '',
        ttgrid_modal_layouts_placeholder_no_layout: '',
        ttgrid_modal_layouts_name_not_unqiue: '',
        ttgrid_modal_layouts_name_too_short: '',
        close: '',
        delete: '',
        new_layout: '',
        restore: '',
        save: '',
        current_layout_tooltip: '',
    };

    /**
     * Styling to apply to the modal.
     */
    public style: Style = {
        input: {},
        addButton: {},
        item: {},
    };

    constructor(public dialogRef: MatDialogRef<ColumnLayoutModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ColumnLayoutModalData, private gridService: GridService, private layoutService: LayoutService, private coreComponentService: CoreComponentService, private translateService: TranslateService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
                this.style['input'].height = info.height - 1 + 'px';
                this.style['addButton'].height = info.height - 1 + 'px';
                this.style['item'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    /**
     * Closes the dialog and returns the chosen column layout to be applied.
     *
     * @param layout the layout to apply.
     */
    applyColumnLayout(layout: GridColumnLayout) {
        this.dialogRef.close(layout);
    }

    /**
     * Retrieves all column layouts for the grid.
     */
    async getColumnLayouts() {
        if (this.data?.loadDataMethod && !isNaN(Number(this.data.loadDataMethod))) {
            this.columnLayouts = (await this.gridService.getColumnLayouts(this.data.loadDataMethod)).map((layout) => {
                return <GridColumnLayout>{
                    ...layout,
                    isActive: !this.data.activeLayoutKeyno ? this.doesLayoutsMatch(layout.layout_schema, this.data.gridColumnLayout) : this.data.activeLayoutKeyno === layout.gridlayout_keyno,
                    hasUpdates: false,
                    willGetDeleted: false,
                };
            });

            this.hasCurrentLayout = this.columnLayouts.some((layout) => this.doesLayoutsMatch(layout.layout_schema, this.data.gridColumnLayout));
        }

        this.ready = true;
    }

    /**
     * Creates a new column layout if name is defined and unique.
     */
    async createColumnLayout() {
        if (!this.layoutname?.trim()) {
            this.errormessage = this.translations['ttgrid_modal_layouts_name_too_short'];
            return;
        }

        if (this.columnLayouts.some((layout) => layout.gridlayout_name === this.layoutname)) {
            this.errormessage = this.translations['ttgrid_modal_layouts_name_not_unqiue'];
            return;
        }

        if (this.layoutname?.trim() && !this.columnLayouts.map((layout) => layout.gridlayout_name).includes(this.layoutname)) {
            await this.gridService.createColumnLayout({ loadDataMethod: this.data.loadDataMethod, layoutname: this.layoutname, layout: this.data.gridColumnLayout });
            this.dialogRef.close();
        }
    }

    /**
     * Updates the given layout with the new name if defined, and the grids current layout.
     *
     * @param layout the layout to update.
     */
    async updateActiveLayout(layout: GridColumnLayout) {
        layout.gridlayout_name = !!this.layoutname?.trim() ? this.layoutname : layout.gridlayout_name;
        layout.layout_schema = this.gridService.utoa(JSON.stringify(this.data.gridColumnLayout));

        await this.gridService.updateColumnLayout(layout);

        this.applyColumnLayout(layout);
    }

    /**
     * Checks if the layout of the given schema and the current layout matches.
     *
     * @param encodedLayoutSchema the encoded layout schema to check if matches.
     * @param currentLayoutSetting the current layout data to check if matches the schema.
     * @returns `true` if the layouts matches, `false` if not.
     */
    private doesLayoutsMatch(encodedLayoutSchema: string, currentLayoutSetting: ColumnLayoutSchema | TTGridState) {
        return encodedLayoutSchema === this.gridService.utoa(JSON.stringify(currentLayoutSetting));
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.translations));

        Object.keys(this.translations).forEach((key) => {
            if (translations[key]) {
                this.translations[key] = translations[key];
            }
        });
    }

    ngOnInit(): void {
        this.translate();
        this.getColumnLayouts();
        this.dialogRef.beforeClosed().subscribe(async () => {
            const layoutsToDelete = this.columnLayouts.filter((layout) => layout.willGetDeleted === true);

            layoutsToDelete.forEach((layout) => this.gridService.deleteColumnLayout(layout));
        });
    }
}

/**
 * Represents data to pass to the modal.
 */
export interface ColumnLayoutModalData {
    /**
     * The load data method for the grid this column layout modal is opened for.
     */
    loadDataMethod: number;

    /**
     * The current column layout of the user.
     */
    gridColumnLayout: ColumnLayoutSchema | TTGridState;

    /**
     * The id of the current layout.
     */
    activeLayoutKeyno?: string;
}
