import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Style } from '@app/core/services/core-component.service';
import { FormButton } from '../form-field-button/form-field-button.component';

@Component({
    selector: 'tt-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateComponent extends FormFieldBaseComponent implements OnInit, OnChanges, FormFieldButtons {
    /**
     * A date string of the format `YYYY-MM-DD`.
     */
    @Input()
    ttModel: string = '';

    /**
     * Object representing the date given in ttModel, internal use within the component.
     */
    model: null | Date = null;

    /**
     * Emits event with a string representation of the new date given in the event if the format `YYYY-MM-DD HH:MM`.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * Whether to allow a date to be empty (empty string).
     */
    @Input()
    set ttAllowEmpty(value: BooleanInput) {
        this._allowEmpty = coerceBooleanProperty(value);
    }
    get ttAllowEmpty(): boolean {
        return this._allowEmpty;
    }
    _allowEmpty = true;

    @Input()
    public override style: Style = {
        date: {},
        hour: {},
        minute: {},
    };

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    @Input()
    ttButtons?: FormButton[] | undefined;

    /**
     * Ids of elements in the component.
     */
    id = {
        dateInput: crypto.randomUUID(),
    };

    /**
     * Handles changes in the date values, and emits the `ttModelChange` event with the new date-string.
     *
     * @emits `(ttModelChange)` event emitted with the new date string.
     */
    onModelChange(event: null | Date) {
        this.model = event;

        if (this.model) {
            this.ttModel = this.model.toISOString().substring(0, 10);
            this.rememberSubject.next(this.ttModel);
            this.ttModelChange.emit(this.ttModel);
        } else {
            if (!!this.ttModel && !this.ttAllowEmpty) {
                this.model = new Date(this.ttModel);
                this.rememberSubject.next(this.ttModel);
                this.ttModelChange.emit(this.ttModel);
            } else if (!this.ttModel && !this.ttAllowEmpty) {
                this.model = new Date();
                this.ttModel = this.model.toISOString().substring(0, 10);
                this.rememberSubject.next(this.ttModel);
                this.ttModelChange.emit(this.ttModel);
            } else {
                this.ttModel = '';
                this.rememberSubject.next(this.ttModel);
                this.ttModelChange.emit(this.ttModel);
            }
        }
    }

    private async getLastModelState() {
        if (!this.ttOnlyRemember && this.ttRememberId) {
            const last = (await this.remember.getLastStatus(this.ttRememberId))[0].variablevalue;
            this.setModelFromString(last);
        }
    }

    private setModelFromString(model: string) {
        const dateValue = model.split(' ')[0];

        if (dateValue && new Date(dateValue).toString() !== 'Invalid Date') {
            this.model = new Date(dateValue);
        } else if (!dateValue && !this.ttAllowEmpty) {
            this.model = new Date();
        }
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (!this.ttOnlyRemember && this.ttRememberId) {
            this.getLastModelState();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes?.['ttAllowEmpty']) {
            this.ttAllowEmpty = changes['ttAllowEmpty'].currentValue;
        }

        if (changes?.['ttModel']) {
            this.setModelFromString(changes['ttModel'].currentValue);
        }
    }
}
