(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('requisitionSimplified', {
        templateUrl: 'views/components/views/requisitionSimplified/requisitionSimplified.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', 'requisitionSimplifiedService', 'utilityService', 'ttGridFactory', 'rememberService', 'stateService', 'modalService', 'translateService', function ($q, requisitionSimplifiedService, utilityService, ttGridFactory, rememberService, stateService, modalService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let variableNames = {
                requisitiondel_keyno: '',
                requisitiondel_keyno_from: '',
                stockplace_id_from: '',
                stockplace_id_to: '',
                labour_no: '',
                status_no: ''
            };

            vm.translations = {
                send: '',
                receive: '',
                undo_send: '',
                undo_receive: '',
            };

            vm.model = {
                setting: {},

                requisitionSimplifiedViewBoxes: {
                    //height: '25vh',
                    rememberId: 'requisitionsimplified.boxes',
                    boxes: [
                        { item_id: uuid(), item_name: 'requisitiondel', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'requisition', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'filters', type: 'v', size: '25', depth: 1 },
                        { item_id: uuid(), item_name: 'requisitiondelgrid', type: 'v', size: '75', depth: 1 }
                    ],
                },

                requisitiondelKeynoSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('requisitiondel_keyno') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => search('requisitiondel_keyno') }
                ],
                requisitiondelKeynoFromSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('requisitiondel_keyno_from') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => search('requisitiondel_keyno_from') }
                ],

                selectListStockplaceFrom: [],
                selectListStockplaceTo: [],
                selectListLabour: [],
                selectListStatus: [],

                gridRequisitionReady: false,
                gridRequisitionDelReady: false

            };

            vm.gridRequisition = new ttGridFactory({
                rememberId: 'w_requisitionSimplifiedRequisitionGrid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: true,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridRequisitiondel = new ttGridFactory({
                rememberId: 'w_requisitionSimplifiedRequisitiondelGrid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: true,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }, //'multiple, row'
                    filterable: false
                }
            });

            vm.gridRequisitiondel.onDataBound = () => {
                setTimeout(() => {
                    try {
                        vm.gridRequisitiondel.gridfunc.selectRow(0)
                        //vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                    } catch (error) {

                    }
                }, 100);
            },

            vm.gridRequisitiondel.onSelect = (event) => {
                if (event.$item) {
                    vm.model.setting.requisitiondel_keyno = event.$item.requisitiondel_keyno;

                    loadGridRequisition();
                }
            };



            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadTranslations() {
                const data = await translateService.translateBatch(vm.translations);

                Object.keys(vm.translations).forEach((key) => {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            }

            let loadSetting = function () {
                const deferred = $q.defer();

                requisitionSimplifiedService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadGridRequisitiondel(),
                        loadGridRequisition()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockplaceFrom = function () {
                requisitionSimplifiedService.listStockplaceFrom().then(function (list) {
                    angular.copy(list, vm.model.selectListStockplaceFrom);
                });
            };
            let loadStockplaceTo = function () {
                requisitionSimplifiedService.listStockplaceTo().then(function (list) {
                    angular.copy(list, vm.model.selectListStockplaceTo);
                });
            };
            let loadLabour = function () {
                requisitionSimplifiedService.listLabour().then(function (list) {
                    angular.copy(list, vm.model.selectListLabour);
                });
            };
            let loadStatus = function () {
                requisitionSimplifiedService.listStatus().then(function (list) {
                    angular.copy(list, vm.model.selectListStatus);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridRequisitiondel = function () {
                vm.gridRequisitiondel.dataTask.loadData = {
                    method: 3497,
                    parameters: vm.model.setting
                };

                vm.model.gridRequisitionDelReady = true;

                if (vm.gridRequisitiondel.gridfunc !== null) vm.gridRequisitiondel.gridfunc.read();
            };

            let loadGridRequisition = function () {
                vm.gridRequisition.dataTask.loadData = {
                    method: 3498,
                    parameters: vm.model.setting
                };

                vm.model.gridRequisitionReady = true;

                if (vm.gridRequisition.gridfunc !== null) vm.gridRequisition.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS


            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'requisitiondel_keyno':
                    case 'requisitiondel_keyno_from':
                    case 'stockplace_id_from':
                    case 'stockplace_id_to':
                    case 'labour_no':
                    case 'status_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'requisitiondel_keyno':
                        vm.model.setting.requisitiondel_keyno = '';

                        rememberFunc('requisitiondel_keyno');
                        loadGridRequisitiondel();
                        loadGridRequisition();
                        break;
                    case 'requisitiondel_keyno_from':
                        vm.model.setting.requisitiondel_keyno_from = '';

                        rememberFunc('requisitiondel_keyno_from');
                        loadGridRequisitiondel();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTION

            let search = function (id) {
                switch (id) {
                    case 'requisitiondel_keyno':
                        rememberFunc('requisitiondel_keyno');
                        loadGridRequisitiondel();
                        loadGridRequisition();
                        break;
                    case 'requisitiondel_keyno_from':
                        rememberFunc('requisitiondel_keyno_from');
                        loadGridRequisitiondel();
                        break;
                    default:
                        break;
                }
            };

            // #endregion SEARCH FUNCTION

            // #region BUTTONS FUNCTIONS

            var getIsSelectedItemsParms = function (key) {
                if (!key) return [];
                let dirtyItems = vm.gridRequisitiondel.gridfunc.getDirtyRows();
                let isSelectedItems = [];
                let keyList = [];

                if (angular.isArray(key)) {
                    keyList = key;
                } else {
                    keyList.push(key);
                }

                angular.forEach(dirtyItems, function (item) {
                    if (item && item.is_selected === true) {
                        let data = {};
                        angular.forEach(keyList, function (k) {
                            if (item[k]) data[k] = item[k];
                        });

                        isSelectedItems.push(data);
                    }
                });

                return isSelectedItems;
            };

            var getConfirmMessage = function (proc_name) {
                let confirmMessage = '';
                switch (proc_name) {
                    case 'send':
                        confirmMessage = vm.translations.send;
                        break;
                    case 'receive':
                        confirmMessage = vm.translations.receive;
                        break;
                    case 'undo_send':
                        confirmMessage = vm.translations.undo_send;
                        break;
                    case 'undo_receive':
                        confirmMessage = vm.translations.undo_receive;
                        break;
                    default:
                        break;
                };
                return confirmMessage; 
            }

            
            vm.onClick = async function (proc_name) {
                let errorcode = 0;
                let errormessage = 'IDs processed successfully: ';
                var parmItems = getIsSelectedItemsParms(['requisitiondel_keyno']);

                let requisitiondel_keyno_list = parmItems
                    .map(item => item.requisitiondel_keyno)
                    .join(', ');

                console.log(requisitiondel_keyno_list)
                modalService.confirm({
                    type: 'warning',
                    message: getConfirmMessage(proc_name) + ' ID: ' + requisitiondel_keyno_list
                }).then(async function () {

                    vm.gridRequisitiondel.gridfunc.gridProgress(true);
                    if (parmItems.length > 0) {
                        for (let i = 0; i < parmItems.length; i++) {
                            let item = parmItems[i];
                            let result = '';
                            try {
                                console.log(proc_name)
                                switch (proc_name) {
                                    case 'send':
                                        result = await requisitionSimplifiedService.send(item);
                                        break;
                                    case 'receive':
                                        result = await requisitionSimplifiedService.receive(item);
                                        break;
                                    case 'undo_send':
                                        result = await requisitionSimplifiedService.undoSend(item);
                                        break;
                                    case 'undo_receive':
                                        result = await requisitionSimplifiedService.undoReceive(item);
                                        break;
                                    default:
                                        break;
                                };

                                console.log(result);
                                if (result[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'danger',
                                        title: 'Varsel',
                                        message: errormessage + "\n" + "IDs with error: " + item.requisitiondel_keyno + "\n" + "Error code: " + result[0].errorcode + "\n" + "Error message: " + result[0].errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-danger',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                    errorcode = -1;
                                    break;
                                }
                                else {
                                    errormessage = errormessage + String(item.requisitiondel_keyno) + ", "
                                }
                            } catch (error) {
                                console.error(error);
                                errorcode = -1;
                                break;
                            };
                        };
                    };
                    vm.gridRequisitiondel.gridfunc.gridProgress(false);

                    if (errorcode === 0) {
                        modalService.show({
                            type: 'success',
                            title: 'Varsel',
                            message: 'Done',
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    };

                    vm.gridRequisitiondel.gridfunc.rebind();
                });
            };

            // #endregion BUTTONS FUNCTIONS


            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'requisitiondel_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.requisitiondel_keyno) !== true) return;

                        vm.model.setting.requisitiondel_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.requisitiondel_keyno) !== true) return;

                        rememberFunc('requisitiondel_keyno');
                        loadGridRequisitiondel();
                        loadGridRequisition();
                        break;

                    case 'requisitiondel_keyno_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.requisitiondel_keyno_from) !== true) return;

                        vm.model.setting.requisitiondel_keyno_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.requisitiondel_keyno_from, true) !== true) return;

                        rememberFunc('requisitiondel_keyno_from');
                        loadGridRequisitiondel();                        
                        break;

                    case 'stockplace_id_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_from) !== true) return;

                        vm.model.setting.stockplace_id_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from, true) !== true) return;

                        rememberFunc('stockplace_id_from');
                        loadGridRequisitiondel();
                        break;

                    case 'stockplace_id_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_to) !== true) return;

                        vm.model.setting.stockplace_id_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to, true) !== true) return;

                        rememberFunc('stockplace_id_to');
                        loadGridRequisitiondel();
                        break;

                    case 'labour_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.labour_no) !== true) return;

                        vm.model.setting.labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.labour_no, true) !== true) return;

                        rememberFunc('labour_no');
                        loadGridRequisitiondel();
                        break;

                    case 'status_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.status_no) !== true) return;

                        vm.model.setting.status_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.status_no, true) !== true) return;

                        rememberFunc('status_no');
                        loadGridRequisitiondel();
                        break;

                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS


            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTranslations();
                loadSetting();
                loadStockplaceFrom();
                loadStockplaceTo();
                loadLabour();
                loadStatus();

            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
