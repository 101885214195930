(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('translate', {
        templateUrl: 'views/components/views/translate/translate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'stateService', 'translateListService', 'rememberService', 'layoutService', function ($q, utilityService, stateService, translateListService, rememberService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            let variableNames = {
                language_id_to_translate: '',
                language_id_display: '',
                bg_translate_type: '',
                word_last_used_dt: '',
                searchtext: ''
            };
            
            vm.model = {
                mobileSize: layoutService.getMediaQueries().tablet,
                bgTranslateType: {
                    selected: 'SEARCH',
                    buttons: [
                        { id: 'SEARCH', label: 'bg_translate_type_search', color: 'primary', onClick: () => setTranslateTypeView('SEARCH') },
                        { id: 'NOTTRANSLATED', label: 'bg_translate_type_nottranslated', color: 'primary', onClick: () => setTranslateTypeView('NOTTRANSLATED') },
                        { id: 'TRANSLATED', label: 'bg_translate_type_translated', color: 'primary', onClick: () => setTranslateTypeView('TRANSLATED') }
                    ]
                },
                filterSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => { reset('searchtext'); vm.model.itemsListSearchTranslations = []; } },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchTranslation() }
                ],
                setting: {},
                selectListLanguages: [],
                itemsListTranslations: [],
                itemsListSearchTranslations: [],
                retrieving: false
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setTranslateTypeView = function (id) {
                vm.model.bgTranslateType.selected = vm.model.setting.bg_translate_type = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                translateListService.getTranslate().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setTranslateTypeView(vm.model.setting.bg_translate_type);

                    $q.all([
                        loadTranslations(),
                        loadSearchTranslations()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadLanguages = function () {
                translateListService.listLanguages().then(function (result) {
                    angular.copy(result, vm.model.selectListLanguages);
                });
            };
            
            let loadTranslations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.language_id_to_translate) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.language_id_display) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.word_last_used_dt) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_translate_type) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListTranslations = [];
    
                return translateListService.listTranslations(vm.model.setting).then(function (result) {
                    vm.model.retrieving = false;
                    angular.copy(result, vm.model.itemsListTranslations);
                });
            };

            let loadSearchTranslations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.language_id_to_translate) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.language_id_display) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.word_last_used_dt) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_translate_type) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListSearchTranslations = [];

                return translateListService.listSearchTranslations(vm.model.setting).then(function (result) {
                    vm.model.retrieving = false;
                    angular.copy(result, vm.model.itemsListSearchTranslations);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
    
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'language_id_to_translate':
                    case 'language_id_display':
                    case 'bg_translate_type':
                    case 'word_last_used_dt':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;
    
                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
    
                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }
    
                return rememberService.remember(variableNames[id], variableValue);
            };
    
    		// #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgTranslateType = function (value) {
                vm.model.bgTranslateType.selected = value;
                vm.model.setting.bg_translate_type = vm.model.bgTranslateType.selected;

                rememberFunc('bg_translate_type');

                if (vm.model.setting.bg_translate_type === 'SEARCH') {
                    loadSearchTranslations();
                } else {
                    loadTranslations();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            vm.retrieveChanges = function () {
                vm.model.retrieving = true;

                if (vm.model.setting.bg_translate_type === 'SEARCH') {
                    loadSearchTranslations();
                } else {
                    loadTranslations();
                }
            };

            vm.searchTranslation = function () {
                if (vm.model.setting.searchtext > '') rememberFunc('searchtext');

                loadSearchTranslations();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'language_id_to_translate':
                    case 'language_id_display':
                    case 'word_last_used_dt':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        vm.searchTranslation();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
