(function () {
    'use strict';

    angular.module("imApp").factory("priceListFactorsService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 670,
                    parameters: parms || {}
                });
            },
            listFactors: function (parms) {
                return $ihttp.post({
                    method: 669,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 671,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
