import { Injectable } from '@angular/core';
import { UtilityService } from '../utility.service';

/** JSDoc typedefs from the original code can stay here or be converted to TS interfaces */

/**
 * @typedef {Object} GroupButtonClick
 * @property {() => void} click the function to be called when the group button is clicked.
 */

/**
 * @typedef {Object} GroupButton
 * @property {string} id the id of the group button.
 * @property {string} icon the icon classes needed to apply an icon for the group button.
 * @property {'primary' | 'warning' | 'success' | 'danger'} color
 * @property {GroupButtonClick | ((...args: any[]) => void)} onClick the function or object with config for click
 */

/**
 * @typedef {Object} GroupButtonClickConfig
 * @property {GroupButton} button the group button
 * @property {any} parameter optional parameter
 * @property {any} controller the vm / 'this' context
 * @property {Event} [event] optional event
 */

/**
 * @typedef {Object} StyleParam
 * @property {Object} style the default style object for the component.
 * @property {Object} [ttStyle] the custom styling for the component.
 * @property {'left' | 'right' | 'center' | 'justify'} [textAlign] text alignment
 * @property {string | string[]} mainElement the main html element(s) for the component
 */

/**
 * @typedef {Object} LabelConfig
 * @property {boolean} [labelAlwaysOnTop=false] whether the label should always be on top
 * @property {'auto'|'top'|'side'|'none'|'hidden'} [labelView='auto'] positioning/view of the label
 * @property {boolean} [hideLabel=false] whether the label should be hidden
 */


@Injectable({
    providedIn: 'root'
})
export class TtDirectivesService {

    constructor(private utilityService: UtilityService) { }

    // ----------------------------------------------------------------------
    //  ttifyObject
    // ----------------------------------------------------------------------
    /**
     * Converts keys in `object` like "some_property" to "ttSomeProperty".
     */
    public ttifyObject(object: any): any {
        const newObject: any = {};
        for (const property in object) {
            const propertyValue = object[property];
            // Convert underscores to camelCase
            let ttifiedProperty = property.replace(/(_\w)/g, (match) => match[1].toUpperCase());

            // Ensure it starts with 'tt' in PascalCase
            if (!ttifiedProperty.startsWith('tt')) {
                ttifiedProperty = 'tt' + ttifiedProperty.charAt(0).toUpperCase() + ttifiedProperty.slice(1);
            }
            newObject[ttifiedProperty] = propertyValue;
        }
        return newObject;
    }

    // ----------------------------------------------------------------------
    //  setOptions
    // ----------------------------------------------------------------------
    /**
     * Sets the tt-options object on the component controller. 
     * Called when changes from the parent happen.
     */
    public setOptions(controller: any, changes: any): void {
        const options = this.ttifyObject(changes.ttOptions?.currentValue);

        for (const property in options) {
            if (!options[property]) continue;

            if (property === 'ttModel') {
                controller.ttModel = options.ttModel;
                continue;
            }
            if (property === 'ttChange') {
                controller.ttChange = options.ttChange;
                continue;
            }

            // Set fallback if not already defined
            controller[property] ??= options[property];

            // If 'changes[property]' doesn't exist, create a new SimpleChange-like object
            if (!changes[property]) {
                changes[property] = {
                    previousValue: null,
                    currentValue: options[property]
                };
            }

            // If 'changes[property].currentValue' is nullish, set it
            changes[property].currentValue ??= options[property];
        }
    }

    // ----------------------------------------------------------------------
    //  onDestroy
    // ----------------------------------------------------------------------
    /**
     * Calls each function in `onDestroy`.
     */
    public onDestroy(onDestroy: Array<(() => void) | undefined>) {
        onDestroy.forEach((func) => {
            if (typeof func === 'function') {
                func();
            }
        });
    }

    // ----------------------------------------------------------------------
    //  isValidAlign
    // ----------------------------------------------------------------------
    /**
     * Checks if `textAlign` is a valid alignment param (center, left, right, justify).
     */
    public isValidAlign(textAlign: string): boolean {
        return (
            textAlign === 'center' ||
            textAlign === 'left' ||
            textAlign === 'right' ||
            textAlign === 'justify'
        );
    }

    // ----------------------------------------------------------------------
    //  onButtonClick
    // ----------------------------------------------------------------------
    /**
     * Generic click event handler for a group button, applying parameter for a given controller.
     */
    public onButtonClick(config: {
        button: any;       // or GroupButton
        parameter: any;
        controller: any;
        event?: Event;
    }): void {
        const { button, parameter, controller, event } = config;
        if (!button?.onClick) return;

        // If onClick is a direct function
        if (typeof button.onClick === 'function') {
            if (parameter !== undefined) {
                button.onClick(parameter, event);
            } else {
                button.onClick(button, event);
            }
        }
        // If onClick is an object with a 'click' function
        else if (typeof button.onClick === 'object' && typeof button.onClick.click === 'function') {
            const args: any[] = [];
            if (parameter !== undefined) args.push(parameter);

            // push other properties from onClick except 'click'
            for (const key in button.onClick) {
                if (key !== 'click') {
                    args.push(button.onClick[key]);
                }
            }

            if (args.length > 0) {
                button.onClick.click.apply(controller, args);
            } else {
                button.onClick.click(button, event);
            }
        } else {
            // console.error(`Invalid onClick event`, button, controller);
        }
    }

    // ----------------------------------------------------------------------
    //  setStyle
    // ----------------------------------------------------------------------
    /**
     * Sets the style object for a component based on given properties.
     */
    public setStyle(config: {
        style: any;
        ttStyle?: any;
        textAlign?: string;
        mainElement: string | string[];
    }): any {
        const { style, textAlign, mainElement } = config;
        let { ttStyle } = config;

        // If no ttStyle, use style as the base
        if (!ttStyle) {
            ttStyle = { ...style };
        }

        // If ttStyle isn't an object, try to parse from JSON
        if (typeof ttStyle !== 'object' || Array.isArray(ttStyle)) {
            if (typeof ttStyle === 'string') {
                ttStyle = this.stringToObject(ttStyle) ?? {};
            } else {
                // fallback to the original style
                return { ...style };
            }
        }

        // If textAlign is valid, set it on the relevant mainElement(s)
        if (textAlign && this.isValidAlign(textAlign)) {
            if (Array.isArray(mainElement) && mainElement.length > 0) {
                mainElement.forEach((element) => {
                    this.setTextAlignOnStyles({ style, ttStyle, textAlign, mainElement: element });
                });
            } else if (typeof mainElement === 'string' && mainElement.length > 0) {
                this.setTextAlignOnStyles({ style, ttStyle, textAlign, mainElement });
            } else {
                console.error(`Main element missing or invalid in ttDirectivesService.setStyle`);
            }
        }

        return this.mergeObjects(style, ttStyle);
    }

    // ----------------------------------------------------------------------
    //  setClasses
    // ----------------------------------------------------------------------
    /**
     * Merges a 'classes' object with an optional 'ttClasses' (string or object).
     */
    public setClasses(config: { classes: any; ttClasses?: any }): any {
        const { classes } = config;
        let { ttClasses } = config;

        if (ttClasses && typeof ttClasses !== 'object') {
            // if it's a string, parse JSON
            if (typeof ttClasses === 'string') {
                ttClasses = this.stringToObject(ttClasses);
            } else {
                // if it's not string or object, just return classes
                return { ...classes };
            }
        }

        classes.container = ttClasses?.container?.length ? ttClasses.container : classes.container;
        classes.base = ttClasses?.base?.length ? ttClasses.base : classes.base;
        classes.label = ttClasses?.label?.length ? ttClasses.label : classes.label;
        classes.input = ttClasses?.input?.length ? ttClasses.input : classes.input;

        return classes;
    }

    // ----------------------------------------------------------------------
    //  getBaseClasses
    // ----------------------------------------------------------------------
    /**
     * Returns a string of classes to add to position the label according to config.
     */
    public getBaseClasses(labelConfig: {
        labelAlwaysOnTop?: boolean;
        labelView?: 'auto' | 'top' | 'side' | 'none' | 'hidden';
        hideLabel?: boolean;
    }): string {
        const {
            labelAlwaysOnTop = false,
            labelView = 'auto',
            hideLabel = false
        } = labelConfig;

        return this.setLabelConfig({ labelAlwaysOnTop, labelView, hideLabel });
    }

    // ----------------------------------------------------------------------
    //  setLayoutStyle
    // ----------------------------------------------------------------------
    /**
     * Sets styling on the style object from layout information (like fontSizes, height, padding, etc.).
     */
    public setLayoutStyle(style: any, info: any): void {
        Object.keys(style).forEach((key) => {
            if (this.isFormElement(key)) {
                style[key].fontSize = info.fontSizes.textSize;
                style[key].height = info.height + 'px';
                style[key].paddingTop = info.padding.top + 'px';
                style[key].paddingBottom = info.padding.bottom + 'px';
                style[key].paddingLeft = info.padding.left + 'px';
                style[key].paddingRight = info.padding.right + 'px';
            } else if (key === 'textarea') {
                style[key].fontSize = info.fontSizes.textSize;
                style[key].minHeight = '0';
                style[key].maxHeight = 'none';
                style[key].height = (info.height * 3) + 'px';
                style[key].paddingTop = info.padding.top + 'px';
                style[key].paddingBottom = info.padding.bottom + 'px';
                style[key].paddingLeft = info.padding.left + 'px';
                style[key].paddingRight = info.padding.right + 'px';
            } else if (key === 'select') {
                style[key].fontSize = info.fontSizes.textSize;
                style[key].height = info.height + 'px';
                style[key].paddingTop = info.padding.top + 'px';
                style[key].paddingBottom = info.padding.bottom + 'px';
                style[key].paddingLeft = info.padding.left + 'px';
                style[key].paddingRight = '2rem';
            } else if (key === 'checkbox') {
                style[key].height = info.fontSizes.textSize;
                style[key].width = info.fontSizes.textSize;
                style[key].fontSize = info.fontSizes.textSize;
            } else if (key === 'iconButtonText') {
                style[key] = info.fontSizes.textSizeL;
            } else if (key === 'icon' || key === 'buttonText') {
                style[key].fontSize = info.fontSizes.textSize;
            } else if (key === 'label') {
                style[key].fontSize = info.fontSizes.textSizeS;
            } else if (key === 'sublabel') {
                style[key].fontSize = info.fontSizes.textSizeSs;
            } else if (key === 'button' || key === 'lockedButton') {
                style[key].fontSize = info.fontSizes.textSize;
                style[key].height = info.height + 'px';
            } else if (key === 'group') {
                style[key].height = info.height + 'px';
            } else if (key === 'badge') {
                style[key].fontSize = info.fontSizes.textSizeSs;
            }
        });
    }

    // ----------------------------------------------------------------------
    //  Private helpers
    // ----------------------------------------------------------------------

    private mergeObjects(style: any, ttStyle: any): any {
        const combined = { ...style };
        for (const key of Object.keys(ttStyle)) {
            if (ttStyle[key]) {
                // If style has this attribute and it's an object, merge them recursively
                if (style?.[key] && typeof ttStyle[key] === 'object' && !Array.isArray(ttStyle[key])) {
                    combined[key] = this.mergeObjects(style[key], ttStyle[key]);
                } else {
                    combined[key] = ttStyle[key];
                }
            }
        }
        return combined;
    }

    /**
     * If 'mainElement' is 'input', 'date', etc., set textAlign
     */
    private setTextAlignOnStyles({ style, ttStyle, textAlign, mainElement }:
        { style: any; ttStyle: any; textAlign: string; mainElement: string; }) {
        if (!textAlign || !this.isValidAlign(textAlign)) return;

        // If there's a custom property on ttStyle for 'mainElement', set it
        if (ttStyle[mainElement] && typeof ttStyle[mainElement] === 'object') {
            ttStyle[mainElement].textAlign = textAlign;
        }
        else if (style[mainElement] && typeof style[mainElement] === 'object') {
            style[mainElement].textAlign = textAlign;
        }
    }

    /**
     * Converts a JSON string to an object
     */
    private stringToObject(str: string): any {
        const trimmed = str.trim();
        let jsonString = trimmed;
        if (!jsonString.startsWith('{')) {
            jsonString = '{' + jsonString + '}';
        }
        try {
            return JSON.parse(jsonString);
        } catch (err) {
            console.error('stringToObject: invalid JSON:', str, err);
        }

        return null;
    }

    /**
     * setLabelConfig helper for getBaseClasses
     */
    private setLabelConfig({ labelAlwaysOnTop = false, labelView = 'auto', hideLabel = false }:
        { labelAlwaysOnTop?: boolean; labelView?: string; hideLabel?: boolean; }): string {
        let baseClasses = '';
        if (hideLabel) {
            baseClasses += ' tt-input__base--label-none';
        }

        switch (labelView) {
            case 'top':
                baseClasses += ' tt-input__base--label-top';
                break;
            case 'side':
                baseClasses += ' tt-input__base--label-side';
                break;
            case 'none':
            case 'hidden':
                baseClasses += ' tt-input__base--label-hidden';
                break;
            case 'auto':
            default:
                break;
        }

        if (labelAlwaysOnTop) {
            baseClasses += ' tt-input__base--label-top';
        }
        return baseClasses;
    }

    /**
     * Return true if `property` is a typical form element in setLayoutStyle
     */
    private isFormElement(property: string): boolean {
        return ['input', 'date'].includes(property);
    }
}
