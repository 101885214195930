(function () {
    'use strict';

    angular.module("imApp").factory("custactCalcService", ['$q', '$ihttp', function ($q, $ihttp) {
        let service = {
            loaded: false,
            p2CustactCalcSum: {},
            loadP2CustactCalcSum: function (custact_no, retrievetype, include_top, price_locval, calctype) {
                var deferred = $q.defer();

                var parmsP2CustactCalcSum = {
                    method: 376,
                    parameters: {
                        custact_no: custact_no,
                        retrievetype: retrievetype,
                        include_top: include_top,
                        price_locval: price_locval,
                        calctype: calctype
                    }
                };

                $ihttp.post(parmsP2CustactCalcSum).then(function (data) {
                    service.p2CustactCalcSum = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3416,
                    parameters: parms || {}
                });
            },
            listSubTaskTexts: function (parms) {
                return $ihttp.post({
                    method: 597,
                    parameters: parms || {}
                });
            },
            saveEditText: function (list) {
                return $ihttp.post({
                    method: 598,
                    parameters: list
                });
            },
            createOrder: function (menu) {
                return $ihttp.post({
                    method: 868,
                    parameters: menu
                });
            },
            updatePoComponents: function (menu) {
                return $ihttp.post({
                    method: 869,
                    parameters: menu
                });
            }
        };

        return service;
    }]);
})();
