import { Component, OnInit } from '@angular/core';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { StateService } from '@app/core/services/state.service';

@Component({
    selector: 'tt-p2-datatask-logs',
    templateUrl: './p2-datatask-logs.component.html',
    styleUrls: ['./p2-datatask-logs.component.css'],
})
export class P2DatataskLogsComponent implements OnInit {
    /**
     * The page load to display in the component.
     */
    public get?: P2DatataskLogGet;

    /**
     * The list of datatask logs.
     */
    public logs: Item[] = [];

    /**
     * Whether datatask logs are currently being loaded to component.
     */
    public loadingDatataskLogs = false;

    /**
     * The id of the selected datatask log to display.
     */
    public selectedLog: string | number = '';

    /**
     * Mediaquery for laptop size (min-width).
     */
    public laptopMediaQuery: MediaQueryList;

    constructor(private datatask: DataTaskService, private state: StateService,  layout: LayoutService) {
        this.laptopMediaQuery = layout.getMediaQueries().laptop;

    }

    private async getPageLoad() {
        this.get = (await this.datatask.Post(1632, { portal_user_keyno: this.state.getStateParams()['portal_user_keyno'] }))[0];
    }

    public onLogSelected(log: Item) {
        this.selectedLog = log.item_id;
    }

    public async loadDatataskLogs() {
        this.loadingDatataskLogs = true;

        try {
            if (!!this.get) {
                this.logs = await this.datatask.Post(353, { portal_user_keyno: this.get?.portal_user_keyno });
            }
        } finally {
            this.loadingDatataskLogs = false;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.getPageLoad();
        this.loadDatataskLogs();
    }
}

export interface P2DatataskLogGet {
    searchtext: string;
    portal_user_keyno: string | number;
}
