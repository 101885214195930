(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryOrderStatus', {
        templateUrl: 'views/components/views/deliveryOrderStatus/deliveryOrderStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$uibModal', 'stateService', 'deliveryOrderStatusService', 'ieScreenBlockService', 'modalService', function ($q, $uibModal, stateService, deliveryOrderStatusService, ieScreenBlockService, modalService) {
            var vm = this;

            vm.model = {
                invoiceDate: null,
                setting: {}
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'deliveryorderstatus.list.grid',
                    loadSetupId: null,
                    loadData: {
                        method: 2030,
                        parameters: {}
                    },
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: ['is_selected', 'dos_date'],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'customInvoice', text: 'LAG FAKTURA', func: function () { selectInvoiceDate(); }, icon: 'checkbox-checked', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    disableSortOnCheckbox: true,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple, row',
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            

            var getDeliveryOrderStatus = function () {
                deliveryOrderStatusService.getDeliveryOrderStatus().then(function (data) {
                    angular.copy(data[0], vm.model.setting);
                
                });
                
            };

            function selectInvoiceDate() {
                let selected_deliveries = [];
                let allRows = vm.grid.gridfunc.getAllRows();
                let selectedCount = 0;
                let lastDeliveryDate = '1900-01-01';

                if (vm.model.setting.default_invoice_date_today === '0') {
                    angular.forEach(allRows, function (item) {
                        if (item.is_selected) {
                            if (item.dos_date.toLocaleDateString('en-CA') > lastDeliveryDate) {
                                lastDeliveryDate = item.dos_date.toLocaleDateString('en-CA');
                            }
                            selected_deliveries.push(item);
                            selectedCount += 1;
                        }
                    });
                } else {
                    lastDeliveryDate = new Date().toISOString().split('T')[0];
                };

                
                openInvoiceDateModal(lastDeliveryDate).then(function (result) {
                    vm.model.invoiceDate = result.invoiceDate;
                }).catch((reason) => {
                    //console.log('reason');
                }).finally(() => {
                    customInvoice();                    
                });
            };


            let openInvoiceDateModal = function (lastDeliveryDate) {
                let deferred = $q.defer();

                let modalInstance = $uibModal.open({
                    component: 'deliveryOrderStatusInvoiceDate',
                    resolve: {
                        parameters: function () {
                            return {
                                lastDeliveryDate: lastDeliveryDate
                            };
                        }
                    },
                    //size: 'pst-twenty',
                    //backdrop: 'static'
                });

                modalInstance.result.then(function (response) {
                    deferred.resolve(response);
                }, function () {
                    deferred.reject();
                })

                return deferred.promise;
            };

            function customInvoice() {

                var delivery_keyno_str = '';
                var delivery_keyno_str_debug = '';
                var records = [];
                var selected_deliveries = [];
                var allRows = vm.grid.gridfunc.getAllRows();

                var selectedCount = 0;

                angular.forEach(allRows, function (item) {
                    if (item.is_selected) {
                        selected_deliveries.push(item);
                        selectedCount += 1;
                    }
                });

                if (selectedCount > 0) {
                    ieScreenBlockService.start('message');
                    ieScreenBlockService.setMessage('Generer faktura');

                    angular.forEach(selected_deliveries, function (item) {
                        if (delivery_keyno_str.indexOf(item.item_delivery_keyno) < 0) {
                            delivery_keyno_str += item.item_delivery_keyno + ',';
                            records.push({
                                delivery_keyno: item.item_delivery_keyno,
                                invoice_date: moment(vm.model.invoiceDate).format('YYYY-MM-DD') //moment(item.dos_date).format('YYYY-MM-DD')
                            });
                        }

                        delivery_keyno_str_debug += item.item_delivery_keyno + ',';
                    });

                    var refresh = function () {
                        stateService.go('deliveryorderstatus', {}, { reload: true, inherit: false });
                    };

                    deliveryOrderStatusService.createInvoices({ records: records, delivery_keynos_debug: delivery_keyno_str_debug }).then(function (data) {
                        ieScreenBlockService.stop();

                        if (data.errorcode < 0) {
                            var instance = $uibModal.open({
                                component: 'deliveryOrderStatusError',
                                resolve: {
                                    parameters: function () {
                                        return data;
                                    }
                                },
                                size: 'pst-ninety',
                                backdrop: 'static'
                            });

                            instance.result.then(refresh, refresh);

                            instance.closed.then(refresh);
                        } else {
                            if (angular.isObject(data.translations)) {
                                modalService.show({
                                    type: 'success',
                                    title: data.translations.title,
                                    message: data.translations.msg,
                                    buttons: [{
                                        label: data.translations.ok,
                                        cssClass: 'btn-success',
                                        action: function (modalInstance) {
                                            modalInstance.close();

                                            vm.grid.gridfunc.read();
                                        }
                                    }]
                                });
                            } else {
                                vm.grid.gridfunc.read();
                            }
                        }
                    });
                }
            }

            vm.$onInit = function () {
                getDeliveryOrderStatus();
            };


        }]
    });
})();
