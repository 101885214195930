import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'tt-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.css'],
})
export class SliderComponent extends FormFieldBaseComponent implements OnInit, OnChanges {
    /**
     * The value to set to the slider.
     */
    @Input()
    public ttModel: string | number = 50;

    /**
     * Event emitted when the model has changed.
     */
    @Output()
    public ttModelChange = new EventEmitter<number | string>();

    /**
     * The minimum value the user can set in the slider. If greater than or equal to the max value, the user cannot interact with the slider.
     *
     * @default 0
     */
    @Input()
    public set ttMin(value: NumberInput) {
        this._min = coerceNumberProperty(value);
    }
    public get ttMin(): number {
        return this._min;
    }
    private _min = 0;

    /**
     * The maximum value the user can set in the slider. If greater than or equal to the min value, the user cannot interact with the slider.
     *
     * @default 100
     */
    @Input()
    public set ttMax(value: NumberInput) {
        this._min = coerceNumberProperty(value);
    }
    public get ttMax(): number {
        return this._max;
    }
    private _max = 100;

    /**
     * The value the slider increments with. Inputted value will be rounded to fit the step.
     *
     * @default 1
     */
    @Input()
    public set ttStep(value: NumberInput) {
        this._step = coerceNumberProperty(value);
    }
    public get ttStep(): number {
        return this._step;
    }
    private _step = 5;

    /**
     * The time in ms to wait inbetween each change event.
     *
     * @default 250
     */
    @Input()
    public set ttDebouncTime(value: NumberInput) {
        this._debounceTime = coerceNumberProperty(value);
    }
    public get ttDebouncTime(): number {
        return this._debounceTime;
    }
    private _debounceTime = 250;

    private modelChangeSubject = new Subject<number | string>();

    public id = {
        range: crypto.randomUUID(),
    };

    public onSliderChanged(_: number) {
        this.modelChangeSubject.next(this.ttModel);
    }

    public onNumberInputChanged(event: number | string) {
        const recidual = +event % this.ttStep;

        if (recidual !== 0) {
            const halfOfStep = this.ttStep / 2;

            if (recidual >= halfOfStep) {
                this.ttModel = +event - recidual + this.ttStep;
            } else {
                this.ttModel = +event - recidual;
            }
        }

        this.modelChangeSubject.next(this.ttModel);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.modelChangeSubject.pipe(debounceTime(this.ttDebouncTime)).subscribe({ next: () => this.ttModelChange.emit(this.ttModel) });
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        super.ngOnChanges(changes);

        if (changes['ttMin']) {
            this.ttMin = changes['ttMin'].currentValue;
        }

        if (changes['ttMax']) {
            this.ttMax = changes['ttMax'].currentValue;
        }

        if (changes['ttStep']) {
            this.ttStep = changes['ttStep'].currentValue;
        }

        if (changes['ttDebouncTime']) {
            this.ttDebouncTime = changes['ttDebouncTime'].currentValue;
        }
    }
}
