(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('operationMenu', {
        templateUrl: 'views/components/views/operationMenu/operationMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'operationMenuService', function ($stateParams, stateService, operationMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let operationId = $stateParams.operation_id;
            
            vm.model = {
                operationNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('operationlangs', { operation_id: vm.model.menu.operation_id }) }
                ],
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.menu.prod_keyno }) }
                ],
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadMenu() {
                vm.model.menu = (await operationMenuService.getMenu({ operation_id: operationId }))[0];
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

		    // #endregion ON INIT FUNCTION

        }]
    });
})();
