(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('factoringBunts', {
        templateUrl: 'views/components/views/factoringBunts/factoringBunts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'translateService', 'modalService', 'factoringBuntsComponentService', 'logisticService', 'ttGridFactory', 'rememberService', function (stateService, utilityService, translateService, modalService, factoringBuntsComponentService, logisticService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                businessco_no: '',
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                gridReady: false,
                companyButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                setting: {},
                selectListCompanies: [],
                creatingNewBunts: false
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            async function translate() {
                let data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadSetting() {
                vm.model.setting = (await factoringBuntsComponentService.getSetting())[0];

                loadGrid();
            }

            async function loadCompanies() {
                vm.model.selectListCompanies = (await logisticService.listCompanies({ add_all: '1' }));
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGrid() {
                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.setting.businessco_no) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            function rememberFunc(id) {
                Object.keys(variableNames).forEach((key) => {
                    switch (key) {
                        case '':
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            }

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            function reset(id) {
                switch (id) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        rememberFunc('businessco_no');
                        loadGrid();
                        break;
                    default:
                        break;
                }
            }

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.creatingNewBunts = true;

                factoringBuntsComponentService.createNewBunts(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.creatingNewBunts = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.creatingNewBunts = false;

                        loadGrid();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                translate();
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
