(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('oeeStateEquipments', {
        templateUrl: 'views/components/views/oeeStateEquipments/oeeStateEquipments.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeStateEquipmentsService', function ($stateParams, oeeStateEquipmentsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                itemsListEquipments: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadEquipments = function () {
                oeeStateEquipmentsService.listEquipments({
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListEquipments);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEquipments();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
