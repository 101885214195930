<tt-form-field-base
    [ttRememberId]="ttRememberId"
    [ttRememberDebounceTime]="ttRememberDebounceTime"
    [ttFieldId]="id.dateInput"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttToggleTipText]="ttToggleTipText"
    [ttTranslateToggleTipText]="ttTranslateToggleTipText"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="datetime"
>
    <div class="tt-input__group--time">
        <span class="tt-input__icon--time fa-fw fal fa-calendar"></span>
        <tt-date-input
            [ttId]="id.dateInput"
            [ttTabable]="ttTabable"
            [(ttModel)]="model"
            (ttModelChange)="onModelChange($event)"
            [ttDisabled]="ttDisabled"
            [ttReadonly]="ttReadonly"
            [ttAllowEmpty]="ttAllowEmpty"
        ></tt-date-input>
    </div>

    <tt-form-field-buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
</tt-form-field-base>
