(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('kpiComp', {
        templateUrl: 'views/components/views/kpiComp/kpiComp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'kpiCompService', 'ttGridFactory', 'layoutService', function ($stateParams, stateService, translateService, modalService, kpiCompService, ttGridFactory, layoutService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let kpiCompKeyno = $stateParams.kpicomp_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.update = function () {
                vm.model.edit = { ...vm.model.edit };
            }

            vm.model = {
                kpiCompKeyno: kpiCompKeyno,
                gridKpiReady: false,
                kpiCompNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.kpicomp_name_word_id }) }
                ],
                kpiCompDescriptionButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.kpicomp_description_word_id }) }
                ],
                tileHeadingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.tile_heading_text_word_id }) }
                ],
                tileSubheadingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.edit.tile_subheading_text_word_id }) }
                ],
                edit: {},
                selectListWebpages: [],
                selectListKpiTypes: [],
                fontSizes: layoutService.getFontSizes(),
                lockedSave: false
            };

            vm.gridKpi = new ttGridFactory({
                rememberId: 'kpicomp_usergroup_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                kpiCompService.getEdit({ kpicomp_keyno: kpiCompKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadGridKpi();
                });
            };

            let loadWebpages = function () {
                kpiCompService.listWebpages().then(function (list) {
                    angular.copy(list, vm.model.selectListWebpages);
                });
            };

            async function loadKpiTypes() {
                vm.model.selectListKpiTypes = (await kpiCompService.listKpiTypes());
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            async function loadGridKpi() {
                vm.gridKpi.dataTask.loadData = {
                    method: 3520,
                    parameters: { kpicomp_keyno: vm.model.edit.kpicomp_keyno }
                };

                vm.model.gridKpiReady = true;

                if (vm.gridKpi.gridfunc !== null) vm.gridKpi.gridfunc.read();
            }

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                kpiCompService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadWebpages();
                loadKpiTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
