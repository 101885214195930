(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('oeeDashBoard', {
        templateUrl: 'views/components/views/oeeDashBoard/oeeDashBoard.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['oeeDashBoardService', 'watcherFactory', function (oeeDashBoardService, watcherFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

    		var watcher = watcherFactory.$create(vm);
            var reload = false;

            vm.model = {
                itemsListOeeDashboard: []
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadOeeDashboard = function () {
                oeeDashBoardService.listOeeDashboard().then(function (list) {
                    angular.copy(list, vm.model.itemsListOeeDashboard);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            reload = oeeDashBoardService.reloadOeeDashboard();
            
            watcher.$watch(function () {
                return oeeDashBoardService.reloaded;
            }, function (newValue) {
                if (newValue == false) return;

                reload = oeeDashBoardService.reloadOeeDashboard();
                
                loadOeeDashboard();
            });

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadOeeDashboard();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
