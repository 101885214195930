(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('requisitionToConsignment', {
        templateUrl: 'views/components/views/requisitionToConsignment/requisitionToConsignment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'requisitionToConsignmentService', function ($stateParams, $q, stateService, utilityService, requisitionToConsignmentService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockPlaceIdFrom = $stateParams.stockplace_id_from;
            let stockPlaceIdTo = $stateParams.stockplace_id_to;
            let consignmentKeyno = $stateParams.consignment_keyno;
    		
            vm.model = {
                bgRequisitionToConsignment: {
                    selected: 'FULL',
                    buttons: [
                        { id: 'FULL', label: 'requisitiontoconsignment_full', color: 'primary', onClick: () => setRequisitionToConsignmentView('FULL') },
                        { id: 'PARTLY', label: 'requisitiontoconsignment_partly', color: 'primary', onClick: () => setRequisitionToConsignmentView('PARTLY') },
                        { id: 'NOT', label: 'requisitiontoconsignment_not', color: 'primary', onClick: () => setRequisitionToConsignmentView('NOT') }
                    ]
                },
                setting: {},
                itemsListRequisitionsToConsignment: [],
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setRequisitionToConsignmentView = function (id) {
                vm.model.bgRequisitionToConsignment.selected = vm.model.setting.bg_requisitiontoconsignment = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                requisitionToConsignmentService.getSetting({
                    stockplace_id_from: stockPlaceIdFrom,
                    stockplace_id_to: stockPlaceIdTo,
                    consignment_keyno: consignmentKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    setRequisitionToConsignmentView(vm.model.setting.bg_requisitiontoconsignment);
                    
                    loadRequisitionToConsignments();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadRequisitionToConsignments = function () {
                if (utilityService.validateParmsValue(vm.model.setting.est_date_of_delivery_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_requisitiontoconsignment) !== true) return;
                
                vm.model.itemsListRequisitionsToConsignment = [];
                
                return requisitionToConsignmentService.listRequisitionToConsignments(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListRequisitionsToConsignment);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgRequisitionToConsignment = function (value) {
                vm.model.bgRequisitionToConsignment.selected = value;
                vm.model.setting.bg_requisitiontoconsignment = vm.model.bgRequisitionToConsignment.selected;

                loadRequisitionToConsignments();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region TOGGLE FUNCTIONS
            
    		vm.selectAllFull = function () {
                angular.forEach(vm.model.itemsListRequisitionsToConsignment, function (item) {
    				if (item.item_id === '0' || item.item_id === '-1') return;
    
    				item.item_is_selected = '1';
    			});
    		};
    
    		vm.unselectAllFull = function () {
    			angular.forEach(vm.model.itemsListRequisitionsToConsignment, function (item) {
    				if (item.item_id === '0' || item.item_id === '-1') return;
    
    				item.item_is_selected = '0';
    			});
    		};

            // #endregion TOGGLE FUNCTIONS

            // #region CREATE BUTTON FUNCTION

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                let mergeObj = vm.model.setting;
                mergeObj.records = vm.model.itemsListRequisitionsToConsignment;

                requisitionToConsignmentService.createRequisitionToConsignments(mergeObj).then(function (response) {
                    vm.model.lockedCreate = false;

                    stateService.go(response[0].webpagename, { consignment_keyno: response[0].consignment_keyno });
                }, function () {
                    vm.model.lockedCreate = false;
                });
            };

            // #endregion CREATE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
