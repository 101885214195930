import { Injectable } from '@angular/core';
import { Item } from '@app/core/components/listbox/listbox.component';
import { DataTaskService } from '@app/core/services/data-task.service';

@Injectable({
    providedIn: 'root',
})
export class AccountingService {
    constructor(private datatask: DataTaskService) {}

    /**
     * Retrieves list of accounting-report layouts.
     *
     * @param res which layouts to retrieve, `'1'` retrieves result layouts, `'0'` retrieves non-result layouts and `'a'` retrieves all layouts.
     */
    public getReportLayouts(res: 'a' | '1' | '0' = 'a'): Promise<Item[]> {
        return this.datatask.Post(2801, { res: res });
    }

    /**
     * Retrieves list of years which have accounting history.
     *
     * @param businessco_no the id of the company to retrieve accouting years for.
     */
    public getAccountingYears(businessco_no: string | number): Promise<AccountingYearItem[]> {
        return this.datatask.Post(2820, { businessco_no: businessco_no });
    }
}

/**
 * Represents a accounting year item.
 */
export interface AccountingYearItem {
    /**
     * The year of the item.
     */
    transyear: string;

    /**
     * The displayed value for the year.
     */
    transyear_name: string;

    /**
     * The order place in the list for the accounting year.
     */
    orderby: string;
}
