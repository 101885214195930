// BJS 20250306 - Migrated using chatGPT o1.
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ElementRef,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs';
import { TtItemService } from '../../services/legacy/tt-item.service';
import { TtDirectivesService } from '../../services/legacy/tt-directives.service';
import { EventService } from '../../services/event.service';

/**
 * ButtonLine interface (equivalent to 'btnList' items in original).
 */
export interface ButtonLine {
    item_func?: string;   // function or action name
    color?: string;       // style
    glyph?: string;       // icon
}

export interface TtInputLegacyModel {
    mId: string;
    txtAreaId: string;
    focusKeeper: boolean;
    initialFocus: boolean;
    field: string;
    height: string;
    type: string;
    placeholder: string;
    searchText: string | null;
    selectedText: string | null;
    lines: Record<string, any>;    // or define a more specific type if you know it
    icons: string;
    dtd: Date;
    dth: string;
    dtm: string;
    hours: Array<{ hour: string }>;
    mins: Array<{ mins: string }>;
    dtvar: { minuteInterval: number };
    opened: boolean;
    imOptions: any;  // If known, define a stricter type
    trueValue: string;
    falseValue: string;
    labelAlwaysOnTop: boolean;
    root: HTMLElement | null;
    groupStyle: { [key: string]: string };
    inputStyle: { [key: string]: string };
    glyphStyle: { [key: string]: string };
    selectStyle: { [key: string]: string };
    checkboxStyle: { [key: string]: string };
    labelClasses: string;
    subLabelClasses: string;
    inputClasses: string;
    baseClasses: string;
}

@Component({
    selector: 'tt-input-legacy',
    templateUrl: './tt-input-legacy.component.html',
    styleUrls: ['./tt-input-legacy.component.css']
})
export class TtInputLegacyComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    // ----- [Inputs from the original directive's scope] -----------------------
    @Input() label?: string;         // label (e.g. "labour")
    @Input() plabel?: string;        // alt label, if needed
    @Input() sublabel?: string;      // sub-label
    @Input() type: string = 'input'; // e.g. "search", "input", "textarea", "checkbox", "select", "datetime", "signature", ...
    @Input() typevar: string = '';   // e.g. "rbg", "n3+", "s", "f", "o2", etc.

    // "ptype", "ptypevar", "dtvar" etc. not strictly necessary in Angular, but we can keep them:
    @Input() ptype?: string;
    @Input() ptypevar?: string;
    @Input() dtvar?: { minuteInterval?: number };

    @Input() field: any;             // actual data value
    @Input() myuser?: string;        // used with "message" type, user color
    @Input() spin?: boolean;         // spinner state for search, etc.
    @Input() placeholder?: string;   // placeholder text
    @Input() glyphs?: string;        // glyph classes (glyphicon, fa, etc.)
    @Input() icons?: string;         // optional icon in the field
    @Input() btnList?: ButtonLine[]; // dynamic buttons for "o" typevar
    @Input() btnvar?: string;        // predefined btn sets
    @Input() pbtnvar?: string;       // alt property usage
    @Input() data?: any[];           // array for "select"
    @Input() dataid?: string;        // name of ID field in data
    @Input() dataname?: string;      // name of display field in data

    // Functions from the original directive
    @Input() optionfunc?: (arg?: any) => void;
    @Input() changefunc?: () => void;

    @Input() ieTrueValue: any = '1';   // custom true
    @Input() ieFalseValue: any = '0';  // custom false

    @Input() ttItemId?: string;        // used for ttItemService
    @Input() ttGroupId?: string;       // used for ttItemService
    @Input() ttFieldId?: string;       // used for ttItemService

    // Additional styling or invalid states
    @Input() ttStyle?: any;      // manual style injection
    @Input() ttInvalid?: boolean; // invalid state => red border, etc.

    // ----- [Outputs] -----------------------------------------
    @Output() fieldChange = new EventEmitter<any>();

    // ----- [Internals] ---------------------------------------
    numericEdit: boolean = false; // For "n" typevar editing

    // Datetime-based
    dateValue?: Date;              // "d"
    hourValue: string = '00';      // "h"
    minuteValue: string = '00';    // "m"
    minutesList: string[] = [];    // for intervals

    // BJS 20180525 => custom booleans
    useCustomBoolean: boolean = false;

    // Subscription for layout changes
    private layoutSub?: Subscription;

    // Mimicking $scope.fontSizes
    public fontSizes = {
        textSize: '',
        textSizeS: '',
        textSizeSs: ''
    };

    public model: TtInputLegacyModel = {
        mId: '',              // You might set this after generating a UUID
        txtAreaId: '',
        focusKeeper: false, // For "c" typevar focusing logic
        initialFocus: true, // For "a" typevar (autofocus) handling
        field: '',
        height: '0',
        type: 'text',         // e.g. 'input', 'checkbox', etc.
        placeholder: '',
        searchText: null,
        selectedText: null,
        lines: [],
        icons: '',
        dtd: new Date(),      // for datetime logic
        dth: '00',
        dtm: '00',
        hours: Array.from({ length: 24 }, (_, i) => { return { hour: i.toString().padStart(2, '0') } }),
        mins: [],             // e.g. { mins: '00' }, { mins: '15' }, etc.
        dtvar: { minuteInterval: 1 },
        opened: false,
        imOptions: {},
        trueValue: "'1'",
        falseValue: "'0'",
        labelAlwaysOnTop: false,
        root: document.querySelector(':root') as HTMLElement | null,
        groupStyle: {} as { [key: string]: string },
        inputStyle: {} as { [key: string]: string },
        glyphStyle: {} as { [key: string]: string },
        selectStyle: {} as { [key: string]: string },
        checkboxStyle: {} as { [key: string]: string },
        labelClasses: '',
        subLabelClasses: '',
        inputClasses: '',
        baseClasses: ''
    };

    // Flags we parse out of typevar
    public hasAFlag = false; // 'a' => sets autofocus initially
    public hasAAFlag = false; // 'aa' => sets autofocus initially
    public hasBFlag = false; // 'b' => enables extra button(s)
    public hasCFlag = false; // 'c' => "clear input" icon
    public hasDFlag = false; // 'd' => "textarea as editor" or extra "datetime" usage
    public hasEFlag = false; // 'e' => e-mail button
    public hasFFlag = false; // 'f' => "suggest from search"
    public hasGFlag = false; // 'g' => goTo function on a button
    public hasHFlag = false; // 'h' => text to the right
    public hasKFlag = false; // 'k' => barcode scan button
    public hasNFlag = false; // 'n' => numeric input
    public hasOFlag = false; // 'o' => one or more extra line buttons
    public hasPFlag = false; // 'p' => password input
    public hasQFlag = false; // 'q' => required star behind label
    public hasRFlag = false; // 'r' => readOnly
    public hasSFlag = false; // 's' => highlight or select text on focus
    public hasTFlag = false; // 't' => telephone button
    public hasUFlag = false; // 'u' => up/down (spinner style) input
    public hasZFlag = false; // 'z' => clears input after optionFunc
    public numericPrecision = 0; // if 'n3', parse '3'
    public numericAlwaysPositive = false; // if 'n4+' is used

    // For textareas requiring elastic or dynamic sizing, if needed
    @ViewChild('textareaRef') textareaRef?: ElementRef<HTMLTextAreaElement>;

    constructor(
        private elRef: ElementRef,
        private layoutService: LayoutService,
        private modalService: ModalService,
        private ttItemService: TtItemService,
        private ttDirectivesService: TtDirectivesService,
        private eventService: EventService
    ) {
        this.model.mId = `tt-input-legacy-${this.generateUuid()}`
        this.model.txtAreaId = this.generateUuid();
    }

    private generateUuid(): string {
        // Some quick & dirty example. 
        // Or import { v4 as uuid } from 'uuid'; then return uuid();
        return Math.random().toString(36).substring(2, 9);
    }

    // ========== LIFE CYCLE HOOKS =============================
    ngOnInit(): void {
        // Register with ttItemService if needed
        this.ttItemService.register(this, {
            ttItemId: this.ttItemId,
            ttGroupId: this.ttGroupId,
            ttFieldId: this.ttFieldId
        });

        // If ptype / ptypevar is set
        if (!this.type && this.ptype) {
            this.type = this.ptype;
        }
        if (!this.typevar && this.ptypevar) {
            this.typevar = this.ptypevar;
        }

        this.model.field = this.field ? this.field : '';
        this.model.placeholder = this.placeholder ? this.placeholder : '';

        this.processTypevarFlags();
        this.setupDatetimeDefaults();
        this.loadLayoutSettings();
        this.setupCustomBoolean();
        this.applyNumericFormattingIfNeeded();

        this.dataid = this.dataid || this.dataname;
        this.dataname = this.dataname || this.dataid;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // If 'field' changes from parent, reflect it in model.field
        if (changes['field'] && !changes['field'].isFirstChange()) {
            this.model.field = this.field ?? '';
            this.applyNumericFormattingIfNeeded();
        }

        if (changes['placeholder'] && !changes['placeholder'].isFirstChange()) {
            this.model.placeholder = this.placeholder ?? '';
        }


        if (changes['label'] && !changes['label'].isFirstChange()) {
            if (!this.placeholder) {
                this.model.placeholder = this.label ?? '';
            }
        }
        //if (angular.isUndefined($scope.placeholder)) {
        //    $scope.model.placeholder = $scope.label;
        //}

        // If ttInvalid changes, update style
        if (changes['ttInvalid']) {
            // We can handle style in template with [ngClass]
        }

        if (changes['typevar'] && !changes['typevar'].isFirstChange()) {
            this.processTypevarFlags();
        }
    }

    ngAfterViewInit(): void {
        // Focus if typevar includes 'a' (autofocus) 
        if (this.hasAFlag) {
            const inputEl = this.elRef.nativeElement.querySelector('#' + this.model.mId) as HTMLElement;
            if (inputEl) {
                // If typevar = 'aa', always keep focus?
                inputEl.focus();
            }
        }
    }

    // ========== SERVICE HOOKS (layoutService, modalService) =============
    loadLayoutSettings(): void {
        // We subscribe once; if the LayoutService is behavior-subject-based,
        // we'll get updates every time layout changes.
        this.layoutSub = this.layoutService.layoutChanged.subscribe(info => {
            if (!info) return;

            // 1) Copy fontSizes
            this.fontSizes.textSize = info.fontSizes?.textSize ?? '16px';
            this.fontSizes.textSizeS = info.fontSizes?.textSizeS ?? '14px';
            this.fontSizes.textSizeSs = info.fontSizes?.textSizeSs ?? '12px';

            // 2) groupStyle, e.g. margin bottom
            if (info.margin) {
                this.model.groupStyle['marginBottom'] = info.margin.bottom + 'px';
            }

            // 3) inputStyle: size, height, padding, etc.
            if (info.fontSizes) {
                this.model.inputStyle['fontSize'] = info.fontSizes.textSize;
            }
            if (typeof info.height === 'number') {
                this.model.inputStyle['height'] = info.height + 'px';
            }
            if (info.padding) {
                this.model.inputStyle['paddingTop'] = info.padding.top + 'px';
                this.model.inputStyle['paddingBottom'] = info.padding.bottom + 'px';
                this.model.inputStyle['paddingLeft'] = info.padding.left + 'px';
                this.model.inputStyle['paddingRight'] = info.padding.right + 'px';
            }

            // 4) selectStyle: typically same as input, plus maybe tweak left padding
            this.model.selectStyle['fontSize'] = info.fontSizes?.textSize ?? '16px';
            if (typeof info.height === 'number') {
                this.model.selectStyle['height'] = info.height + 'px';
            }
            if (info.padding) {
                this.model.selectStyle['paddingTop'] = info.padding.top + 'px';
                this.model.selectStyle['paddingBottom'] = info.padding.bottom + 'px';
                this.model.selectStyle['paddingRight'] = info.padding.right + 'px';
                // original code subtracted 3 px from left
                this.model.selectStyle['paddingLeft'] = (info.padding.left ?? 0 - 3) + 'px';
            }

            // 5) glyphStyle
            if (info.fontSizes) {
                this.model.glyphStyle['fontSize'] = info.fontSizes.textSize;
            }

            // 6) Possibly set a CSS variable for typeahead font size
            if (this.model.root && this.model.root.style && typeof this.model.root.style.setProperty === 'function') {
                this.model.root.style.setProperty('--im-uib-typeahead-fontsize', this.fontSizes.textSize);
            }

            // 7) If you had a "ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: info.labelAlwaysOnTop })" in old code:
            if (this.ttDirectivesService && typeof this.ttDirectivesService.getBaseClasses === 'function') {
                this.model.baseClasses = this.ttDirectivesService.getBaseClasses({
                    labelAlwaysOnTop: info.labelAlwaysOnTop
                });
            }

            // 8) Any other watchers from the old code
            //   e.g. if you had: angular.extend($scope.model.selectStyle, $scope.ttStyle);
            //   or dynamic manipulations of checkboxStyle, etc.

            // Done updating the layout-based styles in your component.
            // If your template uses these fields, they should reflect the new styling immediately.
        });
    }

    private isNumber(value: string | undefined | null): boolean {
        if (!value) return false;

        // Try parsing as float; if it's NaN, return false
        return !isNaN(parseFloat(value)) && isFinite(Number(value));
    }

    public whenReady() {
        this.eventService.trigger('element:ready');
    };

    public open(_: any) {
        this.model.opened = true;
    }

    async openCalculator(): Promise<void> {
        // showCalculator(label, plabel, currentValue)
        const newVal = await this.modalService.showCalculator(this.label, this.plabel, this.model.field);

        if (newVal !== undefined) {
            this.model.field = newVal;
            this.emitFieldChange();
        }
    }

    async openSignaturePad(): Promise<void> {
        const sig = await this.modalService.openSignaturePadDialog();

        if (sig !== undefined) {
            this.model.field = sig;
            this.emitFieldChange();
        }
    }

    public goTo(index?: number): any {
        // If an index is provided
        if (index !== undefined) {
            // Check if we have lines and item_func
            if (
                this.model.lines &&
                Object.keys(this.model.lines).length > 0 &&
                this.model.lines[index] &&
                this.model.lines[index].item_func
            ) {
                // Call optionfunc with item_func from the line
                if (typeof this.optionfunc === 'function') {
                    this.optionfunc({ item_func: this.model.lines[index].item_func });
                }
            } else {
                // Otherwise, call optionfunc with item_func = index
                if (typeof this.optionfunc === 'function') {
                    this.optionfunc({ item_func: index });
                }
            }
        } else {
            // If no index, just call optionfunc with no args
            if (typeof this.optionfunc === 'function') {
                this.optionfunc();
            }
        }

        // If 'z' flag is in typevar, clear input afterwards
        if (this.hasZFlag) {
            this.clearInput();
        }
    }

    public goUp(): void {
        // If model.field is numeric, increment by 1
        if (this.isNumber(this.model.field)) {
            const numericValue = parseInt(this.model.field, 10);

            this.model.field = (numericValue + 1).toString();
        }
    }

    public goDown(): void {
        // If model.field is numeric, decrement by 1
        if (this.isNumber(this.model.field)) {
            const numericValue = parseInt(this.model.field, 10);

            this.model.field = (numericValue - 1).toString();
        }
    }

    // ========== TYPEVAR LOGIC ===========================================

    /**
     * Reads the typevar string (e.g. "n3o", "brch", etc.) and sets up internal
     * states in the TtInputLegacyComponent. 
     * This is a direct replacement for the many indexOf checks in the old directive code.
     */
    public processTypevarFlags(): void {
        // reset them each time
        this.hasAFlag = false;
        this.hasAAFlag = false;
        this.hasBFlag = false;
        this.hasCFlag = false;
        this.hasDFlag = false;
        this.hasEFlag = false;
        this.hasFFlag = false;
        this.hasGFlag = false;
        this.hasHFlag = false;
        this.hasKFlag = false;
        this.hasNFlag = false;
        this.numericPrecision = 0;
        this.numericAlwaysPositive = false;
        this.hasOFlag = false;
        this.hasPFlag = false;
        this.hasQFlag = false;
        this.hasRFlag = false;
        this.hasSFlag = false;
        this.hasTFlag = false;
        this.hasUFlag = false;
        this.hasZFlag = false;

        if (!this.typevar) {
            return; // no flags to parse
        }

        if (this.typevar.indexOf('aa') > -1) {
            this.hasAAFlag = true;
        }
        // 'a' => sets autofocus
        const aIndex = this.typevar.indexOf('a');
        if (aIndex > -1) {
            // check if double a
            if (this.typevar.length === aIndex || this.typevar[aIndex + 1] !== 'a') {
                this.hasAFlag = true;
            }
        }
        // 'b' => enables button(s)
        if (this.typevar.indexOf('b') > -1) {
            this.hasBFlag = true;
        }
        // 'c' => clear input icon
        if (this.typevar.indexOf('c') > -1) {
            this.hasCFlag = true;
        }
        // 'd' => e.g. "textarea editor" or "datetime"? 
        if (this.typevar.indexOf('d') > -1) {
            this.hasDFlag = true;
        }
        // 'e' => e-mail button
        if (this.typevar.indexOf('e') > -1) {
            this.hasEFlag = true;
        }
        // 'f' => suggest from search
        if (this.typevar.indexOf('f') > -1) {
            this.hasFFlag = true;
        }
        // 'g' => goTo function on a button
        if (this.typevar.indexOf('g') > -1) {
            this.hasGFlag = true;
        }
        // 'h' => text alignment right
        if (this.typevar.indexOf('h') > -1) {
            this.hasHFlag = true;
        }
        // 'k' => barcode scanning
        if (this.typevar.indexOf('k') > -1) {
            this.hasKFlag = true;
        }
        // 'n' => numeric
        const nIndex = this.typevar.indexOf('n');
        if (nIndex > -1) {
            this.hasNFlag = true;
            // Check next char for digits
            const nextCharIndex = nIndex + 1;
            if (nextCharIndex < this.typevar.length) {
                const nextChar = this.typevar.charAt(nextCharIndex);
                const parsed = parseInt(nextChar, 10);
                if (!isNaN(parsed)) {
                    this.numericPrecision = parsed; // e.g. 'n3'
                    // Also check if there's a plus sign after that '3'
                    const plusCharIndex = nIndex + 2;
                    if (plusCharIndex < this.typevar.length) {
                        const maybePlus = this.typevar.charAt(plusCharIndex);
                        if (maybePlus === '+') {
                            this.numericAlwaysPositive = true; // e.g. 'n3+'
                        }
                    }
                } else if (nextChar === '+') {
                    // if 'n+'
                    this.numericAlwaysPositive = true;
                }
            }
        }
        // 'o' => extra line buttons
        if (this.typevar.indexOf('o') > -1) {
            this.hasOFlag = true;
        }
        // 'p' => password
        if (this.typevar.indexOf('p') > -1) {
            this.hasPFlag = true;
        }
        // 'q' => required star behind label
        if (this.typevar.indexOf('q') > -1) {
            this.hasQFlag = true;
        }
        // 'r' => readOnly
        if (this.typevar.indexOf('r') > -1) {
            this.hasRFlag = true;
        }
        // 's' => highlight/ select text on focus
        if (this.typevar.indexOf('s') > -1) {
            this.hasSFlag = true;
        }
        // 't' => telephone
        if (this.typevar.indexOf('t') > -1) {
            this.hasTFlag = true;
        }
        // 'u' => up/down spinner input
        if (this.typevar.indexOf('u') > -1) {
            this.hasUFlag = true;
        }
        // 'z' => clears input after optionFunc completes
        if (this.typevar.indexOf('z') > -1) {
            this.hasZFlag = true;
        }

        if (this.type === 'datetime') {
            // parse dtvar for minuteInterval
            const interval = this.dtvar?.minuteInterval ?? 1;

            this.buildMinuteList(interval);
        }
    }

    public addClass(condition?: string): string {
        let allClass = '';

        switch (condition) {
            case 'select':
                allClass = 'tt-input__form-control tt-input__form-control--select ';
                break;

            case 'inputStart':
                // If 'c' is in typevar, add 'right-inner-addon'
                if (this.hasCFlag) {
                    allClass = 'right-inner-addon';
                }
                break;

            case 'checkbox':
                allClass = 'tt-input__form-control tt-input__form-control--checkbox ';
                break;

            case 'html':
                allClass = '';
                break;

            case 'bubble':
                allClass = 'tt-input__message';
                // If myuser === '1', add a special class
                if (this.myuser === '1') {
                    allClass += ' tt-input__message--current-user';
                }
                break;

            default:
                // Default: 'tt-input__form-control '
                allClass = 'tt-input__form-control ';

                // If 'n' is in typevar but 'u' is not, OR 'h' is in typevar => text right
                if ((this.hasNFlag && !this.hasUFlag) || this.hasHFlag) {
                    allClass += ' input-text-right ';
                }

                // If 'u' is in typevar => center
                else if (this.hasUFlag) {
                    allClass += ' input-text-center ';
                }
                // Otherwise => normal text
                else {
                    allClass += ' input-text ';
                }
                break;
        }

        return allClass;
    }

    public addBtnStyle(index: number): string {
        // default style
        let allClass = 'tt-input__button tt-input__button--success';

        // If 'typevar' contains 'o' and 'index' is valid
        if (index !== undefined && this.hasOFlag) {
            // Ensure model.lines exists, not empty, 
            // and we have a valid element at [index] with a color property
            if (
                this.model.lines &&
                Object.keys(this.model.lines).length > 0 &&
                this.model.lines[index] &&
                this.model.lines[index].color
            ) {
                const color = this.model.lines[index].color;

                if (color === 'default') {
                    allClass = 'input-group-addon btn btn-default btn-lg';
                } else if (color === 'primary') {
                    allClass = 'tt-input__button tt-input__button--primary';
                } else if (color === 'success') {
                    allClass = 'tt-input__button tt-input__button--success';
                } else if (color === 'info') {
                    allClass = 'tt-input__button tt-input__button--info';
                } else if (color === 'warning') {
                    allClass = 'tt-input__button tt-input__button--warning';
                } else if (color === 'danger') {
                    allClass = 'tt-input__button tt-input__button--danger';
                }
            }
        }

        return allClass;
    }

    public addGlyph(specific?: string): string {
        // default
        let allClass = 'fas fa-chevron-right';

        // If 'this.glyphs' is set, override default
        if (this.glyphs != null) {
            allClass = 'glyphicon ' + this.glyphs;
        }

        // If 'specific' is "down"
        if (specific === 'down') {
            allClass = 'glyphicon glyphicon-minus';
        }

        // If 'specific' is "up"
        if (specific === 'up') {
            allClass = 'glyphicon glyphicon-plus';
        }

        // If 'specific' is "remove"
        if (specific === 'remove') {
            allClass = 'glyphicon glyphicon-erase';
        }

        // If 'specific' is "calc"
        if (specific === 'calc') {
            allClass = 'fa fa-calculator';
        }

        // If 'specific' is "barcode"
        if (specific === 'barcode') {
            allClass = 'glyphicon glyphicon-barcode';
        }

        // Check if this.typevar includes 'o'
        if (specific != null && this.hasOFlag) {
            // Ensure we have model.lines
            if (this.model.lines && Object.keys(this.model.lines).length > 0) {
                // We'll interpret 'specific' as an index or key into lines
                const spec: string = specific;

                const lineObj = this.model.lines[specific];
                if (lineObj?.glyph) {
                    // default prefix is 'glyphicon '
                    let prefix = 'glyphicon ';
                    const glyphStr = lineObj.glyph;

                    // Detect which prefix to use if glyph starts with 'fa-', 'fab-', etc.
                    if (glyphStr.startsWith('fa-')) {
                        prefix = 'fa ';
                    } else if (glyphStr.startsWith('fab-')) {
                        prefix = 'fab ';
                    } else if (glyphStr.startsWith('fad-')) {
                        prefix = 'fad ';
                    } else if (glyphStr.startsWith('fal-')) {
                        prefix = 'fal ';
                    } else if (glyphStr.startsWith('far-')) {
                        prefix = 'far ';
                    } else if (glyphStr.startsWith('fas-')) {
                        prefix = 'fas ';
                    }

                    // If we have 'this.spin' and 'glyphicon-search', apply spinner logic
                    if (this.spin && glyphStr.indexOf('glyphicon-search') > -1) {
                        if (this.spin === true) {
                            allClass = 'fa fa-spinner fa-spin';
                        } else {
                            allClass = prefix + glyphStr;
                        }
                    } else {
                        // normal case
                        allClass = prefix + glyphStr;
                    }
                }
            }
        }

        // If 'specific' is "icons" and we have 'this.icons'
        if (specific === 'icons' && this.icons != null) {
            if (this.icons.length > 0) {
                const glyphStr = this.icons;

                if (glyphStr.startsWith('fa-')) {
                    allClass = 'fa ' + glyphStr;
                } else if (glyphStr.startsWith('fab-')) {
                    allClass = 'fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (glyphStr.startsWith('fad-')) {
                    allClass = 'fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (glyphStr.startsWith('fal-')) {
                    allClass = 'fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (glyphStr.startsWith('far-')) {
                    allClass = 'far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else if (glyphStr.startsWith('fas-')) {
                    allClass = 'fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                } else {
                    allClass = 'glyphicon ' + glyphStr;
                }
            }
        }

        return allClass;
    }

    buildMinuteList(interval: number) {
        this.minutesList = [];
        for (let i = 0; i < 60; i += interval) {
            this.minutesList.push(i.toString().padStart(2, '0'));
        }
        // If current minuteValue not in the list, add it:
        if (!this.minutesList.includes(this.minuteValue)) {
            this.minutesList.push(this.minuteValue);
        }
    }

    setupDatetimeDefaults(): void {
        if (this.type !== 'datetime' || !this.field) return;

        // If field is "YYYY-MM-DD HH:mm" => parse
        // e.g. "2022-12-01 14:05"
        const str = String(this.field);
        const datePart = str.substring(0, 10);   // "2022-12-01"
        const timePart = str.substring(11, 16); // "14:05"

        // parse date
        try {
            this.dateValue = new Date(datePart + 'T00:00:00');
        } catch (err) {
            // fallback
        }

        if (timePart.length === 5) {
            this.hourValue = timePart.substring(0, 2);
            this.minuteValue = timePart.substring(3, 5);
        }
    }

    setupCustomBoolean(): void {
        // If type is checkbox and ieTrueValue/ieFalseValue are set
        if ((this.type === 'checkbox' || this.type === 'checkbox_s') && this.ieTrueValue !== undefined && this.ieFalseValue !== undefined) {
            this.useCustomBoolean = true;
        }
    }

    applyNumericFormattingIfNeeded(): void {
        if (!this.hasNFlag) return;
        if (this.type === 'search' && this.hasFFlag) {
            // skip numeric if it's a search with typeahead
            return;
        }
        if (!this.model.field) return;

        if (this.hasNFlag) {
            // format the field
            const stringVal = String(this.model.field);

            // 1) Remove invalid chars
            let replaced = this.numericAlwaysPositive
                ? stringVal.replace(/[^0-9.,]/g, '')
                : stringVal.replace(/[^0-9.,-]/g, '');

            // 2) unify '.' => single decimal
            replaced = replaced.replace(/,/g, '.');
            const parts = replaced.split('.');
            if (parts.length > 2) {
                // merge trailing
                replaced = parts[0] + '.' + parts.slice(1).join('');
            }

            // 3) If decimalCount > 0, ensure we have that many decimals
            if (this.numericPrecision > 0) {
                if (!replaced.includes('.')) {
                    replaced += '.';
                    for (let i = 0; i < this.numericPrecision; i++) replaced += '0';
                } else {
                    // pad or slice
                    const dotIndex = replaced.indexOf('.');
                    const afterDot = replaced.length - (dotIndex + 1);
                    if (afterDot < this.numericPrecision) {
                        // pad
                        for (let i = 0; i < this.numericPrecision - afterDot; i++) replaced += '0';
                    } else if (afterDot > this.numericPrecision) {
                        replaced = replaced.slice(0, dotIndex + 1 + this.numericPrecision);
                    }
                }
            } else {
                // if this.numericPrecision is 0, remove decimals
                if (replaced.includes('.')) {
                    replaced = replaced.substring(0, replaced.indexOf('.'));
                }
            }

            // remove leading zeros if not decimal
            if (this.numericAlwaysPositive && replaced.length > 1) {
                while (replaced[0] === '0' && replaced[1] !== '.') {
                    replaced = replaced.substring(1);
                }
            }

            this.model.field = replaced;
        }
    }

    // ========== EVENT HANDLERS (MIRRORING THE OLD ONES) ==================
    onFocus(event: FocusEvent): void {
        // If we have 's' => select text
        if (this.hasSFlag) {
            const target = event.target as HTMLInputElement | HTMLTextAreaElement;
            // select all text
            setTimeout(() => {
                target.setSelectionRange(0, target.value.length);
            }, 1);
        }

        // If 'a' => keepFocus?
        if (this.hasAFlag && this.hasAAFlag) {
            // always keep focus?
            // This is tricky in Angular. We can re-focus in blur if needed
            // TODO
        }
        this.model.focusKeeper = true;
    }

    onBlur(event: FocusEvent): void {
        // If 'n' => apply numeric formatting
        if (this.hasNFlag) {
            this.applyNumericFormattingIfNeeded();
            this.emitFieldChange();
        }

        // If 'c' => losing focus => hide clear?
        if (this.hasCFlag) {
            setTimeout(() => {
                this.model.focusKeeper = false;
            }, 200);
        }
    }

    onInputChange(event: Event): void {
        const inputEl = event.target as HTMLInputElement | HTMLTextAreaElement;
        this.model.field = inputEl.value;
        this.emitFieldChange();
    }

    onCheckboxChangeLegacy(event: Event): void {
        // For the large checkbox or small checkbox
        const inputEl = event.target as HTMLInputElement;
        this.model.field = inputEl.checked ? this.ieTrueValue : this.ieFalseValue;
        this.emitFieldChange();
    }

    onSelectChange(value: any): void {
        this.model.field = value;
        this.emitFieldChange();
    }

    onSearchChange(event: Event): void {
        const inputEl = event.target as HTMLInputElement;
        this.model.field = inputEl.value;
        this.emitFieldChange();
    }

    // For type='datetime'
    onDatetimeDateChange(dateString: string): void {
        // parse date
        const d = new Date(dateString + 'T00:00:00');
        if (!isNaN(d.getTime())) {
            this.dateValue = d;
        }
        this.rebuildDatetimeField();
    }

    onDatetimeHourChange(val: string): void {
        this.hourValue = val;
        this.rebuildDatetimeField();
    }

    onDatetimeMinuteChange(val: string): void {
        this.minuteValue = val;
        this.rebuildDatetimeField();
    }

    rebuildDatetimeField(): void {
        // e.g. '2023-02-10' => local string
        if (this.dateValue) {
            const year = this.dateValue.getFullYear();
            const month = (this.dateValue.getMonth() + 1).toString().padStart(2, '0');
            const day = this.dateValue.getDate().toString().padStart(2, '0');

            // "YYYY-MM-DD"
            const datePart = `${year}-${month}-${day}`;
            this.model.field = `${datePart} ${this.hourValue}:${this.minuteValue}`;
        } else {
            this.model.field = '';
        }
        this.emitFieldChange();
    }

    onSignatureClick(): void {
        if (!this.hasRFlag) {
            // open signature pad
            this.openSignaturePad();
        }
    }

    public onBarcodeClick(): void {
        // Start polling for a typed-in value
        this.waitForBarcodeValue();

        // Trigger the external barcode scan by changing window.location
        window.location.href = `readbarcode://${this.model.mId}`;
    }

    /**
     * Poll the input field until it has a non-empty value. 
     * Then call either optionfunc or goChange, mirroring old AngularJS logic.
     */
    private waitForBarcodeValue(): void {
        // 1. Find the input element by ID
        const inputEl = document.getElementById(this.model.mId) as HTMLInputElement | null;
        if (!inputEl) {
            // If not found, just schedule another check
            setTimeout(() => this.waitForBarcodeValue(), 250);
            return;
        }

        // 2. Check if the input has a non-empty string
        const val = inputEl.value?.trim() ?? '';
        if (val.length > 0) {
            // We found the scanned data
            this.field = val;

            // If user provided an optionfunc, call it after a short delay
            if (typeof this.optionfunc === 'function') {
                setTimeout(() => {
                    if (this.optionfunc) {
                        this.optionfunc(); // or this.optionfunc({ value: val }) if you need parameters
                    }
                }, 100);
            }
            // otherwise call goChange (which might call changefunc)
            else {
                setTimeout(() => {
                    this.emitFieldChange();
                }, 100);
            }

            // Stop polling once we get a value
            return;
        }

        // 3. If empty, wait another 250ms and check again
        setTimeout(() => this.waitForBarcodeValue(), 250);
    }

    onCalcClick(): void {
        this.openCalculator();
    }

    emitFieldChange(): void {
        // mirror $scope.model.field => $scope.field
        this.field = this.model.field;

        this.fieldChange.emit(this.field);

        if (this.changefunc) {
            this.changefunc();
        }
    }

    goToButton(index?: number): void {
        if (!this.optionfunc) return;

        if (index !== undefined && this.btnList && this.btnList.length > index) {
            const line = this.btnList[index];
            if (line && line.item_func) {
                this.optionfunc({ item_func: line.item_func });
            } else {
                this.optionfunc({ item_func: index });
            }
        } else {
            this.optionfunc();
        }

        // if typevar includes 'z', clear after optionfunc
        if (this.hasZFlag) {
            this.clearInput();
        }
    }

    clearInput(): void {
        this.model.field = '';
        this.emitFieldChange();
        // re-focus if needed
        const inputEl = this.elRef.nativeElement.querySelector('#' + this.model.mId) as HTMLElement;
        if (inputEl) {
            setTimeout(() => inputEl.focus(), 0);
        }
    }

    ngOnDestroy(): void {
        // Always unsubscribe to avoid memory leaks
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }
}
