(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('orderPurchMenu', {
        templateUrl: 'views/components/views/orderPurchMenu/orderPurchMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderPurchMenuService', function ($stateParams, stateService, orderPurchMenuService) {
            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;
            let setting = {}
            
            vm.model = {
    			itemsListPurchaseHeads: []
            };

            async function loadSetting() {
                setting = (await orderPurchMenuService.getSetting({ order_internal_no: orderInternalNo }))[0];

                loadPurchaseHeads()
            }

            async function loadPurchaseHeads() {
                vm.model.itemsListPurchaseHeads = (await orderPurchMenuService.listPurchaseHeads(setting));
            }

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'orderpurch':
                        go({ order_internal_no: setting.order_internal_no });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
