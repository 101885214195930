import { gridStateToColumnLayout, gridStateToUserSettings } from "../../../../../app/core/components/grid/grid.types";

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttGrid', {
        templateUrl: 'views/components/directives/ttGrid/ttGrid.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<'
        },
        controller: ['$window', '$document', '$element', '$uibModal', '$q', '$ihttp', '$timeout', 'base64', 'ttGridService', 'utilityService', 'translateService', 'layoutService', 'userService', 'modalService', 'stateService', 'eventService', 'modalProgressFactory', 'modalLayoutsFactory', 'modalPopupTableFactory', 'modalDynamicViewFactory', 'ieScreenBlockService', 'pdfService', 'popupService', function ($window, $document, $element, $uibModal, $q, $ihttp, $timeout, base64, ttGridService, us, translateService, layoutService, userService, modalService, stateService, eventService, modalProgressFactory, modalLayoutsFactory, modalPopupTableFactory, modalDynamicViewFactory, ieScreenBlockService, pdfService, popupService) {
            // ###############################
            // #region variables
            // ###############################
            var vm = this;
            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, async function (info) {
                try {
                    if (angular.isUndefined(info)) return;

                    if (vm.useAgGrid !== info.useAgGrid) {
                        vm.useAgGrid = info.useAgGrid
                        await vm.toggleGrids(info.useAgGrid);
                    }

                    //vm.fontSize = info.fontSizes;
                    vm.fontSizeS = info.fontSizes.textSizeS;
                    vm.fontSize = info.fontSizes.textSize;
                    vm.fontSizeXL = info.fontSizes.textSizeXL;
                    vm.thumbnailSize = (parseInt(info.fontSizes.thumbnail) - 6) + 'px';

                    vm.breadcrumbsShowing = info?.showBreadcrumbs === true;
                    setTimeout(resizeFixed, 200);
                    setTimeout(scrollFixed, 200);
                    //vm.fontSizeXL = info.fontSizes.textSize;
                } catch (error) {
                    console.log(error);
                }
            });

            let defaultDataTaskKeyno = 2219;
            let columnInfo = {};
            let dataRaw = [];

            vm.id = uuid();
            vm.rebind = false;
            vm.fontSizeS = null;
            vm.fontSize = null;
            vm.fontSizeXL = null;
            vm.thumbnailSize = null;
            vm.breadcrumbsShowing = true;
            vm.grid = undefined;
            vm.response = null;
            vm.lastKeydown = null;
            vm.lastClickedRow = null;

            let delayChanges = null;
            let delayedChanges = {};
            let nextEdits = null;

            var gridConfig = {
                hasActiveLayout: false,
                hasBoolean: false,
                hasCustomToolbarBtns: false,
                hasCustomToolbarTgls: false,
                hasEditable: false,
                hasGlyphs: false,
                hasPath: false,
                hasThumb: false,
                hasSpecialFunc: false,
                hasStyleFunc: false,
                canHideSpecialFunc: false,
                toolbar: [],
                activeLayout: null,
                toggle: { headers: false },
                selectedRows: { index: [], data: [] },
                columnFormatType: [],
            };

            var userSettings = {
                page: 1,
                pageSize: 25,
                columns: {},
                filter: {},
                filterable: undefined,
                sort: []
            };

            var changes = {};

            var words = [
                'grid_pg_display', 'grid_pg_empty', 'grid_pg_page', 'grid_pg_of', 'grid_pg_itemsperpage', 'grid_pg_first', 'grid_pg_last',
                'grid_pg_next', 'grid_pg_previous', 'grid_pg_refresh', 'grid_pg_morepages', 'grid_select_col', 'grid_functions', 'grid_glyphs', 'grid_thumb'
            ];

            var wordlang = {
                ttgrid_modal_remove_title: '',
                ttgrid_modal_remove_message: '',
                ttgrid_modal_remove_ok: '',
                ttgrid_modal_remove_cancel: '',
                ttgrid_modal_add_title: '',
                ttgrid_modal_add_message: '',
                ttgrid_modal_add_ok: '',
                ttgrid_modal_add_cancel: '',
                ttgrid_modal_save_title: '',
                ttgrid_modal_save_message: '',
                ttgrid_modal_save_ok: '',
                ttgrid_modal_save_cancel: '',
                ttgrid_modal_save_error_titleprefix: '',
                ttgrid_modal_save_error_btn: '',
                ttgrid_modal_layouts_placeholder: '',
                ttgrid_modal_layouts_placeholder_error: '',
                ttgrid_modal_layouts_placeholder_no_layout: '',
                ttgrid_save_progress_label: '',
                ttgrid_save_default_error_msg: ''
            };

            // ###############################
            // #endregion variables
            // ###############################

            // ###############################
            // #region translation
            // ###############################

            var addToTranslations = function (translations, wordsList) {
                if (angular.isArray(translations) !== true) {
                    translations = [];
                }

                if (angular.isArray(wordsList) !== true) {
                    wordsList = [];
                }

                angular.forEach(wordsList, function (word) {
                    translations.push(word);
                });

                return translations;
            };

            var splitTranslations = function (translations) {
                var userTranslations = angular.copy(translations);

                // BJS 20220327
                angular.forEach(wordlang, function (_, key) {
                    if (angular.isDefined(translations[key])) {
                        wordlang[key] = translations[key];

                        delete translations[key];
                    }
                });

                angular.forEach(words, function (word) {
                    delete userTranslations[word];
                });

                angular.forEach(userTranslations, function (_, key) {
                    delete translations[key];
                });

                return userTranslations;
            };

            // ###############################
            // #endregion translation
            // ###############################

            // ###############################
            // #region datatask
            // ###############################

            // ###############################
            // #endregion datatask
            // ###############################

            // ###############################
            // #region user settings
            // ###############################

            var canRemember = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask.rememberId) || angular.isString(vm.ttOptions.dataTask.rememberId) !== true) return false;

                return vm.ttOptions.dataTask.rememberId.length > 0;
            };

            var getRemember = function (remembering, response) {
                switch (true) {
                    case (remembering !== true):
                    case (angular.isArray(response) !== true):
                    case (response.length < 3):
                    case (angular.isArray(response[2]) !== true):
                    case (response[2].length < 1):
                    case (angular.isUndefined(response[2][0].variablevalue)):
                    case (angular.isString(response[2][0].variablevalue) !== true):
                    case (response[2][0].variablevalue.trim().length < 1):
                        return undefined;
                    default:
                        return angular.fromJson(response[2][0].variablevalue);

                }
            };

            var timerRemember = null;

            var saveUserSettings = function (isBase) {
                if (canRemember() !== true) return;

                if (angular.isDefined(timerRemember)) {
                    $timeout.cancel(timerRemember);
                }

                timerRemember = $timeout(function () {
                    ttGridService.remember(null, vm.ttOptions.dataTask.rememberId, userSettings, isBase);
                }, 300);
            };

            var updateUserSettings = function (remembering, response) {
                var remember = getRemember(remembering, response);

                if (angular.isUndefined(remember)) return;

                if (!!remember && Object.hasOwn(remember, 'agGrid') && remember.agGrid === true) {
                    try {
                        remember = gridStateToUserSettings(remember, vm.ttOptions?.config?.serverSideHandling, true);
                    } catch (error) {
                        console.log(error);
                    }
                }

                if (angular.isDefined(remember.pageSize)) {
                    userSettings.pageSize = remember.pageSize;
                }

                if (angular.isDefined(remember.page)) {
                    userSettings.page = parseInt(remember.page);
                }

                if (angular.isDefined(remember.columns) && (angular.isObject(remember.columns) || angular.isArray(remember.columns))) {
                    if (angular.isArray(remember.columns)) {
                        angular.forEach(remember.columns, function (col) {
                            if (angular.isDefined(col.field)) {
                                if (angular.isUndefined(userSettings.columns[col.field])) userSettings.columns[col.field] = {};
                                if (angular.isDefined(userSettings.columns[col.field].width)) userSettings.columns[col.field].width = col.width;
                                if (angular.isDefined(userSettings.columns[col.field].hidden)) userSettings.columns[col.field].hidden = col.hidden;
                            }
                        });
                    } else {
                        userSettings.columns = remember.columns;
                    }
                }

                if (angular.isDefined(remember.filterable) && angular.isObject(remember.filterable)) {
                    userSettings.filterable = remember.filterable;
                }

                if (angular.isDefined(remember.filter) && angular.isObject(remember.filter)) {
                    if (angular.isArray(remember.filter.filters) && remember.filter.filters.length > 0 && angular.isDefined(remember.filter.filters[0])) {
                        for (var f = 0; f < remember.filter.filters.length; f++) {
                            if (angular.isUndefined(remember.filter.filters[f].field)) {
                                remember.filter.filters.splice(f, 1);
                                f--;
                            }
                        }
                    }

                    if (angular.isUndefined(remember.filter.filters) || (angular.isArray(remember.filter.filters) && remember.filter.filters.length === 0)) {
                        userSettings.filter = {};
                    } else {
                        userSettings.filter = remember.filter;
                    }
                }

                if (angular.isDefined(remember.sort) && angular.isArray(remember.sort)) {
                    userSettings.sort = remember.sort;
                }
            };

            var getSortUserSettings = function () {
                switch (true) {
                    case (angular.isDefined(vm.ttOptions.kendo.sortable) && vm.ttOptions.kendo.sortable === false):
                    case (angular.isUndefined(userSettings.sort)):
                    case (angular.isArray(userSettings.sort) !== true):
                    case (userSettings.sort.length < 1):
                    case (angular.isUndefined(userSettings.sort[0].field)):
                        return undefined;
                    default:
                        return userSettings.sort;

                }
            };

            var getFilterUserSettings = function (schema) {
                switch (true) {
                    case (angular.isUndefined(userSettings.filter)):
                    case (angular.isObject(userSettings.filter) !== true):
                        return undefined;
                    default:
                        if (angular.isDefined(schema) && angular.isDefined(userSettings.filter.filters)) {
                            angular.forEach(userSettings.filter.filters, function (fltr) {
                                angular.forEach(schema, function (col) {
                                    if (col.colname === fltr.field && (col.coltype === 'date' || col.coltype === 'datetime' || col.coltype === 'timestamp')) {
                                        fltr.value = new Date(fltr.value);
                                        return;
                                    }
                                });
                            });
                        }

                        // JLR 20241031 - prevent columns which only has set filter set from ag-grid to be included in the list of filters.
                        if (userSettings.filter.filters) {
                            userSettings.filter.filters = userSettings.filter.filters.filter((filter) => !(Object.keys(filter).length === 2 && Object.keys(filter).includes('set')));
                        }

                        return userSettings.filter;
                }
            };

            var updateSortUserSettings = function (newSort) {
                switch (true) {
                    case angular.isUndefined(newSort):
                    case angular.isArray(newSort) !== true:
                        //case newSort.length < 1:
                        return;
                }

                if (angular.isUndefined(userSettings.sort)) {
                    userSettings.sort = [];
                }

                userSettings.sort = newSort;
                saveUserSettings();
            };

            var updateFilterUserSettings = function (newFilter) {
                switch (true) {
                    case angular.isUndefined(newFilter):
                    case angular.isObject(newFilter) !== true:
                        return;
                }

                if (angular.isUndefined(userSettings.filter)) {
                    userSettings.filter = {};
                }

                if (angular.isDefined(vm.response[0]) && angular.isDefined(userSettings.filter.filters)) {
                    angular.forEach(userSettings.filter.filters, function (fltr) {
                        angular.forEach(vm.response[0], function (col) {
                            /*if (col.colname === fltr.field && (col.coltype === 'date' || col.coltype === 'datetime' || col.coltype === 'timestamp')) {
                                var tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                                fltr.value = (new Date(new Date(fltr.value) - tzo)).toISOString().replace('T', ' ').slice(0, -1);
                                
                                return;
                            }*/
                        });
                    });
                }

                userSettings.filter = newFilter;

                saveUserSettings();
            };

            // ###############################
            // #endregion user settings
            // ###############################

            // ###############################
            // #region toolbar
            // ###############################

            var toolbarBtnDisability = function () {
                if (gridConfig.hasCustomToolbarBtns) {
                    var tb = $('.k-grid-toolbar');

                    angular.forEach(vm.ttOptions.config.toolbar.buttons, async function (btn) {
                        if (angular.isFunction(btn.disabled)) {
                            var tbBtn = tb.find("#tt-grid-tbbtn-" + btn.name);

                            if (angular.isDefined(tbBtn[0])) {
                                var isDisabled = btn.disabled();

                                if (isDisabled instanceof Promise) {
                                    isDisabled = await isDisabled;
                                }

                                switch (isDisabled) {
                                    case 'hidden':
                                        tbBtn.toggleClass('im-grid-col-btn-hidden', true);
                                        tbBtn.toggleClass('k-state-disabled', false);
                                        break;
                                    case true:
                                        tbBtn.toggleClass('im-grid-col-btn-hidden', false);
                                        tbBtn.toggleClass('k-state-disabled', true);
                                        break;
                                    case false:
                                        tbBtn.toggleClass('im-grid-col-btn-hidden', false);
                                        tbBtn.toggleClass('k-state-disabled', false);
                                        break;
                                }
                            }
                        }
                    });
                }
            };

            var showOnToolbar = function (configId, dataTaskId) {
                if (angular.isDefined(vm.ttOptions.config.toolbar[configId]) && vm.ttOptions.config.toolbar[configId] === true) {
                    return true;
                }

                if (angular.isUndefined(dataTaskId))
                    return false;

                if (angular.isUndefined(vm.ttOptions.dataTask))
                    return false;
                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId]))
                    return false;
                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId].button))
                    return false;
                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId].button.position))
                    return false;

                return vm.ttOptions.dataTask[dataTaskId].button.position === 'toolbar';
            };

            function generateGridToolbar(translations) {
                if (angular.isUndefined(vm.ttOptions.config.toolbar) || vm.ttOptions.config.toolbar === false) return;

                var btns = [];
                gridConfig.toolbar = [];

                if (angular.isDefined(vm.ttOptions.config.toolbar.excelExport) && vm.ttOptions.config.toolbar.excelExport === true) {
                    gridConfig.toolbar.push({ name: 'excel', text: '', template: '<a class="k-button k-button-icontext k-grid-excel im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-excel im-no-mar"></span></a>' });
                }
                if (angular.isDefined(vm.ttOptions.config.toolbar.pdfExport) && vm.ttOptions.config.toolbar.pdfExport === true) {
                    gridConfig.toolbar.push({ name: 'pdf', text: '', template: '<a class="k-button k-button-icontext k-grid-pdf im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-pdf im-no-mar"></span></a>' });
                }
                if (angular.isDefined(vm.ttOptions.config.toolbar.filter) && vm.ttOptions.config.toolbar.filter === true) {
                    btns.push('filter', 'filterClear');
                }

                if (showOnToolbar('columnVisibility')) {
                    btns.push('colShowHide');
                }

                if (showOnToolbar('headers')) {
                    btns.push('toggleHeaders');
                }

                if (showOnToolbar('edit')) {
                    btns.push('colEdit');
                }

                if (showOnToolbar('lock')) {
                    btns.push('colLock');
                }

                if (showOnToolbar('refresh')) {
                    btns.push('refresh');
                }

                if (showOnToolbar('read')) {
                    btns.push('read');
                }

                if (showOnToolbar('rebind')) {
                    btns.push('rebind');
                }

                if (showOnToolbar('add', 'addRow')) {
                    btns.push('addBefore', 'addAfter');
                }

                if (showOnToolbar('addSimple', 'addRow')) {
                    btns.push('addBefore');
                }

                if (showOnToolbar('remove', 'removeRow')) {
                    btns.push('remove');
                }

                if (showOnToolbar('save', 'saveData')) {
                    btns.push('save');
                }

                if (showOnToolbar('wrapping')) {
                    btns.push('wrapping');
                }

                if (showOnToolbar('layouts')) {
                    btns.push('layouts');
                }

                if (showOnToolbar('print')) {
                    btns.push('print');
                }

                angular.forEach(btns, function (name) {
                    var btn = makeToolbarBtnTemplate(name, '');
                    if (btn !== null) gridConfig.toolbar.push(btn);
                });

                if (angular.isDefined(vm.ttOptions.config.toolbar.buttons) && vm.ttOptions.config.toolbar.buttons.length > 0) {
                    angular.forEach(vm.ttOptions.config.toolbar.buttons, function (button) {
                        gridConfig.hasCustomToolbarBtns = true;
                        var btnTxt = angular.isUndefined(button.translate) || button.translate !== false ? (angular.isDefined(button.text) && button.text !== '' ? (angular.isDefined(translations[button.text]) ? translations[button.text] : button.text) : '') : angular.isDefined(button.text) && button.text !== '' ? button.text : '';
                        var btn = makeToolbarBtnTemplate(button.name, btnTxt, 'vm.customGridToolbarBtnClick(\'' + button.name + '\')', button.icon, button.cssClass);
                        if (btn !== null) gridConfig.toolbar.push(btn);
                    });
                }

                if (angular.isDefined(vm.ttOptions.config.toolbar.toggles) && vm.ttOptions.config.toolbar.toggles.length > 0) {
                    angular.forEach(vm.ttOptions.config.toolbar.toggles, function (toggle) {
                        gridConfig.hasCustomToolbarTgls = true;
                        //let tglTxt = angular.isUndefined(toggle.translate) || toggle.translate !== false ? (angular.isDefined(toggle.text) && toggle.text !== '' ? (angular.isDefined(translations[toggle.text]) ? translations[toggle.text] : toggle.text) : '') : angular.isDefined(toggle.text) ? toggle.text : '';
                        //let tgl = makeToolbarTglTemplate(toggle.name, tglTxt, 'vm.customGridToolbarTglClick(\'' + toggle.name + '\')', toggle.icon);
                        let tgl = makeToolbarTglTemplate(toggle, translations);
                        if (tgl !== null) gridConfig.toolbar.push(tgl);
                    });
                }
            };

            vm.showToolbarBtn = function (name) {
                if (name === 'filter') {
                    return true;
                } else if (name === 'filterClear') {
                    if (angular.isUndefined(vm.grid)) return false;
                    var options = vm.grid.getOptions();
                    if (angular.isDefined(options) && angular.isDefined(options.dataSource) && angular.isDefined(options.dataSource.filter) && angular.isDefined(options.dataSource.filter.filters) && options.dataSource.filter.filters.length > 0) {
                        return true;
                    } else return false;
                } else {
                    return true;
                }
            };

            vm.showToolbarTgl = function (name) {
                return true;
            };

            vm.gridToolbarBtnClick = function (name) {
                var currentOptions = vm.grid.getOptions();
                var newOptions = {};

                if (name === 'filter') {
                    if (angular.isDefined(currentOptions.filterable) && angular.isDefined(currentOptions.filterable.mode)) {
                        clearFilter();
                        //vm.grid.dataSource.filter({});
                        //updateFilterUserSettings({});
                        userSettings.filterable = undefined;
                        //options.dataSource.filter = undefined;
                        newOptions.filterable = false;
                        newOptions.excel = currentOptions.excel;
                        newOptions.excel.filterable = false;
                        newOptions.pdf = currentOptions.pdf;
                        newOptions.pdf.filterable = false;
                    } else {
                        userSettings.filterable = { mode: "row" };
                        newOptions.filterable = { mode: "row" };
                        newOptions.excel = currentOptions.excel;
                        newOptions.excel.filterable = true;
                        newOptions.pdf = currentOptions.pdf;
                        newOptions.pdf.filterable = true;
                    }

                    vm.grid.setOptions(newOptions);
                    saveUserSettings();
                } else if (name === 'filterClear') {
                    clearFilter();
                    //vm.grid.dataSource.filter({});
                    //updateFilterUserSettings({});
                    //options.dataSource.filter = undefined;
                    //$scope.model.grid.setOptions(options);
                } else if (name === 'columns') {
                    goShowHideColumns();
                } else if (name === 'colShowHide') {
                    goShowHideColumns('colShowHide');
                    //$scope.model.grid.dataSource.filter({});
                    //options.dataSource.filter = undefined;
                    //$scope.model.grid.setOptions(options);
                } else if (name === 'colEdit') {
                    goShowHideColumns('colEdit');
                } else if (name === 'colLock') {
                    goShowHideColumns('colLock');
                } else if (name === 'toggleHeaders') {
                    newOptions.columns = currentOptions.columns;

                    if (gridConfig.toggle.headers) {
                        angular.forEach(newOptions.columns, function (col) {
                            if (col.field.indexOf('grid_') > -1) {
                                col.title = angular.isDefined(vm.response[1][col.field]) ? vm.response[1][col.field] : ' ';
                            } else {
                                for (var s = 0; s < vm.response[0].length; s++) {
                                    if (vm.response[0][s].colname === col.field) {
                                        col.title = angular.isDefined(vm.response[0][s].title) ? vm.response[0][s].title : vm.response[0][s].colname;

                                        if (col.headerTemplate) {
                                            col.headerTemplate = col.headerTemplate.slice(0, -(col.field.length)) + col.title;
                                        }
                                        break;
                                    }
                                }
                            }
                        });
                    } else {
                        angular.forEach(newOptions.columns, function (col) {
                            if (col.headerTemplate) {
                                col.headerTemplate = col.headerTemplate.slice(0, -(col.title.length)) + col.field;
                            }

                            col.title = col.field;
                        });
                    }

                    vm.grid.setOptions(newOptions);

                    gridConfig.toggle.headers = !gridConfig.toggle.headers;
                } else if (name === 'addBefore') {
                    gridAddRowBefore();
                } else if (name === 'addAfter') {
                    gridAddRowAfter();
                } else if (name === 'remove') {
                    gridRemoveRows();
                } else if (name === 'save') {
                    saveAllChanges();
                } else if (name === 'refresh') {
                    vm.grid.refresh();
                } else if (name === 'read') {
                    readGridData();
                } else if (name === 'rebind') {
                    rebindGrid();
                } else if (name === 'wrapping') {
                    angular.isDefined(vm.ttOptions.config.css.textWrapping) ? vm.ttOptions.config.css.textWrapping = !vm.ttOptions.config.css.textWrapping : vm.ttOptions.config.css.textWrapping = true;
                    var r = document.querySelector(':root');
                    if (vm.ttOptions.config.css.textWrapping === true) {
                        r.style.setProperty('--im-k-nowrap', 'break-spaces');
                    } else {
                        r.style.setProperty('--im-k-nowrap', 'nowrap');
                    }
                } else if (name === 'layouts') {
                    ttGridService.loadLayouts(null, { p2_datatask_keyno: getLoadDataMethod() }).then(function (data) {
                        //var currentLayout = angular.toJson(userSettings.columns);
                        //var currentLayout = angular.toJson(userSettings);
                        var currentLayoutData = { tt_grid_filterable: (angular.isDefined(currentOptions.filterable) && angular.isDefined(currentOptions.filterable.mode)) ? true : false };

                        angular.forEach(vm.grid.columns, function (col, i) {
                            currentLayoutData[col.field] = {
                                hidden: false,
                                width: null,
                                order: i,
                                filter: null
                            };
                        });

                        angular.forEach(userSettings.columns, function (value, key) {
                            if (angular.isDefined(currentLayoutData[key])) {
                                if (angular.isDefined(value.hidden)) currentLayoutData[key].hidden = value.hidden;
                                if (angular.isDefined(value.width)) currentLayoutData[key].width = value.width;
                                //if (angular.isDefined(value.order)) currentLayoutData[key].order = value.order;
                            }
                        });

                        angular.forEach(userSettings.filter.filters, function (filter) {
                            if (angular.isDefined(currentLayoutData[filter.field])) {
                                currentLayoutData[filter.field].filter = { operator: filter.operator, value: filter.value };
                            }
                        });

                        currentLayoutData = angular.toJson(currentLayoutData);

                        angular.forEach(data, function (item) {
                            if (angular.isDefined(item.layout_schema)) {
                                item.layout_schema = decodeURIComponent(escape(atob(item.layout_schema)));
                                item.hasUpdates = false;
                                item.isActive = false;
                                item.isCurrent = item.layout_schema === currentLayoutData ? true : false;
                                item.willGetDeleted = false;
                                if (gridConfig.activeLayout !== null && gridConfig.activeLayout.gridlayout_keyno === item.gridlayout_keyno) {
                                    item.isActive = true;
                                } else if (gridConfig.activeLayout === null && item.layout_schema === currentLayoutData) {
                                    item.isActive = true;
                                    gridConfig.activeLayout = item;
                                }
                            }
                        });

                        var gridLayouts = modalLayoutsFactory.$create({
                            datatask_keyno: getLoadDataMethod(),
                            placeholder: wordlang.ttgrid_modal_layouts_placeholder,
                            placeholderError: wordlang.ttgrid_modal_layouts_placeholder_error,
                            placeholderNoLayout: wordlang.ttgrid_modal_layouts_placeholder_no_layout,
                            //currentLayout: currentLayout,
                            currentLayoutData: currentLayoutData,
                            activeLayout: gridConfig.activeLayout,
                            list: data.length > 0 ? data : []
                        });

                        gridLayouts.show().then(function (value) {
                            var layoutsList = [];

                            //gridConfig.activeLayout = value.activeLayout;

                            angular.forEach(value.list, function (item) {
                                if (item.willGetDeleted) {
                                    if (item.gridlayout_keyno !== '') {
                                        layoutsList.push({
                                            gridlayout_keyno: item.gridlayout_keyno,
                                            function: 'DELETE'
                                        });

                                        if (value.activeLayout !== null && value.activeLayout.gridlayout_keyno === item.gridlayout_keyno) gridConfig.activeLayout = null;
                                    }
                                } else {
                                    if (item.gridlayout_keyno === '') {
                                        layoutsList.push({
                                            gridlayout_keyno: '',
                                            p2_datatask_keyno: item.p2_datatask_keyno,
                                            gridlayout_name: item.gridlayout_name,
                                            layout_schema: item.layout_schema,
                                            orderby: item.orderby || 0,
                                            function: 'NEW'
                                        });

                                        gridConfig.activeLayout = null;
                                    } else {
                                        if (item.isActive) {
                                            if (gridConfig.activeLayout === null || gridConfig.activeLayout.gridlayout_keyno !== item.gridlayout_keyno) {
                                                var layoutSchema = angular.fromJson(item.layout_schema);
                                                var newFilters = [];

                                                try {
                                                    if (!!layoutSchema && Object.hasOwn(layoutSchema, 'agGrid') && layoutSchema.agGrid === true) {
                                                        layoutSchema = gridStateToColumnLayout(layoutSchema);
                                                    }
                                                } catch (error) {
                                                    console.log(error);
                                                }

                                                //var isFilterable = 
                                                angular.forEach(layoutSchema, function (schema, key) {
                                                    if (key === 'tt_grid_filterable') return;
                                                    if (schema.hidden === false) {
                                                        vm.grid.showColumn(key);
                                                    } else {
                                                        vm.grid.hideColumn(key);
                                                    }

                                                    if (schema.width !== null) {
                                                        if (angular.isDefined(userSettings.columns[key])) {
                                                            userSettings.columns[key].width = schema.width;
                                                        } else {
                                                            userSettings.columns[key] = {
                                                                width: schema.width
                                                            };
                                                        }

                                                        for (var w = 0; w < vm.grid.columns.length; w++) {
                                                            if (vm.grid.columns[w].field === key) {
                                                                vm.grid.resizeColumn(vm.grid.columns[w], schema.width);
                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if (angular.isDefined(schema.order)) {
                                                        if (angular.isDefined(userSettings.columns[key])) {
                                                            userSettings.columns[key].order = schema.order;
                                                        } else {
                                                            userSettings.columns[key] = {
                                                                order: schema.order
                                                            };
                                                        }

                                                        for (var o = 0; o < vm.grid.columns.length; o++) {
                                                            if (vm.grid.columns[o].field === key) {
                                                                try {
                                                                    vm.grid.reorderColumn(schema.order, vm.grid.columns[o]);
                                                                } catch (_) { }
                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if (schema.filter !== null) {
                                                        var newFilter = schema.filter;
                                                        newFilter.field = key;
                                                        newFilters.push(newFilter);
                                                    }
                                                });

                                                if (newFilters.length > 0) {
                                                    if (angular.isDefined(layoutSchema.tt_grid_filterable) && layoutSchema.tt_grid_filterable === true) {
                                                        userSettings.filterable = { mode: "row" };
                                                        newOptions.filterable = { mode: "row" };
                                                        newOptions.excel = currentOptions.excel;
                                                        newOptions.excel.filterable = true;
                                                        newOptions.pdf = currentOptions.pdf;
                                                        newOptions.pdf.filterable = true;
                                                        vm.grid.setOptions(newOptions);
                                                    }
                                                    vm.grid.dataSource.filter(newFilters);
                                                } else {
                                                    if (angular.isDefined(layoutSchema.tt_grid_filterable) && layoutSchema.tt_grid_filterable === false) {
                                                        updateFilterUserSettings({});
                                                        userSettings.filterable = undefined;
                                                        newOptions.filterable = false;
                                                        newOptions.excel = currentOptions.excel;
                                                        newOptions.excel.filterable = false;
                                                        newOptions.pdf = currentOptions.pdf;
                                                        newOptions.pdf.filterable = false;
                                                        vm.grid.setOptions(newOptions);
                                                    }
                                                    vm.grid.dataSource.filter({});
                                                }

                                                //if (angular.isObject(layoutSchema.filterable) && layoutSchema.filterable.mode === 'row') {
                                                //    if (angular.isObject(layoutSchema.filter) && angular.isArray(layoutSchema.filter.filters)) {
                                                //        vm.grid.dataSource.filter(layoutSchema.filter.filters);
                                                //    }
                                                //}

                                                saveUserSettings();
                                            }

                                            if (gridConfig.activeLayout !== null && gridConfig.activeLayout.gridlayout_keyno === item.gridlayout_keyno && item.hasUpdates) {
                                                item.hasUpdates = false;
                                                layoutsList.push({
                                                    gridlayout_keyno: item.gridlayout_keyno,
                                                    gridlayout_name: item.gridlayout_name,
                                                    layout_schema: item.layout_schema,
                                                    orderby: item.orderby || 0,
                                                    function: 'UPDATE'
                                                });
                                            }

                                            gridConfig.activeLayout = item;
                                        }
                                    }
                                }
                            });

                            angular.forEach(layoutsList, function (layout) {
                                ttGridService.saveLayouts(null, layout);
                            });
                        });
                    });
                } else if (name === 'print') {
                    openPrintModal();
                }
            };

            vm.customGridToolbarBtnClick = function (name) {
                for (var f = 0; f < vm.ttOptions.config.toolbar.buttons.length; f++) {
                    if (vm.ttOptions.config.toolbar.buttons[f].name === name) {
                        if (name.startsWith('§print')) {
                            //console.log('§print click');
                            //console.dir(vm.ttOptions.config.toolbar.buttons[f]);
                            //let resolve = {
                            //    parameters: function () {
                            //        return {
                            //            dataItem: {}
                            //        };
                            //    }
                            //};

                            //dbModal('ttGridPrintModal', resolve, '');
                            openPrintModal(vm.ttOptions.config.toolbar.buttons[f]);
                        } else if (angular.isFunction(vm.ttOptions.config.toolbar.buttons[f].func)) {
                            vm.ttOptions.config.toolbar.buttons[f].func(vm.ttOptions.config.toolbar.buttons[f]);
                        }

                        break;
                    }
                }
            };

            vm.customGridToolbarTglClick = function (name) {
                let toggles = vm.ttOptions.config.toolbar.toggles;
                for (var t = 0; t < toggles.length; t++) {
                    let toggle = toggles[t];
                    if (toggle.name === name) {
                        let nextState = toggle.state + 1;
                        if (angular.isDefined(toggle.states[nextState])) {
                            toggle.state = nextState;
                        } else {
                            toggle.state = 0;
                        }

                        if (angular.isFunction(toggle.states[toggle.state].func)) {
                            let state = toggle.states[toggle.state];
                            toggle.text = state.text ?? '';
                            toggle.icon = state.icon ?? '';

                            let value = document.getElementById(`tt-grid-tbtgl-${name}`); // $('.k-grid-toolbar').find("#tt-grid-tbtgl-" + name)[0]
                            let text = angular.isUndefined(toggle.translate) || toggle.translate !== false ? (toggle.text !== '' ? (angular.isDefined(wordlang[toggle.text]) ? wordlang[toggle.text] : toggle.text) : '') : toggle.text;

                            text = text.toUpperCase();

                            let spanStart = '<span class="k-icon im-k-icon ';
                            let spanIcon = '';
                            let spanEnd = '"></span>';

                            if (toggle.icon.includes('fa-')) {
                                spanStart += 'font-awesome-5 ';
                                spanIcon = toggle.icon;
                            } else if (toggle.icon.startsWith('glyphicon-')) {
                                spanStart = '<span class="font-inherit pr-5 glyphicon ';
                                spanIcon = toggle.icon;
                            } else {
                                //spanStart += "im-k-icon";

                                if (toggle.icon === 'none') {
                                    spanIcon = '-none';
                                } else if (toggle.icon === 'null') {
                                    spanIcon = '-null';
                                } else {
                                    spanIcon = ' k-i-' + toggle.icon;
                                }
                            }

                            let span = spanStart + spanIcon + spanEnd;
                            value.innerHTML = span + ((text !== '') ? ' ' : '') + text;

                            state.func(state);
                        }

                        break;
                    }
                }
            };

            // ###############################
            // #endregion toolbar
            // ###############################

            // ###############################
            // #region checkbox
            // ###############################

            vm.checkboxBoxClickAll = function (e, that) {
                e.stopPropagation();

                var active = vm.grid.current().find("input");
                var key = e.target.id;
                var dataSource = vm.grid.dataSource;
                var filters = dataSource.filter();
                var allData = dataSource.data();
                var query = new kendo.data.Query(allData);
                var data = query.filter(filters).data;
                let shouldAutoSave = shouldAutoSaveOnDataChanged() && key !== 'is_selected';

                angular.forEach(data, function (item) {
                    item[key] = active.is(':checked');
                    item.dirty = true;
                    item.dirtyFields[key] = true;

                    if (shouldAutoSave) {
                        const rowData = getRowData(angular.copy(item));

                        if (angular.isUndefined(changes[item.uid])) {
                            changes[item.uid] = { state: 'update', data: rowData };
                        } else {
                            changes[item.uid].data = rowData;
                        }
                    };

                });

                // BJS/OYS 20221229
                if (angular.isFunction(vm.ttOptions.optionfunc)) {
                    var data = { data: { func: 'CheckboxHeaderClick', data: allData, key: key } };

                    vm.ttOptions.optionfunc(data);
                }



                if (shouldAutoSave) {
                    //autoSaveState('update').then(function (response) {
                    //    updateAggregates();
                    //});
                    saveAllChanges(false);
                } else {
                    vm.grid.refresh();
                }

                //onDataChanged({ model: item, values }, true);
            };

            vm.checkboxBoxClick = function (e) {
                e.stopPropagation();

                let options = vm.grid.getOptions();
                let dataColIdx = vm.grid.current().index();

                if (!options.columns[dataColIdx]?.isEditable) return;

                let dataRow = vm.grid.current().closest("tr");
                let dataItem = vm.grid.dataItem(dataRow);
                let key = options.columns[dataColIdx].field;

                checkboxCellEditor(dataItem, key, dataRow);
            };

            function checkboxCellEditor(item, key, row) {
                item.set(key, !item[key]);

                let values = {};
                values[key] = item[key];

                onDataChanged({ model: item, values }, true);

                if (angular.isFunction(vm.ttOptions.optionfunc)) {
                    var data = { data: { func: 'CheckboxBoxClick', dataItem: item, key: key, row: row } };

                    vm.ttOptions.optionfunc(data);
                }
            }

            // ###############################
            // #endregion checkbox
            // ###############################

            // ###############################
            // #region gridcell
            // ###############################

            // ###############################
            // #endregion gridcell
            // ###############################

            // ###############################
            // #region grid cell lookup
            // ###############################

            function lookupCellEditor(container, options, eCol, schema) {
                if (angular.isUndefined(container) || angular.isUndefined(options)) return;

                var editorCellId = uuid();
                let dataTextField = angular.isDefined(eCol.datatextfield) && eCol.datatextfield !== null ? eCol.datatextfield : 'item_name';

                // RHE 20230420 Added to allow skipping to next editable column on empty data
                if (options.model[options.field] === null || options.model[options.field].length === 0) options.model[options.field] = ' ';

                $('<input id="' + editorCellId + '" required data-value-field="' + options.field + '" data-bind="value:' + options.field + '" style="color: #515967;"/>').appendTo(container).kendoAutoComplete({
                    dataSource: new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function (read) {
                                var rowData = {};

                                angular.forEach(options.model, function (property, key) {
                                    if (!['parent', 'uid', '_event', '_handlers', '__proto__'].includes(key)) {
                                        rowData[key] = property;
                                    }
                                });

                                var thisElem = $("#" + editorCellId);

                                return ttGridService.loadLookupSearch(read, eCol.lookup, options.field, thisElem.val(), rowData);
                            }
                        },
                        change: function (e) { },
                    }),
                    autoWidth: true,
                    dataTextField: dataTextField,
                    //dataValueField: options.field,
                    delay: 250,
                    filter: "contains",
                    highlightFirst: false,
                    minLength: 1,
                    suggest: false,
                    //open: function (e) { },
                    select: function (e) {
                        if (angular.isDefined(eCol.relations)) {
                            let counter = eCol.relations.length;
                            angular.forEach(eCol.relations, function (rel) {
                                if (angular.isDefined(options.model[rel.key]) && angular.isDefined(e.dataItem[rel.value])) {
                                    //delayChanges = true;
                                    let type = identifyType(e.dataItem[rel.value], null);
                                    let newVal = null;

                                    if (type === 'number') {
                                        newVal = parseFloat(e.dataItem[rel.value]);
                                    } else {
                                        newVal = e.dataItem[rel.value];
                                    }

                                    delayedChanges[rel.key] = newVal;

                                    options.model[rel.key] = newVal;
                                    options.model.dirtyFields[rel.value] = true;
                                    options.model.dirty = true;

                                    //$timeout(function () {
                                    //    options.model[rel.key] = newVal;
                                    //    options.model.dirtyFields[rel.value] = true;
                                    //    options.model.dirty = true;

                                    //    ////let dc = delayChanges === false ? true : delayChanges;
                                    //    ////let dc = delayedChanges;
                                    //    //let dc = delayedChanges;
                                    //    //delayChanges = false;
                                    //    //counter--;
                                    //    //if (eCol.relations.length === 1 || counter === 1) {
                                    //    //    onDataChanged(dc);
                                    //    //    delayedChanges = {};
                                    //    //}
                                    //});


                                }
                            });

                            if (delayedChanges.length > 0) {
                                onDataChanged(delayedChanges);
                                delayedChanges = {};
                                //$timeout(function () {
                                //    onDataChanged(delayedChanges);
                                //    delayedChanges = {};
                                //}, 500);
                            }

                            //console.dir(options.model);
                            //console.dir(delayedChanges);
                            //console.dir(vm.grid.dataSource.get(0))
                        }

                        if (angular.isDefined(eCol.optionfunc) && eCol.optionfunc === true) {
                            let item = {};

                            angular.forEach(e.dataItem, function (property, key) {
                                if (!['parent', 'uid', '_event', '_handlers', '__proto__'].includes(key)) {
                                    item[key] = property;
                                }
                            });

                            if (angular.isDefined(vm.grid.current())) {
                                let dataRow = vm.grid.current().closest("tr");
                                let dataRowIdx = $("tr", vm.grid.tbody).index(dataRow);

                                goOptionFunc({ func: 'LookupCellEditor', field: options.field, item: item, rowIdx: dataRowIdx });
                            }
                        }

                        toolbarBtnDisability();
                    }
                });

                $timeout(function () {
                    $("#" + editorCellId).select();
                });
            }

            // ###############################
            // #endregion grid cell lookup
            // ###############################

            // ###############################
            // #region go functions
            // ###############################

            var goTo = function (item) {
                if (angular.isUndefined(item)) return;
                if (angular.isUndefined(item.item_path) && (angular.isUndefined(item.item_state) || angular.isUndefined(item.item_parms))) return;
                if (item.item_path === '' && (item.item_state === '' || item.item_parms === '')) return;

                stateService.go(item.item_state, item.item_parms);
            };

            var goTab = function (item) {
                if (angular.isUndefined(item)) return;
                if (angular.isUndefined(item.item_path) && (angular.isUndefined(item.item_state) || angular.isUndefined(item.item_parms))) return;
                if (item.item_path === '' && (item.item_state === '' || item.item_parms === '')) return;

                stateService.newTab(item.item_state, item.item_parms);
            };

            var goCustom = function (item, func) {
                if (angular.isUndefined(item)) return;

                vm.ttOptions.config.specialFunc.buttons[func].func(item, vm.ttOptions.config.specialFunc.buttons[func]);
            };

            var goGridFuncDataItem = function (dataItem, func) {
                if (func === 'goTo') goTo(dataItem);
                else if (func === 'goTab') goTab(dataItem);
                else if (angular.isDefined(func) && isNumber(func)) goCustom(dataItem, func);
            };

            var goGridFunc = function (e, func) {
                var dataItem = vm.grid.dataItem($(e.target).closest("tr"));

                if (func === 'goTo' && e?.ctrlKey && e.ctrlKey === true) func = 'goTab';

                goGridFuncDataItem(dataItem, func);
            };

            var goOptionFunc = function (func) {
                if (angular.isFunction(vm.ttOptions.optionfunc)) {
                    $timeout(function () {
                        vm.ttOptions.optionfunc({ data: func });
                    }, 10);
                }
            };

            // ###############################
            // #endregion go functions
            // ###############################

            // ###############################
            // #region gridbutton
            // ###############################

            // ###############################
            // #endregion gridbutton
            // ###############################

            // ###############################
            // #region gridfooter
            // ###############################

            let parseAggregateFunction = function (aggregates, functionString) {
                let aggFunc = functionString;
                Object.entries(aggregates).forEach((col) => {
                    const value = Number.parseFloat(col[1].sum ?? '0') + Number.parseFloat(col[1].min ?? '0') + Number.parseFloat(col[1].max ?? '0') + Number.parseFloat(col[1].average ?? '0') + Number.parseFloat(col[1].float ?? '0');
                    aggFunc = aggFunc.replaceAll(col[0], value);
                });
                let func = new Function([], 'return ' + aggFunc);
                return func();
            }

            let updateAggregates = function () {
                if (angular.isUndefined(vm.grid) || vm.grid === null || angular.isUndefined(vm.grid.footer) || vm.grid.footer === null) return;

                let aggregates = vm.grid.dataSource.aggregates();

                let schemaAggregates = vm.response[0]?.find(e => e.colname === 'xxxoptionsxxx')?.gridoptions?.aggregates;

                angular.forEach(vm.grid.columns, function (col) {
                    if (col.hidden !== true && angular.isDefined(col.footerTemplate)) {

                        let colAggregate = schemaAggregates?.find(e => e.field === col.field);
                        if (colAggregate && colAggregate.aggregate === 'custom') {
                            vm.grid.dataSource.aggregates()[col.field].sum = parseAggregateFunction(aggregates, colAggregate.aggregate_function);
                        } else if (angular.isDefined(aggregates[col.field]) && angular.isDefined(aggregates[col.field].sum)) {

                            let newSum = 0;
                            angular.forEach(getRows(false, true), function (item) {
                                newSum += item[col.field];
                            });
                            vm.grid.dataSource.aggregates()[col.field].sum = newSum;
                        }
                    }
                });

                if (angular.isFunction(vm.grid.footerTemplate)) vm.grid.footer.find(".k-footer-template").replaceWith(vm.grid.footerTemplate(vm.grid.dataSource.aggregates()));
            }

            // ###############################
            // #endregion gridfooter
            // ###############################

            // ###############################
            // #region grid functions
            // ###############################

            var clearSorting = function (column) {
                if (angular.isUndefined(vm.grid.dataSource.sort()) || vm.grid.dataSource.sort().length === 0) return;

                var options = vm.grid.getOptions();

                if (angular.isDefined(column)) {
                    for (var s = 0; s < vm.grid.dataSource.sort().length; s++) {
                        if (angular.isDefined(options.dataSource.sort[s]) && options.dataSource.sort[s].field === column) {
                            options.dataSource.sort.splice(s, 1);
                            break;
                        }
                    }
                } else {
                    vm.grid.dataSource.sort({});
                }
            };

            let clearFilter = function () {
                vm.grid.dataSource.filter({});
                updateFilterUserSettings({});
            };

            // ###############################
            // #endregion grid functions
            // ###############################

            // ###############################
            // #region templates
            // ###############################

            var makeToolbarBtnTemplate = function (name, text, func, icon, cssClass) {
                if (angular.isUndefined(name)) name = 'customBtn' + gridConfig.toolbar.length;
                if (angular.isUndefined(text)) text = name;
                if (angular.isUndefined(func)) func = 'vm.gridToolbarBtnClick(\'' + name + '\')';
                if (angular.isUndefined(icon) || icon === null) icon = 'warning';
                if (angular.isUndefined(cssClass)) cssClass = '';

                var show = 'vm.showToolbarBtn(\'' + name + '\')';

                // BJS 20221009
                text = text.toUpperCase();

                var containsBtnName = null;

                for (var t = 0; t < gridConfig.toolbar.length; t++) {
                    if (angular.isDefined(gridConfig.toolbar[t].name) && gridConfig.toolbar[t].name === name) {
                        if (name.indexOf('customBtn') <= -1) {
                            containsBtnName = true;
                            break;
                        }

                        if (containsBtnName === true) {
                            break;
                        } else if (containsBtnName === false) {
                            containsBtnName = true;
                        } else {
                            containsBtnName = false;
                        }

                        name = 'customBtn' + (gridConfig.toolbar.length + 1);

                        t = -1;
                    }
                }

                if (containsBtnName === true) {
                    return null;
                }

                var glyphBeginning = '<span class="k-icon im-k-icon ';
                var glyphEnd = '"></span>';
                var glyphIcon = '';

                // BJS 20221009 - Added option for fontawesome icons.
                if (icon.includes('fa-')) {
                    glyphBeginning += 'font-awesome-5 ';
                    glyphIcon = icon;
                } else if (icon.startsWith('glyphicon-')) {
                    glyphBeginning = '<span class="font-inherit pr-5 glyphicon ';
                    glyphIcon = icon;
                } else {
                    //glyphBeginning += "im-k-icon";

                    switch (name) {
                        case 'filter':
                            glyphIcon = 'k-i-filter';
                            break;
                        case 'filterClear':
                            glyphIcon = 'k-i-filter-clear';
                            break;
                        case 'columns':
                        case 'colShowHide':
                            glyphIcon = 'k-i-columns';
                            break;
                        case 'colEdit':
                            glyphIcon = 'k-i-edit';
                            break;
                        case 'colLock':
                            glyphIcon = 'k-i-lock';
                            break;
                        case 'toggleHeaders':
                            glyphIcon = 'k-i-group-box';
                            break;
                        case 'addBefore':
                            glyphIcon = 'k-i-insert-up';
                            break;
                        case 'addAfter':
                            glyphIcon = 'k-i-insert-down';
                            break;
                        case 'remove':
                            glyphIcon = 'k-i-delete';
                            break;
                        case 'save':
                            glyphIcon = 'k-i-save';
                            break;
                        case 'config':
                            glyphIcon = 'k-i-gear';
                            break;
                        case 'refresh':
                        case 'read':
                        case 'rebind':
                            glyphIcon = 'k-i-refresh-sm';
                            break;
                        case 'wrapping':
                            glyphIcon = 'k-i-text-wrap';
                            break;
                        case 'layouts':
                            glyphIcon = 'k-i-table-properties';
                            break;
                        case 'print':
                            glyphIcon = 'k-i-print';
                            break;
                        default:
                            if (icon === 'none') {
                                glyphIcon = '-none';
                            } else if (icon === 'null') {
                                glyphIcon = '-null';
                            } else {
                                glyphIcon = 'k-i-' + icon;
                            }
                    }
                }

                var glyphSpan = glyphBeginning + glyphIcon + glyphEnd;

                let style = name.startsWith('custom') ? ' style="font-size: ' + vm.fontSize + ';"' : '';

                var toolbarBtn = {
                    name: name,
                    text: text,
                    //template: '<a ng-click="' + func + '" class="' + ((cssClass !== '') ? cssClass : 'k-button im-grid-toolbar-btn-i') + '" ng-show="' + show + '">' + glyphSpan + ((text !== '') ? ' ' : '') + text + '</a>'
                    template: '<a id="tt-grid-tbbtn-' + name + '" ng-click="' + func + '" class="' + ((cssClass !== '') ? cssClass : 'k-button im-grid-toolbar-btn-i') + '" ng-show="' + show + '"' + style + '>' + glyphSpan + ((text !== '') ? ' ' : '') + text + '</a>'
                };

                return toolbarBtn;
            };

            let makeToolbarTglTemplate = function (toggle, translations) {
                let state = toggle.states[toggle.state];
                let name = angular.isUndefined(toggle.name) ? 'customTgl' + gridConfig.toolbar.length : toggle.name;
                let text = angular.isUndefined(state.text) ? '' : state.text;
                let func = angular.isUndefined(toggle.func) ? `vm.customGridToolbarTglClick('${name}')` : toggle.func;
                let icon = angular.isUndefined(state.icon) || state.icon === null ? 'warning' : state.icon;
                //let cssClass = toggle.cssClass ?? 'btn btn-info im-grid-tgl-xs-r';
                let cssClass = toggle.cssClass ?? 'k-button im-grid-toolbar-btn-i';

                let show = `vm.showToolbarTgl('${name}')`;

                text = toggle.translate === false || state.translate === false ? text : text !== '' && angular.isDefined(translations[text]) ? translations[text] : text;
                text = text.toUpperCase();

                let glyphBeginning = '<span class="k-icon im-k-icon ';
                let glyphEnd = '"></span>';
                let glyphIcon = '';

                if (icon.includes('fa-')) {
                    glyphBeginning += 'font-awesome-5 ';
                    glyphIcon = icon;
                } else if (icon.startsWith('glyphicon-')) {
                    glyphBeginning = '<span class="font-inherit pr-5 glyphicon ';
                    glyphIcon = icon;
                } else {
                    glyphBeginning += "im-k-icon";

                    if (icon === 'none') {
                        glyphIcon = '-none';
                    } else if (icon === 'null') {
                        glyphIcon = '-null';
                    } else {
                        glyphIcon = ' k-i-' + icon;
                    }
                }

                let glyphSpan = glyphBeginning + glyphIcon + glyphEnd;

                let toolbarBtn = {
                    name: name,
                    text: text,
                    //template: `<a id="tt-grid-tbtgl-${name}" ng-click="${func}" class="btn btn-info im-grid-tgl-xs-r" ng-show="${show}">${glyphSpan + ((text !== '') ? ' ' : '') + text}</a>`
                    template: `<a id="tt-grid-tbtgl-${name}" ng-click="${func}" class="${cssClass}" ng-show="${show}">${glyphSpan + ((text !== '') ? ' ' : '') + text}</a>`
                };

                return toolbarBtn;
            };

            var makeBtnTemplate = function (name, glyph, usesItemPath, type, style) {
                usesItemPath = usesItemPath || false;
                style = style || '';

                if (style.length > 0) {
                    style = ' style="' + style + '"';
                }

                // BJS 20220703 - Modified so buttons are only visible if item_path has a value if item_path
                //                is used for the link.
                // BJS 20220914 - Added option for setting style on button.
                if (usesItemPath === true) {
                    var allClass =
                        '<a' + style + ' ng-if="dataItem.item_path !== undefined && dataItem.item_path !== null && dataItem.item_path.length > 0" class="k-grid-' + name + '" ng-class="' + addBtnStyle(type) + '">' +
                        '<span ng-class="' + addGlyph(glyph) + '" type="button" style="color: white;"></span>' +
                        '</a>';
                } else {
                    var allClass =
                        '<a' + style + ' class="k-grid-' + name + '" ng-class="' + addBtnStyle(type) + '">' +
                        '<span ng-class="' + addGlyph(glyph) + '" type="button" style="color: white;"></span>' +
                        '</a>';
                }

                return allClass;
            };

            // BJS 20220913 - Replaced name parameter with type parameter (name was not in use)
            var addBtnStyle = function (type) {
                if (angular.isUndefined(type)) {
                    type = 'primary';
                }

                var allClass = "'tt-button tt-button--" + type + " im-grid-col-btn-xs pull-right'";

                return allClass;
            };

            var addGlyph = function (specific) {
                var allClass = "'glyphicon glyphicon-chevron-right'"; //default

                if (specific === 'goTab') {
                    allClass = "'fas fa-window-restore'";
                }
                else if (angular.isDefined(specific)) {
                    allClass = "'glyphicon " + specific + "'";

                    if (specific.startsWith('fa-')) {
                        allClass = "'fa " + specific + "'"; // BJS 20220705
                    } else if (specific.startsWith('fab-')) {
                        allClass = "'fab " + specific.slice(0, 2) + specific.slice(3) + "'";
                    } else if (specific.startsWith('fad-')) {
                        allClass = "'fad " + specific.slice(0, 2) + specific.slice(3) + "'";
                    } else if (specific.startsWith('fal-')) {
                        allClass = "'fal " + specific.slice(0, 2) + specific.slice(3) + "'";
                    } else if (specific.startsWith('far-')) {
                        allClass = "'far " + specific.slice(0, 2) + specific.slice(3) + "'";
                    } else if (specific.startsWith('fas-')) {
                        allClass = "'fas " + specific.slice(0, 2) + specific.slice(3) + "'";
                    }
                }

                return allClass;
            };

            var addGridGlyph = function (glyph) {
                var allClass = ''; //default

                if (angular.isDefined(glyph)) {
                    allClass = 'glyphicon ' + glyph;

                    if (glyph.startsWith('fa-')) {
                        allClass = 'fa ';
                    } else if (glyph.startsWith('fab-')) {
                        allClass = 'fab ' + glyph.slice(0, 2) + glyph.slice(3);
                    } else if (glyph.startsWith('fad-')) {
                        allClass = 'fad ' + glyph.slice(0, 2) + glyph.slice(3);
                    } else if (glyph.startsWith('fal-')) {
                        allClass = 'fal ' + glyph.slice(0, 2) + glyph.slice(3);
                    } else if (glyph.startsWith('far-')) {
                        allClass = 'far ' + glyph.slice(0, 2) + glyph.slice(3);
                    } else if (glyph.startsWith('fas-')) {
                        allClass = 'fas ' + glyph.slice(0, 2) + glyph.slice(3);
                    }
                }

                return allClass;
            };

            var makeGlyphsTemplate = function () {
                var allClass = '<a class="k-grid-gridGlyphs" style="min-width:8px;"></a>';

                return allClass;
            };

            var getFooterTemplate = function (aggregate, decimals) {
                var footerTemplate = '';
                var deci = angular.isDefined(decimals) && decimals !== '' ? decimals : '0';

                switch (aggregate) {
                    case 'count':
                        footerTemplate = 'Total Count: #=count#';
                        break;
                    case 'sum':
                        var zeros = '';

                        for (var d = 0; d < parseInt(deci); d++) {
                            zeros += '0';
                        }

                        var format = '\\#:\\#,\\#\\#.' + zeros;

                        footerTemplate = '<div style="text-align: right; font-size: ' + vm.fontSize + ';">#=kendo.toString(sum, "' + format + '")#</div>';
                        break;
                    case 'average':
                        footerTemplate = 'Average: #=average#';
                        break;
                    case 'min':
                    case 'max':
                        footerTemplate = '<div>Min: #= min #</div><div>Max: #= max #</div>';
                        break;
                    case 'custom':
                        var zeros = '';

                        for (var d = 0; d < parseInt(deci); d++) {
                            zeros += '0';
                        }

                        var format = '\\#:\\#,\\#\\#.' + zeros;
                        footerTemplate = '<div style="text-align: right; font-size: ' + vm.fontSize + ';">#=kendo.toString(sum, "' + format + '")#</div>';
                        break;
                    default:
                        footerTemplate = 'Error in aggregate';
                }

                return footerTemplate;
            }

            // ###############################
            // #endregion templates
            // ###############################

            // ###############################
            // #region onGridFunctions
            // ###############################

            var onBeforeEdit = function (e) {
                //e.preventDefault();
            };

            var onCancel = function (e) { };

            var onCellClose = function (e) {
                //console.log('onCellClose');
                //console.dir(e);
                //var current = e.sender.current() || [];
                var current = e.container || {};
                var options = vm.grid.getOptions();
                var cellIndex = 0;
                var rowIndex = 0;

                if (current[0]) {
                    cellIndex = vm.grid.cellIndex($(current[0]).closest('td'));
                    rowIndex = $(current[0]).closest('tr').index();

                    if (angular.isFunction(options.columns[cellIndex].styleFunc)) {
                        let styles = options.columns[cellIndex].styleFunc(e.model);

                        angular.forEach(styles, function (style, key) {
                            current[0].style[key] = style;
                        });
                    }

                    if (vm.lastKeydown !== null) {
                        var currentNumberOfItems = vm.grid.dataSource.view().length;
                        var currentNumberOfColumns = options.columns.length;
                        var isBetweenRow = rowIndex > 0 && rowIndex < currentNumberOfItems ? true : false;
                        var isBetweenCol = cellIndex > 0 && cellIndex < currentNumberOfColumns ? true : false;
                        var nextCellRow = rowIndex;
                        var nextCellCol = cellIndex;
                        let nextCell = { nextIndex: cellIndex, rowStep: 0 };
                        if (angular.isDefined(vm.ttOptions.config.navigation) && angular.isDefined(vm.ttOptions.config.navigation.altNav) && vm.ttOptions.config.navigation.altNav === true) {
                            switch (vm.lastKeydown.key) {
                                case 'Enter':
                                case 'Tab':
                                    if (vm.lastKeydown.shiftKey === true) {
                                        nextCell = findNextEditCell(cellIndex, false);
                                        //if (cellIndex === 0 && rowIndex !== 0) {
                                        //    nextCellRow--;
                                        //    nextCellCol = currentNumberOfColumns;
                                        //} else if (isBetweenCol || cellIndex === currentNumberOfColumns) nextCellCol--;
                                    } else {
                                        nextCell = findNextEditCell(cellIndex, true);
                                        //if (cellIndex === currentNumberOfColumns) {
                                        //    if (rowIndex === currentNumberOfItems && angular.isDefined(vm.ttOptions.config.navigation.newRow) && vm.ttOptions.config.navigation.newRow === true) {
                                        //        //Add new row
                                        //    } else {
                                        //        nextCellRow++;
                                        //        nextCellCol = 0;
                                        //    }
                                        //} else if (isBetweenCol || cellIndex === 0) nextCellCol++;
                                    }

                                    nextCellRow += nextCell.rowStep;
                                    nextCellCol = nextCell.nextIndex;

                                    if (shouldAutoSaveOnDataChanged() && getDirtyRows().length > 0) {
                                        nextEdits = { row: nextCellRow, col: nextCellCol };
                                    }

                                    // wait for cell to close and Grid to rebind when changes have been made
                                    setTimeout(function () {
                                        editCell(nextCellRow, nextCellCol);
                                    });

                                    break;
                                //case 'Tab':
                                //    if (vm.lastKeydown.shiftKey === true) {
                                //        if (isBetweenCol || cellIndex === currentNumberOfColumns) nextCellCol--;
                                //    } else {
                                //        if (isBetweenCol || cellIndex === 0) nextCellCol++;
                                //    }
                                //    break;
                            }
                        } else {
                            switch (vm.lastKeydown.key) {
                                case 'Enter':
                                    if (vm.lastKeydown.shiftKey === true) {
                                        if (isBetweenRow || rowIndex === currentNumberOfItems) nextCellRow--;
                                        //nextCell = findNextEditCell(cellIndex, false);
                                    } else {
                                        if (isBetweenRow || rowIndex === 0) nextCellRow++;
                                        //nextCell = findNextEditCell(cellIndex, true);
                                    }

                                    //nextCellRow += nextCell.rowStep;
                                    //nextCellCol = nextCell.nextIndex;

                                    if (shouldAutoSaveOnDataChanged() && getDirtyRows().length > 0) {
                                        nextEdits = { row: nextCellRow, col: nextCellCol };
                                    } else {
                                        // wait for cell to close and Grid to rebind when changes have been made
                                        setTimeout(function () {
                                            editCell(nextCellRow, nextCellCol);
                                        });
                                    }
                                    break;
                                case 'Tab':
                                    if (vm.lastKeydown.shiftKey === true) {
                                        nextCell = findNextEditCell(cellIndex, false);
                                    } else {
                                        nextCell = findNextEditCell(cellIndex, true);
                                    }

                                    nextCellRow += nextCell.rowStep;
                                    nextCellCol = nextCell.nextIndex;

                                    if (shouldAutoSaveOnDataChanged() && getDirtyRows().length > 0) {
                                        nextEdits = { row: nextCellRow, col: nextCellCol };
                                    } else {
                                        // wait for cell to close and Grid to rebind when changes have been made
                                        setTimeout(function () {
                                            editCell(nextCellRow, nextCellCol);
                                        });
                                    }
                                    break;
                            }
                        }
                        vm.lastKeydown = null;
                        //e.preventDefault();
                    }
                }

                if (e.type === 'save' && angular.isFunction(vm.ttOptions.optionfunc)) {
                    var col = options.columns[cellIndex];

                    var data = { data: { func: 'OnCellClose', ridx: rowIndex, cidx: cellIndex, cval: col.field, change: e.model[col.field], rdata: e.model } };

                    //singleRowUpdate(e.model);
                    //singleRowUpdate(rowIndex);

                    vm.ttOptions.optionfunc(data);
                }

                toolbarBtnDisability();
            };

            var onChange = function (e) {

                if (vm.ttOptions?.onSelect && angular.isFunction(vm.ttOptions.onSelect)) {
                    let dataItem = vm.grid.dataItem(vm.grid.select());

                    if (dataItem) vm.ttOptions.onSelect({ $event: e, $item: dataItem });
                }


                if (angular.isFunction(vm.ttOptions?.kendo?.selectable.indexOf) && (vm.ttOptions.kendo.selectable.indexOf('row') !== -1 || vm.ttOptions.kendo.selectable.indexOf('multiple') !== -1)) {

                    var data = { data: { func: 'OnChange', change: e } };

                    vm.ttOptions.optionfunc(data);
                }
            };



            var onColumnHide = function (e) {
                if (angular.isDefined(userSettings.columns[e.column.field])) {
                    userSettings.columns[e.column.field].hidden = e.column.hidden;
                } else {
                    userSettings.columns[e.column.field] = {
                        hidden: e.column.hidden
                    };
                }

                saveUserSettings();
            };

            var onColumnLock = function (e) { };

            var onColumnMenuInit = function (e) { };

            var onColumnMenuOpen = function (e) { };

            var onColumnReorder = function (e) {
                angular.forEach(e.sender.columns, function (col, key) {
                    if (angular.isDefined(userSettings.columns[col.field])) {
                        userSettings.columns[col.field].order = key;
                    } else {
                        userSettings.columns[col.field] = {
                            order: key
                        };
                    }
                });

                var shift = e.newIndex > e.oldIndex ? -1 : 1;

                angular.forEach(userSettings.columns, function (col, key) {
                    if (key === e.column.field) {
                        col.order = e.newIndex;
                    } else {
                        if (shift === -1) {
                            if (col.order > e.oldIndex && col.order <= e.newIndex) {
                                col.order += shift;
                            }
                        } else {
                            if (col.order >= e.newIndex && col.order < e.oldIndex) {
                                col.order += shift;
                            }
                        }
                    }
                });

                saveUserSettings();
            };

            var onColumnResize = function (e) {
                //console.dir(e);
                if (angular.isDefined(userSettings.columns[e.column.field])) {
                    userSettings.columns[e.column.field].width = e.newWidth;
                } else {
                    userSettings.columns[e.column.field] = {
                        width: e.newWidth
                    };
                }

                //let table = wrapper.find('[role="grid"]');
                //let gridContent = wrapper.find('.k-grid-content');
                //let gridContentWidth = Number(gridContent?.css('width')?.replace('px', ''));
                //let tableWidth = Number(table?.css('width')?.replace('px', ''));

                //if (tableWidth < gridContentWidth) {
                //    let lastColumn = vm.grid.columns[vm.grid.columns.length - 1];
                //    let widthDifference = gridContentWidth - tableWidth;
                //    let paddingRight = Number(wrapper.find(".k-grid-header").css('padding-right')?.replace('px', ''));
                //    table.css('width', '100%');
                //    vm.grid.resizeColumn(vm.grid.columns[vm.grid.columns.length - 1], lastColumn.width + widthDifference - paddingRight);
                //}

                saveUserSettings();
            };

            var onColumnShow = function (e) {
                if (angular.isDefined(userSettings.columns[e.column.field])) {
                    userSettings.columns[e.column.field].hidden = e.column.hidden;
                } else {
                    userSettings.columns[e.column.field] = {
                        hidden: e.column.hidden
                    };
                }

                saveUserSettings();
            };

            var onColumnStick = function (e) { };

            var onColumnUnlock = function (e) { };

            var onColumnUnstick = function (e) { };

            var onDataBinding = function (e) {
                if (angular.isFunction(vm.ttOptions.onDataBinding)) {
                    vm.ttOptions.onDataBinding(e);
                }

                //var i = 0;
                //var items = e.sender.items();
                //var columns = e.sender.columns;
                //var schema = e.sender.dataSource.options.schema;
                //items.each(function () {
                //    var row = $(this);
                //    var dataItem = e.sender.dataItem(row);

                //    if (i < 1) {
                //        i++;
                //    }
                //});
            };

            var wrapper = undefined;

            var resizeFixed = function () {
                if (angular.isUndefined(wrapper))
                    return;
                if (angular.isUndefined(vm.ttOptions.config.fixedHeader))
                    return;
                if (vm.ttOptions.config.fixedHeader !== true)
                    return;

                var header = wrapper.find(".k-grid-header");
                var toolbar = wrapper.find(".k-grid-toolbar");
                let footer = wrapper.find(".k-grid-footer");
                let content = wrapper.find(".k-grid-content");
                let table = wrapper.find('[role="grid"]');
                let colgroup = table.find('colgroup');
                let footerColgroup = footer.find('colgroup');

                var paddingRight = content[0].offsetWidth - content[0].clientWidth;
                table.css("width", '100%');
                header.css("width", `calc(${content.css('width')} - ${paddingRight}px)`);
                toolbar.css("width", `calc(${content.css('width')})`);
                footer.css("width", `calc(${content.css('width')} - ${paddingRight}px)`);
                footer.find('table').css('width', '100%');

                colgroup.children().css('min-width', '40px');
                footerColgroup.children().css('min-width', '40px');

                //wrapper.css('height', `calc(100% - 2px )`);
                //content.css('height', `calc(100% - 1px )`);
                //content.css('height', `calc(100% - ${content.css('height')} - ${header.css('height')} )`);
                //content.css('height', `calc(${content.css('height')} - 1px )`);

                if (vm.ttOptions?.config?.css?.height === 'fill') {
                    let headerHeight = Number(wrapper.find('.k-grid-toolbar')?.css('height')?.replace('px', '') ?? 0) + Number(wrapper.find('.k-grid-header')?.css('height')?.replace('px', '') ?? 0);
                    let footerHeight = Number(wrapper.find('.k-grid-footer')?.css('height')?.replace('px', '') ?? 0) + Number(wrapper.find('.k-grid-pager')?.css('height')?.replace('px', '') ?? 0) + Number(wrapper.find('.k-grid-pager')?.css('padding-bottom')?.replace('px', '') ?? 0) + Number(wrapper.find('.k-grid-pager')?.css('padding-top')?.replace('px', '') ?? 0);

                    wrapper.css('height', '100%');
                    wrapper.find('.k-grid-content').css('height', `calc(100% - ${Math.ceil(headerHeight + footerHeight + 7)}px )`);

                }

            };

            var scrollFixed = function () {
                if (angular.isUndefined(wrapper))
                    return;
                if (angular.isUndefined(vm.ttOptions.config.fixedHeader))
                    return;
                let header = wrapper.find(".k-grid-header");
                let footer = wrapper.find(".k-grid-footer");
                let table = wrapper.find('[role="grid"]');
                let content = wrapper.find(".k-grid-content");
                let toolbar = wrapper.find(".k-grid-toolbar");
                let paddingRight = (content[0]?.offsetWidth ?? 0) - (content[0]?.clientWidth ?? 0);

                if (vm.ttOptions.config.fixedHeader !== true) {
                    table.css("width", '100%');
                    header.css("width", `calc(100% - ${paddingRight}px)`);
                    toolbar.css("width", `calc(100%`);
                    footer.css("width", `calc(100% - ${paddingRight}px)`);
                    footer.find('table').css('width', '100%');
                    return;
                }

                let navBar = $(".tt-navbar")[0];
                let breadcrumbs = $(".tt-breadcrumbs__nav")[0];
                let ttNavbarHeight = navBar.scrollHeight + (vm.breadcrumbsShowing === true ? (breadcrumbs?.scrollHeight ?? 0) : 0);
                header.css("width", `calc(${content.css('width')} - ${paddingRight}px)`);
                toolbar.css("width", `calc(${content.css('width')})`);
                footer.css("width", `calc(${content.css('width')} - ${paddingRight}px)`);
                footer.find('table').css('width', '100%');

                let scrollPosition = $(this).scrollTop() + (ttNavbarHeight);

                let tableOffsetTop = wrapper.offset().top;
                let tableOffsetBottom = tableOffsetTop + content.height();

                if (scrollPosition > tableOffsetTop && scrollPosition < tableOffsetBottom) {
                    if (!toolbar.hasClass("fixed-header")) {
                        toolbar.addClass("fixed-header");
                        toolbar.css('top', ttNavbarHeight + 'px');
                        header.css('top', ttNavbarHeight + toolbar.clientHeight + 'px');
                        resizeFixed();

                        if (vm.ttOptions.config.toolbar.hidden === true) {
                            header.addClass("fixed-header");
                            header.css('top', ttNavbarHeight + 'px');
                            content.css("padding-top", header.height() + "px");
                        } else {
                            header.addClass("fixed-header-extended");
                            header.css('top', ttNavbarHeight + toolbar.height() + 1 + 'px');
                            content.css("padding-top", header.height() + toolbar.height() + "px");
                        }
                    }
                } else {
                    if (toolbar.hasClass("fixed-header")) {
                        toolbar.removeClass("fixed-header");
                        header.removeClass("fixed-header");
                        header.removeClass("fixed-header-extended");
                        content.css("padding-top", 0);
                    }
                }
                $(this).scrollTop(scrollPosition - ttNavbarHeight);
            }

            var onDataBound = function (e) {
                var i = 0;
                var r = document.querySelector(':root');
                var items = e.sender.items();
                var columns = e.sender.columns;
                var schema = e.sender.dataSource.options.schema;

                vm.grid = $('#' + vm.id).data('kendoGrid');

                if (angular.isUndefined(vm.ttOptions.config.css) || angular.isUndefined(vm.ttOptions.config.css.altColor) || vm.ttOptions.config.css.altColor === true) {
                    r.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');
                } else if (vm.ttOptions.config.css.altColor === false) {
                    r.style.setProperty('--im-k-alt-bg-color', undefined);
                } else {
                    r.style.setProperty('--im-k-alt-bg-color', vm.ttOptions.config.css.altColor);
                }

                $(".k-grid-content").css("min-height", "200px");

                if (angular.isFunction(vm.ttOptions.onDataBound)) {
                    vm.ttOptions.onDataBound(e);
                }

                var pageSize = e.sender.dataSource.pageSize();
                var page = e.sender.dataSource.page();
                var dataLength = e.sender.dataSource.data().length;
                var maxPage = parseInt(dataLength / pageSize) + 1;

                var userSettingUpdated = false;

                if (userSettings.pageSize !== pageSize) {
                    userSettings.pageSize = pageSize;

                    userSettingUpdated = true;
                }

                if (userSettings.page !== page || page > maxPage || page < 0) {
                    if (page > maxPage || page < 0) {
                        page = 1;
                        e.sender.dataSource.page(page);
                    }
                    userSettings.page = page;

                    userSettingUpdated = true;
                }

                if (userSettingUpdated) {
                    saveUserSettings();
                }

                if (gridConfig.hasBoolean) {
                    var gridRadio = e.sender.element.find('.im-grid-radio');

                    if (angular.isUndefined(gridRadio) || gridRadio.length === 0) {
                        var radioLabels = e.sender.element.find('.k-filter-row label>input[type=radio]');

                        if (angular.isDefined(radioLabels) && radioLabels.length > 0 && gridRadio.length === 0) {
                            radioLabels.each(function (r) {
                                if (r % 2 === 0) {
                                    radioLabels[r].insertAdjacentHTML("beforebegin", '<i class="far fa-check-square im-grid-radio"></i>');

                                    var radioLabel = radioLabels[r].closest('.k-filtercell');
                                    radioLabel.style.marginLeft = '-10px';
                                    radioLabel.style.textAlign = 'center';
                                } else {
                                    radioLabels[r].insertAdjacentHTML("beforebegin", '<i class="far fa-square im-grid-radio"></i>');
                                }
                            });
                        }
                    }
                }

                if (angular.isDefined(vm.grid) && vm.grid !== null) {
                    if (angular.isDefined(vm.ttOptions.config.toolbar) && angular.isDefined(vm.ttOptions.config.toolbar.hidden)) {
                        if (vm.ttOptions.config.toolbar.hidden === true) {
                            $('.k-grid-toolbar').hide();
                        } else if (vm.ttOptions.config.toolbar.hidden === false) {
                            $('.k-grid-toolbar#' + vm.id).show();
                        }
                    }
                }

                if (gridConfig.hasGlyphs || gridConfig.hasStyleFunc) {
                    var glyphlistCount = 0;

                    function isBlack(color) {
                        return color === 'black' || color === '#000000';
                    }

                    items.each(function () {
                        var row = $(this);
                        var dataItem = e.sender.dataItem(row);

                        if (gridConfig.hasGlyphs) {
                            if (!dataItem.hasChildren) {
                                row.find(".k-hierarchy-cell").html("");
                            }

                            if (angular.isDefined(dataItem.item_glyphicon)) {
                                var glyphlist = dataItem.item_glyphicon.split(' ');
                                var glyphlistColor = angular.isDefined(dataItem.item_glyphicon_color) ? dataItem.item_glyphicon_color.split(' ') : [];

                                var glyphCell = row.find(".k-grid-gridGlyphs");

                                if (glyphlistCount < glyphlist.length) {
                                    glyphlistCount = glyphlist.length;
                                }

                                for (var g = 0; g < glyphlist.length; g++) {
                                    var style = (glyphlistColor.length > 0) ? ' style="color: ' + (isBlack(glyphlistColor[g]) ? 'var(--tt-text-color)' : glyphlistColor[g]) + '; width: ' + vm.fontSize + '; padding: 0px 2px;"' : '';
                                    //console.log(style);

                                    $('<span class="' + addGridGlyph(glyphlist[g]) + '"' + style + '></span>').appendTo(glyphCell);
                                }
                            }
                        }

                        if (gridConfig.hasStyleFunc) {
                            for (var c = 0; c < vm.grid.columns.length; c++) {
                                if (angular.isFunction(vm.grid.columns[c].styleFunc)) {
                                    let styles = vm.grid.columns[c].styleFunc(dataItem);

                                    angular.forEach(styles, function (style, key) {
                                        row[0].cells[c].style[key] = style;
                                    });
                                }
                            }
                        }
                    });

                    for (var c = 0; c < vm.grid.columns.length; c++) {
                        if (vm.grid.columns[c].field === 'grid_glyphs') {
                            var newWidth = (glyphlistCount * (parseFloat(vm.fontSize.slice(0, -2)) + 4)) + 2;

                            vm.grid.resizeColumn(vm.grid.columns[c], newWidth);
                            break;
                        }
                    }
                }

                toolbarBtnDisability();

                if (gridConfig.hasPath || gridConfig.hasSpecialFunc) {
                    if (vm.grid.columns[vm.grid.columns.length - 1].field === 'grid_functions') {
                        if (gridConfig.canHideSpecialFunc) {
                            angular.forEach(vm.ttOptions.config.specialFunc.buttons, function (btn) {
                                if (angular.isFunction(btn.disabled)) {
                                    items.each(function (index) {
                                        var row = $(this);
                                        var dataItem = e.sender.dataItem(row);
                                        var commandBtn = row.find(".k-grid-" + btn.name);

                                        if (angular.isDefined(commandBtn[0])) {
                                            var isDisabled = btn.disabled(dataItem);

                                            switch (isDisabled) {
                                                case 'hidden':
                                                    commandBtn.toggleClass('im-grid-col-btn-hidden', true);
                                                    commandBtn.toggleClass('k-state-disabled', false);
                                                    break;
                                                case true:
                                                    commandBtn.toggleClass('im-grid-col-btn-hidden', false);
                                                    commandBtn.toggleClass('k-state-disabled', true);
                                                    break;
                                                case false:
                                                    commandBtn.toggleClass('im-grid-col-btn-hidden', false);
                                                    commandBtn.toggleClass('k-state-disabled', false);
                                                    break;
                                            }
                                        }
                                    });
                                }
                            });
                        }

                        var minLength = 38;
                        var functionsCount = vm.grid.columns[vm.grid.columns.length - 1].command.length;
                        var functionsWidth = functionsCount > 1 ? (functionsCount * 32) + 6 : minLength;

                        vm.grid.resizeColumn(vm.grid.columns[vm.grid.columns.length - 1], functionsWidth);
                    }
                }

                if (gridConfig.hasEditable) {
                    var headerCells = e.sender.element.find('th');

                    headerCells.each(function (i, e) {
                        //if (angular.isDefined(columns) && angular.isDefined(columns[i]) && angular.isDefined(columns[i].field)
                        //    && angular.isDefined(schema) && angular.isDefined(schema.model) && angular.isDefined(schema.model.fields)) {
                        if (columns?.[i]?.field && schema?.model?.fields) {
                            var fields = schema.model.fields;

                            if (columns[i].field === 'grid_glyphs') {
                                var headerCell = $(this);
                                //var link = headerCell.find("a.k-link");
                                //var link = headerCell.find("th.GridGlyphs");
                                //var icon = link.find('span.fa-icons');
                                var icon = headerCell.find('span.fa-icons');

                                if (!icon.length) {
                                    headerCell.prepend('<span class="fal fa-icons" style="font-size: ' + vm.fontSizeXL + ';"></span> ');
                                }
                            }

                            if (angular.isDefined(fields[columns[i].field]) && fields[columns[i].field].editable === true) {
                                var headerCell = $(this);
                                var link = headerCell.find("a.k-link");
                                let isLookup = false;
                                let isClickonly = false;

                                const editCol = vm.ttOptions.config.editColumns.find(col => col.key === columns[i].field);

                                if (editCol?.lookup && angular.isFunction(columns[i].editor)) isLookup = true;
                                if (editCol?.clickonly === true) isClickonly = true;

                                //if (angular.isFunction(columns[i].editor)) {
                                //for (let j = 0; j < vm.ttOptions.config.editColumns.length; j++) {
                                //let editCol = vm.ttOptions.config.editColumns[j];
                                //if (editCol?.lookup && editCol.key === columns[i].field && angular.isFunction(columns[i].editor)) {
                                //    isLookup = true;
                                //}

                                //if (editCol?.clickonly === true && editCol.key === columns[i].field) {
                                //    isClickonly = true;
                                //}

                                //if (isLookup || isClickonly) break;
                                //}
                                //}

                                if (isLookup) {
                                    let icon = link.find('span.k-i-search');

                                    if (!icon.length) {
                                        link.prepend('<span class="k-icon k-i-search" style="font-size: ' + vm.fontSize + ';"></span> ');
                                    }
                                } else if (isClickonly) {
                                    let icon = link.find('span.k-i-change-manually');

                                    if (!icon.length) {
                                        link.prepend('<span class="k-icon k-i-change-manually" style="font-size: ' + vm.fontSize + ';"></span> ');
                                    }
                                } else {
                                    let icon = link.find('span.k-i-edit');

                                    if (!icon.length) {
                                        link.prepend('<span class="k-icon k-i-edit" style="font-size: ' + vm.fontSize + ';"></span> ');
                                    }
                                }
                            }
                        }
                    });
                }

                updateAggregates();

                wrapper = this.wrapper;

                resizeFixed();

                kendo.ui.progress($element, false);
            };

            var onDetailCollapse = function (e) { };

            var onDetailExpand = function (e) { };

            var onDetailInit = function (e) { };

            var onEdit = function (e) {
                //console.log('onEdit');
                //console.dir(e);
                vm.lastKeydown = null;
                vm.lastClickedRow = e.container.closest('tr');

                var input = e.container.find("input");

                setTimeout(function () {
                    input.select();
                    input.focus();
                }, 1);
            };

            var onExcelExport = function (e) { };

            var onFilter = function (e) {
                saveUserSettings();
                updateAggregates();
            };

            var onFilterMenuInit = function (e) { };

            var onFilterMenuOpen = function (e) { };

            var onGroup = function (e) { };

            var onGroupCollapse = function (e) { };

            var onGroupExpand = function (e) { };

            var onNavigate = function (e) {
                //console.log('onNavigate');
                if (angular.isDefined(vm.ttOptions.config.rowClick) && vm.ttOptions.config.rowClick === true) {
                    let el = $(e.element[0]);

                    if (el[0].nodeName !== 'TH') {
                        e.preventDefault();

                        var dataItem = el.closest("tr");

                        goGridFuncDataItem(dataItem, 'goTo');
                    }
                }
            };

            var onPage = function (e) {
                userSettings.page = e.page;
                saveUserSettings();
            };

            var onPdfExport = function (e) { };

            var onSort = function (e) { };

            // ###############################
            // #endregion onGridFunctions
            // ###############################

            // ###############################
            // #region getLoad functions
            // ###############################

            var getLoadSetupMethod = function () {
                return angular.isDefined(vm.ttOptions.dataTask.loadSetupId) && vm.ttOptions.dataTask.loadSetupId !== null ? vm.ttOptions.dataTask.loadSetupId : 1999;
            };

            var getLoadDataMethod = function () {
                return angular.isObject(vm.ttOptions.dataTask.loadData)
                    ? vm.ttOptions.dataTask.loadData.method
                    : vm.ttOptions.dataTask.loadData;
            };

            var getLoadDataParms = function () {
                // BJS 20220323 - Modified so parameters can be a function
                if (angular.isObject(vm.ttOptions.dataTask.loadData)) {
                    var parms = angular.isFunction(vm.ttOptions.dataTask.loadData.parameters)
                        ? vm.ttOptions.dataTask.loadData.parameters()
                        : vm.ttOptions.dataTask.loadData.parameters;

                    if (angular.isUndefined(parms) || parms === null) {
                        parms = {};
                    }
                    parms.webpage_name = stateService.getCurrentName();

                    return parms;
                } else {
                    return {};
                }
            };

            var getServerSideHandling = function () {
                var serverSideHandling = {
                    enabled: false,
                    data: 'items',
                    total: 'total'
                };

                if (angular.isDefined(vm.ttOptions.config.serverSideHandling) && vm.ttOptions.config.serverSideHandling !== null) {
                    if (us.isBoolean(vm.ttOptions.config.serverSideHandling)) {
                        serverSideHandling.enabled = vm.ttOptions.config.serverSideHandling;
                    } else {
                        if (angular.isObject(vm.ttOptions.config.serverSideHandling)) {
                            if (us.isBoolean(vm.ttOptions.config.serverSideHandling.enabled)) {
                                serverSideHandling.enabled = vm.ttOptions.config.serverSideHandling.enabled;
                            }

                            if (angular.isString(vm.ttOptions.config.serverSideHandling.data)) {
                                serverSideHandling.data = vm.ttOptions.config.serverSideHandling.data;
                            }

                            if (angular.isString(vm.ttOptions.config.serverSideHandling.total)) {
                                serverSideHandling.total = vm.ttOptions.config.serverSideHandling.total;
                            }
                        }
                    }
                }

                return serverSideHandling;
            };

            // ###############################
            // #endregion getLoad functions
            // ###############################

            // ###############################
            // #region data changes
            // ###############################

            var getRowData = function (row) {
                var del = [];

                for (var key in row) {
                    switch (true) {
                        case key === 'dirty':
                        case key === 'uid':
                        case key === 'grid_select_col':
                        // BJS 20220529 - Added angular.isData check because a data will
                        //                return true on angular.isObject.
                        case (angular.isObject(row[key]) && !angular.isDate(row[key])):
                        case angular.isArray(row[key]):
                        case angular.isFunction(row[key]):
                            del.push(key);
                            break;
                    }
                }

                angular.forEach(del, function (value) {
                    if (value !== 'dirtyFields') {
                        delete row[value];
                    }
                });

                return row;
            }

            var shouldAutoSaveOnDataChanged = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.saveData)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.saveData.autoSave)) return false;

                return vm.ttOptions.dataTask.saveData.autoSave === true;
            };

            let onDataChangedRunning = false;

            // BJS 20220311 - fires on kendo "save" event which fires when focus is moved outside
            //                of a cell where data has been changed
            var onDataChanged = function (e, waitForCompletion) {
                //console.log('onDataChanged');
                //console.log(e);

                if (onDataChangedRunning === true && waitForCompletion === true) {
                    return;
                }

                //if (delayChanges === true || e === true) {
                if (delayChanges === true) {
                    delayedChanges = e;

                    return;
                }

                var changed = getRowData(angular.copy(e.model));
                let isOnlyIsSelected = null; //Added to stop autosaving when clicking this column

                angular.forEach(e.values, function (value, key) {
                    //Added to stop autosaving when clicking this column
                    if (isOnlyIsSelected === true && key !== 'is_selected') {
                        isOnlyIsSelected = false;
                    } else if (isOnlyIsSelected === null) {
                        isOnlyIsSelected = key === 'is_selected' ? true : false;
                    }

                    changed[key] = value;
                });

                if (isOnlyIsSelected) return; //Added to stop autosaving when clicking this column

                onDataChangedRunning = true;
                if (delayedChanges) {
                    angular.forEach(delayedChanges, function (value, key) {
                        if (angular.isDefined(changed) && angular.isDefined(changed[key])) changed[key] = value;
                    });
                }

                // BJS 20220923 - Must modify date values to prevent them being converted to utc datetime
                //                when converting to json.
                angular.forEach(changed, function (value, key) {
                    if (value instanceof Date) {
                        var tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                        value = (new Date(new Date(value) - tzo)).toISOString().replace('T', ' ').slice(0, -1);

                        changed[key] = value;
                    }
                });

                if (angular.isDefined(vm.ttOptions.config.onDataChanged) && angular.isFunction(vm.ttOptions.config.onDataChanged)) {
                    vm.ttOptions.config.onDataChanged(changed, e);
                }

                if (angular.isUndefined(changes[e.model.uid])) {
                    changes[e.model.uid] = { state: 'update', data: changed };
                } else {
                    changes[e.model.uid].data = changed;
                }

                if (shouldAutoSaveOnDataChanged()) {
                    autoSaveState('update').then(function (response) {
                        updateAggregates();
                    }).finally(() => {
                        delayedChanges = {};
                        setTimeout(function () {
                            if (nextEdits !== null) {
                                editCell(nextEdits.row, nextEdits.col);
                                nextEdits = null;
                            }
                        });

                        onDataChangedRunning = false;
                    });
                } else {
                    onDataChangedRunning = false;
                }
            };


            // ###############################
            // #endregion changes
            // ###############################

            // ###############################
            // #region save
            // ###############################

            var getDataTaskId = function (state) {
                switch (state) {
                    case 'add':
                        return 'addRow';
                    case 'update':
                    case 'all':
                        return 'saveData';
                    case 'remove':
                        return 'removeRow';
                    default:
                        return null;
                }
            };

            var validateMethod = function (state) {
                var dataTaskId = getDataTaskId(state);

                if (dataTaskId === null) return;

                if (angular.isObject(vm.ttOptions.dataTask) !== true) {
                    vm.ttOptions.dataTask = {};
                }

                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId])) {
                    vm.ttOptions.dataTask[dataTaskId] = {};
                }

                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId].method) || vm.ttOptions.dataTask[dataTaskId].method === null || vm.ttOptions.dataTask[dataTaskId].method === '' || vm.ttOptions.dataTask[dataTaskId].method === NaN) {
                    vm.ttOptions.dataTask[dataTaskId].method = defaultDataTaskKeyno;
                }

                if (angular.isFunction(vm.ttOptions.dataTask[dataTaskId].method) !== true && angular.isNumber(vm.ttOptions.dataTask[dataTaskId].method) !== true) {
                    vm.ttOptions.dataTask[dataTaskId].method = parseInt(vm.ttOptions.dataTask[dataTaskId].method, 10);
                }

                if (vm.ttOptions.dataTask[dataTaskId].method === NaN) {
                    vm.ttOptions.dataTask[dataTaskId].method = defaultDataTaskKeyno;
                }

                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId].parameters)) {
                    vm.ttOptions.dataTask[dataTaskId].parameters = {};
                }
            };

            var shouldConfirmSave = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.saveData)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.saveData.confirm)) return false;

                return vm.ttOptions.dataTask.saveData.confirm === true;
            };

            var tryCustomSave = function (state) {
                var dataTaskId = getDataTaskId(state);

                if (dataTaskId === null) return $q.resolve(false);
                if (angular.isUndefined(vm.ttOptions)) return $q.resolve(false);
                if (angular.isUndefined(vm.ttOptions.dataTask)) return $q.resolve(false);
                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId])) return $q.resolve(false);
                if (angular.isUndefined(vm.ttOptions.dataTask[dataTaskId].method)) return $q.resolve(false);
                if (angular.isFunction(vm.ttOptions.dataTask[dataTaskId].method) !== true) return $q.resolve(false);

                var result = vm.ttOptions.dataTask[dataTaskId].method(state, changes);

                if (angular.isFunction(result) === true) {
                    result = result(state, changes);
                }

                if (angular.isDefined(result) && angular.isDefined(result.then)) {
                    var deferred = $q.defer();

                    result.then(function () {
                        deferred.resolve(true);
                    });

                    return deferred.promise;
                } else {
                    return $q.resolve(true);
                }
            };

            var showErrorMessage = function (errorcode, errormessage) {
                var deferred = $q.defer();

                modalService.show({
                    type: 'danger',
                    title: wordlang.ttgrid_modal_save_error_titleprefix + ' - ' + errorcode,
                    message: errormessage,
                    buttons: [{
                        label: wordlang.ttgrid_modal_save_error_btn,
                        cssClass: 'btn-danger',
                        action: function (modalInstance) {
                            modalInstance.close();

                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            var getErrorInfo = function (response) {
                var msg = { error: false };

                if (angular.isArray(response) === true) {
                    if (response.length !== 1) return msg;
                    if (angular.isUndefined(response[0].errorcode)) return msg;
                    if (response[0].errorcode === null) return msg;
                    if (response[0].errorcode === '0' || response[0].errorcode === 0) return msg;

                    return { error: true, code: response[0].errorcode, msg: response[0].errormessage };
                }

                var errorInfo = angular.isUndefined(response)
                    ? msg
                    : angular.isDefined(response.errorcode) && angular.isDefined(response.errormessage) && response.errorcode !== '0' && response.errorcode !== 0
                        ? { error: true, code: response.errorcode, msg: response.errormessage }
                        : angular.isUndefined(response.data)
                            ? msg
                            : angular.isUndefined(response.data.message)
                                ? msg
                                : { error: true, code: 0, msg: response.data.message };

                // BJS 20220619 - Handles error messages from grid update.
                if (errorInfo.error !== true && angular.isDefined(response.savedata) && angular.isDefined(response.savedata.errorcode) && response.savedata.errorcode !== 0) {
                    errorInfo = { error: true, code: response.savedata.errorcode, msg: response.savedata.errormessage };
                }

                return errorInfo;
            };

            // Saves all changes of a specific state ()
            var autoSaveState = function (state) {
                var deferred = $q.defer();

                var delChanges = [];

                var autoSaveCompleted = function (response) {
                    angular.forEach(changes, function (value, key) {
                        if (value.state === state) {
                            delChanges.push(key);
                        }
                    });

                    if (angular.isDefined(vm.ttOptions.dataTask.saveData.refreshRowOnSave) && vm.ttOptions.dataTask.saveData.refreshRowOnSave === true && angular.isDefined(response) && response.length > 0 && angular.isDefined(response[0].savedata)) {
                        var items = vm.grid.items();
                        var changedIds = Object.getOwnPropertyNames(changes);

                        angular.forEach(changedIds, function (id) {
                            for (var i = 0; i < items.length; i++) {
                                let item = vm.grid.dataItem(items[i]);
                                if (item.uid === id) {
                                    item.dirty = false;

                                    angular.forEach(response[0].savedata, function (value, key) {
                                        if (angular.isDefined(item[key]) === true) {
                                            if (angular.isFunction(item[key]) !== true && angular.isObject(item[key]) !== true && angular.isArray(item[key]) !== true) {
                                                item[key] = item[key] instanceof Date && value !== null ? value.toISOString() : value;
                                            } else if (angular.isObject(item[key]) && item[key] instanceof Date) {
                                                item[key] = value instanceof Date && value !== null ? value.toISOString() : value;
                                            }
                                        }
                                    });

                                    singleRowUpdate(items[i]);
                                    break;
                                }
                            }
                        });

                        angular.forEach(delChanges, function (value) {
                            delete changes[value];
                        });

                        kendo.ui.progress($element, false);

                        deferred.resolve(response);
                    } else {
                        angular.forEach(delChanges, function (value) {
                            delete changes[value];
                        });

                        readGridData().then(function () {
                            kendo.ui.progress($element, false);

                            deferred.resolve(response);
                        });
                    }
                };

                var doSave = function () {
                    if (!vm.ttOptions.dataTask.saveData.hideRefreshSpinner || vm.ttOptions.dataTask.saveData.hideRefreshSpinner !== true) kendo.ui.progress($element, true);

                    tryCustomSave(state).then(function (hasCustomSave) {
                        if (hasCustomSave === true) {
                            angular.forEach(changes, function (value, key) {
                                if (value.state === state) {
                                    delChanges.push(key);
                                }
                            });

                            autoSaveCompleted();
                        } else {
                            validateMethod(state);

                            var promises = [];

                            var dataTaskId = getDataTaskId(state);

                            // doing forEach here but since this method is only meant for autoSave there should never
                            // be more than one record of the selected state.
                            angular.forEach(changes, function (value, key) {
                                if (value.state === state) {
                                    var parameters = vm.ttOptions.dataTask[dataTaskId].parameters;

                                    if (angular.isUndefined(parameters) || parameters === null || parameters === '') {
                                        parameters = {};
                                    }

                                    // BJS 20221125 - Fix for datetime offset.
                                    let tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

                                    angular.forEach(value.data, function (d, key) {
                                        if (angular.isDefined(columnInfo[key]) && columnInfo[key].type === 'date') {
                                            value.data[key] = moment((new Date(new Date(d) - tzo))).format('YYYY-MM-DD HH:mm');
                                        }
                                    });

                                    parameters.state = state;
                                    parameters.changes = value.data;
                                    parameters.load_datatask_keyno = getLoadDataMethod();

                                    if (vm.ttOptions.config.saveInclLoadParms == true) {
                                        parameters = { ...parameters, ...getLoadDataParms() }
                                    }

                                    promises.push($ihttp.post({ method: vm.ttOptions.dataTask[dataTaskId].method, parameters: parameters }));

                                    delChanges.push(key);
                                }
                            });

                            $q.all(promises).then(function (responses) {
                                var msg = '';
                                var hasError = false;

                                angular.forEach(responses, function (response) {
                                    if (angular.isDefined(response)) {
                                        var info = getErrorInfo(response);

                                        if (info.error === true) {
                                            msg += info.msg + '\n\r';

                                            hasError = true;
                                        }
                                    }
                                });

                                if (hasError === true) {
                                    showErrorMessage(0, msg).then(function () {
                                        autoSaveCompleted(responses)
                                    });
                                } else {
                                    autoSaveCompleted(responses);
                                }
                            });
                        }
                    });
                }

                if (shouldConfirmSave() === true) {
                    modalService.confirm({
                        type: 'warning',
                        title: wordlang.ttgrid_modal_save_title,
                        message: wordlang.ttgrid_modal_save_message,
                        okLabel: wordlang.ttgrid_modal_save_ok,
                        cancelLabel: wordlang.ttgrid_modal_save_cancel
                    }).then(doSave);
                } else {
                    doSave();
                }

                return deferred.promise;
            };

            // BJS 20220311
            var saveAllChanges = function (showProgress) {
                var dirtyRows = getDirtyRows();

                angular.forEach(dirtyRows, function (row) {
                    if (angular.isUndefined(changes[row.uid])) {
                        changes[row.uid] = { state: 'update', data: getRowData(angular.copy(row)) };
                    } else {
                        if (changes[row.uid]?.data) changes[row.uid].data = getRowData(angular.copy(row));
                    }
                });

                var changeCount = 0;

                for (var prop in changes) if (changes.hasOwnProperty(prop)) changeCount++;

                if (changeCount < 1) return $q.resolve();

                var deferred = $q.defer();

                var doSave = function () {
                    kendo.ui.progress($element, true);

                    var saveCompleted = function (progress) {
                        changes = {};

                        if (!vm.ttOptions.dataTask?.saveData?.readAfterSave === false) readGridData();

                        kendo.ui.progress($element, false);

                        if (angular.isDefined(progress) && angular.isObject(progress) && angular.isFunction(progress.hide)) {
                            progress.hide();
                        }

                        deferred.resolve();
                    };

                    tryCustomSave('all').then(function (hasCustomSave) {
                        if (hasCustomSave === true) {
                            saveCompleted();
                        } else {
                            validateMethod('add');
                            validateMethod('update');
                            validateMethod('remove');

                            var dtids = {
                                add: getDataTaskId('add'),
                                update: getDataTaskId('update'),
                                remove: getDataTaskId('remove')
                            };

                            var singleSave = angular.isUndefined(vm.ttOptions.dataTask.saveData)
                                ? true
                                : angular.isUndefined(vm.ttOptions.dataTask.saveData.single)
                                    ? true
                                    : vm.ttOptions.dataTask.saveData.single === true;

                            var progress = modalProgressFactory.$create({
                                label: wordlang.ttgrid_save_progress_label,
                                min: 0,
                                max: changeCount
                            });

                            if (showProgress !== false) progress.show();

                            if (singleSave === true) {
                                // Save only one change at a time, wait for save to complete before continuing.
                                // If one save fails then updating is stopped.
                                var rows = [];

                                angular.forEach(changes, function (row) {
                                    rows.push(row);
                                });

                                var saveRow = function () {
                                    if (rows.length < 1) {
                                        saveCompleted();

                                        return;
                                    }

                                    var dataTaskId = dtids[rows[0].state];
                                    console.dir(dataTaskId);

                                    var parameters = vm.ttOptions.dataTask[dataTaskId].parameters;

                                    if (!parameters || parameters === '') {
                                        parameters = {};
                                    }

                                    parameters.state = rows[0].state;
                                    parameters.changes = rows[0].data;
                                    parameters.load_datatask_keyno = getLoadDataMethod();

                                    var doStep = function (response) {
                                        progress.step();

                                        var msg = getErrorInfo(response);

                                        if (msg.error === true) {
                                            // show error message
                                            rows.length = 0;

                                            showErrorMessage(msg.code, msg.msg).then(function () {
                                                saveCompleted(progress);
                                            });

                                            return;
                                        } else if (progress?.instance?.closed?.$$state?.status && progress.instance.closed.$$state.status === 1) {
                                            rows.length = 0;
                                            saveCompleted(progress);

                                            return;
                                        } else {
                                            rows.shift();

                                            saveRow();
                                        }
                                    };
                                    $ihttp.post({ method: vm.ttOptions.dataTask[dataTaskId].method, parameters: parameters }).then(function (d) { doStep(d); }, function (e) { doStep(e); });
                                };

                                saveRow();
                            } else {
                                // Post all changes at the same time
                                var errors = [];

                                angular.forEach(changes, function (value, key) {
                                    var dataTaskId = dtids[value.state];

                                    var parameters = vm.ttOptions.dataTask[dataTaskId].parameters;

                                    if (angular.isUndefined(parameters) || parameters === null) {
                                        parameters = {};
                                    }

                                    parameters.state = value.state;
                                    parameters.changes = value.data;
                                    parameters.load_datatask_keyno = getLoadDataMethod();

                                    var doStep = function (response) {
                                        var msg = getErrorInfo(response);

                                        if (msg.error === true) {
                                            errors.push(msg);
                                        }

                                        progress.step();

                                        changeCount--;
                                    }

                                    $ihttp.post({ method: vm.ttOptions.dataTask[dataTaskId].method, parameters: parameters }).then(function (r) { doStep(r); }, function (e) { doStep(e); });
                                });

                                var waitAll = function () {
                                    if (changeCount < 1) {
                                        if (errors.length > 0) {
                                            var msg = '';

                                            angular.forEach(errors, function (e) {
                                                msg += e.msg + '\n\r';
                                            });

                                            showErrorMessage(0, msg).then(function () {
                                                saveCompleted(progress);
                                            });
                                        } else {
                                            saveCompleted();
                                        }

                                        return;
                                    }

                                    $timeout(waitAll, 25);
                                };

                                waitAll();
                            }
                        }
                    });
                }

                if (shouldConfirmSave() === true) {
                    modalService.confirm({
                        type: 'warning',
                        title: wordlang.ttgrid_modal_save_title,
                        message: wordlang.ttgrid_modal_save_message,
                        okLabel: wordlang.ttgrid_modal_save_ok,
                        cancelLabel: wordlang.ttgrid_modal_save_cancel
                    }).then(doSave);
                } else {
                    doSave();
                }

                return deferred.promise;
            };

            // ###############################
            // #endregion save
            // ###############################

            // ###############################
            // #region other functions
            // ###############################

            var goShowHideColumns = function (mode) {
                modalService.gridColumnsModal(vm.grid, mode).then(function (value) {
                });
            };

            let openPrintModal = function (reports) {
                let reportsList;

                if (angular.isObject(reports) && angular.isFunction(reports.func)) {
                    //console.log('printModal is Custom');
                    //console.dir(datatask.func());
                    reportsList = reports.func();
                } else {
                    reportsList = vm.ttOptions.reports;
                }

                let modalInstance = $uibModal.open({
                    component: 'ttGridPrintModal',
                    resolve: {
                        parameters: function () {
                            return {
                                load: {
                                    method: getLoadDataMethod(),
                                    parms: getLoadDataParms()
                                },
                                reports: reportsList ?? [],
                                selected: {
                                    row: vm.ttOptions.gridfunc.getSelectedRow(),
                                    rows: vm.ttOptions.gridfunc.getSelectedRows(),
                                    isSelected: vm.ttOptions.gridfunc.getAllRows().filter((row) => row.is_selected === true),
                                }
                            };
                        }
                    },
                    animation: true,
                    //size: 'pst-ninetyfive',
                    //backdrop: 'static'
                    backdrop: true
                });

                modalInstance.result.then(function (response) {
                    //deferred.resolve(response);
                    //console.log('response');
                    console.dir(response);
                    //console.dir(reportsList);

                    let reportParms = {
                        p2_reportdef_keyno: response.p2_reportdef_keyno,
                        //p2_reportdef_keyno: response.printServerKeyno > 0 ? response.printServerKeyno : response.p2_reportdef_keyno,
                        report_type_keyno: response.report_type_keyno,
                        p2_datatask_keyno: getLoadDataMethod(),
                        parms: getLoadDataParms(),
                        filename: response.filename,
                        printer: response.printer,
                        //report_name: response.report_name,
                        selected: {
                            row: vm.ttOptions.gridfunc.getSelectedRow(),
                            rows: vm.ttOptions.gridfunc.getSelectedRows(),
                            isSelected: vm.ttOptions.gridfunc.getIsSelectedRows() //getAllRows().filter((row) => row.is_selected === true),
                        }
                    };
                    console.dir(reportParms);

                    if (response.report_type_keyno === 2) {
                        const parameters = vm.ttOptions.gridfunc.getIsSelectedRows();
                        if (parameters.length == 0) {
                            parameters.push(getLoadDataParms());
                        }
                        pdfService.printPDFBook({
                            reportKeyno: response.p2_reportdef_keyno,
                            printerKeyno: response.printer,
                            parameters: parameters
                        }).then(() => {
                            console.log(vm.ttOptions);
                            if (vm.ttOptions?.config?.readAfterPrint) {
                                vm.ttOptions?.gridfunc?.read?.();
                            }
                        });
                    } else {
                        ttGridService.gridPrint(reportParms).then(() => {
                            console.log(vm.ttOptions);
                            if (vm.ttOptions?.config?.readAfterPrint) {
                                vm.ttOptions?.gridfunc?.read?.();
                            }
                        });
                    }


                }, function () {
                    //deferred.reject();
                });

                //return deferred.promise;

                //let resolve = {
                //    parameters: function () {
                //        return {
                //            dataItem: {}
                //        };
                //    }
                //};

                //dbModal('ttGridPrintModal', resolve, '');

                //let openPrintModal = function (dataItem) {
                //    let deferred = $q.defer();

                //    let modalInstance = $uibModal.open({
                //        component: 'ttGridPrintModal',
                //        resolve: {
                //            parameters: function () {
                //                return {
                //                    dataItem: dataItem
                //                };
                //            }
                //        },
                //        //size: 'pst-ninetyfive',
                //        backdrop: 'static'
                //    });

                //    modalInstance.result.then(function (response) {
                //        deferred.resolve(response);
                //    }, function () {
                //        deferred.reject();
                //    });

                //    return deferred.promise;
                //};

                //function dbModal(component, resolve, size) {
                //    size ??= 'pst-ninetyfive'
                //    $uibModal.open({
                //        component: component,
                //        resolve: resolve,
                //        animation: true,
                //        size: size,
                //        backdrop: true
                //    });
                //};
            };

            //function calculatePageSizes(options) {
            //    if (isNumber($scope.setup.pager.pageSizes)) {
            //        var pageSizes = [];
            //        var numberOfSplits = isNumber($scope.setup.pager.pageSizes) && $scope.setup.pager.pageSizes > 0 ? $scope.setup.pager.pageSizes : 10;
            //        var dataLength = options.dataSource.data.length;
            //        var splitSize = Math.floor((dataLength / numberOfSplits) + 0.5);
            //        splitSize = splitSize >= 1 ? splitSize : 1;

            //        for (var p = 0; p < numberOfSplits; p++) {
            //            var nextNum = (splitSize + (p * splitSize));
            //            pageSizes.push(nextNum);
            //        }
            //        options.pageable.pageSizes = pageSizes;
            //    } else {
            //        if ($scope.setup.pager.pageSizes === true) {
            //            options.pageable.pageSizes = [10, 25, 50, 100];
            //        } else {
            //            options.pageable.pageSizes = $scope.setup.pager.pageSizes;
            //        }
            //    }
            //}

            let viewMatching = function (criteria, value) {
                if (dataRaw.length === 0) {
                    angular.copy(vm.grid.dataSource.data(), dataRaw);
                }

                if (criteria === '') {
                    vm.grid.setDataSource(dataRaw);
                } else if (angular.isDefined(criteria)) {
                    let matchingData = [];

                    for (let i = 0; i < dataRaw.length; i++) {
                        let item = dataRaw[i];
                        if (item[criteria] === value) {
                            matchingData.push(item);
                        }
                    }

                    if (matchingData.length > 0) {
                        vm.grid.setDataSource(matchingData);
                    }
                }
            }

            var definePageable = function (translations, devModeResponse) { //vm.ttOptions.kendo.pageable
                var pageable = vm.ttOptions.kendo.pageable;

                return isDefinedAndNotNull(pageable) ? {
                    pageSize: isDefinedAndNotNull(pageable.pageSize) ? pageable.pageSize : 25,
                    pageSizes: isDefinedAndNotNull(pageable.pageSizes) ? pageable.pageSizes : [5, 10, 15, 20, 25, 50, 100, 250, 500],
                    input: isDefinedAndNotNull(pageable.input) ? pageable.input : true,
                    numeric: isDefinedAndNotNull(pageable.numeric) ? pageable.numeric : false,
                    messages: {
                        display: angular.isObject(devModeResponse) && devModeResponse !== null ? getLoadDataMethod() + ' - ' + devModeResponse.proc_name + ' ::: ' + translations.grid_pg_display : translations.grid_pg_display,              // default: "{0} - {1} of {2} items"
                        empty: translations.grid_pg_empty,                  // default: "No items to display",
                        page: translations.grid_pg_page,                    // default: "Page"
                        of: translations.grid_pg_of,                        // default: "of {0}")
                        itemsPerPage: translations.grid_pg_itemsperpage,    // default: "items per page"
                        first: translations.grid_pg_first,                  // default: "Go to the first page"
                        last: translations.grid_pg_last,                    // default: "Go to the last page"
                        next: translations.grid_pg_next,                    // default: "Go to the next page"
                        previous: translations.grid_pg_previous,            // default: "Go to the previous page"
                        refresh: translations.grid_pg_refresh,              // default: "Refresh"
                        morePages: translations.grid_pg_morepages           // default: "More pages"
                    }
                } : {
                    pageSize: 25,
                    pageSizes: [5, 10, 15, 20, 25, 50, 100, 250, 500],
                    input: true,
                    numeric: false,
                    messages: {
                        display: angular.isObject(devModeResponse) && devModeResponse !== null ? getLoadDataMethod() + ' - ' + devModeResponse.proc_name + ' ::: ' + translations.grid_pg_display : translations.grid_pg_display,              // default: "{0} - {1} of {2} items"
                        empty: translations.grid_pg_empty,                  // default: "No items to display",
                        page: translations.grid_pg_page,                    // default: "Page"
                        of: translations.grid_pg_of,                        // default: "of {0}")
                        itemsPerPage: translations.grid_pg_itemsperpage,    // default: "items per page"
                        first: translations.grid_pg_first,                  // default: "Go to the first page"
                        last: translations.grid_pg_last,                    // default: "Go to the last page"
                        next: translations.grid_pg_next,                    // default: "Go to the next page"
                        previous: translations.grid_pg_previous,            // default: "Go to the previous page"
                        refresh: translations.grid_pg_refresh,              // default: "Refresh"
                        morePages: translations.grid_pg_morepages           // default: "More pages"
                    }
                }
            };

            var defineFilterable = function () {
                var filterable = vm.ttOptions.kendo.filterable;

                return angular.isDefined(userSettings.filterable) ? userSettings.filterable : isDefinedAndNotNull(filterable) ? filterable : { mode: "row" };
            };

            var singleRowUpdate = function (row, rowIdx) {
                rowIdx = isDefinedAndNotNull(rowIdx) && rowIdx >= 0 ? rowIdx : 0;
                row = isDefinedAndNotNull(row) ? row : vm.grid.items()[rowIdx];
                //var dataItem = vm.grid.dataItem(row);
                //dataItem.dirty = true;
                //dataItem['prod_name'] = "changed value";
                kendoFastReDrawRow(row);
            }

            var kendoFastReDrawRow = function (row, dataItem) {
                if (dataItem) {
                    row = vm.grid.table.find("[data-uid=" + dataItem.uid + "]")[0];
                } else {
                    dataItem = vm.grid.dataItem(row);
                }

                var rowChildren = $(row).children('td[role="gridcell"]');

                for (var i = 0; i < vm.grid.columns.length; i++) {
                    var column = vm.grid.columns[i];
                    var template = column.template;
                    var cell = rowChildren.eq(i);

                    if (column.field === 'grid_glyphs' && angular.isDefined(dataItem) && angular.isDefined(dataItem.item_glyphicon)) {
                        let glyphlist = dataItem.item_glyphicon.split(' ');
                        let glyphlistColor = angular.isDefined(dataItem.item_glyphicon_color) ? dataItem.item_glyphicon_color.split(' ') : [];

                        for (let g = 0; g < glyphlist.length; g++) {
                            cell[0].children[0].children[g].setAttribute('class', addGlyph(glyphlist[g]).replaceAll("'", ''));
                            if (g >= glyphlistColor.length) continue;
                            cell[0].children[0].children[g].style.color = glyphlistColor[g];
                        }
                    }

                    if (template !== undefined && template.indexOf('dataItem.grid_') < 0 && dataItem) {
                        var kendoTemplate = kendo.template(template);

                        // Render using template
                        if (dataItem[column.field] !== null) cell.html(kendoTemplate(dataItem));
                        else cell.html(dataItem[column.field]);
                    } else if (dataItem) {
                        var fieldValue = dataItem[column.field];
                        var format = column.format;
                        var values = column.values;

                        if (values) {
                            // use the text value mappings (for enums)
                            for (var j = 0; j < values.length; j++) {
                                var value = values[j];
                                if (value.value == fieldValue) {
                                    cell.html(value.text);
                                    break;
                                }
                            }
                        } else if (format !== undefined) {
                            // use the format
                            cell.html(kendo.format(format, fieldValue));
                        } else {
                            // Just dump the plain old value
                            cell.html(fieldValue);
                        }
                    }
                }
            }

            // ###############################
            // #endregion other functions
            // ###############################

            // ###############################
            // #region utilityFunctions
            // ###############################

            function isDefinedAndNotNull(x) {
                return angular.isDefined(x) && x !== null;
            }

            function isNumber(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }

            //function isInArray(value, array) {
            //    return array.indexOf(value) > -1;
            //}

            //function numberWithSpaces(x, deci) {
            //    deci = angular.isDefined(deci) ? deci : 0;
            //    //return Number.parseFloat(x).toFixed(deci);
            //    x = Number(Math.round(parseFloat(x + 'e' + deci)) + 'e-' + deci).toFixed(deci)
            //    return kendo.toString(x, "##.#,##").replace(/./g, " ");
            //}

            function identifyType(value, domain) {
                if (angular.isDefined(domain)) {
                    switch (domain) {
                        case 'currency':
                            return 'currency';
                        case 'decimal':
                        case 'integer':
                        case 'number':
                        case 'numeric':
                            return 'number';
                        case 'date':
                        case 'time':
                        case 'datetime':
                        case 'timestamp':
                            return 'date';
                        case 'bit':
                        case 'bool':
                        case 'boolean':
                            return 'boolean';
                        default:
                            return 'string';

                    }
                }

                if (angular.isUndefined(value) || value === null || value === '') {
                    return 'string';
                } else if (isNumber(value)) {
                    return 'number';
                } else if (value === 'true' || value === 'false') {
                    return 'boolean';
                } else {
                    return 'string';
                }
            }

            function getType(domain) {
                switch (domain) {
                    case 'decimal':
                    case 'number':
                        return 'number';
                    case 'date':
                    case 'time':
                    case 'datetime':
                    case 'timestamp':
                        return 'date';
                    case 'bit':
                    case 'bool':
                    case 'boolean':
                        return 'boolean';
                    default:
                        return 'string';
                }
            }

            function getFormat(schema) {
                switch (schema.coltype) {
                    case 'decimal':
                        var deci = angular.isDefined(schema.decimals) && schema.decimals !== '' ? schema.decimals : '0';

                        var zeros = '';

                        for (var d = 0; d < parseInt(deci); d++) {
                            zeros += '0';
                        }

                        return '{0:#,##.' + zeros + '}';
                    //return '{0:n' + deci + '}';
                    case 'date':
                        return '{0:dd/MM/yyyy}';
                    case 'datetime':
                    case 'timestamp':
                        return '{0:dd/MM/yyyy HH:mm:ss}';
                    default:
                        return null;
                }
            }

            // ###############################
            // #endregion utilityFunctions
            // ###############################

            // ###############################
            // #region grid
            // ###############################

            var setGridOptions = function (options) {
                //console.log('options');
                //console.dir(options);
                if (angular.isDefined(options.aggregates)) {
                    if (angular.isUndefined(vm.ttOptions.kendo)) {
                        vm.ttOptions.kendo = {};
                    }

                    if (angular.isUndefined(vm.ttOptions.kendo.aggregate)) {
                        vm.ttOptions.kendo.aggregate = [];
                    }

                    angular.forEach(options.aggregates, function (agg) {
                        if (angular.isDefined(agg.template) && (agg.template === null || agg.template.trim().length < 1)) {
                            delete agg.template;
                        }

                        vm.ttOptions.kendo.aggregate.push(agg);
                    });
                }

                if (angular.isDefined(options.kendo)) {
                    // BJS 20221209 - if selectable === 'na' then value is not set from database
                    if (angular.isDefined(options.kendo.selectable) && options.kendo.selectable !== 'na') {
                        vm.ttOptions.kendo.selectable = options.kendo.selectable;
                    }

                    if (angular.isDefined(options.kendo.sortable) && options.kendo.sortable !== 'na') {
                        vm.ttOptions.kendo.sortable = options.kendo.sortable;
                    }

                    if (angular.isDefined(options.kendo.filterable) && options.kendo.filterable !== 'na') {
                        if (options.kendo.filterable === 'row') {
                            vm.ttOptions.kendo.filterable = { mode: "row" };
                        } else {
                            vm.ttOptions.kendo.filterable = options.kendo.filterable;
                        }
                    }
                }

                if (options.add.autoSave !== null) {
                    vm.ttOptions.dataTask.addRow.autoSave = options.add.autoSave;
                }

                if (options.add.confirm !== null) {
                    vm.ttOptions.dataTask.addRow.confirm = options.add.confirm;
                }

                if (options.remove.autoSave !== null) {
                    vm.ttOptions.dataTask.removeRow.autoSave = options.remove.autoSave;
                }

                if (options.remove.confirm !== null) {
                    vm.ttOptions.dataTask.removeRow.confirm = options.remove.confirm;
                }

                if (options.save.autoSaveChanges !== null) {
                    vm.ttOptions.dataTask.saveData.autoSave = options.save.autoSaveChanges;
                }

                if (options.save.confirm !== null) {
                    vm.ttOptions.dataTask.saveData.confirm = options.save.confirm;
                }

                if (options.save.single !== null) {
                    vm.ttOptions.dataTask.saveData.single = options.save.single;
                }

                if (options.save.refreshRowOnSave !== null) {
                    vm.ttOptions.dataTask.saveData.refreshRowOnSave = options.save.refreshRowOnSave;
                }

                if (options.save.hideRefreshSpinner !== null) {
                    vm.ttOptions.dataTask.saveData.hideRefreshSpinner = options.save.hideRefreshSpinner;
                }

                if (options.save.readAfterSave !== null) {
                    vm.ttOptions.dataTask.saveData.readAfterSave = options.save.readAfterSave;
                }

                if (options.reports !== null) {
                    vm.ttOptions.reports = options.reports;
                }

                if (options.toolbar.add !== null) {
                    vm.ttOptions.config.toolbar.add = options.toolbar.add;
                }

                if (options.toolbar.addSimple !== null) {
                    vm.ttOptions.config.toolbar.addSimple = options.toolbar.addSimple;
                }

                if (options.toolbar.columnVisibility !== null) {
                    vm.ttOptions.config.toolbar.columnVisibility = options.toolbar.columnVisibility;
                }

                if (options.toolbar.delete !== null) {
                    vm.ttOptions.config.toolbar.remove = options.toolbar.delete;
                }

                if (options.toolbar.edit !== null) {
                    vm.ttOptions.config.toolbar.edit = options.toolbar.edit;
                }

                if (options.toolbar.excelExport !== null) {
                    vm.ttOptions.config.toolbar.excelExport = options.toolbar.excelExport;
                }

                if (options.toolbar.filter !== null) {
                    vm.ttOptions.config.toolbar.filter = options.toolbar.filter;
                }

                if (options.toolbar.headers !== null) {
                    vm.ttOptions.config.toolbar.headers = options.toolbar.headers;
                }

                if (options.toolbar.hidden !== null) {
                    vm.ttOptions.config.toolbar.hidden = options.toolbar.hidden;
                }

                if (options.toolbar.layouts !== null) {
                    vm.ttOptions.config.toolbar.layouts = options.toolbar.layouts;
                }

                if (options.toolbar.refresh !== null) {
                    vm.ttOptions.config.toolbar.refresh = options.toolbar.refresh;
                }

                if (options.toolbar.read !== null) {
                    vm.ttOptions.config.toolbar.read = options.toolbar.read;
                }

                if (options.toolbar.rebind !== null) {
                    vm.ttOptions.config.toolbar.rebind = options.toolbar.rebind;
                }

                if (options.toolbar.print !== null) {
                    vm.ttOptions.config.toolbar.print = options.toolbar.print;
                }

                if (options.toolbar.lock !== null) {
                    vm.ttOptions.config.toolbar.lock = options.toolbar.lock;
                }

                if (options.toolbar.pdfExport !== null) {
                    vm.ttOptions.config.toolbar.pdfExport = options.toolbar.pdfExport;
                }

                if (options.toolbar.save !== null) {
                    vm.ttOptions.config.toolbar.save = options.toolbar.save;
                }

                if (options.toolbar.wrapping !== null) {
                    vm.ttOptions.config.toolbar.wrapping = options.toolbar.wrapping;
                }

                if (options.save.saveInclLoadparms == true) {
                    vm.ttOptions.config.saveInclLoadParms = true;
                }

                // BJS 20220228
                angular.forEach(options.toolbar.buttons, function (btn) {
                    vm.ttOptions.config.toolbar.buttons.push({
                        name: btn.name,
                        text: btn.text,
                        translate: btn.translate,
                        icon: btn.icon,
                        cssClass: btn.cssClass,
                        dbType: {
                            id: btn.type,
                            state: btn.state,
                            state_params: btn.state_params,
                            p2_datatask_keyno: btn.p2_datatask_keyno,
                            component: btn.component,
                            route_id: btn.route_id,
                            modal_size: btn.modal_size
                        },
                        func: dbToolbarButtonClick
                    });
                });

                if (angular.isUndefined(vm.ttOptions.config.specialFunc)) {
                    vm.ttOptions.config.specialFunc = {
                        newTab: options.specialFunc.newTab,
                        buttons: []
                    };
                } else {
                    vm.ttOptions.config.specialFunc.newTab = options.specialFunc.newTab;

                    if (angular.isUndefined(vm.ttOptions.config.specialFunc.buttons)) {
                        vm.ttOptions.config.specialFunc.buttons = [];
                    }
                }

                angular.forEach(options.specialFunc.buttons, function (btn) {
                    if (btn?.type === 'noclick') {
                        //Add no special row features
                    } else if (!btn?.type || btn.type === 'cell') {
                        vm.ttOptions.config.specialFunc.cellBtns ??= [];
                        vm.ttOptions.config.specialFunc.cellBtns.push({
                            name: btn.name,
                            type: btn.btn_type,
                            func: dbRowClick,
                            dbType: {
                                id: btn.type,
                                state: btn.state,
                                state_params: btn.state_params,
                                p2_datatask_keyno: btn.p2_datatask_keyno,
                                component: btn.component,
                                route_id: btn.route_id,
                                modal_size: btn.modal_size
                            }
                        });
                    } else {
                        // JLR 20240905 check if button already exist in buttons list to prevent multiple buttons when grid gets rebind.
                        if (vm.ttOptions?.config?.specialFunc?.buttons.filter((button) => button.name === btn.name).length === 0) {
                            vm.ttOptions.config.specialFunc.buttons.push({
                                name: btn.name,
                                text: btn.text,
                                translate: btn.translate,
                                icon: btn.icon,
                                type: btn.btn_type,
                                func: dbRowClick,
                                dbType: {
                                    id: btn.type,
                                    state: btn.state,
                                    state_params: btn.state_params,
                                    p2_datatask_keyno: btn.p2_datatask_keyno,
                                    component: btn.component,
                                    route_id: btn.route_id,
                                    modal_size: btn.modal_size
                                }
                            });
                        }
                    }
                });
            };

            var buildOptions = function (schema, translations, devModeResponse) {
                var deferred = $q.defer();

                var fields = {};
                var aggregate = [];
                var columns = [];
                var boolCols = [];

                // BJS 20220307 - Added support for setting up edit options in a stored procedure.
                if (angular.isUndefined(vm.ttOptions.config.editColumns) || vm.ttOptions.config.editColumns.length < 1) {
                    vm.ttOptions.config.editColumns = [];
                }

                var userTranslations = splitTranslations(translations);

                gridConfig.hasBoolean = false;
                gridConfig.hasEditable = false;
                gridConfig.hasGlyphs = false;
                gridConfig.hasPath = false;
                gridConfig.hasThumb = false;
                gridConfig.hasSpecialFunc = false;
                gridConfig.hasStyleFunc = false;
                gridConfig.canHideSpecialFunc = false;
                gridConfig.useSpecialFuncEdit = false;
                gridConfig.openEditOnNewRow = false;

                if (angular.isObject(vm.ttOptions.dataTask) && angular.isObject(vm.ttOptions.dataTask.addRow) && angular.isDefined(vm.ttOptions.dataTask.addRow.openEdit)) {
                    gridConfig.openEditOnNewRow = vm.ttOptions.dataTask.addRow.openEdit;
                }

                var hasItemPathColumn = false;

                // BJS 20220504 - Need to do an extra loop over schema so we can find gridOptions before setting up columns
                angular.forEach(schema, function (cs) {
                    if (cs.colname === 'xxxoptionsxxx') {
                        // BJS 20220331 - Setting custom grid options from procedure
                        setGridOptions(cs.gridoptions);
                    }

                    if (cs.colname === 'item_path') {
                        hasItemPathColumn = true;
                    }
                });

                if (angular.isDefined(vm.ttOptions.config.specialFunc)) {
                    // BJS 20220911 - Added specialFunc.edit
                    gridConfig.useSpecialFuncEdit = angular.isDefined(vm.ttOptions.config.specialFunc.edit) && vm.ttOptions.config.specialFunc.edit === true;

                    gridConfig.hasSpecialFunc = angular.isDefined(vm.ttOptions.config.specialFunc.buttons) && vm.ttOptions.config.specialFunc.buttons.length > 0
                        ? true
                        : gridConfig.useSpecialFuncEdit;
                }

                angular.forEach(schema, function (colSchema) {
                    columnInfo[colSchema.colname] = {
                        type: colSchema.coltype
                    };

                    var col = {};

                    if (colSchema.colname === 'xxxoptionsxxx') {
                        // BJS 20220504 - Do nothing
                    } else if (colSchema.colname === 'item_glyphicon' || colSchema.colname === 'item_glyphicon_color') {
                        if (!gridConfig.hasGlyphs) {
                            col = {
                                field: 'grid_glyphs',
                                title: angular.isDefined(translations['grid_glyphs']) ? translations['grid_glyphs'] : ' ',
                                command: [{ name: 'gridGlyphs', template: makeGlyphsTemplate() }],
                                filterable: false,
                                attributes: {
                                    'class': 'CellClickHandler',
                                    style: "padding: 0em; text-overflow: clip; text-align: left;"
                                },
                                headerAttributes: {
                                    'class': 'GridGlyphs',
                                    style: 'vertical-align: inherit;'
                                }
                            }
                            gridConfig.hasGlyphs = true;
                        }
                    } else if (colSchema.colname === 'item_thumb') {
                        col = {
                            field: 'item_thumb',
                            //title: 'item_thumb',
                            title: angular.isDefined(translations['grid_thumb']) ? translations['grid_thumb'] : ' ',
                            filterable: false,
                            editable: false,
                            //width: '38px',
                            width: vm.thumbnailSize,
                            template: `<img class="#= (data.item_thumb) ? item_thumb + ' img-grid-thumbnail' : '' !== '' ? 'img-grid-thumbnail' : 'img-grid-thumbnail-hidden' #" 
                                            src="#= (data.item_thumb) ? item_thumb : '' #?thumbnail=${vm.thumbnailSize}" 
                                            onmouseover="
                                                let translateX = -this.getBoundingClientRect().x.toFixed() / window.innerWidth * 100 + '%';
                                                let translateY = 0;
                                                let popup = this.nextElementSibling.children[0].children[0];
                                                let bottomPosition = this.getBoundingClientRect().bottom.toFixed();
                                                let leftPosition = this.getBoundingClientRect().left.toFixed();
                                                let tresholdY = this.getBoundingClientRect().y.toFixed() / (window.innerWidth - 400) * 100;

                                                if (tresholdY >= 50) {
                                                       translateY = -400 - ${parseInt(vm.thumbnailSize)} + 'px';
                                                }

                                                popup.style.transform = 'translate(' + translateX + ',' + translateY +')';
                                                popup.style.left = leftPosition + 'px';
                                                popup.style.top = bottomPosition + 'px';
                                                popup.style.display = 'block';
                                            "
                                            onmouseout="this.nextElementSibling.children[0].children[0].style.display = 'none'"
                                            style="aspect-ratio: 1 / 1;
                                            width: ${vm.thumbnailSize};" 
                                        />
                                        <div style="position: fixed; z-index: 1000000000000000000; top: 0; left: 0; height: 100vh; width: 100vw; pointer-events: none;">
                                            <div style="position: relative; height: 100%; width: 100%;">
                                                <div class="item-thumb-popup" onmouseover="this.style.display = 'block';" onmouseout="this.style.display = 'none'" style="z-index: 10000000000000000; display: none; position: absolute; width: 400px; height: 400px; pointer-events: all;">
                                                    <img src="#= (data.item_thumb) ? item_thumb : '' #?thumbnail=400px" style="aspect-ratio: 1 / 1; width: 400px;" />
                                                </div>
                                            </div>
                                        </div>`,
                            attributes: {
                                'class': 'CellClickHandler',
                                style: "padding: 0em; text-overflow: clip; text-align: left;"
                            },
                            headerAttributes: {
                                style: 'vertical-align: inherit;'
                            }
                        }
                        gridConfig.hasThumb = true;
                    } else if (colSchema.colname === 'item_state' || colSchema.colname === 'item_parms' || colSchema.colname === 'item_path') {
                        if (!gridConfig.hasPath) {
                            col = {
                                field: 'grid_functions',
                                title: angular.isDefined(translations['grid_functions']) ? translations['grid_functions'] : ' ',
                                command: [{ name: 'gridGoToFunc', template: makeBtnTemplate('gridGoToFunc', undefined, hasItemPathColumn), click: function (e) { goGridFunc(e, 'goTo') } }],
                                filterable: false,
                                width: '38px',
                                attributes: {
                                    // BJS 20221215 - Modifed 4px to 3px because with 2 buttons it was too wide and the buttons were on separate lines
                                    style: 'padding: 0.2em 3px 0.2em 0em;'
                                },
                                headerAttributes: {
                                    style: 'vertical-align: inherit;'
                                }
                            }

                            if (angular.isObject(vm.ttOptions.config.specialFunc) && angular.isDefined(vm.ttOptions.config.specialFunc.newTab) && vm.ttOptions.config.specialFunc.newTab === true) {
                                col.command.push({ name: 'gridGoTabFunc', template: makeBtnTemplate('gridGoTabFunc', 'goTab', hasItemPathColumn), click: function (e) { goGridFunc(e, 'goTab') } });
                            }

                            gridConfig.hasPath = true;
                        }
                    } else {
                        var title = angular.isDefined(colSchema.title) ? colSchema.title : colSchema.colname;
                        var type = identifyType(null, colSchema.coltype);
                        var format = getFormat(colSchema);
                        var editor = null;
                        var editable = false;
                        let clickOnly = false;
                        var attributes = { style: 'text-align: ' + colSchema.align + ';' };
                        var headerAttributes = { style: 'text-align: ' + colSchema.align + ';' };
                        var template = null;
                        var headerTemplate = null;
                        var footerTemplate = null;
                        var filterable = {};

                        if (angular.isDefined(colSchema.editable)) {
                            let foundColIndex = vm.ttOptions.config.editColumns.findIndex((col) => col === colSchema.colname && colSchema.editable !== true);

                            if (foundColIndex > -1) {
                                vm.ttOptions.config.editColumns.splice(foundColIndex, 1); // Remove the element at the found index
                            }
                            //console.log(colSchema.colname, ' is found: ', editCol);

                            if (colSchema.editable === true) {
                                vm.ttOptions.config.editColumns.push(colSchema.colname);
                            } else {
                                if (angular.isObject(colSchema.editable)) {
                                    vm.ttOptions.config.editColumns.push(colSchema.editable);
                                }
                            }
                        }

                        if (angular.isDefined(vm.ttOptions.config.editColumns)) {
                            for (var e = 0; e < vm.ttOptions.config.editColumns.length; e++) {
                                var ec = vm.ttOptions.config.editColumns[e];
                                var eKey = angular.isDefined(ec.key) ? ec.key : ec;
                                if (eKey === colSchema.colname) {
                                    gridConfig.hasEditable = true;
                                    editable = true;
                                    if (ec?.clickonly) clickOnly = ec.clickonly;
                                    if (type === 'boolean') {
                                        editor = function cbEditor(container, options) {
                                            if (angular.isUndefined(container)) return;
                                            //$('<input id="' + options.model.uid + '" type="checkbox" class="im-grid-checkbox cbClickHandler" name="' + colSchema.colname + '" data-type="boolean" data-bind="checked:' + options.field +'"/>').appendTo(container);
                                            $('<input id="' + options.model.uid + '" type="checkbox" class="im-grid-checkbox" name="' + colSchema.colname + '" data-type="boolean" data-bind="checked:' + options.field + '"/>').appendTo(container);
                                        };
                                        gridConfig.hasBoolean = true;
                                    } else if (ec?.lookup) {
                                        editor = function colEditor(container, options) { lookupCellEditor(container, options, ec, colSchema); };
                                    } else if (type === 'number') {
                                        editor = function numericEditor(container, options) {
                                            let deci = colSchema.decimals && colSchema.decimals !== '' ? colSchema.decimals : '0';
                                            $('<input data-bind="value:' + options.field + '"/>')
                                                .appendTo(container)
                                                .kendoNumericTextBox({
                                                    format: 'n' + deci,
                                                    decimals: deci
                                                });
                                        }
                                    } else if (ec?.popover) {
                                        console.log('test popover');
                                        //editor = function colEditor(container, options) { lookupCellEditor(container, options, ec, colSchema); };
                                    }
                                    break;
                                }
                            }
                        }

                        fields[colSchema.colname] = {
                            from: colSchema.colname,
                            type: type,
                            editable: editable,
                            editType: colSchema.edittype,
                            select: null
                        };

                        // BJS 20220912
                        if (colSchema.edittype === 'DD') {
                            fields[colSchema.colname].select = {
                                data: colSchema.dd_data,
                                id: colSchema.dd_data_id,
                                name: colSchema.dd_data_name
                            };
                        }
                        if (angular.isDefined(vm.ttOptions.kendo.aggregate)) {
                            if (vm.ttOptions.kendo.aggregate === true) {
                                if (colSchema.coltype === 'decimal' && colSchema.width === 17) {
                                    footerTemplate = getFooterTemplate('sum', colSchema.decimals);

                                    aggregate.push({ field: colSchema.colname, aggregate: 'sum', template: footerTemplate });
                                }
                            } else {
                                var mustSetTemplate = function (aggregate) {
                                    if (angular.isUndefined(aggregate.template)) return true;
                                    if (aggregate.template === null) return true;
                                    if (angular.isString(aggregate.template) !== true) return true;

                                    return aggregate.template.length < 1;
                                };

                                let isAutoSum = false;

                                if (vm.ttOptions.kendo.aggregate !== false) {
                                    if (colSchema.coltype === 'decimal' && colSchema.width === 17) {
                                        footerTemplate = getFooterTemplate('sum', colSchema.decimals);

                                        aggregate.push({ field: colSchema.colname, aggregate: 'sum', template: footerTemplate });
                                        isAutoSum = true;
                                    }
                                }

                                if (!isAutoSum) {
                                    for (var a = 0; a < vm.ttOptions.kendo.aggregate.length; a++) {
                                        if (vm.ttOptions.kendo.aggregate[a].field !== colSchema.colname)
                                            continue;

                                        if (mustSetTemplate(vm.ttOptions.kendo.aggregate[a]) === true) {
                                            vm.ttOptions.kendo.aggregate[a].template = getFooterTemplate(vm.ttOptions.kendo.aggregate[a].aggregate);
                                        }

                                        footerTemplate = vm.ttOptions.kendo.aggregate[a].template;

                                        //if (!['sum', 'min', 'max', 'count', 'average'].includes(vm.ttOptions.kendo.aggregate[a].aggregate)) {
                                        //vm.ttOptions.kendo.aggregate[a].aggregate = 'sum';
                                        //}

                                        const aggregateType = ['sum', 'min', 'max', 'count', 'average'].includes(vm.ttOptions.kendo.aggregate[a].aggregate) ? vm.ttOptions.kendo.aggregate[a].aggregate : 'sum';

                                        aggregate.push({ field: colSchema.colname, aggregate: aggregateType, template: footerTemplate });
                                        //aggregate.push(vm.ttOptions.kendo.aggregate[a]);
                                        break;
                                    }
                                }
                            }
                        }

                        let gotoClickClass = '';

                        if (vm.ttOptions.config?.specialFunc?.cellBtns?.length > 0) {
                            let cBtns = vm.ttOptions.config.specialFunc.cellBtns;
                            for (let c = 0; c < cBtns.length; c++) {
                                if (colSchema.colname === cBtns[c].name) {
                                    gotoClickClass = ' tt-cell-goto';
                                    break;
                                }
                            }
                        }

                        //if (angular.isUndefined(gridConfig.columnFormatType[colSchema.colname]))

                        gridConfig.columnFormatType[colSchema.colname] = type;

                        var noWrap =
                            angular.isDefined(vm.ttOptions.config.css) &&
                                angular.isDefined(vm.ttOptions.config.css.textWrapping) &&
                                vm.ttOptions.config.css.textWrapping === true ? '' : 'nowrap';

                        let defaultClass = '';

                        switch (type) {
                            case 'number':
                                defaultClass = 'table-cell CellClickHandler' + gotoClickClass;
                                attributes = { 'class': defaultClass, style: 'text-align: ' + colSchema.align + ';' };
                                //attributes = { 'class': 'table-cell CellClickHandler', style: 'text-align: ' + colSchema.align + ';', type: 'number', pattern: '[0-9]*', inputmode: 'decimal' };
                                filterable = {
                                    cell: {
                                        operator: "gte",
                                        template: function (args) {
                                            args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                            args.element.css("font-size", vm.fontSize);
                                        }
                                    }
                                };
                                if (editor !== null) col.editor = editor;
                                break;
                            case 'date':
                                defaultClass = (noWrap === '' ? '' : (noWrap + ' ')) + 'CellClickHandler' + gotoClickClass;
                                attributes = { 'class': defaultClass, style: 'text-align: ' + colSchema.align + ';' };
                                filterable = {
                                    cell: {
                                        operator: "gte",
                                        template: function (args) {
                                            args.element.kendoDatePicker({
                                                format: "dd/MM/yyyy"
                                            });
                                            args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                            args.element.css("font-size", vm.fontSize);
                                        }
                                    }
                                };
                                if (editor !== null) col.editor = editor;
                                break;
                            case 'boolean':
                                attributes = { 'class': 'table-cell cbClickHandler', style: 'text-align: ' + colSchema.align + ';' };
                                filterable = {
                                    messages: {
                                        isTrue: '',
                                        isFalse: ''
                                    }
                                };
                                //template = '<input id="' + options.model.uid + '" type="checkbox" class="im-grid-checkbox" name="' + colSchema.colname + '" data-type="boolean" data-bind="checked:' + options.field + '"/>';
                                //template = '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox" name="' + colSchema.colname + '" data-type="boolean" data-bind="#= ' + colSchema.colname + ' #" ng-disabled="' + !editable + '"/>';
                                //template = '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox cbClickHandler" ng-disabled="' + !editable + '" />';
                                template = '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox" ng-disabled="' + !editable + '" />';
                                headerTemplate = '<input type="checkbox" id="' + colSchema.colname + '" class="im-grid-checkbox" ng-click="vm.checkboxBoxClickAll($event, this)" ng-disabled="' + !editable + '" /> ' + title;
                                //if (editable) {
                                //    col.editable = {
                                //        mode: 'incell',
                                //        template: '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox" />'
                                //    };
                                //}
                                //col.editable = editor;
                                if (editor !== null) col.editor = editor;
                                //console.dir(col.editable);
                                //col.editable.template = '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox" />';
                                //col.editable.template = '<input type="checkbox" #= ' + colSchema.colname + ' ? \'checked=true\' : "" # class="im-grid-checkbox" ng-click="vm.checkboxBoxClick($event)" ng-disabled="' + !editable + '" />';
                                boolCols.push(colSchema.colname);
                                break;
                            default:
                                defaultClass = (noWrap === '' ? '' : (noWrap + ' ')) + 'table-cell CellClickHandler' + gotoClickClass;
                                attributes = { 'class': defaultClass, style: 'text-align: ' + colSchema.align + ';' };
                                filterable = {
                                    cell: {
                                        operator: "contains",
                                        suggestionOperator: "contains",
                                        template: function (args) {
                                            args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                            args.element.css("font-size", vm.fontSize);
                                        }
                                    }
                                };
                                if (editor !== null) col.editor = editor;
                                break;
                        }

                        if (angular.isDefined(colSchema.style_script) && colSchema.style_script !== null && colSchema.style_script !== '') {
                            //attributes.style += ' ' + colSchema.style_script + ';';
                            //let func = new Function ([arg1, arg2, ...argN], functionBody);
                            //new Function('a', 'b', 'return a + b'); // basic syntax
                            //new Function('a,b', 'return a + b'); // comma-separated
                            //new Function('a , b', 'return a + b'); // comma-separated with spaces
                            col.styleFunc = new Function('row', colSchema.style_script);
                            gridConfig.hasStyleFunc = true;
                            //col.styleFunc = colSchema.style_script;
                        }

                        //if (type === 'number') {
                        //    //attributes.style += ' ' + 'background-color: rgba(243, 23, 0, 0.32)' + ';';
                        //    attributes.style += ' ' + 'background-color: \\#68EB04AA' + ';';                    //Needs \\ to escape #
                        //}

                        //if (angular.isDefined(currentCol.filter) && angular.isDefined(fltr.cell)) {
                        //    if (angular.isDefined(currentCol.filter.operator)) fltr.cell.operator = currentCol.filter.operator;
                        //    if (angular.isDefined(currentCol.filter.suggestionOperator)) fltr.cell.suggestionOperator = currentCol.filter.suggestionOperator;
                        //    //if (angular.isDefined(currentCol.filter.value)) fltr.value = currentCol.filter.value;
                        //}

                        col.field = colSchema.colname;
                        col.title = title;
                        col.attributes = attributes;
                        col.headerAttributes = headerAttributes;
                        col.hidden = colSchema.visibility === 'hidden'; // BJS 20220310
                        col.isEditable = editable;
                        col.isClickOnly = clickOnly;

                        if (format !== null) col.format = format;
                        if (template !== null) col.template = template;
                        if (headerTemplate !== null) col.headerTemplate = headerTemplate;
                        if (footerTemplate !== null) col.footerTemplate = footerTemplate;
                        if (filterable !== null) col.filterable = filterable;

                        // BJS 20220310
                        if (angular.isDefined(colSchema.colwidth) && colSchema.colwidth !== null) {
                            col.width = colSchema.colwidth + 'px';
                        }

                        if (colSchema.filterable !== null) {
                            col.filterable = colSchema.filterable === '1';
                        }

                        if (colSchema.sortable !== null) {
                            col.sortable = colSchema.sortable === '1';
                        }
                    }

                    if (angular.isDefined(col.field)) {
                        if (vm.fontSize !== null) {
                            col.attributes.style += 'font-size: ' + vm.fontSize + ';';
                            col.headerAttributes.style += 'font-size: ' + vm.fontSize + ';';
                            col.headerAttributes.style += ' font-weight: 700;';
                        }

                        if (angular.isDefined(userSettings.columns[colSchema.colname])) {
                            if (angular.isDefined(userSettings.columns[colSchema.colname].width)) {
                                col.width = userSettings.columns[colSchema.colname].width + 'px';
                            }

                            if (angular.isDefined(userSettings.columns[colSchema.colname].hidden)) {
                                col.hidden = userSettings.columns[colSchema.colname].hidden;
                            }
                        }

                        columns.push(col);
                    }
                });

                // BJS 20220313 - Added posibility for setting default column order
                //                from stored procedure
                if (schema.length > 0 && angular.isDefined(schema[0].colorder)) {
                    var referenceOrder = {};
                    var orderIndex = 10000;

                    angular.forEach(schema, function (value, key) {
                        referenceOrder[value.colname] = value.colorder !== null ? value.colorder : orderIndex;
                        orderIndex++;
                    });

                    columns.sort(function (a, b) {
                        return referenceOrder[a.field] - referenceOrder[b.field];
                    });
                }

                if (angular.isDefined(userSettings.columns) && angular.isObject(userSettings.columns)) {
                    var referenceOrder = {};
                    var orderIndex = 0;

                    angular.forEach(userSettings.columns, function (value, key) {
                        referenceOrder[key] = angular.isDefined(value.order) ? value.order : orderIndex;
                        orderIndex++;
                    });

                    columns.sort(function (a, b) {
                        return referenceOrder[a.field] - referenceOrder[b.field];
                    });
                }

                if (gridConfig.hasGlyphs) {
                    var first = 'grid_glyphs';
                    columns.sort(function (x, y) { return x.field === first ? -1 : y.field === first ? 1 : 0; }); // Makes sure the column is first
                }

                if (gridConfig.hasPath) {
                    if (columns[columns.length - 1].field !== 'grid_functions') {
                        for (var s = 0; s < columns.length; s++) {
                            if (columns[s].field === 'grid_functions') {
                                columns.push(columns.splice(s, 1)[0]); // Makes sure the column is last
                                break;
                            }
                        }
                    }
                }

                if (gridConfig.hasSpecialFunc) {
                    if (columns[columns.length - 1].field === 'grid_functions') {
                        angular.forEach(vm.ttOptions.config.specialFunc.buttons, function (value, key) {
                            if (angular.isDefined(value.name) && value.type !== 'cell') {
                                if (angular.isFunction(value.disabled)) gridConfig.canHideSpecialFunc = true;
                                columns[columns.length - 1].command.push({ name: value.name, template: makeBtnTemplate(value.name, value.icon, undefined, value.type, value.style), click: function (e) { goGridFunc(e, key) } });
                            }
                        });

                        // BJS 20220909
                        if (gridConfig.useSpecialFuncEdit === true) {
                            columns[columns.length - 1].command.push({ name: 'editModal', template: makeBtnTemplate('editModal', 'far-pencil', false), click: function (e) { showEditModal(e, true) } });
                        }
                    } else {
                        var commandList = [];

                        angular.forEach(vm.ttOptions.config.specialFunc.buttons, function (value, key) {
                            if (angular.isDefined(value.name) && value.type !== 'cell') {
                                if (angular.isFunction(value.disabled)) gridConfig.canHideSpecialFunc = true;

                                commandList.push({ name: value.name, template: makeBtnTemplate(value.name, value.icon, undefined, value.type, value.style), click: function (e) { goGridFunc(e, key); } });
                            }
                        });

                        // BJS 20220909
                        if (gridConfig.useSpecialFuncEdit === true) {
                            commandList.push({ name: 'editModal', template: makeBtnTemplate('editModal', 'far-pencil', false), click: function (e) { showEditModal(e, true) } });
                        }

                        columns.push({
                            field: 'grid_functions',
                            title: angular.isDefined(translations['grid_functions']) ? translations['grid_functions'] : ' ',
                            command: commandList,
                            filterable: false,
                            width: '38px',
                            attributes: {
                                // BJS 20221215 - Modifed 4px to 3px because with 2 buttons it was to wide and the buttons were on separate lines
                                style: 'padding: 0.2em 3px 0.2em 0em;' + (vm.fontSize !== null ? 'font-size: ' + vm.fontSize + ';' : '')
                            },
                            headerAttributes: {
                                style: 'vertical-align: inherit;' + (vm.fontSize !== null ? 'font-size: ' + vm.fontSize + ';' : '')
                            }
                        });
                    }
                }

                var k = vm.ttOptions.kendo;

                if (angular.isDefined(k) && angular.isDefined(k.selectable) && (k.selectable === true || (typeof k.selectable === 'string' && (k.selectable.indexOf('row') > -1 || k.selectable.indexOf('multiple') > -1)))) {
                    var selectColKey = 'grid_select_col';
                    var selectCol = {
                        field: selectColKey,
                        title: ' ',
                        filterable: false,
                        selectable: true,
                        resizable: false,
                        reorderable: false,
                        width: "34px",
                        sortable: false,
                        attributes: {
                            style: "padding: 0.2em 0em 0em 0.6em"
                        }
                    }

                    columns.unshift(selectCol);

                    fields[selectColKey] = { from: selectColKey };
                }

                // BJS 20220318 - Added onSetupAsync
                var resolve = function () {
                    generateGridToolbar(userTranslations);

                    linkGridFunctions();

                    var ssh = getServerSideHandling();

                    var delayRequest = true;
                    var delayPromise = null;

                    var delayedRequest = function () {
                        delayPromise = null;
                        delayRequest = false;

                        vm.ttOptions.gridfunc.read();

                        delayRequest = true;
                    };

                    var dataSourceOptions = {
                        requestStart: function (e) {
                            // BJS 20220501 - When using serverside filtering we need to delay sending the request until the user has finished typing.
                            if (ssh.enabled !== true) return;
                            if (angular.isUndefined(vm.grid)) return;
                            if (angular.isUndefined(vm.grid.dataSource)) return;
                            if (delayRequest !== true) return;

                            if (delayPromise !== null) {
                                $timeout.cancel(delayPromise);
                            }

                            delayPromise = $timeout(delayedRequest, 350);

                            e.preventDefault();
                        },
                        transport: {
                            read: function (opt) {
                                // BJS 20220430 - Modified to be able to handle server side paging, sort and filtering.
                                var parms = getLoadDataParms();

                                if (ssh.enabled === true && angular.isObject(parms)) {
                                    parms.ttGridOptions = opt.data;
                                }

                                ttGridService.loadData(getLoadDataMethod(), parms).then(function (data) {
                                    var processData = function (pd) {
                                        angular.forEach(pd, function (item) {
                                            angular.forEach(boolCols, function (bc) {
                                                if (angular.isDefined(item[bc])) {
                                                    if (item[bc] === true || item[bc] === 'true' || item[bc] === '1' || item[bc] === 1) {
                                                        item[bc] = true;
                                                    } else {
                                                        item[bc] = false;
                                                    }
                                                }
                                            });
                                        });
                                    };

                                    if (ssh.enabled === true) {
                                        processData(data[ssh.data]);
                                    } else {
                                        processData(data);
                                    }

                                    opt.success(data);
                                });
                            }
                        },
                        change: function (e) {
                            updateSortUserSettings(e.sender._sort);
                            updateFilterUserSettings(e.sender._filter);
                            //if (aggregate.length > 0) updateAggregates();

                            if (angular.isFunction(vm.ttOptions.config.onDataSourceChanges)) {
                                vm.ttOptions.config.onDataSourceChanges(e);
                            }
                        },
                        aggregate: aggregate,
                        schema: {
                            model: {
                                fields: fields
                            },
                            parse: function (data) {
                                // BJS 20221012
                                if (angular.isObject(vm.ttOptions.kendo) !== true) return data;
                                if (angular.isObject(vm.ttOptions.kendo.datasource) !== true) return data;
                                if (angular.isObject(vm.ttOptions.kendo.datasource.schema) !== true) return data;
                                if (angular.isFunction(vm.ttOptions.kendo.datasource.schema.parse) !== true) return data;

                                angular.isFunction(vm.ttOptions.kendo.datasource.schema.parse(data));

                                return data;
                            }
                        },
                        pageSize: userSettings.pageSize,
                        page: userSettings.page,
                        //page: function (e) {
                        //    return userSettings.page;
                        //},
                        sort: getSortUserSettings(),
                        filter: getFilterUserSettings(schema)
                    };

                    // BJS 20220429
                    if (ssh.enabled === true) {
                        dataSourceOptions.schema.data = ssh.data;
                        dataSourceOptions.schema.total = ssh.total;
                        dataSourceOptions.serverPaging = true;
                        dataSourceOptions.serverFiltering = true;
                        dataSourceOptions.serverSorting = true;
                    }

                    var ttOptions = {
                        dataSource: new kendo.data.DataSource(dataSourceOptions),
                        columns: columns,
                        toolbar: gridConfig.toolbar,
                        height: angular.isDefined(vm.ttOptions.kendo.height) && vm.ttOptions.kendo.height !== null ? vm.ttOptions.kendo.height : undefined,
                        excel: {
                            allPages: true,
                            filterable: angular.isDefined(vm.ttOptions.kendo.filterable) && vm.ttOptions.kendo.filterable !== null ? false : true,
                        },
                        pdf: {
                            allPages: true,
                            filterable: angular.isDefined(vm.ttOptions.kendo.filterable) && vm.ttOptions.kendo.filterable !== null ? false : true,
                        },
                        editable: angular.isDefined(vm.ttOptions.kendo.editable) && vm.ttOptions.kendo.editable !== null ? vm.ttOptions.kendo.editable : 'incell',
                        filterable: defineFilterable(),
                        groupable: angular.isDefined(vm.ttOptions.kendo.groupable) && vm.ttOptions.kendo.groupable !== null ? vm.ttOptions.kendo.groupable : false,
                        navigatable: angular.isDefined(vm.ttOptions.kendo.navigatable) && vm.ttOptions.kendo.navigatable !== null ? vm.ttOptions.kendo.navigatable : true,
                        pageable: definePageable(translations, devModeResponse),
                        reorderable: angular.isDefined(vm.ttOptions.kendo.reorderable) && vm.ttOptions.kendo.reorderable !== null ? vm.ttOptions.kendo.reorderable : true,
                        resizable: angular.isDefined(vm.ttOptions.kendo.resizable) && vm.ttOptions.kendo.resizable !== null ? vm.ttOptions.kendo.resizable : true,
                        scrollable: angular.isDefined(vm.ttOptions.kendo.scrollable) && vm.ttOptions.kendo.scrollable !== null ? vm.ttOptions.kendo.scrollable : true,
                        selectable: angular.isDefined(vm.ttOptions.kendo.selectable) && vm.ttOptions.kendo.selectable !== null ? vm.ttOptions.kendo.selectable : true,
                        persistSelection: angular.isDefined(vm.ttOptions.kendo.persistSelection) && vm.ttOptions.kendo.persistSelection !== null ? vm.ttOptions.kendo.persistSelection : false,
                        sortable: angular.isDefined(vm.ttOptions.kendo.sortable) && vm.ttOptions.kendo.sortable !== null ? vm.ttOptions.kendo.sortable : true,
                        beforeEdit: onBeforeEdit,
                        cancel: onCancel,
                        cellClose: onCellClose,
                        change: onChange,
                        columnHide: onColumnHide,
                        columnLock: onColumnLock,
                        columnMenuInit: onColumnMenuInit,
                        columnMenuOpen: onColumnMenuOpen,
                        columnReorder: onColumnReorder,
                        columnResize: onColumnResize,
                        columnShow: onColumnShow,
                        columnStick: onColumnStick,
                        columnUnlock: onColumnUnlock,
                        columnUnstick: onColumnUnstick,
                        dataBinding: onDataBinding,
                        dataBound: onDataBound,
                        detailCollapse: onDetailCollapse,
                        detailExpand: onDetailExpand,
                        //detailInit: onDetailInit,
                        edit: onEdit,
                        excelExport: onExcelExport,
                        filter: onFilter,
                        filterMenuInit: onFilterMenuInit,
                        filterMenuOpen: onFilterMenuOpen,
                        group: onGroup,
                        groupCollapse: onGroupCollapse,
                        groupExpand: onGroupExpand,
                        navigate: onNavigate,
                        page: onPage,
                        pdfExport: onPdfExport,
                        //remove: onRemove,     // Not in use
                        save: onDataChanged,    // fires when focus is moved outside of a cell where data is changed
                        saveChanges: saveAllChanges,
                        sort: onSort
                    };

                    if (ssh.enabled === true) {
                        ttOptions.serverPaging = true;
                        ttOptions.serverSorting = true;
                        ttOptions.serverFiltering = true;
                    }

                    deferred.resolve(ttOptions);
                };

                if (angular.isFunction(vm.ttOptions.onSetup)) {
                    vm.ttOptions.onSetup(schema, fields, columns, userTranslations);
                }

                if (angular.isFunction(vm.ttOptions.onSetupAsync)) {
                    var promise = vm.ttOptions.onSetupAsync(schema, fields, columns, userTranslations);

                    if (promise && angular.isFunction(promise.then)) {
                        promise.then(resolve);
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }

                return deferred.promise;
            };

            function addIfNotExists(parent, name, value) {
                if (angular.isObject(parent) !== true) return;
                if (angular.isString(name) !== true) return;

                if (angular.isUndefined(parent[name])) {
                    parent[name] = value;
                }
            }

            var loadGrid = function () {
                var deferred = $q.defer();

                addIfNotExists(vm, 'ttOptions', {});
                addIfNotExists(vm.ttOptions, 'dataTask', {});
                addIfNotExists(vm.ttOptions.dataTask, 'rememberId', '');
                addIfNotExists(vm.ttOptions.dataTask, 'loadData', {});
                addIfNotExists(vm.ttOptions.dataTask, 'addRow', {});
                addIfNotExists(vm.ttOptions.dataTask, 'removeRow', {});
                addIfNotExists(vm.ttOptions.dataTask, 'saveData', {});
                addIfNotExists(vm.ttOptions, 'config', {});
                addIfNotExists(vm.ttOptions.config, 'toolbar', {});
                addIfNotExists(vm.ttOptions.config.toolbar, 'buttons', []);
                addIfNotExists(vm.ttOptions, 'translations', []);


                // ensure user settings are loaded before building grid
                userService.ensureIsReady().then(function () {
                    kendo.ui.progress($element, true);

                    var promises = [];

                    promises.push(ttGridService.loadSetup(getLoadSetupMethod(), getLoadDataMethod(), getLoadDataParms()));

                    vm.ttOptions.translations = addToTranslations(vm.ttOptions.translations, words);

                    angular.forEach(vm.ttOptions.config.toolbar.buttons, function (button) {
                        if (angular.isUndefined(button.translate) || button.translate !== false) {
                            if (angular.isDefined(button.text) && button.text !== '') wordlang[button.text] = '';
                        }
                    });

                    angular.forEach(vm.ttOptions.config.toolbar.toggles, function (toggle) {
                        if (angular.isUndefined(toggle.translate) || toggle.translate !== false) {
                            angular.forEach(toggle.states, function (state) {
                                if (angular.isUndefined(state.translate) || state.translate !== false) {
                                    //if (angular.isDefined(state.text) && state.text !== '') wordlang.push(state.text);
                                    if (angular.isDefined(state.text) && state.text !== '') wordlang[state.text] = '';
                                }
                            });
                        }
                    });

                    // BJS 20220327
                    // wordlang is updated in function "splitTranslations" after being translated
                    angular.forEach(wordlang, function (_, key) {
                        vm.ttOptions.translations.push(key);
                    });

                    promises.push(translateService.translateBatch(vm.ttOptions.translations));

                    var remembering = canRemember();

                    if (remembering === true) {
                        promises.push(ttGridService.getRemember(null, vm.ttOptions.dataTask.rememberId));
                    }

                    if (userService.developMode === true) {
                        promises.push(ttGridService.getLoadMethodName(getLoadDataMethod()));
                    }

                    $q.all(promises).then(function (response) {

                        updateUserSettings(remembering, response);

                        let devModeResponse = null;

                        if (userService.developMode === true) {
                            devModeResponse = response[response.length - 1];
                        }

                        buildOptions(response[0], response[1], devModeResponse).then(function (options) {
                            vm.options = options;
                            vm.response = response;

                            // run on next digest cycle
                            $timeout(function () {
                                // stopping focuslock when clicking to select number of items per page.
                                // restarts focuslock after. These events are handled in ttInput component.
                                var element = $('#' + vm.id + ' .k-grid-pager .k-pager-sizes .k-dropdown')

                                element.click(function () {
                                    eventService.trigger('event:focuslock:stop');
                                });

                                element.blur(function () {
                                    eventService.trigger('event:focuslock:start');
                                });

                                $('#' + vm.id + ' .k-grid-pager .k-pager-sizes .k-dropdown select').change(function (e) {
                                    eventService.trigger('event:focuslock:start');
                                });

                                if (Object.keys(vm.ttOptions.dataTask.loadData).length === 0) {
                                    $(".k-loading-image").hide();
                                }

                                deferred.resolve();
                            });
                        });
                    }, function (error) {
                        kendo.ui.progress($element, false);

                        console.log('error! error! error! error! error!');

                        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                            console.log(error.data.message);

                            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                                console.log(error.data.data.message);
                            }
                        } else {
                            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                                console.log(error.data.data.message);
                            }
                        }

                        console.dir(error);

                        deferred.reject(error);
                    });
                });

                return deferred.promise;
            };

            var rebindGrid = function () {
                var deferred = $q.defer();

                //console.log('rebinding');

                loadGrid().then(function () {
                    // Any change of vm.rebind triggers rebind. Toggles between true and false to start rebind.
                    vm.rebind = !vm.rebind;

                    deferred.resolve();
                });

                return deferred.promise;
            };

            var readGridData = function () {
                // kendo-grid="id" or k-scope-name="id" does not seem to work with a component without $scope.
                // Using jquery to get dataSource instead.
                return vm.grid.dataSource.read();
            };

            // ###############################
            // #endregion grid
            // ###############################

            // ###############################
            // #region gridfunc
            // ###############################

            var linkGridFunctions = function () {
                if (angular.isDefined(vm.ttOptions.gridfunc)) {
                    vm.ttOptions.gridfunc = {
                        refresh: function () { vm.grid.refresh(); },
                        read: readGridData,
                        refreshToolbarBtnDisability: toolbarBtnDisability,
                        refreshAggregates: updateAggregates,
                        callPopupTable: function (data) { return callPopupTable(data); },
                        setFocusToCell: function (rowIndex, colIndex) { vm.grid.current(vm.grid.tbody.children().eq(rowIndex).children().eq(colIndex)); },
                        editCell: function (rowIndex, colIndex) { editCell(rowIndex, colIndex); },
                        clearFilter: clearFilter,
                        clearSorting: function () { clearSorting(); },
                        addRowBefore: function (atIndex, dataItem) { return gridAddRowBefore(atIndex, dataItem); },
                        addRowAfter: function (atIndex, dataItem) { return gridAddRowAfter(atIndex, dataItem); },
                        getColumnFormatType: function (key) { return angular.isDefined(key) ? gridConfig.columnFormatType[key] : gridConfig.columnFormatType },
                        getResponse: function (index) { return angular.isDefined(index) ? vm.response[index] : vm.response },
                        getResponseColumns: function () { return vm.response[0] },
                        updateRow: singleRowUpdate,
                        removeRow: gridRemoveRow,
                        removeRows: gridRemoveRows, // removes all selected rows
                        getDirtyRows: function () { return getDirtyRows(); },
                        getSelectedRow: function () { return vm.grid.dataItem(vm.grid.select()); }, // BJS 20230121 - Gets currently selected row(s). Not using is_selected column or native isselected column.
                        getSelectedRows: function () { return getSelectedRows(); },
                        getAllRows: function () { return vm.grid.dataSource.data(); },
                        getRows: getRows, //function (dirty, sorted) { return getRows(dirty, sorted); },
                        getIsSelectedRows: getIsSelectedRows, //function (dirty, filtered, sorted, viewOnly) { return getRows(dirty, filtered, sorted, viewOnly); }, // EO/RHE 20240905 - Gets currently selected row(s), Using is_selected column.
                        getRowAt: function (index) { return getRowAt(index); },
                        getGrid: function () { return vm.grid; },
                        getDataItem: function (atIndex) { return angular.isDefined(atIndex) ? vm.grid.dataItem('tbody tr:eq(' + atIndex + ')') : vm.grid.dataItem("tbody tr:eq(0)"); },
                        getDataItems: function () { return vm.grid.dataItems(); }, //SAME AS getAllRows
                        //setDataItem: function (atIndex) { return angular.isDefined(atIndex) ? vm.grid.dataItem('tbody tr:eq('+atIndex+')') : vm.grid.dataItem("tbody tr:eq(0)"); },
                        getDataSource: function () { return vm.grid.dataSource; },
                        setDataSource: function (newDataSource) { vm.grid.dataSource.fetch(() => vm.grid.dataSource.data(newDataSource)).then(() => scrollFixed()); },
                        getGridColumns: function () { return vm.grid.columns; },
                        getColumnSchema: function () { return vm.grid.dataSource.options.schema.model.fields; },
                        viewMatching: viewMatching,
                        saveChanges: function (showProgress) { saveAllChanges(showProgress) },
                        rebind: rebindGrid,
                        gridProgress: function (spin) { kendo.ui.progress($element, angular.isDefined(spin) ? spin : false); },
                        selectRow: function (index) { selectRow(index); },
                        isReady: function () { return angular.isDefined(vm.grid) && vm.grid !== null; },
                        hasRows: function () { return vm.grid.dataSource.data().length > 0; },
                        resize: () => {
                            resizeFixed();
                            scrollFixed();
                        },

                        /**
                         * Redraws the row with the given data item.
                         *
                         * @param {Object} dataItem the data item to redraw the grid row for.
                         */
                        redrawRow: kendoFastReDrawRow,
                        test: function (p1, p2) { console.log('bjs func test ok: ' + p1 + ' - ' + p2); return 'efgh'; }
                    };
                }
            };

            var getRowAt = function (index) {
                let rows = vm.grid.dataSource.data();

                return rows.length > index
                    ? rows[index]
                    : null;
            };

            var selectRow = function (index) {
                let rows = vm.grid.dataSource.data();

                if (rows.length > index) {
                    let row = vm.grid.table.find("[data-uid=" + rows[index].uid + "]");

                    vm.grid.select(row);
                }
            };

            var callPopupTable = function (data) {
                var deferred = $q.defer();
                var popupTable = modalPopupTableFactory.$create({
                    list: data || []
                });

                popupTable.show().then(function (value) {
                    deferred.resolve(value.selectedItem);
                });

                return deferred.promise;
            };

            let getRows = function (dirty = false, filtered = false, sorted = false, viewOnly = false) {
                let allRows = viewOnly === true ? vm.grid.dataSource.view() : vm.grid.dataSource.data();
                let rows;

                if (dirty) {
                    rows = [];
                    angular.forEach(allRows, function (dataItem) {
                        if (dataItem.dirty === true) rows.push(dataItem);
                    });
                } else {
                    rows = allRows;
                }

                if (filtered) {
                    let filters = vm.grid.dataSource.filter();
                    let query = new kendo.data.Query(rows);
                    if (filters) rows = query.filter(filters).data;
                }

                if (sorted) {
                    let sort = vm.grid.dataSource.sort();
                    let query = new kendo.data.Query(rows);
                    if (sort) rows = query.sort(sort).data;
                }

                return rows;
            };

            var getDirtyRows = function () {
                var dirty = [];

                angular.forEach(vm.grid.dataSource.data(), function (dataItem) {
                    if (dataItem.dirty === true) dirty.push(dataItem);
                });

                return dirty;
            };

            var updateSelectedRows = function () {
                var rows = vm.grid.select();

                gridConfig.selectedRows.index = [];
                gridConfig.selectedRows.data = [];

                rows.each(function (r) {
                    var dataItem = vm.grid.dataItem(this);
                    var index = vm.grid.dataSource.indexOf(dataItem);

                    gridConfig.selectedRows.index.push(index);
                    gridConfig.selectedRows.data.push(dataItem);
                });

                return gridConfig.selectedRows;
            };

            var getSelectedRows = function () {
                updateSelectedRows();

                return gridConfig.selectedRows.data;
            };

            let getIsSelectedRows = function (dirty = false, filtered = false, sorted = false, viewOnly = false) {
                let isSelectedRows = [];

                angular.forEach(getRows(dirty, filtered, sorted, viewOnly), function (row) {
                    if (row && row.is_selected === true) isSelectedRows.push(row);
                });

                return isSelectedRows;
            };

            var getFirstSelectedRow = function () {
                var rows = vm.grid.select();

                if (rows.length < 1)
                    return null;

                rows.each(function (r) {
                    var dataItem = vm.grid.dataItem(this);
                    var index = vm.grid.dataSource.indexOf(dataItem);

                    return { index: index, data: dataItem };
                });
            };

            var editCell = function (r, c) {
                if (angular.isUndefined(r)) return;

                //console.log('row: ', r, ' col: ', c);
                //console.dir(vm.grid);
                //console.dir(vm.grid.dataSource.pageSize());
                //console.dir(vm.grid.tbody.children());
                //console.dir(vm.grid.tbody.children().eq(r));
                //console.dir(vm.grid.columns);

                //IF C IS UNDEFINED, FIND FIRST EDITABLE AND VISIBLE CELL
                if (angular.isUndefined(c)) {
                    //let canEdit = false;
                    //let i = 0;

                    for (let i = 0; i < vm.grid.columns.length; i++) {
                        if (vm.grid.columns[i].hidden || !vm.grid.columns[i].isEditable) continue;

                        //canEdit = true;
                        vm.grid.editCell(vm.grid.tbody.children().eq(r).children().eq(i));
                        break;
                    }

                    //if (canEdit) {
                    //}
                } else {
                    if (r < 0 || angular.isUndefined(vm.grid.tbody.children().eq(r)[0])) {
                        if (angular.isDefined(vm.ttOptions.config.navigation) && angular.isDefined(vm.ttOptions.config.navigation.newLine) && vm.ttOptions.config.navigation.newLine === true) {
                            if (r < 0) {
                                gridAddRowBefore(0);
                                vm.grid.editCell(vm.grid.tbody.children().eq(0).children().eq(c));
                            } else {
                                if (r >= vm.grid.dataSource.pageSize()) {
                                    r = vm.grid.dataSource.pageSize() - 1;
                                    gridAddRowBefore(r);
                                } else {
                                    gridAddRowAfter(r - 1);
                                }
                                vm.grid.editCell(vm.grid.tbody.children().eq(r).children().eq(c));
                            }
                        }
                    } else {
                        vm.grid.editCell(vm.grid.tbody.children().eq(r).children().eq(c));
                    }
                }
            };

            var findNextEditCell = function (startingCellIndex, next) {
                if (angular.isUndefined(startingCellIndex)) return;

                if (angular.isUndefined(next)) next = true;

                let direction = next === true ? 1 : -1;
                let condition = next === true ? function (i) {
                    return i < vm.grid.columns.length;
                } : function (i) {
                    return i >= 0;
                };
                let canEdit = false;
                let nextIndex = startingCellIndex;
                let rowStep = 0;

                for (let i = startingCellIndex + direction; condition(i); i += direction) {
                    if (vm.grid.columns[i].hidden || !vm.grid.columns[i].isEditable || vm.grid.columns[i].isClickOnly) continue;

                    nextIndex = i;
                    canEdit = true;
                    break;
                }

                if (!canEdit) {
                    if (direction > 0) {
                        for (let i = 0; condition(i); i += direction) {
                            if (vm.grid.columns[i].hidden || !vm.grid.columns[i].isEditable || vm.grid.columns[i].isClickOnly) continue;

                            nextIndex = i;
                            rowStep++;
                            canEdit = true;
                            break;
                        }
                    } else {
                        for (let i = vm.grid.columns.length - 1; condition(i); i += direction) {
                            if (vm.grid.columns[i].hidden || !vm.grid.columns[i].isEditable || vm.grid.columns[i].isClickOnly) continue;

                            nextIndex = i;
                            rowStep--;
                            canEdit = true;
                            break;
                        }

                    }
                }

                return { nextIndex: nextIndex, rowStep: rowStep };
            };

            var shouldConfirmRemoveRow = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.removeRow)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.removeRow.confirm)) return false;

                return vm.ttOptions.dataTask.removeRow.confirm === true;
            };

            var shouldAutoSaveRemoveRow = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.removeRow)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.removeRow.autoSave)) return false;

                return vm.ttOptions.dataTask.removeRow.autoSave === true;
            };

            var gridRemoveRow = function (dataItem) {
                var doRemoveRow = function () {
                    vm.grid.dataSource.remove(dataItem);

                    updateSelectedRows();

                    changes[dataItem.uid] = { state: 'remove', data: getRowData(angular.copy(dataItem)) };

                    if (shouldAutoSaveRemoveRow()) {
                        autoSaveState('remove');
                    }
                };

                if (shouldConfirmRemoveRow() === true) {
                    modalService.confirm({
                        type: 'warning',
                        title: wordlang.ttgrid_modal_remove_title,
                        message: wordlang.ttgrid_modal_remove_message,
                        okLabel: wordlang.ttgrid_modal_remove_ok,
                        cancelLabel: wordlang.ttgrid_modal_remove_cancel
                    }).then(doRemoveRow);
                } else {
                    doRemoveRow();
                }
            };

            var gridRemoveRows = function () {
                var doRemoveRow = async function () {
                    var rows = getSelectedRows();

                    if (rows.length < 1)
                        return;

                    angular.forEach(rows, function (row) {
                        vm.grid.dataSource.remove(row);

                        if (angular.isUndefined(changes[row.uid])) {
                            changes[row.uid] = { state: 'remove', data: getRowData(angular.copy(row)) };
                        } else {
                            if (changes[row.uid].state === 'add') {
                                delete changes[row.uid];
                            } else {
                                changes[row.uid].state = 'remove';
                            }
                        }
                    });

                    updateSelectedRows();

                    if (shouldAutoSaveRemoveRow()) {
                        await autoSaveState('remove');
                    }

                    if (vm.ttOptions.dataTask?.removeRow?.post instanceof Function) {
                        vm.ttOptions.dataTask.removeRow.post();
                    }
                };

                if (shouldConfirmRemoveRow() === true) {
                    modalService.confirm({
                        type: 'warning',
                        title: wordlang.ttgrid_modal_remove_title,
                        message: wordlang.ttgrid_modal_remove_message,
                        okLabel: wordlang.ttgrid_modal_remove_ok,
                        cancelLabel: wordlang.ttgrid_modal_remove_cancel
                    }).then(doRemoveRow);
                } else {
                    doRemoveRow();
                }
            };

            var getRow = function (atIndex, dataItem, isBefore) {
                var row = angular.isDefined(atIndex)
                    ? { index: atIndex, data: dataItem }
                    : getFirstSelectedRow();

                if (angular.isUndefined(row) || angular.isObject(row) !== true) {
                    if (isBefore) {
                        row = { index: 0, data: {} };
                    } else {
                        if (vm.grid.dataSource.data().length < vm.grid.dataSource.pageSize()) {
                            row = { index: vm.grid.dataSource.data().length - 1, data: {} };
                        } else {
                            row = { index: vm.grid.dataSource.pageSize() - 2, data: {} };
                        }
                    }
                }

                if (angular.isNumber(row.index) !== true) {
                    row.index = 0;
                }

                if (angular.isUndefined(row.data) || angular.isObject(row.data) !== true) {
                    row.data = {};
                }

                return row;
            };

            var gridAddRowBefore = function (atIndex, dataItem) {
                // Adds row before first selected row
                var row = getRow(atIndex, dataItem, true);

                gridAddRow(row.index, row.data, true);

                if (angular.isDefined(row.index) && row.index >= 0) {
                    editCell(row.index);
                }
            };

            var gridAddRowAfter = function (atIndex, dataItem) {
                // Adds row after first selected row
                var row = getRow(atIndex, dataItem, false);

                gridAddRow(row.index + 1, row.data);

                if (angular.isDefined(row.index) && row.index < vm.grid.dataSource.pageSize()) {
                    editCell(row.index + 1);
                }
            };

            var shouldAutoSaveAddRow = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return;
                if (angular.isUndefined(vm.ttOptions.dataTask.addRow)) return;
                if (angular.isUndefined(vm.ttOptions.dataTask.addRow.autoSave)) return;

                return vm.ttOptions.dataTask.addRow.autoSave === true;
            };

            var shouldConfirmAddRow = function () {
                if (angular.isUndefined(vm.ttOptions.dataTask)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.addRow)) return false;
                if (angular.isUndefined(vm.ttOptions.dataTask.addRow.confirm)) return false;

                return vm.ttOptions.dataTask.addRow.confirm === true;
            };

            let addRow = function (atIndex, dataItem, addBefore) {
                let before = angular.isDefined(addBefore) ? addBefore : true;
                let row = getRow(atIndex, dataItem, before);
                let index = before ? row.index : row.index + 1;

                gridAddRow(index, row.data);

                //if (angular.isDefined(row.index) && row.index >= 0 && row.index < vm.grid.dataSource.pageSize()) {
                //    editCell(index);
                //}
            };

            var gridAddRow = function (atIndex, dataItem, addBefore) {
                var doAddRow = function () {
                    updateSelectedRows();

                    var selectedRowsCount = gridConfig.selectedRows.index.length;
                    var insertIndex = 0;

                    if (angular.isDefined(atIndex) && isNumber(atIndex)) {
                        insertIndex = atIndex;
                    } else if (selectedRowsCount > 0) {
                        if (angular.isDefined(addBefore) && addBefore === true) {
                            insertIndex = gridConfig.selectedRows.index[selectedRowsCount - 1];
                        } else {
                            insertIndex = gridConfig.selectedRows.index[selectedRowsCount - 1] + 1;
                        }
                    } else {
                        if (angular.isDefined(addBefore) && addBefore === true) {
                            insertIndex = 0;
                        } else {
                            insertIndex = vm.grid.dataSource.data().length;
                        }
                    }

                    dataItem = angular.isDefined(dataItem) ? dataItem : {};

                    if (angular.isUndefined(vm.ttOptions.config.keepSortOnAdd) || vm.ttOptions.config.keepSortOnAdd !== true) clearSorting();

                    var preCompleted = function () {
                        var row = vm.grid.dataSource.insert(insertIndex, dataItem);

                        if (gridConfig.hasPath) {
                            row.item_state = '';
                            row.item_parms = '';
                            row.item_path = '';
                        }

                        if (gridConfig.hasThumb) {
                            row.item_thumb = '';
                        }

                        insertIndex = vm.grid.dataSource.indexOf(row);

                        // RHE 20230324 - Currently not in use, readd section once 'fixed'
                        //var td = ''; // BJS 20220925 - This value is set but the set value is never used, purpose???
                        //var fields = vm.grid.getOptions().dataSource.schema.model.fields;
                        //var columns = vm.grid.columns;
                        //var editableKeys = [];

                        //angular.forEach(fields, function (dataItem, key) {
                        //    if (angular.isDefined(dataItem.editable) && dataItem.editable === true) {
                        //        editableKeys.push(key);
                        //    }
                        //});

                        //var foundEditable = false;

                        //for (var k = 0; k < editableKeys.length; k++) {
                        //    for (var c = 0; c < columns.length; c++) {
                        //        if (columns[c].hidden !== true && columns[c].field === editableKeys[k]) {
                        //            foundEditable = true;
                        //            td = ' td:eq(' + c + ')';
                        //            break;
                        //        }
                        //    }
                        //    if (foundEditable) {
                        //        break;
                        //    }
                        //}

                        // BJS 20220422 - Kommentert vekk fordi den gir feilmelding og ifølge Robin så skal det ikke være behov for denne.
                        //var contact = vm.grid.table.find('tr[data-uid="' + row.uid + '"]' + td);

                        //vm.grid.editCell(contact);

                        changes[row.uid] = { state: 'add', data: getRowData(angular.copy(row)) };

                        var checkPost = function (response) {
                            // BJS 20220913 - Added option for running a function after adding new row.
                            if (angular.isObject(vm.ttOptions.dataTask.addRow) && angular.isFunction(vm.ttOptions.dataTask.addRow.post)) {
                                let newRow;

                                // BJS 20220926 - Tries to find the new row in the grid. The index of the inserted row might have changed because
                                //                there is i new read of the grid after the update to the database. In order to find the row in the grid
                                //                the update procedure must return values to match the row. It is fastest if the update proc only
                                //                returns the new primary key in an object with key name and value identical to the name of the key in the grid.
                                //                All returned values must match the values on a row to have a match but only if the value also exists
                                //                on the row. There must minimum be 1 matched property for the newRow value to be set and the new row
                                //                to be sent to the post function.
                                if (angular.isDefined(response) && response.length > 0 && angular.isDefined(response[0].savedata)) {
                                    var data = vm.grid.dataSource.data();

                                    for (let i = 0; i < data.length; i++) {
                                        var row = data[i];

                                        var propMatch = false;
                                        var isMatch = true;

                                        angular.forEach(response[0].savedata, function (value, key) {
                                            if (angular.isDefined(row[key]) === true) {
                                                if (angular.isFunction(row[key]) !== true && angular.isObject(row[key]) !== true && angular.isArray(row[key]) !== true) {
                                                    propMatch = true;

                                                    var rowVal = (row[key] instanceof Date
                                                        ? row[key]?.toISOString()
                                                        : row[key])?.toString();

                                                    var saveVal = (value instanceof Date
                                                        ? value?.toISOString()
                                                        : value)?.toString();

                                                    if (rowVal !== saveVal) {
                                                        isMatch = false;
                                                    }
                                                }
                                            }
                                        });

                                        if (propMatch === true && isMatch === true) {
                                            newRow = row;
                                            break;
                                        }
                                    }
                                }

                                //command: [{ name: 'gridGoToFunc', template: makeBtnTemplate('gridGoToFunc', undefined, hasItemPathColumn), click: function (e) { goGridFunc(e, 'goTo') } }],

                                vm.ttOptions.dataTask.addRow.post(newRow);

                                toolbarBtnDisability();
                            }

                            //let dataRow = vm.grid.current().closest("tr");
                            //console.log('dataRow');
                            //console.dir(vm.grid);
                            //console.dir(vm.grid.current());
                            //console.dir(dataRow);

                            editCell(atIndex);
                            updateAggregates();

                            //if (nextEdits !== null) {
                            //    setTimeout(function () {
                            //        editCell(nextEdits.row, nextEdits.col);
                            //        nextEdits = null;
                            //    });
                            //}
                        };

                        var checkAutoSave = function () {
                            if (shouldAutoSaveAddRow() === true) {
                                autoSaveState('add').then(checkPost, checkPost);
                            } else {
                                checkPost();
                            }
                        };

                        // BJS 20220911 - Opening modal editor if active
                        if (gridConfig.openEditOnNewRow === true) {
                            showEditModal(row).then(checkAutoSave, checkAutoSave);
                        } else {
                            checkAutoSave();
                        }
                    };

                    // BJS 20220423 - Added option to run a function to possibliy modify dataItem
                    //                before inserting new row.
                    if (angular.isDefined(vm.ttOptions.dataTask) && angular.isDefined(vm.ttOptions.dataTask.addRow) && angular.isFunction(vm.ttOptions.dataTask.addRow.pre)) {
                        var result = vm.ttOptions.dataTask.addRow.pre(dataItem);

                        if (angular.isDefined(result) && angular.isFunction(result.then)) {
                            result.then(function () {
                                preCompleted();
                            }, function () { });
                        } else {
                            preCompleted();
                        }
                    } else {
                        preCompleted();
                    }
                }

                if (shouldConfirmAddRow() === true) {
                    modalService.confirm({
                        type: 'warning',
                        title: wordlang.ttgrid_modal_add_title,
                        message: wordlang.ttgrid_modal_add_message,
                        okLabel: wordlang.ttgrid_modal_add_ok,
                        cancelLabel: wordlang.ttgrid_modal_add_cancel
                    }).then(doAddRow);
                } else {
                    doAddRow();
                }
            };

            function showEditModal(e, getDataItem) {
                let dataItem = angular.isDefined(getDataItem) && getDataItem === true
                    ? vm.grid.dataItem($(e.target).closest("tr"))
                    : e;

                return callLineEditPopup(true, dataItem);
            };

            // ###############################
            // #region Line Edit
            // ###############################

            var initEditPopup = function (remValue) {
                var deferred = $q.defer();

                var icbConfigPopup = modalDynamicViewFactory.$create({
                    cancel: true,
                    data: remValue
                });

                icbConfigPopup.show().then(function (config) {
                    return deferred.resolve(config);
                });

                return deferred.promise;
            };

            var getSortedEditableCols = function (key) {
                var gridCols = vm.grid.columns;
                var editableCols = [];
                var sortedEditableCols = [];

                angular.forEach(vm.grid.dataSource.options.schema.model.fields, function (col) {
                    if (col.from === 'is_selected') return;
                    if (angular.isDefined(col.editable) && col.editable === true) {
                        var editType = null;

                        switch (col.editType) {
                            case 'DD':
                                // BJS 20220912
                                editType = 'ttselect';
                                break;
                            case 'CB':
                                editType = 'checkbox';
                                break;
                            case 'LU':
                                editType = 'lookup';
                                break;
                            case 'T':
                                editType = 'input';
                            case 'TL':
                                editType = 'elastic';
                                break;
                            case 'HE':
                                editType = 'textarea';
                                break;
                            case 'DA':
                                editType = 'datetime';
                                break;
                            case 'N0':
                            case 'N1':
                            case 'N2':
                                editType = 'number' + col.editType.slice(1);
                                break;
                        }

                        if (editType !== null) {
                            var disabled = false;

                            for (var i = 0; i < gridCols.length; i++) {
                                if (col.from === gridCols[i].field) {
                                    if (angular.isUndefined(gridCols[i].hidden) || gridCols[i].hidden !== true) break;
                                    disabled = 'hidden';
                                    break;
                                }
                            }

                            editableCols.push({
                                name: col.from,
                                info: {
                                    label: col.from,
                                    placeholder: '',
                                    type: editType,
                                    translate: false
                                },
                                data: {
                                    field: ''
                                },
                                disabled: disabled
                            });

                            // BJS 20220912
                            if (editType === 'ttselect') {
                                var index = editableCols.length - 1;

                                editableCols[index].data.list = col.select.data;
                                editableCols[index].data.id = col.select.id;
                                editableCols[index].data.name = col.select.name;
                            }
                        }
                    }
                });

                angular.forEach(gridCols, function (col) {
                    for (var c = 0; c < editableCols.length; c++) {
                        if (col.field === editableCols[c].name) {
                            sortedEditableCols.push(editableCols[c]);
                            break;
                        }
                    }
                });

                return sortedEditableCols;
            };

            var callLineEditPopup = function (rebind, row) {
                var deferred = $q.defer();

                var sortedEditableCols = getSortedEditableCols();

                angular.forEach(row, function (r, key) {
                    for (var s = 0; s < sortedEditableCols.length; s++) {
                        if (key === sortedEditableCols[s].name) {
                            sortedEditableCols[s].data.field = r;
                            break;
                        }
                    }
                });

                initEditPopup(sortedEditableCols).then(function (config) {
                    angular.forEach(row, function (r, key) {
                        for (var c = 0; c < config.length; c++) {
                            if (key === config[c].name) {
                                row[key] = config[c].data.field;
                                break;
                            }
                        }
                    });

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // ###############################
            // #endregion Line Edit
            // ###############################

            // ###############################
            // #endregion gridfunc
            // ###############################

            // ###############################
            // #region events
            // ###############################


            function handleKeyDownSelection(event) {
                if (!event || !event.code) return;

                let newSelectedRow;

                switch (event.code) {
                    case 'ArrowDown':
                        newSelectedRow = vm.grid.current()?.closest('tr')?.next();
                        break;
                    case 'ArrowUp':
                        newSelectedRow = vm.grid.current()?.closest('tr')?.prev();
                        break;
                    default:
                        break;
                }

                if (newSelectedRow?.[0]) {
                    vm.grid.select(newSelectedRow);
                    newSelectedRow.focus();
                    //vm.grid.trigger('change');
                }
            }

            var keyDownHandler = function (e) {
                //if (e.defaultPrevented) {
                //    return; // Do nothing if the event was already processed
                //}

                vm.lastKeydown = e;

                if (vm.id === ttGridService.activeGridId && vm.ttOptions?.kendo?.selectable?.mode === 'row' || vm.ttOptions?.kendo?.selectable === 'row') {
                    handleKeyDownSelection(e);
                }

                //console.dir(e);

                if (vm.id === ttGridService.activeGridId && angular.isDefined(vm.ttOptions.config.shortcuts) && vm.ttOptions.config.shortcuts === true) {
                    if (e.ctrlKey) {
                        switch (e.code) {
                            case 'KeyI':
                                e.preventDefault();
                                let val;
                                let col;

                                if (vm.lastClickedRow !== null) {
                                    val = vm.lastClickedRow.find("input")[1];
                                    if (angular.isDefined(val)) {
                                        val = val.value;
                                        col = vm.grid.columns[vm.grid.current()[0].cellIndex].field;
                                        let dataItem = vm.grid.dataItem(vm.lastClickedRow);
                                        console.log('dataItem');
                                        console.dir(dataItem);
                                        //console.dir(col);
                                        //console.dir(val);
                                        //console.dir(vm.lastClickedRow);
                                        //console.dir(vm.lastClickedRow.find("input")); //e.container.find("input").val()
                                        //console.dir(vm.lastClickedRow.find("input")[0].value); //e.container.find("input").val()
                                        //console.dir(vm.lastClickedRow.find("input")[1].value); //e.container.find("input").val()
                                        //console.dir(vm.lastClickedRow.find("input").val()); //e.container.find("input").val()
                                        //console.dir(vm.grid);
                                        //console.dir(vm.grid.current());
                                        //console.dir(vm.grid.columns[vm.grid.current()[0].cellIndex]);
                                        //console.dir(vm.grid.dataSource._view);
                                        //console.dir(vm.grid.dataSource._view[3]);
                                        //console.dir(vm.grid.dataSource._view[3].discount);
                                        //console.log('end');
                                        //vm.grid.closeCell();
                                        //vm.grid.table.focus();
                                        //vm.grid.saveChanges();
                                        //setTimeout(function () {
                                        //    dataItem.set(col, val);
                                        //    //dataItem[col] = val;
                                        //    //dataItem.dirty = true;
                                        //    //dataItem.dirtyFields[col] = true;
                                        //}, 1500);
                                        dataItem.set(col, val);
                                        //console.dir(changes);
                                    }
                                    //return;
                                }


                                //if (shouldAutoSaveOnDataChanged()) {
                                //    autoSaveState('update').then(function (response) {
                                //        if (e.shiftKey) {
                                //            gridAddRowBefore();
                                //            setTimeout(function () {
                                //                editCell(0);
                                //            });
                                //        } else {
                                //            //gridAddRowAfter();
                                //        }
                                //    });
                                //} else {
                                //    if (e.shiftKey) {
                                //        gridAddRowBefore();
                                //        setTimeout(function () {
                                //            editCell(0);
                                //        });
                                //    } else {
                                //        gridAddRowAfter();
                                //    }
                                //}

                                if (e.shiftKey) {
                                    gridAddRowBefore();
                                    setTimeout(function () {
                                        //if (angular.isDefined(col) && angular.isDefined(val)) {
                                        //    vm.grid.dataItem(vm.lastClickedRow).set(col, val);
                                        //}
                                        editCell(0);
                                    });
                                } else {
                                    gridAddRowAfter();
                                    //if (angular.isDefined(col) && angular.isDefined(val)) {
                                    //    setTimeout(function () {
                                    //        vm.grid.dataItem(vm.lastClickedRow).set(col, val);
                                    //    });
                                    //}
                                }

                                break;
                            case 'KeyD':
                                e.preventDefault();
                                //console.log('ctrl D');

                                if (vm.lastClickedRow !== null) {
                                    let dataItem = vm.grid.dataItem(vm.lastClickedRow);

                                    vm.lastClickedRow = null;

                                    gridRemoveRow(dataItem);
                                    saveAllChanges(false);
                                }

                                break;
                        }
                    }
                }
            };

            let setActiveGrid = function (e) {
                ttGridService.activeGridId = vm.id;

                //vm.grid.editCell(vm.grid.tbody.children().eq(0).children().eq(23));
            };

            let cellClickHandler = function (e) {
                //updateAggregates();
                vm.lastClickedRow = $(this).closest('tr');

                if (angular.isFunction(vm.ttOptions.optionfunc) !== true && !vm.ttOptions.config.specialFunc?.cellBtns?.length > 0)
                    return;

                //let row = $(this).closest('tr');
                let col = $(this).closest('td');

                let dataItem = vm.grid.dataItem(vm.lastClickedRow);
                let cellIndex = col[0].cellIndex;
                let column = vm.grid.columns[cellIndex];
                let clickedCell = { dataItem: dataItem, column: column, row: vm.lastClickedRow };

                if (vm.ttOptions.config.specialFunc?.cellBtns?.length > 0) {
                    for (let i = 0; i < vm.ttOptions.config.specialFunc.cellBtns.length; i++) {
                        if (vm.ttOptions.config.specialFunc.cellBtns[i]?.name === column.field) {
                            ////console.log(vm.ttOptions.config.specialFunc.cellBtns[i]?.name, ' === ', column.field);
                            //console.dir(vm.ttOptions.config.specialFunc.cellBtns[i]);
                            //console.dir({ options: vm.ttOptions, column: column, row: dataItem }); //2837
                            //console.dir({ datatask: vm.ttOptions.dataTask.loadData, field: column.field, row: dataItem, gridRemId: vm.ttOptions.dataTask.rememberId }); //2837

                            dbRowClick({ datatask: vm.ttOptions.dataTask.loadData, field: column.field, row: dataItem, gridRemId: vm.ttOptions.dataTask.rememberId }, vm.ttOptions.config.specialFunc.cellBtns[i]);
                            break;
                        }
                    }
                }

                if (angular.isFunction(vm.ttOptions.optionfunc)) vm.ttOptions.optionfunc({ data: { func: 'CellClickHandler', clickedCell: clickedCell, options: vm.ttOptions } });
            };

            let cbClickHandler = function (e) {
                //console.log('cbClickHandler');
                //console.dir(e);
                //console.dir(this);
                //vm.lastClickedRow = $(this).closest('tr');

                //if (angular.isFunction(vm.ttOptions.optionfunc) !== true)
                //    return;

                vm.checkboxBoxClick(e);

                //let row = vm.grid.current().closest("tr");
                //let cellIndex = vm.grid.current().index();
                ////let row = $(this).closest('tr');
                ////let col = $(this).closest('td');

                //let dataItem = vm.grid.dataItem(row);
                ////let cellIndex = col[0].cellIndex;
                //let column = vm.grid.columns[cellIndex];
                //let key = column.field;
                ////let clickedCell = { dataItem: dataItem, column: column };

                ////console.dir(row);
                ////console.dir(cellIndex);
                ////console.dir(dataItem);
                ////console.dir(column);
                ////console.dir(key);
                ////console.dir(clickedCell);

                //checkboxCellEditor(dataItem, key);

                //console.dir(row);
                //console.dir(col);
                //console.dir(dataItem);
                //console.dir(cellIndex);
                //console.dir(column);
                //console.dir(clickedCell);

                //let dataRow = vm.grid.current().closest("tr");
                //let dataColIdx = vm.grid.current().index();
                //let options = vm.grid.getOptions();
                //let key = options.columns[dataColIdx].field;

                //console.dir(dataColIdx);
                //console.dir(options);
                //console.dir(key);

                //vm.ttOptions.optionfunc({ data: { func: 'CellClickHandler', clickedCell: clickedCell, options: vm.ttOptions } });

                //vm.checkboxBoxClick = function (e) {
                //    e.stopPropagation();
                //    var dataRow = vm.grid.current().closest("tr");
                //    var dataColIdx = vm.grid.current().index();
                //    var dataItem = vm.grid.dataItem(dataRow);
                //    var options = vm.grid.getOptions();
                //    var key = options.columns[dataColIdx].field;

                //    checkboxCellEditor(dataItem, key);
                //    //vm.grid.refresh();
                //};


                //checkboxCellEditor(dataItem, key);
            };

            vm.agOpenModal = function (event) {
                event.preventDefault();

                const resolve = {
                    dataItem: () => event.rowData,
                    rows: () => vm.ttOptions.gridfunc.getSelectedRows(),
                    isSelectedRows: () => vm.ttOptions.gridfunc.getIsSelectedRows(),
                    loadData: () => vm.ttOptions?.dataTask?.loadData,
                    rememberId: () => vm.ttOptions?.dataTask?.rememberId

                };

                dbModal(event.modalComponent, resolve, event.modalSize);
            }

            /**
             * 
             * @param {any} event undefined or the button the modal was triggered from.
             */
            vm.agOpenPrintModal = function (event) {
                event.preventDefault();

                openPrintModal(event.button);
            }

            vm.agNavigate = function (event) {
                event.preventDefault();

                const item = { item_state: event.itemState, item_parms: event.itemParms }

                if (event.newTab) {
                    goTab(item);
                } else {
                    goTo(item);
                }
            }

            vm.agDatatask = function (event) {
                //event.preventDefault();

                //dbDatatask(event.p2_datatask_keyno, { dataItem: event.rowData }, event.newTab);
            }

            /**
             * Whether to use kendo or ag-grid. Initial value is `null` so it doesn't start loading until setting is retrieved from layout-service.
             *
             * @type {boolean | null}
             */
            vm.useAgGrid = null;

            vm.toggleGrids = async function (toggle) {
                if (toggle === false) {
                    await initKendoGrid();
                } else if (toggle === true) {
                    cleanupKendoGrid();
                }

                vm.useAgGrid = toggle;
            }

            vm.$onInit = async function () {

                //const change = layoutService.onLayoutChanged().subscribe((info) => console.log(info));
                //console.log(change);

                if (vm.useAgGrid === false) {
                    await initKendoGrid();
                } else if (vm.useAgGrid === true) {
                    cleanupKendoGrid()
                }
            };

            vm.$onDestroy = function () {
                //$window.removeEventListener('resize', resizeFixed);
                //$window.removeEventListener('scroll', scrollFixed);
                //$window.removeEventListener("keydown", keyDownHandler, true);

                //// BJS 20221203 - Moved eventhandler from $document to $element so it will
                ////                be removed if element is removed.
                //$element.off('click', '.CellClickHandler', cellClickHandler);
                ////$document.off('click', '.CellClickHandler', cellClickHandler);

                //if (vm.id === ttGridService.activeGridId) ttGridService.activeGridId = null;

                //angular.forEach(onDestroy, function (fn) {
                //    if (angular.isFunction(fn) === true) {
                //        fn();
                //    }
                //});
                cleanupKendoGrid()
            };

            async function initKendoGrid() {
                $window.addEventListener('resize', resizeFixed);
                $window.addEventListener('scroll', scrollFixed);
                $window.addEventListener("keydown", keyDownHandler, true);

                // BJS 20221203 - Moved eventhandler from $document to $element so it will
                //                be removed if element is removed.
                $element.on('click', '.CellClickHandler', cellClickHandler);
                $element.on('click', '.cbClickHandler', cbClickHandler);
                $element.on('mousedown', setActiveGrid);
                //$element.on('keydown', '.CellClickHandler', keyDownCellHandler);

                await loadGrid();

                if (vm.ttOptions?.onReady && angular.isFunction(vm.ttOptions.onReady)) {
                    vm.ttOptions.onReady();
                }
            }

            function cleanupKendoGrid() {
                $window.removeEventListener('resize', resizeFixed);
                $window.removeEventListener('scroll', scrollFixed);
                $window.removeEventListener("keydown", keyDownHandler, true);

                // BJS 20221203 - Moved eventhandler from $document to $element so it will
                //                be removed if element is removed.
                $element.off('click', '.CellClickHandler', cellClickHandler);
                //$document.off('click', '.CellClickHandler', cellClickHandler);

                if (vm.id === ttGridService.activeGridId) ttGridService.activeGridId = null;

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            }

            // ###############################
            // #endregion events
            // ###############################

            // ###############################
            // #region DB Handlers
            // ###############################

            // BJS 20220301
            function dbGoto(state, state_params) {
                let params;

                if (state_params.length > 0) {
                    params = angular.fromJson(base64.urldecode(state_params));
                }

                stateService.go(state, params);
            };

            // BJS 20220301
            function dbModal(component, resolve, size) {
                size ??= 'pst-ninetyfive'
                const modalRef = $uibModal.open({
                    component: component,
                    resolve: resolve,
                    animation: true,
                    size: size,
                    backdrop: true
                });
                console.log(modalRef);
                modalRef.result.then(() => {
                    console.log('modal closed');
                    setTimeout(() => {
                        vm.ttOptions.gridfunc.read();
                    });
                })
            };

            // BJS 20220301
            function dbDatatask(p2_datatask_keyno, parameters, newTab) {
                if (p2_datatask_keyno < 1) return;

                ieScreenBlockService.start();

                $ihttp.post({
                    method: p2_datatask_keyno,
                    parameters: parameters
                }).then(function (response) {
                    if (response?.[0] && p2_datatask_keyno === 2837) {
                        if (newTab ??= true) goTab(response[0]);
                        else goTo(response[0]);

                        vm.lastKeydown = null;
                    } else {
                        vm.ttOptions.gridfunc.read();
                    }
                    //}).catch((reason) => {
                    //    ieScreenBlockService.stop();
                }).finally(() => {
                    ieScreenBlockService.stop();
                });
            };

            // BJS 20220301
            function dbRowClick(dataItem, btn) {
                switch (btn.dbType.id) {
                    case 'staticgoto':
                        dbGoto(btn.dbType.state, btn.dbType.state_params);
                        break;
                    case 'modal':
                        dbModal(btn.dbType.component, {
                            dataItem: function () {
                                return dataItem;
                            },
                            rows: function () {
                                return vm.ttOptions.gridfunc.getSelectedRows();
                            },
                            isSelectedRows: () => vm.ttOptions.gridfunc.getIsSelectedRows(),
                            loadData: function () {
                                return vm.ttOptions?.dataTask?.loadData;
                            },
                            rememberId: function () {
                                return vm.ttOptions?.dataTask?.rememberId;
                            }
                        },
                            btn.dbType.modal_size);
                        break;
                    case 'popup':
                        $ihttp.post({ method: 2837, parameters: { datatask: vm.ttOptions?.dataTask?.loadData, field: `popup:${btn.name}`, row: dataItem, gridRemId: vm.ttOptions?.dataTask?.rememberId } })
                            .then((response) => {
                                popupService.openPopup(response[0].item_state + '/' + response[0].item_parms, { rememberId: vm.ttOptions?.dataTask?.rememberId ? `${vm.ttOptions?.dataTask?.rememberId}.popup:${btn.name}` : undefined });
                            });
                    case 'datatask':
                        dbDatatask(btn.dbType.p2_datatask_keyno, {
                            dataItem: dataItem
                        });
                        break;
                    case 'cell':
                        if (!vm?.lastKeydown || !vm.lastKeydown.ctrlKey || !vm.lastKeydown.shiftKey) {
                            if (btn.dbType.p2_datatask_keyno === 0) {
                                dbDatatask(2837, dataItem, !vm?.lastKeydown ? false : vm.lastKeydown.ctrlKey && !vm.lastKeydown.shiftKey ? true : false);
                            } else {
                                dbDatatask(btn.dbType.p2_datatask_keyno, dataItem);

                                if (btn.dbType.id === 'cell') {
                                } else {
                                    dbGoto(btn.dbType.state, btn.dbType.state_params);
                                }
                            }
                        }

                        vm.lastKeydown = null;
                        break;
                    case 'goto':
                        if (btn.dbType.p2_datatask_keyno === 0) {
                            if (btn.dbType?.state || btn.dbType.state === '') {
                                dbDatatask(2837, {
                                    dataItem: dataItem
                                });
                            } else {
                                dbGoto(btn.dbType.state, btn.dbType.state_params);
                            }
                        } else {
                            dbDatatask(btn.dbType.p2_datatask_keyno, {
                                dataItem: dataItem
                            });
                        }
                        break;
                }
            };

            // BJS 20220228 - Handler function for custom toolbar buttons defined in colschema.
            function dbToolbarButtonClick(btn) {
                switch (btn.dbType.id) {
                    case 'goto':
                        dbGoto(btn.dbType.state, btn.dbType.state_params);
                        break;
                    case 'modal':
                        dbModal(btn.dbType.component, {
                            row: function () {
                                return vm.ttOptions.gridfunc.getSelectedRow();
                            },
                            rows: function () {
                                return vm.ttOptions.gridfunc.getSelectedRows();
                            },
                            isSelectedRows: () => vm.ttOptions.gridfunc.getIsSelectedRows()
                        },
                            btn.dbType.modal_size);
                        break;
                    case 'datatask':
                        dbDatatask(btn.dbType.p2_datatask_keyno, {
                            row: vm.ttOptions.gridfunc.getSelectedRow(),
                            rows: vm.ttOptions.gridfunc.getSelectedRows(),
                            isSelected: getIsSelectedRows()
                        });
                        break;
                    case 'print':
                        //openPrintModal(btn.dbType.p2_datatask_keyno);
                        openPrintModal();
                        //dbDatatask(btn.dbType.p2_datatask_keyno, {
                        //    row: vm.ttOptions.gridfunc.getSelectedRow(),
                        //    rows: vm.ttOptions.gridfunc.getSelectedRows(),
                        //    isSelected: getIsSelectedRows()
                        //});
                        break;
                }
            };

            // ###############################
            // #endregion DB Handlers
            // ###############################
        }]
    });
})();
