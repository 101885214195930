(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('subscriptions', {
        templateUrl: 'views/components/views/subscriptions/subscriptions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'subscriptionsService', 'ttGridFactory', 'rememberService', function (stateService, utilityService, subscriptionsService, ttGridFactory, rememberService) {

		    // #region VARIABLES & DEFINITIONS

            const vm = this;
    		
            let variableNames = {
                filtervalue: ''
            };
            
            vm.model = {
                bgSubscriptionsMain: {
                    selected: 'SUMPRSUB',
                    buttons: [
                        { id: 'SUMPRSUB', label: 'gb_subscriptions_main_sumprsub', color: 'primary', onClick: () => vm.model.bgSubscriptionsMain.selected = 'SUMPRSUB' },
                        { id: 'SUBGRID', label: 'gb_subscriptions_main_subgrid', color: 'primary', onClick: () => vm.model.bgSubscriptionsMain.selected = 'SUBGRID' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
                setting: {},
                itemsListSubscriptions: [],
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_subscriptions_grid ',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                subscriptionsService.getSubscriptions().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            let loadSubscriptions = function () {
                subscriptionsService.listSubscriptions().then(function (result) {
                    angular.copy(result, vm.model.itemsListSubscriptions);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3468,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgSubscriptionsMain = function (value) {
                vm.model.bgSubscriptionsMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSubscriptions();
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
