(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('subscriptionUsergroup', {
        templateUrl: 'views/components/views/subscriptionUsergroup/subscriptionUsergroup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'subscriptionUsergroupService', 'layoutService', function ($stateParams, subscriptionUsergroupService, layoutService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                tabletSize: layoutService.getMediaQueries().tablet,
                itemState: '',
                p2SubscriptionUsergroupKeyno: '',
                itemsListSubscriptionUserGroups: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSubscriptionUserGroups = function () {
                subscriptionUsergroupService.listSubscriptionUserGroups({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListSubscriptionUserGroups);

                    angular.forEach(vm.model.itemsListSubscriptionUserGroups, function (item) {
                        if (item.item_state > '') {
                            vm.model.itemState = item.item_state;
                        }
                    });
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region UPDATE PROCEDURE LIST FUNCTION

            vm.updateList = function () {
                loadSubscriptionUserGroups();
            };

            // #endregion UPDATE PROCEDURE LIST FUNCTION

            // #region LIST DIRECTION BUTTON FUNCTION

            vm.openEdit = function (item) {
                vm.model.p2SubscriptionUsergroupKeyno = item.item_id;
            };

            // #endregion LIST DIRECTION BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSubscriptionUserGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
