(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('prodSizeColli', {
        templateUrl: 'views/components/views/prodSizeColli/prodSizeColli.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'stateService', 'utilityService', 'translateService', 'prodSizeColliService', function ($stateParams, modalService, stateService, utilityService, translateService, prodSizeColliService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodSizeKeyno = $stateParams.prodsize_keyno;
            let prodSizeColliKeyno = $stateParams.prodsizecolli_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION

            let loadEdit = function () {
                prodSizeColliService.getEdit({
                    prodsize_keyno: prodSizeKeyno,
                    prodsizecolli_keyno: prodSizeColliKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region RECALCULATION FUNCTIONS

            let recalcVolumeM3 = function () {
                vm.model.edit.volume_m3 = (utilityService.parseNumber(vm.model.edit.height_cm) * utilityService.parseNumber(vm.model.edit.length_cm) * utilityService.parseNumber(vm.model.edit.width_cm)) / 1000000;
            };
            
            let recalcVolumeM3StackingQty = function () {
                if (vm.model.edit.input_colli_size === '0') {
                    vm.model.edit.volume_m3_stack = utilityService.parseNumber(vm.model.edit.stacking_qty) * utilityService.parseNumber(vm.model.edit.volume_m3);
                }
            };

            let recalcVolumeM3Stack = function () {
                if (vm.model.edit.input_colli_size === '1') {
                    vm.model.edit.volume_m3_stack = (utilityService.parseNumber(vm.model.edit.height_cm_stack) * utilityService.parseNumber(vm.model.edit.length_cm_stack) * utilityService.parseNumber(vm.model.edit.width_cm_stack)) / 1000000;
                }
            };

		    // #endregion RECALCULATION FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                prodSizeColliService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    prodSizeColliService.deleteItem(vm.model.edit).then(function (response) {
                    	if (response[0].errorcode !== '0') {
                    		modalService.show({
                    			type: 'warning',
                    			title: translations.error,
                    			message: response[0].errormessage,
                    			buttons: [{
                    				label: translations.ok,
                    				cssClass: 'btn-warning',
                    				action: function (dialogItself) {
                    					dialogItself.close();
                    					vm.model.lockedDelete = false;
                    				}
                    			}]
                    		});
                    	} else {
                    		vm.model.lockedDelete = false;
                    		stateService.back();
                    	}
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
            
            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'width_cm':
                    case 'height_cm':
                    case 'length_cm':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        recalcVolumeM3();
                        break;
                    case 'width_cm_stack':
                    case 'height_cm_stack':
                    case 'length_cm_stack':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        recalcVolumeM3Stack();
                        break;
                    case 'volume_m3':
                    case 'stacking_qty':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        recalcVolumeM3StackingQty();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
