(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('salaryType', {
        templateUrl: 'views/components/views/salaryType/salaryType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'salaryTypeService', 'economyService', 'logisticService', 'typeaheadService', function ($stateParams, stateService, translateService, modalService, salaryTypeService, economyService, logisticService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let salaryTypeId = $stateParams.salarytype_id;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
            	edit: {},
                selectListSalaryTypes: [],
                selectListUnits: [],
                selectListImpactTimeAccount: [],
                selectListExtraQuantityTypes: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                salaryTypeService.getEdit({ salarytype_id: salaryTypeId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadSalaryTypes = function () {
                economyService.listSalaryTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListSalaryTypes);
                });
            };

            let loadUnits = function () {
                logisticService.listUnits().then(function (list) {
                    angular.copy(list, vm.model.selectListUnits);
                });
            };

            let loadImpactTimeAccount = function () {
                salaryTypeService.listImpactTimeAccount().then(function (list) {
                    angular.copy(list, vm.model.selectListImpactTimeAccount);
                });
            };

            let loadExtraQuantityTypes = function () {
                salaryTypeService.listExtraQuantityTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListExtraQuantityTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchProduct({ prod_name: vm.model.edit[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'prod_name':
                        if (item) {
                            vm.model.edit.prod_id = item?.item_id ?? '';
                            vm.model.edit.prod_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_id = '';
                        vm.model.edit.prod_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                if (vm.model.edit.overtime === '0') {
                    vm.model.edit.extrapst = '0';
                }

                if (vm.model.edit.absence === '0') {
                    vm.model.edit.absence_paid = '0';
                    vm.model.edit.absence_legal = '0';
                }

                salaryTypeService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].status_no !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].status_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    salaryTypeService.deleteItem({ salarytype_id: vm.model.edit.salarytype_id }).then(function (response) {
                        if (response[0].status_no !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                                message: response[0].status_message,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadSalaryTypes();
                loadUnits();
                loadImpactTimeAccount();
                loadExtraQuantityTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
