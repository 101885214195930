(function () {
    'use strict';

    angular.module("imApp").factory("niService", [function () {
        let service = {
            inputNumber: {
                number: '',
                webPageName: ''
            }
        };

        return service;
    }]);
})();
