// BJS 20250306 - Migrated using chatGPT o1.
import { Injectable } from '@angular/core';
import { EventService } from '../event.service';
import { LayoutService } from '../layout.service';
import { ResponsiveService } from '../responsive.service';
import { UserService } from '../user.service';
import { UserStore } from '../user.store';
import { UtilityService } from '../utility.service';

// Simple UUID function – or import from 'uuid'
function uuid(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

interface TTGroup {
    state: 'initializing' | 'ready';
    defers: Array<(value?: void | PromiseLike<void>) => void>;
    active: boolean;
    fields: {
        [fieldId: string]: {
            info: any;
            onChange: { [handlerId: string]: (args: any) => void };
        };
    };
}

@Injectable({
    providedIn: 'root'
})
export class TtItemService {
    private groups: { [groupId: string]: TTGroup } = {};

    constructor(
        private layoutService: LayoutService,
        private utilityService: UtilityService,  // if "us" is your utility
        private userService: UserService,
        private userStore: UserStore,
        private eventService: EventService,
        private responsive: ResponsiveService
    ) { }

    /**
     * Splits "group.field" into { group, field }
     */
    private getId(commonId: string): { group: string; field: string } {
        const parts = commonId.split('.');
        return {
            group: parts[0],
            field: parts[1]
        };
    }

    /**
     * Ensure the group is initialized (fetch field info from layoutService).
     * If already initializing or ready, queue or resolve accordingly.
     */
    private async initGroup(groupId: string): Promise<void> {
        // If we have NOT seen this group yet:
        if (!this.groups[groupId]) {
            // Create the group object
            this.groups[groupId] = {
                state: 'initializing',
                defers: [],    // an array of resolve callbacks
                active: false,
                fields: {}
            };

            // Now do your async loading...
            const list = await this.layoutService.getFieldInfo(groupId);

            // Create each field
            list.forEach((field: any) => {
                this.groups[groupId].fields[field.field_id] = {
                    info: field,
                    onChange: {}
                };
            });

            // Mark group as ready
            this.groups[groupId].state = 'ready';

            // Resolve all promises that were queued while we were initializing
            const defers = this.groups[groupId].defers.splice(0);
            defers.forEach(resolveFn => resolveFn());

        } else {
            // If the group already exists but is still initializing, we must wait
            if (this.groups[groupId].state === 'initializing') {
                // We'll push a resolver into the defers array, then wait for it
                await new Promise<void>(resolve => {
                    this.groups[groupId].defers.push(resolve);
                });
            }
            // If the group is already 'ready', do nothing (we're good to go)
        }
    }


    /**
     * (In AngularJS) We used scope.$on('$destroy') to remove handlers. 
     * In Angular 15, you can call this method in ngOnDestroy of your component.
     * Just pass the same object that had "ttItemServiceVars" if you still maintain that structure.
     */
    private addCleanup(destroyObj: any): void {
        if (!destroyObj?.ttItemServiceVars?.onChangeInfo) return;

        // On destruction, remove the onChange handlers
        destroyObj._ttDestroy = () => {
            destroyObj.ttItemServiceVars.onChangeInfo.forEach((info: any) => {
                if (!this.groups[info.groupId]) return;
                if (!this.groups[info.groupId].fields[info.fieldId]) return;
                if (!this.groups[info.groupId].fields[info.fieldId].onChange[info.handlerId]) return;
                delete this.groups[info.groupId].fields[info.fieldId].onChange[info.handlerId];
            });
        };
    }

    /**
     * MAIN SERVICE METHODS
     */

    /**
     * Original signature:
     *   service.register(arg1, arg2, arg3)
     *
     * In AngularJS, it detected if arg2 was a string or not. We replicate that logic here:
     *   - if (arg3 is undefined or arg2 is string) => registerCustom
     *   - else => registerDefault
     */
    public register(arg1: any, arg2?: any, arg3?: any): Promise<any> {
        const isCustom = (arg3 === undefined) || (typeof arg2 === 'string');
        if (isCustom) {
            return this.registerCustom(arg1, arg2);
        } else {
            return this.registerDefault(arg1, arg2, arg3);
        }
    }

    /**
     * Toggles the active state of a group, firing 'active' events for all fields.
     */
    public toggleActive(groupId: string): void {
        if (!this.groups[groupId]) {
            return;
        }
        this.groups[groupId].active = !this.groups[groupId].active;

        Object.keys(this.groups[groupId].fields).forEach(fieldId => {
            const field = this.groups[groupId].fields[fieldId];
            Object.values(field.onChange).forEach(handler => {
                if (typeof handler === 'function') {
                    handler({
                        type: 'active',
                        active: this.groups[groupId].active,
                        info: field.info
                    });
                }
            });
        });
    }

    /**
     * Toggles "enabled" state for a specific field and updates layoutService
     */
    public async toggleEnabled(id: string): Promise<any> {
        const tmpId = this.getId(id);
        const groupId = tmpId.group;
        const fieldId = tmpId.field;

        if (!this.groups[groupId]) {
            return;
        }

        const infoObj = this.groups[groupId].fields[fieldId].info;
        // flip
        infoObj.enabled = this.utilityService.toBoolean(infoObj.enabled, false) ? '0' : '1';

        // update layout
        const updatedInfo = await this.layoutService.updateFieldInfo(infoObj);
        // copy back
        Object.assign(this.groups[groupId].fields[fieldId].info, updatedInfo);

        // broadcast
        this.eventService.trigger('ttItemService:fieldInfoToggle', updatedInfo);
        return updatedInfo;
    }

    /**
     * Returns a promise that resolves with the group's fields once the group is init'd.
     */
    public async getGroupInfo(groupId: string): Promise<any> {
        await this.initGroup(groupId);
        return this.groups[groupId].fields;
    }

    /**
     * Helper to generate an ID from a scope-like object, used by registerDefault
     */
    private generateId(scope: any): string | null {
        // 1) if scope.ttItemId is a string => use that
        if (scope?.ttItemId && typeof scope.ttItemId === 'string') {
            return scope.ttItemId;
        }
        // 2) else if scope.ttGroupId is a string => build from group + field
        if (!scope?.ttGroupId || typeof scope.ttGroupId !== 'string') {
            return null;
        }
        let ttItemId = scope.ttGroupId + '.';

        if (scope?.ttFieldId && typeof scope.ttFieldId === 'string') {
            ttItemId += scope.ttFieldId;
            return ttItemId;
        }
        if (scope?.label && typeof scope.label === 'string') {
            ttItemId += scope.label;
            return ttItemId;
        }
        if (scope?.dataid && typeof scope.dataid === 'string') {
            ttItemId += scope.dataid;
            return ttItemId;
        }
        if (scope?.dataname && typeof scope.dataname === 'string') {
            ttItemId += scope.dataname;
            return ttItemId;
        }

        return null;
    }

    /**
     * registerDefault() replicates the old logic for scope/element/attr
     */
    private registerDefault(scope: any, element: any, attr: any): Promise<any> {
        // The original code was doing show/hide checks, toggling classes, hooking eventService, etc.
        // We keep as much as possible.

        const isVisible = (info?: any): boolean => {
            if (!info) {
                info = scope.ttInfo;
            }
            let visible = false;
            if (info && this.utilityService.toBoolean(info.enabled, true)) {
                // check usergroups
                if (info.usergroups && info.usergroups.length > 0) {
                    for (let i = 0; i < info.usergroups.length; i++) {
                        if (this.userStore.userGroups['_' + info.usergroups[i].usergroup_keyno]) {
                            visible = true;
                            break;
                        }
                    }
                } else {
                    visible = true;
                }
                // check sizes
                if (visible && info.sizes && info.sizes.length > 0) {
                    const parts = info.sizes.split(' ');
                    visible = false;
                    for (let j = 0; j < parts.length; j++) {
                        if (parts[j] === this.responsive.current) {
                            visible = true;
                            break;
                        }
                    }
                }
            }
            return visible;
        };

        const showHide = (makeVisible: boolean) => {
            if (makeVisible) {
                attr?.classList?.remove('ng-hide');
            } else {
                attr?.classList?.add('ng-hide');
            }
            if (scope?.ttInfo?.ttItemId) {
                this.eventService.trigger('ttItemService:visiblityChanged', { ttItemId: scope.ttInfo.ttItemId, visible: makeVisible }, true);
            }
        };

        let btnElements: any;

        const toggleButtonElements = () => {
            if (btnElements) {
                // remove
                if (btnElements?.parentNode) {
                    btnElements.parentNode.removeChild(btnElements);
                }
                btnElements = undefined;
            } else {
                // In Angular, we don’t typically compile strings, but we can replicate:
                // `<div class="col-xs-12 sp-0"><div class="pull-right" ...>..</div></div>`
                // We'll just no-op or create a minimal element.
                const newEl = document.createElement('div');
                newEl.className = 'col-xs-12 sp-0';
                newEl.innerHTML = `
          <div class="pull-right" tt-item-usergroup-manager="ttInfo"></div>
          <tt-item-size-manager class="pull-right" tt-info="ttInfo" style="padding-right:2px;"></tt-item-size-manager>
        `;
                element.prepend(newEl);
                btnElements = newEl;
            }
        };

        const toggleEnabled = (info: any) => {
            attr?.classList?.remove('item-active-off');
            attr?.classList?.remove('item-active-on');
            attr?.classList?.remove('item-active-on-group');

            if (this.utilityService.toBoolean(info.enabled, false)) {
                if (!info.sizes) {
                    info.sizes = '';
                }
                if (info.usergroups.length > 0 || info.sizes.length > 0) {
                    attr?.classList?.add('item-active-on-group');
                } else {
                    attr?.classList?.add('item-active-on');
                }
            } else {
                attr?.classList?.add('item-active-off');
            }

            this.eventService.trigger('ttItem:toggledEnabled', info);
        };

        const clickHandler = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggleEnabled(scope.ttItemId).then(info => {
                toggleEnabled(info);
            });
        };

        const changeHandler = (args: any) => {
            switch (args.type) {
                case 'active':
                    toggleButtonElements();
                    toggleEnabled(args.info);

                    let visible = false;
                    if (this.utilityService.toBoolean(args.active, false)) {
                        visible = true;
                        element?.addEventListener('click', clickHandler);
                    } else {
                        attr?.classList?.remove('item-active-off');
                        attr?.classList?.remove('item-active-on');
                        attr?.classList?.remove('item-active-on-group');

                        visible = isVisible(args.info);
                        element?.removeEventListener('click', clickHandler);
                    }

                    showHide(visible);

                    this.eventService.trigger('ttItem:toggledActive', args);
                    this.eventService.trigger('ttIf:all', { groupId: args.info.group_id, visible: this.utilityService.toBoolean(args.active, false) });
                    break;
            }
        };

        // generate the ID
        const id = this.generateId(scope);
        if (!id) {
            return Promise.resolve(null);
        }
        const cid = this.getId(id);

        // watchers replaced with eventService triggers
        const dereg1 = this.eventService.on('layoutService:fieldInfo:changed', (info: any) => {
            if (info.group_id === cid.group && info.field_id === cid.field) {
                toggleEnabled(info);
            }
        });

        const dereg2 = this.eventService.on('ttItemUsergroupManager:toggle', (ugToggleData: any) => {
            if (ugToggleData.field.group_id === cid.group && ugToggleData.field.field_id === cid.field) {
                toggleEnabled(scope.ttInfo);
            }
        });

        const dereg3 = this.eventService.on('ttItemSizeManager:toggle', (ttItemSizeManager: any) => {
            if (ttItemSizeManager.field.group_id === cid.group && ttItemSizeManager.field.field_id === cid.field) {
                toggleEnabled(scope.ttInfo);
            }
        });

        const dereg4 = this.eventService.on('event:responsive-breakpoint', () => {
            showHide(isVisible());
        });

        // in your real Angular app, you'd clean these up in ngOnDestroy
        if (scope.$on) {
            scope.$on('$destroy', () => {
                if (dereg1) dereg1();
                if (dereg2) dereg2();
                if (dereg3) dereg3();
                if (dereg4) dereg4();
            });
        }

        scope.ttItemId = id;

        return new Promise((resolve) => {
            // we replicate the old "service.register(scope, changeHandler).then(...)" 
            this.registerCustom(scope, id, changeHandler).then((info: any) => {
                scope.ttInfo = info;
                scope.ttInfo.ttItemId = scope.ttItemId;
                const visible = isVisible(info);
                showHide(visible);

                if (this.groups[info.group_id].active === true) {
                    // manually trigger 'active'?
                    Object.values(this.groups[info.group_id].fields[info.field_id].onChange).forEach(handler => {
                        if (typeof handler === 'function') {
                            handler({
                                type: 'active',
                                active: true,
                                info: this.groups[info.group_id].fields[info.field_id].info
                            });
                        }
                    });
                }
                resolve(info);
            });
        });
    }

    /**
     * registerCustom(scope, id, onChange)
     * or registerCustom(scope, onChange)
     */
    private registerCustom(scope: any, id?: any, onChange?: any): Promise<any> {
        return new Promise(async (resolve) => {
            if (!scope || !scope.$on) {
                resolve(null);
                return;
            }

            // if second param is function => shift
            if (typeof id === 'function') {
                onChange = id;
                id = scope.ttItemId;
            }

            if (typeof onChange !== 'function') {
                resolve(null);
                return;
            }

            const tmpId = this.getId(id);
            const groupId = tmpId.group;
            const fieldId = tmpId.field;

            await this.initGroup(groupId);

            if (!this.groups[groupId].fields[fieldId]) {
                this.groups[groupId].fields[fieldId] = {
                    info: {
                        keyno: 0,
                        group_id: groupId,
                        field_id: fieldId,
                        enabled: '1',
                        usergroups: []
                    },
                    onChange: {}
                };
            }

            if (!scope.ttItemServiceVars) {
                scope.ttItemServiceVars = {};
            }
            if (!Array.isArray(scope.ttItemServiceVars.onChangeInfo)) {
                scope.ttItemServiceVars.onChangeInfo = [];
            }

            const handlerId = uuid();
            this.groups[groupId].fields[fieldId].onChange[handlerId] = onChange;

            scope.ttItemServiceVars.onChangeInfo.push({
                groupId,
                fieldId,
                handlerId
            });

            // handle cleanup
            this.addCleanup(scope);

            resolve(this.groups[groupId].fields[fieldId].info);
        });
    }
}
