(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('saftExportComponent', {
        templateUrl: 'views/components/views/saftExport/saftExport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'saftExportService', 'rememberService', 'ieScreenBlockService', function (stateService, utilityService, saftExportService, rememberService, ieScreenBlockService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                rememberName: 'w_saftexport',
                starting: false,
                isProcessing: true,
                exportingToFile: false,
                statusMessage: '',
                status: '',
                selectListCompanies: [],
                setting: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var buildRememberId = function (id) {
                // JGH vil bruke samme verdi som w_accountingprocessing for businessco
                let prefix = id === 'businessco_no'
                    ? 'w_accountingprocessing'
                    : vm.model.rememberName;

                return prefix + '.' + id;
            };

            var rememberFunc = function (id, value) {
                if (utilityService.validateParmsValue(value, true) !== true) return;

                vm.model.setting[id] = value;

                let rememberId = buildRememberId(id);

                rememberService.remember(rememberId, value);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');
                var partId = parts[parts.length - 1];

                switch (partId) {
                    case 'businessco_no':
                        rememberFunc(partId, value);
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                }
            };


            // #endregion FIELD CHANGE MULTI FUNCTIONS

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom', startDate);

                if (vm.model.setting.date_tom) rememberFunc('date_tom', endDate);

                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index', vm.model.setting.dateselector_index);

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                }
            }

            vm.startGeneratingFile = function () {
                vm.model.isProcessing = true;

                vm.model.starting = true;

                if (refreshInterval !== null) {
                    clearInterval(refreshInterval);
                    refreshInterval = null;
                }

                saftExportService.startGeneratingFile(vm.model.setting).then(function (response) {
                    vm.model.starting = false;
                    //refreshInterval = setInterval(() => vm.refreshStatus(), 5000);
                }, function (error) {
                    vm.model.starting = false;

                    //refreshInterval = setInterval(() => vm.refreshStatus(), 5000);
                }).finally(() => {
                    vm.refreshStatus();
                    refreshInterval = setInterval(() => vm.refreshStatus(), 5000)
                });
            }

            vm.setStatus = function (data) {
                switch (data.status) {
                    case 3:
                    case 4:
                    case 7:
                        vm.model.isProcessing = false;
                        break;
                    default:
                        break;
                }
                vm.model.status = data.status;
                vm.model.statusMessage = data.statusMessage;
            }
            let refreshInterval = null;

            vm.refreshStatus = function () {
                //refreshInterval = setInterval(() => {

                    saftExportService.refresh().then(function (response) {
                        //console.dir(response);

                        vm.setStatus(response);

                        console.log(refreshInterval);
                        if ('' + response.status === '3' && refreshInterval !== null) {
                            //console.log('clear interval');
                            clearInterval(refreshInterval);
                            refreshInterval = null;
                        }
                    });
                //}, 5000);
            }

            vm.download = async function () {
                vm.model.exportingToFile = true;

                let responses = await Promise.all([rememberService.getLastStatusEncoded('w_saftexport.content', { urlencode_response: '1' }), rememberService.getLastStatus('w_saftexport.filename')]);
                let response = responses[0];
                let filename = responses[1]?.[0]?.variablevalue ?? uuid();
                function validateResponse() {
                    if (angular.isUndefined(response)) return false;
                    if (response === null) return false;
                    if (response.length < 1) return false;
                    if (angular.isUndefined(response[0].variablevalue)) return false;
                    if (response[0].variablevalue === null) return false;
                    if (response[0].variablevalue.trim().length < 1) return false;

                    return true;
                }

                var isValidResponse = validateResponse();

                if (!isValidResponse) {
                    vm.model.exportingToFile = false;
                    return;
                }

                let content = response[0].variablevalue;

                let data = new Blob([content], { type: 'text/txt' });
                let url = URL.createObjectURL(data);

                const a = document.createElement('a');

                a.href = url;
                a.download = filename.endsWith('.xml') ? filename : filename + '.xml';

                // Append to body to make it clickable
                document.body.appendChild(a);
                a.click();

                // Clean up: remove the anchor and revoke the URL
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

                vm.model.exportingToFile = false;
            }

            vm.$onInit = function () {
                saftExportService.init().then(function (response) {
                    vm.setStatus(response);

                    angular.copy(response.businesscoList, vm.model.selectListCompanies);
                });

                saftExportService.get().then(function (response) {
                    if (response['businessco_no']) {
                        response.businessco_no = parseInt(response.businessco_no);
                    }

                    angular.copy(response, vm.model.setting);
                });
            };

            vm.$onDestroy = function () {
                if (!!refreshInterval) {
                    clearInterval(refreshInterval);
                }
            }
        }]
    });
})();
