<button
    *ngIf="ready && !ttSkeleton"
    class="tt-switch__button"
    [ngClass]="{ 'tt-switch__button--active': ttActive, 'tt-switch__button--stretch': ttStretchWidth }"
    [matTooltip]="ttDisabled ? '' : tooltipText.trim()"
    [matTooltipShowDelay]="500"
    (click)="ttClick.emit($event)"
    [disabled]="ttDisabled"
>
    <span class="tt-switch__text">
        <span *ngIf="!!ttIcon" class="fa-fw" [ngClass]="ttIcon"></span>
        <span>{{ text }}</span>
    </span>
</button>
<div *ngIf="!ready || ttSkeleton" class="tt-skeleton tt-skeleton--switch" [ngClass]="{ 'tt-skeleton--switch--stretch': ttStretchWidth }"></div>
