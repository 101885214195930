(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('requisitionCreate', {
        templateUrl: 'views/components/views/requisitionCreate/requisitionCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'requisitionCreateService', function ($stateParams, stateService, requisitionCreateService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeynoFrom = $stateParams.stock_keyno_from;
            let stockKeynoTo = $stateParams.stock_keyno_to;
            
            vm.model = {
                stockPlaceNameFromButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.edit.stock_keyno_from }) }
                ],
                stockPlaceNameToButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.edit.stock_keyno_to }) }
                ],
                edit: {},
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALL

            let loadEdit = function () {
                requisitionCreateService.getEdit({
                    stock_keyno_from: stockKeynoFrom,
                    stock_keyno_to: stockKeynoTo
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                requisitionCreateService.createRequisition(vm.model.edit).then(function () {
                    vm.model.lockedCreate = false;

                    stateService.back('/' + 'stockget' + '/' + vm.model.edit.stock_keyno_to);
                }, function () {
                    vm.model.lockedCreate = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
