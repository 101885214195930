(function () {
    'use strict';

    angular.module("imApp").factory("orderHeadCopyService", ['$ihttp', function ($ihttp) {
        let service = {
            getCopy: function (parms) {
                return $ihttp.post({
                    method: 1783,
                    parameters: parms || {}
                });
            },
            copyOrder: function (copy) {
                return $ihttp.post({
                    method: 1784,
                    parameters: copy
                });
            }
        };

        return service;
    }]);
})();
