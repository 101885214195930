<h2 mat-dialog-title class="tt-secondary-background" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle style="color: white">
    {{ heading || "" }}
</h2>

<mat-dialog-content style="max-height: none; height: 100%; flex: 1">
    <tt-translate-edit
        [(ttTextToTranslate)]="data.textToTranslate"
        [(ttTranslateFromLanguage)]="data.translateFromLanguage"
        [ttTranslateEdits]="data.edits"
        [ttSave]="onSave"
        [ttGoogleLanguageIdKey]="data.googleLanguageIdKey!"
        [ttLanguageIdKey]="data.languageIdKey!"
        [ttLanguageNameKey]="data.languageNameKey!"
        [ttWordIdKey]="data.wordIdKey!"
        [ttWordNameKey]="data.wordNameKey!"
    ></tt-translate-edit>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- <!-- <tt-button class="col-xs-2 sp-2 pull-right" ttText="create_acvoucher" ttType="success" (ttClick)="process('create_acvoucher')"></tt-button> -->
    <tt-button class="col-xs-2 sp-0" ttText="close" ttType="secondary" (ttClick)="dialog.close()"></tt-button>
</mat-dialog-actions>
