<tt-card class="col-xs-5 p-0" ttHeading="h_translation_text">
    <tt-textarea ttLabel="translateedit_text_to_translate" [(ttModel)]="ttTextToTranslate" (ttModelChange)="ttTextToTranslateChange.emit($event)"></tt-textarea>
    <tt-select
        ttLabel="translateedit_translate_from_language"
        [(ttModel)]="ttTranslateFromLanguage!"
        (ttModelChange)="ttTranslateFromLanguageChange.emit($event)"
        [ttData]="translateFromLanguages"
        ttDataId="google_language_id"
        ttDataName="item_name"
        ttShowClearButton
        [ttClearValue]="''"
    ></tt-select>
    <tt-button ttText="translateedit_translate" (ttClick)="translate()" ttIcon="far fa-sync" ttType="primary"></tt-button>
    <tt-button-save ttText="save" (ttClick)="onSave()"></tt-button-save>
</tt-card>

<tt-card class="col-xs-7 p-0 height-full" ttHeading="h_translation_languages" ttClass="height-full flex-column">
    <div class="tt-translate-edit__languages">
        <tt-checkbox ttLabel="w_translateedit_translate_all" [(ttModel)]="translateToAllLanguages" (ttModelChange)="onTranslateToAllLanguagesChange($event)"></tt-checkbox>
        <div class="tt-translate-edit__languages-list">
            <tt-card *ngFor="let edit of translateEdits" class="translate-edit__card">
                <tt-textarea [ttLabel]="edit[ttLanguageNameKey]" ttTranslateLabel="false" [(ttModel)]="$any(edit)[ttWordNameKey]"></tt-textarea>
                <div style="display: flex; width: 100%; gap: 1.6rem">
                    <tt-checkbox *ngIf="edit['keeptext'] !== undefined" ttLabel="keeptext" [(ttModel)]="$any(edit)['keeptext']"></tt-checkbox>
                    <tt-checkbox ttLabel="w_translateedit_to_be_translated" [(ttModel)]="$any(edit)['toTranslate']" (ttModelChange)="updateTranslateToAllLanguages()"></tt-checkbox>
                </div>
            </tt-card>
        </div>
    </div>
</tt-card>
