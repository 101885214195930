// ##################################################################
// ## Author: ODL
// ## Description: beskrivelse
// ## Dependencies:
// ## Mods:
// ## Date: 15.10.2018
// ------------------------------------------------------------------
//  Modified:
//   Date:  26. October 2018
//   By:    BJS
//   Desc:  Rewritten most of the controller
// ##################################################################

import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';

(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailCreate', {
        templateUrl: 'views/components/views/emailCreate/emailCreate.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'base64', '$q', 'stateService', 'utilityService', 'modalService', 'layoutService', 'ieScreenBlockService', 'fileService', 'printService', 'docarcService', 'documentsService', 'graphAuthService', 'graphMailService', 'emailCreateService', 'htmlReportService', 'pdfService', '$uibModal', function ($stateParams, base64, $q, stateService, utilityService, modalService, layoutService, ieScreenBlockService, fileService, printService, docarcService, documentsService, graphAuthService, graphMailService, emailCreateService, htmlReportService, pdfService, $uibModal) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            let onDestroy = [];

            vm.model = {
                documentListButtons: [
                    {
                        id: 'preview',
                        icon: 'far fa-file-search',
                        type: 'primary',
                        tooltip: 'preview',
                        onClick: ({ event, item }) => {
                            const modalRef = $uibModal.open({
                                component: 'ttDocumentViewerModal',
                                resolve: {
                                    ttModel: function () {
                                        return item;
                                    },
                                    ttEmailUserSenderFallback: function () {
                                        return true;
                                    }
                                },
                                size: 'pst-ninety',
                            });

                            modalRef.closed.then(function () {
                                vm.grid.gridfunc.rebind();
                                setSumDebitCredit();
                            });
                        }
                    }
                ],
                custNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customerpersons', { cust_no: vm.model.edit.cust_no }) }
                ],
                emailTemplateButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => resetEmailTemplate() },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplates', {}) }
                ],
                emailTemplateButtons2: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => resetEmailTemplate() },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplates', {}) },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('emailtemplate', { emailtemplate_keyno: vm.model.edit.emailtemplate_keyno }) }
                ],
                recipientEmailButtons: [
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => rememberAndGoTo('emailAddTo') }
                ],
                copyEmailButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.edit.copy_email = '' },
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => rememberAndGoTo('emailAddCopy') }
                ],
                bbcEmailButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.edit.bcc_email = '' },
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => rememberAndGoTo('emailAddBcc') }
                ],
                bbcEmailButtons2: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.edit.bcc_email = '' },
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => rememberAndGoTo('emailAddBcc') },
                    { id: 'setting', icon: 'fa fa-cog', color: 'warning', type: 'warning', onClick: () => rememberAndGoTo('businesscosettingsmtp') }
                ],
                customerFilterValueButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.edit.customer_filtervalue = '' }
                ],
                productFilterValueButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.edit.product_filtervalue = '' }
                ],
                bgAttatchment: {
                    default: '0',
                    selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_attatchment_report', item_func: 'REPORT' },
                        { item_id: '1', item_name: 'bg_attatchment_file', item_func: 'FILE' }
                    ]
                },
                bgAttatchDocs: {
                    default: '0',
                    selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_attatchdocs_customer', item_func: 'CUSTOMER' },
                        { item_id: '1', item_name: 'bg_attatchdocs_products', item_func: 'PRODUCT' },
                        { item_id: '2', item_name: 'bg_attatchdocs_custact', item_func: 'CUSTACT' }
                    ]
                },
                edit: {},
                p2SelectReportAttachList: [],
                p2DocumentsList: [],
                p2DocumentsListCustNo: [],
                p2DocumentsListOrderInternalNo: [],
                p2DocumentsListCustactNo: [],
                selectListEmailTemplates: [],
                readyEmailBody: true
            };

            var loadingDocumentsList = false;
            var loadingDocumentsListCustNo = false;
            var loadingDocumentsListOrderInternalNo = false;
            var loadingDocumentsListCustactNo = false;
            var loadingDetails = false;
            var loadingWebpageReports = false;

            vm.style = {
                editor: {
                    div: {
                        height: '',
                        minHeight: '350px',
                        maxHeight: '700px'
                    }
                }
            };

            let resetEmailTemplate = function () {
                vm.model.edit.emailtemplate_keyno = '0';

                loadEmailCreate();
            };

            let rememberAndGoTo = function (id) {
                switch (id) {
                    case 'emailAddTo':
                        rememberEmailCreate();

                        stateService.go('emailadd', {
                            argtype: $stateParams.argtype,
                            argvalue: $stateParams.argvalue,
                            functionname: 'to'
                        });

                        break;
                    case 'emailAddCopy':
                        rememberEmailCreate();

                        stateService.go('emailadd', {
                            argtype: $stateParams.argtype,
                            argvalue: $stateParams.argvalue,
                            functionname: 'copy'
                        });
                        break;
                    case 'emailAddBcc':
                        rememberEmailCreate();

                        stateService.go('emailadd', {
                            argtype: $stateParams.argtype,
                            argvalue: $stateParams.argvalue,
                            functionname: 'bcc'
                        });
                        break;
                    case 'businesscosettingsmtp':
                        rememberEmailCreate();

                        stateService.go('businesscosettingsmtp', { businessco_no: vm.model.edit.businessco_no });
                        break;
                    default:
                        break;
                };
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region PROCEDURE ON LAYOUT CHANGE FUNCTION CALL

            layoutService.onLayoutChanged(onDestroy, function (info) {
                setEditorHeight();
            });

            // #endregion PROCEDURE ON LAYOUT CHANGE FUNCTION CALL

            // #region LOAD PROCEDURE FUNCTIONS

            var stopScreenBlock = function () {
                if (loadingDocumentsList === true) return;
                if (loadingDocumentsListCustNo === true) return;
                if (loadingDocumentsListOrderInternalNo === true) return;
                if (loadingDetails === true) return;
                if (loadingWebpageReports === true) return;
                

                ieScreenBlockService.stop();
            };

            var loadP2DocumentsList = function () {
                if (angular.isString(vm.model.edit.argtype) !== true) return;
                if (vm.model.edit.argtype.length < 1) return;
                if (angular.isString(vm.model.edit.argvalue) !== true) return;
                if (vm.model.edit.argvalue.length < 1) return;
                if (loadingDocumentsList === true) return;

                loadingDocumentsList = true;

                ieScreenBlockService.start();

                return documentsService.loadP2DocumentsList({
                    argtype: vm.model.edit.argtype + '_products',
                    argvalue: vm.model.edit.argvalue,
                    searchtext: '',
                    doctype: 'a',
                    onlyFilenames: '0',
                    glyphicon_select: '0'
                }).then(function (data) {
                    angular.copy(data, vm.model.p2DocumentsList);

                    loadingDocumentsList = false;

                    stopScreenBlock();
                });
            };

            var loadP2DocumentsListCustactNo = function () {
                //if (angular.isString(vm.model.edit.custact_no) !== true) return;
                if (loadingDocumentsListCustactNo === true) return;
                if (vm.model.edit.custact_no.length > '0') {

                    loadingDocumentsListCustactNo = true;

                    ieScreenBlockService.start();


                    return documentsService.loadP2DocumentsList({
                        argtype: 'custact_no',
                        argvalue: vm.model.edit.custact_no,
                        searchtext: '',
                        doctype: 'a',
                        onlyFilenames: '0',
                        glyphicon_select: '0'
                    }).then(function (data) {
                        angular.copy(data, vm.model.p2DocumentsListCustactNo);

                        loadingDocumentsListCustactNo = false;

                        stopScreenBlock();
                    });
                }
            };

            var loadP2DocumentsListCustNo = function () {
                if (angular.isString(vm.model.edit.cust_no) !== true) return;
                if (vm.model.edit.cust_no.length < 1 || vm.model.edit.cust_no === '0') return;
                if (loadingDocumentsListCustNo === true) return;

                loadingDocumentsListCustNo = true;

                ieScreenBlockService.start();



                return documentsService.loadP2DocumentsList({
                    argtype: vm.model.edit.argtype === 'order_internal_no' ? 'order_internal_no' : 'cust_no',
                    argvalue: vm.model.edit.argtype === 'order_internal_no' ? vm.model.edit.argvalue : vm.model.edit.cust_no,
                    searchtext: '',
                    doctype: 'a',
                    onlyFilenames: '0',
                    glyphicon_select: '0'
                    //glyphicon_select: '1'
                }).then(function (data) {
                    angular.copy(data, vm.model.p2DocumentsListCustNo);

                    loadingDocumentsListCustNo = false;

                    stopScreenBlock();
                });
            };
            
            var loadP2DocumentsListOrderInternalNo = function () {
                if (angular.isString(vm.model.edit.argvalue) !== true) return;
                if (vm.model.edit.argvalue.length < 1 || vm.model.edit.argvalue === '0') return;
                if (loadingDocumentsListOrderInternalNo === true) return;

                loadingDocumentsListOrderInternalNo = true;

                ieScreenBlockService.start();



                return documentsService.loadP2DocumentsList({
                    argtype: 'order_internal_no',
                    argvalue: vm.model.edit.argvalue,
                    searchtext: '',
                    doctype: 'a',
                    onlyFilenames: '0',
                    glyphicon_select: '0'
                    //glyphicon_select: '1'
                }).then(function (data) {
                    angular.copy(data, vm.model.p2DocumentsListOrderInternalNo);

                    loadingDocumentsListOrderInternalNo = false;

                    stopScreenBlock();
                });
            };

            var loadDetails = function () {
                let deferred = $q.defer();

                if (loadingDetails === true) return;

                loadingDetails = true;

                ieScreenBlockService.start();

                if ($stateParams.argtype === 'order_internal_no') {
                    vm.model.bgAttatchDocs.records.push(
                        { item_id: '3', item_name: 'bg_attatchdocs_order', item_func: 'ORDER' }
                    );
                }

                emailCreateService.details($stateParams.webpage, $stateParams.argtype, $stateParams.argvalue).then(function (data) {
                    angular.copy(data, vm.model.edit);

                    vm.model.edit.email_body = vm.model.edit.email_signature;

                    $q.all([
                        loadP2DocumentsList(),
                        loadP2DocumentsListCustNo(),
                        loadP2DocumentsListCustactNo(),
                        loadP2DocumentsListOrderInternalNo(),
                        loadEmailTemplates()
                    ]).then(() => deferred.resolve());

                    loadingDetails = false;

                    stopScreenBlock();

                    if (vm.model.edit.errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: vm.model.edit.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                });

                return deferred.promise;
            };

            let loadEmailCreate = function () {
                if (utilityService.validateParmsValue(vm.model.edit.emailtemplate_keyno, true) !== true) return;

                emailCreateService.getEmailCreate(vm.model.edit).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    vm.model.edit.email_body = vm.model.edit.email_signature;
                });
            };

            let loadEmailTemplates = function () {
                if (utilityService.validateParmsValue(vm.model.edit.webpage) !== true) return;

                return emailCreateService.listEmailTemplates({ webpage: vm.model.edit.webpage }).then(function (result) {
                    angular.copy(result, vm.model.selectListEmailTemplates);
                });
            };

            var loadWebpageReports = function () {
                if (loadingWebpageReports === true) return;

                loadingWebpageReports = true;

                ieScreenBlockService.start();

                return emailCreateService.webpageReports($stateParams.webpage, $stateParams.argtype, $stateParams.argvalue).then(function (data) {
                    angular.copy(data, vm.model.p2SelectReportAttachList);

                    loadingWebpageReports = false;

                    stopScreenBlock();
                });
            };

            var load = function () {
                //loadDetails();
                return loadWebpageReports();
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region FETCH VALUE PROCEDURE FUNCTION

            var loadBeforeGet = function () {
                let deferred = $q.defer();

                emailCreateService.loadEmailCreateGetBefore().then(function (data) {
                    if (data[0].previous_class === '') {
                        loadDetails().then(() => deferred.resolve());
                    } else {
                        var jsonencoded = '';
                        jsonencoded = data[0].previous_class;
                        var jsonuncoded = '';
                        jsonuncoded = atob(jsonencoded);
                        vm.model.edit = angular.copy(angular.fromJson(jsonuncoded));

                        $q.all([
                            loadP2DocumentsList(),
                            loadP2DocumentsListCustNo(),
                            loadEmailTemplates()
                        ]).then(() => deferred.resolve());

                        if (data[0].emails_to_add_to !== '') {
                            if (vm.model.edit.recipient_email !== '' && vm.model.edit.recipient_email.indexOf(';') <= 1) {
                                vm.model.edit.recipient_email += '; ';
                            }
                            vm.model.edit.recipient_email += data[0].emails_to_add_to;
                        }

                        if (data[0].emails_to_add_copy !== '') {
                            if (vm.model.edit.copy_email !== '' && vm.model.edit.copy_email.indexOf(';') <= 1) {
                                vm.model.edit.copy_email += '; ';
                            }
                            vm.model.edit.copy_email += data[0].emails_to_add_copy;
                        }

                        if (data[0].emails_to_add_bcc !== '') {
                            if (vm.model.edit.bcc_email !== '' && vm.model.edit.bcc_email.indexOf(';') <= 1) {
                                vm.model.edit.bcc_email += '; ';
                            }
                            vm.model.edit.bcc_email += data[0].emails_to_add_bcc;
                        }
                    }
                });

                return deferred.promise;
            };

            // #endregion FETCH VALUE PROCEDURE FUNCTION

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            var rememberEmailCreate = function () {
                var variableNameEmailCreate = 'w_emailadd_get';
                var jsonuncoded = '';
                jsonuncoded = angular.toJson(vm.model.edit);
                var jsoncoded = '';
                jsoncoded = btoa(jsonuncoded);
                //var variableValueEmailCreate = jsoncoded;
                //var variableValueEmailCreate = angular.toJson(vm.model.edit);

                emailCreateService.remember(variableNameEmailCreate, jsoncoded);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region BUTTON GROUP FUNCTIONS

            vm.selectBgAttatchment = function (item) {
                vm.model.bgAttatchment.selected = item;
            };

            vm.selectBgAttatchDocs = function (item) {
                vm.model.bgAttatchDocs.selected = item;
            };

            // #endregion BUTTON GROUP FUNCTIONS

            // #region SEND EMAIL BUTTON FUNCTION
            vm.getHtmlReport = async function (p2_reportdef_keyno, instanceId) {
                try {
                    const p2ReportDef = (await pdfService.loadP2ReportDef(p2_reportdef_keyno))[0];
                    const htmlReport = (await htmlReportService.getReport(p2ReportDef.htmlreport_keyno))[0];

                    const parms = {
                        htmlreport_keyno: htmlReport.htmlreport_keyno,
                        jsonwhere: '{ "' + vm.model.edit.argtype + '": "' + vm.model.edit.argvalue + '" }'
                    }

                    const htmlReportHtml = (await htmlReportService.getHtmlReportHtml(parms))[0];

                    const htmlparms = {
                        sourceHtml: base64.urldecode(htmlReportHtml.htmlstring),
                        filename: base64.urldecode(htmlReportHtml.filename),
                        saveToFileServer: true,
                        pdfOptions: {
                            marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                            landscape: p2ReportDef.print_landscape == '1' ? true : false,
                            outline: false,
                            printBackground: true,
                            displayHeaderFooter: true,
                            headerTemplate: base64.urldecode(htmlReportHtml.headerstring),
                            footerTemplate: base64.urldecode(htmlReportHtml.footerstring),
                        }
                    };
                    if (p2ReportDef.paper_size_id !== 'standard' && p2ReportDef.height > 0 && p2ReportDef.width > 0) {
                        htmlparms.pdfOptions.height = `${p2ReportDef.height}mm`;
                        htmlparms.pdfOptions.width = `${p2ReportDef.width}mm`;

                        htmlparms.pdfOptions.marginOptions = { bottom: '5mm', top: '5mm', left: '5mm', right: '5mm' };
                    }

                    const retval = await pdfService.htmlToPdfBlob(htmlparms.sourceHtml, htmlparms);
                    retval.instanceId = instanceId;
                    return retval;
                } catch (e) {
                    console.dir(e);
                }
            }

            vm.sendEmail = function () {

                //console.log(vm.model.p2DocumentsListCustNo.filter((item) => item.item_is_selected === '1'));
                //console.log(vm.model.p2DocumentsList.filter((item) => item.item_is_selected === '1'));
                //console.log(vm.model.p2DocumentsListCustactNo.filter((item) => item.item_is_selected === '1'));
                //return;
                var promises = [];
                var instances = {};
                var parameters = {};

                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Generating report and saving to fileserver.');

                parameters[vm.model.edit.argtype] = vm.model.edit.argvalue;

                for (var i = 0; i < vm.model.p2SelectReportAttachList.length; i++) {
                    if (vm.model.p2SelectReportAttachList[i].item_is_selected !== '1') continue;

                    var instanceId = uuid();

                    instances[instanceId] = { p2_reportdef_keyno: vm.model.p2SelectReportAttachList[i].p2_reportdef_keyno };

                    switch (vm.model.p2SelectReportAttachList[i].report_type_keyno) {
                        case '1':
                            promises.push(printService.archive({
                                reportId: vm.model.p2SelectReportAttachList[i].report_id,
                                instanceId: instanceId,
                                parameters: parameters
                            }));
                            break;
                        case '2':
                            promises.push(vm.getHtmlReport(vm.model.p2SelectReportAttachList[i].p2_reportdef_keyno, instanceId));
                            break;
                        default:
                            console.error('Invalid report type in emailCreate.component for report keyno: ' + vm.model.p2SelectReportAttachList[i].report_id);
                            break;
                    }
                }

                $q.all(promises).then(function (result) {
                    var detailsPromises = [];

                    ieScreenBlockService.setMessage('Getting file details from fileserver.');

                    var newDocumentsCount = result.length;
                    angular.forEach(result, function (fileItem) {
                        detailsPromises.push(fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }));
                    });

                    angular.forEach(vm.model.p2DocumentsList, function (item) {
                        if (item.item_is_selected === '1') {
                            detailsPromises.push(fileService.getFileDetails({ fileName: item.docfilename, wait: true, instanceId: item.item_id }));
                        }
                    });

                    angular.forEach(vm.model.p2DocumentsListCustNo, function (item) {
                        if (item.item_is_selected === '1') {
                            detailsPromises.push(fileService.getFileDetails({ fileName: item.docfilename, wait: true, instanceId: item.item_id }));
                        }
                    });

                    angular.forEach(vm.model.p2DocumentsListCustactNo, function (item) {
                        if (item.item_is_selected === '1') {
                            detailsPromises.push(fileService.getFileDetails({ fileName: item.docfilename, wait: true, instanceId: item.item_id }));
                        }
                    });

                    angular.forEach(vm.model.p2DocumentsListOrderInternalNo, function (item) {
                        if (item.item_is_selected === '1') {
                            detailsPromises.push(fileService.getFileDetails({ fileName: item.docfilename, wait: true, instanceId: item.item_id }));
                        }
                    });

                    $q.all(detailsPromises).then(async function (detailsResult) {
                        var existing = detailsResult.splice(newDocumentsCount, detailsResult.length - newDocumentsCount);

                        var docarcPromises = [];

                        angular.forEach(detailsResult, function (dr) {
                            var docarc = {
                                filename: dr.fileName,
                                contentType: dr.contentType || '',
                                latitude: 0,
                                longitude: 0,
                                altitude: 0,
                                createdTimestamp: dr.regDateTime || '',
                                size: dr.size || 0,
                                checksum: dr.checksum || '',
                                argType: vm.model.edit.argtype || '',
                                argValue: vm.model.edit.argvalue || '',
                                p2ReportdefKeyno: instances[dr.instanceId].p2_reportdef_keyno
                            };
                            docarcPromises.push(docarcService.add(docarc));
                        });

                        ieScreenBlockService.setMessage('Registering reports in touchtime database.');

                        let sentByClient = '1';

                        try {
                            await graphAuthService.getAccount();
                        } catch (error) {
                            sentByClient = '0';
                        }

                        $q.all(docarcPromises).then(function (docarcResult) {
                            var emailObj = {
                                recipient_email: vm.model.edit.recipient_email,
                                copy_email: vm.model.edit.copy_email,
                                bcc_email: vm.model.edit.bcc_email,
                                from_email: vm.model.edit.from_email,
                                email_heading: base64.urlencode(vm.model.edit.email_heading),
                                email_body: base64.urlencode(vm.model.edit.email_body),
                                webpage: vm.model.edit.webpage,
                                argtype: vm.model.edit.argtype,
                                argvalue: vm.model.edit.argvalue,
                                reports: [],
                                documents: [],
                                send_by_client: sentByClient,
                                emailtemplate_keyno: vm.model.edit.emailtemplate_keyno
                            };

                            angular.forEach(docarcResult, function (item) {
                                emailObj.reports.push({ docarc_keyno: item[0].docarc_keyno, docfilename: item[0].docfilename });
                            });

                            angular.forEach(existing, function (item) {
                                emailObj.documents.push({ docarc_keyno: item.instanceId, docfilename: item.localFileName });
                            });

                            ieScreenBlockService.setMessage('Sending email and adding email record to database.');

                            // BJS 20231204 - Modified procedure to not return an array for the data, this is not necessary.
                            //                Modified mailinfo to return an array of mailinfo objects to enable sending more than
                            //                than one email at the same time
                            emailCreateService.sendEmail(emailObj).then(function (data) {

                                ieScreenBlockService.stop();
                                const result = typeof data[0] == 'object' ? data[0] : data;
                                if (result?.errorcode != 0) {

                                    modalService.show({
                                        type: 'warning',
                                        title: 'Varsel',
                                        message: result?.errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    if (sentByClient === '1' && result?.mailinfo && result.mailinfo?.length > 0) {
                                        //if (sentByClient === '1' && data?.mailinfo && data.mailinfo.length > 0) {
                                        try {
                                            angular.forEach(data.mailinfo, function (mi) {
                                                graphMailService.sendMessage({ subject: base64.urldecode(mi.subject), content: base64.urldecode(mi.bodyHtml), recipients: mi.to, ccRecipients: mi.cc, bccRecipients: mi.bcc, attachments: mi.attachments, userId: mi.from });
                                            });
                                        } catch (error) {
                                            modalService.show({
                                                type: 'warning',
                                                title: 'Varsel',
                                                message: 'An error occured',
                                                buttons: [{
                                                    label: 'OK',
                                                    cssClass: 'btn-warning',
                                                    action: function (dialogItself) {
                                                        dialogItself.close();
                                                    }
                                                }]
                                            });
                                        }
                                    }

                                    load();

                                    if (vm.model.edit.after_create_email_goto_orderheads === '1') {
                                        if ($stateParams.argtype === 'order_internal_no') {
                                            stateService.back('/orderheads/-/-');
                                        } else if ($stateParams.argtype === 'invoice_no') {
                                            stateService.back('/invoiceheads/-/-');
                                        } else {
                                            stateService.back();
                                        }
                                    } else {
                                        stateService.back();
                                    }
                                }
                            }, (function (data) {
                                console.log('on failure');
                                console.dir(data);
                                ieScreenBlockService.stop();
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data.data.message,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            }));
                        }, function (re) {
                            console.log('docarc rejection');
                            console.dir(re);
                        });
                    });
                }).catch(function (error) {
                    console.error('Error in promises:', error);
                });
            };

            // #endregion SEND EMAIL BUTTON FUNCTION

            // #region TOGGLE FUNCTIONS

            vm.toggleChosenReport = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            vm.toggleChosenCustNo = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            vm.toggleChosenDocument = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'portalusersignature':
                        go({ portal_user_keyno: vm.model.edit.portal_user_keyno });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region DIRECTION BUTTON FUNCTIONS

            vm.goToCustomerPersons = function () {
                stateService.go('customerpersons', {
                    cust_no: vm.model.edit.cust_no
                });
            };

            // #endregion DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'emailtemplate_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.emailtemplate_keyno) !== true) return;

                        vm.model.edit.emailtemplate_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.emailtemplate_keyno, true) !== true) return;

                        loadEmailCreate();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            function setEditorHeight() {
                let sumOtherHeight = 0;

                let buttons = [...document.querySelectorAll('button')].filter((button) => {
                    if (!button.closest('.im-btn-group')) {
                        return true;
                    } else if ((button.closest('.im-btn-group') && button.classList.contains('im-group-btn-1'))) {
                        sumOtherHeight += 15;
                        return true;
                    }

                    return false;
                });

                let inputs = [...document.querySelectorAll('.tt-input__container')];
                let ttHeader = document.querySelector('label[tt-header]');
                let height = (inputs[0]?.clientHeight ?? 0) + 7;

                sumOtherHeight += (buttons.length + inputs.length - 1) * height + 55 + 40 + 15 + (ttHeader?.clientHeight == 0);
                vm.style.editor.div.height = 'calc(100vh - ' + sumOtherHeight + 'px)';
                vm.style.editor = { ...vm.style.editor };
            }

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                $q.all([
                    loadBeforeGet(),
                    load()
                ]).then(() => {
                    //let load = document.querySelectorAll('.tt-input__container, button');
                    //console.dir(load);

                    setEditorHeight();
                });
            };

            // #endregion ON INIT FUNCTION

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                //document.removeEventListener('click', toggleModalOnEvent);
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
