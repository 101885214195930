(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("deliveryMethodBusinesscoService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            businesscos: {
                records: []
            },
            businesscoToggle: function (id, active_value) {
                var deferred = $q.defer();

                var parms_businesscoToggle = {
                    method: 397,
                    parameters: {
                        deliverymethod_businessco_keyno: id,
                        active: active_value
                    }
                };

                $ihttp.post(parms_businesscoToggle).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            businesscoList: function (id) {
                var deferred = $q.defer();

                var parms_businesscoList = {
                    method: 393,
                    parameters: {
                        deliverymethod_no: id
                    }
                };

                $ihttp.post(parms_businesscoList).then(function (data) {
                    service.businesscos.records = [];

                    angular.forEach(data, function (item) {
                        //service.businesscos.records.push(item);
                        var icon = 'glyphicon-unchecked';

                        if (item.active == '1') {
                            icon = 'glyphicon-check';
                        }

                        if (item.agreement_id == '' && item.internal_driver === '0') {
                            icon = 'glyphicon-ban-circle';
                        }

                        var i = {
                            item_id: item.deliverymethod_businessco_keyno,
                            //item_order: item.company_name,
                            item_name: item.company_name,
                            item_agreement_id: item.agreement_id,
                            item_glyphicon: icon,
                            item_active: item.active
                        };

                        service.businesscos.records.push(i);
                    });

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
