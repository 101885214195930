<tt-card *ngIf="deviationType" class="col-md-4 sp-4" [ttHeading]="deviationTypeKeyno === '0' ? 'create_deviationtype' : 'edit_deviationtype'">
    <tt-input ttLabel="deviationtype_keyno" [ttModel]="deviationType.deviationtype_keyno" ttReadonly></tt-input>
    <tt-input *ngIf="deviationTypeKeyno > '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationType.deviationtype_name" [ttButtons]="nameButtons"></tt-input>
    <tt-input *ngIf="deviationTypeKeyno === '0'" ttLabel="deviationtype_name" [(ttModel)]="deviationType.deviationtype_name"></tt-input>
    <tt-checkbox ttLabel="isactive" [(ttModel)]="deviationType.isactive"></tt-checkbox>
    <tt-button-save ttText="deviationtype_save" (ttClick)="save()" [ttLoading]="saving" [ttDisabled]="deleting"></tt-button-save>
    <ng-container *ngIf="deviationTypeKeyno > '0'">
        <tt-button ttText="deviationtypemenu_delete" ttType="danger" (ttClick)="delete()" [ttLoading]="deleting"></tt-button>
        <tt-tabs [(ttSelectedId)]="selectedtab" ttRememberId="deviationtype_tab">
            <tt-tab ttId="activitytype_relation" ttLabel="deviationtypemenu_activitytypedevtypes"></tt-tab>
            <tt-tab ttId="deviationsource_relation" ttLabel="deviationtype_deviationsources"></tt-tab>
        </tt-tabs>
        <ng-container [ngSwitch]="selectedtab">
            <tt-multi-select
                *ngSwitchCase="'activitytype_relation'"
                [ttData]="activityTypes"
                (ttSelectItem)="onActivityTypeRelationChanged($event)"
                (ttCheckAll)="onAllActivityTypesChecked($event)"
                ttAllSelected="false"
            ></tt-multi-select>
            <tt-multi-select
                *ngSwitchCase="'deviationsource_relation'"
                [ttData]="deviationSources"
                ttDataId="deviationsource_keyno"
                ttDataName="deviationsrouce_name"
                (ttSelectItem)="onDeviationSourceRelationChanged($event)"
                (ttCheckAll)="onAllDeviationSourceChecked($event)"
                ttAllSelected="false"
            ></tt-multi-select>
        </ng-container>
    </ng-container>
</tt-card>
