import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { ChangePasswordDialogComponent } from '@app/modal/change-password-dialog/change-password-dialog.component';

@Component({
    selector: 'tt-businessco-setting-smtp',
    templateUrl: './businessco-setting-smtp.component.html',
    styleUrls: ['./businessco-setting-smtp.component.css'],
})
export class BusinesscoSettingSmtpComponent implements OnInit {
    public get?: BusinesscosettingSmtpGet;
    public newPassword: string = '';

    public sendingTestEmail = false;

    public saving = false;

    constructor(private datatask: DataTaskService, private state: StateService, private translate: TranslateService, private dialog: MatDialog, private modal: ModalService) {}

    public openChangePasswordDialog() {
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, { width: '60rem', maxWidth: '98vh', data: { password: this.newPassword } });

        dialogRef.afterClosed().subscribe((value?: string) => {
            if (value !== undefined) {
                this.newPassword = value;
                this.get!.update_password = '1';
            }
        });
    }

    public undoChangePassword() {
        this.newPassword = '';
        this.get!.update_password = '0';
    }

    public async sendTestEmail() {
        this.sendingTestEmail = true;

        try {
            await this.saveSmtpConfig();
            const response = await this.datatask.Post(237, { businessco_no: this.state.getStateParams()['businessco_no'] });

            this.modal.show({ type: 'success', message: response[0].resultmessage });
        } finally {
            this.sendingTestEmail = false;
        }
    }

    public async save() {
        this.saving = true;

        try {
            this.saveSmtpConfig();
            await this.datatask.Post(236, this.get);
            this.state.back();
        } finally {
            this.saving = false;
        }
    }

    private saveSmtpConfig() {
        if (this.get!.update_password === '1') {
            this.get!.smtp_auth_password = this.newPassword;
        } else {
            this.get!.smtp_auth_password = '';
        }

        return this.datatask.Post(236, this.get);
    }

    private async getPageLoad() {
        this.get = (await this.datatask.Post(235, { businessco_no: this.state.getStateParams()['businessco_no'] }))[0];
    }

    async ngOnInit(): Promise<void> {
        this.getPageLoad();
    }
}

// All types are currently `string` because all? return data from database is string
interface BusinesscosettingSmtpGet {
    businessco_companyname: string;
    smtp_server: string;
    smtp_port: number;
    auth_required: '1' | '0';
    smtp_auth_username: string;
    smtp_auth_username_subtext: string;
    smtp_auth_password: string;
    email: string;
    email_bcc: string;
    businessco_no: string;
    update_password: '1' | '0';
}
