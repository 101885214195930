(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansOrders', {
        templateUrl: 'views/components/views/consignmentPlansOrders/consignmentPlansOrders.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'consignmentPlansOrdersService', 'ieScreenBlockService', 'utilityService', function ($stateParams, $timeout, stateService, consignmentPlansOrdersService, ieScreenBlockService, utilityService) {
            var vm = this;
            var resizing = false;
            var editing = true;
            var onDestroy = [];

            vm.model = {
                argtype: $stateParams.argtype,
                argvalue: $stateParams.argvalue,
                searchButtons: [
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => vm.searchOrders(vm.model.searchtext) },
                ],
                //btnListFilterVal: [
                //    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                //],
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.consignmentPlansOrdersColumnResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }

                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.consignmentPlansOrdersColumnResize();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        //{ item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        //{ item_id: '5', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: false },
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                lines: [],
                linesRaw: [],
                loaded: false
            };

            let searchTimeout = null;
            let resolver = null;

            vm.searchOrders = function (value) {
                vm.model.searchtext = value;

                if (searchTimeout) {
                    $timeout.cancel(searchTimeout);
                }

                if (angular.isFunction(resolver?.resolve)) {
                    resolver.reject();
                }

                vm.model.searchButtons[0].icon = 'fas fa-spinner fa-spin';

                if (!value || value.trim() === '') {
                    vm.model.lines = [];
                    vm.model.searchButtons[0].icon = 'glyphicon glyphicon-search';
                } else {
                    searchTimeout = $timeout(() => {
                        resolver = consignmentPlansOrdersService.loadOrders({
                            custact_no: vm.model.argvalue,
                            searchstring: vm.model.searchtext
                        });

                        resolver.promise.then(function (data) {
                            resolver = null;

                            let smallDataLoad = [];

                            for (let i = 0; i < data.length; i++) {
                                data[i].item_id = illegalCharRegExp(data[i].item_id);
                                smallDataLoad.push(data[i]);
                            }

                            angular.copy(smallDataLoad, vm.model.lines);
                            angular.copy(smallDataLoad, vm.model.linesRaw);
                            vm.loaded = true;

                            if (!vm.model.searchtext || vm.model.searchtext.trim() === '') {
                                vm.model.lines = [];
                            }

                            vm.model.searchButtons[0].icon = 'glyphicon glyphicon-search';
                        });
                    }, 400);
                }
            };

            //vm.btnListFuncFilterVal = function (item_func) {
            //    switch (item_func) {
            //        case 'erase_item':
            //            vm.model.filterval = '';
            //            break;
            //        default:
            //            break;
            //    }
            //};

            var illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            };

            vm.consignmentPlansOrdersColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateList = [];

                if (angular.isDefined(vm.model.lines[0]) && angular.isDefined(vm.model.lines[0].item_secondary)) {
                    for (var r = 0; r < vm.model.lines[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.lines[0].item_col_width })
                        }

                        if (angular.isDefined(vm.model.lines[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.lines[0].item_secondary[r].item_col_width })
                        }
                    }
                }

                var updateParms = {
                    view_id: 'consignmentplansorders.list',
                    records: updateList
                };

                consignmentPlansOrdersService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }

                    resizing = false;
                });
            };

            vm.gridClick = function (item) {
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;

                if (baseItem.item_id != 0) {
                    if (vm.model.argtype == 'custact') {
                        ieScreenBlockService.start('message');
                        ieScreenBlockService.setMessage('Oppretter forsendelse og kobler til utkjøring');

                        consignmentPlansOrdersService.linkOrder({
                            custact_no: vm.model.argvalue,
                            order_internal_no: baseItem.item_id
                        }).then(function (data) {
                            ieScreenBlockService.stop();
                            stateService.go('consignmentplansconsignments', { custact_no: vm.model.argvalue });
                        });
                    } else {
                        ieScreenBlockService.start('message');
                        ieScreenBlockService.setMessage('Oppretter forsendelse og kobler til utkjøring');

                        consignmentPlansOrdersService.createConsignment({
                            order_internal_no: baseItem.item_id
                        }).then(function (data) {
                            ieScreenBlockService.stop();
                            stateService.go('consignmentplansconsignmentcontent', { consignment_keyno: data[0].consignment_keyno, custact_no: 0 });
                        });
                    }
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
