(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('interBranchMulti', {
        templateUrl: 'views/components/views/interBranchMulti/interBranchMulti.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['interBranchMultiService', function (interBranchMultiService) {
            const vm = this;
            
            vm.model = {
                itemsListMultiInterBranches: []
            };

            async function loadMultiInterBranches() {
                vm.model.itemsListMultiInterBranches = (await interBranchMultiService.listMultiInterBranches());
            }

            vm.$onInit = function () {
                loadMultiInterBranches();
            };
        }]
    });
})();
