(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('interBranchLevel', {
        templateUrl: 'views/components/views/interBranchLevel/interBranchLevel.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'interBranchLevelService', 'logisticService', 'economyService', function ($stateParams, stateService, interBranchLevelService, logisticService, economyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let interBranchLevelKeyno = $stateParams.interbranchlevel_keyno;
            
            vm.model = {
                edit: {},
                selectListCompanies: [],
                selectListPrices: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            async function loadEdit() {
                vm.model.edit = (await interBranchLevelService.getEdit({ interbranchlevel_keyno: interBranchLevelKeyno }))[0];
            }

            async function loadCompanies() {
                vm.model.selectListCompanies = (await logisticService.listCompanies());
            }

            async function loadPrices() {
                vm.model.selectListPrices = (await economyService.listPrices());
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                interBranchLevelService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadPrices();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
